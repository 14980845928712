import React from 'react'
import { useSelector, useDispatch } from "react-redux";
//redux actions
import { D_Handle_Show_Popup, D_RESET_ERROR_MESSAGE, D_AddTag, D_PatchTag, D_Reset_Complete_Api_Status } from "../../../costSlice"
import ListModal from '../../../subcomponents/Form/ListModal';
import { 
    API_Default_SalesChannel_Data,
    API_Default_CapacityPlanning_Data,
    API_Default_Cost_Data,
    API_Default_DerivedCost_Data,
    API_Default_UnderivedCost_Data,
    API_Default_GACost_Data,
    API_Default_CapexCost_Data,
    API_Default_DirectCost_Data,
    API_GET_SalesChannelList_Data,
    API_GET_Direct_Cost_List_Data,
    API_GET_CapacityPlanning_Data,
    API_GET_Cost_Data,
    API_GET_DerivedCost_Data,
    API_GET_UnderivedCost_Data,
    API_GET_GACost_Data,
    API_GET_CapexCost_Data,
    API_CREATE_SalesChannelList_Data,
    API_CREATE_DirectCostList_Data,
    API_CREATE_CapacityPlanning_Data,
    API_CREATE_Cost_Data,
    API_CREATE_DerivedCost_Data,
    API_CREATE_UnderivedCost_Data,
    API_CREATE_GACost_Data,
    API_CREATE_CapexCost_Data,
    API_PUT_SalesChannelList_Data,
    API_PUT_DirectCostList_Data,
    API_PUT_CapacityPlanning_Data,
    API_PUT_Cost_Data,
    API_PUT_DerivedCost_Data,
    API_PUT_UnderivedCost_Data,
    API_PUT_GACost_Data,
    API_PUT_CapexCost_Data,
} from "../../../costSlice";

const CategoryList = ({
    Title,
    InputPlaceholder,
    warningTooltipDataIndex
}) => {
    const dispatch = useDispatch();
    const isTagModalOpen = useSelector((state) => state.costScreen.is_popup_open)
    const category_list_type = useSelector((state) => state.costScreen.category_list_type);
    const rootApiStatus = useSelector((state) => state.costScreen.api_status)
    const ApiStatus = rootApiStatus[category_list_type];
    const DataList = useSelector((state) => state.costScreen.category_list_data);
    const list = DataList[category_list_type];
    React.useEffect(() => {
        dispatch(D_Reset_Complete_Api_Status());
    }, [])


    const CallbackToggleModal = () => dispatch(D_Handle_Show_Popup({ "type": null, "status": false }));
    const CallbackResetNotificationMessage = () => dispatch(D_RESET_ERROR_MESSAGE({ "type": category_list_type }));

    const ApiCallingFunction = (type, arg) => {
        switch (type) {
            case 'fetch': {
                if(category_list_type==="sales_channel_category_list"){
                    dispatch(API_Default_SalesChannel_Data());
                    dispatch(API_GET_SalesChannelList_Data());
                }else if(category_list_type==="direct_cost_category_list"){
                    // dispatch(API_Default_DirectCost_Data());
                    dispatch(API_GET_Direct_Cost_List_Data());
                }else if(category_list_type==="capacity_planning_category_list"){
                    // dispatch(API_Default_CapacityPlanning_Data());
                    dispatch(API_GET_CapacityPlanning_Data());
                }else if(category_list_type==="cost_category_list"){
                    // dispatch(API_Default_Cost_Data());
                    dispatch(API_GET_Cost_Data());
                }else if(category_list_type==="derived_cost_category_list"){
                    // dispatch(API_Default_DerivedCost_Data());
                    dispatch(API_GET_DerivedCost_Data());
                }else if(category_list_type==="un_derived_cost_category_list"){
                    // dispatch(API_Default_UnderivedCost_Data());
                    dispatch(API_GET_UnderivedCost_Data());
                }else if(category_list_type==="g_and_a_cost_category_list"){
                    // dispatch(API_Default_GACost_Data());
                    dispatch(API_GET_GACost_Data());
                }else if(category_list_type==="capex_category_list"){
                    // dispatch(API_Default_CapexCost_Data());
                    dispatch(API_GET_CapexCost_Data());
                }
                break;
            }
            case 'create': {
                if(category_list_type==="sales_channel_category_list"){
                    dispatch(API_CREATE_SalesChannelList_Data({ "salesChannelTitle": arg.tag }));
                }else if(category_list_type==="direct_cost_category_list"){
                    dispatch(API_CREATE_DirectCostList_Data({ "directCostTitle": arg.tag }));
                }else if(category_list_type==="capacity_planning_category_list"){
                    dispatch(API_CREATE_CapacityPlanning_Data({ "capacityPlanningTitle": arg.tag }));
                }else if(category_list_type==="cost_category_list"){
                    dispatch(API_CREATE_Cost_Data({ "costTitle": arg.tag }));
                }else if(category_list_type==="derived_cost_category_list"){
                    dispatch(API_CREATE_DerivedCost_Data({ "derivedCostTitle": arg.tag }));
                }else if(category_list_type==="un_derived_cost_category_list"){
                    dispatch(API_CREATE_UnderivedCost_Data({ "underivedCostTitle": arg.tag }));
                }else if(category_list_type==="g_and_a_cost_category_list"){
                    dispatch(API_CREATE_GACost_Data({ "gaCostTitle": arg.tag }));
                }else if(category_list_type==="capex_category_list"){
                    dispatch(API_CREATE_CapexCost_Data({ "capexCostTitle": arg.tag }));
                }
                break;
            }
            case 'patch': {
                if(category_list_type==="sales_channel_category_list"){
                    dispatch(API_PUT_SalesChannelList_Data({ "salesChannelId": arg.id,"salesChannelTitle": arg.tag }));
                }else if(category_list_type==="direct_cost_category_list"){
                    dispatch(API_PUT_DirectCostList_Data({ "directCostId": arg.id, "directCostTitle": arg.tag }));
                }else if(category_list_type==="capacity_planning_category_list"){
                    dispatch(API_PUT_CapacityPlanning_Data({ "capacityPlanningId": arg.id,"capacityPlanningTitle": arg.tag }));
                }else if(category_list_type==="cost_category_list"){
                    dispatch(API_PUT_Cost_Data({ "costId": arg.id,"costTitle": arg.tag }));
                }else if(category_list_type==="derived_cost_category_list"){
                    dispatch(API_PUT_DerivedCost_Data({ "derivedCostId": arg.id,"derivedCostTitle": arg.tag }));
                }else if(category_list_type==="un_derived_cost_category_list"){
                    dispatch(API_PUT_UnderivedCost_Data({ "underivedCostId": arg.id,"underivedCostTitle": arg.tag }));
                }else if(category_list_type==="g_and_a_cost_category_list"){
                    dispatch(API_PUT_GACost_Data({ "gaCostId": arg.id, "gaCostTitle": arg.tag}));
                }else if(category_list_type==="capex_category_list"){
                    dispatch(API_PUT_CapexCost_Data({ "capexCostId": arg.id,"capexCostTitle": arg.tag }));
                }
                dispatch(D_PatchTag({ "directCostId": arg.id, "directCostTitle": arg.tag }));
                // dispatch(API_Patch_Direct_Cost_List_Name({ "directCostId": arg.id,"directCostTitle": arg.tag }))
                break;
            }
        }
    }

    return (
        <div>
            <ListModal
                Title={Title}
                warningDataIndex={warningTooltipDataIndex}
                InputPlaceholder={InputPlaceholder}
                ListData={list}
                ApiStatus={ApiStatus}
                tooltipMessages={{ warning: 'name exist already in list' }}
                ApiCallingFunction={ApiCallingFunction}
                isTagModalOpen={isTagModalOpen}
                CallbackToggleModal={CallbackToggleModal}
                CallbackResetNotificationMessage={CallbackResetNotificationMessage}
            />
        </div>
    )
}

export default CategoryList