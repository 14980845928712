import React from 'react'
import PGHeader from './components/PGHeader'
import PGFooter from './components/PGFooter'
import { Outlet } from 'react-router-dom'
import locals from '../localization/localization_en_website.json'
import useLocationWebOrProduct from '../hooks/useLocationWebOrProduct'

const HEADER_LOCALS = locals.website.header;
const FOOTER_LOCALS = locals.website.footer;

const Layout = () => {
    
    const IS_USER_IN_PRODUCT = useLocationWebOrProduct();

    return (
        <>
            {
                !(IS_USER_IN_PRODUCT)
                    ? <PGHeader locals={HEADER_LOCALS} />
                    : null
            }
            <div className='mt-[80px]'></div>
            <Outlet />
            {
                !(IS_USER_IN_PRODUCT)
                    ? <PGFooter locals={FOOTER_LOCALS} />
                    : null
            }
        </>
    )
}

export default Layout