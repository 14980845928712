import locals from "../../localization/localization_en_build_business_Canvas.json"

const locals_bbc = locals.build_business_canvas;

export const colorPalette = ["bg-[#EBF1FF]", "bg-[#FEF2C0]", "bg-[#F3E8FC]"]

export const stickyNotesPalette = {
  [locals_bbc.mertics[0].title]: { color: "bg-[#D7C3FF]" },
  [locals_bbc.mertics[1].title]: { color: "bg-[#FFF598]" },
  [locals_bbc.mertics[2].title]: { color: "bg-[#C6FFDB]" },
  [locals_bbc.mertics[3].title]: { color: "bg-[#FFC978]" },
  [locals_bbc.mertics[4].title]: { color: "bg-[#F4939B]" },
  [locals_bbc.mertics[5].title]: { color: "bg-[#F8E964]" },
  [locals_bbc.mertics[6].title]: { color: "bg-[#FCCEDD]" },
  [locals_bbc.mertics[7].title]: { color: "bg-[#B1CAFF]" },
  [locals_bbc.mertics[8].title]: { color: "bg-[#FFC978]" }
};