import React, { useState, useEffect } from 'react';
import { Button, Checkbox, message, Form, Input, Tooltip } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import checkcircle from '../assets/Login/check-circle.svg';
import Message from '../assets/Login/Message.svg';
import Lock from '../assets/Login/Lock.svg';
import { Link } from 'react-router-dom';
import { createUser } from '../features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import test3 from '../assets/Login/newResetpassword.svg';
import '../index.css';
import { userInfoStatus, updateErr, userInfoError, resetPasswordState } from '../features/userSlice';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/Header/logotest.svg';
import { verifyResetPasswordLink } from "../features/userSlice";
import TNC from "../assets/docs/Terms_and_Conditions.pdf";
import PP from "../assets/docs/Privacy_Policy.pdf";
import MessageNotification from '../components/reuseablecomponent/MessageNotification';
import resetPasswordLocals from "../localization/localization_en_reset_password.json";
import footerLocals from "../localization/localization_en_footer.json"
import { passwordValidator, passwordValidatorForEnableBtn } from '../utils/passwordValidator';
import PasswordPolicy from '../components/reuseablecomponent/PasswordPolicy';
import brand from "../assets/common/brand.svg";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStatus = useSelector(userInfoStatus);
  const userErr = useSelector(userInfoError);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isEmailValid, setEmailValid] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const status = useSelector((state) => state.user.resetPasswordStatus);
  const [passwordValidationErrMsg, setPasswordValidationErrMsg] = useState(null);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  useEffect(() => {
    if (status === "succeeded") {
      message.open({
        content:"Password has been reset successfully",
        type:"success"
      })
      const timer = setTimeout(() => {
        dispatch(resetPasswordState());
        navigate('/product',{replace:true});
      },2000);
      return () => clearTimeout(timer);
    }
    if (status === "failed") {
      message.open({
        content:"Something went wrong, Please try again later",
        type:'error'
      })
      dispatch(resetPasswordState());
    }
  }, [status])

  useEffect(() => {
    if(confirmPassword.length){
      setIsValidConfirmPassword(confirmPassword === password);
    }
  }, [confirmPassword, password])

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailValid(validateEmail(emailValue));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    passwordValidator(undefined, e.target.value, (msg) => {
      setPasswordValidationErrMsg(msg);
    });
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handlePasswordVisibilityChange = () => {
    setPasswordVisible((prev) => !prev);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isLoginDisabled = !isEmailValid || password.length === 0 || password !== confirmPassword || status === "loading";

  const formFields = [
    {
      name: 'email',
      className: `resp_login_width ${email && !isEmailValid ? 'border-red-500' : ''}`,
      rules: [
        {
          required: true,
          message: '',
        },
      ],
      inputProps: {
        className: `h-[50px] rounded-xl ${email && !isEmailValid ? 'border-red-500' : ''}`,
        prefix: <img className='w-[18px]' src={Message} alt="" />,
        suffix: email && isEmailValid && <img className='w-[18px]' src={checkcircle} alt='' />,
        onChange: handleEmailChange,
      },
    },
      {
        name: 'password',
        className: 'resp_login_width',
        rules: [
          {
            required: true,
            message: '',//passwordValidationErrMsg
            validator: passwordValidator
          },
        ],
        inputProps: {
          className: 'h-[50px] rounded-xl',
          prefix: <img width={18} src={Lock} alt="" />,
          iconRender: (visible) =>
            visible ? (
              <EyeOutlined onClick={handlePasswordVisibilityChange} />
            ) : (
              <EyeInvisibleOutlined onClick={handlePasswordVisibilityChange} />
            ),
          onChange: handlePasswordChange,
          visibilityToggle: true,
          visible: passwordVisible.toString(), // Change this line
        },
      },
      {
        name: 'confirmpassword',
        className: 'resp_login_width',
        rules: [
          {
            required: true,
            message: '',
            validator: passwordValidator,
            dependencies: ['password']
          },
        ],
        inputProps: {
          placeholder: 'Confirm New Password'
        },
      },
  ];

  const onFinish = (values) => {
    console.log(values);
    // dispatch(resetpassword(values));
  };

  useEffect(() => {
    if (userStatus === 'failed') {
      messageApi.open({
        type: 'error',
        content: userErr,
      });
      setTimeout(() => {
        dispatch(updateErr());
      }, 1000);
    } else {
      if (userStatus === 'succeeded') {
        form.resetFields();
        navigate('/product/verifyemail');
      }
    }
  }, [userStatus, dispatch]);

  return (
    <div className='md:min-h-[100vh] min-h-[90vh] flex flex-col justify-between'>
      {contextHolder}
      <div className='absolute hidden top-0 left-0 right-0 md:flex -z-[10000] min-h-full'>
        <div className="left_shadow bg-white w-[60%]"></div>
        <div className="left_shadow bg-[#FAFAFA] w-[45%] 2xl:w-[55%]"></div>
      </div>
      <div onClick={() => navigate('/')} className="header sticky top-0 lg:pt-[48px] lg:pl-[64px] pl-[24px]">
        <img src={logo} className='w-[138px] h-[21px] md:block hidden' alt="" />
        <img src={brand} className="w-[64px] h-[64px] block md:hidden" alt="" />
      </div>
      <div className="flex flex-auto justify-between items-center">
        <div className='lg:w-[60%] w-[100%]'>
          <Form
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            className="lg:w-[474px] lg:ml-[64px] mx-[24px]"
          >
            <p style={{ marginTop: '0px', marginBottom: '0px' }} className="text-[24px] text-[#111827] font-DMSans">Reset Your Password</p>
            {formFields.map((field,index) => (
              <Form.Item
                key={field.name}
                name={field.name}
                className={field.className}
                rules={field.rules}
                style={{ marginTop: '15px', marginBottom: '0px' }}
              >
                {field.name === 'password' && (
                  <Input.Password
                    {...field.inputProps}
                    placeholder={resetPasswordLocals.reset_password.form[index].placeholder}
                  />
                )
                }
                {field.name === 'confirmpassword' && (
                  <div className='relative'>
                    <img className='absolute top-[0.8rem] left-[0.8rem]' width={18} src={Lock} alt="" />
                    <input type={!isConfirmPasswordVisible ? "password" : "text"} onChange={handleConfirmPasswordChange} disabled={passwordValidatorForEnableBtn(password)} style={{ borderColor: isValidConfirmPassword ? '#dadada' : 'red' }} className='custom_input' placeholder={resetPasswordLocals.reset_password.form[index].placeholder} />
                    <div onClick={() => setIsConfirmPasswordVisible((prev) => !prev)} className='cursor-pointer'>
                      {isConfirmPasswordVisible ? <EyeOutlined className='absolute top-[0.9rem] right-[0.8rem]' /> : <EyeInvisibleOutlined className='absolute top-[0.9rem] right-[0.8rem]' />}
                    </div>
                  </div>
                )
                }
                {
                  field.name === "email" && (
                    <Input {...field.inputProps} placeholder={resetPasswordLocals.reset_password.form[index].placeholder} />
                  )
                }
              </Form.Item>
            ))}
            <PasswordPolicy/>
            <Form.Item style={{ marginTop: "40px", marginBottom: '12px' }}>
              <Button
                loading={status==="loading"}
                aria-disabled={isLoginDisabled}
                className={`${!isLoginDisabled && 'btn-onboarding-grad'
                  } text-[16px] rounded-xl font-semibold resp_login_width flex justify-center tracking-wide font-InterRegular w-full h-[50px]`}
                htmlType="submit"
                disabled={isLoginDisabled}
                style={{
                  margin: '0rem 0rem',
                  backgroundColor: true ? '#f5f8ff' : '',
                  color: isLoginDisabled ? '#9693f2' : 'white'
                }}
                onClick={() => {
                  dispatch(verifyResetPasswordLink({ emailToken: window.location.href.split('/')[5], email, password }));
                }}
              >
                {resetPasswordLocals.reset_password.reset_password_btn}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="w-[40%] bg-[#FAFAFA] md:flex justify-center hidden">
          <img src={test3} className="my-auto w-[60%]" alt="" />
        </div>
      </div>
      <div className="footer sticky top-[100%]">
        <div className='flex justify-between items-center text-[#9E9E9E] text-[13px] pb-[32px] px-[64px]'>
          <div className='font-InterRegular tracking-wide'>{footerLocals.footer.copyright_text}</div>
          <div className=''>
            <button onClick={() => navigate('/terms-and-conditions')} className='mr-[24px]'>{footerLocals.footer.terms_and_condition_text}</button>
            <button onClick={() => navigate('/privacy-policy')}>{footerLocals.footer.privacy_policy_text}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;