import React from 'react'
import editDark from "../../../assets/common/editDark.svg"
import closeDark from "../../../assets/common/closeDark.svg"
import IconButton from "../../../DesignSystem/Buttons/IconButton";
import slideshowNotFilled from "../../../assets/common/slideshowNotFilled.svg"

const NavigationTool = () => {
    return (
        <React.Fragment>
            <div className='flex flex-col gap-y-[18px]'>
                <IconButton icon={closeDark} btnBgColor='white' />
                <IconButton icon={slideshowNotFilled} btnBgColor='white' />
                <IconButton icon={editDark} btnBgColor='white' />
            </div>
        </React.Fragment>
    )
}

export default NavigationTool