import React, { useState } from 'react'
import locals from '../../localization/localization_en_website.json'
import PricingCardSection from '../components/PricingCardSection';
import PricingToolkit from '../components/PricingToolkit';
import AntDCarousal from '../../DesignSystem/Carousal/AntDCarousal';
import AntDSoloAccordin from '../../DesignSystem/Accordion/AntDSoloAccordin';
import { FreeTrialFAQ, AccountDeactivation, PricingWorkFAQ, SubscriptionLimitFAQ } from '../componentUtils/FrequentlyAskedQuestionTextPreparation';
import HeadingThree from '../../DesignSystem/TypographyHeadings/HeadingThree';
import MainContentSection from '../components/MainContentSection';
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour';
import ResponsiveHeadingThreeSix from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingThreeSix';
import useScrollToTopPage from '../../hooks/useScrollToTopPage';
import TogglePricingComponent from '../subcomponents/TogglePricingComponent';

const PRICING_LOCALS = locals.website.pricing;
const FAQ_LOCALS = locals.website.pricing.faq;

const Pricing = () => {

    useScrollToTopPage();

    const [pricingSpanType, setPricingSpanType] = useState(PRICING_LOCALS.unit.monthly || "monthly");

    const FAQ = [
        {
            "label": FAQ_LOCALS.free_trial.label,
            "children": <FreeTrialFAQ content={FAQ_LOCALS.free_trial.content} />
        },
        {
            "label": FAQ_LOCALS.pricing_work.label,
            "children": <PricingWorkFAQ content={FAQ_LOCALS.pricing_work.content} />
        },
        {
            "label": FAQ_LOCALS.subscription_limit.label,
            "children": <SubscriptionLimitFAQ content={FAQ_LOCALS.subscription_limit.content} />
        },
        {
            "label": FAQ_LOCALS.account_deactivation.label,
            "children": <AccountDeactivation content={FAQ_LOCALS.account_deactivation.content} />
        }
    ]
    return (
        <div className='flex flex-col sm:gap-y-[40px] gap-y-[24px] xl:mx-[48px] mx-[18px]'>
            <MainContentSection locals={PRICING_LOCALS.main_content} className={'pb-0'} />
            <TogglePricingComponent
                isChecked={pricingSpanType === PRICING_LOCALS.unit.yearly}
                locals={PRICING_LOCALS.pricing_span_currency_detail}
                handleOnChange={(value) => setPricingSpanType(value ? PRICING_LOCALS.unit.yearly : PRICING_LOCALS.unit.monthly)}
            />
            <PricingCardSection
                unit={PRICING_LOCALS.unit}
                pricingCards={PRICING_LOCALS.pricing_cards}
                pricing_span_unit={pricingSpanType===PRICING_LOCALS.unit.monthly ? PRICING_LOCALS.unit.month : PRICING_LOCALS.unit.year}
            />
            <PricingToolkit pricingToolkit={PRICING_LOCALS.toolkit} />
            <div>
                <div className='flex justify-center mb-[1rem] text-center'>
                    <ResponsiveHeadingThreeSix text={FAQ_LOCALS.title} />
                </div>
                <AntDSoloAccordin accordinData={FAQ} />
            </div>
        </div>
    )
}

export default Pricing