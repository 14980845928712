import { createSlice } from "@reduxjs/toolkit";

const PaintSlice = createSlice({
    name: "paint",
    initialState: {
        selectedPalette: null,
        isPaintPaletteOpen: false
    },
    reducers: {
        togglePaintPalette(state) {
            state.isPaintPaletteOpen = !state.isPaintPaletteOpen;
        },
        updateActivePalette(state, action) {
            state.selectedPalette = action.payload;
        }
    }
});

export const {
    togglePaintPalette,
    updateActivePalette
} = PaintSlice.actions;
export default PaintSlice.reducer;
