export const generatePadding = (direction, value) => {
    const paddingStyles = {};

    if (direction === 'left') {
        paddingStyles.paddingLeft = `${value}px`;
    } else if (direction === 'right') {
        paddingStyles.paddingRight = `${value}px`;
    } else if (direction === 'top') {
        paddingStyles.paddingTop = `${value}px`;
    } else if (direction === 'bottom') {
        paddingStyles.paddingBottom = `${value}px`;
    } else if (direction === 'horizontal') {
        paddingStyles.paddingLeft = `${value}px`;
        paddingStyles.paddingRight = `${value}px`;
    } else if (direction === 'vertical') {
        paddingStyles.paddingTop = `${value}px`;
        paddingStyles.paddingBottom = `${value}px`;
    } else {
        paddingStyles.padding = `${value}px`;
    }

    return paddingStyles;
};
