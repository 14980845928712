import api, { setBearerToken } from "../../services/api/api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateAccessToken } from "../../features/userSlice";
import { promptQuestions, samplepdfs } from "./prompt";
import { fetchPromptAnswerEndpoint, fetchPromptKeysEndpoint } from "../../services/endPoints/aiAPI";
import aiLocals from "../../localization/localization_en_ai.json"
import routesConfig from "../../services/config/routesConfig.json"
import {generateUrlEndPoint} from "../../utils/endPointsGeneration";

const locals = aiLocals.ai;

export const fetchPromptAnswer = createAsyncThunk(
    "prompt/fetchPromptAnswer",
    async (data, { getState, dispatch }) => {
        const state = getState();
        try {
            let payload = { 
                type: data.type, 
                promptIds: data.promptIds, 
                profileDetails: { ...state.prompt.promptInputs, 
                    "CompanyName": state?.onboarding?.value?.Company, 
                    "gtmChannel": state.prompt.gtm.recursive_data[state.prompt.gtm.recursive_data.length-1].selectedtag.toString(),
                    "tmaSegment": state.prompt.tma.recursive_data[state.prompt.tma.recursive_data.length-1].selectedtag.toString(),
                    "marketSegment": state.prompt.tma.selectedSegmentTag.toString()
                } 
            };
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'ai', 'fetchPromptAnswer');
            if (!!urlGenerator) {
                const urlConfig = { data: payload, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const fetchPromptKeys = createAsyncThunk(
    "prompt/fetchPromptKeys",
    async (data, { getState, dispatch }) => {
        const state = getState();
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'ai', 'fetchallkeys');
            const urlWithParams = urlGenerator.url.replace(':productname',state.prompt.productName)
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, url: urlWithParams }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

const psmLoadingState = {problemLoading: false, solutionLoading: false, fesabilityLoading: false};

const initialStateObject = {
    isShowFloatingIconCount:0,
    loading: 'idle',
    productName: "",
    promptQuestions: [],
    index: null,
    prompts: [],
    competitorList: null,
    showWelcome: false,
    promptInputs: {
      "Product": "",
      "Industry": "",
      "Geography": [],
      "ProblemSelected": null,
      "Solutions": null
    },
    showEdit: {
      "Product": false,
      "Industry": false,
      "Geography": false
    },
    modelOpen: false,
    bardResponses: [],
    isConditionalRendering: false,
    isProblemSolutionMatrixForDisabled: false,
    problemSolutionMatrix: { problem: null, solution: null, evaluateFesability: null, selectproblemCTA: false, selectedProblem: null, ...psmLoadingState},
    gtm: { list: null, list_of_tags: null, isShowRecursiveParentComponent: false, recursive_data: [{ selectedtag: [], output: null }], isFinalProcessLoading: false},
    marketingFunnel: { isShowRecursiveParentComponent: false,recursive_data: [{ selectedtag: null, output: null }], isFinalProcessLoading: false },
    tma: { list: null, list_of_tags: null, selectedSegmentTag: [], isShowRecursiveParentComponent: false, recursive_data: [{ selectedtag: [], output: null }], isFinalProcessLoading: false },
    introDescribeFeature:"",
    pdfRefmodules:null,
    isInputDisabled: false
  };
  

const promptSlice = createSlice({
    name: "prompt",
    initialState: initialStateObject,
    reducers: {
        resetFloatingIconCount(state){
            state.isShowFloatingIconCount = 0;
        },
        handleSelectTag(state,action){
            state.promptInputs = {...state.promptInputs,[action.payload.key]:action.payload.value}
        },
        handleExplorePSMTags(state,action){
            console.log(action.payload);
            state.problemSolutionMatrix = {...state.problemSolutionMatrix,[action.payload.key]:action.payload.value}
        },
        handleGtmTags(state, action){
            state.gtm = {...state.gtm, [action.payload.key]: action.payload.value}
        },
        handleTMATags(state, action){
            state.tma = {...state.tma, [action.payload.key]: action.payload.value}
        },
        handleIsConditionalRendering(state,action){
            state.isConditionalRendering = action.payload;
        },
        handleDisableInput(state, action){
            state.isInputDisabled = !state.isInputDisabled;
        },
        handleInputChange(state, action) {
            state.promptInputs = { ...state.promptInputs, [action.payload.fieldName]: action.payload.data }
        },
        handleEditShow(state, action) {
            state.showEdit = { ...state.showEdit, [action.payload.fieldName]: action.payload.isShow }
        },
        toogleModalOpen(state) {
            state.modelOpen = !state.modelOpen;
            if (state.promptInputs.Geography.length > 0) {
                state.showEdit = { ...state.showEdit, ["Geography"]: true }
            } else {
                state.showEdit = { ...state.showEdit, ["Geography"]: false }
            }
        },
        handleAddFilterGeography(state, action) {
            const copyOfData = { ...state.promptInputs };
            const geography = copyOfData.Geography;
            if (action.payload.type === "add") {
                state.promptInputs = { ...state.promptInputs, ["Geography"]: [...geography, action.payload.data] }
            } else {
                const filteredLocation = geography.filter((geo, index) => {
                    if (index !== action.payload.data) {
                        return geo;
                    }
                })
                state.promptInputs = { ...state.promptInputs, ["Geography"]: filteredLocation }
            }
            if (state.promptInputs.Geography.length > 0) {
                state.showEdit = { ...state.showEdit, ["Geography"]: true }
            } else {
                state.showEdit = { ...state.showEdit, ["Geography"]: false }
            }
        },
        handlePushAndRemoveTags(state, action) {
            if (action.payload.type === "ADD") {
                const copyResponses = [...state.bardResponses];
                copyResponses[copyResponses.length - 1].tags = [action.payload.tag]
                state.bardResponses = copyResponses;
            } else {
                const copyResponses = [...state.bardResponses];
                const filteredTags = copyResponses[copyResponses.length - 1].tags.filter((tag) => {
                    if (tag !== action.payload.tag) {
                        return tag;
                    }
                })
                copyResponses[copyResponses.length - 1].tags = filteredTags;
                state.tags = copyResponses;
            }
        },
        toggleWelcomeAi(state) {
            state.showWelcome = !state.showWelcome;
        },
        updateProductFeature(state, action) {
            state.productName = action.payload;
            if(state.productName==="problemSolutionMatrix"){
                state.introDescribeFeature = locals.captions.module_greet_description.problem_solution_matrix;
            }else if(state.productName === "competitorAnalysis"){
                state.introDescribeFeature = locals.captions.module_greet_description.competitor_analysis;
            }else if(state.productName === "idealCustomerProfile"){
                state.introDescribeFeature = locals.captions.module_greet_description.ideal_customer_profile;
            }else if(state.productName === "buildBusinessCanvas"){
                state.introDescribeFeature = locals.captions.module_greet_description.build_business_canvas;
            }else if(state.productName === "leanStartupCanvas"){
                state.introDescribeFeature = locals.captions.module_greet_description.lean_startup_canvas;
            }else if(state.productName === "gtm"){
                state.introDescribeFeature = locals.captions.module_greet_description.gtm;
            }else if(state.productName === "marketingFunnel"){
                state.introDescribeFeature = locals.captions.module_greet_description.marketingFunnel;
            }else if(state.productName === "tma"){
                state.introDescribeFeature = locals.captions.module_greet_description.tma;
            }
            state.pdfRefmodules = samplepdfs?.[state?.productName]
            // state.index = action.payload === "idealCustomerProfile" ? 1 : 0;
            state.promptQuestions = promptQuestions[action.payload]
        },
        updateLoadingState(state) {
            state.loading = 'idle';
        },
        resetAllValues(state) {
            Object.assign(state,initialStateObject);
        },
        resetLoadingState(state){
            state.loading = 'idle';
            state.problemSolutionMatrix = {...state.problemSolutionMatrix,...psmLoadingState}
        },
        appendTag(state,action){
            state.bardResponses = [...state.bardResponses,action.payload];
        },
        dynamicHandleAddRemoveTags(state, action) {
            const { type, module, tag } = action.payload || {};
            if (action.payload.type === "ADD") {
                state[module].recursive_data = state[module].recursive_data.map((res, index) => {
                    if(index===state[module].recursive_data.length-1){
                        return {...res, "selectedtag": [...res.selectedtag, tag]}
                    }
                    return res;
                })
            } else {
                state[module].recursive_data = state[module].recursive_data.map((res, index) => {
                    if(index===state[module].recursive_data.length-1){
                        return {...res, "selectedtag": res.selectedtag.filter((t) => t!==tag)}
                    }
                    return res;
                })
            }
        },
        updateMarketingFunnelTagSelection(state, action) {
            state.marketingFunnel.recursive_data[state.marketingFunnel.recursive_data.length-1].selectedtag = action.payload;
        },
        showMarketingFunnelParentComponent(state, action) {
            state.marketingFunnel.isShowRecursiveParentComponent = true;
            // state.isInputDisabled = true;
        },
        updateLoadingStatus(state, action){
            const { module, key, value } = action.payload;
            state[module][key] = value;
        },
        updateTagTMA(state, action){
            const { key, value, tagType, propType } = action.payload;
            if(propType === "other" || tagType!=="single"){
                state.tma[key] = value
            }else{
                state.tma.recursive_data[state.tma.recursive_data.length-1].selectedtag = value;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPromptAnswer.pending, (state, action) => {
                state.loading = 'loading';
                state.problemSolutionMatrix = {...state.problemSolutionMatrix};
            })
            .addCase(fetchPromptAnswer.fulfilled, (state, action) => {
                state.loading = 'success';
                state.problemSolutionMatrix = {...state.problemSolutionMatrix, problemLoading:false, solutionLoading:false, fesabilityLoading:false};
                state.gtm = {...state.gtm, isFinalProcessLoading: false }
                state.marketingFunnel = { ...state.marketingFunnel, isFinalProcessLoading: false }
                state.tma = { ...state.tma, isFinalProcessLoading: false }
                if(action.payload.type === "evaluateFeasability"){
                    state.problemSolutionMatrix = { ...state.problemSolutionMatrix, evaluateFesability: action.payload.data };
                }else if(action.payload.type === "problem"){
                    state.isProblemSolutionMatrixForDisabled = true;
                    state.problemSolutionMatrix = {...state.problemSolutionMatrix, problem:action.payload.data};
                }else if(action.payload.type === "solution"){
                    state.problemSolutionMatrix = {...state.problemSolutionMatrix, solution:action.payload.data};
                }else if (action.payload.type === "fetchAllCompetitor") {
                    const copystateBardResponse = [...state.bardResponses];
                    state.bardResponses = [...copystateBardResponse, { type: "fetchAllCompetitor", tags: [], responseData: action.payload.data },{ type: "fetchBasedOnTags",tags: [], responseData: [] }]
                    state.isInputDisabled = true;
                }else if(action.payload.type === "gtmChannels") {
                    state.gtm.list = action.payload.data
                    state.isInputDisabled = true;
                }else if(action.payload.type === "gtmOverview") {
                    state.gtm.recursive_data[state.gtm.recursive_data.length-1].output = action.payload.data;
                    state.gtm.recursive_data = [...state.gtm.recursive_data, { selectedtag: [], output: null }]
                }else if(action.payload.type === "marketingFunnelOverview"){
                    state.marketingFunnel.recursive_data[state.marketingFunnel.recursive_data.length-1].output = action.payload.data;
                    state.marketingFunnel.recursive_data = [...state.marketingFunnel.recursive_data, { selectedtag: null, output: null }]
                }else if(action.payload.type === "tmaSegmentList"){
                    state.tma.list = action.payload.data;
                    state.isInputDisabled = true;
                }else if(action.payload.type === "tmaAnalysis"){
                    state.tma.recursive_data[state.tma.recursive_data.length-1].output = action.payload.data;
                    state.tma.recursive_data = [...state.tma.recursive_data, { selectedtag: null, output: null }]
                }
                else {
                    const copystateBardResponse = [...state.bardResponses];
                    copystateBardResponse[copystateBardResponse.length - 1].responseData = action.payload.data;
                    state.bardResponses = [...copystateBardResponse, { type: action.payload.type,tags: [], responseData: [] }]
                }
                state.isShowFloatingIconCount = state.isShowFloatingIconCount + 1;
                state.problemSolutionMatrix = {...state.problemSolutionMatrix, problemLoading: false, solutionLoading: false, fesabilityLoading: false}
            })
            .addCase(fetchPromptAnswer.rejected, (state, action) => {
                state.loading = 'failed'
                state.problemSolutionMatrix = {...state.problemSolutionMatrix,}
                state.problemSolutionMatrix = {...state.problemSolutionMatrix, problemLoading: false, solutionLoading: false, fesabilityLoading: false};
                state.gtm = {...state.gtm, isFinalProcessLoading: false }
                state.marketingFunnel = { ...state.marketingFunnel, isFinalProcessLoading: false }
            })
            .addCase(fetchPromptKeys.fulfilled, (state, action) => {
                state.prompts = action.payload.data;
            })
    },
});

export const { handleDisableInput, handleTMATags, updateTagTMA, updateLoadingStatus, showMarketingFunnelParentComponent, updateMarketingFunnelTagSelection, dynamicHandleAddRemoveTags, handleGtmTags, handleSelectTag, handleExplorePSMTags, appendTag ,handleIsConditionalRendering, resetLoadingState, resetAllValues, updateLoadingState, handlePushAndRemoveTags, handleInputChange, handleEditShow, toogleModalOpen, handleAddFilterGeography, toggleWelcomeAi, updateProductFeature, resetFloatingIconCount } = promptSlice.actions;
export default promptSlice.reducer;
