import React, { useState } from 'react'
import locals from '../../../localization/localization_en_ideal_customer_profile.json'
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton';
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';
import BaseModal from '../../../DesignSystem/Modals/BaseModal';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import SouthEastOutlinedIcon from '@mui/icons-material/SouthEastOutlined';
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';
import HeadingFive from '../../../DesignSystem/TypographyHeadings/HeadingFive';
import IconTitleCard from '../../../DesignSystem/Cards/IconTitleCard';
import CloseIcon from '@mui/icons-material/Close';

const popup_content = locals.ideal_customer_profile.pick_custom_template_popup_locals;

const ChooseTemplatePopup = ({ isOpen, handleCloseModal, handleConfirmButton, selectedTemplate, handleSelectTemplate }) => {

    const iconRef = {
        BusinessOutlinedIcon,
        SouthEastOutlinedIcon,
        LocationCityIcon,
        PeopleOutlinedIcon
    }

    return (
        <div>
            <BaseModal
                isOpen={isOpen}
                handleCloseModal={handleCloseModal}
            >
                <div className='flex flex-col gap-y-[0.5rem]'>
                    <HeadingFive headingCSSStyles={{ margin: 0 }} text={popup_content.title} />
                    <TextLarge text={popup_content.description} fontWeight={500} color='#9E9E9E' />
                    <div className='flex gap-x-[40px] pt-[0.5rem] pb-[1.5rem]'>
                        {
                            popup_content.list_of_template.map((template, index) => {
                                return (
                                    <IconTitleCard
                                        key={index}
                                        text={template.label}
                                        description={template.description}
                                        isSelected={template.value === selectedTemplate}
                                        icon1={iconRef[template.icon1]}
                                        icon2={iconRef[template.icon2]}
                                        icon3={iconRef[template.icon3]}
                                        handleOnClick={() => handleSelectTemplate(template.value)}
                                    />
                                )
                            })
                        }
                    </div>
                    <div className='flex justify-between'>
                        <SecondaryButton onClick={handleCloseModal} text={popup_content.prev_button} />
                        <PrimaryButton isDisabled={!selectedTemplate} onClick={() => handleConfirmButton(selectedTemplate)} text={popup_content.next_button} />
                    </div>
                </div>
                <button className='absolute top-[1rem] right-[1rem]' onClick={handleCloseModal}><CloseIcon/></button>
            </BaseModal>
        </div>
    )
}

export default ChooseTemplatePopup