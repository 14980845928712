import React from 'react'
import { Table } from "antd";
import { useSelector } from "react-redux";
import TableColumnHeading from '../../subcomponents/ROFormComponents/components/TableColumnHeading';
import TextExtraLarge from '../../../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import SkeletonLoader from '../../../../DesignSystem/Loader/SkeletonLoader';
import { generateMonth } from '../../functionality/RevenueOnboarding/revenueOnboardingUtiliy';

const ROScreen6 = () => {
  try {
    const { marketing_spend_per_channel_fiscal_year, api_status } = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
    const IS_LOADING = api_status?.status?.[5]?.post?.status === 'loading';
    const SKELETON_ROWS = 10;
    const MONTHS = Array.from({ length: 10 }).map((_, index) => {
      return {
        title: generateMonth({ "startMonth": Number(marketing_spend_per_channel_fiscal_year?.split('-')?.[1]), "seriesMonth": index >= 12 ? index - 12 : index, outputType: "label" })?.slice(0, 3) + '-' + marketing_spend_per_channel_fiscal_year?.split('-')?.[0]?.slice(2),
        dataIndex: 'month',
        width: 150
      }
    })
    const DEFAULT_COLUMNS = [
      {
        title: 'Summary',
        dataIndex: 'name',
        width: 250
      },
      ...MONTHS
    ];
    const columns = DEFAULT_COLUMNS.map((column, index) => {
      return {
        "key": index,
        "fixed": index == 0 ? "left" : '',
        "title": <TableColumnHeading title={column?.title} />,
        "dataIndex": column.dataIndex,
        "width": column.width,
        "render": () => {
          return (
            <div className='py-[0.5rem]'>
              <SkeletonLoader width={index === 0 ? 200 : 120} />
            </div>
          )
        }
      }
    })
    const totalColumnWidth = columns?.reduce((total, column) => total + (column.width || 0), 0);
    if (IS_LOADING) {
      return (
        <div>
          <div className='border border-p7 rounded-xl bg-[#f2f5ff] p-[16px] flex flex-col gap-y-[16px] w-full min-h-fit overflow-hidden relative'>
            <TextExtraLarge text={"Outputs"} fontWeight={500} />
            <Table
              columns={columns}
              dataSource={Array.from({ length: SKELETON_ROWS })}
              scroll={{
                x: totalColumnWidth,
              }}
              pagination={{
                pageSize: SKELETON_ROWS,
                position: ['none', 'none'],
              }} />
          </div>
        </div>
      )
    }
  } catch (error) {
    throw new Error(error)
  }
}

export default ROScreen6