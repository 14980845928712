import Marquee from "react-fast-marquee";
import { cn } from "../../utils/cn";

const ReactLibraryMarquee = ({
    children,
    className,
    play = true,
    pauseOnHover = true,
    pauseOnClick = false,
    direction = "left",
    speed = 50,
    delay = 0,
    loop = 0,
}) => {

    const config = {
        children,
        play,
        pauseOnHover,
        pauseOnClick,
        direction,
        speed,
        delay,
        loop,
    }

    return (
        <Marquee {...config}>
            {children}
        </Marquee>
    )
}

export default ReactLibraryMarquee