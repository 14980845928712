import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Tag,
  Table,
  ConfigProvider,
  Popover,
  Input,
  Empty,
} from "antd";
import "./index.css";
import DataRoom from "../localization/data_room/localization_en_data_room.json";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import FileIC from "../assets/TableIcon/fileIc.png";
import OwnerIC from "../assets/TableIcon/owner.png";
import ReviewTick from "../assets/TableIcon/reviewTick.png";
import CommentIc from "../assets/TableIcon/commentIc.png";
import TagIc from "../assets/TableIcon/tags.png";
import LinkIc from "../assets/TableIcon/LinkIc.png";
import CirclePlus from "../assets/TableIcon/circlePlusIc.png";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ActionMenuCard from "../DesignSystem/Cards/ActionMenuCard";
import {
  generateSignedUrlForDownload,
  listDirectoryApi,
} from "../features/dataRoomSlice";
import {
  PlusOutlined,
  SearchOutlined,
  DownloadOutlined,
  DeleteOutlined,
  ReloadOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  D_ToggleCommentSection,
  D_UpdateChatRowOrCell,
} from "../components/Chat/chatSlice";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import LockIconSVG from "../DesignSystem/SVG/LockIconSVG";
import DownloadSVG from "../DesignSystem/SVG/DownloadSVG";
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import DeleteSVG from "../DesignSystem/SVG/DeleteSVG";

export default function AdminDataRoomFile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { directoryName } = useParams();
  const linkInfo = useSelector((state) => state.link);
  const activeItem = useSelector((state) => state.link.activeItem);
  const openKeys = useSelector((state) => state.link.openKeys);
  const username = useSelector((state) => state.user.value.username);
  const [featureData, setFeatureData] = useState({});
  const [flippedCards, setFlippedCards] = useState(
    new Array(featureData["cards"]?.length).fill(false)
  );
  const status = useSelector((state) => state.link.status);
  const progress = useSelector((state) => state.link.progress);
  const onboarding = useSelector((state) => state.onboarding.value.Company);
  const [animateOnMount, setAnimateOnMount] = useState(false);
  const [checkedCards, setCheckedCards] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectedCardDetails, setSelectedCardDetails] = useState(null);
  const [checkedCardDetails, setCheckedCardDetails] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dataRoomFile, setDataRoomFile] = useState([]);
  const [modalTitle, setModalTitle] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const user = useSelector((state) => state.user);

  const [deleteModalTitle, setDeleteModalTitle] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [documentList, setDocumentList] = useState("");
  const [reClick, setReClick] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(dataRoomFile);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    // Filter the data based on the search input
    setFilteredData(
      dataRoomFile.filter((item) =>
        item.name.toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [searchInput, dataRoomFile]);

  const handleSearchToggle = () => {
    console.log("Toogle");
    setSearchVisible(!searchVisible);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setButtonDisabled(!newSelectedRowKeys.length);
    setSelectedRowKeys(newSelectedRowKeys);
    console.log(newSelectedRowKeys);
  };

  const showModalTag = (tags, id) => {
    setModalTitle("Tags");
    setIsModalVisible(true);
    setDocumentList({ tags, id });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAddCommentSection = (id) => {
    dispatch(D_UpdateChatRowOrCell({ _id: id, moduleType: "data-room" }));
    dispatch(D_ToggleCommentSection(true));
  };

  const columns = [
    {
      title: (
        <div className="flex items-center py-3 ml-2 bg-white">
          <span className=" font-normal text-[#757575]">File ID</span>
        </div>
      ),
      dataIndex: "fileId",
      key: "fileId",
      render: (_, record) => {
        return (
          <div className="py-2">
            <h1 className="text-[#111827] font-medium text- pl-2">
              {record.fileId}
            </h1>
          </div>
        );
      },
      width: 70,
    },
    {
      title: (
        <div className="flex items-center justify-start py-3 bg-white">
          {/* <img src={FileIC} alt="file-ic" className=" w-3 h-3.5 border mr-2" /> */}
          <InsertDriveFileOutlinedIcon
            fontSize="small"
            className="text-gray-500 mr-2"
          />
          <h1 className="m-0  font-normal text-[#757575]">File Name</h1>
        </div>
      ),
      dataIndex: "fileName",
      key: "fileName",
      render: (_, record) => {
        return (
          <div className="flex">
            <h1 className=" text-[#111827] font-medium font-sans truncate-to-1-lines">
              {record.name}
            </h1>
          </div>
        );
      },
      width: 220,
    },
    {
      title: (
        <div className="flex items-center justify-start py-3 bg-white">
          {/* <img
                        src={OwnerIC}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
          <PersonOutlineOutlinedIcon
            fontSize="small"
            className="text-gray-500 mr-2"
          />

          <h1 className="m-0  font-normal text-[#757575]">Owner</h1>
        </div>
      ),
      dataIndex: "ownerName",
      key: "ownerName",
      render: (_, record) => {
        return (
          <div className="flex items-center py-1 relative">
            <div className="absolute left-[-50px] top-[2px]">
              {record.lockStatus ? (
                <div className="scale-90">
                  <LockIconSVG />
                </div>
              ) : null}
            </div>
            <div className=" flex items-center justify-center mr-2 w-5 h-5 rounded-full bg-orange-200">
              <h1 className=" text-orange-500 m-0 text-[10px]">
                {user.value.username[0]}
              </h1>
            </div>
            <div className=" flex  items-center">
              <h1 className=" text-[#111827] font-sans text-sm m-0 font-medium tracking-wide">
                {user.value.username}
              </h1>
            </div>
          </div>
        );
      },
      width: user.value.username.length * 15,
    },
    {
      title: (
        <div className="flex items-center justify-start py-3 bg-white">
          {/* <img
                        src={ReviewTick}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
          <CheckCircleOutlinedIcon
            fontSize="small"
            className="text-gray-500 mr-2"
          />
          <h1 className="m-0  font-normal text-[#757575]">Review Progress</h1>
        </div>
      ),
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const getColor = (status) => {
          let color;
          let text;
          switch (status) {
            case "In Progress":
              color = "orange";
              text = "In Progress";
              break;
            case "Completed":
              color = "green";
              text = "Completed";
              break;
            case "Not Assigned":
              color = "red";
              text = "Not Assigned";
              break;
            default:
              color = "blue";
              text = "Hover To View";
          }

          return { color: color, text: text };
        };

        const progressData = record.sharingDetails || [];
        const content = (
          <div className={`p-4 border rounded-lg`}>
            {progressData.length > 0 ? (
              progressData.map((value) => (
                <div
                  key={value._id}
                  className="mb-1 flex justify-center flex-col"
                >
                  <div className="w-full flex items-center justify-center py-2">
                    {value.sharedUserId.username}
                  </div>
                  <div
                    className={`flex items-center justify-center border border-[${
                      getColor(value.reviewStatus).color
                    }] bg-opacity-50 rounded-full py-1.5 bg-${
                      getColor(value.reviewStatus).color
                    }-200 w-full`}
                  >
                    <div
                      className={`bg-opacity-50 mr-2 w-1 h-1 rounded-full`}
                      style={{
                        backgroundColor: getColor(value.reviewStatus).color,
                      }}
                    />
                    <h1
                      className={`text-${
                        getColor(value.reviewStatus).color
                      }-500 m-0 text-[11px] font-bold`}
                    >
                      {value.reviewStatus}
                    </h1>
                  </div>
                </div>
              ))
            ) : (
              <p>No progress data available.</p>
            )}
          </div>
        );
        console.log("This is the record", record);
        return progressData.length > 0 ? (
          <Popover content={content} title="User Progress">
            <div className="flex items-center py-0.5">
              <div
                className={`flex items-center justify-center border ${
                  progressData.length > 0 ? "border-blue-300" : "border-red-500"
                } bg-opacity-50 rounded-full py-1.5 w-[90%] ${progressData.length > 0 ? "bg-blue-100" : "bg-[#f7e2ff]"}`}
              >
                <div
                  className={`${
                    progressData.length > 0 ? "bg-blue-500" : "bg-red-500"
                  } bg-opacity-50 mr-2 w-1 h-1 rounded-full`}
                />
                <h1
                  className={`${
                    progressData.length > 0 ? "text-blue-400" : "text-red-500"
                  } m-0 text-[11px] font-bold`}
                >
                  {progressData.length > 0 ? "Check Status" : "Not Assigned"}
                </h1>
              </div>
            </div>
          </Popover>
        ) : (
          <div className="flex items-center py-0.5">
            <div
              className={`flex items-center justify-center border ${
                progressData.length > 0 ? "border-blue-500" : "border-[#f8ccf2]"
              } bg-opacity-50 rounded-full py-1.5 w-[90%] ${progressData.length > 0 ? "bg-blue-100" : "bg-[#f7e2ff]"}`}
            >
              <div
                className={`${
                  progressData.length > 0 ? "bg-blue-500" : "bg-pink-300"
                } bg-opacity-50 mr-2 w-1 h-1 rounded-full`}
              />
              <h1
                className={`${
                  progressData.length > 0 ? "text-blue-500" : "text-pink-300"
                } m-0 text-[11px] font-bold`}
              >
                {progressData.length > 0 ? "Hover" : "Not Assigned"}
              </h1>
            </div>
          </div>
        );
      },
      width: 150,
    },
    {
      title: (
        <div className="flex items-center justify-start py-3 bg-white pl-4">
          {/* <img
                        src={CommentIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
          <ChatBubbleOutlineOutlinedIcon
            fontSize="small"
            className="text-gray-500 mr-2"
          />
          <h1 className="m-0  font-normal text-[#757575]">Comment</h1>
        </div>
      ),
      key: "comments",
      render: (_, record) => {
        return (
          <button
            className="flex pl-4 py-0.5"
            onClick={() => {
              handleAddCommentSection(record._id);
            }}
          >
            <div className=" text-[#372EE5] font-semibold cursor-pointer ">
              Add Comment
            </div>
          </button>
        );
      },
      width: 150,
    },
    {
      title: (
        <div className="flex items-center justify-start py-3 bg-white">
          {/* <img
                        src={TagIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-4 border mr-2"
                    /> */}
          <SellOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
          <h1 className="m-0  font-normal text-[#757575]">Tags</h1>
        </div>
      ),
      key: "tags",
      dataIndex: "tags",
      render: (_, records) => (
        <div className="py-0.5">
          {records.tags ? (
            <>
              {records.tags.split(",").map((tag) => (
                <Tag color="blue" className="rounded-full" key={tag.trim()}>
                  {tag.trim()}
                </Tag>
              ))}
              <Tag color="cyan" className="rounded-full font-bold">
                <div
                  onClick={() => {
                    showModalTag(records.tags, records._id);
                  }}
                >
                  +
                </div>
              </Tag>
            </>
          ) : (
            <Tag color="cyan" className="rounded-full font-bold">
              <div
                onClick={() => {
                  showModalTag(records.tags, records._id);
                }}
              >
                +
              </div>
            </Tag>
          )}
        </div>
      ),
      width: 250,
    },
    {
      title: (
        <div className="flex items-center justify-start py-3 bg-white">
          {/* <img
                        src={LinkIc}
                        alt="file-ic"
                        className=" w-4 object-contain h-3 border mr-2"
                    /> */}
          <AddLinkOutlinedIcon
            fontSize="small"
            className="text-gray-500 mr-2"
          />
          <h1 className="m-0  font-normal text-[#757575]">Linked Docs</h1>
        </div>
      ),
      dataIndex: "linkedDocs",
      key: "linkedDocs",
      render: (_, record) => (
        <div className="flex py-0.5">
          <span className=" text-[#424242] font-medium pl-12">
            {record.linkedData
              ? record.linkedData.length
              : "0"}
          </span>
        </div>
      ),
      width: 150,
    },
    {
      title: (
        <div className="flex items-center justify-center py-3 bg-white">
          {/* <img
            src={LinkIc}
            alt="file-ic"
            className=" w-3 object-contain h-3 border mr-2"
          />
          <h1 className="m-0  font-normal text-[#757575]">Respond</h1> */}
          {/* <img
                        src={CirclePlus}
                        alt="file-ic"
                        className="w-3 object-contain h-5 border ml-5"
                    /> */}
          <AddCircleOutlineOutlinedIcon
            fontSize="small"
            className="text-gray-500 mr-2"
          />
        </div>
      ),
      key: "actions",
      render: (_, record) => (
        <div className="py-1.5 w-full flex justify-center bg-white">
          <ActionMenuCard
            document={record}
            handleClose={() => {
              setReClick(!reClick);
            }}
          />
        </div>
      ),
      width: 40,
      fixed: "right",
    },
  ];
  const handleCardCheckboxChange = (isChecked, cardDetails) => {
    if (isChecked) {
      const updatedCheckedCards = [...checkedCardDetails, cardDetails];
      console.log("Updated Checked Cards:", updatedCheckedCards);
      setCheckedCardDetails(updatedCheckedCards);
    } else {
      const filteredCards = checkedCardDetails.filter(
        (card) => card.id !== cardDetails.id
      );
      console.log("Updated Checked Cards:", filteredCards);
      setCheckedCardDetails(filteredCards);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const body = {
          directory: directoryName,
        };
        const response = await dispatch(listDirectoryApi(body));
        const listOfSharing = response?.payload.listOfSharing || [];
        const listOfLinked = response?.payload.listOfLinked || [];
        const extractedData = response.payload?.listOfFiles.map(
          (obj, index) => {
            return {
              _id: obj._id,
              organisationId: obj.organisationId,
              userId: obj.userId,
              name: obj.name,
              path: obj.path,
              type: obj.file,
              description: obj.description,
              archiveStatus: obj.archiveStatus,
              tags: obj.tags,
              color: obj.color,
              size: obj.size,
              lockStatus: obj.lockStatus,
              createdAt: obj.createdAt,
              updatedAt: obj.updatedAt,
              sharingDetails: listOfSharing[index],
              linkedData: listOfLinked[index],
              fileId: index + 1,
            };
          }
        );
        setDataRoomFile(extractedData);
        console.log(extractedData);
      } catch (error) {
        console.error("Error : ", error);
      } finally {
        setIsLoading(false);
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
      }
    };

    getData();
  }, [dispatch, reClick]);

  useEffect(() => {
    const anyCardChecked = checkedCardDetails.length === 0;
    setButtonDisabled(anyCardChecked);

    setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
    console.log("checkedCardDetails", checkedCardDetails);
  }, [checkedCardDetails]);

  //   Add File Modal
  const handleButtonClick = () => {
    setModalTitle("Add File"); // Change the index as needed
    console.log(directoryName);
    setDocumentList(directoryName);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setModalTitle(null);
  };
  //   Delete Modal
  const handleDeleteButton = () => {
    setModalTitle("Delete File"); // Change the index as needed
    setIsModalVisible(true);
    setDocumentList(selectedRowKeys);
  };

  const closeDeleteModal = () => {
    setIsModalVisible(false);
    setIsDeleteModalVisible(null);
  };

  const onDownloadClick = async () => {
    setLoading(true);
    const filteredObjects = dataRoomFile.filter((obj) =>
      selectedRowKeys.includes(obj._id)
    );

    for (const documentMain of filteredObjects) {
      const body = {
        directory: directoryName,
        filename: documentMain.name,
      };

      const result = await dispatch(generateSignedUrlForDownload(body));
      if (result && result.payload && result.payload.url) {
        console.log("entered");
        const downloadUrl = result.payload.url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.target = "_blank";
        link.download = document.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    setLoading(false);
  };

  const noDataContent = (
    <div className="py-10">
      <Empty
        description={
          !searchVisible
            ? `To begin, please add a file to your data room to keep your information organized.`
            : `No results for ${searchInput}. Please try refining your search and go again.`
        }
      />
    </div>
  );

  const SkeletonLoader = ({
    avatar = false,
    active = true,
    loading = true,
  }) => {
    return (
      <div className="bg-neutral-200 animate-pulse w-full h-10 rounded-lg"></div>
    );
  };

  return (
    <div className="pt-[6rem] min-h-[100vh]">
      {isModalVisible && (
        <ModalSection
          title={modalTitle}
          isOpen={true}
          closeHandler={() => {
            setIsModalVisible(false);
            setModalTitle("");
            setDocumentList("");
            setReClick(!reClick);
          }}
          documentList={documentList}
          secondaryCloseHandler={() => {
            setIsModalVisible(true);
            setModalTitle("");
            setDocumentList("");
          }}
        />
      )}
      <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
        <div className="mx-auto">
          <Button
            type="text"
            icon={<LeftOutlined />}
            onClick={() => navigate(-1)}
            className="mb-4 flex items-center justify-center"
          >
            Back
          </Button>
          <DescribeFeature
            text={DataRoom.data_room.dasboard.title}
            description={DataRoom.data_room.dasboard.description}
          />
          <div className="flex justify-between items-center">
            <p className="font-DMSans text-[#111827] text-[18px] mt-6">
              {directoryName.charAt(0).toUpperCase() + directoryName.slice(1)} Files
            </p>
            <div className="flex gap-2 items-center">
              {searchVisible && (
                <Input
                  placeholder="Search..."
                  value={searchInput}
                  onChange={handleSearchChange}
                  style={{ width: 200 }}
                />
              )}
              <Button
                icon={<SearchOutlined />}
                className="flex items-center justify-center bg-[#EBF1FF] text-[#372EE5]"
                onClick={handleSearchToggle}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleButtonClick}
                className="bg-[#372EE5] font-semibold"
              >
                Add File
              </Button>
            </div>
          </div>
          <div className="w-full h-fit justify-between flex items-center">
            <div className="flex gap-5">
              <PrimaryButton
                type="primary"
                className={`font-bold ${
                  buttonDisabled ? "cursor-not-allowed text-blue-500" : ""
                }`}
                onClick={onDownloadClick}
                isLoading={loading}
                isDisabled={buttonDisabled}
                text="Download"
                isIcon={true}
                icon={<DownloadOutlinedIcon color={`${buttonDisabled ? "#b8b8b8" : "#ffffff"}`} />}
              />

              <PrimaryButton
                type="primary"
                danger
                className={`font-bold ${
                    buttonDisabled ? "cursor-not-allowed text-blue-500" : "bg-red-500"
                }`}
                icon={<DeleteSVG height="20" width="20" color={`${buttonDisabled ? "#b8b8b8" : "#ffffff"}`}/>}
                isDisabled={buttonDisabled}
                onClick={handleDeleteButton}
                text={"Delete"}
                isIcon={true}
              >
              </PrimaryButton>
            </div>
            <div>
              <Button
                onClick={() => {
                  setReClick(!reClick);
                }} // Call handleReloadClick on click
                className="bg-[#F5F5F5] text-[#372EE5] flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading && !isInitialLoad ? (
                  <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg lg:overflow-visible">
                    <svg
                      class="w-3.5 h-4 text-gray-300 animate-spin"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                        stroke="currentColor"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                        stroke="#372EE5"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="text-gray-900"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  <ReloadOutlined />
                )}
              </Button>
            </div>
            {isDeleteModalVisible && (
              <ModalSection
                title={deleteModalTitle}
                closeHandler={closeDeleteModal}
                documentList={rowSelection?.selectedRowKeys}
              />
            )}
          </div>
          <div className="mt-4 overflow-hidden">
            {isInitialLoad ? (
              <div className="w-full flex flex-col gap-3">
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
              </div>
            ) : (
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBg: "#FFFFFF",
                    },
                  },
                }}
              >
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  rowSelection={rowSelection}
                  scroll={{ x: 1500 }}
                  rowKey={(record) => record._id}
                  className="border border-[#9E9E9E] rounded-2xl overflow-hidden"
                  rowClassName={(record) =>
                    selectedRowKeys.includes(record._id)
                      ? "bg-blue-100 mb-2"
                      : "bg-white border border-[#9E9E9E] px-3 mb-2"
                  }
                  pagination={false}
                  rowHoverable={false}
                  locale={{
                    emptyText: (
                      <span>
                        <div className="flex flex-col items-center justify-center my-10">
                          <div className="font-medium tracking-wide text-md text-neutral-500">Uh-oh! It looks like there's no file added yet. To continue, please add a file first. You've got this!</div>
                        </div>
                      </span>
                    ),
                  }}
                />
              </ConfigProvider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
