import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Progress, Button, Skeleton, Input, Empty } from "antd";
import "../index.css";
import ArchiveOutline from "../DesignSystem/SVG/ArchiveOutlineSVG"
import ArchiveRoomDashboardCard from "../DesignSystem/Cards/ArchiveRoomDashboardCard";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import { documentuserShareList, listDirectoryApi, updateArchiveStatusApi } from "../features/dataRoomSlice";
import { ReloadOutlined, SecurityScanOutlined } from "@ant-design/icons";
import {
    SearchOutlined,
} from "@ant-design/icons";
import { fetchArchiveFileApi } from "../features/dataRoomSlice";
import SecondaryButton from "../DesignSystem/Buttons/SecondaryButton";
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import ArchiveFileDataRoom from "./ArchiveFileDataRoom";

export default function ArchiveDataRoom() {
    const dispatch = useDispatch();
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [checkedCards, setCheckedCards] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [keysData, setKeysData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [reClick, setReClick] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [searchVisible, setSearchVisible] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [reload, setReload] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        setFilteredData(keysData.filter((item) =>
            item.name.toLowerCase().includes(searchInput.toLowerCase())
        ));
    }, [searchInput, keysData]);

    const handleSearchToggle = () => {
        setSearchVisible(!searchVisible);
        if(searchVisible) {
            setSearchInput('')
        }
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
    
                const { payload } = await dispatch(fetchArchiveFileApi(""));
    
                // Use Promise.all to wait for all directory data to be processed
                const extractedData = await Promise.all(payload?.listOfDirectories.map(async (obj) => {
                    const fileCountResponse = await dispatch(listDirectoryApi({ directory: obj.name }));
                    const fileCount = fileCountResponse.payload.listOfFiles.length;
                    const data = {
                        addFolder: "",
                        documentId: obj._id
                    }
                    const filesShareResponse = await dispatch(documentuserShareList(data))
                    console.log(filesShareResponse)
    
                    return {
                        _id: obj._id,
                        organisationId: obj.organisationId,
                        userId: obj.userId,
                        name: obj.name,
                        path: obj.path,
                        type: obj.type,
                        description: obj.description,
                        archiveStatus: obj.archiveStatus,
                        tags: obj.tags,
                        color: obj.color,
                        fileCount: fileCount,
                    };
                }));
    
                setKeysData(extractedData);
                setFilteredData(extractedData);
            } catch (error) {
                console.error("Failed to list directory", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [dispatch, reClick]);
    

    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        console.log("Card Details : ", cardDetails)
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card.id !== cardDetails.id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);

    // Modal
    const [modalTitle, setModalTitle] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleButtonClick = () => {
        setModalTitle("Add Folder"); // Change the index as needed
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };

    console.log(keysData)

    const OnDeleteClick = async () => {
        const ids = [...new Set(checkedCardDetails.map((card) => card.id))];
        console.log("These are the id selected : ", ids)

        for (const id of ids) {
            const body = {
                documentId: id,
                archiveStatus: false
            };
            console.log(body)
            await dispatch(updateArchiveStatusApi(body));
        }

        setReClick(!reClick)
    };

    return (
        <div className="pt-[6rem] min-h-[100vh]">
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    text="Success! The folder has been unarchived and is now visible in my files."
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                        setReload(!reload)
                    }}
                />
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
                {
                    <div className="mx-auto">
                        <DescribeFeature
                            text={"Archive Folders"}
                            description={"Access and manage your archived files easily. Archived documents are stored separately for a clutter-free view, allowing for quick retrieval when needed."}
                        />
                        <div className="flex justify-between items-center mt-6">
                            <div className="flex flex-row gap-3">
                                <PrimaryButton
                                    type="primary"
                                    icon={<ArchiveOutline className="transition-all" color={buttonDisabled ? "#b8b8c3" : "#ffffff"}/>}
                                    onClick={OnDeleteClick}
                                    className="bg-[#372EE5] font-semibold text-[#ffffff]"
                                    isDisabled={buttonDisabled}
                                    isIcon={true}
                                    text={"Unarchive"}
                                >
                                    Unarchive
                                </PrimaryButton>
                            </div>
                            <div className="flex gap-2 items-center">
                                {searchVisible && (
                                    <Input
                                        placeholder="Search..."
                                        value={searchInput}
                                        onChange={handleSearchChange}
                                        style={{ width: 200 }}
                                    />
                                )}

                                <Button
                                    icon={<SearchOutlined />}
                                    className="flex items-center border-none justify-center bg-[#F5F5F5] text-[#372EE5]"
                                    onClick={handleSearchToggle}
                                />
                                <div>
                                    <Button
                                        onClick={() => { setReClick(!reClick);}} // Call handleReloadClick on click
                                        className="bg-[#F5F5F5] text-[#372EE5] flex items-center justify-center"
                                        disabled={loading}
                                    >
                                        {loading ? ( 
                                            <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg lg:overflow-visible">
                                                <svg class="w-3.5 h-4 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none"
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                <path
                                                    d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                                                    stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path
                                                    d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                                                    stroke="#372EE5" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-900">
                                                </path>
                                                </svg>
                                            </div>
                                        ) : (
                                            <ReloadOutlined />
                                        )
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-7 w-full my-5 gap-5">
                                {Array.from({ length: 8 }).map((_, index) => (
                                    <Skeleton.Input
                                        key={index}
                                        active
                                        style={{ width: 250, height: 110, borderRadius: 12 }}
                                    />
                                ))}
                            </div>
                        ) : filteredData.length === 0 ? (
                            <div className="flex flex-col items-center justify-center my-20">
                                <Empty description="Uh-oh! There are no folders available to unarchive. Please check back later!" />
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-7 w-full my-5 gap-5">
                                {filteredData?.map((card, index) => (
                                    <ArchiveRoomDashboardCard
                                        key={index}
                                        cardTitle={card.name}
                                        color={card.color}
                                        onCheckboxChange={handleCardCheckboxChange}
                                        id={card._id}
                                        hasPermission={false}
                                        onClose={() => {setReClick(!reClick)}}
                                        fileCount={card.fileCount}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                }
                <ArchiveFileDataRoom/>
            </div>
        </div >
    );
}
