import appLocals from "../localization/localization_en_app.json"

export const generateURLS3Asset = ({ assetType, sub_path, category }) => {
    try {
        const ASSET = appLocals.app.s3_assets;
        if (["video"].includes(assetType)) { 
            return new URL(
                ASSET?.root_url + 
                ASSET?.sub_path?.[assetType]?.root_url + 
                ASSET?.sub_path?.[assetType]?.sub_path?.[sub_path]
            )?.href;
        }
        if (["image"].includes(assetType)) { 
            return new URL(
                ASSET?.root_url + 
                ASSET?.sub_path?.[assetType]?.root_url + 
                ASSET?.sub_path?.[assetType]?.sub_path?.[category]?.root_url +
                ASSET?.sub_path?.[assetType]?.sub_path?.[category]?.sub_path?.[sub_path]
            )?.href;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}