import { useSelector } from "react-redux"
import bmlocals from "../../../localization/localization_en_business_modal.json"
import { convertValueToFixed } from "../components/MyReport/formatTableData";
import { v4 as uuidv4 } from "uuid";
const includeOutputAttr = ["averageOrderValue", "averageSalesPrice", "monthlyOtherDirectCost", "monthlyRawMaterialCost", "purchasePrice", "orderByCategory", "monthlyDirectCostEcommerce", "monthlyDirectCostWebsite", "monthlyGiftingCost", "monthlyLogisticsCost", "monthlyPackingCost", "monthlyPaymentGatewayFees", "monthlyPercentOfReturnsCancellationOrdersAreChargeable", "monthlyReverseLogisticsCost", 'payrollDerivedBuyingMerchandizing',
    'returnsCancellationsAreChargeableEcommerce',
    'returnsCancellationsAreChargeableWebsite',
    'payrollDerivedContentCreative',
    'payrollDerivedPersonnelCTCIncrement',
    'payrollDerivedPersonnelHeadcount',
    'payrollDerivedPersonnelPayrollCost',
    'payrollDerivedProductDesign',
    'payrollDerivedSafety',
    'payrollDerivedSupport',
    'payrollDerivedTLCTCIncrement',
    'payrollDerivedTLHeadcount',
    'payrollDerivedTLPayrollCost',
    'payrollDerivedTotalOrdersPlusOrdersChargeable',
    'payrollDerivedWarehousing',
    'payrollUnderivedPersonnelCTCIncrement',
    'payrollUnderivedPersonnelHeadCountVariation',
    'payrollUnderivedPersonnelPayrollCost',
    'payrollUnderivedTLCTCIncrement',
    'payrollUnderivedTLHeadCount',
    'payrollUnderivedTLPayrollCost',
    'totalHeadCount',
    'totalPayroll',
    'totalGA',
    'excludeFromCAPEX',
    'commissionCostByCategory',
    'ordersByCategory',
    'revenueByCategory',
    'monthlyCustomCost'
]
let screen5metricsArray = [
    "appInstalls",
    'cacPerUser',
    'newMAU',
    'newRegisteredUsers',
    'numberOfReferrals',
    'numberOfRegisteredUsers',
    'numberOfRegisteredUsersECommerce',
    'numberOfRegisteredUsersOrganic',
    'numberOfRegisteredUsersPaid',
    'numberOfRegisteredUsersSocialMediaBuyer',
    'pctMAUPlacesOrderMonth',
    'pctMoMChurn',
    'pctPaidUserOrganicUsers',
    'pctRegisteredUsersInstallYourApp',
    'pctRegisteredUsersReferToHowManyReferrals',
    'pctRegisteredusersActiveUsers',
    'pctYourECommercePlatformBuyersConvertRegistred',
    'pctYourGuestBuyersConvertRegisteredUsers',
    'pctYourReferralBecomeRegisteredUsers',
    'retainedUsers',
    'totalMAU',
    'retentionMarketing',
    'totalDirectCostEcommerce',
    'totalDirectCostWebsite',
    'totalOperatingExpenses',
    'totalReturnsCancellationsOrdersAreChargeable',
    'totalReturnsCancellationsOrdersAreChargeableEcommerce',
    'totalReturnsCancellationsOrdersAreChargeableWebsite',
    'collectionCenterDeposit',
    'collectionCenterDepositVariation',
    'collectionCenterLease',
    'collectionCenterPerWarehouseVariation',
    'collectionCenterSetupCost',
    'collectionCenterSetupCostVariation',
    'collectionCenterStaff',
    'collectionCenterStaffCost',
    'costOfMachineVariation',
    'eachCollectionCenterStaffCostVariation',
    'laborCost',
    'leaseAmountPerMonthVariation',
    'leasePerCollectionCenterVariation',
    'machineCAPEX',
    'machineCapacityVariation',
    'monthlyOfficeDeposit',
    'monthlyOfficeLease',
    'monthlyOfficeSetupCost',
    'numberOfCollectionCenter',
    'numberOfLabor',
    'numberOfMachines',
    'numberOfWarehouse',
    'ordersProcessedPerLaborVariation',
    'overheadCostVariation',
    'perLaborCostVariation',
    'staffPerCollectionCenterVariation',
    'warehouseCapacityVariation',
    'warehouseDeposit',
    'warehouseDepositVariation',
    'warehouseLease',
    'warehouseOverheadCost',
    'warehouseSetupCostVariation',
    'warehouseSetupcost',
    'emailServices',
    'erpCostProjection',
    'monthYear',
    'monthlyChatSoftware',
    'monthlyERP',
    'monthlyEmailServices',
    'monthlyITSupportRepairs',
    'monthlyOtherGA',
    'monthlyOutsourcedTechResources',
    'monthlyOverheadCost',
    'monthlyServerCostAWS',
    'monthlySoftwareLicenses',
    'officeOverheads',
    'otherGACostProjection',
    'serverCostProjection',
    'deposits',
    'intangibleAssetsCost',
    'laptopsCost',
    'laptopsVariation',
    'netForCAPEX',
    'officeEquipmentCost',
    'officeEquipmentsVariation',
    'totalCAPEX',
    'totalMarketingCost',
    'officeEquipmentsByHeadcount',
    'monthlyCustomCost'
]

let pandlSheetData = [
    "cm1Percent",
    "cm2Percent",
    "cogs",
    "contribution2",
    "contrubution1",
    "depreciation",
    "grossMargin",
    "grossMarginPercent",
    "growthPercent",
    "interestCost",
    "marketing",
    "netRevenue",
    "operatingIncomeEDIBTA",
    "operatingIncomePercent",
    "opex",
    "overheads",
    "pat",
    "patPercent",
    "pst",
    "retentionMarketing",
    "returnsCancellation",
    "revenue",
    "tax",
    "taxes"
];


const INPUT_LABEL_LOCALS = bmlocals.business_modal.revenue.output_table_label;
const ROW_TITLE_LOCALS = bmlocals.business_modal.revenue.output_row_title_render;

export const flattenObjectData = (data, decimal_place, valueType = "value", spanType = "monthly") => {
    let flattenedObj = {}
    if (!Array.isArray(data)) return;
    data?.map((subdata, key) => {
        const { month, year, value, totalValue, fiscalYearText, fiscalYear, finalYear, monthIndex, newFiscalYearFlag, newYearFlag } = subdata || {};
        const targetValue = subdata[valueType]
        flattenedObj = { ...flattenedObj, [spanType === "monthly" ? `${month}-${year}` : fiscalYear]: targetValue === null || targetValue === undefined || isNaN(targetValue) ? null : typeof targetValue === "number" ? targetValue?.toFixed(decimal_place) : 0 }
    })
    return flattenedObj;
}

const seggregatePropObjData = (data, decimal_place) => {
    try {
        let output = {};
        data.map((sensitiveData) => {
            const month_year = `${sensitiveData.month}-${sensitiveData.year}`
            const { month, year, monthIndex, value, breakdown, finalYear, fiscalYear, fiscalYearText, newFiscalYearFlag, newYearFlag, totalValue, ...remaningPC } = sensitiveData || {};
            for (let catg of Object.entries(remaningPC)) {
                if (catg[0] in output) {
                    output = { ...output, [catg[0]]: { ...output[catg[0]], [month_year]: (catg[1] === null || catg[1] === undefined || isNaN(catg[1])) ? null : catg[1].toFixed(decimal_place) } }
                } else {
                    output = { ...output, [catg[0]]: { title: catg[0], [month_year]: (catg[1] === null || catg[1] === undefined || isNaN(catg[1])) ? null : catg[1].toFixed(decimal_place) } }
                }
            }
        })
        return Object.values(output);
    } catch (e) {
        return output
    }
}

export const seggregateScreen3Data = (data, decimal_place, spanType = "monthly") => {
    let outputData = {};
    for (let outputAttribute of Object.entries(data)) {
        let subData = {};
        if (outputAttribute[0] === "commissionCostByCategory") {
            const ccbc = []
            const ccvyoy = []
            outputAttribute[1].map((obj) => {
                ccbc.push({ "month": obj.month, "year": obj.year, ...obj.ccbc })
                ccvyoy.push({ "month": obj.month, "year": obj.year, ...obj.ccvyoy })
            })
            outputData = { ...outputData, ccbc: seggregatePropObjData(ccbc, decimal_place), ccvyoy: seggregatePropObjData(ccvyoy, decimal_place) }
        } else if (["marketing", "overheads"].includes(outputAttribute[0])) {
            let nestedArrayRow = {};
            let totalValueRow = {};
            outputAttribute[1].map((d) => {
                totalValueRow = { ...totalValueRow, [spanType === "monthly" ? `${d.month}-${d.year}` : d.fiscalYearText]: convertValueToFixed({ "value": d?.totalValue, decimal_place }) };
            })
            outputAttribute[1].map((d) => {
                if (typeof d?.value === "object" && !!d?.value) {
                    for (let catg of Object.entries(d?.value)) {
                        const keyName = catg[0];
                        const nestedValue = catg[1];
                        nestedArrayRow = nestedArrayRow[keyName] === undefined
                            ? { ...nestedArrayRow, [keyName]: { [spanType === "monthly" ? `${d.month}-${d.year}` : d.fiscalYearText]: convertValueToFixed({ "value": nestedValue, decimal_place }) } }
                            : { ...nestedArrayRow, [keyName]: { ...nestedArrayRow[keyName], [spanType === "monthly" ? `${d.month}-${d.year}` : d.fiscalYearText]: convertValueToFixed({ "value": nestedValue, decimal_place }) } }
                    }
                }
            })
            let children = []
            for (let catg of Object.entries(nestedArrayRow)) {
                children = [...children, { key: catg[0], "title": catg[0], ...catg[1] }]
            }
            const parentChildrenData = [{ "key": outputAttribute[0], "title": outputAttribute[0], ...totalValueRow, children }]
            outputData = { ...outputData, [outputAttribute[0]]: parentChildrenData }
        }
        else if (["report_marketing_spend_total", "totalAOV", "totalOrders", "totalRevenue", "totalVisits", "organicTrafficVisits", "organicTrafficConversionRate", "paidTrafficOrders", "paidTrafficIsOrganic", "paidTrafficVisitsByChannel", "organicTrafficOrders", "monthlyNetPaymentTermFromYourVendor", "monthlyPercentOfRevenueAreYourCurrentAssets", "percentOfPayrollAsShortTermAndLongTermProbation", ...screen5metricsArray, ...pandlSheetData].includes(outputAttribute[0])) {
            outputData = { ...outputData, [outputAttribute[0]]: [{ title: INPUT_LABEL_LOCALS?.[outputAttribute[0]].label, ...flattenObjectData(outputAttribute[1], decimal_place, "value", spanType) }] }
        } else if (outputAttribute[0] === "cpc") {
            const cpc = [];
            outputAttribute[1].map((obj) => {
                cpc.push({ "month": obj.month, "year": obj.year, ...obj.cpcObj })
            })
            outputData = { ...outputData, cpc: seggregatePropObjData(cpc, decimal_place) }
        }
        else if (["ordersByCategory", "revenueByCategory", "ordersByChannel", "monthlyNetPaymentTermToYourCustomer"].includes(outputAttribute[0])) {
            outputData = { ...outputData, [outputAttribute[0]]: seggregatePropObjData(outputAttribute[1], decimal_place) }
        }
        else if (includeOutputAttr.includes(outputAttribute[0])) {
            try {
                outputAttribute[1].map((sensitiveData) => {
                    const month_year = `${sensitiveData.month}-${sensitiveData.year}`
                    const { month, year, monthIndex, value, fiscalYearText, newFiscalYearFlag, finalYear, fiscalYear, newYearFlag, ...remaningPC } = sensitiveData || {};
                    for (let productCatg of Object.entries(remaningPC)) {
                        if (productCatg[0] in subData) {
                            subData = { ...subData, [productCatg[0]]: { ...subData[productCatg[0]], [month_year]: productCatg[1] === null || productCatg[1] === undefined || isNaN(productCatg[1]) ? null : productCatg[1]?.toFixed(2) } }
                        } else {
                            subData = { ...subData, [productCatg[0]]: { "title": productCatg[0], [month_year]: productCatg[1] === null || productCatg[1] === undefined || isNaN(productCatg[1]) ? null : productCatg[1]?.toFixed(2) } }
                        }
                    }
                })
                outputData = { ...outputData, [outputAttribute[0]]: Object.values(subData) }
            } catch (e) {
                return outputData
            }
        }
    }
    return outputData;
}

export const seggregateScreen2Data = (data, decimal_place) => {
    let outputData = {}
    if (!!data === false) return;
    for (let productCategory of Object.entries(data)) {
        const title = productCategory[0];
        const segmentValues = productCategory[1];
        console.log(title, segmentValues, "----debug----");
        for (let subSegment of Object.entries(segmentValues)) {
            const tableName = subSegment[0];
            const tableRow = subSegment[1];
            if (includeOutputAttr.includes(subSegment[0])) {
                if (tableName in outputData) {// table not present in outputData
                    outputData = { ...outputData, [tableName]: [...outputData[tableName], { "title": title, ...flattenObjectData(tableRow) }] }
                } else {// table present in outputData
                    outputData = { ...outputData, [tableName]: [{ "title": title, ...flattenObjectData(tableRow) }] }
                }
            }
        }
    }
    return outputData;
}

export const seggregateScreenArrayData = (data, decimal_place) => {
    let outputDataComputed = []
    if (!!data === false) return;
    for (let category of Object.entries(data)) {
        const title = category[0];
        const segmentValues = category[1];
        outputDataComputed.push({ "title": title, ...flattenObjectData(segmentValues, decimal_place) });
    }
    return outputDataComputed;
}

export const seggregateScreen1Data = ({ data, title }, decimal_place, spanType) => {
    let outputData = []
    if (!!data === false) return
    for (let productCategory of Object.entries(data)) {
        if (productCategory[1].length > 0) {
            const title = productCategory[0];
            const segmentValues = productCategory[1];
            const rowData = { title, ...flattenObjectData(segmentValues, decimal_place, "value", spanType) }
            outputData.push(rowData)
        }
    }
    return { [title]: outputData };
}

const seggregateCashflowData = (data) => {
    let finalMonthlyData = {}
    data.map((row) => {
        if (row.title === "Opening Cash Balance") {
            finalMonthlyData = { ...finalMonthlyData, "Opening Cash Balance": row }
        }
        if (row.title === "Cash Profit") {
            finalMonthlyData = { ...finalMonthlyData, "Cash Profit": { ...row, "children": [], key: "Cash Profit" } }
        }
        if (row.title === "Capital Expenditure (Capex)") {
            finalMonthlyData = { ...finalMonthlyData, "Cash Profit": { ...finalMonthlyData["Cash Profit"], "children": [...finalMonthlyData["Cash Profit"].children, { ...row, "key": "Capital Expenditure (Capex)" }] } }
        }
        if (row.title === "Deposits") {
            finalMonthlyData = { ...finalMonthlyData, "Cash Profit": { ...finalMonthlyData["Cash Profit"], "children": [...finalMonthlyData["Cash Profit"].children, { ...row, "key": "Deposits" }] } }
        }
        if (row.title === "Working Capital Changes") {
            finalMonthlyData = { ...finalMonthlyData, "Cash Profit": { ...finalMonthlyData["Cash Profit"], "children": [...finalMonthlyData["Cash Profit"].children, { ...row, "key": "Working Capital Changes" }] } }
        }
        if (row.title === "Change in Other Assets/Liabilities") {
            finalMonthlyData = { ...finalMonthlyData, "Cash Profit": { ...finalMonthlyData["Cash Profit"], "children": [...finalMonthlyData["Cash Profit"].children, { ...row, "key": "Change in Other Assets/Liabilities" }] } }
        }
        if (row.title === "Net Cashflow") {
            finalMonthlyData = { ...finalMonthlyData, "Net Cashflow": { ...row, "children": [], key: "Net Cashflow" } }
        }
        if (row.title === "Equity") {
            finalMonthlyData = { ...finalMonthlyData, "Net Cashflow": { ...finalMonthlyData["Net Cashflow"], "children": [...finalMonthlyData["Net Cashflow"].children, { ...row, "key": "Equity" }] } }
        }
        if (row.title === "Net Long-Term Borrowing") {
            finalMonthlyData = { ...finalMonthlyData, "Net Cashflow": { ...finalMonthlyData["Net Cashflow"], "children": [...finalMonthlyData["Net Cashflow"].children, { ...row, "key": "Net Long-Term Borrowing" }] } }
        }
        if (row.title === "Net Short-Term Borrowing") {
            finalMonthlyData = { ...finalMonthlyData, "Net Cashflow": { ...finalMonthlyData["Net Cashflow"], "children": [...finalMonthlyData["Net Cashflow"].children, { ...row, "key": "Net Short-Term Borrowing" }] } }
        }
        if (row.title === "Closing Cash Balance") {
            finalMonthlyData = { ...finalMonthlyData, "Closing Cash Balance": row }
        }
    })
    return finalMonthlyData
}

const idToTitleConversionObject = ({ data, list }) => {
    let idToTitleData = {}
    for (const table of Object.entries(data)) {
        if (Array.isArray(table[1])) {
            const mappedData = table[1]?.map((r) => {
                return ({ ...r, "title": list[r.title] ?? r.title })
            })
            idToTitleData = { ...idToTitleData, [table[0]]: mappedData }
        }
    }
    return idToTitleData;
}

const idToTitleConversionArray = ({ data, list }) => {
    let idToTitleData = []
    for (let i = 0; i < data.length; i++) {
        idToTitleData.push({ ...data[i], "title": list[data[i].title] ?? data[i].title })
    }
    return idToTitleData;
}

const idToTableTitleConversion = ({ data, list, prefixTableName }) => {
    let tables = {};
    for (const table of Object.entries(data)){
        tables = { ...tables, [`${prefixTableName} [ ${list?.[table?.[0]] ?? table[0]} ]`]: table[1] }
    }
    return tables
}

const backendTitleToClientTitleConversion = (data) => {
    const convertedTitle = data.map((row) => {
        if ("children" in row) {
            return ({
                ...row,
                "title": ROW_TITLE_LOCALS?.[row?.title] ?? row.title,
                "children": row?.children?.map((child) => {
                    return {
                        ...child,
                        "title": ROW_TITLE_LOCALS?.[child?.title] ?? child.title
                    }
                })
            })
        }
        return ({
            ...row,
            "title": ROW_TITLE_LOCALS?.[row?.title] ?? row.title
        })
    })
    return convertedTitle;
}

const convertSingleDataIntoTable = ({ data, decimal_place = 2 }) => {
    let tables = {};
    for (const table of Object.entries(data)) {
        let subData = {}
        console.log(table[0],table[1])
        table[1].map((sensitiveData) => {
            const month_year = `${sensitiveData.month}-${sensitiveData.year}`
            const { month, year, monthIndex, value, fiscalYearText, newFiscalYearFlag, finalYear, fiscalYear, newYearFlag, ...remaningPC } = sensitiveData || {};
            for (let productCatg of Object.entries(remaningPC)) {
                if (productCatg[0] in subData) {
                    subData = { ...subData, [productCatg[0]]: { ...subData[productCatg[0]], [month_year]: productCatg[1] === null || productCatg[1] === undefined || isNaN(productCatg[1]) ? null : productCatg[1]?.toFixed(2) } }
                } else {
                    subData = { ...subData, [productCatg[0]]: { "title": productCatg[0], [month_year]: productCatg[1] === null || productCatg[1] === undefined || isNaN(productCatg[1]) ? null : productCatg[1]?.toFixed(2) } }
                }
            }
        })
        console.log(subData, "subData")
        tables = { ...tables, [table[0]]: Object.values(subData) }
    }
    return tables;
}

const seggegateIdFromMixedIdAndTitle = ({ data, isIdOrTitle="id" }) => {
    let cleanedTitle = {};
    for(const row of Object.entries(data)){
        const rowId = row[0].split(' ').length>1 ? row[0].split(' ').slice(0,1).join('') : row[0]
        const rowTitle = row[0].split(' ').length>1 ? row[0].split(' ').slice(1).join('') : row[0]
        cleanedTitle = { ...cleanedTitle, [isIdOrTitle==="id" ? rowId : rowTitle]: row[1] }
    }
    return cleanedTitle;
}

export const outputTablePayloadPreparation = (data, screenNumber, type, spanType, category_list) => {
    // console.log(Object.values(data.productCategoryOutput)[0],"demo")
    // console.log(data,"-------entry-----","exe")
    let outputData = {}
    // console.log(data);
    console.log('category_list')
    console.log(category_list);
    const decimal_place = useSelector((state) => state.globalSetting.active_global_settings.decimal_places);
    if (Array.isArray(data) || !!data == false) return;
    if (type === "cost_screen") {
        let CatgMapping = {};
        [...category_list]?.map((tag) => {
            CatgMapping = { ...CatgMapping, [tag.id]: tag.label }
        })
        switch (screenNumber) {
            case 0: {
                let totalScreen1TableData = {}
                for (const prop of Object.entries(data)) {
                    if (['monthlyMarketingSpend', 'monthlyRetentionMarketing'].includes(prop[0])) {
                        totalScreen1TableData = { ...totalScreen1TableData, ...seggregateScreen1Data({ data: prop[1], title: prop[0] }, decimal_place) }
                    } else if (['retentionMarketing', 'totalMarketingCost'].includes(prop[0])) {
                        totalScreen1TableData = { ...totalScreen1TableData, ...seggregateScreen3Data({ [prop[0]]: prop[1] }, decimal_place) }
                    }
                }
                let TotalMarketingCost = []
                const seggregateCollasped_NormalTable = totalScreen1TableData;
                TotalMarketingCost = [{ ...totalScreen1TableData.totalMarketingCost?.[0], "children": idToTitleConversionArray({ "data": [...totalScreen1TableData.monthlyMarketingSpend, ...totalScreen1TableData.retentionMarketing].map((r) => ({ ...r, "isChildren": true })), "list": CatgMapping }) }]
                delete seggregateCollasped_NormalTable.monthlyMarketingSpend;
                delete seggregateCollasped_NormalTable.totalMarketingCost;
                delete seggregateCollasped_NormalTable.retentionMarketing
                return { ...seggregateCollasped_NormalTable, "totalMarketingCost": TotalMarketingCost }
            }
            case 1: {
                let totalScreen2TableData = {}
                for (const prop of Object.entries(data)) {
                    totalScreen2TableData = { ...totalScreen2TableData, ...seggregateScreen3Data({ [prop[0]]: prop[1] }, decimal_place) }
                }
                const monthlyCustomListTables = convertSingleDataIntoTable({ "data": data.monthlyCustomList, decimal_place })
                const monthlyCustomTables = idToTableTitleConversion({ data: monthlyCustomListTables, list: CatgMapping, prefixTableName: "Monthly Custom Direct Cost 1" })
                // console.log(monthlyCustomList, "monthlyCustomList")
                totalScreen2TableData = idToTitleConversionObject({ "data": { ...totalScreen2TableData, ...monthlyCustomTables }, "list": CatgMapping })
                let totalOperatingExpenses = [];
                if ("totalOperatingExpenses" in totalScreen2TableData && "totalDirectCostWebsite" in totalScreen2TableData && "totalDirectCostEcommerce" in totalScreen2TableData) {
                    totalOperatingExpenses = [{ ...totalScreen2TableData.totalOperatingExpenses?.[0], "children": [totalScreen2TableData.totalDirectCostWebsite?.[0], totalScreen2TableData.totalDirectCostEcommerce?.[0]] }]
                    delete totalScreen2TableData.totalOperatingExpenses;
                    delete totalScreen2TableData.totalDirectCostWebsite;
                    delete totalScreen2TableData.totalDirectCostEcommerce;
                    return { ...totalScreen2TableData, totalOperatingExpenses }
                }
                return totalScreen2TableData
            }
            case 2: {
                let totalScreen3TableData = {}
                const monthlyCustomCost = seggegateIdFromMixedIdAndTitle({ data: data.monthlyCustomCost, isIdOrTitle: "title" });
                for (const prop of Object.entries(data)) {
                    totalScreen3TableData = { ...totalScreen3TableData, ...seggregateScreen3Data({ [prop[0]]: prop[1] }, decimal_place) }
                }
                const monthlyCustomCapacityPlanning = seggregateScreenArrayData(data.monthlyCustomCapacityPlanning, decimal_place)
                console.log(CatgMapping, "CatgMapping")
                totalScreen3TableData = idToTitleConversionObject({ "data": {...totalScreen3TableData, monthlyCustomCapacityPlanning}, "list": CatgMapping })
                return {...totalScreen3TableData, "monthlyCustomCost": seggregateScreenArrayData(monthlyCustomCost, decimal_place)}
            }
            case 3: {
                let totalScreen4TableData = {}
                for (const prop of Object.entries(data)) {
                    totalScreen4TableData = { ...totalScreen4TableData, ...seggregateScreen3Data({ [prop[0]]: prop[1] }, decimal_place) }
                }
                totalScreen4TableData = idToTitleConversionObject({ "data": totalScreen4TableData, "list": CatgMapping })
                return totalScreen4TableData
            }
            case 4: {
                let totalScreen5TableData = {}
                const monthlyOverheadCost = data.monthlyOverheadCost.map((month, index) => month.type==="% Of Lease" ? {...month, "value": month?.value?.Amount} : {...month, "value": month.value})
                data = {...data, monthlyOverheadCost};
                for (const prop of Object.entries(data)) {
                    const test = Object.values({ [prop[0]]: prop[1] })
                    totalScreen5TableData = { ...totalScreen5TableData, ...seggregateScreen3Data({ [prop[0]]: prop[1] }, decimal_place) }
                }
                const GACostProjection = seggregateScreenArrayData(data.GACostProjection, decimal_place);
                totalScreen5TableData = { GACostProjection, ...totalScreen5TableData, "totalGA": backendTitleToClientTitleConversion(totalScreen5TableData.totalGA) }
                totalScreen5TableData = idToTitleConversionObject({ "data": totalScreen5TableData, "list": CatgMapping })
                return totalScreen5TableData
            }
            case 5: {
                let totalScreen6TableData = {}
                for (const prop of Object.entries(data)) {
                    totalScreen6TableData = { ...totalScreen6TableData, ...seggregateScreen3Data({ [prop[0]]: prop[1] }, decimal_place) }
                }
                const customCapexTable = seggregateScreenArrayData(data.customCapex, decimal_place);
                let customCapexTables = {};
                console.log(customCapexTable, "customCapexTable")
                if (customCapexTable) {
                    customCapexTable.map((tableRow) => {
                        customCapexTables = {...customCapexTables, [`Custom Capex ${tableRow.title}`]:[tableRow]}
                    })
                }
                console.log(customCapexTables, "customCapexTables")
                // const capexCustomTables = idToTableTitleConversion({ data: customCapexListTable, list: CatgMapping, prefixTableName: "Custom Capex 1" })
                // console.log(capexCustomTables)
                totalScreen6TableData = idToTitleConversionObject({ "data": totalScreen6TableData, "list": CatgMapping })
                return {...totalScreen6TableData,...customCapexTables};
            }
        }
    }
    if (type === "revenue_screen") {
        let CatgMapping = {};
        [...category_list]?.map((tag) => {
            CatgMapping = { ...CatgMapping, [tag.id]: tag.title }
        })
        switch (screenNumber) {
            case 1: {
                const screen1TableData = seggregateScreen1Data({ "data": data.output, title: "marketingSpend" }, decimal_place);
                const screen2TableData = seggregateScreen2Data(data.productCategoryOutput, decimal_place);
                return { ...screen1TableData, ...idToTitleConversionObject({ "data": screen2TableData, "list": CatgMapping }) }
            }
            case 2: {
                const screen3TableData = seggregateScreen3Data(data, decimal_place);
                const screen3mappingTable = seggregateScreen2Data(data.productCategoryOutput, decimal_place);
                return { ...screen3TableData, ...idToTitleConversionObject({ "data": screen3mappingTable, "list": CatgMapping }) }
            }
            case 3: {
                const screen3TableData = seggregateScreen3Data(data, decimal_place);
                return idToTitleConversionObject({ "data": screen3TableData, "list": CatgMapping })
            }
            case 4: {
                const screen3TableData = seggregateScreen3Data(data, decimal_place);
                return idToTitleConversionObject({ "data": screen3TableData, "list": CatgMapping })
            }
            case 5: {
                let output = [];
                const mainTitle = { "totalOrdersByCategory": "Orders", "totalRevenueByCategory": "Revenue", "returnsCancellationsByCategory": "Returns/Cancellations", "netRevenueByCategory": "Net Revenue By Category", "cogsByCategory": "COGS", "taxesByCategory": "Taxes", "netRevenue": "Net Revenue", "growthPercentage": "Growth %", "grossMargin": "Gross Margin", "grossMarginPercentage": "Gross Margin %" }
                for (const outputAttribute of Object.entries(data)) {
                    if (["totalOrdersByCategory", "totalRevenueByCategory", "returnsCancellationsByCategory", "netRevenueByCategory", "cogsByCategory", "taxesByCategory"].includes(outputAttribute[0])) {
                        let categoryAccordinData = seggregatePropObjData(outputAttribute[1], decimal_place);
                        categoryAccordinData = idToTitleConversionArray({ "data": categoryAccordinData, "list": CatgMapping })
                        let categoryAccordinHeader = flattenObjectData(outputAttribute[1], decimal_place, "totalValue");
                        categoryAccordinHeader = { "title": mainTitle[outputAttribute[0]], "key": uuidv4(), "children": categoryAccordinData.map((subChildren) => ({ "title": mainTitle[subChildren.title], ...subChildren, "parentTitle": mainTitle[outputAttribute[0]] })), ...categoryAccordinHeader }
                        output = [...output, categoryAccordinHeader]
                    }
                    if (["netRevenue", "growthPercentage", "grossMargin", "grossMarginPercentage"].includes(outputAttribute[0])) {
                        output = [...output, { "title": mainTitle[outputAttribute[0]], key: uuidv4(), ...flattenObjectData(outputAttribute[1]) }]
                    }
                }
                return { "Summary": output };
            }
        }
    }
    if (type === "pandl") {
        let CatgMapping = {};
        if (category_list) {
            [...category_list]?.map((tag) => {
                CatgMapping = { ...CatgMapping, [tag.id]: tag.title }
            })
        }
        const mainTitle = { "payroll": "Payroll", "facilities": "Facilities", "ga": "G&A", "technology": "Technology", "gross margin percent": "Gross Margin %", "cm1 percent": "CM 1 %", "cm2 percent": "CM 2%", "operating income percent": "Operating Income %", "pat percent": "PAT %", "revenue": "Revenue", "growth percent": "Growth %", "returns cancellation": "Returns/Cancellations", "net revenue": "Net Revenue", "cogs": "COGS", "taxes": "Taxes", "opex": "Opex", "Retention Marketing": "Retention Marketing", "depreciation": "Depreciation", "interest cost": "Interest Cost", "pst": "pst", "tax": "Tax", "gross margin": "Gross Margin", "contrubution1": "Contribution 1", "marketing": "Marketing", "contribution2": "Contribution 2", "overheads": "Overheads", "operating income edibta": "Operating Income(EBIDTA)", "pat": "PAT" }
        let pandlData = seggregateScreen3Data(data, decimal_place, spanType);
        pandlData = { ...pandlData, "marketing": [{ ...pandlData.marketing[0], "children": idToTitleConversionArray({ "data": pandlData.marketing[0].children.map((r) => ({ ...r, "key": r.title })), "list": CatgMapping }) }] }
        //pat
        const collapsedPAT = { key: "pat", ...pandlData.pat[0], children: [{ key: "patPercent", ...pandlData.patPercent[0] }] };
        // operating income edibta
        const collapsedIncomeEdita = { key: "operatingIncomeEDIBTA", ...pandlData["operatingIncomeEDIBTA"][0], children: [{ key: "operatingIncomePercent", ...pandlData["operatingIncomePercent"][0] }] }
        // contrubution1
        const collapsedContrubution1 = { key: "contrubution1", ...pandlData["contrubution1"][0], children: [{ key: "cm1Percent", ...pandlData["cm1Percent"][0] }] }
        // contribution2
        const collapsedContribution2 = { key: "contribution2", ...pandlData["contribution2"][0], children: [{ key: "cm2Percent", ...pandlData["cm2Percent"][0] }] }
        // gross margin
        const collapsedGrossmargin = { key: "grossMargin", ...pandlData["grossMargin"][0], children: [{ key: "grossMarginPercent", ...pandlData["grossMarginPercent"][0] }] }
        const collapsedData = {
            "grossMargin": collapsedGrossmargin,
            "contrubution1": collapsedContrubution1,
            "contribution2": collapsedContribution2,
            "operatingIncomeEDIBTA": collapsedIncomeEdita,
            "pat": collapsedPAT
        }
        let masterPAndLData = { "Particulars": null };
        let collapseData = [];
        for (const attr of Object.entries(pandlData)) {
            if (["cm2Percent", "cm1Percent", "operatingIncomePercent", "grossMarginPercent", "patPercent"].includes(attr[0])) continue;
            if (["pat", "operatingIncomeEDIBTA", "contrubution1", "contribution2", "grossMargin"].includes(attr[0])) {
                collapseData = [...collapseData, collapsedData[attr[0]]];
                continue;
            }
            collapseData = [...collapseData, attr[1][0]]
        }
        collapseData = collapseData.map((row) => {
            let children;
            if ("children" in row) {
                children = row.children.map((childRow) => ({ ...childRow, "title": !!mainTitle[childRow.title] ? mainTitle[childRow.title] : childRow.title, "parentTitle": !!mainTitle[childRow.title] ? mainTitle[childRow.title] : childRow.title }))
            }
            return { ...row, "title": !!mainTitle[row.title] ? mainTitle[row.title] : "", children }
        })
        masterPAndLData.Particulars = collapseData
        return masterPAndLData;
    }
    if (type === "workingCapital") {
        let CatgMapping = {};
        if (Array.isArray(category_list)) {
            [...category_list]?.map((tag) => {
                CatgMapping = { ...CatgMapping, [tag.value]: tag.label }
            })
        }
        let workingCapTableData = [];
        const inventoryDays = seggregateScreen1Data({ data: data.inventoryDays, title: "inventoryDays" }, decimal_place)
        const numberOfMonths = seggregateScreen1Data({ data: data?.numberOfMonths, title: "numberOfMonths" }, decimal_place)
        let staticArrayData = seggregateScreen3Data(data, decimal_place);
        let nestedPurchaseData = [];
        let workingCapital = [];
        let currentAssets = [];
        let currentLiabilities = [];
        let nestedShortTermLoansAndAdvances = [];
        let nestedTaxesPayable = [];
        let nestedCurrentAssets = [];
        let nestedCurrentLiabilities = [];
        let nestedWorkingCapData = [];
        let nestedNumberOfMonths = [];
        if (data?.purchases) {
            for (const attribute of Object.entries(data?.purchases)) {
                nestedPurchaseData = [...nestedPurchaseData, [{ title: INPUT_LABEL_LOCALS?.[attribute[0]]?.label, "key": INPUT_LABEL_LOCALS?.[attribute[0]]?.label, ...flattenObjectData(attribute[1], decimal_place) }]?.[0]]
            }
        }
        if (data?.taxesPayable) {
            for (const attribute of Object.entries(data.taxesPayable)) {
                nestedTaxesPayable = [...nestedTaxesPayable, [{ title: INPUT_LABEL_LOCALS?.[attribute[0]]?.label, "key": INPUT_LABEL_LOCALS?.[attribute[0]]?.label, ...flattenObjectData(attribute[1], decimal_place) }]?.[0]]
            }
        }
        if (data?.shortTermLoansAndAdvances) {
            for (const attribute of Object.entries(data.shortTermLoansAndAdvances)) {
                if (attribute[0] !== "total") {
                    nestedShortTermLoansAndAdvances = [...nestedShortTermLoansAndAdvances, [{ title: INPUT_LABEL_LOCALS?.[attribute[0]]?.label, "key": INPUT_LABEL_LOCALS?.[attribute[0]]?.label, ...flattenObjectData(attribute[1], decimal_place) }]?.[0]]
                }
            }
        }
        if (data?.currentAssets) {
            for (const attribute of Object.entries(data?.currentAssets)) {
                nestedCurrentAssets = [...nestedCurrentAssets, [{ title: INPUT_LABEL_LOCALS?.[attribute[0]]?.label, "key": INPUT_LABEL_LOCALS?.[attribute[0]]?.label, ...flattenObjectData(attribute[1], decimal_place) }]?.[0]]
            }
        }
        const parentCurrentAssets = { ...nestedCurrentAssets.find((r) => !!r.title === false), "title": "Current Assets" }
        nestedCurrentAssets = nestedCurrentAssets.filter((r) => !!r.title);
        if (data?.currentLiabilities) {
            for (const attribute of Object.entries(data?.currentLiabilities)) {
                nestedCurrentLiabilities = [...nestedCurrentLiabilities, [{ title: INPUT_LABEL_LOCALS?.[attribute[0]]?.label, "key": INPUT_LABEL_LOCALS?.[attribute[0]]?.label, ...flattenObjectData(attribute[1], decimal_place) }]?.[0]]
            }
        }
        const parentCurrentLiabilities = { ...nestedCurrentLiabilities.find((r) => !!r.title === false), "title": "Current Liabilities" }
        nestedCurrentLiabilities = nestedCurrentLiabilities.filter((r) => !!r.title);
        if (data?.workingCapital) {
            for (const attribute of Object.entries(data?.workingCapital)) {
                nestedWorkingCapData = [...nestedWorkingCapData, [{ title: INPUT_LABEL_LOCALS?.[attribute[0]]?.label, "key": INPUT_LABEL_LOCALS?.[attribute[0]]?.label, ...flattenObjectData(attribute[1], decimal_place) }]?.[0]]
            }
        }
        const parentWorkingCapData = { ...nestedWorkingCapData.find((r) => !!r.title === false), "title": "Working Capital" }
        nestedWorkingCapData = nestedWorkingCapData.filter((r) => !!r.title);
        let totalShortTermLoansAndAdvances = null;
        if (data?.shortTermLoansAndAdvances?.total) {
            totalShortTermLoansAndAdvances = flattenObjectData(data?.shortTermLoansAndAdvances?.total, decimal_place)
        }
        const mergeShortTermLoansAndAdvances = { "title": "Short Term Loans And Advances", "key": "Short Term Loans And Advances", ...totalShortTermLoansAndAdvances, "children": nestedShortTermLoansAndAdvances }
        const mergePurchaseData = { "title": "Purchases", "key": "Purchases", "children": nestedPurchaseData }
        const totalTaxesPayable = nestedTaxesPayable.find((r) => !!r?.title === false)
        nestedTaxesPayable = nestedTaxesPayable.filter((r) => !!r.title)
        const mergeTaxesPayable = { ...totalTaxesPayable, "title": "Taxes Payable", "key": "Taxes Payable", "children": nestedTaxesPayable }
        workingCapTableData = [
            staticArrayData?.monthlyNetPaymentTermFromYourVendor?.[0],
            { "title": "Inventory Days", "key": "inventoryDays", "children": idToTitleConversionArray({ "data": inventoryDays?.inventoryDays.map((r) => ({ ...r, "key": r.title })), "list": CatgMapping }) },
            { "title": "Number Of Months", "key": "numberOfMonths", "children": idToTitleConversionArray({ "data": numberOfMonths?.numberOfMonths.map((r) => ({ ...r, "key": r.title })), "list": CatgMapping }) },
            staticArrayData?.monthlyPercentOfRevenueAreYourCurrentAssets?.[0],
            staticArrayData?.percentOfPayrollAsShortTermAndLongTermProbation?.[0],
            { "title": "Monthly Net Payment Term To Your Customer", "key": "Monthly Net Payment Term To Your Customer", "children": staticArrayData?.monthlyNetPaymentTermToYourCustomer.map((r) => ({ ...r, "key": r.title })) },
            mergeShortTermLoansAndAdvances,
            mergePurchaseData,
            mergeTaxesPayable,
            { "title": "Current Assets", ...parentCurrentAssets, "children": nestedCurrentAssets, "key": "Current Assets", },
            { "title": "Current Liabilities", ...parentCurrentLiabilities, "children": nestedCurrentLiabilities, "key": "Current Liabilities" },
            { "title": "Working Capital", ...parentWorkingCapData, "children": nestedWorkingCapData, "key": "Working Capital" }
        ]
        return { "Particulars": backendTitleToClientTitleConversion(workingCapTableData) }
    }
    if (type === "cashFlowScreen") {
        // console.log(data,"data")
        const monthlyData = seggregateScreen1Data({ data: data.monthly, title: "cashflow" }, decimal_place)
        const yearlyData = seggregateScreen1Data({ data: data.yearly, title: "cashflow" }, decimal_place, "yearly")
        const titltMappedMonthlyData = monthlyData.cashflow.map((row) => ({ ...row, "title": INPUT_LABEL_LOCALS[row.title]?.label }))
        const titltMappedYearlyData = yearlyData.cashflow.map((row) => ({ ...row, "title": INPUT_LABEL_LOCALS[row.title]?.label }))
        const finalMonthlyData = seggregateCashflowData(titltMappedMonthlyData)
        const finalYearlyData = seggregateCashflowData(titltMappedYearlyData)
        return { "cashflowMonthly": backendTitleToClientTitleConversion(Object.values(finalMonthlyData)), "cashflowYearly": backendTitleToClientTitleConversion(Object.values(finalYearlyData)) }
    }
    if (type === "debtWorkingScreen") {
        let CatgMapping = {};
        [...category_list]?.map((tag) => {
            CatgMapping = { ...CatgMapping, [tag.id]: tag.label }
        })
        let formattedData = {
            ...seggregateScreen1Data({ data: data.amountReceivedDebtRaised, title: "amountReceivedDebtRaised" }, decimal_place),
            ...seggregateScreen1Data({ data: data.balanceClosing, title: "balanceClosing" }, decimal_place),
            ...seggregateScreen1Data({ data: data.openingOfLoan, title: "openingOfLoan" }, decimal_place),
            ...seggregateScreen1Data({ data: data.paymentsRepayment, title: "paymentsRepayment" }, decimal_place),
            "totalInterestPaid": { "title": INPUT_LABEL_LOCALS["totalInterestPaid"].label, ...flattenObjectData(data.totalInterestPaid, decimal_place) }
        }
        formattedData = idToTitleConversionObject({ "data": formattedData, "list": CatgMapping })
        let debtWorkingOutputData = [];
        for (const attr of Object.entries(formattedData)) {
            if (attr[0] === "totalInterestPaid") {
                debtWorkingOutputData.push(attr[1]);
            } else {
                if (Array.isArray(attr[1])) {
                    const flattenMapData = attr[1].map((nestedRow) => Array.isArray(nestedRow.title) ? { ...nestedRow, "title": nestedRow?.title?.[0], "key": uuidv4() } : { ...nestedRow, "key": uuidv4() })
                    const parent = attr[1]?.find((r) => r?.title === "total")
                    const children = flattenMapData?.filter((r) => r?.title !== "total").map((r) => ({ ...r, "key": r.title }))
                    debtWorkingOutputData.push({ ...parent, "key": attr[0], "title": INPUT_LABEL_LOCALS[attr[0]].label, "children": children });
                }
            }
        }
        const keyMappedData = debtWorkingOutputData.map((row) => {
            return ({
                ...row,
                "key": uuidv4(),
                "children": row.children.map((child) => {
                    return {
                        ...child,
                        "key": uuidv4()
                    }
                })
            })
        })
        return { "debtWorkingOutputData": backendTitleToClientTitleConversion(keyMappedData) }
    }
    if (type === "balanceSheetScreen") {
        let formattedData = [];
        for (const attr of Object.entries(data.yearly)) {
            formattedData.push({ "title": INPUT_LABEL_LOCALS[attr[0]].label, "key": INPUT_LABEL_LOCALS[attr[0]].label, ...flattenObjectData(attr[1], decimal_place, "value", "yearly") })
        }
        let finalData = {
            "catgA": [],
            "catgB": [],
            "catgC": [],
            "catgD": [],
            "catgE": [],
            "catgF": [],
            "catgG": [],
            "catgH": [],
            "catgI": [],
        }

        formattedData.map((row) => {
            if (["Capital and Reserves", "Shareholders' Fund", "Reserves and Surplus"].includes(row.title)) finalData.catgA.push(row)
            if (["Non-Current Liabilities", "Long-Term Borrowings"].includes(row.title)) finalData.catgB.push(row)
            if (["Current Liabilities", "Short-Term Borrowings", "Trade Payables", "Other Current Liabilities", "Short-Term Provisions"].includes(row.title)) finalData.catgC.push(row)
            if (["Non-Current Assets", "Tangible Fixed Assets", "Intangible Fixed Assets/Intangible WIP", "Long-Term Deposits", "Deferred Tax Assets"].includes(row.title)) finalData.catgD.push(row)
            if (["Current Assets", "Inventories", "Trade Receivables", "Cash Investments", "Short-Term Loans and Advances", "Other Current Assets"].includes(row.title)) finalData.catgE.push(row)
            if (["Working Capital"].includes(row.title)) finalData.catgF.push(row)
            if (["Working Capital Plus Minus", "Ideal Working Capital Days", "Ideal Working Capital", "Difference"].includes(row.title)) finalData.catgG.push(row)
            if (["Net Assets"].includes(row.title)) finalData.catgH.push(row)
            if (["Net Worth"].includes(row.title)) finalData.catgI.push(row)
        })
        let balanceSheetData = [];
        for (const row of Object.entries(finalData)) {
            if (row[1].length === 1) {
                balanceSheetData.push(row[1][0])
            } else {
                const parentData = row[1].find((r) => ["Capital and Reserves", "Non-Current Liabilities", "Current Liabilities", "Non-Current Assets", "Current Assets", "Working Capital Plus Minus"].includes(r.title))
                const nestedData = row[1].filter((r) => !["Capital and Reserves", "Non-Current Liabilities", "Current Liabilities", "Non-Current Assets", "Current Assets", "Working Capital Plus Minus"].includes(r.title))
                balanceSheetData.push({ ...parentData, "children": nestedData })
            }
        }
        return { "balanceSheetOutput": backendTitleToClientTitleConversion(balanceSheetData) }
    }
}
