import appLocals from "../localization/localization_en_app.json"

const WARNING_MESSAGE = appLocals.app.warning_message;

export const topLevelRenderNotifcationBasedDecimalValue = () => {
    
}

export const numberValidation = ({ value, placeHolder, validator, isStrictPercentageCheck }) => {
    try {
        if (isStrictPercentageCheck && placeHolder.includes('.') && placeHolder.includes('%')) { // accept value above 100 + - .
            if(!(/^-?\d+(\.\d+)?$/).test(value)) return { "status": false, "message": WARNING_MESSAGE.positive_negative_with_decimal_above_hundred }
        }
        else if (isStrictPercentageCheck && !placeHolder.includes('.') && placeHolder.includes('%')) {// accept value above 100 + - 
            if(!(/^-?\d+$/).test(value)) return { "status": false, "message": WARNING_MESSAGE.positive_negative_without_decimal_above_hundred }
        }
        else if (['positive_negative_decimal_number', 'negative_decimal_number'].includes(validator) && !placeHolder.includes('.') && placeHolder.includes('%')) {
            if(!(/^-?(?:100|\d{1,2})$/).test(value)) return { "status": false, "message": WARNING_MESSAGE.positive_negative_percentage_without_decimal }
        }
        else if (['positive_negative_decimal_number', 'negative_decimal_number'].includes(validator) && placeHolder.includes('.') && placeHolder.includes('%')) {
            if(!(/^(?:-?(?:100(?:\.0{1,2})?|\d{1,2}(?:\.\d{1,2})?))$/).test(value)) return { "status": false, "message": WARNING_MESSAGE.positive_negative_percentage_with_decimal }
        }
        else if (placeHolder.includes('%') && !placeHolder.includes('.')) {
            if(!(/^(?:100|\d{1,2})$|^0$/).test(value)) return { "status": false, "message": WARNING_MESSAGE.positive_percentage_without_decimal }
        }
        else if (placeHolder.includes('%') && placeHolder.includes('.')) {
            if(!(/^(?:100(?:\.0{1,2})?|\d{1,2}(?:\.\d{1,2})?)$/).test(value)) return { "status": false, "message": WARNING_MESSAGE.positive_percentage_with_decimal }
        }
        return { "status": true }
    } catch (e) {
        console.log(e);
        return { "status": false }
    }
}