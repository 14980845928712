import React, { useState, useEffect } from 'react';

const ImageSrcHashComponent = ({ src, className, placeholderImage }) => {

    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {

        const image = new Image();
        image.onload = () => setImageLoaded(true);
        image.src = src;

    }, [src])

    return (
        <div className={className}>
            <div
                style={{
                    backgroundImage: imageLoaded ? null : `url(${placeholderImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '100%'
                }}
                className='w-full h-full'
            >
                <img
                    src={src}
                    alt=""
                    style={{ display: !imageLoaded ? 'none' : "inline" }}
                    className='w-full'
                />
            </div>
        </div>
    );
};

export default ImageSrcHashComponent;