import { message } from "antd";
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import locals from "../../localization/localization_en_website.json"
import ResponsiveHeadingTwoFive from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingTwoFive'
import LogoInputComponent from '../subcomponents/LogoInputComponent';
import SearchIcon from '@mui/icons-material/Search'
import TagSelection from '../components/TagSelection';
import BlogCardMoreInfo from '../../DesignSystem/Cards/website/BlogCardMoreInfo';
import { BLOG_IMG_REFERENCE } from "../../assets/website";
import FooterAlertPage from '../components/FooterAlertPage';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import useScrollToTopPage from '../../hooks/useScrollToTopPage';
import ResponsiveHeadingFourInter from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter';
import { API_Post_WebsiteForm } from '../../features/appSlice';
import { validateEmail } from '../../utils/validation';
import TextLarge from "../../DesignSystem/TypographyDescriptions/TextLarge";
import { D_Reset_WebsiteForm_Status } from "../../features/appSlice";
import CloseIcon from '@mui/icons-material/Close';

const BLOG_LOCALS = locals.website.blogs;

const Blog = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const api_status = useSelector((state) => state.app.website_form.api_status)
    const [tag, setActiveTag] = useState(BLOG_LOCALS.active_tag)
    const [filterBlogText, setFilterBlogText] = useState("");
    const [email, setEmail] = useState("");

    useScrollToTopPage();

    useEffect(() => {
        dispatch(D_Reset_WebsiteForm_Status())
    }, [])

    useEffect(() => {
        if (api_status.status === "failed") {
            message.open({ "type": "error", "content": api_status.message })
        }
    }, [api_status.status])

    const SORTED_BLOGS = useMemo(() => {
        return BLOG_LOCALS.blog_list.map((b) => ({...b, "posted_by": {...b.posted_by, "created": eval(b?.posted_by?.created)}})).sort((a,b) => b.posted_by.created-a.posted_by.created)
    }, [])

    const IS_BLOG_RENDERED = BLOG_LOCALS.blog_list.filter((blog, index) => {
        if (
            blog.blog_tag.includes(tag)
            &&
            (
                filterBlogText.trim().length === 0
                    ? true
                    : blog.title.toLowerCase().includes(filterBlogText.toLowerCase())
            )
        ) {
            return blog;
        }
    })

    return (
        <div className='flex flex-col sm:gap-y-[80px] gap-y-[40px] py-[80px]'>
            <div className='flex flex-col justify-center items-center gap-y-[16px] p-[12px]'>
                <ResponsiveHeadingTwoFive text={BLOG_LOCALS.secondary_title} />
                <LogoInputComponent
                    placeholder={BLOG_LOCALS.search_bar_placeholder}
                    value={filterBlogText}
                    prefixIcon={<SearchIcon fontSize='large' className='text-baseGray600' />}
                    className={"py-[0.4rem] text-[20px] max-w-[514px]"}
                    handleOnChange={(e) => setFilterBlogText(e.target.value)}
                />
                {/* <div>
                    <TagSelection
                        activeTag={tag}
                        list_of_tags={BLOG_LOCALS.list_of_tags}
                        className=""
                        handleOnClick={(value) => setActiveTag(value)}
                    />
                </div> */}
            </div>
            {
                IS_BLOG_RENDERED.length === 0
                    ? (
                        <div className='flex flex-col justify-center items-center'>
                            <div className='flex gap-x-[0.4rem]'>
                                <ResponsiveHeadingFourInter text={BLOG_LOCALS.no_blog_found.text1} color={"#757575"} />
                                <ResponsiveHeadingFourInter text={filterBlogText + '.'} color={"#000000"} />
                            </div>
                            <ResponsiveHeadingFourInter className={"text-center"} text={BLOG_LOCALS.no_blog_found.text2} color={"#757575"} />
                        </div>
                    ) : (
                        <div className='grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[20px] p-[12px]'>
                            {
                                SORTED_BLOGS.map((blog, index) => {
                                    if (
                                        blog.blog_tag.includes(tag)
                                        &&
                                        (
                                            filterBlogText.trim().length === 0
                                                ? true
                                                : blog.title.toLowerCase().includes(filterBlogText.toLowerCase())
                                        )
                                    ) {
                                        return (
                                            <BlogCardMoreInfo
                                                key={index}
                                                image={BLOG_IMG_REFERENCE[blog.img_refernce]}
                                                heading={blog.title}
                                                btnName={blog.btn_name}
                                                handleOnClick={() => navigate(`/blogs/${blog.slug}`)}
                                                postedByName={blog.posted_by.person_name}
                                                postReadTimeLine={blog.posted_by.read_time}
                                            />
                                        )
                                    }
                                })
                            }
                        </div>
                    )
            }
            <div>
                <FooterAlertPage
                    locals={BLOG_LOCALS.subscribe_mail}
                    handleOnClick={() => {
                        if (validateEmail(email)) {
                            dispatch(API_Post_WebsiteForm({ data: { "email_address": email }, formType: "subscribe-new-blog" }));
                        } else {
                            message.open({ "type": "warning", "content": "Enter valid email" })
                        }
                    }}
                    buttonProps={{
                        disabled: api_status.status === "loading"
                    }}
                    isLoading={api_status.status === "loading"}
                >
                    {
                        api_status.status === "success"
                            ? (
                                <div className="bg-white rounded-md flex items-center justify-center">
                                    <div className="flex flex-col p-[12px]">
                                        <TextLarge text={BLOG_LOCALS.subscribe_mail.successfully_subscribed.text1} fontWeight={500} color={"#757575"} />
                                        <TextLarge text={BLOG_LOCALS.subscribe_mail.successfully_subscribed.text2} fontWeight={500} color={"#757575"} />
                                    </div>
                                    <button onClick={() => dispatch(D_Reset_WebsiteForm_Status())}>
                                        <CloseIcon className="text-[#757575] mx-[12px]" />
                                    </button>
                                </div>
                            ) : (
                                <LogoInputComponent
                                    placeholder={BLOG_LOCALS.subscribe_mail.email_address_placeholder}
                                    value={email}
                                    prefixIcon={<MailOutlinedIcon fontSize='medium' className='text-baseGray600' />}
                                    className={"py-[0.4rem] text-[20px] max-w-[514px]"}
                                    handleOnChange={(e) => setEmail(e.target.value)}
                                />
                            )
                    }
                </FooterAlertPage>
            </div>
        </div>
    )
}

export default Blog