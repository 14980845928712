import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour'
import SearchInput from '../../DesignSystem/Forms/InputVarients/SearchInput'
import bmLocals from '../../localization/localization_en_business_modal.json'
import { D_UpdateMyReportModeType, API_Fetch_Business_Modal_List, API_GET_REPORT_BUSINESS_MODAL_LIST, D_Reset_Screen_Screen_Api_Status } from "./businessModalSlice"
import PageLoader from '../reuseablecomponent/PageLoader';
import useOpenOopsPopup from './subcomponents/common/useOpenOopsPopup';
import WarningPopup from './subcomponents/common/WarningPopup';
import { useLocation, useNavigate } from "react-router-dom"
import { updateItemSelection } from '../../features/manageLinkSlice';
import MyReportMetricList from './components/MyReport/MyReportMetricList';
import MyReportViewAndDownload from './components/MyReport/MyReportViewAndDownload';


const MY_REPORT_LOCALS = bmLocals.business_modal.my_reports;

const BusinessModelMyReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const businessModel = useSelector((state) => state.businessModal)
    const { MODE, ACTIVE_BUSINESS_MODEL_ID, SELECTED_METRIC, SELECTED_METRIC_TABLE_DATA } = useSelector((state) => state.businessModal.MY_REPORTS);
    const [filteredTargetMetricText, setFilteredTargetMetricText] = React.useState("");
    const [filteredTargetCategoryText, setFilteredTargetCategoryText] = React.useState("");
    const my_report_api_status = useSelector((state) => state.businessModal.my_report_api_status)
    const { isModelOpen, handlePopUpOpen, handlePopUpClose } = useOpenOopsPopup();
    const [warningType, setWarningType] = React.useState("unknown_error")
    const FETCH_API_STATUS = my_report_api_status.get_report_bm_list
    // const MODE = 'DOWNLOAD';
    React.useEffect(() => {
        // dispatch(API_Fetch_Business_Modal_List());// api call to fetch all bm model { label: "", value: bmID, "markedAsActive": false/true }
        dispatch(API_GET_REPORT_BUSINESS_MODAL_LIST());
        // update global settings to fetch CURRENCY for label
        return () => {
            dispatch(D_UpdateMyReportModeType({ MODE_TYPE: "DETAILS", "SELECTED_METRIC": null }));
        }
    }, [])
    React.useEffect(() => {
        if (FETCH_API_STATUS.status === "success" && businessModel.my_report_list?.businessModelList?.length === 0) {
            setWarningType("no_business_model")
            handlePopUpOpen();
        }
        if (FETCH_API_STATUS.status === "failed") {
            setWarningType("unknown_error_refresh")
            handlePopUpOpen();
        }
    }, [FETCH_API_STATUS.status])
    React.useEffect(() => {
        setFilteredTargetMetricText("");
        setFilteredTargetCategoryText("");
    }, [MODE])
    const handleOnModeClick = (MODE_TYPE, SELECTED_METRIC) => {
        if (businessModel.my_report_list.reportBusinessModelList[SELECTED_METRIC].length === 0) {
            handlePopUpOpen();
            setWarningType("detailed_reports_access_warning");
            return;
        }
        dispatch(D_UpdateMyReportModeType({ MODE_TYPE, SELECTED_METRIC }));
        // dispatch(API_GET_REPORT_BUSINESS_MODAL_LIST(SELECTED_METRIC));
        if (MODE_TYPE === "VIEW") {
            navigate('/product/home/business-modal/my-report/view')
        } else if (MODE_TYPE === "DOWNLOAD") {
            navigate('/product/home/business-modal/my-report/download')
        }
        // api call to fetch table data on success render table MODE DATA;
    }
    const IS_LOADING = FETCH_API_STATUS.status === "loading"
    return (
        <div className='mt-[5rem]'>
            {/* title and search bar component */}
            {IS_LOADING ? <PageLoader /> : null}
            {isModelOpen
                ? (
                    <WarningPopup
                        isModelOpen={isModelOpen}
                        handlePopUpClose={() => {
                            if (warningType === "no_business_model") {
                                dispatch(updateItemSelection({ "activeItem": "2.1" }))
                                handlePopUpClose();
                                navigate('/product/home/business-modal')
                                return;
                            }
                            handlePopUpClose();
                        }}
                        warningType={warningType ? warningType : "unknown_error"} />
                )
                : null}
            <div className='flex justify-between items-center mb-[24px]'>
                <HeadingFour text={MY_REPORT_LOCALS.title} />
                <div className='w-[335px]'>
                    <SearchInput 
                        value={MODE === "DETAILS" ? filteredTargetMetricText : filteredTargetCategoryText}
                        onChangeEvent={(event) => MODE === "DETAILS" ? setFilteredTargetMetricText(event.target.value) : setFilteredTargetCategoryText(event.target.value)}
                    />
                </div>
            </div>
            {(MODE === "DETAILS" || location.pathname==="/product/home/business-modal/my-report")
                ? <MyReportMetricList
                    handleOnModeClick={handleOnModeClick}
                    filteredTargetMetricText={filteredTargetMetricText}
                />
                : <MyReportViewAndDownload
                    filteredTargetCategoryText={filteredTargetCategoryText}
                />
            }
        </div>
    )
}

export default BusinessModelMyReport