import React from 'react'
import { blogsList } from '../data/blogsList'
import { useLocation } from 'react-router-dom'
import TextSmall from '../../DesignSystem/TypographyDescriptions/TextSmall'
import ResponsiveHeadingFourInter from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter'
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium'
import useScrollToTopPage from '../../hooks/useScrollToTopPage'
import '../website.css'
import WebsiteHelmet from '../SEO/components/WebsiteHelmet'
import { blogAuthor } from '../../DesignSystem/Cards/website/BlogCardMoreInfo'
import ImageSrcHashComponent from '../components/ImageSrcHashComponent'

const IndividualBlog = () => {

    useScrollToTopPage();
    
    const pathname = useLocation()?.pathname?.split('/')

    const targetBlog = pathname[pathname.length - 1]

    const blog = blogsList?.find((blog) => blog.slug === targetBlog);

    const urlInjectedHTML = blog?.html?.replaceAll("{domain}", import.meta.env.VITE_REACT_WEBSITE_BLOG_PRE_DOMAIN_NAME)

    return (
        <div className='pt-[2rem] my-[2rem] px-[12px]'>
            <WebsiteHelmet
                title={blog?.title}
                description={blog?.seo_meta?.description}
                keywords={blog?.seo_meta?.keywords}
                previewImgUrl={blog?.iconSvg}
                ogUrl={window.location.href}
                imageWidth={920}
                imageHeight={608}
                author={blog?.content.author}
                publishedDate={blog?.created}
            />
            <TextMedium fontWeight={400} text={"Resources"} className={"bg-p6/60 py-1 px-3 rounded-full text-primary"} />
            <div className={"py-[1.2rem] flex flex-col gap-y-[1rem]"}>
                <ResponsiveHeadingFourInter text={blog?.title} />
                <div className='flex items-center gap-x-[0.75rem]'>
                    <img className='w-[1.5rem] rounded-full' src={blogAuthor[blog?.content.author]} alt="" />
                    <TextMedium fontWeight={400} text={blog?.content.author} />
                    <div className='w-[5px] h-[5px] rounded-full bg-black'></div>
                    <TextMedium fontWeight={400} text={blog?.content.duration} />
                    <div className='w-[5px] h-[5px] rounded-full bg-black'></div>
                    <TextMedium fontWeight={400} text={blog?.created?.getDate() + '/' + blog?.created?.getMonth() + '/' + blog?.created?.getFullYear()} />
                </div>
            </div>
            <div className='w-full space-y-8 pt-9'>
                <ImageSrcHashComponent
                    src={blog?.blogImage?.img}
                    placeholderImage={blog?.blogImage?.placeholder_img}
                    className={'max-h-[660px] aspect-video w-full rounded-[1rem] overflow-hidden'}
                />
                <article
                    className='prose max-w-full blog_article_font lg:px-[5rem]'
                    dangerouslySetInnerHTML={{ __html: urlInjectedHTML }}
                />
                <br/>
                <br/>
            </div>
        </div>
    )
}

export default IndividualBlog