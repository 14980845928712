import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const loader = (
    <LoadingOutlined
        style={{
            fontSize: 62,
            fontWeight: "bolder"
        }}
        spin
    />
);

const PageLoader = ({ spinnerColor = "#372EE5" }) => {
    return (
        <div className='w-full h-full bg-black/20 fixed top-0 right-0 z-[50]'>
            <div className='absolute left-1/2 top-1/2 -translate-x-1/2'>
                <Spin indicator={loader} style={{color:spinnerColor}} />
            </div>
        </div>
    )
}

export default PageLoader