import React from 'react'

const CloseSVG = ({ color }) => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10780_152622)">
                <path d="M10.25 3.705L9.545 3L6.75 5.795L3.955 3L3.25 3.705L6.045 6.5L3.25 9.295L3.955 10L6.75 7.205L9.545 10L10.25 9.295L7.455 6.5L10.25 3.705Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_10780_152622">
                    <rect width="12" height="12" fill="white" transform="translate(0.75 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default CloseSVG