import React from 'react'
import HeadingTwo from '../../DesignSystem/TypographyHeadings/HeadingTwo';
import PGButtonComponent from '../../DesignSystem/Buttons/PGButtonComponent';
import { useNavigate } from 'react-router-dom'
import ResponsiveHeadingTwoFive from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingTwoFive';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const FooterAlertPage = ({ locals, handleOnClick, children, buttonProps, isLoading }) => {

    const navigate = useNavigate();
    const { title, button } = locals;

    return (
        <div className='xl:p-[48px] p-[20px] flex flex-col sm:gap-y-[40px] gap-y-[10px] justify-center items-center bg-maindarkShade1'>
            <ResponsiveHeadingTwoFive text={title} color={"#FFFFFF"} className="text-center" />
            {children ?? null}
            <PGButtonComponent {...buttonProps} onClick={() => button.path_link ? navigate(button.path_link) : handleOnClick()}  className={'bg-white text-maindarkShade1 w-fit'}>
                {isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, marginRight: "1rem" }} spin />} /> : null}
                {button.name}
            </PGButtonComponent>
        </div>
    )
}

export default FooterAlertPage