import { Steps } from 'antd';
import { stages } from '../data';
import { useState, useEffect } from 'react';
import { Select } from 'antd';
import { Button, DatePicker, Form, Input, Modal, Radio } from 'antd';
import { message } from 'antd';
import leftarrow from '../assets/Onboarding/leftarrow.svg';
import rightarrow from '../assets/Onboarding/rightarrow.svg';
import faderightarrow from '../assets/Onboarding/faderightarrow.svg';
import rightArrowSkip from '../assets/Onboarding/rightArrowNew.svg';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import blue from "../assets/Onboarding/blue.svg";
import yellow from "../assets/Onboarding/yellow.svg";
import green from "../assets/Onboarding/green.svg";
import { validateEmail } from '../utils/validation';
import { resetStatusOnRefresh, toggleInputMobile, resetLevel1details, patchOnboardingDetails, updatecommingFromSetting, setInputValue, updateStepper, updateBusinessDuration, updatelevel1Details, updateMoreDetails, updateShowWarning, handleModelOpen, onboardingApi, organisationApi, onboardingTeamInvite, deleteEmails, checkForExistanceOfEmail, bulkInviteUsers, onboardingdeleteuser, fetchOnboardingDetails } from '../features/onboardingSlice';
import logo from '../assets/Header/logotest.svg'
import Plus from '../assets/Onboarding/Plus.svg'
import arrow from "../assets/common/arrow.svg";
import plus from "../assets/common/miniPlus.svg";
import leftArrow from "../assets/common/leftArrow.svg"
import "./auth/onboarding.css"
import close from "../assets/common/close.svg";
import { CheckCircleOutlined } from "@ant-design/icons";
import rightArrow from "../assets/common/rightArrow.svg";
import fadeRightArrow from "../assets/common/fadeRightArrow.svg";
const { Step } = Steps;
import TNC from "../assets/docs/Terms_and_Conditions.pdf";
import PP from "../assets/docs/Privacy_Policy.pdf";
import { MAX_USERS_IN_TEAM } from "../data"
import EditIcon from '@mui/icons-material/Edit';

import '../index.css'
import MessageNotification from '../components/reuseablecomponent/MessageNotification';
import ButtonComponent from '../components/reuseablecomponent/ButtonVarients/ButtonComponent';
import UpgradePlanOnboarding from '../DesignSystem/Modals/UpgradePlanOnboarding';
import onboardingLocals from "../localization/localization_en_onboarding.json";
import footerLocals from "../localization/localization_en_footer.json";
import PageSpinner from '../components/reuseablecomponent/PageSpinner';
import { updateOnboardingComplete } from '../features/userSlice';

const AntdOnboarding = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [count, setCount] = useState(0);
    const [editCompanyName, setEditCompanyName] = useState(true);
    const loading = useSelector((state) => state.onboarding?.loading);
    const user = useSelector((state) => state.user.value?.email)
    const onboarding = useSelector((state) => state.onboarding?.value);
    const status = useSelector((state) => state.onboarding?.status);
    const orgStatus = useSelector((state) => state.onboarding?.orgStatus);
    const teamStage = useSelector((state) => state.onboarding?.teamDetails);
    const showInputForMobile = useSelector((state) => state.onboarding?.showInputForMobile);
    const [inviteClicked, setInviteClicked] = useState(false);
    const [current, setCurrent] = useState(onboarding?.currentStage);
    const [placeholder, setPlaceholder] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const text = "Company Name"
    const users = [blue, green, yellow];
    const bulkInviteUserStatus = useSelector((state) => state.onboarding.isBulkInviteExceeded);
    const BtnCondition = (onboarding["currentStage"] === 1) ? onboarding?.BusinessStage == null ? true : false : false;
    // console.log(BtnCondition)
    const controlEnableDisable = (onboarding["currentStage"] === 0) ? !((onboarding.Company?.trim().length == 0) ? false : true) : BtnCondition;
    const [form] = Form.useForm();
    const commingFromSetting = useSelector((state) => state.onboarding?.commingFromSetting)
    const noOfUsers = useSelector((state) => state.onboarding?.noOfUsers);
    const error = useSelector((state) => state.onboarding?.error);
    const [isShowUpgrade, setIsShowUpgrade] = useState(false)
    const fetchTeamDetailsStatus = useSelector((state) => state.onboarding.fetchTeamDetailsStatus);

    useEffect(() => {
        dispatch(fetchOnboardingDetails());
    },[])

    useEffect(() => {
        if (commingFromSetting) {
            return;
        }
        if (status === "succeeded" && onboarding.currentStage>2) {
            dispatch(updateOnboardingComplete(true));
            navigate('/product/welcome')
        } else if (status === "failed") {
            messageApi.open({
                type: 'error',
                content: onboardingLocals.onboarding.error_message.onboarding_process_failed,
            });
        }
    }, [status])

    useEffect(() => {
        form.setFieldsValue({
            Company: onboarding.Company,
            BusinessStage: onboarding.BusinessStage,
        });
    }, [onboarding.currentStage, form]);

    useEffect(() => {
        if (teamStage.moreSubDetails.showWarning) {
            const warningTimer = setTimeout(() => {
                dispatch(updateShowWarning());
            }, 3000)
            messageApi.open({
                type: 'warning',
                content: onboardingLocals.onboarding.warning_message.fill_input,
            });
        }
    }, [teamStage.moreSubDetails?.showWarning])
    // useEffect(() => {
    //     let index = -1;
    //     const interval = setInterval(() => {
    //         index++;
    //         setPlaceholder((prev) => prev + text[index]);
    //         if (index === text.length) {
    //             index = -1;
    //             setPlaceholder('');
    //         }
    //     }, 400);
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);
    useEffect(() => {
        dispatch(resetStatusOnRefresh());
        return () => {
            dispatch(updatecommingFromSetting(false));
            dispatch(resetLevel1details());
            dispatch(updateStepper(onboarding?.currentStage - 1));
        }
    }, [])

    useEffect(() => {
        if (orgStatus === "succeeded" && onboarding?.currentStage === 0) {
            dispatch(updateStepper(onboarding?.currentStage + 1));
        }
    }, [orgStatus])

    const handleOnboardingComplete = () => {
        dispatch(onboardingApi());
    }


    const next = () => {
        if (onboarding?.currentStage === 0) {
            dispatch(organisationApi({ fieldName: "Company" }));
        } else {
            if(onboarding?.currentStage < 2) {
                dispatch(updateStepper(onboarding?.currentStage + 1));
            }else{
                if(commingFromSetting){
                    navigate('/product/home/settings')
                }else{
                    handleOnboardingComplete();
                    navigate('/product/welcome')
                }
            }
        }
    }
    const prev = () => {
        dispatch(updateStepper(onboarding.currentStage - 1));
    }
    const checkUserEmail = (email) => {
        const isValid = validateEmail(email);
        console.log(email === user, "l1");
        if (email === user) {
            return false;
        } else {
            return true;
        }
    }
    const checksubDetails = (email) => {
        if (teamStage.subDeatils?.email == email) {
            return false;
        }
        return true;
    }
    const checkMoreSubDetailsArr = (email) => {
        const isFound = teamStage.moreSubDetails?.data?.find((ele) => {
            if (ele?.email == email) {
                return email;
            }
        })
        if (isFound) {
            return false;
        } else {
            return true;
        }
    }
    const checkForUserExistance = (email, type) => {
        if (type === "desktop1") {
            console.log(checkUserEmail(email) && checkMoreSubDetailsArr(email))
            return checkUserEmail(email) && checkMoreSubDetailsArr(email)
        } else {
            console.log("desktop2")
            return (checkUserEmail(email) && checksubDetails(email) && checkMoreSubDetailsArr(email));
        }
    }
    const handleInviteAll = () => {
        dispatch(bulkInviteUsers());
    }
    console.log(onboarding.currentStage,"stage",showInputForMobile)
    const listOfTeamMembersEmail = Array.from(new Set(teamStage.moreSubDetails.data.map((user) => user.email)))
    return (
        <div className='min-h-[90vh] md:min-h-[100vh] flex flex-col items-center justify-between md:justify-between relative mx-auto my-auto'>
            {contextHolder}
            {fetchTeamDetailsStatus==="pending" && <PageSpinner/>}
            <UpgradePlanOnboarding isShowUpgrade={isShowUpgrade} handleOnCloseModal={() => setIsShowUpgrade(false)} />
            {orgStatus === "failed" || error?.length > 0 && <MessageNotification type={"error"} content={error} status={(onboarding.currentStage === 0) ? orgStatus : loading} />}
            <header className='w-full md:pt-[48px] hidden md:px-[64px] md:flex justify-between'>
                <img onClick={() => navigate('/')} src={logo} className="w-[138px] h-[21px]" alt="" />
                {/* <Button className='text-primary bg-[#F5F5F5] border-none text-[14px] font-semibold font-InterRegular py-[12px] px-[28px] md:block hidden h-auto rounded-[18px]'>{onboardingLocals.onboarding.help_button_text}</Button> */}
            </header>
            <div className='flex flex-col lg:w-[85%] w-[100%] md:px-0'>
                <div className='stepper hidden md:flex justify-center'>
                    <div className='w-[50%]'>
                        <Steps current={onboarding.currentStage}> {/* Set the current step to 2 (zero-based index) */}
                            {onboardingLocals.onboarding.onboarding_question.map((item, index) => (
                                <Step
                                    key={index}
                                    title={item.title}
                                    icon={
                                        onboarding.currentStage > index ? <CheckCircleOutlined style={{ color: "#fff", backgroundColor: "#4ADE80", borderRadius: "100%", fontSize: "33px" }} /> : undefined
                                    } />
                            ))}
                        </Steps>
                    </div>
                </div>
                <div>
                    <header className='md:hidden block w-full px-[24px]'>
                        <div className='flex justify-between my-[18px]'>
                            <button className={onboarding.currentStage===0 ? 'invisible' : 'visible'} onClick={() => prev()}><img src={arrow} alt="" className='' /></button>
                            {onboarding.currentStage === 2 && <Button onClick={() => {
                                // handleOnboardingComplete();
                                next();
                                navigate("/product/onboarding/complete",{ replace: true });
                            }} type='link' className='text-primary p-0'>{!teamStage.moreSubDetails.data.length ? onboardingLocals.onboarding.skip_button_text : "Finish"}</Button>}
                        </div>
                        <div className='flex justify-between items-center'>
                            <div>
                                <p className='text-[#757575] font-InterRegular text-[12px] tracking-wide'>Step {onboarding.currentStage + 1}/3</p>
                                <p className='text-[#111827] font-InterRegular text-[14px] font-medium tracking-wide'>{onboardingLocals.onboarding.onboarding_question[onboarding.currentStage]?.title}</p>
                            </div>
                            <div>
                                <span className={`${onboarding["currentStage"] == 2 ? 'block' : ' hidden '}text-[#BDBDBD] font-InterRegular text-[12px] tracking-wide`}>{noOfUsers === 0 ? onboardingLocals.onboarding.status.partial : onboardingLocals.onboarding.status.completed}</span>
                                <span className={`${onboarding["currentStage"] == 2 ? 'hidden ' : 'block'}text-[#BDBDBD] font-InterRegular text-[12px] tracking-wide`}>{onboardingLocals.onboarding.status.start}</span>
                            </div>
                        </div>
                    </header>
                    <div className={`stepper relative h-[3px] w-[100%] md:hidden block bg-[#757575] mb-[40px]`}>
                        <div style={{ width: `${((onboarding.currentStage)/3)*100}%` }} className={`absolute h-[3px] bg-primary`}></div>
                    </div>
                    <div className='flex justify-center w-[100%] items-center md:justify-between px-[24px]'>
                        <div className='md:w-[75%] w-full md:min-h-[345px] flex flex-col md:mt-[62px]'>
                            <div className='title'>
                                <h2 className='font-DMSans md:text-[24px] font-bold text-[18px] mb-[32px]'>{onboardingLocals.onboarding.onboarding_question[onboarding.currentStage]?.label}</h2>
                            </div>
                            <div className='w-full'>
                                {
                                    stages[onboarding.currentStage]?.userinput.map((input, index) => {
                                        if (input.type === "input") {
                                            return <div key={index}
                                                className='w-[75%]'
                                                // name="Company"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: ''
                                                //     },
                                                // ]}
                                            >
                                                <Input 
                                                    disabled={editCompanyName} 
                                                    suffix={<EditIcon onClick={() => setEditCompanyName(false)} 
                                                    className='text-primary cursor-pointer' />} 
                                                    style={{ borderRadius: "16px" }} 
                                                    value={onboarding.Company} 
                                                    name='Company' 
                                                    onChange={(e) => dispatch(setInputValue(e.target.value))} 
                                                    placeholder={onboardingLocals.onboarding.onboarding_question[onboarding.currentStage].placeholder} 
                                                    className='md:py-2 resp_onb_inp sm:py-1 sm:h-[32px] md:h-[64px] font-InterRegular md:border text-xl'
                                                    onBlur={() => setEditCompanyName(true)}
                                                />
                                            </div>
                                        }
                                        if (input.type === "button") {
                                            return (
                                                <div key={index}>
                                                    <div className='md:w-[75%]'>
                                                        <Select className='onboardingSelect' value={onboarding.BusinessStage} onChange={(value) => dispatch(updateBusinessDuration(value))} placeholder={onboardingLocals.onboarding.onboarding_question[onboarding.currentStage].placeholder} style={{ width: '10rem' }}
                                                            options={onboardingLocals.onboarding.onboarding_question[onboarding.currentStage].dropdown_options}></Select>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        if (input.type === "customizedInput") {
                                            return (
                                                <div key={index} className='w-[100%]'>
                                                    <div className='md:flex justify-between hidden'>
                                                        <Input disabled={teamStage?.subDeatils?.buttonClicked || noOfUsers === 5 || loading === "loading"} className={`${(teamStage?.subDeatils?.email?.length > 0) && (!teamStage?.subDeatils?.invite && 'customAntdInputOnboardingFailed')} teamInput font-InterRegular tracking-wide`} onChange={(e) => dispatch(updatelevel1Details({ type: "email", value: e.target.value }))} value={teamStage?.subDeatils?.email} placeholder={onboardingLocals.onboarding.onboarding_question[onboarding.currentStage].placeholder} />
                                                        <div className='h-[2.5rem] mx-2 md:block hidden'>
                                                            <Select size={"large"} className='border-primary antdSelect'
                                                                disabled={true}
                                                                options={onboardingLocals.onboarding.onboarding_question[onboarding.currentStage].dropdown_options}
                                                                value={(teamStage?.subDeatils?.role != null) ? "user" : "User"} />
                                                        </div>
                                                        <Button loading={loading === "loading"} disabled={!validateEmail(teamStage.subDeatils.email) || noOfUsers === 5 || loading === "loading" ? true : false} onClick={() => {
                                                            if (checkForUserExistance(teamStage?.subDeatils?.email, "desktop1")) {
                                                                dispatch(onboardingTeamInvite({ email: teamStage?.subDeatils?.email, role: "user", commingFrom: "level1" }));
                                                                if (count === 3) {
                                                                    setCount(0);
                                                                } else {
                                                                    setCount(prev + 1)
                                                                }
                                                            } else {
                                                                message.open({
                                                                    type: 'warning',
                                                                    content: onboardingLocals.onboarding.warning_message.already_exist
                                                                })
                                                            }
                                                        }} className={`${(teamStage?.subDeatils?.buttonClicked) ? 'bg-[#4ADE80] pointer-events-none text-white' : 'border-primary text-primary'} p-[16px] h-auto px-[53px] rounded-[16px] text-[16px] font-InterRegular`}>{teamStage?.subDeatils?.buttonClicked ? onboardingLocals.onboarding.invitationStatus.Invited : onboardingLocals.onboarding.invitationStatus.not_invited}</Button>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <div className='flex justify-end'>
                                                                <Button disabled={showInputForMobile || noOfUsers <= 0 || noOfUsers == 5} onClick={() => {
                                                                    dispatch(toggleInputMobile());
                                                                }} type='link' className='text-primary md:hidden flex items-center justify-center font-InterRegular text-[10px] tracking-wide'>
                                                                    <img src={plus} alt="" className='mr-[8px]' />
                                                                    <span className='text-primary'>{onboardingLocals.onboarding.invite_more_button_text}</span>
                                                                </Button>
                                                            </div>
                                                            <ul className='rounded-[12px] md:hidden block'>
                                                                {
                                                                    teamStage.moreSubDetails.data.map((d, i) => {
                                                                        return (
                                                                            <li key={i} className='mb-[12px]'>
                                                                                <div className='flex justify-between'>
                                                                                    <input className='w-full' type="text" value={d?.email} disabled={true} style={{ backgroundColor: "transparent", padding: "10px 12px" }} />
                                                                                    <button><img src={close} alt="" onClick={() => dispatch(onboardingdeleteuser({ i, email: d.email })) } /></button>
                                                                                </div>
                                                                                {/* <Radio.Group value={d?.role}>
                                                                                    {
                                                                                        onboardingLocals.onboarding.onboarding_question[onboarding.currentStage].dropdown_options.map((option, i) => {
                                                                                            return <Radio value={option.value}>{option.label}</Radio>
                                                                                        })
                                                                                    }
                                                                                </Radio.Group> */}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        {
                                                            (showInputForMobile) && (
                                                                <div className=" md:hidden block">
                                                                    <Input
                                                                        disabled={noOfUsers === 5}
                                                                        style={{ backgroundColor: "transparent", border: "1px solid #96ADFF", borderRadius: "12px", padding: "10px 12px", fontSize: "13px" }}
                                                                        onChange={(e) => dispatch(updateMoreDetails({ type: "email", value: e.target.value }))}
                                                                        value={teamStage?.moreSubDetails?.email}
                                                                        placeholder={onboardingLocals.onboarding.onboarding_question[onboarding.currentStage].placeholder} />
                                                                    {/* <div className='flex justify-between'>
                                                                        <Radio.Group disabled={noOfUsers === 5} value={teamStage?.moreSubDetails?.role} onChange={(e) => dispatch(updateMoreDetails({ type: "role", value: e.target.value }))}>
                                                                            {
                                                                                onboardingLocals.onboarding.onboarding_question[onboarding.currentStage].dropdown_options.map((option, i) => {
                                                                                    return <Radio value={option.value}>{option.label}</Radio>
                                                                                })
                                                                            }
                                                                        </Radio.Group>
                                                                    </div> */}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                <button type='link' disabled={!teamStage?.subDeatils?.buttonClicked} onClick={() => {
                                    if (teamStage?.subDeatils?.buttonClicked) {
                                        dispatch(handleModelOpen({ data: true }));
                                    }
                                }} className={`${(onboarding["currentStage"] == 2) ? 'md:flex hidden' : 'hidden'} mx-auto rounded-[8px] items-center bg-[#FAFAFA] py-[8px] px-[36px] text-primary mt-[96px] mb-[40px] ext-[#372EE5]`}>
                                    <span className='text-[16px]'>{onboardingLocals.onboarding.invite_more_button_text}</span>
                                    <img src={Plus} alt="" />
                                </button>
                                <Modal
                                    footer={null}
                                    title=""
                                    centered
                                    width={746}
                                    open={teamStage?.modelOpen}
                                    onOk={() => dispatch(handleModelOpen({ data: false }))}
                                    onCancel={() => dispatch(handleModelOpen({ data: false }))}>
                                    <div className='flex justify-between items-start'>
                                        <div>
                                            <p style={{ margin: 0 }} className='text-[#111827] font-InterRegular text-[18px] font-medium tracking-wide'>{onboardingLocals.onboarding.expand_team_modal.main_title}</p>
                                            <p className='text-[#757575] font-InterRegular text-[14px] font-medium tracking-wide'>{onboardingLocals.onboarding.expand_team_modal.description}</p>
                                        </div>
                                        <div>
                                            <button onClick={() => dispatch(handleModelOpen(false))}><img src={close} alt="" /></button>
                                        </div>
                                    </div>
                                    <div>
                                        <Input
                                            disabled={noOfUsers === 5 || !teamStage?.subDeatils?.buttonClicked}
                                            className={`${(teamStage?.moreSubDetails?.email?.length > 0) && (!teamStage?.moreSubDetails?.invite && 'customAntdInputOnboardingFailed')} teamInput`}
                                            onChange={(e) => dispatch(updateMoreDetails({ type: "email", value: e.target.value }))}
                                            value={teamStage?.moreSubDetails?.email}
                                            placeholder={onboardingLocals.onboarding.expand_team_modal.form.placeholder} />
                                        <div className='flex justify-end'>
                                            {/*<Radio.Group disabled={true} value={"user"}>
                                            <Radio value={"admin"}>Admin</Radio>
                                            <Radio value={"user"}>User</Radio>
                                        </Radio.Group>*/}
                                            <Button loading={loading === "loading"} disabled={noOfUsers === 5 || !validateEmail(teamStage?.moreSubDetails?.email) || loading === "loading"}
                                                className='border border-primary px-[40px] py-[12px] text-primary rounded-[16px] text-[16px] mt-[8px] h-auto' onClick={() => {
                                                    if (checkForUserExistance(teamStage?.moreSubDetails?.email, "desktop2")) {
                                                        // dispatch(onboardingTeamInvite({ email: teamStage?.moreSubDetails?.email, role: teamStage?.moreSubDetails?.role, commingFrom:"level2" }));
                                                        dispatch(checkForExistanceOfEmail({ email: teamStage?.moreSubDetails?.email, role: teamStage?.moreSubDetails?.role, commingFrom: "level2" }));
                                                    } else {
                                                        message.open({
                                                            type: 'warning',
                                                            content: onboardingLocals.onboarding.warning_message.already_exist
                                                        })
                                                    }
                                                }}>{onboardingLocals.onboarding.expand_team_modal.add_button_text}</Button>
                                            <Button onClick={() => {
                                                // message.open({
                                                //     type: 'warning',
                                                //     content: "Please contact us to add more users"
                                                // })
                                                setIsShowUpgrade(true);
                                            }} className='border border-primary ml-[1rem] px-[40px] py-[12px] text-primary rounded-[16px] text-[16px] mt-[8px] h-auto'>{onboardingLocals.onboarding.expand_team_modal.upgrade_button_text}</Button>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <div className='flex justify-between items-center'>
                                            <p className='text-[#111827] font-InterRegular text-[14px] tracking-wide font-medium'>{onboardingLocals.onboarding.expand_team_modal.invitees}</p>
                                            <p>{onboardingLocals.onboarding.invitationStatus.Invited} {noOfUsers} / {MAX_USERS_IN_TEAM} {onboardingLocals.onboarding.expand_team_modal.users_description_text}</p>
                                        </div>
                                        <ul className='bg-[#FAFAFA] rounded-[12px] overflow-scroll p-[12px] h-[200px]'>
                                            <li key="initialEmail" className='flex justify-between mb-[12px]'>
                                                <div className='flex items-center justify-center'>
                                                    <img src={users[0]} alt="" />
                                                    <p style={{ margin: 0 }} className='font-InterRegular pl-[4px] font-medium tracking-wide text-[13px]'>{teamStage?.subDeatils?.email}</p>
                                                </div>
                                                <div className='flex justify-center items-center'>
                                                    <p style={{ margin: 0 }} className='text-[#372EE5] font-InterRegular tracking-wide text-[13px] font-normal'>{onboardingLocals.onboarding.user_type.user}</p>
                                                    <button className='invisible' disabled={true}><img src={close} alt="" /></button>
                                                </div>
                                            </li>
                                            {
                                                teamStage.moreSubDetails.data.map((d, i) => {
                                                    return (
                                                        <li key={i} className='flex justify-between mb-[12px]'>
                                                            <div className='flex items-center justify-center'>
                                                                <img src={users[i % 3]} alt="" />
                                                                <p style={{ margin: 0 }} className='font-InterRegular pl-[4px] font-medium tracking-wide text-[13px]'>{d.email}</p>
                                                            </div>
                                                            <div className='flex justify-center items-center'>
                                                                <p style={{ margin: 0 }} className='text-[#372EE5] font-InterRegular tracking-wide text-[13px] font-normal'>{d.role === "user" ? onboardingLocals.onboarding.user_type.user : onboardingLocals.onboarding.user_type.user}</p>
                                                                <button onClick={() => {
                                                                    dispatch(onboardingdeleteuser({ i, email: d.email }));
                                                                }}><img src={close} alt="" /></button>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className='flex justify-end'>
                                            <Button onClick={handleInviteAll} disabled={(bulkInviteUserStatus && noOfUsers === 5) || loading == "loading"} className='border border-primary px-[40px] py-[12px] text-primary rounded-[16px] mt-[8px] h-auto text-[16px] font-medium'>{(bulkInviteUserStatus) ? onboardingLocals.onboarding.expand_team_modal.invited_btn_text : onboardingLocals.onboarding.expand_team_modal.invite_all_btn_text }</Button>
                                        </div>
                                        {/*<p>Please <a className='text-blue-500'>contact us</a> to add more than 5 users</p>*/}
                                    </div>
                                </Modal>
                                <div className={`md:flex hidden mt-[48px] ${onboarding.currentStage !== 2 ? 'md:w-[75%]' : 'w-full'} ${onboarding.currentStage !== 0 ? 'justify-between' : 'justify-end'}`}>
                                    {onboarding.currentStage > 0 && (
                                        <Button
                                            type='link'
                                            className='flex items-center antdPrevious'
                                            onClick={() => {
                                                if (commingFromSetting) {
                                                    navigate(-1);
                                                } else {
                                                    prev();
                                                }
                                            }}
                                        >
                                            <img src={leftArrow} alt='' className='' /><p style={{ margin: 0 }} className='text-[#111827]'>{onboardingLocals.onboarding.button_controls.back}</p>
                                        </Button>
                                    )}
                                    {onboarding.currentStage < stages.length - 1 && (
                                        <Button
                                            type="link"
                                            style={{ paddingRight: 0 }}
                                            loading={orgStatus === "loading"}
                                            disabled={controlEnableDisable || orgStatus === "loading"}
                                            className="flex items-center justify-center relative"
                                            onClick={() => {
                                                if (commingFromSetting) {
                                                    dispatch(patchOnboardingDetails({ fieldName: "BusinessStage" }));
                                                    navigate(-1);
                                                } else {
                                                    next();
                                                }
                                            }}
                                        >
                                            <p style={{ margin: 0 }} className={`${controlEnableDisable ? 'text-[#c4c1f7]' : 'text-primary'} font-semibold ml-2`}>
                                                {commingFromSetting ? onboardingLocals.onboarding.button_controls.done : onboardingLocals.onboarding.button_controls.next}
                                            </p>
                                            <img src={controlEnableDisable ? fadeRightArrow : rightArrow} alt="" />
                                        </Button>

                                    )}
                                    {onboarding.currentStage === stages.length - 1 && (
                                        <button className='flex items-center justify-center text-[#372EE5]' onClick={() => {
                                            if (commingFromSetting) {
                                                navigate('/product/home/settings');
                                            } else {
                                                message.success(onboardingLocals.onboarding.success_message);
                                                next();
                                                // handleOnboardingComplete();
                                            }
                                        }}>
                                            <span>{(teamStage?.subDeatils?.email || commingFromSetting) ? onboardingLocals.onboarding.button_controls.done : onboardingLocals.onboarding.button_controls.skip}</span>
                                            <img src={rightArrowSkip} alt="" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="onbaordingimage w-[45%] pt-[35px] lg:w-[50%] hidden md:flex justify-center">
                            <img src={stages[onboarding.currentStage]?.img} alt="" className='' />
                        </div>
                    </div>
                </div>
            </div>
            <Button style={{ display: onboarding.currentStage===2 ? "block" : "none" }} disabled={noOfUsers === 5} className={`${(showInputForMobile) ? 'bg-[#EBF1FF]' : 'bg-primary text-white'} css_hide_stage3_large md:hidden w-[94%] text-primary font-InterRegular text-[14px] font-medium tracking-wide rounded-[12px] border-none py-[10px] h-auto`} onClick={() => {
                const check = checkForUserExistance(teamStage?.moreSubDetails?.email, "desktop2")
                if (validateEmail(teamStage?.moreSubDetails?.email) && teamStage?.moreSubDetails?.role?.length > 0 && check) {
                    dispatch(toggleInputMobile());
                    dispatch(onboardingTeamInvite({ email: teamStage?.moreSubDetails?.email, role: teamStage?.moreSubDetails?.role, commingFrom: "level2" }));
                } else {
                    message.open({
                        type: 'warning',
                        content: onboardingLocals.onboarding.warning_message.already_exist
                    })
                }
            }}>{(showInputForMobile) ? onboardingLocals.onboarding.invitationStatus.not_invited : onboardingLocals.onboarding.invitationStatus.Invited}</Button>
            <Button style={{ display: onboarding.currentStage<=1 ? "block" : "none" }} disabled={controlEnableDisable} onClick={() => {
                next()
            }} className={`${onboarding.currentStage === 2 && 'hidden'} css_hide_stage2_large md:hidden block w-[94%] text-primary font-InterRegular text-[14px] font-medium tracking-wide rounded-[12px] bg-[#EBF1FF] border-none py-[10px] h-auto`}>{onboardingLocals.onboarding.button_controls.next}</Button>
            <footer className='w-full hidden font-InterRegular text-[12px] text-[#9E9E9E] px-[64px] pb-[10px] md:flex justify-between'>
                <div><p>{footerLocals.footer.copyright_text}</p></div>
                <div>
                    <div className='flex'>
                    <button onClick={() => navigate('/refund-policy')} className='mr-[24px]'>{footerLocals.footer.refund_policy}</button>
                    <button onClick={() => navigate('/terms-and-conditions')} className='mr-[24px]'>{footerLocals.footer.terms_and_condition_text}</button>
                    <button onClick={() => navigate('/privacy-policy')}>{footerLocals.footer.privacy_policy_text}</button>
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default AntdOnboarding;
