import React from 'react'
import { InputNumber } from "antd";
import '../../ds.style.css';

const NumericInput = ({ size = "large", placeholder, value, name, onChangeEvent, onBlurEvent, isDisabled, isReadyOnly, isRequired, minNumber, maxNumber, borderColor, status, styles }) => {
    return (
        <InputNumber
            type='number'
            size={size}
            min={minNumber}
            max={maxNumber}
            style={{ borderRadius: '6px', border: borderColor ? `1px solid ${borderColor}` : "none" }}
            status={status}
            placeholder={placeholder}
            name={name}
            className={borderColor ? null : "custom_inp"}
            value={value}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadyOnly}
            onChange={onChangeEvent}
            onBlur={onBlurEvent}
            controls={false}
        />
    )
}

export default NumericInput