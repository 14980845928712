export const paramsMap = () => {
    try {
        const path = window.location.href?.split('?')?.[1]?.split('&');
        let paramsMap = {
            "url": window.location.href
        };
        path?.map((params) => {
            if(params?.includes('=')){
                const param = params?.split("=");
                paramsMap  ={
                    ...paramsMap,
                    [param[0]]: param[1]
                }
            }
        })
        return paramsMap;
    } catch (error) {
        console.log(error);
        return {};
    }
}