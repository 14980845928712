import React from 'react'
import dayjs from 'dayjs';
import { DatePicker, message } from 'antd';
import { useDispatch, useSelector } from "react-redux"
import CustomNumber from '../ROFormComponents/components/CustomNumber'
import StaticText from '../../components/CostOnboarding/microcomponents/StaticText'
import SelectOption from '../../../../DesignSystem/Forms/SelectOption'
import { D_Capture_Value_WorkingCapital } from "../../workingCapitalSlice";
import { D_Capture_Value_DebtWorking } from "../../debtWorkingSlice";
import { D_Capture_Value_CashFlow } from "../../cashFlowSlice"
import { D_Capture_Value_BalanceSheet } from "../../balanceSheetSlice"
import { ValidateNumber } from '../../components/CostOnboarding/utils/ValidateNumber';
import { D_Capture_Value_Working_Cap_Prod_Catg } from '../../revenueSlice';
import { numberValidation } from '../../../../utils/numberValidation';
import appLocals from '../../../../localization/localization_en_app.json'

const Validation_Warning_Message = appLocals.app.warning_message


const RenderComponent = ({ column, value, component_type, decimal_place, tableName, rowIndex, data_index, validator, columnIndex, module, isReadOnly, fiscal_year, month_year }) => {
    // const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const decimalPlace = useSelector((state) => state.globalSetting.active_global_settings.decimal_places)
    const TransmitData = ({ latestValue, id }) => {
        if(module==='working_capital'){
            dispatch(D_Capture_Value_WorkingCapital({ "tableName": tableName, "value": latestValue, "rowNumber": rowIndex, "data_index": data_index }))
        }else if(module==='debt_working'){
            dispatch(D_Capture_Value_DebtWorking({ "tableName": tableName, "value": latestValue, "rowNumber": rowIndex, "data_index": data_index, id }))
        }else if(module==='cash_flow'){
            dispatch(D_Capture_Value_CashFlow({ "tableName": tableName, "value": latestValue, "rowNumber": rowIndex, "data_index": data_index }))
        }else if(module==='balance_sheet'){
            dispatch(D_Capture_Value_BalanceSheet({ "tableName": tableName, "value": latestValue, "rowNumber": rowIndex, "data_index": data_index }))
        }
    }
    switch (component_type) {
        case 'number_input': {
            const placeHolder = column.placeHolder
            return (
                <>
                {/* {contextHolder} */}
                {/* {validator} */}
                <CustomNumber
                    name={column.name}
                    placeholder={placeHolder}
                    isReadOnly={isReadOnly}
                    performActionOnClick={() => message.warning('Please select the loan name first, then enter the value.')}
                    value={value}
                    handleOnBlur={(event) => {
                        const numberValue = event.target.value;
                        if (["positive_decimal_number", "positive_negative_decimal_number", "positive_number","positive_number_min_1_max_12","positive_number_min_1"].includes(validator)) {
                            message.destroy()
                            const Validation_Status = ValidateNumber({ "validation_type": validator, "value": numberValue, "decimal_place": decimal_place });
                            const { status } = Validation_Status
                            if (Validation_Status.status) {
                                const percentageCheck = numberValidation({ value: String(Validation_Status.value), placeHolder, validator, "isStrictPercentageCheck": column?.isStrictPercentageCheck })
                                if(!percentageCheck.status && numberValue){
                                    message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                    return;
                                }
                                TransmitData({ "latestValue": status ? Number(Validation_Status.value) : null })
                            } else {
                                TransmitData({ "latestValue": null })
                                const percentageCheck = numberValidation({ value: String(Validation_Status.value), placeHolder, validator, "isStrictPercentageCheck": column?.isStrictPercentageCheck })
                                if(!percentageCheck.status && numberValue){
                                    message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                    return;
                                }
                                if(decimalPlace===0){
                                    switch(validator){
                                        case 'positive_decimal_number': {
                                            message.open({ type: 'warning', content: Validation_Warning_Message.positive_number, duration: 3}); return;
                                        }
                                    }
                                }
                                if(event.target.value){
                                    validator && Validation_Warning_Message?.[validator] && message.open({ type: 'warning', content: Validation_Warning_Message[validator], duration: 3});
                                }
                            }
                        }
                    }}
                />
                </>
            )
        }
        case 'static_text': {
            return (
                <StaticText
                    cellColor={"white"}
                    placeHolder={column.placeHolder}
                    value={value}
                    is_mandatory={column.is_mandatory}
                />
            )
        }
        case 'dropdown': {
            const SORTED_LIST = columnIndex===1 ? column.select_options?.sort((a,b) => a?.label?.localeCompare(b?.label)) : column.select_options;
            return (
                <React.Fragment>
                    {
                        !!value && columnIndex <= 1
                            ? <StaticText value={value} />
                            : <>
                            <SelectOption
                                name={column.name}
                                placeholder={column.placeHolder}
                                value={value}
                                isDisabled={isReadOnly}
                                onChangeEvent={(value) => {
                                    const id = SORTED_LIST.find((option) => option.label===value).id
                                    TransmitData({ "latestValue": value, "id": id })
                                }}
                                listOfOptions={SORTED_LIST}
                            /></>
                    }
                </React.Fragment>
            )
        }
        case 'month_year_picker': {
            return (
                <>
                    {/* <>{validator}</> */}
                    {/* {contextHolder} */}
                    <DatePicker disabled={isReadOnly} onChange={(_, dateString) => {
                        const { status } = ValidateNumber({ "validation_type": validator, "value": dateString, "month_year": month_year })
                        if(!status){
                            validator && Validation_Warning_Message?.[validator] && message.open({ type: 'warning', content: Validation_Warning_Message[validator], duration: 3});
                        }
                        TransmitData({ "latestValue": status ? dateString : null })
                    }} value={!!value ? dayjs(value, ['YYYY/MM']) : null}  placeholder={column.placeHolder} format={'YYYY/MM'} picker="month" />
                </>
            )
        }
    }
}

export default RenderComponent