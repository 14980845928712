import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/api/api';
import { FillRowCols } from '../utils/FillRowsCols';
import { colors } from '../data';
import psmLocals from "../localization/localization_en_problem_solution_matrix.json";
import routesConfig from "../services/config/routesConfig.json"
import { generateUrlEndPoint } from '../utils/endPointsGeneration';

export const problemStatementApi = createAsyncThunk(
  'problemstatement/problemStatementApi',
  async (problemStatement, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'createProblem');
      if (!!urlGenerator) {
        const urlConfig = { data: problemStatement, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const solutionStatementApi = createAsyncThunk(
  'solutionstatement/solutionStatementApi',
  async (solutionStatement, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'createSolution');
      if (!!urlGenerator) {
        const urlConfig = { data: solutionStatement, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const exportRatingApi = createAsyncThunk(
  'exportrating/exportRatingApi',
  async (exportrating, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'createRating');
      if (!!urlGenerator) {
        const urlConfig = { data: exportrating, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const postingCommentApi = createAsyncThunk(
  'postingcomment/postingCommentApi',
  async (postcomment, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'createComment');
      if (!!urlGenerator) {
        const urlConfig = { data: postcomment, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const deletingCommentApi = createAsyncThunk(
  'deletingcomment/deletingCommentApi',
  async (deletecomment, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'deleteComment');
      const urlWithParams = `${urlGenerator.url}`+`?id=${deletecomment.commentId}`
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, url: urlWithParams }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const updatingCommentApi = createAsyncThunk(
  'updatingcomment/updatingCommentApi',
  async (updatecomment, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'updateComment');
      if (!!urlGenerator) {
        const urlConfig = { data: updatecomment, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const fetchingPSMDataApi = createAsyncThunk(
  'fetchingPSMRootData/fetchingPSMDataApi',
  async (optionalArg = null, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'fetchProblemSolution');
      console.log(urlGenerator);
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const postingNestedCommentApi = createAsyncThunk(
  'postingNestedComment/postingNestedCommentApi',
  async (postNestedComment, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'addNestedComment');
      if (!!urlGenerator) {
        const urlConfig = { data: postNestedComment, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const fetchComments = createAsyncThunk(
  'fetchComment/fetchComments',
  async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'fetchAllComments');
      const urlWithParams = urlGenerator.url.replace(':commentId',state.psm.conversationList.sid);
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, url: urlWithParams }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const deletingNestedCommentApi = createAsyncThunk(
  'deletingnestedcomment/deletingNestedCommentApi',
  async (nestedCommentId, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'deleteNestedComment');
      const urlWithParams = `${urlGenerator.url}`+`?id=${nestedCommentId.id}`
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, url: urlWithParams }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const updatingNestedCommentApi = createAsyncThunk(
  'updatingnestedcomment/updatingNestedCommentApi',
  async (updatedDetails, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'updateNestedComments');
      const urlWithParams = `${urlGenerator.url}`+`?id=${updatedDetails.id}`
      if (!!urlGenerator) {
        const urlConfig = { data: {newMsg: updatedDetails.updatedData}, ...urlGenerator, url: urlWithParams }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const postBgColorApi = createAsyncThunk(
  'postBgColor/postBgColorApi',
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'patchBgColor');
      if (!!urlGenerator) {
        const urlConfig = { data: data, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const deleteSolutionApi = createAsyncThunk(
  'deleteSolution/deleteSolutionApi',
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'deleteSolution');
      const urlWithParams = `${urlGenerator.url}`+`?id=${data.sid}`
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, url: urlWithParams }
        const response = await api.request(urlConfig);
        return data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const deleteProblemApi = createAsyncThunk(
  'deleteSolution/deleteProblemApi',
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'problemSolutionMatrix', 'deleteProblemWithRespectiveSolutions');
      const urlWithParams = urlGenerator.url.replace(':problemId',data.pid);
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, url: urlWithParams }
        const response = await api.request(urlConfig);
        return {response:response.data, deletedIndex: data.index};
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

const initialState = {
  isfresh: true,
  showExample: false,
  showGenerateScoreCard: false,
  psmstatus: 'idle',
  problemsolutionmatrix: [
    {
      problemStatement: "",
      collectionSolution: [
        { solutiondescription: "", bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0 },
        { solutiondescription: "", bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0 }
      ]
    },
  ],
  problemStatementDataHandle: {
    row: 0,
    problemDefination: ""
  },
  solutionStatementDataHandle: {
    row: 0,
    col: 0,
    solutionDefination: ""
  },
  maxSolutionNumber: 3,
  conversationList: {
    comments: 0,
    row: 0,
    col: 0,
    sid: '',
    commentlength: 0,
    status: 'idle',// 'idle' | 'loading' | 'succeeded' | 'failed',
    commentStatus: 'idle',
    deleteStatus: 'idle',
    deleteNestedParentId: null,
    deleteNestedStatus: 'idle',
    updateStatus: 'idle',
    error: null
  },
  showConversationList: false,
  individualRating: {
    ratings: {},
    row: 0,
    col: 0,
    sid: "",
    status: 'idle',// 'idle' | 'loading' | 'succeeded' | 'failed'
    solutionDescription: null,
    error: null
  },
  oldRatings: {
    ratings: [],
    mainRowIndex: 0,
    renderindex: 0,
    pIndex: 0,
    sIndex: 0,
  },
  optimalSolution: {
    modelOpen: false,
    solutionDescription: "",
    activeSolutionList: [],
    optimalSolutionRating: null
  },
  status: 'idle',
  error: null,
  deleteParentCommentId: null,
  deleteProblemSolution: 'idle'
};


const psmSlice = createSlice({
  name: 'psm',
  initialState,
  reducers: {
    handleDeleteParentId(state, action) {
      state.deleteParentCommentId = action.payload;
    },
    resetPsmStatus(state) {
      state.error = null
    },
    updateRating(state, action) {
      // console.log(action.payload,"pay");
      // state.individualRating = { ...state.individualRating, ...action.payload };
      state.problemsolutionmatrix[state.individualRating.row].collectionSolution[state.individualRating.col] = {
        ...state.problemsolutionmatrix[state.individualRating.row].collectionSolution[state.individualRating.col], feasibilityRatings: action.payload.ratings
      };
      state.individualRating = { ...state.individualRating, status: action.payload.status, ratings: action.payload.ratings }
    },
    updatingTrackRating(state, action) {
      state.individualRating = { ...state.individualRating, ...action.payload };
    },
    updateMatrix(state, action) {
      if (action.payload.updatedMatrix !== undefined) {
        state.problemsolutionmatrix = action.payload.updatedMatrix
      }
      state.maxSolutionNumber = action.payload.maxSolutionNumber;
    },
    updateConversationList(state, action) {
      state.conversationList = { ...state.conversationList, ...action.payload };
    },
    showDemo(state, action) {
      state.isfresh = false;
    },
    prepareProblemSolutionData(state, action) {
      state.problemStatementDataHandle = action.payload;
    },
    prepareSolutionData(state, action) {
      state.solutionStatementDataHandle = action.payload;
    },
    toggleGenerateScoreCard(state) {
      state.showGenerateScoreCard = !state.showGenerateScoreCard;
    },
    toggleShowHideExample(state) {
      state.showExample = !state.showExample;
      if (!state.showExample) {
        state.problemsolutionmatrix = state.problemsolutionmatrix.splice(1);
      } else {
        let spamSolutions = [];
        const newSolution = { bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0, docColor: colors[Math.floor(Math.random() * colors.length)] };
        for (let i = 0; i < state.maxSolutionNumber; i++) {
          spamSolutions.push({ ...newSolution, solutiondescription: psmLocals.problem_solution_matrix.psm_table.example.solution_examples[i] });
        }
        state.problemsolutionmatrix.unshift({ problemStatement: psmLocals.problem_solution_matrix.psm_table.example.problem_example, collectionSolution: spamSolutions })
      }
    },
    toggleConversationList(state, action) {
      if (action.payload.type === "showList") {
        state.showConversationList = true;
      } else {
        state.showConversationList = false;
      }
    },
    addNestedComment(state, action) {
      // state[action.payload.index].
    },
    updateNestedParentHoldingId(state, action) {
      state.deleteNestedParentId = action.payload
    },
    updateOptimalSolutionModalToggle(state, action) {
      if (state.optimalSolution.modelOpen === false) {
        state.optimalSolution.solutionDescription = action.payload.solutionData;
        state.optimalSolution.activeSolutionList = action.payload.arrayOfSolutions;
        state.optimalSolution.optimalSolutionRating = action.payload.optimalSolutionRating
      } else {
        state.optimalSolution.solutionDescription = "";
        state.optimalSolution.activeSolutionList = [];
        state.optimalSolution.optimalSolutionRating = null
      }
      state.optimalSolution.modelOpen = !state.optimalSolution.modelOpen;
    },
    patchBgColor(state, action) {
      state.problemsolutionmatrix[action.payload.row].collectionSolution[action.payload.col].bgColor = action.payload.selectedColor;
    },
    updateNoOfComments(state, action) {
      state.problemsolutionmatrix[state.conversationList.row].collectionSolution[state.conversationList.col].comments = action.payload;
    },
    D_UpdateCommentCount(state, action){
      const { count, tableCellOrRowId } = action.payload;
      state.problemsolutionmatrix = state.problemsolutionmatrix.map((problem) => {
        return {
          ...problem,
          "collectionSolution": problem.collectionSolution.map((sol) => {
            return sol.sid===tableCellOrRowId ? {...sol, "comments": count} : sol
          })
        }
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(problemStatementApi.pending, (state, action) => {
        state.status = "loading";
        console.log("hey pending", state, action);
      })
      .addCase(problemStatementApi.fulfilled, (state, action) => {
        state.status = "succeded"
        if (action.payload.data !== undefined) {
          state.problemsolutionmatrix[state.problemStatementDataHandle.row] = { ...state.problemsolutionmatrix[state.problemStatementDataHandle.row], "pid": action.payload.data };
        }
      })
      .addCase(problemStatementApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("rejected", state, action);
      })
      .addCase(solutionStatementApi.pending, (state, action) => {
        console.log("hey solution pending", state, action);
        state.status = "loading"
      })
      .addCase(solutionStatementApi.fulfilled, (state, action) => {
        state.status = "succeded"
        if (action.payload.data !== undefined) {
          // state.problemsolutionmatrix[state.problemStatementDataHandle.row]={...state.problemsolutionmatrix[state.problemStatementDataHandle.row],"pid":action.payload.data}; 
          state.problemsolutionmatrix[state.solutionStatementDataHandle.row].collectionSolution[state.solutionStatementDataHandle.col] = { ...state.problemsolutionmatrix[state.solutionStatementDataHandle.row].collectionSolution[state.solutionStatementDataHandle.col], "sid": action.payload.data }
        }
        console.log(action.payload);
      })
      .addCase(solutionStatementApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("rejected solution", state, action);
      })
      .addCase(exportRatingApi.pending, (state, action) => {
        state.status = "loading"
        state.individualRating.status = "loading"
        console.log("hey rating pending");
      })
      .addCase(exportRatingApi.fulfilled, (state, action) => {
        state.status = "succeded"
        state.individualRating.status = "succeeded"
        console.log("hey rating succeedded");
      })
      .addCase(exportRatingApi.rejected, (state, action) => {
        state.individualRating.status = "failed"
        // state.individualRating.error = action.
        console.log("hey rating rejected");
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(postingCommentApi.pending, (state, action) => {
        state.status = "loading"
        state.conversationList.status = "loading"
        console.log("hey comment pending");
      })
      .addCase(postingCommentApi.fulfilled, (state, action) => {
        state.status = "succeded"
        state.conversationList.status = "succeeded";
        if (state.conversationList.comments[state.conversationList.commentlength]._id === undefined) {
          state.conversationList.comments[state.conversationList.commentlength] = { ...state.conversationList.comments[state.conversationList.commentlength], "_id": action.payload.msgId }
          // state.problemsolutionmatrix[state.conversationList.row].collectionSolution[state.conversationList.col].comments = state.conversationList.comments;
        }
        console.log("hey comment succeedded");
      })
      .addCase(postingCommentApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.conversationList.status = "failed"
        // state.individualRating.error = action.
        console.log("hey comment rejected");
      })
      .addCase(deletingCommentApi.pending, (state, action) => {
        state.status = "loading"
        state.conversationList.deleteStatus = "loading"
        console.log("hey delete pending");
      })
      .addCase(deletingCommentApi.fulfilled, (state, action) => {
        state.status = "succeded"
        state.conversationList.deleteStatus = "succeeded";
        const filteredComments = state.conversationList.comments.filter((comment, _) => {
          if (comment._id !== action.payload.msgId) {
            return comment;
          }
        });
        state.conversationList.comments = filteredComments
        // state.problemsolutionmatrix[state.conversationList.row].collectionSolution[state.conversationList.col].comments = filteredComments;
        console.log("hey delete succeedded");
      })
      .addCase(deletingCommentApi.rejected, (state, action) => {
        state.conversationList.deleteStatus = "failed"
        state.status = "failed";
        state.error = action.error.message;
        // state.individualRating.error = action.
        console.log("hey delete rejected");
      })
      .addCase(updatingCommentApi.pending, (state, action) => {
        state.status = "loading"
        state.conversationList.updateStatus = "loading"
        console.log("hey update pending");
      })
      .addCase(updatingCommentApi.fulfilled, (state, action) => {
        state.status = "succeded"
        state.conversationList.updateStatus = "succeeded"
        // state.problemsolutionmatrix[state.conversationList.row].collectionSolution[state.conversationList.col].comments = state.conversationList.comments
        console.log("hey update succeedded");
      })
      .addCase(updatingCommentApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.conversationList.updateStatus = "failed"
        console.log("hey update rejected");
      })
      .addCase(fetchingPSMDataApi.pending, (state, action) => {
        state.psmstatus = "loading"
        state.status = "loading"
      })
      .addCase(fetchingPSMDataApi.fulfilled, (state, action) => {
        state.psmstatus = "succeeded"
        state.status = "succeded"
        const filledRows = FillRowCols(action.payload, state.showExample);
        state.showExample = filledRows.showExample;
        if (filledRows.psm.length === 0) {
          state.problemsolutionmatrix = [{
            problemStatement: psmLocals.problem_solution_matrix.psm_table.example.problem_example, collectionSolution: [
              { solutiondescription: psmLocals.problem_solution_matrix.psm_table.example.solution_examples[0], bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0, docColor: colors[Math.floor(Math.random() * colors.length)] },
              { solutiondescription: psmLocals.problem_solution_matrix.psm_table.example.solution_examples[1], bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0, docColor: colors[Math.floor(Math.random() * colors.length)] },
              { solutiondescription: psmLocals.problem_solution_matrix.psm_table.example.solution_examples[2], bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0, docColor: colors[Math.floor(Math.random() * colors.length)] },
            ]
          }]
        } else {
          if (state.showExample) {
            let spamSolutions = [];
            const newSolution = { bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0 };
            for (let i = 0; i < filledRows.maxisolution; i++) {
              spamSolutions.push({ ...newSolution, solutiondescription: psmLocals.problem_solution_matrix.psm_table.example.solution_examples[i], docColor: colors[Math.floor(Math.random() * colors.length)] });
            }
            state.problemsolutionmatrix = [{
              problemStatement: psmLocals.problem_solution_matrix.psm_table.example.problem_example,
              collectionSolution: spamSolutions
            }, ...filledRows.psm];
          } else {
            state.problemsolutionmatrix = filledRows.psm;
          }
        }
        state.maxSolutionNumber = filledRows.maxisolution;
      })
      .addCase(fetchingPSMDataApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.psmstatus = "failed"
        console.log("hey fetch rejected");
      })
      .addCase(postingNestedCommentApi.pending, (state, action) => {
        state.status = "loading"
        console.log("nested comment pending");
      })
      .addCase(postingNestedCommentApi.fulfilled, (state, action) => {
        console.log("nested comment fulfilled");
        state.status = "succeded"
        const filteredComments = state.conversationList.comments.map((comment, _) => {
          if (comment._id === state.deleteNestedParentId) {
            let copySingleComment = { ...comment };
            let nestedCommentCopy = Array.isArray(copySingleComment.nestedComments) ? [...copySingleComment.nestedComments] : [];
            nestedCommentCopy[nestedCommentCopy.length - 1] = { ...nestedCommentCopy[nestedCommentCopy.length - 1], _id: action.payload.nestedMsgId, user_id: { _id: action.payload.userId, ...nestedCommentCopy[nestedCommentCopy.length - 1].user_id }, updatedAt: action.payload.updatedAt }
            return { ...copySingleComment, nestedComments: nestedCommentCopy }
          } else {
            return comment;
          }
        });
        console.log(filteredComments, "filter");
        state.conversationList.comments = filteredComments
      })
      .addCase(postingNestedCommentApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("nested comment rejected");
      })
      .addCase(fetchComments.pending, (state, action) => {
        console.log("comment pending");
        state.status = "loading"
        state.conversationList.commentStatus = 'idle';
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        console.log("comment fulfilled");
        state.status = "succeded"
        state.conversationList.comments = action.payload;
        state.conversationList.commentStatus = 'fulfilled';
      })
      .addCase(fetchComments.rejected, (state, action) => {
        console.log("comment rejected");
        state.status = "failed";
        state.error = action.error.message;
        state.conversationList.commentStatus = 'rejected';
      })
      .addCase(deletingNestedCommentApi.pending, (state, action) => {
        state.status = "loading"
        console.log("nested comment pending");

        state.conversationList.deleteNestedStatus = 'idle';
      })
      .addCase(deletingNestedCommentApi.fulfilled, (state, action) => {
        state.status = "succeded"
        console.log("nested comment fulfilled");
        const filteredComments = state.conversationList.comments.map((comment, _) => {
          if (comment._id === state.deleteNestedParentId) {
            let copySingleComment = { ...comment };
            let nestedCommentCopy = Array.isArray(copySingleComment.nestedComments) ? [...copySingleComment.nestedComments] : [];
            const newArr = nestedCommentCopy.filter((nc, i) => {
              if (nc._id !== action.payload.msgId) {
                return nc;
              }
            })
            return { ...copySingleComment, nestedComments: newArr }
          } else {
            return comment;
          }
        });
        console.log(filteredComments[0], "deleted------", action.payload);
        state.conversationList.comments = filteredComments
        state.conversationList.deleteNestedStatus = 'fulfilled';
      })
      .addCase(deletingNestedCommentApi.rejected, (state, action) => {
        console.log("nested comment rejected");
        state.conversationList.deleteNestedStatus = 'rejected';
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(postBgColorApi.pending, (state, action) => {
        console.log("comment pending");
        state.status = "loading"
        state.conversationList.commentStatus = 'idle';
      })
      .addCase(postBgColorApi.fulfilled, (state, action) => {
        console.log("comment fulfilled");
        state.status = "succeded"
        state.conversationList.comments = action.payload;
        state.conversationList.commentStatus = 'fulfilled';
      })
      .addCase(postBgColorApi.rejected, (state, action) => {
        console.log("comment rejected");
        state.status = "failed";
        state.error = action.error.message;
        state.conversationList.commentStatus = 'rejected';
      })
      .addCase(deleteProblemApi.pending, (state, action) => {
        state.deleteProblemSolution = 'loading';
      })
      .addCase(deleteProblemApi.fulfilled, (state, action) => {
        state.deleteProblemSolution = 'succeded';
        // deletedIndex
        const copyOfPSM = state.problemsolutionmatrix;
        const filteredPSM = copyOfPSM.filter((_, i) => i !== action.payload.deletedIndex);
        if (filteredPSM.length == 0) {
          state.maxSolutionNumber = 3;
          state.showExample = true;
          let spamSolutions = [];
          const newSolution = { bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0, docColor: colors[Math.floor(Math.random() * colors.length)] };
          for (let i = 0; i < state.maxSolutionNumber; i++) {
            spamSolutions.push({ ...newSolution, solutiondescription: psmLocals.problem_solution_matrix.psm_table.example.solution_examples[i] });
          }
          state.problemsolutionmatrix = [{ problemStatement: psmLocals.problem_solution_matrix.psm_table.example.problem_example, collectionSolution: spamSolutions }]
        }else{
          state.problemsolutionmatrix = filteredPSM;
        }
      })
      .addCase(deleteProblemApi.rejected, (state, action) => {
        state.deleteProblemSolution = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteSolutionApi.pending, (state) => {
        state.deleteProblemSolution = "loading";
      })
      .addCase(deleteSolutionApi.fulfilled, (state, action) => {
        state.deleteProblemSolution = "succeded";
        const newSolution = { isDisabled: -1, bgColor: "", solutiondescription: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0 };
        const updatedMatrix = [...state.problemsolutionmatrix];
        // const remainingSolutions = updatedMatrix[action.payload.rowIndex]?.collectionSolution?.filter((_, idx) => idx !== action.payload.solutionIndex);
        const updatedSolution = updatedMatrix[action.payload.rowIndex]?.collectionSolution?.map((subsolution,index) => {
          if(index==action.payload.solutionIndex){
            return {docColor:subsolution.docColor,...newSolution};
          }
          return subsolution;
        })
        // const updatedSolution = [...remainingSolutions, newSolution];
        updatedMatrix[action.payload.rowIndex] = { ...updatedMatrix[action.payload.rowIndex], collectionSolution: updatedSolution };
        state.problemsolutionmatrix = updatedMatrix;
      })
      .addCase(deleteSolutionApi.rejected, (state) => {
        state.deleteProblemSolution = "failed";
        state.error = action.error.message;
      })
  }
})

export const { D_UpdateCommentCount, handleDeleteParentId, resetPsmStatus, updateNoOfComments, patchBgColor, updateNestedParentHoldingId, updateRating, updatingTrackRating, updateMatrix, updateConversationList, showDemo, prepareProblemSolutionData, prepareSolutionData, toggleGenerateScoreCard, toggleShowHideExample, toggleConversationList, addNestedComment, updateOptimalSolutionModalToggle } = psmSlice.actions;
export default psmSlice.reducer;