import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Select, Button } from 'antd';
import white_tick from "../../assets/common/white_tick.svg"
import dropdown_icon from '../../assets/common/dropdown_icon.svg'

const SelectOptionRow = ({
    ref,
    name,
    value,
    open,
    styles,
    disabled,
    placeHolder,
    handleOnClick,
    listOfOptions,
    handleOnBlur,
    onClickAddMore,
    isAddMoreDisabled=false,
    addMoreButtonName,
    handleOnChangeValue
}) => {
    const handleAddMoreClick = (event) => {
        event.stopPropagation();
        onClickAddMore();
    }
    return (
        <React.Fragment>
            <Select
                name={name}
                onClick={handleOnClick}
                className='react_option_table_row'
                mode="multiple"
                value={value}
                style={{
                    width: '100%',
                    ...styles
                }}
                suffixIcon={<img src={dropdown_icon} alt="" />}
                onBlur={(value) => handleOnBlur(value)}
                onChange={(value) => handleOnChangeValue(value)}
                placeholder={placeHolder}
                menuItemSelectedIcon={<><img src={white_tick} alt="" /></>}
                showSearch={false}
                disabled={disabled}
            >
                {
                    listOfOptions?.map((option, index) => {
                        return (
                            <Select.Option key={index} value={option?.value} className="custom-select-option-1">
                                <span>{option?.label}</span>
                                {option?.tooltip ? <span className="w-[18px] h-[18px] aspect-square"><ToolTip tooltip={option?.tooltip} icon={option?.value === value ? whiteTooltip : stoneTooltip} /></span> : null}
                            </Select.Option>
                        )
                    })
                }
                {
                    addMoreButtonName
                        ? (
                            <Select.Option key={"add_more"}>
                                <button
                                    disabled={isAddMoreDisabled}
                                    onClick={handleAddMoreClick}
                                    className="flex gap-x-[0.5rem] items-center justify-center"
                                    style={{ padding: "0.3rem 0rem", width: "100%", backgroundColor: "#BECEFF", borderRadius: "0.3rem" }}
                                >
                                    <PlusOutlined />{addMoreButtonName}
                                </button>
                            </Select.Option>
                        ) : null
                }
            </Select>
            <style>
                {
                    `
                        .react_option_table_row>.ant-select-selector>.ant-select-selection-overflow>.ant-select-selection-overflow-item{
                            min-width: 100%;
                        }
                        .react_option_table_row>.ant-select-selector>.ant-select-selection-overflow>.ant-select-selection-overflow-item>.ant-select-selection-item{
                            min-width: 100% !important;
                            display: flex;
                            justify-content: space-between;
                        }
                        .ant-select-selection-search{
                            display: none !important;
                        }
                        .ant-select-multiple.ant-select-show-arrow .ant-select-selector, :where(.css-dev-only-do-not-override-1m62vyb).ant-select-multiple.ant-select-allow-clear .ant-select-selector {
                            padding-inline-end: 24px;
                            height: 100%;
                        }
                        .ant-select-multiple .ant-select-selection-item {
                            height: 24px;
                            border-radius: 0 !important;
                            margin: 0 !important;
                            border-bottom: 1px solid #E0E0E0;
                        }
                        .ant-select-multiple .ant-select-selector {
                            padding: 1px 0px;
                        }
                        .react_option_table_row>.ant-select-selector{
                            border: none !important;
                        }
                        // .ant-select-item-option-selected{
                        //     background-color: #372EE5 !important;
                        //     color: white !important;
                        // }
                    `
                }
            </style>
        </React.Fragment>
    );
};
export default SelectOptionRow;