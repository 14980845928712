import React from 'react'
import { Button } from "antd";

const ButtonComponent = ({ isBorder, borderColor, onClick, text, bgColor, textColor, type, isLoading, isDisabled, isPreImg, isPostImg, preImg, postImg, pVertical, pHorizontal}) => {
    return (
        <React.Fragment>
            <Button
                style={{ backgroundColor: bgColor,
                     color: textColor,
                      border:(isBorder) && `1px solid ${borderColor}`,
                      padding: `${pVertical}px ${pHorizontal}px`
                    }}
                type={type}
                loading={isLoading}
                disabled={isDisabled}
                className='border-none h-auto flex justify-center'
                onClick={onClick}
            >
                {isPreImg && <img src={preImg} />}
                {text}
                {isPostImg && <img src={postImg} />}
            </Button>
        </React.Fragment>
    )
}

export default ButtonComponent