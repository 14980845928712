import { outputTablePayloadPreparation, seggregateScreen1Data, seggregateScreen3Data } from "../../functionality/OutputTablePayloadPreparationFunction";

export const tablePayloadPreparation = ({ report_type, data, metric, spanType, categoryList }) => {
    if(metric === "Balance Sheet"){
        return outputTablePayloadPreparation(data, "balanceSheetScreen", "balanceSheetScreen").balanceSheetOutput
    }else if(metric === "Cash Flow"){
        return (
            spanType==="monthly"
            ? outputTablePayloadPreparation(data, "cashFlow", "cashFlowScreen").cashflowMonthly
            : outputTablePayloadPreparation(data, "cashFlow", "cashFlowScreen").cashflowYearly
        )
    }else if(metric === "Marketing Cost"){
        let perChannel = []
        for (const prop of Object.entries(data)) {
            perChannel = seggregateScreen1Data({ data: prop[1], title: prop[0] }, 2).perChannel;
        }
        return [{...seggregateScreen3Data({ "totalMarketingCost": data.total }).totalMarketingCost[0], "children": perChannel }]
    }else if(metric === "Average Order Value"){
        let screen1TableData = seggregateScreen1Data({ "data": data, title: "averageOrderValue" }, 2);
        let prodCatgMap = {};
        categoryList.productCategoryList.map((prod) => {
            prodCatgMap = {...prodCatgMap, [prod._id]: prod.title}
        })
        return screen1TableData.averageOrderValue.map((r) => ({...r, "title": prodCatgMap[r.title]}));
    }else if(metric === "Profit & Loss"){
        return outputTablePayloadPreparation(data[spanType], "pandl", "pandl", spanType).Particulars
    }
}