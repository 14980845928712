import React from 'react'
import { Table } from "antd";
import bmOuputLocals from '../../../localization/localization_en_business_modal.json'
import { commaSeparatorNumberFormat } from '../../../utils/commaSeparatorNumberFormat';
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';
import { convertStringInK } from '../../../utils/convertStringInK';

const ENVIRONMENT = import.meta.env.VITE_REACT_ENVIRONMENT;
const ouputTableEquipCurrency = bmOuputLocals.business_modal.revenue.output_table_refernce_currency;

const ColumnHeading = ({ value }) => {
    return <div className='py-[0.75rem] pl-[0.5rem]'><TextLarge text={value} fontWeight={500} /></div>
}

const RowHeading = ({ value }) => {
    return <div className='bg-white py-[12px] px-[8px]'>{value}</div>
}

const convertStringNegativeNumber = (value) => {
    try {
        return `(${value.split('-').join('')})`
    } catch (e) {
        return value
    }
}

const DataColumn = ({ value, currency }) => {
    let cellValue;
    if (false) {
        cellValue = value?.includes('-') ? `(${value?.split('-')?.[0]}${value?.split('-')?.[1]})` : value;
    } else {
        if (value?.includes('-')) {
            cellValue = convertStringNegativeNumber(value.includes(currency) ? convertStringInK(value) : value)
        } else {
            cellValue = value?.includes(currency) ? convertStringInK(value) : value;
        }
    }
    return <div className='bg-white py-[12px] px-[8px] break-all' style={{ color: value?.includes('-') ? 'red' : 'black' }}>
        {cellValue}
    </div>
}

const IndividualOutput = ({ tableName, tableTitle, dataSource, currency, test }) => {
    // return <>{JSON.stringify(dataSource)}</>
    try {
        const source = dataSource[0]
        if (source === null || source === undefined) return;
        const tableColumnNames = Object.keys(source);
        const shouldIncludeCurrency = ouputTableEquipCurrency.includes(tableName)
        const COLUMNS = new Array(Object.values(dataSource[0]).length).fill(null).map((column, columnIndex) => {
            return {
                "key": columnIndex,
                "fixed": (columnIndex === 0) ? 'left' : '',
                "title": <ColumnHeading value={columnIndex === 0 ? tableTitle : tableColumnNames[columnIndex]} />,
                "dataIndex": columnIndex === 0 ? "title" : tableColumnNames[columnIndex],
                "width": columnIndex === 0 ? 255 : 125,
                "render": (value, record, rowIndex) => (
                    <div className=''>
                        {columnIndex === 0
                            ? <RowHeading value={value} />
                            : <DataColumn value={commaSeparatorNumberFormat({ "number": value, "prefix": shouldIncludeCurrency ? '' : currency })} currency={currency} />
                        }
                    </div>
                )
            };
        });
        const totalColumnWidth = COLUMNS?.reduce((total, column) => total + (column.width || 0), 0);
        return (
            <div>
                <Table
                    columns={COLUMNS}
                    className='normal_output_table'
                    dataSource={dataSource}
                    bordered
                    scroll={{
                        x: totalColumnWidth,
                    }}
                    pagination={{
                        pageSize: COLUMNS?.length,
                        position: ['none', 'none'],
                    }}
                />
            </div>
        )
    } catch (error) {
        throw new Error(error);
    }
}

export default IndividualOutput