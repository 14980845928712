import React, { useState, useEffect } from 'react';
import { Button, Checkbox, message, Form, Input, Tooltip } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import checkcircle from '../assets/Login/check-circle.svg';
import profile from '../assets/SignUp/Profile.png';
import Loginpana2 from '../assets/Login/Loginpana2.svg';
import Message from '../assets/Login/Message.svg';
import Lock from '../assets/Login/Lock.svg';
import { Link } from 'react-router-dom';
import { createUser, resetStatus } from '../features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import test3 from '../assets/Login/createAccVector.svg';
import '../index.css';
import { updateCreateAccCred, userInfoStatus, updateErr, userInfoError, updateStatus } from '../features/userSlice';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/Header/logotest.svg';
import brand from "../assets/common/brand.svg";
import "./auth/auth.css";
import { Select, Space } from 'antd';
import TNC from "../assets/docs/Terms_and_Conditions.pdf";
import PP from "../assets/docs/Privacy_Policy.pdf";
import createAccountLocals from "../localization/localization_en_create_account.json"
import footerLocals from "../localization/localization_en_footer.json"
import { passwordValidator, passwordValidatorForEnableBtn } from '../utils/passwordValidator';
import infoIcon from "../assets/common/tooltip.svg"
import TextMedium from '../DesignSystem/TypographyDescriptions/TextMedium';
import VerifyEmailModal from '../components/modals/VerifyEmailModal';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';

const WARNING_ERROR_MESSAGE = createAccountLocals.create_account.warning_error_messages;

const AntCreateAccount = ({ user_type }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStatus = useSelector(userInfoStatus);
  const credentialDetail = useSelector((state) => state.user.createacc);
  const userErr = useSelector((state) => state.user.error);
  const [checkAgreeTermsAndCond, setCheckAgreeTermsAndCond] = useState(false);
  const [isSignUpDisabled, setIsSignUpDisabled] = useState(false);
  const [passwordValidationErrMsg, setPasswordValidationErrMsg] = useState(null);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [isEmailValid, setEmailValid] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [subscriptionList, setSubscriptionList] = useState([]);
  // const [subscriptionId,setSubscriptionId] = useState(null);
  const [placeholder, setplaceholder] = useState("");

  // useEffect(() => {
  //   const fetchAllSubcription = async () => {
  //     fetch('http://localhost:5000/stripe/all-subscription-plans')
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         return response.json();
  //       })
  //       .then(data => {
  //         setplaceholder(data?.allSubscriptions[0]?.description)
  //         const items = data?.allSubscriptions?.map((subscription, index) => {
  //           return (
  //             {
  //               label: subscription?.currency?.toUpperCase()+" "+subscription?.amount_to_display+" for "+subscription?.interval_count+" "+subscription?.interval?.slice(0,1).toUpperCase()+subscription?.interval?.slice(1),
  //               value: subscription?.id
  //             }
  //           )
  //         })
  //         setSubscriptionList(items);
  //       })
  //       .catch(error => {
  //         console.error('There was a problem with the fetch operation:', error);
  //       });
  //   }
  //   fetchAllSubcription()
  // }, [])


  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    console.log(e.target.name, e.target.value)
    // dispatch(updateCreateAccCred());
    // setEmail(emailValue);
    // setEmailValid(validateEmail(emailValue));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordVisibilityChange = () => {
    setPasswordVisible((prev) => !prev);
  };

  // const validateEmail = (email) => {
  //   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  // };
  const isLoginDisabled = 
    !checkAgreeTermsAndCond ||
    !credentialDetail?.["isEmailValid"] ||
    passwordValidatorForEnableBtn(credentialDetail?.["password"]) ||
    userStatus === "loading" ||
    !credentialDetail?.["username"]?.trim()?.length>=3 ||
    !credentialDetail?.["companyName"]?.trim()?.length>=3 ||
    !credentialDetail?.["countryCode"] ||
    (!!credentialDetail?.["phoneNumber"] ? !isValidPhoneNumber(String(credentialDetail?.["phoneNumber"])) : true)

  const handleFormInputs = (e, name) => {
    dispatch(updateCreateAccCred({ key: name, value: e.target.value }))
    // console.log(e.target.value,name);
  }
  // console.log(userStatus,isLoginDisabled);

  const formFields = [
    {
      name: 'username',
      className: 'resp_login_width',
      rules: [
        {
          required: true,
          message: WARNING_ERROR_MESSAGE.username_input,
          validator: !(credentialDetail?.["username"]?.trim()?.length>=3 && credentialDetail?.["username"]?.trim()?.length<=20)
        },
      ],
      inputProps: {
        placeholder: createAccountLocals.create_account.form.username.placeholder,
        className: 'md:h-[50px] h-[42px] md:rounded-xl',
        prefix: <PersonIcon className='text-baseGray500' />,
        onChange: (e) => handleFormInputs(e, 'username'),
      },
      maxChar:20
    },
    {
      name: 'companyName',
      className: 'resp_login_width',
      rules: [
        {
          required: true,
          message: WARNING_ERROR_MESSAGE.company_input,
          validator: !(credentialDetail?.["companyName"]?.trim()?.length>=3 && credentialDetail?.["companyName"]?.trim()?.length<=100)
        },
      ],
      inputProps: {
        placeholder: createAccountLocals.create_account.form.companyName.placeholder,
        className: 'md:h-[50px] h-[42px] md:rounded-xl',
        prefix: <ApartmentIcon className='text-baseGray500' />,
        onChange: (e) => handleFormInputs(e, 'companyName'),
      },
      maxChar:100
    },
    {
      name: 'phoneNumber',
      className: 'resp_login_width',
      rules: [
        {
          required: true,
          message: WARNING_ERROR_MESSAGE.phone_number,
          validator: !(credentialDetail?.["phoneNumber"]?.length===0 ? true : isValidPhoneNumber(String(credentialDetail?.["phoneNumber"])))
        },
      ],
      inputProps: {
        placeholder: createAccountLocals.create_account.form.phoneNumber.placeholder,
        className: `md:h-[50px] h-[42px] md:rounded-xl border px-[1rem] outline-none ${!isValidPhoneNumber(String(credentialDetail?.["phoneNumber"])) && credentialDetail?.["phoneNumber"]?.length>0 ? 'border-red-500' : 'border-[#d9d9d9]'}`,
        prefix: <img className='md:w-[18px] w-[14px]' src={profile} alt="" />,
        onChange: (val) => dispatch(updateCreateAccCred({ key: 'phoneNumber', value: val ?? '' })),
        onCountryChange: (countryCode) => dispatch(updateCreateAccCred({ key: 'countryCode', value: countryCode }))
      },
    },
    {
      name: 'email',
      className: `resp_login_width`,
      rules: [
        {
          required: true,
          message: WARNING_ERROR_MESSAGE.email,
          validator: !credentialDetail?.["isEmailValid"]
        },
      ],
      inputProps: {
        placeholder: createAccountLocals.create_account.form.email.placeholder,
        className: `md:h-[50px] h-[42px] md:rounded-xl ${credentialDetail?.["email"] && !credentialDetail?.["isEmailValid"] ? 'border-red-500' : ''}`,
        prefix: <EmailIcon className='text-baseGray500' style={{ fontSize: "1.4rem" }} />,
        suffix: credentialDetail?.["email"] && credentialDetail?.["isEmailValid"] && <img className='md:w-[20px] w-[14px]' src={checkcircle} alt='' />,
        onChange: (e) => handleFormInputs(e, 'email'),
      },
    },
    {
      name: 'password',
      className: 'resp_login_width',
      rules: [
        {
          required: true,
          message: passwordValidationErrMsg,
          validator: passwordValidator
        }
      ],
      inputProps: {
        placeholder: createAccountLocals.create_account.form.password.placeholder,
        className: 'md:h-[50px] h-[42px] md:rounded-xl',
        prefix: <LockIcon className='text-baseGray500' style={{ fontSize: "1.4rem" }} />,
        iconRender: (visible) => (
          visible ? (
            <EyeOutlined onClick={handlePasswordVisibilityChange} />
          ) : (
            <EyeInvisibleOutlined onClick={handlePasswordVisibilityChange} />
          )
        ),
        onChange: (e) => {
          const value = e.target.value;
          passwordValidator(undefined, value, (msg) => {
            setPasswordValidationErrMsg(msg);//msg
          });
          handleFormInputs(e, "password");
        },
        visibilityToggle: true,
        visible: passwordVisible.toString(),
      },
    },
  ];

  const onFinish = (values) => {
    if (credentialDetail["subscriptionId"] === null) {
      message.open({
        type: "warning",
        content: createAccountLocals.create_account.form.warning_message.subscription
      })
    } else {
      const { username, password, email, subscriptionId, companyName, countryCode, phoneNumber } = credentialDetail;
      let data = { username, password, email, subscriptionId, companyName, countryCode, phoneNumber }
      if(user_type){
        data = {...data, user_type}
      }
      dispatch(createUser(data));
      // dispatch(createUser({...values,subscriptionId}));
    }
  };

  useEffect(() => {
    if (userStatus === 'failed') {
      messageApi.open({
        type: 'error',
        content: userErr,
      });
      setTimeout(() => {
        dispatch(updateErr());
      }, 1000);
    }
    if (userStatus === 'succeeded') {
      dispatch(updateStatus())
      navigate('/product/verifyemail');
    }
  }, [userStatus, dispatch]);

  // console.log(checkAgreeTermsAndCond)
  const handleCloseEmailVerificationModal = () => {
    setIsVerifyModalOpen((prev) => !prev);
  }

  return (
    <div className='md:min-h-[100vh] min-h-[90vh] mx-[24px] md:mx-0 flex flex-col justify-between'>
      {contextHolder}
      <VerifyEmailModal isModalOpen={isVerifyModalOpen} handleCloseEmailVerificationModal={handleCloseEmailVerificationModal} />
      <div className='absolute hidden top-0 left-0 right-0 md:flex -z-[10000] min-h-full'>
        <div className="left_shadow bg-white w-[60%]"></div>
        <div className="left_shadow lg:bg-[#FAFAFA] w-[45%] 2xl:w-[55%]"></div>
      </div>
      <div onClick={() => navigate('/')} className="header top-0 md:pt-[48px] lg:pl-[64px] md:pl-[10px]">
        <img src={logo} className="w-[138px] h-[21px] md:block hidden" alt="" />
        <img src={brand} className="w-[64px] h-[64px] block md:hidden" alt="" />
      </div>
      <div className="md:flex justify-between items-center xl:my-[0rem] md:my-[5rem] md:py-[2rem]">
        <div className='md:w-[55%] lg:w-[60%] w-[100%]'>
          <Form
            initialValues={credentialDetail}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            className="lg:w-[474px] w-full resp_create_form lg:ml-[64px] md:ml-[10px]"
          >
            <p style={{ marginTop: '0px', marginBottom: '0px' }} className="text-[24px] text-[#111827] font-DMSans">{createAccountLocals.create_account.mainTitle}</p>
            <div style={{ marginTop: '0px', marginBottom: '12px' }} className='text-[#757575] font-InterRegular text-[14px] font-medium'>
              <p className='md:text-[14px] text-[12px] font-InterRegular tracking-wide' style={{ marginTop: '0px', marginBottom: '0px' }}>{createAccountLocals.create_account.free_trial_decsription}</p>
            </div>
            <div>
              {formFields.map((field) => {
                return (
                  <Form.Item
                    key={field?.name}
                    name={field.name}
                    className={field?.className}
                    rules={field?.rules}
                    style={{ marginTop: '15px', marginBottom: '0px' }}
                  >
                    {
                      field?.name === 'password'
                        ? (
                          <Input.Password
                            disabled={userStatus === "loading"}
                            {...field.inputProps}
                          />
                        ) : null
                    }
                    {
                      ['username', 'email', 'companyName'].includes(field?.name)
                        ? (
                          <Input 
                            maxLength={field?.maxChar}
                            disabled={userStatus === "loading"} 
                            {...field.inputProps}
                          />
                        ) : null
                    }
                    {
                      field?.name === 'phoneNumber'
                        ? (
                          <PhoneInput
                            defaultCountry={credentialDetail["countryCode"]}
                            className='hide_outline'
                            style={{
                              borderRadius: "0.4rem"
                            }}
                            disabled={userStatus === "loading"}
                            {...field.inputProps}
                          />
                        ) : null
                    }
                  </Form.Item>
                )
              })}
              <div className='flex gap-x-[0.2rem] mt-[0.5rem]'>
                <Tooltip className='passValidationTooltip cursor-pointer' title={
                  <div>
                    {
                      createAccountLocals.create_account.password_validation_tooltip.map((msg, passIndex) => <div key={passIndex}>• {msg}</div>)
                    }
                  </div>
                } placement="bottomRight">
                  <img src={infoIcon} alt="" />
                </Tooltip>
                <TextMedium text={"Password Policy"} fontWeight={200} />
              </div>
            </div>
            {/*<Form.Item style={{ marginTop: "15px"}}>
              <Select
                value={credentialDetail["subscriptionId"]}
                placeholder={placeholder}
                onChange={(value) => {
                  dispatch(updateCreateAccCred({key:"subscriptionId",value}))
                }}
                options={subscriptionList}
              />
              </Form.Item>*/}
            <Form.Item style={{ marginBottom: '30px', marginTop: '8px' }}>
              <Checkbox disabled={userStatus === "loading"} checked={checkAgreeTermsAndCond} onChange={(e) => setCheckAgreeTermsAndCond(e.target.checked)} />
              <span className='font-InterRegular text-[13px] font-medium ml-[12px] tracking-wide text-[#616161]'>{createAccountLocals.create_account.terms_and_condition_stmt.agree_stmt} <button className='text-primary' onClick={() => navigate('/terms-and-conditions')}>{createAccountLocals.create_account.terms_and_condition_stmt.terms_and_condition_stmt}</button> {createAccountLocals.create_account.terms_and_condition_stmt.query_word} <button onClick={() => navigate('/privacy-policy')} className='text-primary'>{createAccountLocals.create_account.terms_and_condition_stmt.privacy_and_poilicy_stmt}</button></span>
            </Form.Item>
            <Form.Item style={{ marginTop: "0px", marginBottom: '12px' }}>
              <Button
                aria-disabled={isLoginDisabled}
                className={`${!isLoginDisabled && 'btn-onboarding-grad'
                  } text-[16px] rounded-xl font-semibold flex justify-center resp_login_width w-full h-[42px] md:h-[50px]`}
                htmlType="submit"
                loading={userStatus === "loading"}
                disabled={isLoginDisabled}
                style={{
                  margin: '0rem 0rem',
                  backgroundColor: true ? '#f5f8ff' : '',
                  color: isLoginDisabled ? '#9693f2' : 'white'
                }}
              >
                {createAccountLocals.create_account.sign_up_button_text}
              </Button>
            </Form.Item>

            <div className="resp_login_width md:block hidden text-[14px] font-medium tracking-wide">
              {createAccountLocals.create_account.account_exist}
              <Link to={'/product'} className="text-[#372ee5] pl-2 font-semibold">{createAccountLocals.create_account.login_button_text}</Link><br />
              {createAccountLocals.create_account.account_not_verified}
              <button onClick={handleCloseEmailVerificationModal} className="text-[#372ee5] pl-2 font-semibold">{createAccountLocals.create_account.account_not_verified_btn_cta}</button>
            </div>
          </Form>
        </div>
        <div className="w-[40%] lg:bg-[#FAFAFA] md:flex justify-center hidden">
          <img src={test3} className="my-auto" alt="" />
        </div>
      </div>
      <div className="sticky top-[100%]">
        <div className='flex justify-between items-center text-[#9E9E9E] text-[13px] md:pb-[32px] lg:px-[64px] px-[8px]'>
          <div className='font-InterRegular tracking-wide hidden md:block'>{footerLocals.footer.copyright_text}</div>
          <div className="resp_login_width flex md:hidden justify-center text-[14px] font-medium tracking-wide">
            {createAccountLocals.create_account.account_exist}
            <Link to={'/product'} className="text-[#372ee5] pl-2 font-semibold">{createAccountLocals.create_account.login_button_text}</Link>
          </div>
          <div className='respFooter'>
            <button onClick={() => navigate('/refund-policy')} className='mr-[24px]'>{footerLocals.footer.refund_policy}</button>
            <button onClick={() => navigate('/terms-and-conditions')} className='mr-[24px]'>{footerLocals.footer.terms_and_condition_text}</button>
            <button onClick={() => navigate('/privacy-policy')}>{footerLocals.footer.privacy_policy_text}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AntCreateAccount;
