import React, { useState } from 'react'
import PlanDescription from '../../DesignSystem/Cards/website/PlanDescription'
import { flexible_plan_icon } from '../../assets/website'
import { useNavigate } from 'react-router-dom'
import UserInformationForm from './UserInformationForm'
import HeadingTwo from '../../DesignSystem/TypographyHeadings/HeadingTwo'
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge'
import SwitchButton from '../../DesignSystem/Buttons/SwitchButton';

const ICON_REFERNCE = {
    paperRocket: flexible_plan_icon?.paper_rocket,
    airplane: flexible_plan_icon?.airplane,
    pgRocket: flexible_plan_icon?.pg_rocket,
    shuttleSpace: flexible_plan_icon?.space_shuttle
}


const PricingCardSection = ({ pricingCards, pricing_span_unit, unit }) => {
    const navigate = useNavigate();
    const [activeCurrency, setActiveCurrency] = useState("INR");
    return (
        <div className='grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[18px] 2xl:gap-[24px]'>
            <UserInformationForm>
                {
                    ({ handleTogglePopup }) => (
                        pricingCards.map((card, index) => {
                            const activeCurrencyValue = card.pricing_details?.[pricing_span_unit]?.[activeCurrency];
                            return (
                                <PlanDescription
                                    key={index}
                                    textSize={"lg"}
                                    title={card.title}
                                    handleOnClick={() => {
                                        // if (card?.is_form) {
                                        //     handleTogglePopup({ "form_name": card.form_name })
                                        // }else{
                                        //     navigate(card.link_path)
                                        // }
                                        navigate(card.link_path)
                                    }}
                                    topLevelButtonType={card.button_type}
                                    priorityTag={card.priorityTag}
                                    image={ICON_REFERNCE[card.icon_ref_name]}
                                    description={card.description}
                                    features={card.list_of_features}
                                    isTopButtonVisible={true}
                                    topLevelButtonName={card.top_level_button_name}
                                    isBottomButtonVisible={false}
                                    isCardhoverEffect={true}
                                    isCustomBorderColor={true}
                                    ComponentViewPricing={
                                        card.pricing_details !== null
                                            ? (
                                                <div className='flex flex-col items-center'>
                                                    <div className='flex items-end mb-[8px]'>
                                                        <HeadingTwo headingCSSStyles={{ margin: 0 }} text={activeCurrencyValue} />
                                                        <TextLarge fontWeight={500} color={'#757575'} text={`/${pricing_span_unit}`} />
                                                    </div>
                                                    <div className='flex items-center gap-x-[8px]'>
                                                        <TextLarge fontWeight={500} color={activeCurrency===unit.inr ? "#372EE5":'#757575'} text={unit.inr} />
                                                        <SwitchButton 
                                                            checked={activeCurrency===unit.usd} 
                                                            handleOnChange={() => {
                                                                setActiveCurrency((prevValue) => prevValue===unit.usd ? unit.inr : unit.usd)
                                                            }}
                                                        />
                                                        <TextLarge fontWeight={500} color={activeCurrency===unit.usd ? "#372EE5":'#757575'} text={unit.usd} />
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }
                                />
                            )
                        })
                    )
                }
            </UserInformationForm>
        </div>
    )
}

export default PricingCardSection
