import React from 'react'
import HeadingFour from './HeadingFour'
import { cn } from '../../utils/cn'

const ResponsiveHeadingFourInter = ({ color, text, className }) => {
    return (
        <>
            <HeadingFour color={color} text={text} headingCSSStyles={{ marginBottom: 0 }} className={`sm:block hidden ${className}`} />
            <span style={{ color }} className={cn('text-[18px] sm:hidden block font-semibold font-InterRegular', className)}>{text}</span>
        </>
    )
}

export default ResponsiveHeadingFourInter