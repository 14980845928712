import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleGenerateScoreCard } from '../features/psmSlice'

const PsmScoreCard = () => {
    const dispatch = useDispatch();
    return (
        <div>
            <div className="rate_fesability_score_card absolute top-0 left-0 bottom-0 right-0 w-full min-h-[100vh] z-[10000] bg-black/40">
                <div className='w-[1014px] px-[31px] py-[28px] rounded-[16px] bg-white absolute left-1/2 -translate-x-1/2 top-1 '>
                    <p className='font-DMSans text-[20px] italic text-center text-[#909090]'>
                        Congratulations! You've found the Optimized Solution! After conducting a thorough evaluation and considering key metric ratings, this solution has emerged as the most suitable option to address the problem at hand.
                    </p>
                    <div className='w-[90%] mx-auto rounded-[12px] h-[300px] flex justify-center items-center score_card_grad'>
                        <p className='font-DMSans text-[24px] text-white text-center'>“Here we will show the solution to users which is generated based on the rating given in feasibility parameters”</p>
                    </div>
                    <div className='flex justify-center'>
                        <button onClick={()=>dispatch(toggleGenerateScoreCard())} className='bg-primary text-white px-[24px] py-[12px] text-[16px] font-InterRegular font-medium rounded-[8px] mt-[48px]'>Done</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PsmScoreCard
