import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api/api";
import caLocals from "../../localization/localization_en_competitor_analysis.json"
import routesConfig from "../../services/config/routesConfig.json"
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";

const staticRowsMarketing = caLocals.competitor_analysis.table.other.staticRowsMarketing;
const staticRowsSales = caLocals.competitor_analysis.table.other.staticRowsSales;
const staticRowsInvestment = caLocals.competitor_analysis.table.other.staticRowsInvestment;
const staticArrRowsMarketing = caLocals.competitor_analysis.table.other.staticArrRowsMarketing;
const staticArrRowsSales = caLocals.competitor_analysis.table.other.staticArrRowsSales;
const staticArrRowsInvestment = caLocals.competitor_analysis.table.other.staticArrRowsInvestment;

export const getCompetitorProductDataApi = createAsyncThunk(
  "competitor/getCompetitorProductDataApi",
  async () => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'productWithFeature');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const addCompetitorProductDataApi = createAsyncThunk(
  "competitor/addCompetitorProductDataApi",
  async (_, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'addCompetitor');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const addFeatureDataApi = createAsyncThunk(
  "competitor/addFeatureDataApi",
  async (_, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'addFeature');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const postCompetitorDataApi = createAsyncThunk(
  "competitor/postCompetitorDataApi",
  async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'updateDataProducts');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, data: { product: state.competitor.product }}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const postFeatureDataApi = createAsyncThunk(
  "competitor/postFeatureDataApi",
  async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'updateFeatures');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, data: state.competitor.feature}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const getOtherDataApi = createAsyncThunk(
  "competitor/getOtherDataApi",
  async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'getOtherCa');
      const urlWithParams = urlGenerator.url.replace(':attr',state.competitor.tabtitle+"RefId");
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, url: urlWithParams}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const postTextOtherDataApi = createAsyncThunk(
  "competitor/postTextOtherDataApi",
  async (updateOtherTextArea, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'postOtherCa');
      const urlWithParams = urlGenerator.url.replace(':attr',state.competitor.tabtitle+"RefId");
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, url: urlWithParams, data: updateOtherTextArea}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const deleteCompetitorApi = createAsyncThunk(
  "competitor/deleteCompetitorApi",
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'deleteCompetitor');
      const urlWithParams = urlGenerator.url.replace(':id',data.id).replace(':count',data.count)
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, url: urlWithParams}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const featureDeleteApi = createAsyncThunk(
  "competitor/featureDeleteApi",
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'deleteFeature');
      const urlWithParams = urlGenerator.url.replace(':type',data.type).replace(':featureid',data.featureid)
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, url: urlWithParams}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const updateFeatureTextApi = createAsyncThunk(
  "competitor/updateFeatureTextApi",
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'patchFeatureText');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, data: data}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const updateCompetitorTextApi = createAsyncThunk(
  "competitor/updateCompetitorTextApi",
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'updateCompetitorName');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, data: data}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const toggleFeatureApi = createAsyncThunk(
  "competitor/toggleFeatureApi",
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'toggleFeature');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, data: data}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const postProductTextApi = createAsyncThunk(
  "competitor/postProductTextApi",
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'patchProductText');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, data: data}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const patchFeaturePaletteApi = createAsyncThunk(
  "competitor/patchFeaturePaletteApi",
  async (data, { getState, dispatch}) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'patchFeatureColor');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, data: data}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const patchPricingPaletteApi = createAsyncThunk(
  "competitor/patchPricingPaletteApi",
  async (data, { getState, dispatch}) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'competitorAnalysis', 'patchProductPricing');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, data: data}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

const competitorSlice = createSlice({
  name: "competitor",
  initialState: {
    key: "1",
    staticRows: {},
    staticArrRows: [],
    tabtitle: "",
    productstatus: "idle",
    product: [],
    otherAttr: [],
    feature: [],
    dataToDownloadJSONToCSV: {},
    deleteAndAddloading: false,
    status:'idle',
    isCompetitorAdded: false,
    error:null
  },
  reducers: {
    resetCompetitorAdded(state, action){
      state.isCompetitorAdded = action.payload;
    },
    resetCompetitorStatusErr(state,action){
      state.status = 'idle';
      state.error = null;
      state.productstatus = 'idle';
    },
    updatekeyChange: (state, action) => {
      const tabNames = caLocals.competitor_analysis.tab_header;
      if (action.payload.key === "1") {
        state.tabtitle = tabNames.tab1
      } else if (action.payload.key === "2") {
        state.staticRows = staticRowsMarketing;
        state.staticArrRows = staticArrRowsMarketing;
        state.tabtitle = tabNames.tab2
      } else if (action.payload.key === "3") {
        state.staticRows = staticRowsSales;
        state.staticArrRows = staticArrRowsSales;
        state.tabtitle = tabNames.tab3
      } else if (action.payload.key === "4") {
        state.staticRows = staticRowsInvestment;
        state.staticArrRows = staticArrRowsInvestment;
        state.tabtitle = tabNames.tab4
      }
      state.key = action.payload.key;
    },
    updateFeatureCompetitor: (state, action) => {
      if (action.payload.type === "product") {
        state.product = action.payload.data;
      } else {
        state.feature = action.payload.data;
      }
    },
    updateCompetitorText: (state, action) => {
      const updatedText = state.otherAttr.map((competitor) => {
        if (competitor._id === action.payload.textid) {
          return { ...competitor, [action.payload.fieldName]: { ...competitor[action.payload.fieldName], data: action.payload.text } };
        } else {
          return competitor;
        }
      });
      state.otherAttr = updatedText;
    },
    oneTimeUpdateOtherAttrColor(state, action) {
      state.otherAttr = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompetitorProductDataApi.pending, (state) => {
        state.productstatus = "loading";
      })
      .addCase(getCompetitorProductDataApi.fulfilled, (state, action) => {
        state.productstatus = "succeeded";
        state.product = action.payload.product;
        state.feature = action.payload.feature;
      })
      .addCase(getCompetitorProductDataApi.rejected, (state, action) => {
        state.productstatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addCompetitorProductDataApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCompetitorProductDataApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isCompetitorAdded = true;
        state.product = [...state.product, action.payload];
      })
      .addCase(addCompetitorProductDataApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addFeatureDataApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addFeatureDataApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.feature = [...state.feature, action.payload];
      })
      .addCase(addFeatureDataApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(postCompetitorDataApi.pending, (state) => {
        state.productstatus = "loading";
      })
      .addCase(postCompetitorDataApi.fulfilled, (state, action) => {
        state.productstatus = "succeeded";
      })
      .addCase(postCompetitorDataApi.rejected, (state, action) => {
        state.productstatus = "failed";
        state.error = action.error.message;
      })
      .addCase(getOtherDataApi.pending, (state) => {
        state.productstatus = "loading";
      })
      .addCase(getOtherDataApi.fulfilled, (state, action) => {
        state.productstatus = "succeeded";
        state.otherAttr = action.payload;
      })
      .addCase(getOtherDataApi.rejected, (state, action) => {
        state.productstatus = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteCompetitorApi.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteCompetitorApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (Object.keys(action.payload.data).length !== 0) {
          state.product = state.product.slice(0,state.product.length-1);
          state.product = [...state.product, action.payload.data];
        }
      })
      .addCase(deleteCompetitorApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(featureDeleteApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(featureDeleteApi.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(featureDeleteApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(postTextOtherDataApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCompetitorTextApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(patchFeaturePaletteApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(postFeatureDataApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateFeatureTextApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(toggleFeatureApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(postProductTextApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export const { resetCompetitorStatusErr, oneTimeUpdateOtherAttrColor, updatekeyChange, updateFeatureCompetitor, updateCompetitorText, resetCompetitorAdded } =
  competitorSlice.actions;
export default competitorSlice.reducer;
