import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../services/api/api";
import { FillRowCols } from "../utils/FillRowsCols";
import { colors } from "../data";
import psmLocals from "../localization/localization_en_problem_solution_matrix.json";
import routesConfig from "../services/config/routesConfig.json";
import { generateUrlEndPoint } from "../utils/endPointsGeneration";

export const createDirectoryApi = createAsyncThunk(
  "createDirectory/createDirectoryApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "createDirectory"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const listDirectoryApi = createAsyncThunk(
  "listDirectory/listDirectoryApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "listDirectory"
      );
      console.log("XXXXXXXXURL ", urlGenerator);
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        console.log("XXXXXXXXURLCONFIG ", urlConfig);
        const response = await api.request(urlConfig);
        console.log("XXXXXXXXResponse ", response);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const createFileApi = createAsyncThunk(
  "createFile/createFileApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "createFile"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const fetchArchiveFileApi = createAsyncThunk(
    "fetchArchiveFile/fetchArchiveFileApi",
    async (addFolder, { getState, dispatch  }) => {
        try {
            const urlGenerator = generateUrlEndPoint(
                routesConfig.root.subpaths,
                "dataRoom",
                "fetchArchiveFiles"
            )
            if (!!urlGenerator) {
                const urlConfig = { data: addFolder, ...urlGenerator  };
                const response = await api.request(urlConfig);
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg)
        }
    }
)

export const reviewStatusApi = createAsyncThunk(
    "reviewStatus/reviewStatusApi",
    async (addFolder, { getState, dispatch  }) => {
        try {
            const urlGenerator = generateUrlEndPoint(
                routesConfig.root.subpaths,
                "dataRoom",
                "reviewDocument"
            )
            console.log(urlGenerator)
            if (!!urlGenerator) {
                const urlConfig = { data: addFolder, ...urlGenerator  };
                const response = await api.request(urlConfig);
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg)
        }
    }
)

export const reviewDocumentStatusApi = createAsyncThunk(
    "reviewDocumentStatus/reviewDocumentStatusApi",
    async (addFolder, { getState, dispatch  }) => {
        try {
            const urlGenerator = generateUrlEndPoint(
                routesConfig.root.subpaths,
                "dataRoom",
                "reviewDocumentStatus"
            )
            if (!!urlGenerator) {
                const urlConfig = { data: addFolder, ...urlGenerator  };
                const response = await api.request(urlConfig);
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg)
        }
    }
)


export const shareDocumentsApi = createAsyncThunk(
  "shareDocuments/shareDocumentsApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "shareDocument"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
)

export const deleteDocumentsApi = createAsyncThunk(
  "deleteDocuments/deleteDocumentsApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "deleteDocuments"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const linkDocumentApi = createAsyncThunk(
  "linkDocument/linkDocumentApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "linkDocument"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const treeApi = createAsyncThunk(
  "tree/treeApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "tree"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const QuestionAsk = createAsyncThunk(
  "questionAsk/questionAskApi",
  async (addFolder, { getState, dispatch }) => {
    console.log("Hi")
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "questionAsk"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const questionReply = createAsyncThunk(
  "questionReply/questionReplyApi",
  async (addFolder, { getState, dispatch }) => {
    console.log("Hi")
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "questionReply"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const questionResolve = createAsyncThunk(
  "questionResolve/questionResolveApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "questionResolve"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const questionAttachement = createAsyncThunk(
  "questionAttachement/questionAttachementApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "questionAttachement"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const questionDeleteApi = createAsyncThunk(
  "questionDelete/questionDeleteApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "questionDelete"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const questionEntryDeleteApi = createAsyncThunk(
  "questionEntryDelete/questionEntryDeleteApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "questionEntryDelete"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const questionAskReplyListApi = createAsyncThunk(
  "questionAskReplyList/questionAskReplyListApi",
  async ({addFolder, conversationId}, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "questionAskReplyList",
        `conversationId=${conversationId}`
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const questionAskReplyListApiWithoutDocumentId = createAsyncThunk(
  "questionAskReplyList/questionAskReplyListApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "questionAskReplyList"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);


export const updateArchiveStatusApi = createAsyncThunk(
  "updateArchiveStatus/updateArchiveStatusApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "updateArchiveStatus"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const updateColor = createAsyncThunk(
    "updateColor/updateColorApi",
    async (addFolder, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(
                routesConfig.root.subpaths,
                "dataRoom",
                "updateColor"
            );
            if(!!urlGenerator) {
                const urlConfig = { data: addFolder, ...urlGenerator  };
                const response = await api.request(urlConfig);
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
)

export const getDetailsDocumentsApi = createAsyncThunk(
  "getDetailsDocuments/getDetailsDocumentsApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "getDetailsDocuments"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const sharedWithMeApi = createAsyncThunk(
  "sharedWithMe/sharedWithMeApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "sharedWithMe"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const sharedWithMeDirApi = createAsyncThunk(
  "sharedWithMeDir/sharedWithMeDirApi",
  async (directoryId, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "sharedWithMeDir"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: { directoryId }, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const generateSignedUrlForDownload = createAsyncThunk(
  "generateSignedUrlForDownload/generateSignedUrlForDownloadApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "generateSignedUrlForDownload"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const getTokenInfoApi = createAsyncThunk(
  "getTokenInfo/getTokenInfoApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "getTokenInfo"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const getDetailsDirectoryApi = createAsyncThunk(
    "getDetailsDirectory/getDetailsDirectoryApi",
    async (addFolder, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(
                routesConfig.root.subpaths,
                "dataRoom",
                "getDetailsDirectory"
            )
            if (!!urlGenerator) {
              const urlConfig = { data: addFolder, ...urlGenerator };
              const response = await api.request(urlConfig);
              return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg)
        }
    }
)

export const updateLockStatusApi = createAsyncThunk(
  "updateLockStatus/updateLockStatusApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "updateLockStatus"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
)

export const updateTagsApi = createAsyncThunk(
  "updateTags/updateTagsApi",
  async (addFolder, { getState, dispatch }) => {
    console.log("Hi")
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "updateTagsList"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
)

export const updateDocumentDescriptionApi = createAsyncThunk(
  "updateDocumentDescription/updateDocumentDescriptionApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "updateDocumentDescription"
      );
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const fetchAllUsersApi = createAsyncThunk(
  "fetchAllUsers/fetchAllUsersApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "setting",
        "fetchUsers"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);


export const addInvestorApi = createAsyncThunk(
  "addInvestor/addInvestorApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "addInvestor"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const updateStageOfFudning = createAsyncThunk(
  "updateStageOfFunding/updateStageOfFundingApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "updateStageOfFunding"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
export const createStageOfFunding = createAsyncThunk(
  "createStageOfFunding/createStageOfFundingApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "createStageOfFunding"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
export const getStageOfFundingList = createAsyncThunk(
  "getStageOfFundingList/getStageOfFundingListApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "getStageOfFundingList"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
export const getStageOfFundingById = createAsyncThunk(
  "getStageOfFundingById/getStageOfFundingByIdApi",
  async ({addFolder, id}, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "getStageOfFundingById",
        `stageOfFundingId=${id}`
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
export const getDeafultStageOfFundingList = createAsyncThunk(
  "getDefaultStageOfFundinglist/getDefaultStageOfFundingListApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "getDefaultStageOfFundingList"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
export const investorTeamList = createAsyncThunk(
  "investorTeamList/inevestorTeamListApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "investorTeamList"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
export const documentuserShareList = createAsyncThunk(
  "documentUserShareList/documentUserShareListApi",
  async ({addFolder, documentId}, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "documentUserShareList",
        `documentId=${documentId}`
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
export const copyFileApi = createAsyncThunk(
  "copyFile/copyFileApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "copyFile"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
export const renameFileApi = createAsyncThunk(
  "renameFile/renameFileApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "renameFile"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
export const fetchAllInvestorApi = createAsyncThunk(
  "fetchAllInvestor/fetchAllInvestorApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "setting",
        "fetchInvestors"
      );
      console.log(urlGenerator)
      if (!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const generateSignedUrl = createAsyncThunk(
  "generateSignedUrl/generateSignedUrlApi",
  async (addFolder, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(
        routesConfig.root.subpaths,
        "dataRoom",
        "generateSignedUrlForUpload"
      );
      if(!!urlGenerator) {
        const urlConfig = { data: addFolder, ...urlGenerator };
        const response = await api.request(urlConfig);
        console.log(response)
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
)

const initialState = {
  status: "idle",
  error: null,
};

const dataRoomSlice = createSlice({
  name: "dataRoom",
  initialState,
  reducers: {
    handlecreateDirectory(state, action) {
      state.createDirectory = action.payload;
    },
    handlelistDirectory(state, action) {
      state.listDirectory = action.payload;
    },
    handlecreateFile(state, action) {
      state.createFile = action.payload;
    },
    handledeleteDocuments(state, action) {
      state.deleteDocuments = action.payload;
    },
    handlegetDetailsDocuments(state, action) {
      state.getDetailsDocuments = action.payload;
    },
    handleupdateDocumentDescription(state, action) {
      state.updateDocumentDescription = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDirectoryApi.pending, (state, action) => {
        state.status = "loading";
        console.log("hey pending", state, action);
      })
      .addCase(createDirectoryApi.fulfilled, (state, action) => {
        state.status = "succeded";
        if (action.payload.data !== undefined) {
          // Add this later
        }
      })
      .addCase(createDirectoryApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("rejected", state, action);
      })
      .addCase(listDirectoryApi.pending, (state, action) => {
        state.status = "loading";
        console.log("hey pending", state, action);
      })
      .addCase(listDirectoryApi.fulfilled, (state, action) => {
        state.status = "succeded";
        if (action.payload.data !== undefined) {
          // Add this later
        }
      })
      .addCase(listDirectoryApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("rejected", state, action);
      })
      .addCase(createFileApi.pending, (state, action) => {
        state.status = "loading";
        console.log("hey pending", state, action);
      })
      .addCase(createFileApi.fulfilled, (state, action) => {
        state.status = "succeded";
        if (action.payload.data !== undefined) {
          // Add this later
        }
      })
      .addCase(createFileApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("rejected", state, action);
      })
      .addCase(deleteDocumentsApi.pending, (state, action) => {
        state.status = "loading";
        console.log("hey pending", state, action);
      })
      .addCase(deleteDocumentsApi.fulfilled, (state, action) => {
        state.status = "succeded";
        if (action.payload.data !== undefined) {
          // Add this later
        }
      })
      .addCase(deleteDocumentsApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("rejected", state, action);
      })
      .addCase(getDetailsDocumentsApi.pending, (state, action) => {
        state.status = "loading";
        console.log("hey pending", state, action);
      })
      .addCase(getDetailsDocumentsApi.fulfilled, (state, action) => {
        state.status = "succeded";
        if (action.payload.data !== undefined) {
          // Add this later
        }
      })
      .addCase(getDetailsDocumentsApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("rejected", state, action);
      })
      .addCase(updateDocumentDescriptionApi.pending, (state, action) => {
        state.status = "loading";
        console.log("hey pending", state, action);
      })
      .addCase(updateDocumentDescriptionApi.fulfilled, (state, action) => {
        state.status = "succeded";
        if (action.payload.data !== undefined) {
          // Add this later
        }
      })
      .addCase(updateDocumentDescriptionApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("rejected", state, action);
      });
  },
});

export const {
  handlecreateDirectory,
  handlelistDirectory,
  handlecreateFile,
  shareDocumnetsApi,
  handledeleteDocuments,
  handlegetDetailsDocuments,
  handleupdateDocumentDescription,
} = dataRoomSlice.actions;
export default dataRoomSlice.reducer;
