import React from 'react'
import TextExtraLarge from '../../../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import GenerateForm from './GenerateForm'
import { useSelector, useDispatch } from "react-redux";
import { RevenueOnboardingFormStatus } from '../../functionality/RevenueOnboarding/CheckIndividualFormIsCompleted';
import { D_Handle_On_Change_Select, D_On_Change_Date } from '../../revenueSlice';
import ToolTip from '../../../../DesignSystem/ToolTip/ToolTip'
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';


const DefineFormItem = ({ formItem, data, isReadOnly = false, select_options, tableDefination, handleRadioSelectChange, handleOnBlur, errorCheckingPreInputs, tabState, orgProductCategory, handleCheckBoxOnChange, screen2Data, tableTooltip }) => {
    let radioInputProps, checkBoxInputProps, toggletableAttrProps, toggleTableProps, normalEditableTableProps, toggleTableLabelProps, numberProps, tabTableProps;
    const dispatch = useDispatch();

    const isError = RevenueOnboardingFormStatus(errorCheckingPreInputs);

    const isShowError = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.isShowError);
    const projection_duration = useSelector((state) => state.globalSetting.active_global_settings.projection_duration)
    const activeScreenNumber = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.activeScreenNumber)
    const trackComponentRendering = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.trackComponentRendering)
    const IS_VIEW = useSelector((state) => state.businessModal.MODE_TYPE.view)

    const organization_product_category = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.category_list.product_category)
    const organization_channel_name = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.category_list.channel_name)
    const format_organization_product_category = organization_product_category.map((category) => ({"label":category.title,"value":category.id}))
    const format_organization_channel_name = organization_channel_name.map((category) => ({"label":category.title,"value":category.id}))
    const categoryList = ((Number(trackComponentRendering[activeScreenNumber])===1 || Number(trackComponentRendering[activeScreenNumber])===2)) ? format_organization_product_category : format_organization_channel_name

    const fiscal_year_screen2 = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.fiscal_year_screen2_product_category);
    const marketing_spend_per_sales_channel = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.marketing_spend_per_sales_channel)

    const handleDateSelection = (payload) => dispatch(D_On_Change_Date(payload))

    if(formItem.type === "check_box_input"){
        checkBoxInputProps = { handleCheckBoxOnChange, "data": data, "selectOptions": select_options, "name": formItem.name, "isMulti": formItem.isMutliSelect, isDisabled: IS_VIEW }
    }else if (formItem.type === "radio_input") {
        radioInputProps = { handleRadioSelectChange, "data": data, "selectOptions": select_options, "name": formItem.name, "isMulti": formItem.isMutliSelect, isDisabled: IS_VIEW }
    } else if (formItem.type === "toggle_table_attr") {
        toggletableAttrProps = { data, tableDefination, handleDateSelection, isDisabled: IS_VIEW }
    } else if (formItem.type === "switch_table") {
        const HANDLE_ON_CHANGE_SELECT_OPTION = (value) => dispatch(D_Handle_On_Change_Select(value))
        toggleTableProps = { data: screen2Data, formItem, handleRadioSelectChange, isReadOnly, categoryList:categoryList,HANDLE_ON_CHANGE_SELECT_OPTION, isDisabled: IS_VIEW }
    } else if (formItem.type === "editable_table") {
        let allSelectOptions = [], filteredOption = [];
        if(Array.isArray(data)){
            allSelectOptions = data?.map((subdata) => (subdata.channel))
            filteredOption = categoryList.filter((subdata) => !allSelectOptions?.includes(subdata.value));
        }
        // return <pre>{JSON.stringify(data,null, 2)}{JSON.stringify(filteredOption)}</pre>
        normalEditableTableProps = { table_info_data: formItem, data, name: formItem.name, categoryList:categoryList, isReadOnly, filteredOption, tableTooltip }
        // return <pre>{JSON.stringify(normalEditableTableProps,null, 2)}</pre>
    } else if (formItem.type === "tab_table") {
        let updatedFormItem = formItem;
        if(projection_duration!==5){
            const slicedColumns = formItem.table.columns.slice(0,4);
            updatedFormItem = { ...formItem, "table": { "columns": slicedColumns } }
        }
        tabTableProps = { data, formItem: updatedFormItem, isReadOnly, tabState, orgProductCategory:format_organization_product_category, fiscal_year: fiscal_year_screen2, handleDateSelection }
    } else if (formItem.type === "normal_edit_table") {
        normalEditableTableProps = { table_info_data: formItem, data, categoryList, name: formItem.name }
    } else if (formItem.type === "number_input") {
        const { name, placeholder } = formItem
        numberProps = { name, placeholder, "value": data, handleOnBlur }
    }
    let tooltip;
    if(formItem.name === "marketing_spend_per_sales_channel"){
        tooltip = marketing_spend_per_sales_channel.type === "last_month_marketing_spend" ? formItem.last_month_tooltip : formItem.last_6_month_tooltip
    }else {
        tooltip = formItem?.tooltip
    }
    return (
        <div className='bg-baseGray100 p-[16px] rounded-[12px] relative'>
            <label className='flex mb-[14px] gap-x-[8px]'>
                {formItem.isMandatory ? <sub className='text-red-500  text-[1.2rem] pt-[0.2rem]'>*</sub> : null}
                <div className='inline-flex gap-x-[4px] items-center'>
                    <TextExtraLarge text={formItem.label} fontWeight={500} />
                    {!!tooltip
                    ? <div className='cursor-pointer'>
                        <ToolTip placement={"top"} tooltip={tooltip} />
                    </div>
                    : null}
                </div>
            </label>
            <div className={(formItem.type === "number_input") ? 'w-[100px]' : 'w-full'}>
                <ComponentErrorBoundary>
                    <GenerateForm
                        type={formItem.type}
                        radioInputProps={radioInputProps}
                        toggletableAttrProps={toggletableAttrProps}
                        toggleTableProps={toggleTableProps}
                        editableTableProps={normalEditableTableProps}
                        toggleTableLabelProps={toggleTableLabelProps}
                        numberProps={numberProps}
                        tabTableProps={tabTableProps}
                        checkBoxInputProps={checkBoxInputProps}
                    />
                </ComponentErrorBoundary>
            </div>
            { (isError && isShowError)
                ? <div className='text-sm mt-[0.5rem] text-red-600'>Please fill out all mandatory fields to proceed</div>
                : null
            }
        </div>
    )
}

export default DefineFormItem