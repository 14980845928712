import React from 'react'
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge'
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium'

const PublishedText = ({ heading, description }) => {
    return (
        <div className='flex gap-x-[8px]'>
            <TextMedium fontWeight={500} color={"#616161"} text={heading} />
            <TextMedium fontWeight={600} color={"#424242"} text={description} />
        </div>
    )
}

export default PublishedText