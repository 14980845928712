import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../services/api/api';
import routesConfig from "../services/config/routesConfig.json"
import { generateUrlEndPoint } from '../utils/endPointsGeneration';

export const sendResetPasswordLink = createAsyncThunk(
    'user/sendResetPasswordLink', async (email) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'passwordReset');
            if (!!urlGenerator) {
                const urlConfig = { data: { email }, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response.data;
            }
        }
        catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
)

export const sendNewEmailVerificationLink = createAsyncThunk(
    'user/sendNewEmailVerificationLink', async (email) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'resendverifyaccountlink');
            if (!!urlGenerator) {
                const urlConfig = { data: { email }, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response.data;
            }
        }
        catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
)

const spamSlice = createSlice({
    name: 'spam',
    initialState: {
        email: "",
        new_verfication_email_status:{
            status:'idle',
            message:''
        },
        status: "",
        statusMsg: null,
        model: false,
        isToolTipModalOpen: false,
        isDeleteNestedCommentOpen: false
    },
    reducers: {
        updateEmail: (state, action) => {
            state.email = action.payload;
        },
        updateModelShow: (state, action) => {
            state.model = action.payload;
        },
        resetPasswordMsg: (state, action) => {
            state.status = null;
            state.statusMsg = null;
        },
        toggleToolTipModal(state) {
            state.isToolTipModalOpen = !state.isToolTipModalOpen;
        },
        toggleisDeleteNestedCommentOpen(state) {
            state.isDeleteNestedCommentOpen = !state.isDeleteNestedCommentOpen;
        },
        resetEmailVerificationStatus(state,action){
            state.new_verfication_email_status = { status:'idle', message:'' }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendResetPasswordLink.pending, (state, action) => {
                state.status = "pending"
            })
            .addCase(sendResetPasswordLink.fulfilled, (state, action) => {
                state.status = "fulfilled"
            })
            .addCase(sendResetPasswordLink.rejected, (state, action) => {
                state.status = "rejected"
                state.statusMsg = action.error.message;
            })
            .addCase(sendNewEmailVerificationLink.pending, (state, action) => {
                state.new_verfication_email_status.status = 'loading';
            })
            .addCase(sendNewEmailVerificationLink.fulfilled, (state, action) => {
                state.new_verfication_email_status.status = 'success';
            })
            .addCase(sendNewEmailVerificationLink.rejected, (state, action) => {
                state.new_verfication_email_status.status = 'error';
                state.new_verfication_email_status.message = action.error.message;
            })
    }
})


export const { resetEmailVerificationStatus, toggleisDeleteNestedCommentOpen, toggleToolTipModal, updateEmail, updateModelShow, resetPasswordMsg } = spamSlice.actions
export default spamSlice.reducer