import React, { useState, useEffect } from "react";
import { Modal, Input, notification, message } from "antd";
import CloseButton from "../../SVG/CloseButtonSVG";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { renameFileApi } from "../../../features/dataRoomSlice";

const RenameModalAntD = ({ isOpen, onClose, documentList, initialValue }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [inputValue, setInputValue] = useState(initialValue)
    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState(false)
    const [context, setContext] = useState(false)
    console.log(documentList)

    useEffect(() => {
        if (isOpen) {
            setModalOpen(true);
            setInputValue(documentList?.name.split('.')[0] || initialValue);
            setContext(documentList?.context === "folder");
        }
    }, [isOpen, documentList, initialValue]);

    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleRename = async () => {
        setLoading(true)
        const body = {
            documentId: documentList._id,
            newFileName: inputValue
        }
        await dispatch(renameFileApi(body)).then((response) => {
            if (response?.meta.requestStatus === "fulfilled") {
                message.success("File Successfully Renamed")
            } else {
                message.error("Failed to rename file. Please try again")
            }
            setLoading(false)
            handleOk()
        })
    }

    return (
        <Modal
            title={<span style={{ fontSize: "20px" }}>{!context ? "Rename File" : "Rename Folder"}</span>}
            centered
            open={modalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <button
                className="absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all"
                onClick={handleCancel}
            >
                <CloseButton />
            </button>
            <div className=" px-2 flex flex-col gap-3 ">
                <div className=" flex flex-col mt-5 ">
                    <h1 className=" text-[#111827] text-[16px]">{!context ? "File Name" : "Folder Name"}</h1>
                    <Input defaultValue={initialValue} type="text" placeholder={documentList?.name.split('.')[0]} onChange={(e) => setInputValue(e.target.value)} value={inputValue}/>
                </div>
                <div className=" w-full flex flex-row justify-between mt-3 ">
                    <div>
                        <SecondaryButton text={"Cancel"} size="Medium" isDisabled={loading} onClick={handleCancel}/>
                    </div>
                    <div>
                        <PrimaryButton text={"Rename"} size="Medium" onClick={handleRename} isLoading={loading} isDisabled={loading}/>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RenameModalAntD;
