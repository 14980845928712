import axios from 'axios';

export async function sendRequest(config) {
    try {
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        throw error; 
    }
}