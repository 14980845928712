import React from 'react';
import { Slider } from 'antd';
const LibrarySlider = ({ value, handleOnChange, handleOnAfterChange, isDisabled, middleValueName }) => {
    const sliderData = [
        {
            label: "Pessimist",
            sliderPositionValue: 0,
            value: -5
        },
        {
            label: "Low",
            sliderPositionValue: 25,
            value: -2.5
        },
        {
            label: middleValueName,
            sliderPositionValue: 50,
            value: 0
        },
        {
            label: "High",
            sliderPositionValue: 75,
            value: 2.5
        },
        {
            label: "Optimist",
            sliderPositionValue: 100,
            value: 5
        }
    ]
    const [] = React.useState();
    const [sliderValue, setSliderValue] = React.useState(value)
    let endPoints = {};
    sliderData.map((slice) => {
        endPoints = {
            ...endPoints,
            [slice.sliderPositionValue]: {
                style: {
                    color: value === slice.value ? '#372EE5' : '#9E9E9E'
                },
                label: <strong>{slice.label}</strong>
            }
        }
    })
    return (
        <>
            <Slider
                disabled={isDisabled}
                marks={endPoints}
                step={25}
                onAfterChange={(eventValue) => {
                    handleOnAfterChange(eventValue)
                    if (![0, 25, 50, 75, 100].includes(eventValue)) {
                        setSliderValue(value)
                    }
                }}
                value={sliderValue}
                defaultValue={50}
                onChange={(value) => setSliderValue(value)}
            />
        </>
    )
};
export default LibrarySlider;