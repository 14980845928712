import React from 'react';
import { Table } from "antd";
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium';

const RenderTable = ({ columns, data }) => {

    const tableColumns = columns.map((col) => {
        return (
            {
                "title": <div className='p-[0.7rem]'><TextLarge fontWeight={600} text={col.title} /></div>,
                "dataIndex": col.data_index,
                "key": col.data_index,
                "render": (value, record, rowIndex) => {
                    if(typeof value === "string"){
                        return <TextMedium text={value} fontWeight={500} display='block' className={'p-[1rem]'} />
                    }
                    if(Array.isArray(value)){
                        return (
                            <div className='flex flex-col gap-y-[2rem] p-[1rem]'>
                                {
                                    value.map((val, index) => {
                                        return <TextMedium key={index} text={val} fontWeight={500} display='block' />
                                    })
                                }
                            </div>
                        )
                    }
                }
            }
        )
    })

    return (
        <Table
            bordered
            columns={tableColumns}
            dataSource={data}
            className='normal_input_table'
            pagination={{
                pageSize: data.length,
                position: ['none', 'none'],
            }}
        />
    )
}

export default RenderTable