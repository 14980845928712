import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import routesConfig from "../../services/config/routesConfig.json";
import api from "../../services/api/api";
import { v4 as uuidv4 } from "uuid";
import balance_sheet_locals from "../../localization/localization_en_pAndL_workCap_debtWork_cashFlow_balanceSheet.json";

const INITIAL_STATE = {
    "output": null,
    "isSaveDisabled": false,
    "isGenerateOutputDisabled": true,
    "save_process_api_status": { "status": "idle", counter: 0, message: null },
    "balance_sheet_opening_balance": balance_sheet_locals.balance_sheet.form.data[0].table.default_rows,
    "balance_sheet_working_capital_days_target": balance_sheet_locals.balance_sheet.form.data[1].table.default_rows
}

export const API_Save_BalanceSheet_Data = createAsyncThunk(
    "balanceSheet/API_Save_BalanceSheet_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-balance-sheet');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);
export const API_Process_BalanceSheet_Data = createAsyncThunk(
    "balanceSheet/API_Process_BalanceSheet_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-balance-sheet');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const balanceSheetSlice = createSlice({
    name: "balance-sheet",
    initialState: {
        "balanceSheetStore": INITIAL_STATE
    },
    reducers: {
        D_Reset_ErrorMessage(state, action) {
            state.balanceSheetStore.save_process_api_status = { "status": "idle", counter: 0, message: null }
        },
        D_Capture_Value_BalanceSheet(state, action) {
            if(state.balanceSheetStore.output) state.balanceSheetStore.output = null
            if (state.balanceSheetStore.isSaveDisabled) {
                state.balanceSheetStore.isSaveDisabled = false;
                state.balanceSheetStore.isGenerateOutputDisabled = true;
            }
            const { name, tableName, value, rowNumber, data_index } = action.payload;
            state.balanceSheetStore[tableName] = state.balanceSheetStore[tableName].map((row, index) => index === rowNumber ? { ...row, [data_index]: isNaN(value) ? value : value === null ? null : Number(value) } : row);
        },
        D_Update_Screen_Screen_BalanceSheet(state, action) {
            const SCREEN_DATA = action.payload.screenData
            if (SCREEN_DATA?.input) {
                const {
                    balance_sheet_opening_balance,
                    balance_sheet_working_capital_days_target
                } = SCREEN_DATA?.input
                state.balanceSheetStore.balance_sheet_opening_balance = balance_sheet_opening_balance
                state.balanceSheetStore.balance_sheet_working_capital_days_target = balance_sheet_working_capital_days_target
            }
            state.balanceSheetStore.output = SCREEN_DATA?.output ? SCREEN_DATA?.output : null;
            state.balanceSheetStore.isSaveDisabled = state.balanceSheetStore.output ? true : false
            state.balanceSheetStore.isGenerateOutputDisabled = state.balanceSheetStore.output ? true : false
        },
        D_Reset_BalanceSheet_State(state) {
            state.balanceSheetStore.output = null;
            state.balanceSheetStore.isSaveDisabled = false;
            state.balanceSheetStore.isGenerateOutputDisabled = true;
            state.balanceSheetStore.save_process_api_status = { status: "idle", counter: 0, message: null };
            state.balanceSheetStore.balance_sheet_opening_balance = balance_sheet_locals.balance_sheet.form.data[0].table.default_rows;
            state.balanceSheetStore.balance_sheet_working_capital_days_target = balance_sheet_locals.balance_sheet.form.data[1].table.default_rows;

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_Save_BalanceSheet_Data.pending, (state) => {
                state.balanceSheetStore.save_process_api_status.counter = state.balanceSheetStore.save_process_api_status.counter + 1
                state.balanceSheetStore.save_process_api_status.status = 'loading';
            })
            .addCase(API_Save_BalanceSheet_Data.fulfilled, (state) => {
                state.balanceSheetStore.save_process_api_status.counter = state.balanceSheetStore.save_process_api_status.counter + 1
                state.balanceSheetStore.save_process_api_status.status = 'success';
                state.balanceSheetStore.isSaveDisabled = true;
                state.balanceSheetStore.isGenerateOutputDisabled = false;
            })
            .addCase(API_Save_BalanceSheet_Data.rejected, (state, action) => {
                state.balanceSheetStore.save_process_api_status.counter = state.balanceSheetStore.save_process_api_status.counter + 1
                state.balanceSheetStore.save_process_api_status.status = 'failed';
                state.balanceSheetStore.save_process_api_status.message = action.error.message
            })
            .addCase(API_Process_BalanceSheet_Data.pending, (state) => {
                state.balanceSheetStore.save_process_api_status.counter = state.balanceSheetStore.save_process_api_status.counter + 1
                state.balanceSheetStore.save_process_api_status.status = 'loading';
            })
            .addCase(API_Process_BalanceSheet_Data.fulfilled, (state, action) => {
                state.balanceSheetStore.save_process_api_status.counter = state.balanceSheetStore.save_process_api_status.counter + 1
                state.balanceSheetStore.save_process_api_status.status = 'success';
                state.balanceSheetStore.isGenerateOutputDisabled = true;
                state.balanceSheetStore.output = action.payload;
            })
            .addCase(API_Process_BalanceSheet_Data.rejected, (state, action) => {
                state.balanceSheetStore.save_process_api_status.counter = state.balanceSheetStore.save_process_api_status.counter + 1
                state.balanceSheetStore.save_process_api_status.status = 'failed';
                state.balanceSheetStore.save_process_api_status.message = action.error.message
            })
    }
});

export const { D_Update_Screen_Screen_BalanceSheet, D_Reset_ErrorMessage, D_Reset_BalanceSheet_State, D_Capture_Value_BalanceSheet } = balanceSheetSlice.actions;
export default balanceSheetSlice.reducer;