import React from 'react'
import { Button } from "antd";
import eyeIcon from "../../assets/common/eyeIcon.svg"
import TextLarge from '../TypographyDescriptions/TextLarge';

const PrimaryButton = ({ isLoading, onClick, text, isIcon = false, size = "small", isDisabled = false, htmlType = "button", icon, styles, className }) => {
  const padding = size === "small" ? "8px 24px" : size === "medium" ? "12px 40px" : "16px 28px";
  const fontSize = size === "small" ? "16px" : size === "medium" ? "16px" : "18px";

  // Determine classes for button when disabled
  const disabledClass = isDisabled ? 'bg-[#D8D8D8] text-white cursor-not-allowed' : 'bg-mainPrimary text-p9';
  
  return (
    <React.Fragment>
      <Button
        onClick={onClick}
        disabled={isDisabled}
        type='primary'
        style={{ padding, fontSize, letterSpacing: "0.2px", lineHeight: "140%", ...styles }}
        loading={isLoading}
        htmlType={htmlType}
        icon={isIcon && (React.isValidElement(icon) ? icon : <img style={{ margin: 0 }} src={eyeIcon} />)}
        className={`${disabledClass} h-auto text-[16px] font-semibold ${className}`}
      >
        <TextLarge fontWeight={600} text={text} />
      </Button>
    </React.Fragment>
  )
}

export default PrimaryButton;
