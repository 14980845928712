import React, { useState } from 'react'
import { shapes } from '../../assets/website'
import TextSmall from '../../DesignSystem/TypographyDescriptions/TextSmall';
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import PGButtonComponent from '../../DesignSystem/Buttons/PGButtonComponent';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import classNames from 'classnames';
import UserInformationForm from './UserInformationForm';
import { useNavigate } from 'react-router-dom';

const { down_arrow, down_arrow_primary } = shapes;

const CollapsedMenu = ({ navlinks, login_btn, contact_us_btn, toggleCloseMenuBar }) => {

    const navigate = useNavigate();
    const [activeNavLink, setActiveNavLink] = useState(null)
    const [isSubMenuOpen, setSubMenuOpen] = useState({ product: false, pricing: false, resources: false, company: false })

    return (
        <>
            <div className='flex flex-col px-[1.5rem] bg-white overflow-y-auto h-[100%] pt-[calc(60px+76px)] pb-[76px]'>

                {
                    navlinks.map((navLinkSection, index) => {
                        return (
                            <>
                                <div key={index} className='flex flex-col gap-y-[32px]'>
                                    <PGButtonComponent
                                        onClick={() => {
                                            if(navLinkSection.href){
                                                navigate(navLinkSection.href);
                                                toggleCloseMenuBar();
                                                return;
                                            }
                                            setSubMenuOpen((prev) => ({ ...prev, [navLinkSection.name]: !prev[navLinkSection.name] }))
                                        }}
                                        onMouseEnter={() => setActiveNavLink(index)}
                                        onMouseLeave={() => setActiveNavLink(null)}
                                        className='p-0 bg-transparent text-black gap-x-[4px] outline-none w-full justify-between'
                                    >
                                        <TextLarge text={navLinkSection.title} fontWeight={500} className={classNames(" hover:text-primary", { 'text-[#372EE5]': isSubMenuOpen[navLinkSection.name] && navLinkSection?.sub_link?.length > 0 })} />
                                        {navLinkSection?.sub_link?.length > 0 ? isSubMenuOpen[navLinkSection.name] ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon /> : null}
                                    </PGButtonComponent>
                                    {
                                        navLinkSection?.sub_link?.length > 0 && isSubMenuOpen[navLinkSection.name]
                                            ? (
                                                <ul className='p-[0.5rem] flex flex-col gap-y-[24px]'>
                                                    {
                                                        navLinkSection?.sub_link?.map((link, index) => {
                                                            return (
                                                                <div className='w-[12rem] mb-1'>
                                                                    <button key={index} onClick={() => {
                                                                        toggleCloseMenuBar();
                                                                        navigate(link.href)
                                                                    }}>
                                                                        <span className='hover:bg-primary hover:text-white text-[#111827] p-[0.35rem] w-full transition-all cursor-pointer rounded-md'>{link.title}</span>
                                                                    </button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            ) : null
                                    }
                                </div>
                                {
                                    navlinks.length - 1 !== index
                                        ? (
                                            <div className='w-[12rem] mb-1'>
                                                <div>
                                                    <div className='hover:bg-primary hover:text-white text-[#111827] p-[0.35rem] w-full transition-all cursor-pointer rounded-md'></div>
                                                </div>
                                            </div>
                                        ) : null
                                }
                            </>
                        )
                    })
                }

                <UserInformationForm>
                    {
                        ({ handleTogglePopup }) => (
                            <button className='flex' onClick={() => {
                                // toggleCloseMenuBar();
                                // handleTogglePopup({ "form_name": contact_us_btn?.form_name })
                                navigate('/contact-us')
                                toggleCloseMenuBar();
                            }}>
                                <TextLarge text={contact_us_btn.cta_name} fontWeight={500} className={classNames(" hover:text-primary mt-[14px]")} />
                            </button>
                        )
                    }
                </UserInformationForm>

                <PGButtonComponent onClick={() => navigate(login_btn.path_link)} className={'p-[8px] w-full mt-[40px]'}>
                    <TextSmall text={login_btn.cta_name} color='#FFFFFF' />
                </PGButtonComponent>


            </div>
        </>
    )
}

export default CollapsedMenu