import React from 'react'
import { useSelector } from "react-redux"
import DefineFormItem from '../../subcomponents/ROFormComponents/DefineFormItem';
import { ROPreparePayloadIsNullDataCheck } from '../../functionality/RevenueOnboarding/PreparePayloadIsNullDataCheck';
import { RevenueOnboardingFormStatus } from '../../functionality/RevenueOnboarding/CheckIndividualFormIsCompleted';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const ROScreen5 = ({ form, isFormFilledAsExpected, counterToTriggerFormStatus }) => {
  const screen5Form = form.data;
  const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
  const acquiring_app_users = revenueOnboardingData.acquiring_app_users;
  React.useEffect(() => {
    if (counterToTriggerFormStatus > 0) {
      let isFilled = true;
      form.data.map((formItem, index) => {
        let errorCheckingPreInputs = {}
        if(index != 1){
          errorCheckingPreInputs= ROPreparePayloadIsNullDataCheck(revenueOnboardingData, formItem);
        }else{
          formItem.data.map((table) => {
            if(acquiring_app_users.includes(table.selection_name)){
              errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck(revenueOnboardingData, table)
            }
          })
        }
        const status = JSON.stringify(errorCheckingPreInputs) === "{}" ? false : RevenueOnboardingFormStatus(errorCheckingPreInputs);
        errorCheckingPreInputs = {};
        if(status === true){
          isFilled = false;
      }
      });
      isFormFilledAsExpected(isFilled)
    }
  }, [counterToTriggerFormStatus])
  return (
    <div className='flex flex-col gap-y-[24px]'>
      {
        screen5Form?.map((formItem, index) => {
          let errorCheckingPreInputs;
          if(index === 0){
            errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck(revenueOnboardingData, formItem)
            const data = revenueOnboardingData[formItem.name]?.filter(r => r.visible===true)
            return (
              <ComponentErrorBoundary>
                <DefineFormItem tableTooltip={formItem.table.defaultData} errorCheckingPreInputs={errorCheckingPreInputs} key={index} formItem={formItem} data={data} />
              </ComponentErrorBoundary>
            )
          }else if(index === 1){
            return formItem.data.map((table, index) => {
              // console.log(acquiring_app_users,acquiring_app_users.includes(table.selection_name),table.selection_name,"name");
              if(acquiring_app_users.includes(table.dependency)){
                errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck(revenueOnboardingData, table)
                return (
                  <ComponentErrorBoundary>
                    <DefineFormItem errorCheckingPreInputs={errorCheckingPreInputs} key={index} formItem={table} data={revenueOnboardingData[table.name]} />
                  </ComponentErrorBoundary>
                )
              }
            })
          }else {
              errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck(revenueOnboardingData, formItem)
              const data = revenueOnboardingData[formItem.name];
              // console.log(data,"debug")
              return (
                <ComponentErrorBoundary>
                  <DefineFormItem tableTooltip={formItem.table.defaultData} errorCheckingPreInputs={errorCheckingPreInputs} key={index} formItem={formItem} data={data} />
                </ComponentErrorBoundary>
              )
          }
        })
      }
    </div>
  )
}

export default ROScreen5