import React from 'react'
import { Button } from "antd";
import TextLarge from '../TypographyDescriptions/TextLarge';

const LinkButton = ({ isLoading, onClick, text, size="small", isDisabled=false, icon, suffixIcon, textColor="text-mainPrimary", className}) => {
  const padding = size === "small" ? "8px 24px" : size === "medium" ? "12px 40px" : "16px 48px";
  const fontSize = size === "small" ? "16px" : size === "medium" ? "16px" : "18px";
  return (
    <React.Fragment>
      <Button
        type='link'
        onClick={onClick}
        disabled={isDisabled}
        style={{ padding,fontSize,letterSpacing: "0.2px", lineHeight: "140%" }}
        loading={isLoading}
        className={`${isDisabled ? 'text-DisabledColor' : textColor} border-none bg-none flex justify-center h-auto text-[16px] font-semibold ${className}`}
      >
        {icon ? <img className='w-[2rem] pr-[8px]' src={icon} alt="" /> : null}
        <TextLarge fontWeight={500} text={text} />
        {suffixIcon ? <img className='w-[2rem] pr-[8px]' src={suffixIcon} alt="" /> : null}
      </Button>
    </React.Fragment>
  )
}

export default LinkButton