import React, { useState, useEffect } from "react";
import searchNotFilled from "../assets/common/searchNotFilled.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Progress,
    Button,
    Tag,
    Table,
    ConfigProvider,
    Space,
    Tooltip,
    Dropdown,
    Menu,
} from "antd";
import "./index.css";
import DataRoom from "../localization/data_room/localization_en_data_room.json";
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import PlusSVG from "../DesignSystem/SVG/PlusSVG";
import DownloadSVG from "../DesignSystem/SVG/DownloadSVG";
import DeleteSVG from "../DesignSystem/SVG/DeleteSVG";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import FileIC from "../assets/TableIcon/fileIc.png";
import OwnerIC from "../assets/TableIcon/owner.png";
import ReviewTick from "../assets/TableIcon/reviewTick.png";
import CommentIc from "../assets/TableIcon/commentIc.png";
import TagIc from "../assets/TableIcon/tags.png";
import LinkIc from "../assets/TableIcon/LinkIc.png";
import CirclePlus from "../assets/TableIcon/circlePlusIc.png";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import ActionMenuCard from "../DesignSystem/Cards/ActionMenuCard";
import outlineContent from "../assets/common/Outline Content.svg";
import DownloadModal from "../DesignSystem/Cards/ActionMenuOptionModal/DownloadModal";
import {
    PlusOutlined,
    SearchOutlined,
    DownloadOutlined,
    DeleteOutlined,
    FilterOutlined,
    ReloadOutlined,
    LeftOutlined,
} from "@ant-design/icons";
import ActionMenuArchiveCard from "../DesignSystem/Cards/ActionMenuArchiveCard";
import { fetchArchiveFileApi, listDirectoryApi } from "../features/dataRoomSlice";
import ActionMenuArchiveFileCard from "../DesignSystem/Cards/ActionMenuArchiveFilesCard";

export default function ArchiveFileRoom() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const linkInfo = useSelector((state) => state.link);
    const activeItem = useSelector((state) => state.link.activeItem);
    const openKeys = useSelector((state) => state.link.openKeys);
    const username = useSelector((state) => state.user.value.username);
    const [featureData, setFeatureData] = useState({});
    const [flippedCards, setFlippedCards] = useState(
        new Array(featureData["cards"]?.length).fill(false)
    );
    const status = useSelector((state) => state.link.status);
    const progress = useSelector((state) => state.link.progress);
    const onboarding = useSelector((state) => state.onboarding.value.Company);
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [checkedCards, setCheckedCards] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [filesData, setFilesData] = useState([]);
    const [reClick, setReClick] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [folderNames, setFolderNames] = useState([])
    const user = useSelector((state) => state.user);
    const { directoryName } = useParams();

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            try {
                // // First API call to fetch archived files
                // const response = await dispatch(fetchArchiveFileApi(""));
                // const extractedData = response.payload?.listOfArchivedFiles.map((obj, index) => ({
                //     _id: obj._id,
                //     organisationId: obj.organisationId,
                //     userId: obj.userId,
                //     name: obj.name,
                //     path: obj.path,
                //     type: obj.file,
                //     description: obj.description,
                //     archiveStatus: obj.archiveStatus,
                //     tags: obj.tags,
                //     color: obj.color,
                //     size: obj.size,
                //     lockStatus: obj.lockStatus,
                //     createdAt: obj.createdAt,
                //     updatedAt: obj.updatedAt,
                //     fileId: index + 1,
                // }));
                // setFilesData(extractedData);
    
                // Second API calls for each folder
                // const foldersData = response.payload?.listOfDirectories.map(obj => ({
                //     name: obj.name
                // }));
                // setFolderNames(foldersData);
    
                // Use Promise.all to wait for all folder API calls to finish
                const body = { directory: directoryName };
                const response2 = await dispatch(listDirectoryApi(body));
                const data = response2.payload.listOfFiles.map((obj, index) => ({
                    _id: obj._id,
                    organisationId: obj.organisationId,
                    userId: obj.userId,
                    name: obj.name,
                    path: obj.path,
                    type: obj.file,
                    description: obj.description,
                    archiveStatus: obj.archiveStatus,
                    tags: obj.tags,
                    color: obj.color,
                    size: obj.size,
                    lockStatus: obj.lockStatus,
                    createdAt: obj.createdAt,
                    updatedAt: obj.updatedAt,
                    fileId: index + 1,
                }));
                setFilesData(data);
    
                // Wait for all directoryPromises to resolve and flatten the result
                // const directoryResults = await Promise.all(directoryPromises);
                // const allFiles = directoryResults.flat();
    
                // Update the state with the combined data
                
    
            } catch (error) {
                console.error("Error: ", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        getData();
    }, [dispatch, reClick]);
    

    const onSelectChange = (newSelectedRowKeys) => {
        setButtonDisabled(!newSelectedRowKeys.length);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const dataRoomFiles = [
        {
            fileId: 1,
            fileName: "File Name ABC",
            ownerName: "Owner Name",
            status: "in-progress",
            tags: ["Tag1", "Tag2"],
            linkedDocs: 3,
        },
        {
            fileId: 2,
            fileName: "File Name XYZ",
            ownerName: "John Doe",
            status: "completed",
            tags: ["Tag1", "Tag2"],
            linkedDocs: 0,
        },
        {
            fileId: 3,
            fileName: "File Name 123",
            ownerName: "Williamson",
            status: "not-assigned",
            tags: ["Tag1", "Tag2"],
            linkedDocs: 0,
        },
    ];

    const showModalTags = () => {
        setModalTitle("Info")
        setIsModalVisible(true)
        setText("Please unarchive the files to add tags.")
    }
    const columns = [
        {
            title: (
                <div className="flex items-center py-3 ml-2 bg-white">
                    <span className=" font-normal text-[#757575]">File ID</span>
                </div>
            ),
            dataIndex: "fileId",
            key: "fileId",
            render: (_, record) => {
                return (
                    <div className="py-2">
                        <h1 className="text-[#111827] font-medium text- pl-2">
                            {_}
                        </h1>
                    </div>
                );
            },
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    <img src={FileIC} alt="file-ic" className=" w-3 h-3.5 border mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">File Name</h1>
                </div>
            ),
            dataIndex: "fileName",
            key: "fileName",
            render: (_, record) => {
                return (
                    <div className="flex">
                        <h1 className=" text-[#111827] font-medium font-sans ">
                            {record.name}
                        </h1>
                    </div>
                );
            },
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    <img
                        src={OwnerIC}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    />
                    <h1 className="m-0  font-normal text-[#757575]">Owner</h1>
                </div>
            ),
            dataIndex: "ownerName",
            key: "ownerName",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1 relative">
                        <div className="absolute left-[-25px] top-[-0.5px]">
                            {record.lockStatus ? (
                                <div className="scale-125">🔒</div>
                            ) : (
                                null
                            )}
                        </div>
                        <div className=" flex items-center justify-center mr-2 w-5 h-5 rounded-full bg-orange-200">
                            <h1 className=" text-orange-500 m-0 text-[10px]">
                                {(user.value.username)[0]}
                            </h1>
                        </div>
                        <div className=" flex  items-center">
                            <h1 className=" text-[#111827] font-sans text-sm m-0 font-medium tracking-wide">
                                {user.value.username}
                            </h1>
                        </div>
                    </div>
                );
            },
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    <img
                        src={ReviewTick}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    />
                    <h1 className="m-0  font-normal text-[#757575]">Review Progress</h1>
                </div>
            ),
            dataIndex: "status",
            key: "status",
            render: (status) => {
                let color;
                let text;
                switch (status) {
                    case "in-progress":
                        color = "orange";
                        text = "In Progress";
                        break;
                    case "completed":
                        color = "green";
                        text = "Completed";
                        break;
                    case "not-assigned":
                        color = "red";
                        text = "Not Assigned";
                        break;
                    default:
                        color = "blue";
                        text = "Unarchive";
                }
                return (
                    <div className="flex items-center py-0.5 ">
                        <div
                            className={` flex items-center justify-center border border-[${color}] bg-opacity-50 rounded-lg  py-1.5 bg-${color}-200 w-[90%]`}
                        >
                            <h1 className={` text-${color}-500 m-0 text-[11px]`}>{text}</h1>
                        </div>
                    </div>
                );
            },
            width: "14%",
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white pl-4">
                    <img
                        src={CommentIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    />
                    <h1 className="m-0  font-normal text-[#757575]">Comment</h1>
                </div>
            ),
            key: "comments",
            render: (_, record) => {
                return (
                    <div className="flex pl-4 py-0.5">
                        <div className=" text-[#372EE5] font-semibold cursor-pointer ">
                            Add Comment
                        </div>
                    </div>
                );
            },
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    <img
                        src={TagIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    />
                    <h1 className="m-0  font-normal text-[#757575]">Tags</h1>
                </div>
            ),
            key: "tags",
            dataIndex: "tags",
            render: (_, records) => (
                <div className="py-0.5">
                    {records.tags ? (
                        <>
                        {records.tags.split(',').map((tag) => (
                            <Tag color="blue" className="rounded-full" key={tag.trim()}>
                                {tag.trim()}
                            </Tag>
                        ))}
                        <Tag color="cyan" className="rounded-full font-bold">
                            <div onClick={() => { showModalTags(records.tags, records._id) }}>+</div>
                        </Tag>
                        </>
                    ) : (
                        <Tag color="cyan" className="rounded-full font-bold">
                            <div onClick={() => { showModalTags(records.tags, records._id) }}>+</div>
                        </Tag>
                    )}
                </div>
            ),
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    <img
                        src={LinkIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    />
                    <h1 className="m-0  font-normal text-[#757575]">Linked Docs</h1>
                </div>
            ),
            dataIndex: "linkedDocs",
            key: "linkedDocs",
            render: (linkedDocs) => (
                <div className="flex py-0.5">
                    <span className=" text-[#424242] font-medium">{"0 Linked Docs"}</span>
                </div>
            ),
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img
            src={LinkIc}
            alt="file-ic"
            className=" w-3 object-contain h-3 border mr-2"
          />
          <h1 className="m-0  font-normal text-[#757575]">Respond</h1> */}
                    <img
                        src={CirclePlus}
                        alt="file-ic"
                        className="w-3 object-contain h-5 border ml-5"
                    />
                </div>
            ),
            key: "actions",
            render: (_, record) => (
                <div className="py-1.5 w-full flex justify-center">
                    <ActionMenuArchiveFileCard documentId={record?._id} onClose={() => { setReClick(!reClick) }} />
                </div>
            ),
        },
    ];

    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card.id !== cardDetails.id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);

    const [modalTitle, setModalTitle] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [deleteModalTitle, setDeleteModalTitle] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const [isDownloadModalVisible, setIsDownloadModalVisisble] = useState(false);

    //   Add File Modal
    const handleButtonClick = () => {
        setModalTitle("Add File"); // Change the index as needed
        setIsModalVisible(true);
    };
    const [text, setText] = useState(null)

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };
    //   Delete Modal
    const handleDeleteButton = () => {
        setModalTitle("Delete File"); // Change the index as needed
        setIsModalVisible(true);
    };

    const closeDeleteModal = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(null);
    };

    const onDownloadClick = () => {
        setModalTitle("Download");
        setIsModalVisible(true);
    };

    const SkeletonLoader = ({ avatar = false, active = true, loading = true }) => {
        return (
            <div className="bg-neutral-200 animate-pulse w-full h-10 rounded-lg">
            </div>
        );
    };


    return (
        <div className="pt-[6rem] min-h-[100vh]">
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    isOpen={true}
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                    }}
                    text={text}
                />
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
                <Button
                    type="text"
                    icon={<LeftOutlined />}
                    onClick={() => navigate(-1)}
                    className="mb-4 flex items-center justify-center"
                >
                    Back
                </Button>
                <DescribeFeature
                    text={DataRoom.data_room.dasboard.title}
                    description={DataRoom.data_room.dasboard.description}
                />
                <div className="mx-auto">
                    <div className="flex justify-between items-center">
                        <p className="font-DMSans text-[#111827] text-[18px] mt-6">
                            Archive Files
                        </p>
                        <div className="flex gap-2 items-center">
                            <Button
                                icon={<SearchOutlined />}
                                className="flex items-center justify-center bg-[#EBF1FF] text-[#372EE5]"
                            />
                            <Button
                                icon={<ReloadOutlined />}
                                onClick={() => { setReClick(!reClick) }} // Call handleReloadClick on click
                                className="bg-[#F5F5F5] text-[#372EE5] flex items-center justify-center"
                            />
                        </div>
                    </div>
                    <div className="mt-4 overflow-hidden">
                        {isLoading ? (
                            <div className="w-full flex flex-col gap-3">
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Table: {
                                            headerBg: "#FFFFFF",
                                        },
                                    },
                                }}
                            >
                                <Table
                                    columns={columns}
                                    dataSource={filesData}
                                    rowSelection={rowSelection}
                                    rowKey="fileId"
                                    className="border border-[#9E9E9E] rounded-2xl overflow-hidden"
                                    rowClassName={() =>
                                        "bg-white border border-[#9E9E9E] px-3 mb-2"
                                    }
                                    pagination={false}
                                    rowHoverable={false}
                                    components={{
                                        body: {
                                            row: ({ children, ...restProps }) => (
                                                isLoading ? (
                                                    <tr {...restProps}>
                                                        <td colSpan={columns.length} className="w-full text-center">
                                                            <div className="w-full flex justify-center items-center">
                                                                <div className="w-6 h-6 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr {...restProps}>
                                                        {children}
                                                    </tr>
                                                )
                                            )
                                        }
                                    }}
                                />
                            </ConfigProvider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
