import React from 'react'
import { Button } from 'antd'

const CustomLinkButton = ({ text, handleOnClick, isLoading, isDisabled }) => {
    return (
        <Button
            type='link'
            className='text-primary'
            style={{ paddingLeft: 0 }}
            loading={isLoading}
            disabled={isDisabled}
            onClick={handleOnClick}
        >
            {text}
        </Button>
    )
}

export default CustomLinkButton