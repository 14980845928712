import React, { useEffect, useState } from 'react'
import { Input } from "antd";
const { TextArea } = Input;
import { message, Upload } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { updateTextArea, uploadICPAvatarApi, resetStatusErr } from './ICPSlice';
import uploadprofile from "./assets/uploadprofile.svg"
import './icp.css'
import { updateICPTextDataApi } from './ICPSlice';
import UploadImage from '../reuseablecomponent/UploadImage';
import { toggleShowEditICP, uploadAvatarICPLocal } from './ICPSlice';
import MessageNotification from '../reuseablecomponent/MessageNotification';
import locals from "../../localization/localization_en_ideal_customer_profile.json"

const ICPForm = ({ activeTabName }) => {
    const dispatch = useDispatch();
    const permission = useSelector((state) => state.user.permission);
    const editDataFormDetails = useSelector((state) => state.icp.editDataFormDetails);
    const Answer = useSelector((state) => state.icp.Answer);
    const tabNumber = useSelector((state) => state.icp.tabNumber);
        const handleInputChange = (name, value) => {
        dispatch(updateTextArea({ activeTabName, name, value }));
    };
    const showEdit = useSelector((state) => state.icp.showEdit)
    const avatar = useSelector((state) => state.icp.Answer?.Demographics?.avatar);
    const fetchstatus = useSelector((state) => state.icp.fetchstatus)
    const status = useSelector((state) => state.icp.status)
    const error = useSelector((state) => state.icp.error)
    const handleTextareaChange = (e, name) => {
        Answer?.[activeTabName]?.[name] === null || Answer?.[activeTabName]?.[name]?.length===0 ? dispatch(updateTextArea({ activeTabName, name, value: '• ' + e.target.value })) : dispatch(updateTextArea({ activeTabName, name, value: e.target.value }));
    };

    useEffect(() => {
        if (status === "failed") {
            const timer = setTimeout(() => {
            dispatch(resetStatusErr());
            }, 2000)
            return () => clearTimeout(timer);
        }
        }, [error])


    const handleTextareaKeyPress = (e, name) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default behavior of the Enter key
            if (e.target.value.trim() !== '') {
                dispatch(updateTextArea({ activeTabName, name, value: e.target.value + '\n• ' }))
            }
        }
    };
    const handleProfileUpload = (preview) => {
        dispatch(toggleShowEditICP(false));
        dispatch(uploadICPAvatarApi(preview));
        dispatch(uploadAvatarICPLocal(preview));
    }
    const handleShowEdit = (value) => {
        dispatch(toggleShowEditICP(value));
    }
    // console.log(Answer?.templateImage);
    return (
        <div className='relative'>
            {status==="failed" && <MessageNotification status={status} content={error} type={"error"} />}
            {
                editDataFormDetails?.Questions[tabNumber]?.surveyQuestions.map((question, index) => {
                    if (question.type === "input") {
                        return (
                            <div key={index} className='w-[325px] pl-[1.5rem]'>
                                <label htmlFor={question.name} className='text-[#372EE5] font-InterRegular font-medium tracking-wide text-[14px]'>{question.name}</label>
                                <Input
                                    disabled={!permission.edit || fetchstatus=="loading"}
                                    style={{ marginTop: "8px" }}
                                    placeholder={question.inputProps.placeholder}
                                    onBlur={() => dispatch(updateICPTextDataApi({FieldName:question.name,data:Answer?.[activeTabName]?.[question.name]}))}
                                    value={Answer?.[activeTabName]?.[question.name] || ''}
                                    className='block newCustomizedInput'
                                    onChange={(e) => handleInputChange(question.name, e.target.value)}
                                />
                            </div>
                        )
                    } else if (question.type === "textarea") {
                        return (
                            <div key={index} className={`${index===2 && tabNumber == 0 ? 'w-[70%]' : 'w-full'} mt-[16px] pl-[1.5rem]`}>
                                <label htmlFor={question.name} className='text-[#372EE5] font-InterRegular font-medium tracking-wide text-[14px]'>{question.name}</label>
                                <TextArea
                                    disabled={!permission.edit || fetchstatus=="loading"}
                                    className='textareaWithCount mt-[8px]'
                                    showCount
                                    autoSize={{
                                        minRows:question.bulletInclude ? 9 : 3,
                                        maxRows:3
                                    }}
                                    onBlur={() => dispatch(updateICPTextDataApi({FieldName:question.name,data:Answer?.[activeTabName]?.[question.name]}))}
                                    value={Answer?.[activeTabName]?.[question.name] || ''}
                                    onChange={(e) => question.bulletInclude ? handleTextareaChange(e,question.name) : handleInputChange(question.name, e.target.value)}   
                                    onKeyPress={(e) => question.bulletInclude && handleTextareaKeyPress(e,question.name)}
                                    maxLength={question.maxWords}
                                    placeholder={locals.ideal_customer_profile.form.text_area_placeholder}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} className='absolute top-0 right-0'>
                                <UploadImage showEdit={showEdit} handleShowEdit={handleShowEdit} profilePlaceholderText={locals.ideal_customer_profile.form.upload_imgage_placeholder} avatar={Answer?.templateImage} handleProfileUpload={handleProfileUpload} /> 
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default ICPForm