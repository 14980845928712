import React, { useEffect, useState } from 'react'
import { Modal, message, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux"
import { sendNewEmailVerificationLink, resetEmailVerificationStatus } from "../../features/spamSlice";
import { validateEmail } from '../../utils/validation';
import { useNavigate } from 'react-router-dom';
import close from "../../assets/common/close.svg";
import modalLocals from "../../localization/localization_en_verify_email.json"

const unverifiedEmailLocals = modalLocals.verify_email.unverified_email_text;

const VerifyEmailModal = ({ isModalOpen, handleCloseEmailVerificationModal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const newVerificationEmailStatus = useSelector((state) => state.spam.new_verfication_email_status);
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        dispatch(resetEmailVerificationStatus());
    },[])
    useEffect(() => {
        if (newVerificationEmailStatus?.status === "success") {
            message.open({ content: modalLocals.verify_email.success.email_sent, type:"success"})
            handleCloseEmailVerificationModal();
        }
        if (newVerificationEmailStatus?.status === "error")message.open({ type: "error", content: newVerificationEmailStatus.message })
    }, [newVerificationEmailStatus?.status]);
    const handleModalOpen = () => {
        handleCloseEmailVerificationModal();
    }
    return (
        <React.Fragment>
            {contextHolder}
            <Modal
                footer={null}
                title=""
                centered
                open={isModalOpen}
            >
                <Button onClick={handleModalOpen} className='absolute top-1 h-auto border-none right-1'><img src={close} alt="" /></Button>
                <p style={{ margin: 0 }} className='font-InterRegular text-[18px] font-medium tracking-wide'>{unverifiedEmailLocals.title}</p>
                <p className='text-[#757575] text-[14px] tracking-wide font-InterRegular'>{unverifiedEmailLocals.description}</p>
                <p>{unverifiedEmailLocals.form.label}</p>
                <div className='flex flex-col items-center justify-center'>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder={unverifiedEmailLocals.form.placeholder} className='input_forgot_pass' />
                    <Button disabled={newVerificationEmailStatus?.status === "loading"} loading={newVerificationEmailStatus?.status === "loading"} onClick={() => {
                        if (!validateEmail(email)) {
                            messageApi.open({
                                type: 'warning',
                                content: modalLocals.verify_email.warning.invalid_email,
                            });
                        } else {
                            dispatch(sendNewEmailVerificationLink(email));
                        }
                    }} className='bg-primary py-[12px] flex justify-center w-full mt-[24px] rounded-[12px] h-auto text-white'>{unverifiedEmailLocals.submit_btn_text}</Button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default VerifyEmailModal