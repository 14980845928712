import React from 'react';
import { Table } from 'antd';
import setting_locals from "../../../localization/localization_en_setting.json"
import { convertEpochToDate } from '../../../utils/convertEpochToDate';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';

const RenderCell = ({ children, isParentTableRow }) => {
    return (
        <div className={`py-[0.75rem] pl-[0.2rem] ${isParentTableRow ? '' : 'bg-[#F5F5F5]'}`}>
            {children}
        </div>
    )
}

const RenderData = (data, isParentTableRow, planDetails) => {
    return data.map((col) => {
        return (
            {
                ...col,
                "width": 200,
                "title": (
                    <div className={`py-[1rem] pl-[0.2rem] ${isParentTableRow ? 'text-primary' : 'bg-red-500'}`}>{col.title}</div>
                ),
                "render": (value, record) => {
                    const data_index = col.data_index;
                    let val = value[data_index];
                    if (["start_at", "end_at", "billing_start", "billing_end", "paid_at"].includes(data_index)) {
                        return <RenderCell isParentTableRow={isParentTableRow}>{val ? convertEpochToDate(val) : "N/A"}</RenderCell>
                    }
                    if (data_index === "short_url") {
                        return <RenderCell isParentTableRow={isParentTableRow}><a href={val} className='text-primary'><RemoveRedEyeIcon fontSize='small' /></a></RenderCell>
                    }
                    if (data_index === "total_count") {
                        return <RenderCell isParentTableRow={isParentTableRow}>{val + ' ' + "months"}</RenderCell>
                    }
                    if (data_index === "line_items") {
                        // return <pre>{JSON.stringify(record.test, null, 2)}</pre>
                        if (val?.length > 0) {
                            return <RenderCell isParentTableRow={isParentTableRow} >{record.currency_symbol + '' + (Number(val[0].amount / 100)).toLocaleString()}</RenderCell>
                        }
                        return 'N/A'
                    }
                    if (["id"].includes(data_index)) {
                        return <RenderCell isParentTableRow={isParentTableRow} >{val}</RenderCell>
                    }
                    if (data_index === "resume_subscription") {
                        if (["created", "issued"].includes(record["status"]) && isParentTableRow) {
                            return (
                                <PrimaryButton onClick={() => window.open(record["short_url"], '_blank')} text={"Resume"} />
                            )
                        }
                        return <RenderCell isParentTableRow={isParentTableRow}>
                            <span className='invisible'>PG</span>
                        </RenderCell>
                    }
                    return <RenderCell isParentTableRow={isParentTableRow} >{
                        record[data_index]
                            ? typeof record[data_index] === "string" ? record?.[data_index]?.[0]?.toUpperCase() + record?.[data_index]?.slice(1) : record[data_index]
                            : 'N/A'
                    }</RenderCell>
                }
            }
        )
    })
}
const SubscriptionInvoiceList = ({ tableData, currentSubDetail }) => {

    const { api_status: pinfo_status, data: pinfo_data, message: pinfo_message } = useSelector((state) => state.app.pricing_info);

    let planDetails = {};
    (pinfo_data?.planList?.allPlans?.items)?.map((plan) => {
        planDetails = { ...planDetails, [plan?.id]: { "span": plan?.period, "amount": `${plan.item.currency === "USD" ? "$" : "₹"}${plan.item.amount / 100}` } }
    })

    const filterResumeTableColumn = (tableColumns) => !["created"].includes(currentSubDetail.status) ? tableColumns.filter((col) => col.data_index!=="resume_subscription") : tableColumns

    const expandedRowRender = (record) => {
        const data = record.existingInvoices.map((invoice, index) => ({ key: index.toString(), ...invoice, "payment_method": record.payment_method }));

        const NESTED_COLUMNS = RenderData(setting_locals.settings.tabs.Subscription.new_payment.paid_plan_details.table.new_pg_nested_columns, false, "exe")
        const totalColumnWidth = NESTED_COLUMNS?.reduce((total, column) => total + (column.width || 0), 0);
        return (
            <Table
                columns={filterResumeTableColumn(NESTED_COLUMNS)}
                dataSource={data}
                pagination={false}
                scroll={{
                    x: totalColumnWidth,
                }}
                showHeader={false}
                size="middle"
            />
        )
    };


    const data = (tableData?.subscriptionListRazorPayInfo ?? []).map((subscription, index) => {
        const { existingInvoices, existingSubscription } = subscription;
        return ({
            "key": index,
            ...existingSubscription,
            "existingInvoices": existingInvoices.items
        })
    }).map((subp) => {
        return ({
            ...subp,
            "plan_id": planDetails[subp.plan_id] === "yearly" ? "Year" : "Month",
            "subscription_amount": planDetails?.[subp?.plan_id]?.amount
        })
    })

    const PARENT_COLUMNS = RenderData(setting_locals.settings.tabs.Subscription.new_payment.paid_plan_details.table.new_pg_parent_columns, true)

    const totalColumnWidth = PARENT_COLUMNS?.reduce((total, column) => total + (column.width || 0), 0);

    return (
        <>
            <ConfigProvider
                theme={{
                    "components": {
                        "Table": {
                            "cellPaddingBlockMD": 0,
                            "cellPaddingInlineMD": 0
                        }
                    }
                }}
            >
                <Table
                    columns={filterResumeTableColumn(PARENT_COLUMNS)}
                    expandable={{
                        expandedRowRender,
                        defaultExpandedRowKeys: ['0'],
                    }}
                    scroll={{
                        x: totalColumnWidth,
                    }}
                    bordered={false}
                    dataSource={data}
                    size="middle"
                    pagination={{
                        pageSize: data.length,
                        position: ['none', 'none'],
                    }}
                    className='border rounded-md'
                />
            </ConfigProvider>
        </>
    );
};

export default SubscriptionInvoiceList;