import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api/api";
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import routesConfig from "../../services/config/routesConfig.json";
import { v4 as uuidv4 } from 'uuid';
import { downloadBackendFile } from "../../utils/downloadBackendFile";
import { convertAmountInK } from "../../utils/convertAmountInK";

export const API_Fetch_Business_Modal_List = createAsyncThunk(
    "businessModal/API_Fetch_Business_Modal_List",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'list-business-model');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_Business_Modal = createAsyncThunk(
    "businessModal/API_Fetch_Business_Modal",
    async (id) => {
        try {
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-business-model');
            urlGenerator = { ...urlGenerator, url:`${urlGenerator.url}?businessModelId=${id}` }
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_BUSINESS_MODAL = createAsyncThunk(
    "businessModal/API_CREATE_Business_Modal",
    async (newBusinessModalName) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-business-model');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": {businessModelTitle: newBusinessModalName} }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_DELETE_BUSINESS_MODAL = createAsyncThunk(
    "businessModal/API_DELETE_BUSINESS_MODAL",
    async (_, { getState }) => {
        try {
            const state = getState();
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'delete-business-model');
            urlGenerator = {...urlGenerator, "url": `${urlGenerator.url}?businessModelId=${state.businessModal.deleteBusinessModelDetails.businessModelId}`}
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator}
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_MARK_AS_FINAL_BUSINESS_MODAL = createAsyncThunk(
    "businessModal/API_MARK_AS_FINAL_BUSINESS_MODAL",
    async (businessModelId, { getState }) => {
        try {
            const state = getState();
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'final-business-model');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": { businessModelId, "businessModelFinalStatus": true } }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_MARK_AS_FINAL_BUSINESS_MODAL = createAsyncThunk(
    "businessModal/API_GET_MARK_AS_FINAL_BUSINESS_MODAL",
    async (_, { getState }) => {
        try {
            const state = getState();
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'final-business-model-get');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_REPORT_BUSINESS_MODAL_LIST = createAsyncThunk(
    "businessModal/API_GET_REPORT_BUSINESS_MODAL_LIST",
    async (metric, { getState }) => {
        try {
            const state = getState();
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'report-business-model-list');
            if (!!urlGenerator) {
                const wholeListpOrSpeciifcData = metric ? { params: { "reportType": metric } } : {}
                const urlConfig = { ...urlGenerator, ...wholeListpOrSpeciifcData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_DOWNLOAD_REPORT_BUSINESS_MODAL_LIST = createAsyncThunk(
    "businessModal/API_DOWNLOAD_REPORT_BUSINESS_MODAL_LIST",
    async (payload, { getState }) => {
        try {
            const state = getState();
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'report-business-model-download');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "params": payload, "responseType": 'blob'}
                const response = await api.request(urlConfig);
                const apiHeaderfileName = response.headers.get('content-disposition')
                downloadBackendFile({ "response": response.data, "fileName": apiHeaderfileName.split('=')?.[1] || "file" })
                return response;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_BUSINESS_MODAL_SCREEN_LIST = createAsyncThunk(
    "businessModal/API_GET_BUSINESS_MODAL_SCREEN_LIST",
    async (metric, { getState }) => {
        try {
            const state = getState();
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-business-model-screen-data');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, params: { "reportType": metric } }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_WORKIN_CAP_DEBT_CASHFLOW_PL_BALANCE_SHEET_ONBOARDING_SCREEN_SLICE = createAsyncThunk(
    "businessModal/API_GET_WORKIN_CAP_DEBT_CASHFLOW_PL_BALANCE_SHEET_ONBOARDING_SCREEN_SLICE",
    async ({ activeScreen, previousScreen }, { getState, dispatch }) => {
        try {
            const state = getState();
            const paramsData = {
                "businessModelId": state.businessModal.active_BusinessModal_Id,
                "screenId": activeScreen
            };
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-business-model-screen-data');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "params": paramsData };
                const response = await api.request(urlConfig);
                return { ...response.data, "previousScreen": previousScreen, "activeScreen": activeScreen }
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const extractMetricValue = ({ totalData, data_index, targetMetricToExtract="value", targetYear }) => {
    return totalData?.[data_index]?.find((yearData) => yearData?.fiscalYear===targetYear)?.[targetMetricToExtract]?.toFixed(2) || 0;
}

const findTargetFiscalYearProjectionValue = ({ data, targetMetric, currentYear }) => {
    const targetValue = data.yearly?.[targetMetric]?.find((year) => year?.fiscalYear === currentYear)?.value
    return typeof targetValue === "number" ? Number(targetValue?.toFixed(2)) : 0 || 0;
};


export const API_POST_BUSINESS_DASHBOARD_OVERALL_DATA = createAsyncThunk(
    "businessModal/API_POST_BUSINESS_DASHBOARD_OVERALL_DATA",
    async (_, { getState, dispatch }) => {
        try {
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-dashboard');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator };
                const response = await api.request(urlConfig);
                const state = getState();
                const data = response.data;
                const currentYear = data.monthYearValues[0];
                const CURRENCY = state.globalSetting.currencySymbol;

                let catgMap = {};
                data.productCategoryList.map((catg) => {
                    catgMap = { ...catgMap, [catg._id]: catg.title }
                })
                const metricData = {
                    "grossMargin": convertAmountInK(extractMetricValue({ totalData: data.yearly, data_index: "grossMargin", targetYear: currentYear }), CURRENCY),
                    "earningsBeforeInterestTaxesDepreciationAndAmortizationEBITDA": convertAmountInK(extractMetricValue({ totalData: data.yearly, data_index: "earningsBeforeInterestTaxesDepreciationAndAmortizationEBITDA", targetYear: currentYear }), CURRENCY),
                    "bebtToEquityRatio": extractMetricValue({ totalData: data.yearly, data_index: "bebtToEquityRatio", targetYear: currentYear }),
                    "quickRatio": extractMetricValue({ totalData: data.yearly, data_index: "quickRatio", targetYear: currentYear }),
                    "cac": convertAmountInK(extractMetricValue({ totalData: data.yearly, data_index: "totalCAC", targetYear: currentYear }), CURRENCY),
                    "operatingMargin": `${extractMetricValue({ totalData: data.yearly, data_index: "operatingMargin", targetYear: currentYear })} %`,
                    "netMargin": `${extractMetricValue({ totalData: data.yearly, data_index: "netMargin", targetYear: currentYear })} %`,
                    "currentRatio": extractMetricValue({ totalData: data.yearly, data_index: "currentRatio", targetYear: currentYear })
                };
                const financialRatio = {
                    "debtRatio": extractMetricValue({ totalData: data.yearly, data_index: "debtRatio", targetYear: currentYear }),
                    "interestCoverageRatio": extractMetricValue({ totalData: data.yearly, data_index: "interestCoverageRatio", targetYear: currentYear }),
                    "assetTurnoverRatio": extractMetricValue({ totalData: data.yearly, data_index: "assetTurnoverRatio", targetYear: currentYear }),
                    "inventoryTurnoverRatio": extractMetricValue({ totalData: data.yearly, data_index: "inventoryTurnoverRatio", targetYear: currentYear }),
                    "receivablesTurnoverRatio": extractMetricValue({ totalData: data.yearly, data_index: "receivablesTurnoverRatio", targetYear: currentYear })
                }
                const revenue_by_catg_data = data.yearly.totalRevenueByCategory.find((catgFiscalYear) => catgFiscalYear.fiscalYear===currentYear).value
                const total_revenue = Object.entries(revenue_by_catg_data).map((d) => {
                    return { "type": catgMap?.[d?.[0]] ?? d[0], "value": d[1] }
                })

                const net_income = data.yearly.netIncome.map((fiscalYear) => ({ "category": "Net Income", "value": fiscalYear.value?.toFixed(2), "year": fiscalYear.fiscalYear}))
                const total_expenses = data.yearly.totalExpenses.map((fiscalYear) => ({ "category": "Total Expenses", "value": fiscalYear.value?.toFixed(2), "year": fiscalYear.fiscalYear}))
                const total_expense_and_net_income = [...total_expenses, ...net_income];

                const marketing_by_catg_data = data.yearly.totalMarketingCostPerChannel.find((catgFiscalYear) => catgFiscalYear.fiscalYear===currentYear).value
                const marketing_cost = Object.entries(marketing_by_catg_data).map((d) => ({ "category": d[0], "value": d[1] }))       
                
                const average_order_value_data = data.yearly.totalAOVPerCategory.find((catgFiscalYear) => catgFiscalYear.fiscalYear===currentYear).value
                const average_order_value = Object.entries(average_order_value_data).map((d) => ({ "type": catgMap?.[d?.[0]] ?? d[0], "value": d[1] }))

                const operatingCashFlow = data.yearly.operatingCashFlow.map((catg) => ({ "category": "Operating Cash Flow","value":catg.value,"year":catg.fiscalYear }))
                const investingCashFlow = data.yearly.investingCashFlow.map((catg) => ({ "category": "Investing Cash Flow","value":catg.value,"year":catg.fiscalYear }))
                const financingCashFlow = data.yearly.financingCashFlow.map((catg) => ({ "category": "Financing Cash Flow","value":catg.value,"year":catg.fiscalYear }))
                const freeCashFlow = data.yearly.freeCashFlow.map((catg) => ({ "category": "Free Cash Flow","value":catg.value,"year":catg.fiscalYear }))
                const cash_flow_metric = [...operatingCashFlow,...investingCashFlow,...financingCashFlow,...freeCashFlow]
                let metric_sum = {
                    "total_revenue": convertAmountInK(findTargetFiscalYearProjectionValue({ data, targetMetric: "totalRevenue", currentYear }), CURRENCY),
                    "total_expense_and_net_income": `${convertAmountInK(findTargetFiscalYearProjectionValue({ data, targetMetric: "totalExpenses", currentYear }), CURRENCY)}& ${convertAmountInK(findTargetFiscalYearProjectionValue({ data, targetMetric: "netIncome", currentYear }), CURRENCY)}`,
                    "marketing_cost": convertAmountInK(findTargetFiscalYearProjectionValue({ data, targetMetric: "totalMarketingCost", currentYear }), CURRENCY), 
                    "average_order_value": convertAmountInK(findTargetFiscalYearProjectionValue({ data, targetMetric: "totalAOV", currentYear }), CURRENCY),
                    "cash_flow_metric": convertAmountInK(
                        Number(findTargetFiscalYearProjectionValue({ data, targetMetric: "operatingCashFlow", currentYear }) +  findTargetFiscalYearProjectionValue({ data, targetMetric: "investingCashFlow", currentYear }) +  findTargetFiscalYearProjectionValue({ data, targetMetric: "financingCashFlow", currentYear }) +  findTargetFiscalYearProjectionValue({ data, targetMetric: "freeCashFlow", currentYear })),
                        CURRENCY
                    ),       
                    "financial_ratio": null
                }

                metric_sum = {
                    "total_revenue": null,
                    "total_expense_and_net_income": null,
                    "marketing_cost": null,
                    "average_order_value": null,
                    "cash_flow_metric": null,
                    "financial_ratio": null
                }

                return { 
                    "data": { 
                        "cardMetricData": metricData, financialRatio, CurrentYearProjection: currentYear, yearlyProjection: data.monthYearValues, 
                        "chart_data": { total_revenue, total_expense_and_net_income, marketing_cost, average_order_value, cash_flow_metric } },
                        "is_bm_data_exist": Object.keys(data).length>0  || false,
                        metric_sum
                    }
            }
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);

const API_STATUS = {
    "create_business_modal": { "status": 'idle', "message": null },
    "fetch_business_modal" : { "status": 'idle', "message": null },
    "delete_business_modal": { "status": 'idle', "message": null },
    "get_business_modal": { "status": 'idle', "message": null },
    "mark_as_final": { "status": 'idle', "message": null },
    "get_mark_as_final": { "status": 'idle', "message": null },
    "dowload_report_status": { "status": 'idle', "message": null },
    "fetch_business_model_screen_slice": { "status": 'idle', "message": null }
}

const MY_REPORT_API_STATUS = {
    "get_report_bm_list": { "status": 'idle', "message": null }
}

const MODE_TYPE = {
    "create": false,
    "view": false,
    "edit": false
}

const BusinessModalSlice = createSlice({
    name: "business-modal",
    initialState: {
        "is_mark_final_new_or_old": false,
        "mark_as_final_data": null,
        "my_report_list": null,
        "unknown_error_popup": false,
        "deleteBusinessModelDetails": { "popupOpen": false, "businessModelId": null },
        "graph_filter_year_trace": null,
        "tracing_user_filled_input_screen": [],
        "active_BusinessModal_Id": null,
        "api_track_counter": 0,
        "api_status":API_STATUS,
        "my_report_api_status": MY_REPORT_API_STATUS,
        "list_of_business_modal": [],
        "fetched_business_modal": null,
        "MODE_TYPE":MODE_TYPE,
        "fetch_screen_screen_bm_api_status": { status: 'idle', message: null },
        "fetch_screen_screen_data": null,
        "MY_REPORTS": {
            "categoryList": null,
            "active_report_list_data":{
                "Average Order Value": { "activeId": null, "data": [] },
                "Balance Sheet": { "activeId": null, "data": [] },
                "Cash Flow": { "activeId": null, "data": [], "spanType": "monthly" },
                "Marketing Cost": { "activeId": null, "data": [] },
                "Profit & Loss": { "activeId": null, "data": [], "spanType": "monthly" }
            }, 
            ACTIVE_BUSINESS_MODEL_ID: "", // if bm is marked as final - active else 1st created bm will be active - inside dropdown he can custom select other bm
            MODE: "DETAILS",// DETAILS | VIEW | DOWNLOAD
            SELECTED_METRIC: null,
            SELECTED_METRIC_TABLE_DATA: [], 
        },
        "report_download_excel_file": null,
        "is_business_model_dashboard_data_exist": false,
        "fetching_business_modal_dashboard_status": { status: 'idle', message: null },
        "business_modal_dashboard": null,
        "metric_sum": {
            "total_revenue": null,
            "total_expense_and_net_income": null,
            "marketing_cost": null,
            "average_order_value": null,
            "cash_flow_metric": null,
            "financial_ratio": null
        }
    },
    reducers: {
        D_Reset_Screen_Screen_Api_Status(state, action){
            state.fetch_screen_screen_bm_api_status = { status: 'idle', message: null }
            state.api_status.get_mark_as_final.status = 'idle';
        },
        D_Reset_Business_Model_Status(state, action){
            state.fetching_business_modal_dashboard_status = { status: 'idle', message: null };
            state.business_modal_dashboard = null;
        },
        D_Update_Metric_Buisness_Model(state, action){
            const updatedBmMainData = action.payload.data_index==="activeId" ? {"data": state.my_report_list.reportBusinessModelList[state.MY_REPORTS["SELECTED_METRIC"]].find((metricBM) => {
                return metricBM._id===action.payload.value;
            })} : {}
            state.MY_REPORTS.active_report_list_data = { 
                ...state.MY_REPORTS.active_report_list_data, 
                [state.MY_REPORTS["SELECTED_METRIC"]]: { 
                    ...state.MY_REPORTS.active_report_list_data[state.MY_REPORTS["SELECTED_METRIC"]], 
                    [action.payload.data_index]: action.payload.value, ...updatedBmMainData
                } 
            }
        },
        D_Update_Mark_As_Final(state){
            state.is_mark_final_new_or_old = true;
        },
        D_Update_ActiveBusinessModelId(state, action){
            state.active_BusinessModal_Id = action.payload;
        },
        D_UpdateDeleteBusinessModelParamater(state, action){
            state.deleteBusinessModelDetails = action.payload
        },
        D_UpdateFilterYearCharts(state, action){
            state.graph_filter_year_trace = action.payload;
        },
        D_UpdateActiveBusinessModelId(state, action){
            state.active_BusinessModal_Id = action.payload._id;
            const { createdAt, organisationId, title, titleLower,updatedAt,userId,__v, _id, ...screen_filled_inputs } = action.payload || {};
            state.tracing_user_filled_input_screen = Object.keys(screen_filled_inputs)
        },
        D_UpdateMyReportModeType(state, action) {
            const { MODE_TYPE, SELECTED_METRIC } = action.payload;
            if(!["DETAILS","VIEW","DOWNLOAD"].includes(MODE_TYPE)) return;
            state.MY_REPORTS.MODE = MODE_TYPE;
            state.MY_REPORTS.SELECTED_METRIC = SELECTED_METRIC;
        },
        D_Update_Mode_Type(state, action){
            const { type, value } = action.payload;
            if(["create", "view", "edit"].includes(action.payload.type)){
                state.MODE_TYPE = { ...MODE_TYPE, [type]: value };
                return;
            }
            if(action.payload.type === "reset") state.MODE_TYPE = MODE_TYPE
        },
        D_Reset_All_Api_Status(state, action){
            state.api_status = API_STATUS
        },
        D_Reset_Api_Status(state, action){
            const { httpType } = action.payload;
            state.api_status[httpType] = { "status": 'idle', "message": null }
        },
        D_Patch_Global_Setting_BM_List(state, action){
            const { currency, projection_duration, decimal_places, tds_rate_vendor, tds_rate_employee, gst_credit, fiscal_start_year, depreciationRate, tax_rate } = action.payload.gs || {};
            state.list_of_business_modal = state.list_of_business_modal.map((bm) => {
                if(bm._id===state.active_BusinessModal_Id){
                    return {
                        ...bm,
                        "global_setting": {
                            currency: currency,
                            projectionDuration: projection_duration,
                            decimalPlaces: decimal_places,
                            TDSRateVendor: tds_rate_vendor,
                            TDSRateEmployee: tds_rate_employee,
                            GSTCredit: gst_credit,
                            FiscalYearStartMonth: fiscal_start_year,
                            taxRate: tax_rate ? tax_rate : 26,
                            depreciationRate: depreciationRate,
                        }
                    }
                }
                return bm;
            });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_Fetch_Business_Modal_List.pending, (state) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.fetch_business_modal.status = 'loading';
            })
            .addCase(API_Fetch_Business_Modal_List.fulfilled, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.fetch_business_modal.status = 'success';
                state.list_of_business_modal = action.payload.map((bm, index) => ({
                    "key": bm?._id,
                    "_id": bm?._id,
                    "index": String(index+1),
                    "username": bm?.userId?.username,
                    "title": bm.title,
                    "finalStatus": bm?.finalStatus,
                    "global_setting": bm?.globalSettings,
                    "createdAt": bm?.createdAt,
                    "updatedAt": bm?.updatedAt,
                    "balanceSheetStatus": bm?.balanceSheetStatus ? bm?.balanceSheetStatus : false
                }));
                state.is_mark_final_new_or_old = state.list_of_business_modal.find((bm) => bm.finalStatus) ? true : false;
            })
            .addCase(API_Fetch_Business_Modal_List.rejected, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.fetch_business_modal = { status: 'failed', message: action.error.message }
            })
            .addCase(API_CREATE_BUSINESS_MODAL.pending, (state) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.create_business_modal.status = 'loading';
            })
            .addCase(API_CREATE_BUSINESS_MODAL.fulfilled, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.create_business_modal.status = 'success';
                state.active_BusinessModal_Id = action.payload._id;
                state.MODE_TYPE = { ...MODE_TYPE, "create": true }
                state.list_of_business_modal = [...state.list_of_business_modal,{
                    "key": action.payload._id,
                    "_id": action.payload._id,
                    "index": state.list_of_business_modal.length+1,
                    "username": undefined,
                    "title": action.payload.title,
                    "finalStatus": false,
                    "global_setting": null,
                    "createdAt": action.payload.createdAt,
                    "updatedAt": action.payload.updatedAt,
                    "balanceSheetStatus": action.payload?.balanceSheetStatus ? action.payload?.balanceSheetStatus : false
                }]
            })
            .addCase(API_CREATE_BUSINESS_MODAL.rejected, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.create_business_modal = { status: 'failed', message: action.error.message }
            })
            .addCase(API_DELETE_BUSINESS_MODAL.pending, (state) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.delete_business_modal.status = 'loading';
            })
            .addCase(API_DELETE_BUSINESS_MODAL.fulfilled, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.delete_business_modal.status = 'success';
                state.list_of_business_modal = state.list_of_business_modal.filter((bm) => bm._id !== state.deleteBusinessModelDetails.businessModelId)
                state.deleteBusinessModelDetails = { "popupOpen": false, "businessModelId": null }
            })
            .addCase(API_DELETE_BUSINESS_MODAL.rejected, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.delete_business_modal = { status: 'failed', message: action.error.message }
                state.deleteBusinessModelDetails = { "popupOpen": false, "businessModelId": null }
            })
            .addCase(API_Fetch_Business_Modal.pending, (state) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.get_business_modal.status = 'loading';
            })
            .addCase(API_Fetch_Business_Modal.fulfilled, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.get_business_modal.status = 'success';
                state.fetched_business_modal = action.payload;
            })
            .addCase(API_Fetch_Business_Modal.rejected, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.get_business_modal = { status: 'failed', message: action.error.message }
            })
            .addCase(API_MARK_AS_FINAL_BUSINESS_MODAL.pending, (state) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.mark_as_final.status = 'loading';
            })
            .addCase(API_MARK_AS_FINAL_BUSINESS_MODAL.fulfilled, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.mark_as_final.status = 'success';
                state.list_of_business_modal = state.list_of_business_modal.map((bm)=>{
                    if(bm._id === action.payload._id){
                        return {...bm, "finalStatus": true}
                    }
                    return {...bm, "finalStatus": false}
                })
            })
            .addCase(API_MARK_AS_FINAL_BUSINESS_MODAL.rejected, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.mark_as_final = { status: 'failed', message: action.error.message }
            })
            .addCase(API_GET_MARK_AS_FINAL_BUSINESS_MODAL.pending, (state) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.get_mark_as_final.status = 'loading';
            })
            .addCase(API_GET_MARK_AS_FINAL_BUSINESS_MODAL.fulfilled, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.mark_as_final_data = action.payload;
                state.api_status.get_mark_as_final.status = 'success';
            })
            .addCase(API_GET_MARK_AS_FINAL_BUSINESS_MODAL.rejected, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.get_mark_as_final = { status: 'failed', message: action.error.message }
            })
            .addCase(API_GET_REPORT_BUSINESS_MODAL_LIST.pending, (state) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.my_report_api_status.get_report_bm_list.status = 'loading';
            })
            .addCase(API_GET_REPORT_BUSINESS_MODAL_LIST.fulfilled, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.my_report_api_status.get_report_bm_list.status = 'success';
                if(action.payload?.reportType){
                    const metric = action.payload.reportType;
                    let targetMetricName = metric;
                    if(metric === 'average-order-value'){
                        targetMetricName = "Average Order Value";
                    }else if(metric === 'balance-sheet'){
                        targetMetricName = "Balance Sheet";
                    }else if(metric === 'cash-flow'){
                        targetMetricName = "Cash Flow";
                    }else if(metric === 'marketing-cost'){
                        targetMetricName = "Marketing Cost";
                    }else if(metric === 'profit-and-loss'){
                        targetMetricName = "Profit & Loss";
                    }
                    const updatedMetricData = action.payload.reportBusinessModelList[targetMetricName]
                    state.my_report_list.reportBusinessModelList = { ...state.my_report_list.reportBusinessModelList, [targetMetricName]: updatedMetricData };
                    const targetData = updatedMetricData?.find((bmMetric) => {
                        return bmMetric._id===state.MY_REPORTS.active_report_list_data[targetMetricName].activeId
                    })
                    if(targetData){
                        state.MY_REPORTS.active_report_list_data[targetMetricName].data = targetData
                    }
                    return;
                }
                const businessModelList = action.payload?.businessModelList?.map((bm) => ({ "label": bm.title, "value": bm._id }))
                state.my_report_list = { "businessModelList": businessModelList, "reportBusinessModelList": action.payload?.reportBusinessModelList };
                let reports = {};
                for(const metric of Object.entries(action.payload?.reportBusinessModelList)){
                    const activeId = metric[1].length>0 ? metric[1][0]._id : null;
                    const data = metric[1].length>0 ? metric[1][0] : [];
                    reports = { ...reports, [metric[0]] : { activeId, data, spanType: "monthly" } }
                }
                state.MY_REPORTS.active_report_list_data = reports;
                state.MY_REPORTS.categoryList = { "marketPlaceChannelList": action.payload?.defaultMarketplaceChannelList, "productCategoryList": action.payload?.productCategoryList            }
            })
            .addCase(API_GET_REPORT_BUSINESS_MODAL_LIST.rejected, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.my_report_api_status.get_report_bm_list = { status: 'failed', message: action.error.message }
            })
            .addCase(API_DOWNLOAD_REPORT_BUSINESS_MODAL_LIST.pending, (state) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.dowload_report_status.status = 'loading';
            })
            .addCase(API_DOWNLOAD_REPORT_BUSINESS_MODAL_LIST.fulfilled, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.dowload_report_status.status = 'success';
            })
            .addCase(API_DOWNLOAD_REPORT_BUSINESS_MODAL_LIST.rejected, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.dowload_report_status = { status: 'failed', message: action.error.message }
            })
            .addCase(API_GET_BUSINESS_MODAL_SCREEN_LIST.pending, (state) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.fetch_business_model_screen_slice.status = 'loading';
            })
            .addCase(API_GET_BUSINESS_MODAL_SCREEN_LIST.fulfilled, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.fetch_business_model_screen_slice.status = 'success';
            })
            .addCase(API_GET_BUSINESS_MODAL_SCREEN_LIST.rejected, (state, action) => {
                state.api_track_counter = state.api_track_counter + 1;
                state.api_status.fetch_business_model_screen_slice = { status: 'failed', message: action.error.message }
            })
            .addCase(API_GET_WORKIN_CAP_DEBT_CASHFLOW_PL_BALANCE_SHEET_ONBOARDING_SCREEN_SLICE.pending, (state) => {
                state.fetch_screen_screen_bm_api_status.status = 'loading';
            })
            .addCase(API_GET_WORKIN_CAP_DEBT_CASHFLOW_PL_BALANCE_SHEET_ONBOARDING_SCREEN_SLICE.fulfilled, (state, action) => {
                state.fetch_screen_screen_bm_api_status.status = 'success';
                const { activeScreen, screenData } = action.payload || {};
                let isInputPresent = false;
                if(activeScreen==="pnlScreen"){
                    isInputPresent = Object.keys(screenData)?.includes('output')
                }else{
                    isInputPresent = Object.keys(screenData)?.includes('input')
                }
                state.fetch_screen_screen_data = {...action.payload, isInputPresent};
            })
            .addCase(API_GET_WORKIN_CAP_DEBT_CASHFLOW_PL_BALANCE_SHEET_ONBOARDING_SCREEN_SLICE.rejected, (state, action) => {
                state.fetch_screen_screen_bm_api_status = { "status": "failed", "message": action.error.message }
            })
            .addCase(API_POST_BUSINESS_DASHBOARD_OVERALL_DATA.pending, (state) => {
                state.fetching_business_modal_dashboard_status.status = 'loading';
            })
            .addCase(API_POST_BUSINESS_DASHBOARD_OVERALL_DATA.fulfilled, (state, action) => {
                state.fetching_business_modal_dashboard_status.status = 'success';
                state.business_modal_dashboard = action.payload.data;
                state.is_business_model_dashboard_data_exist = true;
                state.metric_sum = action.payload.metric_sum
            })
            .addCase(API_POST_BUSINESS_DASHBOARD_OVERALL_DATA.rejected, (state, action) => {
                state.fetching_business_modal_dashboard_status = { status: 'failed', message: action.error.message }
                state.is_business_model_dashboard_data_exist = false;
            })
    }
})

export const { D_Reset_Business_Model_Status, D_Reset_Screen_Screen_Api_Status, D_Update_Metric_Buisness_Model, D_Update_Mark_As_Final, D_Update_ActiveBusinessModelId, D_Patch_Global_Setting_BM_List, D_UpdateDeleteBusinessModelParamater, D_UpdateFilterYearCharts, D_UpdateActiveBusinessModelId, D_UpdateMyReportModeType, D_Reset_Api_Status, D_Reset_All_Api_Status, D_Update_Mode_Type } = BusinessModalSlice.actions;
export default BusinessModalSlice.reducer;
