import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../services/api/api";
import { setBearerToken } from "../services/api/api";
import { updateAccessToken } from "./userSlice";
import { v4 as uuidv4 } from "uuid";
import { postSegmentDataEndpoint, getSegmentDataEndpoint } from "../services/endPoints/targetMarketAnalysisAPI"
import routesConfig from "../services/config/routesConfig.json"
import { generateUrlEndPoint } from '../utils/endPointsGeneration';

export const postSegmentData = createAsyncThunk(
  "segment/postSegmentData",
  async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'targetMarketAnalysis', 'postSegmentData');
      if (!!urlGenerator) {
        const urlConfig = {
          data: {
            segments: state.tma.segmentData,
            activeColors: state.tma.activeColors,
          }, ...urlGenerator
        }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const getSegmentData = createAsyncThunk(
  "segment/getSegmentData",
  async (_, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'targetMarketAnalysis', 'fetchSegmentData');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator }
        const response = await api.request(urlConfig);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);
const targetMarketAnalysisSlice = createSlice({
  name: "tma",
  initialState: {
    default: {
      segmentName: null,
      APV: null,
      PF: null,
      ACL: null,
      Customers: null,
    },
    segmentData: [],
    showSegmentInput: false,
    segmentUpdateInputs: {},
    segmentType: {
      new: false,
      upte: false,
    },
    latestKey: "",
    defaultColors: ["#6495fa", "#64daab", "#657798", "#f7c12c", "#7666fa", "#75cbee", "#FFF598", "#D9F4FF", "#EFDFFE", "#FFD9D9"],
    activeColors: [],
    status: "idle",
    error: null
  },
  reducers: {
    updateSegmentData: (state, action) => {
      const uniqueKey = uuidv4();
      state.latestKey = uniqueKey;
      if (state.activeColors.length === 0) {
        state.activeColors.push(state.defaultColors[state.activeColors.length]);
      } else {
        for (let i = 0; i < state.defaultColors.length; i++) {
          if (!state.activeColors.includes(state.defaultColors[i])) {
            state.activeColors.push(state.defaultColors[i]);
            break;
          }
        }
      }
      state.segmentData = [
        ...state.segmentData,
        { ...action.payload, key: uniqueKey },
      ];
    },
    updateShowSegmentInput: (state) => {
      state.showSegmentInput = !state.showSegmentInput;
    },
    updateSegmentType: (state, action) => {
      const status = { ...state.segmentType, ...action.payload };
      state.segmentType = status;
    },
    patchSegmentData: (state, action) => {
      if (action.payload.updateSegment) {
        state.segmentData[state.latestKey] = action.payload.updatedData;
        state.segmentUpdateInputs = {};
        state.latestKey = 0;
      } else {
        state.segmentUpdateInputs = action.payload.item;
        state.latestKey = action.payload.key;
      }
    },
    deleteSegment: (state, action) => {
      state.segmentData = action.payload.slicedData;
      state.activeColors.splice(action.payload.index, 1);
    },
    tmaResetStatusWithErr(state, action) {
      state.error = null;
      state.status = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSegmentData.pending, (state, action) => {
        state.status = 'pending'
        console.log("hey pending", state, action);
      })
      .addCase(getSegmentData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.segmentData = action.payload === null ? [] : action.payload.segments;
        state.activeColors= action.payload === null ? [] : action.payload.activeColors;
      })
      .addCase(getSegmentData.rejected, (state, action) => {
        console.log("exe", action.payload);
        state.status = 'failed'
      }).addCase(postSegmentData.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(postSegmentData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.showSegmentInput = false;
      })
      .addCase(postSegmentData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message;
      });
  },
});

export const {
  updateSegmentData,
  updateShowSegmentInput,
  updateSegmentType,
  patchSegmentData,
  deleteSegment,
  tmaResetStatusWithErr
} = targetMarketAnalysisSlice.actions;
export default targetMarketAnalysisSlice.reducer;
