import adminActionMenu from "../../../localization/data_room/localization_en_admin_data_room.json";
import AccessModal from "./AccessModal";
import AccessInvestorModal from "./AccessInvestorModal"
import AddDescriptionModal from "./AddDescription";
import AddFileModal from "./AddFileModal";
import AddFolderModal from "./AddFolderModal";
import AddFolderDescriptionModal from "./AddFolderDescription";
import ColorPallet from "./ColorPallet";
import DeleteFileModal from "./DeleteFileModal";
import DeleteModal from "./DeleteModal";
import DetailModal from "./DetailModal";
import LinkDocumentModal from "./LinkDocumentModal"
import RenameModalAntD from "./RenameModalAnt"
import DownloadModal from "./DownloadModal"
import ShareModal from "./ShareModal"
import DownloadFolderModal from "./DownloadFolderModal";
import InfoBoxModal from "./InfoBoxesModel";
import EditInvestorModal from "./EditInvestorModal";
import AddTagsModal from "./AddTags"
import DetailFolderModal from "./DeltailsFolderModal";
import AskQuestionModal from "./AskNewQuestionModal";
import DiscussionThreadDrawer from "./ViewConversationModal";
import MoveToInvestorModal from "./MoveToInvestorModal";
import LockFileModal from "./LockFileModal";
import ArchiveFileModal from "./ArchiveFileModal";
import MakeCopyModal from "./MakeCopyModal";
import ArchiveFolderModal from "./ArchiveFolderModal";

const ModalSection = ({ title, closeHandler, documentList, text, initialValue, secondaryCloseHandler }) => {
    switch (title) {
        case adminActionMenu.admin_action_menu.actionMenu["5"]:
            return <RenameModalAntD isOpen={true} onClose={closeHandler} documentList={documentList} initialValue={initialValue} />;
        case adminActionMenu.admin_action_menu.actionMenu['19']:
            return <ShareModal isOpen={true} onClose={closeHandler} documentList={documentList}/>
        case adminActionMenu.admin_action_menu.actionMenu["6"]:
            return <DeleteModal isOpen={true} onClose={closeHandler} documentList={documentList} />;
        case adminActionMenu.admin_action_menu.actionMenu["10"]:
            return <DownloadModal isOpen={true} onClose={closeHandler} documentList={documentList} />
        case adminActionMenu.admin_action_menu.actionMenu["4"]:
            return <DetailModal isOpen={true} documentList={documentList} onClose={closeHandler}/>;
        case adminActionMenu.admin_action_menu.actionMenu["24"]:
            console.log(documentList)
            return <DetailFolderModal isOpen={true} onClose={closeHandler} documentList={documentList} />;
        case adminActionMenu.admin_action_menu.actionMenu['14']:
            return <AddDescriptionModal isOpen={true} onClose={closeHandler} documentList={documentList} />
        case adminActionMenu.admin_action_menu.actionMenu['13']:
            return <ColorPallet closedModalHandler={closeHandler}/>
        case adminActionMenu.admin_action_menu.actionMenu['2']:
            return <AccessModal isOpen={true} onClose={closeHandler} documentList={documentList}/>
        case adminActionMenu.admin_action_menu.actionMenu['11']:
            return <LinkDocumentModal isOpen={true} onClose={closeHandler} documentList={documentList}/>
        case adminActionMenu.admin_action_menu.actionMenu['15']:
            return <AddFolderModal isOpen={true} onClose={closeHandler} onSecondaryClose={secondaryCloseHandler}/>
        case adminActionMenu.admin_action_menu.actionMenu['16']:
            console.log(documentList)
            return <AddFileModal isOpen={true} onClose={closeHandler} documentList={documentList} onSecondaryClose={secondaryCloseHandler}/>
        case adminActionMenu.admin_action_menu.actionMenu['17']:
            return <DeleteFileModal isOpen={true} onClose={closeHandler} documentList={documentList} />
        case adminActionMenu.admin_action_menu.actionMenu['18']:
            return <AddFolderDescriptionModal isOpen={true} onClose={closeHandler} documentList={documentList}/>
        case adminActionMenu.admin_action_menu.actionMenu['20']:
            return <DownloadFolderModal isOpen={true} onClose={closeHandler} documentList={documentList}/>
        case "Access Info":
            return <InfoBoxModal isOpen={true} title={"Folders have been successfully granted access to the investors."} onClose={closeHandler}/>
        case "Folder Info":
            return <InfoBoxModal isOpen={true} title={"New Folder has been successfully added to your data room."} onClose={closeHandler}/>
        case "Info":
            console.log("Success")
            return <InfoBoxModal isOpen={true} title={text} onClose={closeHandler}/>
        case adminActionMenu.admin_action_menu.actionMenu['21']:
            return <AccessInvestorModal isOpen={true} onClose={closeHandler} documentList={documentList}/>
        case adminActionMenu.admin_action_menu.actionMenu['22']:
            return <InfoBoxModal isOpen={true} title={"The update to the latest version was succesfull."} onClose={closeHandler}/>
        case adminActionMenu.admin_action_menu.actionMenu['23']:
            return <EditInvestorModal isOpen={true} onClose={closeHandler} documentList={documentList} onSecondaryClose={secondaryCloseHandler}/>
        case "Tags":
            return <AddTagsModal isOpen={true} onClose={closeHandler} documentList={documentList} onSecondaryClose={secondaryCloseHandler}/>
        case adminActionMenu.admin_action_menu.actionMenu['25']:
            return <AskQuestionModal isOpen={true} onClose={closeHandler} documentList={documentList}/>
        case adminActionMenu.admin_action_menu.actionMenu['26']:
            return <DiscussionThreadDrawer isOpen={true} onClose={closeHandler} documentList={documentList}/>
        case adminActionMenu.admin_action_menu.actionMenu['1']:
            return <MoveToInvestorModal isOpen={true} onClose={closeHandler} documentList={documentList} />
        case adminActionMenu.admin_action_menu.actionMenu['7']:
            return <LockFileModal isOpen={true} onClose={closeHandler} documentList={documentList} lock={true}/>
        case adminActionMenu.admin_action_menu.actionMenu['27']:
            return <LockFileModal isOpen={true} onClose={closeHandler} documentList={documentList} lock={false} />
        case adminActionMenu.admin_action_menu.actionMenu['8']:
            return <ArchiveFileModal isOpen={true} onClose={closeHandler} documentList={documentList} archive={true} />
        case adminActionMenu.admin_action_menu.actionMenu['28']:
            return <ArchiveFileModal isOpen={true} onClose={closeHandler} documentList={documentList} archive={false} />
        case adminActionMenu.admin_action_menu.actionMenu['29']:
            return <MakeCopyModal isOpen={true} onClose={closeHandler} documentList={documentList} />
        case adminActionMenu.admin_action_menu.actionMenu['30']:
            return <ArchiveFolderModal isOpen={true} onClose={closeHandler} documentList={documentList} archive={true} />
        case adminActionMenu.admin_action_menu.actionMenu['31']:
            return <ArchiveFolderModal isOpen={true} onClose={closeHandler} documentList={documentList} archive={false} />
    }
};

export default ModalSection;
