import React, { useState } from 'react'
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DescribeFeature from '../reuseablecomponent/DescribeFeature';
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import CreateBusinessModalPopup from './components/CreateBusinessModalPopup';
import { API_Fetch_Business_Modal_List, D_Reset_Api_Status, D_Reset_All_Api_Status, D_Update_Mode_Type, D_UpdateActiveBusinessModelId } from './businessModalSlice';
import BusinessModalList from './subcomponents/BusinessModalList';
// import { API_Fetch_Product_Category_List } from './globalSettingSlice';
import { D_Patch_Business_Modal_Fetch_Data, API_FETCH_DEFAULT_CHANNEL_LIST_Data, API_FETCH_CHANNEL_LIST_Data, D_Reset_Complete_Api_Status } from './revenueSlice';
import Loader from './subcomponents/Loader';
import { API_Fetch_Currency_List_Setting } from "./globalSettingSlice";
import { D_Patch_CostScreen_Fetched_Data_From_DB } from "./costSlice";
import OopsPopUp from '../reuseablecomponent/PopUpVarients/OopsPopUp';
import business_model_locals from '../../localization/localization_en_business_modal.json'
import { fetchWarningPopupErrorMessage } from './functionality/fetchWarningPopupErrorMessage';

const Warning_Message = business_model_locals.business_modal.warning_tooltip;

const BusinessModal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ popUpContent, setPopUpContent ] = React.useState(null);
    const { api_status, list_of_business_modal, api_track_counter, fetched_business_modal, MODE_TYPE, active_BusinessModal_Id } = useSelector((state) => state.businessModal)
    const [loadingState, setLoadingState] = useState({ "screenLoading": false, "postLoading": false, "deleteLoading": false });
    const { active_global_settings } = useSelector((state) => state.globalSetting)
    const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
    const currencyList = useSelector((state) => state.globalSetting.currencyList)
    const default_channel_api_status = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.api_status_category.channel_name.status)
    const social_media_marketplaces = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.social_media_marketplaces)
    const product_category = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.category_list.product_category)
    const channel_name = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.category_list.channel_name)
    const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
    const getRevenueFirstScreenApiStatus = revenueOnboardingData?.api_status?.fetch_screen_slice_status?.status

    React.useEffect(() => {
        dispatch(D_Reset_All_Api_Status())
        dispatch(D_Update_Mode_Type({ type:"reset" }));
        dispatch(API_Fetch_Business_Modal_List());
        // dispatch(API_Fetch_Product_Category_List());
        if(currencyList?.length===0 || !!currencyList===false){
            dispatch(API_Fetch_Currency_List_Setting());
        }
        // return () => dispatch(D_Reset_All_Api_Status())
    }, [])
    React.useEffect(() => {
        if(getRevenueFirstScreenApiStatus === "success"){
            dispatch(D_Reset_Complete_Api_Status());// specific for revenue screen
            const isView = MODE_TYPE.view;
            if(isView){
                if(!revenueOnboardingData?.traceKeyNameFromGetScreenData?.includes("input")){
                    setIsModalOpen(true)
                    setPopUpContent(fetchWarningPopupErrorMessage({ "buttonClickedName": "view" })?.message)
                    return;
                }
                navigate('/product/home/business-modal/revenue-onboarding');
            }else{
                if(active_BusinessModal_Id){
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/revenue-screen`);
                }
            }
        }
    }, [getRevenueFirstScreenApiStatus])
    const handleLoadingAndNotification = ({ httpType, apiStatus }) => {
        const loadingReferences = { 
            "fetch_business_modal": "screenLoading", 
            "create_business_modal": "postLoading", 
            "delete_business_modal": "deleteLoading", 
            "get_business_modal": "screenLoading",
            "mark_as_final": "screenLoading" 
        }
        setLoadingState((prev) => ({...prev, [loadingReferences[httpType]]: "loading" === apiStatus["status"]}));
        if(apiStatus.status === "success" && (httpType === "fetch_business_modal" || httpType === "create_business_modal" || httpType === "delete_business_modal")) dispatch(D_Reset_Api_Status({ httpType }))
        if(apiStatus.message){
            if(apiStatus.status === "success"){
                message.success("success", 2, () => dispatch(D_Reset_Api_Status({ httpType })))
            }else if(apiStatus.status === "failed"){
                message.error(apiStatus.message, 2, () => dispatch(D_Reset_Api_Status({ httpType })))
            }
        }
    }
    React.useEffect(() => {
        const API_STATUS = api_status;
        for(const targetApiStatus of Object.entries(API_STATUS)){
            handleLoadingAndNotification({ "httpType": targetApiStatus[0], "apiStatus": targetApiStatus[1] })
        }
    }, [api_track_counter])
    React.useEffect(() => {
        dispatch(API_FETCH_DEFAULT_CHANNEL_LIST_Data());
    }, [])
    const handleCloseModalClose = () => setIsCreateModalOpen(false);
    const IS_DISABLED = loadingState.deleteLoading || loadingState.screenLoading || loadingState.postLoading || default_channel_api_status==="loading";
    const { description1, description2, description3 } = Warning_Message.Business_Model || {}
    return (
        <div className='mt-[4.5rem] flex flex-col gap-y-[1rem]'>
            { loadingState.screenLoading || getRevenueFirstScreenApiStatus==="loading" ? <Loader/> : null }
            {
                description1
                ? (
                    <OopsPopUp
                        isOpen={isModalOpen}
                        description1={popUpContent?.description1}
                        description2={popUpContent?.description2}
                        description3={popUpContent?.description3}
                        onClick={() => setIsModalOpen((prev) => !prev)}
                    />
                ) : null
            }
            <DescribeFeature text={"My Business Model List"} description={""} />
            { isCreateModalOpen ? <CreateBusinessModalPopup handleCloseModal={handleCloseModalClose} isModalOpen={isCreateModalOpen} /> : null }
            <div>
                <BusinessModalList IS_LOADING={api_status.fetch_business_modal.status==="loading"} isDisabled={IS_DISABLED} data={list_of_business_modal} />
            </div>
            <div className='flex justify-center'>
                <PrimaryButton isDisabled={IS_DISABLED} onClick={() => {
                    if(list_of_business_modal.length<business_model_locals.business_modal.business_modal.business_modal_list.table.max_no_rows){
                        setIsCreateModalOpen(true)
                        return;
                    }
                    setIsModalOpen(true)
                    setPopUpContent(Warning_Message.Business_Model)
                }} text={"Start Creating"} />
            </div>
        </div>
    )
}

export default BusinessModal