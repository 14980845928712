import React from 'react'
import { message, Modal } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import tooltip_white from "../../../assets/common/tooltip_white.svg"
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';
import ToolTip from '../../../DesignSystem/ToolTip/ToolTip';
import FormList from './FormList';
import close_white from "../../../assets/common/close_white.svg"
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton';
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';
import { D_Toggle_ChannelTooltip, D_Capture_TableData, D_Update_Row_Data_Status } from '../gtmSlice';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const PopUpFormModal = ({ form, incompleteForm, isModalOpen, CRUDType, children, formData, UpdateBudgetData, module_type, rowIndex, currency, patchRowFormPopup, toggleShowErrorMessage, toggleHideErrorMessage }) => {
    const dispatch = useDispatch()
    const { crud_form_type } = useSelector((state) => state.gtm.gtm_strategy.gtm_plan);
    React.useEffect(() => {
        return () => dispatch(D_Toggle_ChannelTooltip())
    }, [])
    const checkFormFilled = () => {
        let checkFormFilledStatus = true;
        for(const field of Object.entries(formData)){
            if(field[1]===null) checkFormFilledStatus = false;
            if(field?.[1]){
                if(typeof field[1]==="object" && !Array.isArray(field[1])){
                    for(const attr of Object?.entries(field[1])){
                        if(attr[1]===null) checkFormFilledStatus = false;
                    }
                }
            }
        }
        if(checkFormFilledStatus){
            patchRowFormPopup({ formData, rowIndex, currency });
            dispatch(D_Capture_TableData({ module_type, "MODE_TYPE": CRUDType, "data_index": CRUDType==="EDIT" ? null : "budget", "value": formData, rowIndex, currency }))
            dispatch(D_Update_Row_Data_Status({ module_type, "isEditChannelModalOpen": false, "crud_form_type": null, "rowIndex": null }))
            UpdateBudgetData && UpdateBudgetData({ type: "reset" })
        }else{
            toggleShowErrorMessage();
            // message.warning(incompleteForm);
        }
    }
    return (
        <Modal
            centered
            open={isModalOpen}
            className='form_modal'
            style={{ minWidth: "50rem", minHeight: "10rem", borderRadius: "16px", backgroundColor: "red", overflow: "hidden" }}
        >
            <div className='bg-primary text-white flex items-center justify-end py-[16px]'>
                <div className='absolute left-1/2 -translate-x-1/2'>
                    <TextLarge text={crud_form_type==="VIEW" ? form?.form_title : "Edit"} fontWeight={500} />
                </div>
                <div className='pr-[2rem] cursor-pointer'>
                    {CRUDType === "VIEW" ? <InfoOutlinedIcon onClick={() => dispatch(D_Toggle_ChannelTooltip())} /> : null}
                    {CRUDType === "EDIT" ? <CloseOutlinedIcon onClick={() => {
                        dispatch(D_Update_Row_Data_Status({ module_type, "isEditChannelModalOpen": false, "crud_form_type": null, "rowIndex": null }))
                        toggleHideErrorMessage();
                    }} /> : null}
                </div>
            </div>
            <div className='p-[24px]'>
                {children}
                <div className='flex justify-between'>
                    <SecondaryButton text="Cancel" onClick={() => {
                        dispatch(D_Update_Row_Data_Status({ module_type, "isEditChannelModalOpen": false, "crud_form_type": null, "rowIndex": null }))
                        toggleHideErrorMessage()
                    }} />
                    <PrimaryButton text="Done" onClick={checkFormFilled} />
                </div>
            </div>
        </Modal>
    )
}

export default PopUpFormModal