import React, { useEffect, useState } from 'react'
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import settinglocals from "../../../localization/localization_en_setting.json"
import HeadingFour from '../../../DesignSystem/TypographyHeadings/HeadingFour';
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium';
import HeadingFive from '../../../DesignSystem/TypographyHeadings/HeadingFive';
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';
import TextExtraSmall from '../../../DesignSystem/TypographyDescriptions/TextExtraSmall';
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';
import SwitchButton from '../../../DesignSystem/Buttons/SwitchButton';
import HeadingSix from '../../../DesignSystem/TypographyHeadings/HeadingSix';
import useApp from '../../../hooks/useApp';
import { API_FETCH_PRICING_INFO, API_FETCH_PAYMENT_GATEWAY, API_INITIATE_SUBSCRIPTION, D_Reset_Payment_Error_Message } from '../../../features/appSlice';
import SkeletonLoader from '../../../DesignSystem/Loader/SkeletonLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatpricinglist } from '../utility/formatpricinglist';

const {
    buttons,
    payment_details_popup
} = settinglocals.settings.tabs.Subscription;

const {
    title,
    description1,
    description2,
    package_details,
    pricing
} = payment_details_popup

const PrePaymentDetail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { api_status: pinfo_status, data: pinfo_data, message: pinfo_msg } = useSelector((state) => state.app.pricing_info);
    const { api_status: pgate_status, data: pgate_data, message: pgate_msg } = useSelector((state) => state.app.payment_gateway_list);
    const { api_status: pinit_status, data: pinit_data, message: pinit_msg } = useSelector((state) => state.app.initiate_subscription);

    const { handleNavigateToHome } = useApp();

    const [activeCurrency, setActiveCurrency] = useState("inr");
    const [spanType, setSpanType] = useState('year');
    const [activePlanId, setActivePlanId] = useState(null);

    useEffect(() => {
        dispatch(API_FETCH_PRICING_INFO());
        dispatch(API_FETCH_PAYMENT_GATEWAY());
    }, [])

    useEffect(() => {
        if (pinfo_status === "failed") {
            pinfo_msg && message.error(pinfo_msg)
            dispatch(D_Reset_Payment_Error_Message({ "target_state": "pricing_info" }))
        }
        if (pgate_status === "failed") {
            pgate_msg && message.error(pgate_msg)
            dispatch(D_Reset_Payment_Error_Message({ "target_state": "payment_gateway_list" }))
        }
        if (pinit_status === "failed") {
            pinit_msg && message.error(pinit_msg)
            dispatch(D_Reset_Payment_Error_Message({ "target_state": "initiate_subscription" }))
        }
        if (pinit_status === "success") {
            if (pinit_data?.newSubscription?.short_url) {
                dispatch(D_Reset_Payment_Error_Message({ "target_state": "initiate_subscription" }))
                window.open(pinit_data?.newSubscription?.short_url, '_blank');
                navigate('/product/home/setting/subscription');
            }
        }
    }, [pgate_status, pinfo_status, pinit_status])

    const transformData = (data) => {
        const plans = data?.allPlans?.items ?? [];
        // console.log(data?.allPlans, "plans")
        return plans?.map(plan => {
            const inrPrice = `₹ ${(plan.item.amount / 100).toLocaleString()} /-`;
            const usdPrice = `$ ${(plan.item.amount / 8000).toFixed(2)} /-`;
            return {
                "inr": {
                    "plan_id": plan.id,
                    "span_type": plan.period === "yearly" ? "year" : "month",
                    "price": `${plan.period.charAt(0).toUpperCase() + plan.period.slice(1)} - ${inrPrice}`
                },
                "usd": {
                    "plan_id": plan?.id ?? null,
                    "span_type": plan.period === "yearly" ? "year" : "month",
                    "price": `${plan.period.charAt(0).toUpperCase() + plan.period.slice(1)} - ${usdPrice}`
                },
                "discount": plan.period === "yearly" ? "Save 20%" : null
            };
        });
    };

    const { inr_list=[], usd_list=[] } = formatpricinglist(pinfo_data) || {};

    return (
        <div className='w-full min-h-[calc(100vh)] flex items-center'>
            <div className='flex flex-col gap-y-[24px] max-w-[773px] bg-p9 m-auto mt-[6rem] mb-[1rem] p-[24px] rounded-2xl border border-p7'>
                <div>
                    <div className='flex flex-col items-center justify-center mb-[10px]'>
                        {/* <img src={subscription_icons.upgrade_arrow} className='mb-[24px]' alt="" /> */}
                        <HeadingFour headingCSSStyles={{ margin: 0 }} text={title} />
                    </div>
                    <div className='text-center'>
                        <TextMedium fontWeight={500} text={description1} />
                    </div>
                    <div className='text-center'>
                        <TextMedium fontWeight={500} text={description2} />
                    </div>
                </div>
                <div className='flex flex-col gap-y-[16px]'>
                    <HeadingSix headingCSSStyles={{ margin: 0 }} text={package_details.title} />
                    <ul className='flex flex-col gap-y-[12px]'>
                        {
                            package_details.list.map((feat, index) => {
                                return (
                                    <li key={index} className='flex gap-x-[8px] items-center'>
                                        <div className='w-[36px] aspect-square p-1 rounded-md shadow-lg shadow-p3/30 border bg-white border-p3 flex items-center justify-center'>
                                            <DoneIcon className='text-primary' />
                                        </div>
                                        <TextLarge text={feat.featue} fontWeight={600} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div>
                    <div className='flex justify-center'>
                        <HeadingSix headingCSSStyles={{ margin: '0.5rem' }} text={payment_details_popup.plan_name} />
                    </div>
                    <div className='p-[16px] border border-p7 rounded-md'>
                        <div className='flex items-center gap-x-[8px] mb-[16px]'>
                            <TextLarge fontWeight={500} text={"INR"} color={true ? '#372EE5' : '#757575'} />
                            <SwitchButton
                                checked={activeCurrency === "usd"}
                                isDisabled={false}
                                handleOnChange={() => {
                                    setActiveCurrency((prev) => prev === "inr" ? "usd" : "inr")
                                    setActivePlanId(null);
                                }}
                            />
                            <TextLarge fontWeight={500} text={"USD"} color={false ? '#372EE5' : '#757575'} />
                        </div>
                        <div className='flex flex-col gap-y-[12px]'>
                            {
                                pinfo_status === "loading" && (
                                    Array.from({ length: 2 }).map((_, index) => (
                                        <SkeletonLoader
                                            key={index}
                                            height={60}
                                        />
                                    ))
                                )
                            }
                            {
                                pinfo_status === "success" && (
                                    (activeCurrency==="inr" ? inr_list : usd_list)?.map((price, index) => {
                                        const isActiveSpan = price.plan_id === activePlanId;
                                        return (
                                            <div className='relative cursor-pointer bg-white rounded-md' onClick={() => setActivePlanId(price.plan_id)}>
                                                {/* {
                                                    price.discount
                                                        ? (
                                                            <div className='border border-[#018732] bg-[#E5FFEE] rounded-full absolute top-[50%] -translate-y-1/2 right-[1rem] px-[0.5rem] pb-[0.2rem]'>
                                                                <TextExtraSmall text={price.discount} fontWeight={600} color={"#018732"} />
                                                            </div>
                                                        ) : null
                                                } */}
                                                <div key={index} className={`p-[20px] border rounded-md flex items-center divide-x divide-[#9E9E9E] ${isActiveSpan ? 'border-primary' : ''}`}>

                                                    <div className='pr-[16px]'>
                                                        <div
                                                            className={`w-[1.5rem] aspect-square rounded-full border flex items-center justify-center ${isActiveSpan ? 'border-primary' : 'border-baseGray400'}`}
                                                        >
                                                            <div className={`w-[1rem] h-[1rem] rounded-full aspect-square' ${isActiveSpan ? 'bg-primary' : ''}`}></div>
                                                        </div>
                                                    </div>
                                                    <HeadingFive headingCSSStyles={{ margin: 0 }} text={price?.price} className={'pl-[16px]'} />
                                                </div>

                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    </div>
                </div>
                <PrimaryButton
                    isDisabled={pinit_status === "loading" || activePlanId===null}
                    isLoading={pinit_status === "loading"}
                    onClick={() => {
                        if (pgate_data?.length === 1) {
                            // let selected_plan = null;
                            // api_pricing_info.map((item) => {
                            //     if (item[activeCurrency].span_type === spanType) {
                            //         selected_plan = item[activeCurrency].plan_id;
                            //     }
                            // })
                            dispatch(API_INITIATE_SUBSCRIPTION({
                                "plan_id": activePlanId
                            }));
                        }
                        if (pgate_data?.length === 2) {

                        }
                    }}
                    text={buttons.buy_now} styles={{ "justifyContent": "center" }} />
                <div className='flex justify-center'>
                    <button onClick={handleNavigateToHome}>
                        <TextLarge text={buttons.use_plan} fontWeight={600} className={'text-[#757575] underline'} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PrePaymentDetail