import CardRadioInput from "../../../../../DesignSystem/Forms/RadioVarients/CardRadioInput"

const RadioInput = (props) => {
    const { data, selectOptions, name, isMulti, handleRadioSelectChange, isDisabled=false } = props;
    return (
        <div className="flex gap-[8px] flex-wrap h-full justify-start">
            {
                selectOptions.map((option, index) => {
                    return (
                        <div key={index} className="w-[250px] max-h-fit">
                            <CardRadioInput
                                value={option.value}
                                isMulti={isMulti}
                                name={name}
                                key={index}
                                text={option.label}
                                tooltip={option.tooltip}
                                isDisabled={option.isDisabled || isDisabled}
                                handleOnClick={handleRadioSelectChange}
                                isChecked={isMulti ? data?.includes(option.value) : data === option.value}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default RadioInput