import React from 'react';
import { Table } from 'antd';
const extraColumns = Array.from(Array(500)).map((_,index) => {
    return ({
        key:index,
        title: `Age${index}`,
        width: 100,
        dataIndex: 'age',
        return: (text,record,index) => {
            return (
                <h1 className='bg-white'>{index}world</h1>
            )
        }
    })
})
const columns = [
    {
        title: 'Full Name',
        width: 100,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        render: (text,record,index) => {
            return (
                <h1 className='bg-white'>{text}</h1>
            )
        }
    },
    {
        title: 'Age',
        width: 100,
        dataIndex: 'age',
        key: 'age',
        fixed: 'left',
        return: (text,record,index) => {
            return (
                <h1 className='bg-white'>{text}</h1>
            )
        }
    },
    ...extraColumns
];
const data = [];
for (let i = 0; i < 500; i++) {
    data.push({
        key: i,
        name: `Edward ${i}`,
        age: 32,
        address: `London Park no. ${i}`,
    });
}
const Test = () => (
    <Table
        columns={columns}
        dataSource={data}
        scroll={{
            x: 1500,
            y: 300,
        }}
    />
);
export default Test;