import React from 'react'
import { useDispatch } from "react-redux"
import responseLogo from "../../components/AIService/assets/responseLogo.svg"
import { toggleWelcomeAi, updateProductFeature } from '../../components/AIService/promptSlice';
import WarningLimitedResourceOopsPopup from '../../components/reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';
import { paywall_locals } from '../../localization';
import useApp from '../../hooks/useApp';

const AIButton = ({ featureName }) => {
    const dispatch = useDispatch();
    const { upgradeRenewPaywallPopup } = useApp();
    return (
        <WarningLimitedResourceOopsPopup
            description1={paywall_locals.target_market_analysis[upgradeRenewPaywallPopup].ai.description1}
            description2={paywall_locals.target_market_analysis[upgradeRenewPaywallPopup].ai.description2}
        >
            {({ handleTogglePopup, isFreeSubscription }) => {
                return (
                    <button
                        onClick={() => {
                            if (isFreeSubscription) {
                                handleTogglePopup();
                                return;
                            }
                            dispatch(toggleWelcomeAi());
                            dispatch(updateProductFeature(featureName));
                        }}
                        className='flex items-center bg-primary text-white rounded-[8px] p-[4px] fixed bottom-[1rem] right-[1rem] z-10'
                    >
                        <img className="w-[30%]" src={responseLogo} />
                        <span className="ml-[0.5rem]">Ask AI</span>
                    </button>
                )
            }}
        </WarningLimitedResourceOopsPopup>
    )
}

export default AIButton