import React from 'react';
import { Select } from 'antd';
import './searchStyle.css'
import searchNotFilled from "../../assets/common/searchNotFilled.svg";


const NormalSearch = ({ placeholder, searchList, cbTakeSelectedSearch }) => {

  const handleChange = (value) => {
    cbTakeSelectedSearch(value);
  };

  return (
    <React.Fragment>
      <Select
        className='customSearchSelectStyle'
        mode="tags"
        style={{
          width: '100%',  
        }}
        placeholder={placeholder}
        size='large'
        suffixIcon={<img src={searchNotFilled} />}
        onChange={handleChange}
        tokenSeparators={[',']}
        options={searchList}
      />
    </React.Fragment>
  )
}
export default NormalSearch;