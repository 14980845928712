import React from 'react'
import defaultPlaceholder from "../../assets/Home/userSkeleton.svg"

const NotificationMessage = ({splittedtext, activity, difference}) => {
    return (
        <div className='flex py-[18px] px-[24px] bg-[#F5F5F5] border-b border-[#E0E0E0]'>
            <div className="profile">
                <div className='w-[30px] h-[30px] rounded-full'>
                    <img src={activity?.userId?.avatar.length > 0 ? activity?.userId?.avatar : defaultPlaceholder} alt="" />
                </div>
            </div>
            <div className="info ml-[8px]">
                <div>
                    {
                        splittedtext.map((text) => {
                            if (text.length == 0) {
                                return <span>{activity.userId.username}</span>
                            } else {
                                return <span className='text-[#757575]'>{text}</span>
                            }
                        })
                    }
                </div>
                <p style={{ marginBottom: 0 }} className='text-[#757575] mt-[8px] font-InterRegular text-[12px] font-medium tracking-wide'>{`${difference}`}</p>
            </div>
        </div>
    )
}

export default NotificationMessage