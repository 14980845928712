import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'

const TextMedium = ({ fontWeight=700, color="#111827", text, margin, padding, isDisplay=true, display="inline", wordType="break-words", className }) => {
  const styles = {
    color: color,
    lineHeight: "140%",
    fontWeight,
    ...generatePadding(padding?.direction, padding?.number),
    ...generateMargin(margin?.direction, margin?.number),
    display: isDisplay ? display : ''
  }
  return (
    <React.Fragment>
        <span style={styles} className={`${wordType} text-[14px] font-bold font-InterRegular ${className}`}>{text}</span>
    </React.Fragment>
  )
}

export default TextMedium