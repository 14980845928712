import React, { useEffect, useState } from 'react'
import DescribeFeature from "../reuseablecomponent/DescribeFeature";
import Show from "./assets/Show.svg"
import Edit from "./assets/Edit.svg"
import CloseSquare from "./assets/CloseSquare.svg"
import { useNavigate } from "react-router-dom";
import { resetSaveApi, updateTemplateNameToDownload, updateIsWorkSpaceHasName, deleteWorkSpace, customTemplateDifferatiation, toggleSearchScreen, fetchingAndPostingCustomICPDataApi, toggleCustomTemplate, getAllWorkSpaceDataApi, updateActiveDocId, updateDefaultCustomAns, updateCommingFrom, updateEditFeature, updateTabNumber, updateTemplateTitle, updateEditformDetails, resetPatchTemplate, updateICPTemplateNameApi, resetICPState, updateActiveTemplateType } from './ICPSlice';
import { useDispatch, useSelector } from "react-redux";
import { deleteWorkSpaceApi, fetchICPDraftDataApi, fetchAnsICPDataApi } from './ICPSlice';
import Search from "./assets/Search.svg";
import { Button } from "antd";
import Delete from "./assets/Delete.svg";
import Download from "./assets/Download.svg";
import SearchComponent from './SearchComponent';
import responseLogo from "../AIService/assets/responseLogo.svg"
import { updateProductFeature } from '../AIService/promptSlice';
import MessageNotification from "../reuseablecomponent/MessageNotification"
import { FloatButton } from 'antd';
import { toggleWelcomeAi } from '../AIService/promptSlice';
import AIWelcome from '../reuseablecomponent/PopUpVarients/AIWelcome';
import { postUserActivityDataApi } from '../UserActivity/userActivitySlice';
import icpLocals from "../../localization/localization_en_ideal_customer_profile.json"
import { icpImageAndBgColor } from "./data";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RedirectModuleToCardView from '../reuseablecomponent/RedirectModuleToCardView';
import TextEditModal from '../reuseablecomponent/PopUpVarients/TextEditModal';
import FilterSelectOption from '../../DesignSystem/Forms/FilterSelectOption';
import ChooseTemplatePopup from './subcomponents/ChooseTemplatePopup';
import BlurContainer from '../../DesignSystem/Container/BlurContainer';
import useApp from '../../hooks/useApp';
import { paywall_locals } from '../../localization';
import WarningLimitedResourceOopsPopup from '../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';

const PAYWALL_LOCALS = paywall_locals.ideal_customer_profile;

const defaultB2BTemplate = icpLocals.ideal_customer_profile.defaultCustomB2bTemplateValues;
const defaultB2CTemplate = icpLocals.ideal_customer_profile.defaultCustomTemplateValues;
const defaultCustomTemplateValues = icpLocals.ideal_customer_profile.defaultCustomTemplateValues;
const icp = icpLocals.ideal_customer_profile.idealCustomerProfile;
const rename_template_locals = icpLocals.ideal_customer_profile.rename_icp_template;

const ICPDashboard = () => {
    const locals = icpLocals.ideal_customer_profile;
    const { isFreeSubscription } = useApp();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const workSpace = useSelector((state) => state.icp.workSpace)
    const draftIcp = useSelector((state) => state.icp.draftICP);
    const isSearchOpen = useSelector((state) => state.icp.isSearchOpen);
    const permission = useSelector((state) => state.user.permission);
    const renameICPTemplateName = useSelector((state) => state.icp.patchTemplateNameStatus);
    const { active_template_type } = useSelector((state) => state.icp);
    const [filterTemplate, setFilterTemplate] = useState("all");
    const [isOpen, setIsOpen] = useState(false);
    const [activeCustomTemplate, setActiveCustomTemplate] = useState(null); // b2b
    const { upgradeRenewPaywallPopup } = useApp();

    const handleToggletemplate = (val) => setActiveCustomTemplate(val);
    useEffect(() => {
        dispatch(resetICPState());
        dispatch(fetchICPDraftDataApi());
        dispatch(getAllWorkSpaceDataApi());
        dispatch(customTemplateDifferatiation(null))
        dispatch(updateActiveDocId({ commingFrom: "deleteActiveDocId", activeDocId: null }));
        dispatch(updateIsWorkSpaceHasName(""));
        dispatch(resetSaveApi())
    }, [])
    const handleAddTemplate = () => {
        // dispatch(updateActiveDocId({ commingFrom: "deleteActiveDocId", activeDocId: null }));
        dispatch(toggleCustomTemplate());
        dispatch(customTemplateDifferatiation("addTemplate"))
        dispatch(updateActiveTemplateType(activeCustomTemplate))
        // if (draftIcp?.["Add New Template"].docId) {
        //     dispatch(fetchingAndPostingCustomICPDataApi());
        // } else
        if (draftIcp?.["Custom B2C Template"].docId && activeCustomTemplate === "b2c") {
            dispatch(fetchingAndPostingCustomICPDataApi({ templateType: "Custom B2C Template" }));
        } else if (draftIcp?.["Custom B2B Template"].docId && activeCustomTemplate === "b2b") {
            dispatch(fetchingAndPostingCustomICPDataApi({ templateType: "Custom B2B Template" }));
        } else {
            dispatch(updateDefaultCustomAns(activeCustomTemplate === "b2b" ? defaultB2BTemplate : defaultB2CTemplate));
            // dispatch(fetchingAndPostingCustomICPDataApi());
        }
        navigate('/product/home/buildmarketanalysis/idealcustomerprofile/view');
    }
    const handleViewWorkSpace = (space) => {
        if (space.TemplateName === undefined) {
            dispatch(updateDefaultCustomAns(defaultCustomTemplateValues));
            dispatch(updateActiveDocId({ commingFrom: "workspace", activeDocId: space._id }));
            dispatch(toggleCustomTemplate());
            navigate('/product/home/buildmarketanalysis/idealcustomerprofile/view');
        } else {
            dispatch(updateActiveDocId({ commingFrom: "workspace", activeDocId: space._id, TemplateName: space.TemplateName }));
            dispatch(updateTemplateTitle(space.TemplateName));
            dispatch(fetchAnsICPDataApi());
            dispatch(updateEditformDetails({ title: space.TemplateName }));
            dispatch(updateCommingFrom("workspace"));
            navigate('/product/home/buildmarketanalysis/idealcustomerprofile/view');
        }
    }
    const handleEditWorkSpace = (space) => {
        dispatch(updateTemplateNameToDownload(space.TemplateName));
        if (space.TemplateName === undefined) {
            dispatch(customTemplateDifferatiation("workspaceTemplate"))
            dispatch(updateDefaultCustomAns(defaultCustomTemplateValues));
            dispatch(updateActiveDocId({ commingFrom: "workspace", activeDocId: space._id }));
            dispatch(toggleCustomTemplate());
            navigate('/product/home/buildmarketanalysis/idealcustomerprofile/view');
        } else {
            dispatch(updateActiveDocId({ commingFrom: "workspace", activeDocId: space._id, TemplateName: space.TemplateName }));
            dispatch(updateTemplateTitle(space.TemplateName));
            dispatch(updateCommingFrom("workspace"));
            dispatch(fetchAnsICPDataApi());
            dispatch(updateEditformDetails({ title: space.TemplateName }));
            dispatch(updateTabNumber({ type: "independent", independentNumber: 0 }));
            navigate('/product/home/buildmarketanalysis/idealcustomerprofile/edit')
        }
    }
    const handleDeleteWorkSpace = (space) => {
        dispatch(deleteWorkSpaceApi(space._id));
        dispatch(deleteWorkSpace(space._id));
        dispatch(postUserActivityDataApi({ action: "deleteWorkspace", label: "[username] has deleted the content in the  Ideal Customer Profile." }))
    }
    const colors = ["#79155B", "#183D3D"]
    const isFreeTrialTemplateCheck = (active_template_type) => {
        let present_template_type = active_template_type;
        if (active_template_type === null) {
            present_template_type = icp.find((temp) => temp.title === TemplateName).template_name
        }
        const isActiveTemplateAlreadtExists = workSpace.find((space) => space.actualTemplateType === present_template_type);
        return isActiveTemplateAlreadtExists;
    }
    return (
        <div>
            <AIWelcome link={"/product/home/ai/promptin/idealCustomerProfile"} featureName={"idealCustomerProfile"} />
            <WarningLimitedResourceOopsPopup
                description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].restrict_custom_template_one_b2b_b2c.description1}
                description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].restrict_custom_template_one_b2b_b2c.description2}
            >
                {({ handleTogglePopup, isFreeSubscription }) => {
                    return (
                        <ChooseTemplatePopup
                            isOpen={isOpen}
                            selectedTemplate={activeCustomTemplate}
                            handleSelectTemplate={handleToggletemplate}
                            handleCloseModal={() => {
                                setIsOpen(false);
                                setActiveCustomTemplate(null);
                            }}
                            handleConfirmButton={(value) => {
                                setIsOpen(false);
                                if(isFreeSubscription && isFreeTrialTemplateCheck(activeCustomTemplate)){
                                    handleTogglePopup();
                                    return;
                                }
                                handleAddTemplate();
                            }}
                        />
                    )
                }}
            </WarningLimitedResourceOopsPopup>
            {
                (isSearchOpen)
                    ? <div className='pt-[6.5rem]'>
                        <SearchComponent />
                    </div>
                    : (
                        <div className='pt-[6.5rem]'>
                            <WarningLimitedResourceOopsPopup
                                description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].ai.description1}
                                description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].ai.description2}
                            >
                                {({ handleTogglePopup, isFreeSubscription }) => {
                                    return (
                                        <button
                                            onClick={() => {
                                                if (isFreeSubscription) {
                                                    handleTogglePopup();
                                                    return;
                                                }
                                                dispatch(toggleWelcomeAi());
                                                dispatch(updateProductFeature("idealCustomerProfile"));
                                            }}
                                            className="p-[7px] sticky top-[90%] left-[100%] bg-primary text-white flex items-center rounded-[8px]"
                                        >
                                            <img className="w-[30%]" src={responseLogo} />
                                            <span className="ml-[0.5rem]">Ask AI</span>
                                        </button>
                                    )
                                }}
                            </WarningLimitedResourceOopsPopup>

                            <DescribeFeature text={locals.module_title} description={locals.module_description} />
                            <div className='mt-[24px] w-[90%] flex items-center justify-between'>
                                <span className='text-[#111827] font-InterRegular text-[16px] font-semibold tracking-wide'>{locals.dashboard.title}</span>
                                <div className='flex items-center gap-x-[12px]'>
                                    <Button onClick={() => dispatch(toggleSearchScreen(true))} className='p-[8px] h-auto bg-[#EBF1FF] rounded-[12px]'><SearchOutlinedIcon fontSize='small' /></Button>
                                    <FilterSelectOption
                                        value={filterTemplate}
                                        options={icpLocals.ideal_customer_profile.filter_template}
                                        onChange={(val) => setFilterTemplate(val)}
                                    />
                                    <Button disabled={!permission.edit} className='bg-primary text-white py-[0.4rem] font-InterRegular text-[13px] font-medium tracking-wide h-auto' onClick={() => {
                                        setIsOpen(true);
                                    }}>{locals.dashboard.add_template_btn_text}</Button>
                                </div>
                            </div>
                            <ul className='grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 w-[95%] mt-[24px]'>
                                {
                                    icp.filter((temp) => filterTemplate === "all" ? true : temp.template_name === filterTemplate).slice(0, 4).map((data, index) => {

                                        return (
                                            <li className='border w-[211px] h-[138.25px] p-[12px] shadow-lg mb-[23px] border-[#372EE5] rounded-[16px]' key={index}>
                                                <div className="title flex items-center">
                                                    <div className={`${icpImageAndBgColor?.[data?.title]?.bgColor} min-w-[46px] flex items-center justify-center min-h-[46px] aspect-square px-[7px] py-[12px] rounded-[12px]`}>
                                                        <img src={icpImageAndBgColor?.[data?.title]?.templateIcon} alt="" />
                                                    </div>
                                                    <span className='text-[#111827] ml-[8px] font-InterRegular text-[16px] font-medium tracking-wide'>{data.title}</span>
                                                </div>
                                                <div className="crud_icons flex items-center mt-[24px] pt-[8px] border-t border-[#372EE5] justify-around">
                                                    <button onClick={() => {
                                                        console.log(index);
                                                        dispatch(updateEditFeature({ featureIndex: index }))
                                                        dispatch(updateActiveDocId({ commingFrom: "dashboardCard" }));
                                                        dispatch(updateCommingFrom("dashboardCard"));
                                                        navigate('/product/home/buildmarketanalysis/idealcustomerprofile/view');
                                                    }} className='cursor-pointer'>
                                                        <RemoveRedEyeOutlinedIcon className='text-primary' />
                                                    </button>
                                                    <div className='w-[0.1px] bg-[#372EE5] h-[28px]'></div>
                                                    <WarningLimitedResourceOopsPopup
                                                        description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].no_edit.description1}
                                                        description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].no_edit.description2}
                                                    >
                                                        {
                                                            (({ handleTogglePopup, isFreeSubscription }) => (
                                                                <button onClick={() => {
                                                                    if (isFreeSubscription) {
                                                                        handleTogglePopup();
                                                                        return;
                                                                    }
                                                                    dispatch(updateEditFeature({ featureIndex: index }))
                                                                    dispatch(updateActiveDocId({ commingFrom: "dashboardCard" }));
                                                                    dispatch(updateCommingFrom("dashboardCard"));
                                                                    navigate('/product/home/buildmarketanalysis/idealcustomerprofile/edit')
                                                                }} disabled={!permission.edit}>
                                                                    <ModeEditOutlinedIcon className='text-primary' />
                                                                </button>
                                                            ))
                                                        }
                                                    </WarningLimitedResourceOopsPopup>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <BlurContainer
                                isShowBlurEffect={isFreeSubscription}
                                isShowButton
                                text={paywall_locals.ideal_customer_profile[upgradeRenewPaywallPopup].restrict_other_template}
                                className={'mb-[1rem]'}
                            >
                                <ul className='grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 w-[95%] mt-[24px]'>
                                    {
                                        icp.filter((temp) => filterTemplate === "all" ? true : temp.template_name === filterTemplate).slice(4).map((data, index) => {

                                            return (
                                                <li className='border w-[211px] h-[138.25px] p-[12px] shadow-lg mb-[23px] border-[#372EE5] rounded-[16px]' key={index}>
                                                    <div className="title flex items-center">
                                                        <div className={`${icpImageAndBgColor?.[data?.title]?.bgColor} min-w-[46px] flex items-center justify-center min-h-[46px] aspect-square px-[7px] py-[12px] rounded-[12px]`}>
                                                            <img src={icpImageAndBgColor?.[data?.title]?.templateIcon} alt="" />
                                                        </div>
                                                        <span className='text-[#111827] ml-[8px] font-InterRegular text-[16px] font-medium tracking-wide'>{data.title}</span>
                                                    </div>
                                                    <div className="crud_icons flex items-center mt-[24px] pt-[8px] border-t border-[#372EE5] justify-around">
                                                        <button onClick={() => {
                                                            console.log(index);
                                                            dispatch(updateEditFeature({ featureIndex: index }))
                                                            dispatch(updateActiveDocId({ commingFrom: "dashboardCard" }));
                                                            dispatch(updateCommingFrom("dashboardCard"));
                                                            navigate('/product/home/buildmarketanalysis/idealcustomerprofile/view');
                                                        }} className='cursor-pointer'>
                                                            <RemoveRedEyeOutlinedIcon className='text-primary' />
                                                        </button>
                                                        <div className='w-[0.1px] bg-[#372EE5] h-[28px]'></div>
                                                        <button onClick={() => {
                                                            dispatch(updateEditFeature({ featureIndex: index }))
                                                            dispatch(updateActiveDocId({ commingFrom: "dashboardCard" }));
                                                            dispatch(updateCommingFrom("dashboardCard"));
                                                            navigate('/product/home/buildmarketanalysis/idealcustomerprofile/edit')
                                                        }} disabled={!permission.edit}>
                                                            <ModeEditOutlinedIcon className='text-primary' />
                                                        </button>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </BlurContainer>
                            <h3 className='text-[#111827] mb-[24px] font-InterRegular text-[16px] font-semibold tracking-wide'>{locals.dashboard.workspace.workspace_title}</h3>
                            <ul className='flex flex-wrap'>
                                {
                                    workSpace?.map((space, i) => {
                                        const createdAtDate = new Date(space.createdAt);
                                        const day = String(createdAtDate.getDate()).padStart(2, '0');
                                        const month = String(createdAtDate.getMonth() + 1).padStart(2, '0');
                                        const year = createdAtDate.getFullYear();
                                        const formattedDate = `${day}/${month}/${year}`
                                        let test = space.workSpaceName;
                                        let shorten;
                                        const splittedtext = test.split(' ');
                                        if (splittedtext.length === 1) {
                                            shorten = test.slice(0, 2).toUpperCase();
                                        } else {
                                            const updatedText = splittedtext[0].slice(0, 1) + splittedtext[1].slice(0, 1)
                                            shorten = updatedText.toUpperCase()
                                        }
                                        return <li key={space._id} className='border mb-[24px] flex flex-col justify-between rounded-[12px] mr-[24px] p-[12px] border-primary w-[215px] h-[269px]'>
                                            <div className='border relative rounded-[8px] flex justify-start items-end h-[60%]'>
                                                <span style={{ backgroundColor: `${colors[Math.floor(Math.random() * colors.length)]}` }} className={`absolute top-[10%] rounded-full flex justify-center items-center left-1/2 -translate-x-1/2 text-white font-InterRegular text-[32px] font-medium tracking-widest w-[4.2rem] h-[4.2rem]`}>{shorten}</span>
                                                <div className='p-1'>
                                                    <TextEditModal
                                                        heading={rename_template_locals.heading}
                                                        placeholder={rename_template_locals.placeholder}
                                                        subTitle={rename_template_locals.sub_heading}
                                                        maxCharLength={38}
                                                        initialValue={space.workSpaceName}
                                                        apiStatus={renameICPTemplateName.status}
                                                        errorMessage={renameICPTemplateName.error}
                                                        handleOnClick={(value) => dispatch(updateICPTemplateNameApi({ "templateId": space._id, "templateName": value }))}
                                                        handleResetMsg={() => dispatch(resetPatchTemplate())}
                                                    >
                                                        {
                                                            ({ togglePopup }) => (
                                                                <button onClick={togglePopup} className='text-[#111827] font-InterRegular font-medium tracking-wide break-all text-[15px]' style={{ marginBottom: '4px' }}>{space.workSpaceName}</button>
                                                            )
                                                        }
                                                    </TextEditModal>
                                                    <p className='text-[10px] text-[#111827] font-InterRegular font-medium tracking-wide' style={{ marginBottom: '0px' }}>{formattedDate}</p>
                                                </div>
                                            </div>
                                            <div className='flex justify-between py-[0.6rem] h-[20%]'>
                                                <button disabled={!permission.edit} onClick={() => {
                                                    handleViewWorkSpace(space);
                                                    dispatch(updateIsWorkSpaceHasName(space.workSpaceName));
                                                }}><RemoveRedEyeOutlinedIcon className='text-primary' /></button>
                                                <button disabled={!permission.edit} className='border-x border-primary px-[2rem]' onClick={() => {
                                                    handleEditWorkSpace(space)
                                                    dispatch(updateIsWorkSpaceHasName(space.workSpaceName));
                                                }}><ModeEditOutlinedIcon className='text-primary' /></button>
                                                <button disabled={!permission.delete} onClick={() => handleDeleteWorkSpace(space)}><DeleteOutlinedIcon className='text-infoAlertError1' /></button>
                                            </div>
                                        </li>
                                    })
                                }
                                {
                                    <button disabled={!permission.edit} onClick={() => {
                                        setIsOpen(true);
                                    }} className='bg-[#EBF1FF] mb-[24px] flex cursor-pointer flex-col justify-center items-center w-[215px] h-[269px] border-[#BECEFF] rounded-[12px]'>
                                        <img src={CloseSquare} alt="" className='mb-[0.5rem]' />
                                        <div className='text-primary'><p style={{ marginBottom: "4px" }} dangerouslySetInnerHTML={{ __html: locals.dashboard.workspace.create_template_btn_text }} /></div>
                                    </button>
                                }
                            </ul>
                            <div className='pb-[1rem]'>
                                <RedirectModuleToCardView moduleName={'idealCustomerProfile'} />
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default ICPDashboard