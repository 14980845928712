import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import CloseButton from "../../SVG/CloseButtonSVG";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { updateArchiveStatusApi } from "../../../features/dataRoomSlice";
import ModalSection from "./ModalSection";

const ArchiveFileModal = ({ isOpen, onClose, documentList, archive }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        setModalOpen(false);
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleLock = async () => {
        setLoading(true);
        const body = {
            documentId: documentList._id,
            archiveStatus: archive
        };

        await dispatch(updateArchiveStatusApi(body)).then((response) => {
            if (response?.meta.requestStatus === "fulfilled") {
                message.success(archive ? "File archived successfully" : "File Unarchived Successfully");
                setIsModalVisible(true);
            } else {
                message.error(archive ? "File archiving failed" : "File Unarchiving Failed")
            }
            setLoading(false);
            handleOk();
        });
    };

    return (
        <>
            {isModalVisible && (
                <ModalSection
                    title="Info"
                    closeHandler={() => { setIsModalVisible(false); onClose(); }}
                    text={archive ? "Success! The file has been archived and is now visible in Archived Files" : "Success! The file has been unarchived and is now visible in My Files."}
                />
            )}
            <Modal
                title={<span style={{ fontSize: "20px" }}>{archive ? "Archive File" : "Unarchive File"}</span>}
                centered
                open={modalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <button
                    className="absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all"
                    onClick={handleCancel}
                >
                    <CloseButton />
                </button>
                <div className="px-2 flex flex-col gap-3">
                    <div>
                        {archive ? (
                            "This will archive the file you can access the same in archive file section."
                        ) : (
                            "Are you sure you want to unarchive this item? This action will restore the item to its original location."
                        )}
                    </div>
                    <div className="w-full flex flex-row justify-between mt-3">
                        <div>
                            <SecondaryButton text={"Cancel"} size="Medium" isDisabled={loading} onClick={handleCancel} />
                        </div>
                        <div>
                            <PrimaryButton text={archive ? "Archive" : "Unarchive"} size="Medium" onClick={handleLock} isLoading={loading} isDisabled={loading} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ArchiveFileModal;
