import React from 'react'
import { useSelector } from 'react-redux';
import CustomImageUpload from '../../../../DesignSystem/Image/CustomImageUpload';
import TextInput from '../../../../DesignSystem/Forms/InputVarients/TextInput';

const ICPProfileDetail = ({
    // profile props
    profileImage,
    profileName,
    isUploadOrImgComponent,
    isShowEditImgComponent,
    isEditTemplateUserName,
    handleImageUpload,
    handleOnBlurProfileName,
    handleOnChangeProfileName,
    handleShowEditIconUploadComponent,
    handleOnClickProfileName,

    // template name props
    templateName,
    templateBgColor,
    templateIcon,
    isCustomTemplateOpen

}) => {

    const permission = useSelector((state) => state.user.permission);

    return (
        <div className='flex justify-between'>

            {/* profile details */}
            <div className="profile flex items-center">
                <div className='w-[60px] aspect-square'>
                    {
                        isUploadOrImgComponent
                            ? <CustomImageUpload
                                showEdit={isShowEditImgComponent}
                                avatar={profileImage}
                                handleProfileUpload={handleImageUpload}
                                handleShowEdit={handleShowEditIconUploadComponent}
                            />
                            : <img src={profileImage} />
                    }
                </div>
                <div className='ml-[12px]'>
                    {
                        isEditTemplateUserName
                            ? <TextInput
                                value={profileName}
                                placeholder={"John Doe"}
                                maxCharLength={20}
                                isDisabled={!permission.edit}
                                onBlurEvent={handleOnBlurProfileName}
                                onChangeEvent={handleOnChangeProfileName}
                            />
                            : <button onClick={handleOnClickProfileName}>
                                {profileName}
                              </button>
                    }
                </div>
            </div>

            {/* template details */}
            <div className={`${isCustomTemplateOpen && 'hidden'} templateName flex items-center`}>
                <div className={`${templateBgColor} w-[44px] mr-[8px] h-[44px] flex justify-center items-center rounded-[12px]`}>
                    <img src={templateIcon} alt="" />
                </div>
                <span>{templateName}</span>
            </div>
        </div>
    )
}

export default ICPProfileDetail