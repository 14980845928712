import React, { useState } from "react";
import { Dropdown, Menu, Space } from "antd";
import AdminDataRoom from "../../localization/data_room/localization_en_admin_data_room.json";
import { useDispatch } from "react-redux";
import { updateArchiveStatusApi } from "../../features/dataRoomSlice.jsx"
import grayDots from "../../assets/common/grayDots.svg";
import ModalSection from "./ActionMenuOptionModal/ModalSection.jsx";
import ArchiveOutline from "../SVG/ArchiveOutlineSVG.jsx";

const ActionMenuArchiveFileCard = ({ documentId, onClose }) => {
    const [modalTitle, setModalTitle] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState(null)
    const dispatch = useDispatch();

    const id = documentId;

    const handleMenuClick = (e) => {
        console.log("click", e);
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu[e.key]);
        setIsModalVisible(true);
    };

    const closeModalHandler = () => {
        setIsModalVisible(false);
        setModalTitle(null);
        onClose();
    };

    const handleUnarchiveClick = async () => {
        const documentList = {
            _id: id
        }
        setData(documentList)
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu['8'])
        setIsModalVisible(true)
    }

    const menuItems = [
        {
            label: (
                <Space className="py-2" onClick={handleUnarchiveClick}>
                    <ArchiveOutline />
                    {"Unarchive"}
                </Space>
            ),
            key: "28",
        },
    ];

    const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

    return (
        <Space wrap>
            <Dropdown overlay={menu} trigger={["click"]}>
                <Space className="cursor-pointer opacity-75 hover:opacity-100">
                    <img src={grayDots} onClick={handleMenuClick} />
                </Space>
            </Dropdown>
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    closeHandler={closeModalHandler}
                    documentList={data}
                />
            )}
        </Space>
    );
};

export default ActionMenuArchiveFileCard;
