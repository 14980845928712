import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Skeleton } from 'antd';
import { getDetailsDirectoryApi } from "../../../features/dataRoomSlice";
import closeSvg from "../../../assets/common/close.svg";
import PrimaryButton from "../../Buttons/PrimaryButton";

const DetailFolderModal = ({ isOpen, onClose, documentList }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (documentList) {
      const body = {
        documentId: documentList,
      };
      dispatch(getDetailsDirectoryApi(body)).then((response) => {
        setDocumentDetails(response?.payload);
        setLoading(false);
      });
    }
  }, [documentList]);

  const handleOk = () => {
    setModalOpen(false);
    onClose()
  };

  const handleCancel = () => {
    setModalOpen(false);
    onClose()
  };

  const skeletonLoader = (
    <div className="px-2 flex flex-col gap-3">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="flex">
          <div className="flex-1">
            <Skeleton.Input active style={{ width: 100, height: 16 }} />
          </div>
          <div className="flex-1 flex justify-end">
            <Skeleton.Input active style={{ width: 150, height: 16 }} />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Modal
      title={<span style={{ fontSize: '20px' }}>Directory Details</span>}
      centered
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
        <img src={closeSvg} alt="Close" />
      </button>
      {loading ? (
        skeletonLoader
      ) : (
        documentDetails && (
          <div className="px-2 flex flex-col gap-3">
            <div className="flex">
              <div className="flex-1">
                <h1 className="text-[#757575] text-[14px] font-medium">Name</h1>
              </div>
              <div className="flex-1 flex text-[#757575] text-[14px] font-medium">
                <p>{documentDetails.document.name}</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1">
                <h1 className="text-[#757575] text-[14px] font-medium">Size</h1>
              </div>
              <div className="flex-1 flex text-[#757575] text-[14px] font-medium">
                <p>{documentDetails.document.size} bytes</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1">
                <h1 className="text-[#757575] text-[14px] font-medium">Type</h1>
              </div>
              <div className="flex-1 flex text-[#757575] text-[14px] font-medium">
                <p>{documentDetails.document.type}</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1">
                <h1 className="text-[#757575] text-[14px] font-medium">Uploaded Date</h1>
              </div>
              <div className="flex-1 flex text-[#757575] text-[14px] font-medium">
                <p>{new Date(documentDetails.document.createdAt).toLocaleString()}</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1">
                <h1 className="text-[#757575] text-[14px] font-medium">Description</h1>
              </div>
              <div className="flex-1 flex text-[#757575] text-[14px] font-medium">
                <p>{documentDetails.document.description || "No description available"}</p>
              </div>
            </div>
          </div>
        )
      )}
      <div className="w-full flex justify-end mt-3">
        <PrimaryButton onClick={handleCancel} text={"Done"} size="Medium" />
      </div>
    </Modal>
  );
};

export default DetailFolderModal;

