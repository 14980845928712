export const privacy_policy = {
    "content": `
            <h2>Privacy Policy</h2>
            <div>
                <h3>PRIVACY, SECURITY AND TRANSPARENCY</h3>
                <p>Thank you for visiting the online and mobile resources published by Predict Growth AI Private Limited(collectively “Predict Growth”). Our privacy statement, contained in the pages that follow, serves, to give notice about the types of personal information we collect, how we use it, who we share it with and why, and what we do to try to protect it. We delve into those matters in a fair amount of detail in the pages that follow. We encourage you to read them carefully. In the meantime, we provide a quick overview below.</p>
            </div>
            <div>
                <h3>What do we collect?</h3>
                <p>There are three types of information that we obtain from you online and then store and use:</p>
                <ul>
                    <li>non-personal information that’s collected automatically from each visitor, such as your device operating system; and</li>
                    <li>personal information that you voluntarily provide to us or that is collected automatically</li>
                    <li>business information that you maintain while using our Website.</li>
                </ul>
            </div>
            <div>
                <h3>Why do we use it?</h3>
                <p>We use non-personal information to administer our online and mobile resources, make them better, and to make business decisions about what programs our customers might like. We use voluntarily provided personal and business information to respond to your inquiries and provide you with the services you have requested, amongst other uses as further described below. We do not sell or rent your personal or business information to third party data vendors or marketing companies.</p>
            </div>
            <div>
                <h3>When do we share it?</h3>
                <p>We share personal and business information when needed to fulfill our legal obligations and when our vendors and business partners need it to perform under the contracts we have with them. We do not sell or rent any personal or business information to third party data brokers or marketing companies. As you might expect, we disclose your information when required by law.</p>
            </div>
            <div>
                <h3>Contacting Us</h3>
                <ul>
                    <li>Questions about our online privacy policy may be sent to Predict Growth at <a class="primary_color" href="mailto:support@predictgrowth.ai">support@predictgrowth.ai</a>.</li>
                    <li>The English language version of this privacy statement is the controlling version regardless of any translation you may attempt.</li>
                    <li>Predict Growth, which we refer to “us,” “we,” or “our” below, thanks you for visiting and using the online and mobile resources we publish. We use the words “you” and “your” to mean you, the reader, and other visitors to and users of to our online and mobile resources. Our privacy statement (“this statement,” “this privacy statement,” and “our statement”) informs you about from whom and the types of personal information we collect, how we use it, who we share it with and why, and what we do to try to protect it.</li>
                    <li>Online and mobile resources means the websites and other internet features we own that allow you to interact with our websites, as well apps we’ve created and distributed to let our customers and followers view our online and mobile resources or otherwise interact with the content we provide.</li>
                </ul>
            </div>
            <div>
                <h3>WHO WE COLLECT PERSONAL INFORMATION FROM</h3>
                <ul>
                    <li>We may collect personal information from the following groups of data subjects: visitors to, and users of, our online and mobile resources; our customers; current members of our workforce and those who apply for posted jobs; and third-party vendors and business partners.</li>
                    <li>Personal information generally means information that can be used to identify you or that can be easily linked to you (for example, your name, address, telephone number, email address, social security number and date of birth). The privacy laws in some jurisdictions include unique elements in what they consider to be the personal information of the consumers or data subjects they protect. If those laws apply, such as the California Consumer Privacy Act (“CCPA”) or European General Data Protection Regulation (“GDPR”), our use of the phrase “personal information” includes the unique elements required by such laws.</li>
                    <li>The categories of information we collect from each of these groups, and the ways in which we use it, differs. As you may have noticed, it’s possible that the same person could fall into more than one group. Most of this statement addresses our processing and sharing of personal information collected from visitors to and users of our online and mobile resources and our customers.</li>
                    <li>Nonetheless, we collect and retain the types of professional or employment related personal information you would expect an employer to have about its existing and former workforce and new job applicants. We provide legally required notices of collection, and describe our use and sharing of the personal information of our workforce and applicants in greater detail in confidential internal human resource manuals and documents accessible to members of our workforce, or by publication on the proprietary workforce/applicant portals and apps we operate. In some cases, such portals and apps may be operated by third parties who transfer the personal information to us. In those situations, the legal responsibility to provide notice usually rests with the third party, not us.</li>
                    <li>In addition, like all corporate enterprises, we buy goods and services, lease equipment and office space and attend industry events. In doing so, we interact with many existing and potential vendors and business partners from whom we necessarily collect certain personal information in connection with our contractual and business relationships. As with our customers, this information is typically limited to minimum business contact information. We use and share personal information collected from our vendors and business partners to manage, administer and perform under our contracts with them, or share information about our products. We describe our use of vendor and business partner personal information in greater detail in our confidential contracts with those parties or on the internal vendor management portals we operate.</li>
                </ul>
            </div>
            <div>
                <h3>WHAT WE COLLECT</h3>
                <ul>
                    <li>There are three types of information that we obtain from you online and then store and use: (i) non-personal information that’s collected automatically from each visitor, such as your device operating system; (ii) personal information that you voluntarily provide to us or that is collected automatically; and (iii) business information that you voluntarily provide to us in connection with using Predict Growth’s services and Website.</li>
                    <li>By using our online and mobile resources or purchasing our products or services, you are signifying to us that you agree with this section of our privacy statement and that we may use and disclose your information as described.</li>
                </ul>
            </div>
            <div>
                <h3>Voluntarily Submitted Information.</h3>
                <ul>
                    <li>If you participate in certain activities via our online and mobile resources, you may be asked to provide us with information about yourself. The types of personal information we collect in those situations includes identifiers (such as your name, email address, physical address, and phone number), professional information (such as the business you are in), and financial account information (such as your credit card information). We do not sell, rent, or trade voluntarily submitted personal information with third parties.</li>
                    <li>Some of the ways you voluntarily give us your personal information and how we use it: Emails and Online Forms – When you send us an email or fill out an online form, such as to contact us, your email address and any other personal information (e.g., address or phone number) that may be in the content of your message or attached to it, are retained by us and used to respond back directly to you and to process your request. Depending on the personal information provided, communications from us may be in the form of emails, telephone calls, and/or text messages. We may also send you information about any of our products or services we think may be of interest to you.</li>
                    <li>Registering for an Account – When you register for an account or you register for a sub-account, you submit personal information to us such as your name and email address. We use that information to create and manage your account and, in some cases, establish a password and profile to communicate with you and any sub-accounts you created via email.</li>
                    <li>Registering for Events – When you register for events, conferences or programs we ourselves may host (rather than outsource to a third-party event manager with its own privacy policies), you will be submitting the types of identifiers described above. If the event requires a fee, we may also ask you to submit credit card or other financial information. We use this information to register you for the event and send you communications regarding the event.</li>
                    <li>Becoming a Subscriber to Our Service – We use any information provided from our customers to perform our contractual obligations and provide the products and services purchased to them, to manage their accounts and communicate with them.</li>
                    <li>Social Media and Community Features – Some of our online and mobile resources may offer social media-like community features letting users post messages and comments, and/or upload an image or other files and materials. If you choose to make use of these features, the information you post, including your screen name and any other personal information, will be in the public domain and not covered/protected by this statement.</li>
                </ul>
            </div>
            <div>
                <h3>Automatically Collected Information.</h3>
                <ul>
                    <li>When you visit our online and mobile resources, basic information is passively collected through your web browser via use of tracking technologies, such as a “cookie” which is a small text file that is downloaded onto your computer or mobile device when you access the online and mobile resources. It allows us to recognize your computer or mobile device and store some information about your preferences or past actions.</li>
                    <li>We allow third party vendors to use cookies or similar technologies to collect information about your browsing activities over time following your use of the site. For example, we use Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses cookies to help us analyze how you use the online and mobile resources and enhance your experience when you visit the online and mobile resources. For more information on how Google uses this data, go to www.google.com/policies/privacy/partners/. You can learn more about how to opt out of Google Analytics by going to https://tools.google.com/dlpage/gaoptout.</li>
                    <li>The internet activity information collected through cookies and other similar means includes such things as: the domain name and IP address from which you accessed our online and mobile resources; the type of browser and operating system you use; the date and time and length of your visit; the specific page visited, graphics viewed and any documents downloaded; the specific links to other sites you accessed from our online and mobile resources; and the specific links from other sites you used to access our online and mobile resources.</li>
                    <li>Additionally, if you access our online and mobile resources from a phone or other mobile device, the mobile services provider may transmit to us uniquely identifiable mobile device information which allows us to then collect mobile phone numbers and associate them with the mobile device identification information. Some mobile phone vendors also operate systems that pinpoint the physical location of devices and we may receive this information as well if location services are enabled on your device. If you do not want us to collect and use geolocation data, disable location services through your device settings.</li>
                    <li>Regardless, we use both automatically collected information and mobile device information to compile generic reports about popular pages on our online and mobile resources, and to see how our customers and followers are accessing our online and mobile resources. We then use that data to administer the online and mobile resources and make them better, make your activities more convenient and efficient and to enhance the functionality of our online and mobile resources, such as by remembering certain of your information in order to save you time.</li>
                    <li>We use and retain your personal information in accordance with applicable law and as long as necessary to carry out the purposes described above in accordance with our internal data retention procedures.</li>
                </ul>
            </div>
            <div>
                <h3>Business information.</h3>
                <ul>
                    <li>If you participate in certain activities via our online and mobile resources, because of the nature of Predict Growth’s services, you are likely to provide information about your business activities. The types of business information we maintain those situations includes identifiers (such as customer names, email addresses, physical addresses, and phone numbers), and financial information (such as your sales, revenue, inventory). We do not sell, rent, or trade voluntarily submitted business information with third parties.</li>
                    <li>We use business information provided from our customers to perform our contractual obligations and provide the products and services purchased and to manage accounts and communicate with customers.</li>
                </ul>
            </div>
            <div>
                <h3>User Beware: External Sites, Apps, Links and Social Media</h3>
                <ul>
                    <li>We maintain a presence on one or more external social media platforms such as Twitter, Facebook, YouTube and LinkedIn. We may further allow features of our online and mobile resources to connect with, or be viewable from, that external social media presence. Similarly, our online and mobile resources may contain links to other websites or apps controlled by third parties.</li>
                    <li>We are not responsible for either the content on, or the privacy practices of, social media platforms, or any third-party sites or apps to which we link. Those apps, sites and platforms are not controlled by us and therefore have their own privacy policies and terms of use. If you have questions about how those apps, sites and platforms collect and use personal information, you should carefully read their privacy policies and contact them using the information they provide.</li>
                </ul>
            </div>
            <div>
                <h3>Information collected while using the application</h3>
                <ul>
                    <li>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission: a) Information regarding your location, and b) Pictures and other information from your Device's camera and photo library</li>
                    <li>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</li>
                    <li>You can enable or disable access to this information at any time, through Your Device settings.</li>
                </ul>
            </div>
            <div>
                <h3>WHEN/WITH WHOM DO WE SHARE PERSONAL INFORMATION</h3>
                <ul>
                    <li>We use non-personal information to administer our online and mobile resources, make them better, and to make business decisions about what programs our customers might like.</li>
                    <li>We use voluntarily provided personal and business information for our legitimate interests, including to respond to your inquiries and provide you with the services you have requested, amongst other uses as further described below. We do not sell or rent your personal and business information to third party data vendors or marketing companies. As you might expect, we disclose your information when required by law.</li>
                </ul>
            </div>
            <div>
                <h3>Affiliates.</h3>
                <p>In addition to those third parties set forth above, we may share your information, including personal and business information, within our family of companies. Those companies will use such information in generally the same manner as we do under this privacy statement which includes sending you information about their products, services, or initiatives that may be of interest to you.</p>
            </div>
            <div>
                <h3>Legally Compelled Disclosures.</h3>
                <p>We may disclose your information, including personal and business information, to government authorities, and to other third parties when compelled to do so by such government authorities, or at our discretion or otherwise as required or permitted by law, including but not limited to responding to court orders and subpoenas.</p>
            </div>
            <div>
                <h3>To Prevent Harm.</h3>
                <p>We may disclose your information, including personal and business information, when we have reason to believe that someone is causing injury to or interference with our rights or property, other users of the online and mobile resources, or anyone else that could be harmed by such activities.</p>
            </div>
            <div>
                <h3>Business Transfer.</h3>
                <p>If we or any of our affiliates, or substantially all of its or their assets, are acquired by one or more third parties as a result of an acquisition, merger, sale, reorganization, consolidation, or liquidation, personal and business information may be one of the transferred assets.</p>
            </div>
            <div>
                <h3>Vendors and Business Partners.</h3>
                <ul>
                    <li>We may share your information, including personal and business information, with our vendors and other third parties with whom we have a contractual relationship. We may also share such information with vendors who provide third party software services. We do our best to disclose only the information each of those parties need.</li>
                    <li>We have standards for those vendors and business partners who receive personal and business information from us. We attempt to bind such vendors and business partners to those standards via written contracts. We further attempt to contractually restrict what our vendors and business partners can do with the personal and business information we provide to them such that it is used only to the extent necessary to carry out the business purpose for which it was provided; is not disclosed to anyone else without our consent or under our instruction; remains, as between us and the applicable vendor or business partner.</li>
                    <li>Please note, however, that we cannot guarantee that all of our vendors and business partners will agree to these contractual requirements; nor can we ensure that, even when they do agree, they will always fully comply.</li>
                </ul>
            </div>
            <div>
                <h3>YOUR RIGHTS AND OPTIONS</h3>
                <p>If we are using personal data you provided to us in order to enable us to send you materials, such as newsletters or product alerts via text or email, and you decide you don’t want to receive such materials, you may opt out by contacting us using the contact information below. When we receive your request, we will take reasonable steps to remove your name from our distribution lists. You need to understand it may take a period of time to remove your name from our lists after your request and due to such latency you may still receive materials for a period of time after you opt out. In addition to opting out, you have the ability to access, amend, and delete your personal information by contacting us using the contact information below.</p>
            </div>
            <div>
                <h3>CHILDREN’S PRIVACY</h3>
                <p>Federal laws impose special restrictions and obligations on commercial website operators who direct their operations toward, and collect and use information from, children under the age of 13. We take those age-related requirements very seriously, and consistent with it do not intend for our online and mobile resources to be used by children under the age of 13 without first obtaining the verifiable consent of such child’s parent or legal guardian. Moreover, we do not knowingly collect personal information from minors under the age of 13, only a parent or legal guardian may provide such information after adhering to our verification process for submitting such information via the online and mobile resources. If we become aware that anyone under the age of 18 has submitted personal information to our online and mobile resources without such parental or legal guardian approval and that is not an integral part to accessing or using our Services, we will delete that information and will not use it for any purpose whatsoever. If you believe that someone under the age of 18 has submitted personal information to our online and mobile resources, please contact us at <a class="primary_color" href="mailto:support@predictgrowth.ai">support@predictgrowth.ai</a></p>
            </div>
            <div>
                <h3>HOW WE PROTECT COLLECTED PERSONAL INFORMATION</h3>
                <ul>
                    <li>We will take all reasonable security precautions to protect your personal and business information provided to our online and mobile resources. We have adopted a security program that includes technical, organizational, administrative, and other security measures designed to protect, in a manner consistent with accepted industry standards and applicable law, against anticipated or actual threats to the security of personal information (the “Security Program”). We cannot, however, guarantee that your information, whether during transmission or while stored on our systems or otherwise in our care, will be free from unauthorized access or that loss, misuse, destruction, or alteration will not occur. Except for our duty to maintain the Security Program under applicable law, we disclaim any other liability for any such theft or loss of, unauthorized access or damage to, or interception of any data or communications including personal information. We have every reason to believe our Security Program is reasonable and appropriate for our business and the nature of foreseeable risks to the personal and business information we collect or maintain. We further periodically review and update our Security Program, including as required by applicable law.</li>
                    <li>Nonetheless, as part of our Security Program, we have specific incident response and management procedures that are activated whenever we become aware that your information was likely to have been compromised. We further require, as part of our vendor and business partner oversight procedures, that such parties notify us immediately if they have any reason to believe that an incident adversely affecting personal or business information we provided to them has occurred.</li>
                </ul>
            </div>
            <div>
                <h3>CHANGES TO THIS PRIVACY STATEMENT</h3>
                <ul>
                    <li>The English language version of this privacy statement is the controlling version regardless of any translation you may attempt.</li>
                    <li>We reserve the right to change or update this statement from time to time. Please check our online and mobile resources periodically for such changes since all information collected is subject to the statement in place at that time.</li>
                </ul>
            </div>
            <div>
                <h3>CONTACTING US</h3>
                <p>If you have questions about our privacy statement or privacy practices, please contact us at: Predict Growth AI Private Limited. Attn: Legal & Compliance Department <a class="primary_color" href="mailto:support@predictgrowth.ai">support@predictgrowth.ai</a></p>
            </div>
    `
}