import locals from '../../../localization/localization_en_ai.json'

const responseHeading = locals.ai.captions;


export function DownloadPayloadPreparation({ promptDetail, companyName }) {
    try {
        const {
            productName,
            promptInputs,
            bardResponses,
            promptQuestions,
            introDescribeFeature,
            problemSolutionMatrix,
            tma, gtm, marketingFunnel
        } = promptDetail;

        const prompt_qa = promptQuestions?.form?.map((q) => ({
            "question": q.label,
            "answer": Array.isArray(promptInputs[q.name]) ? promptInputs[q.name]?.toString() : q.name === "CompanyName" ? companyName : promptInputs[q.name]
        }))

        if(productName==='') return [];
        switch (productName) {
            case 'problemSolutionMatrix': {
                const payload = [
                    {
                        "prompt_title": introDescribeFeature,
                        "prompt_qa": prompt_qa,
                        "prompt_response": {
                            "text": responseHeading.psm_text.list_text,
                            "description": problemSolutionMatrix?.problem?.[0]
                        }
                    }
                ]
                if (problemSolutionMatrix?.solution?.length > 0) {
                    payload.push({
                        "prompt_title": null,
                        "prompt_qa": [],
                        "prompt_response": {
                            "text": responseHeading.psm_text.feasible_solution_text_1,
                            "description": problemSolutionMatrix?.solution?.[0]
                        }
                    })
                }
                if (problemSolutionMatrix?.evaluateFesability?.length > 0) {
                    payload.push({
                        "prompt_title": null,
                        "prompt_qa": [],
                        "prompt_response": {
                            "text": responseHeading.psm_text.feasible_solution_text_2,
                            "description": problemSolutionMatrix?.evaluateFesability?.[0]
                        }
                    })
                }
                return payload;
            }
            case 'tma': {
                const payload = [
                    {
                        "prompt_title": introDescribeFeature,
                        "prompt_qa": prompt_qa,
                        "prompt_response": {
                            "text": responseHeading.tma_text.list_text,
                            "description": tma?.list?.[0]
                        }
                    }
                ]
                tma.recursive_data?.map((prompt_qa) => {
                    if (prompt_qa.output) {
                        payload.push({
                            "prompt_title": null,
                            "prompt_qa": [],
                            "prompt_response": {
                                "text": prompt_qa.selectedtag,
                                "description": prompt_qa.output
                            }
                        })
                    }
                })
                return payload;
            }
            case 'competitorAnalysis': {
                const payload = [
                    {
                        "prompt_title": introDescribeFeature,
                        "prompt_qa": prompt_qa,
                        "prompt_response": {
                            "text": responseHeading.competitor_analysis_text.list_text,
                            "description": bardResponses?.[0]?.responseData?.[0]
                        }
                    }
                ]
                bardResponses?.map((resp, index) => {
                    if (resp.responseData.length > 0 && index>0) {
                        payload.push({
                            "prompt_title": null,
                            "prompt_qa": [],
                            "prompt_response": {
                                "text": "",
                                "description": resp.responseData[0]
                            }
                        })
                    }
                })
                return payload;
            }
            case 'idealCustomerProfile': {
                const payload = [
                    {
                        "prompt_title": introDescribeFeature,
                        "prompt_qa": prompt_qa,
                        "prompt_response": {
                            "text": "",
                            "description": bardResponses?.[0]?.responseData?.[0]
                        }
                    }
                ]
                bardResponses?.map((resp, index) => {
                    if (resp.responseData.length > 0 && index>0) {
                        payload.push({
                            "prompt_title": null,
                            "prompt_qa": [],
                            "prompt_response": {
                                "text": "",
                                "description": resp.responseData[0]
                            }
                        })
                    }
                })
                return payload;
            }
            case 'buildBusinessCanvas': {
                const payload = [
                    {
                        "prompt_title": introDescribeFeature,
                        "prompt_qa": prompt_qa,
                        "prompt_response": {
                            "text": "",
                            "description": bardResponses?.[0]?.responseData?.[0]
                        }
                    }
                ]
                bardResponses?.map((resp, index) => {
                    if (resp.responseData.length > 0 && index>0) {
                        payload.push({
                            "prompt_title": null,
                            "prompt_qa": [],
                            "prompt_response": {
                                "text": "",
                                "description": resp.responseData[0]
                            }
                        })
                    }
                })
                return payload;
            }
            case 'leanStartupCanvas': {
                const payload = [
                    {
                        "prompt_title": introDescribeFeature,
                        "prompt_qa": prompt_qa,
                        "prompt_response": {
                            "text": "",
                            "description": bardResponses?.[0]?.responseData?.[0]
                        }
                    }
                ]
                bardResponses?.map((resp, index) => {
                    if (resp.responseData.length > 0 && index>0) {
                        payload.push({
                            "prompt_title": null,
                            "prompt_qa": [],
                            "prompt_response": {
                                "text": "",
                                "description": resp.responseData[0]
                            }
                        })
                    }
                })
                return payload;
            }
            case 'gtm': {
                const payload = [
                    {
                        "prompt_title": introDescribeFeature,
                        "prompt_qa": prompt_qa,
                        "prompt_response": {
                            "text": gtm.list?.length > 0 ? responseHeading.gtm_text.list_text : null,
                            "description": gtm.list?.[0]
                        }
                    }
                ]
                gtm.recursive_data?.map((resp) => {
                    if (resp.output) {
                        payload.push({
                            "prompt_title": null,
                            "prompt_qa": [],
                            "prompt_response": {
                                "text": resp?.output?.length > 0 ? responseHeading.gtm_text.final_output_text : null,
                                "description": resp?.output[0]
                            }
                        })
                    }
                })
                return payload;
            }
            case 'marketingFunnel': {
                const payload = [
                    {
                        "prompt_title": introDescribeFeature,
                        "prompt_qa": prompt_qa,
                        "prompt_response": {
                            "text": null,
                            "description": null
                        }
                    }
                ]
                marketingFunnel.recursive_data?.map((prompt_qa) => {
                    if (prompt_qa.output) {
                        payload.push({
                            "prompt_title": null,
                            "prompt_qa": [],
                            "prompt_response": {
                                "text": prompt_qa.selectedtag,
                                "description": prompt_qa.output[0]
                            }
                        })
                    }
                })
                return payload;
            }
        }
    } catch (error) {
        console.log(error)
        return [];
    }
}