import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import routesConfig from "../../services/config/routesConfig.json";
import api from "../../services/api/api";
import { v4 as uuidv4 } from "uuid";
import cashflow_locals from "../../localization/localization_en_pAndL_workCap_debtWork_cashFlow_balanceSheet.json";

const INITIAL_STATE = {
    "output": null,
    "isSaveDisabled": false,
    "isGenerateOutputDisabled": true,
    "month_year_revenue_screen1_ref": { first_month: '', first_year: '' },
    "save_process_api_status": { "status": "idle", counter: 0, message: null },
    "cashflow_equity_receive": cashflow_locals.cash_flow.form.data[0].table.default_rows,
    "opening_balance_change_assets_liabilities": null
}

const FETCH_DEFAULT_ROW = ({ tableName }) => {
    switch (tableName) {
        case 'cashflow_equity_receive': {
            return cashflow_locals.cash_flow.form.data[0].table.add_row_schema;
        }
    }
}

export const API_Save_CashFlow_Data = createAsyncThunk(
    "cashFlow/API_Save_CashFlow_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-cash-flow');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_CashFlow_Data = createAsyncThunk(
    "cashFlow/API_Process_CashFlow_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-cash-flow');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const cashFlowSlice = createSlice({
    name: "cash-flow",
    initialState: {
        "cflowStore": INITIAL_STATE
    },
    reducers: {
        D_Reset_ErrorMessage(state, action) {
            state.cflowStore.save_process_api_status = { "status": "idle", counter: 0, message: null }
        },
        D_Capture_Normal_Input_Value_CashFlow(state, action) {
            if(state.cflowStore.output) state.cflowStore.output = null
            if (state.cflowStore.isSaveDisabled) {
                state.cflowStore.isSaveDisabled = false;
                state.cflowStore.isGenerateOutputDisabled = true;
            }
            if (action.payload.name in state.cflowStore) state.cflowStore[action.payload.name] = action.payload.value;
        },
        D_Capture_Value_CashFlow(state, action) {
            if(state.cflowStore.output) state.cflowStore.output = null
            if (state.cflowStore.isSaveDisabled) {
                state.cflowStore.isSaveDisabled = false;
                state.cflowStore.isGenerateOutputDisabled = true;
            }
            const { name, tableName, value, rowNumber, data_index } = action.payload;
            state.cflowStore[tableName] = state.cflowStore[tableName].map((row, index) => index === rowNumber ? { ...row, [data_index]: isNaN(value) ? value : value === null ? null : Number(value) } : row);
        },
        D_Add_Row_CashFlow(state, action) {
            if(state.cflowStore.output) state.cflowStore.output = null
            if (state.cflowStore.isSaveDisabled) {
                state.cflowStore.isSaveDisabled = false;
                state.cflowStore.isGenerateOutputDisabled = true;
            }
            const { tableName } = action.payload;
            state.cflowStore[tableName] = [...state.cflowStore[tableName], { "key": uuidv4(), ...FETCH_DEFAULT_ROW({ tableName }) }];
        },
        D_Remove_Row_CashFlow(state, action) {
            if(state.cflowStore.output) state.cflowStore.output = null
            if (state.cflowStore.isSaveDisabled) {
                state.cflowStore.isSaveDisabled = false;
                state.cflowStore.isGenerateOutputDisabled = true;
            }
            const { tableName, rowNumber } = action.payload;
            state.cflowStore[tableName] = state.cflowStore[tableName].filter((row, index) => index !== rowNumber)
            if (state.cflowStore[tableName].length === 0) state.cflowStore[tableName] = [{ "key": uuidv4(), ...FETCH_DEFAULT_ROW({ tableName }) }]
        },
        D_Update_Screen_Screen_CashFlow(state, action) {
            const SCREEN_DATA = action.payload.screenData
            if (SCREEN_DATA?.input) {
                const {
                    cashflow_equity_receive,
                    opening_balance_change_assets_liabilities
                } = SCREEN_DATA?.input
                state.cflowStore.cashflow_equity_receive = cashflow_equity_receive
                state.cflowStore.opening_balance_change_assets_liabilities = opening_balance_change_assets_liabilities
            }
            state.cflowStore.output = SCREEN_DATA?.output ? SCREEN_DATA?.output : null;
            state.cflowStore.isSaveDisabled = state.cflowStore.output ? true : false
            state.cflowStore.isGenerateOutputDisabled = state.cflowStore.output ? true : false
            state.cflowStore.month_year_revenue_screen1_ref = { "first_month": SCREEN_DATA?.revenueScreenOneInput?.first_month,"first_year": SCREEN_DATA?.revenueScreenOneInput?.first_year  }
        },
        D_Reset_CashFlow_State(state) {
            state.cflowStore = INITIAL_STATE;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_Save_CashFlow_Data.pending, (state) => {
                state.cflowStore.save_process_api_status.counter = state.cflowStore.save_process_api_status.counter + 1
                state.cflowStore.save_process_api_status.status = 'loading';
            })
            .addCase(API_Save_CashFlow_Data.fulfilled, (state) => {
                state.cflowStore.save_process_api_status.counter = state.cflowStore.save_process_api_status.counter + 1
                state.cflowStore.save_process_api_status.status = 'success';
                state.cflowStore.isSaveDisabled = true;
                state.cflowStore.isGenerateOutputDisabled = false;
            })
            .addCase(API_Save_CashFlow_Data.rejected, (state, action) => {
                state.cflowStore.save_process_api_status.counter = state.cflowStore.save_process_api_status.counter + 1
                state.cflowStore.save_process_api_status.status = 'failed';
                state.cflowStore.save_process_api_status.message = action.error.message
            })
            .addCase(API_Process_CashFlow_Data.pending, (state) => {
                state.cflowStore.save_process_api_status.counter = state.cflowStore.save_process_api_status.counter + 1
                state.cflowStore.save_process_api_status.status = 'loading';
            })
            .addCase(API_Process_CashFlow_Data.fulfilled, (state, action) => {
                state.cflowStore.save_process_api_status.counter = state.cflowStore.save_process_api_status.counter + 1
                state.cflowStore.save_process_api_status.status = 'success';
                state.cflowStore.isGenerateOutputDisabled = true;
                state.cflowStore.output = action.payload;
            })
            .addCase(API_Process_CashFlow_Data.rejected, (state, action) => {
                state.cflowStore.save_process_api_status.counter = state.cflowStore.save_process_api_status.counter + 1
                state.cflowStore.save_process_api_status.status = 'failed';
                state.cflowStore.save_process_api_status.message = action.error.message
            })
    }
});

export const { D_Update_Screen_Screen_CashFlow, D_Reset_ErrorMessage, D_Reset_CashFlow_State, D_Capture_Normal_Input_Value_CashFlow, D_Capture_Value_CashFlow, D_Add_Row_CashFlow, D_Remove_Row_CashFlow } = cashFlowSlice.actions;
export default cashFlowSlice.reducer;