import { Table, ConfigProvider } from 'antd';
import TextLarge from '../TypographyDescriptions/TextLarge';
import { cn } from '../../utils/cn';
import SkeletonLoader from '../Loader/SkeletonLoader';

const AntDTable = (props) => {


    const mapColumns = (columns) => {
        return columns.map((col, colIndex) => {
            const columnConfig = {
                "key": `column-${colIndex}`,
                "fixed": col?.fixed,
                "title": () => {
                    if (props.customHeaderComponent) {
                        const CustomHeaderComponent = props.customHeaderComponent;
                        return (
                            <CustomHeaderComponent
                                col={col}
                            />
                        )
                    }
                    return (
                        <div className='p-2'>
                            <TextLarge
                                fontWeight={500}
                                text={col?.label}
                            />
                        </div>
                    )
                },
                "dataIndex": col.dataIndex,
                "width": col.width,
                "render": (value, record, rowIndex) => {
                    if (props.isLoading) {
                        return (
                            <SkeletonLoader
                                height={30}
                                className={cn({
                                    'w-[80%] ml-[0.5rem] mb-[0.25rem]': true,
                                    'ml-[0.3rem]': colIndex === 0
                                })}
                            />
                        )
                    }
                    if (props.customComponent) {
                        const CustomComponent = props.customComponent;
                        return (
                            <CustomComponent
                                value={value}
                                record={record}
                                rowIndex={rowIndex}
                                colIndex={colIndex}
                                dataIndex={col.dataIndex}
                                col={col}
                                handleOnChange={props.handleOnChange}
                                className={props.className}
                            />
                        )
                    }
                    return "Cell"
                }
            }
            return columnConfig;
        })
    }

    const columns = mapColumns(props.columns);

    const totalColumnWidth = props.columns?.reduce((total, column) => total + (column?.width || 0), 0);

    return (
        <div className='w-full'>
            <ConfigProvider
                theme={{
                    "components": {
                        "Table": {
                            "headerBorderRadius": props.headerBorderRadius ?? 8
                        }
                    }
                }}
            >
                <Table
                    bordered={props.isBordered ?? true}
                    className={props.className}
                    columns={columns || []}
                    dataSource={
                        props?.data?.map((item, index) => ({ ...item, key: item._id || index })) || []
                    }
                    scroll={{
                        x: totalColumnWidth
                    }}
                    pagination={{
                        pageSize: props?.isPagination ? 10 : props?.data?.length,
                        position: ['none', 'none']
                    }}
                    locale={{
                        emptyText: (
                            <div className='py-[1rem]'>
                                {props.noDataFoundComponent ?? <div>Not Found</div>}
                            </div>
                        )
                    }}
                />
            </ConfigProvider>
        </div>
    )
}

export default AntDTable