import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { fetchUserAfterOnboarding, updateTeamMemberInvite } from '../features/userSlice';
import { patchOnboardingDetails } from '../features/onboardingSlice';

const useInviteTeamMember = () => {
    const dispatch = useDispatch();
    const isTeamMemberInvited = useSelector((state) => state.user.isTeamMemberInvited)
    React.useEffect(() => {
        console.log("exe", !isTeamMemberInvited)
        !isTeamMemberInvited && dispatch(fetchUserAfterOnboarding())
        dispatch(patchOnboardingDetails({ "fieldName": "stageNumber", "data": 3 }));
        dispatch(updateTeamMemberInvite())
    }, [])
}

export default useInviteTeamMember