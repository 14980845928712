import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { fetchUserActivityDataApi } from "./userActivitySlice";
import { updateLoading, resetActivityOnUnMount, clearAllNotificationDataApi } from './userActivitySlice';
import sound from "../../assets/common/sound.svg";
import { formatDistanceToNow } from "date-fns"
import NotificationMessage from './NotificationMessage';
import refreshIcon from "../../assets/common/refreshIcon.svg"
import notification_locals from "../../localization/localization.en_notification_message.json"

const UserActivity = () => {
    const dispatch = useDispatch();
    const useractivity = useSelector((state) => state.useractivity);
    useEffect(() => {
        dispatch(fetchUserActivityDataApi());
        return () => dispatch(resetActivityOnUnMount());
    }, [])
    useEffect(() => {
        if (useractivity.loading === "success") {
            dispatch(updateLoading("idle"))
        }
    }, [useractivity.loading])
    const lastSeenTextNode = notification_locals.last_seen.replace('{data}',useractivity.lastSeenNotification)
    return (
        <div className='w-[485px] border flex flex-col justify-between rounded-md shadow-xl'>
            <div className='min-h-[50vh] bg-white'>
                <div className='text-[#111827] bg-white flex justify-between border font-InterRegular items-center py-[16px] px-[24px] text-[14px] font-medium tracking-wide'>
                    <div>
                        <span className='text-[14px]'>{notification_locals.title}</span>
                        <span className='text-[11px] ml-[0.5rem]'>{lastSeenTextNode}</span>
                    </div>
                    <button onClick={() => dispatch(fetchUserActivityDataApi())} className='text-primary font-semibold rounded-md'><img src={refreshIcon} alt="" /></button>
                </div>
                {
                    useractivity.activites.length > 0
                        ? <ul style={{marginBottom:0}} className="activitylist overflow-y-auto max-h-[50vh]">
                            {
                                useractivity.activites.map((activity) => {
                                    const splittedtext = activity.label.split('[username]');
                                    const timestamp = new Date(activity.createdAt);
                                    const difference = formatDistanceToNow(timestamp, { addSuffix: true });
                                    return (
                                        <React.Fragment>
                                            <NotificationMessage splittedtext={splittedtext} difference={difference} activity={activity} />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </ul>
                        : <div className='flex items-center justify-between mx-6 mt-2'>
                            <h3 className='font-semibold font-InterRegular'>{notification_locals.no_notifications}</h3>
                        </div>
                }
            </div>
            <div className='text-[#111827] bg-white flex justify-between border font-InterRegular items-center py-[16px] px-[24px] text-[14px] font-medium tracking-wide'>
                <button onClick={() => dispatch(clearAllNotificationDataApi())} className='text-slate-500 p-2 bg-white border shadow-md font-semibold rounded-md'>{notification_locals.clear_all}</button>
            </div>
        </div>
    )
}

export default UserActivity