import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Select, Space, Button } from 'antd';
import TextMedium from '../TypographyDescriptions/TextMedium';
import white_dropdown from "../../assets/common/white_dropdown.svg"

const SelectOptionWithAddMore = ({
    name,
    value,
    listOfOptions = [],
    handleOnClickAddItem,
    onChangeEvent,
    placeHolder = "Select Option",
    isAddMore = true,
    minSelectHeight = 32,
    optionTextAlign = "left",
    addMoreButtonName = "Add More",
    customPlaceholder = false,

    optionStyle,
    isDividerLineVisible = false,
    isAddMoreDisabled = false,
    isSelectOptionDisabled = false,
    NoDataFoundTextComponent,
    isPlaceHolderCenter=true

}) => {
    const selectedValue = listOfOptions.find((item) => item.value===value)
    return (
        <React.Fragment>
            <div className='relative rounded-md'>
                <Select
                    name={name}
                    value={value}
                    className=''
                    onChange={onChangeEvent}
                    disabled={isSelectOptionDisabled}
                    placeholder={customPlaceholder ? '' : placeHolder}
                    notFoundContent={<div className='flex justify-center'>
                        {!!NoDataFoundTextComponent ? NoDataFoundTextComponent : <TextMedium color='#9E9E9E' text={"No Data Found"} fontWeight={400} />}
                    </div>}
                    style={{
                        minHeight: `${minSelectHeight}px`,
                    }}
                    dropdownRender={(menu) => {
                        return (
                            <>
                                {menu}
                                {isDividerLineVisible ? <Divider style={{ margin: '8px 0' }} /> : null}
                                <div style={{ display: isAddMore ? "block" : "none" }}>
                                    <Button
                                        block
                                        type="text"
                                        icon={<PlusOutlined />}
                                        disabled={isAddMoreDisabled}
                                        onClick={handleOnClickAddItem}
                                        style={{ padding: "0rem 0.5rem", width: "100%" }}
                                    >
                                        {addMoreButtonName}
                                    </Button>
                                </div>
                            </>
                        )
                    }}
                    options={listOfOptions.map((item) => {
                        return (
                            {
                                label: <div className={`${optionTextAlign === "center" ? "w-full text-center" : "pl-[0.5rem]"}`}>{item.label}</div>,
                                value: item.value,
                                style: { "backgroundColor": item?.backgroundColor ?? "#FFFFFF", "color": item?.textColor ?? "#000000", ...optionStyle }
                            }
                        )
                    })}
                />
                {/* custom placeholder */}
                {
                    customPlaceholder && !!value===false
                        ? (
                            <div className={`w-full absolute top-0 h-full rounded-md pointer-events-none`}>
                                <div className={`flex items-center h-full pl-[0.6rem] pr-[2.15rem] ${isPlaceHolderCenter ? 'justify-center' : ''}`}>
                                    <TextMedium color={"#9E9E9E"} fontWeight={400} text={placeHolder} />
                                </div>
                            </div>
                        ) : null
                }
                {/* custom value */}
                {
                    !!value
                    ? (
                        <div
                            style={{ "backgroundColor": selectedValue?.backgroundColor ?? "#FFFFFF", "color": selectedValue?.textColor ?? "#000000" }}
                            className={`${selectedValue} w-full absolute top-0 flex items-center justify-center h-full left-0 rounded-md pointer-events-none`}
                        >
                            <TextMedium color={ selectedValue?.textColor ?? "#000000"} fontWeight={400} text={selectedValue?.label} />
                            <img src={white_dropdown} className='absolute transform right-2 top-1/2 -translate-y-1/2' alt="" />
                        </div>
                    ) : null
                }
            </div>
            <style>
                {
                    `
                        .ant-select{
                            border-radius: 6px;
                        }
                        .ant-select-selector {
                            width: 100%;
                            min-height: inherit !important;
                        }
                    `
                }
            </style>
        </React.Fragment>
    )
}

export default SelectOptionWithAddMore;