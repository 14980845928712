import { months } from "../data/data";
import businessmodalLocals from "../../../localization/localization_en_business_modal.json"
import { percentAndAvgForSmAndECommerProp } from "../functionality/RevenueOnboarding/generateDefaultData";
import { default_total_mau_and_registered_users } from "../functionality/RevenueOnboarding/generateDefaultData";
import { generateDefaultKeyDetailsData } from "../functionality/RevenueOnboarding/generateDefaultData";

const locals = businessmodalLocals.business_modal.revenue;

export const formatFiscalYearForAntDCalender = ({ dbmonth, dbyear }) => {
    if(!!dbmonth===false || !!dbyear===false) return null;
    try {
        let month = "";
        for (const mon of Object.entries(months)) {
            if (mon[1].value === dbmonth) {
                month = String(mon[0]).length === 1 ? 0 + mon[0] : mon[0];
            }
        }
        return String(dbyear + '-' + month);
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const traceComponentRendering = ({ db_channels_of_sales }) => {
    let trackComponentRendering = [0, 1, 5]
    try {
        const channels_of_sales = {
            "E-Commerce": 2,
            "Social Media": 3,
            "Website & Mobile App": 4
        }
        for (const channel of Object.entries(channels_of_sales)) {
            if (db_channels_of_sales?.includes(channel[0])) {
                trackComponentRendering?.push(channel[1])
            }
        }
        trackComponentRendering?.sort((a, b) => a - b)
        return trackComponentRendering;
    } catch (error) {
        console.log(error);
        return trackComponentRendering;
    }
}

export const formatDependencyData = ({ dependencyData }) => {
    return {
        "channel_sales": dependencyData?.channels_of_sales,
        "marketing_spend_per_channel_fiscal_year": formatFiscalYearForAntDCalender({ "dbmonth": dependencyData?.marketing_spend_per_sales_channel?.first_month, "dbyear": dependencyData?.marketing_spend_per_sales_channel?.first_year }),
        "trackComponentRendering": traceComponentRendering({ "db_channels_of_sales": dependencyData?.channels_of_sales })
    }
}

export const revenueScreenOneDataInjection = ({ input, output }) => {
    try {
        let acquiringUserOptions = [];
        let month = "";
        for (const mon of Object.entries(months)) {
            if (mon[1].value === input?.marketing_spend_per_sales_channel?.first_month) {
                month = String(mon[0]).length === 1 ? 0 + mon[0] : mon[0];
            }
        }
        const year = input?.marketing_spend_per_sales_channel?.first_year
        const select_options = locals.form[0].data[2].select_options
        let acquiring_user_options = []
        for (const medium of Object.entries(select_options)) {
            acquiring_user_options?.push(medium[1])
        }
        if (acquiring_user_options.length) {
            let acquiring_user_visible_options = acquiring_user_options.flat().sort((a, b) => a?.position - b?.position)
            if(!input?.channels_of_sales.includes("Social Media")){
                acquiring_user_visible_options = acquiring_user_visible_options.filter((option) => option.value!=="Social Media Buyer")
            }
            if(!input?.channels_of_sales.includes("E-Commerce")){
                acquiring_user_visible_options = acquiring_user_visible_options.filter((option) => option.value!=="E-Commerce Buyer")
            }
            if (input?.channels_of_sales.includes("Website & Mobile App")) {
                acquiringUserOptions = acquiring_user_visible_options;
            }
        }
        const type = input?.marketing_spend_per_sales_channel?.type;
        const monthdb = input?.marketing_spend_per_sales_channel?.first_month;
        const yeardb = input?.marketing_spend_per_sales_channel?.first_year;
        let finalData = {}
        for (let salesChannel of Object.entries(input?.marketing_spend_per_sales_channel)) {
            if (typeof salesChannel[1] === "object") {
                let salesChannelAddedAttr = {};
                if (type === "last_month_marketing_spend") {
                    salesChannelAddedAttr = {
                        ...salesChannel[1], month: monthdb, year: yeardb,
                        "last_six_months": Array.from({ length: 6 }).map((_, i) => ({ month: monthdb, year: yeardb, "spend_value": null }))
                    }
                } else {
                    salesChannelAddedAttr = {
                        ...salesChannel[1], month: monthdb, year: yeardb,
                        "last_month_marketing_spend": null,
                        "mom_spend_growth_percent": null
                    }
                }
                finalData = { ...finalData, [salesChannel[0]]: salesChannelAddedAttr }
            } else {
                finalData = { ...finalData, [salesChannel[0]]: salesChannel[1] }
            }
        }
        return {
            "channel_sales": input?.channels_of_sales,
            "marketing_spend_per_sales_channel": finalData,
            "trackComponentRendering": traceComponentRendering({ "db_channels_of_sales": input?.channels_of_sales }),
            "marketing_spend_per_channel_fiscal_year": `${year}-${month}`,
            "acquiring_app_users": input?.users_acquiring,
            "acquiringUserOptions": acquiringUserOptions,
            "output": (output === undefined || output === null) ? null : { output }
        }
    } catch (error) {
        console.log(error)
        return {};
    }
}

export const revenueScreenTwoDataInjection = ({ input, output, productCategoryList }) => {
    try {
        const productCatgeory = productCategoryList.map((pc) => ({ "id": pc._id, "title": pc.title, }))
        let manufacture_product_category = []
        let buy_product_category = []
        input?.product_categories_details?.map((pc) => {
            // const nameId = productCatgeory?.find((tag) => tag?.title === pc?.name)
            if (pc?.type === "manufacture") {
                manufacture_product_category?.push(pc);
                return;
            }
            buy_product_category.push(pc)
        })
        return {
            "manufacture_or_buy": input?.manufacture_or_buy,
            "product_categories_details": {
                "manufacture": manufacture_product_category,
                "buy": buy_product_category
            },
            "output": !!output === false ? null : { "productCategoryOutput": output }
        }
    } catch (error) {
        console.log(error);
        return {};
    }
}

export const revenueScreenThreeDataInjection = ({ screen2Input, screen3Input, productCategoryList, output }) => {
    try {
        let manufacture_product_category = []
        let buy_product_category = []
        const productCatgeory = productCategoryList.map((pc) => ({ "id": pc._id, "title": pc.title, }))
        screen2Input?.product_categories_details?.map((pc, index) => {
            // const nameId = productCatgeory?.find((tag) => tag?.title === pc?.name)
            const screen3Data = {
                "commission_cost": !!screen3Input ? screen3Input?.platform_commission_cost?.[index]?.commission_cost : null,
                "platform_commission_cost_varying_cost_over_time": !!screen3Input ? screen3Input?.platform_commission_cost?.[index]?.platform_commission_cost_varying_cost_over_time : null
            }
            if (pc?.type === "manufacture") {
                manufacture_product_category?.push({ ...pc, ...screen3Data });
                return;
            }
            buy_product_category.push({ ...pc, ...screen3Data })
        })
        return {
            "marketing_spend_conversion": screen3Input?.marketing_spend_conversion ? screen3Input?.marketing_spend_conversion : null,
            "manufacture_or_buy": screen2Input?.manufacture_or_buy,
            "product_categories_details": {
                "manufacture": manufacture_product_category,
                "buy": buy_product_category
            },
            "output": !!output === false ? null : output
        }
    } catch (error) {
        console.log(error);
        return {};
    }
}

export const revenueScreenFourDataInjection = ({ input, output, channelCategorylist }) => {
    try {
        const organic_paid_traffic_social_media_marketplaces = input?.organic_paid_traffic_social_media_marketplaces
        const organic_traffic_conversion_rate = input?.organic_traffic_conversion_rate
        return {
            "social_media_marketplaces": input?.social_media_marketplaces,
            "organic_paid_traffic_social_media_marketplaces": organic_paid_traffic_social_media_marketplaces,
            "organic_traffic_conversion_rate": organic_traffic_conversion_rate,
            "output": !!output === false ? null : output
        }
    } catch (error) {
        console.log(error);
        return {};
    }
}

export const revenueScreenFiveDataInjection = ({ input, output, channel_sales, acquiring_app_users }) => {
    try {
        let key_details_user_acquisition_engagement = input?.user_acquisition_engagement_retention ? input?.user_acquisition_engagement_retention : generateDefaultKeyDetailsData()
        const social_media = !!input?.referal_registered_users?.social_media ? input?.referal_registered_users?.social_media : [percentAndAvgForSmAndECommerProp]
        const e_commerce = !!input?.referal_registered_users?.e_commerce ? input?.referal_registered_users?.e_commerce : [percentAndAvgForSmAndECommerProp]
        const word_of_mouth_1 = !!input?.referal_registered_users?.word_of_mouth_1 ? input?.referal_registered_users?.word_of_mouth_1 : [percentAndAvgForSmAndECommerProp]
        const word_of_mouth_2 = !!input?.referal_registered_users?.word_of_mouth_2 ? input?.referal_registered_users?.word_of_mouth_2 : [percentAndAvgForSmAndECommerProp]
        const total_mau_and_registered_users = !!input?.total_mau_and_registered_users ? input.total_mau_and_registered_users : default_total_mau_and_registered_users
        if (channel_sales?.includes("Website & Mobile App")) {
            const checkIsAllDataVisible = key_details_user_acquisition_engagement?.filter((row) => row?.visible === true)?.length;
            if (acquiring_app_users?.includes("Paid Marketing")) {
                if (checkIsAllDataVisible !== 6){
                    key_details_user_acquisition_engagement = key_details_user_acquisition_engagement?.map((row) => ({ ...row, visible: true }));
                }
            } else {
                if (checkIsAllDataVisible !== 4){
                    const slicedData = key_details_user_acquisition_engagement?.map((row, index) => index <= 1 ? { ...row, visible: false, fixed: null, varying_amount_over_time: null } : {...row, visible: true})
                    key_details_user_acquisition_engagement = slicedData;
                }
            }
        }
        return {
            key_details_user_acquisition_engagement,
            social_media,
            e_commerce,
            word_of_mouth_1,
            word_of_mouth_2,
            total_mau_and_registered_users,
            "output": !!output === false ? null : output
        }
    } catch (error) {
        console.log(error);
        return {};
    }
}

export const revenueScreenSixDataInjection = ({ output }) => {
    try {
        return {
            "output": !!output === false ? null : output
        }
    } catch (error) {
        console.log(error);
        return {};
    }
}