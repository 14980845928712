import React from 'react'
import TextMedium from '../../TypographyDescriptions/TextMedium';
import pdlocals from "../../../localization/localization_en_pitch_deck.json"
import { editSlideShowHeaderData } from "../../../components/PitchDeck/data";
import { Button } from "antd";

const navItems = pdlocals.pitch_deck.tools.header;

const DividerElement = () => <div className='w-[1px] h-[2rem] bg-baseGray500'></div>

const ListNavItem = ({ currentFeature="home" }) => {
    
    return (
        <ul style={{ margin: 0 }} className='flex justify-between items-center w-full px-[40px] py-[16px]'>
            {
                navItems?.map((item, index) => {
                    const Icon = editSlideShowHeaderData[item.icon];
                    const isActiveLink = item.name.toLowerCase()===currentFeature;
                    const activeColor = isActiveLink ? "#372EE5" : "#FFFFFF";
                    return (
                        <React.Fragment key={index}>
                            {index === navItems.length - 1 && <DividerElement />}
                            <Button
                                style={{ color: activeColor, border: "none", backgroundColor: isActiveLink && "white" }}
                                className='flex items-center hover:bg-[#21208F] hover:text-white px-[16px] py-[8px]'>
                                <Icon color={activeColor} />
                                <TextMedium padding={{ direction: "left", number: 8 }} text={item.name} color='' />
                            </Button>
                            {index === 0 && <DividerElement />}
                        </React.Fragment>
                    )
                })
            }
        </ul>
    )
}

export default ListNavItem