import React from 'react'
import RadioInput from './RadioInput'
import VerticalScrollableTable from '../../../../../DesignSystem/Table/VerticalScrollableTable';

const ToggleTableLabel = ({ toggleTableLabelProps }) => {
    const { formItem, data, isReadOnly } = toggleTableLabelProps;
    const {product_category_2,radioInputProps} = data
    const tableProps = { "table_info_data": formItem, name: formItem.tableName, "data": product_category_2, isReadOnly }
    return (
        <div className='flex flex-col gap-y-[16px]'>
            <RadioInput {...radioInputProps} />
            {
                radioInputProps.data
                ? <VerticalScrollableTable tableProps={tableProps} label={formItem.sublabels[radioInputProps.data]} />
                : null
            }
        </div>
    )
}

export default ToggleTableLabel