import React from 'react';
import { Tabs } from 'antd';
import "./tabVarientStyle.css"

const LinkTab = ({tabNames=[], activeKey, handleTabChange}) => {
    return (
        <>
            <Tabs
                activeKey={activeKey}
                tabPosition={'top'}
                className='link-tab'
                animated={true}
                onTabClick={handleTabChange}
                items={new Array(tabNames.length).fill(null).map((_, i) => {
                    return {
                        label: `${tabNames[i].label}`,
                        key: tabNames[i].key,
                        disabled: false,
                        children: <div className='mt-[40px]'>{tabNames[i].component}</div>,
                    };
                })}
            />
        </>
    );
};
export default LinkTab;