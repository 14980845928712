import { colors } from "../data"

export const FillRowCols = (psm, showExample) => {
    const newSolution = { isDisabled:-1,bgColor:"",solutiondescription: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0 };
    const updatedMatrix = psm;
    // Find the maximum number of collectionSolution items in any row
    const maxNumberOfSolutions = Math.max(
        ...updatedMatrix.map((problem) => problem.collectionSolution.length)
    )
    const maxisolution = maxNumberOfSolutions <= 2 ? 3 : maxNumberOfSolutions;


    // Now, make sure all rows have the same number of collectionSolution items
    const finalMatrix = updatedMatrix.map((problem) => {
        const diff = maxisolution - problem.collectionSolution.length;
        if (diff > 0) {
            // Add empty solutions to make the length equal to maxNumberOfSolutions
            const updatedCollectionSolution = [...problem.collectionSolution];
            for (let i = 0; i < diff; i++) {
                updatedCollectionSolution.push({ ...newSolution});
            }
            return { ...problem, collectionSolution: updatedCollectionSolution };
        }
        return problem;
    });
    const updatedMatrixWithDisabled = finalMatrix.map((problem) => {
        const updatedCollectionSolution = problem.collectionSolution.map((solution) => {
            const doc = colors[0]
          return {
            ...solution,
            isDisabled: solution?.solutiondescription && solution.solutiondescription.trim().length === 0 ? -1 : 1,
            docColor:colors[Math.floor(Math.random() * colors.length)]
          };
        });
        return { ...problem,isProblemDisabled:problem.problemStatement.trim().length===0 ? -1 : 1, collectionSolution: updatedCollectionSolution };
      });
    if(psm.length===0){
        return { showExample:true,psm:updatedMatrixWithDisabled, maxisolution}
    }
    return {psm:updatedMatrixWithDisabled,showExample:showExample, maxisolution}
}