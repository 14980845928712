import PrimaryButton from "../../Buttons/PrimaryButton";
import PrimaryDangerButton from "../../Buttons/PrimaryDangerButton";
import closeSvg from "../../../assets/common/close.svg";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteDocumentsApi } from "../../../features/dataRoomSlice";
import { message, Modal, notification } from 'antd';
import TextInput from "../../Forms/InputVarients/TextInput";
import CloseButton from '../../SVG/CloseButtonSVG';

const DeleteModal = ({ isOpen, onClose, documentList }) => {
	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState("");
	const [modalOpen, setModalOpen] = useState(isOpen);
    const [loading, setLoading] = useState(false)

	useEffect(() => {
		setModalOpen(isOpen);
	}, [isOpen]);

	const handleOk = () => {
		setModalOpen(false);
        onClose();
	};

	const handleCancel = () => {
		setModalOpen(false);
        onClose();
	};


	const handleClick = async () => {
        setLoading(true)
		const body = {
			documentIdList: documentList
		}
		await dispatch(deleteDocumentsApi(body)).then((response) => {
            if(response) {
                // notification.info({
                //     message: "Success",
                //     description: "Folder SuccessFully Deleted"
                // })
				message.success("Folder SuccessFully Deleted")
            } else {
                // notification.error({
                //     message: "Error",
                //     description: "Folder can not be deleted. Please try again!"
                // });
				message.error("Folder can not be deleted. Please try again!")
            }
            setLoading(false)
            handleOk()
        })
	};
	return (
		<Modal
			title={<span style={{ fontSize: '20px' }}>Delete Folder</span>}
			centered
			open={modalOpen}
			onOk={handleOk}
			onCancel={handleCancel}
		>
			<button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
				<CloseButton />
			</button>

			<p className=" text-[#111827] text-[14px] pr-6">
				Are you sure you want to delete these folders from this
				dashboard ? You can also access the same file in 'My Files’
				after deletion.
			</p>

			<div className=" flex justify-between mt-3 ">
				<SecondaryButton
					text={"Cancel"}
					onClick={handleCancel}
					size={"Medium"}
                    isDisabled={loading}
				/>
				<PrimaryDangerButton text={"Delete"} onClick={handleClick} size={"Medium"} isLoading={loading} isDisabled={loading}/>
			</div>
		</Modal>
	);
};

export default DeleteModal;
