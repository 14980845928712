import React, { useState, useEffect } from "react";
import { Modal, Input, notification, message } from "antd";
import CloseButton from "../../SVG/CloseButtonSVG";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { renameFileApi, updateLockStatusApi } from "../../../features/dataRoomSlice";
import TextMedium from "../../TypographyDescriptions/TextMedium";
import ModalSection from "./ModalSection";

const LockFileModal = ({ isOpen, onClose, documentList, lock }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        setModalOpen(false);
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleLock = async () => {
        setLoading(true)
        const body = {
            documentId: documentList._id,
            lockStatus: lock
        }

        await dispatch(updateLockStatusApi(body)).then((response) => {
            if (response?.meta.requestStatus !== "fulfilled") {
                message.error(lock ? "File locking failed" : "File unlocking failed")
            }
            setLoading(false)
            setIsModalVisible(true);
            handleOk();
        })
    }

    return (
        <>
        {isModalVisible && (
            <ModalSection
                title="Info"
                closeHandler={() => { setIsModalVisible(false); onClose(); }}
                text={lock ? "Success! The file has been locked." : "Success! The file has been unlocked."}
            />
        )}
        <Modal
            title={<span style={{ fontSize: "20px" }}>{lock ? "Lock File" : "Unlock File"}</span>}
            centered
            open={modalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <button
                className="absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all"
                onClick={handleCancel}
            >
                <CloseButton />
            </button>
            <div className="flex flex-col gap-3 ">
                <div>
                {
                    lock
                    ? (
                        <>
                            <TextMedium fontWeight={500} text={"Are your sure want to lock this file ?"} display="block" />
                            <TextMedium fontWeight={500} text={"Once locked it, it will be protected from further details."} />
                        </>
                    ) : (
                        <>
                           <TextMedium fontWeight={500} text={"Unlocking this file will allow you to make further edits."} display="block" />
                           <TextMedium fontWeight={500} text={"Are you sure you want to proceed ?"} /> 
                        </>
                    )
                }
                </div>
                <div className=" w-full flex flex-row justify-between mt-3 ">
                    <div>
                        <SecondaryButton text={"Cancel"} size="Medium" isDisabled={loading} />
                    </div>
                    <div>
                        <PrimaryButton text={lock ? "Lock" : "Unlock"} size="Medium" onClick={handleLock} isLoading={loading} isDisabled={loading} />
                    </div>
                </div>
            </div>
        </Modal>
        </>
    );
};

export default LockFileModal;

