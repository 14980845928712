import React from 'react'
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge'
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour'
import TextSmall from '../../DesignSystem/TypographyDescriptions/TextSmall'
import ResponsiveHeadingFourInter from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter'

const TeamMemberOverview = ({ person_image, role, name, introduction }) => {
    return (
        <div className='flex flex-col gap-y-[24px]'>
            <img src={person_image} alt="" className='rounded-[10px] overflow-hidden' />
            <div className='flex flex-col items-center gap-y-[8px]'>
                <TextLarge text={role} fontWeight={500} color={"#111827"} />
                <ResponsiveHeadingFourInter text={name} color={"#372EE5"} />
                <TextSmall className="text-justify" text={introduction} color='#757575' />
            </div>
        </div>
    )
}

export default TeamMemberOverview