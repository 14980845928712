import bm_locals from "../../../../localization/localization_en_business_modal.json";

export const LAST_MONTH = {
    "last_month_ms": null,
    "month_on_month_ms": null,
}

export const LAST_6_MONTH = {
    "month1": null,
    "month2": null,
    "month3": null,
    "month4": null,
    "month5": null,
    "month6": null,
}

export const MARKETING_SPEND__LAST_MONTH_DEFAULT_ROW_HEADING = {
    "Social Media": {
        "category": "Social Media",
        "tooltip": "Tooltip1"
    },
    "Website & Mobile App": {
        "category": "Website & Mobile App",
        "tooltip": "Tooltip2"
    },
    "E-commerce": {
        "category": "E-commerce",
        "tooltip": "Tooltip3"
    }
}

export const MARKETING_SPEND__LAST_6_MONTH_DEFAULT_ROW_HEADING = {
    "Social Media": {
        "category": "Social Media",
        "tooltip": "Tooltip1"
    },
    "Website & Mobile App": {
        "category": "Website & Mobile App",
        "tooltip": "Tooltip2"
    },
    "E-commerce": {
        "category": "E-commerce",
        "tooltip": "Tooltip3"
    }
}

export const platformCommissionCostProp = {
    "commission_cost": null,
    "change_yoy": null
}

export const generatePlatformCommissionCost = (data) => {
    return data.map((row) => ({ ...platformCommissionCostProp, "category": row.category }))
}

export const generateMarketingSpendData = (type) => {
    let data = [];
    let index = 0;
    const marketing_span_type = type === "last_month" ? LAST_MONTH : LAST_6_MONTH;
    const marketing_span_row = type === "last_month" ? MARKETING_SPEND__LAST_MONTH_DEFAULT_ROW_HEADING : MARKETING_SPEND__LAST_6_MONTH_DEFAULT_ROW_HEADING;
    for (const [key, value] of Object.entries(marketing_span_row)) {
        data.push({ key: index, ...value, ...marketing_span_type });
        index++;
    }
    return data;
}

export const marketing_spend_per_sale_channel = {
    "month": null,
    "year": null,
    "last_month_marketing_spend": null,
    "mom_spend_growth_percent": null,
    "last_six_months": new Array(6).fill(null).map((_, index) => ({ "month": null, "year": null, "spend_value": null }))
}

export const generateIndividualRow = (type, channel) => {
    return type === "last_month"
        ? { key: channel, ...MARKETING_SPEND__LAST_MONTH_DEFAULT_ROW_HEADING[channel], ...LAST_MONTH }
        : { key: channel, ...MARKETING_SPEND__LAST_6_MONTH_DEFAULT_ROW_HEADING[channel], ...LAST_6_MONTH }
}

export const socialMediaMarketingPlaceDataProp = {
    "channel": null,
    "marketing_spend_split": null,
    "cpc_per_channel": null,
    "varying_cost_over_time": null,
    "conversion_by_channel_percentage": null
}

export const generateSocialMediaMarketingPlaceData = (data) => Array.from({ length: 3 }).map((defaultData, index) => ({ ...socialMediaMarketingPlaceDataProp, "channel": data?.[index]?.id }))

const defaultuserAcquisitionEngagementRetentionRows = [
    {
        "questions": "CAC Per User",
        "tooltip": "Tooltip",
        "fixed": null,
        "varying_yoy": null,
        "isMandatory":true
    },
    {
        "questions": "% of Paid Users are Organic Users",
        "tooltip": "Tooltip",
        "fixed": null,
        "varying_yoy": null,
        "isMandatory":true
    },
    {
        "questions": "% of MoM Churn",
        "tooltip": "Tooltip",
        "fixed": null,
        "varying_yoy": null,
        "isMandatory":true
    },
    {
        "questions": "% of Registered Users are Active Users",
        "tooltip": "Tooltip",
        "fixed": null,
        "varying_yoy": null,
        "isMandatory":true
    },
    {
        "questions": "% of Your MAU Places an Order in a Month",
        "tooltip": "Tooltip",
        "fixed": null,
        "varying_yoy": null,
        "isMandatory":true
    },
    {
        "questions": "% of Registered Users Install your App",
        "tooltip": "Tooltip",
        "fixed": null,
        "varying_yoy": null,
        "isMandatory":true
    }
]


export const generateUserAcquisitionEngagementRetentionRows = (data) => {
    let createdRows = [];
    defaultuserAcquisitionEngagementRetentionRows.map((row, index) => {
        const subdata = row
        if (index <= 1) {
            if (!data.includes("Paid Marketing")) return;
            createdRows.push(subdata)
            return;
        }
        createdRows.push(subdata);
    })
    return createdRows;
}

export const filterBasedOnSelection = (actualData, isPresentSelectionData) => {
    if(isPresentSelectionData){
        return (
            actualData.length === 6 
            ? actualData
            : [...defaultuserAcquisitionEngagementRetentionRows.slice(0,2),...actualData]
        )
    }else{
        return (
            actualData.length === 6
            ? actualData.slice(2, actualData.length)
            : actualData
        )
    }
}

export const percentAndAvgForSmAndECommerProp = {
    fixed:null,
    varying_over_time:null
}

export const default_total_mau_and_registered_users = [
    {
        "new_registered_users": null,
        "total_mau": null
    }
]

export const generateDefaultKeyDetailsData = (type="create") => {
    let generatedData = [];
    let counter = 0;
    for (const data of Object.entries(bm_locals.business_modal.revenue.form[4].data[0].table.defaultData)){
        if(type === "slice" && generatedData.length>=2){
            return generatedData;
        }
        generatedData = [
            ...generatedData,
            { "question": data[0], "fixed":null, "varying_amount_over_time": null, "visible": true}
        ]
        counter++;
    }
    return generatedData;
}