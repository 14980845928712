import React from 'react'
import HeadingSix from '../../../DesignSystem/TypographyHeadings/HeadingSix'
import StaticTableRender from '../../../DesignSystem/Table/StaticTableRender'
import DescriptionWithButton from './DescriptionWithButton'
import SubscriptionInvoiceList from './SubscriptionInvoiceList'

const SubscriptionBlockDetail = ({ title, tableData, tableColumns, expireStatus, currentSubDetail, preExpireStatus, onClick, buttonName, isDataLoading, isSubscriptionTable=false, isShowCancelSubscription=false, toggleCancelSubcriptionModelOpen }) => {
    return (
        <div className='flex flex-col gap-y-[0.5rem]'>
            <HeadingSix headingCSSStyles={{ margin: 0 }} text={title} />
            {
                isSubscriptionTable
                ? <SubscriptionInvoiceList tableData={tableData} currentSubDetail={currentSubDetail} />
                : <StaticTableRender columns={tableColumns} data={tableData} isDataLoading={isDataLoading} />
            }
            {
                preExpireStatus.isShow && (
                    <DescriptionWithButton onClick={onClick} description={preExpireStatus.text} buttonName={buttonName} />
                )
            }
            {
                expireStatus.isShow && (
                    <DescriptionWithButton onClick={onClick} description={expireStatus.text} buttonName={buttonName} />
                )
            }
            { isShowCancelSubscription && <div className='mx-auto'>
                <button onClick={toggleCancelSubcriptionModelOpen} className='bg-primary px-2 text-white rounded-md py-[0.5rem]'>Cancel Subscription</button>
            </div> }
        </div>
    )
}

export default SubscriptionBlockDetail