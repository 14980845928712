export function daysBetweenTimestamp({ timestamp1, timestamp2 }) {
    try {
        const date1 = new Date(timestamp1);
        const date2 = new Date(timestamp2);

        const timeDiff = date2 - date1;

        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        return daysDiff > 0 ? daysDiff : 0;
    } catch (error) {
        console.log(error);
        return 0;
    }
}