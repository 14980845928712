import React, { memo, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import blue from '../assets/profile/green.svg';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Input } from 'antd';
import profile_green from "../assets/PSM/profile_green.svg"
import moreIcon from "../assets/PSM/moreIcon.svg"
import EditComment from "../assets/PSM/EditComment.svg"
import DeleteComment from "../assets/PSM/DeleteComment.svg"
import "./problemSolutionMatrix/psm.css"
import { timeStamptoHour } from './reuseablecomponent/timestampToHour';
import notificationLocals from "../localization/localization.en_notification_message.json"
import commentLocals from "../localization/localization_en_comment.json"

import { handleDeleteParentId, updateNoOfComments, updatingNestedCommentApi, updateNestedParentHoldingId, deletingNestedCommentApi, fetchComments, updateConversationList, postingCommentApi, deletingCommentApi, updatingCommentApi, postingNestedCommentApi, addNestedComment, updateMatrix } from '../features/psmSlice';
import { postUserActivityDataApi } from './UserActivity/userActivitySlice';
import ToolTipModal from './modals/ToolTipModal';
import { toggleisDeleteNestedCommentOpen } from '../features/spamSlice';
import DeleteNestedCommentModal from './modals/DeleteNestedCommentModal';
import useOutsideClick from '../hooks/useOutsideClick';
import DangerModal from '../DesignSystem/Modals/DangerModal';
const { TextArea } = Input;

const notificationLocal = notificationLocals.notification_message.comment

const CommentList = () => {
    const dispatch = useDispatch();
    const nestedCommentFocus = useRef();
    const isDeleteNestedCommentOpen = useSelector((state) => state.spam.isDeleteNestedCommentOpen)
    const msgData = useSelector((state) => state.psm.conversationList);
    const username = useSelector((state) => state.user.value.username);
    const avatar = useSelector((state) => state.user.value.avatar);
    const state = useSelector((state) => state.psm.problemsolutionmatrix);
    const currentUser = useSelector((state) => state.user.value.id);
    const conversationDetails = useSelector((state) => state.psm.conversationList);
    const [msg, setMsg] = useState('');
    const [showOptions, setShowOptions] = useState(null);
    const [editingCommentId, setEditingCommentId] = useState(null); // Track the ID of the comment being edited
    const comments = msgData.comments
    const [nestedShowInputField, setNestedShowInputField] = useState(null);
    const [nestedActiveCommentText, setNestedActiveCommentText] = useState("");
    const [showNestedCRUDButton, setShowNestedCRUDButton] = useState({ index: null, subindex: null });
    const [disbaleNestedComment, setdisbaleNestedComment] = useState(null);
    const deleteParentCommentId = useSelector((state) => state.psm.deleteParentCommentId);
    const [prevMessage, setPrevMessage] = useState("");
    const [isDeleteMsgModalOpen, setIsDeleteMsgModalOpen] = useState({ openFalse: false, id: null });
    const [nestedModalValueRef, setNestedModalValueRef] = useState(null);
    const [editFromNestedOrParent, setEditFromNestedOrParent] = useState(null);
    const commentRef = useRef(null);
    const nestedCommentRef = useRef(null);

    useOutsideClick(commentRef,() => {
        setShowOptions(null);
    })
    useOutsideClick(nestedCommentRef, () => {
        setShowNestedCRUDButton({index:null, subindex:null});
    })

    useEffect(() => {
        dispatch(fetchComments());
    }, [conversationDetails.sid])

    const handleNewMessage = () => {
        const updatedComments = [...comments, { msgContent: msg, nestedComments:[], userId:{_id:currentUser,username,avatar} }];
        dispatch(
            updateConversationList({
                comments: updatedComments,
                commentlength: comments.length,
            })
        );
        dispatch(updateNoOfComments(updatedComments.length));
        dispatch(postingCommentApi({ sid: msgData.sid, msgContent: msg }));
        dispatch(postUserActivityDataApi({ ...notificationLocal.add }));
        // setComments(updatedComments);
        setMsg('');
    };
    const handleEditIconClick = (commentId) => {
        console.log(commentId === editingCommentId)
        if (editingCommentId === commentId) {
            // If the comment is already being edited, save the changes
            const commentToSave = comments.find((comment) => comment._id === commentId);
            // console.log(commentToSave,commentId,commentToSave.msgContent)
            dispatch(updatingCommentApi({ commentId, msgContent: commentToSave.msgContent }));
            dispatch(updateConversationList({ comments: comments }));
            setEditingCommentId(null); // Disable editing after saving the changes
            setShowOptions(null);
        } else {
            // If the comment is not being edited, enable editing
            setEditingCommentId(commentId);
        }
    };

    const handleTextareaBlur = (updatedText, commentId) => {
        if (editingCommentId === commentId) {
            // Save changes when the user clicks outside the textarea
            dispatch(updateCommentApi({ commentId, updatedText }));
            setEditingCommentId(null); // Disable editing after saving the changes
        }
    };

    const handleCommentChange = (value, commentId) => {
        const updatedMatrix = comments?.map((comment) =>
            comment._id === commentId
                ? { ...comment, msgContent: value } // Return the modified comment
                : comment
        );
        // setEditFromNestedOrParent("parent");
        dispatch(updateConversationList({ comments: updatedMatrix }));
    };


    const handleDeleteComment = () => {
        dispatch(deletingCommentApi({ commentId: deleteParentCommentId }));
        dispatch(updateNoOfComments(comments.length - 1));
        handleToggleDeleteComment();
    }

    const handleDeleteNestedComment = (nestedCommentId) => {
        dispatch(deletingNestedCommentApi({ id: nestedCommentId }));
    }
    const handleNestedInput = (value, nestedId, subComment) => {
        const updatedMatrix = comments.map((dcomment) => {
            console.log(dcomment._id , nestedId,dcomment._id === nestedId,"level1");
            if (dcomment._id === nestedId) {
                let copySingleComment = { ...dcomment };
                let nestedCommentCopy = Array.isArray(copySingleComment.nestedComments) ? [...copySingleComment.nestedComments] : [];
                console.log(nestedCommentCopy,"copy");
                const newArr = nestedCommentCopy.map((nc) => {
                    console.log(nc._id, subComment, nc._id === subComment,"level2");
                    if (nc._id === subComment) {
                        return { ...nc, nestedMsgContent: value };
                    } else {
                        return nc;
                    }
                })
                return { ...copySingleComment, nestedComments: newArr }
            } else {
                return dcomment;
            }
        });
        console.log(updatedMatrix);
        // setEditFromNestedOrParent("child");
        dispatch(updateConversationList({ comments: updatedMatrix }));
    }
    const handleToggleDeleteComment = () => {
        dispatch(toggleisDeleteNestedCommentOpen());
        handleCommentChange(prevMessage, isDeleteMsgModalOpen.id);
        setShowOptions(null);
        setEditingCommentId(null);
    }
    const handleNestedCommentOnEmptyValue = () => {
        const { previouMessage ,nestedId ,parentId } = nestedModalValueRef;
        console.log(previouMessage ,nestedId ,parentId)
        dispatch(toggleisDeleteNestedCommentOpen());
        setdisbaleNestedComment(null);
        handleNestedInput(previouMessage, nestedId, parentId);
    }
    const handleModalDeleteNestedComment = () => {
        handleDeleteNestedComment(nestedModalValueRef.parentId);
        dispatch(updateNestedParentHoldingId(nestedModalValueRef.nestedId));
        dispatch(toggleisDeleteNestedCommentOpen());
    };
    
    return (
        <div className="max-w-[300px] h-screen p-[24px]">
            {isDeleteNestedCommentOpen && editFromNestedOrParent=="parent" && <DeleteNestedCommentModal isOpen={isDeleteNestedCommentOpen} onCancel={handleToggleDeleteComment} onDelete={handleDeleteComment} />}
            {isDeleteNestedCommentOpen && editFromNestedOrParent=="child" && <DeleteNestedCommentModal isOpen={isDeleteNestedCommentOpen} onCancel={handleNestedCommentOnEmptyValue} onDelete={handleModalDeleteNestedComment} />}
            <div className='w-full chat input section'>
                <div className="profile_section flex items-center mb-[10px]">
                    <img src={!!avatar ? avatar : profile_green} alt="" className='w-[32px] h-[32px]' />
                    <p style={{ marginBottom: 0 }} className='pl-[12px]'>{username}</p>
                </div>
                <div className="chatTypingSection scrollbar-hide">
                    <TextareaAutosize
                        value={msg}
                        style={{ resize: 'none', outline: 'none' }}
                        onChange={(e) => setMsg(e.target.value)}
                        minRows={5}
                        placeholder={commentLocals.comments.form.placeholder}
                        className="text-[14px] scrollbar-hide w-full rounded-[8px] p-[8px] border-[#BECEFF] border"
                    />
                    <div className='flex justify-end'><Button onClick={handleNewMessage} style={{ paddingLeft: 0 }} className='text-primary font-InterRegular text-[13px] font-semibold tracking-wide' type='link'>{commentLocals.comments.form.submit_btn_text}</Button></div>
                </div>
                <div className="addCommentSection"></div>
            </div>
            <div className='w-full h-[77%] overflow-y-auto'>
                <ul className="">
                    {
                        Array.isArray(comments)
                        ? comments.map((comment, index) => {
                            return (
                                <li key={comment._id} className="mx-auto border-b-[#bdbdbd] border-b mb-[0px]">
                                    <div className='flex justify-between'>
                                        <div className="profile_section flex items-start mt-[0.5rem]">
                                            <img src={comment?.userId?.avatar.length ? comment?.userId?.avatar : profile_green} alt="" className='w-[32px] h-[32px]' />
                                            <div className='pl-[12px]'>
                                                <p style={{ marginBottom: 0 }} className=''>{comment?.userId?.username}</p>
                                                <p style={{ marginBottom: 0 }} className='text-[#9E9E9E] font-InterRegular text-[10px] font-semibold tracking-wide'>{timeStamptoHour(comment.updatedAt)}</p>
                                            </div>
                                        </div>
                                        <div className='relative'>
                                            <img src={moreIcon} onClick={() => {
                                                console.log(currentUser,comment?.userId?._id)
                                                if (currentUser === comment?.userId?._id) {
                                                    setShowOptions(index)
                                                }
                                            }} className='cursor-pointer' alt="" />
                                            {
                                                showOptions === index &&
                                                <div>
                                                    { /*<div className='triangle-up absolute right-1'></div>*/}
                                                    <ul ref={commentRef} onClick={() => setShowOptions(null)} className='w-[136px] -left-[7rem] shadow-xl border cursor-pointer top-[2.2rem] absolute h-[100px] pl-[20px] z-[100] pt-[12px] bg-white rounded-xl'>
                                                        <li onClick={() => {
                                                            handleEditIconClick(comment._id);
                                                            setPrevMessage(comment.msgContent);
                                                            dispatch(handleDeleteParentId(comment._id));
                                                        }} className='flex items-center text-[14px] font-InterRegular font-medium tracking-wide mt-2'><img className='pr-[12px]' src={EditComment} alt="" /> Edit</li>
                                                        <li onClick={() => {
                                                            // handleParentDeleteComment();
                                                            dispatch(handleDeleteParentId(comment._id));
                                                            handleToggleDeleteComment()
                                                            setEditFromNestedOrParent("parent");
                                                            console.log("test")
                                                            // handleToggleDeleteComment();
                                                            
                                                        }} className='flex items-center text-[14px] font-InterRegular font-medium tracking-wide mt-4'><img className='pr-[12px]' src={DeleteComment} alt="" /> Delete</li>
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="chatTypingSection scrollbar-hide">
                                        <div className='ml-[2.5rem] mb-[0.5rem]'>
                                            {
                                                editingCommentId === comment._id
                                                    ? <TextareaAutosize
                                                        value={comment.msgContent}
                                                        style={{ resize: 'none', outline: 'none', background: editingCommentId === comment._id ? '#ffffff' : 'white' }}
                                                        onChange={(e) => handleCommentChange(e.target.value, comment._id)}
                                                        onBlur={() => {
                                                            setEditFromNestedOrParent("parent");
                                                            if(comment.msgContent?.trim()?.length==0){
                                                                console.log("show modal parent comment",comment._id);
                                                                setIsDeleteMsgModalOpen((prev) => ({...prev, id: comment._id}));
                                                                handleToggleDeleteComment();
                                                            }else{
                                                                handleEditIconClick(comment._id);
                                                            }
                                                            // console.log(comment._id);
                                                        }}
                                                        minRows={1}
                                                        placeholder={commentLocals.comments.form.placeholder}
                                                        className="text-[12px] scrollbar-hide w-full rounded-[8px] p-[8px] border-[#BECEFF] border"
                                                    />
                                                    : <div><span className='text-[12px] break-words'>{comment.msgContent}</span></div>
                                            }
                                        </div>
                                        <ul className='NestedComments ml-5'>
                                            {
                                                comment.nestedComments?.map((subComment, subindex) => {
                                                    return <li className='i' key={subindex}>
                                                        <div className='flex justify-between'>
                                                            <div className="profile_section flex items-center">
                                                                <img src={subComment?.user_id?.avatar?.length ? subComment?.user_id?.avatar : profile_green} alt="" className='w-[32px] h-[32px]' />
                                                                <div className='pl-[12px]'>
                                                                    <p style={{ marginBottom: 0 }} className=''>{subComment?.user_id?.username}</p>
                                                                    <p style={{ marginBottom: 0 }} className='text-[#9E9E9E] font-InterRegular text-[10px] font-semibold tracking-wide'>{timeStamptoHour(subComment.updatedAt)}</p>
                                                                </div>
                                                            </div>
                                                            <div className='relative'>
                                                                <img src={moreIcon} onClick={() => {
                                                                    if (currentUser === comment?.userId?._id) {
                                                                        setShowNestedCRUDButton({ index, subindex })
                                                                    }
                                                                }} className='cursor-pointer' alt="" />
                                                                {
                                                                    showNestedCRUDButton.index === index && showNestedCRUDButton.subindex === subindex &&
                                                                    <div ref={nestedCommentRef}>
                                                                        {/*<div className='triangle-up absolute right-1'></div>*/}
                                                                        <ul onClick={() => setShowNestedCRUDButton({ index: null, subindex: null })} className='w-[136px] -left-[7rem] shadow-xl border cursor-pointer top-[2.2rem] absolute h-[100px] pl-[20px] z-[100] pt-[12px] bg-white rounded-xl'>
                                                                            <li onClick={() => {
                                                                                setNestedModalValueRef({previouMessage: subComment.nestedMsgContent,nestedId: comment._id,parentId: subComment._id});
                                                                                setdisbaleNestedComment(subComment._id);
                                                                                setPrevMessage(subComment.nestedMsgContent);
                                                                                setIsDeleteMsgModalOpen((prev) => ({...prev, id: subComment._id}));
                                                                            }} className='flex items-center text-[14px] font-InterRegular font-medium tracking-wide mt-2'><img className='pr-[12px]' src={EditComment} alt="" /> {commentLocals.comments.crud_button[0]}</li>
                                                                            <li onClick={() => {
                                                                                handleDeleteNestedComment(subComment._id);
                                                                                dispatch(updateNestedParentHoldingId(comment._id));
                                                                            }} className='flex items-center text-[14px] font-InterRegular font-medium tracking-wide mt-4'><img className='pr-[12px]' src={DeleteComment} alt="" /> {commentLocals.comments.crud_button[1]}</li>
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='ml-[2.5rem] mb-[0.5rem]'>
                                                            {
                                                                subComment._id === disbaleNestedComment
                                                                    ? <TextareaAutosize
                                                                        onBlur={() => {
                                                                            setEditFromNestedOrParent("child");
                                                                            if(subComment.nestedMsgContent.trim().length===0){
                                                                                //delete modal
                                                                                // setIsDeleteMsgModalOpen((prev) => ({...prev, id: subComment._id}))
                                                                                handleToggleDeleteComment();
                                                                                return;
                                                                            }
                                                                            setdisbaleNestedComment(null);
                                                                            comments.every((dcomment) => {
                                                                                if (dcomment._id === comment._id) {
                                                                                    let copySingleComment = { ...dcomment };
                                                                                    dcomment.nestedComments.every((nc, i) => {
                                                                                        if (nc._id === subComment._id) {
                                                                                            dispatch(updatingNestedCommentApi({ id: subComment._id, updatedData: nc.nestedMsgContent }));
                                                                                            return false;
                                                                                        } else {
                                                                                            return nc;
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    return dcomment;
                                                                                }
                                                                            });
                                                                        }}
                                                                        onChange={(e) => handleNestedInput(e.target.value, comment._id, subComment._id)}
                                                                        value={subComment.nestedMsgContent}
                                                                        style={{ resize: 'none', outline: 'none', background: editingCommentId === comment._id ? '#ffffff' : 'white' }}
                                                                        minRows={1}
                                                                        placeholder={commentLocals.comments.form.placeholder}
                                                                        className="text-[12px] scrollbar-hide w-full rounded-[8px] p-[8px] border-[#BECEFF] border"
                                                                    />
                                                                    : <div><span className='text-[12px] break-words'>{subComment.nestedMsgContent}</span></div>
                                                            }
                                                        </div>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        {
                                            nestedShowInputField === index && (
                                                <div className='ml-5 mb-5 relative'>
                                                    <TextareaAutosize
                                                        value={nestedActiveCommentText}
                                                        onBlur={() => setNestedShowInputField(index)}
                                                        onChange={(e) => setNestedActiveCommentText(e.target.value)}
                                                        style={{ resize: 'none', outline: 'none', background: editingCommentId === comment._id ? '#ffffff' : 'white' }}
                                                        minRows={1}
                                                        placeholder={commentLocals.comments.form.placeholder}
                                                        className="text-[14px] scrollbar-hide w-full rounded-[8px] p-[8px] border-[#BECEFF] border"
                                                    />
                                                    <Button onClick={() => {
                                                        if(nestedActiveCommentText.trim().length==0) return;
                                                        setNestedShowInputField(null);
                                                        dispatch(postingNestedCommentApi({ parentCommentId: comment._id, nestedMsgContent: nestedActiveCommentText }));
                                                        dispatch(updateNestedParentHoldingId(comment._id));
                                                        // dispatch(addNestedComment());//conversationDetails
                                                        const updatedComment = comments.map((singleComment, ci) => {
                                                            if (singleComment._id === comment._id) {
                                                                let copySingleComment = { ...singleComment };
                                                                let nestedCommentCopy = Array.isArray(copySingleComment.nestedComments) ? [...copySingleComment.nestedComments] : [];
                                                                nestedCommentCopy.push({ _id: null, nestedMsgContent: nestedActiveCommentText, updatedAt: null, user_id:{_id:null, username, avatar} });
                                                                return { ...copySingleComment, nestedComments: nestedCommentCopy };
                                                            } else {
                                                                return singleComment;
                                                            }
                                                        })
                                                        console.log(updatedComment, "****************");
                                                        dispatch(postUserActivityDataApi({ action: "addedComment", label: "[username] has replied to your comment in the Problem Solution Matrix." }));
                                                        dispatch(updateConversationList({ comments: updatedComment }));
                                                        // setComments(updatedComment);
                                                        setNestedActiveCommentText("");
                                                    }} style={{ paddingLeft: 0 }} className='text-primary font-InterRegular text-[13px] absolute -bottom-[1.5rem] right-0 font-semibold tracking-wide' type='link'>{commentLocals.comments.crud_button[3]}</Button>
                                                </div>
                                            )
                                        }
                                        {nestedShowInputField !== index && <Button onClick={() => {
                                            setNestedShowInputField(index);
                                        }} style={{ paddingLeft: 0 }} className='text-primary font-InterRegular text-[13px] mt-[0.5rem] font-semibold tracking-wide' type='link'>{commentLocals.comments.crud_button[3]}</Button>}
                                    </div>
                                </li>
                            )
                        })
                        : null
                    }
                </ul>
            </div>
        </div>
    );
};

export default memo(CommentList);
