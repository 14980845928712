import React from 'react'
import TextExtraLarge from '../../TypographyDescriptions/TextExtraLarge'
import NumericInput from '../../Forms/InputVarients/NumericInput'
import SecondaryButton from '../../Buttons/SecondaryButton'
import BaseModal from '../BaseModal'

const SingleInputModal = ({ mainTitle, label, borderColor, placeholder, handleOnClick, btnText, value, onChangeEvent, isOpen, handleOnCloseModal }) => {
  return (
    <React.Fragment>
      <BaseModal isOpen={isOpen} isShowCloseButton={true} handleCloseModal={handleOnCloseModal}>
        <div className='pb-[40px]'>
          <TextExtraLarge text={mainTitle} />
          <div className='mt-[18px]'>
            <label htmlFor="input">{label}</label>
            <div className='flex gap-x-[8px] mt-[0.5rem]'>
              <NumericInput value={value} onChangeEvent={onChangeEvent} borderColor={borderColor} placeholder={placeholder} />
              <SecondaryButton onClick={handleOnClick} text={btnText} />
            </div>
          </div>
        </div>
      </BaseModal>
    </React.Fragment>
  )
}

export default SingleInputModal