import { PDFDownloadLink, Document, Page, Text, View } from '@react-pdf/renderer';
import { pdfColumnDataPreparation } from '../../../utils/pdfColumnDataPreparation';
import { styles } from './css/CustomColumnPDFStyle';
import { formatData } from "../../../utils/FormatJSONData";

const CustomColumnPDF = ({ jsondata, competitorNameKeyValue, name, CompanyName }) => {


    const formattedData = formatData(jsondata,competitorNameKeyValue,name,CompanyName);

    const dataToPrint = pdfColumnDataPreparation(formattedData);

    return (
        <>
            <Document>
                {
                    dataToPrint.map((pageData, mainIndex) => {
                        const COLUMN_SIZE = pageData[0].columnHeading.length;
                        return (
                            <Page size="A4" style={[styles.page, { marginTop: 20 }]} key={mainIndex}>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        {
                                            pageData[0].columnHeading.map((heading, rowIndex) => {
                                                return (
                                                    <View key={rowIndex} style={[styles.tableCol, { width: `${Math.floor(100 / COLUMN_SIZE)}%` }]}>
                                                        <Text style={styles.tableCell}>{heading}</Text>
                                                    </View>
                                                )
                                            })
                                        }
                                    </View>
                                    {
                                        pageData[0].data.map((sensitiveData, sensitiveIndex) => {
                                            return (
                                                <View style={styles.tableRow} key={sensitiveIndex}>
                                                    {
                                                        sensitiveData.map((rowData, index) => {
                                                            return (
                                                                <View key={index} style={[styles.tableCol, { width: `${Math.floor(100 / COLUMN_SIZE)}%` }]}>
                                                                    <Text style={styles.tableCell}>{rowData}</Text>
                                                                </View>
                                                            )
                                                        })
                                                    }
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                            </Page>
                        )
                    })
                }
            </Document>
        </>
    )
}

export default CustomColumnPDF