export const getRevenueScreenDataReferenceTitle = {
    '0': 'revenueScreenOne',
    '1': 'revenueScreenTwo',
    '2': 'revenueScreenThree',
    '3': 'revenueScreenFour',
    '4': 'revenueScreenFive',
    '5': 'revenueScreenSix',
}

export const getCostScreenDataReferenceTitle = {
    '0': 'costScreenOne',
    '1': 'costScreenTwo',
    '2': 'costScreenThree',
    '3': 'costScreenFour',
    '4': 'costScreenFive',
    '5': 'costScreenSix'
}