import React, { useState } from 'react'
import { Templates } from '../data';
import PitchDeckCard from '../../../DesignSystem/Cards/pitchDeck/PitchDeckCard';

const TemplateCards = () => {
  const [toggleFavorite, settoggleFavorite] = useState(null);

  return (
    <React.Fragment>
      <div className='mt-[24px] grid md:grid-cols-2 lg:grid-cols-3 gap-6'>
        {
          Templates.Basic.data.map((template, index) => {
            return <PitchDeckCard
              isFavorite={toggleFavorite===index} 
              handleFavoriteTemplate={() => settoggleFavorite(index)}
              cardBgImage={template.backgroundImage}
              cardTitle={template.title}
              toolTipMessage={template.tooltip}
            />
          })
        }
      </div>
    </React.Fragment>
  )
}

export default TemplateCards