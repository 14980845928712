import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import store from "./store.js";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";
import "./index.css";
import "antd/dist/reset.css";
import AppErrorBoundary from "./components/reuseablecomponent/ErrorBoundary/AppErrorBoundary.jsx";

export let persistor = persistStore(store);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              headerBg: "#4B54FF"
            }   
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
