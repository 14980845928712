import React from 'react';
import { Input } from "antd";
const { TextArea } = Input;

const AutoSizeTextArea = ({
    placeHolder,
    value,
    name,
    maxLength,
    isRequired,
    isDisabled,
    isReadyOnly,
    onChangeEvent,
    onBlurEvent,
    minRows,
    maxRows,
    borderColor,
    isPaddingNone = false,
    styles
}) => {
    return (
        <TextArea
            autoSize={!maxRows ? { minRows } : { minRows, maxRows }}
            style={{
                borderRadius: '6px',
                border: `1px solid ${borderColor ? borderColor : '#E0E0E0'}`,
                padding: isPaddingNone ? 0 : '8px',
                ...styles,
                // Ensure placeholder text is visible by adjusting padding and color
                paddingLeft: isPaddingNone ? '4px' : '8px',
                paddingTop: isPaddingNone ? '4px' : '8px',
                paddingBottom: isPaddingNone ? '4px' : '8px',
                fontSize: '14px',
            }}
            name={name}
            value={value}
            placeholder={placeHolder}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadyOnly}
            onChange={onChangeEvent}
            onBlur={onBlurEvent}
            maxLength={maxLength}
        />
    );
};

export default AutoSizeTextArea;

