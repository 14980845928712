import React, { useState, useEffect } from 'react'
import ConfettiComponent from '../components/Confetti'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import logo from '../assets/Header/logotest.svg';
import welcomeScreenImg from "../assets/Onboarding/welcomeScreenImg.png"
import Fundraising from '../assets/Home/Fundraising enablement.svg'
import '../index.css';
import TNC from "../assets/docs/Terms_and_Conditions.pdf";
import PP from "../assets/docs/Privacy_Policy.pdf";
import footerLocals from "../localization/localization_en_footer.json";
import welcomeLocals from "../localization/localization_en_welcome.json";
import useInviteTeamMember from '../hooks/useInviteTeamMember';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoModal from '../components/reuseablecomponent/PopUpVarients/VideoModal';
import { generateURLS3Asset } from '../utils/generateURLS3Asset';
import { API_REFRESH_TOKEN } from '../features/appSlice';

const WelcomeScreen = () => {
    const dispatch = useDispatch();
    const [showConfetti, setShowConfetti] = useState(true);
    const username = useSelector((state) => state.user.value.username);
    const navigate = useNavigate();
    useInviteTeamMember();
    useEffect(() => {
        dispatch(API_REFRESH_TOKEN());
        const timer = setTimeout(() => {
            setShowConfetti((prev) => !prev);
        }, 3000)
        return () => timer;
    }, [])
    const welcome_video = generateURLS3Asset({ assetType: "video", sub_path: "Welcome_Demo_Video" })
    return (
        <div>
            {showConfetti && <ConfettiComponent />}
            <div className='h-[100vh] flex flex-col justify-between' >
                <header onClick={() => navigate('/')} className='w-full py-[19px] md:px-[64px] flex items-center justify-between'>
                    <img src={logo} className='w-[138px] h-[21px]' alt="" />
                    {/* <Button type='link' className='text-primary bg-[#F5F5F5] text-[14px] font-semibold font-InterRegular py-[12px] px-[28px] h-auto rounded-[18px]'>{welcomeLocals.help_btn_text}</Button> */}
                </header>
                <div className='w-full max-w-[1000px] mx-auto flex flex-col justify-center flex-1'>
                    <p className='text-center text-[#111827] font-DMSans text-[24px] font-bold '>{welcomeLocals.main_greet_text} {username}!</p>
                    <p className='text mx-auto text-center font-InterRegular text-[16px] font-medium tracking-wide text-[#9E9E9E]'>
                        {welcomeLocals.description}
                    </p>
                    <div className='flex justify-center mx-auto w-[100%] relative rounded-[20px] overflow-hidden'>
                        <div className='w-full h-full'>
                            <img src={welcomeScreenImg} className='p-[2rem] blur-[2px] object-contain rounded-[3rem] bg-[#EBEAF4]' alt="" />
                        </div>
                        <VideoModal
                            video={welcome_video}
                        >
                            {
                                ({ handleToggleModal }) => (
                                    <button
                                        onClick={() => handleToggleModal()}
                                        className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'
                                    >
                                        <PlayCircleOutlineIcon style={{ fontSize: '4rem' }} className='text-primary' />
                                    </button>
                                )
                            }
                        </VideoModal>
                    </div>
                    <div className='flex justify-center mt-[23px]'>
                        <Button onClick={() => navigate('/product/home')} className='btn-onboarding-grad text-white rounded-[12px] px-[38px] py-[12px] h-auto'>{welcomeLocals.get_started_button}</Button>
                    </div>
                </div>
                <div className="footer sticky top-[100%]">
                    <div className='flex justify-between items-center text-[#9E9E9E] text-[12px] pb-[32px] px-[64px]'>
                        <div className='font-medium tracking-wide font-InterRegular'>{footerLocals.footer.copyright_text}</div>
                        <div className='font-medium tracking-wide font-InterRegular'>
                            <button onClick={() => navigate('/refund-policy')} className='mr-[24px]'>{footerLocals.footer.refund_policy}</button>
                            <button onClick={() => navigate('/terms-and-conditions')} className='mr-[24px]'>{footerLocals.footer.terms_and_condition_text}</button>
                            <button onClick={() => navigate('/privacy-policy')}>{footerLocals.footer.privacy_policy_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeScreen