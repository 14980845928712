import React from 'react'
import weblocals from "../../localization/localization_en_website.json"
import { cn } from '../../utils/cn';

const SearchNotFound = ({ searchText, fontWeight=400, fontSize=12, className }) => {
    const { text1, text2 } = weblocals.website.search_not_found;
    return (
        <div className={cn('flex flex-col justify-center items-center', className)}>
            <div className='flex gap-x-[0.4rem]'>
                <span style={{ fontWeight , fontSize: `${fontSize}px`}} className='font-DMSans text-[#757575]'>{text1}</span>
                <span style={{ fontWeight , fontSize: `${fontSize}px`}} className='font-DMSans'>{searchText}</span>
            </div>
            <span style={{ fontWeight , fontSize: `${fontSize}px`}} className='font-DMSans text-center text-[#757575]'>{text2}</span>
        </div>
    )
}

export default SearchNotFound