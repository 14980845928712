import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton'
import { updateItemSelection, updateMenuItemSelection } from "../../features/manageLinkSlice"

const RedirectModuleToCardView = ({ moduleName }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const stackLastSingletVisitedRoute = useSelector((state) => state.app.stackLastSingletVisitedRoute);

    const handleOnClick = () => {

        if(stackLastSingletVisitedRoute === "/product/home/business-planning/my-dashboard"){
            return navigate(stackLastSingletVisitedRoute);
        }

        if(['problemSolutionMatrix', 'businessIdeationBingo'].includes(moduleName)){
            dispatch(updateItemSelection({ "activeItem": "1.1" }))
        }
        if(['targetMarketAnakysis', 'competitorAnalysis', 'idealCustomerProfile'].includes(moduleName)){
            dispatch(updateItemSelection({ "activeItem": "1.2" }))
        }
        if(['buildBusinessCanvas', 'leanstartupcanvas'].includes(moduleName)){
            dispatch(updateItemSelection({ "activeItem": "1.3" }))
            // dispatch(updateMenuItemSelection({ "openKeys": ["0", "1"], "selectedKeys": "1" }))
        }
        if(['GTMPlan', 'marketingFunnel'].includes(moduleName)){
            dispatch(updateItemSelection({ "activeItem": "1.4" }))
        }
        navigate('/product/home');
    }

    return (
        <PrimaryButton text={"Back"} onClick={handleOnClick} size='small' />
    )
}

export default RedirectModuleToCardView