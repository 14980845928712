import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setBearerToken } from '../services/api/api';
import api from '../services/api/api'
import { updateAccessToken } from './userSlice';
import { bingoApiEndpoint, fetchBingoApiEndpoint, patchBingoApiEndpoint, resetBingoApiEndpoint } from '../services/endPoints/businessIdeationBingoAPI';
import routesConfig from "../services/config/routesConfig.json"
import {generateUrlEndPoint} from "../utils/endPointsGeneration";

export const bingoApi = createAsyncThunk(
    'bingo/bingoApi',
    async (bingoData, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessIdeationBingo', 'post');
            if (!!urlGenerator) {
                const urlConfig = { data: bingoData, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const fetchbingoApi = createAsyncThunk(
    'bingo/fetchbingoApi',
    async (_, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessIdeationBingo', 'get');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const patchbingoApi = createAsyncThunk(
    'bingo/patchbingoApi',
    async (data, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessIdeationBingo', 'patch');
            if (!!urlGenerator) {
                const urlConfig = { data: data, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const resetbingoApi = createAsyncThunk(
    'bingo/resetbingoApi',
    async (data, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessIdeationBingo', 'reset');
            if (!!urlGenerator) {
                const urlConfig = { data: data, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

const bingoSlice = createSlice({
    name: 'bingo',
    initialState: {
        score: 0,
        selectedQuestion: [],
        status: 'idle',
        error: null
    },
    reducers: {
        updateScoreInfo: (state, action) => {
            state.score = action.payload;
        },
        resetStatusError: (state, action) => {
            state.status = 'idle';
            state.error = null
        },
        postToggleAnswer(state, action) {
            const ansNumber = action.payload;
            let selectedOptions = state.selectedQuestion
            if (selectedOptions?.includes(ansNumber)) {
                const filteredAns = selectedOptions.filter((ans, _) => ans != ansNumber)
                state.selectedQuestion = filteredAns;
            } else {
                selectedOptions.push(ansNumber);
                state.selectedQuestion = selectedOptions;
            }
        },
        resetData(state) {
            state.selectedQuestion = [];
            state.score = 0;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(bingoApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(bingoApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(bingoApi.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchbingoApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchbingoApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedQuestion = action.payload.bingoDoc;
            })
            .addCase(fetchbingoApi.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
})


export const { resetData, updateScoreInfo, resetStatusError, postToggleAnswer } = bingoSlice.actions
export default bingoSlice.reducer