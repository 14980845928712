import React, { useEffect, useMemo, useState } from "react";
import { Button, Tabs } from "antd";
import CompetitorTable from "./competitorTable";
import CompetitorOther from "./CompetitorOther";
import { useDispatch, useSelector } from "react-redux";
import { updatekeyChange } from "./CompetitorSlice";
import CompetitorText from "./CompetitorText";
import "./competitorAnalysis.css";
import caLocals from "../../localization/localization_en_competitor_analysis.json"
import RedirectModuleToCardView from "../reuseablecomponent/RedirectModuleToCardView";
import { useLocation, useNavigate } from "react-router-dom";
import WarningLimitedResourceOopsPopup from "../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup";
import { paywall_locals } from "../../localization";
import useApp from "../../hooks/useApp";

const PAYWALL_LOCALS = paywall_locals.competitor_analysis;

const TabsComponent = () => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.competitor.key);
  const productstatus = useSelector((state) => state.competitor.productstatus);
  const status = useSelector((state) => state.competitor.status);
  const calocals = caLocals.competitor_analysis;
  const { upgradeRenewPaywallPopup } = useApp();

  const handleKeyChange = (key) => {
    dispatch(updatekeyChange({ key: key }));
  };
  const items = [
    {
      key: "1",
      label: calocals.tab_header.tab1,
      children: <CompetitorTable />,
    },
    {
      key: "2",
      label: calocals.tab_header.tab2,
      children: <CompetitorText />,
    },
    {
      key: "3",
      label: calocals.tab_header.tab3,
      children: <CompetitorText />,
    },
    {
      key: "4",
      label: calocals.tab_header.tab4,
      children: <CompetitorText />,
    },
  ];
  return (
    <div>
      <WarningLimitedResourceOopsPopup
        description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].restrict_other_tab.description1}
        description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].restrict_other_tab.description2}
      >
        {({ handleTogglePopup, isFreeSubscription }) => {
          return (
            <Tabs
              className="primary"
              activeKey={key}
              defaultActiveKey="1"
              items={items}
              onChange={(val) => {
                if (isFreeSubscription && ['2', '3', '4'].includes(val)) {
                  handleTogglePopup();
                  return;
                }
                handleKeyChange(val)
              }}
              centered={true}
              destroyInactiveTabPane={true}
            />
          )
        }}
      </WarningLimitedResourceOopsPopup>

      {
        (status !== "loading" && productstatus !== "loading") && (
          <div className="flex justify-between mt-[1rem]">
            {
              key === "1"
                ? <RedirectModuleToCardView moduleName={'competitorAnalysis'} />
                : null
            }
            {/* <Button onClick={() => {
              if (key !== '4') {
                dispatch(updatekeyChange({ key: String(Number(key) + 1) }));
              }
            }} className={`${key === '4' && 'hidden'} btn-onboarding-grad text-white px-[35px] rounded-[8px] font-InterRegular font-semibold text-[16px] py-[9px] h-auto`}>
              {calocals.tab_btn.next}
            </Button> */}
            <div></div>
          </div>
        )
      }
    </div>
  );
};
export default TabsComponent;
