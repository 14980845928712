import React from 'react';
import { shapes } from '../../assets/website';
import { Carousel } from 'antd';
import PGButtonComponent from '../Buttons/PGButtonComponent';
import classNames from 'classnames';

const { left_long_arrow, right_long_arrow } = shapes;

const AntDCarousal = ({ image, isAutoplay = false }) => {

    const carousalRef = React.useRef(null);
    const [activeIndex, setActiveIndex] = React.useState(0);

    const handleBeforeChange = (from, to) => {
        setActiveIndex(to);
    };

    return (
        <div className='flex flex-col gap-y-[30px]'>
            <div className='border-[10px] rounded-[1.5rem] overflow-hidden border-p6'>
                <Carousel dots={false} autoplay={isAutoplay} ref={carousalRef} beforeChange={handleBeforeChange}>
                    {
                        image.map((img, index) => {
                            return (
                                <div key={index}>
                                    <img src={img} className='h-full w-[100%] mx-auto' alt="" />
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
            <div className='flex items-center justify-center gap-x-[16px] h-[24px]'>
                {/* left arrow */}
                <PGButtonComponent
                    onClick={() => carousalRef.current.prev()}
                    className={'border-none bg-transparent p-0'}
                >
                    <img src={left_long_arrow} alt="" />
                </PGButtonComponent>
                {/* list of dots */}
                <div className='flex items-center gap-x-[8px] justify-center h-[24px]'>
                    {
                        image.map((_, index) => {
                            return (
                                <div key={index} className={classNames('bg-baseGray400 w-[8px] aspect-square rounded-full', { 'bg-primary': index === activeIndex })}></div>
                            )
                        })
                    }
                </div>
                {/* right arrow */}
                <PGButtonComponent
                    onClick={() => carousalRef.current.next()}
                    className={'border-none bg-transparent p-0'}
                >
                    <img src={right_long_arrow} alt="" />
                </PGButtonComponent>
            </div>
        </div>
    )
};
export default AntDCarousal;