import React from 'react'
import HeadingFour from '../TypographyHeadings/HeadingFour'
import TextMedium from '../TypographyDescriptions/TextMedium'

const FeatureDescribe = ({ heading, description, headingColor = "#111827", descriptionColor = "#9E9E9E" }) => {
  return (
    <React.Fragment>
      <div>
        <HeadingFour text={heading} color={headingColor} />
        <TextMedium text={description} color={descriptionColor} fontWeight={500} />
      </div>
    </React.Fragment>
  )
}

export default FeatureDescribe