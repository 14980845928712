import React from 'react'
import pg_watermark from "../../assets/common/pg_watermark.svg"
import { privacy_policy } from '../data/privacyPolicy'

const PrivacyPolicy = () => {
    return (
        <div>
            <img className='w-[388px] aspect-square absolute top-[10rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <img className='w-[388px] aspect-square absolute top-[100rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <img className='w-[388px] aspect-square absolute top-[200rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <img className='w-[388px] aspect-square absolute top-[300rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <div 
                className='tc_pcpy py-[40px] relative mx-[18px] flex flex-col gap-y-[2rem]' 
                dangerouslySetInnerHTML={{ __html: privacy_policy.content }}
            />
        </div>
    )
}

export default PrivacyPolicy