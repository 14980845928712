import React from 'react'
import TextComponent from '../../../reuseablecomponent/TextVarients/TextComponent'
import ButtonComponent from '../../../reuseablecomponent/ButtonVarients/ButtonComponent'
import Location from './Location'
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import locals from "../../../../localization/localization_en_stepper.json"

const stepperLocals  = locals.stepper;

const FeatureCard = ({ index, cardTitle, cardBgColor, cardBorder, componentUrl, btnTextColor, featureRefIndex, buttonText }) => {
  const activeItem = useSelector((state) => state.link.activeItem);
  const navigate = useNavigate();
  const isActive = featureRefIndex===activeItem;
  return (
    <React.Fragment>
      <div className='flex relative' key={index}>
        <div className='flex justify-start items-center'>
          <div style={{ backgroundColor: isActive ? "#372ee5" : cardBgColor, border:`1px solid ${cardBorder}` }} className={`p-[16px] flex min-w-[355px] justify-between items-center rounded-[8px]`}>
            <TextComponent color={isActive ? "#FFF" : "#4b5462"} fontSize={14} fontWeight={600} text={cardTitle} />
            <ButtonComponent onClick={() => navigate(componentUrl)} bgColor={"#FFF"} text={isActive ? stepperLocals.module_status[1] : buttonText ? buttonText : stepperLocals.module_status[0]} textColor={"#372ee5"} type={"primary"} />
          </div>
        </div>
        { isActive && <Location topPosition={`1rem`} />}
      </div>
    </React.Fragment>
  )
}

export default FeatureCard