import React from 'react'
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge'

const DescribeFeature = ({text, description}) => {
  return (
    <div>
        <h2 className='text-[#111827] font-DMSans font-bold text-[20px]'>{text}</h2>
        <TextLarge color={"#9E9E9E"} text={description} fontWeight={300} />
    </div>
  )
}

export default DescribeFeature