import React from 'react'
import close from "../../../assets/common/close.svg"
import { useSelector } from "react-redux"
import { Modal } from "antd";

const ShowExampleModal = ({onClick, isOpen}) => {
  const pdfRefmodules = useSelector((state) => state.prompt.pdfRefmodules)
  return (
    <React.Fragment>
        <Modal open={isOpen} width={1000} zIndex={10000}>
            <button onClick={onClick} className='absolute top-[0.5rem] right-[0.5rem]'><img src={close} alt="" /></button>
            <img src={pdfRefmodules} alt="" />
        </Modal>
    </React.Fragment>
  )
}

export default ShowExampleModal