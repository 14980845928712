import React from 'react';
import { Steps } from 'antd';
import { CheckCircleOutlined } from "@ant-design/icons";
import "../ds.style.css"
const { Step } = Steps;

const CompletedComponent = () => {
    return (
        <CheckCircleOutlined style={{ color: "#fff", backgroundColor: "#4ADE80", borderRadius: "100%", fontSize: "32px" }} />
    )
}

const Stepper = ({ labelVisibility = "none", labelPlacement, currentStage, onboardingItems=[] }) => {
    return (
        <Steps current={currentStage} labelPlacement={labelPlacement}>
            {onboardingItems.map((item, index) => {
                const properties = labelVisibility === "none" ? {} : { title: item.label };
                return (
                    <Step
                        key={index}
                        {...properties}
                        icon={
                            currentStage > index ? <CompletedComponent /> : undefined
                        }
                    />
                )
            })}
        </Steps>
    )
};
export default Stepper;