import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'
import { cn } from '../../utils/cn'

const TextExtraLarge = ({ fontWeight = 700, text, color = "#111827", margin, padding, display="inline", className, isStyleColor=true }) => {
  let styles = {
    lineHeight: "140%",
    fontWeight,
    ...generatePadding(padding?.direction, padding?.number),
    ...generateMargin(margin?.direction, margin?.number),
    display:display
  }
  if(isStyleColor){
    styles = {...styles, color}
  }
  return (
    <React.Fragment>
      <span style={styles} className={cn('text-[18px] font-bold font-InterRegular', className)}>{text}</span>
    </React.Fragment>
  )
}

export default TextExtraLarge