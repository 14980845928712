import React, { useState } from 'react'
import { Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';

const BaseModal = ({ children, isOpen, isShowCloseButton = false, handleCloseModal, modalClassname }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const togglePopup = () => setIsModalOpen((prev) => !prev);
    return (
        <React.Fragment>
            <Modal centered open={isOpen || isModalOpen} className={modalClassname}>
                {
                    typeof children === "function" ? children({ togglePopup }) : children
                }
                {isShowCloseButton && <button onClick={() => handleCloseModal ? handleCloseModal() : setIsModalOpen((prev) => !prev)} className='absolute top-[0.5rem] right-[0.5rem]'>
                    <CloseIcon/>
                </button>}
            </Modal>
        </React.Fragment>
    )
}

export default BaseModal