import { useMemo } from "react";
import { useSelector } from "react-redux";
import menu_locals from "../localization/localization_en_menu.json"

const useLockedFeature = () => {

    const { checkPaymentStatus } = useSelector((state) => state.app);

    const MENU_ITEMS = useMemo(() => {
       return Object.values(menu_locals.menu).filter((item) => ![menu_locals.menu[0], menu_locals.menu["menu"], menu_locals.menu["location"]].includes(item)) || []
    }, [])

    const DEFAULT_LOCKED_MODULES = (import.meta.env.VITE_REACT_LOCKED_MENU_ITEM?.split(",")?.map((feat) => feat?.trim())) || [];

    return { 
        "LOCKED_MODULES": checkPaymentStatus ? DEFAULT_LOCKED_MODULES : MENU_ITEMS 
    }

}

export default useLockedFeature