import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import ButtonComponent from '../ButtonVarients/ButtonComponent';
import TextComponent from '../TextVarients/TextComponent';
import { useDispatch, useSelector } from "react-redux";
import { toggleCancelSubcriptionModelOpen } from "../../userSetting/settingSlice";
import close from "../../../assets/common/close.svg"
import { Input, Radio, Space } from 'antd';
import modalLocals from "../../../localization/localization_en_modals.json"
import TextInput from '../../../DesignSystem/Forms/InputVarients/TextInput';

const DeleteSubscriptionModal = ({ isCancelSubscriptionModalOpen, toggleCancelSubcriptionModelOpen, handleCancelSubscriptionApi }) => {
    const cancelSubcriptionModelOpen = useSelector((state) => state.setting.cancelSubcriptionModelOpen)
    const [value, setValue] = useState(null);
    const [customText, setCustomtext] = useState(null);
    const cancel_subscription =useSelector((state) => state.app.cancel_subscription)
    const onChange = (e) => {
        setValue(e.target.value);
    };
    useEffect(() => {
        setCustomtext(null);
    }, [value])
    const dispatch = useDispatch();
    const IS_API_LAODING = cancel_subscription?.api_status === "loading"
    // const handleRemoveSubscription = () => {
    //     dispatch(toggleCancelSubcriptionModelOpen());
    // }
    return (
        <React.Fragment>
            <Modal
                centered
                width={800}
                bodyStyle={{ minHeight: '200px' }}
                open={isCancelSubscriptionModalOpen}
                okButtonProps={{
                    disabled: true,
                }}
                cancelButtonProps={{
                    disabled: true,
                }}
            >
                {true && <Button onClick={toggleCancelSubcriptionModelOpen} className='h-auto absolute top-[18px] right-[18px] border-none'><img src={close} className='' /></Button>}
                <div className='py-[16px]'>
                    <TextComponent fontSize={18} fontWeight={600} text={modalLocals.delete_subscription.title} />
                </div>
                <div className='mb-[24px]'>
                    <TextComponent fontSize={14} fontWeight={500} text={modalLocals.delete_subscription.text1} />
                </div>
                <TextComponent fontSize={14} fontWeight={500} text={modalLocals.delete_subscription.suggest_msg} />
                <div className='mt-[24px]'>
                    <TextComponent fontSize={14} fontWeight={500} text={modalLocals.delete_subscription.alert_msg} />
                </div>
                <div className='mt-[24px]'>
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                            {
                                modalLocals.delete_subscription.reason.map((reason, index) => {
                                    return <Radio disabled={cancel_subscription.api_status === "loading"} value={reason.value}>{reason.label}</Radio>
                                })
                            }
                        </Space>
                    </Radio.Group>
                </div>
                {
                    value === "other" && (
                        <div className='max-w-[310px] pl-[4.5rem]'>
                            <TextInput
                                className="border border-red-500"
                                borderColor="#372EE5"
                                placeholder={"Enter your reason"}
                                value={customText}
                                onChangeEvent={(e) => setCustomtext(e.target.value)}
                                maxCharLength={255}
                                isDisabled={cancel_subscription.api_status === "loading"}
                            />
                        </div>
                    )
                }
                <div className='flex justify-between mt-[41px]'>
                    <ButtonComponent isDisabled={IS_API_LAODING} onClick={toggleCancelSubcriptionModelOpen} type={"link"} isBorder={true} borderColor={"#757575"} isLoading={false} text={modalLocals.delete_subscription.button_text.cancel} textColor={"#757575"} />
                    <ButtonComponent isDisabled={IS_API_LAODING} isLoading={IS_API_LAODING} onClick={() => handleCancelSubscriptionApi(value==="other" ? customText : value)} type={"primary"} bgColor={"#F75555"} text={modalLocals.delete_subscription.button_text.confirm} textColor={"#fff"} />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default DeleteSubscriptionModal