import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Space } from "antd";
import AdminDataRoom from "../../localization/data_room/localization_en_admin_data_room.json";
import MoveArrow from "../SVG/MoveArrowSVG";
import AcessPanel from "../SVG/AcessPanelSVG";
import AddTask from "../SVG/AddTaskSVG";
import Info from "../SVG/InfoSVG";
import Create from "../SVG/CreateSVG.jsx";
import { useDispatch } from "react-redux";
import { copyFileApi, updateArchiveStatusApi, updateLockStatusApi } from "../../features/dataRoomSlice.jsx";
import DeleteOutlineSVG from "../SVG/DeleteOutlineSVG.jsx";
import DownloadOutlineSVG from "../SVG/DownloadOutlineSVG.jsx";
import ColorCodeSVG from "../SVG/ColorCodeSVG.jsx";
import DiscriptionSVG from "../SVG/DiscriptionSVG.jsx";
import LockOutlined from "../SVG/LockOutlineSVG.jsx";
import ArchiveOutline from "../SVG/ArchiveOutlineSVG.jsx";
import LinkOutline from "../SVG/LinkOutlineSVG.jsx";
import CopyOutline from "../SVG/CopyOutlineSVG.jsx";
import grayDots from "../../assets/common/grayDots.svg";
import ModalSection from "./ActionMenuOptionModal/ModalSection.jsx";
import ActionMenuInvestorCard from "./ActionMenuInvestorCard.jsx";

const ActionMenuInvestorFilesCard = ({document, handleClose}) => {
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentList, setDocumentList] = useState(null)
  console.log(document)

  const handleMenuClick = (e) => {
    console.log("click", e);
    setModalTitle(AdminDataRoom.admin_action_menu.actionMenu[e.key]);
    setIsModalVisible(true);
    setDocumentList(document)
  };

  console.log(document)

  const closeModalHandler = () => {
    setIsModalVisible(false);
    setModalTitle(null);
    handleClose();
  };

  const handleArchiveClick = async () => {
    const body = {
      documentId: document?._id,
      archiveStatus: true
    }

    dispatch(updateArchiveStatusApi(body));
  }

  const handleUnarchiveClick = async () => {
    const body = {
      documentId: document?._id,
      archiveStatus: false
    }

    dispatch(updateArchiveStatusApi(body))
  }

  const handleLockClick = async () => {
    console.log("Lock Clicked")
    const body = {
      documentId: document._id,
      lockStatus: true
    }

    await dispatch(updateLockStatusApi(body))
  }

  const handleUnLockClick = async () => {
    const body = {
      documentId: document?._id,
      lockStatus: false
    }

    await dispatch(updateLockStatusApi(body))
  }

  const handleMakeCopy = async () => {
    const body = {
      documentId: document._id
    }

    await dispatch(copyFileApi(body));
  }

  const menuItems = [
    {
      label: (
        <Space className="py-2">
          <Info />
          {AdminDataRoom.admin_action_menu.actionMenu[4]}
        </Space>
      ),
      key: "4",
    },
    {
      label: (
        <Space className="py-2">
          <DownloadOutlineSVG />
          {AdminDataRoom.admin_action_menu.actionMenu[10]}
        </Space>
      ),
      key: "10",
    },
  ];

  const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

  return (
    <Space wrap>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Space className="cursor-pointer opacity-75 hover:opacity-100">
          <img src={grayDots} onClick={handleMenuClick} />
        </Space>
      </Dropdown>
      {isModalVisible && (
        <ModalSection
          title={modalTitle}
          closeHandler={closeModalHandler}
          documentList={documentList}
        />
      )}
    </Space>
  );
};

export default ActionMenuInvestorFilesCard;
