import PrimaryButton from "../../Buttons/PrimaryButton";
import PrimaryDangerButton from "../../Buttons/PrimaryDangerButton";
import closeSvg from "../../../assets/common/close.svg";
import SecondaryButton from "../../Buttons/SecondaryButton";

const colors = [
	{
		color: "#E0C0FF",
		borderColor: "#9121FE",
	},
	{
		color: "#A7C0FF",
		borderColor: "#372EE5",
	},
	{
		color: "#FFCACA",
		borderColor: "#FF0000",
	},
	{
		color: "#E4FFEE",
		borderColor: "#00FF5D",
	},
	{
		color: "#FFEEAB",
		borderColor: "#FACC15",
	},
];

const ColorPallet = ({ closedModalHandler }) => {
	return (
		<div
			onClick={(e) => e.stopPropagation()}
			className=" absolute -right-56  rounded-lg flex gap-4 p-4 items-center z-50 justify-center bg-white shadow">
			{colors.map((item) => {
				console.log(item);
				return (
					<div
						style={{
							borderColor: item.borderColor,
							backgroundColor: item.color,
						}}
						className={` border w-[24px] h-[24px] rounded-full`}
					/>
				);
			})}
		</div>
	);
};

export default ColorPallet;
