import './location-animation-style.css'

const LocationPointerAnimation = ({ color }) => (
    <svg
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.001 0.912598C6.73078 0.912598 4.05078 3.5846 4.05078 6.8458C4.05078 8.1094 4.45438 9.2838 5.13738 10.249L9.27478 17.4016C9.85418 18.1586 10.2394 18.0148 10.7212 17.3616L15.2848 9.5956C15.3768 9.4288 15.4492 9.2512 15.5122 9.0698C15.8018 8.36409 15.9507 7.60861 15.9506 6.8458C15.9508 3.5846 13.2716 0.912598 10.001 0.912598ZM10.001 3.6926C11.7622 3.6926 13.1626 5.0898 13.1626 6.8458C13.1626 8.6018 11.7622 9.9984 10.001 9.9984C8.23998 9.9984 6.83898 8.602 6.83898 6.8458C6.83898 5.0898 8.23998 3.6928 10.001 3.6928V3.6926Z"
            fill={color}
            className="animateUpAndDown"
        />
        <path
            d="M13.7826 10.6941L13.773 10.7193C13.776 10.7117 13.7784 10.7039 13.7814 10.6963L13.7826 10.6941ZM6.8012 14.7239C3.976 15.1231 2 16.0781 2 17.4839C2 19.3775 5.3016 20.9125 10 20.9125C14.6984 20.9125 18 19.3775 18 17.4839C18 16.0781 16.0242 15.1231 13.1992 14.7239L12.8078 15.3903C14.937 15.6857 16.4 16.3065 16.4 17.0269C16.4 18.0369 13.5346 18.8555 10 18.8555C6.4654 18.8555 3.6 18.0369 3.6 17.0269C3.5998 16.3089 5.0532 15.6887 7.189 15.3921C7.06 15.1693 6.9304 14.9469 6.8012 14.7239Z"
            fill={color}
        />
    </svg>
);
export default LocationPointerAnimation;