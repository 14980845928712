import React from 'react'
import { ErrorBoundary } from "react-error-boundary";
import warning_error_2 from "../../../assets/common/warning_error_2.svg";
import bm_locals from "../../../localization/localization_en_business_modal.json"
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';

const WARNING_MSG = bm_locals.business_modal.warning_tooltip.error_warning_client;

const ErrorFallBackComponent = ({resetErrorBoundary}) => {
    return (
        <div className='flex flex-col gap-y-[12px] items-center justify-center bg-white rounded-md p-[16px]'>
            <img src={warning_error_2} alt="" />
            <TextLarge text={WARNING_MSG.description1} fontWeight={400} />
            <TextLarge text={WARNING_MSG.description2} fontWeight={400} />
            <PrimaryButton text={WARNING_MSG.retry_button} onClick={resetErrorBoundary} />
        </div>
    )
}

const ComponentErrorBoundary = ({ children }) => {
    return (
        <ErrorBoundary
            FallbackComponent={
                ({ error, resetErrorBoundary }) => {
                    return (<ErrorFallBackComponent resetErrorBoundary={resetErrorBoundary} />)
                }
            }
        >
            {children}
        </ErrorBoundary>
    )
}

export default ComponentErrorBoundary