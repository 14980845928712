import React, { useEffect } from "react";
import { Modal, message } from "antd"
import { useSelector, useDispatch } from "react-redux";
import Avatar from "react-avatar-edit";
import { useState } from "react";
import editLight from "../../assets/common/editLight.svg";
import greenTick from "../../assets/common/greenTick.svg";
import { UploadOutlined } from '@ant-design/icons';

function CustomImageUpload({ showEdit, profilePlaceholderText, avatar, handleProfileUpload, handleShowEdit }) {
    const [preview, setPreview] = useState(null);
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const imageTypesToBeAllowed = ["image/jpeg", "image/png", "image/webp", "image/bmp"];

    function onClose() {
        setPreview(null);
    }
    function onCrop(pv) {
        setPreview(pv);
    }
    function onBeforeFileLoad(elem) {
        if (elem?.target?.files[0]?.size > 3e+6 || !imageTypesToBeAllowed.includes(elem.target.files[0].type)) {
            messageApi.open({
                type: 'warning',
                content: 'File should not exceed 3mb and should be of image type',
            });
            elem.target.value = "";
        }
    }
    const handleUpload = () => {
        if (preview.length > 0) {
            handleProfileUpload(preview);
            setPreview(null);
        }
    }
    return (
        <div className="relative">
            {contextHolder}
            {
                (avatar?.length>0 && !showEdit)
                    ? <div className="flex justify-center">
                        <div className="flex justify-center border border-black p-1 rounded-full relative">
                            <img className="cursor-pointer" src={avatar} alt="" />
                            <button onClick={() => {
                                dispatch(handleShowEdit(true));
                            }} className="bg-primary rounded-full absolute -bottom-[0.5rem] -right-[0.5rem] border flex justify-center items-center w-[30px] aspect-square"><img className="w-[50%]" src={editLight} alt="" /></button>
                        </div>
                    </div>
                    : <div className="">
                        <Avatar
                            width={60}
                            height={60}
                            label={<UploadOutlined/>}
                            labelStyle={{
                                fontSize: '1.5rem',
                                color: '#372ee5',
                                fontWeight: 'bold',
                                cursor:"pointer"
                            }}
                            borderStyle={{
                                border:"1px dashed #372ee5",
                                borderRadius:"100%",
                                width:"100%",
                                height:"100%",
                                display:"flex",
                                justifyContent:"center"
                            }}
                            onCrop={onCrop}
                            onClose={onClose}
                            mimeTypes={imageTypesToBeAllowed}
                            onBeforeFileLoad={onBeforeFileLoad}
                            src={null}
                        />
                        <button onClick={handleUpload} className={`${preview === null && 'hidden'} w-4 h-4 absolute top-[0.65rem] right-[0.5rem]`}><img src={greenTick} alt="" /></button>
                    </div>
            }
        </div>
    );
}
export default CustomImageUpload;