import React, { useState } from 'react'
import { Modal, Radio } from 'antd';
import classNames from 'classnames';
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour';
import SecondaryButton from '../../DesignSystem/Buttons/SecondaryButton';
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import { useNavigate } from 'react-router-dom';
import bpdlocals from '../../localization/localization_en_business_planning.json'
import LinkButton from '../../DesignSystem/Buttons/LinkButton';

const SelectProblemModal = ({ no_data, isModalOpen, handleSelectProblem, handleCloseModal, listOfData = [], isPrimaryButtonDisabled, leftBtnName, rightButtonName, heading }) => {

    const navigate = useNavigate();
    const [selectedProblem, setSelectedProblem] = useState(null);

    return (
        <Modal
            open={isModalOpen}
            centered
            style={{ minWidth: "800px" }}
            zIndex={10000}
        >
            <HeadingFour text={heading} color={"#424242"} />
            {
                listOfData?.length === 0
                    ? (
                        <div className='my-[4rem] flex justify-center'>
                            <TextLarge text={no_data} fontWeight={600} className={"text-center"} />
                        </div>
                    ) : (
                        <ul className='flex flex-col gap-y-[0.5rem] py-[10px]'>
                            {
                                listOfData?.map((d, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={classNames(
                                                'rounded-md p-[12px]',
                                                { 'bg-baseGray300 text-black': d._id !== selectedProblem },
                                                { 'bg-p7 text-mainPrimary': d._id === selectedProblem }
                                            )}
                                        >
                                            <Radio
                                                onChange={(e) => setSelectedProblem(e.target.value)}
                                                checked={d?._id === selectedProblem}
                                                name={d?._id}
                                                value={d._id}
                                            >
                                                <span className='pl-[0.5rem]'>{d?.problem}</span>
                                            </Radio>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    )
            }
            <div className='flex justify-between'>
                <SecondaryButton onClick={handleCloseModal} text={leftBtnName} />
                <LinkButton text={bpdlocals.business_planning.my_dashboard.module.problem_solution_matrix.go_to_psm} onClick={() => {
                    navigate('/product/home/problemsolutionmatrix');
                }} />
                <PrimaryButton onClick={() => {
                    if (selectedProblem) {
                        handleCloseModal();
                        handleSelectProblem(selectedProblem);
                    }
                }} text={rightButtonName} isDisabled={isPrimaryButtonDisabled} />
            </div>
        </Modal>
    )
}

export default SelectProblemModal