import React from "react";

const AcessPanel = ({ color = "#fff", ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9999 13.6199C14.6184 13.6199 15.1199 13.1184 15.1199 12.4999C15.1199 11.8813 14.6184 11.3799 13.9999 11.3799C13.3813 11.3799 12.8799 11.8813 12.8799 12.4999C12.8799 13.1184 13.3813 13.6199 13.9999 13.6199Z"
      fill="#424242"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9998 14.5C13.2698 14.5 11.8098 14.86 11.7598 15.58C12.2598 16.29 13.0798 16.75 13.9998 16.75C14.9198 16.75 15.7398 16.29 16.2398 15.58C16.1898 14.86 14.7298 14.5 13.9998 14.5Z"
      fill="#424242"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8.09V3.27L7.5 0L0 3.27V8.18C0 12.72 3.2 16.97 7.5 18C8.05 17.87 8.58 17.68 9.1 17.45C10.18 18.99 11.97 20 14 20C17.31 20 20 17.31 20 14C20 11.03 17.84 8.57 15 8.09ZM8 14C8 14.56 8.08 15.11 8.23 15.62C7.99 15.73 7.75 15.84 7.5 15.92C4.33 14.92 2 11.68 2 8.18V4.58L7.5 2.18L13 4.58V8.09C10.16 8.57 8 11.03 8 14ZM14 18C11.79 18 10 16.21 10 14C10 11.79 11.79 10 14 10C16.21 10 18 11.79 18 14C18 16.21 16.21 18 14 18Z"
      fill="#424242"
    />
  </svg>
);
export default AcessPanel;
