import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import DefineForm from "./components/DefineForm"
import useCheckFormFillStatus from './hooks/useCheckFormFillStatus';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const COScreen6 = ({ formData, counterToTriggerFormStatus, isFormFilledAsExpected }) => {
    useCheckFormFillStatus({ formData, counterToTriggerFormStatus, isFormFilledAsExpected });
    return (
        <div className='flex flex-col gap-y-[24px]'>
            {
                formData.data.map((formItem, index) => {
                    return (
                        <ComponentErrorBoundary>
                            <DefineForm key={index} formItem={formItem} />
                        </ComponentErrorBoundary>
                    )
                })
            }
        </div>
    )
}

export default COScreen6