import React, { useState } from 'react'
import { Input, Button, message, Tooltip } from "antd"
import uploadIcon from "./assets/uploadIcon.svg"
import { useSelector, useDispatch } from "react-redux";
import { updateTextFields } from "./settingSlice";
import UploadImage from '../reuseablecomponent/UploadImage';
import { uploadAvatar } from './settingSlice';
import { toggleShowEdit } from './settingSlice';
import setting_locals from "../../localization/localization_en_setting.json"
import PasswordPolicy from '../reuseablecomponent/PasswordPolicy';

const locals = setting_locals.settings.tabs['Account Settings'];

const UserForm = () => {
    const dispatch = useDispatch();
    const managementType = useSelector((state) => state.setting.AccountSettingData);
    const AccountSettingData = useSelector((state) => state.setting.AccountSettingData)
    const formValues = useSelector((state) => state.setting[AccountSettingData.title]);
    const avatar = useSelector((state) => state.setting["Profile Management"].avatar)
    const loading = useSelector((state) => state.setting.loading);
    const showEdit = useSelector((state) => state.setting.showEdit);
    const handleProfileUpload = (preview) => {
        dispatch(toggleShowEdit(false));
        dispatch(uploadAvatar(preview));
    }
    const handleShowEdit = (value) => {
        // alert(value);
        dispatch(toggleShowEdit(value));
    }
    let isValidPassword;
    if(managementType.title === "Password Management"){
        isValidPassword = formValues["Confirm New Password"]?.length > 0 && formValues["Confirm New Password"]===formValues["New Password"]
    }
    // const validatePasswordPolicy = (label, password) => {
    //     if(label === "Confirm New Password"){
    //         return passwordValidatorForEnableBtn(password) && password.length>0;
    //     }
    //     return passwordValidatorForEnableBtn(password)
    // }
    return (
        <div>
            <ul className='relative'>
                {
                    AccountSettingData.formInputs.map((formField, index) => {
                        if (formField.type === "input") {
                            return (
                                <li key={index} className='w-[70%] mb-[16px]'>
                                    <label htmlFor={formField.label}>{formField.label}</label>
                                    <Input readOnly={formField.label==="Email" && formField.type==="input"} disabled={(AccountSettingData.title === "Password Management")}
                                        value={formValues[formField.label] || ''} onChange={(e) => dispatch(updateTextFields({ type: AccountSettingData.title, data: e.target.value, fieldName: formField.label }))}
                                        style={{ borderRadius: "8px", border: formField.label === "Email" && formValues[formField.label].length > 0 && !formValues.isEmailValid ? '1px solid red' : '1px solid #757575', padding: "12px 15px", marginTop: "8px" }}
                                        placeholder={formField.placeHolder} />
                                </li>
                            )
                        }
                        if (formField.type === "password") {
                            return (
                                <li key={index} className='w-[70%] mb-[16px]'>
                                    <label htmlFor={formField.label}>{formField.label}</label>
                                    <Input.Password
                                        id={formField.label} value={formValues[formField.label] || ''} onChange={(e) => {
                                            dispatch(updateTextFields({ type: AccountSettingData.title, data: e.target.value, fieldName: formField.label }))
                                        }} style={{ borderRadius: "8px", border: (formField.label === "Confirm New Password" &&  isValidPassword===false && formValues["Confirm New Password"]?.length ) ? '1px solid red' : '1px solid #757575', padding: "12px 15px", marginTop: "8px" }} placeholder={formField.placeHolder} />
                                </li>
                            )
                        }
                        if (formField.type === "upload") {
                            return (
                                <li key={index} className='absolute top-0 right-0 '>
                                    <p className='text-[#111827] font-InterRegular text-[16px] font-medium tracking-wide'>{locals.upload_image.label}</p>
                                    <UploadImage showEdit={showEdit} handleShowEdit={handleShowEdit} profilePlaceholderText={locals.upload_image.placeHolder} avatar={avatar} handleProfileUpload={handleProfileUpload} /> 
                                </li>
                            )
                        }
                    })
                }
            </ul>
            {managementType.title === "Password Management" 
            ? <PasswordPolicy/>
            : null}
        </div>
    )
}

export default UserForm
