import React from 'react';
import { Collapse, theme } from 'antd';
import { shapes } from '../../assets/website';
import './index.css'

const { down_arrow } = shapes;

const AntDSoloAccordin = ({ accordinData }) => {
    const { token } = theme.useToken();
    const [activeKey, setActiveKey] = React.useState([]);
    const panelStyle = {
        marginBottom: 24,
        borderRadius: token.borderRadiusLG,
        border: '1px solid #BECEFF',
    };

    // Generate the items array outside of the Collapse component text-[16px] text-[14px]
    const items = accordinData.map((accordinItem, index) => {
        const isActive = activeKey.includes(JSON.stringify(index));
        return (
            {
                "key": JSON.stringify(index),
                "label": <span style={{ color: isActive ? "#4B54FF" : "#111827" }} className={`sm:text-[20px] text-[16px] font-bold font-DMSans`}>{accordinItem.label}</span>,
                "children": <div className='bg-[#EBF1FF80] p-5'>{accordinItem.children}</div>,
                "style": {
                    ...panelStyle,
                    backgroundColor: isActive ? "#EBF1FF80" : "#F5F5F5",
                    border: `1px solid ${isActive ? "#BECEFF" : "#E0E0E0"}`
                },
            }
        )
    });

    return (
        <Collapse
            accordion
            className='ant_solo_accordin'
            bordered={true}
            expandIconPosition="end"
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => {
                return (
                    <div>
                        <img
                            src={down_arrow}
                            className={`${isActive ? 'rotate-180' : 'rotate-0'} min-w-[1.5rem]`}
                            alt=""
                        />
                    </div>
                )
            }}
            style={{
                background: "#FFFFFF",
            }}
            activeKey={activeKey}
            onChange={(keys) => setActiveKey(keys)}
            items={items}
        />
    );
};
export default AntDSoloAccordin;