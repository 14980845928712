import React from 'react'
import { Input } from "antd";
import { cn } from '../../utils/cn';
import classNames from 'classnames';

const LogoInputComponent = ({ value, handleOnChange, className, placeholder, prefixIcon }) => {
    return (
        <Input
            value={value}
            onChange={handleOnChange}
            className={cn(classNames(className))}
            placeholder={placeholder}
            prefix={prefixIcon}
        />
    )
}

export default LogoInputComponent