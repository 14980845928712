import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

const ConfettiComponent = () => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    setShowConfetti(true);

    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {showConfetti && (
        <Confetti
          numberOfPieces={400}
          recycle={false}
          timeout={60000}
          run={1}
          gravity={0.35}
          initialVelocityY={10}
          width={windowSize.width}
          height={windowSize.height}
        />
      )}
    </div>
  );
};

export default ConfettiComponent;