import React from 'react'
import SkeletonLoader from '../../../DesignSystem/Loader/SkeletonLoader'

const CardSkeletonLoader = () => {
    return (
        <div className='flex gap-x-[1rem] border p-[1rem] rounded-md'>
            <SkeletonLoader width={40} height={40} />
            <div className='w-full flex flex-col gap-y-[1rem]'>
                <SkeletonLoader style={{ maxWidth: "100%" }} height={40} />
                <div className='mt-[0.5rem]'>
                    {
                        Array.from({ length: 4 }).map((_, index) => {
                            return <SkeletonLoader key={index} style={{ maxWidth: "100%" }} height={20} />
                        })
                    }
                </div>
                <SkeletonLoader style={{ maxWidth: "100px" }} height={40} />
            </div>
        </div>
    )
}

export default CardSkeletonLoader