import React from 'react'
import module_locals from "../../../localization/localization_en_website.json"
import ModuleTemplate from '../../components/ModuleTemplate';

const DATA_ROOM_LOCALS = module_locals.website.modules['data-room'];

const MDataRoom = () => {
    return (
        <div>
            <ModuleTemplate
                locals={DATA_ROOM_LOCALS}
            />
        </div>
    )
}

export default MDataRoom