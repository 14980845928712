const PlusSVG = ({ color="#fff",...props }) => (
    <svg
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g>
            <path fill={color} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z" />
        </g>
    </svg>
)
export default PlusSVG
