import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'

const HeadingFive = ({ text, color, margin, padding, className, headingCSSStyles }) => {
  return (
    <React.Fragment>
        <h5 style={{color:color,...generatePadding(padding?.direction,padding?.number), ...generateMargin(margin?.direction,margin?.number), ...headingCSSStyles}} className={`text-[20px] font-bold font-DMSans ${className}`}>{text}</h5>
    </React.Fragment>
  )
}

export default HeadingFive