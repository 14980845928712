import React from 'react'
import { Table } from "antd";
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import downHeadArrow from "../../assets/common/downHeadArrow.svg"
import upsideHeadArrow from "../../assets/common/upsideHeadArrow.svg"
import TextMedium from '../TypographyDescriptions/TextMedium';
import TableColumnHeading from '../../components/BusinessModal/subcomponents/ROFormComponents/components/TableColumnHeading';
import { commaSeparatorNumberFormat } from '../../utils/commaSeparatorNumberFormat';
import { convertStringInK } from '../../utils/convertStringInK';

//STAGING
const ENVIRONMENT = import.meta.env.VITE_REACT_ENVIRONMENT;
//value.includes(currency) ? "yes" : "no"
export const addBracketsForProdNegativeValue = (value, currency) => {
    if(value?.includes(currency)){
        return `(${value?.split('-')?.join('')})`
    }else{
        return `(${value.split('-')[1]})`
    }
}

const CollapseTable = ({ data, fixedIndexConfig = [0], mainTableHeading, currency, originalData }) => {
    try {
        const RECORD_IS_PARENT_OR_CHILDREN = ({ key }) => {
            let isParent = false;
            data.forEach((record) => {
                if (record.key === key) isParent = true;
            })
            return isParent;
        }
        let COLUMN_TITLE = []
        const isDataSourcePresent = data.length>0;
        if(data.length>0){
            COLUMN_TITLE = Object?.keys(data?.[0])?.filter((attribute) => !["key", "children"]?.includes(attribute))
        }else{
            if(Array.isArray(originalData) && originalData.length>0){
                COLUMN_TITLE = Object?.keys(originalData?.[0])?.filter((attribute) => !["key", "children"]?.includes(attribute))
            }
        }
        const COLUMNS = COLUMN_TITLE.map((title, columnIndex) => {
            return (
                {
                    "key": columnIndex,
                    "fixed": fixedIndexConfig.includes(columnIndex) ? 'left' : '',
                    "title": (<div style={{ padding: columnIndex===0 ? '0rem 1rem' : '0rem' }}>
                        <TableColumnHeading title={ (!!mainTableHeading && columnIndex===0) ? mainTableHeading : title.slice(0, 1).toUpperCase() + title.slice(1)} />
                    </div>),
                    "dataIndex": title,
                    "width": columnIndex===0 ? 250 :130,
                    "render": (value, record, rowIndex) => {
                        const PADDING = columnIndex === 0 ? record?.children ? "0rem" : "12px 1.4rem" : "12px 0.4rem";
                        const IS_CHILDREN = !RECORD_IS_PARENT_OR_CHILDREN({ key: record.key })
                        const backgroundColor = IS_CHILDREN ? '#F5F5F5' : 'white';
                        const NON_CURRENCY_ROW = ["Orders","Growth Percentage","Gross Margin Percentage","Growth %","Gross Margin %","CM 1 %","CM 2%","Operating Income %","PAT %","Working Capital Changes"]
                        let EXCLUDE_CURRENCY = NON_CURRENCY_ROW.includes(record.title)
                        let stagingFormattedValue, fontColor, prodFormattedValue;
                        if(IS_CHILDREN){
                            EXCLUDE_CURRENCY = NON_CURRENCY_ROW.includes(record.parentTitle)
                        }
                        if(columnIndex>0){
                            stagingFormattedValue = commaSeparatorNumberFormat({ "number": value, "prefix": NON_CURRENCY_ROW.includes(record.title) ? '' : currency })
                            prodFormattedValue = convertStringInK(stagingFormattedValue)
                            prodFormattedValue = prodFormattedValue?.includes('-') ? addBracketsForProdNegativeValue(prodFormattedValue, currency) : prodFormattedValue;
                            fontColor = commaSeparatorNumberFormat({ "number": value, "prefix":EXCLUDE_CURRENCY ? '' : currency })?.includes('-') ? 'red' : record?.children ? 'blue' : 'black'
                            stagingFormattedValue = stagingFormattedValue?.includes('-') ? `(${stagingFormattedValue.split('-')[0]+String(value).split('-')[1]} )` : stagingFormattedValue;
                        }
                        if(!!stagingFormattedValue && stagingFormattedValue?.includes('-')){
                            stagingFormattedValue = `(+${stagingFormattedValue}+)`
                        }
                        const cellValue = false ? stagingFormattedValue : prodFormattedValue
                        return (
                            <span
                                style={{
                                    display: 'inline-block',
                                    padding: PADDING,
                                    width: !record.children ? "100%" : columnIndex===0 ? "14rem" : "fit-content",
                                    backgroundColor: "#FFFFFF"
                                }}
                            >
                            {
                                columnIndex===0 
                                ? (
                                    <TextMedium color={record.children ? 'blue' : 'black'} fontWeight={400} text={value} />
                                ) : (
                                    <TextMedium 
                                        wordType="break-all" 
                                        color={fontColor} 
                                        fontWeight={400} 
                                        text={cellValue ?? 0} 
                                    />
                                )
                            }
                            </span>
                        )
                    }
                }
            )
        })
        const customExpandIcon = ({ expanded, onExpand, record }) => {
            if (!record?.children) return null;
            return expanded ? (
                <span style={{ display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer', backgroundColor: "white" }} className='-mt-[0.1rem]'><img src={upsideHeadArrow} onClick={e => onExpand(record, e)} /></span>
            ) : (
                <span style={{ display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer', backgroundColor: "white" }} className='-mt-[0.1rem]'><img src={downHeadArrow} onClick={e => onExpand(record, e)} /></span>
            );
        };
        // const getRowClassName = (record, index) => {
        //     return record.isChildren ? 'children_cell' : 'odd-row';
        // };
        // rowClassName={getRowClassName}
        const totalColumnWidth = COLUMNS?.reduce((total, column) => total + (column.width || 0), 0);
        return (
            <Table
                bordered
                className='collapsible_table'
                columns={COLUMNS}
                dataSource={isDataSourcePresent ? data : []}
                scroll={{
                    x: totalColumnWidth,
                }}
                expandable={{
                    expandIcon: customExpandIcon,
                    defaultExpandAllRows: true,
                }}
                pagination={{
                    pageSize: data?.length,
                    position: ['none', 'none'],
                }}
            />
        )
    } catch (error) {
        throw new Error(error)
    }
}

export default CollapseTable