import React from 'react'
import Location from './Location'
import TextComponent from '../../../reuseablecomponent/TextVarients/TextComponent'
import { useSelector } from "react-redux"
import greenTick from "../../../../assets/common/greenTick.svg"


const ParentModuleActiveOrInActivePosition = ({ text, parentRefIndex, status }) => {
  const subParentActiveKey = useSelector((state) => state.link.openKeys);
  const activeItem = useSelector((state) => state.link.activeItem);
  const activeOrInactive = parentRefIndex === activeItem;
  let usageOfModule;
  if (status === 1) {
    usageOfModule =
      <div className={`w-[28px] h-[28px] mr-[12px] aspect-square border bg-white flex justify-center items-center rounded-full`}>
        <img src={greenTick} alt="" />
      </div>
  } else {
    usageOfModule = <div className={`${activeOrInactive ? 'border-[#ECA258]' : 'border-[#9E9E9E]'} w-[28px] h-[28px] mr-[12px] aspect-square border bg-white flex justify-center items-center rounded-full`}>
      <div className={`${activeOrInactive ? 'bg-[#ECA258]' : 'bg-white'} w-[70%] h-[70%] rounded-full`}></div>
    </div>
  }
  return (
    <React.Fragment>
      <div className='flex items-center relative'>
        <div className='flex items-center min-w-[383px]'>
          {usageOfModule}
          <TextComponent color={"#111827"} fontSize={16} fontWeight={500} text={text} />
        </div>
        {activeOrInactive && activeItem.length <= 3 && activeItem===subParentActiveKey[subParentActiveKey.length-1] && <Location topPosition={`-1rem`} />}
      </div>
    </React.Fragment>
  )
}

export default ParentModuleActiveOrInActivePosition