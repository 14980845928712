import { AppendUUIDKeyTable } from "../components/CostOnboarding/utils/AppendUUIDKeyTable";
import { months } from "../data/data";
import costScreenLocals from "../../../localization/localization_en_cost_screen.json"
import { addAttributeDbIdByName } from "./addAttributeDbIdByName";

export const formatFiscalYearForAntDCalender = ({ dbmonth, dbyear }) => {
    if (!!dbmonth === false || !!dbyear === false) return null;
    try {
        let month = "";
        for (const mon of Object.entries(months)) {
            if (mon[1].value === dbmonth) {
                month = String(mon[0]).length === 1 ? 0 + mon[0] : mon[0];
            }
        }
        return String(dbyear + '-' + month);
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const formatDependencyData = ({ dependencyData }) => {
    return {
        "channel_sales": dependencyData?.channels_of_sales,
        "marketing_spend_per_channel_fiscal_year": formatFiscalYearForAntDCalender({ "dbmonth": dependencyData?.marketing_spend_per_sales_channel?.first_month, "dbyear": dependencyData?.marketing_spend_per_sales_channel?.first_year })
    }
}

export const costScreenOneDataInjection = ({ data, input, output }) => {
    try {
        // month year and revenue deps data
        console.log(data,"data")
        let month = "";
        for (const mon of Object?.entries(months)) {
            if (mon[1]?.value === data?.revenueScreenOneInput?.marketing_spend_per_sales_channel?.first_month) {
                month = String(mon[0])?.length === 1 ? 0 + mon[0] : mon[0];
            }
        }
        const year = data?.revenueScreenOneInput?.marketing_spend_per_sales_channel?.first_year
        const revenueMarketingSpendData = data.revenueScreenOneInput.channels_of_sales.map((channel) => {
            return ({
                "name": channel,
                ...data.revenueScreenOneInput?.marketing_spend_per_sales_channel[channel]
            })
        })
        let marketing_spend = revenueMarketingSpendData
        let marketing_spend_span_type = data.revenueScreenOneInput.marketing_spend_per_sales_channel.type;
        let revenue_onboarding_marketing_spend_details = {
            "month_year": `${year}-${month}`,
            "marketing_spend_data_length" : data.revenueScreenOneInput.channels_of_sales.length
        }
        let cost_incurred_for_user_through_marketing = AppendUUIDKeyTable(costScreenLocals.cost.form[0].data[1].table.defaultRows);

        //cost screen 1
        if (!!data?.input) {
            marketing_spend = [...marketing_spend, ...input.marketing_spend_per_sales_channel]
            cost_incurred_for_user_through_marketing = input.cost_incurred_for_user_through_marketing;
        }
        return {
            marketing_spend,
            marketing_spend_span_type,
            revenue_onboarding_marketing_spend_details,
            cost_incurred_for_user_through_marketing,
            "output": !!output ? output : null,
            "isSaveDisabled": output ? true : false
        }
    } catch (error) {
        console.log(error)
        return {};
    }
}

export const costScreenTwoDataInjection = ({ data, input, output, defaultRowNameList }) => {
    try {
        let channel_sales = [];
        let incurring_direct_cost = AppendUUIDKeyTable(costScreenLocals.cost.form[1].data[0].table.defaultRows);
        let return_cancellation_chargeable_percentage = AppendUUIDKeyTable(costScreenLocals.cost.form[1].data[1].table.defaultRows);
        incurring_direct_cost = addAttributeDbIdByName({ "data": incurring_direct_cost,"dbCategoryList": defaultRowNameList })
        channel_sales = data.revenueScreenOneInput.channels_of_sales
        if (!!input) {
            incurring_direct_cost = input.incurring_direct_cost;
            return_cancellation_chargeable_percentage = input.return_cancellation_chargeable_percentage;
            output = !!output ? output : null;
        }
        return {
            channel_sales,
            incurring_direct_cost,
            return_cancellation_chargeable_percentage,
            output
        }
    } catch (error) {
        console.log(error)
        return {};
    }
}

export const costScreenThreeDataInjection = ({ input, output, defaultRowNameCapPlanningList, defaultRowNameCostList }) => {
    try {
        let capacity_planning = AppendUUIDKeyTable(costScreenLocals.cost.form[2].data[1].table.defaultRows);
        let cost = AppendUUIDKeyTable(costScreenLocals.cost.form[2].data[2].table.defaultRows);
        capacity_planning = addAttributeDbIdByName({ "data": capacity_planning, "dbCategoryList": defaultRowNameCapPlanningList })
        cost = addAttributeDbIdByName({ "data": cost, "dbCategoryList": defaultRowNameCostList })
        let facilities_own_operation = [];
        console.log(input, "input")
        if (!!input) {
            facilities_own_operation = input.facilities_own_operation
            if (!!input?.capacity_planning) {
                const mapping = { "Warehouse/Factory Capacity": "WarehouseFactory", "Machine Capacity": "WarehouseFactory", "Orders Processed Per Labor": "WarehouseFactory", "Collection Centre Per Warehouse": "Collection Center", "Staff Per Collection Centre": "Collection Center" };
                capacity_planning = input.capacity_planning.map((row) => ({ ...row, "deps": !!row.name ? (!!mapping[row.name] ? mapping[row.name] : "other") : "other" }))
            }
            if (!!input?.cost) {
                const mapping = { "Lease": "default", "Deposit": "default", "Setup Cost": "default", "Cost Of Machine": "WarehouseFactory", "Per Labour Cost": "WarehouseFactory", "Overheads Cost": "WarehouseFactory", "Collection Centre Staff Cost": "Collection Center" }
                cost = input.cost.map((row) => ({ ...row, "deps": !!row.name ? (!!mapping[row.name] ? mapping[row.name] : "other") : "other" }))
            }
        }
        return {
            facilities_own_operation,
            cost,
            capacity_planning,
            output: !!output ? output : null
        }
    } catch (error) {
        console.log(error)
        return {};
    }
}

export const costScreenFourDataInjection = ({ input, output, defaultRowNameDerviedCostList, defaultRowNameUnDerviedCostList }) => {
    try {
        let personnel_tl_ratio = null;
        let undervied_costs_personnal_diff_department = AppendUUIDKeyTable(costScreenLocals.cost.form[3].data[1].table.defaultRows);
        let dervied_costs_personnal_diff_department = AppendUUIDKeyTable(costScreenLocals.cost.form[3].data[2].table.defaultRows);
        undervied_costs_personnal_diff_department = addAttributeDbIdByName({ "data": undervied_costs_personnal_diff_department, "dbCategoryList": defaultRowNameUnDerviedCostList })
        dervied_costs_personnal_diff_department = addAttributeDbIdByName({ "data": dervied_costs_personnal_diff_department, "dbCategoryList": defaultRowNameDerviedCostList })
        if (!!input) {
            personnel_tl_ratio = input.personnel_tl_ratio
            undervied_costs_personnal_diff_department = input.undervied_costs_personnal_diff_department
            dervied_costs_personnal_diff_department = input.dervied_costs_personnal_diff_department
        }
        return {
            personnel_tl_ratio,
            undervied_costs_personnal_diff_department,
            dervied_costs_personnal_diff_department,
            output: !!output ? output : null
        }
    } catch (error) {
        console.log(error)
        return {};
    }
}

export const costScreenFiveDataInjection = ({ input, output, defaultRowNameList }) => {
    try {
        let currently_incurring_g_and_a_cost = AppendUUIDKeyTable(costScreenLocals.cost.form[4].data[0].table.defaultRows)
        currently_incurring_g_and_a_cost = addAttributeDbIdByName({ "data": currently_incurring_g_and_a_cost, "dbCategoryList": defaultRowNameList })
        if (!!input) {
            currently_incurring_g_and_a_cost = input.currently_incurring_g_and_a_cost;
        }
        return {
            currently_incurring_g_and_a_cost,
            output: !!output ? output : null
        }
    } catch (error) {
        console.log(error)
        return {};
    }
}

export const costScreenSixDataInjection = ({ input, output, defaultRowNameList }) => {
    try {
        let capex_cost = AppendUUIDKeyTable(costScreenLocals.cost.form[5].data[0].table.defaultRows)
        capex_cost = addAttributeDbIdByName({ "data": capex_cost, "dbCategoryList": defaultRowNameList })
        if (!!input) {
            capex_cost = input.capex_cost;
        }
        return {
            capex_cost,
            output: !!output ? output : null
        }
    } catch (error) {
        console.log(error)
        return {};
    }
}