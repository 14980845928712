import React from 'react'
import { useSelector } from "react-redux";
import { Navigate, useLocation } from 'react-router-dom';
import { restrictedResourcesForUser } from './data';

const AuthProtected = ({ children, component }) => {
    const location = useLocation();
    const user = useSelector((state) => state.user);
    const checkUserNameExist = user.redirectToAskNamePage;
    const isOnboardingComplete = user.isOnboardingComplete;
    const emailVerified = user.emailVerified;

    if(component==="login"){
        if(checkUserNameExist) return <Navigate to='/product/new/username' replace />
        if(!isOnboardingComplete && user.value.id && user.value.isAdmin) return <Navigate to='/product/onboarding' replace/>
        // if(user.planStatus === false && user.value.id) return <Navigate to='/product/home/setting/subscription' replace />
        // if(isOnboardingComplete==false && user.value.isAdmin==false && user.value.id) return <Navigate to='/test' replace/>
        if(user.value.AccessToken){
            return <Navigate to="/product/home" replace />
        }
        return children;
    }
    if(["createAccount", "verifyemail"].includes(component)){
        if(emailVerified && isOnboardingComplete){
            return <Navigate to="/product/home" replace />
        }
        return children;
    }
    if(component==="resetPassword"){
        if(!user?.value?.email && user?.value?.email?.length>0 ){
            return <Navigate to="/product/home" replace />
        }
        return children;
    }
    if(component==="newresetpassword"){
        if(location.pathname.split('/').length===4){
            return children;
        }
        return <Navigate to="/product/home" replace />
    }
    return children
}

export default AuthProtected