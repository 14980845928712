import React from 'react'
import module_locals from "../../../localization/localization_en_website.json"
import ModuleTemplate from '../../components/ModuleTemplate';

const BUSINESS_MODEL_LOCALS = module_locals.website.modules['business-model'];

const MBusinessModel = () => {
    return (
        <div>
            <ModuleTemplate
                locals={BUSINESS_MODEL_LOCALS}
            />
        </div>
    )
}

export default MBusinessModel
