import React from 'react'
import classNames from 'classnames'
import HeadingFour from '../../TypographyHeadings/HeadingFour'
import TextSmall from '../../TypographyDescriptions/TextSmall'
import { shapes } from '../../../assets/website'
import TextMedium from '../../TypographyDescriptions/TextMedium'
import PGButtonComponent from '../../Buttons/PGButtonComponent'
import TextLarge from '../../TypographyDescriptions/TextLarge'
import TextExtraSmall from '../../TypographyDescriptions/TextExtraSmall'
import ResponsiveHeadingFourInter from '../../TypographyHeadings/ResponsiveHeadingFourInter'

const { tick } = shapes


const PlanDescription = ({
    title,
    description,
    image,
    features,
    priorityTag,
    handleOnClick,
    isHrVisible = true,
    textSize = "sm",
    isTopButtonVisible = false,
    topLevelButtonName,
    isBottomButtonVisible = true,
    bottomLevelButtonName,
    isCardhoverEffect = false,
    isCustomBorderColor = false,
    topLevelButtonType = "primary",
    ComponentViewPricing
}) => {

    return (
        <div className={classNames(
            'flex flex-col gap-y-[24px] p-[16px] rounded-[8px] bg-white relative',
            { 'hover:bg-[#EBF1FF] transition-all': isCardhoverEffect },
            { 'border-t-[4px] border-t-p3 border-[1.5px] border-p3': isCustomBorderColor }
        )}>

            {
                priorityTag
                    ? (
                        <div className='bg-p8 rounded-full px-[10px] pt-[4px] pb-[6px] absolute top-[16px] right-[16px]'>
                            <TextExtraSmall text={priorityTag} color='#372EE5' />
                        </div>
                    ) : null
            }

            <div className='flex flex-col gap-y-[16px]'>

                <div className='flex flex-col gap-y-[8px]'>
                    <img src={image} className='w-[40px] aspect-square' alt="" />
                    <ResponsiveHeadingFourInter text={title} />
                    <div className='min-h-[2.75rem]'>
                        {
                            textSize === "sm"
                                ? (<TextSmall text={description} fontWeight={400} color='#757575' />)
                                : (<TextLarge text={description} fontWeight={500} color='#757575' />)
                        }
                    </div>
                </div>

                {
                    ComponentViewPricing ?? null
                }

                {
                    isTopButtonVisible
                        ? (
                            topLevelButtonType==="primary"
                                ? <PGButtonComponent onClick={handleOnClick}>{topLevelButtonName}</PGButtonComponent>
                                : <PGButtonComponent onClick={handleOnClick} className={"border border-[#757575] bg-white text-black"}>{topLevelButtonName}</PGButtonComponent>
                        )
                        : null
                }

                {
                    isHrVisible
                        ? (<hr style={{ backgroundColor: "#BDBDBD", height: '1px' }} />)
                        : null
                }

                <ul className='flex flex-col gap-y-[16px]'>
                    {
                        features?.map((feature, index) => {
                            return (
                                <li key={index} className='flex gap-x-[6.67px]'>
                                    <img src={tick} alt="" />
                                    <TextMedium fontWeight={500} color='#424242' text={feature} />
                                </li>
                            )
                        })
                    }
                </ul>

            </div>

            {
                isBottomButtonVisible
                    ? (<PGButtonComponent
                        intent={"link"}
                        size={"free"}
                        onClick={handleOnClick}
                        className='border border-[#424242] text-[#424242] rounded-full py-[8px] hover:bg-maindarkShade1 hover:text-white transition-all'
                    >
                        {bottomLevelButtonName}
                    </PGButtonComponent>)
                    : null
            }

        </div>
    )
}

export default PlanDescription
