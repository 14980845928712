import React from 'react'

const TextDMSansComponent = ({text,fontSize,fontWeight, color}) => {
  return (
    <React.Fragment>
        <p style={{color:color,fontSize:`${fontSize}px`,fontWeight, margin:'0', padding:'0'}} className='font-DMSans tracking-wide'>{text}</p>
    </React.Fragment>
  )
}

export default TextDMSansComponent