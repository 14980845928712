import CardCheckBoxInput from "../../../../../DesignSystem/Forms/CheckBoxVarients/CardCheckBoxInput";

const CheckBoxInput = (props) => {
    const { data, selectOptions, name, isMulti, handleCheckBoxOnChange, isDisabled=false, isReadOnly=false } = props;
    return (
        <div className="flex gap-[8px] flex-wrap h-full justify-start">
            {
                selectOptions.map((option, index) => {
                    return (
                        <div key={index} className="w-[250px] max-h-fit">
                            <CardCheckBoxInput
                                value={option.value}
                                isMulti={isMulti}
                                name={name}
                                key={index}
                                text={option.label}
                                tooltip={option.tooltip}
                                isDisabled={option.isDisabled || isDisabled || isReadOnly}
                                handleOnClick={handleCheckBoxOnChange}
                                isChecked={isMulti ? data?.includes(option.value) : data === option.value}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CheckBoxInput