import React from 'react'
import { useDispatch } from 'react-redux';
import { edit_black } from '../../../assets';
import profile_green from "../../../assets/PSM/profile_green.svg"
import deleteIcon from "../../../assets/common/deleteRed.svg"
import DropdownAction from '../../../DesignSystem/Forms/DropdownAction';
import { timeStamptoHour } from '../../reuseablecomponent/timestampToHour';
import { D_UpdateActiveChatDetail } from '../chatSlice';
import locals from "../../../localization/localization_en_chat.json"
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

const referenceImage = { edit_black: <ModeEditOutlinedIcon className='text-primary'/>, deleteIcon: <DeleteOutlinedIcon className='text-infoAlertError1' /> }

const ProfileWithAction = ({ user, comment, isParentComment, isShowAction = true, isShowTimeStamp = true }) => {

    const dispatch = useDispatch();
    const { _id, username, avatar } = user || {};

    return (
        <div className='flex justify-between items-center'>
            <div className="profile_section flex items-center">
                <img src={!!avatar ? avatar : profile_green} alt="" className='w-[32px] h-[32px]' />
                <div className='pl-[12px]'>
                    <p style={{ marginBottom: 0 }}>{username}</p>
                    {
                        isShowTimeStamp
                            ? (
                                <p style={{ marginBottom: 0 }}>{timeStamptoHour(comment.updatedAt)}</p>
                            ) : null
                    }
                </div>
            </div>
            <div>
                {
                    isShowAction
                        ? (
                            <DropdownAction
                                imgIconOrCustomicon='custom'
                                items={locals.actions.map((action) => ({...action, dropdown_icon_ref: referenceImage[action.dropdown_icon_ref]}))}
                                handleOnClick={
                                    ({ ACTION_TYPE }) => {
                                        dispatch(D_UpdateActiveChatDetail({
                                            ACTION_TYPE,
                                            isParentComment,
                                            isModalOpen: true,
                                            "activeChatId": comment._id,
                                            "commentMessage": comment.message,
                                        }))
                                    }
                                }
                            />
                        ) : null
                }

            </div>
        </div>
    )
}

export default ProfileWithAction