import React from 'react'
import TextMedium from '../TypographyDescriptions/TextMedium';
import TextSmall from '../TypographyDescriptions/TextSmall';

const IconTitleCard = ({
    icon1,
    icon2,
    icon3,
    text,
    description,
    isSelected,
    handleOnClick
}) => {

    const ICONONE = icon1;
    const ICONTWO = icon2;
    const ICONTHREE = icon3;

    return (
        <div 
        onClick={handleOnClick}
        className={
            `cursor-pointer py-[8px] px-[12px] rounded-md flex gap-x-[0.5rem] items-center relative transition-all ${isSelected ? 'bg-primary/10 border border-primary' :'border bg-[#F5F5F5]'}`
        }>
            <div className='flex flex-col py-[4px]'>
                {/* <ICONONE className='mr-auto' /> */}
                {/* <ICONTWO className='mx-auto' /> */}
                <ICONTHREE className={isSelected ? "text-primary" : "text-black"} />
            </div>
            <div>
                <div>
                    <TextMedium color={isSelected ? "#392de6" : "#000000"} text={text} className={'mx-auto'} />
                </div>
                <div>
                    <TextSmall color={isSelected ? "#392de6" : "#000000"} text={description}/>
                </div>
            </div>
            <div style={{ border: `1.5px solid ${isSelected ? "#21208e" : "#9E9E9E"}` }} className='relative w-[14px] h-[14px] rounded-full bg-white'>
                <div className={
                    `w-[8px] aspect-square rounded-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${isSelected ? 'bg-primary' : 'bg-white'}`
                }></div>
            </div>
        </div>
    )
}

export default IconTitleCard