import ListNavItem from './components/ListNavItem';

const PitchDeckNavBar = (props) => {
    const { data, currentFeature } = props;
    return (
        <div className='bg-primary'>
            <ListNavItem data={data} currentFeature={currentFeature} itemProps={props} />
        </div>
    )
}

export default PitchDeckNavBar