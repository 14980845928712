import React from 'react'
import { Radio, Space } from "antd";

const RadioInput = ({ listOfOptions, handleOnChange, value, direction="vertical", isDisabled }) => {
    return (
        <Radio.Group value={value} onChange={handleOnChange} disabled={isDisabled}>
            <Space direction={direction}>
                {
                    listOfOptions?.map((option, index) => {
                        return <Radio id={index} name={option.value} value={option.value} key={index}>{option.label}</Radio>
                    })
                }
            </Space>
        </Radio.Group>
    )
}

export default RadioInput