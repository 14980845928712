import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import FormLabel from '../CostOnboarding/components/FormLabel'
import ErrorFormNotFilled from '../CostOnboarding/microcomponents/ErrorFormNotFilled'
import HeadingFour from '../../../../DesignSystem/TypographyHeadings/HeadingFour'
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton'
import SecondaryButton from '../../../../DesignSystem/Buttons/SecondaryButton'
import cash_flow_locals from "../../../../localization/localization_en_pAndL_workCap_debtWork_cashFlow_balanceSheet.json"
import CustomNumber from '../../subcomponents/ROFormComponents/components/CustomNumber'
import EditableTable from '../../subcomponents/common/EditableTable';
import { useNavigate } from "react-router-dom"
import { ValidateNumber } from '../CostOnboarding/utils/ValidateNumber';
import { API_Process_CashFlow_Data, API_Save_CashFlow_Data, D_Capture_Normal_Input_Value_CashFlow, D_Reset_ErrorMessage } from '../../cashFlowSlice'
import useErrorCheckBMOther from '../../subcomponents/common/useErrorCheckBMOther';
import useOpenOopsPopup from '../../subcomponents/common/useOpenOopsPopup';
import WarningPopup from '../../subcomponents/common/WarningPopup';
import useFetchBMOtherScreen from '../../../../hooks/useFetchBMOtherScreen';
import PageLoader from '../../../reuseablecomponent/PageLoader';
import OutputScreen from '../RevenueOnboarding/OutputScreen';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const FORM_ITEMS = cash_flow_locals.cash_flow.form.data

const RenderFormComponent = ({ formItem, cashFlowData, isShowError }) => {
    const { decimal_places } = useSelector((state) => state.globalSetting.active_global_settings)
    const dispatch = useDispatch();
    const month_year = useSelector((state) => state.cashFlow.cflowStore?.month_year_revenue_screen1_ref)
    const handleOnBlur = (event) => {
        const { status, value } = ValidateNumber({ "validation_type": formItem.validator, "value": event.target.value, "decimal_place": decimal_places })
        if(status){
            dispatch(D_Capture_Normal_Input_Value_CashFlow({ "name": formItem.name, value }))
            return;
        }
        dispatch(D_Capture_Normal_Input_Value_CashFlow({ "name": formItem.name, "value": null }))
    }
    const { SingleFormCheck } = useErrorCheckBMOther()
    const formFilledStatus = SingleFormCheck({ formItem, "CompleteSliceData": cashFlowData })
    return (
        <div className='bg-baseGray100 p-[16px] rounded-[12px] relative'>
            <FormLabel label={formItem.label} isMandatory={formItem.is_mandatory} tooltip={formItem.tooltip} />
            {
                formItem.component_type === "editable_table"
                    ? <EditableTable
                        payloadData={{ month_year, formItem, "data": cashFlowData[formItem.name], "name": formItem.name }}
                    />
                    : null
            }
            {
                formItem.component_type === "number_input"
                    ? <div className='w-[100px]'>
                        <CustomNumber name={formItem.name} value={cashFlowData[formItem.name]} handleOnBlur={handleOnBlur} isBordered={true} isReadOnly={false} placeholder={formItem.placeHolder} borderColor='#BECEFF' /></div>
                    : null
            }
            {(formFilledStatus && isShowError) ? <ErrorFormNotFilled /> : null}
        </div>
    )
}

const CashFlow = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)
    const VIEW_MODE = MODE_TYPE.view;
    const EDIT_MODE = MODE_TYPE.edit;
    const [traceButtonClick, setTraceButtonClick] = React.useState(null);
    const [warningPopupVarient, setWarningPopupVarient] = React.useState(null);
    const { isModelOpen, handlePopUpClose, handlePopUpOpen } = useOpenOopsPopup();
    const active_BusinessModal_Id = useSelector((state) => state.businessModal.active_BusinessModal_Id)
    const [isShowError, setIsShowError] = React.useState(false);
    const cashFlowData = useSelector((state) => state.cashFlow.cflowStore);
    const { OverallFormCheck } = useErrorCheckBMOther();
    const apiStatus = useSelector((state) => state.cashFlow.cflowStore.save_process_api_status);
    const IS_API_CALLING = apiStatus.status === "loading";
    const { fetchTargetScreenData, api_status, activeModeType, isFutureInputPresent } = useFetchBMOtherScreen();
    const [traceButtonType, setTraceButtonType] = React.useState(null);
    React.useEffect(() => {
        if(api_status==="success"){
            if(activeModeType.view && isFutureInputPresent===false){
                setWarningPopupVarient("future_screen_data_not_found_view");
                handlePopUpOpen();
                return;
            }
            if(traceButtonType==="prev"){
                if(EDIT_MODE){
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/working-capital`)
                    return;
                }
                navigate('/product/home/business-modal/working-capital')
            }else{
                if(EDIT_MODE){
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/balance-sheet`)
                    return;
                }
                navigate('/product/home/business-modal/balance-sheet')
            }
        }
    }, [api_status])
    React.useEffect(() => {
        const Status = apiStatus.status
        if(Status === 'failed'){
            handlePopUpOpen();
            if(traceButtonClick==="save") setWarningPopupVarient("save")
            if(traceButtonClick==="process") setWarningPopupVarient("process")
            dispatch(D_Reset_ErrorMessage());
        }
    }, [apiStatus.counter])
    const handleOnSaveButton = () => {
        setIsShowError(true);
        setTraceButtonClick("save");
        const status = OverallFormCheck({ "overAll_form": FORM_ITEMS, "CompleteSliceData": cashFlowData });
        if(status){
            dispatch(API_Save_CashFlow_Data({ "businessModelId": active_BusinessModal_Id, "screenInput": cashFlowData }));
            console.log("save api..")
        }else{
            console.log("form not filled...");
        }
    }
    const handleProcessButton = () => {
        setTraceButtonClick("process");
        dispatch(API_Process_CashFlow_Data({ "businessModelId": active_BusinessModal_Id, "screenInput": cashFlowData }))
    }
    const handleBackButton = () => {
        fetchTargetScreenData({ screenName: "workingCapitalScreen", previousScreen: "cashFlowScreen" });
        setTraceButtonType("prev");
    }
    const handleNextButton = () => {
        fetchTargetScreenData({ screenName: "balanceSheetScreen", previousScreen: "cashFlowScreen" });
        setTraceButtonType("next");
    }
    const isSaveButtonDisabled = cashFlowData.isSaveDisabled
    const isGenerateOutputDisabled = cashFlowData.isGenerateOutputDisabled
    const TopBackButtonDisabled = cashFlowData.isSaveDisabled===false && cashFlowData.isGenerateOutputDisabled===false
    return (
        <div className='mt-[5rem] flex flex-col gap-y-[24px]'>
            <WarningPopup warningType={warningPopupVarient} isModelOpen={isModelOpen} handlePopUpClose={handlePopUpClose} />
            <HeadingFour text={cash_flow_locals.cash_flow.title} />
            { api_status==="loading" ? <PageLoader/> : null }
            <ul className='flex flex-col gap-y-[24px]'>
                {
                    cash_flow_locals.cash_flow.form.data.map((formItem, index) => {
                        return (
                            <ComponentErrorBoundary>
                                <RenderFormComponent isShowError={isShowError} key={index} formItem={formItem} cashFlowData={cashFlowData} />
                            </ComponentErrorBoundary>
                        )
                    })
                }
            </ul>
            <div className='flex justify-between'>
                <div style={{ "visibility": !!cashFlowData.output ? "hidden" : "visible" }}>
                    <SecondaryButton isDisabled={IS_API_CALLING} text={"Back"} onClick={handleBackButton} />
                </div>
                <PrimaryButton isLoading={IS_API_CALLING && traceButtonClick==="save"} isDisabled={isSaveButtonDisabled || IS_API_CALLING} onClick={handleOnSaveButton} text={"Save"} />
                <PrimaryButton isLoading={IS_API_CALLING && traceButtonClick==="process"} isDisabled={!isSaveButtonDisabled || isGenerateOutputDisabled || IS_API_CALLING} onClick={handleProcessButton} text={"Generate Output"} />
            </div>
            <div style={{ "visibility": cashFlowData.output===null ? "hidden" : "visible" }} >
                <ComponentErrorBoundary>
                    <OutputScreen outputData={cashFlowData.output} type={"cashFlowScreen"} />
                </ComponentErrorBoundary>
            </div>
            {
                cashFlowData.output!==null
                ? (
                    <div className='flex justify-between'>
                        <SecondaryButton isDisabled={false} text={"Back"} onClick={handleBackButton} />
                        <PrimaryButton isDisabled={false} text={"Next"} onClick={handleNextButton}/>
                    </div>
                ) : null
            }
            {/* <PrimaryButton isDisabled={false} text={"Next"} onClick={handleNextButton}/>
            <SecondaryButton isDisabled={false} text={"Back"} onClick={handleBackButton} /> */}
        </div>
    )
}

export default CashFlow