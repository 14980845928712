import { useDispatch, useSelector } from "react-redux";
// import UserInformationForm from "../../../website/components/UserInformationForm";
// import { D_Toggle_Website_form } from "../../../features/appSlice";
import WarningOopsPopup from "./WarningOopsPopup";
import appLocals from "../../../localization/localization_en_app.json"
import { useNavigate } from "react-router-dom";

const { description1, description2, left_btn, right_btn } = appLocals.app.restrict_free_trial;

const WarningFreeTrialAccessResource = ({ children }) => {

    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const { subscriptionDetails } = useSelector((state) => state.user)
    const { planStatus } = subscriptionDetails;
    const isFreeSubscription = !["paid", "invited", "internal"].includes(planStatus);
    // const { is_open, api_success_popup_open } = useSelector((state) => state.app.website_form)

    // const handleTogglePopup = () => dispatch(D_Toggle_Website_form({ "is_open": !is_open, form_name: "upgrade_plan" }))

    const handleOnSuccess = () => navigate('/product/home/setting/subscription');

    const handleOnCancel = () => navigate('/pricing');

    return (
        <>
            {/* {
                (is_open || api_success_popup_open)
                    ? <UserInformationForm />
                    : null
            } */}
            <WarningOopsPopup
                leftButton={left_btn}
                rightButton={right_btn}
                description1={description1}
                description2={description2}
                handleOnSuccess={handleOnSuccess}
                handleOnCancel={handleOnCancel}
            >
                {
                    ({ handleTogglePopup }) => {
                        return typeof children === "function" ? children({ handleTogglePopup, isFreeSubscription }) : null
                    }
                }
            </WarningOopsPopup>
        </>
    )
}

export default WarningFreeTrialAccessResource