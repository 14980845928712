import React from 'react'
import { message } from "antd"
import { useSelector, useDispatch } from "react-redux";
import FormLabel from '../CostOnboarding/components/FormLabel'
import ErrorFormNotFilled from '../CostOnboarding/microcomponents/ErrorFormNotFilled'
import HeadingFour from '../../../../DesignSystem/TypographyHeadings/HeadingFour'
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton'
import SecondaryButton from '../../../../DesignSystem/Buttons/SecondaryButton'
import balance_sheet_locals from "../../../../localization/localization_en_pAndL_workCap_debtWork_cashFlow_balanceSheet.json"
import CustomNumber from '../../subcomponents/ROFormComponents/components/CustomNumber'
import EditableTable from '../../subcomponents/common/EditableTable';
import { useNavigate } from "react-router-dom"
import { API_Process_BalanceSheet_Data, API_Save_BalanceSheet_Data, D_Reset_ErrorMessage } from '../../balanceSheetSlice';
import useErrorCheckBMOther from '../../subcomponents/common/useErrorCheckBMOther';
import useOpenOopsPopup from '../../subcomponents/common/useOpenOopsPopup';
import WarningPopup from '../../subcomponents/common/WarningPopup';
import whiteColorTickMark from '../../../../assets/common/whiteColorTickMark.svg';
import { API_MARK_AS_FINAL_BUSINESS_MODAL, D_Update_Mark_As_Final } from '../../businessModalSlice';
import Loader from '../../subcomponents/Loader';
import { D_Reset_Api_Status } from "../../businessModalSlice"
import ConfirmModal from '../../../../DesignSystem/Modals/ConfirmModal';
import bmLocals from '../../../../localization/localization_en_business_modal.json'
import useFetchBMOtherScreen from '../../../../hooks/useFetchBMOtherScreen';
import OutputScreen from '../RevenueOnboarding/OutputScreen';
import PageLoader from '../../../reuseablecomponent/PageLoader';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const FORM_ITEMS = balance_sheet_locals.balance_sheet.form.data

const RenderFormComponent = ({ formItem, balanceSheetData, isShowError }) => {
    const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData);
    const { projection_duration, decimal_places } = useSelector((state) => state.globalSetting.active_global_settings)
    const startyear = Number(revenueOnboardingData.marketing_spend_per_channel_fiscal_year?.split('-')?.[0])
    let data
    const handleOnBlur = () => { }
    let { SingleFormCheck } = useErrorCheckBMOther();
    let form = formItem;
    if(formItem.name==="balance_sheet_working_capital_days_target"){
        const updatedColumnNames = formItem.table.columns.map((col,index) => {
            return {...col, "title": startyear+index}
        })
        const slicedColumn = updatedColumnNames.slice(0, projection_duration);
        form = {...formItem, "table": { ...form.table, "columns": slicedColumn }}
    }
    let isFormFilled = SingleFormCheck({ "formItem": form, "CompleteSliceData": balanceSheetData, projection_duration });
    return (
        <div className='bg-baseGray100 p-[16px] rounded-[12px] relative'>
            <FormLabel label={form.label} isMandatory={form.is_mandatory} tooltip={form.tooltip} />
            {
                form.component_type === "editable_table"
                    ? <EditableTable
                        payloadData={{ "formItem": form, "data": balanceSheetData[form.name], "name": form.name }}
                    />
                    : null
            }
            {(isShowError && isFormFilled) ? <ErrorFormNotFilled /> : null}
        </div>
    )
}

const BalanceSheet = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)
    const VIEW_MODE = MODE_TYPE.view;
    const EDIT_MODE = MODE_TYPE.edit;
    const [traceButtonClick, setTraceButtonClick] = React.useState(null);
    const [isPopupOpen, setIsPopupOpen] = React.useState(false);
    const [markAsFinal, setMarkAsFinal] = React.useState({});
    const is_mark_final_new_or_old = useSelector((state) => state.businessModal.is_mark_final_new_or_old)
    const [warningPopupVarient, setWarningPopupVarient] = React.useState(null);
    const { isModelOpen, handlePopUpClose, handlePopUpOpen } = useOpenOopsPopup();
    const { projection_duration } = useSelector((state) => state.globalSetting.active_global_settings)
    const balanceSheetData = useSelector((state) => state.balanceSheet.balanceSheetStore);
    const POP_UP_LOCALS = bmLocals.business_modal.business_modal.business_modal_list.popup;
    const [isShowError, setIsShowError] = React.useState(false);
    const { OverallFormCheck } = useErrorCheckBMOther();
    const active_BusinessModal_Id = useSelector((state) => state.businessModal.active_BusinessModal_Id)
    const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData);
    const apiStatus = useSelector((state) => state.balanceSheet.balanceSheetStore.save_process_api_status);
    const mark_as_final_api_status = useSelector((state) => state.businessModal.api_status);
    const { fetchTargetScreenData, api_status, activeModeType, isFutureInputPresent } = useFetchBMOtherScreen();
    const [traceButtonType, setTraceButtonType] = React.useState(null);
    const IS_API_CALLING = apiStatus.status === "loading";
    React.useEffect(() => {
        if(api_status==="success"){
            if(activeModeType.view && isFutureInputPresent===false){
                setWarningPopupVarient("future_screen_data_not_found_view");
                handlePopUpOpen();
                return;
            }
            if(traceButtonType==="prev"){
                if(EDIT_MODE){
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/cash-flow`)
                    return;
                }
                navigate('/product/home/business-modal/cash-flow')
            }
        }
    }, [api_status])
    React.useEffect(() => {
        const Status = apiStatus.status
        if(Status === 'failed'){
            handlePopUpOpen();
            if(traceButtonClick==="save") setWarningPopupVarient("save")
            if(traceButtonClick==="process") setWarningPopupVarient("process")
            dispatch(D_Reset_ErrorMessage());
        }
    }, [apiStatus.counter])
    React.useEffect(() => {
        if(mark_as_final_api_status.mark_as_final.status==="success"){
            setIsPopupOpen(true);
            setMarkAsFinal(is_mark_final_new_or_old ? POP_UP_LOCALS.marking_secondary_business_model : POP_UP_LOCALS.marking_start_business_model )
            dispatch(D_Update_Mark_As_Final());
            dispatch(D_Reset_Api_Status({ httpType: "mark_as_final" }))
        }
        if(mark_as_final_api_status.mark_as_final.status==="failed"){
            message.open({ type: 'warning', content: mark_as_final_api_status.mark_as_final.message, duration: 3});
            dispatch(D_Reset_Api_Status({ httpType: "mark_as_final" }))
        }
    }, [mark_as_final_api_status.mark_as_final.status])
    const handleOnSaveButton = () => {
        setIsShowError(true);
        setTraceButtonClick("save");
        const status = OverallFormCheck({ "overAll_form": FORM_ITEMS, "CompleteSliceData": balanceSheetData, projection_duration });
        if(status){
            dispatch(API_Save_BalanceSheet_Data({ "businessModelId": active_BusinessModal_Id, "screenInput": {...balanceSheetData, "fiscalStartYear": Number(revenueOnboardingData?.marketing_spend_per_channel_fiscal_year?.split('-')?.[0])} }))
        }else{
            console.log("form not filled...");
        }
    }
    const handleProcessButton = () => {
        setTraceButtonClick("process");
        dispatch(API_Process_BalanceSheet_Data({ "businessModelId": active_BusinessModal_Id, "screenInput": {...balanceSheetData, "fiscalStartYear": Number(revenueOnboardingData?.marketing_spend_per_channel_fiscal_year?.split('-')?.[0])} }))
    }
    const handleBackButton = () => {
        fetchTargetScreenData({ screenName: "cashFlowScreen", previousScreen: "balanceSheetScreen" });
        setTraceButtonType("prev");
    }
    const handleNextButton = () => {
        navigate(`/product/home/business-modal`)
    }
    const isSaveButtonDisabled = balanceSheetData.isSaveDisabled
    const isGenerateOutputDisabled = balanceSheetData.isGenerateOutputDisabled
    const TopBackButtonDisabled = balanceSheetData.isSaveDisabled===false && balanceSheetData.isGenerateOutputDisabled===false
    return (
        <div className='mt-[5rem] flex flex-col gap-y-[24px]'>
            {mark_as_final_api_status.mark_as_final.status==="loading" || api_status==="loading" ? <PageLoader/> : null}
            {
                isPopupOpen 
                ? (
                    <ConfirmModal 
                        isOpen={isPopupOpen} 
                        onConfirm={() => navigate('/product/home/business-modal/my-dashboard')} 
                        onCancel={() => setIsPopupOpen(false)} 
                        title={markAsFinal.title} 
                        description1={markAsFinal.description1} 
                        description2={markAsFinal.description2}
                        confirmButtonName={markAsFinal.confirm_button_name} 
                    />
                ) : null
            }
            <WarningPopup warningType={warningPopupVarient} isModelOpen={isModelOpen} handlePopUpClose={handlePopUpClose} />
            <HeadingFour text={balance_sheet_locals.balance_sheet.title} />
            <ul className='flex flex-col gap-y-[24px]'>
                {
                    FORM_ITEMS.map((formItem, index) => {
                        return (
                            <ComponentErrorBoundary>
                                <RenderFormComponent isShowError={isShowError} key={index} formItem={formItem} balanceSheetData={balanceSheetData} />
                            </ComponentErrorBoundary>
                        )
                    })
                }
            </ul>
            <div className='flex justify-between'>
                <div style={{ "visibility": !!balanceSheetData.output ? "hidden" : "visible" }}>
                    <SecondaryButton isDisabled={IS_API_CALLING} text={"Back"} onClick={handleBackButton} />
                </div>
                <PrimaryButton isLoading={IS_API_CALLING && traceButtonClick==="save"} isDisabled={isSaveButtonDisabled || IS_API_CALLING} text={"Save"} onClick={handleOnSaveButton} />
                <PrimaryButton isLoading={IS_API_CALLING && traceButtonClick==="process"} isDisabled={isGenerateOutputDisabled || !isSaveButtonDisabled || IS_API_CALLING} text={"Generate Output"} onClick={handleProcessButton} />
            </div>
            <div style={{ "visibility": balanceSheetData.output===null ? "hidden" : "visible" }} >
                <ComponentErrorBoundary>
                    <OutputScreen
                        outputData={balanceSheetData.output}
                        type={"balanceSheetScreen"}
                    />
                </ComponentErrorBoundary>
            </div>
            {
                balanceSheetData.output!==null
                ? (
                    <div className='flex justify-between'>
                        <SecondaryButton isDisabled={false} text={"Back"} onClick={handleBackButton} />
                        <PrimaryButton isIcon icon={whiteColorTickMark} isDisabled={false} text={"Mark As Final"} onClick={() => dispatch(API_MARK_AS_FINAL_BUSINESS_MODAL(active_BusinessModal_Id))} />
                        <PrimaryButton isDisabled={false} text={"Next"} onClick={handleNextButton} />
                    </div>
                ) : null
            }
            {/* <PrimaryButton isDisabled={false} text={"Next"} onClick={handleNextButton} />
            <SecondaryButton isDisabled={false} text={"Back"} onClick={handleBackButton} /> */}
        </div>
    )
}

export default BalanceSheet