//library
import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Table, DatePicker } from 'antd';
//assets
import DeleteImg from "../../../../assets/common/Delete.svg"
import plusImg from "../../../../assets/common/plusPrimary.svg"
//components
import { TableToolTip } from '../ROFormComponents/components/TableColumnHeading';
import TableColumnHeading from '../ROFormComponents/components/TableColumnHeading';
import RenderComponent from './RenderComponent';
import { D_Add_Row_DebtWorking, D_Remove_Row_DebtWorking } from "../../debtWorkingSlice";
import { D_Add_Row_CashFlow, D_Remove_Row_CashFlow } from "../../cashFlowSlice";


const EditableTable = ({ payloadData }) => {
    const dispatch = useDispatch()
    const FORM_ITEM = payloadData.formItem;
    const FORM_DATA = payloadData.data;
    const FORM_NAME = payloadData.name;
    const IS_DELETE_ROWS = FORM_ITEM?.table?.is_delete_row;
    const TABLE_DATA = FORM_DATA;
    const fiscal_year = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.marketing_spend_per_channel_fiscal_year);
    const decimalPlace = useSelector((state) => state.globalSetting.active_global_settings.decimal_places)
    const VIEW_MODE = useSelector((state) => state.businessModal.MODE_TYPE.view)
    let transformedData = [];

    const handleAddDeleteRow = ({ type, rowNumber }) => {
        if(FORM_DATA.length >= FORM_ITEM?.table.max_no_row ){
            alert(); // work on adding max limitation.
            return;
        }
        switch(FORM_ITEM?.module){
            case 'cash_flow': {
                if(type==="add"){ dispatch(D_Add_Row_CashFlow({ "tableName": FORM_ITEM.name }));return; }
                dispatch(D_Remove_Row_CashFlow({ "tableName": FORM_ITEM.name, rowNumber }))
                break;
            }
            case 'debt_working': {
                if(type==="add"){ dispatch(D_Add_Row_DebtWorking({ "tableName":FORM_ITEM.name}));return; }
                dispatch(D_Remove_Row_DebtWorking({ "tableName": FORM_ITEM.name, rowNumber }))
                break;
            }
        }
    }
    const TABLE_COLUMNS = FORM_ITEM?.table?.columns?.map((column, columnIndex) => {
        return (
            {
                "fixed": IS_DELETE_ROWS ? ((columnIndex === 0 || columnIndex === 1) ? 'left' : '') : (columnIndex === 0 ? 'left' : ''),
                "title": <TableColumnHeading isMandatory={column.is_mandatory} title={column.title} tooltip={column.tooltip} />,
                "dataIndex": column?.data_index || 250,
                "width": column.width,
                "render": (value, record, rowIndex) => {
                    const tooltip = FORM_ITEM?.table?.tooltip?.[value];
                    let isReadOnly = false
                    if("loan_name" in record || "equity_investment_amount" in record){
                        if(!((!!record["loan_name"]) || (!!record["equity_investment_amount"]))){
                            isReadOnly = columnIndex===1 ? false : true;
                        }
                    }
                    if (IS_DELETE_ROWS && columnIndex === 0) {
                        return (
                            <div className='flex justify-center items-center bg-white h-[3rem]'>
                                <button onClick={() => handleAddDeleteRow({ "type": "remove", "rowNumber": rowIndex })}><img src={DeleteImg} alt="" /></button>
                            </div>
                        )
                    }
                    return (
                        <div className='flex justify-between items-center px-[8px] bg-white py-[0.8rem] w-[calc(100%-1%)] h-[calc(100%-1px)] mb-[1px]'>
                            <RenderComponent
                                isReadOnly = {isReadOnly}
                                fiscal_year={fiscal_year}
                                column={column}
                                value={value}
                                module={FORM_ITEM?.module}
                                component_type={column.component_type}
                                decimal_place={decimalPlace}
                                tableName={FORM_ITEM.name}
                                rowIndex={rowIndex}
                                data_index={column.data_index}
                                columnIndex={columnIndex}
                                validator={column.validator}
                                month_year={payloadData?.month_year}
                            />
                            {
                                !!tooltip
                                    ? (<div className='w-[18px] aspect-square ml-[8px]'>
                                        <TableToolTip tooltip={tooltip} />
                                    </div>)
                                    : null
                            }
                        </div>
                    )
                }
            }
        )
    })
    const totalColumnWidth = TABLE_COLUMNS?.reduce((total, column) => total + (column.width || 0), 0);
    return (
        <div>
            <div>
                <Table
                    columns={TABLE_COLUMNS}
                    dataSource={TABLE_DATA}
                    bordered
                    scroll={{
                        x: totalColumnWidth,
                    }}
                    className='normal_input_table'
                    pagination={{
                        pageSize: transformedData?.length,
                        position: ['none', 'none'],
                    }}
                />
            </div>
            {
                FORM_ITEM?.table.is_add_row ? (<div className='flex justify-center mt-[8px]'>
                    <button disabled={VIEW_MODE} onClick={() => handleAddDeleteRow({ "type": "add" })} className='flex items-center gap-x-2 text-primary'><img className='w-[1.5rem] aspect-square' src={plusImg} alt="" />{FORM_ITEM?.table?.add_btn_name}</button>
                </div>)
                    : null
            }
        </div>
    )
}

export default EditableTable