import React from 'react'
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import FormLabel from '../CostOnboarding/components/FormLabel'
import ErrorFormNotFilled from '../CostOnboarding/microcomponents/ErrorFormNotFilled'
import HeadingFour from '../../../../DesignSystem/TypographyHeadings/HeadingFour'
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton'
import SecondaryButton from '../../../../DesignSystem/Buttons/SecondaryButton'
import workingCaplocals from '../../../../localization/localization_en_pAndL_workCap_debtWork_cashFlow_balanceSheet.json'
import CustomNumber from '../../subcomponents/ROFormComponents/components/CustomNumber'
import EditableTable from '../../subcomponents/common/EditableTable';
import { useNavigate } from "react-router-dom"
import { ValidateNumber } from '../CostOnboarding/utils/ValidateNumber';
import { API_Save_WorkingCapital_Data, D_Capture_Normal_Input_Value_WorkingCapital, D_ShowSaveActiveButton, D_Reset_ErrorMessage, API_Process_WorkingCapital_Data } from '../../workingCapitalSlice';
import useErrorCheckBMOther from '../../subcomponents/common/useErrorCheckBMOther';
import PayloadPreparationBMOther from '../../subcomponents/common/PayloadPreparationBMOther';
import useOpenOopsPopup from '../../subcomponents/common/useOpenOopsPopup';
import WarningPopup from '../../subcomponents/common/WarningPopup';
import useFetchBMOtherScreen from '../../../../hooks/useFetchBMOtherScreen';
import PageLoader from '../../../reuseablecomponent/PageLoader';
import OutputScreen from '../RevenueOnboarding/OutputScreen';
import appLocals from '../../../../localization/localization_en_app.json'
import business_model_locals from "../../../../localization/localization_en_business_modal.json"
import { numberValidation } from '../../../../utils/numberValidation';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const FORM_ITEMS = workingCaplocals.working_capital.form.data
const Warning_Message = business_model_locals.business_modal.warning_tooltip;
const Validation_Warning_Message = appLocals.app.warning_message

const RenderFormComponent = ({ formItem, workingCapitalData, isShowError }) => {
    const dispatch = useDispatch();
    const { projection_duration, decimal_places } = useSelector((state) => state.globalSetting.active_global_settings)
    const { annual_inventory_days_product_category, fiscalStartYear, product_category, monthYearValues } = useSelector((state) => state.workingCapital.workingCapStore)
    let data, updatedFormItem = formItem;
    if (formItem.component_type === "editable_table") {
        data = workingCapitalData[formItem.name]
    }
    const handleOnBlur = (event) => {
        const { status, value } = ValidateNumber({ "validation_type": formItem.validator, "value": event.target.value, "decimal_place": decimal_places })
        console.log(status,"status")
        if (status && event.target.value) {
            const percentageCheck = numberValidation({ value: String(value), placeHolder: formItem.placeHolder, "validator": formItem.validator, "isStrictPercentageCheck": false })
            if(!percentageCheck.status && event.target.value){
                message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                // alert();
                return;
            }
            dispatch(D_Capture_Normal_Input_Value_WorkingCapital({ "name": formItem.name, value: Number(value) }))
            return;
        }
        if(event.target.value){
            dispatch(D_Capture_Normal_Input_Value_WorkingCapital({ "name": formItem.name, "value": null }))
            message.open({ type: 'warning', content: Validation_Warning_Message.positive_number, duration: 3}); return;
        }
    }
    if (formItem.name === "net_payment_term_customer") {
        data = workingCapitalData[formItem.name];
    } else if (formItem.name === "annual_inventory_days_product_category") {
        data = annual_inventory_days_product_category;
        updatedFormItem = { ...formItem, "table": { "columns": [formItem.table.columns[0], ...Array.from({ length: monthYearValues?.length }).map((_, i) => ({...formItem.table.columns[1], "data_index": `year${i+1}`, "title": monthYearValues[i]}))] } }
    }
    //{ "columns": formItem.table.columns.map((col, index) => (index > 0 ? { ...col, "title": Number(fiscalStartYear) + index - 1 } : { ...col, "select_options": product_category })) }
    if (data?.length === 0 && formItem.name === "net_payment_term_customer") return;
    if (formItem.name === "annual_inventory_days_product_category") {
    } else {
        updatedFormItem = updatedFormItem;
    }

    let { SingleFormCheck } = useErrorCheckBMOther();
    let isFormFilled = SingleFormCheck({ "formItem": updatedFormItem, "CompleteSliceData": workingCapitalData, "RevenueSliceData_Prod_Catg": data, "projection_duration": monthYearValues?.length });

    return (
        <div className='bg-baseGray100 p-[16px] rounded-[12px] relative'>
            {/* <pre>{JSON.stringify(updatedFormItem, null, 2)}</pre> */}
            <FormLabel label={formItem.label} isMandatory={formItem.is_mandatory} tooltip={formItem.tooltip} />
            {formItem.component_type === "number_input" ? <div className='w-[100px]'> <CustomNumber name={formItem.name} value={workingCapitalData[formItem.name]} handleOnBlur={handleOnBlur} isBordered={true} isReadOnly={false} placeholder={formItem.placeHolder} borderColor='#BECEFF' /></div> : null}
            {formItem.component_type === "editable_table" ? <EditableTable payloadData={{ formItem: updatedFormItem, "data": data, "name": updatedFormItem.name }} /> : null}
            {(isShowError && isFormFilled) ? <ErrorFormNotFilled /> : null}
        </div>
    )
}

const WorkingCapital = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)
    const VIEW_MODE = MODE_TYPE.view;
    const EDIT_MODE = MODE_TYPE.edit;
    const workingCapitalData = useSelector((state) => state.workingCapital.workingCapStore)
    const [traceButtonClick, setTraceButtonClick] = React.useState(null);
    const [warningPopupVarient, setWarningPopupVarient] = React.useState(null);
    const { isModelOpen, handlePopUpClose, handlePopUpOpen } = useOpenOopsPopup();
    const [isShowError, setIsShowError] = React.useState(false);
    const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData);
    const { projection_duration } = useSelector((state) => state.globalSetting.active_global_settings)
    const product_category = workingCapitalData.product_category;
    const active_BusinessModal_Id = useSelector((state) => state.businessModal.active_BusinessModal_Id)
    const { OverallFormCheck } = useErrorCheckBMOther();
    const apiStatus = useSelector((state) => state.workingCapital.workingCapStore.save_process_api_status);
    const { fetchTargetScreenData, api_status, activeModeType, isFutureInputPresent } = useFetchBMOtherScreen();
    const [traceButtonType, setTraceButtonType] = React.useState(null);
    const IS_API_CALLING = apiStatus.status === "loading";
    React.useEffect(() => {
        if (api_status === "success") {
            if(activeModeType.view && isFutureInputPresent===false){
                setWarningPopupVarient("future_screen_data_not_found_view");
                handlePopUpOpen();
                return;
            }
            if (traceButtonType === "prev") {
                if (EDIT_MODE) {
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/p&l`)
                    return;
                }
                navigate('/product/home/business-modal/p&l')
            } else {
                if (EDIT_MODE) {
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/cash-flow`)
                    return;
                }
                navigate('/product/home/business-modal/cash-flow')
            }
        }
    }, [api_status])
    React.useEffect(() => {
        const Status = apiStatus.status
        if (Status === 'failed') {
            handlePopUpOpen();
            if (traceButtonClick === "save") setWarningPopupVarient("save")
            if (traceButtonClick === "process") setWarningPopupVarient("process")
            dispatch(D_Reset_ErrorMessage());
        }
    }, [apiStatus.counter])
    const handleOnSaveButton = () => {
        setIsShowError(true);
        setTraceButtonClick("save");
        const status = OverallFormCheck({ "overAll_form": FORM_ITEMS, "CompleteSliceData": workingCapitalData, "RevenueSliceData_Prod_Catg": workingCapitalData.annual_inventory_days_product_category, "projection_duration": workingCapitalData?.monthYearValues?.length, "monthYearValues": workingCapitalData?.monthYearValues });
        if (status) {
            const annual_inventory_days_product_category = PayloadPreparationBMOther({ "data": workingCapitalData.annual_inventory_days_product_category, "targetFormName": "annual_inventory_days_product_category", product_category, "fiscal_year": workingCapitalData.fiscalStartYear })
            let otherAttribute = JSON.parse(JSON.stringify(workingCapitalData));
            delete otherAttribute.annual_inventory_days_product_category;
            const { net_payment_term_customer, percent_revenue_other_current_assets, net_payment_term_vendor, percent_payroll_short_long_term_probation } = otherAttribute;
            dispatch(API_Save_WorkingCapital_Data({
                "businessModelId": active_BusinessModal_Id,
                "screenInput": {
                    annual_inventory_days_product_category,
                    net_payment_term_customer,
                    percent_revenue_other_current_assets,
                    net_payment_term_vendor,
                    percent_payroll_short_long_term_probation
                }
            }))
        } else {
            console.log("form not filled...");
        }
    }
    const handleProcessButton = () => {
        setTraceButtonClick("process");
        const annual_inventory_days_product_category = PayloadPreparationBMOther({ "data": workingCapitalData.annual_inventory_days_product_category, "targetFormName": "annual_inventory_days_product_category", product_category, "fiscal_year": workingCapitalData.fiscalStartYear })
        let otherAttribute = JSON.parse(JSON.stringify(workingCapitalData));
        delete otherAttribute.annual_inventory_days_product_category;
        const { net_payment_term_customer, percent_revenue_other_current_assets, net_payment_term_vendor, percent_payroll_short_long_term_probation } = otherAttribute;
        dispatch(API_Process_WorkingCapital_Data({
            "businessModelId": active_BusinessModal_Id,
            "screenInput": {
                annual_inventory_days_product_category,
                net_payment_term_customer,
                percent_revenue_other_current_assets,
                net_payment_term_vendor,
                percent_payroll_short_long_term_probation
            }
        }))
    }
    const handleBackButton = () => {
        fetchTargetScreenData({ screenName: "pnlScreen", previousScreen: "workingCapitalScreen" });
        setTraceButtonType("prev");
    }
    const handleNextButton = () => {
        fetchTargetScreenData({ screenName: "cashFlowScreen", previousScreen: "workingCapitalScreen" });
        setTraceButtonType("next");
    }
    const isSaveButtonDisabled = workingCapitalData.isSaveDisabled
    const isGenerateOutputDisabled = workingCapitalData.isGenerateOutputDisabled
    const TopBackButtonDisabled = workingCapitalData.isSaveDisabled === false && workingCapitalData.isGenerateOutputDisabled === false
    return (
        <div className='mt-[5rem] flex flex-col gap-y-[24px]'>
            <WarningPopup warningType={warningPopupVarient} isModelOpen={isModelOpen} handlePopUpClose={handlePopUpClose} />
            <HeadingFour text={workingCaplocals.working_capital.title} />
            { api_status==="loading" ? <PageLoader/> : null }
            <ul className='flex flex-col gap-y-[24px]'>
                {
                    FORM_ITEMS.map((formItem, index) => {
                        return (
                            <ComponentErrorBoundary>
                                <RenderFormComponent key={index} formItem={formItem} isShowError={isShowError} workingCapitalData={workingCapitalData} />
                            </ComponentErrorBoundary>
                        )
                    })
                }
            </ul>
            <div className='flex justify-between'>
                <div style={{ visibility: !!workingCapitalData.output ? "hidden": "visible"}}>
                    <SecondaryButton isDisabled={IS_API_CALLING} onClick={handleBackButton} text={"Back"} />
                </div>
                <PrimaryButton isLoading={IS_API_CALLING && traceButtonClick === "save"} isDisabled={isSaveButtonDisabled || IS_API_CALLING} text={"Save"} onClick={handleOnSaveButton} />
                <PrimaryButton isLoading={IS_API_CALLING && traceButtonClick === "process"} isDisabled={!isSaveButtonDisabled || isGenerateOutputDisabled || IS_API_CALLING} text={"Generate Output"} onClick={handleProcessButton} />
            </div>
            <div style={{ "visibility": workingCapitalData.output === null ? "hidden" : "visible" }} >
                <ComponentErrorBoundary>
                    <OutputScreen
                        category_list={workingCapitalData?.categoryList}
                        type={"workingCapital"}
                        outputData={workingCapitalData.output}
                    />
                </ComponentErrorBoundary>
            </div>
            <div style={{ "visibility": workingCapitalData.output === null ? "hidden" : "visible" }} className='flex justify-between'>
                <SecondaryButton isDisabled={IS_API_CALLING} text={"Back"} onClick={handleBackButton} />
                <PrimaryButton isDisabled={IS_API_CALLING} text={"Next"} onClick={handleNextButton} />
            </div>
            {/* <SecondaryButton isDisabled={IS_API_CALLING} onClick={handleBackButton} text={"Back"} />
            <PrimaryButton isDisabled={false} text={"Next"} onClick={handleNextButton} /> */}
        </div>
    )
}

export default WorkingCapital