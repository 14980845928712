import React from 'react'
import FormLabel from './FormLabel'
import GenerateForm from './GenerateForm'
import {useDispatch, useSelector} from "react-redux"
//redux actions
import { D_Capture_CheckBox_Data } from "../../../costSlice"
//utility
import ErrorFormNotFilled from '../microcomponents/ErrorFormNotFilled'
import { ValidateFormScreen } from '../utils/ValidateFormScreen'
import { ComponentPayloadPreparation } from '../utils/ComponentPayloadPreparation'
import ComponentErrorBoundary from '../../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary'


const DefineForm = ({ formItem, index }) => {
    const dispatch = useDispatch();
    const costScreenData = useSelector((state) => state.costScreen);
    const { view, edit } =  useSelector((state) => state.businessModal.MODE_TYPE)
    const category_list_data = useSelector((state) => state.costScreen.category_list_data)
    const handleCheckBoxOnChange = ({ key, value }) => dispatch(D_Capture_CheckBox_Data({ "name": key, value }))
    let payloadData = ComponentPayloadPreparation({ index, formItem, costScreenData, handleCheckBoxOnChange, category_list_data, isViewMode: view, isEditMode: edit  });
    let isFormNotFilled;
    if(costScreenData.isShowErrorComponent){
        const validation_Form_Data = "checkBoxProps" in payloadData ? { "data": payloadData.checkBoxProps.data, "formItem": formItem } : payloadData;
        isFormNotFilled = ValidateFormScreen({ "singleInputData": { "name": payloadData?.inputProps?.name, "value": payloadData?.inputProps?.value }, "formItem": validation_Form_Data?.formItem, "data": validation_Form_Data.data, "other": { marketing_spend_span_type: costScreenData.marketing_spend_span_type } });
    }
    return (
        <div className='bg-baseGray100 p-[16px] rounded-[12px] relative'>
            <FormLabel label={formItem.label} isMandatory={formItem.is_mandatory} tooltip={formItem.tooltip} />
            <ComponentErrorBoundary>
                <GenerateForm type={formItem.component_type} payloadData={payloadData}/>
            </ComponentErrorBoundary>
            { (isFormNotFilled && costScreenData.isShowErrorComponent) ? <ErrorFormNotFilled/> : null }
        </div>
    )
}

export default DefineForm