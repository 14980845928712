import React, { useState } from 'react'
import HeadingThree from '../../../DesignSystem/TypographyHeadings/HeadingThree'
import classNames from 'classnames'
import ResponsiveHeadingFourInter from '../../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter'
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const TitleDescription = ({ title, description, className, handleOnClick, isClampText }) => {

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className={`${className} flex flex-col gap-y-[12px]`}>
            <div className='flex items-center gap-x-[0.5rem]'>
                <button onClick={handleOnClick} className='text-start' onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <ResponsiveHeadingFourInter text={title} className={classNames(
                        "text-[#111827]",
                        { 'hover:text-p3 cursor-pointer': handleOnClick },
                        { 'cursor-text': !handleOnClick }

                    )} headingCSSStyles={{ margin: 0 }} />
                </button>
                {/* {
                    isHovered
                    ? <ArrowForwardIcon style={{ fontSize: "20px" }} className='text-primary' />
                    : null
                } */}
            </div>
            <TextMedium className={'leading-10'} text={description} fontWeight={400} />
        </div>
    )
}

export default TitleDescription