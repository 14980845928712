import React from 'react'
//library
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
//components
import StaticText from '../microcomponents/StaticText';
import { TableToolTip } from '../../../subcomponents/ROFormComponents/components/TableColumnHeading';
import TextLarge from '../../../../../DesignSystem/TypographyDescriptions/TextLarge';
import CustomStyleTextArea from '../../../../../DesignSystem/Forms/InputVarients/CustomStyleTextArea';
import CustomNumber from '../../../subcomponents/ROFormComponents/components/CustomNumber';
import SelectOption from '../../../../../DesignSystem/Forms/SelectOption';
import tickMark from "../../../../../assets/competitoranalysis/checkMark.svg";
import { numberValidation } from '../../../../../utils/numberValidation';
import appLocals from '../../../../../localization/localization_en_app.json'
//redux actions
import { D_Capture_Value } from "../../../costSlice";
//utility
import { ValidateNumber } from '../utils/ValidateNumber';

const Validation_Warning_Message = appLocals.app.warning_message

const ShowWarningTooltip = (warningTooltip) => {
    message.warning(warningTooltip, 1)
}

const RenderTableInputComponent = (props) => {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const { cellDetail } = props || {}
    const decimalPlace = useSelector((state) => state.globalSetting.active_global_settings.decimal_places)
    const {
        name, value, data_index, validator, isReadOnly, isDisabled, isReadyOnly, onBlurEvent, onChangeEvent, handleOnClickCheckBox, VIEW_MODE,record,noDataTagContent,
        tableName, is_mandatory, placeHolder, options, cellColor, visibilityType = "visible", borderColor, custom_tag_input_tooltip, rowIndex, columnIndex, warningTooltip, isSingleInput = false
    } = props || {};
    switch (cellDetail?.component_type) {
        case 'static_textarea': {
            return (
                <StaticText cellColor={cellColor} placeHolder={placeHolder} value={value} visibilityType={visibilityType} is_mandatory={is_mandatory} />
            )
        }
        case 'dynamic_textarea': {
            return (
                <CustomStyleTextArea
                    name={data_index}
                    value={value}
                    placeHolder={placeHolder}
                    isDisabled={isDisabled || VIEW_MODE}
                    isReadyOnly={isReadyOnly}
                    onBlurEvent={onBlurEvent}
                    onChangeEvent={onChangeEvent}
                />
            )
        };
        case 'number_input': {
            return (
                <>
                {contextHolder}
                <CustomNumber
                    borderColor={borderColor}
                    constrain={validator}
                    isReadOnly={false || VIEW_MODE || record?.record?.name===null}
                    isDisabled={VIEW_MODE || isReadOnly}
                    name={data_index}
                    performActionOnClick={() => ShowWarningTooltip(warningTooltip)}
                    placeholder={placeHolder}
                    isStrictPercentageCheck={record?.column?.isStrictPercentageCheck}
                    value={value}
                    handleOnBlur={(event) => {
                        const numberValue = event.target.value;
                        if (["positive_decimal_number", "positive_negative_decimal_number", "positive_number","positive_number_min_1_max_12","positive_number_min_1"].includes(validator)) {
                            message.destroy()
                            const Validation_Status = ValidateNumber({ "validation_type": validator, "value": numberValue, "decimal_place": decimalPlace });
                            if (Validation_Status.status) {
                                const percentageCheck = numberValidation({ value: String(Validation_Status.value), placeHolder, validator,"isStrictPercentageCheck": record?.column?.isStrictPercentageCheck || record?.record?.name==="% MAU" || data_index==="varying_cost_over_time" || record?.record?.method_of_calculation==="% Of Revenue" })
                                if(!percentageCheck.status){
                                    message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                    return;
                                }
                                const tableInputOrNormalInput = isSingleInput
                                    ? { "name": data_index, "value": numberValue }
                                    : { "tableName": tableName, "value": Number(Validation_Status.value), "rowNumber": rowIndex, "data_index": data_index, "dbid": record.record.dbid }
                                dispatch(D_Capture_Value(tableInputOrNormalInput))
                            } else {
                                const tableInputOrNormalInput = isSingleInput
                                    ? { "name": data_index, "value": null }
                                    : { "tableName": tableName, "value": null, "rowNumber": rowIndex, "data_index": data_index, "dbid": record.record.dbid  }
                                dispatch(D_Capture_Value(tableInputOrNormalInput))
                                const percentageCheck = numberValidation({ value: String(Validation_Status.value), placeHolder, validator,"isStrictPercentageCheck": record?.column?.isStrictPercentageCheck || record?.record?.name==="% MAU" || data_index==="varying_cost_over_time" || record?.record?.method_of_calculation==="% Of Revenue" })
                                if(!percentageCheck.status && event.target.value){
                                    message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                    return;
                                }
                                if(decimalPlace===0){
                                    switch(validator){
                                        case 'positive_decimal_number': {
                                            message.open({ type: 'warning', content: Validation_Warning_Message.positive_number, duration: 3}); return;
                                        }
                                    }
                                }
                                if(event.target.value){
                                    validator && Validation_Warning_Message?.[validator] && message.open({ type: 'warning', content: Validation_Warning_Message[validator], duration: 3});
                                }
                            }
                        }
                    }}
                />
                </>
            )
        };
        case 'dropdown': {
            const SORTED_LIST = columnIndex===1 ? options?.sort((a,b) => a?.label?.localeCompare(b?.label)) || options : options;
            return (
                <div className='w-full'>
                    {
                        !!value && columnIndex === 1
                            ? <StaticText value={value} />
                            : <SelectOption
                                isDisabled={VIEW_MODE ? true : (isDisabled || (columnIndex > 1 ? record.record.name===null : false))}
                                value={value}
                                notFoundContent={columnIndex===1 ? noDataTagContent : null}
                                placeholder={placeHolder}
                                listOfOptions={SORTED_LIST}
                                onChangeEvent={(value) => {
                                    dispatch(D_Capture_Value({ "tableName": tableName, "value": value, "rowNumber": rowIndex, "data_index": data_index, "id": SORTED_LIST.find((option) => option.label===value).id }));
                                }}
                            />
                    }
                </div>
            )
        }
        case 'inhouse_check_box': {
            return (
                <button
                    disabled={VIEW_MODE}
                    onClick={() => record.record.name === null ? ShowWarningTooltip(warningTooltip) : dispatch(D_Capture_Value({ "tableName": tableName, "value": !value, "rowNumber": rowIndex, "data_index": data_index }))}
                    className='w-[2rem] border rounded-md border-baseGray800 mx-auto aspect-square flex items-center justify-center'
                >
                    {value ? <img src={tickMark} className='absolute w-[2.7rem]' alt="" /> : null}
                </button>
            )
        }
        case 'custom_tag_input_tooltip': {
            const isTagArray = Array.isArray(custom_tag_input_tooltip);
            return (
                <div className='flex flex-col items-center'>
                    {
                        Array.from({ length: isTagArray ? custom_tag_input_tooltip.length : 1 }, (_, index) => {
                            let skeletonInput;
                            let targetFieldNameForArrayTag = isTagArray ? custom_tag_input_tooltip[index].tag.split(' ').join('') : null;
                            let updatedFieldNamewithNoSpecialChar = isTagArray ? targetFieldNameForArrayTag.replace('%', 'percent') : null;
                            skeletonInput = isTagArray 
                                ? { "tag": custom_tag_input_tooltip[index].tag, "placeHolder": custom_tag_input_tooltip[index].placeHolder, "value": value?.[updatedFieldNamewithNoSpecialChar], "tooltip": custom_tag_input_tooltip[index].tooltip } 
                                : { "tag": custom_tag_input_tooltip?.tag, "placeHolder": custom_tag_input_tooltip?.placeHolder, "value": custom_tag_input_tooltip?.value, "tooltip": custom_tag_input_tooltip?.tooltip }
                            const readyOnlyBasedOnDepsInCostTable = record.record.name === "Overhead Cost" ? record.record.calculation_methodology==="% Of Lease" && updatedFieldNamewithNoSpecialChar==="Amount" && (!!record.leaseRowData?.office) : false;
                            return (
                                <div className='flex items-center' key={index}>
                                    {
                                        skeletonInput?.tag
                                            ? <span className='min-w-[149px] text-p4 border border-p7 rounded-[8px] leading-5 bg-p9 px-[12px]'>{skeletonInput?.tag}</span>
                                            : null
                                    }
                                    <div>
                                        <CustomNumber
                                            borderColor={borderColor}
                                            constrain={validator}
                                            isReadOnly={false || isReadOnly || VIEW_MODE || readyOnlyBasedOnDepsInCostTable || record.record.name === null}
                                            isDisabled={VIEW_MODE}
                                            performActionOnClick={() => ShowWarningTooltip(record?.record?.name ? Validation_Warning_Message.lease_amount_read_only : warningTooltip)}
                                            name={name}
                                            placeholder={ decimalPlace===2 ? skeletonInput?.placeHolder : skeletonInput?.placeHolder.includes('.') ? (skeletonInput?.placeHolder.includes('%') ? skeletonInput?.placeHolder.split('.')[0]+'%' : skeletonInput?.placeHolder.split('.')[0] ) : skeletonInput?.placeHolder}
                                            value={readyOnlyBasedOnDepsInCostTable ? record?.leaseRowData?.office : skeletonInput?.value}
                                            handleOnBlur={(event) => {
                                                const numberValue = event.target.value;
                                                message.destroy()
                                                if (["positive_decimal_number", "positive_negative_decimal_number", "positive_number"].includes(isTagArray ? validator[targetFieldNameForArrayTag] : validator)) {
                                                    const Validation_Status = ValidateNumber({ "validation_type": isTagArray ? validator[targetFieldNameForArrayTag] : validator, "value": numberValue, "decimal_place": decimalPlace });
                                                    if (Validation_Status.status) {
                                                        const percentageCheck = numberValidation({ value: String(event.target.value), placeHolder: skeletonInput?.placeHolder, "validator": isTagArray ? validator[targetFieldNameForArrayTag] : validator,"isStrictPercentageCheck": record?.column?.isStrictPercentageCheck})
                                                        if(!percentageCheck.status){
                                                            message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                                            return;
                                                        }
                                                        dispatch(D_Capture_Value({ "tableName": tableName, "value": Number(Validation_Status.value), "rowNumber": rowIndex, "data_index": data_index, "nested_index": isTagArray ? updatedFieldNamewithNoSpecialChar : null }))
                                                    } else {
                                                        if(event.target.value){
                                                            if(["%OfLease","Amount"].includes(targetFieldNameForArrayTag)){
                                                                const percentageCheck = numberValidation({ value: String(event.target.value), placeHolder: decimalPlace===2 ? skeletonInput?.placeHolder : skeletonInput?.placeHolder.includes('.') ? (skeletonInput?.placeHolder.includes('%') ? skeletonInput?.placeHolder.split('.')[0]+'%' : skeletonInput?.placeHolder.split('.')[0] ) : skeletonInput?.placeHolder, "validator": isTagArray ? validator[targetFieldNameForArrayTag] : validator,"isStrictPercentageCheck": record?.column?.isStrictPercentageCheck})
                                                                if(!percentageCheck.status){
                                                                    message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                                                    return;
                                                                }
                                                                if(decimalPlace===0){
                                                                    switch(validator){
                                                                        case 'positive_decimal_number': {
                                                                            message.open({ type: 'warning', content: Validation_Warning_Message.positive_number, duration: 3}); return;
                                                                        }
                                                                    }
                                                                }
                                                                validator && message.open({ type: 'warning', content: Validation_Warning_Message["positive_decimal_number"], duration: 3});
                                                                return;
                                                            }
                                                            const percentageCheck = numberValidation({ value: String(event.target.value), placeHolder: decimalPlace===2 ? skeletonInput?.placeHolder : skeletonInput?.placeHolder.includes('.') ? (skeletonInput?.placeHolder.includes('%') ? skeletonInput?.placeHolder.split('.')[0]+'%' : skeletonInput?.placeHolder.split('.')[0] ) : skeletonInput?.placeHolder, "validator": isTagArray ? validator[targetFieldNameForArrayTag] : validator,"isStrictPercentageCheck": record?.column?.isStrictPercentageCheck})
                                                                if(!percentageCheck.status){
                                                                    message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                                                    return;
                                                                }
                                                            if(decimalPlace===0){
                                                                switch(validator){
                                                                    case 'positive_decimal_number': {
                                                                        message.open({ type: 'warning', content: Validation_Warning_Message.positive_number, duration: 3}); return;
                                                                    }
                                                                }
                                                            }
                                                            validator && Validation_Warning_Message?.[validator] && message.open({ type: 'warning', content: Validation_Warning_Message[validator], duration: 3});
                                                        }
                                                        dispatch(D_Capture_Value({ "tableName": tableName, "value": null, "rowNumber": rowIndex, "data_index": data_index, "nested_index": isTagArray ? updatedFieldNamewithNoSpecialChar : null }))
                                                    }
                                                } else {
                                                    const tableInputOrNormalInput = isSingleInput
                                                        ? { "name": data_index, "value": numberValue }
                                                        : { "tableName": tableName, "value": null, "rowNumber": rowIndex, "data_index": data_index }
                                                    console.log("validation failed");
                                                    dispatch(D_Capture_Value(tableInputOrNormalInput))
                                                    if(decimalPlace===0){
                                                        switch(validator){
                                                            case 'positive_decimal_number': {
                                                                message.open({ type: 'warning', content: Validation_Warning_Message.positive_number, duration: 3}); return;
                                                            }
                                                        }
                                                    }
                                                    if(event.target.value){
                                                        validator && Validation_Warning_Message?.[validator] && message.open({ type: 'warning', content: Validation_Warning_Message[validator], duration: 3});
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                    {skeletonInput?.tooltip
                                        ? <div className='w-[0.5rem] aspect-square mr-[0.5rem]'>
                                            <TableToolTip tooltip={skeletonInput?.tooltip} />
                                        </div>
                                        : null}
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }
}

export default RenderTableInputComponent