import React, { useState, useEffect } from "react";
import searchNotFilled from "../assets/common/searchNotFilled.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Progress,
    Button,
    Tag,
    Table,
    ConfigProvider,
    Space,
    Tooltip,
    Dropdown,
    Menu,
    Empty,
} from "antd";
import "./index.css";
import DataRoom from "../localization/data_room/localization_en_data_room.json";
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import PlusSVG from "../DesignSystem/SVG/PlusSVG";
import DownloadSVG from "../DesignSystem/SVG/DownloadSVG";
import DeleteSVG from "../DesignSystem/SVG/DeleteSVG";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import FileIC from "../assets/TableIcon/fileIc.png";
import OwnerIC from "../assets/TableIcon/owner.png";
import ReviewTick from "../assets/TableIcon/reviewTick.png";
import CommentIc from "../assets/TableIcon/commentIc.png";
import TagIc from "../assets/TableIcon/tags.png";
import LinkIc from "../assets/TableIcon/LinkIc.png";
import CirclePlus from "../assets/TableIcon/circlePlusIc.png";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import ActionMenuCard from "../DesignSystem/Cards/ActionMenuCard";
import outlineContent from "../assets/common/Outline Content.svg";
import DownloadModal from "../DesignSystem/Cards/ActionMenuOptionModal/DownloadModal";
import {
    PlusOutlined,
    SearchOutlined,
    DownloadOutlined,
    DeleteOutlined,
    FilterOutlined,
    ReloadOutlined,
    LeftOutlined,
} from "@ant-design/icons";
import ActionMenuArchiveCard from "../DesignSystem/Cards/ActionMenuArchiveCard";
import { fetchArchiveFileApi, listDirectoryApi } from "../features/dataRoomSlice";
import ActionMenuArchiveFileCard from "../DesignSystem/Cards/ActionMenuArchiveFilesCard";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LockIconSVG from "../DesignSystem/SVG/LockIconSVG";

export default function ArchiveFileDataRoom() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const linkInfo = useSelector((state) => state.link);
    const activeItem = useSelector((state) => state.link.activeItem);
    const openKeys = useSelector((state) => state.link.openKeys);
    const username = useSelector((state) => state.user.value.username);
    const [featureData, setFeatureData] = useState({});
    const [flippedCards, setFlippedCards] = useState(
        new Array(featureData["cards"]?.length).fill(false)
    );
    const status = useSelector((state) => state.link.status);
    const progress = useSelector((state) => state.link.progress);
    const onboarding = useSelector((state) => state.onboarding.value.Company);
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [checkedCards, setCheckedCards] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [filesData, setFilesData] = useState([]);
    const [reClick, setReClick] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [folderNames, setFolderNames] = useState([])
    const user = useSelector((state) => state.user);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            try {
                // First API call to fetch archived files
                const response = await dispatch(fetchArchiveFileApi(""));
                const extractedData = response.payload?.listOfArchivedFiles.map((obj, index) => ({
                    _id: obj._id,
                    organisationId: obj.organisationId,
                    userId: obj.userId,
                    name: obj.name,
                    path: obj.path,
                    type: obj.file,
                    description: obj.description,
                    archiveStatus: obj.archiveStatus,
                    tags: obj.tags,
                    color: obj.color,
                    size: obj.size,
                    lockStatus: obj.lockStatus,
                    createdAt: obj.createdAt,
                    updatedAt: obj.updatedAt,
                    fileId: index + 1,
                }));
                setFilesData(extractedData);
    
                // // Second API calls for each folder
                // const foldersData = response.payload?.listOfDirectories.map(obj => ({
                //     name: obj.name
                // }));
                // setFolderNames(foldersData);
    
                // // Use Promise.all to wait for all folder API calls to finish
                // const directoryPromises = foldersData.map(async (value) => {
                //     const body = { directory: value.name };
                //     const response2 = await dispatch(listDirectoryApi(body));
                //     return response2.payload.listOfFiles.map((obj, index) => ({
                //         _id: obj._id,
                //         organisationId: obj.organisationId,
                //         userId: obj.userId,
                //         name: obj.name,
                //         path: obj.path,
                //         type: obj.file,
                //         description: obj.description,
                //         archiveStatus: obj.archiveStatus,
                //         tags: obj.tags,
                //         color: obj.color,
                //         size: obj.size,
                //         lockStatus: obj.lockStatus,
                //         createdAt: obj.createdAt,
                //         updatedAt: obj.updatedAt,
                //         fileId: index + 1,
                //     }));
                // });
    
                // // Wait for all directoryPromises to resolve and flatten the result
                // const directoryResults = await Promise.all(directoryPromises);
                // const allFiles = directoryResults.flat();
    
                // // Update the state with the combined data
                // setFilesData(prevFilesData => [...prevFilesData, ...allFiles]);
    
            } catch (error) {
                console.error("Error: ", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        getData();
    }, [dispatch, reClick]);
    

    const onSelectChange = (newSelectedRowKeys) => {
        setButtonDisabled(!newSelectedRowKeys.length);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const dataRoomFiles = [
        {
            fileId: 1,
            fileName: "File Name ABC",
            ownerName: "Owner Name",
            status: "in-progress",
            tags: ["Tag1", "Tag2"],
            linkedDocs: 3,
        },
        {
            fileId: 2,
            fileName: "File Name XYZ",
            ownerName: "John Doe",
            status: "completed",
            tags: ["Tag1", "Tag2"],
            linkedDocs: 0,
        },
        {
            fileId: 3,
            fileName: "File Name 123",
            ownerName: "Williamson",
            status: "not-assigned",
            tags: ["Tag1", "Tag2"],
            linkedDocs: 0,
        },
    ];

    const showModalTags = () => {
        setModalTitle("Info")
        setIsModalVisible(true)
        setText("Oops! Please unarchive the file to add tags.")
    }

    const showModalComments = () => {
        setModalTitle("Info")
        setIsModalVisible(true)
        setText("Oops! Please unarchive the file to add comments.")
    }
    const columns = [
        {
            title: (
                <div className="flex items-center py-3 ml-2 bg-white">
                    <span className=" font-normal text-[#757575]">File ID</span>
                </div>
            ),
            dataIndex: "fileId",
            key: "fileId",
            render: (_, record) => {
                return (
                    <div className="py-2">
                        <h1 className="text-[#111827] font-medium text- pl-2">
                            {_}
                        </h1>
                    </div>
                );
            },
            width: 70,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img src={FileIC} alt="file-ic" className=" w-3 h-3.5 border mr-2" /> */}
                    <InsertDriveFileOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">File Name</h1>
                </div>
            ),
            dataIndex: "fileName",
            key: "fileName",
            render: (_, record) => {
                return (
                    <div className="flex truncate-to-1-lines">
                        <h1 className=" text-[#111827] font-medium font-sans ">
                            {record.name}
                        </h1>
                    </div>
                );
            },
            width: 170,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img
                        src={OwnerIC}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
                    <PersonOutlineOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Owner</h1>
                </div>
            ),
            dataIndex: "ownerName",
            key: "ownerName",
            render: (_, record) => {
                console.log(record)
                return (
                    <div className="flex items-center py-1 relative">
                        <div className="absolute left-[-50px] top-[2px]">
                            {record.lockStatus ? (
                                <div className="scale-90">
                                <LockIconSVG />
                                </div>
                            ) : null}
                        </div>
                        <div className=" flex items-center justify-center mr-2 w-5 h-5 rounded-full bg-orange-200">
                            <h1 className=" text-orange-500 m-0 text-[10px]">
                                {(user.value.username)[0]}
                            </h1>
                        </div>
                        <div className=" flex  items-center">
                            <h1 className=" text-[#111827] font-sans text-sm m-0 font-medium tracking-wide">
                                {user.value.username}
                            </h1>
                        </div>
                    </div>
                );
            },
            width: user.value.username.length * 15,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img
                        src={ReviewTick}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
                    <CheckCircleOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Review Progress</h1>
                </div>
            ),
            dataIndex: "status",
            key: "status",
            render: (status) => {
                let color;
                let text;
                switch (status) {
                    case "in-progress":
                        color = "orange";
                        text = "In Progress";
                        break;
                    case "completed":
                        color = "green";
                        text = "Completed";
                        break;
                    case "not-assigned":
                        color = "red";
                        text = "Not Assigned";
                        break;
                    default:
                        color = "blue";
                        text = "Unarchive to see";
                }
                return (
                    <div className="flex items-center py-0.5 ">
                        <div
                            className={` flex items-center justify-center border border-[${color}] bg-opacity-50 rounded-lg  py-1.5 bg-${color}-200 w-[90%]`}
                        >
                            <h1 className={` text-${color}-500 m-0 text-[11px]`}>{text}</h1>
                        </div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white pl-4">
                    {/* <img
                        src={CommentIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
                    <ChatBubbleOutlineOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Comment</h1>
                </div>
            ),
            key: "comments",
            render: (_, record) => {
                return (
                    <div className="flex pl-4 py-0.5" onClick={showModalComments}>
                        <div className=" text-[#372EE5] font-semibold cursor-pointer ">
                            Add Comment
                        </div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img
                        src={TagIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
                    <SellOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Tags</h1>
                </div>
            ),
            key: "tags",
            dataIndex: "tags",
            render: (_, records) => (
                <div className="py-0.5">
                    {records.tags ? (
                        <>
                        {records.tags.split(',').map((tag) => (
                            <Tag color="blue" className="rounded-full" key={tag.trim()}>
                                {tag.trim()}
                            </Tag>
                        ))}
                        <Tag color="cyan" className="rounded-full font-bold">
                            <div onClick={() => { showModalTags(records.tags, records._id) }}>+</div>
                        </Tag>
                        </>
                    ) : (
                        <Tag color="cyan" className="rounded-full font-bold">
                            <div onClick={() => { showModalTags(records.tags, records._id) }}>+</div>
                        </Tag>
                    )}
                </div>
            ),
            width: 200,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img
                        src={LinkIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
                    <AddLinkOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Linked Docs</h1>
                </div>
            ),
            dataIndex: "linkedDocs",
            key: "linkedDocs",
            render: (linkedDocs) => (
                <div className="flex py-0.5">
                    <span className=" text-[#424242] font-medium">{"Unarchive to See"}</span>
                </div>
            ),
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-center py-3 bg-white">
                    <AddCircleOutlineOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                </div>
            ),
            key: "actions",
            render: (_, record) => (
                <div className="py-1.5 w-full flex justify-center bg-white">
                    <ActionMenuArchiveFileCard documentId={record?._id} onClose={() => { setReClick(!reClick) }} />
                </div>
            ),
            width: 40,
            "fixed": "right"
        },
    ];

    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card.id !== cardDetails.id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);

    const [modalTitle, setModalTitle] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [deleteModalTitle, setDeleteModalTitle] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const [isDownloadModalVisible, setIsDownloadModalVisisble] = useState(false);

    //   Add File Modal
    const handleButtonClick = () => {
        setModalTitle("Add File"); // Change the index as needed
        setIsModalVisible(true);
    };
    const [text, setText] = useState(null)

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };
    //   Delete Modal
    const handleDeleteButton = () => {
        setModalTitle("Delete File"); // Change the index as needed
        setIsModalVisible(true);
    };

    const closeDeleteModal = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(null);
    };

    const onDownloadClick = () => {
        setModalTitle("Download");
        setIsModalVisible(true);
    };

    const SkeletonLoader = ({ avatar = false, active = true, loading = true }) => {
        return (
            <div className="bg-neutral-200 animate-pulse w-full h-10 rounded-lg">
            </div>
        );
    };


    return (
        <div className="pb-10">
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    isOpen={true}
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                    }}
                    text={text}
                />
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"} mt-[3.5rem]`}>
                <div className="mx-auto">
                    <div className="flex justify-between items-center">
                        <p className="font-DMSans text-[#111827] text-[18px] mt-6">
                            Archive Files
                        </p>
                        <div className="flex gap-2 items-center">
                            <Button
                                icon={<SearchOutlined />}
                                className="flex items-center justify-center bg-[#EBF1FF] text-[#372EE5]"
                            />
                            <div>
                            <Button
                                onClick={() => { setReClick(!reClick);}} // Call handleReloadClick on click
                                className="bg-[#F5F5F5] text-[#372EE5] flex items-center justify-center"
                                disabled={isLoading}
                            >
                                {isLoading ? ( 
                                    <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg lg:overflow-visible">
                                        <svg class="w-3.5 h-4 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                        <path
                                            d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                                            stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path
                                            d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                                            stroke="#372EE5" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-900">
                                        </path>
                                        </svg>
                                    </div>
                                ) : (
                                    <ReloadOutlined />
                                )
                                }
                            </Button>
                        </div>
                        </div>
                    </div>
                    <div className="mt-4 overflow-hidden">
                        {isLoading ? (
                            <div className="w-full flex flex-col gap-3">
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Table: {
                                            headerBg: "#FFFFFF",
                                        },
                                    },
                                }}
                            >
                                <Table
                                    columns={columns}
                                    dataSource={filesData}
                                    rowSelection={rowSelection}
                                    scroll={{ x: 1500 }}
                                    rowKey="fileId"
                                    className="border border-[#9E9E9E] rounded-2xl overflow-hidden"
                                    rowClassName={() =>
                                        "bg-white border border-[#9E9E9E] px-3 mb-2"
                                    }
                                    pagination={{
                                        pageSize: filesData?.length,
                                        position: ['none', 'none'],
                                    }}
                                    rowHoverable={false}
                                    locale={{ emptyText: (
                                        <span>
                                            <div className="flex flex-col items-center justify-center my-10">
                                            <Empty description="Uh-oh! There are no files available to unarchive. Please check back later!" />
                                            </div>
                                        </span>
                                    ) }}
                                />
                            </ConfigProvider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
