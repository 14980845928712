import React from 'react'
import { productAsset } from '../../assets/website'
import locals from '../../localization/localization_en_website.json'
import PoweredByMarquee from '../components/PoweredByMarquee'
import { poweredByImages, core_trusted_by } from '../../assets/website'
import ServeFounderDetail from '../components/ServeFounderDetail'
import FlexiblePlanDetail from '../components/FlexiblePlanDetail'
import BlogPostSection from '../components/BlogPostSection'
import MainContentSection from '../components/MainContentSection'
import FooterAlertPage from '../components/FooterAlertPage'

const { shapshot } = productAsset

const HOME_LOCALS = locals.website.home;
const BLOG_LOCALS = locals.website.blogs;

const Home = () => {
    return (
        <div className='flex flex-col gap-y-[40px]'>
            <MainContentSection locals={HOME_LOCALS.main_content} shapshot={shapshot} />
            <PoweredByMarquee title={HOME_LOCALS.poweredBy} marqueeImages={poweredByImages} />
            <ServeFounderDetail locals={HOME_LOCALS.serve_founders} />
            <FlexiblePlanDetail locals={HOME_LOCALS.flexible_plans} />
            <BlogPostSection locals={BLOG_LOCALS} />
            <div>
                <FooterAlertPage locals={HOME_LOCALS.request_demo} />
                <PoweredByMarquee isStatic={false} parentClassName="sm:py-[40px] py-[20px]" title={HOME_LOCALS.trustedBy} marqueeImages={core_trusted_by} />
            </div>
        </div>
    )
}

export default Home