import React, { useEffect, useState } from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import AdminDataRoom from "../../localization/data_room/localization_en_admin_data_room.json";
import MoveArrow from "../SVG/MoveArrowSVG";
import AcessPanel from "../SVG/AcessPanelSVG";
import Info from "../SVG/InfoSVG";
import Create from "../SVG/CreateSVG.jsx";
import DeleteOutlineSVG from "../SVG/DeleteOutlineSVG.jsx";
import DownloadOutlineSVG from "../SVG/DownloadOutlineSVG.jsx";
import ColorCodeSVG from "../SVG/ColorCodeSVG.jsx";
import DiscriptionSVG from "../SVG/DiscriptionSVG.jsx";
import ArchiveOutline from "../SVG/ArchiveOutlineSVG.jsx";
import CopyOutline from "../SVG/CopyOutlineSVG.jsx";
import grayDots from "../../assets/common/grayDots.svg";
import ModalSection from "./ActionMenuOptionModal/ModalSection.jsx";
import { copyFileApi, updateArchiveStatusApi, updateColor } from "../../features/dataRoomSlice.jsx";
import { useDispatch } from "react-redux";

const QuestionAndAnswerCard = ({ documentId, handleClose, handleDashColorChange }) => {
  const [modalTitle, setModalTitle] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentList, setDocumentList] = useState(null);
  const dispatch = useDispatch()
  const handleMenuClick = (e) => {
    console.log("click", e);
    setModalTitle(AdminDataRoom.admin_action_menu.actionMenu[e.key]);
    setIsModalVisible(true);
    if(e.key === "6") {
        setDocumentList([documentId])
    } else {
        setDocumentList(documentId);
    }
  };

  const handleColorChange = async (color) => {
    handleDashColorChange(true)
    const body = {
        documentId: documentId,
        color: color
    }
    await dispatch(updateColor(body)).then(() => {console.log("Hello"); handleClose(); handleDashColorChange(false, color)})
  };

  const closeModalHandler = () => {
    setIsModalVisible(false);
    setModalTitle(null);
    handleClose();
  };

  const handleArchiveClick = async () => {
    const body = {
        documentId: documentId,
        archiveStatus: true
    }

    await dispatch(updateArchiveStatusApi(body));
    handleClose();
  }

  const handleMakeCopy = async () => {
    const body = {
      documentId: documentId
    }

    await dispatch(copyFileApi(body))
  }

  console.log("Hello World")

  const menuItems = [
    {
      label: (
        <Space className="py-2">
          <MoveArrow />
          {AdminDataRoom.admin_action_menu.actionMenu[25]}
        </Space>
      ),
      key: "25",
    },
    {
      label: (
        <Space className="py-2">
          <ColorCodeSVG />
          {AdminDataRoom.admin_action_menu.actionMenu[26]}
        </Space>
      ),
      key: "26",
      // children: [
      //   {
      //     key: "18",
      //     label: (
      //       <Space>
      //         <DiscriptionSVG />
      //         {AdminDataRoom.admin_action_menu.actionMenu[18]}
      //       </Space>
      //     ),
      //   },
      //   {
      //     key: "8",
      //     label: (
      //       <Space onClick={handleArchiveClick}>
      //         <ArchiveOutline />
      //         {AdminDataRoom.admin_action_menu.actionMenu[8]}
      //       </Space>
      //     ),
      //   },
      // ],
    },
    {
      label: (
        <Space className="py-2">
          <Info />
          {AdminDataRoom.admin_action_menu.actionMenu[4]}
        </Space>
      ),
      key: "4",
    },
    // {
    //   label: (
    //     <Space className="py-2" onClick={() => {}}>
    //       <DownloadOutlineSVG />
    //       {AdminDataRoom.admin_action_menu.actionMenu[10]}
    //     </Space>
    //   ),
    //   key: "10",
    // },
  ];

  const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

  return (
    <Space wrap>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Space className="cursor-pointer opacity-75 hover:opacity-100">
          <img src={grayDots} />
        </Space>
      </Dropdown>
      {isModalVisible && (
        <ModalSection
          title={modalTitle}
          closeHandler={closeModalHandler}
          documentList={documentList} // Pass any required document list or other props
        />
      )}
    </Space>
  );
};

export default QuestionAndAnswerCard;
