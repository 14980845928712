import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'
import { cn } from '../../utils/cn'

const HeadingSix = ({ text, color, margin, padding, className, headingCSSStyles }) => {
  return (
    <React.Fragment>
        <h6 style={{color:color,...generatePadding(padding?.direction,padding?.number), ...generateMargin(margin?.direction,margin?.number), ...headingCSSStyles}} className={cn(`text-[18px] font-bold font-DMSans ${className}`)}>{text}</h6>
    </React.Fragment>
  )
}

export default HeadingSix