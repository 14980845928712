import React from 'react'
import { shapes, team_members } from '../../assets/website'
import website_locals from '../../localization/localization_en_website.json'
import MainContentSection from '../components/MainContentSection'
import ResponsiveHeadingThreeSix from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingThreeSix'
import TextExtraLarge from '../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import TeamMemberOverview from '../components/TeamMemberOverview'
import { trusted_partners } from '../../assets/website'
import PoweredByMarquee from '../components/PoweredByMarquee'
import useScrollToTopPage from '../../hooks/useScrollToTopPage'

const ABOUT_US_LOCALS = website_locals.website.about_us

const HeaderWithUnderLine = ({ text, widthClass }) => {
    return (
        <div className='flex flex-col gap-y-[8px]'>
            <ResponsiveHeadingThreeSix text={text} color={"#372EE5"} />
            <div className={`h-[4px] bg-[#D0D5DD] rounded-full ${widthClass}`}></div>
        </div>
    )
}

const TitleDescription = ({ title, description }) => {
    return (
        <div className='flex flex-col gap-y-[32px]'>
            <HeaderWithUnderLine text={title} widthClass={"w-[70%]"} />
            <TextExtraLarge text={description} fontWeight={500} color='#424242' />
        </div>
    )
}
const AboutUs = () => {
    useScrollToTopPage();
    return (
        <div>
            <div className='sm:py-[4rem] mx-[16px]'>

                <MainContentSection locals={ABOUT_US_LOCALS.main_content} />

                <div
                    className="relative"
                >
                    <div className='sm:px-[24px] py-[24px] flex flex-col gap-y-[40px] lg:pt-[6rem] sm:pt-[7rem]'>
                        <TitleDescription title={ABOUT_US_LOCALS.vision.title} description={ABOUT_US_LOCALS.vision.description} />
                        <TitleDescription title={ABOUT_US_LOCALS.mission.title} description={ABOUT_US_LOCALS.mission.description} />
                    </div>
                    <div className='absolute -z-10 top-0 min-w-full xl:h-[36rem] lg:h-[40rem] h-[48rem] md:block hidden'>
                        <img src={shapes.rectangle_about_us} className='w-full h-full' alt="" />
                    </div>
                </div>

                <div className='flex flex-col gap-y-[24px] mt-[7rem]'>
                    <HeaderWithUnderLine text={ABOUT_US_LOCALS.team_details.title} widthClass="w-full" />
                    <div className='flex flex-wrap justify-start gap-[48px]'>
                        {
                            ABOUT_US_LOCALS.team_details.team_members.map((member, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='w-[400px]'>
                                        <TeamMemberOverview
                                            name={member.person_name}
                                            role={member.role}
                                            person_image={team_members[member.img_ref_name]}
                                            introduction={member.about_me}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>

            <div>
                <PoweredByMarquee isStatic title={ABOUT_US_LOCALS.trustedBy} marqueeImages={trusted_partners} imgClassName='w-[12rem] max-h-[40px] object-contain mx-[8rem]' />
            </div>
        </div>
    )
}

export default AboutUs