import React from 'react'
import HeadingSix from '../../TypographyHeadings/HeadingSix'
import TextLarge from '../../TypographyDescriptions/TextLarge'
import { cn } from '../../../utils/cn'

const BorderlessCard = ({ icon, headingColor, textColor = "#757575", heading, description, parentClassName, childClassName, imageClassName, textAlignClassName }) => {
    return (
        <div className={cn(`flex gap-x-[16px] ${parentClassName}`)}>
            <img src={icon} className={imageClassName} alt="" />
            <div className={`${childClassName}`}>
                <HeadingSix text={heading} color={headingColor} className={textAlignClassName} />
                <TextLarge text={description} fontWeight={400} color={textColor} className={textAlignClassName} />
            </div>
        </div>
    )
}

export default BorderlessCard