import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import routesConfig from "../../services/config/routesConfig.json";
import api from "../../services/api/api";
import { v4 as uuidv4 } from "uuid";
import locals from "../../localization/localization_en_pAndL_workCap_debtWork_cashFlow_balanceSheet.json"
import { DropdownDataPreparation, TagDataFormat } from "./components/CostOnboarding/utils/DropdownDataPreparation";
import { filterDuplicateObjectProp } from "../../utils/filterDuplicateObjectProp";

const debt_working_locals = locals.debt_working;

const INITIAL_STATE = {
    "output": null,
    "isSaveDisabled": false,
    "isGenerateOutputDisabled": true,
    "month_year_revenue_screen1_ref": { first_month: '', first_year: '' },
    "save_process_api_status": { "status": "idle", counter: 0, message: null },
    "future_loan_avail": debt_working_locals.form.data[0].table.default_rows,
    "outstanding_loan_incur": debt_working_locals.form.data[1].table.default_rows,
    "loan_name_category_list": [],
    "ApiStatus": { "status": 'idle', "message": null },
    "screen_screen_api_status": { "status": 'idle', "message": null }
}

const FETCH_DEFAULT_ROW = ({ tableName }) => {
    switch (tableName) {
        case 'future_loan_avail': {
            return debt_working_locals.form.data[0].table.add_row_schema;
        }
        case 'outstanding_loan_incur': {
            return debt_working_locals.form.data[1].table.add_row_schema;
        }
    }
}

export const API_GET_LoanTag_Data = createAsyncThunk(
    "debtWorking/API_GET_LoanTag_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-loan-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_LoanTag_Data = createAsyncThunk(
    "debtWorking/API_CREATE_LoanTag_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-loan');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_PUT_LoanTag_Data = createAsyncThunk(
    "debtWorking/API_PUT_LoanTag_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-loan');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Save_DebtWorking_Data = createAsyncThunk(
    "debtWorking/API_Save_DebtWorking_Data",
    async (payloadData) => {
        let payloadFordebtWorking;
        payloadFordebtWorking = { businessModelId: payloadData.businessModelId }
        delete payloadData.businessModelId;
        payloadFordebtWorking = { ...payloadFordebtWorking, "screenInput": payloadData }
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-debt-working');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadFordebtWorking }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_DebtWorking_Data = createAsyncThunk(
    "debtWorking/API_Process_DebtWorking_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-debt-working');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const debtWorkingSlice = createSlice({
    name: "debt-working",
    initialState: {
        "dstore": INITIAL_STATE
    },
    reducers: {
        D_Reset_ErrorMessage(state, action) {
            state.dstore.save_process_api_status = { "status": "idle", counter: 0, message: null }
        },
        D_Reset_Notification(state) {
            state.dstore.ApiStatus = { "status": 'idle', "message": null }
        },
        D_Capture_Value_DebtWorking(state, action) {
            if (state.dstore.output) state.dstore.output = null
            if (state.dstore.isSaveDisabled) {
                state.dstore.isSaveDisabled = false;
                state.dstore.isGenerateOutputDisabled = true;
            }
            const { name, tableName, value, rowNumber, data_index, id } = action.payload;
            state.dstore[tableName] = state.dstore[tableName].map((row, index) => {
                if(index === rowNumber){
                    if(data_index === "loan_name"){
                        return { ...row, [data_index]: isNaN(value) ? value : value === null ? null : Number(value), "dbid": id }
                    }else{
                        return { ...row, [data_index]: isNaN(value) ? value : value === null ? null : Number(value) }
                    }
                }
                return row;
            });
        },
        D_Add_Row_DebtWorking(state, action) {
            if (state.dstore.output) state.dstore.output = null
            if (state.dstore.isSaveDisabled) {
                state.dstore.isSaveDisabled = false;
                state.dstore.isGenerateOutputDisabled = true;
            }
            const { tableName } = action.payload;
            state.dstore[tableName] = [...state.dstore[tableName], { "key": uuidv4(), ...FETCH_DEFAULT_ROW({ tableName }) }];
        },
        D_Remove_Row_DebtWorking(state, action) {
            if (state.dstore.output) state.dstore.output = null
            if (state.dstore.isSaveDisabled) {
                state.dstore.isSaveDisabled = false;
                state.dstore.isGenerateOutputDisabled = true;
            }
            const { tableName, rowNumber } = action.payload;
            state.dstore[tableName] = state.dstore[tableName].filter((row, index) => index !== rowNumber)
            if (state.dstore[tableName].length === 0) state.dstore[tableName] = [{ "key": uuidv4(), ...FETCH_DEFAULT_ROW({ tableName }) }]
        },
        D_Update_Screen_Screen_DebtWorking(state, action) {
            if(!!action.payload===false) return;
            const SCREEN_DATA = action.payload.screenData
            const loan_name_category_list = [...DropdownDataPreparation(SCREEN_DATA.loanList)]
            state.dstore.loan_name_category_list = filterDuplicateObjectProp({ "list": loan_name_category_list, "targetProp": "id" })
            if (SCREEN_DATA?.input) {
                state.dstore.future_loan_avail = SCREEN_DATA?.input?.future_loan_avail
                state.dstore.outstanding_loan_incur = SCREEN_DATA?.input?.outstanding_loan_incur
            }
            state.dstore.output = SCREEN_DATA?.output ? SCREEN_DATA?.output : null;
            state.dstore.isSaveDisabled = state.dstore.output ? true : false
            state.dstore.isGenerateOutputDisabled = state.dstore.output ? true : false
            state.dstore.month_year_revenue_screen1_ref = { "first_month": SCREEN_DATA?.revenueScreenOneInput?.first_month,"first_year": SCREEN_DATA?.revenueScreenOneInput?.first_year  }
        },
        D_Reset_DebtWorking_State(state, action) {
            state.dstore = INITIAL_STATE
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_GET_LoanTag_Data.pending, (state) => {
                state.dstore.ApiStatus.status = 'loading';
            })
            .addCase(API_GET_LoanTag_Data.fulfilled, (state, action) => {
                state.dstoreloan_name_category_list = [...DropdownDataPreparation(action.payload)]
                state.dstore.ApiStatus.status = 'success';
            })
            .addCase(API_GET_LoanTag_Data.rejected, (state, action) => {
                state.dstore.ApiStatus = { status: 'failed', 'message': action.error.message };
            })
            .addCase(API_CREATE_LoanTag_Data.pending, (state) => {
                state.dstore.ApiStatus.status = 'loading';
            })
            .addCase(API_CREATE_LoanTag_Data.fulfilled, (state, action) => {
                state.dstore.ApiStatus.status = 'success';
                state.dstore.loan_name_category_list = [...state.dstore.loan_name_category_list, TagDataFormat(action.payload)]
            })
            .addCase(API_CREATE_LoanTag_Data.rejected, (state, action) => {
                state.dstore.ApiStatus = { status: 'failed', 'message': action.error.message };
            })
            .addCase(API_PUT_LoanTag_Data.pending, (state) => {
                state.dstore.ApiStatus.status = 'loading';
            })
            .addCase(API_PUT_LoanTag_Data.fulfilled, (state, action) => {
                state.dstore.ApiStatus.status = 'success';
                const targetId = action.payload.loanUpdated._id;
                const updatedValue = action.payload.loanUpdated.title;
                state.dstore.loan_name_category_list = state.dstore.loan_name_category_list.map((loanData) => targetId === loanData.id ? { ...loanData, label: [updatedValue], value: [updatedValue] } : loanData)
            })
            .addCase(API_PUT_LoanTag_Data.rejected, (state, action) => {
                state.dstore.ApiStatus = { status: 'failed', 'message': action.error.message };
            })

            .addCase(API_Save_DebtWorking_Data.pending, (state) => {
                state.dstore.save_process_api_status.counter = state.dstore.save_process_api_status.counter + 1
                state.dstore.save_process_api_status.status = 'loading';
            })
            .addCase(API_Save_DebtWorking_Data.fulfilled, (state) => {
                state.dstore.save_process_api_status.counter = state.dstore.save_process_api_status.counter + 1
                state.dstore.save_process_api_status.status = 'success';
                state.dstore.isSaveDisabled = true;
                state.dstore.isGenerateOutputDisabled = false;
            })
            .addCase(API_Save_DebtWorking_Data.rejected, (state, action) => {
                state.dstore.save_process_api_status.counter = state.dstore.save_process_api_status.counter + 1
                state.dstore.save_process_api_status.status = 'failed';
                state.dstore.save_process_api_status.message = action.error.message
            })
            .addCase(API_Process_DebtWorking_Data.pending, (state) => {
                state.dstore.save_process_api_status.counter = state.dstore.save_process_api_status.counter + 1
                state.dstore.save_process_api_status.status = 'loading';
            })
            .addCase(API_Process_DebtWorking_Data.fulfilled, (state, action) => {
                state.dstore.save_process_api_status.counter = state.dstore.save_process_api_status.counter + 1
                state.dstore.save_process_api_status.status = 'success';
                state.dstore.isGenerateOutputDisabled = true;
                state.dstore.output = action.payload;
            })
            .addCase(API_Process_DebtWorking_Data.rejected, (state, action) => {
                state.dstore.save_process_api_status.counter = state.dstore.save_process_api_status.counter + 1
                state.dstore.save_process_api_status.status = 'failed';
                state.dstore.save_process_api_status.message = action.error.message
            })
    }
});

export const { D_Update_Screen_Screen_DebtWorking, D_Reset_ErrorMessage, D_Reset_Notification, D_Reset_DebtWorking_State, D_Capture_Value_DebtWorking, D_Add_Row_DebtWorking, D_Remove_Row_DebtWorking } = debtWorkingSlice.actions;
export default debtWorkingSlice.reducer;