import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import { handleRadioInputSelection, D_Handle_Tab_Change, D_Control_Rendering_Output } from '../../revenueSlice';
import DefineFormItem from '../../subcomponents/ROFormComponents/DefineFormItem';
import { RevenueOnboardingFormStatus } from '../../functionality/RevenueOnboarding/CheckIndividualFormIsCompleted';
import { ROPreparePayloadIsNullDataCheck } from '../../functionality/RevenueOnboarding/PreparePayloadIsNullDataCheck';
import AddProductCategory from '../../subcomponents/Form/AddProductCategory';
import { API_Create_Product_Category, API_Fetch_Product_Category_List, API_Patch_Product_Category, D_RESET_ERROR_MESSAGE } from '../../revenueSlice';
import ListModal from '../../subcomponents/Form/ListModal';
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const ROScreen2 = ({ form, counterToTriggerFormStatus, isFormFilledAsExpected }) => {
  const dispatch = useDispatch();
  const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
  const { projection_duration } = useSelector((state) => state.globalSetting.active_global_settings); //projection_duration
  const { manufacture, buy, supply_chain_selection, product_category_1, business_contribution, returns_cancellations, quantity_per_order, product_category_active_tab, isScreen2InputFieldDisabled } = revenueOnboardingData;
  const { manufacture_or_buy, product_categories_details } = revenueOnboardingData;
  const validationPayload = { ...revenueOnboardingData, projection_duration, manufacture_or_buy }
  const [isTagModalOpen, setIsTagModalOpen] = React.useState(false);
  const ApiStatus = revenueOnboardingData.api_status_category.product_category
  const ListData = revenueOnboardingData.category_list.product_category
  const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)

  const VIEW_MODE = MODE_TYPE.view;
  const handleRadioSelectChange = (selection) => {
    dispatch(handleRadioInputSelection({ selection }));
  }
  React.useEffect(() => {
    if (counterToTriggerFormStatus > 0) {
      let isFilled = true;
      form.data.map((formItem) => {
        if(manufacture_or_buy===undefined)isFilled = false
        let errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck({ ...revenueOnboardingData, projection_duration, manufacture_or_buy }, formItem);
        const status = RevenueOnboardingFormStatus(errorCheckingPreInputs);
        if(status === true){
          isFilled = false;
      }
      });
      isFormFilledAsExpected(isFilled);
    }
  }, [counterToTriggerFormStatus])
  const HANDLE_TAB_CHANGE = (tabNumber) => dispatch(D_Handle_Tab_Change(tabNumber))
  const CallbackToggleModal = () => setIsTagModalOpen((prev) => !prev);
  const CallbackResetNotificationMessage = () => dispatch(D_RESET_ERROR_MESSAGE({ type: 'product_category' }));
  const ApiCallingFunction = (type, arg) => {
    switch(type){
      case 'fetch': {
        dispatch(API_Fetch_Product_Category_List());
        break;
      }
      case 'create': {
        dispatch(API_Create_Product_Category(arg.tag));
        break;
      }
      case 'patch': {
        dispatch(API_Patch_Product_Category({ "productCategoryId": arg.id,"productCategoryTitle": arg.tag }))
        break;
      }
    }
  }
  return (
    <section className='flex flex-col gap-y-[24px]'>
      {
                (manufacture_or_buy!==null) ? <div className='flex justify-end'>
                    {isTagModalOpen ? 
                    <ListModal
                      Title={"Product Category Name"}
                      InputPlaceholder={"Enter Name"}
                      warningDataIndex={"Product_Catgory"}
                      ListData={ListData}
                      ApiStatus={ApiStatus}
                      tooltipMessages={{ warning: 'Product category exist already in list' }}
                      ApiCallingFunction={ApiCallingFunction}
                      isTagModalOpen={isTagModalOpen}
                      CallbackToggleModal={CallbackToggleModal}
                      CallbackResetNotificationMessage={CallbackResetNotificationMessage}
                    />
                    : null }
                    <PrimaryButton isDisabled={VIEW_MODE} onClick={() => setIsTagModalOpen(true)} text={"Create Product Category"} />
                </div> : null
            }
      {
        form.data.map((formItem, index) => {
          let radioInputProps;
          let errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck(validationPayload, formItem)
          if (formItem?.select_options) {
            radioInputProps = { handleRadioSelectChange, "data": revenueOnboardingData[formItem.name], "selectOptions": formItem.select_options, "name": formItem.name }
          }
          if (index === 0) {
            // let data = { manufacture, buy, supply_chain_selection, radioInputProps };
            let screen2Data = { manufacture_or_buy, product_categories_details, radioInputProps };
            return (
              <ComponentErrorBoundary>
                <DefineFormItem errorCheckingPreInputs={errorCheckingPreInputs} key={index} screen2Data={screen2Data} formItem={formItem} />
              </ComponentErrorBoundary>
            )
          }
          if (index === 1 && !!manufacture_or_buy) {
            let screen2Data = { product_categories_details };
            return (
              <ComponentErrorBoundary>
                <DefineFormItem errorCheckingPreInputs={errorCheckingPreInputs} index={index} data={[...product_categories_details.manufacture,...product_categories_details.buy]} formItem={formItem} isReadOnly={isScreen2InputFieldDisabled} />
              </ComponentErrorBoundary>
            )
          }
          if (index === 2 && !!manufacture_or_buy) {
            let business_contribution = [];
            let returns_cancellations = [];
            let quantity_per_order = [];
            [...product_categories_details["manufacture"], ...product_categories_details["buy"]].map((prodCatg, index) => {
              let add_BusinessContributionProductCategory = {}, add_ReturnsCancellationProductCategory = {}, add_QualityPerOrderProductCategory = {};
              prodCatg?.business_contribution?.map((yearProdCatg, index) => {
                add_BusinessContributionProductCategory = { ...add_BusinessContributionProductCategory, [`year${index+1}`]: yearProdCatg.value }
              })
              prodCatg?.returns_cancellations?.map((yearProdCatg, index) => {
                add_ReturnsCancellationProductCategory = { ...add_ReturnsCancellationProductCategory, [`year${index+1}`]: yearProdCatg.value }
              })
              prodCatg?.quantity_per_order?.map((yearProdCatg, index) => {
                add_QualityPerOrderProductCategory = { ...add_QualityPerOrderProductCategory, [`year${index+1}`]: yearProdCatg.value }
              })
              business_contribution = [...business_contribution, {"name": prodCatg.name, ...add_BusinessContributionProductCategory}]
              returns_cancellations = [...returns_cancellations, {"name": prodCatg.name, ...add_ReturnsCancellationProductCategory}]
              quantity_per_order = [...quantity_per_order, {"name": prodCatg.name, ...add_QualityPerOrderProductCategory}]
            })
            let data = { "business_contribution": business_contribution, "returns_cancellations": returns_cancellations, "quantity_per_order":  quantity_per_order}
            let tabState = { product_category_active_tab, HANDLE_TAB_CHANGE }
            return (
              <ComponentErrorBoundary>
                <DefineFormItem errorCheckingPreInputs={errorCheckingPreInputs} data={data} formItem={formItem} tabState={tabState} isReadOnly={isScreen2InputFieldDisabled} />
              </ComponentErrorBoundary>
            )
          }
        })
      }
    </section>
  )
}

export default ROScreen2