import React, { useState, useEffect } from 'react'
import ConfettiComponent from './Confetti'
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ChartAnimation from './reuseablecomponent/ChartAnimation';
import bibLocals from "../localization/localization_en_business_ideation_bingo.json"
import SecondaryButton from '../DesignSystem/Buttons/SecondaryButton';
import PrimaryButton from '../DesignSystem/Buttons/PrimaryButton';
import { updateItemSelection } from '../features/manageLinkSlice';

const BingoScoreCard = () => {
    const dispatch = useDispatch();
    const [showConfetti, setShowConfetti] = useState(true);
    const permission = useSelector((state) => state.user.permission);
    const navigate = useNavigate();
    const score = useSelector(state => state.bingo.score);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowConfetti((prev) => !prev);
        }, 3000)
        return () => timer;
    }, [])

    let scoreoutput, description;
    if (score <= 5) {
        scoreoutput = bibLocals.business_ideation_bingo.performance_feedback_text.level1.type;
        description = bibLocals.business_ideation_bingo.performance_feedback_text.level1.description;
    } else if (score >= 6 && score <= 10) {
        scoreoutput = bibLocals.business_ideation_bingo.performance_feedback_text.level2.type;
        description = bibLocals.business_ideation_bingo.performance_feedback_text.level2.description;
    } else if (score >= 11 && score <= 15) {
        scoreoutput = bibLocals.business_ideation_bingo.performance_feedback_text.level3.type;
        description = bibLocals.business_ideation_bingo.performance_feedback_text.level3.description;
    } else {
        scoreoutput = bibLocals.business_ideation_bingo.performance_feedback_text.level4.type;
        description = bibLocals.business_ideation_bingo.performance_feedback_text.level4.description;
    }
    
    return (
        <div className='scrollbar-hide pt-[64px]'>
            {showConfetti && <ConfettiComponent />}
            <p style={{margin:0}} className='text-[40px] font-bold font-DMSans text-center text-[#372EE5]'>{scoreoutput}</p>
            <div className='flex justify-center'>
                <div className='relative'>
                    {/*<div className='bingoscorecard mx-auto w-[250px] h-[250px] rounded-full' style={{ backgroundImage: "conic-gradient(#FF9979, #FFF731, #88FF8D)" }}>
                        <div className='relative font-semibold border-primary border-2 flex justify-center items-center text-[#372ee5] w-[160px] h-[160px] rounded-full bg-[#ebf1ff] innerdiv top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <span className='text-[30px]'>{score}</span>/20
                        </div>
                    </div>
                    */}
                    <ChartAnimation score={score} />
                </div>
            </div>
            <p className='w-[60%] text-center mx-auto mt-[8px] font-InterRegular italic font-semibold text-primary text-[16px] leading-6 mb-[40px]'>{description}</p>
            <div className='flex justify-center gap-x-[1rem]'>
                <SecondaryButton onClick={() => {
                    navigate('/product/home')
                    dispatch(updateItemSelection({ "activeItem": "1.1" }));
                }} text={bibLocals.business_ideation_bingo.score_card_btn_text.back_to_dashboard} />
                <PrimaryButton isDisabled={!permission.edit} onClick={() => navigate('/product/home/businessideationbingo')} text={bibLocals.business_ideation_bingo.score_card_btn_text.explore_module_again}  />
            </div>
        </div>
    )
}

export default BingoScoreCard
