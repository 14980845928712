import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import LinkButton from '../../../DesignSystem/Buttons/LinkButton';
import CustomLinkButton from '../../../DesignSystem/Buttons/CustomLinkButton';
import locals from "../../../localization/localization_en_chat.json"

const MessageInput = ({
    username,
    avatar,
    placeHolderAvatar,
    message,
    isLoading,
    submitParentComment,
    onChangeParentComment
}) => {
    return (
        <div>
            <div className="profile_section flex items-center mb-[10px]">
                <img src={!!avatar ? avatar : placeHolderAvatar} alt="" className='w-[32px] h-[32px]' />
                <p style={{ marginBottom: 0 }} className='pl-[12px]'>{username}</p>
            </div>
            <TextareaAutosize
                value={message}
                style={{ resize: 'none', outline: 'none' }}
                onChange={(e) => onChangeParentComment(e.target.value)}
                minRows={5}
                disabled={isLoading}
                placeholder={locals.chat_input.placeholder}
                className="text-[14px] scrollbar-hide w-full rounded-[8px] p-[8px] border-[#BECEFF] border"
            />
            <CustomLinkButton isDisabled={isLoading} isLoading={isLoading} text={locals.chat_input.add_btn} handleOnClick={submitParentComment} />
        </div>
    )
}

export default MessageInput