export function formatTimestampToReadableDate({ isoDateStr, delimiter = '-' }) {
    try {
        const date = new Date(isoDateStr);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${day}${delimiter}${month}${delimiter}${year}`;
    } catch (error) {
        console.log(error);
        return 'error occured while computing timestamp'
    }
}
