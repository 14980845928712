import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RenderList from '../render/RenderList';
import RenderObjectData from '../render/RenderObjectData';
import ResponsiveHeadingFourInter from '../../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter';
import RenderComplexData from '../render/RenderComplexData';
import RenderTitleDescription from '../render/RenderTitleDescription';
import TitleDescription from '../../help-guide/subcomponents/TitleDescription';

const ListTitleDescription = ({ list }) => {
    const navigate = useNavigate();
    const { topicid, catgid } = useParams() || {};

    const handleOnClick = (qaid) => {
        navigate(`/product/home/resource-guide/${topicid}/${catgid}/${qaid}`)
    }
    return (
        <div className='border border-[#bcbcbd] border-t-[4px] border-t-p3 rounded-md px-[24px] divide-y-2'>
            {
                list.map(({ title, answer, _id }, index) => {
                    return (
                        <TitleDescription
                            key={index}
                            title={title}
                            description={answer.description ?? answer.description1}
                            className="py-[40px]"
                            isClampText={true}
                            handleOnClick={() => handleOnClick(_id)}
                        />
                    )
                })
            }
        </div>
    )
}

export default ListTitleDescription


{/* <RenderTitleDescription
                            key={index}
                            qaId={_id}
                            title={title}
                            className='py-[1rem] flex flex-col gap-y-[8px]'
                            description={answer.description1 ?? answer.description}
                            handleOnClick={() => handleOnClick(_id)}
                        /> */}