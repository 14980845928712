import React from 'react'
//single_or_overall_formCheck
const useErrorCheckBMOther = () => {

    function CheckErrorExists({ formItem, CompleteSliceData, RevenueSliceData_Prod_Catg, projection_duration, type }) {
        let isFormFilled = false; // form filled;
        if(!!formItem===false) return true;
        if ("table" in formItem) {
            if (formItem.name === "net_payment_term_customer") {
                isFormFilled = false;
            }
            if (formItem.name === "annual_inventory_days_product_category") {
                RevenueSliceData_Prod_Catg.map((d) => {
                    for (const prop of Object.entries(d)) {
                        const yearNumber = Number(prop[0]?.slice(-1));
                        if (isNaN(yearNumber) === false) {
                            if (yearNumber <= projection_duration) {
                                if (prop[1] === null) isFormFilled = true;
                            }
                        }
                    }
                })
            }
            if (["future_loan_avail", "outstanding_loan_incur", "cashflow_equity_receive"].includes(formItem.name)){
                CompleteSliceData[formItem.name].map((row) => {
                    const { loan_name, equity_investment_amount } = row;
                    for(const prop of Object.entries(row)){
                        if((!!loan_name || !!equity_investment_amount)){
                            if(prop[1]===null) isFormFilled = true;
                        }
                    }
                })
            }
            if (["balance_sheet_working_capital_days_target", "balance_sheet_opening_balance"].includes(formItem.name)){
                CompleteSliceData[formItem.name].map((row, index) => {
                    let itr = 0;
                    for(const prop of Object.entries(row)){
                        if(formItem.name==="balance_sheet_working_capital_days_target"){
                            if(itr<projection_duration){
                                if(prop[1]===null) isFormFilled = true;
                            }
                            itr++
                        }else {
                            if(prop[1]===null) isFormFilled = true;
                        }
                    }
                })
            }
        } else if (formItem.is_mandatory) {
            isFormFilled = CompleteSliceData[formItem.name] === null
        }
        // console.log(isFormFilled)
        return isFormFilled;
    }
    
    const SingleFormCheck = ({ formItem, CompleteSliceData, RevenueSliceData_Prod_Catg, projection_duration }) => {
        return CheckErrorExists({ formItem, CompleteSliceData, RevenueSliceData_Prod_Catg, projection_duration, type: "single" });
    }
    const OverallFormCheck = ({ overAll_form, CompleteSliceData, RevenueSliceData_Prod_Catg, projection_duration, monthYearValues }) => {
        let formFilledStatus = true;
        overAll_form.map((form) => {
            let updatedFormItem = form;
            if(form.name === "annual_inventory_days_product_category"){
                updatedFormItem = { ...form, "table": { "columns": [form.table.columns[0], ...Array.from({ length: monthYearValues.length }).map((_, i) => ({...form.table.columns[1], "data_index": `year${i+1}`, "title": monthYearValues[i]}))] } }
            }
            let formStatus = CheckErrorExists({ formItem: form, CompleteSliceData, RevenueSliceData_Prod_Catg, projection_duration, type: "overall" })
            if (formStatus === true) formFilledStatus = false;
        })
        return formFilledStatus;
    }

    return { SingleFormCheck, OverallFormCheck };

}

export default useErrorCheckBMOther