import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api/api";
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import routesConfig from "../../services/config/routesConfig.json";
import { segregateSelectOption } from "./functionality/RevenueOnboarding/segregateSelectOption";
import businessmodalLocals from "../../localization/localization_en_business_modal.json"
import { updateDependentProductCategory } from "./functionality/RevenueOnboarding/updateDependentProductCategory";
import { product_category, buy, defaultRevenueNewRowData, manufacture, product_category_1, product_category_2, RO_product_category_1, RO_product_category_2 } from "./data/data";
import { generateIndividualRow, generatePlatformCommissionCost, platformCommissionCostProp, generateSocialMediaMarketingPlaceData, generateUserAcquisitionEngagementRetentionRows, filterBasedOnSelection, percentAndAvgForSmAndECommerProp, socialMediaMarketingPlaceDataProp, generateDefaultKeyDetailsData, default_total_mau_and_registered_users } from "./functionality/RevenueOnboarding/generateDefaultData";
import { findDuplicateTargetAndFilter, generateMonth, updateTargetTableValue } from "./functionality/RevenueOnboarding/revenueOnboardingUtiliy";
import { generateApiTraceInitialState } from "./functionality/RevenueOnboarding/revenueOnboardingUtiliy";
import { generateDefaultProductCategory } from "./data/data";
import { v4 as uuidv4 } from "uuid";
import { months } from "./data/data";
import { formatDependencyData, revenueScreenSixDataInjection, revenueScreenFiveDataInjection, revenueScreenFourDataInjection, revenueScreenOneDataInjection, revenueScreenThreeDataInjection, revenueScreenTwoDataInjection, traceComponentRendering } from "./utility/revenueScreenDataInjection";
import { resetRevenueScreenData } from "./utility/resetRevenueScreenData";
import { getRevenueScreenDataReferenceTitle } from "./constants/getScreenDataReferenceTitle";
import { filterDuplicateObjectProp } from "../../utils/filterDuplicateObjectProp";
import { D_Store_GlobalSettingData } from "./globalSettingSlice";

const stepperValueMapping = { 0: -5, 25: -2.5, 50: 0, 75: 2.5, 100: 5 }

export const API_Post_Screen1_Data = createAsyncThunk(
    "revenueOnboarding/API_Post_Screen1_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-revenue-screen-one');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_Screen2_Data = createAsyncThunk(
    "revenueOnboarding/API_Post_Screen2_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-revenue-screen-two');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_Screen3_Data = createAsyncThunk(
    "revenueOnboarding/API_Post_Screen3_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-revenue-screen-three');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_Screen4_Data = createAsyncThunk(
    "revenueOnboarding/API_Post_Screen4_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-revenue-screen-four');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_Screen5_Data = createAsyncThunk(
    "revenueOnboarding/API_Post_Screen5_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-revenue-screen-five');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Output_Screen1_Data = createAsyncThunk(
    "revenueOnboarding/API_Process_Output_Screen1_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-revenue-screen-one');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Output_Screen2_Data = createAsyncThunk(
    "revenueOnboarding/API_Process_Output_Screen2_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-revenue-screen-two');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Output_Screen3_Data = createAsyncThunk(
    "revenueOnboarding/API_Process_Output_Screen3_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-revenue-screen-three');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Output_Screen4_Data = createAsyncThunk(
    "revenueOnboarding/API_Process_Output_Screen4_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-revenue-screen-four');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Output_Screen5_Data = createAsyncThunk(
    "revenueOnboarding/API_Process_Output_Screen5_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-revenue-screen-five');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Output_Screen6_Data = createAsyncThunk(
    "revenueOnboarding/API_Process_Output_Screen6_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-revenue-screen-six');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_REVENUE_ONBOARDING_SCREEN_SLICE = createAsyncThunk(
    "revenueOnboarding/API_GET_REVENUE_ONBOARDING_SCREEN_SLICE",
    async (type, { getState, dispatch }) => {
        try {
            if (!["prev", "next"].includes(type)) return;
            const state = getState();
            const MODE_TYPES = state.businessModal.MODE_TYPE;
            const activeScreen = state.revenueOnboarding.revenueOnboardingData.activeScreenNumber;
            const traceComponentRendering = state.revenueOnboarding.revenueOnboardingData.trackComponentRendering;
            const getDataForTargetScreenName = type === "prev"
                ? traceComponentRendering[activeScreen] === 0 ? traceComponentRendering[activeScreen] : traceComponentRendering[activeScreen - 1]
                : traceComponentRendering[activeScreen] === traceComponentRendering.length - 1 ? traceComponentRendering[activeScreen] : traceComponentRendering[activeScreen + 1]
            const screenRefernce = getRevenueScreenDataReferenceTitle;
            const paramsData = {
                "businessModelId": state.businessModal.active_BusinessModal_Id,
                "screenId": screenRefernce[String(getDataForTargetScreenName)]
            };
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-business-model-screen-data');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "params": paramsData };
                const response = await api.request(urlConfig);
                dispatch(D_Store_GlobalSettingData({ "settingMetric": response.data.screenData.globalSettings }));
                return { 
                    ...response?.data,
                    "info": { "activeScreen": getDataForTargetScreenName, "screenReference": screenRefernce[String(getDataForTargetScreenName)] },
                    "screenNumberStatus": {
                        "activeScreen": getDataForTargetScreenName,
                        "previousScreen": traceComponentRendering[activeScreen]
                    },
                    "MODE_TYPES": MODE_TYPES
                };
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_Product_Category_List = createAsyncThunk(
    "revenueScreen/API_Fetch_Product_Category_List",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-product-category-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Create_Product_Category = createAsyncThunk(
    "revenueScreen/API_Create_Product_Category",
    async (product_category) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-product-category');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: { "productCategoryTitle": product_category } }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            console.log(error,"error")
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Patch_Product_Category = createAsyncThunk(
    "revenueScreen/API_Patch_Product_Category",
    async (patchProductCategoryData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-product-category');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": patchProductCategoryData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_Product_Category_By_Id = createAsyncThunk(
    "revenueScreen/API_Fetch_Product_Category_By_Id_List",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-product-category-by-id');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_FETCH_DEFAULT_CHANNEL_LIST_Data = createAsyncThunk(
    "revenueScreen/API_FETCH_DEFAULT_CHANNEL_LIST_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-default-marketplace-channels-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_FETCH_CHANNEL_LIST_Data = createAsyncThunk(
    "revenueScreen/API_FETCH_CHANNEL_LIST_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-marketplace-channel-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_CHANNEL_LIST_Data = createAsyncThunk(
    "revenueScreen/API_CREATE_CHANNEL_LIST_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-marketplace-channel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_PATCH_CHANNEL_LIST_Data = createAsyncThunk(
    "revenueScreen/API_PATCH_CHANNEL_LIST_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-marketplace-channel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);
const locals = businessmodalLocals.business_modal.revenue;

const initiaProductCategory = generateDefaultProductCategory(3);
const initialManufacturerData = locals.form[1].data[0].table["Manufacture"]["table"].default_rows
const initialBuyData = locals.form[1].data[0].table["Buy"]["table"].default_rows

const SUPPLY_CHAIN_TYPES = ["buy", "manufacture", "both"]
const PRODUCT_CATEGORY_2 = { "manufacture": RO_product_category_2(), "buy": [] }


const apiTraceInitialState = {
    counter: 0,
    status: generateApiTraceInitialState(6),
    "fetch_screen_slice_status": { status: 'idle', message: null }
}

const hitSaveButtonClick = {
    "screen0": 0,
    "screen1": 0,
    "screen2": 0,
    "screen3": 0,
    "screen4": 0,
    "screen5": 0
}

const INITIAL_OUTPUT = {
    "0": null,
    "1": null,
    "2": null,
    "3": null,
    "4": null,
    "5": null
}

const revenueOnboardingInitialState = {
    "activeScreenNumber": 0,
    "previousScreenNumber": 0,
    "channel_data_list": [],
    "api_status": apiTraceInitialState,
    "api_status_category": { 
        "product_category": { "status": 'idle', "message": null, "httpType": null},
        "channel_name": { "status": 'idle', "message": null, "httpType": null}
    },
    "category_list": {
        "product_category": [],
        "channel_name": [],
        "default_channel_name": []
    },
    "output": INITIAL_OUTPUT,
    "traceHitSaveCount": hitSaveButtonClick,
    "outputSliderValues": {
        0: { prevValue: 50, nextValue: 50 },
        1: { prevValue: 50, nextValue: 50 },
        2: { prevValue: 50, nextValue: 50 },
        3: { prevValue: 50, nextValue: 50 },
        4: { prevValue: 50, nextValue: 50 },
        5: { prevValue: 50, nextValue: 50 }
    },

    "isSaveActive": true,
    "isGenerateOutputActive": false,
    "isTopLevelBackButtonVisibility": true,
    "isBottomLevelButtonVisibility": false,

    "marketing_spend_span": "last_month",
    "last_month": [],
    "last_6_month": [],

    "marketing_spend_per_sales_channel": {
        type: "last_month_marketing_spend",  //last_six_months, last_month_marketing_spend
    },


    "manufacture_or_buy": null,
    "first_month": null,
    "first_year": null,
    "product_categories_details": {
        "manufacture": generateDefaultProductCategory(3, null, "manufacture"),
        "buy": []
    },




    "isShowOuput": false,
    "product_category_active_tab": 0,
    "global_setting": {
        "projection_duration": 5,
        "decimal_places": 0
    },
    "isShowError": false,
    "checkIsFormFilled": {
        counter: 0
    },
    "trackComponentRendering": [0, 1, 2, 3, 4, 5],
    "marketing_spend_per_channel_fiscal_year": null,
    "channel_sales": [],
    "acquiring_app_users": [],
    "acquiringUserOptions": [],
    "supply_chain_selection": null,
    "manufacture": initialManufacturerData,
    "buy": initialBuyData,
    "isScreen2InputFieldDisabled": true,
    // "product_category_2_selection": null,
    "product_category_1": {
        "manufacture": generateDefaultProductCategory(2, new Date().getFullYear(), "manufacture"),
        "buy": []
    },
    // "product_category_2": RO_product_category_2(),
    "fiscal_year_screen2_product_category": null,
    "business_contribution": PRODUCT_CATEGORY_2,
    "returns_cancellations": PRODUCT_CATEGORY_2,
    "quantity_per_order": PRODUCT_CATEGORY_2,
    "marketing_spend_conversion": null,
    "platform_commission_cost": [],
    "social_media_marketplaces": [],
    "organic_paid_traffic_social_media_marketplaces": [{ "fixed_percentage": null, "varying_cost_over_time": null }],
    "organic_traffic_conversion_rate": [{ "fixed_percentage": null, "varying_cost_over_time": null }],
    "key_details_user_acquisition_engagement": generateDefaultKeyDetailsData(),
    "social_media": [percentAndAvgForSmAndECommerProp],
    "e_commerce": [percentAndAvgForSmAndECommerProp],
    "word_of_mouth_1": [percentAndAvgForSmAndECommerProp],
    "word_of_mouth_2": [percentAndAvgForSmAndECommerProp],
    "total_mau_and_registered_users": default_total_mau_and_registered_users,
    "stepperPredictionApiStatus": 0,
    "isAddProductCategoryModalOpen": false,
    "traceKeyNameFromGetScreenData": null,
    "categoryList": []
}

const RevenueOnboardingSlice = createSlice({
    name: "revenue-onboarding",
    initialState: {
        revenueOnboardingData: revenueOnboardingInitialState
    },
    reducers: {
        D_CONTROL_PRODUCT_CATEGORY_MODAL_OPEN(state, action) {
            state.revenueOnboardingData.isAddProductCategoryModalOpen = action.payload;
        },
        D_RESET_ERROR_MESSAGE(state, action){
            if(action.payload?.type in state.revenueOnboardingData.api_status_category){
                state.revenueOnboardingData.api_status_category[action.payload.type] = { "status": 'idle', "message": null }
            }
        },
        D_Increement_SalesMargin(state, action) {
            // const { value } = action.payload;
            const sliderValue = state.revenueOnboardingData.outputSliderValues[state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber]]
            const prevValue = stepperValueMapping[sliderValue.prevValue];
            const nextValue = stepperValueMapping[sliderValue.nextValue];
            state.revenueOnboardingData.product_categories_details.manufacture = state.revenueOnboardingData.product_categories_details.manufacture.map((prodCatg) => {
                let value = Number(prodCatg.sales_margin_percent);
                value = value - prevValue;
                value = value + nextValue;
                return { ...prodCatg, "sales_margin_percent": value }
            })
            state.revenueOnboardingData.product_categories_details.buy = state.revenueOnboardingData.product_categories_details.buy.map((prodCatg) => {
                let value = Number(prodCatg.sales_margin_percent);
                value = value - prevValue;
                value = value + nextValue;
                return { ...prodCatg, "sales_margin_percent": value }
            })
            state.revenueOnboardingData.stepperPredictionApiStatus = "saveStepper";
        },
        D_Trace_StepperPredictionApiStatus(state, action) {
            state.revenueOnboardingData.stepperPredictionApiStatus = action.payload;
        },
        D_Reset_Revenue_Onboarding_State(state, action) {
            const resetFullOrHalfState = action.payload;
            if (resetFullOrHalfState === 'half') {
                state.revenueOnboardingData = {
                    ...revenueOnboardingInitialState,
                    "product_categories_details": state.revenueOnboardingData.product_categories_details,
                    "channel_sales": state.revenueOnboardingData.channel_sales,
                    "marketing_spend_per_sales_channel": state.revenueOnboardingData.marketing_spend_per_sales_channel,
                    "marketing_spend_per_channel_fiscal_year": state.revenueOnboardingData.marketing_spend_per_channel_fiscal_year
                };
            } else {
                state.revenueOnboardingData = revenueOnboardingInitialState;
            }
        },
        D_Control_Toggle_Screen(state, action) {
            state.revenueOnboardingData.activeScreenNumber = action.payload;
        },
        D_Patch_Business_Modal_Fetch_Data(state, action) {
            const { data, productCatgeory, channelCategorylist } = action.payload;
            const trackComponentRendering = traceComponentRendering({ "db_channels_of_sales": data?.revenueScreenOneInput?.channels_of_sales });
            state.revenueOnboardingData.trackComponentRendering = trackComponentRendering
            if (!!data?.revenueScreenOneInput) {
                const screenOneResponse = revenueScreenOneDataInjection({ "input": data?.revenueScreenOneInput, "output": data?.revenueScreenOneOutput })
                const {
                    channel_sales,
                    marketing_spend_per_sales_channel,
                    marketing_spend_per_channel_fiscal_year,
                    acquiring_app_users,
                    acquiringUserOptions,
                    output
                } = screenOneResponse || {};
                if (Object.keys(screenOneResponse).length > 0) {
                    state.revenueOnboardingData.acquiringUserOptions = acquiringUserOptions;
                    state.revenueOnboardingData = {
                        ...state.revenueOnboardingData,
                        channel_sales,
                        marketing_spend_per_sales_channel,
                        marketing_spend_per_channel_fiscal_year,
                        acquiring_app_users,
                        output: { ...state.revenueOnboardingData.output, 0: output }
                    }
                }
            }
            if (!!data?.revenueScreenTwoInput) {
                const screenTwoResponse = revenueScreenTwoDataInjection({ "input": data?.revenueScreenTwoInput, "output": data?.revenueScreenTwoOutput, productCatgeory })
                const {
                    manufacture_or_buy,
                    product_categories_details,
                    output
                } = screenTwoResponse || {};
                if (Object.keys(screenTwoResponse).length > 0) {
                    state.revenueOnboardingData = {
                        ...state.revenueOnboardingData,
                        manufacture_or_buy,
                        product_categories_details,
                        output: { ...state.revenueOnboardingData.output, 1: output }
                    }
                }
            }
            if (trackComponentRendering?.includes(2) && !!data?.revenueScreenThreeInput) {
                // platform_commission_cost: data?.revenueScreenThreeInput?.platform_commission_cost, --> unused, since we are using data from screen2 iteself.
                const screenThreeResponse = revenueScreenThreeDataInjection({ "screen2Input": data?.revenueScreenTwoInput, "screen3Input": data?.revenueScreenThreeInput, productCatgeory, "output": data?.revenueScreenThreeOutput })
                const {
                    marketing_spend_conversion,
                    product_categories_details,
                    output
                } = screenThreeResponse || {}
                if (Object.keys(screenThreeResponse).length > 0) {
                    state.revenueOnboardingData = {
                        ...state.revenueOnboardingData,
                        marketing_spend_conversion,
                        product_categories_details,
                        output: { ...state.revenueOnboardingData.output, 2: output }
                    }
                }
            }
            if (trackComponentRendering?.includes(3) && !!data.revenueScreenFourInput) {
                const screenFourResponse = revenueScreenFourDataInjection({ "input": data?.revenueScreenFourInput, "output": data?.revenueScreenFourOutput, channelCategorylist })
                const {
                    social_media_marketplaces,
                    organic_paid_traffic_social_media_marketplaces,
                    organic_traffic_conversion_rate,
                    output
                } = screenFourResponse || {};
                if (Object.keys(screenFourResponse).length > 0) {
                    state.revenueOnboardingData = {
                        ...state.revenueOnboardingData,
                        social_media_marketplaces,
                        organic_paid_traffic_social_media_marketplaces,
                        organic_traffic_conversion_rate,
                        "output": {
                            ...state.revenueOnboardingData.output,
                            3: output
                        }
                    }
                }
            }
            if (trackComponentRendering?.includes(4) && !!data?.revenueScreenFiveInput) {
                const screenFiveResponse = revenueScreenFiveDataInjection({ "input": data?.revenueScreenFiveInput, "output": data?.revenueScreenFiveOutput, "channel_sales": state.revenueOnboardingData.channel_sales, "acquiring_app_users": state.revenueOnboardingData.acquiring_app_users })
                const {
                    key_details_user_acquisition_engagement,
                    social_media,
                    e_commerce,
                    word_of_mouth_1,
                    word_of_mouth_2,
                    total_mau_and_registered_users,
                    output
                } = screenFiveResponse || {};
                if (Object.keys(screenFiveResponse).length > 0) {
                    state.revenueOnboardingData = {
                        ...state.revenueOnboardingData,
                        key_details_user_acquisition_engagement,
                        social_media,
                        e_commerce,
                        word_of_mouth_1,
                        word_of_mouth_2,
                        total_mau_and_registered_users,
                        "output": {
                            ...state.revenueOnboardingData.output,
                            4: output
                        }
                    }
                }
            }
            if (!!data?.revenueScreenSixOutput) {
                const screenSixResponse = revenueScreenSixDataInjection({ "output": data?.revenueScreenSixOutput })
                if (Object.keys(screenSixResponse).length > 0) {
                    state.revenueOnboardingData = {
                        ...state.revenueOnboardingData,
                        output: {
                            ...state.revenueOnboardingData.output,
                            5: screenSixResponse
                        }
                    }
                }
            }
        },
        D_Patch_SliderValue(state, action) {
            const { screenNumber, value } = action.payload;
            state.revenueOnboardingData.outputSliderValues = { ...state.revenueOnboardingData.outputSliderValues, [screenNumber]: { "prevValue": state.revenueOnboardingData.outputSliderValues[screenNumber].nextValue, "nextValue": value } }
        },
        D_Button_Controls(state, action) {
            const { type, value } = action.payload
            if (type === "resetTopButton") {
                state.revenueOnboardingData = { ...state.revenueOnboardingData, "isSaveActive": true, "isGenerateOutputActive": false, "isTopLevelBackButtonVisibility": true }
                return;
            }
            if (["isSaveActive", "isGenerateOutputActive", "isBottomLevelButtonVisibility"].includes(type)) {
                state.revenueOnboardingData[type] = value;
            }
        },
        D_Reset_Complete_Api_Status(state) {
            state.revenueOnboardingData.api_status = apiTraceInitialState
        },
        D_Reset_Api_Status(state, action) {
            const { httpType } = action.payload;
            const { activeScreenNumber, api_status, trackComponentRendering } = state.revenueOnboardingData;
            state.revenueOnboardingData.api_status.status = {
                ...api_status.status,
                [trackComponentRendering[activeScreenNumber]]: { ...api_status.status[trackComponentRendering[activeScreenNumber]], [httpType]: { status: 'idle', message: null } }
            }
        },
        D_Handle_Tab_Change(state, action) {
            state.revenueOnboardingData.product_category_active_tab = action.payload;
        },
        D_Inject_Global_Setting(state, action) {
            state.revenueOnboardingData.global_setting = action.payload;
            const { business_contribution, returns_cancellations, quantity_per_order } = state.revenueOnboardingData;
            const revenueProjectionDuration = state.revenueOnboardingData.global_setting.projection_duration;
            const globalSettingProjectionDuration = action.payload.projection_duration
            state.revenueOnboardingData.channel_data_list = action.payload.channelListData
            if (revenueProjectionDuration !== globalSettingProjectionDuration) {
                state.revenueOnboardingData.business_contribution = business_contribution.map((data) => ({ ...data, "year4": null, "year5": null }));
                state.revenueOnboardingData.returns_cancellations = returns_cancellations.map((data) => ({ ...data, "year4": null, "year5": null }));
                state.revenueOnboardingData.quantity_per_order = quantity_per_order.map((data) => ({ ...data, "year4": null, "year5": null }));
            }
        },
        trackCounter(state, action) {
            const { type } = action.payload;
            const { counter } = state.revenueOnboardingData.checkIsFormFilled
            state.revenueOnboardingData.checkIsFormFilled.counter = type === "reset" ? 0 : counter + 1
        },
        toggleShowError(state, action) {
            state.revenueOnboardingData.isShowError = action.payload;
        },
        storeInputValue: (state, action) => {
            const { name, value } = action.payload;
            state.revenueOnboardingData.isSaveActive = true;
            state.revenueOnboardingData[name] = value;
        },
        toggleScreen: (state, action) => {
            const { type, totalNumberOfScreen } = action.payload;
            const { activeScreenNumber, product_categories_details, marketing_spend_per_channel_fiscal_year, traceHitSaveCount, trackComponentRendering } = state.revenueOnboardingData;
            state.revenueOnboardingData.previousScreenNumber = activeScreenNumber;
            const ACTIVE_SCREEN = trackComponentRendering[activeScreenNumber + 1];
            const PREVIOUS_SCREEN = trackComponentRendering[activeScreenNumber];
            const isSaveActive = type === "prev" ? false : !(traceHitSaveCount[`screen${ACTIVE_SCREEN}`] >= traceHitSaveCount[`screen${PREVIOUS_SCREEN}`])
            state.revenueOnboardingData = { ...state.revenueOnboardingData, isSaveActive, isGenerateOutputActive: false, isBottomLevelButtonVisibility: false, isShowOuput: false }
            if (type === "prev") {
                if (activeScreenNumber != 0) {
                    state.revenueOnboardingData.activeScreenNumber = activeScreenNumber - 1;
                }
            } else {
                if (activeScreenNumber != totalNumberOfScreen) {
                    state.revenueOnboardingData.activeScreenNumber = activeScreenNumber + 1;
                    const presentScreenOutputCheck = !!state.revenueOnboardingData.output[state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber]]
                    state.revenueOnboardingData.isSaveActive = presentScreenOutputCheck ? false : true;
                    state.revenueOnboardingData.isGenerateOutputActive = presentScreenOutputCheck ? false : !state.revenueOnboardingData.isSaveActive
                }
                const KForm = locals.form.slice(2, 5)
                const activeChannelInput = state.revenueOnboardingData.channel_sales;
                let updatedRenderingComponent = [];
                KForm.forEach((form, index) => { // dynamically 
                    if (activeChannelInput.includes(form.title)) {//render form page
                        updatedRenderingComponent.push(2 + index);
                    } else { // reset form data bcz this formItem is deselected in screen1 q1 and q3
                        if (form.title === "E-Commerce") {
                            state.revenueOnboardingData = { ...state.revenueOnboardingData, "marketing_spend_conversion": null, "platform_commission_cost": [] }
                        } else if (form.title === "Social Media") {
                            state.revenueOnboardingData = { ...state.revenueOnboardingData, "social_media_marketplaces": generateSocialMediaMarketingPlaceData(state.revenueOnboardingData.channel_data_list), "organic_paid_traffic_social_media_marketplaces": [{ "fixed_percentage": null, "varying_cost_over_time": null }], "traffic_conversion_rate": [{ "fixed_percentage": null, "varying_cost_over_time": null }] }
                        } else if (form.title === "Website & Mobile App") {
                            state.revenueOnboardingData = { ...state.revenueOnboardingData, "key_details_user_acquisition_engagement": [], "sm_buyer": [percentAndAvgForSmAndECommerProp], "e_commerce_buyer": [percentAndAvgForSmAndECommerProp], "word_of_mouth_1": [percentAndAvgForSmAndECommerProp], "word_of_mouth_2": [percentAndAvgForSmAndECommerProp] }
                        }
                    }
                })
                state.revenueOnboardingData.trackComponentRendering = Array.from(new Set([0, 1, ...updatedRenderingComponent, 5])).sort((a, b) => a - b);
                if (state.revenueOnboardingData.activeScreenNumber === 1) { // inject 0 for screen1 q2 last_month_marketing_spend for null values
                    if (state.revenueOnboardingData.marketing_spend_per_sales_channel.type === "last_month_marketing_spend") {
                        for (let channel of Object.entries(state.revenueOnboardingData.marketing_spend_per_sales_channel)) {
                            if (["E-Commerce", "Social Media", "Website & Mobile App"].includes(channel[0])) {
                                const { mom_spend_growth_percent } = channel[1]
                                state.revenueOnboardingData.marketing_spend_per_sales_channel = {
                                    ...state.revenueOnboardingData.marketing_spend_per_sales_channel,
                                    [channel[0]]: { ...state.revenueOnboardingData.marketing_spend_per_sales_channel[channel[0]], "mom_spend_growth_percent": !!mom_spend_growth_percent ? mom_spend_growth_percent : 0 }
                                }
                            }
                        }
                    }
                    const year = Number(marketing_spend_per_channel_fiscal_year?.split('-')?.[0])
                    state.revenueOnboardingData.product_categories_details.manufacture = product_categories_details.manufacture.map((data) => {
                        return {
                            ...data,
                            business_contribution: data?.business_contribution?.map((subData) => ({ ...subData, year })),
                            returns_cancellations: data?.returns_cancellations?.map((subData) => ({ ...subData, year })),
                            quantity_per_order: data?.quantity_per_order?.map((subData) => ({ ...subData, year }))
                        }
                    })
                    state.revenueOnboardingData.product_categories_details.buy = product_categories_details.buy.map((data) => {
                        return {
                            ...data,
                            business_contribution: data?.business_contribution?.map((subData) => ({ ...subData, year })),
                            returns_cancellations: data?.returns_cancellations?.map((subData) => ({ ...subData, year })),
                            quantity_per_order: data?.quantity_per_order?.map((subData) => ({ ...subData, year }))
                        }
                    })
                    if (!state.revenueOnboardingData["channel_sales"].includes("Website & Mobile App")) { // reset screen data
                        state.revenueOnboardingData["key_details_user_acquisition_engagement"] = generateDefaultKeyDetailsData()
                        state.revenueOnboardingData = {
                            ...state.revenueOnboardingData, social_media: [percentAndAvgForSmAndECommerProp], e_commerce: [percentAndAvgForSmAndECommerProp], word_of_mouth_1: [percentAndAvgForSmAndECommerProp], word_of_mouth_2: [percentAndAvgForSmAndECommerProp]
                        }
                    } else {
                        const checkIsAllDataVisible = state.revenueOnboardingData.key_details_user_acquisition_engagement?.filter((row) => row.visible === true).length;
                        if (state.revenueOnboardingData.acquiring_app_users.includes("Paid Marketing")) {
                            if (checkIsAllDataVisible === 6) return
                            state.revenueOnboardingData.key_details_user_acquisition_engagement = state.revenueOnboardingData.key_details_user_acquisition_engagement?.map((row) => ({ ...row, visible: true }));
                        } else {
                            if (checkIsAllDataVisible === 4) return
                            const slicedData = state.revenueOnboardingData.key_details_user_acquisition_engagement?.map((row, index) => index <= 1 ? { ...row, visible: false, fixed: null, varying_amount_over_time: null } : row)
                            state.revenueOnboardingData.key_details_user_acquisition_engagement = slicedData;
                        }
                        if (!state.revenueOnboardingData.acquiring_app_users.includes("Social Media Buyer")) state.revenueOnboardingData.social_media = [percentAndAvgForSmAndECommerProp]
                        if (!state.revenueOnboardingData.acquiring_app_users.includes("E-commerce Buyer")) state.revenueOnboardingData.e_commerce = [percentAndAvgForSmAndECommerProp]
                        if (!state.revenueOnboardingData.acquiring_app_users.includes("Word of Mouth")) {
                            state.revenueOnboardingData.word_of_mouth_1 = [percentAndAvgForSmAndECommerProp]
                            state.revenueOnboardingData.word_of_mouth_2 = [percentAndAvgForSmAndECommerProp]
                        }
                    }
                    if (!state.revenueOnboardingData["channel_sales"].includes("E-Commerce")) {
                        state.revenueOnboardingData.marketing_spend_conversion = null;
                        state.revenueOnboardingData.product_categories_details = {
                            manufacture: [...state.revenueOnboardingData.product_categories_details.manufacture.map((subdata) => ({ ...subdata, commission_cost: null, platform_commission_cost_varying_cost_over_time: null }))],
                            buy: [...state.revenueOnboardingData.product_categories_details.buy.map((subdata) => ({ ...subdata, commission_cost: null, platform_commission_cost_varying_cost_over_time: null }))]
                        }
                    }
                    if (!state.revenueOnboardingData["channel_sales"].includes("Social Media")) {
                        state.revenueOnboardingData.social_media_marketplaces = generateSocialMediaMarketingPlaceData(state.revenueOnboardingData.channel_data_list)
                        state.revenueOnboardingData.organic_paid_traffic_social_media_marketplaces = [{ "fixed_percentage": null, "varying_cost_over_time": null }]
                        state.revenueOnboardingData.organic_traffic_conversion_rate = [{ "fixed_percentage": null, "varying_cost_over_time": null }]
                    }
                }
                const RENDERED_COMPONENT_LIST = state.revenueOnboardingData.trackComponentRendering
                // console.log(RENDERED_COMPONENT_LIST[activeScreenNumber], "trace")
                switch (RENDERED_COMPONENT_LIST[activeScreenNumber]) {
                    case 1: {
                        state.revenueOnboardingData.product_categories_details.manufacture = state.revenueOnboardingData.product_categories_details.manufacture.map((prodCatg) => {
                            return {
                                ...prodCatg,
                                "varying_cost_over_time": !!prodCatg.varying_cost_over_time ? prodCatg.varying_cost_over_time : 0,
                                "business_contribution": prodCatg.business_contribution.map((subData) => ({ ...subData, value: !!subData.value ? subData.value : 0 })),
                                "returns_cancellations": prodCatg.returns_cancellations.map((subData) => ({ ...subData, value: !!subData.value ? subData.value : 0 })),
                                "quantity_per_order": prodCatg.quantity_per_order.map((subData) => ({ ...subData, value: !!subData.value ? subData.value : 0 })),
                            }
                        })
                        state.revenueOnboardingData.product_categories_details.buy = state.revenueOnboardingData.product_categories_details.buy.map((prodCatg) => {
                            return {
                                ...prodCatg,
                                "varying_cost_over_time": !!prodCatg.varying_cost_over_time ? prodCatg.varying_cost_over_time : 0,
                                "business_contribution": prodCatg.business_contribution.map((subData) => ({ ...subData, value: !!subData.value ? subData.value : 0 })),
                                "returns_cancellations": prodCatg.returns_cancellations.map((subData) => ({ ...subData, value: !!subData.value ? subData.value : 0 })),
                                "quantity_per_order": prodCatg.quantity_per_order.map((subData) => ({ ...subData, value: !!subData.value ? subData.value : 0 })),
                            }
                        })
                        break;
                    }
                    case 2: {
                        state.revenueOnboardingData.product_categories_details = {
                            manufacture: [...state.revenueOnboardingData.product_categories_details.manufacture.map((subdata) => ({ ...subdata, platform_commission_cost_varying_cost_over_time: !!subdata.platform_commission_cost_varying_cost_over_time ? subdata.platform_commission_cost_varying_cost_over_time : 0 }))],
                            buy: [...state.revenueOnboardingData.product_categories_details.buy.map((subdata) => ({ ...subdata, platform_commission_cost_varying_cost_over_time: !!subdata.platform_commission_cost_varying_cost_over_time ? subdata.platform_commission_cost_varying_cost_over_time : 0 }))]
                        }
                        break;
                    }
                    case 3: {
                        state.revenueOnboardingData.social_media_marketplaces = state.revenueOnboardingData.social_media_marketplaces.map((subdata) => ({ ...subdata, "varying_cost_over_time": !!subdata.varying_cost_over_time ? subdata.varying_cost_over_time : 0 }))
                        state.revenueOnboardingData.organic_paid_traffic_social_media_marketplaces = state.revenueOnboardingData.organic_paid_traffic_social_media_marketplaces.map((subdata) => ({ ...subdata, "varying_cost_over_time": !!subdata.varying_cost_over_time ? subdata.varying_cost_over_time : 0 }))
                        state.revenueOnboardingData.organic_traffic_conversion_rate = state.revenueOnboardingData.organic_traffic_conversion_rate.map((subdata) => ({ ...subdata, "varying_cost_over_time": !!subdata.varying_cost_over_time ? subdata.varying_cost_over_time : 0 }))
                        break;
                    }
                    case 4: {
                        state.revenueOnboardingData.key_details_user_acquisition_engagement = state.revenueOnboardingData.key_details_user_acquisition_engagement.map((subdata) => ({ ...subdata, "varying_amount_over_time": !!subdata.varying_amount_over_time ? subdata.varying_amount_over_time : 0 }))
                        state.revenueOnboardingData.social_media = state.revenueOnboardingData.social_media.map((subdata) => ({ ...subdata, "varying_over_time": !!subdata.varying_over_time ? subdata.varying_over_time : 0 }))
                        state.revenueOnboardingData.e_commerce = state.revenueOnboardingData.e_commerce.map((subdata) => ({ ...subdata, "varying_over_time": !!subdata.varying_over_time ? subdata.varying_over_time : 0 }))
                        state.revenueOnboardingData.word_of_mouth_1 = state.revenueOnboardingData.word_of_mouth_1.map((subdata) => ({ ...subdata, "varying_over_time": !!subdata.varying_over_time ? subdata.varying_over_time : 0 }))
                        state.revenueOnboardingData.word_of_mouth_2 = state.revenueOnboardingData.word_of_mouth_2.map((subdata) => ({ ...subdata, "varying_over_time": !!subdata.varying_over_time ? subdata.varying_over_time : 0 }))
                        break;
                    }
                }
            }
        },
        filterAcquiringUserOption: (state, action) => {
            const { name, select_options } = action.payload;
            const draftData = JSON.stringify(state.revenueOnboardingData.channel_sales);
            const result = segregateSelectOption(JSON.parse(draftData), select_options);
            if (result.length === 0) {
                state.revenueOnboardingData = { ...state.revenueOnboardingData, acquiringUserOptions: [], [name]: [] }
                return;
            }
            const data = result.filter((defaultOption) => defaultOption.default === true);
            if (data.length === 0) return;
            state.revenueOnboardingData = { ...state.revenueOnboardingData, acquiringUserOptions: result }
            if (state.revenueOnboardingData.channel_sales.includes('Website & Mobile App') && !state.revenueOnboardingData.acquiring_app_users.length) state.revenueOnboardingData.acquiring_app_users = ["Paid Marketing"]
        },
        handleRadioInputSelection: (state, action) => {
            const { key, value } = action.payload?.selection;
            const { product_categories_details, marketing_spend_per_channel_fiscal_year } = state.revenueOnboardingData;
            const year = Number(marketing_spend_per_channel_fiscal_year.split('-')[0])
            state.revenueOnboardingData[key] = value;
            state.revenueOnboardingData.isSaveActive = true;
            state.revenueOnboardingData.output[state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber]] = null;
            if (SUPPLY_CHAIN_TYPES.includes(value)) {
                state.revenueOnboardingData.isShowError = false;
                if (value === "both") {
                    state.revenueOnboardingData.product_categories_details = { "manufacture": generateDefaultProductCategory(2, year, "manufacture"), "buy": generateDefaultProductCategory(2, year, "buy") }
                    state.revenueOnboardingData.product_categories_details.manufacture = state.revenueOnboardingData.product_categories_details.manufacture.map((data) => {
                        return {
                            ...data,
                            business_contribution: data.business_contribution.map((subData) => ({ ...subData, year })),
                            returns_cancellations: data.returns_cancellations.map((subData) => ({ ...subData, year })),
                            quantity_per_order: data.quantity_per_order.map((subData) => ({ ...subData, year }))
                        }
                    })
                    state.revenueOnboardingData.product_categories_details.buy = state.revenueOnboardingData.product_categories_details.buy.map((data) => {
                        return {
                            ...data,
                            business_contribution: data.business_contribution.map((subData) => ({ ...subData, year })),
                            returns_cancellations: data.returns_cancellations.map((subData) => ({ ...subData, year })),
                            quantity_per_order: data.quantity_per_order.map((subData) => ({ ...subData, year }))
                        }
                    })
                    return;
                }
                const updatefreshAndDeleteData = value === "manufacture" ? { "manufacture": generateDefaultProductCategory(2, year, "manufacture"), "buy": [] } : { "manufacture": [], "buy": generateDefaultProductCategory(2, year, "buy") }
                state.revenueOnboardingData.product_categories_details = updatefreshAndDeleteData;
                state.revenueOnboardingData.product_categories_details.manufacture = state.revenueOnboardingData.product_categories_details.manufacture.map((data) => {
                    return {
                        ...data,
                        business_contribution: data.business_contribution.map((subData) => ({ ...subData, year })),
                        returns_cancellations: data.returns_cancellations.map((subData) => ({ ...subData, year })),
                        quantity_per_order: data.quantity_per_order.map((subData) => ({ ...subData, year }))
                    }
                })
                state.revenueOnboardingData.product_categories_details.buy = state.revenueOnboardingData.product_categories_details.buy.map((data) => {
                    return {
                        ...data,
                        business_contribution: data.business_contribution.map((subData) => ({ ...subData, year })),
                        returns_cancellations: data.returns_cancellations.map((subData) => ({ ...subData, year })),
                        quantity_per_order: data.quantity_per_order.map((subData) => ({ ...subData, year }))
                    }
                })
            }
            return;
        },
        D_HandleCheckBoxInputSelect: (state, action) => {
            const { name, value } = action.payload;
            const revenueOnboardingData = state.revenueOnboardingData;
            state.revenueOnboardingData.isSaveActive = true;
            if (revenueOnboardingData[name]?.includes(value)) {
                state.revenueOnboardingData[name] = revenueOnboardingData[name].filter((data) => data != value);
            } else {
                state.revenueOnboardingData[name] = [...state.revenueOnboardingData[name], value];
            }
        },
        toggleTableAttribute: (state) => {
            state.revenueOnboardingData.marketing_spend_per_sales_channel.type = state.revenueOnboardingData.marketing_spend_per_sales_channel.type === "last_month_marketing_spend" ? "last_six_months" : "last_month_marketing_spend"
            return;
            state.revenueOnboardingData.marketing_spend_span = state.revenueOnboardingData.marketing_spend_span === "last_month" ? "last_6_month" : "last_month";
            const targetData = state.revenueOnboardingData[state.revenueOnboardingData.marketing_spend_span];
            const activechannel = state.revenueOnboardingData.channel_sales;
            let newFilteredData = [];
            targetData.forEach((row) => {
                if (activechannel.includes(row.category)) {
                    newFilteredData.push(row);
                }
            })
            activechannel.forEach((channel) => {
                const isPresent = targetData.some((row) => channel.includes(row.category));
                if (!isPresent) {
                    const constructedData = generateIndividualRow(state.revenueOnboardingData.marketing_spend_span, channel);
                    newFilteredData.push(constructedData);
                }
            })
            state.revenueOnboardingData[state.revenueOnboardingData.marketing_spend_span] = newFilteredData;
        },
        D_UpdateMarketingSpendData: (state, action) => {
            // console.log(action.payload, "payload")
            state.revenueOnboardingData.marketing_spend_per_sales_channel = action.payload;
        },
        updateMarketingSpanTableRow: (state, action) => {
            const { spanType, updatedRows } = action.payload;
            state.revenueOnboardingData[spanType] = updatedRows;
        },
        addRowInRO: (state, action) => {
            const { targetFeature } = action.payload;
            const { product_categories_details, marketing_spend_per_channel_fiscal_year, social_media_marketplaces } = state.revenueOnboardingData;
            state.revenueOnboardingData.isSaveActive = true;
            state.revenueOnboardingData.output[state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber]] = null;
            if (SUPPLY_CHAIN_TYPES.includes(targetFeature)) {
                const pc = generateDefaultProductCategory(1, marketing_spend_per_channel_fiscal_year?.split('-')[0], targetFeature)
                product_categories_details[targetFeature].push(pc[0])
            }
            if (targetFeature === "social_media_marketplaces") {
                const sm_marketing_place_row = { key: uuidv4(), ...socialMediaMarketingPlaceDataProp }
                social_media_marketplaces.push(sm_marketing_place_row)
            }
            return;
        },
        removeRowInRO: (state, action) => {
            const { targetFeature, index } = action.payload;
            const { product_categories_details, social_media_marketplaces } = state.revenueOnboardingData;
            state.revenueOnboardingData.isSaveActive = true;
            state.revenueOnboardingData.output[state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber]] = null;
            if (SUPPLY_CHAIN_TYPES.includes(targetFeature)) {
                state.revenueOnboardingData.product_categories_details[targetFeature] = product_categories_details[targetFeature].filter((_, rowIndex) => rowIndex !== index)
                if (!state.revenueOnboardingData.product_categories_details[targetFeature].length) {
                    state.revenueOnboardingData.product_categories_details[targetFeature] = [{ key: uuidv4(), ...product_category() }]
                }
            }
            if (targetFeature === "social_media_marketplaces") {
                state.revenueOnboardingData.social_media_marketplaces = social_media_marketplaces.filter((row, i) => i !== index)
                if (!state.revenueOnboardingData.social_media_marketplaces.length) {
                    const sm_marketing_place_row = { key: uuidv4(), ...socialMediaMarketingPlaceDataProp }
                    state.revenueOnboardingData.social_media_marketplaces = [sm_marketing_place_row]
                }
            }
            return;
        },
        storeTableValue: (state, action) => {
            state.revenueOnboardingData.isSaveActive = true;
            state.revenueOnboardingData.output[state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber]] = null;
            const { name, value, rowNumber, dataIndex, attribute } = action.payload;
            const { product_categories_details, marketing_spend_per_sales_channel } = state.revenueOnboardingData;
            const additionalProductCategory = ["business_contribution", "returns_cancellations", "quantity_per_order"]
            if (["manufacture", "buy", "product_category_1", "platform_commission_cost", ...additionalProductCategory].includes(name)) {
                if (name === "product_category_1" || name === "platform_commission_cost" || additionalProductCategory.includes(name)) {
                    if (rowNumber < product_categories_details.manufacture.length) {
                        state.revenueOnboardingData.product_categories_details["manufacture"] = product_categories_details.manufacture.map((prodCatg, index) => {
                            if (index === rowNumber) {
                                if (additionalProductCategory.includes(name)) {
                                    return {
                                        ...prodCatg,
                                        [name]: prodCatg[name].map((subYear, i) => (Number(dataIndex.slice(-1)) === i + 1) ? { ...subYear, value } : subYear)
                                    }
                                } else {
                                    return { ...prodCatg, [dataIndex]: value }
                                }
                            }
                            return prodCatg;
                        })
                    } else {
                        state.revenueOnboardingData.product_categories_details["buy"] = product_categories_details.buy.map((prodCatg, index) => {
                            if ((product_categories_details.manufacture.length + index === rowNumber)) {
                                if (additionalProductCategory.includes(name)) {
                                    return {
                                        ...prodCatg,
                                        [name]: prodCatg[name].map((subYear, i) => (Number(dataIndex.slice(-1)) === i + 1) ? { ...subYear, value } : subYear)
                                    }
                                } else {
                                    return { ...prodCatg, [dataIndex]: value }
                                }
                            }
                            return prodCatg
                        })
                    }
                    return;
                }
                state.revenueOnboardingData.product_categories_details[name] = product_categories_details[name].map((prodCtg, index) => index === rowNumber ? ({ ...prodCtg, [dataIndex]: value }) : prodCtg)
            } else if (name === "marketing_spend_per_sales_channel") {
                if (marketing_spend_per_sales_channel.type === "last_six_months") {
                    state.revenueOnboardingData.marketing_spend_per_sales_channel = {
                        ...state.revenueOnboardingData.marketing_spend_per_sales_channel,
                        [attribute]: {
                            ...state.revenueOnboardingData.marketing_spend_per_sales_channel[attribute],
                            "last_six_months": state.revenueOnboardingData.marketing_spend_per_sales_channel[attribute].last_six_months.map((month, index) => (index === rowNumber) ? { ...month, spend_value: value } : month)
                        }
                    }
                    return;
                }
                state.revenueOnboardingData.marketing_spend_per_sales_channel = {
                    ...state.revenueOnboardingData.marketing_spend_per_sales_channel,
                    [attribute]: {
                        ...state.revenueOnboardingData.marketing_spend_per_sales_channel[attribute], [dataIndex]: value
                    }
                }
            } else {
                if (action.payload?.name === "key_details_user_acquisition_engagement") {
                    if (state.revenueOnboardingData.key_details_user_acquisition_engagement[0].visible === false) {
                        state.revenueOnboardingData[name] = state.revenueOnboardingData[name].map((row, index) => (index === rowNumber + 2) ? { ...row, [dataIndex]: value } : row)
                        return;
                    }
                }
                state.revenueOnboardingData[name] = state.revenueOnboardingData[name].map((row, index) => (index === rowNumber) ? { ...row, [dataIndex]: value } : row)
            }
        },
        D_Capture_Value_Working_Cap_Prod_Catg(state, action) {
            const { value, rowNumber, data_index } = action.payload
            const identifyManfOrBuy = rowNumber < state.revenueOnboardingData.product_categories_details.manufacture.length ? "manufacture" : "buy";
            if (!["year1", "year2", "year3", "year4", "year5"].includes(data_index)) return;
            state.revenueOnboardingData.product_categories_details[identifyManfOrBuy] = state.revenueOnboardingData.product_categories_details[identifyManfOrBuy].map((row, index) => {
                const updatedIndex = identifyManfOrBuy === "buy" ? state.revenueOnboardingData.product_categories_details.manufacture.length + index : index;
                if (updatedIndex === rowNumber) {
                    const updatedData = [{ ...row.annual_inventory_days_product_category[0], [data_index]: value }]
                    return { ...row, "annual_inventory_days_product_category": updatedData }
                } else {
                    return row;
                }
            });
        },
        D_Handle_On_Change_Select: (state, action) => {
            state.revenueOnboardingData.isSaveActive = true;
            const { dataIndex, name, rowIndex, value } = action.payload;
            const { product_categories_details, social_media_marketplaces } = state.revenueOnboardingData;
            state.revenueOnboardingData.isSaveActive = true;
            state.revenueOnboardingData.output[state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber]] = null;
            if (["manufacture", "buy"].includes(name)) {
                const alternateSupplyChain = name === "manufacture" ? "buy" : "manufacture";
                state.revenueOnboardingData.product_categories_details[name] = product_categories_details[name].map((catg, index) => (index === rowIndex) ? { ...catg, [dataIndex]: value, id: value } : catg)// update selected value
                state.revenueOnboardingData.product_categories_details[name] = product_categories_details[name].filter((catg, index) => ((index === rowIndex) ? true : (value === catg?.name) ? false : true)) // filter based on active supply chain name
                state.revenueOnboardingData.product_categories_details[alternateSupplyChain] = product_categories_details[alternateSupplyChain].filter((catg) => value !== catg.name) //filter based on opposite supply chain
            }
            if (name === "social_media_marketplaces") {
                state.revenueOnboardingData.social_media_marketplaces = social_media_marketplaces.map((data, index) => (index === rowIndex) ? { ...data, [dataIndex]: value } : data);
            }
            return;
        },
        D_On_Change_Date: (state, action) => {
            const { marketing_spend_per_sales_channel } = state.revenueOnboardingData;
            state.revenueOnboardingData[action.payload.type] = action.payload.value;
            if (!(!!action.payload.value)) {
                return;
            }
            const marketing_spend_per_channel_fiscal_year = state.revenueOnboardingData.marketing_spend_per_channel_fiscal_year
            const month_year = action.payload?.value?.split('-');
            if (!!action.payload.value && Array.isArray(month_year)) {
                const month = months[Number(month_year[1])].value;
                const year = Number(month_year[0]);
                // console.log("---start---")
                for (let channel of Object.entries(marketing_spend_per_sales_channel)) {
                    if (["E-Commerce", "Social Media", "Website & Mobile App"].includes(channel[0])) {
                        state.revenueOnboardingData.marketing_spend_per_sales_channel = {
                            ...state.revenueOnboardingData.marketing_spend_per_sales_channel,
                            [channel[0]]: {
                                ...state.revenueOnboardingData.marketing_spend_per_sales_channel[channel[0]],
                                "month": month, "year": year,
                                "last_six_months": state.revenueOnboardingData.marketing_spend_per_sales_channel[channel[0]].last_six_months.map((data, index) => ({ ...data, "year": year, month: generateMonth({ "startMonth": Number(marketing_spend_per_channel_fiscal_year.split('-')[1]), "seriesMonth": index, outputType: "value" }) }))
                            }
                        }
                    }
                }
                // console.log("---end---")
            }
        },
        D_Control_Rendering_Output: (state, action) => {
            state.revenueOnboardingData.isShowOuput = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_Fetch_Product_Category_List.pending, (state) => {
                state.revenueOnboardingData.api_status_category.product_category.status = "loading";
            })
            .addCase(API_Fetch_Product_Category_List.fulfilled, (state, action) => {
                state.revenueOnboardingData.api_status_category.product_category.status = "success";
                if (action.payload?.length) {
                    state.revenueOnboardingData.category_list.product_category = action.payload?.map((catg) => ({ title: catg.title, id: catg._id }))
                    state.revenueOnboardingData.category_list.product_category = filterDuplicateObjectProp({ list: state.revenueOnboardingData.category_list.product_category, targetProp: "id" })
                }
            })
            .addCase(API_Fetch_Product_Category_List.rejected, (state, action) => {
                state.revenueOnboardingData.api_status_category.product_category = { status: 'failed', message: action.error.message }
            })
            .addCase(API_Create_Product_Category.pending, (state) => {
                state.revenueOnboardingData.api_status_category.product_category.status = "loading";
            })
            .addCase(API_Create_Product_Category.fulfilled, (state, action) => {
                state.revenueOnboardingData.api_status_category.product_category.status = "success";
                if (action.payload?.title) { //title || titleLower
                    state.revenueOnboardingData.category_list.product_category = [...state.revenueOnboardingData.category_list.product_category, { title : action.payload.title, id: action.payload._id }]
                }
            })
            .addCase(API_Create_Product_Category.rejected, (state, action) => {
                state.revenueOnboardingData.api_status_category.product_category = { status: 'failed', message: action.error.message }
            })
            .addCase(API_Patch_Product_Category.pending, (state) => {
                state.revenueOnboardingData.api_status_category.product_category.status = "loading";
            })
            .addCase(API_Patch_Product_Category.fulfilled, (state, action) => {
                state.revenueOnboardingData.api_status_category.product_category.status = "success"
                state.revenueOnboardingData.category_list.product_category = state.revenueOnboardingData.category_list.product_category.map((catg) => {
                    return catg.id===action.payload.productCategoryUpdated._id ? {...catg, title: action.payload.productCategoryUpdated.title} : catg;
                })
            })
            .addCase(API_Patch_Product_Category.rejected, (state, action) => {
                state.revenueOnboardingData.api_status_category.product_category = { status: 'failed', message: action.error.message }
            })


            .addCase(API_FETCH_DEFAULT_CHANNEL_LIST_Data.pending, (state, action) => {
                state.revenueOnboardingData.api_status_category.channel_name.status = "loading";
            })
            .addCase(API_FETCH_DEFAULT_CHANNEL_LIST_Data.fulfilled, (state, action) => {
                state.revenueOnboardingData.api_status_category.channel_name.status = "success"
                const formattedChannelName = [...action.payload.map((defaultChannel) => ({ "title": defaultChannel.title, "id": defaultChannel._id, "isDefault": true })),...state.revenueOnboardingData.category_list.channel_name];
                state.revenueOnboardingData.category_list.channel_name = formattedChannelName
                state.revenueOnboardingData.category_list.channel_name = filterDuplicateObjectProp({ list: state.revenueOnboardingData.category_list.channel_name, targetProp: "id" })
                state.revenueOnboardingData.category_list.default_channel_name = filterDuplicateObjectProp({ list: formattedChannelName, targetProp: "id" })
            })
            .addCase(API_FETCH_DEFAULT_CHANNEL_LIST_Data.rejected, (state, action) => {
                state.revenueOnboardingData.api_status_category.channel_name = { status: 'failed', message: action.error.message }
            })
            .addCase(API_FETCH_CHANNEL_LIST_Data.pending, (state) => {
                state.revenueOnboardingData.api_status_category.channel_name.status = "loading";
            })
            .addCase(API_FETCH_CHANNEL_LIST_Data.fulfilled, (state, action) => {
                state.revenueOnboardingData.category_list.channel_name = [...state.revenueOnboardingData.category_list.channel_name, ...action.payload.map((catg) => ({"title": catg.title, "id": catg._id, "isDefault": false}))]
                state.revenueOnboardingData.category_list.channel_name = filterDuplicateObjectProp({ list: state.revenueOnboardingData.category_list.channel_name, targetProp: "id" })
            })
            .addCase(API_FETCH_CHANNEL_LIST_Data.rejected, (state) => {
                state.revenueOnboardingData.api_status_category.channel_name = { status: 'failed', message: action.error.message }
            })
            .addCase(API_CREATE_CHANNEL_LIST_Data.pending, (state, action) => {
                state.revenueOnboardingData.api_status_category.channel_name.status = "loading";
            })
            .addCase(API_CREATE_CHANNEL_LIST_Data.fulfilled, (state, action) => {
                state.revenueOnboardingData.api_status_category.channel_name.status = "success"
                state.revenueOnboardingData.category_list.channel_name = [...state.revenueOnboardingData.category_list.channel_name, { "title": action.payload.title, "id": action.payload._id, "isDefault": false}]
            })
            .addCase(API_CREATE_CHANNEL_LIST_Data.rejected, (state, action) => {
                state.revenueOnboardingData.api_status_category.channel_name = { status: 'failed', message: action.error.message }
            })
            .addCase(API_PATCH_CHANNEL_LIST_Data.pending, (state, action) => {
                state.revenueOnboardingData.api_status_category.channel_name.status = "loading";
            })
            .addCase(API_PATCH_CHANNEL_LIST_Data.fulfilled, (state, action) => {
                state.revenueOnboardingData.api_status_category.channel_name.status = "success"
                state.revenueOnboardingData.category_list.channel_name = state.revenueOnboardingData.category_list.channel_name.map((channel) => channel.id===action.payload.marketplaceChannelUpdated._id ? {...channel, "title": action.payload.marketplaceChannelUpdated.title} : channel)
            })
            .addCase(API_PATCH_CHANNEL_LIST_Data.rejected, (state, action) => {
                state.revenueOnboardingData.api_status_category.channel_name = { status: 'failed', message: action.error.message }
            })
            .addCase(API_Post_Screen1_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Post_Screen1_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
                // state.revenueOnboardingData = { ...state.revenueOnboardingData, "isBottomLevelButtonVisibility": false, "isGenerateOutputActive": false }
            })
            .addCase(API_Post_Screen1_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
                state.revenueOnboardingData.isSaveActive = true;
            })
            .addCase(API_Post_Screen2_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Post_Screen2_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
                // state.revenueOnboardingData.output[targetScreen] = action.payload;
                // state.revenueOnboardingData.isShowOuput = true;
            })
            .addCase(API_Post_Screen2_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
                state.revenueOnboardingData.isSaveActive = true;
                if (state.revenueOnboardingData.stepperPredictionApiStatus > 0) {
                    state.revenueOnboardingData.stepperPredictionApiStatus = 0;
                }
            })

            .addCase(API_Post_Screen3_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Post_Screen3_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
            })
            .addCase(API_Post_Screen3_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
                state.revenueOnboardingData.isSaveActive = true;
            })


            .addCase(API_Post_Screen4_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Post_Screen4_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
            })
            .addCase(API_Post_Screen4_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
                state.revenueOnboardingData.isSaveActive = true;
            })


            .addCase(API_Post_Screen5_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Post_Screen5_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
            })
            .addCase(API_Post_Screen5_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
                state.revenueOnboardingData.isSaveActive = true;
            })

            .addCase(API_Process_Output_Screen1_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Process_Output_Screen1_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData = {
                    ...state.revenueOnboardingData,
                    isShowOuput: true,
                    isBottomLevelButtonVisibility: true,
                    isGenerateOutputActive: false,
                }
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
                state.revenueOnboardingData.output[targetScreen] = action.payload;
            })
            .addCase(API_Process_Output_Screen1_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
            })


            .addCase(API_Process_Output_Screen2_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Process_Output_Screen2_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData = {
                    ...state.revenueOnboardingData,
                    isShowOuput: true,
                    isBottomLevelButtonVisibility: true,
                    isGenerateOutputActive: false,
                }
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
                const output = action.payload;
                delete output?.businessModelUpdated;
                delete output?.monthYear
                state.revenueOnboardingData.output[targetScreen] = output;
            })
            .addCase(API_Process_Output_Screen2_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
                if (state.revenueOnboardingData.stepperPredictionApiStatus > 0) {
                    state.revenueOnboardingData.stepperPredictionApiStatus = 0;
                }
            })


            .addCase(API_Process_Output_Screen3_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Process_Output_Screen3_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData = {
                    ...state.revenueOnboardingData,
                    isShowOuput: true,
                    isBottomLevelButtonVisibility: true,
                    isGenerateOutputActive: false,
                }
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
                const output = action.payload;
                delete output?.businessModelUpdated;
                delete output?.monthYear
                state.revenueOnboardingData.output[targetScreen] = output;
            })
            .addCase(API_Process_Output_Screen3_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
            })



            .addCase(API_Process_Output_Screen4_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Process_Output_Screen4_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData = {
                    ...state.revenueOnboardingData,
                    isShowOuput: true,
                    isBottomLevelButtonVisibility: true,
                    isGenerateOutputActive: false,
                }
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
                const output = action.payload;
                delete output?.businessModelUpdated;
                delete output?.monthYear
                state.revenueOnboardingData.output[targetScreen] = output;
            })
            .addCase(API_Process_Output_Screen4_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
            })



            .addCase(API_Process_Output_Screen5_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Process_Output_Screen5_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData = {
                    ...state.revenueOnboardingData,
                    isShowOuput: true,
                    isBottomLevelButtonVisibility: true,
                    isGenerateOutputActive: false,
                }
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
                const output = action.payload;
                delete output?.businessModelUpdated;
                delete output?.monthYear
                state.revenueOnboardingData.output[targetScreen] = output;
            })
            .addCase(API_Process_Output_Screen5_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
            })



            .addCase(API_Process_Output_Screen6_Data.pending, (state) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'loading'
            })
            .addCase(API_Process_Output_Screen6_Data.fulfilled, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData = {
                    ...state.revenueOnboardingData,
                    isShowOuput: true,
                    isBottomLevelButtonVisibility: true,
                    isGenerateOutputActive: false,
                }
                state.revenueOnboardingData.api_status.status[targetScreen].post.status = 'success'
                const output = action.payload;
                delete output?.businessModelUpdated;
                delete output?.monthYear
                state.revenueOnboardingData.output[targetScreen] = output;
            })
            .addCase(API_Process_Output_Screen6_Data.rejected, (state, action) => {
                const targetScreen = state.revenueOnboardingData.trackComponentRendering[state.revenueOnboardingData.activeScreenNumber];
                state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.status[targetScreen].post = { status: 'failed', message: action.error.message }
            })
            .addCase(API_GET_REVENUE_ONBOARDING_SCREEN_SLICE.pending, (state) => {
                // state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.fetch_screen_slice_status.status = 'loading';
            })
            .addCase(API_GET_REVENUE_ONBOARDING_SCREEN_SLICE.fulfilled, (state, action) => {
                // state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                // return;
                state.revenueOnboardingData.api_status.fetch_screen_slice_status.status = 'success';
                state.revenueOnboardingData.traceKeyNameFromGetScreenData = Object.keys(action.payload.screenData)
                const INFO = action.payload?.info;
                const MODE_TYPES = action.payload?.MODE_TYPES;
                if (!!INFO === false) return;
                const SCREEN_DATA = action.payload.screenData;
                const screenNumberStatus = action.payload?.screenNumberStatus;
                const ACTIVE_SCREEN = screenNumberStatus.activeScreen; // 1
                const PREVIOUS_SCREEN = screenNumberStatus.previousScreen; // 0
                console.log("ACTIVE SCREEN", ACTIVE_SCREEN, "RESET SCREEN", PREVIOUS_SCREEN)
                // wiping out previous screen data
                if (ACTIVE_SCREEN !== PREVIOUS_SCREEN) {
                    if(true){
                        const isRevenueScreenSix = INFO.screenReference === "revenueScreenSix";
                        const hasOutput = state.revenueOnboardingData.traceKeyNameFromGetScreenData.includes('output');
                        const hasInput = state.revenueOnboardingData.traceKeyNameFromGetScreenData.includes('input');
                        if(MODE_TYPES?.view){
                            if (!((isRevenueScreenSix && hasOutput) || (!isRevenueScreenSix && hasInput))) return; 
                        }
                        const SCREEN_SLICE_INITIAL_STATE = resetRevenueScreenData({ "screenNumber": JSON.stringify(PREVIOUS_SCREEN), "default_channel_list": state.revenueOnboardingData.category_list.default_channel_name });
                        state.revenueOnboardingData = {
                            ...state.revenueOnboardingData,
                            ...SCREEN_SLICE_INITIAL_STATE,
                            output: INITIAL_OUTPUT
                        };
                    }
                }
                if (SCREEN_DATA?.revenueScreenOneinput) {
                    const depsData = formatDependencyData({ "dependencyData": SCREEN_DATA?.revenueScreenOneinput })
                    state.revenueOnboardingData = {
                        ...state.revenueOnboardingData,
                        ...depsData
                    }
                }
                state.revenueOnboardingData.categoryList = [];
                if(SCREEN_DATA?.productCategoryCombined){
                    state.revenueOnboardingData.categoryList = SCREEN_DATA?.productCategoryCombined?.map((catg) => ({ title: catg.title, id: catg._id }))
                }
                if(SCREEN_DATA?.marketplaceChannelListCombined){
                    state.revenueOnboardingData.categoryList = [
                        ...state.revenueOnboardingData.categoryList,
                        ...SCREEN_DATA?.marketplaceChannelListCombined?.map((catg) => ({ title: catg.title, id: catg._id }))
                    ];
                }
                // injecting latest data
                switch (INFO.screenReference) {
                    case 'revenueScreenOne': {
                        if (SCREEN_DATA?.input) {
                            const screenOneResponse = revenueScreenOneDataInjection({ "input": SCREEN_DATA?.input, "output": SCREEN_DATA?.output })
                            const {
                                channel_sales,
                                marketing_spend_per_sales_channel,
                                marketing_spend_per_channel_fiscal_year,
                                acquiring_app_users,
                                acquiringUserOptions,
                                trackComponentRendering,
                                output
                            } = screenOneResponse || {};
                            if (Object.keys(screenOneResponse).length > 0) {
                                state.revenueOnboardingData = {
                                    ...state.revenueOnboardingData,
                                    channel_sales,
                                    marketing_spend_per_sales_channel,
                                    marketing_spend_per_channel_fiscal_year,
                                    acquiringUserOptions,
                                    acquiring_app_users,
                                    trackComponentRendering,
                                    output: { ...state.revenueOnboardingData.output, 0: output }
                                }
                            }
                        }
                        if(MODE_TYPES.edit){
                            state.revenueOnboardingData.isSaveActive = false;
                        } 
                        break;
                    };
                    case 'revenueScreenTwo': {
                        state.revenueOnboardingData.category_list.product_category = SCREEN_DATA?.productCategoryList?.map((catg) => ({ title: catg.title, id: catg._id }))
                        state.revenueOnboardingData.category_list.product_category = filterDuplicateObjectProp({ list: state.revenueOnboardingData.category_list.product_category, targetProp: "id" })
                        if (!!SCREEN_DATA?.input) {
                            const screenTwoResponse = revenueScreenTwoDataInjection({ "input": SCREEN_DATA?.input, "output": SCREEN_DATA?.output, "productCategoryList": SCREEN_DATA?.productCategoryList })
                            console.log(screenTwoResponse, "screenTwoResponse")
                            const {
                                manufacture_or_buy,
                                product_categories_details,
                                output
                            } = screenTwoResponse || {};
                            if (Object.keys(screenTwoResponse).length > 0) {
                                state.revenueOnboardingData = {
                                    ...state.revenueOnboardingData,
                                    manufacture_or_buy,
                                    product_categories_details,
                                    output: { ...state.revenueOnboardingData.output, 1: output }
                                }
                            }
                        }
                        break;
                    };
                    case 'revenueScreenThree': {
                        if (!!SCREEN_DATA?.revenueScreenTwoinput) {
                            const screenThreeResponse = revenueScreenThreeDataInjection({ "screen2Input": SCREEN_DATA?.revenueScreenTwoinput, "screen3Input": SCREEN_DATA?.input, "productCategoryList": SCREEN_DATA?.productCategoryList, "output": SCREEN_DATA?.output })
                            console.log(screenThreeResponse);
                            const {
                                marketing_spend_conversion,
                                product_categories_details,
                                output
                            } = screenThreeResponse || {}
                            if (Object.keys(screenThreeResponse).length > 0) {
                                state.revenueOnboardingData = {
                                    ...state.revenueOnboardingData,
                                    marketing_spend_conversion,
                                    platform_commission_cost: SCREEN_DATA?.revenueScreenThreeInput?.platform_commission_cost,
                                    product_categories_details,
                                    output: { ...state.revenueOnboardingData.output, 2: output }
                                }
                            }
                        }
                        break;
                    };
                    case 'revenueScreenFour': {
                        if (!!SCREEN_DATA?.input) {
                            const screenFourResponse = revenueScreenFourDataInjection({ "input": SCREEN_DATA?.input, "output": SCREEN_DATA?.output, "channelCategorylist": state.revenueOnboardingData.category_list.default_channel_name })
                            const {
                                social_media_marketplaces,
                                organic_paid_traffic_social_media_marketplaces,
                                organic_traffic_conversion_rate,
                                output
                            } = screenFourResponse || {};
                            if (Object.keys(screenFourResponse).length > 0) {
                                state.revenueOnboardingData = {
                                    ...state.revenueOnboardingData,
                                    social_media_marketplaces,
                                    organic_paid_traffic_social_media_marketplaces,
                                    organic_traffic_conversion_rate,
                                    "output": {
                                        ...state.revenueOnboardingData.output,
                                        3: output
                                    }
                                }
                            }
                            state.revenueOnboardingData.category_list.channel_name = [
                                ...state.revenueOnboardingData.category_list.channel_name, 
                                ...SCREEN_DATA.marketplaceChannelList.map((catg) => ({"title": catg.title, "id": catg._id, "isDefault": false})),
                                ...SCREEN_DATA.defaultMarketplaceChannelList.map((catg) => ({"title": catg.title, "id": catg._id, "isDefault": true}))
                            ]
                            state.revenueOnboardingData.category_list.channel_name = filterDuplicateObjectProp({ list: state.revenueOnboardingData.category_list.channel_name, targetProp: "id" })
                        }else{
                            state.revenueOnboardingData.category_list.channel_name = [...state.revenueOnboardingData.category_list.channel_name, ...SCREEN_DATA.marketplaceChannelList.map((catg) => ({"title": catg.title, "id": catg._id, "isDefault": false}))]
                            state.revenueOnboardingData.category_list.channel_name = filterDuplicateObjectProp({ list: state.revenueOnboardingData.category_list.channel_name, targetProp: "id" })
                            state.revenueOnboardingData.social_media_marketplaces = generateSocialMediaMarketingPlaceData(state.revenueOnboardingData.category_list.default_channel_name)
                        }
                        break;
                    };
                    case 'revenueScreenFive': {
                        if (!!SCREEN_DATA?.revenueScreenOneinput) {
                            const screenFiveResponse = revenueScreenFiveDataInjection({ "input": SCREEN_DATA?.input, "output": SCREEN_DATA?.output, "channel_sales": SCREEN_DATA?.revenueScreenOneinput.channels_of_sales, "acquiring_app_users": SCREEN_DATA?.revenueScreenOneinput?.users_acquiring })
                            const {
                                key_details_user_acquisition_engagement,
                                social_media,
                                e_commerce,
                                word_of_mouth_1,
                                word_of_mouth_2,
                                total_mau_and_registered_users,
                                output
                            } = screenFiveResponse || {};
                            if (Object.keys(screenFiveResponse).length > 0) {
                                state.revenueOnboardingData = {
                                    ...state.revenueOnboardingData,
                                    "acquiring_app_users": SCREEN_DATA?.revenueScreenOneinput?.users_acquiring,
                                    key_details_user_acquisition_engagement,
                                    social_media,
                                    e_commerce,
                                    word_of_mouth_1,
                                    word_of_mouth_2,
                                    total_mau_and_registered_users,
                                    "output": {
                                        ...state.revenueOnboardingData.output,
                                        4: output
                                    }
                                }
                            }
                        }
                        break;
                    };
                    case 'revenueScreenSix': {
                        if (!!SCREEN_DATA?.output) {
                            const screenSixResponse = revenueScreenSixDataInjection({ "output": SCREEN_DATA?.output })
                            const {
                                output
                            } = screenSixResponse || {};
                            if (Object.keys(screenSixResponse).length > 0) {
                                state.revenueOnboardingData = {
                                    ...state.revenueOnboardingData,
                                    output: {
                                        ...state.revenueOnboardingData.output,
                                        5: output
                                    }
                                }
                            }
                        }
                        break;
                    };
                }
            })
            .addCase(API_GET_REVENUE_ONBOARDING_SCREEN_SLICE.rejected, (state, action) => {
                // state.revenueOnboardingData.api_status.counter = state.revenueOnboardingData.api_status.counter + 1;
                state.revenueOnboardingData.api_status.fetch_screen_slice_status.status = { status: 'failed', message: action.error.message }
            })
    }
});

export const {
    D_RESET_ERROR_MESSAGE,
    D_Increement_SalesMargin,
    D_Trace_StepperPredictionApiStatus,
    D_Patch_Business_Modal_Fetch_Data,
    D_Trace_Save_Button_Clicked,
    filterAcquiringUserOption,
    handleRadioInputSelection,
    toggleTableAttribute,
    updateMarketingSpanTableRow,
    addRowInRO,
    removeRowInRO,
    storeTableValue,
    toggleScreen,
    storeInputValue,
    toggleShowError,
    trackCounter,
    D_Inject_Global_Setting,
    D_Handle_Tab_Change,
    D_Handle_On_Change_Select,
    D_On_Change_Date,
    D_Control_Rendering_Output,
    D_HandleCheckBoxInputSelect,
    D_Reset_Api_Status,
    D_Reset_Complete_Api_Status,
    D_UpdateMarketingSpendData,
    D_Button_Controls,
    D_Patch_SliderValue,
    D_Control_Toggle_Screen,
    D_Reset_Revenue_Onboarding_State,
    D_Capture_Value_Working_Cap_Prod_Catg,
    D_CONTROL_PRODUCT_CATEGORY_MODAL_OPEN
} = RevenueOnboardingSlice.actions;
export default RevenueOnboardingSlice.reducer;
