import React from "react";
import LogoWithTextCard from './Cards/LogoWithTextCard'
import ColorPicker from './Colors/ColorPicker'
import WarningModal from './Modals/WarningModal'
import SDButtonVarients from './SampleComponents/SDButtonVarients'
import SDInputVarients from './SampleComponents/SDInputVarients'
import SDModalVarients from './SampleComponents/SDModalVarients'
import ColorList from "./Colors/ColorList";
import Counter from "./Forms/Counter";
import AddButton from "./Buttons/AddButton";
import PreviewEditSlide from "../components/PitchDeck/subcomponents/PreviewEditSlide";
import SelectOption from "./Forms/SelectOption";
import ImageDescriptionCard from "./Cards/ImageDescriptionCard";
import InHouseImageUpload from "./Image/InHouseImageUpload";
import { editSlideShowHeaderData } from "../components/PitchDeck/data";
import RadioGroupButton from "./Buttons/RadioGroupButton";
import MenuAlignTool from "../components/PitchDeck/components/MenuAlignTool";
import AutoSizeTextArea from "./Forms/InputVarients/AutoSizeTextArea";
import CustomStyleTextArea from "./Forms/InputVarients/CustomStyleTextArea";
const COLOR_PALETTE = ["#FF865F", "#FF49BA", "#FFBF21", "#5591C7", "#4679A7", "#FFF"];
import TextareaAutosize from "react-textarea-autosize"
import Accordion from "./Accordion/Accordion"
import { useState } from "react";
import CRUDSelectOption from "./Forms/CRUDSelectOption";
import LibrarySlider from "./Slider/LibrarySlider";
import SelectOptionWithAddMore from "./Forms/SelectOptionWithAddMore";
import SelectOptionRow from "./Forms/SelectOptionRow";
import TestComponent from "./TestComponent";

const listOfOptions = [
    { "label": "Low", "value": "Low", "textColor": "#FFFFFF", "backgroundColor": "#75CCE8" },
    { "label": "Medium", "value": "Medium", "textColor": "#FFFFFF", "backgroundColor": "#DFA611" },
    { "label": "High", "value": "High", "textColor": "#FFFFFF", "backgroundColor": "#C1232F" }
]
const listOfOptions2 = [
    { "label": "Low", "value": "Low" },
    { "label": "Medium", "value": "Medium" },
    { "label": "High", "value": "High" }
]

const StandardDesignComponent = () => {
    const [number, setNumber] = useState(null)
    const [selected, setSelected] = useState(null);
    // const [color, setColor] = React.useState(undefined);
    // const ref = useRef(null);
    // const updateOnChangeColorPick = (color) => {
    //     setColor(color.hex);
    // }
    // const handleFetchBase64Img = (imgs) => {
    //     console.log(imgs);
    // }
    return (
        <div className='flex flex-col h-[100vh] gap-y-[10rem]'>
            {/* <CRUDSelectOption/> */}
            {/* <LibrarySlider/> */}
            {/* hello world ?? */}
            {/* <ColorPicker color={color} updateOnChangeColorPick={updateOnChangeColorPick} />
            <div className="w-[15rem]">
                <ColorList palette={COLOR_PALETTE} />
            </div>
            <Counter/>
            <AddButton/>
            <PreviewEditSlide/>
            <SelectOption value={undefined} placeholder={"Placeholder"} listOfOptions={[{label:"a",value:"a"}, {label:"b",value:"b"}, {label:"c",value:"d"},]} onChangeEvent={(e) => console.log(e)} />
            <ImageDescriptionCard/>
            <InHouseImageUpload handleFetchBase64Img={handleFetchBase64Img} />
            <RadioGroupButton />
            <MenuAlignTool />
            <CustomStyleTextArea />
            <Accordion /> */}
            {/* <SDModalVarients />
            <SDButtonVarients />
            <SDInputVarients /> */}
            {/* <div className="w-[10rem]">
                <SelectOption
                    onClickAddMore={() => console.log("valie")}
                    placeholder={"Component1"}
                    listOfOptions={listOfOptions2}
                    handleOnClickAddItem={() => console.log("clicked")}
                />
            </div> */}
            {/* <div className="w-[10rem]">
                <SelectOptionWithAddMore
                    value={"Low"}
                    showActiveState={true}
                    placeholder={"Component1"}
                    listOfOptions={listOfOptions2}
                    handleOnClickAddItem={() => console.log("clicked")}
                />
            </div> */}
            {/* <div className="w-[130px]">
                <SelectOptionWithAddMore
                    optionTextAlign="center"
                    isAddMore={false}
                    value={selected}
                    handleOnChange={(value) => setSelected(value)}
                    customPlaceholder={true}
                    placeHolder="Select Priority"
                    minSelectHeight={56}
                    listOfOptions={listOfOptions}
                    optionStyle={{ padding: "1rem 0rem", marginBottom: "0.5rem", "display": "flex", alignItems: "center" }}
                />
            </div> */}
            {/* <SelectOptionRow/> */}
            <TestComponent/>
        </div>
    )
}

export default StandardDesignComponent