import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import TextInput from "../../Forms/InputVarients/TextInput";
import CloseButton from '../../SVG/CloseButtonSVG';
import SecondaryButton from '../../Buttons/SecondaryButton';
import PrimaryButton from '../../Buttons/PrimaryButton';
import ModalSection from './ModalSection';
import { useDispatch } from 'react-redux';
import { filteredSelectOptions } from '../../../components/BusinessModal/functionality/RevenueOnboarding/revenueOnboardingUtiliy';
import { generateSignedUrlForDownload } from '../../../features/dataRoomSlice';

const DownloadFolderModal = ({ isOpen, onClose, documentList }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [chooseModal, setChooseModal] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };

    const handleCancel = () => {
        setModalOpen(false);
        onClose();
    };

    const handleChooseClick = () => {
        setChooseModal(true);
    }

    const handleDownloadClick = async () => {
        // Generate a Toast if number of file count is zero
        const filteredArr = []
        for (const documentMain of documentList) {
            filteredArr.push(documentMain.title)
        }
        const body = {
            directory: filteredArr[0]
        }
        await dispatch(generateSignedUrlForDownload(body))
    }

    return (
        <>
        {chooseModal ? (
            <ModalSection title={'Link Document'} onClose={() => {setChooseModal(false)}} />
        ): (
            null
        )}
        <Modal
            title={<span style={{ fontSize: '20px' }}>Download Folder</span>}
            centered
            open={modalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
                <CloseButton />
            </button>
            <div className=" px-2 flex flex-col gap-3 ">
                <div className=" flex flex-col gap-1 ">
                    <div>
                        {"Downloading this folder will include all the files within the folder."}
                    </div>
                    <div>
                        {"Are you sure you want to proceed ?"}
                    </div>
                </div>
                <div className=" w-full flex flex-row justify-between mt-3 ">
                    <div>
                        <SecondaryButton text={"Select Files"} size="Medium" onClick={handleChooseClick}/>
                    </div>
                    <div>
                        <PrimaryButton text={"Download"} size="Medium" onClick={handleDownloadClick}/>
                    </div>
                </div>
            </div>
        </Modal>
    </>        
    );
};

export default DownloadFolderModal;
