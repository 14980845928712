import React from 'react'
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

const TestComponent = () => {
  return (
    <MessageOutlinedIcon
      className='text-red-500 hover:text-green-500'
    />
  )
}

export default TestComponent