import React from 'react'
import { flexible_plan_icon } from '../../assets/website';
import HeadingThree from '../../DesignSystem/TypographyHeadings/HeadingThree';
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import PGButtonComponent from '../../DesignSystem/Buttons/PGButtonComponent';
import PlanDescription from '../../DesignSystem/Cards/website/PlanDescription';
import { useNavigate } from 'react-router-dom'
import ResponsiveHeadingThreeSix from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingThreeSix';

const ICON_REFERNCE = {
    paperRocket: flexible_plan_icon?.paper_rocket,
    airplane: flexible_plan_icon?.airplane,
    pgRocket: flexible_plan_icon?.pg_rocket,
    shuttleSpace: flexible_plan_icon?.space_shuttle
}

const FlexiblePlanDetail = ({ locals }) => {

    const navigate = useNavigate();
    const { title, description, pricing_btn, demo_btn, cards } = locals;


    return (
        <div className='bg-maindarkShade1 xl:p-[48px] p-[20px] flex lg:flex-row flex-col lg:gap-0 gap-[24px]'>

            <div className='lg:w-[50%] w-full flex flex-col gap-y-[40px] justify-center'>
                <div className='flex flex-col'>
                    <ResponsiveHeadingThreeSix text={title} color={"#FFFFFF"} className='text-center' />
                    <TextLarge text={description} color={"#FFFFFF"} fontWeight={500} className='text-center' />
                </div>
                <div className='flex gap-x-[24px] sm:flex-row flex-col gap-[20px] justify-center'>
                    <PGButtonComponent onClick={() => navigate(pricing_btn.path_link)} intent={"primary"} className="bg-white text-maindarkShade1 sm:w-fit w-full">{pricing_btn.name}</PGButtonComponent>
                    <PGButtonComponent onClick={() => navigate(demo_btn.path_link)} intent={"secondary"} className="bg-transparent text-white border-white sm:w-fit w-full">{demo_btn.name}</PGButtonComponent>
                </div>
            </div>


            <div className='lg:w-[50%] w-full grid sm:grid-cols-2 grid-cols-1 xl:gap-[40px] gap-[20px]'>
                {
                    cards.map((card, index) => {
                        const handleOnClick= () => navigate(card.path_link)
                        return (
                            <div className=''>
                                <PlanDescription
                                    key={index}
                                    handleOnClick={handleOnClick}
                                    title={card.title}
                                    image={ICON_REFERNCE[card.icon_ref_name]}
                                    description={card.description}
                                    features={card.list_of_features}
                                    bottomLevelButtonName={card.learn_more_btn}
                                />
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default FlexiblePlanDetail