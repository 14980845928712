//library
import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Table, message } from 'antd';
//assets
import DeleteImg from "../../../../../assets/common/Delete.svg"
import plusImg from "../../../../../assets/common/plusPrimary.svg"
import business_model_locals from '../../../../../localization/localization_en_business_modal.json'
//components
import { TableToolTip } from '../../../subcomponents/ROFormComponents/components/TableColumnHeading';
import RenderTableInputComponent from '../components/RenderTableInputComponent';
import TableColumnHeading from '../../../subcomponents/ROFormComponents/components/TableColumnHeading';
import DisabledCell from '../microcomponents/DisabledCell';
import OopsPopUp from '../../../../reuseablecomponent/PopUpVarients/OopsPopUp';
//utils
import { RenderPlaceHolder } from '../utils/RenderPlaceHolder';
import { IsDisableCell } from '../utils/IsDisableCell';
import { FetchValidator } from '../utils/FetchValidator';
import { IdentifyReadOnly } from '../utils/IdentifyReadOnly';
import { CustomTagInputTooltip } from '../utils/CustomTagInputTooltip';
//redux actions
import { D_Add_Table_Row, D_Remove_Table_Row } from '../../../costSlice';

const Warning_Message = business_model_locals.business_modal.warning_tooltip;


const EditableTable = ({ payloadData }) => {
    const dispatch = useDispatch()
    const FORM_ITEM = payloadData.formItem;
    const FORM_DATA = payloadData.data;
    const FORM_NAME = payloadData.name;
    const IS_DELETE_ROWS = FORM_ITEM?.table?.is_delete_row;
    const TABLE_DATA = FORM_DATA;
    const [ isModalOpen, setIsModalOpen ] = React.useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const decimalPlace = useSelector((state) => state.globalSetting.active_global_settings.decimal_places)
    const costScreenData = useSelector((state) => state.costScreen)
    const VIEW_MODE = useSelector((state) => state.businessModal.MODE_TYPE.view)
    const marketing_spend_details = useSelector((state) => state.costScreen.revenue_onboarding_marketing_spend_details)
    const CURRENCY_SYMBOL = useSelector((state) => state.globalSetting.currencySymbol)
    const leaseRowData = costScreenData.cost.find((row) => row.name==="Lease")
    let transformedData = [];

    const handleAddRow = () => FORM_DATA.length>=FORM_ITEM?.table.max_no_row ? setIsModalOpen(true) : dispatch(D_Add_Table_Row({ "name": FORM_NAME }))
    const TABLE_COLUMNS = FORM_ITEM?.table?.columns?.map((column, columnIndex) => {
        return (
            {
                "fixed": IS_DELETE_ROWS ? ((columnIndex === 0 || columnIndex === 1) ? 'left' : '') : (!!column?.fixed ? column.fixed : ''),
                "title": <TableColumnHeading isMandatory={column.is_mandatory} title={column.title} tooltip={column.tooltip} />,
                "dataIndex": column?.data_index || 250,
                "width": column.width,
                "render": (value, record, rowIndex) => {
                    const tooltip = FORM_ITEM?.table?.tooltip?.[value];
                    let placeHolder = RenderPlaceHolder({ "table": FORM_ITEM?.table, "record": record, "column": column, "rowIndex": rowIndex })
                    const isDisableCell = "disable_cell" in FORM_ITEM?.table ? IsDisableCell({ disableCellRef: FORM_ITEM?.table?.disable_cell, "columnName": column.title, "rowName": FORM_ITEM?.table?.disable_cell_target_data_index ? record[FORM_ITEM?.table?.disable_cell_target_data_index] : record.name }) : false;
                    let custom_tag_input_tooltip = CustomTagInputTooltip({ column, record, FORM_ITEM, value })
                    const { isReadOnly, isDeleteButtonReadOnly } = IdentifyReadOnly({ "name": FORM_ITEM.name, record })
                    const validator = FetchValidator({ "table": FORM_ITEM?.table, record ,column, "custom_validation": FORM_ITEM.table?.custom_deps_validation, "is_custom_validation_present": "custom_deps_validation" in FORM_ITEM.table })
                    const finalReadOnlyProps = isReadOnly ? (rowIndex<marketing_spend_details.marketing_spend_data_length) : false;
                    let PLACEHOLDER = placeHolder?.includes('CURRENCY') ? placeHolder?.replace('CURRENCY', CURRENCY_SYMBOL) : placeHolder;
                    if(PLACEHOLDER?.includes('.')){
                        PLACEHOLDER = decimalPlace===2 ? PLACEHOLDER : PLACEHOLDER.includes('%') ? PLACEHOLDER.split('.')[0]+'%' : PLACEHOLDER.split('.')[0]
                    }
                    PLACEHOLDER = PLACEHOLDER ? PLACEHOLDER : column.placeHolder
                    
                    if (isDisableCell) return <DisabledCell value={record.name} />
                    if (IS_DELETE_ROWS && columnIndex === 0) return <div className='flex justify-center items-center bg-white h-[3rem]'>
                        <button style={{ display: isDeleteButtonReadOnly ? "none" : "block", outline: 'none' }} disabled={isDeleteButtonReadOnly || VIEW_MODE} onClick={() => dispatch(D_Remove_Table_Row({ "name": FORM_NAME, rowIndex, "dbid": record.dbid }))}><img src={DeleteImg} alt="" /></button>
                    </div>
                    return (
                        <div className='flex justify-between items-center px-[8px] bg-white py-[0.8rem] w-[calc(100%-1%)] h-[calc(100%-1px)] mb-[1px]'>
                            <RenderTableInputComponent
                                VIEW_MODE={VIEW_MODE}
                                columnIndex={columnIndex}
                                value={value}
                                noDataTagContent={FORM_ITEM?.table?.noDataTagContent}
                                record={{ record, column, leaseRowData }} //leaseRowData - readyOnly mode = true ? if row exits : false
                                validator={validator}
                                warningTooltip={FORM_ITEM?.table?.warning_tooltip}
                                isReadOnly={finalReadOnlyProps}
                                tableName={FORM_NAME}
                                data_index={column?.data_index}
                                rowIndex={rowIndex}
                                cellDetail={column}
                                tooltip={tooltip}
                                options={column.select_options}
                                placeHolder={PLACEHOLDER}
                                custom_tag_input_tooltip={custom_tag_input_tooltip}
                            />
                            {
                                column.is_tooltip || tooltip
                                    ? (<div className='w-[18px] aspect-square ml-[8px]'>
                                        {!!tooltip ? <TableToolTip tooltip={tooltip} /> : null}
                                    </div>)
                                    : null
                            }
                        </div>
                    )
                }
            }
        )
    })
    const totalColumnWidth = TABLE_COLUMNS?.reduce((total, column) => total + (column.width || 0), 0);
    const { description1, description2, description3 } = Warning_Message[FORM_ITEM?.table?.categoryName] || {}
    return (
        <div>
            {
                description1
                ? (
                    <OopsPopUp
                        isOpen={isModalOpen}
                        description1={description1}
                        description2={description2}
                        description3={description3}
                        onClick={() => setIsModalOpen((prev) => !prev)}
                    />
                ) : null
            }
            <div>
                <Table
                    columns={TABLE_COLUMNS}
                    dataSource={TABLE_DATA}
                    bordered
                    scroll={{
                        x: totalColumnWidth,
                    }}
                    className='normal_input_table'
                    pagination={{
                        pageSize: TABLE_DATA?.length,
                        position: ['none', 'none'],
                    }}
                />
            </div>
            {
                FORM_ITEM?.table.is_add_row ? (<div className='flex justify-center mt-[8px]'>
                <button disabled={VIEW_MODE} onClick={handleAddRow} className='flex items-center gap-x-2 text-primary'><img className='w-[1.5rem] aspect-square' src={plusImg} alt="" />Add More</button>
            </div>)
                : null
            }
        </div>
    )
}

export default EditableTable