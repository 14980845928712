import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import Search from "./assets/Search.svg"
import Show from "./assets/Show.svg"
import { icpImageAndBgColor } from './data';
import { icpImgs } from './data';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { customTemplateDifferatiation, updateEditFeature, toggleCustomTemplate, updateActiveDocId, updateTemplateTitle, fetchAnsICPDataApi, updateEditformDetails, updateCommingFrom, toggleSearchScreen } from "./ICPSlice";
import locals from "../../localization/localization_en_ideal_customer_profile.json"

const icpFeatureRefVal = locals.ideal_customer_profile.icp_feature_reference_value;
const icp = locals.ideal_customer_profile.idealCustomerProfile;

import { useSelector } from "react-redux";
import DescribeFeature from '../reuseablecomponent/DescribeFeature';
import SearchNotFound from '../../website/components/SearchNotFound';
const SearchComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const draftICP = useSelector((state) => state.icp.draftICP);
    const workSpace = useSelector((state) => state.icp.workSpace);
    const [searchInput, setsearchInput] = useState("");
    const [searchItems, setSearchItems] = useState([]);
    useEffect(() => {
        const searchItemsOne = icp.slice(0,4).map((feature, i) => {
            return {
                title: feature.title,
                TemplateName: feature.title,
                img: icpImageAndBgColor?.[feature?.title]?.templateIcon,
                bgColor: icpImageAndBgColor?.[feature?.title]?.bgColor,
                docId: draftICP[feature.title].docId,
                isCustom: false
            }
        })
        const searchItemsTwo = workSpace.map((workspace, i) => {
            const data = icpImgs[workspace.TemplateName];
            return {
                title: workspace.workSpaceName,
                TemplateName: workspace.TemplateName,
                docId: workspace._id,
                img: data !== undefined ? data["img"] : '',
                bgColor: data !== undefined ? data["bgColor"] : 'bg-slate-200',
                isCustom: (workspace.TemplateName === undefined ? true : false)
            }
        })
        setSearchItems([...searchItemsOne, ...searchItemsTwo]);

        return () => {
            dispatch(toggleSearchScreen(false));
        }
    }, [])

    const filteredSearchTemplates = searchItems.filter((item, index) => (item.title?.toLowerCase()?.includes(searchInput?.toLowerCase()))) || []

    return (
        <div className='w-[90%]'>
            <DescribeFeature text={locals.ideal_customer_profile.module_title} description={""} />
            <div className='mt-[32px]'>
                <Input value={searchInput} onChange={(e) => setsearchInput(e.target.value)} className='border border-primary' size="large" placeholder={locals.ideal_customer_profile.search.placeholder} prefix={<img className='pr-2' src={Search} />} />
            </div>
            <ul>
                {
                    filteredSearchTemplates?.length > 0 && searchInput?.trim()?.length > 0
                        ? (
                            filteredSearchTemplates.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <div>
                                            <div className='flex items-center bg-[#F5F5F5] p-[8px] rounded-[8px] my-[4px] justify-between'>
                                                <div className='flex items-center'>
                                                    <div className={`${item.bgColor} w-[2.5rem] h-[2.5rem] flex items-center justify-center rounded-[8px]`}>
                                                        <img src={item.img} alt="" className='' />
                                                    </div>
                                                    <p style={{ margin: '0' }} className='pl-[8px]'>{item.title}</p>
                                                </div>
                                                <button onClick={() => {
                                                    if (item.isCustom) {
                                                        dispatch(toggleCustomTemplate());
                                                        dispatch(customTemplateDifferatiation("workspaceTemplate"));
                                                        dispatch(updateActiveDocId({ commingFrom: "workspace", activeDocId: item.docId }));
                                                    } else {
                                                        if (item?.docId) {
                                                            dispatch(updateActiveDocId({ commingFrom: "workspace", activeDocId: item.docId, TemplateName: item.TemplateName }));
                                                            dispatch(updateTemplateTitle(item.TemplateName));
                                                            dispatch(fetchAnsICPDataApi());
                                                            dispatch(updateEditformDetails({ title: item.TemplateName }));
                                                            dispatch(updateCommingFrom("workspace"));
                                                        } else {
                                                            dispatch(updateEditFeature({ featureIndex: icpFeatureRefVal[item.title] }))
                                                            dispatch(updateActiveDocId({ commingFrom: "dashboardCard" }));
                                                            dispatch(updateCommingFrom("dashboardCard"));
                                                        }
                                                        navigate("/product/home/buildmarketanalysis/idealcustomerprofile/view");
                                                    }
                                                    navigate("/product/home/buildmarketanalysis/idealcustomerprofile/view");
                                                }}>
                                                    <img src={Show} alt="" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        ) : (
                            searchInput?.trim()?.length>0 && (
                                <SearchNotFound
                                    searchText={searchInput}
                                    className="py-[1rem]"
                                />
                            )
                        )
                }
            </ul>
            <div className={`mt-[48px] ${(searchInput.trim().length === 0) ? 'visible' : 'invisible'}`}>
                <p className='text-[#111827] font-InterRegular font-medium tracking-wide text-[18px]'>Recommended</p>
                <div>
                    {
                        icp.map((icp, index) => {
                            if (index <= 2) {
                                return (
                                    <div>
                                        <div className='flex items-center bg-[#F5F5F5] p-[8px] rounded-[8px] my-[12px] justify-between'>
                                            <div className='flex items-center'>
                                                <div className={`${icpImageAndBgColor?.[icp?.title]?.bgColor} w-[2.5rem] h-[2.5rem] flex items-center justify-center rounded-[8px]`}>
                                                    <img src={icpImageAndBgColor?.[icp?.title]?.templateIcon} alt="" className='' />
                                                </div>
                                                <p style={{ margin: '0' }} className='pl-[8px]'>{icp.title}</p>
                                            </div>
                                            <button>
                                                <img src={Show} onClick={() => {
                                                    dispatch(updateEditFeature({ featureIndex: index }))
                                                    dispatch(updateActiveDocId({ commingFrom: "dashboardCard" }));
                                                    dispatch(updateCommingFrom("dashboardCard"));
                                                    navigate('/product/home/buildmarketanalysis/idealcustomerprofile/view');
                                                }} alt="" />
                                            </button>
                                        </div>
                                        <hr className='border w-full border-[#757575]'></hr>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
            <div className='flex justify-start'>
                <Button onClick={() => dispatch(toggleSearchScreen(false))} className='bg-primary text-white py-[0.4rem] mt-[48px] font-InterRegular font-[16px] tracking-wide h-auto'>Back</Button>
            </div>
        </div>
    )
}

export default SearchComponent