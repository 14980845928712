import React from 'react';
import { InputNumber } from 'antd';

const PrePostNumericInput = ({
    defaultValue,
    handleOnChange,
    handleOnBlur,
    isLeftUnit,
    isRightUnit,
    value,
    placeholder,
}) => {
    const inputProps = {
        value,
        placeholder,
        onChange: handleOnChange,
        onBlur: handleOnBlur,
        defaultValue,
    };
    if (isLeftUnit) return <InputNumber min={0} {...inputProps} addonBefore={isLeftUnit} />;
    if (isRightUnit) return <InputNumber min={0} {...inputProps} addonAfter={isRightUnit} />;
    return <InputNumber min={0} {...inputProps} addonBefore={isLeftUnit} addonAfter={isRightUnit} />;
};

export default PrePostNumericInput;
