export const addAttributeDbIdByName = ({ data, dbCategoryList }) => {
    try {
        let categoryMap = {};
        dbCategoryList.map((tag) => {
            categoryMap = { ...categoryMap, [tag.label]: tag.id }
        })
        const dbIdAddedAttribute = data.map((row) => {
            return { ...row, "dbid": categoryMap?.[row?.name] }
        } )
        return dbIdAddedAttribute;
    } catch (error) {
        return data;
    }
}