import React, { useState } from "react";
import { Button, Dropdown, Menu, message, Space } from "antd";
import AdminDataRoom from "../../localization/data_room/localization_en_admin_data_room.json";
import MoveArrow from "../SVG/MoveArrowSVG";
import AcessPanel from "../SVG/AcessPanelSVG";
import Info from "../SVG/InfoSVG";
import Create from "../SVG/CreateSVG.jsx";
import { useDispatch } from "react-redux";
import { copyFileApi, reviewDocumentStatusApi, updateArchiveStatusApi, updateLockStatusApi } from "../../features/dataRoomSlice.jsx";
import DeleteOutlineSVG from "../SVG/DeleteOutlineSVG.jsx";
import DownloadOutlineSVG from "../SVG/DownloadOutlineSVG.jsx";
import ColorCodeSVG from "../SVG/ColorCodeSVG.jsx";
import DiscriptionSVG from "../SVG/DiscriptionSVG.jsx";
import LockOutlined from "../SVG/LockOutlineSVG.jsx";
import ArchiveOutline from "../SVG/ArchiveOutlineSVG.jsx";
import LinkOutline from "../SVG/LinkOutlineSVG.jsx";
import CopyOutline from "../SVG/CopyOutlineSVG.jsx";
import grayDots from "../../assets/common/grayDots.svg";
import ModalSection from "./ActionMenuOptionModal/ModalSection.jsx";
import MoreSymbolSVG from "../SVG/MoreSymbolSVG.jsx";

const ActionMenuCard = ({ document, handleClose }) => {
    const dispatch = useDispatch();
    const [modalTitle, setModalTitle] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleMenuClick = (e) => {
        console.log("click", e);
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu[e.key]);
        setIsModalVisible(true);
    };

    console.log(document)

    const closeModalHandler = () => {
        setIsModalVisible(false);
        setModalTitle(null);
        handleClose();
    };

    const handleArchiveClick = async () => {
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu['8'])
        setIsModalVisible(true)
    }

    const handleUnarchiveClick = async () => {
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu['28'])
        setIsModalVisible(true)
    }

    const handleLockClick = async () => {
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu['7'])
        setIsModalVisible(true)
    }

    const handleUnLockClick = async () => {
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu['27'])
        setIsModalVisible(true)
    }

    const handleMakeCopy = async () => {
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu['29'])
        setIsModalVisible(true)

    }

    const handleAssignForReview = async () => {
        handleClose();
        const body = {
            documentId: document._id,
            reviewStatus: "In Progress"
        }

        await dispatch(reviewDocumentStatusApi(body)).then((response) => {
            handleClose();
        })
    }

    const menuItems = [
        {
            label: (
                <Space className="py-2">
                    <MoveArrow />
                    {AdminDataRoom.admin_action_menu.actionMenu[1]}
                </Space>
            ),
            key: "1",
        },
        {
            label: (
                <Space className="py-2">
                    <AcessPanel />
                    {AdminDataRoom.admin_action_menu.actionMenu[2]}
                </Space>
            ),
            key: "2",
        },
        {
            label: (
                <Space className="py-2">
                    <Info />
                    {AdminDataRoom.admin_action_menu.actionMenu[4]}
                </Space>
            ),
            key: "4",
        },
        {
            label: (
                <Space className="py-2">
                    <Create />
                    {AdminDataRoom.admin_action_menu.actionMenu[5]}
                </Space>
            ),
            key: "5",
        },
        {
            label: (
                <Space className="py-2">
                    <DownloadOutlineSVG />
                    {AdminDataRoom.admin_action_menu.actionMenu[10]}
                </Space>
            ),
            key: "10",
        },
        {
            label: (
                <Space className=" py-2 icon-container">
                    <MoreSymbolSVG />
                    More
                </Space>
            ),
            key: "33",
            children: [
                {
                    key: "29",
                    label: (
                        <Space onClick={handleMakeCopy}>
                            <CopyOutline />
                            {AdminDataRoom.admin_action_menu.actionMenu[29]}
                        </Space>
                    ),
                },
                // {
                //   label: (
                //     <Space onClick={handleAssignForReview}>
                //       <AcessPanel />
                //       {AdminDataRoom.admin_action_menu.actionMenu[9]}
                //     </Space>
                //   ),
                //   key: "9",
                // },
                {
                    key: "14",
                    label: (
                        <Space>
                            <DiscriptionSVG />
                            {AdminDataRoom.admin_action_menu.actionMenu[14]}
                        </Space>
                    ),
                },
                {
                    key: "8",
                    label: (
                        document.archiveStatus === true ? (
                            <Space onClick={handleUnarchiveClick}>
                                <ArchiveOutline />
                                {"Unarchive"}
                            </Space>
                        ) : (
                            <Space onClick={handleArchiveClick}>
                                <ArchiveOutline />
                                {AdminDataRoom.admin_action_menu.actionMenu[8]}
                            </Space>
                        )
                    ),
                },
                {
                    key: document.lockStatus ? "27" : "7",
                    label: (
                        document?.lockStatus === true ? (
                            <Space onClick={handleUnLockClick}>
                                <LockOutlined />
                                {"Unlock File"}
                            </Space>
                        ) : (
                            <Space onClick={handleLockClick}>
                                <LockOutlined />
                                {AdminDataRoom.admin_action_menu.actionMenu[7]}
                            </Space>
                        )
                    ),
                },
                {
                    key: "17",
                    label: (
                        <Space>
                            <DeleteOutlineSVG />
                            {AdminDataRoom.admin_action_menu.actionMenu[17]}
                        </Space>
                    ),
                },
                {
                    key: "11",
                    label: (
                        <Space>
                            <LinkOutline />
                            {AdminDataRoom.admin_action_menu.actionMenu[11]}
                        </Space>
                    ),
                },
            ],
        },
    ];

    const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

    return (
        <Space wrap>
            <Dropdown overlay={menu} trigger={["click"]}>
                <Space className="cursor-pointer opacity-75 hover:opacity-100">
                    <img src={grayDots} onClick={handleMenuClick} />
                </Space>
            </Dropdown>
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    closeHandler={closeModalHandler}
                    documentList={document}
                />
            )}
        </Space>
    );
};

export default ActionMenuCard;
