import React from 'react'
import TextLarge from '../../TypographyDescriptions/TextLarge'
import { generatePadding } from '../../Spacing/Padding'
import CustomCheckBox from './CustomCheckBox';
import CheckboxInput from '../CheckboxInput';
import tooltipCircle from "../../../assets/common/tooltipCircle.svg"
import { Tooltip } from 'antd'

const CardCheckBoxInput = ({ name, text, isDisabled, isMulti, horizontalPadding=16, verticalPadding=16, isChecked, handleOnClick, value, tooltip }) => {
    const cursor = { cursor: isDisabled ? "not-allowed" : "pointer" };
    const [isShowTooltipHover, setIsShowTooltipHover] = React.useState(false);
    const padding = {
        ...generatePadding("vertical",verticalPadding),
        ...generatePadding("horizontal",horizontalPadding),
        ...cursor,
        backgroundColor: isDisabled ? "#d8d8d8" : "white",
        
    }
    const textStyle = isDisabled ? "#616161" : "#111827"
    return (
        <React.Fragment>
            <div
                onMouseEnter={() => setIsShowTooltipHover(true)}
                onMouseLeave={() => setIsShowTooltipHover(false)}
                onClick={() => !isDisabled && handleOnClick({"key":name,"value":value, isMulti})} 
                disabled={isDisabled} className='border h-full w-full rounded-[8px] flex justify-between items-center gap-x-[16px] relative' 
                style={{...padding, border: `1px solid ${isChecked ? "#372EE5" : "#E0E0E0"}`}}
            >
                <TextLarge text={text} color={textStyle} fontWeight={500} />
                <CheckboxInput text={""} isChecked={isChecked} isDisabled={isDisabled} />
                {!!tooltip
                ? <div className='w-[1rem] aspect-square absolute -top-[0.4rem] -right-[0.5rem]' style={{display: isShowTooltipHover ? "block" : "none"}}>
                    <Tooltip placement={"top"} title={tooltip}><img src={tooltipCircle} alt="" /></Tooltip>
                </div>
                : null}
            </div>
        </React.Fragment>
    )
}

export default CardCheckBoxInput