import React, { useState, useEffect } from 'react';
import gmail from "../assets/VerifyEmail/gmail.svg"
import outlooklogo from "../assets/VerifyEmail/outlooklogo.svg"
import undologo from "../assets/VerifyEmail/undologo.svg"
import getstarted from "../assets/VerifyEmail/getstarted.svg"
import verifyemailillustrator from "../assets/common/verifyEmaillustrator.svg"
import { Button } from 'antd';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { verifyEmail, userEmailVerificationStatus, resendVerifyEmail } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux"
import { userInfo } from '../features/userSlice';
import logo from '../assets/Header/logotest.svg'
import TNC from "../assets/docs/Terms_and_Conditions.pdf";
import PP from "../assets/docs/Privacy_Policy.pdf";
import PageSpinner from '../components/reuseablecomponent/PageSpinner';
import MessageNotification from '../components/reuseablecomponent/MessageNotification';
import verifyEmailLocals from "../localization/localization_en_verify_email.json";
import footerLocals from "../localization/localization_en_footer.json";
import brand from "../assets/common/brand.svg";


const AntVerifyEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [timer, setTimer] = useState(30);
    const userinfo = useSelector(userInfo);
    const verificationStatus = useSelector(userEmailVerificationStatus);
    const [resendEmailPrevent, setResendEmailPrevent] = useState(true);
    const status = useSelector((state) => state.user.status);
    const error = useSelector((state) => state.user.error);
    useEffect(() => {
        if (window.location.href.split('/')[5] !== undefined) {
            dispatch(verifyEmail({ emailToken: window.location.href.split('/')[5] }));
        }
    }, [])
    useEffect(() => {
        // const timer = setTimeout(() => {
        //     setResendEmailPrevent(false);
        // }, 10000);
        // return () => clearTimeout(timer);
        const timerClock = setInterval(() => {
            if (timer === 0) {
                setResendEmailPrevent(false);
                clearInterval(timerClock);
            } else {
                setTimer((prev) => prev - 1);
            }
        }, 1000)
        return () => clearInterval(timerClock);
    }, [resendEmailPrevent, timer])
    if (verificationStatus) {
        setTimeout(() => {
            navigate('/product/onboarding');
        }, 2500);
    }
    return (
        <div>
            {(status === "loading" || status==="success") && <PageSpinner />}
            {status === "failed" && <MessageNotification status={status} type={"error"} content={error} />}
            <div className='md:min-h-[100vh] min-h-[90vh] flex flex-col lg:justify-between justify-start'>
                <div className='absolute hidden top-0 left-0 right-0 md:flex -z-[10000] min-h-full'>
                    <div className="left_shadow bg-white w-[60%]"></div>
                    <div className="left_shadow bg-[#FAFAFA] w-[45%] 2xl:w-[55%]"></div>
                </div>
                <div onClick={() => navigate('/')} className="header sticky top-0 lg:pt-[48px] lg:pl-[64px] pl-[24px]">
                    <img src={logo} className='w-[138px] h-[21px] md:block hidden' alt="" />
                    <img src={brand} className="w-[64px] h-[64px] block md:hidden" alt="" />
                </div>
                <div className="flex flex-auto justify-between items-center">
                    <div className='lg:w-[60%] flex h-full'>
                        <div className="lg:ml-[64px] mx-[24px]">
                            <p style={{ marginBottom: "12px" }} className='text-[#221CB7] text-[24px] font-DMSans'>{verifyEmailLocals.verify_email.main_title}</p>
                            <p style={{ marginBottom: "24px" }} className='text-[#9E9E9E] text-[14px] font-medium tracking-wide font-InterRegular'>{verifyEmailLocals.verify_email.verify_email_text} <span className='text-[#111827] font-InterRegular tracking-wide text-[14px]'>{userinfo.email}</span></p>
                            {/* <button onClick={() => navigate('/signup')} style={{ marginBottom: "24px" }} className={`${window.location.href.split('/')[4] !== undefined ? 'hidden' : 'block'} text-[#372EE5] font-medium tracking-wide font-InterRegular text-[14px]`}>{verifyEmailLocals.verify_email.edit_email_btn_text}</button> */}
                            <p style={{ marginBottom: "48px" }} className='text-[#9E9E9E] font-InterRegular text-[14px] font-medium tracking-wide'>{verifyEmailLocals.verify_email.guidance_description}</p>
                            <div className="buttons flex flex-col lg:w-[474px]">
                                <Button type='link' onClick={() => {
                                    setResendEmailPrevent(true);
                                    setTimer(30);
                                    dispatch(resendVerifyEmail({ email: userinfo.email }))
                                }} disabled={resendEmailPrevent} style={{ marginBottom: "24px" }} className='flex pt-3 justify-center border-none rounded-xl h-[50px]'>
                                    <img src={undologo} alt="" />
                                    <span className='text-[14px] text-primary font-semibold' style={{ padding: "0px 5px" }} type='link'>{verifyEmailLocals.verify_email.resend_email_text} {timer !== 0 && `in ${timer}s`}</span>
                                </Button>
                                <Button onClick={() => window.open("https://outlook.office365.com")} style={{ marginBottom: "24px" }} className='flex border-none justify-center items-center rounded-[12px] bg-[#F5F8FF] h-[50px]'>
                                    <img src={outlooklogo} alt="" />
                                    <span className='text-[16px] text-[#21208F] font-semibold' style={{ padding: "0px 5px" }} type='link'>{verifyEmailLocals.verify_email.outlook_btn_text}</span>
                                    <img src={getstarted} alt="" />
                                </Button>
                                <Button onClick={() => window.open('https://mail.google.com/mail/u/0/#inbox', '_blank')} className='flex justify-center items-center border-none rounded-[12px] bg-[#F5F8FF] h-[50px]'>
                                    <img src={gmail} alt="" />
                                    <span className='text-[16px] text-[#21208F] font-semibold' style={{ padding: "0px 5px" }} type='link'>{verifyEmailLocals.verify_email.gmail_btn_text}</span>
                                    <img src={getstarted} alt="" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="w-[40%] bg-[#FAFAFA] md:flex justify-center mr-[30px] hidden">
                        <img src={verifyemailillustrator} className="my-auto" alt="" />
                    </div>
                </div>
                <div className="footer sticky top-[100%]">
                    <div className='flex justify-between text-[#9E9E9E] items-center text-[12px] pb-[32px] px-[64px]'>
                        <div className=''>{footerLocals.footer.copyright_text}</div>
                        <div className=''>
                            <button onClick={() => navigate('/refund-policy')} className='mr-[24px]'>{footerLocals.footer.refund_policy}</button>
                            <button onClick={() => navigate('/terms-and-conditions')} className='mr-[24px]'>{footerLocals.footer.terms_and_condition_text}</button>
                            <button onClick={() => navigate('/privacy-policy')}>{footerLocals.footer.privacy_policy_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AntVerifyEmail;