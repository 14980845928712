import React, { useEffect, useState } from "react";
import TextLarge from "../TypographyDescriptions/TextLarge";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import FolderSVG from "../SVG/FolderSVG";
import file from "../../assets/common/file.svg";
import profile from "../../assets/PSM/profile.svg";
import CustomCheckBox from "../Forms/CheckBoxVarients/CustomCheckBox";
import TextMedium from "../TypographyDescriptions/TextMedium";
import ActionMenuFolderCard from "./ActionMenuFolderCard";
import ActionMenuInvestorCard from "./ActionMenuInvestorCard";

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
};

const DataRoomDashboardCard = ({
    cardProfileImg,
    bgColor = "#372EE5",
    color,
    cardTitle = "Title",
    onCheckboxChange,
    hasPermission,
    icon1,
    icon2,
    handleOnClick1,
    handleOnClick2,
    cardBorderColor = "#9E9E9E",
    id,
    handleChange,
    isloading,
    fileCount,
    sharedCount,
    role
}) => {
    console.log(color)
    const [isHovered, setIsHovered] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(isloading);
    const [statusColor, setStatusColor] = useState(color)

    useEffect(() => {
        if (isLoading === true) {
            setIsLoading(false)
            console.log("Changed")
        }
    }, [])

    const navigate = useNavigate();

    const handleCheckboxChange = (event) => {
        event.stopPropagation();
        console.log("Changed");
        setIsChecked(!isChecked);
        onCheckboxChange(!isChecked, { title: cardTitle, id: id });
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const handleCardClick = () => {
        if (role === "admin") {
            navigate(`/product/home/data-room/admin/${cardTitle}/files`);
        } else if (role === "investor") {
            return navigate(`/product/home/data-room/investor/${id}/files`)
        } else {
            navigate(`/product/home/data-room/user/${id}/files`)
        }
    };

    const cardStyle = {
        borderColor: isChecked
            ? "var(--Main-Colors-Primary-color, #372EE5)"
            : cardBorderColor,
        backgroundColor: isChecked ? "#EBF1FF" : "",
        border: isChecked
            ? "2px solid var(--Main-Colors-Primary-color, #372EE5)"
            : `2px solid ${cardBorderColor}`,
    };

    const handleActionMenuClick = (event) => {
        event.stopPropagation(); // Prevent click event from bubbling up
    };

    return (
        <React.Fragment>
            <div
                style={cardStyle}
                className="flex flex-col sm:flex-row justify-between relative border overflow-hidden w-full h-full p-3 shadow-lg rounded-2xl cursor-pointer gap-5"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleCardClick}
            >
                <div className="flex flex-col gap-3 py-2">
                    <div
                        className="flex items-center max-w-full gap-x-[8px]"
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        <div className="flex items-center">
                            {!isLoading ? (
                                <FolderSVG color={statusColor ? statusColor : color} />
                            ) : (
                                <Spin />
                            )}
                        </div>
                        <p 
                            style={{
                                margin: 0
                            }}
                            className="text-wrap truncate-to-1-lines text-[14px] tracking-wide font-InterRegular"
                        >
                            {cardTitle}
                        </p>
                    </div>
                    <div
                        style={{ borderColor: cardBorderColor }}
                        className="flex flex-col sm:flex-row items-center"
                    >
                        <div className="flex items-center justify-center h-full gap-1 mb-2 sm:mb-0">
                            <div className="flex -space-x-2">
                                <img className="w-1/2 h-1/2 rounded-full" src={profile} />
                                <img className="w-1/2 h-1/2 rounded-full" src={profile} />
                            </div>
                            <TextMedium
                                fontWeight={500}
                                text={sharedCount > 1 ? `${sharedCount} Members` : `${sharedCount} Member`}
                                color={"#757575"}
                                margin={{ direction: "left" }}
                                className="whitespace-nowrap overflow-hidden text-ellipsis font-DMSans"
                            />
                        </div>
                        <div
                            style={{ backgroundColor: cardBorderColor }}
                            className="w-[0.1px] h-[24px] mx-2 hidden sm:block"
                        ></div>
                        <div className="flex gap-1">
                            <img src={file} />
                            <TextMedium
                                fontWeight={500}
                                text={`${fileCount > 1 ? `${fileCount} Files` : `${fileCount} File`}`}
                                color={"#757575"}
                                margin={{ direction: "left" }}
                                className="whitespace-nowrap overflow-hidden text-ellipsis font-DMSans"
                            />
                        </div>
                    </div>
                </div>
                <div className="absolute top-[0.5rem] right-1">
                    <div className="relative flex justify-end" onClick={(event) => event.stopPropagation()}>
                        {role === "admin" ? (
                            <ActionMenuFolderCard count={fileCount} documentId={id} handleClose={handleChange} handleDashColorChange={(value, color) => { setIsLoading(value); if (color) { setStatusColor(color) } }} directoryName={cardTitle} />
                        ) : role === "investor" ? (
                            <ActionMenuInvestorCard documentId={id} />
                        ) : (
                            <ActionMenuInvestorCard documentId={id} />
                        )}
                    </div>
                </div>
                <div className="absolute bottom-2 right-4">
                    {(isHovered || isChecked) && (
                        <button onClick={handleCheckboxChange}>
                            <CustomCheckBox
                                isChecked={isChecked}
                                handleOnClick={handleCheckboxChange}
                            />
                        </button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default DataRoomDashboardCard;
