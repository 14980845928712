import React from 'react'
import { Button } from "antd";
import TextLarge from '../TypographyDescriptions/TextLarge';

const PrimaryDangerButton = ({ isLoading, onClick, text, size="small", isDisabled=false}) => {
  const padding = size === "small" ? "8px 24px" : size === "medium" ? "12px 40px" : "16px 48px";
  const fontSize = size === "small" ? "16px" : size === "medium" ? "16px" : "18px";
  return (
    <React.Fragment>
      <Button
        onClick={onClick}
        disabled={isDisabled}
        type='primary'
        style={{ padding,fontSize,letterSpacing: "0.2px", lineHeight: "140%" }}
        loading={isLoading}
        danger
        className={`h-auto text-[16px] font-semibold`}
      >
        <TextLarge fontWeight={600} text={text} />
      </Button>
    </React.Fragment>
  )
}

export default PrimaryDangerButton