export function convertArrIntoString(data) {
    try {
        let str = '';
        data.map((d) => {
            str = str + d + ' ';
        })
        return str;
    } catch (error) {
        console.log(error)
        return ""
    }
}