import React from 'react';
import BaseModal from './BaseModal';
import close from "../../assets/common/close.svg"
import ImageButton from '../Buttons/ImageButton';
import TextExtraLarge from '../TypographyDescriptions/TextExtraLarge';
import SecondaryButton from '../Buttons/SecondaryButton';
import TextMedium from '../TypographyDescriptions/TextMedium';
import PrimaryButton from '../Buttons/PrimaryButton';
import CustomStyleTextArea from '../Forms/InputVarients/CustomStyleTextArea';

const InputTextModal = ({ isOpen, onCancel, onConfirm, confirmButtonName = "Confirm", isDisabled, title, value, placeHolder, onChangeEvent, isLoading }) => {
    return (
        <BaseModal isOpen={isOpen}>
            <div className='absolute top-[18px] right-[18px] border-none'>
                <ImageButton image={close} onClick={onCancel} />
            </div>
            <div className='py-[16px]'>
                <TextExtraLarge text={title} />
            </div>
            <CustomStyleTextArea
                value={value}
                isDisabled={isDisabled}
                onChangeEvent={onChangeEvent}
                placeHolder={placeHolder}
            />
            <div className='flex justify-between pt-[18px] w-[100%]'>
                <SecondaryButton text={"Cancel"} onClick={onCancel} />
                <PrimaryButton isDisabled={isDisabled} isLoading={isLoading} text={confirmButtonName} onClick={onConfirm} />
            </div>
        </BaseModal>
    )
}

export default InputTextModal