export const convertAmountInK = (amount, preUnit = "", postUnit = "") => {
    if (amount !== null && amount !== undefined) {
        let amountInString = String(amount);
        let [integerPart, fractionalPart] = amountInString.split('.');

        // Convert the amount to a number and check if it's greater than or equal to 10000
        if (Number(integerPart) >= 10000) {
            let amountInK = (Number(amount) / 1000).toFixed(1); // Keep 1 decimal point for large numbers
            // Remove the decimal part if it's .0 (e.g., 36.0 -> 36)
            amountInK = amountInK.endsWith('.0') ? amountInK.slice(0, -2) : amountInK;

            return `${preUnit} ${amountInK}k ${postUnit}`.trim();
        }

        // For numbers less than 10,000, return the full number
        console.log(amount, "amount")
        return `${preUnit} ${integerPart}${fractionalPart ? '.' + fractionalPart : ''} ${postUnit}`.trim();
    }
};