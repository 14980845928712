import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSubscriptionDetail } from '../../features/userSlice';
import { formatTimestampToReadableDate } from '../../utils/formatTimestampToReadableDate';
import { daysBetweenTimestamp } from '../../utils/daysBetweenTimestamp';
import { getPaymentList } from './settingSlice';
import { API_CANCEL_SUBSCRIPTION, updatePaymentStatus } from '../../features/appSlice';
import SkeletonLoader from '../../DesignSystem/Loader/SkeletonLoader';
import setting_locals from "../../localization/localization_en_setting.json"
import HeadingFive from '../../DesignSystem/TypographyHeadings/HeadingFive';
import SubscriptionBlockDetail from './subcomponents/SubscriptionBlockDetail';
import DeleteSubscriptionModal from '../reuseablecomponent/PopUpVarients/DeleteSubscriptionModal';
import StripePaymentModal from './subcomponents/StripePaymentModal';
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour';
import TextExtraLarge from '../../DesignSystem/TypographyDescriptions/TextExtraLarge';
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import { API_SUBSCRIPTION_LIST, API_FETCH_PRICING_INFO } from '../../features/appSlice';
import SubscriptionInvoiceList from './subcomponents/SubscriptionInvoiceList';
import HeadingSix from '../../DesignSystem/TypographyHeadings/HeadingSix';
import { formatpricinglist } from './utility/formatpricinglist';
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import { getLatestActiveSubscription } from './utility/getActiveSubscriptions';
import { D_ResetCancelSubscriptionApiStatus } from '../../features/appSlice';

const { title, buttons, days_left, active, invitation_period_over, no_subscription } = setting_locals.settings.tabs.Subscription;
const { invitation, paid_plan_details } = setting_locals.settings.tabs.Subscription.new_payment;

const SubscriptionProgressV2 = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = useState(false);
    const [isStripePreQuestionModalOpen, setIsStripePreQuestionModalOpen] = useState(false);
    const { cancelSubcriptionModelOpen, payment_details } = useSelector((state) => state.setting);
    const { planStatus, planDetails } = useSelector((state) => state.user.subscriptionDetails) || {};
    const isInvitationDetailsLoading = useSelector((state) => state.user.status);
    const { api_status: subp_status, data: subp_data, message: subp_message } = useSelector((state) => state.app.subscription_list);
    const { api_status: pinfo_status, data: pinfo_data, message: pinfo_message } = useSelector((state) => state.app.pricing_info);
    const subscription_status = useSelector((state) => state.user.subscriptionDetails);
    const session_id = new URLSearchParams(location?.search)?.get("session_id");
    const cancel_subscription = useSelector((state) => state.app.cancel_subscription)

    const IS_LOADING = subp_status === "loading" || pinfo_status === "loading";

    const curr_subscription_status = subp_status ? getLatestActiveSubscription(subp_data?.subscriptionListRazorPayInfo ?? []) : {};

    const toggleCancelSubcriptionModelOpen = () => setIsCancelSubscriptionModalOpen((prev) => !prev);

    useEffect(() => {
        // dispatch(getSubscriptionDetail());
        // dispatch(getPaymentList());
        dispatch(API_FETCH_PRICING_INFO());
        dispatch(API_SUBSCRIPTION_LIST());
    }, [])

    // useEffect(() => {
    //     if (session_id) {
    //         dispatch(updatePaymentStatus({ "isWarningPopupOpen": false, "isPlanActive": true }));
    //     }
    // }, [session_id])

    useEffect(() => {
        if (cancel_subscription.api_status === "success") {
            toggleCancelSubcriptionModelOpen();
            dispatch(API_SUBSCRIPTION_LIST());
            dispatch(D_ResetCancelSubscriptionApiStatus());
        }
    }, [cancel_subscription.api_status])

    const handleToggleStripePreQuestionModal = () => setIsStripePreQuestionModalOpen((prev) => !prev);

    const handleCancelSubscriptionApi = ({ reason }) => dispatch(API_CANCEL_SUBSCRIPTION({ reason, "subscriptionId": curr_subscription_status.id }))

    const invitedUserTableData = [
        {
            "invitation_start_date": formatTimestampToReadableDate({ "isoDateStr": subscription_status?.invitationStart }) || '',
            "invitation_end_date": formatTimestampToReadableDate({ "isoDateStr": subscription_status?.invitationExpiry }) || '',
            "duration": String(subscription_status?.invitedNoOfDays || 0) + ' ' + days_left,
            "status": subscription_status?.planStatus === "invited" ? active : invitation_period_over
        }
    ]

    // const IS_INVITATION_ENTITIES_PAID_ATLEAST_ONE_PAY = payment_details.isLoading ? false : payment_details?.data?.length > 0;
    // const FRESH_PAY_RENEW_PAY = planStatus === "invited" ? true : IS_INVITATION_ENTITIES_PAID_ATLEAST_ONE_PAY ?? planStatus === "expired";
    // const INVITATION_DAYS_LEFT = daysBetweenTimestamp({ "timestamp1": new Date().toISOString(), "timestamp2": planDetails?.expirationDate }) ?? 0;
    // const ACTIVE_PLAN_DAYS_LEFT = planStatus === "invited" ? 0 : daysBetweenTimestamp({ "timestamp1": new Date().toISOString(), "timestamp2": planDetails?.expirationDate }) ?? 0;

    const IS_INVITATION_ENTITIES_PAID_ATLEAST_ONE_PAY = subp_status === "loading" ? false : subp_data?.subscriptionListRazorPayInfo?.length > 0;
    const FRESH_PAY_RENEW_PAY = planStatus === "invited" ? true : IS_INVITATION_ENTITIES_PAID_ATLEAST_ONE_PAY ?? false; // planStatus === "expired"
    const INVITATION_DAYS_LEFT = daysBetweenTimestamp({ "timestamp1": new Date().toISOString(), "timestamp2": subscription_status?.invitationExpiry }) ?? 0;
    const ACTIVE_PLAN_DAYS_LEFT = planStatus === "invited" ? 0 : daysBetweenTimestamp({ "timestamp1": new Date().toISOString(), "timestamp2": new Date((curr_subscription_status?.end_at ?? 0) * 1000)?.toISOString() }) ?? 0;

    const { inr_list=[], usd_list=[] } = formatpricinglist(pinfo_data) || {};
    const currentCurrencyPlanList = curr_subscription_status?.currency === "usd" ? usd_list : inr_list;

    if(!FRESH_PAY_RENEW_PAY && !IS_LOADING){
        navigate('/product/home/subscription/pay');
    }

    return (
        <div className='mt-[3.5rem]'>
            <HeadingFive text={title} color={"#372EE5"} />
            {/* stripe pre question modal */}

            <StripePaymentModal
                isModalOpen={isStripePreQuestionModalOpen}
                handleCloseModal={handleToggleStripePreQuestionModal}
            />
            {/* delete subscription modal */}
            <DeleteSubscriptionModal
                isCancelSubscriptionModalOpen={isCancelSubscriptionModalOpen}
                toggleCancelSubcriptionModelOpen={toggleCancelSubcriptionModelOpen}
                handleCancelSubscriptionApi={handleCancelSubscriptionApi}
            />
            {/* <pre>
                {JSON.stringify(transformData, null, 2)}
                {JSON.stringify(curr_subscription_status, null, 2)}
            </pre> */}
            {
                IS_LOADING && planStatus === "expired"
                    ? (
                        <div className='flex flex-col gap-y-[0.5rem]'>
                            <SkeletonLoader height={40} />
                            <SkeletonLoader height={40} />
                            <SkeletonLoader height={40} />
                            <SkeletonLoader height={40} />
                            <SkeletonLoader height={40} />
                        </div>
                    ) : null
            }
            {
                (FRESH_PAY_RENEW_PAY)
                    ? (
                        <div className='flex flex-col gap-y-[1rem] mt-[2rem] w-[95%]'>
                            {/* invitation details */}
                            {
                                planStatus && planStatus === "invited" && (
                                    <SubscriptionBlockDetail
                                        title={invitation.title}
                                        isDataLoading={IS_LOADING}
                                        tableData={IS_LOADING ? [invitation.table.skeleton_structure] : invitedUserTableData || []}
                                        tableColumns={invitation.table.columns}
                                        expireStatus={{
                                            "text": invitation.invitation_period_expired,
                                            "isShow": INVITATION_DAYS_LEFT === 0
                                        }}
                                        preExpireStatus={{
                                            "text": invitation.invitation_period_pre_expire,
                                            "isShow": INVITATION_DAYS_LEFT <= 7
                                        }}
                                        isShowCancelSubscription={false}
                                        buttonName={buttons.upgrade}
                                        onClick={() => navigate('/product/home/subscription/pay')}
                                    />
                                )
                            }
                            {/* subscription details */}
                            {
                                IS_INVITATION_ENTITIES_PAID_ATLEAST_ONE_PAY
                                    ? (
                                        <div className='flex flex-col gap-y-[0.5rem]'>
                                            {
                                                currentCurrencyPlanList?.length && ["active", "completed"].includes(curr_subscription_status?.status) ? (
                                                    <div className='mb-[1rem]'>
                                                        <HeadingSix text={paid_plan_details.paid_title} />
                                                        <div className='flex flex-col gap-y-[1rem] max-w-[25rem]'>
                                                            {
                                                                currentCurrencyPlanList?.length > 0 ? (
                                                                    currentCurrencyPlanList.map((plan, index) => {
                                                                        if(curr_subscription_status.plan_id !== plan.plan_id) return null;
                                                                        return (
                                                                            <div key={index} className='p-[20px] justify-between border border-[#e4e7eb] rounded-md flex'>
                                                                                <div>
                                                                                    <div className='font-semibold pt-[0.5rem]'>{plan?.plan_name}</div>
                                                                                </div>
                                                                                <div className='flex gap-x-[0.5rem]'>
                                                                                    <span className='text-[1.7rem] pt-[0.5rem]'>{curr_subscription_status?.currency === "usd" ? "$" : "₹"}</span>
                                                                                    <div className='font-DMSans text-[3.5rem]'>{plan?.price?.split(' ')?.[3]}</div>
                                                                                    <span className='text-[1.2rem] pt-[0.5rem] text-gray-600'>/ {plan?.span_type}</span>
                                                                                </div>
                                                                                {/* <TextLarge text={plan?.[curr_subscription_status?.currency]?.price} fontWeight={600} /> */}
                                                                            </div>
                                                                        )
                                                                    })
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            <SubscriptionBlockDetail
                                                title={paid_plan_details.subp_title}
                                                isDataLoading={IS_LOADING}
                                                tableData={subp_data ?? {}}
                                                tableColumns={paid_plan_details.table.columns}
                                                isSubscriptionTable={true}
                                                expireStatus={{
                                                    "text": paid_plan_details.subscription_period_expired,
                                                    "isShow": (ACTIVE_PLAN_DAYS_LEFT === 0 && curr_subscription_status?.status === "expired") || curr_subscription_status.status === "cancelled"
                                                }}
                                                preExpireStatus={{
                                                    "text": paid_plan_details.subscription_period_pre_expire,
                                                    "isShow": ACTIVE_PLAN_DAYS_LEFT <= 7 && ACTIVE_PLAN_DAYS_LEFT !== 0
                                                }}
                                                toggleCancelSubcriptionModelOpen={toggleCancelSubcriptionModelOpen}
                                                isShowCancelSubscription={(ACTIVE_PLAN_DAYS_LEFT > 0 && curr_subscription_status.status !== "cancelled") || ["active", "created", "completed"].includes(curr_subscription_status.status)}
                                                buttonName={buttons.renew}
                                                currentSubDetail={curr_subscription_status}
                                                onClick={() => navigate('/product/home/subscription/pay')}
                                            />
                                        </div>
                                    ) : null
                            }
                        </div>
                    ) : (
                        !IS_LOADING && <div className='flex justify-center items-center min-h-[calc(100vh-6rem)]'>
                            <div className='flex flex-col items-center'>
                                <HeadingFour headingCSSStyles={{ margin: 0 }} text={no_subscription.main_title1} />
                                <HeadingFour headingCSSStyles={{ margin: 0 }} text={no_subscription.main_title2} />
                                <HeadingFour headingCSSStyles={{ margin: 0 }} text={no_subscription.main_title3} />
                                <TextExtraLarge text={no_subscription.description} fontWeight={500} color='#757575' className={'text-center mt-[12px]'} />
                                <div className="mt-[48px]">
                                    <PrimaryButton onClick={() => navigate('/product/home/subscription/pay')} text={no_subscription.get_started} />
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default SubscriptionProgressV2