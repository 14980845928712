import React from 'react';
import { useDispatch } from "react-redux";
import {
    API_Default_SalesChannel_Data,
    API_Default_DirectCost_Data,
    API_Default_CapacityPlanning_Data,
    API_Default_Cost_Data,
    API_Default_DerivedCost_Data,
    API_Default_UnderivedCost_Data,
    API_Default_CapexCost_Data,
    API_Default_GACost_Data,
    API_GET_SalesChannelList_Data,
    API_GET_Direct_Cost_List_Data,
    API_GET_CapacityPlanning_Data,
    API_GET_Cost_Data,
    API_GET_DerivedCost_Data,
    API_GET_UnderivedCost_Data,
    API_GET_GACost_Data,
    API_GET_CapexCost_Data
} from "../../../costSlice"
import { D_Fetch_category_List_Status } from "../../../costSlice"

const useFetchCategoryList = ({ isCategoryListFetchedFromServer }) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        if(true) return; // isCategoryListFetchedFromServer
        dispatch(D_Fetch_category_List_Status(true))
        dispatch(API_Default_SalesChannel_Data())
        dispatch(API_GET_SalesChannelList_Data());
        dispatch(API_Default_DirectCost_Data());
        dispatch(API_GET_Direct_Cost_List_Data());
        dispatch(API_Default_Cost_Data())
        dispatch(API_Default_CapacityPlanning_Data());
        dispatch(API_GET_CapacityPlanning_Data());
        dispatch(API_GET_Cost_Data());
        dispatch(API_GET_DerivedCost_Data());
        dispatch(API_GET_UnderivedCost_Data());
        dispatch(API_Default_DerivedCost_Data());
        dispatch(API_Default_UnderivedCost_Data());
        dispatch(API_GET_GACost_Data());
        dispatch(API_Default_GACost_Data());
        dispatch(API_GET_CapexCost_Data());
        dispatch(API_Default_CapexCost_Data());
    }, [])
}

export default useFetchCategoryList