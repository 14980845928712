import React, { useEffect } from 'react'
import TextDMSansComponent from '../../reuseablecomponent/TextVarients/TextDMSansComponent'
import ProductFeatures from './Components/ProductFeatures'
import ToolTipModule from './Components/ToolTipModule'
import { useDispatch, useSelector } from "react-redux"
import { fetchFeatureProgressApi } from '../stepperProgressSlice'
import locals from "../../../localization/localization_en_stepper.json"

const stepperLocals  = locals.stepper;

const StepperTracker = () => {
  const dispatch = useDispatch();
  const stepperProgressData = useSelector((state) => state.stepperprogress.stepperProgressData)
  useEffect(()=>{
    dispatch(fetchFeatureProgressApi())
  },[])
  return (
    <div className='mt-[96px]'>
      <div className='pb-[8px] border-b border-b-black'>
        <TextDMSansComponent color={"#111827"} fontSize={20} fontWeight={700} text={stepperLocals.captions.intro_heading} />
      </div>
      <div className="flex justify-between w-full mt-[50px]">
        <ProductFeatures stepperProgressData={stepperProgressData}/>
        <ToolTipModule/>
      </div>
    </div>
  )
}

export default StepperTracker