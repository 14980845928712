import React from 'react'
import { Dropdown } from 'antd';
import grayDots from "../../assets/common/grayDots.svg"
import TextMedium from '../TypographyDescriptions/TextMedium';
import { Space } from 'antd';


const DropdownAction = ({ items=[], handleOnClick, moduleType, overlayStyle, imgIconOrCustomicon="img", isDisabled }) => {
    return (
        <Dropdown
            overlayStyle={overlayStyle}
            menu={{
                items: items.map((item, index) => {
                    return (
                        {
                            key: index,
                            label: (
                                <a className='flex gap-x-[0.5rem] items-center cursor-pointer' onClick={() => handleOnClick({ moduleType, "ACTION_TYPE": item.action_type })}>
                                    {
                                        imgIconOrCustomicon==="img"
                                        ? <img src={item.dropdown_icon_ref} alt="" width={25}/>
                                        : (item.dropdown_icon_ref)
                                    }
                                    <TextMedium color='#424242' text={item.dropdown_text} fontWeight={300} />
                                </a>
                            ),
                            icon: null,
                            disabled: false,
                        }
                    )
                })
            }}
            trigger={['click']}
            disabled={isDisabled}
        >
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <img src={grayDots} className='w-[24px] aspect-square cursor-pointer' alt="" />
                </Space>
            </a>
        </Dropdown>
    )
}

export default DropdownAction