import React, { useState } from 'react'
import { Table } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from "react-redux"
import { format } from 'date-fns'
import bmLocals from "../../../localization/localization_en_business_modal.json"
import TableColumnHeading from './ROFormComponents/components/TableColumnHeading';
import { edit_black, view_black, delete_black } from '../../../assets';
import DotsThreeVertical from "../../../assets/competitoranalysis/DotsThreeVertical.svg"
import ImageButton from '../../../DesignSystem/Buttons/ImageButton';
import { API_DELETE_BUSINESS_MODAL, API_Fetch_Business_Modal, D_Update_Mode_Type, D_UpdateDeleteBusinessModelParamater, D_Reset_Api_Status, D_Update_ActiveBusinessModelId } from '../businessModalSlice';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import TickMark from "../../../assets/common/TickMark.svg"
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium';
import grayDots from "../../../assets/common/grayDots.svg"
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';
import gs_settings from "../../../assets/common/gs_settings.svg";
import { useNavigate } from "react-router-dom";
import ConfirmModal from '../../../DesignSystem/Modals/ConfirmModal';
import deleteRed from "../../../assets/common/deleteRed.svg"
import editPrimary from "../../../assets/common/editPrimary.svg"
import DeleteModalComponent from '../../reuseablecomponent/PopUpVarients/DeleteModal';
import OopsPopUp from '../../reuseablecomponent/PopUpVarients/OopsPopUp';
import appLocals from '../../../localization/localization_en_app.json'
import { D_Store_GlobalSettingData, D_Update_Setting_Mode_Type } from '../globalSettingSlice'
import SkeletonLoader from '../../../DesignSystem/Loader/SkeletonLoader';
import { generateMonth } from '../functionality/RevenueOnboarding/revenueOnboardingUtiliy';
import { API_MARK_AS_FINAL_BUSINESS_MODAL } from '../businessModalSlice';
import { API_GET_REVENUE_ONBOARDING_SCREEN_SLICE } from '../revenueSlice';
import classNames from 'classnames';
import TickMarkComponent from './TickMarkComponent';

const UNKNOWN_ERROR_TOOLTIP = appLocals.app.business_model.error_message.unknown_error;
const INCOMPLETE_MARK_AS_FINAL = bmLocals.business_modal.warning_tooltip.incomplete_business_model_mark_as_final;

const convertMongoDbTimeStampToDate = (mongoTimeStamp) => {
    const createdAt = new Date(mongoTimeStamp); // Replace this with your MongoDB createdAt timestamp
    const day = createdAt.getDate();
    const month = generateMonth({ startMonth: createdAt.getMonth(), seriesMonth: 1, outputType: "value" })
    const year = createdAt.getFullYear();
    const formattedDate = `${day.toString().padStart(2, '0')} ${month.toString().padStart(2, '0')} ${year}`;
    return formattedDate
}

const BusinessModalList = ({ data = [], isDisabled, IS_LOADING }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isPopupOpen, setIsPopupOpen] = React.useState(false);
    const [popupContent, setPopUpContent] = React.useState(false);
    const [showUnknownError, setUnknownError] = useState(false);
    const [showUnknownErrorContent, setUnknownErrorContent] = useState(false);
    const POP_UP_LOCALS = bmLocals.business_modal.business_modal.business_modal_list.popup;
    const DELETE_POP_UP_OPEN = useSelector((state) => state.businessModal.deleteBusinessModelDetails)
    const businessModalListTable = bmLocals.business_modal.business_modal.business_modal_list.table.columns;
    const { api_track_counter, api_status, is_mark_final_new_or_old } = useSelector((state) => state.businessModal)
    const IS_DELETE_LOADING = useSelector((state) => state.businessModal.api_status.delete_business_modal.status)
    React.useEffect(() => {
        for(const apiStatus of Object.entries(api_status)){
            if(apiStatus[1].status==="success"){
                if(apiStatus[0]==="mark_as_final"){
                    setIsPopupOpen(true);
                    setPopUpContent(is_mark_final_new_or_old ? POP_UP_LOCALS.marking_secondary_business_model : POP_UP_LOCALS.marking_start_business_model);
                    dispatch(D_Reset_Api_Status({ httpType: apiStatus[0] }))
                }
            }
            if(apiStatus[1].status==="failed") {
                setUnknownError(true);
                setUnknownErrorContent(UNKNOWN_ERROR_TOOLTIP.message);
                dispatch(D_Reset_Api_Status({ httpType: apiStatus[0] }))
            }
        }
    }, [api_track_counter])
    let transformedData = data.map((modal, index) => {
        return {
            ...modal,
            createdAt: convertMongoDbTimeStampToDate(modal.createdAt),
            updatedAt: convertMongoDbTimeStampToDate(modal.updatedAt),
            action: null
        }
    });
    const handleEditBusinessModal = (record) => {
        dispatch(D_Update_ActiveBusinessModelId(record.key))
        dispatch(D_Update_Mode_Type({ type: "edit", value: true }))
        dispatch(API_GET_REVENUE_ONBOARDING_SCREEN_SLICE("prev"));
    }
    const handleViewBusinessModal = (record) => {
        dispatch(D_Update_ActiveBusinessModelId(record.key))
        dispatch(D_Update_Mode_Type({ type: "view", value: true }))
        dispatch(API_GET_REVENUE_ONBOARDING_SCREEN_SLICE("prev"));
    }
    const columns = businessModalListTable?.map((column, columnIndex) => {
        return (
            {
                "key": uuidv4(),
                "fixed": columnIndex === 0 || columnIndex === 1 ? "left" : columnIndex === businessModalListTable.length - 1 ? "right" : "",
                "title": (
                    <div className='border-black border-b py-[12px] px-[8px]'>
                        <TextLarge text={column.label} color={"#424242"} fontWeight={500} />
                    </div>
                ),
                "dataIndex": column.dataIndex,
                "width": column.width,
                // 
                "render": (value, record, rowIndex) => {
                    if(IS_LOADING) return (
                        <div className='pl-[0.6rem] py-4 relative bg-[#F4F7FF]'>
                            <SkeletonLoader/>
                        </div>
                    )
                    const items = [
                        {
                            key: '1',
                            label: (
                                <a className='flex gap-x-[0.5rem] items-center' onClick={() => handleEditBusinessModal(record)}>
                                    <img src={editPrimary} alt="" width={25}/>
                                    <TextMedium text={"Edit"} color='#424242' fontWeight={300} />
                                </a>
                            ),
                            icon: null
                        },
                        {
                            key: '2',
                            label: (
                                <a className='flex gap-x-[0.5rem] items-center' onClick={() => dispatch(D_UpdateDeleteBusinessModelParamater({ "popupOpen": true, "businessModelId": record._id }))}>
                                    <img src={deleteRed} alt="" width={25} />
                                    <TextMedium color='#424242' text={"Delete"} fontWeight={300} />
                                </a>
                            ),
                            icon: null,
                            disabled: false,
                        },
                        {
                            key: '3',
                            label: (
                                <a className='flex gap-x-[0.5rem] items-center' onClick={() => handleViewBusinessModal(record)}>
                                    <img src={view_black} alt="" width={25}/>
                                    <TextMedium color='#424242' text={"View"} fontWeight={300} />
                                </a>
                            ),
                            icon: null,
                            disabled: false,
                        },
                        {
                            key: '4',
                            label: (
                                <a className='flex gap-x-[0.5rem] items-center' onClick={() => {
                                    navigate('/product/home/business-modal/setting');
                                    dispatch(D_Update_Setting_Mode_Type(true));
                                    dispatch(D_Store_GlobalSettingData({ "settingMetric": record?.global_setting }))
                                }}>
                                    <img src={gs_settings} alt=""width={25} />
                                    <TextMedium color='#424242' text={"View Global Settings"} fontWeight={300} />
                                </a>
                            ),
                            icon: null,
                            disabled: false,
                        },
                        {
                            key: '5',
                            label: (
                                <a className={classNames('flex gap-x-[0.5rem] items-center')} onClick={() => {
                                    if(record.finalStatus) return;
                                    if(record?.balanceSheetStatus){
                                        dispatch(API_MARK_AS_FINAL_BUSINESS_MODAL(record?._id))
                                    }else{
                                        setUnknownError(true);
                                        setUnknownErrorContent(INCOMPLETE_MARK_AS_FINAL);
                                    }
                                }}>
                                    <TickMarkComponent fill={record.finalStatus ? "#D8D8D8" : "#424242"} />
                                    <TextMedium color={record.finalStatus ? "#D8D8D8" : "#424242"} text={"Mark As Final"} fontWeight={300} />
                                </a>
                            ),
                            icon: null,
                            disabled: false,
                        }
                    ];
                    if (columnIndex === businessModalListTable.length - 1) {
                        return (
                            <div className='pl-[0.6rem] py-2 relative mb-2 bg-[#F4F7FF]'>
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    trigger={['click']}
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <img src={grayDots} className='w-[24px] aspect-square' alt="" />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                        )
                    }
                    if(column?.dataIndex==="title"){
                        return (
                            <div className='pl-[0.6rem] py-3 relative mb-3 bg-[#F4F7FF]'>
                                <a className='underline decoration-primary text-primary' onClick={() => handleViewBusinessModal(record)}>{!!value ? value : '-'}</a>
                            </div>
                        )
                    }
                    if(column?.dataIndex==="status"){
                        return (
                            <div className={`pl-[0.6rem] py-3 relative mb-3 bg-[#F4F7FF]`}>
                                <span className={`${record.finalStatus ? "border border-[#17BC9F] text-[#17BC9F] bg-[#17BC9F]/30" : "border border-[#F17400] text-[#F17400] bg-[#F17400]/30"} py-[4px] px-[12px] rounded-full`}>{record.finalStatus ? "Final" : "Draft"}</span>
                            </div>
                        )
                    }
                    return (
                        <div className='pl-[0.6rem] py-3 relative mb-3 bg-[#F4F7FF]'>
                            {
                                column.dataIndex==="index"
                                ? rowIndex+1
                                : !!value ? value : '-'
                            }
                        </div>
                    )
                }
            }
        )
    })
    const totalColumnWidth = columns?.reduce((total, column) => total + (column.width || 0), 0);
    const { title, description1, description2, confirm_button_name } = POP_UP_LOCALS.marking_start_business_model || {};
    const { D_title, D_description1, D_delete_button_name } = POP_UP_LOCALS.delete_business_model || {}
    const handleOnConfirm = () => {
        setIsPopupOpen(false);
        navigate('/product/home/business-modal/my-report')
        // dispatch() API CALL TO MARK PRIMARY DASHBOARD --- ON SUCCESS NAVIGATE TO MY DASHBOARD ROUTE
    }
    return (
        <div>
            {
                showUnknownError
                ? (
                    <OopsPopUp
                        isOpen={showUnknownError}
                        description1={showUnknownErrorContent.description1}
                        description2={showUnknownErrorContent.description2}
                        onClick={() => setUnknownError(false)}
                    />
                ) : null
            }
            {
                isPopupOpen 
                ? (
                    <ConfirmModal 
                        isOpen={isPopupOpen} 
                        onConfirm={handleOnConfirm} 
                        onCancel={() => setIsPopupOpen(false)} 
                        title={popupContent.title} 
                        description1={popupContent.description1} 
                        description2={popupContent.description2}
                        confirmButtonName={confirm_button_name} 
                    />
                ) : null
            }
            {
                DELETE_POP_UP_OPEN.popupOpen
                ? (
                    <DeleteModalComponent
                        isModelOpen
                        isLoading={IS_DELETE_LOADING==="loading"}
                        isCalling={DELETE_POP_UP_OPEN.popupOpen}
                        deleteButtonName={D_delete_button_name}
                        modelTitle={D_title}
                        modelDescription={D_description1}
                        handleCloseModel={() => dispatch(D_UpdateDeleteBusinessModelParamater({ "popupOpen": false, "businessModelId": null }))}
                        handleOnDelete={() => dispatch(API_DELETE_BUSINESS_MODAL())}    
                    />
                ) : null
            }
            <div className='border border-black rounded-lg overflow-hidden'>
                <Table
                    columns={columns}
                    dataSource={IS_LOADING ? Array.from({ length: 2 }) : transformedData}
                    bordered={false}
                    scroll={{
                        x: totalColumnWidth,
                    }}
                    locale={{ 
                        emptyText: (
                        <div className='py-[1rem] flex flex-col'>
                            <TextMedium fontWeight={500} color='' text={bmLocals.business_modal.warning_tooltip.No_Data_Found.description1} />
                            <TextMedium fontWeight={500} color='' text={bmLocals.business_modal.warning_tooltip.No_Data_Found.description2} />
                            <TextMedium fontWeight={500} color='' text={bmLocals.business_modal.warning_tooltip.No_Data_Found.description3} />
                        </div>
                        )
                    }}
                />
            </div>
        </div>
    )
}

export default BusinessModalList
