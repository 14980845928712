import React from 'react'
import { useNavigate } from 'react-router-dom';
import ResponsiveHeadingThreeFive from '../TypographyHeadings/ResponsiveHeadingThreeFive'
import PrimaryButton from '../Buttons/PrimaryButton'
import { cn } from '../../utils/cn'
import useApp from '../../hooks/useApp';
import { paywall_locals } from '../../localization';

const BlurContainer = ({ children, handleOnClick, className, isShowBlurEffect, isShowButton, text, buttonName = "Upgrade" }) => {
    const navigate = useNavigate();
    const defaultHandleOnClick = () => {
        navigate('/product/home/setting/subscription');
    };
    const { upgradeRenewPaywallPopup } = useApp();
    if (!isShowBlurEffect) return children;
    return (
        <div className={cn('px-[0.5rem] bg-black/40 rounded-2xl relative', className)}>
            <div className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white flex flex-col items-center gap-y-[24px] w-full text-center px-[5rem]'}>
                <ResponsiveHeadingThreeFive text={text} />
                {
                    isShowButton
                        ? <PrimaryButton text={upgradeRenewPaywallPopup==="upgrade" ? paywall_locals.buttons.upgrade : paywall_locals.buttons.renew} onClick={handleOnClick || defaultHandleOnClick} />
                        : null
                }

            </div>
            <div className='blur-[12px] pointer-events-none select-none'>
                {children}
            </div>
        </div>
    )
}

export default BlurContainer