import React from 'react'
import { Button } from "antd";

const BMButton = ({ type, icon, text, handleOnClick }) => {
    const buttonColor = type === "primary" ? "bg-primary text-white" : "bg-white text-primary"
    return (
        <div>
            <Button onClick={handleOnClick} icon={<img src={icon} width={23} />} style={{ padding: '12px' }} className={`${buttonColor} font-InterRegular font-semibold`}>{text}</Button>
        </div>
    )
}

export default BMButton