import appLocals from "../../../localization/localization_en_app.json"

const ERROR_MESSAGE = appLocals.app.business_model.error_message;

const VIEW_MODE_ERROR = ERROR_MESSAGE.future_screen_data_not_found_view
const EDIT_MODE_ERROR = ERROR_MESSAGE.future_screen_data_not_found_edit
const SAVE_MODE_ERROR = ERROR_MESSAGE.save
const DOWNLOAD_MODE_ERROR = ERROR_MESSAGE.download
const PROCESS_MODE_ERROR = ERROR_MESSAGE.process

export const fetchWarningPopupErrorMessage = ({ buttonClickedName }) => {
    switch(buttonClickedName){
        case 'save': return SAVE_MODE_ERROR;
        case 'edit': return EDIT_MODE_ERROR;
        case 'view': return VIEW_MODE_ERROR;
        case 'download': return DOWNLOAD_MODE_ERROR;
        case 'process': return PROCESS_MODE_ERROR
    }
}