import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'

const TextLarge = ({ fontWeight = 700, color, text = "Title", margin, padding, isDisplay=true, display="inline", className, style={} }) => {
  const styles = {
    color: color,
    lineHeight: "0%",
    fontWeight,
    ...generatePadding(padding?.direction, padding?.number),
    ...generateMargin(margin?.direction, margin?.number),
    display: isDisplay ? display : '',
    ...style
  }
  return (
    <React.Fragment>
      <span
        style={styles}
        className='text-[14px] tracking-wide font-InterRegular'
      >{text}</span>
    </React.Fragment>
  )
}

export default TextLarge