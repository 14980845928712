import React, { useState, useEffect } from 'react'
import { Modal, Radio } from 'antd';
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';
import { initiatePaymentRequest, trackStripePaymentRedirect } from '../settingSlice';
import { useDispatch, useSelector } from "react-redux";
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton';
import HeadingSix from '../../../DesignSystem/TypographyHeadings/HeadingSix';
import CloseIcon from '@mui/icons-material/Close';

const StripePaymentModal = (props) => {
    const { isModalOpen, handleCloseModal } = props;
    const dispatch = useDispatch();
    const [currencyType, setcurrencyType] = useState(null);
    const { isRedirected, redirect_stripe_payment_link, isloading } = useSelector((state) => state.setting.payment_details);
    useEffect(() => {
        if (redirect_stripe_payment_link && isRedirected === false) {
            window.location.replace(redirect_stripe_payment_link);
            dispatch(trackStripePaymentRedirect(true));
        }
    }, [redirect_stripe_payment_link])
    return (
        <Modal width={650} centered open={isModalOpen}>
            <CloseIcon className='absolute top-[1.5rem] right-[1.5rem] cursor-pointer' onClick={handleCloseModal} />
            <div className='min-h-[10rem] flex flex-col justify-between'>
                <HeadingSix text={"Select your preferred payment currency type"} />
                <Radio.Group onChange={(event) => setcurrencyType(event.target.value)} value={currencyType}>
                    <Radio value={"inr"}>INR 9999</Radio>
                    <Radio value={"usd"}>USD 200</Radio>
                </Radio.Group>
                <div className='pt-[1rem] flex justify-between'>
                    <PrimaryButton text={"Pay"} onClick={() => {
                        if (currencyType === null) {
                            alert();
                        } else {
                            dispatch(initiatePaymentRequest({ "currency": currencyType }));
                            dispatch(trackStripePaymentRedirect(false));
                        }
                    }} isDisabled={false} isLoading={isloading === "pending"} />
                    <SecondaryButton text={"Cancel"} onClick={handleCloseModal} />
                </div>
            </div>
        </Modal>
    )
}

export default StripePaymentModal