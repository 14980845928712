import React, { useEffect, useState } from "react";
import test1 from "../assets/Login/loginvector.svg";
import { useNavigate } from "react-router-dom";
import logotest from "../assets/Header/logotest.svg";
import "../index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserName, createUserName } from "../features/userSlice";
import { Input, Button } from "antd";
import TNC from "../assets/docs/Terms_and_Conditions.pdf";
import PP from "../assets/docs/Privacy_Policy.pdf";
import usernameLocals from "../localization/localization_en_ask_username.json"
import footerLocals from "../localization/localization_en_footer.json"

const InviteTeamPage = () => {
const navigate = useNavigate();
const dispatch = useDispatch();
const email = useSelector((state) => state.user.value.email);
const redirectToAskNamePage = useSelector((state) => state.user.redirectToAskNamePage);
const [name,setName] = useState("");

useEffect(()=>{
  if(!redirectToAskNamePage){
    navigate('/product/home');
  }
},[redirectToAskNamePage])
  return (
    <div className="flex">
      <div className="absolute hidden top-0 left-0 right-0 md:flex -z-[10000] min-h-full">
        <div className="left_shadow bg-white w-[60%]"></div>
        <div className="left_shadow bg-[#FAFAFA] w-[55%]"></div>
      </div>
      <div className="left_part w-[60%] min-h-[99.7vh]">
        <div className="w-[100%] h-[100%]">
          <div className="mx-[64px] flex flex-col justify-between bg-white h-[100%]">
            <div className="logo mt-[48px]">
              <img src={logotest} className="w-[138px] h-[21px]" alt="" />
            </div>
            <div className="lg:w-[515px]">
              <p style={{marginBottom:'10px'}} className="text-[#372EE5] font-DMSans text-[24px] font-bold">{usernameLocals.ask_username.ask_username}</p>
              <p style={{marginBottom:'24px'}} className="text-[#9E9E9E] text-[15px] font-InterRegular tracking-wide font-semibold">{usernameLocals.ask_username.accept_invitation} <span className="underline">{email}</span></p>
              <Input onChange={(e) => setName(e.target.value)} style={{marginBottom:'48px'}} className="input_forgot_pass" placeholder={usernameLocals.ask_username.form.placeholder} />
              <Button onClick={() => {
                dispatch(updateUserName({name:name.trim()}));//local
                dispatch(createUserName());//api
                navigate('/product/home');
              }} disabled={(name.trim().length>0) ? false  : true} className="text-primary rounded-[12px] flex justify-center bg-[#EBF1FF] px-[16px] py-[18px] h-auto w-full">{usernameLocals.ask_username.form.continue_btn_text}</Button>
            </div>
            <div className="text-[#9E9E9E] mb-[31px] text-[13px] tracking-wide font-InterRegular">
              {footerLocals.footer.copyright_text}
            </div>
          </div>
        </div>
      </div>
      <div className="right_part w-[40%] bg-[#FAFAFA] flex relative items-center min-h-[99.7vh] h-[10rem]">
        <img src={test1} alt="" className="xl:ml-[35px] w-[70%] h-[70%]" />
        <div className="footer absolute bottom-0 right-[64px] text-[13px] font-InterRegular flex text-[#9E9E9E]">
          <p style={{ marginBottom: "31px" }} className="mr-[24px]"><button onClick={() => navigate('/refund-policy')}>{footerLocals.footer.refund_policy}</button></p>
          <p style={{ marginBottom: "31px" }} className="mr-[24px]"><button onClick={() => navigate('/privacy-policy')}>{footerLocals.footer.terms_and_condition_text}</button></p>
          <p style={{ marginBottom: "31px" }}><button onClick={() => navigate('/terms-and-conditions')}>{footerLocals.footer.privacy_policy_text}</button></p>
        </div>
      </div>
    </div>
  );
};

export default InviteTeamPage;
