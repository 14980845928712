const acceptOrRejectDecimalValue = ({ value, decimal_place }) => {
    if(!(/^-?\d+(\.\d+)?$/).test(value)){
        return { status: false }
    }
    const isPositive = !value.includes('-')
    const string_value = isPositive ? value : value.slice(1);
    if(decimal_place===2){
        if(string_value.split('.')[1].length <= 2) return { "status": true, value: Number(value) }
        if(string_value.split('.')[1].length >2) return { "status": true, "value": isPositive ? Number(string_value.split('.')[0]+'.'+string_value.split('.')[1].slice(0,2)) : Number('-'+string_value.split('.')[0]+'.'+string_value.split('.')[1].slice(0,2)) }
    }else{
        return { "status": true, "value": isPositive ? Number(string_value.split('.')[0]) : Number(`-${string_value.split('.')[0]}`) }
    }
}

const handleZeroDecimalNumber = ({ string_value, validation_type }) => {
    if(string_value.includes('.')){
        return { "status": false }
    }
    return { "status": true, "value": Number(string_value) };
}

export const ValidateNumber = ({ validation_type, value, decimal_place, month_year }) => {
    if(!!value===false) return { status: false };
    if(value === 0) return { status: true, value };
    if(isNaN(value) && validation_type!=='present_month_year_to_future') return { status: false };
    switch(validation_type){
        case 'positive_decimal_number': {
            const string_value = String(value)
            if(string_value.includes('-')) return { status: false };
            if(!string_value.includes('.')) return { status: true, value };
            if(decimal_place===0) return handleZeroDecimalNumber({ decimal_place, string_value, validation_type });
            return acceptOrRejectDecimalValue({ "value": string_value, decimal_place })
        }
        case 'positive_negative_decimal_number': {
            const string_value = String(value)
            if(!string_value.includes('.')) return { status: true, value };
            if(decimal_place===0) return handleZeroDecimalNumber({ decimal_place, string_value, validation_type });
            return acceptOrRejectDecimalValue({ "value": string_value, decimal_place })
        }
        case 'negative_decimal_number': {
            const string_value = String(value)
            if(!string_value.includes('.')) return { status: true, value }
            if(decimal_place===0) return handleZeroDecimalNumber({ decimal_place, string_value, validation_type });
            return acceptOrRejectDecimalValue({ "value": string_value, decimal_place })
        }
        case 'positive_number': {
            const string_value = String(value)
            if(string_value.includes('-') || string_value.includes('.')) return { status: false };
            // return string_value.includes('.') ? { "status": true, "value": Number(string_value.split('.')[0]) } : { "status": true, "value": Number(string_value) }
            return { "status": true, "value": Number(string_value) }
        }
        case 'positive_number_min_1': {
            const string_value = String(value)
            if(string_value.includes('-') || string_value.includes('.')) return { status: false };
            const isNumberGreaterThanZero = Number(string_value)>0
            return { "status": isNumberGreaterThanZero, "value": isNumberGreaterThanZero ? Number(string_value) : null }
        }
        case 'positive_number_min_1_max_12': {
            const string_value = String(value)
            if(string_value.includes('-') || string_value.includes('.')) return { status: false };
            const isNumberGreaterThanZero = Number(string_value)>0 && Number(string_value)<=12;
            return { "status": isNumberGreaterThanZero, "value": isNumberGreaterThanZero ? Number(string_value) : null }
        }
        case 'present_month_year_to_future': {
            const currentMonth = (new Date()).getMonth();
            const currentYear =(new Date()).getFullYear();
            // const userSelectDate = value?.split('/');
            const [ year, month ] = value?.split('/');
            const fiscal_month = month_year.first_month
            const fiscal_year = month_year.first_year
            if(Number(month)>=Number(fiscal_month) && Number(year)>=Number(fiscal_year)){
                return { "status": true, "value": value }
            }
            if(Number(year)>Number(fiscal_year)){
                return { "status": true, "value": value }
            }
            return { "status": false }
            // if(Number(userSelectDate[0])===currentYear && Number(userSelectDate[1])-1>=currentMonth){
            //     return { "status": true, "value": value }
            // }
            // if(Number(userSelectDate[0])>currentYear){
            //     return { "status": true, "value": value }
            // }
            // return { "status": false }
        }
    }
}