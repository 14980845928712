import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Table,
    ConfigProvider,
} from "antd";
import "./index.css";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import InvestorName from "../assets/TableIcon/Investor_name.png";
import Email from "../assets/TableIcon/email.png"
import AccessImage from "../assets/TableIcon/AceessImage.png"
import CirclePlus from "../assets/TableIcon/circlePlusIc.png";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import {
    PlusOutlined,
} from "@ant-design/icons";
import ActionMenuInvestorFilesCard from "../DesignSystem/Cards/ActionMenuInvestorFilesCard";
import { sharedWithMeApi, sharedWithMeDirApi } from "../features/dataRoomSlice";
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import { useParams } from "react-router-dom";
import TextLarge from "../DesignSystem/TypographyDescriptions/TextLarge";

// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   const day = String(date.getDate()).padStart(2, '0');
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//   const year = date.getFullYear();
//   return `${day}/${month}/${year}`;
// };

export default function UserDataRoomFiles() {
    const dispatch = useDispatch();
    const { directoryId } = useParams() || {};
    const [featureData, setFeatureData] = useState({});
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filesData, setFilesData] = useState([])
    const [data, setData] = useState([])
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const isDashboard = location.pathname === "/product/home/data-room/user"

    const onSelectChange = (newSelectedRowKeys) => {
        setButtonDisabled(!newSelectedRowKeys.length);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsInitialLoad(true)
                // const response = await dispatch(sharedWithMeApi());
                const response = await (isDashboard
                    ? dispatch(sharedWithMeApi(directoryId))
                    : dispatch(sharedWithMeDirApi(directoryId)));
                console.log(response.payload, "response.payload user data room");
                const extractedData = (isDashboard ? response.payload?.shareFileInfoList : response.payload.dirFileList)?.map((obj, index) => {
                    return {
                        _id: obj._id,
                        fileId: index + 1,
                        fileName: obj.name,
                        path: obj.path,
                        folderName: obj.path.split('/')[(obj.path.split('/')).length - 2]
                    }
                })
                console.log(extractedData)
                setFilesData(extractedData);
            } catch (error) {
                console.error("Failed to list directory", error);
                // setError(error);
            } finally {
                setIsInitialLoad(false)
            }
        };

        fetchData();
    }, [dispatch, isModalVisible]);

    // const Dummydata = [
    //   {
    //       fileId: 1,
    //       fileName: "File ABC 1"
    //   },
    //   {
    //       fileId: 2,
    //       fileName: "File ABC 2"
    //   },
    //   {
    //       fileId: 3,
    //       fileName: "File ABC 3"
    //   }
    // ]

    const columns = [
        {
            title: (
                <div className="flex items-center py-3 ml-2 bg-white justify-center">
                    <span className=" font-normal text-[#757575]">File Id</span>
                </div>
            ),
            dataIndex: "FileId",
            key: "FileId",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1 justify-center">
                        <h1 className="text-[#111827] font-medium font-sans text-sm m-0 tracking-wide pl-2">
                            {record.fileId}
                        </h1>
                    </div>
                );
            },
            width: '13%',
        },
        {
            title: (
                <div className="flex items-center py-3 bg-white w-full">
                    <img src={Email} alt="file-ic" className=" w-4 h-4 border mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">File Name</h1>
                </div>
            ),
            dataIndex: "FileName",
            key: "FielName",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1">
                        <h1 className=" text-[#111827] font-medium font-sans text-sm m-0 tracking-wide">
                            {record.fileName}
                        </h1>
                    </div>
                );
            },
            width: '50%',
        },
        {
            title: (
                <div className="flex items-center justify-start pl-10 py-3 bg-white pl-4">
                    <img
                        src={AccessImage}
                        alt="file-ic"
                        className=" w-4 object-contain h-4 border mr-2"
                    />
                    <h1 className="m-0  font-normal text-[#757575]">Question</h1>
                </div>
            ),
            key: "comments",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1 ml-3">
                        <PrimaryButton text={"Ask Question"} size="Meidum" onClick={() => handleAskQuestion(record?._id)}></PrimaryButton>
                    </div>
                );
            },
        },
        {
            title: (
                <div className="flex items-center justify-center py-3 bg-white pr-4">
                    <img
                        src={CirclePlus}
                        alt="file-ic"
                        className="w-3 object-contain h-5 border ml-5"
                    />
                </div>
            ),
            key: "actions",
            render: (_, record) => (
                <div className="py-1.5 w-full flex justify-center">
                    <ActionMenuInvestorFilesCard document={record} />
                </div>
            ),
        },
    ];
    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card._id !== cardDetails._id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);

    const [modalTitle, setModalTitle] = useState(null);

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const handleAskQuestion = (id) => {
        setModalTitle("Ask New Question")
        setData(id)
        console.log(id)
        setIsModalVisible(true)
    }

    const handleButtonClick = () => {
        setModalTitle("Edit Investor Details");
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };

    const handleDeleteButton = () => {
        setModalTitle("Delete File");
        setIsModalVisible(true);
    };

    const closeDeleteModal = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(null);
    };

    const onDownloadClick = () => {
        setModalTitle("Download");
        setIsModalVisible(true);
    };

    return (
        <div 
            className="pb-10"
            style={{
                "marginTop": isDashboard ? '0px' : '4rem'
            }}
        >
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    isOpen={true}
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                    }}
                    documentList={data}
                />
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
            {
                    !isDashboard
                        ? (
                            <TextLarge
                                text="Shared Files"
                            />
                        ) : null
                }
                <div className="mx-auto">
                    <div className="mt-4 overflow-hidden">
                        <ConfigProvider
                            theme={{
                                components: {
                                    Table: {
                                        headerBg: "#FFFFFF",
                                    },
                                },
                            }}
                        >
                            <Table
                                columns={columns}
                                dataSource={filesData}
                                rowKey={(record) => record.fileId}
                                className="border border-[#9E9E9E] rounded-2xl overflow-hidden"
                                rowClassName={() =>
                                    "bg-white border border-[#9E9E9E] px-3 mb-2"
                                }
                                rowSelection={rowSelection}
                                pagination={false}
                                rowHoverable={false}
                                components={{
                                    body: {
                                        row: ({ children, ...restProps }) => (
                                            isInitialLoad ? (
                                                <tr {...restProps}>
                                                    <td colSpan={columns.length} className="w-full text-center">
                                                        <div className="w-full flex justify-center items-center">
                                                            <div className="w-6 h-6 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr {...restProps}>
                                                    {children}
                                                </tr>
                                            )
                                        )
                                    }
                                }}
                            />
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

