import React, { useState, useEffect } from 'react'
import { updateMenuItemSelection, updateItemSelection } from '../features/manageLinkSlice';
import Play from "../assets/Home/Play.svg"
import MoreSquare from "../assets/Home/More Square.svg";
import { useSelector, useDispatch } from "react-redux";
import { exploreCoreFeature } from "../data"
import { useLocation, useNavigate } from "react-router-dom";
import { Progress, Button } from 'antd';
import Lock from "../assets/Home/menu/Lock.svg";
import { fetchActivity } from '../features/manageLinkSlice';
import '../index.css';
import { fetchOnboardingDetails } from '../features/onboardingSlice';
import dashboardLocals from "../localization/localization_en_dashboard.json"
import LockSVG from '../components/Menu/LockSVG';
import SkeletonLoader from '../DesignSystem/Loader/SkeletonLoader';
import VideoModal from '../components/reuseablecomponent/PopUpVarients/VideoModal';
import { generateURLS3Asset } from '../utils/generateURLS3Asset';
import appLocals from '../localization/localization_en_app.json'
import useLockedFeature from '../hooks/useLockedFeature';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TextSmall from '../DesignSystem/TypographyDescriptions/TextSmall';
import { hexRGBConversion } from '../utils/hexRGBConversion';
import useApp from '../hooks/useApp';

const CoreFeatureCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isPaymentActive, handleRestrictAccessModuleDuePayment } = useApp();
    const linkInfo = useSelector(state => state.link);
    const activeItem = useSelector(state => state.link.activeItem);
    const openKeys = useSelector((state) => state.link.openKeys);
    const username = useSelector((state) => state.user.value.username);
    const [featureData, setFeatureData] = useState({});
    const [flippedCards, setFlippedCards] = useState(new Array(featureData["cards"]?.length).fill(false));
    const status = useSelector((state) => state.link.status);
    const progress = useSelector((state) => state.link.progress);
    const onboarding = useSelector((state) => state.onboarding.value.Company);
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const location = useLocation().pathname;
    const { LOCKED_MODULES } = useLockedFeature();
    

    useEffect(() => {
        if (!onboarding?.length) {
            dispatch(fetchOnboardingDetails());
        }
    }, [])

    const handleCardClick = (event, index) => {
        if (!event.target.matches('Button')) {
            const newFlippedCards = [...flippedCards];
            newFlippedCards[index] = !newFlippedCards[index];
            setFlippedCards(newFlippedCards);
        }
    };
    useEffect(() => {
        if (status === "succeeded") {
            const copyOfCards = featureData["cards"];
            const updatedCards = copyOfCards?.map((card, i) => {
                if(location==="/product/home", card.card_Info.cardTitle === "Business Planning"){
                    return { ...card, progress: progress[i]?.progress, activityCount: progress[i]?.activityCount-1 }
                }
                return { ...card, progress: progress[i]?.progress, activityCount: progress[i]?.activityCount }
            })
            setFeatureData((featureData) => ({ ...featureData, cards: updatedCards }))
        }
    }, [status])
    useEffect(() => {
        console.log()
        const feature = exploreCoreFeature.find((feature) => feature.key === openKeys[openKeys.length - 1]);
        if (feature !== undefined) {
            setFeatureData(feature);
            setAnimateOnMount(true);
            dispatch(fetchActivity({ index1: feature.index1, index2: feature.index2 }));
            setFlippedCards(new Array(feature["cards"]?.length).fill(false));
        }
        const timerToResetAnimation = setTimeout(() => {
            setAnimateOnMount(false);
        }, 700);
        return () => clearTimeout(timerToResetAnimation);
    }, [activeItem])

    const handleNavigateToLearnConcepts = (path) => {
        navigate(path)
    }

    // const IS_EXCUDE_LEARN_CONCEPTS = !['0'].includes(activeItem)

    return (
        <div className="pt-[6rem] w-[75%] min-h-[100vh]">
            <div className={`w-[100%] ${animateOnMount && 'fade-in-out'}`}>
                {
                    <div>
                        <div className='mx-auto'>
                            <div className='mt-[3.3rem]'>
                                <p style={{ marginBottom: '8px' }} className='font-DMSans text-[#111827] text-[18px]'>{featureData?.module_description?.moduleTitle} {featureData?.module_description?.moduleTitle?.includes('Welcome') && username}</p>
                                <p className='text-[14px] italic font-medium tracking-wide text-[#909090]'>{featureData?.module_description?.moduleDescription1}</p>
                                <p className='text-[14px] italic font-medium tracking-wide text-[#909090]'>{featureData?.module_description?.moduleDescription2}</p>
                            </div>
                            <div className='min-w-[100%] mx-auto mt-[24px]'>
                                <ul className='grid xl:grid-cols-2 grid-cols-1 gap-[28px] place-items-center xl:place-items-start'>
                                    {
                                        featureData["cards"] !== undefined && featureData["cards"].map((card, i) => {
                                            const IS_EXCUDE_LEARN_CONCEPTS = card.learn_concept_url !== null;
                                            const isProgressVisible = card.isProgressVisible === undefined ? true : false
                                            const flipStyle = {
                                                transform: flippedCards[i] ? 'rotateY(180deg)' : 'none'
                                            };
                                            const CARD_NAVIGATION_BUTTON = card?.card_Info?.cardTitle===dashboardLocals?.dashboard?.fy_report_ref_btn ? dashboardLocals.dashboard?.view_financial_report_btn_text : dashboardLocals?.dashboard?.get_started_btn_text;
                                            const video = generateURLS3Asset({ assetType: "video", sub_path: card?.video_sub_path })
                                            const IS_LOCKED = LOCKED_MODULES.includes(card?.MODULE_NAME);
                                            const parentCardColor = hexRGBConversion(card.bgColor, 0.5)
                                            return (
                                                <li key={i} className={`flip-card w-[100%] ${IS_EXCUDE_LEARN_CONCEPTS ? 'min-h-[12.5rem]' : ''}`}>
                                                    <div className="flip-card-inner" style={flipStyle}>
                                                        <div className={`flip-card-front relative rounded-md border`} style={{ "backgroundColor": parentCardColor, "borderColor":card.borderColor }}>
                                                            <div style={{ "backgroundColor": card.bgColor, "borderColor":card.borderColor }} className='max-h-[10rem] border-b h-full relative text-[#FFF] rounded-[7px]'>
                                                                <div className="icons flex items-center absolute right-[8px] top-[8px] mr-[8px] cursor-pointer">
                                                                    {
                                                                        <VideoModal
                                                                            video={video}
                                                                        >
                                                                            {
                                                                                ({ handleToggleModal }) => (
                                                                                    <button onClick={() => {
                                                                                        if(!isPaymentActive){
                                                                                            handleRestrictAccessModuleDuePayment();
                                                                                            return;
                                                                                        }else{
                                                                                            card?.video_sub_path ? handleToggleModal() : null
                                                                                        }
                                                                                    }}><img src={Play} alt="" className='mr-[8px]' /></button>
                                                                                )
                                                                            }
                                                                        </VideoModal>
                                                                    }
                                                                    <Button className='p-0 border-none' onClick={(e) => handleCardClick(e, i)}><img src={MoreSquare} alt="" /></Button>
                                                                </div>
                                                                <p className='font-DMSans absolute top-[24px] left-[24px] text-[18px] text-start text-primary'>{card?.card_Info?.cardTitle}</p>
                                                                <button disabled={IS_LOCKED} onClick={(e) => {
                                                                    if(!isPaymentActive){
                                                                        handleRestrictAccessModuleDuePayment();
                                                                        return;
                                                                    }else{
                                                                        if (IS_LOCKED) {
                                                                            const newFlippedCards = [...flippedCards];
                                                                            newFlippedCards[i] = !newFlippedCards[i];
                                                                            setFlippedCards(newFlippedCards);
                                                                        } else if (card.type === 'Component') {
                                                                            dispatch(updateItemSelection({ activeItem: card.itemkey }));
                                                                            navigate(card.link);
                                                                            // console.log(card.link)
                                                                        } else {
                                                                            dispatch(updateItemSelection({ activeItem: card.itemkey }));
                                                                            dispatch(updateMenuItemSelection({ openKeys: [...linkInfo.openKeys, card.itemkey], selectedKeys: card.itemkey.length <= 2 ? [card.itemkey] : [...linkInfo.selectedKeys] }));
                                                                        }
                                                                    }
                                                                }} style={{ backgroundColor: IS_LOCKED ? "#96ADFF" : "#372EE5", cursor: IS_LOCKED ? 'not-allowed' : 'pointer' }} className='absolute bottom-[16px] left-[16px] font-InterRegular p-[8px] bg-primary rounded-[8px] text-[15px] tracking-wide font-medium text-white border-transparent'>{CARD_NAVIGATION_BUTTON}</button>
                                                                {
                                                                    isProgressVisible ? (
                                                                        <div className='absolute bottom-0 right-[8px] w-fit'>
                                                                            <div style={{ marginBottom: 0 }} className='font-InterRegular text-[15px] flex font-medium tracking-wide text-baseGray900'>
                                                                                {card.activityCount} {dashboardLocals.dashboard.activities_text} |&nbsp;{status === "loading" ? <><div className='w-[1.5rem]'><SkeletonLoader baseColor="#9E9E9E" /></div></> : <span>{card.progress}%</span>}
                                                                            </div>
                                                                            <Progress trailColor={card?.strokeColor} size="small" strokeLinecap="round" percent={card.progress} showInfo={false} />
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                <div className='absolute top-[25%] left-1/2 -translate-x-1/2 w-[72px] h-[72px]'>
                                                                    {
                                                                        IS_LOCKED && <LockSVG color={card?.strokeColor} />
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                               IS_EXCUDE_LEARN_CONCEPTS 
                                                                ? (
                                                                    <button onClick={() => handleNavigateToLearnConcepts(card?.learn_concept_url)} className='w-full flex items-center gap-x-[8px] py-[8px] px-[16px]'>
                                                                        <TipsAndUpdatesIcon className='text-[#372EE5]' />
                                                                        <TextSmall fontWeight={500} color='#372EE5' text={dashboardLocals.dashboard.learn_concepts} />
                                                                    </button>
                                                                ): null
                                                            }
                                                        </div>
                                                        <div className="flip-card-back relative border rounded-md" style={{ "backgroundColor": parentCardColor, "borderColor":card.borderColor }}>
                                                            <div style={{ backgroundColor: card.bgColor, borderColor: card.borderColor }} className='max-h-[10rem] h-full border-b relative text-[#FFF] rounded-[7px]'>
                                                                {
                                                                    IS_LOCKED && (
                                                                        <div className='absolute top-[8px] left-[8px] w-[40px] h-[40px]'><LockSVG color={card?.strokeColor} /></div>
                                                                    )
                                                                }
                                                                <div className="icons flex items-center absolute right-[8px] top-[8px] mr-[8px] cursor-pointer">
                                                                    {
                                                                        <VideoModal
                                                                            video={video}
                                                                        >
                                                                            {
                                                                                ({ handleToggleModal }) => (
                                                                                    <button onClick={() => {
                                                                                        if(!isPaymentActive){
                                                                                            handleRestrictAccessModuleDuePayment();
                                                                                            return;
                                                                                        }else{
                                                                                            card?.video_sub_path ? handleToggleModal() : null
                                                                                        }
                                                                                    }}><img src={Play} alt="" className='mr-[8px]' /></button>
                                                                                )
                                                                            }
                                                                        </VideoModal>
                                                                    }
                                                                    <Button className='p-0 border-none' onClick={(e) => handleCardClick(e, i)}><img src={MoreSquare} alt="" /></Button>
                                                                </div>
                                                                <p style={{ marginBottom: 0 }} className={`${card?.card_Info?.cardDescription === null ? 'top-[61px]' : 'top-[42px]'} text-start font-InterRegular absolute left-[20px] pr-[24px] text-[12px] text-primary`}>
                                                                    {
                                                                        IS_LOCKED
                                                                            ? dashboardLocals.dashboard.feature_unavailable
                                                                            : card?.card_Info?.cardDescription
                                                                    }
                                                                </p>
                                                                {
                                                                    card.card_Info?.cardDescription !== null && (
                                                                        <div>
                                                                            <button onClick={() => {
                                                                                if(!isPaymentActive){
                                                                                    handleRestrictAccessModuleDuePayment();
                                                                                    return;
                                                                                }else{
                                                                                    if (card.type === 'Component') {
                                                                                        navigate(card.link);
                                                                                        // console.log(card.link)
                                                                                    } else {
                                                                                        dispatch(updateItemSelection({ activeItem: card.itemkey }));
                                                                                        dispatch(updateMenuItemSelection({ openKeys: [...linkInfo.openKeys, card.itemkey], selectedKeys: card.itemkey.length <= 2 ? [card.itemkey] : [...linkInfo.selectedKeys] }));
                                                                                    }
                                                                                }
                                                                            }} className='absolute bottom-[16px] left-[20px] bg-primary font-InterRegular  p-[8px]  rounded-[8px] text-[15px] tracking-wide font-medium text-white border-transparent'>{CARD_NAVIGATION_BUTTON}</button>
                                                                            {
                                                                                isProgressVisible ? (
                                                                                    <div className='absolute bottom-0 right-[8px] w-fit'>
                                                                                        <div style={{ marginBottom: 0 }} className='font-InterRegular text-[15px] flex font-medium tracking-wide text-baseGray900'>
                                                                                            {card.activityCount} {dashboardLocals.dashboard.activities_text} |&nbsp;{status === "loading" ? <><div className='w-[1.5rem]'><SkeletonLoader baseColor="#9E9E9E" /></div></> : <span>{card.progress}%</span>}
                                                                                        </div>
                                                                                        <Progress trailColor={card?.strokeColor} size="small" strokeLinecap="round" percent={card.progress} showInfo={false} />
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            {
                                                                IS_EXCUDE_LEARN_CONCEPTS
                                                                ? (
                                                                    <button onClick={() => handleNavigateToLearnConcepts(card?.learn_concept_url)} className='w-full flex items-center gap-x-[8px] py-[8px] px-[16px]'>
                                                                        <TipsAndUpdatesIcon className='text-[#372EE5]' />
                                                                        <TextSmall fontWeight={500} color='#372EE5' text={dashboardLocals.dashboard.learn_concepts} />
                                                                    </button>
                                                                ): null
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default CoreFeatureCard