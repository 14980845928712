import React from "react";

const DiscriptionSVG = ({ color = "#fff", ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12H6V10H0V12ZM0 0V2H18V0H0ZM0 7H12V5H0V7Z" fill="#424242" />
  </svg>
);
export default DiscriptionSVG;
