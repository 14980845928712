import React from 'react'
import { Table } from "antd";
import { useSelector } from "react-redux";
import EditableTable from './EditableTable';
import { FormatMarketingSpendData } from '../utils/FormatMarketingSpendData';
import { months } from '../../../data/data';
import { generateMonth } from '../../../functionality/RevenueOnboarding/revenueOnboardingUtiliy';

const MarketingSpendTable = (props) => {
    const SPAN_TYPE = useSelector((state) => state.costScreen.marketing_spend_span_type); //last_six_months , last_month_marketing_spend
    const month_year = useSelector((state) => state.costScreen.revenue_onboarding_marketing_spend_details.month_year)
    const category_list_data = useSelector((state) => state.costScreen.category_list_data)
    const FORM_ITEM = props.payloadData.formItem;
    const Channel = ["E-Commerce","Social Media","Website & Mobile App"]
    const FORM_DATA = useSelector((state) => state.costScreen.marketing_spend)
    const sales_channel_category_list = useSelector((state) => state.costScreen.category_list_data.sales_channel_category_list)
    const Channel_Names = FORM_DATA.map((data) => data.name);
    const FILTERED_OPTIONS = sales_channel_category_list.filter((data) => !Channel_Names.includes(data.value) || !Channel.includes(data.value))
    let FORM_COLUMNS = [];
    FORM_COLUMNS = FORM_ITEM.table[SPAN_TYPE].columns.map((row) => "select_options" in row ? {...row, "select_options":FILTERED_OPTIONS } : row); //trash data line no. 48 up
    let startMonth;
    let index = 0;
    for (const mon of Object.entries(months)) {
        index++;
        if (mon[1].value === SPAN_TYPE==="last_month_marketing_spend" ? FORM_DATA[0]?.month : FORM_DATA[0]?.last_six_months?.[0]?.month) {
            startMonth = index;
        }
    }
    FORM_COLUMNS = SPAN_TYPE==="last_month_marketing_spend"  ? FORM_COLUMNS  : FORM_COLUMNS.map((col, i) => (i>1) ? {...col, "title": generateMonth({ startMonth, seriesMonth: i+1, outputType: "value" })+'_'+Number(month_year?.split('-')?.[0])} : col);
    const TRANSFORMED_TABLE_DATA = FormatMarketingSpendData({SPAN_TYPE,FORM_DATA});
    const payloadData = {  
        formItem: { "table": { "noDataTagContent": FORM_ITEM.table.noDataTagContent,warning_tooltip: SPAN_TYPE==="last_month_marketing_spend" ? FORM_ITEM.table?.last_month_marketing_spend.warning_tooltip : FORM_ITEM.table?.last_six_months.warning_tooltip,columns: SPAN_TYPE==="last_month_marketing_spend" ? FORM_COLUMNS : FORM_COLUMNS, tooltip: FORM_ITEM.table?.tooltip, "is_add_row": true, "is_delete_row": true, "max_no_row": 10 }, "name": "marketing_spend" }, 
        data: TRANSFORMED_TABLE_DATA,
        name: "marketing_spend"
    }
    const filterSelectOptions = (options) => {
        const selected_options = FORM_DATA.map((d) => d.name)
        return options.filter((option) => !selected_options.includes(option.value))
    }
    let finalPayloadData = { // appending category list in formItem column
        ...payloadData,
        "formItem": {
            ...payloadData?.formItem,
            "table": {
                ...payloadData?.formItem?.table,
                columns: payloadData?.formItem?.table?.columns?.map((col) => "select_option_external_deps" in col ? { ...col, "select_options": filterSelectOptions(category_list_data[col.select_option_external_deps]) } : col)
            }
        }
    }
    return (
        <div>
            <EditableTable payloadData={finalPayloadData} />
        </div>
    )
}

export default MarketingSpendTable