import React from 'react'
import { NavLink, Outlet, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import './setting.css'
import MessageNotification from "../reuseablecomponent/MessageNotification"
import { useSelector, useDispatch } from 'react-redux'
import { Tabs } from "antd";
import LinkTab from '../../DesignSystem/Tabs/LinkTab'
import AccountSetting from './AccountSetting'
import UserSetting from './UserSetting'
import Permission from './Permission'
import UpdateOnboarding from './UpdateOnboarding'
import Subscription from './Subscription'
import { updateSettingTabNumber } from './settingSlice'
import GlobalSetting from '../BusinessModal/components/GlobalSetting'
import BMCategoryList from '../BusinessModal/components/BMCategoryList'

const Setting = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const error = useSelector((state) => state.setting.error);
    const loading = useSelector((state) => state.setting.loading);
    const settingTabNumber = useSelector((state) => state.setting.settingTabNumber)
    const navLinkStyle = ({ isActive }) => {
        return {
            color: isActive ? '#372EE5' : '#757575',
            borderBottom: isActive ? '2px solid #372EE5' : '2px solid #E0E0E0',
            padding: "3px 12px",
            marginRight:"12px"
        }
    }
    const isAdmin = useSelector((state) => state.user.value.isAdmin);
    const module_specific_setting = import.meta.env?.VITE_REACT_ACTIVE_SETTING_TAB ? import.meta.env.VITE_REACT_ACTIVE_SETTING_TAB?.split(",")?.map((s) => s?.trim()) : [];
    const adminTab = [
        {"label":"Account Settings",component:<AccountSetting />,key:"account", "dependency": "ACCOUNT_TAB"},
        {"label":"Users Settings", component:<UserSetting/>,key:"user", "dependency": "USER_TAB"},
        {"label":"Permissions", component:<Permission/>,key:"permission", "dependency": "PERMISSION_TAB"},
        {"label":"Update Business Profile", component:<UpdateOnboarding/>,key:"onboarding", "dependency": "BUSINESS_PROFILE_TAB"},
        {"label":"Global Setting", component:<GlobalSetting/>,key:"globalSetting", "dependency": "BUSINESS_MODEL_TAB"},
        {"label":"Catalog", component:<BMCategoryList/>,key:"catalog", "dependency": "BUSINESS_MODEL_TAB"}
        // {"label":"Subscription", component:<Subscription/>,key:"subscription"}
    ].filter((tab) => tab?.dependency ? module_specific_setting?.includes(tab?.dependency) : false)
    const userTab = [
        {"label":"Account Settings",component:<AccountSetting />,key:"account", "dependency": "ACCOUNT_TAB"},
        // {"label":"Permissions", component:<Permission/>,key:"permission", "dependency": "PERMISSION_TAB"},
        {"label":"Global Setting", component:<GlobalSetting/>,key:"globalSetting", "dependency": "BUSINESS_MODEL_TAB"},
        {"label":"Catalog", component:<BMCategoryList/>,key:"catalog", "dependency": "BUSINESS_MODEL_TAB"}
        // {"label":"Subscription", component:<Subscription/>,key:"subscription"}
    ].filter((tab) => tab?.dependency ? module_specific_setting?.includes(tab?.dependency) : false)
    
    const handleTabChange = (value) => {
        dispatch(updateSettingTabNumber(value));
    }
    return (
        <div className='pt-[4.5rem] min-h-screen'>
            {loading == "loading" && <MessageNotification type={"error"} status={loading} content={error} />}
            <LinkTab activeKey={settingTabNumber} handleTabChange={handleTabChange} tabNames={isAdmin ? adminTab : userTab} />
        </div>
    )
}

export default Setting
