import api from "../../services/api/api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import routesConfig from "../../services/config/routesConfig.json"
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";


export const fetchUserActivityDataApi = createAsyncThunk(
    'useractivity/fetchUserActivityDataApi',
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'notification', 'getactivity');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const postUserActivityDataApi = createAsyncThunk(
    'useractivity/postUserActivityDataApi',
    async (data, { getState }) => {
        try {
            const state = getState();
            const isAdmin = state.user.value.isAdmin;
            const currentlocation = window.location.pathname;
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'notification', 'postactivity');
            const isPostActivity = isAdmin ? currentlocation.includes('settings') : true;
            if (!!urlGenerator && isPostActivity) {
                const urlConfig = { ...urlGenerator, data: data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const clearAllNotificationDataApi = createAsyncThunk(
    'useractivity/clearAllNotificationDataApi',
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'notification', 'clearnotification');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator}
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);



const userActivitySlice = createSlice({
    name: "useractivity",
    initialState: {
        loading: 'idle',
        toggleNotification: false,
        activites: [],
        resetActivity: [],
        lastSeenNotification: ''
    },
    reducers: {
        updateLoading(state, action) {
            state.loading = action.payload;
        },
        toggleNotification(state, action) {
            state.toggleNotification = !state.toggleNotification;
        },
        resetActivityOnUnMount(state, action) {
            state.activites = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserActivityDataApi.pending, (state, action) => {
                state.loading = 'pending';
            })
            .addCase(fetchUserActivityDataApi.fulfilled, (state, action) => {
                state.loading = 'success';
                console.log(action.payload);
                state.activites = action.payload.data;
                state.lastSeenNotification = action.payload.lastSince
            })
            .addCase(fetchUserActivityDataApi.rejected, (state, action) => {
                state.loading = 'failed';
            })
            .addCase(clearAllNotificationDataApi.pending, (state, action) => {
                state.loading = 'pending';
            })
            .addCase(clearAllNotificationDataApi.fulfilled, (state, action) => {
                state.loading = 'success';
                state.activites = [];
                state.lastSeenNotification = action.payload.lastSince
            })
            .addCase(clearAllNotificationDataApi.rejected, (state, action) => {
                state.loading = 'failed';
            })
    },
});

export const { resetActivityOnUnMount, updateLoading, toggleNotification } = userActivitySlice.actions;
export default userActivitySlice.reducer;
