import React from 'react'
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium'
import TextSmall from '../../../DesignSystem/TypographyDescriptions/TextSmall'
import classNames from 'classnames'
import SearchNotFound from '../../components/SearchNotFound'

const NestedVerticalList = ({ list, nested, activeParentItem, activeChildrenItem, handleOnClick, filterSubList }) => {
    const isAtleastOneSearchResultsFound = list?.filter((q) => q?.title?.toLowerCase()?.includes(filterSubList?.toLowerCase()))
    if (nested && isAtleastOneSearchResultsFound?.length === 0) {
        return <SearchNotFound searchText={filterSubList} />
    }
    return (
        <ul
            className={nested ? 'flex flex-col justify-start items-start gap-y-[16px] border-l' : ''}
        >
            {
                list?.map((item, index) => {
                    if (nested && !item?.title?.toLowerCase()?.includes(filterSubList?.toLowerCase())) {
                        return null;
                    }
                    return (
                        <li
                            key={item?._id || index}
                            className={
                                classNames(
                                    'flex flex-col justify-start items-start gap-y-[16px]',
                                    { 'my-[16px]': !nested }
                                )
                            }
                        >
                            <button
                                className='flex justify-start items-start text-start'
                                onClick={() => handleOnClick && handleOnClick({ "parentOrChildren": nested ? "children" : "parent", "_id": item?._id })}
                            >
                                {
                                    nested
                                        ? (
                                            <TextSmall
                                                key={index}
                                                text={item?.title}
                                                fontWeight={500}
                                                color={activeChildrenItem === item?._id ? "#4B54FF" : "#757575"}
                                                className={"pl-[11.5px]"}
                                            />
                                        ) : (
                                            <TextMedium
                                                key={index}
                                                text={item?.title}
                                                fontWeight={500}
                                                color={activeParentItem === item?._id ? "#4B54FF" : "#757575"}
                                            />
                                        )
                                }
                            </button>

                            {
                                (activeParentItem === item._id)
                                    ? (
                                        <NestedVerticalList
                                            list={item?.list}
                                            nested={true}
                                            filterSubList={filterSubList}
                                            activeParentItem={activeParentItem}
                                            activeChildrenItem={activeChildrenItem}
                                            handleOnClick={handleOnClick}
                                        />
                                    ) : null
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default NestedVerticalList