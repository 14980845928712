import React from 'react';
import BaseModal from './BaseModal';
import close from "../../assets/common/close.svg"
import ImageButton from '../Buttons/ImageButton';
import TextExtraLarge from '../TypographyDescriptions/TextExtraLarge';
import SecondaryButton from '../Buttons/SecondaryButton';
import PrimaryDangerButton from '../Buttons/PrimaryDangerButton';
import TextMedium from '../TypographyDescriptions/TextMedium';
import PrimaryButton from '../Buttons/PrimaryButton';

const ConfirmModal = ({ isOpen, onCancel, onConfirm, isDisabled, confirmButtonName = "Confirm", title, description1, description2, isLoading }) => {
    return (
        <BaseModal isOpen={isOpen}>
            <div className='absolute top-[18px] right-[18px] border-none'>
                <ImageButton image={close} onClick={onCancel} />
            </div>
            <div className='py-[16px]'>
                <TextExtraLarge text={title} />
            </div>
            <div className='mt-[0.5rem] mb-[1rem]'>
                <TextMedium display='block' text={description1} fontWeight={500} />
                <TextMedium display='block' text={description2} fontWeight={500} />
            </div>
            <div className='flex justify-between pt-[18px] w-[100%]'>
                <SecondaryButton text={"Cancel"} onClick={onCancel} />
                <PrimaryDangerButton isDisabled={isDisabled} isLoading={isLoading} text={confirmButtonName} onClick={onConfirm} />
            </div>
        </BaseModal>
    )
}

export default ConfirmModal