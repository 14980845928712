import React, { useEffect, useState } from 'react'
import downloadAccess from "./assets/DownloadAccess.svg"
import paintAccess from "./assets/paintAccess.svg"
import EditTable from "./assets/EditTable.svg";
import { Table, Checkbox, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchPermissionDataApi, updatePermission, updatePermissionDataApi, resetStatus, trackNewPermissionAdded } from "./settingSlice";
import PageSpinner from '../reuseablecomponent/PageSpinner';
import TextComponent from '../reuseablecomponent/TextVarients/TextComponent';
import MessageNotification from '../reuseablecomponent/MessageNotification';
import setting_locals from "../../localization/localization_en_setting.json";
import Notification from '../../DesignSystem/Popup/Notification';
import DeleteIcon from "../../assets/common/Delete.svg"
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';

const locals = setting_locals.settings.tabs.Permissions;

const Permission = () => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.setting.permissions);
  const loading = useSelector((state) => state.setting.loading);
  const error = useSelector((state) => state.setting.error);
  const isAdmin = useSelector((state) => state.user.value.isAdmin);
  const errorMessage = useSelector((state) => state.setting.error);
  const successMessage = useSelector((state) => state.setting.success);
  const track = useSelector((state) => state.setting.track);
  const isFreeSubscription = useSelector((state) => state.user.planStatus) === "free";
  
  useEffect(() => {
    dispatch(fetchPermissionDataApi());
  }, [])
  if (loading == "pending" && permissions.data?.length > 0) {
    return <p>Loading...</p>
  }
  const callbackResetStatus = () => {
    dispatch(resetStatus());
}

  const columns = [
    {
      title: <div className='py-[18px] pl-[1rem] text-primary font-InterRegular text-[16px] font-medium tracking-wide'>{locals.table.column1}</div>,
      dataIndex: 'username',
      key: 'username',
      render: (_, record) => {
        return (
          <div className='py-[20px] pl-[1rem] bg-[#F4F7FF] pb-[16px] mb-[1rem] rounded-l-[10px]'>
            <TextComponent fontSize={14} fontWeight={500} text={(record.username ? record.username : '-') + ` ` + `[${record.email}]`} />
          </div>
        )
      },
      width:'40%'
    },
    {
      title: <div><ModeEditOutlinedIcon fontSize='large' className='text-primary' /></div>,
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => {
        return (
          <div className='py-[20px] bg-[#F4F7FF] pb-[16px] mb-[1rem]'>
            <Checkbox disabled={!isAdmin} className='permission' onChange={() => {
              dispatch(updatePermission({ fieldName: "edit", id: record._id }));
              dispatch(trackNewPermissionAdded({docId:record._id,permissionName: "edit"}))
            }} checked={record.edit}></Checkbox>
          </div>
        )
      },
      width:'13.3%'
    },
    {
      title: <div><DownloadOutlinedIcon fontSize='large' className='text-primary' /></div>,
      dataIndex: 'download',
      key: 'download',
      render: (_, record) => {
        return (
          <div className='py-[20px] bg-[#F4F7FF] pb-[16px] mb-[1rem]'>
            <Checkbox disabled={!isAdmin || isFreeSubscription} className='permission' onChange={() => {
              dispatch(updatePermission({ fieldName: "download", id: record._id }))
              dispatch(trackNewPermissionAdded({docId: record._id,permissionName: "download"}))
            }} checked={record.download}></Checkbox>
          </div>
        )
      },
      width:'13.3%'
    },
    {
      title: <div><BrushOutlinedIcon fontSize='large' className='text-primary' /></div>,
      key: 'paint',
      dataIndex: 'paint',
      render: (_, record) => (
        <div className='py-[20px] bg-[#F4F7FF] pb-[16px] mb-[1rem] rounded-r-[8px]'>
          <Checkbox disabled={!isAdmin} className='permission' onChange={() => {
            dispatch(updatePermission({ fieldName: "paint", id: record._id }))
            dispatch(trackNewPermissionAdded({docId: record._id, permissionName:"paint"}));
          }} checked={record.paint}></Checkbox>
        </div>
      ),
      width:'13.3%'
    },
    {
      title: <div><DeleteOutlinedIcon fontSize='large' className='text-infoAlertError1' /></div>,
      key: 'delete',
      dataIndex: 'delete',
      render: (_, record) => (
        <div className='py-[20px] bg-[#F4F7FF] pb-[16px] mb-[1rem] rounded-r-[8px]'>
          <Checkbox disabled={!isAdmin} className='permission' onChange={() => {
            dispatch(updatePermission({ fieldName: "delete", id: record._id }))
            dispatch(trackNewPermissionAdded({docId: record._id, permissionName:"delete"}));
          }} checked={record?.delete}></Checkbox>
        </div>
      ),
      width:'13.3%'
    },
  ];

  return (
    <div>
      {loading!=="pending" && <Notification content={{error:errorMessage, success:successMessage}} type={loading} callbackResetStatus={callbackResetStatus} />}
      {/* {loading == "failed" && <MessageNotification type={"error"} status={loading} content={error} />} */}
      {loading == "loading" && <PageSpinner />}
      <div className='lg:w-[95%]'>
        <Table columns={columns} dataSource={permissions} pagination={false} />
        {isAdmin && <div className='flex justify-end mt-[24px] bg-white'>
        <Button loading={loading === "pending"} disabled={loading === "pending"} onClick={() => dispatch(updatePermissionDataApi())} className='bg-primary text-white'>{locals.confirm}</Button>
      </div>}
      </div>
    </div>
  )
}

export default Permission