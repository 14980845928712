const BgColorSVG = ({ color="#fff",...props }) => (
    <svg
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g>
            <path
                fill={color}
                d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm0 16-7-8v8H5l7-8V5h7v14Z"
            />
        </g>
    </svg>
)
export default BgColorSVG
