import React from 'react'
import ToolTip from '../../../../../DesignSystem/ToolTip/ToolTip'
import TextLarge from '../../../../../DesignSystem/TypographyDescriptions/TextLarge'

export const TableToolTip = ({ tooltip = "Tooltip" }) => {
    return (
        <div className='cursor-pointer w-[18px] aspect-square'>
            <ToolTip
                placement={"top"}
                tooltip={tooltip}
            />
        </div>
    )
}

const TableColumnHeading = ({ title, tooltip, isMandatory }) => {
    return (
        <div className='w-full h-fit py-[12px] px-[8px] flex justify-between items-center'>
            <div className='flex justify-between'>
                {isMandatory ? <sub className='text-red-500 pr-[0.2rem] text-[1.2rem] pt-[0.2rem]'>*</sub> : null}
                <TextLarge fontWeight={500} text={title} />
            </div>
            {!!tooltip ? <div className='w-[18px] aspect-square'><TableToolTip tooltip={tooltip} /></div> : null}
        </div>
    )
}

export default TableColumnHeading