import React, { useState } from 'react'
import { Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';
import ButtonComponent from '../ButtonVarients/ButtonComponent';
import oopsEmoji from "../../../assets/common/oopsEmoji.svg";

const OopsPopUp = ({ isOpen, text, textColor, bgColor, onClick, type, description1, description2, description3, buttonText = "OK", children, controlOkButtonTrigger }) => {
    const [isOopsOpen, setIsOopsOpen] = useState(false);
    const handleTogglePopup = () => setIsOopsOpen((prev) => !prev);
    return (
        <React.Fragment>
            <Modal centered open={isOpen || isOopsOpen}>
                <div className='flex flex-col gap-y-0 justify-center items-center'>
                    <img src={oopsEmoji} alt="" className='mb-[24px]' />
                    <span dangerouslySetInnerHTML={{ __html: description1 }} className='text-center text-black'></span>
                    {description2 && <span className='text-center text-black'>{description2}</span>}
                    {description3 && <span className='text-center text-black'>{description3}</span>}
                    <div className='mt-[24px]'>
                        <ButtonComponent text={buttonText} textColor={textColor} bgColor={"#372EE5"} onClick={() => controlOkButtonTrigger ? controlOkButtonTrigger() : onClick ? onClick() : handleTogglePopup()} type={"primary"} />
                    </div>
                    <CloseIcon className='absolute top-[1rem] right-[1rem] cursor-pointer' onClick={() => onClick ? onClick() :  handleTogglePopup()} />
                </div>
            </Modal>
            {typeof children === "function" ? children({ handleTogglePopup }) : null}
        </React.Fragment>
    )
}

export default OopsPopUp