import React from 'react'
import { Tabs } from 'antd';
import "./tabVarientStyle.css"
import TemplateCards from '../../components/PitchDeck/subcomponents/TemplateCards';

const PrimarySmRoundedTab = () => {
    const tabItems = [
        {
            key: '1',
            label: 'All',
            children: <div>Component1</div>,
        },
        {
            key: '2',
            label: 'Basic',
            children: <TemplateCards/>,
        },
        {
            key: '3',
            label: 'Industry',
            children: <div>Component3</div>,
        },
        {
            key: '4',
            label: 'Funding Stage',
            children: <div>Component2</div>,
        },
        {
            key: '5',
            label: 'Marketing',
            children: <div>Component3</div>,
        },
    ]
    const onChange = (key) => {
        console.log(key)
    };
    return (
        <Tabs className='primarySmRounded' defaultActiveKey="2" items={tabItems} onChange={onChange} />
    )
}

export default PrimarySmRoundedTab