import React from 'react'
import TextExtraLarge from '../../TypographyDescriptions/TextExtraLarge'
import TextMedium from '../../TypographyDescriptions/TextMedium'
import PGButtonComponent from '../../Buttons/PGButtonComponent'
import { shapes } from '../../../assets/website'

const { right_arrow } = shapes;

const PlaneCardMoreInfo = ({ title, description, btn, handleOnClick }) => {
    return (
        <div className='py-[32px] px-[16px] rounded-[4px] border border-[#bcbcbd] border-t-[4px] border-t-p3 flex flex-col gap-y-[20px] justify-between'>
            <div className='flex flex-col gap-y-[16px]'>
                <TextExtraLarge text={title} />
                <TextMedium fontWeight={400} text={description} />
            </div>
            <div>
                <PGButtonComponent className={"p-0"} intent={"link"} onClick={handleOnClick}>
                    <span className='text-p3'>{btn}</span>
                    <img src={right_arrow} alt="" />
                </PGButtonComponent>
            </div>
        </div>
    )
}

export default PlaneCardMoreInfo