import React from 'react'
import SkeletonLoader from './SkeletonLoader'

const BunchOfSkeletonLoader = ({ noOfSkeletonLoader=10, className, height=30 }) => {
    return (
        <div className={`flex flex-col ${className}`}>
            {
                Array.from({ length: noOfSkeletonLoader }).map((_, index) => (
                    <SkeletonLoader key={index} height={height} />
                ))
            }
        </div>
    )
}

export default BunchOfSkeletonLoader