import React from 'react'
import TextLarge from '../TypographyDescriptions/TextLarge'
import TextMedium from '../TypographyDescriptions/TextMedium'

const LogoWithTextCard = ( { cardBgColor="red", logoBgColor="blue", logo, title, description, color } ) => {
  return (
    <React.Fragment>
        <div style={{backgroundColor:cardBgColor}} className='rounded-[64px] p-[12px] flex gap-x-[12px]'>
            <div style={{backgroundColor:logoBgColor}} className='rounded-full'>
                <img src={logo} alt="" className='p-[0.5rem]' />
            </div>
            <div style={{color:color}} className='flex items-center gap-y-[24px]'>
                <TextLarge text={title} fontWeight={600} />
                <div className='mx-[0.2rem]'>:</div>
                <TextMedium fontWeight={500} color={color} text={description} independentStyles={{textDecoration:'underline'}} />
            </div>
        </div>
    </React.Fragment>
  )
}

export default LogoWithTextCard