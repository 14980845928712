import React from 'react'
import { Input, message } from "antd";
import appLocals from "../../../localization/localization_en_app.json"

const TextInput = ({ size = "large", placeholder, value, name, onChangeEvent, onBlurEvent, isDisabled, isReadyOnly, isRequired, maxCharLength, pattern, borderColor, preImg, backgroundColor = '#FFFFFF', warning_message_name }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const padding = size === "small" ? "0px 7px" : size === "middle" ? "4px 11px" : "4px 11px";
    const fontSize = size === "small" ? "14px" : size === "middle" ? "14px" : "16px";
    const height = size === "small" ? "22px" : size === "middle" ? "32px" : "38px"
    return (
        <React.Fragment>
            {contextHolder}
            <Input
                style={{ borderRadius: '6px', padding, fontSize, height, border: `1px solid ${borderColor ? borderColor : '#E0E0E0'}`, backgroundColor }}
                onKeyDown={(e) => {
                    const inputValue = e.target.value + e.key;
                    if (inputValue.length > maxCharLength && warning_message_name) {
                        messageApi.warning(appLocals.app.warning_message[warning_message_name], 2)
                    }
                }}
                placeholder={placeholder}
                prefix={preImg && <img src={preImg} />}
                name={name}
                pattern={pattern}
                value={value}
                required={isRequired}
                disabled={isDisabled}
                readOnly={isReadyOnly}
                onChange={onChangeEvent}
                onBlur={onBlurEvent}
                maxLength={maxCharLength}
                onPressEnter={(e) => e.preventDefault()}
            />
        </React.Fragment>
    )
}

export default TextInput