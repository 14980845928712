import IconButton from '../../../DesignSystem/Buttons/IconButton';
import close from "../../../assets/common/closeWhite.svg";
import SlideShowControl from '../subcomponents/SlideShowControl';
import { useSlideShowFunctionality } from '../functionality/useSlideShowFunctionality';

const SlideShow = () => {

    const { handleShowActiveState, showControlButton, slideNumber, isPaused, slides, handleToggleControls } = useSlideShowFunctionality();

    return (
        <div className='bg-black fixed top-0 flex items-center right-0 bottom-0 z-[100] w-full h-full'>
            <div className='w-full aspect-video'>
                <div className='w-full h-full aspect-video relative'>
                    <div className={`${!showControlButton ? "-top-[40rem]" : "top-[40px]"} duration-1000 ease-in absolute right-[40px]`}>
                        <IconButton icon={close} btnBgColor='black' />
                    </div>
                    <img src={slides[slideNumber]} className='w-full h-full aspect-video' alt="" />
                    <div className={`${!showControlButton ? '-bottom-[40rem]' : 'bottom-0'} duration-1000 ease-in absolute w-full`}>
                        <SlideShowControl
                            handleShowActiveSlide={handleShowActiveState}
                            handleTogglePlay={handleToggleControls}
                            isSlideShow={true} isPaused={isPaused}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlideShow;
