import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import { useSelector } from "react-redux";
import tmaLocals from "../../localization/localization_en_target_market_analysis.json"

export const TMABarGraph = ({ segmentData, colorPalette }) => {
  const [data, setData] = useState([
    { type: "Hello", CLV: 10 },
    { type: "World", CLV: 20 },
  ]);
  const colors = useSelector((state) => state.tma.activeColors);
  useEffect(() => {
    const calculatedSegment = segmentData.map((segment, index) => {
      const segmentCalculation = segment.APV * segment.PF * segment.ACL;
      return { type: segment.segmentName, CLV: segmentCalculation };
    });
    setData(calculatedSegment);
  }, [segmentData]);
  const config = {
    data,
    xField: "type",
    yField: "CLV",
    seriesField: "type",
    legend: false,
    label: {
      visible: false,
      position: "top",
      style: {
        fill: "#000",
        opacity: 1,
        // fontSize: 12,
        fontSize: 0,
      },
      content: ''
      // content: (item) => `$${item.CLV}`,
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fontSize: 0,
        },
      },
    },
    yAxis: {
      grid: "line",
      title: {
        text: tmaLocals.target_market_analysis.graph.bar_graph.y_axis,
        style: {
          fontSize: 15,
          color: "#372EE5",
          fill:"#372EE5"
        },
      },
    },
    colorField: "type",
    color: colors.length>0 ? colors : colorPalette,
  };

  return (
    <div className="">
      <Column {...config} />
    </div>
  );
};
