import React from 'react'
import { Table } from "antd";
import SkeletonLoader from './SkeletonLoader'
import { generateMonth } from '../../components/BusinessModal/functionality/RevenueOnboarding/revenueOnboardingUtiliy'
import TextExtraLarge from '../TypographyDescriptions/TextExtraLarge'
import TableColumnHeading from '../../components/BusinessModal/subcomponents/ROFormComponents/components/TableColumnHeading'

const MonthYearLoader = ({ TableTitle="TableTitle", MonthNumber, MonthYear, OutputTableName=null }) => {
    const SKELETON_ROWS = 10;
    const MONTHS = Array.from({ length: 10 }).map((_, index) => {
        const month_year = generateMonth({ "startMonth": Number(MonthNumber), "seriesMonth": index, outputType: "value" })+'_'+String(MonthYear).slice(2)
        return {
            "title": month_year,
            "dataIndex": 'month',
            "width": 150
        }
    })
    const DEFAULT_COLUMNS = [
        {
            "title": TableTitle,
            "dataIndex": 'name',
            "width": 250
        },
        ...MONTHS
    ];
    const columns = DEFAULT_COLUMNS.map((column, index) => {
        return {
            "key": index,
            "fixed": index == 0 ? "left" : '',
            "title": <TableColumnHeading title={column?.title} />,
            "dataIndex": column.dataIndex,
            "width": column.width,
            "render": () => {
                return (
                    <div className='py-[0.5rem] bg-white pl-[0.5rem]'>
                        <SkeletonLoader width={index === 0 ? 200 : 120} />
                    </div>
                )
            }
        }
    })
    const totalColumnWidth = columns?.reduce((total, column) => total + (column.width || 0), 0);
    return (
        <div>
            <TextExtraLarge text={OutputTableName} fontWeight={500} />
            <Table
                columns={columns}
                dataSource={Array.from({ length: SKELETON_ROWS })}
                scroll={{
                    x: totalColumnWidth,
                }}
                pagination={{
                    pageSize: SKELETON_ROWS,
                    position: ['none', 'none'],
                }}
            />
        </div>
    )
}

export default MonthYearLoader