import React, { useEffect, useState, useRef } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Table, Input, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import './competitorAnalysis.css'
import { oneTimeUpdateOtherAttrColor, getOtherDataApi, postTextOtherDataApi, updateCompetitorText, resetCompetitorStatusErr } from './CompetitorSlice'
import CompetitorOtherAttrTable from "./CompetitorOtherAttrTable";
import CSVDownloadButton from "./JsonToCsv";
import PaintBrush from "../../assets/competitoranalysis/PaintBrush.svg";
import Download from "../../assets/PSM/Download.svg"
import paint from "../../assets/PSM/paint.svg"
import csv from "../../assets/common/csvlogo.svg"
import pdf from "../../assets/common/pdflogo.svg"
import convertJSONToPDF from "../../utils/ConvertJSONToPDF";
import { postUserActivityDataApi } from "../UserActivity/userActivitySlice";
import Paint from "../reuseablecomponent/Paint";
import { togglePaintPalette, updateActivePalette } from "../reuseablecomponent/PaintSlice";
import PageSpinner from "../reuseablecomponent/PageSpinner";
import OopsPopUp from "../reuseablecomponent/PopUpVarients/OopsPopUp";
import caLocals from "../../localization/localization_en_competitor_analysis.json"
import notificationLocals from "../../localization/localization.en_notification_message.json"
import { PDFDownloadLink } from '@react-pdf/renderer';
import CustomColumnPDF from "../reuseablecomponent/PDF_Varients/CustomColumnPDF";
import { fileNameSuffix } from "../../utils/fileNameSuffix";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import WarningFreeTrialAccessResource from "../reuseablecomponent/PopUpVarients/WarningFreeTrialAccessResource";
import WarningLimitedResourceOopsPopup from "../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup";
import { paywall_locals } from "../../localization";
import useApp from "../../hooks/useApp";

const PAYWALL_LOCALS = paywall_locals.competitor_analysis;

const { TextArea } = Input;

const CompetitorText = () => {
  const otherAttr = useSelector((state) => state.competitor.otherAttr);
  const key = useSelector((state) => state.competitor.key);
  const [competitorNameKeyValue, setCompetitorName] = useState({});
  const permission = useSelector((state) => state.user.permission);
  const { selectedPalette, isPaintPaletteOpen } = useSelector((state) => state.paint);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const status = useSelector((state) => state.competitor.status);
  const productstatus = useSelector((state) => state.competitor.productstatus);
  const error = useSelector((state) => state.competitor.error);
  const CompanyName = useSelector((state) => state.onboarding.value.Company);
  const [isPaintOpen, setIsPaintOpen] = useState(false);
  const [showAlertPalette, setShowAlertPalette] = useState(false);
  const downloadRef = useRef()
  const paintRef = useRef()
  const fileName = fileNameSuffix();
  const { upgradeRenewPaywallPopup } = useApp();


  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const staticRows = useSelector((state) => state.competitor.staticArrRows);
  const title = useSelector((state) => state.competitor.tabtitle);


  useOutsideClick(downloadRef, () => {
    setIsDownloadOpen(false);
  })
  useOutsideClick(paintRef, () => {
    setIsPaintOpen(false);
  })

  useEffect(() => {
    if (status === "failed") {
      const timer = setTimeout(() => {
        dispatch(resetCompetitorStatusErr());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [status, error])

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getOtherDataApi());
    }, 50)
    return () => timer;
  }, [key])

  const handleTextAreaChange = (value, textid, fieldName) => {
    const updatedText = value;
    dispatch(updateCompetitorText({ textid, fieldName, text: updatedText }));
  };
  const getLatestModifiedData = (textid, fieldName) => {
    const updatedText = otherAttr.find((competitor) => {
      if (competitor._id === textid._id) {
        console.log("data", competitor);
        return competitor;
      }
    });
    return updatedText;
  }
  const handleSelectedColorInPaint = (color) => {
    dispatch(updateActivePalette(color));
    setIsPaintOpen(false);
  }
  const applyColor = (text) => {
    const updatedColor = otherAttr.map((competitor) => {
      if (competitor._id === text._id) {
        return { ...competitor, [text.label]: { ...competitor[text.label], bgColor: selectedPalette } }
      } else {
        return competitor
      }
    })
    dispatch(updateActivePalette(null));
    dispatch(oneTimeUpdateOtherAttrColor(updatedColor));
  }

  const extractCompetitorHeading = otherAttr?.map((Competitor, index) => {
    return {
      title: (
        <div className="flex items-center h-[70px] border">
          <div className="pl-[22px] text-primary break-all">{Competitor.competitorName.trim().length > 0 ? Competitor.competitorName : (index == 0) ? CompanyName : `${caLocals.competitor_analysis.table.other.header.placeholder.competitor} ${index}`}</div>
        </div>
      ),
      dataIndex: Competitor._id,
      width: 220,
      key: JSON.stringify(index + 1),
      render: (text, record, index) => {
        return (
          <div className="h-[70px] flex items-center justify-center">
            <TextArea
              style={{ backgroundColor: text?.data?.bgColor }}
              onClick={() => {
                if(selectedPalette){
                  if(text?.data?.data.length>0){
                    applyColor(text)
                  }else{
                    setShowAlertPalette(true);
                  }
                }
              }}
              maxLength={100}
              disabled={!permission.edit}
              className={`make_me_color_change`}
              defaultValue={text?.data?.data}
              placeholder={caLocals.competitor_analysis.table.other.cell.placeholder}
              onBlur={() => {
                for (let i = 0; i < otherAttr.length; i++) {
                  if (otherAttr[i]._id === text._id) {
                    const fieldName = staticRows[index].split(" ").join("").replace(/\([^)]*\)/g, '');
                    dispatch(postTextOtherDataApi({ model: title, competitorId: text._id, secondaryId: otherAttr[i][title + "RefId"], key: text.label, value: otherAttr[i][text.label] }));
                    break;
                  }
                }
              }}
              onChange={(e) => {
                const fieldName = staticRows[index].split(" ").join("").replace(/\([^)]*\)/g, '');
                handleTextAreaChange(e.target.value, text._id, fieldName);
              }}
              autoSize={{ minRows: 3, maxRows: 2 }}
            />
          </div>
        );
      },
    };
  });
  const columns = [
    {
      title: <div className="pl-[22px] text-[16px] h-[70px] font-InterRegular font-medium tracking-wide bg-[#372EE5] text-white flex items-center">{title}</div>,
      dataIndex: "ProductHeading",
      width: 220,
      key: "0",
      fixed: "left",
      render: (text, record, index) => (
        <div placeholder={`${caLocals.competitor_analysis.table.other.header.placeholder.competitor} ${index}`} className="h-[75px] text-[#372EE5] border-b-[0.7px] border-[#9E9E9E] bg-[#ebf1ff] font-InterRegular text-[16px] font-medium tracking-wide pl-[22px] flex items-center"><span>{text}</span></div>
      ),
    },
    ...extractCompetitorHeading,
  ];
  useEffect(() => {
    const rows = staticRows.map((heading, index) => {
      if (index === 0) {
        let fetchCompetitorName = {}
        for (let i = 0; i < otherAttr.length; i++) {
          fetchCompetitorName = { ...fetchCompetitorName, [otherAttr[i]._id]: otherAttr[i].competitorName }
        }
        setCompetitorName(fetchCompetitorName);
      }
      const textWithoutSpaces = heading.replace(/\s+/g, "");
      const modifiedHeadingName = textWithoutSpaces.replace(/\([^)]*\)/g, "");
      let rowWiseDataFilter = { ProductHeading: staticRows[index] };
      for (let i = 0; i < otherAttr.length; i++) {
        rowWiseDataFilter = {
          ...rowWiseDataFilter,
          [otherAttr[i]._id]: {
            data: otherAttr[i][modifiedHeadingName],
            _id: otherAttr[i]._id,
            label: modifiedHeadingName
          },
        };
      }
      return { key: index, ...rowWiseDataFilter };
    });
    setData(rows);
  }, [otherAttr.length, otherAttr]);
  if (productstatus==="loading") {
    return <PageSpinner />
  }
  return (
    <div>
      <div className="w-[100%] flex justify-end mx-auto relative">
        {status === "failed" && <MessageNotification status={status} type={"error"} content={error} />}
        {true && <OopsPopUp description1={caLocals.competitor_analysis.warning_tooltip_message.color_code} isOpen={showAlertPalette} onClick={() => {
          setShowAlertPalette(false);
          dispatch(updateActivePalette(null));
        }} />}
        <div className="flex items-center flex-row-reverse">
        <WarningLimitedResourceOopsPopup
          module_data={{ "module": "other_ca", "data": data }}
          description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description1}
          description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description2}
        >
          {({ handleTogglePopup, isFreeSubscription, accessDownload }) => (
            <div ref={downloadRef}>
            <Button onClick={() => (isFreeSubscription || accessDownload) ? handleTogglePopup() : setIsDownloadOpen((prev) => !prev)} disabled={!permission.download} className="pb-0 ml-[12px] border-none"><DownloadOutlinedIcon className='text-black' fontSize='medium' /></Button>
            {isDownloadOpen && <div className="p-[24px] pb-[32px] absolute top-[2rem] shadow-xl border right-0 z-[10] w-[239px] bg-white rounded-[8px]">
              <CSVDownloadButton competitorNameKeyValue={competitorNameKeyValue} Columns={columns} data={data} />
              <Button disabled={!permission.download} onClick={() => {
                // convertJSONToPDF(data, competitorNameKeyValue, title,CompanyName);
                dispatch(postUserActivityDataApi({...notificationLocals.notification_message.competitor_analysis.download.pdf}))
              }} type="link" className="flex mt-[24px] w-full p-0 border-none">
                <img src={pdf} alt="" />
                <PDFDownloadLink document={<CustomColumnPDF jsondata={data} competitorNameKeyValue={competitorNameKeyValue} name={title} CompanyName={CompanyName}/>} fileName={`CompetitorAnalysis_Product_${fileName}.pdf`}>
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : <span className="ml-[12px] text-[#111827] font-InterRegular text-[14px] font-medium tracking-wide">{caLocals.competitor_analysis.download.pdf}</span>
                    }
                </PDFDownloadLink>
              </Button>
            </div>}
            </div>
          )}
        </WarningLimitedResourceOopsPopup>
        <div ref={paintRef}>
          <Paint isPaintOpen={isPaintOpen} handleSelectedColor={handleSelectedColorInPaint} />
          <Button onClick={() => setIsPaintOpen((prev) => !prev)} disabled={!permission.paint} className="p-0 border-none"><BrushOutlinedIcon fontSize='medium' className='text-black' /></Button>
        </div>
        {selectedPalette && <div className='flex justify-center items-center gap-x-2'>
        <div style={{ backgroundColor: selectedPalette }} className='w-[24px] h-[24px] border rounded-full'></div>
        <span className='font-InterRegular text-[12px] text-[#9E9E9E] tracking-wide'>{caLocals.competitor_analysis.palette_selected}</span>
      </div>}
        </div>
      </div>
      <CompetitorOtherAttrTable columns={columns} data={data} />
    </div>
  );
};

export default CompetitorText;