import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import routesConfig from "../../services/config/routesConfig.json";
import api from "../../services/api/api";

const INITIAL_STATE = {
    "output": null,
    "isSaveDisabled": false,
    "outputSliderValues": { prevValue: 50, nextValue:50},
    "isGenerateOutputDisabled": true,
    "spanType": "monthly",
    "save_process_api_status": { "status": "idle", counter: 0, message: null },
    "categoryList": []
}

export const API_Process_P_AND_L_Data = createAsyncThunk(
    "pAndL/API_Process_P_AND_L_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-pnl');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const pAndLSlice = createSlice({
    name: "pAndL",
    initialState: {
        pAndLStore: INITIAL_STATE
    },
    reducers: {
        D_Patch_PANDL_SliderValue(state, action) {
            const { value } = action.payload;
            state.pAndLStore.outputSliderValues = { "prevValue": state.pAndLStore.outputSliderValues.nextValue, "nextValue": value }
        },
        D_Update_SpanSelection(state, action){
            state.pAndLStore.spanType = action.payload;
        },
        D_Reset_ErrorMessage(state, action){
            state.pAndLStore.save_process_api_status = { "status": "idle", counter: 0, message: null }
        },
        D_Reset_P_AND_L_State(state) {
            state.pAndLStore.output = null;
            state.pAndLStore.isSaveDisabled = false;
            state.pAndLStore.outputSliderValues = { prevValue: 50, nextValue: 50 };
            state.pAndLStore.isGenerateOutputDisabled = true;
            state.pAndLStore.spanType = "monthly";
            state.pAndLStore.save_process_api_status = { status: "idle", counter: 0, message: null };

        },
        D_Update_Screen_Screen_pAndL(state, action){
            state.pAndLStore.output = action.payload?.screenData?.output ? action.payload?.screenData?.output[state.pAndLStore.spanType] : null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_Process_P_AND_L_Data.pending, (state) => {
                state.pAndLStore.save_process_api_status.counter = state.pAndLStore.save_process_api_status.counter+1
                state.pAndLStore.save_process_api_status.status = 'loading';
            })
            .addCase(API_Process_P_AND_L_Data.fulfilled, (state, action) => {
                state.pAndLStore.save_process_api_status.counter = state.pAndLStore.save_process_api_status.counter+1
                state.pAndLStore.save_process_api_status.status = 'success';
                state.pAndLStore.isGenerateOutputDisabled = true;
                state.pAndLStore.output = action.payload[state.pAndLStore.spanType];
                state.pAndLStore.categoryList = action.payload?.monthly?.salesChannelsListCombined?.map((catg) => ({ title: catg.title, id: catg._id })) || []
            })
            .addCase(API_Process_P_AND_L_Data.rejected, (state, action) => {
                state.pAndLStore.save_process_api_status.counter = state.pAndLStore.save_process_api_status.counter+1
                state.pAndLStore.save_process_api_status.status = 'failed';
                state.pAndLStore.save_process_api_status.message = action.error.message
            })
    }
});

export const { D_Patch_PANDL_SliderValue, D_Reset_ErrorMessage, D_Reset_P_AND_L_State, D_Update_SpanSelection, D_Update_Screen_Screen_pAndL } = pAndLSlice.actions;
export default pAndLSlice.reducer;