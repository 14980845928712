import React from 'react'
import { Tooltip } from "antd";
import createAccountLocals from "../../localization/localization_en_create_account.json"
import infoIcon from "../../assets/common/tooltip.svg"
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium';

const PasswordPolicy = () => {
    return (
        <div className='flex gap-x-[0.2rem] mt-[0.5rem]'>
            <Tooltip className='passValidationTooltip cursor-pointer' title={
                <div>
                    {
                        createAccountLocals.create_account.password_validation_tooltip.map((msg, passIndex) => <div key={passIndex}>• {msg}</div>)
                    }
                </div>} placement="bottomLeft">
                <img src={infoIcon} alt="" />
            </Tooltip>
            <TextMedium text={"Password Policy"} fontWeight={200} />
        </div>
    )
}

export default PasswordPolicy