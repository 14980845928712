import icp1 from "./assets/icp1.svg"
import icp2 from "./assets/icp2.svg"
import icp3 from "./assets/icp3.svg"
import icp4 from "./assets/icp4.svg"
import icp5 from "./assets/icp5.svg"
import icp6 from "./assets/icp6.svg"
import icp7 from "./assets/icp7.svg"
import icp8 from "./assets/icp8.svg"
import icp9 from "./assets/icp9.svg"
import icp10 from "./assets/icp10.svg"
import icp11 from "./assets/icp11.svg"
import icp12 from "./assets/icp12.svg"
import icp13 from "./assets/icp13.svg"
import icp14 from "./assets/icp14.svg"
import icp15 from "./assets/icp15.svg"
import icp16 from "./assets/icp16.svg"
import team_colloboration_tool from "./assets/team_colloboration_tool.svg"

import pg_male1 from "./assets/template/pg_male1.png";
import pg_male2 from "./assets/template/pg_male2.png";
import pg_male3 from "./assets/template/pg_male3.png";
import pg_male4 from "./assets/template/pg_male4.png";
import pg_woman1 from "./assets/template/pg_woman1.png";
import pg_woman2 from "./assets/template/pg_woman2.png";
import pg_woman3 from "./assets/template/pg_woman3.png";
import pg_woman4 from "./assets/template/pg_woman4.png";
import icpLocals from "../../localization/localization_en_ideal_customer_profile.json"

const locals = icpLocals.ideal_customer_profile.idealCustomerProfile;

export const ICP_GENDER_PROFILE_IMAGE = {
    "Fashion Retailer": { img: pg_woman1, name: "Jane Doe" },
    "Home Furnishings": { img: pg_male1, name: "John Doe" },
    "Electronics Retailer": { img: pg_male2, name: "John Doe" },
    "Organic Foods": { img: pg_woman2, name: "Jane Doe" },
    "Automotive Parts": { img: pg_male3, name: "John Doe" },
    "Electronic Parts": { img: pg_male4, name: "John Doe" },
    "Pharmacy": { img: pg_woman3, name: "Jane Doe" },
    "Food Manufacturing": { img: pg_woman4, name: "Jane Doe" },
    "Marketing Agency": { img: pg_male1, name: "John Doe" },
    "Project Software": { img: pg_male1, name: "John Doe"},
    "CRM Software": {},
    "HR Consulting": { img: pg_male1, name: "" },
    "Financial Services": { img: pg_male1, name: "" },
    "Logistics & Supply Chain": { img: pg_male1, name: "" },
    "Team Collaboration": { img: pg_male1, name: "" },
    "Healthcare Technology": { img: pg_male1, name: "" },
    "Custom": { img: pg_woman1, name: "Jane Doe" }
}

export const TEMPLATE_NAME = locals.map((temp) => temp.title)

export const icpImgs = {
    "Fashion Retailer": { img: icp1, bgColor: "bg-[#FFE0F8]" },
    "Home Furnishings": { img: icp2, bgColor: "bg-[#FFE7CB]" },
    "Electronics Retailer": { img: icp3, bgColor: "bg-[#E8CBFF]" },
    "Organic Foods": { img: icp4, bgColor: "bg-[#E3EED9]" },
    "Automotive Parts": { img: icp5, bgColor: "bg-[#D4ECF3]" },
    "Electronic Parts": { img: icp6, bgColor: "bg-[#D1FFFF]" },
    "Pharmacy": { img: icp7, bgColor: "bg-[#C9F2FF]" },
    "Food Manufacturing": { img: icp8, bgColor: "bg-[#FFE1E5]" },
    "Marketing Agency": { img: pg_male1, bgColor: "bg-[#C2A6FE]" },
    "Project Software": { img: pg_male1, bgColor: "bg-[#F6FFD1]"},
    "CRM Software": { img: pg_male1, bgColor: "bg-[#BBFFB0]" },
    "HR Consulting": { img: pg_male1, bgColor: "bg-[#FAC5FF]" },
    "Financial Services": { img: pg_male1, bgColor: "bg-[#CDC9FF]" },
    "Logistics & Supply Chain": { img: pg_male1, bgColor: "bg-[#CDC9FF]" },
    "Team Collaboration": { img: pg_male1, bgColor: "bg-[#FFEBC5]" },
    "Healthcare Technology": { img: pg_male1, bgColor: "bg-[#C9FFF5]" },
    "Custom": { img: pg_woman1, bgColor: "bg-[#FFE1E5]" },
}

export const icpImageAndBgColor = {
    [locals[0].title]:{
        templateIcon: icp1,
        bgColor: "bg-[#FFE0F8]"
    },
    [locals[1].title]:{
        templateIcon: icp2,
        "bgColor": "bg-[#FFE7CB]"
    },
    [locals[2].title]:{
        templateIcon: icp3,
        "bgColor": "bg-[#E8CBFF]"
    },
    [locals[3].title]:{
        templateIcon: icp4,
        "bgColor": "bg-[#E3EED9]"
    },
    [locals[4].title]:{
        templateIcon: icp5,
        "bgColor": "bg-[#D4ECF3]"
    },
    [locals[5].title]:{
        templateIcon: icp6,
        "bgColor": "bg-[#D1FFFF]"
    },
    [locals[6].title]:{
        templateIcon: icp7,
        "bgColor": "bg-[#C9F2FF]"
    },
    [locals[7].title]:{
        templateIcon: icp8,
        "bgColor": "bg-[#FFE1E5]"
    },
    [locals[8].title]:{
        templateIcon: icp10,
        "bgColor": "bg-[#C2A6FE]"
    },
    [locals[9].title]:{
        templateIcon: icp11,
        "bgColor": "bg-[#F6FFD1]"
    },
    [locals[10].title]:{
        templateIcon: icp12,
        "bgColor": "bg-[#BBFFB0]"
    },
    [locals[11].title]:{
        templateIcon: icp13,
        "bgColor": "bg-[#FAC5FF]"
    },
    [locals[12].title]:{
        templateIcon: icp14,
        "bgColor": "bg-[#CDC9FF]"
    },
    [locals[13].title]:{
        templateIcon: icp15,
        "bgColor": "bg-[#CCFF8C]"
    },
    [locals[14].title]:{
        templateIcon: team_colloboration_tool,
        "bgColor": "bg-[#FFEBC5]"
    },
    [locals[15].title]:{
        templateIcon: icp16,
        "bgColor": "bg-[#C9FFF5]"
    }
}

export const icp = [
    {
        icpImg: icp1,
        bgColor: "bg-[#F2BEFF]",
        title: "Fashion Retailer",
        Questions: [
            {
                surveryTitle: "Demographics",
                surveyQuestions: [
                    {
                        name: "Persona Name",
                        type: "input",
                        inputProps: {
                            placeholder: "Full Name"
                        }
                    },
                    {
                        name: "Upload Photo",
                        type: "img",
                        inputProps: {
                            placeholder: ""
                        }
                    },
                    {
                        name: "Target Market",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Gender",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Annual Income",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Geographical Location",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Psychographics",
                surveyQuestions: [
                    {
                        name: "Interests",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Shopping Behaviour",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Brand Affinity",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Challenges & Pain Points",
                surveyQuestions: [
                    {
                        name: "Challenges & Pain Points",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Value Proposition",
                surveyQuestions: [
                    {
                        name: "Value Proposition",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            }
        ]
    },
    {
        icpImg: icp2,
        bgColor: "bg-[#FFCBD2]",
        title: "Home Furnishings",
        Questions: [
            {
                surveryTitle: "Demographics",
                surveyQuestions: [
                    {
                        name: "Persona Name",
                        type: "input",
                        inputProps: {
                            placeholder: "Full Name"
                        }
                    },
                    {
                        name: "Upload Photo",
                        type: "img",
                        inputProps: {
                            placeholder: ""
                        }
                    },
                    {
                        name: "Target Market",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Geographical Location",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Household Type",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Psychographics",
                surveyQuestions: [
                    {
                        name: "Interests",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Shopping Behaviour",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Brand Affinity",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Lifestyle",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Personality Traits",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Challenges & Pain Points",
                surveyQuestions: [
                    {
                        name: "Challenges & Pain Points",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Value Proposition",
                surveyQuestions: [
                    {
                        name: "Value Proposition",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Objections to Sale",
                surveyQuestions: [
                    {
                        name: "Objections to Sale",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            }
        ]
    },
    {
        icpImg: icp3,
        bgColor: "bg-[#E8CBFF]",
        title: "Electronics Retailer",
        Questions: [
            {
                surveryTitle: "Demographics",
                surveyQuestions: [
                    {
                        name: "Persona Name",
                        type: "input",
                        inputProps: {
                            placeholder: "Full Name"
                        }
                    },
                    {
                        name: "Upload Photo",
                        type: "img",
                        inputProps: {
                            placeholder: ""
                        }
                    },
                    {
                        name: "Target Market",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Geographical Location",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Company Size",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Annual Household Technology Spending",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Psychographics",
                surveyQuestions: [
                    {
                        name: "Interests",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Engagement",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Personality Traits",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Lifestyle",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Challenges & Pain Points",
                surveyQuestions: [
                    {
                        name: "Challenges & Pain Points",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Value Proposition",
                surveyQuestions: [
                    {
                        name: "Value Proposition",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            }
        ]
    },
    {
        icpImg: icp4,
        bgColor: "bg-[#FFCFE2]",
        title: "Organic Foods",
        Questions: [
            {
                surveryTitle: "Demographics",
                surveyQuestions: [
                    {
                        name: "Persona Name",
                        type: "input",
                        inputProps: {
                            placeholder: "Full Name"
                        }
                    },
                    {
                        name: "Upload Photo",
                        type: "img",
                        inputProps: {
                            placeholder: ""
                        }
                    },
                    {
                        name: "Target Market",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Geographical Location",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Psychographics",
                surveyQuestions: [
                    {
                        name: "Lifestyle",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Personality Traits",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Restrictions",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Interests",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Challenges & Pain Points",
                surveyQuestions: [
                    {
                        name: "Challenges & Pain Points",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Value Proposition",
                surveyQuestions: [
                    {
                        name: "Value Proposition",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Goals & Objectives",
                surveyQuestions: [
                    {
                        name: "Goals & Objectives",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            }
        ]
    },
    {
        icpImg: icp5,
        bgColor: "bg-[#FFC9EF]",
        title: "Automotive Parts",
        Questions: [
            {
                surveryTitle: "Demographics",
                surveyQuestions: [
                    {
                        name: "Persona Name",
                        type: "input",
                        inputProps: {
                            placeholder: "Full Name"
                        }
                    },
                    {
                        name: "Upload Photo",
                        type: "img",
                        inputProps: {
                            placeholder: ""
                        }
                    },
                    {
                        name: "Industry",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Business Type",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Target Market",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Geographical Location",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Psychographics",
                surveyQuestions: [
                    {
                        name: "Quality & Compliance",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Technology Adoption",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Innovation and R&D",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Cost Efficiency",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Challenges & Pain Points",
                surveyQuestions: [
                    {
                        name: "Challenges & Pain Points",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Value Proposition",
                surveyQuestions: [
                    {
                        name: "Value Proposition",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            }
        ]
    },
    {
        icpImg: icp6,
        bgColor: "bg-[#D1FFFF]",
        title: "Electronic Parts",
        Questions: [
            {
                surveryTitle: "Demographics",
                surveyQuestions: [
                    {
                        name: "Persona Name",
                        type: "input",
                        inputProps: {
                            placeholder: "Full Name"
                        }
                    },
                    {
                        name: "Upload Photo",
                        type: "img",
                        inputProps: {
                            placeholder: ""
                        }
                    },
                    {
                        name: "Industry",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Business Type",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Target Market",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Geographical Location",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Psychographics",
                surveyQuestions: [
                    {
                        name: "Quality & Compliance",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Technology Adoption",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Innovation and R&D",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Cost Efficiency",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Challenges & Pain Points",
                surveyQuestions: [
                    {
                        name: "Challenges & Pain Points",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Value Proposition",
                surveyQuestions: [
                    {
                        name: "Value Proposition",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            }
        ]
    },
    {
        icpImg: icp7,
        bgColor: "bg-[#C9F2FF]",
        title: "Pharmacy",
        Questions: [
            {
                surveryTitle: "Demographics",
                surveyQuestions: [
                    {
                        name: "Persona Name",
                        type: "input",
                        inputProps: {
                            placeholder: "Full Name"
                        }
                    },
                    {
                        name: "Upload Photo",
                        type: "img",
                        inputProps: {
                            placeholder: ""
                        }
                    },
                    {
                        name: "Industry",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Business Type",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Target Market",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Geographical Location",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Psychographics",
                surveyQuestions: [
                    {
                        name: "Quality & Compliance",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Technology Adoption",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Innovation and R&D",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Cost Efficiency",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Challenges & Pain Points",
                surveyQuestions: [
                    {
                        name: "Challenges & Pain Points",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Value Proposition",
                surveyQuestions: [
                    {
                        name: "Value Proposition",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Goals & Objectives",
                surveyQuestions: [
                    {
                        name: "Goals & Objectives",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            }
        ]
    },
    {
        icpImg: icp8,
        bgColor: "bg-[#C5CCFF]",
        title: "Food Manufacturing",
        Questions: [
            {
                surveryTitle: "Demographics",
                surveyQuestions: [
                    {
                        name: "Persona Name",
                        type: "input",
                        inputProps: {
                            placeholder: "Full Name"
                        }
                    },
                    {
                        name: "Upload Photo",
                        type: "img",
                        inputProps: {
                            placeholder: ""
                        }
                    },
                    {
                        name: "Industry",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Business Type",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Target Market",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Geographical Location",
                        type: "textarea",
                        maxWords: 500,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Psychographics",
                surveyQuestions: [
                    {
                        name: "Quality & Freshness",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Customized Solutions",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Food Safety & Compliances",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    },
                    {
                        name: "Seasonal & Trend-driven Products",
                        type: "textarea",
                        maxWords: 800,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Challenges & Pain Points",
                surveyQuestions: [
                    {
                        name: "Challenges & Pain Points",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Value Proposition",
                surveyQuestions: [
                    {
                        name: "Value Proposition",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            },
            {
                surveryTitle: "Goals & Objectives",
                surveyQuestions: [
                    {
                        name: "Goals & Objectives",
                        type: "textarea",
                        maxWords: 1000,
                        bulletInclude: true,
                        inputProps: {
                            placeholder: "Describe text..."
                        }
                    }
                ]
            }
        ]
    },
    {
        icpImg: icp9,
        bgColor: "bg-[#FFE1C5]",
        title: "Project Software",
        Questions: [
            {
                "surveryTitle": "Demographics",
                "surveyQuestions": [
                    {
                        "name": "Persona Name",
                        "type": "input",
                        "inputProps": {
                            "placeholder": "Full Name"
                        }
                    },
                    {
                        "name": "Upload Photo",
                        "type": "img",
                        "inputProps": {
                            "placeholder": ""
                        }
                    },
                    {
                        "name": "Job Title",
                        "type": "textarea",
                        "maxWords": 50,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    },
                    {
                        "name": "Company Size",
                        "type": "textarea",
                        "maxWords": 500,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    },
                    {
                        "name": "Industry",
                        "type": "textarea",
                        "maxWords": 500,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    },
                    {
                        "name": "Geographical Location",
                        "type": "textarea",
                        "maxWords": 500,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    }
                ]
            },
            {
                "surveryTitle": "Psychographics",
                "surveyQuestions": [
                    {
                        "name": "Workstyle Preferences",
                        "type": "textarea",
                        "maxWords": 800,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    },
                    {
                        "name": "Technological Aptitude",
                        "type": "textarea",
                        "maxWords": 800,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    },
                    {
                        "name": "Communication Style",
                        "type": "textarea",
                        "maxWords": 800,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    }
                ]
            },
            {
                "surveryTitle": "Challenges & Pain Points",
                "surveyQuestions": [
                    {
                        "name": "Challenges & Pain Points",
                        "type": "textarea",
                        "maxWords": 1000,
                        "bulletInclude": true,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    }
                ]
            },
            {
                "surveryTitle": "Value Proposition",
                "surveyQuestions": [
                    {
                        "name": "Value Proposition",
                        "type": "textarea",
                        "maxWords": 1000,
                        "bulletInclude": true,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    }
                ]
            },
            {
                "surveryTitle": "Objections to Sale",
                "surveyQuestions": [
                    {
                        "name": "Objections to Sale",
                        "type": "textarea",
                        "maxWords": 1000,
                        "bulletInclude": true,
                        "inputProps": {
                            "placeholder": "Describe text..."
                        }
                    }
                ]
            }
        ]
    }
]

export const defaultValues = {
    "Fashion Retailer": {
        "Demographics": {
            "avatar": "",
            "Persona Name": "",
            "Target Market": "Fashion-conscious women between the age of 18-30, Who actively follow the latest clothing styles & trends.",
            "Gender": "Primarily focused on women’s fashion, providing a wide range of clothing and accessories.",
            "Annual Income": "Catering to consumers with an annual income between 20,000 and 50,000 as offers a mix of affordable and premium fashion items.",
            "Geographical Location": "Focusing on urban areas with a young, trendy population, such as metropolitan cities or college towns."
        },
        "Psychographics": {
            "Interests": "Attracting customers with interests in fashion, beauty, lifestyle, and social media, who actively engage with fashion influencers and blogs.",
            "Shopping Behaviour": "Addressing consumers shopping behaviour with a focus on online shopping convenience and a seamless omni-channel experience.",
            "Brand Affinity": "Identifying preferences for well-known fashion brands and a willingness to explore new and emerging designers."
        },
        "Challenges & Pain Points": {
            "Challenges & Pain Points": `• Competing With Online Retail: Offering an in-store experience that includes personalized styling advice, interactive displays, and exclusive in-store events to differentiate our brand from online competitors.\n• Foot Fall & Store Visits: Implementing eye-catching window displays, targeted local advertising, and promotions to increase foot traffic and attract customers to our brand physical stores.\n• Customer Loyalty: Building brand loyalty through the loyalty program, offering exclusive discounts and early access to new collections.`
        },
        "Value Proposition": {
            "Value Proposition": `• Personalized Shopping Experience: Providing personalized styling recommendations through stylists or a user-friendly online styling quiz, making customers feel unique and valued.\n• Exclusive Product Selection: Curating a collection of exclusive, limited-edition fashion items, collaborating with local designers, and offering in-store-only products.\n• Seamless Omni- Channel Experience: Integrating online & offline shopping by providing an easy-to-navigate website, offering click-and-collect options, and enabling seamless returns between online and in-store purchases.`
        }
    },
    "Home Furnishings": {
        "Demographics": {
            "avatar": "",
            "Persona Name": "",
            "Target Market": "Fashion-conscious women between the age of 18-30, Who actively follow the latest clothing styles & trends.",
            "Household Type": "Understanding the composition of households, such as [single individuals, couples, nuclear families and multigenerational living] to provide tailored design solutions.",
            "Geographical Location": "Focusing on urban areas with a young, trendy population, such as metropolitan cities or college towns."
        },
        "Psychographics": {
            "Interests": "Attracting customers with interests in fashion, beauty, lifestyle, and social media, who actively engage with fashion influencers and blogs.",
            "Shopping Behaviour": "Addressing consumers shopping behaviour with a focus on online shopping convenience and a seamless omni-channel experience.",
            "Brand Affinity": "Identifying preferences for well-known fashion brands and a willingness to explore new and emerging designers.",
            "Lifestyle": "Understanding customers' lifestyle choices, such as urban living, suburban homes, or rural residences, to cater to diverse interior design needs.",
            "Personality Traits": "Identifying personality traits, such as [trendsetters, minimalists, maximalists, traditionalists, modernists, eco-conscious individuals], to tailor product recommendations and marketing strategies."
        },
        "Challenges & Pain Points": {
            "Challenges & Pain Points": `• Competing with E-commerce: Providing a differentiated experience by offering personalized design consultations, home styling tips, and exclusive in-store events to compete with online retailers.\n• Showroom Footfall: Implementing creative visual merchandising, interactive displays, and targeted marketing to drive foot traffic and enhance the in-store experience.\n• Sustainable Home Solutions: Addressing customers' interest in eco-friendly and sustainable home furnishings by curating a selection of environmentally conscious products.\n• Budget Constraints: Offering a diverse range of products at various price points to accommodate different budget preferences of customers.\n• Space Constraints: Providing space-saving and multi-functional furniture solutions for customers with limited living spaces.`
        },
        "Value Proposition": {
            "Value Proposition": `• Personalized Shopping Experience: Providing personalized styling recommendations through stylists or a user-friendly online styling quiz, making customers feel unique and valued.\n• Exclusive Product Selection: Curating a collection of exclusive, limited-edition fashion items, collaborating with local designers, and offering in-store-only products.\n• Seamless Omni- Channel Experience: Integrating online & offline shopping by providing an easy-to-navigate website, offering click-and-collect options, and enabling seamless returns between online and in-store purchases.`
        },
        "Objections to Sale": {
            "Objections to Sale": `• Pricing Concerns: Some customers might hesitate due to budget constraints or comparison shopping with other retailers.\n• Showroom Experience: Customers may be unsure about the in-store experience or find it challenging to visit the physical showroom due to distance or time constraints.\n• Product Availability: Customers might have specific product preferences, and if those products are not available in-store or online, it could be a potential objection.\n• Design Fit: Customers may have doubts about whether the recommended designs and products align with their specific style and preferences.`
        }
    },
    "Electronics Retailer": {
        "Demographics": {
            "avatar": "",
            "Persona Name": "",
            "Target Market": "Tech-savy consumers and technology enthusiasts of all ages, interested in the latest electronics and gadgets.",
            "Geographical Location": "Focusing on urban areas with a higher concentration of tech-savy individuals, such as technology hubs and city centers.",
            "Company Size": "Understanding customers' willingness to adopt new technologies, including early adopters versus cautious technology consumers.",
            "Annual Household Technology Spending": "$800 - $1,200"
        },
        "Psychographics": {
            "Interests": "Attracting customers with a strong interest in technology, gadgets, gaming, home automation, virtual reality, or other niche technology segments.",
            "Engagement": "Identifying customers who actively participate in tech forums, online communities, and social media groups related to electronics and gadgets.",
            "Personality Traits": "Understanding customers' willingness to adopt new technologies, including early adopters versus cautious technology consumers.",
            "Lifestyle": "Addressing the needs of tech-savy professionals who work remotely or travel frequently and require portable and versatile tech devices."
        },
        "Challenges & Pain Points": {
            "Challenges & Pain Points": `• Competing with E-commerce Giants: Providing exceptional customer service, knowledgeable staff, and in-store tech demos to compete with online retail giants.\n• Staying Updated with Technology Trends: Regularly updating product offerings and providing educational content to help customers stay informed about the latest tech trends.\n• Ensuring Product Authenticity: Assuring customers of product authenticity and providing warranties and after-sales support for electronic devices.\n• Budget Constraints: Offering a diverse range of products at various price points to accommodate different budget preferences of customers.\n• Data Privacy and Security: Addressing concerns related to data privacy and security, especially for customers purchasing smart home devices and IoT products.`
        },
        "Value Proposition": {
            "Value Proposition": `• Extensive Product Selection: Offering a wide range of electronics, including smartphones, laptops, gaming consoles, smart home devices, wearables, and accessories, to meet diverse customer needs.\n• Expert Tech Assistance: Providing knowledgeable staff who can offer tech advice, product comparisons, and personalized recommendations based on customer requirements.\n• Fast and Reliable Delivery: Ensuring fast and reliable product delivery options to satisfy customers' desire for instant gratification.\n• TechZone Membership Benefits: Offering a loyalty program, "TechZone Elite," with exclusive perks, early access to product launches, and special discounts for loyal customers.\n• Tech Recycling and Sustainability: Promoting eco-friendly practices by providing e-waste recycling services and curating a selection of eco-conscious technology products.`
        }
    },
    "Organic Foods": {
        "Demographics": {
            "avatar": "",
            "Persona Name": "",
            "Target Market": "Health-conscious individuals and environmentally-conscious families of all ages, seeking organic and sustainable food products.",
            "Geographical Location": "Focusing on urban and suburban areas with a growing interest in organic and eco-friendly lifestyles."
        },
        "Psychographics": {
            "Lifestyle": "Attracting customers who prioritize healthy eating, wellness, and a desire to support local and sustainable food systems.",
            "Personality Traits": "Addressing the preferences of eco-conscious consumers who seek to reduce their environmental footprint through eco-friendly shopping practices.",
            "Restrictions": "Understanding the dietary needs of customers with food allergies, intolerances, and specific dietary preferences, such as vegan or gluten-free diets.",
            "Interests": "Identifying individuals who enjoy cooking at home and appreciate a diverse range of organic ingredients and specialty foods."
        },
        "Challenges & Pain Points": {
            "Challenges & Pain Points": `• Price and Affordability: Balancing the need to offer organic and sustainable products while ensuring competitive pricing for budget-conscious customers.\n• Education and Awareness: Providing educational resources to raise awareness about the benefits of organic and sustainable food choices.\n• Local Sourcing: Establishing partnerships with local farmers and suppliers to offer locally sourced and seasonal organic produce.\n• Convenience and Accessibility: Addressing the need for convenient and accessible shopping options, such as online ordering and home delivery.`
        },
        "Value Proposition": {
            "Value Proposition": `• Certified Organic Products: Offering a wide selection of certified organic fruits, vegetables, dairy, meats, and packaged goods to meet customers' organic food needs.\n• Sustainable Packaging: Promoting eco-friendly packaging options and reducing single-use plastics to support customers' environmental values.\n• Personalized Dietary Support: Providing personalized dietary advice and assistance to customers with specific food allergies, dietary restrictions, or wellness goals.\n• Community Engagement: Hosting workshops, cooking classes, and community events to foster a sense of community and promote sustainable living.\n• GreenEats Loyalty Program: Implementing a loyalty program that rewards customers with discounts, points, and exclusive promotions on organic groceries.\n`
        },
        "Goals & Objectives": {
            "Goals & Objectives": `• Enhance Customer Awareness - GreenEats aims to increase awareness of organic and sustainable food choices through digital marketing campaigns, educational content, and in-store promotions.\n• Increase Customer Loyalty - The objective is to grow repeat business and foster customer loyalty by implementing the GreenEats Loyalty Program, offering exclusive rewards and personalized recommendations.\n• Expand Local Sourcing - GreenEats plans to establish strong partnerships with local farmers and suppliers to offer a wider selection of locally sourced and seasonal organic produce.`
        }
    },
    "Automotive Parts": {
        "Demographics": {
            "avatar": "",
            "Persona Name": "",
            "Industry": "Automotive Parts Manufacturing",
            "Business Type": "Industrial Manufacturers specializing in custom automotive components",
            "Target Market": "Original Equipment Manufacturers (OEMs) and Tier 1 automotive suppliers",
            "Geographical Location": "Primarily serving automotive manufacturers in North America and Europe"
        },
        "Psychographics": {
            "Quality & Compliance": "Company places a high emphasis on producing automotive parts that meet stringent quality standards and comply with industry regulations.",
            "Technology Adoption": "The manufacturing unit actively embraces advanced technologies like Computer-Aided Design (CAD), Computer-Aided Manufacturing (CAM), and automation to enhance production efficiency and accuracy.",
            "Innovation and R&D": "Company investing in research and development to continuously improve existing products and develop innovative solutions for the automotive industry.",
            "Cost Efficiency": "Company committed to providing cost-effective manufacturing solutions to help customers optimize production costs without compromising on product quality."
        },
        "Challenges & Pain Points": {
            "Challenges & Pain Points": `• Meeting Regulatory Standards: Company facing  the challenge of complying with strict automotive industry standards and certifications to meet OEM and supplier requirements.\n• Technological Upgrades: The manufacturing unit constantly evaluates and implements technological upgrades to stay competitive in the rapidly evolving automotive manufacturing landscape.\n• Customization and Flexibility: Company must be flexible to accommodate the varying demands and specific requirements of different automotive manufacturers.\n• Supply Chain Disruptions: The company encounters challenges in managing supply chain disruptions caused by factors like raw material shortages or transportation delays.\n• Market Competition: Company competes with other automotive component manufacturers for contracts and partnerships with major automotive companies.`
        },
        "Value Proposition": {
            "Value Proposition": `• Quality Assurance and Compliance: Ensures that all automotive parts undergo rigorous quality control processes and comply with industry-specific regulations, providing customers with reliable and safe components.\n• Customized Manufacturing Solutions:Offers custom-designed automotive parts and components tailored to the specific needs and specifications of OEMs and Tier 1 suppliers.\n• On-Time Delivery: Prides itself on meeting tight production schedules and delivering automotive parts on time to support its customers' manufacturing processes.\n• Technological Expertise: With a focus on technological advancements, skilled team is well-equipped to develop and produce automotive parts using cutting-edge manufacturing techniques.\n• Cost-Effective Solutions: Offers competitive pricing and works closely with customers to optimize manufacturing processes and reduce overall production costs.`
        }
    },
    "Electronic Parts": {
        "Demographics": {
            "avatar": "",
            "Persona Name": "",
            "Industry": "Electronics Manufacturing and Assembly",
            "Business Type": "Industrial Manufacturers specializing in electronics components and devices",
            "Target Market": "Original Equipment Manufacturers (OEMs), electronics companies, and technology startups",
            "Geographical Location": "Primarily serving customers in North America, Europe, and Asia"
        },
        "Psychographics": {
            "Quality & Compliance": "Places a strong emphasis on producing electronics components and devices that meet strict quality standards and comply with industry regulations, ensuring reliability and performance.",
            "Technology Adoption": "The manufacturing unit actively adopts cutting-edge technologies like Surface Mount Technology (SMT), Internet of Things (IoT) integration, and smart manufacturing to enhance production efficiency and support the development of innovative electronic products.",
            "Innovation and R&D": "Invests in research and development to stay ahead in the rapidly evolving electronics industry, offering customers advanced features and functionalities in their products.",
            "Cost Efficiency": "Committed to providing cost-effective manufacturing solutions to help customers optimize production costs while delivering high-quality electronics components and devices.",
        },
        "Challenges & Pain Points": {
            "Challenges & Pain Points": `• Rapid Technological Advancements: Faces the challenge of staying up-to-date with the latest electronics technologies and ensuring its manufacturing processes remain at the forefront of innovation.\n• Customization and Flexibility: Encounters challenges in providing highly customized electronics manufacturing services to meet the unique requirements of different OEMs and electronics companies.\n• Component Sourcing: Works to maintain strong relationships with suppliers to ensure access to high-quality electronic components and materials for production.\n• Quality Control and Testing: Focuses on stringent quality control and testing processes to ensure defect-free electronics products and maintain customer satisfaction.\n• Market Competition: Competes with other electronics manufacturers for contracts and partnerships with major OEMs and technology companies.`
        },
        "Value Proposition": {
            "Value Proposition": `• High-Quality Electronics Manufacturing: Offers electronics components and devices that consistently meet stringent quality standards, ensuring superior performance and reliability for end-users.\n• Advanced Technology Integration: Provides state-of-the-art electronics manufacturing solutions with the adoption of advanced technologies, enabling customers to create innovative and cutting-edge products.\n • Customization and Engineering Support: Offers highly customized electronics manufacturing services and provides engineering support to help customers bring their unique electronic products to market efficiently.\n • Efficient Supply Chain Management: Maintains an efficient supply chain to ensure on-time delivery of electronics components and devices, reducing lead times and minimizing production delays for customers.\n • Collaborative R&D Partnership: Collaborates with customers in research and development efforts, co-creating innovative electronic products that address specific market demands.`
        }
    },
    "Pharmacy": {
        "Demographics": {
            "avatar": "",
            "Persona Name": "",
            "Industry": "Pharmaceutical Manufacturing and Contract Research",
            "Business Type": "Industrial Manufacturers offering a range of pharmaceutical manufacturing services and research solutions",
            "Target Market": "Pharmaceutical companies, biotech firms, and contract research organizations (CROs) in India",
            "Geographical Location": "Primarily serving customers across India"
        },
        "Psychographics": {
            "Quality & Compliance": "Places a high emphasis on producing pharmaceutical products that meet strict quality standards and comply with Indian and international regulatory requirements, ensuring safety and efficacy.",
            "Technology Adoption": "The manufacturing unit actively adopts advanced pharmaceutical manufacturing technologies like High-Performance Liquid Chromatography (HPLC), automated filling and packaging, and lyophilization to improve production efficiency and maintain product stability.",
            "Innovation and R&D": "Invests in research and development to develop novel drug formulations and optimize existing manufacturing processes, offering customers innovative pharmaceutical solutions.",
            "Cost Efficiency": "Committed to providing cost-effective pharmaceutical manufacturing services, optimizing production costs to make medicines more affordable and accessible.",
        },
        "Challenges & Pain Points": {
            "Challenges & Pain Points": `• Regulatory Compliance: Faces the challenge of complying with India's specific pharmaceutical regulations and maintaining adherence to international quality standards, ensuring product safety and efficacy.\n• Product Formulation and Scale-up: Encounters challenges in formulating complex drug products and scaling up manufacturing processes to meet market demand.\n• Intellectual Property Protection: Works to protect the intellectual property of its clients during contract research and manufacturing partnerships, ensuring confidentiality and data security.\n• Market Competition: Competes with other pharmaceutical manufacturers and contract research organizations in India for projects and collaborations with major pharmaceutical companies.`
        },
        "Value Proposition": {
            "Value Proposition": `• High-Quality Pharmaceutical Manufacturing: Offers pharmaceutical products that meet stringent quality standards, ensuring the efficacy and safety of medicines for patients.\n • Advanced Technology Integration: Provides advanced pharmaceutical manufacturing solutions with the integration of cutting-edge technologies, delivering consistent and high-quality drug products.\n • Customization and Research Support: Offers highly customized pharmaceutical manufacturing services and research support, catering to the specific needs and requirements of its clients.\n • Efficient Supply Chain Management: Maintains an efficient pharmaceutical supply chain within India, ensuring timely delivery of drugs to customers and patients across the country.\n • Collaborative Research and Development: Collaborates with pharmaceutical companies and CROs in research and development efforts, supporting the discovery of new drug candidates and optimizing manufacturing processes for improved drug delivery.`
        },
        "Goals & Objectives": {
            "Goals & Objectives": `• Expand Market Presence - Aims to increase its market share in the Indian pharmaceutical manufacturing and contract research industry by securing partnerships with prominent pharmaceutical companies and CROs.\n• Enhance Research Capabilities - Objective is to strengthen its research capabilities by investing in state-of-the-art equipment, facilities, and talent to develop innovative drug formulations and delivery systems.\n• Achieve Regulatory Excellence - Strives to maintain the highest standards of regulatory compliance to ensure its pharmaceutical products meet stringent quality requirements and gain regulatory approval efficiently.`
        }
    },
    "Food Manufacturing": {
        "Demographics": {
            "avatar": "",
            "Persona Name": "",
            "Industry": "Food Manufacturing and Processing",
            "Business Type": "B2B Manufacturer specializing in fresh and frozen food products.",
            "Target Market": "Food retailers, grocery store chains, restaurants, and foodservice providers..",
            "Geographical Location": "Serving customers regionally or nationally, with a focus on major metropolitan areas."
        },
        "Psychographics": {
            "Quality & Freshness": "Attracting customers who prioritize high-quality, fresh, and nutritious food products for their consumers.",
            "Customized Solutions": "Addressing the preferences of clients seeking tailored food products or private label offerings to meet their specific branding and packaging needs.",
            "Food Safety & Compliances": "Understanding the need for strict food safety standards and compliance with industry regulations in food manufacturing.",
            "Seasonal & Trend-driven Products": "Identifying businesses that require seasonal or trend-driven food products to stay competitive in the dynamic food industry.",
        },
        "Challenges & Pain Points": {
            "Challenges & Pain Points": `• Product Innovation: Offering expertise in food product development and innovation to meet changing consumer preferences and market trends.\n• Supply Chain Efficiency: Demonstrating a streamlined supply chain to ensure timely delivery of fresh and frozen food products to customers.\n• Quality Control and Traceability: Implementing robust quality control measures and traceability systems to ensure the safety and integrity of food products.\n• Cost Optimization: Addressing cost pressures while maintaining the highest standards of quality and freshness in food manufacturing.\n• Sustainability and Eco-Friendly Practices: Responding to the growing demand for sustainable and environmentally friendly food production practices.`
        },
        "Value Proposition": {
            "Value Proposition": `• Fresh and Nutritious Food Products: Delivering a wide range of fresh and nutritious food products, including fruits, vegetables, meat, dairy, and ready-to-eat meals.\n• Customized and Private Label Offerings: Providing customized food solutions and private label offerings to meet the branding and packaging needs of customers.\n• Food Safety and Compliance: Ensuring strict adherence to food safety standards and compliance with industry regulations for safe and reliable food products.\n• Seasonal and Trend-Driven Innovation: Offering seasonal and trend-driven food products to help clients stay competitive and meet consumer demand.\n• Sustainable Food Practices: Demonstrating commitment to sustainability and eco-friendly practices in food manufacturing and sourcing.`
        },
        "Goals & Objectives": {
            "Goals & Objectives": `• Expand Product Portfolio - Aims to diversify its product offerings, introducing new fresh and frozen food products to cater to evolving consumer preferences.\n• Enhance Customer Relationships - Objective is to build strong and long-lasting relationships with food retailers and restaurant chains through exceptional customer service and product quality.\n• Improve Supply Chain Efficiency - Plans to optimize its supply chain to ensure on-time delivery and reduce food waste in the distribution process.`
        }
    },
    "Project Software": {
        "Demographics": {
            "avatar": "",
            "Persona Name": "",
            "Job Title": "Project Manager or Team Lead",
            "Company Size": "Small to large businesses, startups, remote teams, and enterprises.",
            "Industry": "Technology, marketing, media, design, consulting, and various other sectors.",
            "Geographical Location": "Global, with a focus on urban areas and tech hubs."
        },
        "Psychographics": {
            "Workstyle Preferences": "They prefer to initiate and actively participate in conversations rather than passively waiting for information.",
            "Technological Aptitude": "They are inclined to embrace new technologies and digital tools readily, always on the lookout for innovative solutions to streamline their workflow.",
            "Communication Style": "They value clear and transparent communication, seeking tools that help eliminate ambiguity and promote open dialogue within teams."
        },
        "Challenges & Pain Points": {
            "Challenges & Pain Points": "• Dealing with email overload and inefficient communication channels leading to delays and misunderstandings.\n• Difficulty in coordinating and collaborating effectively with remote or distributed teams.\n• Siloed communication and fragmented knowledge across different departments or teams.\n• Challenges in integrating various tools and platforms used by the team for seamless workflow."
        },
        "Value Proposition": {
            "Value Proposition": "• Centralized platform for real-time messaging, file sharing, and collaboration, reducing reliance on email.\n• Features tailored for remote teams, including video calls, screen sharing, and virtual workspace.\n• Structured communication through channels, threads, and notifications, keeping conversations organized and focused.\n• Extensive integration options with other productivity tools, project management software, and CRM systems.\n• Robust security measures to protect sensitive information, compliance with industry standards, and data encryption."
        },
        "Objections to Sale": {
            "Objections to Sale": "• Addressing concerns about learning curve and ensuring smooth adoption with comprehensive onboarding support and training resources.\n• Offering transparent pricing plans, demonstrating value for investment, and highlighting cost-saving benefits.\n• Assuring data security measures, compliance standards, and privacy controls to address security-related objections.\n• Providing demonstrations and case studies showcasing successful integrations with existing tools and platforms."
        }
    }
}

export const customTemplate = [
    {
        surveryTitle: "Demographics",
        surveyQuestions: [
            {
                name: "Target Market",
                type: "textarea",
                maxWords: 200,
                inputProps: {
                    placeholder: ""
                }
            },
            {
                name: "Gender",
                type: "textarea",
                maxWords: 50,
                inputProps: {
                    placeholder: ""
                }
            },
            {
                name: "Annual Income",
                type: "textarea",
                maxWords: 50,
                inputProps: {
                    placeholder: ""
                }
            },
            {
                name: "Geographical Location",
                type: "textarea",
                maxWords: 50,
                inputProps: {
                    placeholder: ""
                }
            }
        ]
    },
    {
        surveryTitle: "Psychographics",
        surveyQuestions: [
            {
                name: "Interests",
                type: "textarea",
                maxWords: 200,
                inputProps: {
                    placeholder: ""
                }
            },
            {
                name: "Shopping Behaviour",
                type: "textarea",
                maxWords: 200,
                inputProps: {
                    placeholder: ""
                }
            },
            {
                name: "Brand Affinity",
                type: "textarea",
                maxWords: 200,
                inputProps: {
                    placeholder: ""
                }
            }
        ]
    },
    {
        surveryTitle: "Challenges & Pain Points",
        surveyQuestions: [
            {
                name: "Challenges & Pain Points",
                type: "textarea",
                maxWords: 400,
                bulletInclude: true,
                inputProps: {
                    placeholder: ""
                }
            }
        ]
    },
    {
        surveryTitle: "Value Proposition",
        surveyQuestions: [
            {
                name: "Value Proposition",
                type: "textarea",
                maxWords: 400,
                bulletInclude: true,
                inputProps: {
                    placeholder: ""
                }
            }
        ]
    }
]
