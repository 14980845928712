import { colors } from "./palette"

export const totalRevenuePalette = [
    colors.lightPink1,
    colors.lightRed1,
    colors.lightGreen1,
    colors.lightYellow,
    colors.purple,
    colors.pink,
    colors.blue,
    colors.brown,
    colors.red,
    colors.mint,
    colors.orange,
    colors.oliveGreen,
    colors.gray,
    colors.skyBlue1,
    colors.parchment,
    colors.darkGreen,
    colors.darkBlue,
    colors.darkYellow,
    colors.darkPink1,
    colors.lightRed2
]
export const avgOrderValuePalette = [
    colors.lightPink1,
    colors.lightRed1,
    colors.lightGreen1,
    colors.lightYellow,
    colors.purple,
    colors.pink,
    colors.blue,
    colors.brown,
    colors.red,
    colors.mint,
    colors.orange,
    colors.oliveGreen,
    colors.gray,
    colors.skyBlue1,
    colors.parchment,
    colors.darkGreen,
    colors.darkBlue,
    colors.darkYellow,
    colors.darkPink1,
    colors.lightRed2
]
export const marketingCostPalette = [
    colors.lightOrange,
    colors.lightSkyBlue,
    colors.lightRed2,
    colors.lightGreen2,
    colors.darkPink1,
    colors.mint,
    colors.skyBlue1,
    colors.pink,
    colors.gray,
    colors.lightRed3,
    colors.parchment,
    colors.oliveGreen,
    colors.blue,
    colors.brown,
    colors.orange,
    colors.darkGreen,
    colors.darkPink1,
    colors.red,
    colors.darkYellow,
    colors.darkBlue
]
export const businessModelOutputPalette = [
    colors.lightOrange,
    colors.lightSkyBlue,
    colors.lightRed2,
    colors.lightGreen2,
    colors.darkPink1,
    colors.mint,
    colors.skyBlue1,
    colors.pink,
    colors.gray,
    colors.lightRed3,
    colors.parchment,
    colors.oliveGreen,
    colors.blue,
    colors.brown,
    colors.orange,
    colors.darkGreen,
    colors.darkPink1,
    colors.red,
    colors.darkYellow,
    colors.darkBlue
]
export const totalExpNetIncomePalette = [
    colors.skyBlue2,
    colors.orange1
]
export const cashFlowPalette = [
    colors.pink,
    colors.skyBlue2,
    colors.darkBrown,
    colors.orange1
]