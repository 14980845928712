export const ComponentPayloadPreparation = ({ index, formItem, costScreenData, handleCheckBoxOnChange, category_list_data, isViewMode, isEditMode }) => {
    let payloadData;
    const filterSelectOptions = (type, options, facilities_own_operation) => {
        if (["capacity_planning", "cost"].includes(type)) {
            const selected_options = [...costScreenData["capacity_planning"].map((d) => d.name), ...costScreenData["cost"].map((d) => d.name)]
            let category_list = options.filter((option) => !selected_options.includes(option.value))
            if(type === "cost"){
                if(!facilities_own_operation?.includes('Collection Center')){
                    category_list = category_list.filter((catg) => !["Collection Centre Staff Cost"].includes(catg.value))
                }
                if(!facilities_own_operation?.includes('WarehouseFactory')){
                    category_list = category_list.filter((catg) => !["Cost Of Machine","Per Labour Cost","Overheads Cost"].includes(catg.value))
                }
            }else{
                if(!facilities_own_operation?.includes('Collection Center')){
                    category_list = category_list.filter((catg) => !["Collection Centre Per Warehouse","Staff Per Collection Centre"].includes(catg.value))
                }
                if(!facilities_own_operation?.includes('WarehouseFactory')){
                    category_list = category_list.filter((catg) => !['Warehouse/Factory Capacity', 'Machine Capacity', 'Orders Processed Per Labor'].includes(catg.value))
                }
            }
            return category_list;
        } else if (["undervied_costs_personnal_diff_department", "dervied_costs_personnal_diff_department"].includes(type)) {
            const selected_options = [...costScreenData["undervied_costs_personnal_diff_department"].map((d) => d.name), ...costScreenData["dervied_costs_personnal_diff_department"].map((d) => d.name)]
            return options.filter((option) => !selected_options.includes(option.value))
        } else {
            const selected_options = costScreenData?.[type]?.map((d) => d?.name)
            return options?.filter((option) => !selected_options?.includes(option?.value))
        }
    }

    if (formItem.name === "capacity_planning") {
        const facilities_own_operation = ["other", ...costScreenData["facilities_own_operation"]]
        payloadData = { formItem, data: costScreenData["capacity_planning"].filter((d) => facilities_own_operation.includes(d.deps)), "name": formItem.name }
    } else if (formItem.name === "cost") {
        const facilities_own_operation = ["default", ...costScreenData["facilities_own_operation"], "other"];
        const requiredColumns = ["", "Cost", "Varying Cost Over Time", ...costScreenData["facilities_own_operation"]]
        const filteredRows = costScreenData["cost"].filter((d) => facilities_own_operation.includes(d.deps))
        const filteredCols = formItem.table.columns.filter((col) => requiredColumns.includes(col.required_col))
        return { formItem: { ...formItem, "table": { ...formItem.table, "columns": filteredCols.map((col) => "select_option_external_deps" in col ? { ...col, "select_options": filterSelectOptions(formItem.name, category_list_data[col.select_option_external_deps], costScreenData["facilities_own_operation"]) } : col) }, }, data: filteredRows, "name": formItem.name }
    } else if (formItem.component_type === "marketing_spend_table") {
        payloadData = { formItem, data: costScreenData["marketing_spend"] }
        return payloadData;
    } else if (formItem.component_type === "editable_table") {
        payloadData = { formItem, "data": costScreenData[formItem.name], "name": formItem.name }
    } else if (formItem.component_type === "inhouse_checkbox") {
        const { name, isMulti, select_options } = formItem;
        const isDisabled = false // view mode
        const checkBoxProps = { data: costScreenData[formItem.name], selectOptions: select_options, name, isMulti, isDisabled, handleCheckBoxOnChange: handleCheckBoxOnChange ? handleCheckBoxOnChange : null }
        return { checkBoxProps };
    } else if (formItem.component_type === "number_input") {
        const inputProps = { "name": formItem.name, "data_index": formItem.name, "value": costScreenData[formItem.name], "placeHolder": formItem.placeholder, "borderColor": "#BECEFF", "isSingleInput": true, validator: formItem.validator }
        return { inputProps }
    }
    payloadData = { // appending category list in formItem column
        ...payloadData,
        "formItem": {
            ...payloadData?.formItem,
            "table": {
                ...payloadData?.formItem?.table,
                columns: payloadData?.formItem?.table?.columns?.map((col) => "select_option_external_deps" in col ? { ...col, "select_options": filterSelectOptions(formItem.name, category_list_data[col.select_option_external_deps], costScreenData["facilities_own_operation"]) } : col)
            }
        }
    }
    return payloadData

}