import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import locals from "../../localization/localization_en_gtm.json"
import DescribeFeature from '../reuseablecomponent/DescribeFeature'
import EditableTable from './components/EditableTable'
import {
    D_Capture_TableData,
    D_Handle_Delete_Modal,
    D_Toggle_CatgoryModal,
    D_Create_TagName,
    API_Create_MarketingFunnel_LIST,
    API_Delete_MarketingFunnel_LIST,
    API_Create_ContentAssetTag,
    API_Update_ContentAssetTag,
    API_Create_SalesActivityTag,
    API_Update_SalesActivityTag,
    D_Reset_TagStatus,
    API_Fetch_Default_ContentAssetList,
    API_Fetch_Default_SalesActivityList,
    API_Fetch_SalesActivityList,
    API_Fetch_ContentAssetList,
    API_Fetch_Currency_List,
    API_Patch_MarketingFunnel_LIST,
    API_Fetch_MarketingFunnel_LIST,
    API_Fetch_Default_MarketingStageList,
    API_Fetch_MarketingStage_List,
    API_Create_MarketingStage_Tag,
    API_Update_MarketingStage_Tag,
    API_DOWNLOAD_GTM_LIST
} from './gtmSlice';
import ConfirmModal from '../../DesignSystem/Modals/ConfirmModal';
import ListModal from '../BusinessModal/subcomponents/Form/ListModal';
import FormList from './components/FormList';
import PopUpFormModal from './components/PopUpFormModal';
import { pickCurrencySymbolFromString } from '../../utils/pickCurrencySymbolFromString';
import useMousePosition from '../../hooks/useMousePosition';
import PaintFeature from '../reuseablecomponent/PaintFeature';
import DownloadFeature from '../reuseablecomponent/DownloadFeature';
import paint_brush from '../../assets/PSM/paint.svg';
import AIWelcome from '../reuseablecomponent/PopUpVarients/AIWelcome';
import AIButton from '../../DesignSystem/Buttons/AIButton';
import PageLoader from '../reuseablecomponent/PageLoader';
import OopsPopUp from '../reuseablecomponent/PopUpVarients/OopsPopUp';
import { D_ToggleCommentSection } from '../Chat/chatSlice';
import WarningFreeTrialAccessResource from '../reuseablecomponent/PopUpVarients/WarningFreeTrialAccessResource';
import WarningLimitedResourceOopsPopup from '../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';
import { paywall_locals } from '../../localization';
import useApp from '../../hooks/useApp';

const PAYWALL_LOCALS = paywall_locals.marketing_funnel;

const marketing_funnel_locals = locals.gtm_strategy.marketing_funnel;
const download_warning = marketing_funnel_locals.warning_popup.no_data_download_file;
const categoryListLocals = marketing_funnel_locals.category_modal;

const MarketingFunnelROW = {
    stage: "",
    funnel_stage: "",
    content_assets: null,
    sales_activities: null,
    timeline_start_date: null,
    timeline_end_date: null,
    currency: null,
    budget: null,
    tags: []
}

const MarketingFunnel = () => {
    const dispatch = useDispatch();
    const { x, y } = useMousePosition()
    const [tool, setTool] = useState({ "paint": { "isOpen": false, "selectedColor": null }, "download": { "isOpen": false, "downloadType": null } });
    const { table_data, categoryModal, isDeleteModalOpen, rowIndex, isEditChannelModalOpen, crud_form_type, editRowDetails, row_colors, activeTagColor } = useSelector((state) => state.gtm.gtm_strategy.marketing_funnel);
    const { currency, create_delete_patch_api_status, categoryList, api_status_module, currencyList, funnel_stage } = useSelector((state) => state.gtm.gtm_strategy);
    const [memCurrency, setMemCurrency] = useState(currency);
    const [editStageRowData, setEditStageRowData] = useState(editRowDetails);
    const { Title, placeHolder } = categoryListLocals?.[categoryModal?.type] || {};
    const [isCreateDeleteActionHappening, setIsCreateDeleteActionHappening] = useState({ "type": null });
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [modalContent, setModalContent] = React.useState({});
    const { upgradeRenewPaywallPopup } = useApp();
    const [isShowFormNotFilled, setIsShowFormNotFilled] = useState(false);

    React.useEffect(() => {
        if (create_delete_patch_api_status.status === "success" || create_delete_patch_api_status.status === "failed") {
            setIsCreateDeleteActionHappening({ type: null })
        }
    }, [create_delete_patch_api_status.status])

    React.useEffect(() => {
        dispatch(API_Fetch_MarketingStage_List())
        dispatch(API_Fetch_Default_MarketingStageList())
        dispatch(API_Fetch_MarketingFunnel_LIST())
        dispatch(API_Fetch_Currency_List());
        dispatch(API_Fetch_Default_ContentAssetList())
        dispatch(API_Fetch_Default_SalesActivityList())
        dispatch(API_Fetch_SalesActivityList())
        dispatch(API_Fetch_ContentAssetList())
        return () => {
            dispatch(D_ToggleCommentSection(false))
        }
    }, []);

    React.useEffect(() => {
        setEditStageRowData(editRowDetails);
    }, [isEditChannelModalOpen])

    const handleOnChangeTableInput = ({ data_index, value, rowIndex }) => {
        dispatch(API_Patch_MarketingFunnel_LIST({ ...table_data[rowIndex], [data_index]: value }))
        dispatch(D_Capture_TableData({ data_index, value, rowIndex, "module_type": marketing_funnel_locals.module_type }))
    }

    const handleOnChangeEditFormInput = ({ data_index, value }) => {
        if (data_index in editStageRowData) setEditStageRowData((prev) => ({ ...prev, [data_index]: value }))
    }

    const handleOnCurrencyChange = (curr) => {
        setMemCurrency(() => ({ "CURRENCY": pickCurrencySymbolFromString(curr.label), "value": curr.value }))
    }

    const CallbackToggleModal = () => dispatch(D_Toggle_CatgoryModal({ "module_type": marketing_funnel_locals.module_type, "isOpen": false, "catgType": null, "rowIndex": null }))
    const CallbackResetNotificationMessage = () => dispatch(D_Reset_TagStatus({ "target_module": categoryModal?.type }))

    const handleToolInteraction = ({ type, isOpen, selectedColor, downloadType }) => {
        setTool((prev) => ({
            ...prev, [type]: type === "paint" ? { isOpen, selectedColor } : { isOpen, downloadType }
        }))
    }

    const handleApplyColorForRow = (rowIndex) => {
        if (tool.paint.isOpen && tool.paint.selectedColor) {
            const colorToClassNameMapping = {
                "#E0C0FF": "purpl_color",
                "#A7C0FF": "viole_color",
                "#FFCACA": "oragn_color",
                "#E4FFEE": "green_color",
                "#FFEEAB": "yello_color",
                "#FFFFFF": "white_color"
            }
            dispatch(D_Patch_TableColor({ "module_type": gtm_locals.module_type, rowIndex, "selectedColor": colorToClassNameMapping[tool.paint.selectedColor] }))
            setTool((prev) => ({
                ...prev, "paint": { "isOpen": false, "selectedColor": null }
            }))
        }
    }

    const handleCreateFunnelRow = () => {
        dispatch(API_Create_MarketingFunnel_LIST({...MarketingFunnelROW, "currency":currency.value }))
        setIsCreateDeleteActionHappening({ type: "create" })
    }

    const ApiCallingFunction = (type, arg) => {
        if (categoryModal?.type === "content_assets") {
            if (type === "create") {
                dispatch(API_Create_ContentAssetTag({ "contentAssetTitle": arg.tag, "funnelStageType": funnel_stage }));
            }
            if (type === "patch") {
                dispatch(API_Update_ContentAssetTag({ "contentAssetTitle": arg.tag, "contentAssetId": arg.id }));
            }
            return;
        }
        if (categoryModal?.type === "sales_activities") {
            if (type === "create") {
                dispatch(API_Create_SalesActivityTag({ "salesActivitiesTitle": arg.tag, "funnelStageType": funnel_stage }))
            }
            if (type === "patch") {
                dispatch(API_Update_SalesActivityTag({ "salesActivitiesTitle": arg.tag, "salesActivitiesId": arg.id }))
            }
            return;
        }
        if (categoryModal?.type === "marketing_stage") {
            if (type === "create") {
                dispatch(API_Create_MarketingStage_Tag({ "stageTitle": arg.tag, "funnelStageType": funnel_stage }))
            }
            if (type === "patch") {
                dispatch(API_Update_MarketingStage_Tag({ "stageTitle": arg.tag, "stageId": arg.id }))
            }
            return;
        }
        if (categoryModal.type === "gtmTagList") {
            dispatch(API_Patch_MarketingFunnel_LIST({ ...table_data[categoryModal.rowIndex], "tags": [...table_data[categoryModal.rowIndex].tags, { "tagName": arg.tag, "tagColor": activeTagColor }] }))
            dispatch(D_Create_TagName({ "module_type": marketing_funnel_locals.module_type, "tagName": arg.tag }))
            return;
        }
    }

    const handleDeleteFunnelRow = () => {
        if (table_data[rowIndex].marketingFunnelId) {
            dispatch(API_Delete_MarketingFunnel_LIST(table_data[rowIndex].marketingFunnelId))
            setIsCreateDeleteActionHappening({ type: "delete" })
        }
    }

    const patchRowFormPopup = ({ formData, rowIndex, currency }) => {
        dispatch(API_Patch_MarketingFunnel_LIST({ ...table_data[rowIndex], ...formData, currency: currency.value }))
    }

    const handleDeleteTag = ({ module_name, tagName, rowIndex }) => {
        dispatch(D_RemoveTag({ module_name, tagName, rowIndex }))
    }

    const modalInjectedSelectOptionLocals = { ...marketing_funnel_locals.modal.form.form, "row_question": marketing_funnel_locals.modal.form.form.row_question.map((q) => "select_option_list_ref" in q ? { ...q, "select_options": categoryList[q?.select_option_list_ref] } : q) }
    const injectedSelectOptionLocals = { 
        ...marketing_funnel_locals, 
        "table": { 
            ...marketing_funnel_locals.table, 
            "columns": marketing_funnel_locals.table.columns.map((col) => "select_option_list_ref" in col ? { ...col, "select_options": categoryList[col?.select_option_list_ref] } : col) 
        } 
    }

    const updateActionButtonType = (type) => setIsCreateDeleteActionHappening({ type })
    return (
        <div style={{ "cursor": tool.paint.isOpen && tool.paint.selectedColor ? "none" : "default" }} className='mt-[4.5rem]'>
            <DescribeFeature text={marketing_funnel_locals.title} description={""} />
            <AIWelcome featureName={"marketingFunnel"} link={"/product/home/ai/promptin/marketing-funnel"} />
            <AIButton />
            {
                modalContent?.description1
                ? (
                    <OopsPopUp
                        isOpen={isModalOpen}
                        description1={modalContent?.description1}
                        description2={modalContent?.description2}
                        description3={modalContent?.description3}
                        onClick={() => setIsModalOpen((prev) => !prev)}
                    />
                ) : null
            }
            {isCreateDeleteActionHappening.type === "create" && create_delete_patch_api_status.status === "loading" && <PageLoader />}
            {
                tool.paint.isOpen && tool.paint.selectedColor
                    ? <img src={paint_brush} alt="" className='z-10 pointer-events-none' style={{ position: "fixed", "top": y, "left": x }} />
                    : null
            }
            <div className='flex items-center justify-end gap-x-[1rem] my-[0.5rem]'>
                {/* <PaintFeature
                    isOpen={tool.paint.isOpen}
                    onClickButton={() => {
                        handleToolInteraction({ type: "paint", isOpen: !tool.paint.isOpen, selectedColor: null })
                    }}
                    onSelectColor={(color) => {
                        handleToolInteraction({ type: "paint", isOpen: true, selectedColor: color })
                    }}
                /> */}
                <WarningLimitedResourceOopsPopup
                    module_data={{ "module": "gtm", "data": table_data }}
                    description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description1}
                    description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description2}
                >
                    {({ handleTogglePopup, isFreeSubscription, accessDownload }) => (
                        <DownloadFeature isOpen={tool.download.isOpen}
                        onClickButton={() => {
                            if(isFreeSubscription || accessDownload){
                                handleTogglePopup();
                                return;
                            }
                            handleToolInteraction({ type: "download", isOpen: !tool.download.isOpen })
                        }}
                        handleDownloadType={(downloadType) => {
                            handleToolInteraction({ type: "download", isOpen: false, downloadType })
                            if(table_data.length===0){
                                setIsModalOpen(true);
                                setModalContent(download_warning)
                                return;
                            }
                            if(downloadType.type === 'csv'){
                                dispatch(API_DOWNLOAD_GTM_LIST(marketing_funnel_locals.module_type))
                            }
                        }} />
                    )}
                </WarningLimitedResourceOopsPopup>
            </div>
            <ConfirmModal
                isOpen={isDeleteModalOpen}
                title={marketing_funnel_locals.warning_popup.delete_stage.title}
                description1={marketing_funnel_locals.warning_popup.delete_stage.description1}
                description2={marketing_funnel_locals.warning_popup.delete_stage.description2}
                confirmButtonName={marketing_funnel_locals.warning_popup.delete_stage.confirmButtonName}
                isLoading={create_delete_patch_api_status.status === "loading"}
                onCancel={() => dispatch(D_Handle_Delete_Modal({ "module_type": marketing_funnel_locals.module_type, "isOpen": false, "rowIndex": null }))}
                onConfirm={() => handleDeleteFunnelRow()}
            />
            {
                isEditChannelModalOpen
                    ? <PopUpFormModal
                        incompleteForm={modalInjectedSelectOptionLocals.incomplete_form}
                        formData={editStageRowData}
                        isModalOpen={true}
                        CRUDType={crud_form_type}
                        currency={memCurrency}
                        patchRowFormPopup={patchRowFormPopup}
                        toggleShowErrorMessage={() => setIsShowFormNotFilled(true)}
                        toggleHideErrorMessage={() => setIsShowFormNotFilled(false)}
                        children={
                            <FormList
                                channelList={marketing_funnel_locals.table.columns[0].select_options}
                                editTempChannelData={editStageRowData}
                                form={modalInjectedSelectOptionLocals}
                                currency={memCurrency}
                                handleOnCurrencyChange={handleOnCurrencyChange}
                                handleOnChangeEditFormInput={handleOnChangeEditFormInput}
                                module_type={marketing_funnel_locals.module_type}
                                isShowFormNotFilled={isShowFormNotFilled}
                            />
                        }
                        module_type={marketing_funnel_locals.module_type}
                        rowIndex={rowIndex}
                    />
                    : null
            }
            <EditableTable
                handleDeleteTag={handleDeleteTag}
                updateActionButtonType={updateActionButtonType}
                localization={injectedSelectOptionLocals}
                payloadData={table_data}
                createRow={handleCreateFunnelRow}
                handleOnChangeInput={handleOnChangeTableInput}
                module_type={marketing_funnel_locals.module_type}
                handleApplyColorForRow={handleApplyColorForRow}
                row_colors={row_colors}
                PAYWALL_LOCALS={PAYWALL_LOCALS}
                isRestrictRowAddition={false}
            />
            {
                categoryModal.isOpen
                    ? (
                        <ListModal
                            Title={Title}
                            warningDataIndex={categoryModal?.catgType}
                            InputPlaceholder={placeHolder}
                            ListData={categoryList[categoryModal?.type] || []}
                            ApiStatus={api_status_module[categoryModal?.type] || { "status": "idle", "message": null }}
                            tooltipMessages={{ warning: 'name exist already in list' }}
                            ApiCallingFunction={ApiCallingFunction}
                            isTagModalOpen={categoryModal.isOpen}
                            isTagLimitationExist={false}
                            CallbackToggleModal={CallbackToggleModal}
                            CallbackResetNotificationMessage={CallbackResetNotificationMessage}
                        />
                    ) : null
            }
        </div>
    )
}

export default MarketingFunnel