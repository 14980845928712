import CSVDownloadIcon from '../../assets/competitoranalysis/entypo_download.svg'
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { Button } from 'antd';
import csv from "../../assets/common/csvlogo.svg"
import { postUserActivityDataApi } from '../UserActivity/userActivitySlice';
import { fileNameSuffix } from '../../utils/fileNameSuffix';
import caLocals from "../../localization/localization_en_competitor_analysis.json";
import notificationLocals from "../../localization/localization.en_notification_message.json";

const CSVDownloadButton = ({ data, competitorNameKeyValue }) => {
  const title = useSelector((state) => state.competitor.tabtitle);
  const permission = useSelector((state) => state.user.permission);
  const CompanyName = useSelector((state) => state.onboarding.value.Company);
  const dispatch = useDispatch();
  const [preparedData, setPreparedData] = useState([]);
  const priceRangeCondition = (data) => {
    let priceRange;
    if (data.mini !== "" && data.maxi !== "") {
      priceRange = data.mini + "-" + data.maxi;
    } else if (data.mini !== "") {
      priceRange = data.mini + "-Open Closed";
    } else if (data.maxi !== "") {
      priceRange = 0 + "-" + data.maxi;
    } else {
      priceRange = "";
    }
    return priceRange;
  };
  const formatData = (data, competitorNameKeyValue) => {
    let updatedColumns = data.map((data, i) => {
      let addCompetitorName = {};
        let index = 0;
        let counter=0;
      for (let perObject in competitorNameKeyValue) {
        addCompetitorName = {
          ...addCompetitorName,
          [competitorNameKeyValue[perObject] === ""
            ? (counter==0)? CompanyName : `Competitor ${index}`
            : competitorNameKeyValue[perObject]]: (typeof data[perObject].data.data === "object") ? priceRangeCondition(data[perObject].data.data) : typeof data[perObject].data=== "boolean" ? data[perObject].data : data[perObject].data.data,
        };
        index++;
        counter++;
      }
      let productTitle;
      if (typeof data.ProductHeading == "object") {
        // console.log(data.ProductHeading,"heading");
        productTitle = (data.ProductHeading.data === null || data.ProductHeading?.data?.trim()?.length===0) ? `Feature ${i+1}` : data.ProductHeading.data
      } else {
        productTitle = data.ProductHeading
      }
      return {
        [title]: productTitle,
        ...addCompetitorName,
      };
    });
    setPreparedData(updatedColumns);
  };
  useEffect(() => {
    formatData(data, competitorNameKeyValue);
  }, [data, competitorNameKeyValue]);
  const downloadCsv = () => {
    const transformedData = preparedData.map((rowData) => {
      const transformedRow = { ...rowData };
      // Apply value transformations here
      for (const key in transformedRow) {
        let value = transformedRow[key];
        if (value === true) {
          transformedRow[key] = 'Y';
        } else if (value === false) {
          transformedRow[key] = 'N';
        } else if (value === '') {
          transformedRow[key] = '-';
        }
      }
      return transformedRow;
    });
    const csvData = Papa.unparse(transformedData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    const fileName = fileNameSuffix();
    link.setAttribute('download', `CompetitorAnalysis_${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    dispatch(postUserActivityDataApi({...notificationLocals.notification_message.competitor_analysis.download.csv}))
  };

  return (
    <div>
      <Button disabled={!permission.download} onClick={downloadCsv} type="link" className="flex w-full px-[12px] hover:bg-p8 border-none">
        <img src={csv} alt="" />
        <span className="ml-[12px] text-[#111827] font-InterRegular text-[14px] font-medium tracking-wide">{caLocals.competitor_analysis.download.csv}</span>
      </Button>
    </div>
  );
};

export default CSVDownloadButton;