import React, { useEffect, useState } from 'react'
import { Modal, message, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux"
import { updateEmail, updateModelShow, sendResetPasswordLink, resetPasswordMsg } from "../../features/spamSlice";
import { validateEmail } from '../../utils/validation';
import { useNavigate } from 'react-router-dom';
import close from "../../assets/common/close.svg";
import modalLocals from "../../localization/localization_en_modals.json"

const ForgotPasswordModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const modal2Open = useSelector((state) => state.spam.model);
    const userEmail = useSelector((state) => state.spam.email);
    const [email, setEmail] = useState(userEmail);
    const [messageApi, contextHolder] = message.useMessage();
    const emailStatus = useSelector((state) => state.spam.status);
    const statusMsg = useSelector((state) => state.spam.statusMsg);
    useEffect(()=>{
        dispatch(resetPasswordMsg());
        return () => dispatch(resetPasswordMsg());
    },[])
    useEffect(() => {
        if (emailStatus === "fulfilled") {
            message.open({
                content:modalLocals.forgot_password.success_msg.reset_success,
                type:"success"
            })
            const timer = setTimeout(()=>{
                dispatch(updateModelShow(false));
            },500)
            return () => clearTimeout(timer);
        }
        if (emailStatus === "rejected") {
            message.open({
                type: "error",
                content: statusMsg
            })
            let timer = setTimeout(() => {
                dispatch(resetPasswordMsg());
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [emailStatus]);
    const handleModalOpen = () => {
        dispatch(updateModelShow(false));
        dispatch(updateEmail(""));
    }
    console.log(emailStatus)
    return (
        <React.Fragment>
            {contextHolder}
            <Modal
                footer={null}
                title=""
                centered
                open={modal2Open}
            >
                <Button onClick={handleModalOpen} className='absolute top-1 h-auto border-none right-1'><img src={close} alt="" /></Button>
                <p style={{ margin: 0 }} className='font-InterRegular text-[18px] font-medium tracking-wide'>{modalLocals.forgot_password.title}</p>
                <p className='text-[#757575] text-[14px] tracking-wide font-InterRegular'>{modalLocals.forgot_password.suggest_text}</p>
                <p>{modalLocals.forgot_password.email}</p>
                <div className='flex flex-col items-center justify-center'>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder={modalLocals.forgot_password.form.placeholder} className='input_forgot_pass' />
                    <Button disabled={emailStatus === "pending"} loading={emailStatus === "pending"} onClick={() => {
                        if (!validateEmail(email)) {
                            messageApi.open({
                                type: 'warning',
                                content: modalLocals.forgot_password.warning_msg.valid_email,
                            });
                        } else {
                            dispatch(sendResetPasswordLink(email));
                            dispatch(updateEmail(email))
                        }
                    }} className='bg-primary py-[12px] flex justify-center w-full mt-[24px] rounded-[12px] h-auto text-white'>{modalLocals.forgot_password.form.submit_btn_text}</Button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default ForgotPasswordModal