import { useSelector } from "react-redux";

function checkStatusOfFeature(stepper, featureName) {
    let isPresent = false;
    for (let i = 0; i < stepper.length; i++) {
        const item = stepper[i];
        if (item.title === featureName) {
            if (item.status === 1) {
                isPresent = true;
            }
            return isPresent;
        } else if (item.steps && item.steps.length > 0) {
            const result = checkStatusOfFeature(item.steps, featureName);
            if (result === true) {
                return result;
            }
        }
    }
    return isPresent;
}

const useCheckStatusStepperProgress = (featureName) => {
    const stepperProgressData = useSelector((state) => state.stepperprogress.stepperProgressData)
    return checkStatusOfFeature(stepperProgressData, featureName);
}

export default useCheckStatusStepperProgress;