export const colors = {

    "primary": "#372EE5",
    "maindarkShade1":"#21208F",
    "maindarkShade2":"#221CB7",
    "mainPrimary":"#372EE5",
    "p2":"#312BFF",
    "p3":"#4B54FF",
    "p4":"#8585FF",
    "p5":"#6D7FFF",
    "p6":"#96ADFF",
    "p7":"#BECEFF",
    "p8":"#DBE5FF",
    "p9":"#EBF1FF",

    "baseGray900":"#111827",
    "baseGray800":"#424242",
    "baseGray700":"#616161",
    "baseGray600":"#757575",
    "baseGray500":"#9E9E9E",
    "baseGray400":"#BDBDBD",
    "baseGray300":"#E0E0E0",
    "baseGray200":"#EEEEEE",
    "baseGray100":"#F5F5F5",
    "baseGray50" :"#FAFAFA",

    "dashboardBusinessPlanning"     :"#372EE5",
    "dashboardPitchDeck"            :"#8E43CE",
    "dashboardBusinessModel"        :"#00CA72",
    "dashboardDataRoom"             :"#FFCC00",
    "dashboardBuildMarketAnalysis"  :"#16AFFB",
    "dashboardValidateBusinessIdea" :"#EB4862",
    "dashboardGoToMarketPlan"       :"#34946D",
    "dashboardBuildBusinessCanvas"  :"#FFAA00",
    "dashboardBusinessIdeationBingo":"#BA0296",
    "dashboardProblemSolutionMatrix":"#2A60C6",
    "dashboardCompetitorAnalysis"   :"#A4C816",
    "dashboardIdealCustomerProfile" :"#6C51E5",
    "dashboardTargetMarketAnalysis" :"#9300B8",
    "infoAlertWarning"              :"#FACC15",
    "infoAlertSuccess"              :"#4ADE80",
    "infoAlertError1"               :"#F75555",
    "infoAlertDisable"              :"#D8D8D8",
    "infoAlertError2"               :"#C1232F",
    "DisabledFilledBgColor"         :"#D8D8D8",
    "DisabledFilledColor"           :"#EBF1FF",
    "DisabledBorderColor"           :"#D8D8D8",
    "DisabledColor"                 :"#D8D8D8",
    "gradient": {
        "btn1-gradient": "linear-gradient(135deg, #96ADFF 0%, #372EE5 70.27%)",
    },

    "pink"      : "#F5487F",
    "lightPink1": "#FD91D5",
    "darkPink1" : "#F8049C",
    "darkPink1" : "#D283AE",
    "red"       : "#C1173A",
    "lightRed1" : "#FF7B70",
    "lightRed2" : "#F79090",
    "lightRed3" : "#FFADB9",
    "darkGreen" : "#1F708D",
    "lightGreen1": "#5CD0A4",
    "lightGreen2": "#4FFDBE",
    "lightYellow": "#FDD54F",
    "darkYellow" : "#FFBD00",
    "purple"     : "#B881E3",
    "blue"       : "#5F59F7",
    "darkBlue"   : "#1F306E",
    "brown"      : "#785831",
    "darkBrown"  : "#A25050",
    "mint"       : "#D0E680",
    "orange"     : "#F86200",
    "orange1"    : "#FF9635",
    "lightOrange": "#FFBA7B",
    "oliveGreen" : "#848973",
    "gray"       : "#B4B1C6",
    "skyBlue1"    : "#75E8E7",
    "skyBlue2"    : "#4CBBDD",
    "lightSkyBlue": "#8FDFF8",
    "parchment"  : "#FAE5D3"
}