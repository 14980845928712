import { fileNameSuffix } from "../../utils/fileNameSuffix";


const convertToCSV = (data) => {
    // Filter out the "key" column
    const filteredData = data.map(({ key, ...rest }) => rest);

    // Rename column headings
    const columnHeadings = Object.keys(filteredData[0]).map((key, index) => {
        if (index === 0) {
            return "Problem";
        } else {
            return `Solution${index}`;
        }
    });

    const header = columnHeadings.join(',');
    const csvData = filteredData.map((item) => Object.values(item).map(value => (value.includes(',') ? `"${value}"` : value)).join(',')).join('\n');
    return `${header}\n${csvData}`;
};

// Function to trigger CSV download
export const DownloadCSV = (dataArray,isExample) => {
    let dataToDownload = dataArray;
    if(isExample){
        dataToDownload.shift();
    }
    return new Promise((resolve, reject) => {
      const csvContent = convertToCSV(dataToDownload);
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const fileName = fileNameSuffix();
      a.download = `ProblemSolutionMatrix_${fileName}.csv`
  
      document.body.appendChild(a);
      a.click();
      console.log("en ded");
      window.URL.revokeObjectURL(url);
      resolve("success");
    });
  };
  
