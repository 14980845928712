export function formatCatgQAData({ categoryList, qAList, topicList }){
    try {
        const inActiveModules = import.meta.env.VITE_REACT_LOCKED_MENU_ITEM?.split(',')?.map((m) => m?.trim())
        const nonActiveModule = topicList?.map((t) => ({"topicId": t?._id, "topic": t?.topic})).filter((t) => !!inActiveModules?.find((m) => m === t?.topic)).map((m) => m?.topicId)
        const formattedList = categoryList?.map((catg) => {
            return {
                ...catg,
                "qAList": qAList.filter((qa) => qa?.categoryId === catg?._id)?.map((qa) => ({ 
                    "_id": qa._id, 
                    "title": qa?.question, 
                    "answer": qa?.answer, 
                    "structureType": qa?.structureType,
                    "createdAt": qa?.createdAt, 
                    "updatedAt": qa?.updatedAt 
                })) || []
            }
        })?.filter((m) => !nonActiveModule?.includes(m?.topicId))
        return formattedList;
    } catch (error) {
        return [];
    }
}