import React from 'react'
import { Input } from 'antd'
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge'
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium'
import classNames from 'classnames';
import { cn } from '../../../utils/cn';
import { useSelector } from 'react-redux';

const { TextArea } = Input;

const RenderTitleListOfQuestion = ({
    Answer,
    title,
    data = {},
    isCustomTemplateOpen,
    className,
    maxLength,
    qaClassName,
    isQuestionVisible,
    handleInputChange,
    handleOnBlurForm,
    handleTextareaKeyPress,
    handleOnChangeProfileName,
    handleOnBlurProfileName
}) => {

    const permission = useSelector((state) => state.user.permission);

    return (
        <div className={cn(classNames(`flex flex-col gap-y-[24px] w-full`, className))}>
            <TextLarge text={title} color={"#372EE5"} fontWeight={500} />
            <div className={`flex flex-col gap-y-[16px] ${qaClassName}`}>
                {
                    Object.entries(data).map((qa, index) => {
                        if(["avatar"].includes(qa[0])) return null;
                        if(qa[0] === "Persona Name" && isCustomTemplateOpen){
                            return (
                                <div key={index} className='flex flex-col gap-y-[8px]'>
                                    <TextMedium text={qa[0]} color='#372EE5' fontWeight={500} />
                                    <Input
                                        maxLength={20}
                                        value={Answer?.[title]?.[qa[0]]}
                                        placeholder='Full Name'
                                        onChange={handleOnChangeProfileName}
                                        onBlur={handleOnBlurProfileName}
                                    />
                                </div>
                            )
                        }
                        return (
                            <div key={index} className='flex flex-col gap-y-[8px]'>
                                {
                                    isQuestionVisible
                                    ? <TextMedium text={qa[0]} color='#372EE5' fontWeight={500} />
                                    : null
                                }
                                {
                                    isCustomTemplateOpen
                                        ? (
                                            <TextArea
                                                disabled={!permission?.edit}
                                                autoSize={{
                                                    minRows: 3,
                                                    maxRows: 3,
                                                }}
                                                onKeyPress={(e) => handleTextareaKeyPress(e, title, qa[0])}
                                                onChange={(e) => handleInputChange(title, qa?.[0], e.target.value, Answer?.[title]?.[qa[0]] || '')}
                                                onBlur={(e) => handleOnBlurForm({ AttributeName: title, FieldName: qa[0], data: Answer?.[title]?.[qa[0]] })}
                                                className='textareaBulletPoint'
                                                value={isCustomTemplateOpen ? Answer?.[title]?.[qa[0]] || '' : qa[1]}
                                                maxLength={maxLength}
                                            />
                                        ) : (
                                            <div
                                                className="border mb-[0.5rem] min-h-[2.1rem] p-[8px] border-[#9E9E9E]"
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                    color: qa[0]==="Persona Name" ? "#9E9E9E" : "#000000"
                                                }}
                                            >
                                                {qa[0]==="Persona Name" ? "Full Name" : qa[1]}
                                            </div>
                                        )
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default RenderTitleListOfQuestion