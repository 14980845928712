import React from 'react'
import ResponsiveHeadingFourInter from '../../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter'
import RenderList from './RenderList'
import RenderObjectData from './RenderObjectData'

const RenderComplexData = ({ question, answer, qaId, handleOnClick }) => {
    if (Array.isArray(answer)) {
        return (
            <div className='flex flex-col gap-y-[1rem] py-[1.5rem]'>
                <button className='flex' onClick={() => handleOnClick(qaId)}>
                    <ResponsiveHeadingFourInter text={question} className={'hover:text-primary'} />
                </button>
                <RenderList data={answer} />
            </div>
        )
    }
    if (typeof answer === "object") {
        return (
            <div className='flex flex-col gap-y-[1rem] py-[1.5rem]'>
                <button className='flex' onClick={() => handleOnClick(qaId)}>
                    <ResponsiveHeadingFourInter text={question} className={'hover:text-primary'} />
                </button>
                <RenderObjectData data={answer} />
            </div>
        )
    }
}

export default RenderComplexData