import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonLoader = ({ width, height, ...rest }) => {
    let customProps = {};
    if(!!width) customProps = {...customProps, width}
    if(!!height) customProps = {...customProps, height}
    return (
        <div>
            <Skeleton {...customProps} {...rest} /> 
        </div>
    )
}

export default SkeletonLoader