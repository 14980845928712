import api from "../../services/api/api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import routesConfig from "../../services/config/routesConfig.json"
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";

export const fetchLeanStartupCanvasApi = createAsyncThunk(
  "leanstartupcanvas/fetchLeanStartupCanvasApi",
  async () => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'leanStartupCanvas', 'fetchLSC');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const updateLeanStartupCanvasApi = createAsyncThunk(
  "leanstartupcanvas/updateLeanStartupCanvasApi",
  async (keyName, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'leanStartupCanvas', 'updateLSC');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator, data: { keyName, data: state.leanstartupcanvas.Metrics[keyName], docId: state.leanstartupcanvas.docId }}
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

const leanstartupcanvasSlice = createSlice({
  name: "leanstartupcanvas",
  initialState: {
    slideNumber: 0,
    status: 'idle',
    poststatus: 'idle',
    Metrics: {},
    docId: "",
    updateTextValueIndex: { metricName: null, subIndex: null },
    error: null,
    updatedIndexName: null
  },
  reducers: {
    updatelatestOnChangeMetric(state, action) {
      state.updatedIndexName = action.payload;
    },
    updateSlideNumber(state, action) {
      if (action.payload.type === "independent") {
        state.slideNumber = action.payload.SlideNumber;
      }
      else if (action.payload.type === "back") {
        state.slideNumber = state.slideNumber - 1;
      } else {
        state.slideNumber = state.slideNumber + 1;
      }
    },
    updateMetric(state, action) {
      state.Metrics = action.payload;
    },
    addNewMetricValue(state, action) {
      let copyarr = state.Metrics[action.payload.key];
      copyarr.push(action.payload.value);
      state.Metrics = { ...state.Metrics, [action.payload.key]: copyarr }
    },
    deleteMetricValue(state, action) {
      let copyarr = state.Metrics[action.payload.key];
      const filteredText = copyarr.filter((text, i) => {
        if (i !== action.payload.index) {
          return text;
        }
      })
      state.Metrics = { ...state.Metrics, [action.payload.key]: filteredText }
    },
    updateMetricValue(state, action) {
      state.Metrics = { ...state.Metrics, [action.payload.key]: action.payload.value }
    },
    updateTextIndex(state, action) {
      state.updateTextValueIndex = action.payload;
    },
    resetbibErr(state) {
      state.error = null
    },
    resetbibStatus(state) {
      state.status = 'idle';
      state.poststatus = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeanStartupCanvasApi.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLeanStartupCanvasApi.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.Metrics = action.payload.Metrics;
        state.docId = action.payload.docId;
      })
      .addCase(fetchLeanStartupCanvasApi.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateLeanStartupCanvasApi.pending, (state) => {
        state.poststatus = 'loading';
      })
      .addCase(updateLeanStartupCanvasApi.fulfilled, (state, action) => {
        state.poststatus = 'fulfilled';
      })
      .addCase(updateLeanStartupCanvasApi.rejected, (state, action) => {
        state.poststatus = 'failed';
        state.error = action.error.message;
      })
  },
});

export const { updatelatestOnChangeMetric, resetbibStatus, resetbibErr, updateSlideNumber, updateMetric, addNewMetricValue, deleteMetricValue, updateMetricValue, updateTextIndex } = leanstartupcanvasSlice.actions;
export default leanstartupcanvasSlice.reducer;
