import React from 'react'
import HeadingSix from '../TypographyHeadings/HeadingSix'
import { contactUs } from '../../data'
import LogoWithTextCard from '../Cards/LogoWithTextCard'
import BaseModal from './BaseModal'
import close from "../../assets/common/close.svg"
import TextMedium from '../TypographyDescriptions/TextMedium'

const ContactUsModal = ({isOpen=true, handleOnClick}) => {
    return (
        <React.Fragment>
            <BaseModal isOpen={isOpen}>
                <button className='absolute top-[1rem] right-[1rem]' onClick={handleOnClick}><img src={close} alt="" /></button>
                <div>
                    <div className='mb-[32px]'>
                        <HeadingSix text={"Contact Us"} color={"#372EE5"} />
                        <TextMedium fontWeight={400} text='If you want to add more users' color='#372EE5' />
                    </div>
                    <ul className='flex flex-col gap-y-[24px]'>
                        {contactUs.map((detail, _) => <LogoWithTextCard color={"#372EE5"} cardBgColor='#EBF1FF' logoBgColor='#372EE52E' title={detail.cardTitle} description={detail.description} logo={detail.cardImg} />)}
                    </ul>
                </div>
            </BaseModal>
        </React.Fragment>
    )
}

export default ContactUsModal