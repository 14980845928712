import React from 'react'
import { Button } from "antd";
import TextLarge from '../TypographyDescriptions/TextLarge';

const LongCustomButton = ({ size = "small", btnText, isLoading, isDisabled, onClick, prefixIcon, btnBgColor = "#372EE5", btnTextColor = "#EBF1FF", fontWeight = 500, alignItem = "left" }) => {
    const fontSize = size === "small" ? "16px" : size === "medium" ? "16px" : "18px";
    let contentAlign;
    const styles = {
        fontSize,
        letterSpacing: "0.2px",
        lineHeight: "140%",
        backgroundColor: btnBgColor,
        color: btnTextColor
    }
    return (
        <React.Fragment>
            <Button
                onClick={onClick}
                disabled={isDisabled}
                type='primary'
                style={styles}
                loading={isLoading}
                className={`text-p9 w-[100%] py-[8px] cursor-pointer h-auto text-[16px]`}
            >
                <div className={`pr-[8px] ${alignItem == "left" ? '' : 'w-[40%] flex justify-end'}`}><img style={{ margin: 0 }} src={prefixIcon} /></div>
                <div className={`pr-[8px] ${alignItem == "left" ? '' : 'w-[60%] flex justify-start'}`}><TextLarge fontWeight={fontWeight} text={btnText} /></div>
            </Button>
        </React.Fragment>
    )
}

export default LongCustomButton