import React from 'react'
import HeadingThree from './HeadingThree'
import HeadingFive from './HeadingFive'

const ResponsiveHeadingThreeFive = ({ color, text, className }) => {
    return (
        <>
            <HeadingThree headingCSSStyles={{ marginBottom: 0 }} color={color} text={text} className={`sm:block hidden ${className}`} />
            <HeadingFive headingCSSStyles={{ marginBottom: 0 }} color={color} text={text} className={`sm:hidden block ${className}`} />
        </>
    )
}

export default ResponsiveHeadingThreeFive