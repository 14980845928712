import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatePaymentStatus } from '../features/appSlice'
import { updateMenuItemSelection, updateItemSelection } from '../features/manageLinkSlice'
import { useNavigate } from 'react-router-dom'
import { RESET_STATE } from '../store'
import { persistor } from '../main'

const useApp = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAdmin = useSelector((state) => state.user.value.isAdmin);
    const { subscriptionDetails, isPaymentMadeOnce } = useSelector((state) => state.user)
    const { planStatus } = subscriptionDetails || {};
    const isFreeSubscription = !["paid", "invited", "internal"].includes(planStatus);
    const checkPaymentStatus = useSelector((state) => state.app.checkPaymentStatus);
    // popup payment and other status
    const upgradeRenewPaywallPopup = isPaymentMadeOnce ? "renew" : "upgrade";

    const handleRestrictAccessModuleDuePayment = () => {
        if (!checkPaymentStatus.isPlanActive) {
            dispatch(updatePaymentStatus({ "isWarningPopupOpen": true }));
            return;
        }
    }

    const handleNavigateToHome = () => {
        dispatch(updateMenuItemSelection({ openKeys: ["0"], selectedKeys: ["0"] }));
        dispatch(updateItemSelection({ activeItem: "0" }));
        navigate('/product/home');
    }

    const handleLogout = async () => {
        dispatch({ type: RESET_STATE });
        await persistor.purge();
    }

    return {
        isAdmin,
        handleLogout,
        isPaymentMadeOnce,
        upgradeRenewPaywallPopup,
        "isFreeSubscription": isFreeSubscription,
        "isPaymentActive": checkPaymentStatus.isPlanActive,
        handleNavigateToHome,
        handleRestrictAccessModuleDuePayment
    }

}

export default useApp
