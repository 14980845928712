import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    tabNumber: '0',
    previewSlideShow: {//make it false later for both preview and slides
        isOpen: true,
        activeSlideNumber: 0,
    },
    MAX_NO_SLIDES: 4,//make it false later
    isSlideShowOpen: true
}

const pitchDeckSlice = createSlice({
    name: 'pitchdeck',
    initialState: initialState,
    reducers: {
        updateTabs(state, action) {
            state.tabNumber = action.payload;
        },
        toggleShowPreviewSlide(state) {
            state.previewSlideShow = { ...state.previewSlideShow, isOpen: !state.previewSlideShow.isOpen };
        },
        updateActivePeviewSlideShow(state, action) {
            const currentSlide = state.previewSlideShow.activeSlideNumber;
            const MAX_NO_SLIDES = state.MAX_NO_SLIDES;
            const updateSlideNumber = action.payload.type === "prev"
                ? currentSlide === 0 ? 0 : currentSlide - 1
                : currentSlide === MAX_NO_SLIDES-1 ? MAX_NO_SLIDES-1 : currentSlide + 1;
            state.previewSlideShow = { ...state.previewSlideShow, activeSlideNumber: updateSlideNumber }
        }
    },
    extraReducers: (builder) => { }
})
export const { updateTabs, toggleShowPreviewSlide, updateActivePeviewSlideShow } = pitchDeckSlice.actions
export default pitchDeckSlice.reducer