import React from 'react'
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium';

const RenderOrderUnorderList = ({ data, value }) => {

    const ListComponent = data?.list_type ? data?.list_type === "number" ? 'ol' : 'ul' : 'div';

    return (
        <ListComponent
            style={{
                listStyleType: data["list_type"],
                paddingLeft: '1.5rem'
            }}
            className='flex flex-col gap-y-[0.2rem]'
        >
            {
                value.map((val, index) => {

                    if (typeof val === "string") {
                        return <li><TextMedium key={index} fontWeight={400} text={val} /></li>;
                    }

                })
            }
        </ListComponent>
    )

}

export default RenderOrderUnorderList