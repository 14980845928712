import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'

const HeadingOne = ({ text, color, margin, padding, headingCSSStyles }) => {
  return (
    <React.Fragment>
        <h1 style={{color:color,...generatePadding(padding?.direction,padding?.number), ...generateMargin(margin?.direction,margin?.number), ...headingCSSStyles}} className='text-[48px] font-bold font-DMSans'>{text}</h1>
    </React.Fragment>
  )
}

export default HeadingOne