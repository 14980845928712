import React from 'react'
import TextComponent from '../reuseablecomponent/TextVarients/TextComponent'
import PaymentHistoryTable from './PaymentHistoryTable'
import setting_locals from "../../localization/localization_en_setting.json"

const locals = setting_locals.settings.tabs.Subscription.payment.payment_history;


const PaymentHistory = () => {
  return (
    <div>
      <TextComponent fontSize={16} fontWeight={500} text={locals.title} />
      <div>
        <PaymentHistoryTable locals={locals} />
      </div>
    </div>
  )
}

export default PaymentHistory