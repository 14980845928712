import React from 'react'
import { ValidateFormScreen } from '../utils/ValidateFormScreen';
import { useSelector } from "react-redux";
import { ComponentPayloadPreparation } from '../utils/ComponentPayloadPreparation';
import { checkIfValueExists } from '../COScreen2';

const useCheckFormFillStatus = ({ isFormFilledAsExpected, counterToTriggerFormStatus, formData }) => {
    const costScreenData = useSelector((state) => state.costScreen)
    const category_list_data = useSelector((state) => state.costScreen.category_list_data)
    React.useEffect(() => {
        if (counterToTriggerFormStatus > 0) {
            let isFilled = true;
            formData.data.forEach((form, index) => {
                let customizedFormItem = form;
                if (form.name === "incurring_direct_cost" || form.name === "return_cancellation_chargeable_percentage") {
                    const Channel_Names = costScreenData.channel_sales
                    let filteredColumnForm = [];
                    filteredColumnForm = {
                        ...form,
                        "table": {
                            ...form.table,
                            "columns": form.table.columns.filter((col) => "control_deps" in col ? checkIfValueExists({ "channelsData": Channel_Names, "depsData": col["control_deps"] }) : true)
                        }
                    }
                    customizedFormItem = filteredColumnForm;
                }
                const payloadData = ComponentPayloadPreparation({ index, "formItem": customizedFormItem, costScreenData, category_list_data })
                const validation_Form_Data = "checkBoxProps" in payloadData ? { "formItem": form, "data": payloadData.checkBoxProps.data, name: payloadData.checkBoxProps.name  } : payloadData;
                let isDepsPresentBeforeDataCheck = false;
                let status = false;
                if(validation_Form_Data?.name === "return_cancellation_chargeable_percentage"){
                    const isDepsPresent = costScreenData["incurring_direct_cost"].find((rowData) => rowData.name==="Reverse Logistics Costs")
                    if(isDepsPresent===undefined){
                        status = false;
                        isDepsPresentBeforeDataCheck = true;
                    }else{
                        isDepsPresentBeforeDataCheck = false;
                    }
                }
                const validationStatus = ValidateFormScreen({ "singleInputData": { "name": payloadData?.inputProps?.name, "value": payloadData?.inputProps?.value }, "formItem": validation_Form_Data?.formItem, "data": validation_Form_Data.data, "other": { marketing_spend_span_type: costScreenData.marketing_spend_span_type, "name": validation_Form_Data?.name } });
                status = isDepsPresentBeforeDataCheck ? status : validationStatus
                if (status === true) isFilled = false;
            })
            isFormFilledAsExpected(isFilled)
        }
    }, [counterToTriggerFormStatus])
}

export default useCheckFormFillStatus