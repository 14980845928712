import React from 'react'
import HeadingSix from './HeadingSix'
import HeadingThree from './HeadingThree'

const ResponsiveHeadingThreeSix = ({ color, text, className }) => {
    return (
        <>
            <HeadingThree headingCSSStyles={{ marginBottom: 0 }} color={color} text={text} className={`sm:block hidden ${className}`} />
            <HeadingSix color={color} text={text} className={`sm:hidden block ${className}`} />
        </>
    )
}

export default ResponsiveHeadingThreeSix