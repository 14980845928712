import React from 'react'

const SlideShowToolBar = () => {
  return (
    <div className="slide_show_tools max-w-[25%] border flex-1">
      <div className='w-full mt-[4rem] h-[calc(100vh-7.5rem)] overflow-y-auto scrollbar-hide'>
        
      </div>
    </div>
  )
}

export default SlideShowToolBar