import { Input } from "antd";
import TextareaAutosize from "react-textarea-autosize"
const { TextArea } = Input;

const CustomStyleTextArea = ({ color, placeHolder, value, name, maxLength, isDisabled, isReadyOnly, onChangeEvent, onBlurEvent, minRows, maxRows, borderColor, handleOnClickTextArea, isFontWeight }) => {
    return (
        <div>
            <TextareaAutosize
                name={name}
                value={value}
                placeholder={placeHolder}
                disabled={isDisabled}
                readOnly={isReadyOnly}
                onChange={onChangeEvent}
                onBlur={onBlurEvent}
                onClick={handleOnClickTextArea}
                maxLength={maxLength}
                style={{ resize: 'none', outline: 'none', color, border: `1px solid ${borderColor}`, fontWeight: !!isFontWeight && isFontWeight }}
                className="text-[14px] scrollbar-hide w-full rounded-[8px] p-[8px] border-[#BECEFF] border" />
        </div>
    )
}

export default CustomStyleTextArea