import React, { useRef, useEffect } from 'react';
import leftArrow from "./asset/LeftArrow.svg";
import rightArrow from "./asset/RightArrow.svg";
import { Carousel } from 'antd';
import { useSelector, useDispatch } from "react-redux"
import { updateSlideNumber } from "./buildbusinesscanvasSlice"
import CanvasCard from './CanvasCard';
import { colorPalette } from './data';
import { useNavigate } from 'react-router-dom';
import { fetchBuildBusinessCanvasDataApi } from './buildbusinesscanvasSlice';
import DescribeFeature from '../reuseablecomponent/DescribeFeature';
import MessageNotification from "../reuseablecomponent/MessageNotification"
import { toggleWelcomeAi, updateProductFeature } from '../AIService/promptSlice';
import responseLogo from "../AIService/assets/responseLogo.svg"
import AIWelcome from '../reuseablecomponent/PopUpVarients/AIWelcome';
import locals from "../../localization/localization_en_build_business_Canvas.json"
import RedirectModuleToCardView from '../reuseablecomponent/RedirectModuleToCardView';
import WarningLimitedResourceOopsPopup from '../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';
import { paywall_locals } from '../../localization';
import useApp from '../../hooks/useApp';

const PAYWALL_LOCALS = paywall_locals.business_model_canvas;

const bbc_locals = locals.build_business_canvas;

const data = locals.build_business_canvas.mertics;

const BuildBusinessCanvas = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slideNumber = useSelector((state) => state.buildbusinesscanvas.slideNumber);
  const status = useSelector((state) => state.buildbusinesscanvas.status);
  const error = useSelector((state) => state.buildbusinesscanvas.error);
  const { upgradeRenewPaywallPopup } = useApp();
  useEffect(() => {
    dispatch(fetchBuildBusinessCanvasDataApi());
    ref.current.goTo(slideNumber);
    return () => {
      dispatch(updateSlideNumber({ "type": "independent", "SlideNumber": 0 }));
    }
  }, [])
  return (
    <div className='min-h-screen w-[100%] mx-auto pt-[6.5rem]'>
      <AIWelcome link={"/product/home/ai/promptin/buildBuinessCanvas"} featureName={"buildBusinessCanvas"} />
      <DescribeFeature text={bbc_locals.module_title} description={bbc_locals.module_description} />
      <div className='relative mt-[0.5rem]'>
        <Carousel easing={"linear"} effect={'scrollx'} ref={ref}>
          {
            new Array(3).fill(null).map((_, i) => {
              return (
                <section key={i} className={`bg-[#96ADFF] h-[75vh] rounded-[20px] pb-[5rem]`}>
                  <CanvasCard
                    key={i}
                    titles={data.slice(i * 3, i * 3 + 2 + 1)}
                    index={i}
                    colorPalette={colorPalette[i]}
                  />
                </section>
              )
            })
          }
        </Carousel>
        <div className={`absolute bottom-[48px] flex px-[3%] justify-between w-full`}>
          <button onClick={() => {
            ref.current.prev();
            dispatch(updateSlideNumber({ type: "back" }));
          }} className={`${(slideNumber === 0 && 'invisible')} flex items-center`}><img className='mr-1' src={leftArrow} /><span>{bbc_locals.button_controls[1]}</span></button>
          <WarningLimitedResourceOopsPopup
            description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].final_output.description1}
            description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].final_output.description2}
          >
            {({ handleTogglePopup, isFreeSubscription }) => {
              return (
                <button onClick={() => {
                  if (slideNumber !== 2) {
                    ref.current.next();
                    dispatch(updateSlideNumber({ type: "next" }));
                  } else {
                    if (isFreeSubscription) {
                      handleTogglePopup();
                      return;
                    }
                    navigate('/product/home/businesscanvas/buildbusinesscanvas/viewnotes')
                  }
                }} className={` flex items-center rounded-[8px] px-[16px] py-[8px] bg-white`}><span className='mr-1'>{slideNumber === 2 ? bbc_locals.button_controls[2] : bbc_locals.button_controls[0]}</span><img src={rightArrow} /></button>
              )
            }}
          </WarningLimitedResourceOopsPopup>
        </div>
      </div>
      <div className='pt-[1rem]'>
        <RedirectModuleToCardView moduleName={'buildBusinessCanvas'} />
      </div>
      <WarningLimitedResourceOopsPopup
        description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].ai.description1}
        description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].ai.description2}
      >
        {({ handleTogglePopup, isFreeSubscription }) => {
          return (
            <button
              onClick={() => {
                if (isFreeSubscription) {
                  handleTogglePopup();
                  return;
                }
                dispatch(toggleWelcomeAi());
                dispatch(updateProductFeature("buildBusinessCanvas"));
              }}
              className="p-[7px] fixed bottom-[1rem] z-[1000] right-[1rem] bg-primary text-white flex items-center rounded-[8px]"
            >
              <img className="w-[30%]" src={responseLogo} />
              <span className="ml-[0.5rem]">Ask AI</span>
            </button>
          )
        }}
      </WarningLimitedResourceOopsPopup>

    </div>
  );
};

export default BuildBusinessCanvas;