import React from 'react'
import logo from '../../assets/Header/logotest.svg';
import brand from "../../assets/common/brand.svg";
import { useNavigate } from 'react-router-dom';

const AuthHeader = () => {
    const navigate = useNavigate();
    return (
        <div onClick={() => navigate('/')} className="header sticky top-0 md:pt-[48px] w-full">
            <img src={logo} className="w-[138px] h-[21px] md:block hidden" alt="" />
            <img src={brand} className="w-[64px] h-[64px] block md:hidden" alt="" />
        </div>
    )
}

export default AuthHeader