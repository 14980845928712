import React, { useState } from 'react'
import { Space, Card } from 'antd';
import ToolTip from '../../ToolTip/ToolTip';
import eyeIcon from "../../../assets/common/eyeIcon.svg"
import editIcon from "../../../assets/common/editIcon.svg"
import LongCustomButton from '../../Buttons/LongCustomButton';
import IconButton from '../../Buttons/IconButton';
import heartFilledIcon from "../../../assets/common/heartFilledIcon.svg";
import heartNotFilled from "../../../assets/common/heartNotFilled.svg";
import slideshowNotFilled from "../../../assets/common/slideshowNotFilled.svg";
import './pitchDeckStyle.css'
const { Meta } = Card;

const PitchDeckCard = ({ isCardShadow = true, cardTitle = "Title", cardBgImage, isFavorite, handleFavoriteTemplate, toolTipMessage }) => {

    return (
        <React.Fragment>
            <Card
                hoverable={isCardShadow}
                cover={
                    <Space className='relative'>
                        <img className='w-full' src={cardBgImage} alt="" />
                        <div className='absolute top-0 rounded-t-[8px] w-full h-full'>
                            <div className="custom-div relative fadeInAnimation rounded-t-[8px]">
                                <div className='w-[46%] flex flex-col gap-y-[16px]'>
                                    <LongCustomButton alignItem='center' prefixIcon={eyeIcon} />
                                    <LongCustomButton alignItem='center' prefixIcon={editIcon} />
                                </div>
                            </div>
                        </div>
                        <div className='absolute top-[15px] right-[15px] flex flex-col gap-y-[15px]'>
                            <IconButton onClick={handleFavoriteTemplate} isAnimation={isFavorite} animationName={'heartAnimation'} icon={isFavorite ? heartFilledIcon : heartNotFilled} btnBgColor={"white"} />
                            <IconButton icon={slideshowNotFilled} btnBgColor={"white"} />
                        </div>
                    </Space>
                }
            >
                <Meta title={cardTitle} />
                <div className='absolute bottom-[24px] right-[24px]'><ToolTip placement={"top"} tooltip={toolTipMessage} /></div>
            </Card>
        </React.Fragment>
    )
}

export default PitchDeckCard