import React, { useState, useEffect } from "react";
import { Button } from "antd";
import FeasilityCard from "../components/FeasilityCard";
import { rateFeasbilityParameter } from "../data";
import { Rate, message } from "antd";
import "../index.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateRating, exportRatingApi } from "../features/psmSlice";
import { checkNullFesabilityParameter } from "../utils/checkNullFesabilityParameterValue";
import dangericon from "../assets/PSM/Danger.svg";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import OopsPopUp from "../components/reuseablecomponent/PopUpVarients/OopsPopUp";
import psmLocals from "../localization/localization_en_problem_solution_matrix.json"

const RateFeasbilityParameter = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const ratingList = useSelector((state) => state.psm.individualRating);
    const psmRootData = useSelector((state) => state.psm.problemsolutionmatrix);
    const [isToolTipOpen,setIsToolTipOpen] = useState(false);
    const [ratings, setRatings] = useState(ratingList.ratings);
    console.log(ratingList, "listref");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (ratingList.status === "succeeded") {
            dispatch(
                updateRating({
                    ratings,
                    row: ratingList.row,
                    col: ratingList.col,
                    status: "idle",
                })
            );
            navigate("/product/home/problemsolutionmatrix");
        }
    }, [ratingList.status]);
    const handleRatingChange = (index, value) => {
        setRatings((prev) => ({ ...prev, ...value }));
        // console.log(index,value,value[0]);
    };
    const error = () => {
        const iconStyle = {
            marginRight: "8px", // Adjust the spacing between icon and content as needed
            alignSelf: "center", // Vertically center the icon within the container
        };

        const contentStyle = {
            display: "flex",
            alignItems: "center", // Center content vertically within the container
            color: "red",
        };
        setIsToolTipOpen((prev) => !prev);
    };
    const handleSubmitRating = () => {
        if (checkNullFesabilityParameter(ratings)) {
            error();
        } else {
            //  console.log(psmRootData[ratingList.row].collectionSolution[ratingList.col].sid);
            dispatch(
                exportRatingApi({
                    ratings: ratings,
                    sid: psmRootData[ratingList.row].collectionSolution[ratingList.col]
                        .sid,
                })
            );
        }
        // dispatch(updateRating({ ratings}));
    };
    const handleToggleToolTip = () => {
        setIsToolTipOpen((prev) => !prev)
    }
    return (
        <div className="w-[100%] min-h-[99.7vh]">
            {contextHolder}
            { isToolTipOpen && <OopsPopUp isOpen={isToolTipOpen} onClick={handleToggleToolTip} description1={"Please rate all metrics to generate an optimal solution."} /> }
            <div className="w-[100%] min-h-[50vh] pt-[80px]">
                <div className="xl:w-[98%] mx-auto mb-[40px]">
                    <DescribeFeature text={psmLocals.problem_solution_matrix.rate_feasibility_parameter.metric_title} description={psmLocals.problem_solution_matrix.rate_feasibility_parameter.mettic_description} />
                    <h3 className='font-DMSans font-bold text-[20px] text-primary mt-[1rem]'>Solution - {ratingList.solutionDescription}</h3>
                </div>
                <div className="w-[100%]">
                    <ul
                        style={{ marginBottom: "0px" }}
                        className="flex flex-wrap justify-center gap-x-10 gap-y-10"
                    >
                        {rateFeasbilityParameter.map((paramter, i) => {
                            let ratingCustomizedName = psmLocals.problem_solution_matrix.rate_feasibility_parameter.metric_parameters[i].title
                                .split(" ")
                                .join()
                                .replace(",", "");
                            return (
                                <li
                                    style={{ borderColor: i%2==0 ? "#96ADFF" : "#F6E086" }}
                                    key={i}
                                    className={`rounded-[8px] border-2 w-[275px]`}
                                >
                                    <div className="h-[325px] border rounded-[8px]">
                                        <div
                                            style={{ backgroundColor: i%2==0 ? "#DBE5FF" : "#FFE88D"}}
                                            className="h-[50px] rounded-t-[8px] flex items-center justify-center"
                                        >
                                            <p className="text-center text-[#111827] my-auto text-[16px] font-InterRegular font-medium">
                                                {psmLocals.problem_solution_matrix.rate_feasibility_parameter.metric_parameters[i].title}
                                            </p>
                                        </div>
                                        <div
                                            className={`${i%2==0 ? "from-[#f5f8fe]" : "from-[#fffbec]"} bg-gradient-to-b via-transparent to-transparent px-[16px] pt-[24px]`}
                                        >
                                            <p className="text-center h-[131px] mb-[40px] text-[#111827] text-[14px] italic font-medium custom-letter-spacing">
                                                "{psmLocals.problem_solution_matrix.rate_feasibility_parameter.metric_parameters[i].description}"
                                            </p>
                                            <div className="text-center">
                                                <Rate
                                                    value={ratings[ratingCustomizedName]}
                                                    onChange={(value) =>
                                                        handleRatingChange(i, {
                                                            [ratingCustomizedName]: value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="flex justify-center my-[56px]">
                    <Button className="text-[16px] font-InterRegular tracking-wide font-medium h-auto" onClick={() => {navigate("/product/home/problemsolutionmatrix");}}>{psmLocals.problem_solution_matrix.rate_feasibility_parameter.go_back_btn_text}</Button>
                    <Button className="text-[16px] font-InterRegular tracking-wide font-medium h-auto bg-primary px-[24px] ml-[50px] text-white" onClick={handleSubmitRating}>{psmLocals.problem_solution_matrix.rate_feasibility_parameter.save_btn_text}</Button>
                </div>
            </div>
        </div>
    );
};

export default RateFeasbilityParameter;
