import { cn } from "../../utils/cn";
import { cva } from "class-variance-authority";

const button = cva(
    [
        "font-InterRegular",
        "tracking-[0.2px]",
        "rounded-[4px]",
        "flex", "items-center", "justify-center",
        "font-semibold",
        "min-w-fit"
    ],
    {
        variants: {
            intent: {
                "primary": ["bg-primary", "text-white"],
                "secondary": ["border", "border-primary", "text-primary"],
                "ghost": [],
                "link": ["text-primary"],
                "danger": []
            },
            size: {
                "sm": [],
                "md": ["text-[16px]", "px-[24px]", "py-[12px]"],
                "lg": [],
                "free": ["w-full", "h-full"]
            },
            variantType: {
                "button": [],
                "icon": []
            },
            shape: {
                "square": [],
                "circle": ["rounded-full"]
            }
        },
        compoundVariants: [
            { intent: "primary", size: "sm", variantType: "icon", class: [] },
        ],
        defaultVariants: {
            size: "md",
            intent: "primary",
            variantType: "button"
        }
    }
);

const PGButtonComponent = ({
    text,
    intent,
    variantType,
    size,
    shape,
    className,
    children,
    ...rest
}) => {

    return (
        <button
            className={cn(button({ size, intent, variantType, shape, className }))}
            {...rest}
        >
            {children}
        </button>
    );
};

export default PGButtonComponent;