import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { formatTimestamp } from './helper/formatTimestamp'
import { formatCatgQAData } from './helper/formatCatgQAData'
import NestedVerticalList from './components/NestedVerticalList'
import ListTitleDescription from './components/ListTitleDescription'
import NavigationButton from './subcomponents/NavigationButton'
import HeadingThree from '../../DesignSystem/TypographyHeadings/HeadingThree'
import TitleDescription from './subcomponents/TitleDescription'
import WestIcon from '@mui/icons-material/West';
import SearchIcon from '@mui/icons-material/Search'

import PublishedText from './subcomponents/PublishedText'
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge'
import LogoInputComponent from '../subcomponents/LogoInputComponent'
import TextExtraLarge from '../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import RenderTextBlockVarient from './components/RenderTextBlockVarient'
import { API_Fetch_ResourceGuideCatgQaData, API_Fetch_ResourceGuideSearchQNAData, D_Reset_ResourceGuide_SearchApiStatus, D_Reset_ResourceGuide } from '../../features/appSlice'
import { message } from 'antd'
import HeadingFive from '../../DesignSystem/TypographyHeadings/HeadingFive'
import SearchNotFound from '../components/SearchNotFound'
import weblocals from "../../localization/localization_en_website.json";
import { convertArrIntoString } from './helper/convertArrIntoString'
import useOutsideClick from '../../hooks/useOutsideClick'
import { useRef } from 'react'
import ResponsiveHeadingFourInter from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter'
import SkeletonLoader from '../../DesignSystem/Loader/SkeletonLoader'
import BunchOfSkeletonLoader from '../../DesignSystem/Loader/BunchOfSkeletonLoader'
import useScrollToTopPage from '../../hooks/useScrollToTopPage'
import RenderComplexData from './render/RenderComplexData'
import { getKeyByValue } from '../../utils/getKeyByValue'
import { resource_guide_fetch_data_params } from '../../data'
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium'
import ResponsiveHeadingThreeFive from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingThreeFive'
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton'

const ResourceGuideCatgListQA = () => {

    try {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const search_ref = useRef(null);
        const [isSearchOpen, setIsSearchOpen] = useState(false);
        useOutsideClick(search_ref, () => {
            setIsSearchOpen(false);
        });
        const [filterNestedActiveList, setFilterNestedActiveList] = useState('');
        const { topicid, catgid, qaid } = useParams() || {};
        const { data, api_status, error_message, search_api_results, search_api_status } = useSelector((state) => state.app.resource_guide)
        const { search_loading_text } = weblocals.website['resource-guide']

        useScrollToTopPage({ deps: [catgid, qaid] })

        const fetchCategoryData = async ({ categoryid }) => {
            dispatch(API_Fetch_ResourceGuideCatgQaData({ "topicId": topicid, "categoryId": categoryid, "qnaId": qaid }));
        }

        useEffect(() => {
            if (topicid && catgid) {
                fetchCategoryData({ "categoryid": catgid })
                // dispatch(API_Fetch_ResourceGuideCatgQaData({ "topicId": topicid, "categoryId": catgid, "qnaId": qaid }));
            }
            return () => {
                dispatch(D_Reset_ResourceGuide({ "data": null }));
            };
        }, []);


        useEffect(() => {
            if (api_status === "success" && data && data.topicId && data.categoryId) {
                if (data.topicId !== topicid || data.categoryId !== catgid) {
                    //this is duplicating window navigation stack 
                    navigate(`/product/home/resource-guide/${data.topicId}/${data.categoryId}`, { replace: true });
                }
                // dispatch(D_Reset_ResourceGuide({ "api_status": "idle" }));
            } else if (api_status === "failed") {
                message.error(error_message);
                dispatch(D_Reset_ResourceGuide_SearchApiStatus());
            }
        }, [api_status, data, error_message, dispatch]);

        useEffect(() => {
            setFilterNestedActiveList('');
        }, [catgid])

        const cardList = useMemo(() => {
            return (
                formatCatgQAData({
                    "topicList": data?.topicList || [],
                    "categoryList": data?.categoryList || [],
                    "qAList": data?.qAList || []
                })
            )
        }, [data, api_status])

        const activeTopicCatgQa = cardList
            ?.filter((catg) => catg?.topicId === topicid)
            ?.map((catg) => ({
                "_id": catg?._id,
                "title": catg?.category,
                "list": catg?.qAList
            }))
        const targetCatg = cardList?.find((catg) => catg?._id === catgid)

        const arrayIndex = qaid ? targetCatg?.qAList?.findIndex((qa) => qa?._id === qaid) : null;
        const currCard = qaid ? targetCatg?.qAList?.[arrayIndex] : {};
        const nextCard = qaid ? targetCatg?.qAList?.[arrayIndex + 1] : {};
        const prevCard = qaid ? targetCatg?.qAList?.[arrayIndex - 1] : {};

        const searchList = search_api_results && search_api_results?.length ? search_api_results.filter((qa) => qa.categoryId === catgid) : [];

        const calculateFinishOrNext = () => {
            if (arrayIndex === targetCatg?.qAList?.length - 1) {
                const list_of_catg = cardList?.map((catg) => catg?._id)
                const index_of_catg = list_of_catg?.indexOf(catgid);
                return index_of_catg === list_of_catg?.length-1;
            }
            return false
        }

        // console.log(api_status === "loading")

        const topic_sub_url = getKeyByValue(resource_guide_fetch_data_params, data?.topic?.topic)

        return (
            <div className='pt-[3.5rem]'>
                <div className=' py-[24px] mb-[36px]'>
                    {/* <button
                        onClick={() => navigate(`/product/home/resource-guide/${topic_sub_url}`)}
                        className='flex items-center gap-x-[8px]'
                    >
                        <WestIcon className='text-primary' />
                        <TextLarge fontWeight={600} className={"text-primary"} text='Back' />
                    </button> */}
                    <PrimaryButton onClick={() => navigate(`/product/home/resource-guide/${topic_sub_url}`)} text={"Back"} />
                </div>
                <div className='flex gap-x-[40px]'>
                    <div className='min-w-[355px] max-w-[355px] max-h-[calc(100dvh-5rem)]'>
                        {
                            api_status === "loading"
                                ? (
                                    <BunchOfSkeletonLoader noOfSkeletonLoader={4} height={45} className={"gap-y-[1rem]"} />
                                ) : (
                                    <>
                                        <div
                                            ref={search_ref}
                                            className='relative'
                                        >
                                            <LogoInputComponent
                                                placeholder={"Search"}
                                                value={filterNestedActiveList}
                                                prefixIcon={<SearchIcon fontSize='medium' className='text-baseGray600' />}
                                                className={"py-[0.4rem] text-[18px] max-w-[514px]"}
                                                handleOnChange={(e) => {
                                                    dispatch(API_Fetch_ResourceGuideSearchQNAData({ "searchText": e.target.value, "searchType": "qna" }));
                                                    setIsSearchOpen(true);
                                                    setFilterNestedActiveList(e.target.value)
                                                }}
                                            />
                                            {
                                                isSearchOpen && filterNestedActiveList?.length > 0
                                                    ? (
                                                        <div className='w-[50rem] max-h-[40rem] absolute bg-white border divide-y z-10 rounded-md shadow-lg overflow-y-auto px-[2rem]'>
                                                            {/* {
                                                search_api_status === "loading" && (
                                                    <div className='py-[2rem]'>
                                                        {search_loading_text.text1}
                                                    </div>
                                                )
                                            } */}
                                                            {
                                                                Array.isArray(search_api_results) && searchList.length > 0
                                                                    ? (
                                                                        searchList.map((sr, i) => {
                                                                            return (
                                                                                <div
                                                                                    key={i}
                                                                                    onClick={() => {
                                                                                        setIsSearchOpen(false);
                                                                                        navigate(`/product/home/resource-guide/${topicid}/${sr?.categoryId}/${sr?._id}`)
                                                                                    }}
                                                                                    className='min-h-[5rem] bg-white py-[2rem] cursor-pointer'
                                                                                >
                                                                                    <div>
                                                                                        <HeadingFive text={sr?.question} />
                                                                                    </div>
                                                                                    <div className='truncate-to-3-lines'>
                                                                                        {/* {JSON.stringify(sr?.answer)} */}
                                                                                        <TextLarge fontWeight={500} text={typeof sr?.answer === "string" ? sr?.answer : convertArrIntoString(sr?.answer)} />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        searchList?.length === 0 && (
                                                                            <div className='py-[2rem]'>
                                                                                <SearchNotFound searchText={filterNestedActiveList} />
                                                                            </div>
                                                                        )
                                                                    )
                                                            }
                                                        </div>
                                                    ) : null
                                            }
                                        </div>
                                        <NestedVerticalList
                                            list={activeTopicCatgQa}
                                            activeId={qaid ?? catgid}
                                            activeParentItem={catgid}
                                            activeChildrenItem={qaid}
                                            filterSubList={''}//filterNestedActiveList
                                            nested={false}
                                            handleOnClick={({ parentOrChildren, _id }) => {
                                                if (parentOrChildren === "parent") {
                                                    dispatch(API_Fetch_ResourceGuideCatgQaData({ "topicId": topicid, "categoryId": _id }));
                                                    return
                                                    // return navigate(`/product/home/resource-guide/${topicid}/${_id}`)
                                                }
                                                navigate(`/product/home/resource-guide/${topicid}/${catgid}/${_id}`)
                                            }}
                                        />
                                    </>
                                )
                        }
                    </div>
                    <div className='w-full'>
                        {
                            api_status === "loading"
                                ? (
                                    <BunchOfSkeletonLoader noOfSkeletonLoader={4} height={45} className={"gap-y-[1rem]"} />
                                ) : (
                                    <div className='w-full flex flex-col gap-y-[24px]'>
                                        <div className='flex flex-col gap-y-[10px]'>
                                            <ResponsiveHeadingThreeFive className={'text-primary'} headingCSSStyles={{ margin: 0 }} text={targetCatg?.category} />
                                            <TextMedium text={targetCatg?.description} fontWeight={400} />
                                        </div>
                                        <div>
                                            {
                                                qaid
                                                    ? <div>
                                                        <div className='w-full'>
                                                            <div className='rounded-md border border-[#bcbcbd] border-t-[4px] border-t-p3 px-[24px] pb-[24px]'>
                                                                {/* <RenderTextBlockVarient currCard={currCard} /> */}
                                                                <RenderComplexData
                                                                    question={currCard?.title}
                                                                    answer={currCard?.answer}
                                                                    qaId={currCard?._id}
                                                                    // handleOnClick={() => }
                                                                />
                                                                {/* <pre>
                                                                    {JSON.stringify(currCard, null, 2)}
                                                                </pre> */}
                                                                <div className='w-full h-[1px] border-t my-[40px]'></div>
                                                                <div className='flex flex-col gap-y-[24px]'>
                                                                    <PublishedText heading={"Published:"} description={formatTimestamp(currCard?.createdAt)} />
                                                                    <PublishedText heading={"Last Updated:"} description={formatTimestamp(currCard?.updatedAt)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='my-[40px]'>
                                                            <NavigationButton
                                                                arrayIndex={arrayIndex}
                                                                noOfItems={targetCatg?.qAList?.length - 1}
                                                                nextQuestion={nextCard?.title}
                                                                prevQuestion={prevCard?.title}
                                                                handleNextButton={() => {
                                                                    if (arrayIndex === targetCatg?.qAList?.length - 1) {
                                                                        const list_of_catg = cardList?.map((catg) => catg?._id)
                                                                        const index_of_catg = list_of_catg?.indexOf(catgid);
                                                                        if(index_of_catg === list_of_catg?.length-1){
                                                                            return navigate(`/product/home/resource-guide/${topic_sub_url}`)
                                                                        }
                                                                        dispatch(API_Fetch_ResourceGuideCatgQaData({ "topicId": topicid, "categoryId": list_of_catg?.[index_of_catg+1] }));
                                                                        return;
                                                                        // return navigate(`/product/home/resource-guide/${topicid}/${list_of_catg?.[index_of_catg+1]}`)
                                                                    }
                                                                    return navigate(`/product/home/resource-guide/${topicid}/${catgid}/${nextCard?._id}`)
                                                                }}
                                                                handlePrevButton={() => {
                                                                    return navigate(`/product/home/resource-guide/${topicid}/${catgid}/${prevCard?._id}`)
                                                                }}
                                                                finishOrNext={calculateFinishOrNext()}
                                                            />
                                                        </div>
                                                    </div>
                                                    : <div className='w-full pb-[40px]'>
                                                        <ListTitleDescription
                                                            list={targetCatg?.qAList || []}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        )
    } catch (error) {
        console.log(error);
        // window.location.href = window.location.origin + '/product/home/resource-guide'
    }
}

export default ResourceGuideCatgListQA