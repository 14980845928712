import React, { useState, useRef } from 'react'
import { useSelector } from "react-redux"
import ROScreen1 from './RevenueOnboarding/ROScreen1'
import ROScreen2 from './RevenueOnboarding/ROScreen2'
import ROScreen3 from './RevenueOnboarding/ROScreen3'
import ROScreen4 from './RevenueOnboarding/ROScreen4'
import ROScreen5 from './RevenueOnboarding/ROScreen5'
import ROScreen6 from './RevenueOnboarding/ROScreen6'
import { message } from "antd"
import Stepper from '../../../DesignSystem/Stepper/Stepper';
import business_model_locals from "../../../localization/localization_en_business_modal.json"
import HeadingFour from '../../../DesignSystem/TypographyHeadings/HeadingFour'
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton'
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton'
import { API_Post_Screen1_Data, API_Post_Screen2_Data, API_Post_Screen3_Data, API_Post_Screen4_Data, API_Post_Screen5_Data, toggleScreen, toggleShowError, trackCounter, D_Control_Rendering_Output, D_Inject_Global_Setting, D_Reset_Api_Status, D_Reset_Complete_Api_Status, D_Button_Controls, D_Increement_SalesMargin, D_Trace_StepperPredictionApiStatus } from '../revenueSlice'
import { API_Process_Output_Screen1_Data, API_Process_Output_Screen2_Data, API_Process_Output_Screen3_Data, API_Process_Output_Screen4_Data, API_Process_Output_Screen5_Data, API_Process_Output_Screen6_Data } from "../revenueSlice";
import { useDispatch } from "react-redux"
import { PayloadPreparationGlobalSetting, PayloadPreparationRevenueOnboardingScreen } from '../functionality/payloadPreparation'
import OutputScreen from './RevenueOnboarding/OutputScreen'
import PageLoader from '../../reuseablecomponent/PageLoader'
import { D_Update_Mode_Type } from '../businessModalSlice'
import { useNavigate } from "react-router-dom"
import { D_Patch_Revenue_Onboarding_Data } from '../costSlice';
import { D_Update_Net_Payment_term_Data_Based_RO_Screen1 } from '../workingCapitalSlice'
import { checkFutureScreenDataFilled } from '../functionality/checkFutureScreenDataFilled'
import OopsPopUp from '../../reuseablecomponent/PopUpVarients/OopsPopUp'
import { fetchWarningPopupErrorMessage } from '../functionality/fetchWarningPopupErrorMessage'
import { D_UpdateFilterYearCharts } from "../businessModalSlice"
import { D_Update_Onboarding_Screen_Id } from '../../../features/appSlice'
import { API_GET_REVENUE_ONBOARDING_SCREEN_SLICE } from '../revenueSlice'
import { API_GET_COST_ONBOARDING_SCREEN_SLICE, D_Reset_ScreenToScreen_Api_Status } from '../costSlice'
import ComponentErrorBoundary from '../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary'

const locals = business_model_locals.business_modal.revenue;

const RevenueScreenComponents = [ROScreen1, ROScreen2, ROScreen3, ROScreen4, ROScreen5, ROScreen6];

const RevenueOnboarding = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const topScroll = useRef(null);
    const bottomScroll = useRef(null);
    let filteredFormDataBasedChannel = [];
    const [pageName, setPageName] = React.useState('');
    const [controlsType, setIsControlType] = React.useState('');
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [traceButtonType, setTraceButtonType] = useState("none");
    const [warningPopUpDetails, setWarningPopUpDetails] = useState({ traceButtonClicked: null, isPopUpOpen: false })
    const [loadingState, setLoadingState] = useState({ "screenLoading": false, "postLoading": false, "deleteLoading": false });
    const [refreshCounter, setRefreshCounter] = useState(0);
    const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData);
    const { projection_duration, currency, decimal_places } = useSelector((state) => state.globalSetting.active_global_settings)
    const businessModelId = useSelector((state) => state.businessModal.active_BusinessModal_Id)
    const overallScreenRefObj = useSelector((state) => state.businessModal.tracing_user_filled_input_screen)
    const currencyList = useSelector((state) => state.globalSetting.currencyList)
    const { activeScreenNumber, channel_sales, trackComponentRendering, isShowError, api_status, isSaveActive, isGenerateOutputActive, traceHitSaveCount, outputSliderValues, stepperPredictionApiStatus, categoryList } = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
    const { marketing_spend_per_sales_channel, marketing_spend_per_channel_fiscal_year, category_list } = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
    const active_global_settings = useSelector((state) => state.globalSetting.active_global_settings);
    const counter = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.checkIsFormFilled.counter)
    const output = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.output)
    const { isShowOuput, postingScreenData } = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
    const product_category = category_list.product_category
    const channel_name = category_list.channel_name
    filteredFormDataBasedChannel = locals.form.slice(2, 5)?.filter((form) => channel_sales?.includes(form.title))?.sort((a, b) => a.index - b.index);
    const updatedFormData = [...locals.form.slice(0, 2), ...filteredFormDataBasedChannel, {}];
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)
    const networkStatus = useSelector((state) => state.app.networkStatus)
    const IS_LOADING = api_status?.status?.[5]?.post?.status === 'loading'
    const SCREEN_DATA_FETCHING = api_status.fetch_screen_slice_status.status;
    const cost_screen_one_data_fetching = useSelector((state) => state.costScreen.screen_screen_api_status.status)
    const traceKeyNameFromGetCostScreenData = useSelector((state) => state.costScreen.traceKeyNameFromGetCostScreenData);
    const prodCatgListData = revenueOnboardingData.category_list.product_category
    const channelCatgListData = revenueOnboardingData.category_list.channel_name

    // console.log(traceButtonType,"traceButtonType")

    const VIEW_MODE = MODE_TYPE.view;
    const EDIT_MODE = MODE_TYPE.edit;

    // navigateToNextScreen - navigateScreen
    const navigateScreen = () => {
        dispatch(trackCounter({ type: "reset" }));
        dispatch(toggleScreen({ type: traceButtonType, totalNumberOfScreen: updatedFormData.length }));
        dispatch(toggleShowError(false))
        // dispatch(D_Button_Controls({ type: "isSaveActive", value: false }))
    }
    React.useEffect(() => {
        if(SCREEN_DATA_FETCHING==="success"){
            dispatch(D_Reset_Complete_Api_Status())
            if(VIEW_MODE){
                if((!revenueOnboardingData?.traceKeyNameFromGetScreenData?.includes("input") && trackComponentRendering[activeScreenNumber+1]!==5) || 
                   (!revenueOnboardingData?.traceKeyNameFromGetScreenData?.includes("output") && trackComponentRendering[activeScreenNumber+1]===5)){
                    setWarningPopUpDetails((prev) => ({...prev, "isPopUpOpen": true, "traceButtonClicked": "view" }))
                    return;
                }
            }
            navigateScreen();
        }
    }, [SCREEN_DATA_FETCHING])
    // console.log(cost_screen_one_data_fetching,"cost_screen_one_data_fetching")
    React.useEffect(() => {
        if(cost_screen_one_data_fetching==="success"){
            dispatch(D_Reset_ScreenToScreen_Api_Status());
            if(VIEW_MODE){
                if(!traceKeyNameFromGetCostScreenData?.includes("input")){
                    setWarningPopUpDetails((prev) => ({...prev, "isPopUpOpen": true, "traceButtonClicked": "view" }))
                    return;
                }
            }
            if(EDIT_MODE) {
                navigate(`/product/home/business-modal/edit/${businessModelId}/cost-screen`)
                return;
            }
            navigate('/product/home/business-modal/cost-onboarding');
        }
    }, [cost_screen_one_data_fetching])

    const fetchPreviousOrNextScreenData = (type) => {
        console.log("calling get screen data function");
        dispatch(API_GET_REVENUE_ONBOARDING_SCREEN_SLICE(type ? type : traceButtonType));
    }

    const handleLoadingAndNotification = ({ httpType, apiStatus }) => {
        const loadingReferences = { "get": "screenLoading", "post": "postLoading", "delete": "deleteLoading" }
        setLoadingState((prev) => ({...prev, [loadingReferences[httpType]]: "loading" === apiStatus["status"]}));
        if(apiStatus.status === "success" && httpType === "post" && traceButtonType==="stepper"){
            dispatch(D_Trace_StepperPredictionApiStatus(stepperPredictionApiStatus+1))
        }
        if(apiStatus.status === "success" && httpType === "post" && controlsType==="output"){
            bottomScroll?.current?.scrollIntoView({behavior: 'smooth'});
        }
        if(apiStatus.status === "success" && httpType === "post" && controlsType==="next"){
            if(trackComponentRendering[activeScreenNumber]!==5){ // navigation is specific for screen1 to screen2 only
                // console.log("navigation api")
                // navigateToNextScreen(); --- > after fetching future screen data then navigate;
                fetchPreviousOrNextScreenData("next");
                // setTraceButtonType("next");
            }
        }
        if(apiStatus.message){
            if(apiStatus.status === "success"){
                message.success("success", 2, () => dispatch(D_Reset_Api_Status({ httpType })))
            }else if(apiStatus.status === "failed"){
                // message.error(apiStatus.message, 2, () => )
                setTimeout(() => dispatch(D_Reset_Api_Status({ httpType })),2000)
            }
        }
        if(apiStatus.status === "failed"){
            setWarningPopUpDetails((prev) => ({...prev, "isPopUpOpen": true}))
        }
    }
    React.useEffect(() => {
        setRefreshCounter(0);
        dispatch(D_Trace_StepperPredictionApiStatus(0))
        dispatch(D_Reset_Complete_Api_Status())
        dispatch(D_Inject_Global_Setting({ projection_duration, decimal_places, "channelListData": channel_name }))
    }, [])
    React.useEffect(() => {
        topScroll?.current?.scrollIntoView({ behavior: 'smooth' });
        if(trackComponentRendering[activeScreenNumber]!==5) setRefreshCounter(0);
        setPageName(updatedFormData[activeScreenNumber]?.page_name)
        const month_year = revenueOnboardingData?.marketing_spend_per_channel_fiscal_year?.split('-')
        dispatch(D_UpdateFilterYearCharts(new Date().getFullYear()))
        const screenInput = {
            "first_month": Number(month_year?.[1]),
            "first_year": Number(month_year?.[0]),
        }
        if(trackComponentRendering[activeScreenNumber]===5 && !VIEW_MODE) dispatch(API_Process_Output_Screen6_Data({ "screenInput": screenInput, "businessModelId": businessModelId }))
    }, [activeScreenNumber])
    React.useEffect(() => {
        if(!Boolean(refreshCounter)) return;
        const API_STATUS = api_status.status;
        for(const presentScreenStatus of Object.entries(API_STATUS[trackComponentRendering[activeScreenNumber]])){
            handleLoadingAndNotification({ "httpType": presentScreenStatus[0], "apiStatus": presentScreenStatus[1] })
        }
    }, [api_status.counter])
    const handleToggleScreen = ({ type }) => {
        setIsControlType(type);
        setRefreshCounter((prev) => prev+1);
        if (type === "prev") {
            dispatch(trackCounter({ type: "reset" }));
            setTraceButtonType("prev");
            fetchPreviousOrNextScreenData("prev");
            // dispatch(toggleScreen({ type, totalNumberOfScreen: updatedFormData.length }));
            // dispatch(D_Button_Controls({ type: "isSaveActive", value: false }))
            return;
        }
        if (activeScreenNumber+1===trackComponentRendering.length){
            dispatch(API_GET_COST_ONBOARDING_SCREEN_SLICE({ "activeScreen": 0, "previousScreen": 0 }));
        }
        setTraceButtonType(activeScreenNumber===0 ? (type==="next" ? "output" : "save") : type)
        dispatch(trackCounter({ type: "increement" }))
    }
    const SubmitDataToServer = (type) => {
        const currencyCodeValue = currencyList?.find((Currency) => Currency?.value === currency)?.code;
        const globalSettingPayloadData = PayloadPreparationGlobalSetting({ "globalSetting": active_global_settings, currencyCodeValue });
        switch(trackComponentRendering[activeScreenNumber]){
            case 0 : {
                const screen1 = PayloadPreparationRevenueOnboardingScreen({ type: 0, "revenueOnboardingData": { ...revenueOnboardingData, projection_duration, product_category } });
                const PAYLOAD = { "global_settings": globalSettingPayloadData?.global_settings, "screenInput": screen1, "businessModelId": businessModelId }
                if(traceButtonType === "output"){
                    dispatch(API_Process_Output_Screen1_Data(PAYLOAD));
                    return;
                }
                dispatch(D_Update_Net_Payment_term_Data_Based_RO_Screen1({ "CHANNELS": revenueOnboardingData.channel_sales }))
                dispatch(API_Post_Screen1_Data(PAYLOAD))
                break;
            }
            case 1 : {
                // const screen1 = PayloadPreparationRevenueOnboardingScreen({ type: 0, "revenueOnboardingData": { ...revenueOnboardingData, projection_duration, product_category } });
                const screen2 = PayloadPreparationRevenueOnboardingScreen({ type: 1, "revenueOnboardingData": { ...revenueOnboardingData, projection_duration, product_category } });
                // const PAYLOAD_SCREEN1 = { "global_settings": globalSettingPayloadData?.global_settings, "screenInput": screen1, "businessModelId": businessModelId }
                const PAYLOAD_SCREEN2 = { "global_settings": globalSettingPayloadData?.global_settings, "screenInput": screen2, "businessModelId": businessModelId }
                if(traceButtonType === "output" || type==="stepperProcess"){
                    // dispatch(API_Process_Output_Screen1_Data(PAYLOAD_SCREEN1));
                    dispatch(API_Process_Output_Screen2_Data(PAYLOAD_SCREEN2));
                    return;
                }
                // dispatch(API_Post_Screen1_Data(PAYLOAD_SCREEN1))
                dispatch(API_Post_Screen2_Data(PAYLOAD_SCREEN2))
                break;
            }
            case 2 : {
                const screen3 = PayloadPreparationRevenueOnboardingScreen({ type: 2, "revenueOnboardingData": { ...revenueOnboardingData, projection_duration, product_category } });
                const PAYLOAD = { "screenInput": screen3, "businessModelId": businessModelId }
                if(traceButtonType === "output"){
                    dispatch(API_Process_Output_Screen3_Data(PAYLOAD));
                    return;
                }
                dispatch(API_Post_Screen3_Data(PAYLOAD))
                break;
            }
            case 3 : {
                const screen4 = PayloadPreparationRevenueOnboardingScreen({ type: 3, "revenueOnboardingData": { ...revenueOnboardingData, projection_duration, product_category, channel_catgeory:channel_name } });
                const PAYLOAD = { "screenInput": screen4, "businessModelId": businessModelId }
                if(traceButtonType === "output"){
                    dispatch(API_Process_Output_Screen4_Data(PAYLOAD));
                    return;
                }
                dispatch(API_Post_Screen4_Data(PAYLOAD))
                break;
            }
            case 4 : {
                const screen5 = PayloadPreparationRevenueOnboardingScreen({ type: 4, "revenueOnboardingData": { ...revenueOnboardingData, projection_duration, product_category } });
                const PAYLOAD = { "screenInput": screen5, "businessModelId": businessModelId }
                if(traceButtonType === "output"){
                    dispatch(API_Process_Output_Screen5_Data(PAYLOAD));
                    return;
                }
                dispatch(API_Post_Screen5_Data(PAYLOAD))
                break;
            }
        }
    }
    const isFormFilledAsExpected = (status) => {
        setIsFormFilled(status);
        if (!status) {
            if(traceButtonType==="output" || traceButtonType==="save"){
                // message.warning("Please fill out all mandatory fields to proceed", 2);
            }
            dispatch(D_Control_Rendering_Output(false));
            !VIEW_MODE && dispatch(toggleShowError(true));
        }else{
            if(!Boolean(refreshCounter)) return;
            if(traceButtonType==="output" && !VIEW_MODE){
                setWarningPopUpDetails((prev) => ({...prev, "traceButtonClicked": "process"}))
                SubmitDataToServer()
                return;
            }
            if(traceButtonType==="save" && !VIEW_MODE){
                setWarningPopUpDetails((prev) => ({...prev, "traceButtonClicked": "save"}))
                SubmitDataToServer();
                dispatch(D_Button_Controls({ type: "isSaveActive", value: false }));
                dispatch(D_Button_Controls({ type: "isGenerateOutputActive", value: true }));
                return;
            }
            if(activeScreenNumber===0 && !VIEW_MODE){
                // console.log("exe2")
                setWarningPopUpDetails((prev) => ({...prev, "traceButtonClicked": "process"}))
                SubmitDataToServer();
                return;
            }
            if(status && refreshCounter!==0){
                // dispatch(trackCounter({ type: "reset" }));
                // console.log("navigation manually");
                // dispatch(toggleScreen({ type: controlsType, totalNumberOfScreen: updatedFormData.length }));
                // dispatch(toggleShowError(false))
                fetchPreviousOrNextScreenData(controlsType);
            }
        }
    }
    React.useEffect(() => {
        if(stepperPredictionApiStatus===0) return
        if(stepperPredictionApiStatus>2){
            dispatch(D_Trace_StepperPredictionApiStatus(0));
            return;
        }
        SubmitDataToServer(stepperPredictionApiStatus===1 ? "stepperSave" : "stepperProcess")
    }, [stepperPredictionApiStatus])
    const handleStepperOnChange = (value) => {
        if(![1].includes(trackComponentRendering[activeScreenNumber])) return;
        setTraceButtonType("stepper")
        setRefreshCounter((prev) => prev+1);
        dispatch(D_Increement_SalesMargin({value}))
        dispatch(D_Trace_StepperPredictionApiStatus(1));
    }
    const filteredComponent = RevenueScreenComponents.filter((_, index) => trackComponentRendering.includes(index));
    const isLoading = loadingState.postLoading;
    const ACTIVE_SCREEN_NUMBER = trackComponentRendering[activeScreenNumber];
    const IS_SHOW_OUTPUT = traceHitSaveCount[`screen${ACTIVE_SCREEN_NUMBER}`] >= traceHitSaveCount[`screen${ACTIVE_SCREEN_NUMBER-1}`]
    const SHOULD_RENDER_OUTPUT = VIEW_MODE ? (!!output?.[ACTIVE_SCREEN_NUMBER] && ACTIVE_SCREEN_NUMBER!==0) : !!output?.[ACTIVE_SCREEN_NUMBER] && ACTIVE_SCREEN_NUMBER!==0 && isGenerateOutputActive===false 
    const arrayOfAllCatgegoryList = categoryList;
    return (
        <div className='pt-[5rem] flex flex-col gap-y-[24px]' ref={topScroll}>
            { loadingState.screenLoading || stepperPredictionApiStatus!==0 || SCREEN_DATA_FETCHING==="loading" || cost_screen_one_data_fetching==="loading" ? <PageLoader/> : null}
            {
                (warningPopUpDetails.isPopUpOpen && networkStatus) ? (
                    <OopsPopUp 
                        description1={fetchWarningPopupErrorMessage({ "buttonClickedName": warningPopUpDetails.traceButtonClicked })?.message?.description1} 
                        description2={fetchWarningPopupErrorMessage({ "buttonClickedName": warningPopUpDetails.traceButtonClicked })?.message?.description2} 
                        isOpen={warningPopUpDetails.isPopUpOpen} 
                        onClick={() => setWarningPopUpDetails((prev) => ({ traceButtonClicked: null, isPopUpOpen: false }))} 
                    />
                ) : null
            }
            <HeadingFour text={pageName} />
            <div className='px-[40px]'> <Stepper currentStage={activeScreenNumber} onboardingItems={trackComponentRendering} /> </div>
            {
                updatedFormData.map((screenForm, index) => {
                    const Component = filteredComponent[index];
                    if (index === activeScreenNumber) return (
                        <ComponentErrorBoundary>
                            <Component
                                counterToTriggerFormStatus={counter}
                                isFormFilledAsExpected={isFormFilledAsExpected}
                                form={screenForm} key={index}
                            />
                        </ComponentErrorBoundary>
                    )
                })
            }
            { (ACTIVE_SCREEN_NUMBER > 0 && ACTIVE_SCREEN_NUMBER!==5 && !VIEW_MODE) ? 
            <div className='flex justify-between py-[2rem]'>
                <div style={{ visibility : (VIEW_MODE ? ACTIVE_SCREEN_NUMBER!==5 : !!output?.[ACTIVE_SCREEN_NUMBER]) ? "hidden" : "visible" }}>
                    <SecondaryButton isDisabled={isLoading || ACTIVE_SCREEN_NUMBER===0} text={"Back"} onClick={() => handleToggleScreen({ type: "prev" })} />
                </div>
                <PrimaryButton isLoading={isLoading && controlsType==="save"} isDisabled={isLoading || !isSaveActive || VIEW_MODE} onClick={() => handleToggleScreen({ type: "save" })} text={"Save"} />
                <PrimaryButton isLoading={isLoading && controlsType==="output"} isDisabled={isLoading || !isGenerateOutputActive || VIEW_MODE} onClick={() => {
                    setTraceButtonType("output");
                    setIsControlType("output")
                    setRefreshCounter((prev) => prev+1);
                    dispatch(trackCounter({ type: "increement" }))
                }} text={"Generate Output"} />
            </div> : null 
            }
            {
                SHOULD_RENDER_OUTPUT
                ? <div ref={bottomScroll}>
                    <ComponentErrorBoundary>
                        <OutputScreen 
                            moduleType={"revenue"} 
                            catgoryListMap={categoryList}
                            category_list={arrayOfAllCatgegoryList}
                            isSummaryScreenLoading={IS_LOADING && ACTIVE_SCREEN_NUMBER===5} 
                            isStepperVisible={[1].includes(trackComponentRendering[activeScreenNumber])} 
                            stepperOnChange={handleStepperOnChange} type={"revenue_screen"} 
                            outputSliderValues={outputSliderValues} 
                            screenNumber={ACTIVE_SCREEN_NUMBER} 
                            outputData={ACTIVE_SCREEN_NUMBER===1 ? {...output[0],...output[1]} :output[ACTIVE_SCREEN_NUMBER]}
                        />
                    </ComponentErrorBoundary>
                </div> 
                : null
            }
            {
                ACTIVE_SCREEN_NUMBER===0 || (ACTIVE_SCREEN_NUMBER===5 ? true : !!output?.[ACTIVE_SCREEN_NUMBER] && IS_SHOW_OUTPUT && isSaveActive!==true && isGenerateOutputActive!==true) || VIEW_MODE
                ? <div className='flex justify-between py-[3rem]'>
                    <SecondaryButton isDisabled={VIEW_MODE ? !(ACTIVE_SCREEN_NUMBER!==0) : isLoading || ACTIVE_SCREEN_NUMBER===0} text={"Back"} onClick={() => {
                        handleToggleScreen({ type: "prev" });
                        if(EDIT_MODE){
                            navigate(`/product/home/business-modal/edit/${businessModelId}/revenue-screen`)
                            dispatch(D_Update_Onboarding_Screen_Id({"screenName": "revenue-screen", "value": trackComponentRendering[activeScreenNumber-1] }));
                        }
                    }} />
                    { ACTIVE_SCREEN_NUMBER === 0 ? <PrimaryButton isLoading={isLoading && controlsType==="save"} isDisabled={VIEW_MODE ? true : (isLoading || !isSaveActive)} onClick={() => handleToggleScreen({ type: "save" })} text={"Save"} /> : null }
                    <PrimaryButton isDisabled={(VIEW_MODE ? !VIEW_MODE : (isLoading || ACTIVE_SCREEN_NUMBER===0 ? isSaveActive :output[ACTIVE_SCREEN_NUMBER]===null)) || isLoading} isLoading={isLoading && controlsType==="next"} text={"Next"} onClick={() => {
                        if(!!output?.[ACTIVE_SCREEN_NUMBER]){
                            if(ACTIVE_SCREEN_NUMBER===4){
                                // if(!!output[5]===false && VIEW_MODE) return;
                                handleToggleScreen({ type: "next" });
                            }else{
                                handleToggleScreen({ type: "next" });
                            }
                        }
                        if(ACTIVE_SCREEN_NUMBER===0) handleToggleScreen({ type: "next" });
                        if(trackComponentRendering[activeScreenNumber+1]<=5){ // cross check logic is wrong here
                            if(EDIT_MODE){
                                navigate(`/product/home/business-modal/edit/${businessModelId}/revenue-screen`)
                                dispatch(D_Update_Onboarding_Screen_Id({"screenName": "revenue-screen", "value": trackComponentRendering[activeScreenNumber+1] }));
                            }
                        }
                    }} />
                </div>
            : null
            }
        </div>
    )
}
export default RevenueOnboarding
