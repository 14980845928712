import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const loader = (
    <LoadingOutlined
        style={{
            fontSize: 62,
            fontWeight: "bolder"
        }}
        spin
    />
);

const Loader = () => {
    return (
        <div className='absolute w-full h-full bg-black/20 top-0 left-0 z-10'>
            <div className='absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-[40%]'>
                <Spin indicator={loader} style={{ color: "#372EE5" }} />
            </div>
        </div>
    )
}

export default Loader