import React from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import SaveAsModal from './SaveAsModal'
import useICPViewTemplate from './hooks/useICPViewTemplate'
import ICPFormHeader from './components/Form/ICPFormHeader'
import ICPProfileDetail from './components/Form/ICPProfileDetail'
import RenderTitleListOfQuestion from './subcomponents/RenderTitleListOfQuestion'
import MessageNotification from '../reuseablecomponent/MessageNotification'
import PageSpinner from '../reuseablecomponent/PageSpinner'
import locals from "../../localization/localization_en_ideal_customer_profile.json"
import { updateICPCustomTextDataApi } from './ICPSlice'
import WarningLimitedResourceOopsPopup from '../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup'
import { paywall_locals } from '../../localization'
import useApp from '../../hooks/useApp'

const PAYWALL_LOCALS = paywall_locals.ideal_customer_profile;

const ICPViewFeatureVTwo = () => {

    const {
        icp,
        error,
        status,
        fetchStatus,
        modelIsOpen,
        showEdit,
        download_ref,
        isDownloading,
        isCustomTemplateOpen,
        isEditTemplateUserName,
        demographicOrFirmographic,
        active_template_type,
        TemplateName,

        data,
        Answer,
        profileImage,
        profileName,
        templateIcon,
        templateBgColor,
        templateName,
        workSpaceList,

        handleEditForm,
        handleSaveForm,
        handleDownloadPDF,
        handleImageUpload,
        handleShowEditIconUploadComponent,
        handleOnBlurProfileName,
        handleOnChangeInput,
        handleOnBlurInput,
        handleOnClickProfileName,
        handleTextareaKeyPress

    } = useICPViewTemplate();

    const { upgradeRenewPaywallPopup } = useApp();

    const dispatch = useDispatch();
    const permission = useSelector((state) => state.user.permission);

    const commonProps = {
        Answer,
        handleTextareaKeyPress,
        handleOnBlurProfileName,
        handleOnChangeProfileName: (e) => handleOnChangeInput(demographicOrFirmographic, "Persona Name", e.target.value, Answer?.[demographicOrFirmographic]?.["Persona Name"] || ''),
        "handleOnBlurForm": ({ AttributeName, FieldName, data }) => dispatch(updateICPCustomTextDataApi({ AttributeName, FieldName, data })),
        "handleInputChange": (metric, submetric, currValue, prevValue) => handleOnChangeInput(metric, submetric, currValue, prevValue)
    }

    return (
        <div className='pt-[5.5rem]'>
            {fetchStatus === "loading" && <PageSpinner />}
            {modelIsOpen && <SaveAsModal saveType={isCustomTemplateOpen ? "customSave" : "viewSave"} />}
            {(fetchStatus === "failed" || status === "failed") && error.length > 2 && <MessageNotification status={fetchStatus || status} content={error} type={"error"} />}
            <ICPFormHeader
                handleDownloadPDF={handleDownloadPDF}
            />
            {/* <pre>
                {JSON.stringify(data, null ,2)}
            </pre> */}
            <div ref={download_ref} className='flex flex-col divide-y-2 divide-primary'>
                <div className='py-[24px]'>
                    <ICPProfileDetail
                        profileImage={profileImage}
                        profileName={profileName}
                        templateIcon={templateIcon}
                        templateBgColor={templateBgColor}
                        templateName={templateName}
                        isCustomTemplateOpen={isCustomTemplateOpen}
                        isUploadOrImgComponent={isCustomTemplateOpen && !isDownloading}
                        isShowEditImgComponent={showEdit}
                        handleImageUpload={handleImageUpload}
                        handleShowEditIconUploadComponent={handleShowEditIconUploadComponent}
                        isEditTemplateUserName={isEditTemplateUserName}
                        handleOnBlurProfileName={handleOnBlurProfileName}
                        handleOnChangeProfileName={(e) => {
                            handleOnChangeInput(demographicOrFirmographic, "Persona Name", e.target.value, Answer?.[demographicOrFirmographic]?.["Persona Name"] || '')
                        }
                        }
                        handleOnClickProfileName={handleOnClickProfileName}
                    />
                </div>
                <div className='flex divide-x-2 divide-primary py-[24px] w-full'>
                    {
                        ["Demographics", "Firmographics", "Psychographics"].map((item, index) => {
                            if (item && item in (data || {})) {
                                return (
                                    <RenderTitleListOfQuestion
                                        isCustomTemplateOpen={isCustomTemplateOpen}
                                        isQuestionVisible
                                        key={index}
                                        {...commonProps}
                                        title={item}
                                        data={data[item]}
                                        className={["Demographics", "Firmographics"].includes(item) ? "pr-[56px]" : "pl-[56px]"}
                                        maxLength={500}
                                    />
                                )
                            }
                        })
                    }
                </div>
                {
                    ["Challenges & Pain Points", "Value Proposition", "Goals & Objectives", "Objections to Sale"].map((item, index) => {
                        if (item && item in (data || {})) {
                            return (
                                <RenderTitleListOfQuestion
                                    isCustomTemplateOpen={isCustomTemplateOpen}
                                    key={index}
                                    {...commonProps}
                                    title={item}
                                    data={data[item]}
                                    className={"gap-y-[8px] py-[24px]"}
                                    maxLength={1000}
                                />
                            )
                        }
                    })
                }
            </div>
            <div className={`${(isCustomTemplateOpen ? 'justify-center' : 'justify-between')} buttons flex my-[48px]`}>
                {!isCustomTemplateOpen && (
                    <WarningLimitedResourceOopsPopup
                        description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].no_edit.description1}
                        description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].no_edit.description2}
                    >
                        {({ handleTogglePopup, isFreeSubscription }) => {
                            return (
                                <Button disabled={!permission.edit} onClick={() => isFreeSubscription ? handleTogglePopup() : handleEditForm()} className='text-primary border border-primary'>{locals.ideal_customer_profile.direction[3]}</Button>
                            )
                        }}
                    </WarningLimitedResourceOopsPopup>
                )}
                <WarningLimitedResourceOopsPopup
                    description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].restrict_custom_template_one_b2b_b2c.description1}
                    description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].restrict_custom_template_one_b2b_b2c.description2}
                >
                    {({ handleTogglePopup, isFreeSubscription }) => {
                        return (
                            <Button disabled={!permission.edit} onClick={() => {
                                let present_template_type = active_template_type;
                                if(active_template_type === null){
                                    present_template_type = icp.find((temp) => temp.title === TemplateName).template_name
                                }
                                const isActiveTemplateAlreadtExists = workSpaceList.find((space) => space.actualTemplateType === present_template_type);
                                if(isFreeSubscription && isActiveTemplateAlreadtExists){
                                    handleTogglePopup(); 
                                    return;
                                }
                                handleSaveForm()
                            }} className='text-white bg-primary'>{locals.ideal_customer_profile.direction[2]}</Button>
                        )
                    }}
                </WarningLimitedResourceOopsPopup>
            </div>
        </div>
    )
}

export default ICPViewFeatureVTwo