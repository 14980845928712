import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import closeSvg from "../../../assets/common/close.svg";
import demoUser from "../../../assets/common/demoUser.svg";
import CustomCheckBox from "../../Forms/CheckBoxVarients/CustomCheckBox";
import SecondaryButton from '../../Buttons/SecondaryButton';
import PrimaryButton from '../../Buttons/PrimaryButton';
import ModalSection from "./ModalSection"
import { useDispatch, useSelector } from 'react-redux';
import { documentuserShareList, fetchAllInvestorApi } from '../../../features/dataRoomSlice';

const AccessInvestorModal = ({ isOpen, onClose, documentList }) => {
    const [usersSelected, setUsersSelected] = useState([]);
    const [modalOpen, setModalOpen] = useState(false); // No need to use isOpen directly
    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [investorList, setInvestorList] = useState([]);
    const dispatch = useDispatch();
    
    // Fetching all investors
    useEffect(() => {
        const fetchInvestors = async () => {
            try {
                const result = await dispatch(fetchAllInvestorApi());
                if (result.payload && result.payload.users) {
                    setInvestorList(result.payload.users);
                }
            } catch (error) {
                console.error('Error fetching investors:', error);
            }
        };
        
        fetchInvestors();
    }, [dispatch]);
    
    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);
    
    // Handle selecting/deselecting users
    const userHandler = (user) => {
        const isSelected = usersSelected.some((selectedUser) => selectedUser.id === user.id);
        if (isSelected) {
            setUsersSelected(usersSelected.filter((selectedUser) => selectedUser.id !== user.id));
        } else {
            setUsersSelected([...usersSelected, user]);
        }
    };
    
    // Modal close handlers
    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };
    
    const handleCancel = () => {
        setModalOpen(false);
        onClose();
    };
    
    // Handle opening different sections in modal
    const handleSubmit = () => {
        setModalOpen(false);
        setModalTitle("Access Info");
        setModalVisible(true);
    };
    
    const handleAddInvestor = () => {
        setModalOpen(false);
        setModalTitle("Edit Investor Details");
        setModalVisible(true);
    };
    
    return (
        <>
            {modalVisible && (
                <ModalSection title={modalTitle} closeHandler={() => { setModalVisible(false); handleCancel(); }} />
            )}
            <Modal
                title={<span style={{ fontSize: '18px' }}>Select one investor at a time</span>}
                centered
                visible={modalOpen} // Use visible instead of open
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
                    <img src={closeSvg} alt="Close" />
                </button>
                <div className="px-2 flex flex-col gap-3">
                    <div className="flex flex-col gap-4 max-h-[200px] overflow-y-auto hide-scrollbar mt-5">
                        {/* Display all investors */}
                        {investorList.map((user) => (
                            <div key={user.id} className="flex w-full p-2 justify-between items-center">
                                <div className="flex items-center justify-center flex-row gap-2">
                                    <img src={demoUser} alt="user" className="border mr-1" />
                                    <h1 className="text-[#424242] text-[16px] font-medium my-auto">{user.userName}</h1>
                                </div>
                                <CustomCheckBox
                                    isChecked={usersSelected.some((selectedUser) => selectedUser.id === user.id)}
                                    handleOnClick={() => userHandler(user)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="w-full flex flex-row justify-between mt-3">
                        <div>
                            <SecondaryButton text={"Add Investor"} onClick={handleAddInvestor} size="Medium" />
                        </div>
                        <div>
                            <PrimaryButton text={"Grant Access"} size="Medium" onClick={handleSubmit} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AccessInvestorModal;
