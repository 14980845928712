export const pdfColumnDataPreparation = (data) => {
    const columnNames = Object.keys(data[0]);
    const columnHeading = columnNames.slice(1);

    const dataToPrint = [];
    const MAX_SLICE = 3;

    data.forEach((subdata, index) => {
        const values = Object.values(subdata);
        const keys = Object.keys(subdata);
        let start = 1;
        let itr = 0;
        while (start < values.length) {
            const chunk = [values[0], ...values.slice(start, start + MAX_SLICE)];
            if (dataToPrint[itr] === undefined) {
                dataToPrint.push([{ columnHeading: [columnNames[0], ...columnHeading.slice(start - 1, start + MAX_SLICE - 1)], data: [chunk] }]);
            } else {
                dataToPrint[itr][0].data.push(chunk)
            }
            start += MAX_SLICE;
            itr++;
        }
    });

    return dataToPrint;
} 