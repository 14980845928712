import React from "react";
import "../pitchDeckStyle.css"
import NavigationTool from '../subcomponents/NavigationTool';
import FeatureDescribe from '../../../DesignSystem/TypographyComponent/FeatureDescribe';
import SlideShowControl from '../subcomponents/SlideShowControl';
import SlideCard from "../subcomponents/SlideCard"
import { slides } from "../data";
import { updateActivePeviewSlideShow } from "../pitchDeckSlice";
import { useDispatch, useSelector } from "react-redux"

const PreviewSlideShow = () => {

  const dispatch = useDispatch();
  const state = useSelector(state => state.pitchdeck);

  const toggleActiveSlide = (operationType) => dispatch(updateActivePeviewSlideShow({ type: operationType }));

  return (
    <div className='mainContainer fixed z-[1000] top-0 left-0 w-full h-screen bg-black/75 '>
      <div className='WrapperContents w-full h-screen flex flex-col justify-center items-center'>
        <div className='flex w-[80%]'>
          <FeatureDescribe headingColor='white' descriptionColor='#BDBDBD' heading={"Business Strategy Template"} description={"Some information content about the template will show here."} />
        </div>
        <div className='wrapper flex flex-col justify-between w-[80%] bg-white mt-[8px] relative'>
          <div className='ImageWrapper h-[77%] w-full flex justify-center items-center'>
            <div className="w-full h-full aspect-video">
              <img className="image w-full h-full aspect-video" src={slides[state.previewSlideShow.activeSlideNumber]} />
            </div>
          </div>
          <div className="Controls h-[calc(100%-77%)] flex flex-col justify-between">
            <div className="w-full h-[65%]">
              <SlideCard activeSlideNumber={state.previewSlideShow.activeSlideNumber} />
            </div>
            <div className="w-[full h-[35%]">
              <SlideShowControl handleShowActiveSlide={toggleActiveSlide} />
            </div>
          </div>
          <div className="absolute top-0 -right-[3rem]">
            <NavigationTool/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewSlideShow