import React from 'react'
import Locked from './Locked'
import StepNumber from './StepNumber'
import NestedCoreFeatures from './NestedCoreFeatures'
import ParentModuleActiveOrInActivePosition from "./ParentModuleActiveOrInActivePosition"


const SubParentComponent = ({ subParent, stepperProgressData }) => {
    if (subParent.locked) {
        return <div className='mt-[16px] mb-[24px]'><Locked cardBgColor={subParent.cardBgColor} cardBorder={subParent.cardBorder} /></div>
    }
    if (subParent?.sibling) {
        return <NestedCoreFeatures color={{cardBgColor:subParent.cardBgColor,cardBorder:subParent.cardBorder}} children={subParent?.sibling} />
    }
    return (
        <div>
            {
                subParent.children.map((subModule, i) => {
                    return (
                        <React.Fragment>
                            <div key={i} className=''>
                                <StepNumber subStepNumber={i+1} />
                                <ParentModuleActiveOrInActivePosition status={stepperProgressData?.steps[i]?.status} parentRefIndex={subModule.key} text={subModule.title} />
                                {
                                    subModule.locked
                                    ? <div className='my-[16px]'><Locked cardBgColor={subModule.cardBgColor} cardBorder={subModule.cardBorder} /></div>
                                    : <NestedCoreFeatures color={{cardBgColor:subParent.cardBgColor,cardBorder:subParent.cardBorder}} children={subModule.children} />
                                }
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}

export default SubParentComponent