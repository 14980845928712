import api from "../../services/api/api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { settings } from "./data";
import { validateEmail } from "../../utils/validation";
import routesConfig from "../../services/config/routesConfig.json"
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import { postUserActivityDataApi } from "../UserActivity/userActivitySlice";
import notificationLocals from "../../localization/localization.en_notification_message.json"

export const fetchProfileDataApi = createAsyncThunk(
    'setting/fetchProfileDataApi',
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'fetchProfileDetails');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const updateProfileDataApi = createAsyncThunk(
    'setting/updateProfileDataApi',
    async (_, { getState }) => {
        const state = getState();
        const customizeName = {
            ["fullName"]: state.setting["Profile Management"]["Full Name"],
            ["username"]: state.setting["Profile Management"]["Username"].split(" ").join(""),
            ["email"]: state.setting["Profile Management"]["Email"],
            ["avatar"]: state.setting["Profile Management"]["avatar"]
        }
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'profileDetails');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: customizeName }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const changePasswordProfileDataApi = createAsyncThunk(
    "setting/changePasswordProfileDataApi",
    async (data, { getState }) => {
        const state = getState();
        const customizePayloadName = {
            ["newPassword"]: state.setting["Password Management"]["New Password"],
            ["confirmPassword"]: state.setting["Password Management"]["Confirm New Password"],
            userId: data
        }
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'updatePassword');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: customizePayloadName }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const fetchUsersDataApi = createAsyncThunk(
    'setting/fetchUsersDataApi',
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'fetchUsers');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const patchUserDetailsDataApi = createAsyncThunk(
    'setting/patchUserDetailsDataApi',
    async (data, { dispatch }) => {
        const preparePayload = {
            userId: data._id,
            Name: data.username,
            Email: data.email,
            role: data.role.role
        }
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'updateUserDetail');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: preparePayload }
                const response = await api.request(urlConfig);
                dispatch(postUserActivityDataApi({
                    ...notificationLocals.notification_message.settings.editUserName,
                    "targetUserId":data._id 
                }));
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const toggleActiveStateOrDeleteUserDataApi = createAsyncThunk(
    "setting/toggleActiveStateOrDeleteUserDataApi",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'disableOrDeleteUser');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const fetchPermissionDataApi = createAsyncThunk(
    'setting/fetchPermissionDataApi',
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'permissions');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const updatePermissionDataApi = createAsyncThunk(
    'setting/updatePermissionDataApi',
    async (data, { getState }) => {
        const state = getState();
        const newPermissionAddedId = state.setting.track;
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'updatePermission');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: { updatedPermissions: state.setting.permissions, track: newPermissionAddedId } }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const addPaymentForUserApi = createAsyncThunk(
    'setting/addPaymentForUserApi',
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'payment', 'addPaymentForUser');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const initiatePaymentRequest = createAsyncThunk(
    'setting/initiatePaymentRequest',
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'initiatePayment');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const getPaymentList = createAsyncThunk(
    'setting/getPaymentList',
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'stripe', 'get-all-payment-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);


const settingSlice = createSlice({
    name: "setting",
    initialState: {
        number: false,
        fetchLoading: 'idle',
        loading: 'idle',
        success: "",
        showEdit: false,
        settingTabNumber: "account",
        AccountSettingData: settings["Account Settings"]["userInformation"],
        "Profile Management": {
            "Full Name": "",
            "Username": "",
            "Email": "",
            "avatar": "",
            isEmailValid: false
        },
        "Password Management": {
            "Current Password": "************",
            "New Password": "",
            "Confirm New Password": "",
            isValidPassword: false,
        },
        "payment_details":{
            "isloading": true,
            "isRedirected": false,
            "isInitiatePaymentModalOpen": false,
            "redirect_stripe_payment_link": null,
            "expires_at":null,
            "payment_list_status": { isLoading: 'idle', isError: null, data: [] }
        },
        userSettingModal: false,
        switchPasswordComponent: false,
        modalType: null,
        users: [],
        keepUserDetails: {},
        activeuserId: null,
        permissions: [],
        error: null,
        paymentCardsData: [],
        toggleDeleteModal: false,
        adminIsModalOpen: false,
        cancelSubcriptionModelOpen: false,
        deleteCardNumberIndex: null,
        paymentStatus: 'idle',
        track:{}
    },
    reducers: {
        trackStripePaymentRedirect(state, action){
            state.payment_details.isRedirected = action.payload;
        },
        handleDeletePaymentCard(state, action) {
            state.paymentCardsData = state.paymentCardsData.filter((_, i) => i !== state.deleteCardNumberIndex);
        },
        storeDeleteCardIndex(state, action) {
            state.deleteCardNumberIndex = action.payload;
        },
        appendNewPaymentCard(state, action) {
            state.paymentCardsData = [...state.paymentCardsData, action.payload]
        },
        toggleDeleteModal(state) {
            state.toggleDeleteModal = !state.toggleDeleteModal;
        },
        toogleAdminModal(state) {
            state.adminIsModalOpen = !state.adminIsModalOpen;
        },
        toggleCancelSubcriptionModelOpen(state) {
            state.cancelSubcriptionModelOpen = !state.cancelSubcriptionModelOpen;
        },
        changeFormFields(state, action) {
            state.AccountSettingData = state.number ? settings["Account Settings"]["passwordInformation"] : settings["Account Settings"]["userInformation"];
        },
        updateTextFields(state, action) {
            state[action.payload.type] = { ...state[action.payload.type], [action.payload.fieldName]: action.payload.data }
            if (action.payload.type === "Profile Management" && action.payload.fieldName === "Email") {
                const result = validateEmail(state["Profile Management"].Email);
                if (result) {
                    state["Profile Management"].isEmailValid = true;
                } else {
                    state["Profile Management"].isEmailValid = false;
                }
            }
            if (action.payload.type === "Password Management" && action.payload.fieldName === "Confirm New Password") {
                if (state["Password Management"]["New Password"] === state["Password Management"]["Confirm New Password"]) {
                    state["Password Management"].isValidPassword = true;
                } else {
                    state["Password Management"].isValidPassword = false;
                }
            }
        },
        handleUserInputChange(state, action) {
            const copyOfUsers = [...state.users];
            const updatedText = copyOfUsers.map((user) => {
                if (user._id === action.payload._id) {
                    return { ...user, [action.payload.fieldName]: action.payload.data }
                } else {
                    return user;
                }
            })
            state.users = updatedText;
        },
        toggleUserSettingModal(state, action) {
            state.userSettingModal = !state.userSettingModal;
        },
        showPasswordCompo(state, action) {
            state.switchPasswordComponent = action.payload;
        },
        togglePasswordComponent(state, action) {
            state.number = action.payload;//true
        },
        updateModalText(state, action) {
            state.modalType = action.payload;
        },
        resetDefaultValuesOnUnMount(state, action) {
            state["Password Management"] = {
                ...state["Password Management"],
                "New Password": "",
                "Confirm New Password": "",
                isValidPassword: false,
            }
        },
        keepUserDetailsData(state, action) {
            state.keepUserDetails = action.payload;
        },
        updateUserDetails(state, action) {
            const copyOfUsers = state.users;
            const updatedUser = copyOfUsers.map((user) => {
                if (user._id === action.payload?.data?._id) {
                    return action.payload.data;
                } else {
                    return user;
                }
            })
            state.users = updatedUser;
        },
        filterUser(state, action) {
            const copyOfUsers = state.users;
            const updatedUser = copyOfUsers.filter((user) => {
                if (user._id !== action.payload) {
                    return user;
                }
            })
            state.users = updatedUser;
        },
        resetLoading(state) {
            state.loading = 'idle';
        },
        updateuserId(state, action) {
            state.activeuserId = action.payload;
        },
        updatePermission(state, action) {
            const copyOfPermission = state.permissions;
            const updatedPermission = copyOfPermission.map((permission) => {
                if (permission._id === action.payload.id) {
                    return { ...permission, [action.payload.fieldName]: !permission[action.payload.fieldName] }
                } else {
                    return permission;
                }
            })
            state.permissions = updatedPermission;
        },
        uploadAvatar(state, action) {
            state["Profile Management"].avatar = action.payload;
        },
        toggleShowEdit(state, action) {
            state.showEdit = action.payload;
        },
        updateSettingTabNumber(state, action) {
            state.settingTabNumber = action.payload;
        },
        resetStatus(state, action) {
            state.error = null;
            state.success = null;
            state.loading = 'idle';
        },
        trackNewPermissionAdded(state, action) {
            const { docId, permissionName } = action.payload;
            const track = state.track;
            if (track[docId] !== undefined) {
                let permission = track[docId];
                if (permission[permissionName] !== undefined) {
                    delete permission[permissionName];
                    if (Object.keys(permission).length > 0) {
                        state.track = {...state.track, [docId]: permission}
                    } else {
                        const copyofTrack = { ...track };
                        delete copyofTrack[docId];
                        state.track = copyofTrack
                    }
                } else {
                    permission = { ...permission, [permissionName]: 1 }
                    state.track = {...state.track, [docId]: permission}
                }
            } else {
                state.track = {...state.track, [docId]: {[permissionName]:1}}
            }
        },
        resetUserFilledPassword(state, action){
            state["Password Management"] = { "Current Password": "************", "New Password": "", "Confirm New Password": "", isValidPassword: false }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfileDataApi.pending, (state, action) => {
                state.fetchLoading = 'pending';
            })
            .addCase(fetchProfileDataApi.fulfilled, (state, action) => {
                state.fetchLoading = 'success';
                const data = action.payload.data;
                console.log(data, "test")
                let customizeName = {
                    ["Full Name"]: data["fullName"],
                    ["Username"]: data["username"],
                    ["Email"]: data["email"],
                    ["avatar"]: data["avatar"],
                    isEmailValid: true
                }
                console.log(customizeName)
                state.showEdit = data["avatar"].length > 0 ? false : true;
                state["Profile Management"] = { ...state["Profile Management"], ...customizeName };
            })
            .addCase(fetchProfileDataApi.rejected, (state, action) => {
                state.fetchLoading = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateProfileDataApi.pending, (state, action) => {////////////////////////////////
                state.loading = 'pending';
            })
            .addCase(updateProfileDataApi.fulfilled, (state, action) => {
                state.loading = 'success';
                state["Profile Management"] = { ...state["Profile Management"], Username: action.payload.username }
                state.success = action.payload.success;
                //logic
            })
            .addCase(updateProfileDataApi.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(changePasswordProfileDataApi.pending, (state, action) => {
                state.loading = 'pending';
            })
            .addCase(changePasswordProfileDataApi.fulfilled, (state, action) => {//////////////
                state.loading = 'success';
                state["Password Management"] = { ...state["Password Management"], "New Password": "", "Confirm New Password": "", isValidPassword: false }
                state.success = action.payload.success;
            })
            .addCase(changePasswordProfileDataApi.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchUsersDataApi.pending, (state, action) => {
                state.fetchLoading = 'pending';
            })
            .addCase(fetchUsersDataApi.fulfilled, (state, action) => {
                state.fetchLoading = 'success';
                state.users = action.payload.users;
            })
            .addCase(fetchUsersDataApi.rejected, (state, action) => {
                state.fetchLoading = 'failed';
                state.error = action.error.message;
            })
            .addCase(patchUserDetailsDataApi.pending, (state, action) => {
                state.loading = 'pending';
            })
            .addCase(patchUserDetailsDataApi.fulfilled, (state, action) => {
                state.loading = 'success';
                state.success = action.payload.success;
            })
            .addCase(patchUserDetailsDataApi.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(toggleActiveStateOrDeleteUserDataApi.pending, (state, action) => {
                state.loading = 'pending';
            })
            .addCase(toggleActiveStateOrDeleteUserDataApi.fulfilled, (state, action) => {
                state.loading = 'success';
            })
            .addCase(toggleActiveStateOrDeleteUserDataApi.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPermissionDataApi.pending, (state, action) => {
                state.fetchLoading = 'pending';
            })
            .addCase(fetchPermissionDataApi.fulfilled, (state, action) => {
                state.fetchLoading = 'success';
                const data = action.payload.data;
                const formattedData = data.map((userPermission) => {
                    return {
                        _id: userPermission.permission._id,
                        userId: userPermission.permission.userId,
                        username: userPermission.username,
                        email: userPermission.email,
                        edit: userPermission.permission.edit,
                        download: userPermission.permission.download,
                        paint: userPermission.permission.paint,
                        delete: userPermission.permission.delete
                    }
                })
                state.permissions = formattedData;
            })
            .addCase(fetchPermissionDataApi.rejected, (state, action) => {
                state.fetchLoading = 'failed';
                state.error = action.error.message;
            })
            .addCase(addPaymentForUserApi.pending, (state, action) => {
                state.paymentStatus = 'pending';
            })
            .addCase(addPaymentForUserApi.fulfilled, (state, action) => {
                state.paymentStatus = 'success';
            })
            .addCase(addPaymentForUserApi.rejected, (state, action) => {
                state.paymentStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(updatePermissionDataApi.pending, (state, action) => {
                state.loading = 'pending';
            })
            .addCase(updatePermissionDataApi.fulfilled, (state, action) => {/////////
                state.loading = 'success';
                state.success = action.payload.success;
                state.track = {}
            })
            .addCase(updatePermissionDataApi.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(initiatePaymentRequest.pending, (state, action) => {
                state.payment_details.isloading = 'pending';
            })
            .addCase(initiatePaymentRequest.fulfilled, (state, action) => {/////////
                state.payment_details.isloading = 'success';
                const { expires_at, url } = action.payload.checkoutSession;
                state.payment_details = { ...state.payment_details, expires_at, redirect_stripe_payment_link: url }
            })
            .addCase(initiatePaymentRequest.rejected, (state, action) => {
                state.payment_details.isloading = 'failed';
                state.error = action.error.message;
            })
            .addCase(getPaymentList.pending, (state, action) => {
                state.payment_details.payment_list_status = { ...state.payment_details.payment_list_status, isLoading:'pending', isError: null }
            })
            .addCase(getPaymentList.fulfilled, (state, action) => {
                const { paymentList } = action.payload;
                state.payment_details.payment_list_status = { ...state.payment_details.payment_list_status, isLoading:'success', data: paymentList }
            })
            .addCase(getPaymentList.rejected, (state, action) => {
                state.payment_details.payment_list_status.isLoading = 'rejected'
                state.payment_details.payment_list_status.isError = action.error.message;
            })
    },
});

export const { updateSettingTabNumber, handleDeletePaymentCard, storeDeleteCardIndex, appendNewPaymentCard, toogleAdminModal, toggleDeleteModal, toggleCancelSubcriptionModelOpen, toggleShowEdit, uploadAvatar, updatePermission, resetDefaultValuesOnUnMount, filterUser, updateuserId, resetLoading, updateUserDetails, keepUserDetailsData, updateModalText, showPasswordCompo, togglePasswordComponent, toggleUserSettingModal, handleUserInputChange, changeFormFields, updateTextFields, resetStatus, trackNewPermissionAdded, trackStripePaymentRedirect, resetUserFilledPassword } = settingSlice.actions;
export default settingSlice.reducer;