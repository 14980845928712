export const refund_policy = {
    "content": `
        <h2>Refund Policy</h2>
        <div>
            <h3>Payment and Billing</h3><br/>
            <p><b>Subscription Plans:</b> When subscribing to our SaaS product, you are required to pay the fees as published on our pricing page at the time of subscription.</p><br/>
            <p><b>Billing Cycle:</b> Your subscription will be billed in advance at the beginning of each monthly billing period and will automatically renew at the end of each period.</p><br/>
            <p><b>Payment Method:</b> Payments are processed through our authorized payment service provider, subject to their terms of use and privacy policies.</p><br/>
            <p><b>Taxes:</b> All fees are exclusive of applicable taxes, levies, or duties. You are responsible for payment of all such taxes, levies, or duties.</p><br/>
        </div>
        <div>
            <h3>Refunds</h3><br/>
            <p><b>Eligibility:</b> Refunds can only be requested after completing 3 full monthly billing cycles.</p><br/>
            <p><b>Non-refundable Period:</b> The service is non-refundable for the first 3 months of subscription.</p><br/>
            <p><b>No Partial Refunds:</b> There will be no refunds or credits for partial months of service or for months unused with an open account.</p><br/>
            <p><b>Process:</b> To request a refund, eligible customers must contact our support team via our support email address at <a class="primary_color" href="mailto:support@predictgrowth.ai">support@predictgrowth.ai</a>.</p><br/>
            <p><b>Approval:</b> All refund requests are subject to review and approval by our team.</p><br/>
            <p><b>Refund Amount:</b> If approved, refunds will be prorated based on the unused portion of the current billing cycle.</p><br/>
            <p><b>Refund Method:</b> Refunds will be issued to the original payment method used for the subscription.</p><br/>
            <p><b>Account Termination:</b> Requesting a refund will result in the cancellation of your subscription and termination of service.</p><br/>
            <p><b>Refund Processing:</b> Once refund is approved, you will be notified by email, and <u>the refund will be processed within 30 days</u> from the approval email being sent.</p><br/>
        </div>
        <div>
            <h3>Upgrading and Downgrading</h3><br/>
            <p><b>Upgrade Billing:</b> If you upgrade your plan, your existing plan will end, and you will be billed for the new plan immediately upon upgrading.</p><br/>
            <p><b>Proration:</b> When changing plans, our payment service provider will prorate the subscription costs by default.</p><br/>
            <p><b>Data Preservation:</b> All your account data will be preserved when upgrading.</p><br/>
            <p><b>Downgrade Risks:</b> Downgrading your service may result in the loss of content, features, or capacity of your account. We do not accept any liability for such loss.</p><br/>
            <p><b>Automatic Charge:</b> For any upgrade or downgrade in plan level, your provided payment method will automatically be charged the new rate on your next billing cycle.</p><br/>
        </div>
        <div>
            <h3>General Provisions</h3><br/>
            <p><b>Rate Changes:</b> We reserve the right to determine, establish, or modify monthly and annual rates or one-time fees for the use of the Service.</p><br/>
            <p><b>Equal Treatment:</b> To ensure fairness, no exceptions will be made to this refund policy.</p><br/>
            <p><b>Policy Updates:</b> This refund policy may be updated from time to time. Please check our website for the most current version.</p><br/>
        </div>
        <p>For any questions or concerns regarding our refund policy, please contact our customer support team via our support email address at <a class="primary_color" href="mailto:support@predictgrowth.ai">support@predictgrowth.ai</a>.</p>
    `
}