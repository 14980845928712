import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import TextExtraLarge from '../../../DesignSystem/TypographyDescriptions/TextExtraLarge';
import HeadingFour from '../../../DesignSystem/TypographyHeadings/HeadingFour';
import HeadingFive from '../../../DesignSystem/TypographyHeadings/HeadingFive';
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium';
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';

const LinkText = ({ text, icon, className }) => {
    return (
        <div className={`flex items-center gap-x-[8px] ${className}`}>
            <TextLarge text={text} color='#757575' fontWeight={500} />
            {icon}
        </div>
    )
}

const NavigationButton = ({
    noOfItems,
    arrayIndex,
    prevQuestion,
    nextQuestion,
    handlePrevButton,
    handleNextButton,
    finishOrNext
}) => {
    return (
        <div className={`flex justify-between w-full border border-baseGray400 py-[40px] rounded-md ${arrayIndex!=0 ? 'divide-x' : ''}`}>
            <div
                className='w-1/2 pl-[40px] pr-[20px]'
                style={{
                    visibility: arrayIndex === 0 ? "hidden" : "visible"
                }}
            >
                <button onClick={handlePrevButton} className='mb-[16px]'>
                    <LinkText text={"Previous"} icon={<ArrowBackIosIcon className='text-[#757575]' />} className={'flex-row-reverse'} />
                </button>
                <HeadingFive text={prevQuestion} />
            </div>
            <div className='w-1/2 flex flex-col items-end pl-[20px] pr-[40px]'>
                <button onClick={handleNextButton} className='mb-[16px]'>
                    <LinkText text={finishOrNext ? "Finish" : "Next"} icon={<ArrowForwardIosIcon className='text-[#757575]' />} />
                </button>
                <HeadingFive text={nextQuestion} />
            </div>
        </div>
    )
}

export default NavigationButton