import React from 'react'
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium'
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton'

const DescriptionWithButton = ({ description, buttonName, onClick }) => {
    return (
        <div className='border rounded-md p-3 flex flex-col gap-y-[1rem]'>
            <div className='mx-auto'>
                <TextMedium text={description} fontWeight={500} />
            </div>
            <div className='mx-auto'>
                <PrimaryButton text={buttonName} onClick={onClick} size='small' />
            </div>
        </div>
    )
}

export default DescriptionWithButton