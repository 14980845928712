const cleanPayloadData = (data) => {
    return data.map((row) => {
        let appendNullToZero = {}
        const { key, deps, ...extractedData } = row;
        for(let property of Object.entries(extractedData)){
            appendNullToZero = { ...appendNullToZero, [property[0]]: property[1]!==null ? property[1] : 0 }
        }
        return appendNullToZero;
    })
}

export const NetworkPayloadPreparation = ({ screenNumber, businessModelId, costScreenData, formItem }) => {
    switch (screenNumber) {
        case 0: {
            const month_year = costScreenData.revenue_onboarding_marketing_spend_details.month_year.split('-');
            const first_month = Number(month_year[1])
            const first_year =  Number(month_year[0])
            let marketing_spend = costScreenData["marketing_spend"].slice(costScreenData.revenue_onboarding_marketing_spend_details.marketing_spend_data_length)
            if(marketing_spend.length>0){
                marketing_spend = marketing_spend.map((subData) => {
                    if(costScreenData.marketing_spend_span_type==="last_six_months"){
                        return {
                            "dbid": subData?.dbid,
                            "name": subData.name,
                            "month": subData.month,
                            "year": subData.year,
                            "last_six_months": subData.last_six_months.map((data) => ({ ...data, "spend_value": data.spend_value===null ? 0 : data.spend_value}))
                        }
                    }else{
                        return {
                            "dbid": subData?.dbid,
                            "name": subData.name,
                            "month": subData.month,
                            "year": subData.year,
                            "last_month_marketing_spend": subData.last_month_marketing_spend===null ? 0 : subData.last_month_marketing_spend,
                            "mom_spend_growth_percent": subData.mom_spend_growth_percent===null ? 0 : subData.mom_spend_growth_percent
                        }
                    }
                })
            }
            return {
                businessModelId,
                "screenInput": {
                    "first_month": first_month,
                    "first_year": first_year,
                    "marketing_spend_per_sales_channel": marketing_spend,
                    "cost_incurred_for_user_through_marketing": cleanPayloadData(costScreenData["cost_incurred_for_user_through_marketing"])
                    // "marketing_spend_per_sales_channel": {
                    //     "type": costScreenData.marketing_spend_span_type,
                    //     "data": marketing_spend
                    // },
                }
            }
        }
        case 1: {
            return {
                businessModelId,
                "screenInput": {
                    "incurring_direct_cost":cleanPayloadData(costScreenData["incurring_direct_cost"]),
                    "return_cancellation_chargeable_percentage":cleanPayloadData(costScreenData["return_cancellation_chargeable_percentage"])
                }
            }
        }
        case 2: {
            const facilities_own_operation = ["default", "other", ...costScreenData["facilities_own_operation"]]
            const capacity_planning = costScreenData["capacity_planning"].filter((d) => facilities_own_operation.includes(d.deps))
            const cost = costScreenData["cost"].filter((d) => facilities_own_operation.includes(d.deps))
            
            
            return {
                businessModelId, 
                "screenInput": { 
                    "facilities_own_operation":costScreenData["facilities_own_operation"], 
                    "capacity_planning":cleanPayloadData(capacity_planning), "cost":cleanPayloadData(cost)
                }
            }
        }
        case 3: {
            return { 
                businessModelId, 
                "screenInput": { 
                    "personnel_tl_ratio":costScreenData["personnel_tl_ratio"]===null ? 0 : costScreenData["personnel_tl_ratio"], 
                    "undervied_costs_personnal_diff_department":cleanPayloadData(costScreenData["undervied_costs_personnal_diff_department"]), 
                    "dervied_costs_personnal_diff_department":cleanPayloadData(costScreenData["dervied_costs_personnal_diff_department"]) 
                } 
            }
        }
        case 4: {
            return { 
                businessModelId, 
                "screenInput": { 
                    "currently_incurring_g_and_a_cost":cleanPayloadData(costScreenData["currently_incurring_g_and_a_cost"])
                } 
            }
        }
        case 5: {
            return { 
                businessModelId, 
                "screenInput": { 
                    "capex_cost":cleanPayloadData(costScreenData["capex_cost"]) 
                } 
            }
        }
    }
}