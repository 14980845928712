export const COLOR_PLATE = [
    '#FD91D5',
    '#FF7B70',
    '#5CD0A4',
    '#FFD959',
    '#F5487F',
    '#5F59F7',
    '#785831',
    '#C1173A',
    '#D0E680',
    '#F86200',
    '#848973',
    '#B4B1C6',
    '#75E8E7',
    '#FAE5D3',
    '#1F708D',
    '#1F306E',
    '#1F306E',
    '#FFBD00',
    '#F8049C',
    '#FFADB9'
];