import { configureStore } from '@reduxjs/toolkit'
import appReducer from "./features/appSlice"
import userReducer from './features/userSlice'
import onboardingReducer from './features/onboardingSlice'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import manageLinkReducer from './features/manageLinkSlice';
import psmReducer from './features/psmSlice';
import bingoReducer from './features/BingoSlice';
import targetMarketAnalysisReducer from './features/targetmarketanalysisSlice';
import competitorReducer from './components/compitatoranalysis/CompetitorSlice';
import spamReducer from './features/spamSlice';
import buildBusinessCanvasReducer from './components/buildbusinesscanvas/buildbusinesscanvasSlice';
import idealCustomerProfileReducer from './components/idealcustomerprofile/ICPSlice';
import promptReducer from './components/AIService/promptSlice';
import settingReducer from './components/userSetting/settingSlice';
import useractivityReducer from './components/UserActivity/userActivitySlice';
import stepperProgressReducer from "./components/maindashboardstepper/stepperProgressSlice";
import paintReducer from "./components/reuseablecomponent/PaintSlice"; 
import pitchDeckReducer from './components/PitchDeck/pitchDeckSlice';
import revenueOnboardingReducer from "./components/BusinessModal/revenueSlice";
import costOnboardingReducer from "./components/BusinessModal/costSlice";
import globalSettingReducer from "./components/BusinessModal/globalSettingSlice";
import businessModalReducer from "./components/BusinessModal/businessModalSlice";
import leanCanvasReducer from "./components/leanStartupCanvas/leanstartupcanvasSlice";
import {persistReducer,FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import workingCapitalReducer from './components/BusinessModal/workingCapitalSlice';
import debtWorkingReducer from './components/BusinessModal/debtWorkingSlice';
import cashFlowReducer from './components/BusinessModal/cashFlowSlice';
import balanceSheetReducer from './components/BusinessModal/balanceSheetSlice';
import pAndLReducer from './components/BusinessModal/pAndLSlice';
import gtmReducer from './components/GTM/gtmSlice';
import chatReducer from './components/Chat/chatSlice';

export const RESET_STATE = 'RESET_STATE';

const persistConfig = {
    key: 'counter',
    version: 1,
    storage,
    stateReconciler: autoMergeLevel2 // makes deep comparison and adds newly defined state properties
};

const reducers = combineReducers({ 
    app:appReducer,
    chat: chatReducer,
    user: userReducer, 
    onboarding: onboardingReducer, 
    link: manageLinkReducer,
    psm: psmReducer, 
    bingo: bingoReducer, 
    tma: targetMarketAnalysisReducer,
    competitor: competitorReducer,
    spam:spamReducer,
    buildbusinesscanvas:buildBusinessCanvasReducer,
    leanstartupcanvas:leanCanvasReducer,
    icp:idealCustomerProfileReducer,
    prompt:promptReducer,
    setting:settingReducer,
    useractivity:useractivityReducer,
    stepperprogress:stepperProgressReducer,
    paint:paintReducer,
    pitchdeck:pitchDeckReducer,
    businessModal:businessModalReducer,
    revenueOnboarding:revenueOnboardingReducer,
    costScreen:costOnboardingReducer,
    globalSetting:globalSettingReducer,
    workingCapital: workingCapitalReducer,
    debtWorking: debtWorkingReducer,
    cashFlow: cashFlowReducer,
    balanceSheet: balanceSheetReducer,
    pAndL: pAndLReducer,
    gtm: gtmReducer
});

const rootReducer = (state, action) => {
    // When the reset state action is dispatched, reset the state of all reducers to their initial state
    if (action.type === RESET_STATE) {
        return reducers(undefined, action);
    }

    return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export default store
