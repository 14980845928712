import { Pie } from '@ant-design/plots';
import { COLOR_PLATE } from '../color-palette';

const PieChartAntD = ({
    data,
    colors,

    dataIndexName = "value",
    colorFieldDataIndex = "category",
    offsetDistanceBetweenLabelAndSlice = 10,
    chartPadding = 0,

    animation=true,

    legendPosition = "bottom-left"
}) => {
    const total = data.reduce((sum, entry) => sum + entry.value, 0);
    const config = {
        appendPadding: chartPadding,
        data,
        angleField: dataIndexName,
        colorField: colorFieldDataIndex,
        color: data?.length <= colors?.length ? colors : COLOR_PLATE,
        radius: 0.9,
        label: {
            type: 'outer',
            offset: `${offsetDistanceBetweenLabelAndSlice}%`,
            content: (text, item) => Math.round(text.percent * 100)+"%",
            style: {
                textAlign: 'center',
                fontSize: 12,
            },
            autoRotate: true,
        },
        tooltip: {},
        interactions: [
            {
                type: 'element-active',
            },
        ],
        legend: {
            layout: 'horizontal',
            position: legendPosition
        },
    };
    return <Pie {...config} animation={animation} />;
}

export default PieChartAntD