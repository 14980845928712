import api, { setBearerToken } from "../../services/api/api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateAccessToken } from "../../features/userSlice";
import * as apiEndpoints from '../../services/endPoints/idealCustomerProfile';
import locals from "../../localization/localization_en_ideal_customer_profile.json"
import routesConfig from "../../services/config/routesConfig.json"
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";

const defaultValues = locals.ideal_customer_profile.template_default_value;
const defaultCustomTemplateValues = locals.ideal_customer_profile.defaultCustomTemplateValues;
const defaultCustomB2BTemplateValues = locals.ideal_customer_profile.defaultCustomB2bTemplateValues;
const defaultCustomB2CTemplateValues = locals.ideal_customer_profile.defaultCustomTemplateValues;

const icp = locals.ideal_customer_profile.idealCustomerProfile;

export const fetchICPDraftDataApi = createAsyncThunk(
    'icp/fetchICPDraftDataApi',
    async (_, { getState, dispatch }) => {
        const state = getState();
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'draft');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

export const getAllWorkSpaceDataApi = createAsyncThunk(
    'icp/getAllWorkSpaceDataApi',
    async (_, { getState, dispatch }) => {
        const state = getState();
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'fetchAllActiveWorkspace');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

export const postingDefaultAnsICPDataApi = createAsyncThunk(
    'icp/postingDefaultAnsICPDataApi',
    async (_, { getState, dispatch }) => {
        const state = getState();
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'createDefaultAnswer');
            if (!!urlGenerator) {
                const urlConfig = {
                    ...urlGenerator, data: {
                        "TemplateName": state.icp.TemplateName,
                        "defaultValue": defaultValues[state.icp.TemplateName],
                    }
                }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

export const fetchingAndPostingCustomICPDataApi = createAsyncThunk(
    'icp/fetchingAndPostingCustomICPDataApi',
    async (data, { getState, dispatch }) => {
        try {
            const state = getState();
            let docId;
            if (data.type === "workspace") {
                docId = state.icp.activeDocId;
            } else {
                // docId = state.icp.draftICP["Add New Template"].docId;
                docId = state.icp.draftICP[data.templateType].docId;
            }
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'handleCustomTemplate');
            let urlWithOrWithoutParams = urlGenerator.url;
            if (docId) {
                urlWithOrWithoutParams = urlWithOrWithoutParams + docId;
            }
            const payload = docId ? {} : {
                isNew: true,
                "customTemplateType": data.templateType,
                defaultValues: data.templateType==="Custom B2C Template" ? defaultCustomB2CTemplateValues : defaultCustomB2BTemplateValues,
                draftId: state.icp.draftICP._id,
            };
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, url: urlWithOrWithoutParams, data: payload }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

export const fetchAnsICPDataApi = createAsyncThunk(
    'icp/fetchAnsICPDataApi',
    async (_, { getState, dispatch }) => {
        const state = getState();
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'fetchICPAnswer');
            const urlWithParams = urlGenerator.url.replace(':templateans',state.icp.activeDocId)
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, url: urlWithParams }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

export const updateICPTextDataApi = createAsyncThunk(
    'icp/updateICPTextDataApi',
    async (data, { getState, dispatch }) => {
        const state = getState();
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'patchTemplateData');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: { 
                    ...data,
                    AttributeName: state.icp.items[state.icp.tabNumber].label,
                    activeDocId: state.icp.commingFrom === "dashboardCard" ? state.icp.draftICP[state.icp.TemplateName].docId : state.icp.activeDocId,
                } }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

export const updateICPCustomTextDataApi = createAsyncThunk(
    'icp/updateICPCustomTextDataApi',
    async (data, { getState, dispatch }) => {
        const state = getState();
        try {
            let id;
            const TemplateType = state.icp.active_template_type==="b2b" ? "Custom B2B Template" : "Custom B2C Template"
            if (state.icp.differentiateTemplate) {
                id = state.icp.draftICP[TemplateType].docId;
            } else {
                id = state.icp.activeDocId;
            }
            id = id === null ? state.icp.activeDocId : id;
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'patchTemplateData');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: {
                    ...data,
                    activeDocId: id
                } }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

export const updateWorkSpaceSaveICPApi = createAsyncThunk(
    'icp/updateWorkSpaceSaveICPApi',
    async (data, { getState, dispatch }) => {
        const state = getState();
        const TemplateType = state.icp.active_template_type==="b2b" ? "Custom B2B Template" : "Custom B2C Template"
        try {
            let payload;
            if (data.type === "editSave") {
                payload = {
                    draftId: state.icp.draftICP._id,
                    attrName: state.icp.TemplateName,
                    activeDocId: state.icp.activeDocId,
                    workSpaceName: data.workSpaceName,
                    saveType: data.type,
                    "actualTemplateType": state.icp.active_template_type
                };
            } else if (data.type === "viewSave") {
                payload = {
                    defaultValue: defaultValues[state.icp.TemplateName],
                    TemplateName: state.icp.TemplateName,
                    workSpaceName: data.workSpaceName,
                    "actualTemplateType": state.icp.active_template_type
                };
            } else {
                payload = {
                    workSpaceName: data.workSpaceName,
                    activeDocId: state.icp.draftICP[TemplateType].docId,
                    draftId: state.icp.draftICP._id,
                    saveType: data.type,
                    "actualTemplateType": state.icp.active_template_type
                };
            }
            if(payload.actualTemplateType === null){
                payload = {
                    ...payload,
                    "actualTemplateType": icp.find((temp) => temp.title === state.icp.TemplateName).template_name
                }
            }
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'saveWorkspace');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: {...payload, "templateType": TemplateType} }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            console.log(error, "error")
            throw new Error(error.response.data.msg);
        }
    }
);

export const deleteWorkSpaceApi = createAsyncThunk(
    'icp/deleteWorkSpaceApi',
    async (id, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'deleteWorkSpace');
            const urlWithParams = urlGenerator.url.replace(':deleteid',id)
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, url: urlWithParams }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

export const uploadICPAvatarApi = createAsyncThunk(
    'icp/uploadICPAvatar',
    async (data, { getState, dispatch }) => {
        const state = getState();
        try {
            let id;
            const TemplateType = state.icp.active_template_type==="b2b" ? "Custom B2B Template" : "Custom B2C Template"
            if (state.icp.differentiateTemplate) {
                id = state.icp.draftICP[TemplateType].docId;
            } else {
                id = state.icp.activeDocId;
            }
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'uploadAvatar');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data:{ data, id } }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

export const updateICPTemplateNameApi = createAsyncThunk(
    'icp/updateICPTemplateNameApi',
    async (data, { getState }) => {
        const state = getState();
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'idealCustomerProfile', 'update-template-name');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.msg);
        }
    }
);

const INITIAL_STATE = {
    avatar: null,
    showEdit: false,
    featurePosition: null,
    defaultData: null,
    actualData: null,
    tabNumber: null,
    Questions: null,
    TemplateName: null,
    editDataFormDetails: null,
    items: null,
    Answer: null,
    activeDocId: null,
    draftICP: null,
    AnswerStatus: 'idle',
    DraftStatus: 'idle',
    modelIsOpen: false,
    workSpace: null,
    commingFrom: null,
    isCustomTemplateOpen: false,
    customDocId: null,
    isSearchOpen: false,
    differentiateTemplate: null,
    status: 'idle',
    workspacestatus: 'idle',
    error: null,
    fetchStatus: 'idle',
    savemodel: 'idle',
    TemplateNameToDownload: "",
    newSaveModal: 'idle',
    isWorkSpaceHasName: "",
    saveTemplate: "idle",
    postEditDataErr: null,
    postViewEditDataErr: null,
    "patchTemplateNameStatus": {
        "status": "idle",
        "error": null
    },
    "active_template_type": null
}

const idealCustomerProfileSlice = createSlice({
    name: "icp",
    initialState: INITIAL_STATE,
    reducers: {
        updateActiveTemplateType(state, action){
            state.active_template_type = action.payload;
        },
        resetAnswerData(state,action){
            state.Answer = null
        },
        resetError(state, action) {
            state.postViewEditDataErr = null;
            state.postEditDataErr = null;
        },
        updateIsWorkSpaceHasName(state, action) {
            state.isWorkSpaceHasName = action.payload;
        },
        updateSaveModal(state, action) {
            state.savemodel = 'idle';
        },
        updateTemplateNameToDownload(state, action) {
            state.TemplateNameToDownload = action.payload;
        },
        updateEditFeature(state, action) {
            const editDataFormDetails = icp.find((feature, index) => {
                if (index === action.payload.featureIndex) {
                    return feature;
                }
            })
            state.editDataFormDetails = editDataFormDetails;
            state.tabNumber = 0;
            const tabItems = state.editDataFormDetails.Questions.map((title, i) => {
                return {
                    key: i,
                    label: title.surveryTitle,
                }
            })
            state.items = tabItems;
            state.TemplateName = state.editDataFormDetails.title;
            state.Questions = state.editDataFormDetails.Questions.find((survey, i) => {
                if (i === state.tabNumber) {
                    return survey;
                }
            })
        },
        updateTabNumber(state, action) {
            console.log(action.payload);
            if (action.payload.type === "independent") {
                state.tabNumber = action.payload.independentNumber;
            }
            else if (action.payload.type === "inc") {
                state.tabNumber = state.tabNumber + 1;

            } else {
                state.tabNumber = state.tabNumber - 1;
            }
        },
        updateTextArea(state, action) {
            state.Answer = { ...state.Answer, [action.payload.activeTabName]: { ...state.Answer[action.payload.activeTabName], [action.payload.name]: action.payload.value } }
        },
        updateDefaultValue(state, action) {
            state.Answer = defaultValues[state.TemplateName]
        },
        toggleModal(state, action) {
            state.modelIsOpen = action.payload;
        },
        updateActiveDocId(state, action) {
            if (action.payload.commingFrom === "activeId") {
                state.activeDocId = action.payload.activeDocId;
            } else if (action.payload.commingFrom === "deleteActiveDocId") {
                state.activeDocId = action.payload.activeDocId;
            } else if (action.payload.commingFrom === "dashboardCard") {
                state.activeDocId = state.draftICP?.[state.TemplateName]?.docId;
            } else if (action.payload.commingFrom === "workspace") {
                state.activeDocId = action.payload.activeDocId;
                state.TemplateName = action?.payload?.TemplateName;
            }
        },
        updateCommingFrom(state, action) {
            state.commingFrom = action.payload;
        },
        updateTemplateTitle(state, action) {
            state.TemplateName = action.payload;
        },
        updateEditformDetails(state, action) {
            const editDataFormDetails = icp.find((feature, index) => {
                if (feature.title === action.payload.title) {
                    return feature;
                }
            })
            console.log(editDataFormDetails, "t-------->>>>>");
            state.editDataFormDetails = editDataFormDetails;
            state.tabNumber = 0;
        },
        toggleCustomTemplate(state, action) {
            state.isCustomTemplateOpen = !state.isCustomTemplateOpen;
        },
        updateDefaultCustomAns(state, action) {
            state.Answer = action.payload;
        },
        toggleSearchScreen(state, action) {
            state.isSearchOpen = action.payload;
        },
        customTemplateDifferatiation(state, action) {
            state.differentiateTemplate = action.payload;
        },
        deleteWorkSpace(state, action) {
            state.workSpace = state.workSpace.filter((ws, i) => {
                return ws._id !== action.payload;
            })
        },
        toggleShowEditICP(state, action) {
            state.showEdit = action.payload;
        },
        uploadAvatarICPLocal(state, action) {
            state.Answer.templateImage = action.payload;
        },
        resetStatusErr(state, action) {
            state.status = 'idle';
            state.fetchStatus = 'idle';
            state.error = null;
        },
        resetWorkspaceStatusErr(state, action) {
            state.workspacestatus = 'idle';
            state.savemodel = 'idle';
            state.error = null;
        },
        resetErrSaveModal(state) {
            state.error = null;
            state.status = 'idle';
        },
        resetSaveApi(state) {
            state.saveTemplate = 'idle';
        },
        resetPatchTemplate(state){
            state.patchTemplateNameStatus = { "status": "idle", "error": null }
        },
        resetICPState(state){
            return INITIAL_STATE;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchICPDraftDataApi.pending, (state) => {
                state.status = 'loading';
                state.DraftStatus = 'loading';
            })
            .addCase(fetchICPDraftDataApi.fulfilled, (state, action) => {
                state.draftICP = action.payload.draftICP;
                state.status = 'success';
                state.DraftStatus = 'success';
            })
            .addCase(fetchICPDraftDataApi.rejected, (state, action) => {
                state.status = 'failed';
                state.DraftStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAnsICPDataApi.pending, (state) => {
                state.fetchStatus = 'loading';
            })
            .addCase(fetchAnsICPDataApi.fulfilled, (state, action) => {
                state.fetchStatus = 'success';
                if (state.commingFrom === "workspace") {
                    let keys = [];
                    for (const key in action.payload.Answer) {
                        if (key !== 'workSpaceName' && key !== 'organisationId' && key !== 'templateImage') {
                            keys.push({ label: key });
                        }
                    }
                    console.log(keys);
                    state.items = keys;
                }
                state.showEdit = (action.payload.Answer?.templateImage?.length > 0) ? false : true;
                state.Answer = action.payload.Answer;
            })
            .addCase(fetchAnsICPDataApi.rejected, (state, action) => {
                state.fetchStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(postingDefaultAnsICPDataApi.fulfilled, (state, action) => {
                state.activeDocId = action.payload.activeDocId;
                state.draftICP = { ...state.draftICP, [state.TemplateName]: { ...state.draftICP[state.TemplateName], docId: action.payload.activeDocId } };
                state.status = "success"
            })
            .addCase(postingDefaultAnsICPDataApi.rejected, (state, action) => {
                state.status = "failed";
                state.postEditDataErr = action.error.message;
            })
            .addCase(getAllWorkSpaceDataApi.pending, (state, action) => {
                state.workspacestatus = "loading";
            })
            .addCase(getAllWorkSpaceDataApi.fulfilled, (state, action) => {
                state.workSpace = action.payload;
                state.workspacestatus = "success";
            })
            .addCase(getAllWorkSpaceDataApi.rejected, (state, action) => {
                state.workspacestatus = "failed";
                state.error = action.error.message;
            })
            .addCase(fetchingAndPostingCustomICPDataApi.pending, (state, action) => {
                state.fetchStatus = "loading";
            })
            .addCase(fetchingAndPostingCustomICPDataApi.fulfilled, (state, action) => {
                if (action.payload.activeDocId) {
                    state.activeDocId = action.payload.activeDocId;
                    const activeTemplate = state.active_template_type === "b2b" ? "Custom B2B Template" : "Custom B2C Template"
                    state.draftICP = { ...state.draftICP, [activeTemplate]: { ...state.draftICP[activeTemplate], docId: action.payload.activeDocId } };
                }
                if (action.payload.Answer) {
                    state.Answer = action.payload.Answer
                    state.active_template_type = "Firmographics" in action.payload.Answer ? "b2b" : "b2c";
                }
                state.fetchStatus = "success";
            })
            .addCase(fetchingAndPostingCustomICPDataApi.rejected, (state, action) => {
                state.fetchStatus = "failed";
                state.error = action.error.message;
            })
            .addCase(deleteWorkSpaceApi.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(deleteWorkSpaceApi.fulfilled, (state, action) => {
                state.status = "success";
            })
            .addCase(deleteWorkSpaceApi.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(updateWorkSpaceSaveICPApi.pending, (state, action) => {
                state.saveTemplate = "loading";
            })
            .addCase(updateWorkSpaceSaveICPApi.fulfilled, (state, action) => {
                state.saveTemplate = "success";
            })
            .addCase(updateWorkSpaceSaveICPApi.rejected, (state, action) => {
                state.saveTemplate = "failed";
                state.error = action.error.message;
            })
            .addCase(updateICPTextDataApi.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(updateICPCustomTextDataApi.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.postViewEditDataErr = action.error.message;
            })
            .addCase(updateICPTemplateNameApi.pending, (state, action) => {
                state.patchTemplateNameStatus.status = "loading";
            })
            .addCase(updateICPTemplateNameApi.fulfilled, (state, action) => {
                state.patchTemplateNameStatus.status = "success";
                state.workSpace = state.workSpace.map((space) => action.payload?.templateId===space?._id ? ({...space, "workSpaceName": action.payload?.templateName}) : space)
            })
            .addCase(updateICPTemplateNameApi.rejected, (state, action) => {
                state.patchTemplateNameStatus.status = "failed";
                state.patchTemplateNameStatus.error = action.error.message;
            })
    },
});

export const { updateActiveTemplateType, resetICPState, resetPatchTemplate, resetAnswerData, resetError, updateIsWorkSpaceHasName, resetSaveApi, updateSaveModal, updateTemplateNameToDownload, resetErrSaveModal, resetStatusErr, resetWorkspaceStatusErr, uploadAvatarICPLocal, toggleShowEditICP, deleteWorkSpace, customTemplateDifferatiation, toggleSearchScreen, toggleCustomTemplate, updateDefaultCustomAns, updateTabNumber, updateEditFeature, updateTextArea, updateDefaultValue, toggleModal, updateActiveDocId, updateCommingFrom, updateTemplateTitle, updateEditformDetails } = idealCustomerProfileSlice.actions;
export default idealCustomerProfileSlice.reducer;
