import React from 'react'
import HeadingTwo from './HeadingTwo'
import HeadingFive from './HeadingFive'

const ResponsiveHeadingTwoFive = ({ color, text, className }) => {
  return (
    <>
      <HeadingTwo headingCSSStyles={{ marginBottom: 0, fontWeight: 700 }} color={color} text={text} className={`sm:block hidden ${className}`} />
      <HeadingFive headingCSSStyles={{ marginBottom: 0 }} color={color} text={text} className={`sm:hidden block ${className}`} />
    </>
  )
}

export default ResponsiveHeadingTwoFive