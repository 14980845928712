import React, { useState, useEffect } from 'react';
import gmail from "../assets/VerifyEmail/gmail.svg"
import outlooklogo from "../assets/VerifyEmail/outlooklogo.svg"
import undologo from "../assets/VerifyEmail/undologo.svg"
import getstarted from "../assets/VerifyEmail/getstarted.svg"
import forgotpassword from "../assets/Login/newResetpassword.svg"
import { Button, Modal, Input, message } from 'antd';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { verifyEmail, userEmailVerificationStatus } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux"
import { updateEmail, updateModelShow, resetPasswordMsg } from '../features/spamSlice';
import logo from '../assets/Header/logotest.svg';
import { validateEmail } from '../utils/validation';
import { sendResetPasswordLink } from '../features/spamSlice';
import TNC from "../assets/docs/Terms_and_Conditions.pdf";
import PP from "../assets/docs/Privacy_Policy.pdf";
import brand from "../assets/common/brand.svg";
import resetPasswordLocals from "../localization/localization_en_reset_password.json"
import footerLocals from "../localization/localization_en_footer.json"


const ResetPasswordMainPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const status = useSelector((state) => state.spam.status)
    const statusMsg = useSelector((state) => state.spam.statusMsg)
    const [timer, setTimer] = useState(30);
    const userEmail = useSelector((state) => state.spam.email);
    const [email, setEmail] = useState(userEmail);
    const modal2Open = useSelector((state) => state.spam.model);
    const [messageApi, contextHolder] = message.useMessage();
    const verificationStatus = useSelector(userEmailVerificationStatus);
    const [resendEmailPrevent, setResendEmailPrevent] = useState(true);

    useEffect(()=>{
        if(status === "rejected"){
            message.open({
                type:"error",
                content:statusMsg
            })
        }
        const resetMsgTimer = setTimeout(() => {
            dispatch(resetPasswordMsg());
        }, 2000);
        return () => clearTimeout(resetMsgTimer);
    },[status])

    useEffect(() => {
        if (!modal2Open) {
            const timerClock = setInterval(() => {
                if (timer === 0) {
                    setResendEmailPrevent(false);
                    clearInterval(timerClock);
                } else {
                    setTimer((prev) => prev - 1);
                }
            }, 1000);
            return () => clearInterval(timerClock);
        }
    }, [resendEmailPrevent, timer, modal2Open]);
    if (verificationStatus) {
        setTimeout(() => {
            navigate('/product/onboarding');
        }, 1500);
    }
    return (
        <div>
        {contextHolder}
            <div className='min-h-screen flex flex-col md:mx-0 mx-[24px] justify-between'>
                <div className='absolute hidden top-0 left-0 right-0 md:flex -z-[10000] min-h-full'>
                    <div className="left_shadow bg-white w-[60%]"></div>
                    <div className="left_shadow bg-[#FAFAFA] w-[45%] 2xl:w-[55%]"></div>
                </div>
                <div className="header sticky top-0 lg:pt-[48px] lg:pl-[64px] pl-[0px]">
                    <img src={logo} className="w-[138px] h-[21px] md:block hidden" alt="" />
                    <img src={brand} className="w-[64px] h-[64px] block md:hidden" alt="" />
                </div>
                <div className="md:flex justify-between items-center">
                    <div className='md:w-[50%] lg:w-[60%]'>
                        <div className="lg:w-full lg:ml-[64px] ml-[0px]">
                            <p style={{ marginBottom: "12px" }} className='text-[#221CB7] text-[24px] font-DMSans'>{resetPasswordLocals.reset_password.title}</p>
                            <p style={{ marginBottom: "12px" }} className='text-[#9E9E9E] text-[14px] font-medium tracking-wide font-InterRegular'>{resetPasswordLocals.reset_password.guidance_description}
                            <span className='text-[#111827] font-InterRegular tracking-wide text-[14px]'> {userEmail}</span><span className='ml-2 text-sm text-primary cursor-pointer' onClick={() => {
                                dispatch(updateModelShow(true));
                                navigate('/product');
                            }}>{email.trim().length>0 && 'Edit'}</span></p>
                            <p style={{ marginBottom: "48px" }} className='text-[#9E9E9E] font-InterRegular text-[14px] font-medium tracking-wide'>{resetPasswordLocals.reset_password.instruction_description}</p>
                            <div className="buttons flex flex-col lg:w-[474px]">
                                <Button type='link' onClick={() => {
                                    setResendEmailPrevent(true);
                                    dispatch(sendResetPasswordLink(email))
                                    setTimer(30);
                                }} disabled={resendEmailPrevent} style={{ marginBottom: "24px" }} className='flex pt-3 justify-center border-none rounded-xl h-[50px]'>
                                    <img src={undologo} alt="" />
                                    <span className='text-[14px] text-primary font-semibold' style={{ padding: "0px 5px" }} type='link'>{resetPasswordLocals.reset_password.resend_link_text} {!modal2Open && timer !== 0 && `in ${timer}s`}</span>
                                </Button>
                                <Button onClick={() => window.open("https://outlook.office365.com")} style={{ marginBottom: "24px" }} className='flex border-none justify-center items-center rounded-[12px] bg-[#F5F8FF] h-[50px]'>
                                    <img src={outlooklogo} alt="" />
                                    <span className='text-[16px] text-[#21208F] font-semibold' style={{ padding: "0px 5px" }} type='link'>{resetPasswordLocals.reset_password.sm_outlook}</span>
                                    <img src={getstarted} alt="" />
                                </Button>
                                <Button onClick={() => window.open('https://mail.google.com/mail/u/0/#inbox', '_blank')} className='flex justify-center items-center border-none rounded-[12px] bg-[#F5F8FF] h-[50px]'>
                                    <img src={gmail} alt="" />
                                    <span className='text-[16px] text-[#21208F] font-semibold' style={{ padding: "0px 5px" }} type='link'>{resetPasswordLocals.reset_password.sm_gmail}</span>
                                    <img src={getstarted} alt="" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="w-[40%] bg-[#FAFAFA] md:flex justify-center lg:mr-[30px] hidden">
                        <img src={forgotpassword} className="my-auto w-[80%] xl:w-[60%]" alt="" />
                    </div>
                </div>
                <div className="footerPass sticky invisible md:visible top-[100%]">
                    <div className='flex justify-between text-[#9E9E9E] items-center text-[12px] pb-[32px] px-[64px]'>
                        <div className=''>{footerLocals.footer.copyright_text}</div>
                        <div className=''>
                            <button onClick={() => navigate('/terms-and-conditions')} className='mr-[24px]'>{footerLocals.footer.terms_and_condition_text}</button>
                            <button onClick={() => navigate('/privacy-policy')}>{footerLocals.footer.privacy_policy_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordMainPage;
