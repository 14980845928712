const generateDataIndex = (columns) => {
    let mandatoryDataFields = [];
    if (columns) {
        columns.forEach((column) => {
            if (column.isMandatory) {
                mandatoryDataFields.push(column.dataIndex);
            }
        })
    }
    return mandatoryDataFields;
}

const checkSpecificTableIsFilled = (data, mandatoryDataFields) => {
    let isNull = false;
    // console.log(data, mandatoryDataFields)
    if(Array.isArray(data)){
        data.forEach((tableData) => {
            mandatoryDataFields.forEach((dataIndex) => {
                const sensitiveData = tableData[dataIndex];
                if(typeof sensitiveData === 'number' && sensitiveData === 0)return ;
                if (sensitiveData === null || sensitiveData === undefined || (typeof sensitiveData === 'string' && sensitiveData?.trim()?.length === 0)) {
                    isNull = true;
                }
            })
        })
    }
    return isNull;
}


export const RevenueOnboardingFormStatus = (inputs) => {
    // console.log(isNull,"traceing))))))==")
    if(!(!!inputs)) return true;
    const { data, type, columns, restrict_value } = inputs;
    switch (type) {
        case 'radio': return Array.isArray(data) ? data.length === 0 : data===null || data===undefined;
        case 'check_box': return Array.isArray(data) ? data.length === 0 : data===null || data===undefined;
        case 'table': {
            const mandatoryDataFields = generateDataIndex(columns)
            // console.log(mandatoryDataFields,"fields");
            return checkSpecificTableIsFilled(data, mandatoryDataFields)
        }
        case 'tableRowCheck': {
            let isNull = false;
            const rowValidation = inputs.mandatoryRows;
            data?.map((subData, index) => {
                if(rowValidation[index]){
                    for(const attribute of Object.entries(subData)){
                        if(typeof attribute[1] === 'number' && attribute[1] === 0)return ;
                        if (attribute[1] === null || attribute[1] === undefined || (typeof attribute[1] === 'string' && attribute[1]?.trim()?.length === 0)) {
                            isNull = true;
                        }
                    }
                }
            })
            return isNull;
        }
        case 'radioTable': {
            if(!!data.radio === false)return true;
            let isNull = false;
            for( let tableData of Object.entries(data.table.data)){
                const key = tableData[0];
                const value = tableData[1];
                const mandatoryDataFields = generateDataIndex(columns[key])
                if(checkSpecificTableIsFilled(value, mandatoryDataFields)){
                    isNull = true;
                }
            }
            return isNull;
        }
        case 'number_input': return data===null || data===undefined;
        case 'product_category_detail': {
            let isNull = false;
            if(Array.isArray(data)){
                if(data.length===0) return true;
                data.map((miniData) => {
                    const values = Object.values(miniData);
                    values.map((value) => {
                        if(typeof value === 'number' && value === 0)return ;
                        if (value === null || value === undefined || (typeof value === 'string' && value?.trim()?.length === 0)) {
                            isNull = true;
                        }
                    })
                })
            }
            return isNull;
        }
        case 'tab_table': {
            let isNull = false;
            for(let attribute of Object.entries(data)){
                if(Array.isArray(attribute[1])){
                    attribute[1].map((miniData) => {
                        miniData?.map((yearData, index) => {
                            if(index+1 <= restrict_value){
                                if((typeof yearData.year === 'number' && yearData.year === 0) || (typeof yearData.value === 'number' && yearData.value === 0))return ;
                                if (yearData.value === null || yearData.value === undefined || (typeof yearData.value === 'string' && yearData.value?.trim()?.length === 0)){
                                    isNull = true;
                                }
                            }
                        })
                    })
                }
            }
            return isNull;
            // let mandatoryDataFields = generateDataIndex(columns.columns)
            // const ListofData = Object.values(data);
            // if(restrict_value > 3){
            //     mandatoryDataFields = mandatoryDataFields;
            // }else{
            //     mandatoryDataFields = mandatoryDataFields.slice(0, mandatoryDataFields.length-2);
            // }
            // let isNull = false;
            // ListofData.map((nestedArr) => {
            //     if(checkSpecificTableIsFilled(nestedArr, mandatoryDataFields, restrict_value)){
            //         isNull = true;
            //     }
            // });
            // return isNull;
            return false;
        }
        case 'switch_attr_table': {
            let checkIsNull = false;
            if(!(!!data.type)) return true;
            if(data.type === "last_month_marketing_spend"){
                for (let channel of Object.entries(data)) {
                    if (["E-Commerce", "Social Media", "Website & Mobile App"].includes(channel[0])) {
                        const { last_month_marketing_spend } = channel[1]
                        if(typeof last_month_marketing_spend === 'number' && last_month_marketing_spend === 0)return ;
                        if (last_month_marketing_spend === null || last_month_marketing_spend === undefined || (typeof last_month_marketing_spend === 'string' && last_month_marketing_spend?.trim()?.length === 0)) {
                            checkIsNull = true;
                        }
                    }
                }
            }else{
                for (let channel of Object.entries(data)) {
                    if (["E-Commerce", "Social Media", "Website & Mobile App"].includes(channel[0])) {
                        const { last_six_months } = channel[1]
                        last_six_months.map((data) => {
                            const { month, year, spend_value } = data;
                            if(typeof month === 'number' && month === 0)return ;
                            if(typeof year === 'number' && year === 0)return ;
                            if(typeof spend_value === 'number' && spend_value === 0)return ;
                                if(!(!!month && !!year && !!spend_value)){
                                    checkIsNull = true;
                                }
                            })
                    }
                }
            }
            return checkIsNull;
        }
    }
}