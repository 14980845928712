import api, { setBearerToken } from "../../services/api/api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateAccessToken } from "../../features/userSlice";
import * as apiEndpoints from '../../services/endPoints/stepperProgressAPI';
import routesConfig from "../../services/config/routesConfig.json"
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";

export const fetchFeatureProgressApi = createAsyncThunk(
    'stepperprogress/fetchFeatureProgressApi',
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'stepperProgress', 'get');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const postNewFeatureProgressApi = createAsyncThunk(
    'stepperprogress/postNewFeatureProgressApi',
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'stepperProgress', 'post');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

const stepperProgressSlice = createSlice({
    name: "stepperprogress",
    initialState: {
        status: 'idle',
        stepperProgressData: []
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeatureProgressApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFeatureProgressApi.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.stepperProgressData = action.payload.data.stepper;
            })
            .addCase(fetchFeatureProgressApi.rejected, (state, action) => {
                state.status = 'failed';
            })
            .addCase(postNewFeatureProgressApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postNewFeatureProgressApi.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.stepperProgressData = action.payload.data.stepper;
            })
            .addCase(postNewFeatureProgressApi.rejected, (state, action) => {
                state.status = 'failed';
            })
    },
});

export default stepperProgressSlice.reducer;
