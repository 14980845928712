import { generateMonth } from "../../../functionality/RevenueOnboarding/revenueOnboardingUtiliy";

export const GenerateTableRow = (tableName, fiscalYear) => {
    switch(tableName){
        case 'marketing_spend': {
            const month = fiscalYear?.[1];
            const year = fiscalYear?.[0];
            return {
                "dbid": null, 
                "name": null, 
                "month": month.startMonth, 
                "year": year, 
                "last_month_marketing_spend": null, 
                "mom_spend_growth_percent": null, "last_six_months": new Array(6).fill(null).map((_, i) => ({ 
                    "month": generateMonth({ startMonth: Number(month), "seriesMonth": i, outputType: "value" }), 
                    "year": year, 
                    "spend_value": null 
                })) 
            }
        }
        case 'cost_incurred_for_user_through_marketing': return { "name": null, "mail": null, "sms": null};
        case 'incurring_direct_cost': return { "dbid": null, "name": null, "method_of_calculation": "Cost Per Order", "current_value_e_commerce": null, "current_value_for_other_d2c_channel": null, "varying_cost_over_time": null, "TDS_Applicable": false};
        case 'capacity_planning': return  { "dbid": null, 'name': null, "value": null, "varying_cost_over_time": null }
        case 'cost': return {"dbid": null, "name": null, "warehouse": null, "collection_center": null, "office": null, "varying_cost_over_time": null}
        case 'undervied_costs_personnal_diff_department': return { "dbid": null, "name": null, "personnel_ctc": null, "tl_ctc": null, "increment_yoy_percent": null, "personnel_headcount": null, "personnel_headcount_variation_yoy": null }
        case 'dervied_costs_personnal_diff_department': return { "dbid": null, "name": null, "calculation_methodology": "Orders Per Person", "value": null, "mom_variation": null, "other_input": null, "personnel_ctc": null, "tc_ctc": null, "increment_yoy_percent": null, "exclude_from_capex": false}
        case 'currently_incurring_g_and_a_cost': return { "dbid": null, "name": null, "calculation_methodology": "Fixed", "value": null, "varying_cost_over_time":null}
        case 'capex_cost': return {"dbid": null, "name": null,"calculation_methodology": "Fixed", "value": null,"varying_cost_over_time":null};
    }
}