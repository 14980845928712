import React from 'react'
import PitchDeckNavBar from '../../../DesignSystem/Headers/PitchDeckNavBar'
import { editSlideShowHeaderData } from '../data'
import SlideShowToolBar from './SlideShowToolBar'

const EditSlideShow = () => {
    return (
        <div className='mt-[3.5rem] relative'>
            <div className="header fixed 2xl:w-[1440px] mx-auto top-[3.5rem] left-0 right-0">
                <PitchDeckNavBar data={editSlideShowHeaderData} />
            </div>
            <div className='flex'>
                <div className="edit_slide_area w-[75%] border border-black">
                    <div></div>
                </div>
                <SlideShowToolBar/>
            </div>
        </div>
    )
}

export default EditSlideShow