import React from 'react'
import FeatureDescribe from '../../../DesignSystem/TypographyComponent/FeatureDescribe'
import SearchAndCreateTemplate from '../subcomponents/SearchAndCreateTemplate';
import PrimarySmRoundedTab from '../../../DesignSystem/Tabs/PrimarySmRoundedTab';

const LandingPage = () => {
  return (
    <React.Fragment>
      <div className='mt-[5rem] flex flex-col gap-y-[40px]'>
        <FeatureDescribe heading={"Pitch Deck"} description={"Welcome to Pitch Deck! Explore, customize investor-ready pitches, and craft your unique pitch deck."} />
        <div className='flex flex-col gap-y-[24px] mb-[24px]'>
          <SearchAndCreateTemplate />
          <PrimarySmRoundedTab />
        </div>
      </div>
    </React.Fragment>
  )
}

export default LandingPage