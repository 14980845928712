import React from 'react'
import appLocals from '../../../../localization/localization_en_app.json'
import OopsPopUp from '../../../reuseablecomponent/PopUpVarients/OopsPopUp'

const WARNING_MESSAGE = appLocals.app.business_model.error_message;

const WarningPopup = ({ isModelOpen, warningType, handlePopUpClose }) => {
    if(![
        "detailed_reports_access_warning",
        "unknown_error_refresh",
        "no_business_model",
        "timeout",
        "offline",
        "download",
        "save",
        "process",
        "unknown_error",
        "future_screen_data_not_found_view",
        "future_screen_data_not_found_edit"
    ].includes(warningType)) return;
    const description1 = WARNING_MESSAGE[warningType].message?.description1
    const description2 = WARNING_MESSAGE[warningType].message?.description2
    const description3 = WARNING_MESSAGE[warningType].message?.description3
    const buttonText =  WARNING_MESSAGE[warningType].buttons;
    return (
        <div>
            <OopsPopUp
                isOpen={isModelOpen}
                description1={description1}
                description2={description2}
                description3={description3}
                onClick={handlePopUpClose}
                buttonText={typeof buttonText==="string" ? buttonText : 'OK'}
            />
        </div>
    )
}

export default WarningPopup