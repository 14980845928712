import { months } from "../../data/data";

export const updateTargetTableValue = ({ rowIndex, dataIndex, value, tableData }) => {
    return tableData.map((row, index) => {
        if(index === rowIndex) return {...row, [dataIndex]:value};
        return row;
    })
}

export const findDuplicateTargetAndFilter = ({ rowIndex, dataIndex, value, tableData }) => {
    const uniqueProductCategory = [];
    tableData.map((row, index) => {
        if(row[dataIndex] === value){
            if(rowIndex === index){
                uniqueProductCategory.push(row);
            }    
        }else{
            uniqueProductCategory.push(row);
        }
    })
    return uniqueProductCategory;
}

export const filteredSelectOptions = ({ index, productCategory }) => {
    
}
const API_TRAVEL_STATUS = {  status: 'idle', message: null }
export const API_BI_TRAVEL_STATUS = { get: API_TRAVEL_STATUS, post: API_TRAVEL_STATUS, delete: API_TRAVEL_STATUS }

export const generateApiTraceInitialState = (noOfDefaultState) => {
    let state = {};
    new Array(noOfDefaultState).fill(null).map((_, index) => {
        state = { ...state, [index]: API_BI_TRAVEL_STATUS }
    })
    return state;
}

export const generateMonth = ({ startMonth, seriesMonth, outputType="label" }) => {
    if(startMonth+seriesMonth>12){
        return outputType==="label" ? months[startMonth+seriesMonth-12].month : months[startMonth+seriesMonth-12]?.value ;
        // return outputType==="label" ? months[(startMonth+seriesMonth)-startMonth-1].month : months[(startMonth+seriesMonth)-startMonth-1].value
    }else{
        return outputType==="label" ? months[startMonth+seriesMonth]?.month : months[startMonth+seriesMonth]?.value
    }
}