import { v4 as uuidv4 } from "uuid";

export const attributes = {
    "marketing_spend": {
        "Last Month Marketing Spend": "last_month_ms",
        "MoM Spend Growth (%)": "month_on_month_ms",
        "Website Promotion": "web_promotion",
        "Platform Users Acquisition": "platform_user",
        "E-commerce Platform Spend": "e_commerce",
        "Raw Material Cost": "raw_material_coast",
        "Other Direct Cost": "direct_cost",
        "% Change In This Cost": "change_cost",
        "Sales Margin %": "sales_margin",
        "Purchase Price": "purchase_price",
        "Avg.Purchase Variation": "avg_purchase",
        "Sales Margin %": "sales_margin",
        "Quantity Per Order": "quality_per_order",
        "Business Contribution": "business_contribution",
        "Cancellations/Returns": "cancellations",
    }
}

export const manufacture = {
    "category": null,
    "raw_material_coast": null,
    "direct_cost": null,
    "change_yoy": null
}

export const buy = {
    "category": null,
    "purchase_price": null,
    "change_yoy": null
}

export const product_category_1 = {
    "category": null,
    "tax_rate": null,
    "sales_margin": null
}

export const product_category_2 = {
    "category": null,
    "year1": null,
    "year2": null,
    "year3": null,
    "year4": null,
    "year5": null,
}

export const sm_marketing_places = {
    "channel": null,
    "marketing_spend_split": null,
    "cpc_per_channel": null,
    "change_yoy": null,
    "conversion_channels": null
}

const generateData = (rowNumber, data) => new Array(rowNumber).fill(null).map(() => data)

export const RO_product_category_1 = (numberOfRows = 2) => generateData(numberOfRows, product_category_1)

export const RO_product_category_2 = (numberOfRows = 2) => generateData(numberOfRows, product_category_2)

export const defaultRevenueNewRowData = { buy, manufacture, product_category_1, product_category_2, sm_marketing_places }

export const additional_product_category = (year) => {
    return new Array(5).fill(null).map((_, i) => {
        return { year, value: null }
    })
}

export const product_category = (year, type) => {
    return {
        "name": null,
        "id": null,
        "type": type,
        "raw_material_cost": null,
        "other_direct_cost": null,
        "varying_cost_over_time": null,
        "purchase_price": null,
        "change_yoy_percent": null,
        "commission_cost": null,
        "platform_commission_cost_varying_cost_over_time": null,
        "tax": null,
        "sales_margin_percent": null,
        "business_contribution": additional_product_category(year),
        "returns_cancellations": additional_product_category(year),
        "quantity_per_order": additional_product_category(year),
        "annual_inventory_days_product_category": [{ "year1": null, "year2": null, "year3": null, "year4": null, "year5": null }]
    }
}

export const generateDefaultProductCategory = (defaultDataNumber, year=null, type) => {
    return new Array(defaultDataNumber).fill(null).map((_, index) => {
        return {
            key: uuidv4(),
            ...product_category(year, type)
        }
    })
}

export const months = {
    1: {
        month: "January",
        value: "Jan"
    },
    2: {
        month: "February",
        value: "Feb"
    },
    3: {
        month: "March",
        value: "Mar"
    },
    4: {
        month: "April",
        value: "Apr"
    },
    5: {
        month: "May",
        value: "May"
    },
    6: {
        month: "June",
        value: "Jun"
    },
    7: {
        month: "July",
        value: "Jul"
    },
    8: {
        month: "August",
        value: "Aug"
    },
    9: {
        month: "September",
        value: "Sep"
    },
    10: {
        month: "October",
        value: "Oct"
    },
    11: {
        month: "November",
        value: "Nov"
    },
    12: {
        month: "December",
        value: "Dec"
    }
};