import React from 'react'
import HeadingSix from '../../TypographyHeadings/HeadingSix'
import TextSmall from '../../TypographyDescriptions/TextSmall'
import PGButtonComponent from '../../Buttons/PGButtonComponent'
import { shapes } from '../../../assets/website'
import { team_members } from '../../../assets/website'
import ImageSrcHashComponent from '../../../website/components/ImageSrcHashComponent'

const { right_arrow } = shapes;

export const blogAuthor = {
    "Jagriti Shreya": team_members.jagriti_shreya,
    "Saaniya Afreen": team_members.saaniya_afreen
}

const BlogCardMoreInfo = ({ image, heading, btnName, postedByName, postReadTimeLine, handleOnClick }) => {
    return (
        <div onClick={handleOnClick} className='flex flex-col gap-y-[24px] p-[12px] rounded-[8px] shadow-lg border-slate-200 border justify-between cursor-pointer'>

            <div className='flex flex-col'>
                <ImageSrcHashComponent
                    src={image.img}
                    placeholderImage={image.placeholder_img}
                    className={'aspect-video w-full overflow-hidden'}
                />
                <div className='mt-[8px]'>
                    <HeadingSix text={heading} />
                </div>
                <div className='flex items-center gap-x-[8px] justify-start'>
                    <img className='w-[1.5rem] rounded-full' src={blogAuthor[postedByName]} alt="" />
                    <TextSmall text={postedByName} color='#9E9E9E' fontWeight={600} />
                    <div className='w-[6px] aspect-square rounded-full bg-[#9E9E9E]'></div>
                    <TextSmall text={postReadTimeLine} color='#9E9E9E' fontWeight={600} />
                </div>
            </div>

            <div>
                <PGButtonComponent className={"p-0"} intent={"link"}>
                    <span className='text-p3'>{btnName}</span>
                    <img src={right_arrow} alt="" />
                </PGButtonComponent>
            </div>

        </div>
    )
}

export default BlogCardMoreInfo