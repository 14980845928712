import React from 'react'
import TextComponent from '../../../reuseablecomponent/TextVarients/TextComponent'

const StepCounterWithTitle = ({ stepNumber, productTitle }) => {
  return (
    <React.Fragment>
        <div className='flex items-center gap-x-3'>
            <div className='w-[32px] h-[32px] aspect-square border border-primary text-primary rounded-full flex justify-center items-center'>{stepNumber}</div>
            <TextComponent color={"#372EE5"} fontSize={18} fontWeight={600} text={productTitle} />
        </div>
    </React.Fragment>
  )
}

export default StepCounterWithTitle