import React from 'react'

const RenderList = ({ data }) => {
    return (
        <div className='flex flex-col gap-y-[1rem]'>
            {
                data.map((para, index) => {
                    return (
                        <div key={index}>{para}</div>
                    )
                })
            }
        </div>
    )
}

export default RenderList