import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import profile from "../assets/PSM/profile.svg";
import {
    Button,
    Table,
    ConfigProvider,
} from "antd";
import "./index.css";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import InvestorName from "../assets/TableIcon/Investor_name.png";
import Email from "../assets/TableIcon/email.png"
import FundingStage from "../assets/TableIcon/FundingStage.png";
import FundsImage from "../assets/TableIcon/Funds.png"
import AccessImage from "../assets/TableIcon/AceessImage.png"
import TeamImage from "../assets/TableIcon/TeamImage.png";
import AccessExpiry from "../assets/TableIcon/AccessExpiry.png";
import TextMedium from "../DesignSystem/TypographyDescriptions/TextMedium";
import CirclePlus from "../assets/TableIcon/circlePlusIc.png";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import {
    PlusOutlined,
} from "@ant-design/icons";
import ActionMenuInvestorFlowCard from "../DesignSystem/Cards/ActionMenuInvestorFlowCard";
import { fetchAllInvestorApi, getStageOfFundingById } from "../features/dataRoomSlice";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContactsIcon from '@mui/icons-material/Contacts';
import EmailIcon from '@mui/icons-material/Email';
import PaymentsIcon from '@mui/icons-material/Payments';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupsIcon from '@mui/icons-material/Groups';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const getStageOfFundingName = async (id, dispatch) => {
    const body = {
        addFolder: "",
        id: id
    }
    const result = await dispatch(getStageOfFundingById(body));
    return result?.payload?.title
}

const BlinkingLoader = () => (
    <div className="w-20 h-5 rounded-sm animate-pulse bg-neutral-200"></div>
);

export default function AdminInvestorFlow() {
    const dispatch = useDispatch();
    const [featureData, setFeatureData] = useState({});
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filesData, setFilesData] = useState([])
    const [stagesOfFunding, setStagesOfFunding] = useState({});
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [stagesLoading, setStagesLoading] = useState(true);

    const onSelectChange = (newSelectedRowKeys) => {
        setButtonDisabled(!newSelectedRowKeys.length);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await dispatch(fetchAllInvestorApi());
                const extractedData = response.payload?.users.map((obj) => {
                    return {
                        id: obj._id,
                        fullName: obj.fullName,
                        email: obj.email,
                        canAddTeamMember: obj.canAddTeamMembers,
                        accessForTeams: obj.accessForTeam,
                        stageOfFunding: obj.stageOfFunding,
                        accessForInvestors: obj.accessForInvestor,
                        investmentAmount: obj.investmentAmount,
                        investorAccessExpiry: obj.investorAccessExpiry,
                        role: obj.role.role,
                        status: obj.status.status,
                        username: obj.username,
                        teamCount: obj?.teamCount ?? 0,
                    }
                })
                setFilesData(extractedData);
                setLoading(false)
                // Fetch all stages of funding data here
                const stages = {};
                for (const item of extractedData) {
                    if (item.stageOfFunding && !stages[item.stageOfFunding]) {
                        const body = {
                            addFolder: "",
                            id: item.stageOfFunding
                        }
                        const stage = await dispatch(getStageOfFundingById(body));
                        console.log(stage)

                        stages[item.stageOfFunding] = stage?.payload[0]?.title;
                    }
                }
                setStagesOfFunding(stages);
                setStagesLoading(false);
                console.log("Stages Of Funding : ", stages)
            } catch (error) {
                console.error("Failed to list directory", error);
            } finally {
                setLoading(false);
                setReload(false);
            }
        };

        fetchData();
    }, [dispatch, reload]);

    const columns = [
        {
            title: (
                <div className="flex gap-x-2 items-center py-3 ml-2 bg-white">
                    <ContactsIcon fontSize="small" className="text-gray-500" />
                    <span className=" font-normal text-[#757575]">Investor Name</span>
                </div>
            ),
            dataIndex: "InvestorName",
            key: "InvestorName",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1">
                        <h1 className="text-[#111827] font-medium font-sans text-sm m-0 tracking-wide pl-2">
                            {record.username}
                        </h1>
                    </div>
                );
            },
            width: 120,
        },
        {
            title: (
                <div className="flex gap-x-2 items-center justify-start py-3 bg-white">
                    <EmailIcon fontSize="small" className="text-gray-500" />
                    <h1 className="m-0  font-normal text-[#757575]">Email Address</h1>
                </div>
            ),
            dataIndex: "EmailAddress",
            key: "EmailAddress",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1">
                        <h1 className=" text-[#111827] font-medium font-sans text-sm m-0 tracking-wide">
                            {record.email}
                        </h1>
                    </div>
                );
            },
            width: 180,
        },
        {
            title: (
                <div className="flex gap-x-2 items-center justify-start py-3 bg-white">
                    <PaymentsIcon fontSize="small" className="text-gray-500" />
                    <h1 className="m-0  font-normal text-[#757575]">Funding Stage</h1>
                </div>
            ),
            dataIndex: "FundingStage",
            key: "FundingStage",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1">
                        <div className=" flex  items-center">
                            {stagesLoading ? (
                                <BlinkingLoader />
                            ) : (
                                <h1 className=" text-[#111827] font-sans text-sm m-0 font-medium tracking-wide">
                                    {stagesOfFunding[record.stageOfFunding] || "No Stage"}
                                </h1>
                            )}
                        </div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex gap-x-2 items-center justify-start py-3 bg-white">
                    <LocalAtmIcon fontSize="small" className="text-gray-500" />
                    <h1 className="m-0  font-normal text-[#757575]">Funds</h1>
                </div>
            ),
            dataIndex: "Funds",
            key: "Funds",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1">
                        <div className=" flex  items-center">
                            <h1 className=" text-[#111827] font-sans text-sm m-0 font-medium tracking-wide">
                                {record.investmentAmount}
                            </h1>
                        </div>
                    </div>
                );
            },
            width: 130,
        },
        {
            title: (
                <div className="flex gap-x-2 items-center justify-start py-3 bg-white pl-4">
                    <AdminPanelSettingsIcon fontSize="small" className="text-gray-500" />
                    <h1 className="m-0  font-normal text-[#757575]">Access</h1>
                </div>
            ),
            key: "comments",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1 ml-3">
                        <div className=" flex  items-center">
                            <h1 className=" text-[#111827] font-sans text-sm m-0 font-medium tracking-wide">
                                {record.accessForInvestors}
                            </h1>
                        </div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex gap-x-2 items-center justify-start py-3 ml-8 bg-white">
                    <GroupsIcon fontSize="small" className="text-gray-500" />
                    <h1 className="m-0  font-normal text-[#757575]">Team</h1>
                </div>
            ),
            key: "Team",
            dataIndex: "Team",
            render: (_, record) => (
                <div className="flex gap-x-2 items-center justify-center h-full gap-1">
                    <div className="flex -space-x-2">
                        <img className="w-1/2 h-1/2 rounded-full" src={profile} />
                        <img className="w-1/2 h-1/2 rounded-full" src={profile} />
                        <img className="w-1/2 h-1/2 rounded-full" src={profile} />
                    </div>
                    <TextMedium
                        fontWeight={500}
                        text={`${record?.teamCount ?? 0} Members`}
                        color={"#757575"}
                        margin={{ direction: "left" }}
                    />
                </div>
            ),
            width: 150,
        },
        {
            title: (
                <div className="flex gap-x-2 items-center justify-start py-3 bg-white">
                    <PrivacyTipIcon fontSize="small" className="text-gray-500" />
                    <h1 className="m-0  font-normal text-[#757575]">Access Expiry</h1>
                </div>
            ),
            dataIndex: "AccessExpiry",
            key: "AccessExpiry",
            render: (_, record) => (
                <div className="flex py-0.5">
                    <span className=" text-[#424242] font-bold">{formatDate(record.investorAccessExpiry)}</span>
                </div>
            ),
            width: 140,
        },
        {
            title: (
                <div className="flex gap-x-2 items-center bg-white justify-start min-w-[2rem] max-w-[2rem] z-10">
                    <AddCircleOutlineIcon className="text-[#757575]" fontSize="small" />
                </div>
            ),
            key: "actions",
            render: (_, record) => (
                <div className="py-1.5 w-full flex justify-center bg-white min-w-[2rem] max-w-[2rem]">
                    <ActionMenuInvestorFlowCard data={record} />
                </div>
            ),
            width: 20,
            "fixed": "right"
        },
    ];
    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card.id !== cardDetails.id
            );
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
    }, [checkedCardDetails]);

    const [modalTitle, setModalTitle] = useState(null);

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const handleButtonClick = () => {
        setModalTitle("Edit Investor Details");
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };

    const handleDeleteButton = () => {
        setModalTitle("Delete File");
        setIsModalVisible(true);
    };

    const closeDeleteModal = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(null);
    };

    const onDownloadClick = () => {
        setModalTitle("Download");
        setIsModalVisible(true);
    };

    const SkeletonLoader = ({ avatar = false, active = true, loading = true }) => {
        return (
            <div className="bg-neutral-200 animate-pulse w-full h-10 rounded-lg">
            </div>
        );
    };

    return (
        <div className="pt-[6rem] min-h-[100vh]">
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    isOpen={true}
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                        setReload(!reload)
                    }}
                    secondaryCloseHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("")
                    }}
                />
            )}
            {reload && (
                <div className="absolute bottom-0 left-0 w-full flex items-center justify-center p-4 bg-white">
                    <div className="flex items-center absolute right-10 bottom-4 shadow-lg p-4 rounded-lg">
                        <div className="w-6 h-6 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                        <span className="ml-2">Refreshing data</span>
                    </div>
                </div>
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
                <div className="mx-auto">
                    <DescribeFeature
                        text={"My Investor Information"}
                        description={"View and manage your investor profile details, including funding stage, email address, investment amount, and access privileges."}
                    />
                    <div className="flex justify-between items-center">
                        <p className="font-DMSans text-[#111827] text-[18px] mt-6">
                            Investors List
                        </p>
                        <div className="flex gap-2 items-center">
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={handleButtonClick}
                                className="bg-[#372EE5] font-semibold"
                            >
                                Add Investors
                            </Button>
                        </div>
                    </div>
                    <div className="mt-4 overflow-hidden">
                        {loading ? (
                            <div className="w-full flex flex-col gap-3">
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Table: {
                                            headerBg: "#FFFFFF",
                                        },
                                    },
                                }}
                            >
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={filesData}
                                    scroll={{ x: 1500 }}
                                    loading={loading}
                                    rowKey="id"
                                    pagination={{
                                        pageSize: filesData?.length,
                                        position: ['none', 'none'],
                                    }}
                                />
                            </ConfigProvider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
