import React, { useEffect, useState } from 'react'
import { businessIdeationBingoQuestion } from '../data'
import thumbsup from '../assets/BusinessCanvas/thumbsup.svg'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetData, bingoApi, updateScoreInfo, resetStatusError, fetchbingoApi, patchbingoApi, postToggleAnswer, resetbingoApi } from '../features/BingoSlice';
import '../index.css';
import { Button } from "antd"
import DescribeFeature from './reuseablecomponent/DescribeFeature';
import { postNewFeatureProgressApi } from './maindashboardstepper/stepperProgressSlice';
import useCheckStatusStepperProgress from '../utils/checkStatusStepperProgress';
import MessageNotification from "../components/reuseablecomponent/MessageNotification";
import bibLocals from "../localization/localization_en_business_ideation_bingo.json"
import RedirectModuleToCardView from './reuseablecomponent/RedirectModuleToCardView';
import SecondaryButton from '../DesignSystem/Buttons/SecondaryButton';
import PrimaryButton from '../DesignSystem/Buttons/PrimaryButton';

const BusinessIdeationBingo = () => {
    const selectedQuestion = useSelector((state) => state.bingo.selectedQuestion);
    const permission = useSelector((state) => state.user?.permission);
    const bibstatus = useSelector((state) => state.bingo.status)
    const biberror = useSelector((state) => state.bingo.error)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
        dispatch(fetchbingoApi());
    },[])
    // useEffect(() => {
    //     if (bibstatus === "succeeded" || bibstatus === "failed") {
    //         const timeout = bibstatus === "failed" ? 3000 : 0;
    //         const timer = setTimeout(() => {
    //             dispatch(resetStatusError());
    //             if (bibstatus === "succeeded") {
    //                 navigate('/product/home/businessideationbingo/scorecard');
    //             }
    //         }, timeout);
    //         return () => clearTimeout(timer);
    //     }
    // }, [bibstatus]);

    const checkFeatureUsageStatus = useCheckStatusStepperProgress(bibLocals.business_ideation_bingo.module_title);
    const handleSelectedField = (ref, weight) => {
        dispatch(postToggleAnswer(ref));
        dispatch(patchbingoApi({ansNumber:ref}));
    }
    const handleScore = () => {
        let bingoSum = 0;
        for(let i=0;i<businessIdeationBingoQuestion.length;i++){
            if(selectedQuestion?.includes(businessIdeationBingoQuestion[i].ref)){
                bingoSum+=businessIdeationBingoQuestion[i].score;
            }
        }
        navigate('/product/home/businessideationbingo/scorecard');
        dispatch(updateScoreInfo(bingoSum));
        // dispatch(bingoApi({ selectedQuestion: bingoScore, TotalScore: bingoSum }));
        if (!checkFeatureUsageStatus) {
            dispatch(postNewFeatureProgressApi({ status: 1, featureName: bibLocals.business_ideation_bingo.module_title }));
        }
    }
    return (
        <div className='mt-[80px] w-[calc(100%-40px)]'>
            <DescribeFeature text={bibLocals.business_ideation_bingo.module_title} description={bibLocals.business_ideation_bingo.module_Description} />
            <div className='flex justify-center flex-wrap'>
                <div className="boxes flex justify-start gap-8 flex-wrap mt-[40px]">
                    {
                        businessIdeationBingoQuestion.map((question, index) => {
                            const color = '#FFF598'
                            return (
                                <div key={index} style={{backgroundColor:question.color.bgColor,borderColor:question.color.borderColor}} className={`w-[275px] h-[250px] p-[28px] aspect-square border-[1px] flex flex-col border-black`}>
                                    <div className='flex-grow inner-width flex items-center mx-auto'>
                                        <p className='text-start text-[16px] font-InterRegular tracking-wide'>
                                            {bibLocals.business_ideation_bingo.questions[index].question}
                                        </p>
                                    </div>
                                    {(question?.q?.length > 0)
                                        ? <button disabled={!permission?.edit} onClick={() => handleSelectedField(question?.ref, question?.score)} className={`${!selectedQuestion?.includes(JSON.stringify(index+1))? 'border-[#4ADE80]' : 'border-black'} w-[50px] business_ideation_bingo_hover flex justify-center items-center mx-auto h-[50px] bg-white cursor-pointer border-[1px] rounded-md`}>
                                            {selectedQuestion?.includes(JSON.stringify(index+1)) ? <img src={thumbsup} className='w-[90%] h-[90%] thumbs_up_animation' alt="" /> : null}
                                        </button>
                                        : null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='flex justify-between w-[90%] py-[2rem]'>
                <div>
                    <RedirectModuleToCardView moduleName={'businessIdeationBingo'} />
                </div>
                <SecondaryButton onClick={() => {
                    dispatch(resetData());
                    dispatch(resetbingoApi());
                }} text={bibLocals.business_ideation_bingo.module_button_text.go_back} />
                <PrimaryButton isLoading={bibstatus === "loading"} isDisabled={!permission?.edit || bibstatus === "loading"} onClick={handleScore} text={bibLocals.business_ideation_bingo.module_button_text.completed} />
            </div>
        </div >
    )
}

export default BusinessIdeationBingo
