import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import FormLabel from '../CostOnboarding/components/FormLabel'
import ErrorFormNotFilled from '../CostOnboarding/microcomponents/ErrorFormNotFilled'
import HeadingFour from '../../../../DesignSystem/TypographyHeadings/HeadingFour'
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton'
import SecondaryButton from '../../../../DesignSystem/Buttons/SecondaryButton'
import debt_working_locals from "../../../../localization/localization_en_pAndL_workCap_debtWork_cashFlow_balanceSheet.json"
import CustomNumber from '../../subcomponents/ROFormComponents/components/CustomNumber'
import EditableTable from '../../subcomponents/common/EditableTable';
import { useNavigate } from "react-router-dom"
import useErrorCheckBMOther from '../../subcomponents/common/useErrorCheckBMOther';
import CategoryButton from '../CostOnboarding/microcomponents/CategoryButton';
import CategoryList from '../CostOnboarding/subcomponents/CategoryList';
import ListModal from '../../subcomponents/Form/ListModal';
import { API_CREATE_LoanTag_Data, API_GET_LoanTag_Data, API_PUT_LoanTag_Data, API_Process_DebtWorking_Data, API_Save_DebtWorking_Data, D_Reset_Notification, D_Reset_ErrorMessage, D_Reset_DebtWorking_State } from '../../debtWorkingSlice';
import useOpenOopsPopup from '../../subcomponents/common/useOpenOopsPopup';
import WarningPopup from '../../subcomponents/common/WarningPopup';
import useFetchBMOtherScreen from '../../../../hooks/useFetchBMOtherScreen';
import PageLoader from '../../../reuseablecomponent/PageLoader';
import TextLarge from '../../../../DesignSystem/TypographyDescriptions/TextLarge';
import DescribeFeature from '../../../reuseablecomponent/DescribeFeature';
import OutputScreen from '../RevenueOnboarding/OutputScreen';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';


const FORM_ITEMS = debt_working_locals.debt_working.form.data
const catgeoryTagDetails = debt_working_locals.tag_info.debt_working;

const RenderFormComponent = ({ formItem, debtWorkingData, isShowError }) => {
    const { SingleFormCheck } = useErrorCheckBMOther()
    const formFilledStatus = SingleFormCheck({ formItem, "CompleteSliceData": debtWorkingData })
    const loan_name_category_list = useSelector((state) => state.debtWorking.dstore.loan_name_category_list)
    const month_year = useSelector((state) => state.debtWorking.dstore?.month_year_revenue_screen1_ref)
    let availableSelectOptions = loan_name_category_list;
    const listOfPreSelectedOptions = [...debtWorkingData["future_loan_avail"].map((row) => row.loan_name), ...debtWorkingData["outstanding_loan_incur"].map((row) => row.loan_name)]
    availableSelectOptions = availableSelectOptions.filter((option) => !listOfPreSelectedOptions.includes(option.value))
    const updatedFormItem = { ...formItem, "table": { ...formItem.table, "columns": formItem.table.columns.map((col, index) => index===1 ? {...col, "select_options": availableSelectOptions} : col) } }
    
    return (
        <div className='bg-baseGray100 p-[16px] rounded-[12px] relative'>
            <FormLabel label={updatedFormItem.label} isMandatory={updatedFormItem.is_mandatory} tooltip={updatedFormItem.tooltip} />
            { updatedFormItem.component_type === "editable_table" ? <EditableTable payloadData={{ month_year, formItem: updatedFormItem, "data": debtWorkingData[updatedFormItem.name], "name": updatedFormItem.name }} /> : null }
            {(isShowError && formFilledStatus) ? <ErrorFormNotFilled /> : null}
        </div>
    )
}

const DebtWorking = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)
    const VIEW_MODE = MODE_TYPE.view;
    const EDIT_MODE = MODE_TYPE.edit;
    const { OverallFormCheck } = useErrorCheckBMOther();
    const [traceButtonClick, setTraceButtonClick] = React.useState(null);
    const [warningPopupVarient, setWarningPopupVarient] = React.useState(null);
    const { isModelOpen, handlePopUpClose, handlePopUpOpen } = useOpenOopsPopup();
    const [isShowError, setIsShowError] = React.useState(false);
    const debtWorkingData = useSelector((state) => state.debtWorking.dstore);
    const active_BusinessModal_Id = useSelector((state) => state.businessModal.active_BusinessModal_Id)
    const [isTagModalOpen, setIsTagModalOpen] = useState(false);
    const [isSkipActive, setIsSkipActive] = useState(false);
    const apiStatus = useSelector((state) => state.debtWorking.dstore.save_process_api_status);
    const { fetchTargetScreenData, api_status, activeModeType, isFutureInputPresent } = useFetchBMOtherScreen();
    const [traceButtonType, setTraceButtonType] = useState(null);
    const pAndlData = useSelector((state) => state.pAndL.output)
    const IS_API_CALLING = apiStatus.status === "loading";
    const loan_name_category_list = useSelector((state) => state.debtWorking.dstore.loan_name_category_list)
    React.useEffect(() => {
        if(api_status==="success"){
            if(activeModeType.view && isFutureInputPresent===false){
                setWarningPopupVarient("future_screen_data_not_found_view");
                handlePopUpOpen();
                return;
            }
            if(traceButtonType==="prev"){
                if(EDIT_MODE){
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/cost-onboarding`)
                    return;
                }
                navigate('/product/home/business-modal/cost-onboarding')
            }else{
                if(!!pAndlData){} // wrap up and in else block add warning tooltip
                if(EDIT_MODE){
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/p&l`)
                    return;
                }
                navigate('/product/home/business-modal/p&l')
            }
        }
        return () => setTraceButtonClick(null);
    }, [api_status])
    React.useEffect(() => {
        dispatch(D_Reset_ErrorMessage());
    }, [])
    React.useEffect(() => {
        const Status = apiStatus.status
        if(Status === 'failed'){
            handlePopUpOpen();
            if(traceButtonClick==="save") setWarningPopupVarient("save")
            if(traceButtonClick==="process") setWarningPopupVarient("process")
            dispatch(D_Reset_ErrorMessage());
        }
        if(Status === 'success' && isSkipActive){
            handleNextButton();
            dispatch(D_Reset_DebtWorking_State())
        }
    }, [apiStatus.counter])
    const handleOnSaveButton = ({ isSkip=false }) => {
        setIsShowError(true);
        setIsSkipActive(isSkip);
        setTraceButtonClick("save");
        const status = OverallFormCheck({ "overAll_form": FORM_ITEMS, "CompleteSliceData": debtWorkingData });
        if(status){
            let cloneDebtWorking = JSON.parse(JSON.stringify(debtWorkingData))
            delete cloneDebtWorking.loan_name_category_list;
            delete cloneDebtWorking.ApiStatus;
            dispatch(API_Save_DebtWorking_Data({ isSkip, "businessModelId": active_BusinessModal_Id, "future_loan_avail": isSkip ? [] : cloneDebtWorking.future_loan_avail, "outstanding_loan_incur": isSkip ? [] : cloneDebtWorking.outstanding_loan_incur }))
            console.log("save api..")
        }else{
            console.log("form not filled...");
        }
    }
    const handleProcessButton = () => {
        setTraceButtonClick("process");
        let cloneDebtWorking = JSON.parse(JSON.stringify(debtWorkingData))
        delete cloneDebtWorking.loan_name_category_list;
        delete cloneDebtWorking.ApiStatus;
        dispatch(API_Process_DebtWorking_Data({ "businessModelId": active_BusinessModal_Id, "screenInput": {...cloneDebtWorking} }))
    }
    const ApiCallingFunction = (httpType,tagDetail) => {
        //{ "id", "tag" }
        if(httpType==="patch") dispatch(API_PUT_LoanTag_Data({ "loanId": tagDetail.id, "loanTitle": tagDetail.tag }))
        if(httpType==="create") dispatch(API_CREATE_LoanTag_Data({ "loanTitle": tagDetail.tag }))
    }
    const CallbackToggleModal = () => setIsTagModalOpen((prev) => !prev)
    const CallbackResetNotificationMessage = () => dispatch(D_Reset_Notification())
    const isSaveButtonDisabled = debtWorkingData.isSaveDisabled
    const isGenerateOutputDisabled = debtWorkingData.isGenerateOutputDisabled
    const TopBackButtonDisabled = debtWorkingData.isSaveDisabled===false && debtWorkingData.isGenerateOutputDisabled===false
    const handleBackButton = () => {
        setTraceButtonType("prev");
        fetchTargetScreenData({ screenName: "costScreen", previousScreen: "debtWorkingScreen" });
    }
    function handleNextButton() {
        setTraceButtonType("next");
        fetchTargetScreenData({ screenName: "pnlScreen", previousScreen: "debtWorkingScreen" });
    }
    let isDataFilled;
    isDataFilled = debtWorkingData.future_loan_avail.find((row) => row.loan_name!==null) || debtWorkingData.outstanding_loan_incur.find((row) => row.loan_name!==null)
    const arrayOfAllCatgegoryList = loan_name_category_list
    return (
        <div className='mt-[5rem] flex flex-col gap-y-[24px]'>
            <WarningPopup warningType={warningPopupVarient} isModelOpen={isModelOpen} handlePopUpClose={handlePopUpClose} />
            <DescribeFeature text={debt_working_locals.debt_working.title} description={debt_working_locals.debt_working.skip_onboarding_tooltip} />
            { api_status==="loading" ? <PageLoader/> : null }
            <div>
                <ComponentErrorBoundary>
                    <ListModal
                        Title={catgeoryTagDetails.modalTypo.title}
                        InputPlaceholder={catgeoryTagDetails.modalTypo.placeHolder}
                        ListData={debtWorkingData.loan_name_category_list}
                        ApiStatus={debtWorkingData.ApiStatus}
                        tooltipMessages={{ warning: 'name exist already in list' }}
                        ApiCallingFunction={ApiCallingFunction}
                        isTagModalOpen={isTagModalOpen}
                        CallbackToggleModal={CallbackToggleModal}
                        CallbackResetNotificationMessage={CallbackResetNotificationMessage}
                    />
                </ComponentErrorBoundary>
                <div className='flex justify-end'>
                    <PrimaryButton text={catgeoryTagDetails.buttonName} onClick={CallbackToggleModal} isDisabled={IS_API_CALLING} />
                </div>
            </div>
            <ul className='flex flex-col gap-y-[24px]'>
                {
                    debt_working_locals.debt_working.form.data.map((formItem, index) => {
                        return (
                            <ComponentErrorBoundary>
                                <RenderFormComponent isShowError={isShowError} key={index} formItem={formItem} debtWorkingData={debtWorkingData} />
                            </ComponentErrorBoundary>
                        )
                    })
                }
            </ul>
            <div className='flex justify-between'>
                <div>
                    <SecondaryButton isDisabled={true} text={"Back"} onClick={handleBackButton} />
                </div>
                <div className='flex gap-x-[1rem]'>
                    <PrimaryButton isLoading={IS_API_CALLING && traceButtonClick==="save"} isDisabled={isSaveButtonDisabled || IS_API_CALLING} text={"Save"} onClick={handleOnSaveButton} />
                    { !!isDataFilled ? '' : <SecondaryButton isLoading={IS_API_CALLING} isDisabled={isGenerateOutputDisabled} text={"Skip"} onClick={() => handleOnSaveButton({ "isSkip": true })} /> }
                </div>
                {
                    debtWorkingData.future_loan_avail.length==0 && debtWorkingData.outstanding_loan_incur.length==0
                    ? <PrimaryButton isDisabled={false} text={"Next"} onClick={handleNextButton} />
                    : <PrimaryButton isLoading={IS_API_CALLING && traceButtonClick==="process"} isDisabled={isGenerateOutputDisabled || IS_API_CALLING || !isSaveButtonDisabled} text={"Generate Output"} onClick={handleProcessButton} />
                }
            </div>
            <div style={{ "visibility": debtWorkingData.output===null ? "hidden" : "visible" }} >
                <ComponentErrorBoundary>
                    <OutputScreen
                        category_list={arrayOfAllCatgegoryList}
                        outputData={debtWorkingData.output}
                        type={"debtWorkingScreen"}
                    />
                </ComponentErrorBoundary>
            </div>
            {
                debtWorkingData.output!==null
                ? (
                    <div className='flex justify-between'>
                        <SecondaryButton isDisabled={true} text={"Back"} onClick={handleBackButton} />
                        <PrimaryButton isDisabled={false} text={"Next"} onClick={handleNextButton} />
                    </div>
                ) : null
            }
            {/* <PrimaryButton isDisabled={false} text={"Next"} onClick={handleNextButton} /> */}
        </div>
    )
}

export default DebtWorking
