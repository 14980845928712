import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import HeadingFour from '../../../../DesignSystem/TypographyHeadings/HeadingFour';
import { API_Process_P_AND_L_Data, D_Reset_ErrorMessage, D_Update_SpanSelection } from '../../pAndLSlice'
import useOpenOopsPopup from '../../subcomponents/common/useOpenOopsPopup';
import WarningPopup from '../../subcomponents/common/WarningPopup';
import locals from '../../../../localization/localization_en_pAndL_workCap_debtWork_cashFlow_balanceSheet.json'
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton';
import SecondaryButton from '../../../../DesignSystem/Buttons/SecondaryButton';
import OutputScreen from '../RevenueOnboarding/OutputScreen';
import MonthYearLoader from '../../../../DesignSystem/Loader/MonthYearLoader';
import useFetchBMOtherScreen from '../../../../hooks/useFetchBMOtherScreen';
import PageLoader from '../../../reuseablecomponent/PageLoader';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const plLocals = locals.pAndL;
const stepperValueMapping = { 0: -5, 25: -2.5, 50: 0, 75: 2.5, 100: 5 }

const PAndL = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)
    const VIEW_MODE = MODE_TYPE.view;
    const EDIT_MODE = MODE_TYPE.edit;
    const active_BusinessModal_Id = useSelector((state) => state.businessModal.active_BusinessModal_Id)
    const categoryList = useSelector((state) => state.pAndL.pAndLStore.categoryList)
    const [warningPopupVarient, setWarningPopupVarient] = React.useState(null);
    const outputSliderValues = useSelector((state) => state.pAndL.pAndLStore.outputSliderValues)
    const ApiStatus = useSelector((state) => state.pAndL.pAndLStore.save_process_api_status)
    const pAndlData = useSelector((state) => state.pAndL.pAndLStore.output)
    const { isModelOpen, handlePopUpClose, handlePopUpOpen } = useOpenOopsPopup();
    const { fetchTargetScreenData, api_status, activeModeType, isFutureInputPresent } = useFetchBMOtherScreen();
    const [traceButtonType, setTraceButtonType] = React.useState(null);
    const spanType = useSelector((state) => state.pAndL.pAndLStore.spanType)
    React.useEffect(() => {
        if (ApiStatus.status === "failed") {
            setWarningPopupVarient("process");
            handlePopUpOpen();
            dispatch(D_Reset_ErrorMessage())
        }
    }, [ApiStatus.counter])
    React.useEffect(() => {
        if(VIEW_MODE) return;
        dispatch(API_Process_P_AND_L_Data(
            outputSliderValues.nextValue===50 
            ? { spanType, "businessModelId": active_BusinessModal_Id } 
            : { spanType, "businessModelId": active_BusinessModal_Id, "stepperValue": stepperValueMapping[outputSliderValues.nextValue] }
        ));
    }, [])
    React.useEffect(() => {
        if(api_status==="success"){
            if(activeModeType.view && isFutureInputPresent===false){
                setWarningPopupVarient("future_screen_data_not_found_view");
                handlePopUpOpen();
                return;
            }
            if(traceButtonType==="prev"){
                if(EDIT_MODE){
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/debt-working`)
                    return;
                }
                navigate('/product/home/business-modal/debt-working')
            }else{
                if(EDIT_MODE){
                    navigate(`/product/home/business-modal/edit/${active_BusinessModal_Id}/working-capital`)
                    return;
                }
                navigate('/product/home/business-modal/working-capital')
            }
        }
    }, [api_status])
    const handleBackButton = () => {
        fetchTargetScreenData({ screenName: "debtWorkingScreen", previousScreen: "pnlScreen" });
        setTraceButtonType("prev");
    }
    const handleNextButton = () => {
        fetchTargetScreenData({ screenName: "workingCapitalScreen", previousScreen: "pnlScreen" });
        setTraceButtonType("next");
    }
    const handleStepperOnChange = (value) => {
        dispatch(API_Process_P_AND_L_Data({ "spanType": spanType, "businessModelId": active_BusinessModal_Id, "stepperValue": stepperValueMapping[value] }));
    }
    const handleOnSpanSelection = (value) => {
        dispatch(D_Update_SpanSelection(value))
        dispatch(API_Process_P_AND_L_Data({ "spanType": value, "businessModelId": active_BusinessModal_Id, "stepperValue": stepperValueMapping[outputSliderValues.nextValue] }));
    }
    const arrayOfAllCatgegoryList = categoryList || [];
    return (
        <div className='mt-[5rem] flex flex-col gap-y-[24px]'>
            <WarningPopup warningType={warningPopupVarient} isModelOpen={isModelOpen} handlePopUpClose={handlePopUpClose} />
            <HeadingFour text={plLocals.title} />
            {api_status === "loading" ? <PageLoader /> : null}
            <ComponentErrorBoundary>
            {
                ApiStatus.status === "loading"
                    ? <MonthYearLoader MonthNumber={3} MonthYear={2024} TableTitle={plLocals.table.mainTableHeading} />
                    : <OutputScreen
                        category_list={arrayOfAllCatgegoryList}
                        moduleType={"pandl"}
                        type={"pandl"}
                        spanType={spanType}
                        outputData={pAndlData}
                        isStepperVisible={true}
                        handleOnSelect={handleOnSpanSelection}
                        isDropDownPresent={true}
                        stepperOnChange={handleStepperOnChange}
                        outputSliderValues={outputSliderValues}
                    />
            }
            </ComponentErrorBoundary>
            <div className='flex justify-between'>
                <SecondaryButton isDisabled={ApiStatus.status === "loading"} text={"Back"} onClick={handleBackButton} />
                <PrimaryButton isDisabled={ApiStatus.status === "loading"} text={"Next"} onClick={handleNextButton} />
            </div>
        </div>
    )
}

export default PAndL
