import React from 'react'
import { stories_banner } from '../../assets/website';
import website_locals from '../../localization/localization_en_website.json'
import MainContentSection from '../components/MainContentSection'
import ImgTextDescription from '../../DesignSystem/Cards/website/ImgTextDescription';
import useScrollToTopPage from '../../hooks/useScrollToTopPage';

const STORIES_LOCALS = website_locals.website.stories;

const PredictGrowthStories = () => {
    useScrollToTopPage();
    return (
        <div className='mx-[16px] my-[2rem]'>
            <MainContentSection locals={STORIES_LOCALS.main_content} />
            <div className='xl:grid xl:grid-cols-3 flex lg:justify-between justify-center flex-wrap gap-[24px]'>
                {
                    STORIES_LOCALS.story_list.map((story, index) => {
                        return (
                            <div key={index} className='border border-[#E0E0E0] rounded-[24px] min-h-[555px] max-w-[400px] xl:max-w-full'>
                                <ImgTextDescription
                                    image={stories_banner[story.banner_img_ref_name]}
                                    banner_bg_color={story.banner_bg_color}
                                    heading={story.title}
                                    subheading1={STORIES_LOCALS.heading.challenge}
                                    subeheading2={STORIES_LOCALS.heading.solution}
                                    description1={story.challenges}
                                    description2={story.solutions}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PredictGrowthStories