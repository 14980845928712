import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Table,
    ConfigProvider,
    Input,
    Empty,
} from "antd";
import "./index.css";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import InvestorName from "../assets/TableIcon/Investor_name.png";
import Email from "../assets/TableIcon/email.png"
import AccessImage from "../assets/TableIcon/AceessImage.png"
import CirclePlus from "../assets/TableIcon/circlePlusIc.png";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import {
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import ActionMenuInvestorFilesCard from "../DesignSystem/Cards/ActionMenuInvestorFilesCard";
import { sharedWithMeApi, sharedWithMeDirApi } from "../features/dataRoomSlice";
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useLocation } from "react-router-dom";
import TextLarge from "../DesignSystem/TypographyDescriptions/TextLarge";

// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   const day = String(date.getDate()).padStart(2, '0');
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//   const year = date.getFullYear();
//   return `${day}/${month}/${year}`;
// };

export default function InvestorDashboard() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [featureData, setFeatureData] = useState({});
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filesData, setFilesData] = useState([])
    const [data, setData] = useState([])
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const isDashboard = location.pathname === "/product/home/data-room/investor"

    const onSelectChange = (newSelectedRowKeys) => {
        setButtonDisabled(!newSelectedRowKeys.length);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            console.log("Fetching data");
            setIsInitialLoad(true);
            try {
                const response = await dispatch(sharedWithMeApi({}));

                let extractedData = [];
                if (isDashboard) {
                    const directoryIds = response.payload?.sharedDirectoryList.map((value) => value.documentId);
                    for (const dirId of directoryIds) {
                        const dirResponse = await dispatch(sharedWithMeDirApi(dirId));
                        console.log("This is the dir Response : ", dirResponse)
                        const dirData = dirResponse?.payload.dirFileList.map((obj, index) => ({
                            _id: obj._id,
                            fileId: index + 1,
                            name: obj.name,
                            userId: obj.userId,
                            organisationId: obj.organisationId,
                            path: obj.path,
                            type: obj.type,
                            description: obj.description,
                            archiveStatus: obj.archiveStatus,
                            color: obj.color,
                            createdAt: obj.createdAt,
                            updatedAt: obj.updatedAt,
                            folderName: obj.path.split('/').slice(-2, -1)[0]
                        }));
                        extractedData = [...extractedData, ...dirData];
                    }
                    const secondaryData = response?.payload.shareFileInfoList.map((obj, index) => ({
                        _id: obj._id,
                        fileId: index + directoryIds.length + 1,
                        name: obj.name,
                        userId: obj.userId,
                        organisationId: obj.organisationId,
                        path: obj.path,
                        type: obj.type,
                        description: obj.description,
                        archiveStatus: obj.archiveStatus,
                        color: obj.color,
                        createdAt: obj.createdAt,
                        updatedAt: obj.updatedAt,
                        folderName: obj.path.split('/').slice(-2, -1)[0]
                    }))

                    console.log(secondaryData)

                    extractedData = [...extractedData, ...secondaryData]
                    console.log(extractedData)
                } else {
                    extractedData = response.payload.dirFileList.map((obj, index) => ({
                        _id: obj._id,
                        fileId: index + 1,
                        name: obj.name,
                        userId: obj.userId,
                        organisationId: obj.organisationId,
                        path: obj.path,
                        type: obj.type,
                        description: obj.description,
                        archiveStatus: obj.archiveStatus,
                        color: obj.color,
                        createdAt: obj.createdAt,
                        updatedAt: obj.updatedAt,
                        folderName: obj.path.split('/').slice(-2, -1)[0]
                    }));
                }

                console.log(extractedData, "main");
                setFilesData(extractedData);
            } catch (error) {
                console.error("Failed to list directory", error);
            } finally {
                setIsInitialLoad(false);
            }
        };

        fetchData();
    }, [dispatch]);

    const columns = [
        {
            title: (
                <div className="flex items-center py-3 ml-2 bg-white justify-center">
                    <span className=" font-normal text-[#757575]">File Id</span>
                </div>
            ),
            dataIndex: "FileId",
            key: "FileId",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1 justify-center">
                        <h1 className="text-[#111827] font-medium font-sans text-sm m-0 tracking-wide pl-2">
                            {record.fileId}
                        </h1>
                    </div>
                );
            },
            width: 50,
        },
        {
            title: (
                <div className="flex items-center py-3 bg-white w-full">
                    {/* <img src={Email} alt="file-ic" className=" w-4 h-4 border mr-2" /> */}
                    <InsertDriveFileOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">File Name</h1>
                </div>
            ),
            dataIndex: "FileName",
            key: "FielName",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1 justify-start">
                        <h1 className=" text-[#111827] font-medium font-sans text-sm m-0 tracking-wide">
                            {record.name}
                        </h1>
                    </div>
                );
            },
            width: 120,
        },
        {
            title: (
                <div className="flex items-center justify-start pl-10 py-3 bg-white pl-4">
                    {/* <img
                        src={AccessImage}
                        alt="file-ic"
                        className=" w-4 object-contain h-4 border mr-2"
                    /> */}
                    <QuizOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Question</h1>
                </div>
            ),
            key: "comments",
            render: (_, record) => {
                console.log(record)
                return (
                    <div className="flex items-center py-1 ml-3">
                        <PrimaryButton text={"Ask Question"} size="Meidum" onClick={() => handleAskQuestion(record)}></PrimaryButton>
                    </div>
                );
            },
            width: 80,
        },
        {
            title: (
                <div className="flex items-center justify-center py-3 bg-white">
                    <AddCircleOutlineOutlinedIcon fontSize="small" className="text-gray-500 mr-1" />
                </div>
            ),
            key: "actions",
            render: (_, record) => (
                <div className="py-1.5 w-full flex justify-center bg-white">
                    <ActionMenuInvestorFilesCard document={record}/>
                </div>
            ),
            width: 20,
            "fixed": "right"
        },
    ];
    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card._id !== cardDetails._id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);

    const [modalTitle, setModalTitle] = useState(null);

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const handleAskQuestion = (id) => {
        setModalTitle("Ask New Question")
        setData(id)
        console.log(id)
        setIsModalVisible(true)
    }

    const handleButtonClick = () => {
        setModalTitle("Edit Investor Details");
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };

    const handleDeleteButton = () => {
        setModalTitle("Delete File");
        setIsModalVisible(true);
    };

    const closeDeleteModal = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(null);
    };

    const onDownloadClick = () => {
        setModalTitle("Download");
        setIsModalVisible(true);
    };

    const SkeletonLoader = ({ avatar = false, active = true, loading = true }) => {
        return (
            <div className="bg-neutral-200 animate-pulse w-full h-10 rounded-lg">
            </div>
        );
    };

    return (
        <div
            className="pb-10"
            style={{
                "marginTop": isDashboard ? '0px' : '4rem'
            }}
        >
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    isOpen={true}
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                    }}
                    documentList={data}
                />
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
                {
                    !isDashboard
                        ? (
                            <div className="pt-5">
                            <DescribeFeature
                                text={"Investor Dashboard"}
                                description={"Explore files with unique identifiers, allowing for actions such as asking questions, reviewing progress and adding comments"}
                            />
                            <div className="flex justify-between items-center">
                                <p className="font-DMSans text-[#111827] text-[18px] mt-6 font-thin">
                                    Company Information Files
                                </p>
                                <div className="flex gap-2 items-center">
                                    
                                </div>
                            </div>
                            </div>
                        ) : null
                }
                <div className="mx-auto">
                    <div className="mt-4 overflow-hidden">
                        {isInitialLoad ? (
                            <div className="w-full flex flex-col gap-3">
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Table: {
                                            headerBg: "#FFFFFF",
                                        },
                                    },
                                }}
                            >
                                <Table
                                    columns={columns}
                                    dataSource={filesData}
                                    rowKey={(record) => record.fileId}
                                    scroll={{ x: 1000 }}
                                    className="border border-[#9E9E9E] rounded-2xl overflow-hidden"
                                    rowClassName={() =>
                                        "bg-white border border-[#9E9E9E] px-3 mb-2"
                                    }
                                    rowSelection={rowSelection}
                                    rowHoverable={false}
                                    pagination={{
                                        pageSize: filesData?.length,
                                        position: ['none', 'none'],
                                    }}
                                    locale={{emptyText: (
                                        <span>
                                            <div className="flex flex-col items-center justify-center my-10">
                                            <Empty description="Uh-oh! No files have been shared with you for review yet." />
                                            </div>
                                        </span>
                                    )}}
                                />
                            </ConfigProvider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
