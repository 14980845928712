export const FetchValidator = ({ table, record, column, custom_validation, is_custom_validation_present }) => {
    try {
        if (column?.is_custom_validation){
            const cell = record[table.custom_deps_validation.deps_field_name].split(' ').join('_')+'_'+column.title.split(' ').join('_')
            return table.custom_deps_validation.validation[cell] ? table.custom_deps_validation.validation[cell] : table.custom_deps_validation.validation.default
        }
        if (column.validator === undefined) return null;
        if (column.validator === null) {
            if (is_custom_validation_present) {
                const validationList = custom_validation.validation;
                const validationListKey = record[custom_validation["deps_field_name"]]
                return validationList[validationListKey]
            }
            return null;
        } else {
            return column.validator;
        }
    } catch (e) {
        return null;
    }
}