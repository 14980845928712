import React from 'react'
import { Button } from "antd";
import TextLarge from '../TypographyDescriptions/TextLarge';

const CustomButtonWithPrefixIcon = ({ size = "small", btnText, isLoading, isDisabled, onClick, isIcon=true, prefixIcon, btnBgColor = "#372EE5", btnTextColor = "#EBF1FF", fontWeight}) => {
    const padding = size === "small" ? "7px 24px" : size === "medium" ? "12px 40px" : "16px 48px";
    const fontSize = size === "small" ? "16px" : size === "medium" ? "16px" : "18px";
    const styles = {
        padding,
        fontSize,
        letterSpacing: "0.2px",
        lineHeight: "140%",
        backgroundColor: btnBgColor,
        color: btnTextColor
    }
    return (
        <React.Fragment>
            <Button
                onClick={onClick}
                disabled={isDisabled}
                type='primary'
                style={styles}
                loading={isLoading}
                icon={isIcon && <img style={{ margin: 0 }} src={prefixIcon} />}
                className={`text-p9 cursor-pointer h-auto text-[16px] font-semibold`}
            >
                <TextLarge fontWeight={fontWeight} text={btnText} />
            </Button>
        </React.Fragment>
    )
}

export default CustomButtonWithPrefixIcon