import React from 'react'

const TextComponent = ({text,fontSize,fontWeight, color}) => {
  return (
    <React.Fragment>
        <p style={{color:color,fontSize:`${fontSize}px`,fontWeight, margin:'0', padding:'0'}} className='text-[#111827] font-InterRegular tracking-wide'>{text}</p>
    </React.Fragment>
  )
}

export default TextComponent