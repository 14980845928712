export function filterLatestElements(arr) {
    if (arr.length === 0) return [];
    if (arr.indexOf('0') == -1) return ['0'];
    const firstFilter = [];
    for (let i = 0; i < arr.length; i++) {
        const ele = arr[i].substring(0, 1)
        if (arr.indexOf(ele) != -1) {
            firstFilter.push(arr[i]);
        }
    }
    let lastInsertedEle = firstFilter[firstFilter.length - 1];
    const filteredArr = [];
    for (let i = 0; i < firstFilter.length - 1; i++) {
        if (lastInsertedEle[0] === firstFilter[i][0]) {
            if (firstFilter[i].length !== lastInsertedEle.length) {
                filteredArr.push(firstFilter[i]);
            }
        }
    }
    filteredArr.unshift('0');
    filteredArr.push(lastInsertedEle);
    const uniqueArr = [...new Set(filteredArr)]
    return uniqueArr;
}