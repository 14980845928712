import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import { months } from '../../data/data';
import DefineFormItem from '../../subcomponents/ROFormComponents/DefineFormItem';
import { D_Control_Rendering_Output, filterAcquiringUserOption, D_HandleCheckBoxInputSelect, D_UpdateMarketingSpendData } from "../../revenueSlice"
import { marketing_spend_per_sale_channel } from '../../functionality/RevenueOnboarding/generateDefaultData';
import { RevenueOnboardingFormStatus } from '../../functionality/RevenueOnboarding/CheckIndividualFormIsCompleted';
import { generateMonth } from '../../functionality/RevenueOnboarding/revenueOnboardingUtiliy'
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const ROScreen1 = (props) => {
    const { form, isFormFilledAsExpected, counterToTriggerFormStatus } = props;
    const dispatch = useDispatch();
    const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData);
    const { channel_sales, acquiringUserOptions, marketing_spend_per_sales_channel, marketing_spend_per_channel_fiscal_year } = revenueOnboardingData;
    React.useEffect(() => {
        if (counterToTriggerFormStatus > 0) {
            let isFilled = true;
            form.data.forEach((formItem) => {
                let errorCheckingPreInputs = {
                    "data": revenueOnboardingData[formItem.name],
                    "type": formItem.componentType,
                    "columns": formItem?.custom?.table?.[revenueOnboardingData[formItem.name]]?.columns
                }
                const status = formItem.name === "acquiring_app_users" 
                    ? revenueOnboardingData["channel_sales"].includes("Website & Mobile App") ? RevenueOnboardingFormStatus(errorCheckingPreInputs) : false
                    : RevenueOnboardingFormStatus(errorCheckingPreInputs)
                if (status === true) { // status - true  => form is not filled
                    isFilled = false;
                }
            })
            isFormFilledAsExpected(isFilled && marketing_spend_per_channel_fiscal_year!==null)
        }
    }, [counterToTriggerFormStatus])

    const handleCheckBoxOnChange = (selection) => {
        dispatch(D_HandleCheckBoxInputSelect({ name: selection.key, value: selection.value }));
        if (selection.key === "channel_sales") {
            let monthUpdatedData = JSON.parse(JSON.stringify(marketing_spend_per_sales_channel))
            dispatch(filterAcquiringUserOption(form.data[2]));
            if (selection.value in marketing_spend_per_sales_channel) {
                delete monthUpdatedData[selection.value];
                dispatch(D_UpdateMarketingSpendData(monthUpdatedData))
                return;
            }
            monthUpdatedData = { ...monthUpdatedData, [selection.value]: marketing_spend_per_sale_channel }
            if (!!marketing_spend_per_channel_fiscal_year) {
                const month_year = marketing_spend_per_channel_fiscal_year.split('-')
                const month = months[Number(month_year[1])].value;
                const year = Number(month_year[0]);
                const injectedChannelWithDate = {
                    [selection.value]: {
                        ...marketing_spend_per_sale_channel,
                        "month": month, "year": year,
                        "last_six_months": marketing_spend_per_sale_channel.last_six_months.map((data, index) => ({ ...data, "year": year, month: generateMonth({ "startMonth": Number(marketing_spend_per_channel_fiscal_year.split('-')[1]), "seriesMonth": index, outputType: "value" }) }))
                    }
                }
                dispatch(D_UpdateMarketingSpendData({ ...monthUpdatedData, ...injectedChannelWithDate }))
            } else {
                dispatch(D_UpdateMarketingSpendData(monthUpdatedData))
            }
        }
    }

    return (
        <section className='flex flex-col gap-y-[24px]'>
            {
                form.data.map((formItem, index) => {
                    let errorCheckingPreInputs = {
                        "data": revenueOnboardingData[formItem.name],
                        "type": formItem.componentType,
                        "columns": formItem?.custom?.table?.[revenueOnboardingData[formItem.name]]?.columns
                    }
                    if (index === 0) {
                        return (
                            <ComponentErrorBoundary>
                                <DefineFormItem handleCheckBoxOnChange={handleCheckBoxOnChange} errorCheckingPreInputs={errorCheckingPreInputs} key={index} index={index} formItem={formItem} select_options={formItem.select_options} data={revenueOnboardingData[formItem.name]} />
                            </ComponentErrorBoundary>
                        )
                    }
                    if (channel_sales?.length > 0) {
                        if (index === 1) {
                            const spanType = marketing_spend_per_sales_channel.type === "last_month_marketing_spend"
                            let last_month_payload = [];
                            let last_6_month_payload = [];
                            if (spanType) {
                                const overall_span = ["last_month_marketing_spend","mom_spend_growth_percent"]
                                new Array(2).fill(null).map((_, index) => {
                                    let month_payload = { category: formItem.custom.table.last_month.columns[index+1].title };
                                    if ("E-Commerce" in marketing_spend_per_sales_channel) month_payload = {...month_payload, 'E-Commerce': marketing_spend_per_sales_channel["E-Commerce"][overall_span[index]]}
                                    if ("Social Media" in marketing_spend_per_sales_channel) month_payload = {...month_payload, 'Social Media': marketing_spend_per_sales_channel["Social Media"][overall_span[index]]}
                                    if ("Website & Mobile App" in marketing_spend_per_sales_channel) month_payload = {...month_payload, 'Website & Mobile App': marketing_spend_per_sales_channel["Website & Mobile App"][overall_span[index]]}
                                    last_month_payload = [...last_month_payload, month_payload]
                                })
                            } else {
                                new Array(6).fill(null).map((_, index) => {
                                    let month_payload = { category: !!marketing_spend_per_channel_fiscal_year ? generateMonth({ "startMonth": Number(marketing_spend_per_channel_fiscal_year.split('-')[1]), "seriesMonth": index, outputType: "label" }) : `Month ${index + 1}` }
                                    if ("E-Commerce" in marketing_spend_per_sales_channel) month_payload = { ...month_payload, 'E-Commerce': marketing_spend_per_sales_channel["E-Commerce"]["last_six_months"][index].spend_value }
                                    if ("Social Media" in marketing_spend_per_sales_channel) month_payload = { ...month_payload, 'Social Media': marketing_spend_per_sales_channel["Social Media"]["last_six_months"][index].spend_value }
                                    if ("Website & Mobile App" in marketing_spend_per_sales_channel) month_payload = { ...month_payload, 'Website & Mobile App': marketing_spend_per_sales_channel["Website & Mobile App"]["last_six_months"][index].spend_value }
                                    last_6_month_payload = [...last_6_month_payload, month_payload]
                                })
                            }
                            return (
                                <ComponentErrorBoundary>
                                    <DefineFormItem errorCheckingPreInputs={errorCheckingPreInputs} key={index} index={index} formItem={formItem} data={{ channel_sales, tableData: spanType ? last_month_payload : last_6_month_payload, isChecked: marketing_spend_per_sales_channel.type === "last_six_months", tableDefination: form.data[index].custom }} />
                                </ComponentErrorBoundary>
                            )
                        }
                        if (index === 2 && acquiringUserOptions.length) return (
                            <ComponentErrorBoundary>
                                <DefineFormItem errorCheckingPreInputs={errorCheckingPreInputs} key={index} index={index} formItem={formItem} select_options={acquiringUserOptions} data={revenueOnboardingData[formItem.name]} handleCheckBoxOnChange={handleCheckBoxOnChange} />
                            </ComponentErrorBoundary>
                        )
                    }
                })
            }
        </section>
    )
}

export default ROScreen1