import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const loader = (
    <LoadingOutlined
        style={{
            fontSize: 48,
        }}
        spin
    />
);

const PageSpinner = () => {
    const isVerificationScreen = window.location.href.includes('verifyemail');
    return (
        <React.Fragment>
            <div className={`w-[100%] fixed top-0 z-[10000] flex justify-center bg-black/5 items-center h-[100vh]`}>
                <div className='fixed left-[50%] -translate-x-1/2 -translate-y-1/2 top-[40%]'>
                <Spin indicator={loader} style={{color:"#372EE5"}} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default PageSpinner