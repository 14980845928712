import React from 'react'
import { Tabs } from 'antd';
import dayjs from 'dayjs';
import VerticalScrollableTable from '../../../../../DesignSystem/Table/VerticalScrollableTable';
import { DatePicker } from "antd";
import TextExtraLarge from '../../../../../DesignSystem/TypographyDescriptions/TextExtraLarge';
import TextLarge from '../../../../../DesignSystem/TypographyDescriptions/TextLarge';
import { useSelector } from "react-redux";
import locals from "../../../../../localization/localization_en_business_modal.json"

const bm_modals_tabs = locals.business_modal.revenue.form[1].data[2].tabs;

const updateTableHeading = (columns, fiscal_year) => {
    let fiscalIndex = -2;
    return columns.map((column, index) => {
        fiscalIndex++;
        const title = Number(fiscal_year)+fiscalIndex;
        if(index > 0) {
            return { ...column, label: title};
        }
        return column;
    })
}

const Component = ({ tabDetails, tabTableProps, fiscal_year, product_category_active_tab }) => {
    const { subtitle, tableName } = tabDetails;
    let tableProps = {
        table_info_data: { table: { "columns": tabTableProps.formItem.is_label_custom && !!fiscal_year ? updateTableHeading(tabTableProps.formItem.table.columns, fiscal_year) : tabTableProps.formItem.table.columns } },
        data: tabTableProps.data[tableName],
        name: tableName,
        categoryList: tabTableProps.orgProductCategory
    }
    let tooltip;
    if(product_category_active_tab===1){
        tooltip = tabTableProps.formItem.business_contribution_tooltip
    }else if(product_category_active_tab===2){
        tooltip = tabTableProps.formItem.returns_cancellations_tooltip
    }else{
        tooltip = tabTableProps.formItem.quantity_per_order_tooltip;
    }
    const updatedTooltipData = tableProps.table_info_data.table.columns.map((column, index) => {
        if(index === 0){
            return {...column, tooltip }
        }
        return product_category_active_tab===3?{...column, "placeholder": "0", "validator": "positive_number"}: column;
    })
    tableProps = {
        ...tableProps,
        table_info_data: { table : { columns: updatedTooltipData } }
    }
    return (
        <div>
            <div className='my-[1rem] pl-[0.5rem]'>
            <TextLarge text={subtitle} fontWeight={500} />
            </div>
            <VerticalScrollableTable tableProps={tableProps} />
        </div>
    )
}

const items = bm_modals_tabs;

const TabTable = ({ tabTableProps }) => {
    const { handleDateSelection } = tabTableProps;
    const { isShowError, marketing_spend_per_channel_fiscal_year } = useSelector((state) => state.revenueOnboarding.revenueOnboardingData);
    const year = marketing_spend_per_channel_fiscal_year?.split('-')?.[0]
    const { product_category_active_tab, HANDLE_TAB_CHANGE } = tabTableProps.tabState
    const tabItems = items.map((tabItem, index) => {
        return { ...tabItem, children: <Component product_category_active_tab={Number(product_category_active_tab)} fiscal_year={year} tabTableProps={tabTableProps} tabDetails={tabItem} /> }
    })
    return (
        <div>
            {/* <div>
                <DatePicker disabled={true} allowClear={false} defaultValue={!!year ? dayjs(year, ['YYYY']) : null} picker="year" />
                { ( isShowError && year===null) ? <div className='text-sm mt-[0.5rem] text-red-600'>Please fill out fiscal month and year</div> : null }
            </div> */}
            <Tabs defaultActiveKey={product_category_active_tab} items={tabItems} size="large" onChange={(number) => HANDLE_TAB_CHANGE(number)} />
        </div>
    )
}

export default TabTable