import React from 'react'
import { Button } from 'antd'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import leftArrow from "../assets/common/404leftArrow.svg"
import HeadingOne from '../DesignSystem/TypographyHeadings/HeadingOne'
import HeadingFour from '../DesignSystem/TypographyHeadings/HeadingFour'
import TextExtraLarge from '../DesignSystem/TypographyDescriptions/TextExtraLarge'
import pageNotFoundLocals from "../localization/localization_en_page_not_found.json"
import useLocationWebOrProduct from '../hooks/useLocationWebOrProduct'

const PageNotFound = () => {
    const navigate = useNavigate();
    const IS_USER_IN_PRODUCT = useLocationWebOrProduct();
    return (
        <React.Fragment>
            <div className={
                classNames(
                    'w-full flex flex-col justify-center items-center',
                    { 'min-h-screen' : IS_USER_IN_PRODUCT },
                    { 'min-h-[calc(100vh-80px)]' : !IS_USER_IN_PRODUCT }
                )
            }>
                <HeadingOne text={pageNotFoundLocals.page_not_found.error_number} color={"#000"} />
                <HeadingFour text={pageNotFoundLocals.page_not_found.error_message} color={"#000"} />
                <TextExtraLarge className={"text-center"} text={pageNotFoundLocals.page_not_found.describe_issue} color={"#757575"} />
                <TextExtraLarge text={pageNotFoundLocals.page_not_found.guidance_text} color={"#757575"} />
                <div>
                    <Button type='primary' onClick={() => {
                        navigate(IS_USER_IN_PRODUCT ? -1 : '/')
                    }} style={{padding:"10px 22px"}} className='text-[#EBF1FF] h-auto mt-[48px] bg-mainPrimary'><img className='mr-[1rem]' src={leftArrow} alt="" /><span>{pageNotFoundLocals.page_not_found.return_back_btn_text}</span></Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PageNotFound