import React from 'react';
import { InputNumber, message } from 'antd';
import { useSelector } from "react-redux"
import appLocals from '../../../../../localization/localization_en_app.json'

const warning_message = appLocals.app.warning_message

const CustomNumber = ({ name, value, placeholder, handleOnChange, handleOnBlur, handleKeyUp, isReadOnly, isDisabled, isBordered, constrain, performActionOnClick, borderColor="white", isStrictPercentageCheck=true, suffixIcon, fontColor="black"  , className, validationType, styles }) => {
    const [focused, setFocused] = React.useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    let bordered = isBordered ? true : false;
    let extraAttrProps = {};
    // if(constrain === "positive_decimal_number"){
    //     extraAttrProps = { min: 0 }
    // }
    const CURRENCY_SYMBOL = useSelector((state) => state.globalSetting.currencySymbol)
    const DECIMAL_PLACE = useSelector((state) => state.globalSetting.active_global_settings.decimal_places)
    let PLACEHOLDER = placeholder?.includes('CURRENCY') ? placeholder?.replace('CURRENCY', CURRENCY_SYMBOL) : placeholder;
    if(PLACEHOLDER?.includes('.')){
        PLACEHOLDER = DECIMAL_PLACE===2 ? PLACEHOLDER : PLACEHOLDER.includes('%') ? PLACEHOLDER.split('.')[0]+'%' : PLACEHOLDER.split('.')[0]
    }
    const onFocus = () => {
        setFocused(true);
    };

    const onBlur = () => {
        setFocused(false);
    };
    const formattedValue = !focused && value<0 ? `(${String(value).split('-')[1]})` : value;
    return (
        <>
        {contextHolder}
        <InputNumber
            type='text'
            style={{ backgroundColor: 'white', border: `1px solid ${borderColor}`, ...styles }}
            className={className}
            id={(Number(value)>=0 || value===null) ? "#000000" : "negative-value-represent-red"}
            name={name}
            readOnly={isReadOnly}
            disabled={isDisabled}
            onClick={() => isReadOnly && (performActionOnClick && performActionOnClick())}
            suffix={<>{suffixIcon ? suffixIcon : null}</>}
            placeholder={PLACEHOLDER}
            bordered={bordered}
            controls={false}
            value={formattedValue}
            onChange={(value) => {
                handleOnChange && handleOnChange(Number(value))
            }} 
            onBlur={(e) => {
                let regex = /^(?!^\.|.*\.$)(?=.*\d)(?:-?\d*\.?\d*)$/;
                if(e.target.value && regex.test(e.target.value)===false){
                    message.open({ type: 'warning', content: warning_message.invalid_decimal_point_before_after_digit, duration: 3});
                    return;
                }
                // if(e.target.value!==null && e.target.value?.length!==0){
                //     handleOnBlur(e);
                // }
                handleOnBlur(e);
                onBlur();
            }}
            onFocus={onFocus}
            onKeyDown={e => {
                // const inputValue = e.target.value + e.key;
                // if (isStrictPercentageCheck && PLACEHOLDER.includes('%') && (Number(inputValue) > 100)) {
                //     messageApi.warning(warning_message.percentage,3)
                //     e.preventDefault();
                //     return;
                // }
                const allowedCharacters = /[0-9+\-.]/;
                const allowedKeyboardChar = (
                    e.key === 'Tab' ||
                    e.key === 'Delete' || 
                    e.key === 'Backspace' ||
                    (e.ctrlKey && e.key === 'a') ||
                    (e.ctrlKey && e.key === 'r') ||
                    (e.metaKey && e.key === 'a') ||
                    (e.metaKey && e.key === 'r') ||
                    (e.metaKey && e.key === 'c') ||
                    (e.metaKey && e.key === 'x') ||
                    (e.ctrlKey && e.key === 'x') ||
                    (e.key === 'ArrowLeft') ||
                    (e.key === 'ArrowRight')
                )
                if(allowedKeyboardChar){
                    return;
                }if (!allowedCharacters.test(e.key)) {
                    e.preventDefault();
                }
                // let isTestCasePassed = false;
                // if(validationType === 'positive_limit_no_decimal_value'){
                //     isTestCasePassed = (/^([1-9]?\d|100)$|^0$/).test(inputValue)
                // }
                // if(isTestCasePassed===false && validationType){
                //     validationType ? messageApi.warning(warning_message?.[validationType],3) : null
                //     e.preventDefault();
                // }
            }}
        />
        </>
    )
}

export default CustomNumber