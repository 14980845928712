import React from 'react'
import csv_icon from "../../assets/common/csvlogo.svg"
import pdf_icon from "../../assets/common/pdflogo.svg"
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useSelector } from 'react-redux';

const DownloadFeature = ({ isOpen, handleDownloadType, onClickButton }) => {
    const permission = useSelector((state) => state.user.permission);
    return (
        <div className='relative'>
            <button onClick={onClickButton}>
                <DownloadOutlinedIcon/>
            </button>
            {
                isOpen && permission?.download
                    ? (
                        <div className='flex flex-col justify-between p-[24px] rounded-lg items-start gap-y-[1rem] absolute top-[2.2rem] right-0 z-10 bg-white shadow-md border w-[240px] min-h-fit'>
                            <button onClick={() => handleDownloadType({ type: "csv" })} className='flex items-center justify-center gap-x-[1rem]'><img src={csv_icon} className='w-[1.5rem]' alt="" /> CSV</button>
                            {/* <button onClick={() => handleDownloadType({ type: "pdf" })} className='flex items-center justify-center gap-x-[1rem]'><img src={pdf_icon} className='w-[1.5rem]' alt="" /> PDF</button> */}
                        </div>
                    ) : null
            }

        </div>
    )
}

export default DownloadFeature
