import React, { useState, useRef, useEffect } from 'react'
import { Input, Button, Space, Table, Tag, message } from "antd";
import Search from "../idealcustomerprofile/assets/Search.svg"
import addUser from "./assets/addUser.svg";
import more from "./assets/verticalDots.svg"
import Edit from "./assets/Edit.svg"
import Lock from "./assets/Lock.svg"
import Delete from "./assets/Delete.svg"
import rotate from "./assets/rotate.svg"
import { Select } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { changePasswordProfileDataApi, resetDefaultValuesOnUnMount, updateUserDetails, updateuserId, resetLoading, keepUserDetailsData, fetchUsersDataApi, updateModalText, showPasswordCompo, handleUserInputChange, toggleUserSettingModal, togglePasswordComponent, changeFormFields, toggleActiveStateOrDeleteUserDataApi } from "./settingSlice";
import DescribeFeature from '../reuseablecomponent/DescribeFeature';
import "./setting.css"
import UserSettingModal from './UserSettingModal';
import UserForm from './UserForm';
import { useNavigate } from "react-router-dom"
import { updateStepper, updatecommingFromSetting } from '../../features/onboardingSlice';
import MessageNotification from "../reuseablecomponent/MessageNotification";
import TextComponent from '../reuseablecomponent/TextVarients/TextComponent';
import morePlane from "../../assets/common/morePlane.svg";
import useOutsideClick from '../../hooks/useOutsideClick';
import ContactUsModal from '../../DesignSystem/Modals/ContactUsModal';
import setting_locals from "../../localization/localization_en_setting.json";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const locals = setting_locals.settings.tabs['Users Settings'];

const UserSetting = () => {
    const navigate = useNavigate();
    const [searchInput, setsearchInput] = useState("");
    const [editCellNumber, setEditCellNumber] = useState(null);
    const [editInput, setEditInput] = useState(null);
    const [showUserAuthMenu, setShowUserAuthMenu] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const menuRef = useRef(null);
    const dispatch = useDispatch();
    const data = useSelector((state) => state.setting.users);
    const userSettingModal = useSelector((state) => state.setting.userSettingModal)
    const AccountSettingData = useSelector((state) => state.setting.AccountSettingData)
    const switchPasswordComponent = useSelector((state) => state.setting.switchPasswordComponent)
    const activeuserId = useSelector((state) => state.setting.activeuserId)
    const password = useSelector((state) => state.setting["Password Management"])
    const modalType = useSelector((state) => state.setting.modalType)
    const loading = useSelector((state) => state.setting.loading);
    const [filteredData, setFilteredData] = useState(data);
    const error = useSelector((state) => state.setting.error);
    const noOfUsers = useSelector((state) => state.onboarding.noOfUsers);
    const [showUserExceedModal, setShowUserExceedModal] = useState(false);
    useEffect(() => {
        dispatch(togglePasswordComponent(true))
        dispatch(fetchUsersDataApi())
        dispatch(changeFormFields());
        return () => {
            dispatch(showPasswordCompo(false));
            dispatch(resetDefaultValuesOnUnMount());
        }
    }, [])

    useOutsideClick(menuRef, () => {
        setShowUserAuthMenu(false);
        console.log("exe");
    })

    useEffect(() => {
        if (loading === "success") {
            dispatch(resetLoading());
            messageApi.open({
                type: 'success',
                content: 'Updated successfully',
            });
        }
    }, [loading])

    useEffect(() => {
        setFilteredData(data);
    }, [data])

    useEffect(() => {
        if (searchInput.trim().length == 0) {
            setFilteredData(data);
        } else {
            const filteredUser = data.filter((user) => {
                if (user?.username?.toLowerCase().includes(searchInput.toLowerCase()) || user?.email?.toLowerCase().includes(searchInput.toLowerCase())) {
                    return user;
                }
            })
            setFilteredData(filteredUser);
        }
    }, [searchInput])
    const options = locals.user_type;
    if (loading === "loading") {
        return <p className='fixed top-0 right-0 z-[100000] w-[100%] h-[100%] bg-black/40 text-white text-[20px] flex justify-center items-center'>Loading...</p>
    }
    const columns = [
        {
            title: <div className='py-[18px] pl-[1rem] text-primary font-InterRegular text-[16px] font-medium tracking-wide'>{locals.table.column1.title}</div>,
            dataIndex: 'username',
            key: 'username',
            render: (_, record) => {
                return (
                    <div className='py-[20px] pl-[1rem] bg-[#F4F7FF] pb-[16px] mb-[1rem] rounded-l-[10px]'>
                        <TextComponent fontSize={14} fontWeight={500} text={record.username?.trim().length > 0 ? record.username : '-'} />
                    </div>
                )
            },
            width: '25%'
        },
        {
            title: <div className='py-[18px] text-primary font-InterRegular text-[16px] font-medium tracking-wide'>{locals.table.column2.title}</div>,
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                return (
                    <div className='py-[20px] bg-[#F4F7FF] pb-[16px] mb-[1rem]'>
                        <TextComponent fontSize={14} fontWeight={500} text={record.email} />
                    </div>
                )
            },
            width: '30%'
        },
        {
            title: <div className='py-[18px] text-primary font-InterRegular text-[16px] font-medium tracking-wide'>{locals.table.column3.title}</div>,
            dataIndex: 'role',
            key: 'role',
            render: (_, record) => {
                return (
                    <div className='py-[20px] bg-[#F4F7FF] pb-[16px] mb-[1rem]'>
                        {/*<Select
                            disabled={true}
                            placeholder="Role"
                            value={record?.role?.role==="user" ? "User" : "Admin"}
                            options={options}
                />*/}
                        {locals.table.column3.category.user}
                    </div>
                )
            },
            width: '20%'
        },
        {
            title: <div className='py-[18px] text-primary font-InterRegular text-[16px] font-medium tracking-wide'>{locals.table.column4.title}</div>,
            key: 'status',
            dataIndex: 'status',
            render: (text) => (
                <div className='py-[20px] bg-[#F4F7FF] pb-[16px] mb-[1rem]'>
                    <TextComponent fontSize={14} fontWeight={500} text={text.status === "active"
                        ? <span className='bg-[#F5F5F5] text-[#00FF1A] px-[8px] inline-flex items-center rounded'><div className='w-[8px] h-[8px] bg-[#00FF1A] mr-[8px] rounded-full'></div>{text.status === "active" ? locals.table.column4.category.active : locals.table.column4.category.inactive}</span>
                        : <span className='bg-[#F5F5F5] text-[#FFB800] px-[8px] inline-flex items-center rounded'><div className='w-[8px] h-[8px] bg-[#FFB800] mr-[8px] rounded-full'></div>{text.status === "active" ? locals.table.column4.category.active : locals.table.column4.category.inactive}</span>} />
                </div>
            ),
        },
        {
            title: '',
            key: 'action',
            render: (_, record, index) => (
                <div className='py-[20px] bg-[#F4F7FF] pb-[16px] mb-[1rem] rounded-r-[8px]'>
                    <div>
                        <button className='m-0 p-0' onClick={() => {
                            setEditCellNumber(record._id);
                            setShowUserAuthMenu((prev) => !prev);
                        }}>
                            <img src={morePlane} alt="" />
                        </button>
                        {editCellNumber === record._id && showUserAuthMenu && <div ref={menuRef} className={`menuItems absolute top-[1rem] p-[12px] left-[2rem] z-[100] rounded-[8px] shadow-md w-[180px] h-[180px] bg-white border`}>
                            <button onClick={() => {
                                dispatch(updateModalText("edit"));
                                dispatch(updateuserId(record._id));
                                dispatch(toggleUserSettingModal());
                                dispatch(keepUserDetailsData(data[index]));
                            }} className='flex w-full p-[8px] cursor-pointer'>
                                <img src={Edit} alt="" />
                                <span className='ml-[8px]'>{locals.table.column5.dropdown.edit}</span>
                            </button>
                            <button onClick={() => {
                                if (record.status.status !== "active") {
                                    dispatch(toggleActiveStateOrDeleteUserDataApi({ userId: record._id, reason: undefined, isActive: true, isDelete: false }))
                                    dispatch(updateUserDetails({ data: { ...record, status: { status: "active" } } }));
                                } else {
                                    dispatch(keepUserDetailsData(data[index]))
                                    dispatch(updateuserId(record._id));
                                    dispatch(updateModalText("Disable"));
                                    dispatch(toggleUserSettingModal());
                                }
                            }} className='flex p-[8px] cursor-pointer'>
                                <img src={Lock} alt="" />
                                <span className='ml-[8px]'>{(record.status.status === "active") ? locals.table.column5.dropdown.enable[0] : locals.table.column5.dropdown.enable[1]}</span>
                            </button>
                            <button onClick={() => {
                                dispatch(updateuserId(record._id));
                                dispatch(updateModalText("Delete"));
                                dispatch(toggleUserSettingModal());
                            }} className='flex p-[8px] cursor-pointer'>
                                <img src={Delete} alt="" />
                                <span className='ml-[8px]'>{locals.table.column5.dropdown.delete}</span>
                            </button>
                            <button onClick={() => {
                                dispatch(updateuserId(record._id));
                                dispatch(showPasswordCompo(true))
                            }} className='flex p-[8px] cursor-pointer'>
                                <img src={rotate} alt="" />
                                <span className='ml-[8px]'>{locals.table.column5.dropdown.reset_password}</span>
                            </button>
                        </div>}
                    </div>
                </div>
            )
        },
    ];
    return (
        <div>
            {contextHolder}
            {loading == "failed" && <MessageNotification type={"error"} status={loading} content={error} />}
            {userSettingModal && <UserSettingModal options={options} isOpen={userSettingModal} type={modalType} />}
            <ContactUsModal isOpen={showUserExceedModal} handleOnClick={() => setShowUserExceedModal(false)} />
            {
                !switchPasswordComponent ?
                    <div>
                        <div className='flex'>
                            <Input value={searchInput} onChange={(e) => {
                                setsearchInput(e.target.value);
                            }} className='border w-[42%] text-[14px] font-InterRegular tracking-wide text-[#757575] border-[#BDBDBD]' size="large" placeholder={locals.search_component.placeHolder} prefix={<SearchOutlinedIcon/>} />
                            <Button onClick={() => {
                                if (noOfUsers === 5) {
                                    setShowUserExceedModal(true);
                                } else {
                                    dispatch(updatecommingFromSetting(true));
                                    dispatch(updateStepper(2));
                                    navigate("/product/onboarding");
                                }
                            }} size='large' className='bg-primary ml-[24px] text-white flex items-center'>
                                <PersonAddAltOutlinedIcon/>
                                <span className='ml-[8px] font-InterRegular tracking-wide text-[15px]'>{locals.search_component.button_text}</span>
                            </Button>
                        </div>
                        <div className='mt-[20px] w-[77%]'>
                            <Table columns={columns} dataSource={filteredData} pagination={false} />
                        </div>
                    </div>
                    :
                    <div>
                        <DescribeFeature text={AccountSettingData.title} description={""} />
                        <UserForm />
                        <div className='flex justify-end w-[80%]'>
                            <Button onClick={() => {
                                if (password["New Password"] === password["Confirm New Password"] && password["New Password"].trim().length > 0 && password["Confirm New Password"].trim().length > 0) {
                                    dispatch(changePasswordProfileDataApi(activeuserId));
                                    dispatch(showPasswordCompo());
                                } else {
                                }
                            }}
                                className='bg-primary text-white'
                            >{locals.form.confirm}</Button>
                        </div>
                    </div>
            }
        </div>
    )
}

export default UserSetting