import React, { useEffect, useState, useRef } from 'react';
import { Table, Button, Input, message } from 'antd';
import warning from "../../assets/PSM/warning.svg";
import '../../index.css';
import { deleteProblemApi, deleteSolutionApi, patchBgColor, postBgColorApi, updateOptimalSolutionModalToggle, updatingTrackRating, prepareSolutionData, updateMatrix, showDemo, updateConversationList, prepareProblemSolutionData, problemStatementApi, solutionStatementApi, toggleGenerateScoreCard, fetchingPSMDataApi, toggleShowHideExample, toggleConversationList } from '../../features/psmSlice';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Hide from "../../assets/PSM/Hide.svg";
import HideActive from "../../assets/PSM/HideActive.svg";
import Download from "../../assets/PSM/Download.svg"
import paint from "../../assets/PSM/paint.svg"
import profile from "../../assets/PSM/profile.svg"
import messageLight from "../../assets/PSM/messageLight.svg"
import messageActive from "../../assets/PSM/messageActive.svg"
import Edit from "../../assets/PSM/Edit.svg"
import EditActive from "../../assets/PSM/EditActive.svg"
import Delete from "../../assets/PSM/Delete.svg"
import DeleteActive from "../../assets/PSM/DeleteActive.svg"
import blue from "../../assets/PSM/DocImg.svg"
import plus from "../../assets/PSM/updatedPlus.svg"
import OptimalSolution from './OptimalSolution';
import DescribeFeature from '../reuseablecomponent/DescribeFeature';
import { updateCollapsedSideBar, controlledUpdateCollapsedSideBar } from '../../features/manageLinkSlice';
import purple from "../../assets/PSM/docs/purpledoc.svg"
import orange from "../../assets/PSM/docs/orangedoc.svg"
import * as converter from 'number-to-words';
import { DownloadCSV } from '../reuseablecomponent/DownloadCSV';
import { postUserActivityDataApi } from '../UserActivity/userActivitySlice';
import { postNewFeatureProgressApi } from '../maindashboardstepper/stepperProgressSlice';
import useCheckStatusStepperProgress from '../../utils/checkStatusStepperProgress';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Paint from '../reuseablecomponent/Paint';
import MessageNotification from "../reuseablecomponent/MessageNotification"
import { resetPsmStatus } from '../../features/psmSlice';
import PageSpinner from '../reuseablecomponent/PageSpinner';
import { Tooltip } from "antd";
import ToolTipModal from '../modals/ToolTipModal';
import { toggleToolTipModal } from '../../features/spamSlice';
import pdf from "../../assets/common/pdflogo.svg"
import csv from "../../assets/common/csvlogo.svg"
import { fileNameSuffix } from '../../utils/fileNameSuffix';
import OopsPopUp from '../reuseablecomponent/PopUpVarients/OopsPopUp';
import { colors } from "../../data";
import AIWelcome from '../reuseablecomponent/PopUpVarients/AIWelcome';
import AskAiButton from '../AIService/AskAiButton';
import { toggleWelcomeAi, updateProductFeature } from '../AIService/promptSlice';
import responseLogo from "../AIService/assets/responseLogo.svg"
import useOutsideClick from '../../hooks/useOutsideClick';
import psmLocals from "../../localization/localization_en_problem_solution_matrix.json"
import ControlledToggleToolTip from './subcomponents/ControlledToggleToolTip';
import { D_ToggleCommentSection, D_UpdateChatRowOrCell, D_ResetCommentState } from '../Chat/chatSlice'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium';
import WarningFreeTrialAccessResource from '../reuseablecomponent/PopUpVarients/WarningFreeTrialAccessResource';
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import RedirectModuleToCardView from '../reuseablecomponent/RedirectModuleToCardView';
import WarningLimitedResourceOopsPopup from '../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';
import { paywall_locals } from '../../localization';
import useApp from '../../hooks/useApp';

const ToolTipLocals = psmLocals.problem_solution_matrix.psm_table.solution_cell.tooltip;

const PAYWALL_LOCALS = paywall_locals.problem_solution_matrix;

const { TextArea } = Input;

const TableD = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trackFocusOnTextAreaElement = useRef(null);
  const [trackActiveTextArea, setTrackActiveTextArea] = useState({ "type": "", col: null, row: null });
  const state = useSelector((state) => state.psm.problemsolutionmatrix);
  const [isPaintOpen, setIsPaintOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const showExample = useSelector((state) => state.psm.showExample);
  const modelOpen = useSelector((state) => state.psm.optimalSolution.modelOpen)
  const isfresh = useSelector((state) => state.psm.isfresh);
  const psmStatus = useSelector((state) => state.psm.psmstatus);
  const problemStatementDataHandle = useSelector((state) => state.psm.problemStatementDataHandle);
  const maxSolutionNumber = useSelector((state) => state.psm.maxSolutionNumber);
  const [isTextVisible, setTextVisibility] = useState(false);
  const [toggleDownloadIcon, setToggleDownloadIcon] = useState(false);
  const [togglePaint, setTogglePaint] = useState(false);
  const permission = useSelector((state) => state.user.permission);
  const checkFeatureUsageStatus = useCheckStatusStepperProgress(psmLocals.problem_solution_matrix.module_title);
  const [activeSolutionIndex, setActiveSolutionIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState(null);
  const collapsed = useSelector((state) => state.link.collapsed)
  const status = useSelector((state) => state.psm.status)
  const error = useSelector((state) => state.psm.error)
  const [showOopsModal, setShowOopsModal] = useState(false);
  const [oopsModalContent, setOopsModalContent] = useState({ description1: "", description2: "", description3: "" });
  const pdfRef = useRef();
  const paintRef = useRef(null);
  const downloadRef = useRef(null);
  const deleteStatus = useSelector((state) => state.psm.deleteProblemSolution);
  const [alertColorPalette, setAlertColorPalette] = useState(false);
  const [tooltipShowHideCellTrack, setTooltipShowHideCellTrack] = useState({ index: null, i: null });
  const { upgradeRenewPaywallPopup } = useApp();

  const handlePaintHandle = () => {
    setTogglePaint((prev) => !prev)
  }

  useOutsideClick(downloadRef, () => {
    setToggleDownloadIcon(false);
  });
  useOutsideClick(paintRef, () => {
    setTogglePaint(false);
  })


  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(resetPsmStatus());
    }, 3000)
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    dispatch(fetchingPSMDataApi());
    return () => {
      // dispatch(toggleConversationList({ type: "hideList" }))
      dispatch(D_ToggleCommentSection(false))
    }
  }, []);

  useEffect(() => {
    let identifyProblemOrSolution = trackActiveTextArea.type === "problem" ? trackActiveTextArea.row !== null : trackActiveTextArea.col !== null && trackActiveTextArea.row !== null;
    if (trackFocusOnTextAreaElement.current && identifyProblemOrSolution) {
      trackFocusOnTextAreaElement.current.focus();
      const textLength = trackFocusOnTextAreaElement.current.resizableTextArea.textArea.value.length;
      trackFocusOnTextAreaElement.current.resizableTextArea.textArea.selectionStart = textLength;
      trackFocusOnTextAreaElement.current.resizableTextArea.textArea.selectionEnd = textLength;
    }
  }, [trackActiveTextArea])

  const updateProblemSolutionMatrix = (updatedMatrix) => {
    const maxNumberOfSolutions = Math.max(...updatedMatrix.map((problem) => problem?.collectionSolution?.length));
    dispatch(updateMatrix({ updatedMatrix, maxSolutionNumber: maxNumberOfSolutions }));
  };

  const addProblem = () => {
    const problemExceedTooltip = psmLocals.problem_solution_matrix.tooltip_modal_description.limit_add_problem;
    if ((showExample && state.length <= 10) || (!showExample && state.length < 10)) {
      dispatch(showDemo());
      messageApi.success(psmLocals.problem_solution_matrix.notification_message.problem, 2);
      const newBunchOfSolutions = new Array(maxSolutionNumber).fill(null).map((_, i) => {
        return { solutiondescription: "", bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0, isDisabled: -1, docColor: colors[Math.floor(Math.random() * colors?.length)] }
      })
      const newProblem = {
        problemStatement: "",
        isProblemDisabled: -1,
        collectionSolution: newBunchOfSolutions
      };
      updateProblemSolutionMatrix([...state, newProblem]);
    } else {
      setShowOopsModal((prev) => !prev);
      setOopsModalContent((prev) => ({ ...prev, ...problemExceedTooltip }));
    }
  };

  const addSolution = () => {
    const solutionExceedTooltip = psmLocals.problem_solution_matrix.tooltip_modal_description.limit_add_solution;
    if (maxSolutionNumber >= 5) {
      setShowOopsModal((prev) => !prev);
      setOopsModalContent((prev) => ({ ...prev, ...solutionExceedTooltip }));
    } else {
      dispatch(controlledUpdateCollapsedSideBar(true))
      messageApi.success(psmLocals.problem_solution_matrix.notification_message.solution, 2);
      const newSolution = { solutiondescription: "", bgColor: "", feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "MarketSize": 0, "RevenuePotential": 0, "Scalability": 0, "MarketDemand": 0, "CustomerAdoption": 0, "CompetitiveAdvantage": 0 }, comments: 0, isDisabled: -1 }

      const updatedMatrix = state.map((problem, index) => {
        if (index === 0 && showExample) {
          const updatedSolution = {
            ...problem,
            collectionSolution: [...problem?.collectionSolution, { ...newSolution, solutiondescription: psmLocals.problem_solution_matrix.psm_table.example.solution_examples[problem?.collectionSolution?.length], docColor: colors[Math.floor(Math.random() * colors?.length)] }],
          };
          return updatedSolution;
        }
        if (index === activeSolutionIndex) {
          const updatedSolution = {
            ...problem,
            collectionSolution: [...problem?.collectionSolution, { ...newSolution, docColor: colors[Math.floor(Math.random() * colors?.length)] }],
          };
          return updatedSolution;
        }
        return problem;
      });
      // Find the maximum number of collectionSolution items in any row
      const maxNumberOfSolutions = Math.max(...updatedMatrix.map((problem) => problem?.collectionSolution?.length));

      // Update the maxSolutionNumber to be equal to the maximum number of collectionSolution items
      // setMaxSolutionNumber(maxNumberOfSolutions);
      dispatch(updateMatrix({ maxSolutionNumber: maxNumberOfSolutions }));

      // Now, make sure all rows have the same number of collectionSolution items
      const finalMatrix = updatedMatrix.map((problem, index) => {
        const diff = maxNumberOfSolutions - problem?.collectionSolution?.length;//4 -3
        if (diff > 0) {
          // Add empty solutions to make the length equal to maxNumberOfSolutions
          const updatedCollectionSolution = [...problem?.collectionSolution];

          for (let i = 0; i < diff; i++) {
            updatedCollectionSolution.push({
              solutiondescription: "",
              bgColor: "",
              feasibilityRatings: { "Uniqueness": 0, "Feasibility": 0, "Market Size": 0, "Revenue Potential": 0, "Scalability": 0, "Market Demand": 0, "Customer Adoption": 0, "Competitive Advantage": 0 },
              comments: 0,
              isDisabled: -1,
              docColor: colors[Math.floor(Math.random() * colors?.length)]
            });
          }
          return { ...problem, collectionSolution: updatedCollectionSolution };
        }
        return problem;
      });

      updateProblemSolutionMatrix(finalMatrix);
    }
  };

  const handleCommentClick = (problemIndex, solutionIndex) => {
    const comment = state[problemIndex]?.collectionSolution[solutionIndex]?.comments;
  };


  const dataSource = state.map((problem, problemIndex) => {
    const problemData = {
      key: problemIndex,
      problemStatement: problem?.problemStatement,
    };

    problem.collectionSolution.forEach((solution, solutionIndex) => {
      problemData[`solutiondescription${solutionIndex}`] = solution?.solutiondescription;
      // solution.rates.forEach((rate, rateIndex) => {
      //   problemData[`rate${rateIndex}`] = rate[Object.keys(rate)[0]];
      // });
    });
    return problemData;
  });

  const checkTextExitsAndRating = (index) => {
    let counter = 0;
    for (let i = 0; i < state[index]?.collectionSolution.length; i++) {
      if (state[index]?.collectionSolution[i]?.feasibilityRatings["Uniqueness"] > 0) {
        counter++;
      }
    }
    return counter >= 2;
  }
  // console.log(state);
  const handleEnableProblemEditing = (index, type) => {
    const updatedMatrix = state.map((p, pIndex) => {
      if (pIndex === index) {
        return { ...p, isProblemDisabled: type === "onChange" ? -1 : 1 };
      }
      return p;
    });
    console.log(updatedMatrix, "test");
    updateProblemSolutionMatrix(updatedMatrix);
    dispatch(postUserActivityDataApi({ ...psmLocals.problem_solution_matrix.user_activity_message.edit }))
  }
  const handleShowCommentArea = (index, i, sid) => {
    dispatch(toggleConversationList({ type: "showList" }));
    if (!collapsed) {
      dispatch(updateCollapsedSideBar())
    }
    dispatch(updateConversationList({ row: index, col: i, sid }));
  }
  const columns = [
    {
      title: <div className='bg-[#6962eb] text-white py-3'>{showExample ? psmLocals.problem_solution_matrix.psm_table.table_header_2.column1.sub_heading_2 : psmLocals.problem_solution_matrix.psm_table.table_header_2.column1.sub_heading_1}</div>,
      dataIndex: 'problemStatement',
      key: 'problemStatement',
      align: 'center',
      width: 252,
      render: (text, record, index) => {
        // problem disabled condition - ((showExample) && index == 0) || !permission?.edit || state[index]?.isProblemDisabled == 1 || !state[index]?.problemStatement?.trim()?.length === 0
        // console.log(((showExample) && index == 0),!permission.edit, state[index].isProblemDisabled==-1,state[index].problemStatement.trim().length===0,state[index].isProblemDisabled)
        return (
          <div className='w-[252px] h-[15.8rem] border-r border-b bg-white'>
            <div>
              <TextArea
                style={{ border: 'none', color: showExample && index === 0 ? "#bfbfbf" : "black" }}
                value={text}
                maxLength={200}
                disabled={((showExample) && index == 0) ? true : !permission?.edit}
                className='border-transparent textareadisabled text-[18px] font-semibold scrollbar-hide'
                placeholder={psmLocals.problem_solution_matrix.psm_table.problem_cell.placeholder}
                onBlur={() => {
                  // console.log(index,text,"blurr");
                  if (state[index]?.problemStatement?.trim()?.length > 0) {
                    handleEnableProblemEditing(index, "blur");
                  }
                  dispatch(prepareProblemSolutionData({ row: index, problemDefination: text }));
                  dispatch(problemStatementApi((state[index]?.pid === undefined) ? { problemStatement: text, } : { problemStatement: text, pid: state[index]?.pid }));
                }}
                ref={trackActiveTextArea.row === index && trackActiveTextArea.type === "problem" ? trackFocusOnTextAreaElement : null}
                onChange={(e) => {
                  const updatedMatrix = state.map((p, pIndex) => {
                    if (pIndex === index) {
                      return { ...p, problemStatement: e.target.value };
                    }
                    return p;
                  });
                  updateProblemSolutionMatrix(updatedMatrix);
                }}
                autoSize={{ minRows: 6, maxRows: 2 }}
              />
            </div>
            <div className='bg-white pt-[1.7rem] relative'>
              <Button onClick={() => {
                handleEnableProblemEditing(index, "onChange");
                setTrackActiveTextArea({ type: "problem", row: index });
              }} style={{ backgroundColor: "transparent" }} disabled={(showExample && index == 0) || !permission.edit || (state[index].problemStatement.trim().length == 0)} className='absolute top-0 right-[2.5rem] p-0 border-none'><ModeEditOutlinedIcon className={showExample && index == 0 ? 'text-baseGray500' : 'text-primary'} fontSize='small' /></Button>
              <Button onClick={() => {
                dispatch(D_ResetCommentState())
                dispatch(deleteProblemApi({ pid: state[index]?.pid, index: index }));
              }} style={{ backgroundColor: "transparent" }} disabled={(showExample && index == 0) || !permission.delete || (state[index]?.problemStatement?.trim()?.length == 0)} className='absolute top-0 right-[1.2rem] p-0 border-none'><DeleteOutlinedIcon className={showExample && index == 0 ? 'text-baseGray500' : 'text-infoAlertError1'} fontSize='small' /></Button>
              <WarningLimitedResourceOopsPopup
                description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].view_optimal_solution.description1}
                description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].view_optimal_solution.description2}
              >
                {({ handleTogglePopup, isFreeSubscription }) => {
                  return (
                    <Button disabled={showExample && index == 0 || text?.trim()?.length === 0} readOnly={((state[index]?.problemStatement?.trim()?.length > 0) ? false : true)} onClick={() => {
                      if (isFreeSubscription) {
                        handleTogglePopup();
                        return;
                      }
                      let isPresent = true;
                      let counter = 0;
                      for (let i = 0; i < state[index]?.collectionSolution.length; i++) {
                        // if (state[index]?.collectionSolution[i]?.solutiondescription?.trim()?.length > 0) {
                        //   isPresent = false;
                        //   setShowOopsModal((prev) => !prev);
                        //   const min_two_solution_with_rating = psmLocals.problem_solution_matrix.tooltip_modal_description.min_two_solution_with_rating;
                        //   setOopsModalContent((prev) => ({ ...prev, ...min_two_solution_with_rating }));
                        //   break;
                        // }
                        if (state[index]?.collectionSolution[i]?.feasibilityRatings["Uniqueness"] > 0) {
                          counter++;
                          // isPresent = false;
                          // setShowOopsModal((prev) => !prev);
                          // const min_two_solution_with_rating = psmLocals.problem_solution_matrix.tooltip_modal_description.min_two_solution_with_rating;
                          // setOopsModalContent((prev) => ({ ...prev, ...min_two_solution_with_rating }));
                          // break;
                        }
                      }
                      if (counter >= 2) {
                        let optimalSolutionRating = {};
                        let maxi = -1, solution = "";
                        let arrayOfSolutions = [];
                        for (let i = 0; i < state[index]?.collectionSolution?.length; i++) {
                          let sum = 0;
                          if (state[index]?.collectionSolution[i]?.feasibilityRatings["Uniqueness"] !== 0) {
                            arrayOfSolutions.push(`Solution ${i + 1}`);
                            for (let value in state[index]?.collectionSolution[i]?.feasibilityRatings) {
                              sum = sum + state[index]?.collectionSolution[i]?.feasibilityRatings[value];
                            }
                            if (sum > maxi) {
                              solution = state[index]?.collectionSolution[i]?.solutiondescription;
                              optimalSolutionRating = state[index]?.collectionSolution[i];
                              maxi = sum;
                            }
                          }
                        }
                        dispatch(updateOptimalSolutionModalToggle({ solutionData: solution, arrayOfSolutions, optimalSolutionRating }));
                        if (!checkFeatureUsageStatus) {
                          dispatch(postNewFeatureProgressApi({ status: 1, featureName: "Problem Solution Matrix" }));
                        }
                      } else {
                        setShowOopsModal((prev) => !prev);
                        const min_two_solution_with_rating = psmLocals.problem_solution_matrix.tooltip_modal_description.min_two_solution_with_rating;
                        setOopsModalContent((prev) => ({ ...prev, ...min_two_solution_with_rating }));
                      }
                    }} className={`${(checkTextExitsAndRating(index)) ? 'bg-primary text-[#EBF1FF]' : 'bg-[#ebf1ff] text-[#bbc1cc]'} text-[14px] mt-[1.6rem] font-medium rounded-[8px] font-InterRegular px-[2rem]`}>{psmLocals.problem_solution_matrix.psm_table.problem_cell.overall_solution_btn_text}</Button>
                  )
                }}
              </WarningLimitedResourceOopsPopup>
            </div>
          </div>
        )
      },
      fixed: 'left',
    },
    ...new Array(maxSolutionNumber).fill(null).map((_, i) => {
      const dataIndex = `solutiondescription${i}`;
      return {
        title: <div className='bg-[#6962eb] text-white py-3'>{`Solution ${i + 1}`}</div>,
        dataIndex: dataIndex,
        align: 'center',
        key: dataIndex,
        width: 252,
        render: (text, record, index) => {
          const isTooltipDisabled = text.length > 0;
          return (
            <div style={{ backgroundColor: `${state[index]?.collectionSolution[i]?.bgColor}` }} onClick={() => {
              if (state[index]?.collectionSolution[i]?.sid && text?.length > 0) {
                if (selectedColor) {
                  dispatch(patchBgColor({ row: index, col: i, selectedColor }));
                  dispatch(postBgColorApi({ bgColor: selectedColor, solutionId: state[index]?.collectionSolution[i]?.sid }))
                  setSelectedColor(null);
                  e.stopPropagation();
                  return;
                }
              } else {
                if (selectedColor) {
                  setAlertColorPalette(true);
                }
              }
            }} className='bg-white h-[15.7rem] border-r'>
              <div className='flex'>
                <div className='ml-[12px] mt-[0.4rem]'><img className='w-[24px] h-[24px]' src={state[index]?.collectionSolution[i]?.docColor} alt="" /></div>
                <TextArea
                  onClick={(e) => {
                    if (state[index]?.collectionSolution[i]?.sid && text?.length > 0) {
                      if (selectedColor) {
                        dispatch(patchBgColor({ row: index, col: i, selectedColor }));
                        dispatch(postBgColorApi({ bgColor: selectedColor, solutionId: state[index]?.collectionSolution[i]?.sid }))
                        setSelectedColor(null);
                        e.stopPropagation();
                        return;
                      }
                    } else {
                      if (selectedColor) {
                        setAlertColorPalette(true);
                      }
                    }
                  }}
                  disabled={(showExample && index == 0) ? true : state[index]?.problemStatement?.trim()?.length === 0 ? true : !permission?.edit}
                  // readOnly={(((showExample && index == 0) ? true : state[index]?.problemStatement?.trim()?.length === 0 || state[index]?.collectionSolution[i]?.isDisabled === 1)) || !permission?.edit}
                  // disabled={}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                  maxLength={200}
                  onBlur={() => {
                    if (state[index]?.pid !== undefined) {
                      dispatch(prepareSolutionData({ row: index, col: i, solutionDefination: text }));
                      dispatch(solutionStatementApi((state[index]?.collectionSolution[i]?.sid === undefined) ? { solution: text, pid: state[index]?.pid, psmMaxSolution: maxSolutionNumber } : { solution: text, pid: state[index]?.pid, sid: state[index]?.collectionSolution[i]?.sid, psmMaxSolution: maxSolutionNumber }));
                    }
                    const updatedMatrix = state.map((p, pIndex) => {
                      if (pIndex === index) {
                        const updatedSolution = p?.collectionSolution?.map((s, sIndex) =>
                          sIndex === i ? { ...s, isDisabled: s?.solutiondescription?.length === 0 ? -1 : 1 } : s
                        );
                        return { ...p, collectionSolution: updatedSolution };
                      }
                      return p;
                    });
                    updateProblemSolutionMatrix(updatedMatrix);
                  }}
                  ref={trackActiveTextArea.col === i && trackActiveTextArea.row === index ? trackFocusOnTextAreaElement : null}
                  autoSize={{ minRows: 5.7, maxRows: 2 }}
                  placeholder={psmLocals.problem_solution_matrix.psm_table.solution_cell.placeholder}
                  className='border-transparent text-[18px] font-semibold scrollbar-hide'
                  value={text}
                  onMouseEnter={() => setActiveSolutionIndex(index)}
                  onChange={(e) => {
                    const updatedMatrix = state.map((p, pIndex) => {
                      if (pIndex === index) {
                        const updatedSolution = p?.collectionSolution?.map((s, sIndex) =>
                          sIndex === i ? { ...s, solutiondescription: e.target.value } : s
                        );
                        return { ...p, collectionSolution: updatedSolution };
                      }
                      return p;
                    });
                    updateProblemSolutionMatrix(updatedMatrix);
                  }}
                />
              </div>
              <div className='block cursor-pointer'>
                <div className='CRUD_buttons'>
                  <div className='relative top-2 profiles_pic'>
                    <div>
                      <img src={profile} className='w-[20px] absolute left-[16px]' alt="" />
                      <img src={profile} className='w-[20px] absolute left-[20px]' alt="" />
                      <img src={profile} className='w-[20px] absolute left-[24px]' alt="" />
                      <img src={profile} className='w-[20px] absolute left-[28px]' alt="" />
                    </div>
                    <WarningLimitedResourceOopsPopup
                      description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].comment.description1}
                      description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].comment.description2}
                    >
                      {
                        ({ handleTogglePopup, isFreeSubscription }) => {
                          return (
                            <button onClick={() => {
                              if (isFreeSubscription) {
                                handleTogglePopup();
                                return;
                              }
                              handleShowCommentArea(index, i, state[index]?.collectionSolution[i]?.sid)
                              dispatch(D_ToggleCommentSection(true))
                              dispatch(D_UpdateChatRowOrCell({ _id: state[index]?.collectionSolution[i]?.sid, moduleType: "problemSolutionMatrix" }))
                            }} disabled={state[index]?.collectionSolution[i]?.sid == undefined} className='absolute left-[3.2rem]'>{state[index]?.collectionSolution[i]?.comments} {psmLocals.problem_solution_matrix.psm_table.solution_cell.comment_text}</button>
                          )
                        }
                      }
                    </WarningLimitedResourceOopsPopup>
                    <div className='buttons absolute right-[16px] flex'>
                      <ControlledToggleToolTip text={ToolTipLocals.message.text} placement={ToolTipLocals.message.placement} i={i} index={index} isTooltipDisabled={isTooltipDisabled}>
                        <div>
                          {text?.trim()?.length > 0
                            ? (
                              <WarningLimitedResourceOopsPopup
                                description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].comment.description1}
                                description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].comment.description2}
                              >
                                {
                                  ({ handleTogglePopup, isFreeSubscription }) => {
                                    return (
                                      <button disabled={selectedColor !== null || (showExample && index == 0)} onClick={() => {
                                        if (isFreeSubscription) {
                                          handleTogglePopup();
                                          return;
                                        }
                                        handleShowCommentArea(index, i, state[index]?.collectionSolution[i]?.sid)
                                        dispatch(D_ToggleCommentSection(true))
                                        dispatch(D_UpdateChatRowOrCell({ _id: state[index]?.collectionSolution[i]?.sid, moduleType: "problemSolutionMatrix" }))
                                      }}>
                                        {/* <img src={ ? messageLight : messageActive} alt="" /> */}
                                        <ChatOutlinedIcon className={showExample && index == 0 ? 'text-baseGray500' : 'text-primary'} fontSize='small' />
                                      </button>
                                    )
                                  }
                                }
                              </WarningLimitedResourceOopsPopup>
                            )
                            : <ChatOutlinedIcon className='text-baseGray500' fontSize='small' />
                          }
                        </div>
                      </ControlledToggleToolTip>
                      <ControlledToggleToolTip text={ToolTipLocals.edit.text} placement={ToolTipLocals.message.placement} i={i} index={index} isTooltipDisabled={isTooltipDisabled}>
                        <div onClick={() => {
                          // setEditingCell({ row: index, col: i });
                          // console.log(row, index, col, i);
                        }}>
                          {text?.trim()?.length > 0
                            ? (
                              <button disabled={!permission.edit || selectedColor !== null || (showExample && index == 0)} onClick={() => {
                                setTrackActiveTextArea({ type: "solution", col: i, row: index });
                                const updatedMatrix = state.map((p, pIndex) => {
                                  if (pIndex === index) {
                                    const updatedSolution = p?.collectionSolution?.map((s, sIndex) =>
                                      sIndex === i ? { ...s, isDisabled: !s?.isDisabled } : s
                                    );
                                    return { ...p, collectionSolution: updatedSolution };
                                  }
                                  return p;
                                });
                                updateProblemSolutionMatrix(updatedMatrix);
                              }}>
                                <ModeEditOutlinedIcon className={showExample && index == 0 ? 'text-baseGray500' : 'text-primary'} fontSize='small' />
                              </button>
                            )
                            : <ModeEditOutlinedIcon className='text-baseGray500' fontSize='small' />
                          }
                        </div>
                      </ControlledToggleToolTip>
                      <ControlledToggleToolTip text={ToolTipLocals.delete.text} placement={ToolTipLocals.message.placement} i={i} index={index} isTooltipDisabled={isTooltipDisabled}>
                        <div>
                          {
                            text?.trim()?.length > 0
                              ? (
                                <button disabled={!permission.delete || selectedColor !== null || (showExample && index == 0)} onClick={() => {
                                  dispatch(deleteSolutionApi({ sid: state[index]?.collectionSolution[i]?.sid, rowIndex: index, solutionIndex: i }));
                                  dispatch(D_ResetCommentState())
                                  dispatch(postUserActivityDataApi({ ...psmLocals.problem_solution_matrix.user_activity_message.delete }))
                                }}>
                                  <DeleteOutlinedIcon className={showExample && index == 0 ? 'text-baseGray500' : 'text-infoAlertError1'} fontSize='small' />
                                </button>
                              )
                              : <DeleteOutlinedIcon className='text-baseGray500' fontSize='small' />
                          }
                        </div>
                      </ControlledToggleToolTip>
                    </div>
                  </div>
                </div>
                <WarningLimitedResourceOopsPopup
                  description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].evaluate_feasibility.description1}
                  description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].evaluate_feasibility.description2}
                >
                  {({ handleTogglePopup, isFreeSubscription }) => (
                    <Button disabled={(showExample && index == 0) || (text?.trim()?.length === 0) || !permission?.edit || selectedColor !== null} onClick={(e) => {
                      if (isFreeSubscription) {
                        handleTogglePopup();
                        return;
                      }
                      state.forEach((p, pIndex) => {
                        if (pIndex === index) {
                          p.collectionSolution.forEach((s, sIndex) => {
                            if (sIndex === i) {
                              //dispatch
                              // console.log(state[index].collectionSolution[i].feasibilityRatings);
                              dispatch(updatingTrackRating({ "ratings": state[index]?.collectionSolution[i]?.feasibilityRatings, row: index, col: i, sid: state[index]?.collectionSolution[i]?.sid, solutionDescription: text }));
                              navigate('/product/home/problemsolutionmatrix/ratefeasbilityparameter');
                              // console.log(s.rates,"mainRowIndex",i,"renderindex",index,pIndex,sIndex);
                            }
                          });
                        }
                      });
                      return;
                    }} style={{ backgroundColor: "#EBF1FF" }} className="button w-[90%] absolute flex justify-center bottom-[16px] left-[0.8rem]">{psmLocals.problem_solution_matrix.psm_table.solution_cell.rate_solution_btn_text}</Button>
                  )}
                </WarningLimitedResourceOopsPopup>
              </div>
            </div>
          )
        }
      };
    }),
  ];
  // console.log(state);

  if (psmStatus === "failed") {
    return <p>failed...</p>
  }
  const buttonClicked = async () => {
    try {
      // PrepareDataForCSVDownload();
      let modifiedData = [];
      dataSource.map((row) => {
        let modifiedRow = {};
        for (const key in row) {
          modifiedRow = { ...modifiedRow, [key]: typeof row[key] === "string" ? row[key].trim() : row[key] }
        }
        modifiedData.push(modifiedRow);
      })
      await DownloadCSV(modifiedData, showExample);
      // dispatch(postUserActivityDataApi({ ...psmLocals.problem_solution_matrix.user_activity_message.download_CSV }));
    } catch (error) {

    }
  }
  const downloadPDF = () => {
    const input = pdfRef.current;
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF({
    //     orientation: 'landscape'
    //   });
    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = pdf.internal.pageSize.getHeight();
    //   const imgWidth = canvas.width;
    //   const imgHeight = canvas.height;
    //   const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    //   const imgX = (pdfWidth - imgWidth * ratio) / 2;
    //   const imgY = 0;
    //   pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    //   const fileName = fileNameSuffix();
    //   pdf.save(`ProblemSolutionMatrix_${fileName}.pdf`);
    // });
    //   html2canvas(input).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF({
    //       orientation: 'landscape',
    //     });
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = pdf.internal.pageSize.getHeight();
    //     const imgWidth = canvas.width;
    //     const imgHeight = canvas.height;
    //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
    //     const imgY = 0;
    //     pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    //     const fileName = fileNameSuffix();
    //     pdf.save(`ProblemSolutionMatrix_${fileName}.pdf`)
    // });
  };


  const handleSelectedColorInPaint = (color) => {
    setSelectedColor(color);
    setTogglePaint(false);
  }

  const handleDownloadPDF = (data) => {
    console.log(data, "pdf data");
  }

  const OopsModalOpen = () => {
    setShowOopsModal((prev) => !prev);
    setOopsModalContent({ description1: "", description2: "", description3: "" });
  }

  const showSolutionButton = () => {
    if (state.length == 0) {
      return false;
    }
    if (state.length == 1 && showExample) {
      return false
    }
    return true;
  }

  return (
    <div className='pt-[80px]'>
      {contextHolder}
      {status === "failed" && <MessageNotification type={"error"} status={status} content={error} />}
      {showOopsModal && <OopsPopUp description1={oopsModalContent.description1} description2={oopsModalContent.description2} description3={oopsModalContent.description3} onClick={OopsModalOpen} isOpen={showOopsModal} />}
      {alertColorPalette && <OopsPopUp description1={psmLocals.problem_solution_matrix.tooltip_modal_description.color_cell_with_no_input.description1} onClick={() => {
        setAlertColorPalette(false);
        setSelectedColor(null);
      }} isOpen={alertColorPalette} />}
      <DescribeFeature text={psmLocals.problem_solution_matrix.module_title} description={psmLocals.problem_solution_matrix.module_Description} />
      <AIWelcome featureName={"problemSolutionMatrix"} link={"/product/home/ai/promptin/problemSolutionMatrix"} />

      <WarningLimitedResourceOopsPopup
        description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].ai.description1}
        description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].ai.description2}
      >
        {({ handleTogglePopup, isFreeSubscription }) => {
          return (
            <button
              onClick={() => {
                if (isFreeSubscription) {
                  handleTogglePopup();
                  return;
                }
                dispatch(toggleWelcomeAi());
                dispatch(updateProductFeature(featureName));
              }}
              className='flex items-center bg-primary text-white rounded-[8px] p-[4px] fixed bottom-0 right-[8px] z-10'
            >
              <img className="w-[30%]" src={responseLogo} />
              <span className="ml-[0.5rem]">Ask AI</span>
            </button>
          )
        }}
      </WarningLimitedResourceOopsPopup>
      {(psmStatus === "loading" || deleteStatus === "loading") && <PageSpinner />}
      {psmStatus === "succeeded" && <div className={`relative w-full flex`}>
        {contextHolder}
        {modelOpen && <OptimalSolution />}
        <div className={`${(maxSolutionNumber > 2 ? 'w-[86.8%]' : 'w-[68.3%]')} ${(state.length === 1) ? '34vh' : '70vh'} relative`}>
          <div>
            <div className='flex justify-between items-center'>
              <Button onClick={() => dispatch(toggleShowHideExample())} style={{ marginLeft: 0, paddingLeft: 0 }} type='link' className='font-InterRegular text-[12px] font-medium tracking-wide flex items-center'>
                <span className={`${(showExample) ? 'text-primary' : 'text-primary'} text-[14px]`}>{showExample == true ? psmLocals.problem_solution_matrix.example_text.hide : psmLocals.problem_solution_matrix.example_text.view}</span>
                <div className='ml-[4px]'>
                  {
                    showExample
                      ? <RemoveRedEyeOutlinedIcon className='text-primary' fontSize='small' />
                      : <VisibilityOffOutlinedIcon className='text-primary' fontSize='small' />
                  }
                </div>
              </Button>

              <div className='flex flex-row-reverse gap-x-[0.5rem] items-center relative'>
                <WarningLimitedResourceOopsPopup
                  module_data={{ "module": "psm", "data": dataSource, "hasExampleData": showExample }}
                  description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description1}
                  description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description2}
                >
                  {({ handleTogglePopup, isFreeSubscription, accessDownload }) => (
                    <div ref={downloadRef} className="downloadMenu">
                      <button disabled={!permission.download} type='link' onClick={() => (isFreeSubscription || accessDownload) ? handleTogglePopup() : setToggleDownloadIcon((prev) => !prev)} className='h-auto'><DownloadOutlinedIcon className='text-black' fontSize='medium' /></button>
                      {toggleDownloadIcon && <div onClick={() => setToggleDownloadIcon((prev) => !prev)} className={`p-[12px] absolute top-[2.5rem] shadow-xl border right-0 z-[10] w-[239px] bg-white rounded-[8px]`}>
                        <button disabled={!permission.download} className="flex mt-[0px] w-full p-[12px] rounded-md hover:bg-p8 border-none" onClick={async () => {
                          await buttonClicked();
                        }}>
                          <img src={csv} alt="" />
                          <span className="ml-[12px] text-[#111827] font-InterRegular text-[14px] font-medium tracking-wide">CSV</span>
                        </button>
                        {/*<Button disabled={!permission.download} onClick={() => downloadPDF(dataSource)} type="link" className="flex mt-[24px] w-full p-0 border-none">
                          <img src={pdf} alt="" />
                          <span className="ml-[12px] text-[#111827] font-InterRegular text-[14px] font-medium tracking-wide">PDF</span>
                          </Button>*/}
                      </div>}
                    </div>
                  )}
                </WarningLimitedResourceOopsPopup>
                <div ref={paintRef} className="paintMenu">
                  <button onClick={handlePaintHandle} disabled={!permission.paint} type='link' className='h-auto'><BrushOutlinedIcon fontSize='medium' className='text-black' /></button>
                  <Paint isPaintOpen={togglePaint} handleSelectedColor={handleSelectedColorInPaint} />
                </div>
                {selectedColor && <div className='flex justify-center items-center gap-x-2'>
                  <div style={{ backgroundColor: selectedColor }} className='w-[24px] h-[24px] border rounded-full'></div>
                  <span className='font-InterRegular text-[12px] text-[#9E9E9E] tracking-wide'>{psmLocals.problem_solution_matrix.color_code_selected_text}</span>
                </div>}
              </div>
            </div>
            <div className="sub_table_header border h-[50px] flex bg-[#372EE5] sticky top-[4rem]">
              <div className="h-full w-[28.5%] flex justify-center items-center text-[16px] text-white font-inter">
                {psmLocals.problem_solution_matrix.psm_table.table_header_1.column1}
              </div>
              <div className="border-r-0 w-[69.5%] h-full flex justify-center items-center text-[16px] text-white font-inter overflow-x-auto">
                {psmLocals.problem_solution_matrix.psm_table.table_header_1.column2}
              </div>
            </div>
          </div>
          <div className='flex flex-col overflow-hidden justify-center items-center' ref={pdfRef}>
            <div className="w-[100%] border mb-[1rem]" >
              <Table
                columns={columns}
                dataSource={dataSource}
                scroll={{
                  x: 'max-content',
                  y: '70vh',
                }}
                pagination={{
                  pageSize: state.length,
                  position: ['none', 'none'],
                }}
                locale={{
                  emptyText: (
                    <div className='py-[1rem] flex flex-col'>
                      <TextMedium fontWeight={500} color='' text={psmLocals.problem_solution_matrix.psm_table?.no_data?.description1} />
                      <TextMedium fontWeight={500} color='' text={psmLocals.problem_solution_matrix.psm_table?.no_data?.description2} />
                      <TextMedium fontWeight={500} color='' text={psmLocals.problem_solution_matrix.psm_table?.no_data?.description3} />
                    </div>
                  )
                }}
              />
            </div>
            <div className='flex w-[100%] justify-between'>
              <RedirectModuleToCardView moduleName={"problemSolutionMatrix"} />
              <PrimaryButton isDisabled={!permission.edit || (state.length > 0 ? !state[state.length - 1].problemStatement.length > 0 : false)} onClick={addProblem} text={psmLocals.problem_solution_matrix.problem_btn_text} />
              <div></div>
            </div>
          </div>
        </div>
        <div className={`${showSolutionButton() ? 'block' : 'hidden'} w-full ${(state.length === 1) ? '34vh' : '70vh'} relative`}>
          <button disabled={!permission.edit} onClick={addSolution} className='text-white h-auto flex absolute top-1/2 items-center rounded-lg bg-primary'>
            <img onMouseLeave={() => setTextVisibility(false)} onMouseEnter={() => { setTextVisibility(true) }} src={plus} className='p-3' />
            <span className={`pr-3 ${(!isTextVisible) ? 'hidden' : 'block transition-all duration-75 delay-75'} text-start`}>{psmLocals.problem_solution_matrix.solution_btn_text}</span>
          </button>
        </div>
      </div>
      }
    </div>
  );
};

export default TableD;
