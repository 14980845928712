import React from 'react'
import TitleDescription from '../subcomponents/TitleDescription'
import HeadingThree from '../../../DesignSystem/TypographyHeadings/HeadingThree'
import TextExtraLarge from '../../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import ResponsiveHeadingFourInter from '../../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter'
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium'
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge'

const ListOfSubAnswer = ({ currCard }) => {
    return (
        <ul
            className='list-disc flex flex-col gap-y-[0.5rem] pl-[1.5rem]'
        >
            {
                currCard?.map((ans, index) => {
                    return (
                        <li
                            key={index}
                            // className={`text-[18px] tracking-wide leading-[1.5rem] font-InterRegular font-medium text-baseGray700`}
                            // style={{ wordSpacing: "4px" }}
                        >
                            <TextMedium
                                text={ans}
                                fontWeight={400}
                            />
                        </li>
                    )
                })
            }
        </ul>
    )
}

const RenderTextBlockVarient = ({ currCard }) => {
    try {
        if (currCard?.structureType === "planeText") {
            return (
                <TitleDescription
                    title={currCard?.title}
                    description={currCard?.answer}
                />
            )
        }
        if (currCard?.structureType === "listBulletPoints") {
            return (
                <div className='flex flex-col gap-y-[16px]'>
                    <ResponsiveHeadingFourInter
                        text={currCard?.title}
                        headingCSSStyles={{ margin: 0 }}
                    />
                    {
                        (Array.isArray(currCard?.answer) && currCard?.answer?.length > 0)
                            ? (
                                <ListOfSubAnswer
                                    currCard={currCard?.answer}
                                />
                            ) : null
                    }
                </div>
            )
        }
        if (currCard?.structureType === "titleListOfBulletPoints") {
            return (
                <div className='flex flex-col gap-y-[16px]'>
                    <ResponsiveHeadingFourInter
                        text={currCard?.title}
                        headingCSSStyles={{ margin: 0 }}
                    />
                    {
                        (Array.isArray(currCard?.answer?.list) && currCard?.answer?.list?.length > 0)
                            ? (
                                <div className='flex flex-col gap-y-[1rem]'>
                                    <TextMedium
                                        text={currCard?.answer?.title}
                                        fontWeight={400}
                                    />
                                    <ListOfSubAnswer
                                        currCard={currCard?.answer?.list}
                                    />
                                </div>
                            ) : null
                    }
                </div>
            )
        }
        if (currCard?.structureType === "titleListOfBulletNestedPoints") {
            return (
                <div className='flex flex-col gap-y-[20px]'>
                    <ResponsiveHeadingFourInter
                        text={currCard?.title}
                        headingCSSStyles={{ margin: 0 }}
                    />
                    {
                        (Array.isArray(currCard?.answer?.list) && currCard?.answer?.list?.length > 0 )
                            ? (
                                <div className='flex flex-col gap-y-[1rem]'>
                                    {
                                        currCard?.answer?.list?.map((li, index) => {
                                            return (
                                                <div key={index} className='flex flex-col gap-y-[1rem]'>
                                                    <TextLarge
                                                        text={li?.title}
                                                        className={'italic'}
                                                        fontWeight={700}
                                                    />
                                                    <ListOfSubAnswer
                                                        currCard={li?.list}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                    }
                </div>
            )
        }
    } catch (error) {
        console.log(error)
        return "error"
    }
}

export default RenderTextBlockVarient