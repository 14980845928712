const priceRangeCondition = (data) => {
  let priceRange;
  if (data.mini !== null && data.maxi !== null) {
    priceRange = data.mini + "-" + data.maxi;
  } else if (data.mini !== null) {
    priceRange = data.mini + "-Open Closed";
  } else if (data.maxi !== null) {
    priceRange = 0 + "-" + data.maxi;
  } else {
    priceRange = "";
  }
  return priceRange;
};

export const formatData = (data, competitorNameKeyValue,title, CompanyName) => {
  console.log(data,competitorNameKeyValue,"json");
  let updatedColumns = data.map((data, i) => {
    let addCompetitorName = {};
      let index = 0;
      let counter=0;
    for (let perObject in competitorNameKeyValue) {
      addCompetitorName = {
        ...addCompetitorName,
        [competitorNameKeyValue[perObject] === ""
          ? (counter==0)? CompanyName : `Competitor ${index}`
          : competitorNameKeyValue[perObject]]:
          typeof data[perObject].data.data === "object"
            ? priceRangeCondition(data[perObject].data.data)
            : typeof data[perObject].data=== "boolean" ? (data[perObject].data) ? "Y" : "N" : data[perObject].data.data.trim().length==0 ? '-' : data[perObject].data.data,
      };
      index++;
      counter++;
    }
    let productTitle;
    if (typeof data.ProductHeading == "object") {
      productTitle =
        data.ProductHeading.data === null || data.ProductHeading?.data?.trim()?.length===0
          ? `Feature ${i+1}`
          : data.ProductHeading.data;
    } else {
      productTitle = data.ProductHeading;
    }
    return {
      [title]: productTitle,
      ...addCompetitorName,
    };
  });
//   console.log(updatedColumns);
return updatedColumns;
//   return updatedColumns;
};
