import React from 'react'
import { message } from "antd"
import { useSelector } from "react-redux"
import DefineFormItem from '../../subcomponents/ROFormComponents/DefineFormItem';
import { storeInputValue } from '../../revenueSlice';
import { useDispatch } from "react-redux"
import { ROPreparePayloadIsNullDataCheck } from '../../functionality/RevenueOnboarding/PreparePayloadIsNullDataCheck';
import { RevenueOnboardingFormStatus } from '../../functionality/RevenueOnboarding/CheckIndividualFormIsCompleted';
import { ValidateNumber } from '../CostOnboarding/utils/ValidateNumber';
import appLocals from '../../../../localization/localization_en_app.json'
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const Validation_Warning_Message = appLocals.app.warning_message

const ROScreen3 = ({ form, counterToTriggerFormStatus, isFormFilledAsExpected }) => {
  const screen3Form = form.data;
  const [messageApi, contextHolder] = message.useMessage();
  const decimalPlace = useSelector((state) => state.globalSetting.active_global_settings.decimal_places)
  const dispatch = useDispatch();
  const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
  React.useEffect(() => {
    if (counterToTriggerFormStatus > 0) {
      let isFilled = true;
      form.data.map((formItem) => {
        let errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck(revenueOnboardingData, formItem);
        const status = RevenueOnboardingFormStatus(errorCheckingPreInputs);
        if (status === true) {
          isFilled = false;
        }
      });
      isFormFilledAsExpected(isFilled)
    }
  }, [counterToTriggerFormStatus])
  const handleOnBlur = (event) => {
    const Validation_Status = ValidateNumber({ "validation_type": decimalPlace===2 ? screen3Form[0].validator : "positive_number", "value": event.target.value, "decimal_place": decimalPlace })
    if (Validation_Status.status) {
      dispatch(storeInputValue({ "name": "marketing_spend_conversion", "value": Number(Validation_Status.value)}))
      return;
    }
    if(event.target.value){
      messageApi.open({ type: 'warning', content: decimalPlace===0 ? Validation_Warning_Message.positive_number : Validation_Warning_Message[screen3Form[0].validator], duration: 3 });
    }
    dispatch(storeInputValue({ "name": "marketing_spend_conversion", "value": null}))
  }
  return (
    <div className='flex flex-col gap-y-[24px]'>
      {contextHolder}
      {
        screen3Form.map((formItem, index) => {
          let errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck(revenueOnboardingData, formItem)
          let data;
          if (formItem.name === "platform_commission_cost") {
            data = [...revenueOnboardingData["product_categories_details"].manufacture, ...revenueOnboardingData["product_categories_details"].buy]
            console.log(data,"data")
          }else{
            data = revenueOnboardingData[formItem.name]
          }
          return (
            <ComponentErrorBoundary>
              <DefineFormItem errorCheckingPreInputs={errorCheckingPreInputs} key={index} formItem={formItem} data={data} handleOnBlur={handleOnBlur} />
            </ComponentErrorBoundary>
          )
        })
      }
    </div>
  )
}

export default ROScreen3