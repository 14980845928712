import React from 'react'
import locals from "../../../../localization/localization_en_stepper.json"

const stepperLocals  = locals.stepper;

const StepNumber = ({ subStepNumber }) => {
    return (
        <React.Fragment>
            <div className='text-primary py-[16px]'>{stepperLocals.captions.count_heading} {subStepNumber}</div>
        </React.Fragment>
    )
}

export default StepNumber