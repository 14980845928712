export const CustomTagInputTooltip = ({ column, record, FORM_ITEM, value }) => {
    let custom_tag_input_tooltip;
    try {
        if(column?.custom_tag_placeholder){
            const selectedValue = record[FORM_ITEM?.table?.custom_tag_input_tooltip_dependency_index]?.split(' ')?.join('_')
            if(Array.isArray(FORM_ITEM?.table?.custom_tag[selectedValue])){
                custom_tag_input_tooltip = FORM_ITEM?.table?.custom_tag[selectedValue].map((tag) => {
                    return {
                        "value": value,
                        "tag": tag,
                        "placeHolder": FORM_ITEM?.table?.custom_unit[selectedValue][tag.split(' ').join('')], 
                        "tooltip": FORM_ITEM?.table?.custom_tag_input_tooltip[selectedValue][tag.split(' ').join('')]
                    }
                })
                return custom_tag_input_tooltip;
            }
            custom_tag_input_tooltip = { 
                value,
                "tag": FORM_ITEM?.table?.custom_tag[selectedValue],
                "placeHolder": FORM_ITEM?.table?.custom_unit[selectedValue], 
                "tooltip": FORM_ITEM?.table?.custom_tag_input_tooltip[selectedValue]
            }
            return custom_tag_input_tooltip;
        }
        return {};
    } catch (error) {
        return {};
    }
}