import React from 'react'
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import WarningFreeTrialAccessResource from '../../../reuseablecomponent/PopUpVarients/WarningFreeTrialAccessResource';
import WarningLimitedResourceOopsPopup from '../../../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';
import { paywall_locals } from '../../../../localization';
import useApp from '../../../../hooks/useApp';

const PAYWALL_LOCALS = paywall_locals.ideal_customer_profile;

const ICPFormHeader = ({
    handleDownloadPDF
}) => {

    const navigate = useNavigate();
    const permission = useSelector((state) => state.user.permission);
    const { upgradeRenewPaywallPopup } = useApp();

    return (
        <div>
            <div className='flex justify-end'>
                <WarningLimitedResourceOopsPopup
                    description1={paywall_locals.ideal_customer_profile[upgradeRenewPaywallPopup].download.description1}
                    description2={paywall_locals.ideal_customer_profile[upgradeRenewPaywallPopup].download.description2}
                >
                    {
                        ({ handleTogglePopup, isFreeSubscription }) => (
                            <Button disabled={!permission.edit} style={{ border: 'none' }} onClick={() => {
                                if (isFreeSubscription) {
                                    handleTogglePopup();
                                    return;
                                }
                                handleDownloadPDF();
                            }}><DownloadOutlinedIcon fontSize='medium' /></Button>
                        )
                    }
                </WarningLimitedResourceOopsPopup>
                <Button
                    className='border-none'
                    onClick={() => navigate('/product/home/buildmarketanalysis/idealcustomerprofile')}
                >
                    <CloseIcon />
                </Button>
            </div>
        </div>
    )
}

export default ICPFormHeader