import React from 'react'
import RadioInput from './RadioInput'
import VerticalScrollableTable from '../../../../../DesignSystem/Table/VerticalScrollableTable'

const SwitchTable = ({ SwitchTableProps }) => {
    const { formItem, data, categoryList, HANDLE_ON_CHANGE_SELECT_OPTION, isDisabled } = SwitchTableProps;
    const allSelectedOptionValues = [...data.product_categories_details.manufacture,...data.product_categories_details.buy].map((subdata) => subdata.name);
    const filterSelectOption = categoryList.filter((subdata) => !allSelectedOptionValues.includes(subdata.value))
    const { radioInputProps } = data;
    const form = formItem.table;
    let dynamicTableRender = [];
    const keys = Object.keys(form);
    const convertedLowerCasekeys = keys.map((key) => key.toLowerCase());
    radioInputProps.data!=null && keys.forEach((key, index) => {
        const toggleProps = { "data":data.product_categories_details[convertedLowerCasekeys[index]], "name":form[key].name, "table_info_data":form[key], categoryList: categoryList, filteredOption: filterSelectOption}
        if(convertedLowerCasekeys.includes(radioInputProps.data)){
            if(radioInputProps.data===convertedLowerCasekeys[index]){
                dynamicTableRender.push(toggleProps);
            }
        }else{
            dynamicTableRender.push(toggleProps);
        }
    })
    return (
        <div>
            <RadioInput {...radioInputProps} isDisabled={isDisabled} />
            {
                dynamicTableRender?.map((tableProp,index) => {
                    return <VerticalScrollableTable isDisabled={isDisabled} key={index} tableProps={tableProp} label={tableProp.table_info_data.sublabel} onChangeSelect={HANDLE_ON_CHANGE_SELECT_OPTION} /> 
                })
            }
        </div>
    )
}

export default SwitchTable