import React from 'react'
import TextComponent from '../../../reuseablecomponent/TextVarients/TextComponent'

const DescriptionToolTip = ({ heading, description }) => {
    return (
        <div className='p-[16px] border border-[#6D7FFF] my-[24px] rounded-[8px]'>
            <div className='mb-[12px]'>
                <TextComponent color={"#372EE5"} fontSize={18} fontWeight={600} text={heading} />
            </div>
            <TextComponent color={"424242"} fontSize={16} fontWeight={400} text={description} />
        </div>
    )
}

export default DescriptionToolTip