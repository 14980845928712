import { Select } from "antd";
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ToolTip from "../ToolTip/ToolTip";
import stoneTooltip from "../../assets/common/stoneTooltip.svg"
import whiteTooltip from "../../assets/common/whiteTooltip.svg"

const SelectOption = ({ name, value, placeholder, onChangeEvent, listOfOptions, isAllowClear = false, isDisabled, notFoundContent, handleOnClick, addMoreButtonName, isAddMoreDisabled = false, onClickAddMore, styles }) => {
    const content = notFoundContent ? <div className="text-left text-sm text-baseGray500 py-[0.25rem] pl-[0.25rem]">{notFoundContent}</div> : null;
    const notFound = notFoundContent ? { notFoundContent: content } : {};
    return (
        <>
            <Select
                name={name}
                value={value}
                disabled={isDisabled}
                placeholder={placeholder}
                onChange={onChangeEvent}
                allowClear={isAllowClear}
                onClick={handleOnClick}
                {...notFound}
                style={{ ...styles }}
            >
                {
                    listOfOptions?.map((option, index) => {
                        return (
                            <Select.Option key={index} value={option?.value} className="custom-select-option-1">
                                <span>{option?.label}</span>
                                {option?.tooltip ? <span className="w-[18px] h-[18px] aspect-square"><ToolTip tooltip={option?.tooltip} icon={option?.value === value ? whiteTooltip : stoneTooltip} /></span> : null}
                            </Select.Option>
                        )
                    })
                }
                {
                    addMoreButtonName
                        ? (
                            <Select.Option key={"add_more"}>
                                <button
                                    disabled={isAddMoreDisabled}
                                    onClick={onClickAddMore}
                                    className="flex gap-x-[0.5rem] items-center justify-center"
                                    style={{ padding: "0.3rem 0rem", width: "100%", backgroundColor: "#BECEFF", borderRadius: "0.3rem" }}
                                >
                                    <PlusOutlined />{addMoreButtonName}
                                </button>
                            </Select.Option>
                        ) : null
                }
            </Select>
            <style>
                {
                    `
                :where(.css-dev-only-do-not-override-1m62vyb).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                    height: 100% !important;
                    // border: none !important;
                }
                .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
                    padding: 0;
                    transition: all 0.3s, visibility 0s;
                    display: flex;
                    align-items: center;
                }
                .ant-select-single.ant-select-show-arrow .ant-select-selection-item, :where(.css-dev-only-do-not-override-1m62vyb).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
                    padding-inline-end: 18px;
                    display: flex;
                    align-items: center;
                }
                `
                }
            </style>
        </>
    )
}

export default SelectOption