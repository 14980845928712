import React from 'react'
import { useSelector, useDispatch } from "react-redux";
//components
import PrimaryButton from '../../../../../DesignSystem/Buttons/PrimaryButton'
//redux action
import { D_Handle_Show_Popup } from '../../../costSlice';

const CategoryButton = ({ text, type }) => {
    const dispatch = useDispatch();
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)
    const handleShowPopup = () => dispatch(D_Handle_Show_Popup({ status: true, type }))
    const IS_DISABLED = MODE_TYPE.view;
    return (
        <div className='flex justify-end'>
            <PrimaryButton text={text} onClick={handleShowPopup} isDisabled={IS_DISABLED} />
        </div>
    )
}

export default CategoryButton