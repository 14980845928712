export const ValidateFormScreen = ({ singleInputData, formItem, data, other, name }) => {
    if(singleInputData?.name==="personnel_tl_ratio"){
        return singleInputData.value===null
    }
    if(formItem?.name==="marketing_spend"){
        let status = false;
        const SPAN_TYPE = other?.["marketing_spend_span_type"];
        if(SPAN_TYPE==="last_month_marketing_spend"){
            data.forEach((subdata) => {
                if(subdata.name===null || subdata.last_month_marketing_spend===null){
                    status = true;
                }
            })
        }else{
            data.forEach((subdata) => {
                subdata.last_six_months.map((miniData) => {
                    if(miniData.spend_value === null){
                        status = true;
                    }
                })
            })
        }
        return status;
    }
    if(formItem?.name==="capacity_planning"){
        if(data.length===0) return false;
    }
    switch(formItem?.component_type){
        case 'inhouse_checkbox': return data.length===0;
        case 'editable_table': {
            // extracting mandatory fields
            const COLUMNS = formItem.table.columns;
            const FILTERED_COLUMNS = COLUMNS.filter((col) => col.is_mandatory);
            const MANDATORY_DATA_INDEX = FILTERED_COLUMNS.map((col) => col.data_index);
            // console.log(MANDATORY_DATA_INDEX,"----index----")
            // check for null , true - null is present , false - null is not present
            let status = false;
            data.forEach((subData) => {
                for(const dataIndex of Object.entries(subData)){
                    const DATA_INDEX = dataIndex[0];
                    const DATA_VALUE = dataIndex[1];
                    let DISABLED_CELL = formItem?.table?.cell_disabled_columns !== undefined ? !formItem?.table?.cell_disabled_columns?.includes(dataIndex[0]+'_'+subData?.name?.split(' ')?.join('_')) : true;
                    if(dataIndex[0]==="other_input" && subData.calculation_methodology==="Orders Per Person"){
                        DISABLED_CELL = false;
                    }
                    if(MANDATORY_DATA_INDEX?.includes(DATA_INDEX) && DISABLED_CELL){
                        if(DATA_VALUE===null) status = true;
                        if(typeof DATA_VALUE==="object"){
                            if(!!DATA_VALUE){
                                for(const prop of Object.entries(DATA_VALUE)){
                                    if(prop[1]===null) status = true;
                                }
                            }
                        }
                    }
                }
            })
            // console.log(status,"----demo----",form.name);
            console.log("---------------------------exit-------------------------------------")
            return status;
        }
    }
}