import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import DefineFormItem from '../../subcomponents/ROFormComponents/DefineFormItem';
import AddProductCategory from '../../subcomponents/Form/AddProductCategory';
import { ROPreparePayloadIsNullDataCheck } from '../../functionality/RevenueOnboarding/PreparePayloadIsNullDataCheck';
import { RevenueOnboardingFormStatus } from '../../functionality/RevenueOnboarding/CheckIndividualFormIsCompleted';
import ListModal from '../../subcomponents/Form/ListModal';
import { API_CREATE_CHANNEL_LIST_Data, API_FETCH_CHANNEL_LIST_Data, API_PATCH_CHANNEL_LIST_Data } from "../../revenueSlice";
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const ROScreen4 = ({ form, counterToTriggerFormStatus, isFormFilledAsExpected }) => {
  const dispatch = useDispatch();
  const screen3Form = form.data;
  const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
  const [isTagModalOpen, setIsTagModalOpen] = React.useState(false);
  const ListData = revenueOnboardingData.category_list.channel_name
  const ApiStatus = revenueOnboardingData.api_status_category.channel_name
  const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)

  const VIEW_MODE = MODE_TYPE.view;
  React.useEffect(() => {
    if (counterToTriggerFormStatus > 0) {
      let isFilled = true;
      form.data.map((formItem) => {
        let errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck(revenueOnboardingData, formItem);
        const status = RevenueOnboardingFormStatus(errorCheckingPreInputs);
        if(status === true){
          isFilled = false;
      }
      });
      isFormFilledAsExpected(isFilled)
    }
  }, [counterToTriggerFormStatus])
  const CallbackToggleModal = () => setIsTagModalOpen((prev) => !prev);
  const CallbackResetNotificationMessage = () => dispatch(D_RESET_ERROR_MESSAGE({ type: 'product_category' }));
  const ApiCallingFunction = (type, arg) => {
    switch(type){
      case 'fetch': {
        dispatch(API_FETCH_CHANNEL_LIST_Data());
        break;
      }
      case 'create': {
        dispatch(API_CREATE_CHANNEL_LIST_Data({ "marketplaceChannelTitle": arg.tag }))
        // dispatch(API_Create_Channel_Name(arg.tag));
        break;
      }
      case 'patch': {
        dispatch(API_PATCH_CHANNEL_LIST_Data({ "marketplaceChannelTitle": arg.tag, "marketplaceChannelId": arg.id }));
        break;
      }
    }
  }
  return (
    <div className='flex flex-col gap-y-[24px]'>
      <div className='flex justify-end'>
        {
          isTagModalOpen
          ? <ListModal
              Title={"Channel Name"}
              InputPlaceholder={"Enter Name"}
              warningDataIndex={"Channel"}
              ListData={ListData}
              ApiStatus={ApiStatus}
              tooltipMessages={{ warning: 'Channel name exist already in list' }}
              ApiCallingFunction={ApiCallingFunction}
              isTagModalOpen={isTagModalOpen}
              CallbackToggleModal={CallbackToggleModal}
              CallbackResetNotificationMessage={CallbackResetNotificationMessage}
          />
          : null
        }
        <PrimaryButton isDisabled={VIEW_MODE} onClick={() => setIsTagModalOpen(true)} text={"Create Channel"} />
      </div>
      {
        screen3Form.map((formItem, index) => {
          let errorCheckingPreInputs = ROPreparePayloadIsNullDataCheck(revenueOnboardingData, formItem)
          return (
            <ComponentErrorBoundary>
              <DefineFormItem errorCheckingPreInputs={errorCheckingPreInputs} key={index} formItem={formItem} data={revenueOnboardingData[formItem.name]} />
            </ComponentErrorBoundary>
          )
        })
      }
    </div>
  )
}

export default ROScreen4