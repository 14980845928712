export const convertStringInK = (value) => {
    try {
        let cleanedValue = value.includes('.') ? value.split('.')[0] : value
        let splittedValue = cleanedValue.split(',')
        if (String(splittedValue[splittedValue.length - 1]).length === 3 && splittedValue.length > 1) {
            splittedValue[splittedValue.length - 1] = 'k'
            return splittedValue.join(',').slice(0, splittedValue.join(',').length - 2) + 'k'
        }
        return value
    } catch (e) {
        return value
    }
}