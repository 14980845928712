import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import { useNavigate } from "react-router-dom"
import { useRef, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { fileNameSuffix } from "../../../utils/fileNameSuffix"
import { TEMPLATE_NAME, icpImageAndBgColor, ICP_GENDER_PROFILE_IMAGE } from "../data"
import { postUserActivityDataApi } from "../../UserActivity/userActivitySlice"
import {
    toggleModal,
    uploadICPAvatarApi,
    toggleShowEditICP,
    uploadAvatarICPLocal,
    updateICPCustomTextDataApi,
    updateTextArea,
    resetStatusErr,
    resetAnswerData,
    fetchingAndPostingCustomICPDataApi,
    toggleCustomTemplate,
    updateActiveDocId
} from "../../idealcustomerprofile/ICPSlice"
import locals from "../../../localization/localization_en_ideal_customer_profile.json";

const icp = locals.ideal_customer_profile.idealCustomerProfile;
const defaultValues = locals.ideal_customer_profile.template_default_value

const useICPViewTemplate = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const download_ref = useRef(null);
    const [isDownloading, setisDownloading] = useState(false);
    const [isEditTemplateUserName, setIsEditTemplateUserName] = useState(false);
    const {
        error,
        status,
        Answer,
        showEdit,
        commingFrom,
        TemplateName,
        isCustomTemplateOpen,
        TemplateNameToDownload,
        editDataFormDetails,
        fetchStatus,
        activeDocId,
        modelIsOpen,
        isWorkSpaceHasName,
        active_template_type,
        workSpace: workSpaceList
    } = useSelector((state) => state.icp)

    const demographicOrFirmographic = active_template_type==="b2b" ? "Firmographics" : "Demographics"
    const templateType = active_template_type==="b2b" ? "Custom B2B Template" : "Custom B2C Template"

    const templateUsername = Answer?.[demographicOrFirmographic]?.["Persona Name"];

    useEffect(() => {
        if (fetchStatus !== "failed") {
            const timer = setTimeout(() => {
                dispatch(resetStatusErr());
            }, 3000)
            return () => {
                clearTimeout(timer);
                dispatch(resetAnswerData());
            }
        }
    }, [error])
    

    useEffect(() => {
        if (isCustomTemplateOpen) {
            if (activeDocId) {
                dispatch(fetchingAndPostingCustomICPDataApi({ type: "workspace", "templateType": templateType }));
            } else {
                dispatch(fetchingAndPostingCustomICPDataApi({ type: "addTemplate", "templateType": templateType }));
            }
        }
        // return () => {
        //     if (isCustomTemplateOpen) {
        //         dispatch(toggleCustomTemplate());
        //         dispatch(updateActiveDocId({ commingFrom: "deleteActiveDocId", activeDocId: null }));
        //     }
        // }
    }, [])

    const personaName = !isEditTemplateUserName && templateUsername?.trim()?.length == 0 ? "John Doe" : templateUsername

    const TemplateBgColorAndImg = icp.find((template) => {
        if (template.title === TemplateName) {
            return template
        }
    })

    const fetchProfilePic = () => {
        let profileImage = null;

        if (isCustomTemplateOpen && !isDownloading) {
            profileImage = Answer?.templateImage;
        } else {
            profileImage = Answer?.templateImage ? Answer?.templateImage : TEMPLATE_NAME.includes(TemplateName) ? ICP_GENDER_PROFILE_IMAGE[TemplateName].img : Answer?.templateImage
        }

        return profileImage;
    }

    const handleDownloadPDF = () => {
        setisDownloading(true);
        setTimeout(() => {
            const input = download_ref.current;
            html2canvas(input).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                // Calculate the PDF size based on the captured content
                const imgWidth = 210; // A4 page width in mm
                const pageHeight = (imgWidth * canvas.height) / canvas.width;
                const doc = new jsPDF('p', 'mm', 'a4');
                doc.addImage(imgData, 'PNG', 0, 1, imgWidth, pageHeight);
                const fileName = fileNameSuffix();
                // Save the PDF file
                doc.save(`IdealCustomerProfile_${TemplateNameToDownload}_${fileName}.pdf`);
                setisDownloading(false);
            });
            dispatch(postUserActivityDataApi({ ...notificationLocals.notification_message.ideal_customer_profile.download }))
        }, 100);
    };

    const handleImageUpload = (value) => {
        dispatch(uploadICPAvatarApi(value));
        dispatch(toggleShowEditICP(false));
        dispatch(uploadAvatarICPLocal(value));
    }

    const handleShowEditIconUploadComponent = () => {
        dispatch(toggleShowEditICP(true));
    }

    const handleOnBlurProfileName = () => {
        setIsEditTemplateUserName(false);
        dispatch(updateICPCustomTextDataApi({
            "AttributeName": demographicOrFirmographic,
            "FieldName": "Persona Name",
            data: Answer?.[demographicOrFirmographic]?.["Persona Name"]
        }));
    }

    const handleOnClickProfileName = () => {
        if (isCustomTemplateOpen) {
            setIsEditTemplateUserName(true)
        }
    }

    const handleOnChangeInput = (activeTabName, name, activevalue, actualValue) => {
        // console.log("exe", activeTabName, name, activevalue, actualValue)
        dispatch(updateTextArea({ activeTabName, name, value: actualValue.trim().length == 0 && name != "Persona Name" ? `• ${activevalue}` : activevalue }));
    }

    const handleOnBlurInput = ({ AttributeName, FieldName, data }) => {
        dispatch(updateICPCustomTextDataApi({ AttributeName, FieldName, data }))
    }

    const handleEditForm = () => {
        navigate('/product/home/buildmarketanalysis/idealcustomerprofile/edit')
    }

    const handleSaveForm = () => {
        if (!(isWorkSpaceHasName?.length > 2)) {
            dispatch(toggleModal(true))
        } else {
            navigate('/product/home/buildmarketanalysis/idealcustomerprofile')
        }
    }

    const handleTextareaKeyPress = (e, activeTabName, name) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // Prevent the default behavior of the Enter key
          dispatch(updateTextArea({ activeTabName, name, value: e.target.value + '\n• ' }))
        }
      };

    const fetchTemplateData = () => {
        let data = {};
        if (isCustomTemplateOpen) {
            data = { ...Answer };
            delete data.workSpaceName;
            delete data?.templateImage;
            delete data?.organisationId;
        } else {
            data = commingFrom === "workspace" ? { ...Answer } : defaultValues?.[TemplateName];
            delete data?.workSpaceName;
            delete data?.templateImage;
            delete data?.organisationId;
        }
        return data;
    }

    return {
        icp,
        error,
        status,
        fetchStatus,
        modelIsOpen,
        workSpaceList,
        active_template_type,
        TemplateName,
        "profileName": isEditTemplateUserName ? personaName : isEditTemplateUserName || personaName?.trim().length ? personaName : "Jane Doe",
        "templateIcon": icpImageAndBgColor?.[TemplateBgColorAndImg?.title]?.templateIcon,
        "templateBgColor": icpImageAndBgColor?.[TemplateBgColorAndImg?.title]?.bgColor,
        "templateName": commingFrom === "workspace" ? TemplateName : editDataFormDetails?.title,
        "data": fetchTemplateData(),
        demographicOrFirmographic,
        showEdit,
        isDownloading,
        Answer,
        download_ref,
        handleDownloadPDF,
        isCustomTemplateOpen,
        profileImage: fetchProfilePic(),
        handleImageUpload,
        handleShowEditIconUploadComponent,
        isEditTemplateUserName,
        handleOnBlurProfileName,
        handleOnChangeInput,
        handleOnClickProfileName,
        handleOnBlurInput,
        handleEditForm,
        handleSaveForm,
        handleTextareaKeyPress
    }

}

export default useICPViewTemplate