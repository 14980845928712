import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import profile from "../assets/PSM/profile.svg";
import {
    Button,
    Table,
    ConfigProvider,
    Menu,
    Dropdown,
    Skeleton,
    Empty
} from "antd";
import "./index.css";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import CirclePlus from "../assets/TableIcon/circlePlusIc.png";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import FileIC from "../assets/TableIcon/fileIc.png";
import OwnerIC from "../assets/TableIcon/owner.png";
import ReviewTick from "../assets/TableIcon/reviewTick.png";
import CommentIc from "../assets/TableIcon/commentIc.png";
import TagIc from "../assets/TableIcon/tags.png";
import LinkIc from "../assets/TableIcon/LinkIc.png";
import {
    PlusOutlined,
} from "@ant-design/icons";
import { questionAskReplyListApi, questionAskReplyListApiWithoutDocumentId, reviewDocumentStatusApi, sharedWithMeApi } from "../features/dataRoomSlice";
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import QuestionAndAnswerCard from "../DesignSystem/Cards/QuestionAndAnswerCard";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

export default function QuestionAnswer() {
    const dispatch = useDispatch();
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filesData, setFilesData] = useState([])
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const user = useSelector((state) => state.user);

    const onSelectChange = (newSelectedRowKeys) => {
        setButtonDisabled(!newSelectedRowKeys.length);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsInitialLoad(true)
                const reponse = await dispatch(questionAskReplyListApiWithoutDocumentId(""));
                const extractedData = reponse.payload?.getList.map((obj, index) => {
                    return {
                        _id: obj._id,
                        fileId: index + 1,
                        name: obj?.documentId ? obj.documentId.name : "",
                        investorName: "Investor",
                        progress: obj.status,
                        directoryName: obj.documentId ? obj.documentId.path.split('/')[(obj.documentId.path.split('/')).length - 2] : "",
                        documentId: obj.documentId._id,
                        subject: obj.subject,
                    }
                })
                console.log(extractedData)
                setFilesData(extractedData);
            } catch (error) {
                console.error("Failed to list directory", error);
                console.error(error);
            } finally {
                setIsInitialLoad(false)
            }
        };

        fetchData();
    }, [dispatch]);

    const Dummydata = [
        {
            fileId: 1,
            name: "File ABC 1",
            directoryName: "Folder 1",
            investorName: "Investor Name",
            questions: "Question",
            progress: "In Progress",
        },
        {
            fileId: 2,
            name: "File ABC 2",
            directoryName: "Folder 1",
            investorName: "Investor Name",
            questions: "Question",
            progress: "In Progress",

        },
        {
            fileId: 3,
            name: "File ABC 3",
            directoryName: "Folder 1",
            investorName: "Investor Name",
            questions: "Question",
            progress: "In Progress",
        }
    ]

    const columns = [
        {
            title: (
                <div className="flex items-center py-3 ml-2 bg-white">
                    <span className=" font-normal text-[#757575]">File ID</span>
                </div>
            ),
            dataIndex: "fileId",
            key: "fileId",
            render: (_, record) => {
                return (
                    <div className="py-2">
                        <h1 className="text-[#111827] font-medium text- pl-2">
                            {record.fileId}
                        </h1>
                    </div>
                );
            },
            width: 70,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img src={FileIC} alt="file-ic" className=" w-3 h-3.5 border mr-2" /> */}
                    <InsertDriveFileOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">File Name</h1>
                </div>
            ),
            dataIndex: "fileName",
            key: "fileName",
            render: (_, record) => {
                return (
                    <div className="flex">
                        <h1 className=" text-[#111827] font-medium font-sans ">
                            {record.name}
                        </h1>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    <h1 className="m-0  font-normal text-[#757575]">Folder Name</h1>
                </div>
            ),
            dataIndex: "folderName",
            key: "folderName",
            render: (_, record) => {
                return (
                    <div className="flex">
                        <h1 className=" text-[#111827] font-medium font-sans ">
                            {record.directoryName}
                        </h1>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img src={OwnerIC} alt="file-ic" className=" w-3 h-3.5 border mr-2" /> */}
                    <PersonOutlineOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Owner</h1>
                </div>
            ),
            dataIndex: "ownerName",
            key: "ownerName",
            render: (_, record) => {
                return (
                    <div className="flex">
                        <h1 className=" text-[#111827] font-medium font-sans ">
                            {record.investorName}
                        </h1>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    <h1 className="ml-0 mr-0 mt-0 mb-0 font-normal text-[#757575]">Question</h1>
                </div>
            ),
            dataIndex: "question",
            key: "question",
            render: (_, record) => {
                return (
                    <div className="flex">
                        <h1 className=" text-[#111827] font-medium font-sans ">
                            {record.subject}
                        </h1>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img src={ReviewTick} alt="file-ic" className=" w-3 h-3.5 border mr-2" /> */}
                    <CheckCircleOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0 font-normal text-[#757575] overflow-hidden">{"Q&A Progress"}</h1>
                </div>
            ),
            dataIndex: "status",
            key: "status",
            render: (_, record) => {
                let color;
                let text;
                switch (record.progress) {
                    case "in-progress":
                        color = "orange";
                        text = "In Progress";
                        break;
                    case "resolved":
                        color = "green";
                        text = "Resolved";
                        break;
                    default:
                        color = "red";
                        text = "Not Assigned";
                        break;
                }

                const menu = (
                    <Menu>
                        <Menu.Item key={1} onClick={() => { handleReviewChange(record._id, "In Progress") }}>In Progress</Menu.Item>
                        <Menu.Item key={2} onClick={() => { handleReviewChange(record._id, "Completed") }}>Resolved</Menu.Item>
                    </Menu>
                )
                return (
                    <Dropdown overlay={menu} trigger={['click']} open={false}>
                        <div className="flex items-center py-0.5 ">
                            <div
                                className={` flex items-center justify-center border bg-opacity-50 rounded-full  py-1.5 w-[90%]`}
                                style={{ borderColor: color, backgroundColor: `${color}-200` }}
                            >
                                {/* <div
                                    className={` bg-opacity-50 mr-2 w-1 h-1 rounded-full`}
                                    style={{ backgroundColor: color }}
                                /> */}
                                <h1 className={` text-${color}-500 m-0 text-[11px]`}>{text}</h1>
                            </div>
                        </div>
                    </Dropdown>
                );
            },
            width: "14%",
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white pl-4">
                    <h1 className="m-0  font-normal text-[#757575]">Respond</h1>
                </div>
            ),
            key: "comments",
            render: (_, record) => {
                return (
                    <div className="flex pl-4 py-0.5">
                        <PrimaryButton text={"Respond"} size="Medium" onClick={() => handleRespondClick(record)}></PrimaryButton>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-center py-3 bg-white">
                    {/* <img
            src={LinkIc}
            alt="file-ic"
            className=" w-3 object-contain h-3 border mr-2"
          />
          <h1 className="m-0  font-normal text-[#757575]">Respond</h1> */}
                    {/* <img
                        src={CirclePlus}
                        alt="file-ic"
                        className="w-3 object-contain h-5 border ml-5"
                    /> */}
                    <AddCircleOutlineOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                </div>
            ),
            key: "actions",
            render: (_, record) => (
                <div className="py-1.5 w-full flex justify-center">
                    <QuestionAndAnswerCard documentId={record} />
                </div>
            ),
            width: 150,
        },
    ];
    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card.id !== cardDetails.id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);

    const [modalTitle, setModalTitle] = useState(null);
    const [documentData, setDocumentData] = useState(null)

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const handleButtonClick = () => {
        setModalTitle("Edit Investor Details");
        setIsModalVisible(true);
    };

    const handleRespondClick = (record) => {
        setModalTitle("View Conversation");
        setDocumentData(record)
        setIsModalVisible(true);
    }

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };

    const handleDeleteButton = () => {
        setModalTitle("Delete File");
        setIsModalVisible(true);
    };

    const closeDeleteModal = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(null);
    };

    const onDownloadClick = () => {
        setModalTitle("Download");
        setIsModalVisible(true);
    };

    const handleReviewChange = async ({ documentId, status }) => {
        const body = {
            documentId: documentId,
            reviewStatus: status
        }
        await dispatch(reviewDocumentStatusApi(body))
    }

    const SkeletonLoader = ({ avatar = false, active = true, loading = true }) => {
        return (
            <div className="bg-neutral-200 animate-pulse w-full h-10 rounded-lg">
            </div>
        );
    };

    return (
        <div className="pt-[6rem] min-h-[100vh]">
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    isOpen={true}
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                    }}
                    documentList={documentData}
                />
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
                <div className="mx-auto">
                    <DescribeFeature
                        text={"Q&A"}
                        description={"Enagage with the administrator by asking questions or discussing file-related inquiries directly from this section"}
                    />
                    <div className="mt-4 overflow-hidden">
                        {isInitialLoad ? (
                            <div className="w-full flex flex-col gap-3">
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Table: {
                                            headerBg: "#FFFFFF",
                                        },
                                    },
                                }}
                            >
                                <Table
                                    columns={columns}
                                    dataSource={filesData}
                                    rowKey="InvestorName"
                                    scroll={{ x: 1500 }}
                                    className="border border-[#9E9E9E] rounded-2xl overflow-hidden"
                                    rowClassName={() =>
                                        "bg-white border border-[#9E9E9E] px-3 mb-2"
                                    }
                                    rowHoverable={false}
                                    pagination={{
                                        pageSize: filesData?.length,
                                        position: ['none', 'none'],
                                    }}
                                    locale={{ emptyText: (
                                        <span>
                                            <div className="flex flex-col items-center justify-center my-10">
                                            <Empty description={user.isAdmin ? "Uh-oh! Looks like there are no questions posted yet. Please check back later!" : "Uh-oh, you haven't posted any questions yet. Ask your first question to get the information you need."} />
                                            </div>
                                        </span>
                                    ) }}
                                />
                            </ConfigProvider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
