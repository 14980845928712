import React, { useEffect, useState } from 'react'
import { Button, Input, message } from "antd"
import { Modal, Space } from 'antd';
import { resetSaveApi, toggleModal, updateWorkSpaceSaveICPApi } from './ICPSlice';
import { useSelector, useDispatch } from "react-redux";
import closeView from "./assets/closeView.svg"
import { useNavigate } from "react-router-dom"
import { postNewFeatureProgressApi } from '../maindashboardstepper/stepperProgressSlice';
import useCheckStatusStepperProgress from '../../utils/checkStatusStepperProgress';
import MessageNotification from '../reuseablecomponent/MessageNotification';
import locals from "../../localization/localization_en_ideal_customer_profile.json";


const SaveAsModal = ({ saveType }) => {
    const localization = locals.ideal_customer_profile;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [templateName, setTemplateName] = useState("");
    const error = useSelector((state) => state.icp.error)
    const modelIsOpen = useSelector((state) => state.icp.modelIsOpen);
    const checkFeatureUsageStatus = useCheckStatusStepperProgress();
    const savemodel = useSelector((state) => state.icp.savemodel);
    const saveTemplate = useSelector((state) => state.icp.saveTemplate)

    useEffect(() => {
        if (saveTemplate == "success") {
            if (!checkFeatureUsageStatus) {
                dispatch(postNewFeatureProgressApi({ status: 1, featureName: localization.module_title }));
            }
            dispatch(toggleModal(false));
            navigate('/product/home/buildmarketanalysis/idealcustomerprofile')
        }
        // return () => dispatch(resetSaveApi());
    }, [saveTemplate])


    //   useEffect(()=>{
    //     //rediredct//errreset
    //     console.log(error,savemodel,"test mount");
    //     if(savemodel==="success"){
    // if(!checkFeatureUsageStatus){
    //     dispatch(postNewFeatureProgressApi({status:1, featureName:"Ideal Customer Profile"}));
    // }
    //         dispatch(toggleModal());
    //         navigate('/product/home/buildmarketanalysis/idealcustomerprofile');
    //     }
    //     if(savemodel==="failed"){
    //         dispatch(resetErrSaveModal());
    //     }
    //   },[error,savemodel])
    const handleWorkSpaceSaveTemplate = () => {
        if (templateName.trim().length > 2) {
            dispatch(updateWorkSpaceSaveICPApi({ type: saveType, workSpaceName: templateName }));
            if (!checkFeatureUsageStatus) {
                dispatch(postNewFeatureProgressApi({ status: 1, featureName: "Ideal Customer Profile" }));
            }
        } else {
            message.open({
                type: "warning",
                "content": "Template name must be at least 3 characters"
            })
        }
    }
    // const checkFeatureUsageStatus = useCheckStatusStepperProgress("Ideal Customer Profile");
    return (
        <Modal centered maskClosable={false} open={modelIsOpen}>
            {error && <MessageNotification content={error} type={"error"} status={savemodel} />}
            <div className='p-[18px]'>
                <div className='flex justify-between items-center'>
                    <span className='text-[#111827] font-InterRegular text-[18px] font-medium tracking-wide'>{localization.save_modal.title}</span>
                    <img src={closeView} alt="" className='cursor-pointer' onClick={() => {
                        dispatch(toggleModal(false));
                    }} />
                </div>
                <div className='mb-[48px] mt-[32px]'>
                    <Input onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 38) {
                            setTemplateName(inputValue);
                        } else {
                            message.open({
                                type: "warning",
                                content: localization.save_modal.warning_msg
                            });
                        }
                    }} value={templateName} style={{ border: "1px solid #96ADFF", borderRadius: "12px", paddingTop: "12px", paddingBottom: "12px" }} placeholder={localization.save_modal.placeholder} />
                </div>
                <div className="buttons">
                    <Button onClick={() => {
                        dispatch(toggleModal(false));
                    }} className='text-primary border border-primary px-[20px] py-[5px] h-auto'>{localization.save_modal.back_btn}</Button>
                    <Button loading={saveTemplate == "loading"} disabled={saveTemplate == "loading"} onClick={() => {
                        handleWorkSpaceSaveTemplate();
                    }} className='text-white bg-primary ml-[40px] px-[20px] py-[5px] h-auto'>{localization.save_modal.save_btn}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default SaveAsModal