import React from 'react';
import { Button, Modal } from 'antd';
import ButtonComponent from '../ButtonVarients/ButtonComponent';
import TextComponent from '../TextVarients/TextComponent';
import { useDispatch, useSelector } from "react-redux";
import { toggleDeleteModal, handleDeletePaymentCard } from "../../userSetting/settingSlice";
import close from "../../../assets/common/close.svg"
import modalLocals from "../../../localization/localization_en_modals.json"

const DeleteModalComponent = ({ isLoading=false, isCalling=false, modelTitle, modelDescription, isModelOpen=false, deleteButtonName, handleCloseModel, handleOnDelete }) => {
    const istoggleDeleteModal = useSelector((state) => state.setting.toggleDeleteModal)
    const dispatch = useDispatch();
    const handleModelOpen = () => {
        dispatch(toggleDeleteModal());
    }
    const handlePermantDeleteModal = () => {
        dispatch(handleDeletePaymentCard());
        dispatch(toggleDeleteModal());
    }
    return (
        <React.Fragment>
            <Modal
                bodyStyle={{ minHeight: "200px" }}
                centered
                open={isModelOpen || istoggleDeleteModal}
                okButtonProps={{
                    disabled: true,
                }}
                cancelButtonProps={{
                    disabled: true,
                }}
            >
                {true && <Button disabled={isLoading} onClick={isCalling ? handleCloseModel : handleModelOpen } className='h-auto absolute top-[18px] right-[18px] border-none'><img src={close} className='' /></Button>}
                <div className='py-[16px]'>
                    <TextComponent fontSize={18} fontWeight={600} text={isCalling ? modelTitle : modalLocals.delete.confirm_question} />
                </div>
                <TextComponent fontSize={14} fontWeight={500} text={isCalling ? modelDescription : modalLocals.delete.alert_msg} />
                <div className='absolute flex justify-between bottom-[18px] left-[18px] right-[18px] w-[93%]'>
                    <ButtonComponent onClick={isCalling ? handleCloseModel : handleModelOpen} type={"link"} isBorder={true} borderColor={"#757575"} isDisabled={isLoading} isLoading={isLoading} text={modalLocals.delete.button_text.cancel} textColor={"#757575"} />
                    <ButtonComponent onClick={() => {
                        if(isCalling){
                            handleOnDelete()
                        }
                        handlePermantDeleteModal()
                    }} type={"primary"} bgColor={"#F75555"} isDisabled={isLoading} isLoading={isLoading} text={isCalling ? deleteButtonName : modalLocals.delete.button_text.confirm} textColor={"#fff"} />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default DeleteModalComponent