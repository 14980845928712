import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api/api";
import { v4 as uuidv4 } from 'uuid';
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import costScreenLocals from "../../localization/localization_en_cost_screen.json"
import { GenerateTableRow } from "./components/CostOnboarding/utils/GenerateTableRow";
import { AppendUUIDKeyTable } from "./components/CostOnboarding/utils/AppendUUIDKeyTable";
import marketing_spend_catg from "./components/CostOnboarding/data/marketing_spend.json";
import direct_cost_catg from "./components/CostOnboarding/data/direct_cost.json";
import cost_catg from "./components/CostOnboarding/data/cost.json";
import department_catg from "./components/CostOnboarding/data/department.json";
import g_a_catg from "./components/CostOnboarding/data/g_and_a.json";
import capex_cost from "./components/CostOnboarding/data/capex.json";
import routesConfig from "../../services/config/routesConfig.json";
import marketing_spend_dummy_data from "./components/CostOnboarding/data/marketing_spend_data.json"
import { generateApiTraceInitialState, generateMonth } from "./functionality/RevenueOnboarding/revenueOnboardingUtiliy";
import { DropdownDataPreparation, PUT_Category_Title, TagDataFormat } from "./components/CostOnboarding/utils/DropdownDataPreparation";
import { months } from "./data/data";
import { API_BI_TRAVEL_STATUS } from "./functionality/RevenueOnboarding/revenueOnboardingUtiliy";
import ROLOCALS from "../../localization/localization_en_business_modal.json"
import { filterDuplicateObjectProp } from "../../utils/filterDuplicateObjectProp";
import { getCostScreenDataReferenceTitle } from "./constants/getScreenDataReferenceTitle";
import { costScreenFiveDataInjection, costScreenFourDataInjection, costScreenOneDataInjection, costScreenSixDataInjection, costScreenThreeDataInjection, costScreenTwoDataInjection } from "./utility/costScreenDataInjection";

const stepperValueMapping = { 0: -5, 25:-2.5, 50:0, 75:2.5, 100:5 }
const CHANNELS = ROLOCALS.business_modal.revenue.form[0].data[0].select_options

export const API_Post_Cost_Screen1_Data = createAsyncThunk(
    "costOnboarding/API_Post_Cost_Screen1_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-cost-screen-one');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_Cost_Screen2_Data = createAsyncThunk(
    "costOnboarding/API_Post_Cost_Screen2_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-cost-screen-two');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_Cost_Screen3_Data = createAsyncThunk(
    "costOnboarding/API_Post_Cost_Screen3_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-cost-screen-three');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_Cost_Screen4_Data = createAsyncThunk(
    "costOnboarding/API_Post_Cost_Screen4_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-cost-screen-four');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_Cost_Screen5_Data = createAsyncThunk(
    "costOnboarding/API_Post_Cost_Screen5_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-cost-screen-five');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_Cost_Screen6_Data = createAsyncThunk(
    "costOnboarding/API_Post_Cost_Screen6_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-cost-screen-six');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Cost_Screen1_Data = createAsyncThunk(
    "costOnboarding/API_Process_Cost_Screen1_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-cost-screen-one');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Cost_Screen2_Data = createAsyncThunk(
    "costOnboarding/API_Process_Cost_Screen2_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-cost-screen-two');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Cost_Screen3_Data = createAsyncThunk(
    "costOnboarding/API_Process_Cost_Screen3_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-cost-screen-three');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Cost_Screen4_Data = createAsyncThunk(
    "costOnboarding/API_Process_Cost_Screen4_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-cost-screen-four');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Cost_Screen5_Data = createAsyncThunk(
    "costOnboarding/API_Process_Cost_Screen5_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-cost-screen-five');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_Cost_Screen6_Data = createAsyncThunk(
    "costOnboarding/API_Process_Cost_Screen6_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-cost-screen-six');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

//get default category data list

export const API_Default_SalesChannel_Data = createAsyncThunk(
    "costOnboarding/API_Default_SalesChannel_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-default-sales-channels-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Default_DirectCost_Data = createAsyncThunk(
    "costOnboarding/API_Default_DirectCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-default-direct-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Default_CapacityPlanning_Data = createAsyncThunk(
    "costOnboarding/API_Default_CapacityPlanning_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-default-capacity-planning-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Default_Cost_Data = createAsyncThunk(
    "costOnboarding/API_Default_Cost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-default-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Default_UnderivedCost_Data = createAsyncThunk(
    "costOnboarding/API_Default_UnderivedCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-default-underived-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Default_DerivedCost_Data = createAsyncThunk(
    "costOnboarding/API_Default_DerivedCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-default-derived-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Default_GACost_Data = createAsyncThunk(
    "costOnboarding/API_Default_GACost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-default-ga-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Default_CapexCost_Data = createAsyncThunk(
    "costOnboarding/API_Default_CapexCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-default-capex-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

//get category data list

export const API_GET_SalesChannelList_Data = createAsyncThunk(
    "costOnboarding/API_GET_SalesChannelList_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-sales-channel-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_Direct_Cost_List_Data = createAsyncThunk(
    "costOnboarding/API_GET_Direct_Cost_List_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-direct-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_CapacityPlanning_Data = createAsyncThunk(
    "costOnboarding/API_GET_CapacityPlanning_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-capacity-planning-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_Cost_Data = createAsyncThunk(
    "costOnboarding/API_GET_Cost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_UnderivedCost_Data = createAsyncThunk(
    "costOnboarding/API_GET_UnderivedCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-underived-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_DerivedCost_Data = createAsyncThunk(
    "costOnboarding/API_GET_DerivedCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-derived-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_GACost_Data = createAsyncThunk(
    "costOnboarding/API_GET_GACost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-ga-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_CapexCost_Data = createAsyncThunk(
    "costOnboarding/API_GET_CapexCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-capex-cost-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

//create category list

export const API_CREATE_SalesChannelList_Data = createAsyncThunk(
    "costOnboarding/API_CREATE_SalesChannelList_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-sales-channel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_DirectCostList_Data = createAsyncThunk(
    "costOnboarding/API_CREATE_DirectCostList_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-direct-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_CapacityPlanning_Data = createAsyncThunk(
    "costOnboarding/API_CREATE_CapacityPlanning_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-capacity-planning');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_Cost_Data = createAsyncThunk(
    "costOnboarding/API_CREATE_Cost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_UnderivedCost_Data = createAsyncThunk(
    "costOnboarding/API_CREATE_UnderivedCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-underived-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_DerivedCost_Data = createAsyncThunk(
    "costOnboarding/API_CREATE_DerivedCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-derived-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_GACost_Data = createAsyncThunk(
    "costOnboarding/API_CREATE_GACost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-ga-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_CREATE_CapexCost_Data = createAsyncThunk(
    "costOnboarding/API_CREATE_CapexCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-capex-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

//patch category list

export const API_PUT_SalesChannelList_Data = createAsyncThunk(
    "costOnboarding/API_PUT_SalesChannelList_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-sales-channel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_PUT_DirectCostList_Data = createAsyncThunk(
    "costOnboarding/API_PUT_DirectCostList_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-direct-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_PUT_CapacityPlanning_Data = createAsyncThunk(
    "costOnboarding/API_PUT_CapacityPlanning_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-capacity-planning');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_PUT_Cost_Data = createAsyncThunk(
    "costOnboarding/API_PUT_Cost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_PUT_UnderivedCost_Data = createAsyncThunk(
    "costOnboarding/API_PUT_UnderivedCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-underived-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_PUT_DerivedCost_Data = createAsyncThunk(
    "costOnboarding/API_PUT_DerivedCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-derived-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_PUT_GACost_Data = createAsyncThunk(
    "costOnboarding/API_PUT_GACost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-ga-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_PUT_CapexCost_Data = createAsyncThunk(
    "costOnboarding/API_PUT_CapexCost_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-capex-cost');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_GET_COST_ONBOARDING_SCREEN_SLICE = createAsyncThunk(
    "costOnboarding/API_GET_COST_ONBOARDING_SCREEN_SLICE",
    async ({ activeScreen, previousScreen }, { getState }) => {
        try {
            const state = getState();
            const MODE_TYPES = state.businessModal.MODE_TYPE;
            const getDataForTargetScreenName = activeScreen;
            const screenRefernce = getCostScreenDataReferenceTitle;
            const paramsData = {
                "businessModelId": state.businessModal.active_BusinessModal_Id,
                "screenId": screenRefernce[String(getDataForTargetScreenName)]
            };
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-business-model-screen-data');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "params": paramsData };
                const response = await api.request(urlConfig);
                return {
                    ...response?.data,
                    "info": { "activeScreen": getDataForTargetScreenName, "screenReference": screenRefernce[String(getDataForTargetScreenName)] },
                    "screenNumberStatus": { activeScreen, previousScreen },
                    "MODE_TYPES": MODE_TYPES
                };
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const cost_screen_data = {
    "revenue_onboarding_marketing_spend_details": {
        "marketing_spend_data_length": 0,
        "month_year": null
    },
    "lease_data_present_before_after": {"before": false, "after": false},
    "marketing_spend": [],
    "marketing_spend_span_type": "last_six_months",
    "cost_incurred_for_user_through_marketing": AppendUUIDKeyTable(costScreenLocals.cost.form[0].data[1].table.defaultRows),
    "incurring_direct_cost": AppendUUIDKeyTable(costScreenLocals.cost.form[1].data[0].table.defaultRows),
    "return_cancellation_chargeable_percentage": AppendUUIDKeyTable(costScreenLocals.cost.form[1].data[1].table.defaultRows),
    "facilities_own_operation": [],
    "capacity_planning": AppendUUIDKeyTable(costScreenLocals.cost.form[2].data[1].table.defaultRows),
    "cost": AppendUUIDKeyTable(costScreenLocals.cost.form[2].data[2].table.defaultRows),
    "personnel_tl_ratio": null,
    "undervied_costs_personnal_diff_department": AppendUUIDKeyTable(costScreenLocals.cost.form[3].data[1].table.defaultRows),
    "dervied_costs_personnal_diff_department": AppendUUIDKeyTable(costScreenLocals.cost.form[3].data[2].table.defaultRows),
    "currently_incurring_g_and_a_cost": AppendUUIDKeyTable(costScreenLocals.cost.form[4].data[0].table.defaultRows),
    "capex_cost": AppendUUIDKeyTable(costScreenLocals.cost.form[5].data[0].table.defaultRows)
}

const category_list_data = {
    "category_list_data": {
        "sales_channel_category_list": [],
        "direct_cost_category_list": [],
        "capacity_planning_category_list": [],
        "cost_category_list": [],
        "derived_cost_category_list": [],
        "un_derived_cost_category_list": [],
        "g_and_a_cost_category_list": [],
        "capex_category_list": []
    }
}

const screen_ouput_data = {
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
}

const API_MODULES = {
    'sales_channel_category_list': { "status": "idle", "message": null, "httpType": null },
    'direct_cost_category_list': { "status": "idle", "message": null, "httpType": null },
    'capacity_planning_category_list': { "status": "idle", "message": null, "httpType": null },
    'cost_category_list': { "status": "idle", "message": null, "httpType": null },
    'derived_cost_category_list': { "status": "idle", "message": null, "httpType": null },
    'un_derived_cost_category_list': { "status": "idle", "message": null, "httpType": null },
    'g_and_a_cost_category_list': { "status": "idle", "message": null, "httpType": null },
    'capex_category_list': { "status": "idle", "message": null, "httpType": null }
}

const functional_state = {
    "active_screen": 0,
    "isCategoryListFetchedFromServer": false,
    "is_popup_open": false,
    "category_list_type": null,
    "isSaveDisabled": false,
    "isGenerateOutputDisabled": true,
    "isOutputScreenDisabled": true,
    "api_status_alarm_counter": 0,
    "api_status": API_MODULES
}

const INITIAL_STATE = {
    ...cost_screen_data,
    ...functional_state,
    ...category_list_data,
    "outputSliderValues": {
        0:{ prevValue: 50, nextValue:50},
        1:{ prevValue: 50, nextValue:50},
        2:{ prevValue: 50, nextValue:50},
        3:{ prevValue: 50, nextValue:50},
        4:{ prevValue: 50, nextValue:50},
        5:{ prevValue: 50, nextValue:50}
    },
    "isShowErrorComponent": false,
    "screen_output": screen_ouput_data,
    "screen_save_process_api_status": {
        "counter": 0,
        "status": generateApiTraceInitialState(6)
    },
    'screen_screen_api_status': { "status": "idle", "message": null },
    "stepperCostPredictionApiStatus": 0,
    "channel_sales": [],
    "traceKeyNameFromGetCostScreenData": null,
    "categoryList": []
}

const CostOnboardingSlice = createSlice({
    name: "cost-onboarding",
    initialState: INITIAL_STATE,
    reducers: {
        D_Patch_Cost_SliderValue(state, action) {
            const { value } = action.payload;
            state.outputSliderValues = { ...state.outputSliderValues, [state.active_screen]: { "prevValue": state.outputSliderValues[state.active_screen].nextValue, "nextValue": value} }
        },
        D_Update_TableValue_Based_Stepper(state, action){
            const { tableName } = action.payload;
            const sliderValue = state.outputSliderValues[state.active_screen]
            if(tableName==="marketing_spend"){
                const prevValue = stepperValueMapping[sliderValue.prevValue];
                const nextValue = stepperValueMapping[sliderValue.nextValue];
                state.marketing_spend = state.marketing_spend.map((row) => {
                    let value = Number(row.mom_spend_growth_percent);
                    value = value - prevValue;
                    value = value + nextValue;
                    return {...row, "mom_spend_growth_percent": value}
                })
            }
        },
        D_Trace_Cost_StepperPredictionApiStatus(state, action){
            state.stepperCostPredictionApiStatus = action.payload;
        },
        D_Patch_CostScreen_Fetched_Data_From_DB(state, action) {
            const { data } = action.payload || {};
            if(!!data?.revenueScreenOneInput===false){
                return;
            }
            // month year and revenue deps data
            let month = "";
            for (const mon of Object?.entries(months)) {
                if (mon[1]?.value === data?.revenueScreenOneInput?.marketing_spend_per_sales_channel?.first_month) {
                    month = String(mon[0])?.length === 1 ? 0 + mon[0] : mon[0];
                }
            }
            const year = data?.revenueScreenOneInput?.marketing_spend_per_sales_channel?.first_year
            const revenueMarketingSpendData = data.revenueScreenOneInput.channels_of_sales.map((channel) => {
                return ({
                    "name": channel,
                    ...data.revenueScreenOneInput?.marketing_spend_per_sales_channel[channel]
                })
            })
            state.marketing_spend = revenueMarketingSpendData
            state.marketing_spend_span_type = data.revenueScreenOneInput.marketing_spend_per_sales_channel.type;
            state.revenue_onboarding_marketing_spend_details.marketing_spend_data_length = data.revenueScreenOneInput.channels_of_sales.length
            state.revenue_onboarding_marketing_spend_details.month_year = `${year}-${month}`

            //cost screen 1
            if (!!data?.costScreenOneInput) {
                state.marketing_spend = [...state.marketing_spend, ...data.costScreenOneInput.marketing_spend_per_sales_channel]
                state.cost_incurred_for_user_through_marketing = data.costScreenOneInput.cost_incurred_for_user_through_marketing;
                state.screen_output[0] = !!data?.costScreenOneOutput ? data.costScreenOneOutput : null;
                state.isSaveDisabled = state.screen_output[0] ? true : false;
            }
            //cost screen 2
            if (!!data?.costScreenTwoInput) {
                state.incurring_direct_cost = data.costScreenTwoInput.incurring_direct_cost;
                state.return_cancellation_chargeable_percentage = data.costScreenTwoInput.return_cancellation_chargeable_percentage;
                state.screen_output[1] = !!data?.costScreenTwoOutput ? data?.costScreenTwoOutput : null;
            }
            //cost screen 3
            if (!!data?.costScreenThreeInput) {
                state.facilities_own_operation = data.costScreenThreeInput.facilities_own_operation
                if (!!data.costScreenThreeInput?.capacity_planning) {
                    const mapping = { "Warehouse/Factory Capacity": "WarehouseFactory", "Machine Capacity": "WarehouseFactory", "Orders Processed Per Labor": "WarehouseFactory", "Collection Centre Per Warehouse": "Collection Center", "Staff Per Collection Centre": "Collection Center" };
                    state.capacity_planning = data.costScreenThreeInput.capacity_planning.map((row) => ({ ...row, "deps": !!row.name ? (!!mapping[row.name] ? mapping[row.name] : "other") : "other" }))
                }
                if (!!data.costScreenThreeInput?.cost) {
                    const mapping = { "Lease": "default", "Deposit": "default", "Setup Cost": "default", "Cost Of Machine": "WarehouseFactory", "Per Labour Cost": "WarehouseFactory", "Overheads Cost": "WarehouseFactory", "Collection Centre Staff Cost": "Collection Center" }
                    state.cost = data.costScreenThreeInput.cost.map((row) => ({ ...row, "deps": !!row.name ? (!!mapping[row.name] ? mapping[row.name] : "other") : "other" }))
                }
                state.screen_output[2] = !!data?.costScreenThreeOutput ? data?.costScreenThreeOutput : null;
            }
            //cost screen 4
            if (!!data?.costScreenFourInput) {
                state.personnel_tl_ratio = data?.costScreenFourInput.personnel_tl_ratio
                state.undervied_costs_personnal_diff_department = data.costScreenFourInput.undervied_costs_personnal_diff_department
                state.dervied_costs_personnal_diff_department = data.costScreenFourInput.dervied_costs_personnal_diff_department
                state.screen_output[3] = !!data?.costScreenFourOutput ? data?.costScreenFourOutput : null;
            }
            //cost screen 5
            if (!!data?.costScreenFiveInput) {
                state.currently_incurring_g_and_a_cost = data.costScreenFiveInput.currently_incurring_g_and_a_cost
                state.screen_output[4] = !!data?.costScreenFiveOutput ? data?.costScreenFiveOutput : null;
            }
            //cost screen 6
            if (!!data?.costScreenSixInput) {
                state.capex_cost = data.costScreenSixInput.capex_cost;
                state.screen_output[5] = !!data?.costScreenSixOutput ? data?.costScreenSixOutput : null;
            }
        },
        D_Reset_Save_Process_Error_Status(state, action) {
            state.screen_save_process_api_status.status = { ...state.screen_save_process_api_status.status, [state.active_screen]: API_BI_TRAVEL_STATUS }
        },
        D_Reset_category_list_data(state, action) {
            state.category_list_data = category_list_data.category_list_data;
        },
        D_Fetch_category_List_Status(state, action) {
            state.isCategoryListFetchedFromServer = action.payload;
        },
        D_Patch_Revenue_Onboarding_Data(state, action) {
            const { marketing_spend_per_sales_channel, marketing_spend_per_channel_fiscal_year, channel_sales } = action.payload;
            let marketing_spend_data = [];
            const { type, ...mainData } = marketing_spend_per_sales_channel;
            channel_sales.map((channel) => {
                marketing_spend_data = [...marketing_spend_data, { "name": channel,...mainData[channel] }]
            })
            state.marketing_spend_span_type = type;
            state.revenue_onboarding_marketing_spend_details = {
                "marketing_spend_data_length": marketing_spend_data.length,
                "month_year": marketing_spend_per_channel_fiscal_year
            }
            state.marketing_spend = marketing_spend_data;
        },
        D_Reset_Cost_Onboarding_State(state) {
            state.revenue_onboarding_marketing_spend_details = {
                "marketing_spend_data_length": 0,
                "month_year": null
            }
            state.lease_data_present_before_after = { "before": false, "after": false },
                state.marketing_spend = [],
                state.marketing_spend_span_type = "last_six_months",
                state.cost_incurred_for_user_through_marketing = AppendUUIDKeyTable(costScreenLocals.cost.form[0].data[1].table.defaultRows),
                state.incurring_direct_cost = AppendUUIDKeyTable(costScreenLocals.cost.form[1].data[0].table.defaultRows),
                state.return_cancellation_chargeable_percentage = AppendUUIDKeyTable(costScreenLocals.cost.form[1].data[1].table.defaultRows),
                state.facilities_own_operation = [],
                state.capacity_planning = AppendUUIDKeyTable(costScreenLocals.cost.form[2].data[1].table.defaultRows),
                state.cost = AppendUUIDKeyTable(costScreenLocals.cost.form[2].data[2].table.defaultRows),
                state.personnel_tl_ratio = null,
                state.undervied_costs_personnal_diff_department = AppendUUIDKeyTable(costScreenLocals.cost.form[3].data[1].table.defaultRows),
                state.dervied_costs_personnal_diff_department = AppendUUIDKeyTable(costScreenLocals.cost.form[3].data[2].table.defaultRows),
                state.currently_incurring_g_and_a_cost = AppendUUIDKeyTable(costScreenLocals.cost.form[4].data[0].table.defaultRows),
                state.capex_cost = AppendUUIDKeyTable(costScreenLocals.cost.form[5].data[0].table.defaultRows)

            state.active_screen = 0,
                state.isCategoryListFetchedFromServer = false,
                state.is_popup_open = false,
                state.category_list_type = null,
                state.isSaveDisabled = false,
                state.isGenerateOutputDisabled = true,
                state.isOutputScreenDisabled = true,
                state.api_status_alarm_counter = 0,
                state.api_status = API_MODULES
            state.screen_screen_api_status = { "status": "idle", "message": null },

                state.category_list_data = {
                    "sales_channel_category_list": [],
                    "direct_cost_category_list": [],
                    "capacity_planning_category_list": [],
                    "cost_category_list": [],
                    "derived_cost_category_list": [],
                    "un_derived_cost_category_list": [],
                    "g_and_a_cost_category_list": [],
                    "capex_category_list": []
                }

            state.isShowErrorComponent = false;
            state.screen_output = screen_ouput_data;
            state.screen_save_process_api_status = {
                "counter": 0,
                "status": generateApiTraceInitialState(6)
            }
            state.stepperCostPredictionApiStatus = 0
            state.traceKeyNameFromGetCostScreenData = null;
        },
        D_Reset_Control_Button(state) {
            state = { ...state, "isSaveDisabled": false, "isGenerateOutputDisabled": true, "isOutputScreenDisabled": true };
        },
        D_Show_Error_Message(state, action) {
            state.isShowErrorComponent = action.payload;
        },
        D_ToggleScreen(state, action) {
            const { type } = action.payload;
            if (type === "next") {
                const ACTIVE_SCREEN = state.active_screen;
                switch (ACTIVE_SCREEN) {
                    case 0: {
                        console.log("exe 0")
                        state.marketing_spend = state.marketing_spend.map((data) => ({ ...data, "mom_spend_growth_percent": data.mom_spend_growth_percent === null ? 0 : data.mom_spend_growth_percent }))
                        break;
                    }
                    case 1: {
                        console.log("exe 1")
                        state.incurring_direct_cost = state.incurring_direct_cost.map((data) => ({ ...data, "varying_cost_over_time": data.varying_cost_over_time === null ? 0 : data.varying_cost_over_time }))
                        break;
                    }
                    case 2: {
                        console.log("exe 2")
                        state.capacity_planning = state.capacity_planning.map((data) => ({ ...data, "varying_cost_over_time": data.varying_cost_over_time === null ? 0 : data.varying_cost_over_time }))
                        state.cost = state.cost.map((data) => ({ ...data, "varying_cost_over_time": data.varying_cost_over_time === null ? 0 : data.varying_cost_over_time }))
                        break;
                    }
                    case 3: {
                        console.log("exe 3")
                        state.undervied_costs_personnal_diff_department = state.undervied_costs_personnal_diff_department.map((data) => ({ ...data, "increment_yoy_percent": data.increment_yoy_percent === null ? 0 : data.increment_yoy_percent, "personnel_headcount_variation_yoy": data.personnel_headcount_variation_yoy === null ? 0 : data.personnel_headcount_variation_yoy }))
                        state.dervied_costs_personnal_diff_department = state.dervied_costs_personnal_diff_department.map((data) => ({ ...data, "increment_yoy_percent": data.increment_yoy_percent === null ? 0 : data.increment_yoy_percent, "mom_variation": data.mom_variation === null ? 0 : data.mom_variation }))
                        break;
                    }
                    case 4: {
                        console.log("exe 4")
                        state.currently_incurring_g_and_a_cost = state.currently_incurring_g_and_a_cost.map((data) => ({ ...data, "varying_cost_over_time": data.varying_cost_over_time === null ? 0 : data.varying_cost_over_time }))
                        break;
                    }
                    case 5: {
                        console.log("exe 5")
                        state.capex_cost = state.capex_cost.map((data) => ({ ...data, "varying_cost_over_time": data.varying_cost_over_time === null ? 0 : data.varying_cost_over_time }))
                        break;
                    }
                }
                state.active_screen = state.active_screen === costScreenLocals.cost.form.length - 1 ? state.active_screen : state.active_screen + 1;
                state.isSaveDisabled = !!state.screen_output[state.active_screen];
                state.isGenerateOutputDisabled = true;
                state.isOutputScreenDisabled = !state.isSaveDisabled;
            } else {
                state.active_screen = state.active_screen === 0 ? state.active_screen : state.active_screen - 1;
                state.isSaveDisabled = !!state.screen_output[state.active_screen];
                state.isGenerateOutputDisabled = !!state.screen_output[state.active_screen];
                state.isOutputScreenDisabled = false;
            }
        },
        D_Capture_Value(state, action) {
            const { name, tableName, value, rowNumber, data_index, nested_index, id, dbid } = action.payload;
            state.screen_output[state.active_screen] = null
            state.isSaveDisabled = false;
            state.isGenerateOutputDisabled = true;
            state.isOutputScreenDisabled = true;
            if (state.isSaveDisabled === true) {
                state.isSaveDisabled = false;
                state.isGenerateOutputDisabled = true;
                state.isOutputScreenDisabled = true;
            }
            if (tableName === "marketing_spend" && state.marketing_spend_span_type === "last_six_months") {
                if(data_index==="name"){
                    state.marketing_spend = state.marketing_spend.map((row, index) => {
                        if(index===rowNumber){
                            return { ...row, [data_index]: value, "dbid": id }
                        }
                        return row;
                    })
                    return;
                }
                const subIndex = Number(data_index.slice(-1))
                state.marketing_spend = state.marketing_spend.map((row, index) => {
                    if (index === rowNumber) {
                        return {
                            ...row,
                            "last_six_months": row.last_six_months.map((subRow, i) => subIndex === i + 1 ? { ...subRow, "spend_value": value } : subRow)
                        }
                    }
                    return row;
                })
                return;
            }
            // console.log(action.payload);
            if (Array.isArray(state[tableName])) {
                // console.log(action.payload,"--active debug--");
                if (data_index === "calculation_methodology" && tableName === "currently_incurring_g_and_a_cost") {
                    state[tableName] = state[tableName].map((row, index) => {
                        if (index === rowNumber && value === "% Of Lease") {
                            return { ...row, [data_index]: value, "value": { "percentOfLease": null, "Amount": null }, "varying_cost_over_time": null }
                        } else if (index === rowNumber && value !== "% Of Lease") {
                            return { ...row, [data_index]: value, "value": null, "varying_cost_over_time": null }
                        }
                        return row
                    });
                    return;
                }
                if (data_index === "value" && tableName === "currently_incurring_g_and_a_cost") {
                    state[tableName] = state[tableName].map((row, index) => {
                        if (index === rowNumber && row["calculation_methodology"] === "% Of Lease") {
                            return { ...row, [data_index]: { ...row[data_index], [nested_index]: value } }
                        } else if (index === rowNumber && row["calculation_methodology"] !== "% Of Lease") {
                            return { ...row, [data_index]: value }
                        }
                        return row
                    });
                    return;
                }
                // if (tableName === "cost") { // since we are updating this value from api
                //     const record = state.cost.find((_, index) => index === rowNumber)
                //     if (record.name === "Lease") {
                //         if (data_index === "office") {
                //             state.currently_incurring_g_and_a_cost = state.currently_incurring_g_and_a_cost.map((row) => row.name === "Overhead Cost" ? { ...row, "value": { ...row.value, "Amount": value } } : row);
                //         }
                //     }
                // }
                if(tableName==="cost" || tableName==="capacity_planning"){
                    state[tableName] = state[tableName].map((row, index) => {
                        if(data_index==="name" ? (state[tableName].length-rowNumber)+rowNumber-1 === index : dbid===row.dbid){
                            return id ? { ...row, "dbid": id, [data_index]: value } : { ...row, [data_index]: value }
                        }
                        return row;
                    })
                    return;
                }
                state[tableName] = state[tableName].map((row, index) => {
                    if(index === rowNumber){
                        return id ? { ...row, "dbid": id, [data_index]: value } : { ...row, [data_index]: value }
                    }
                    return row;
                });
                if (action.payload.data_index === "method_of_calculation" || action.payload.data_index === "calculation_methodology") {
                    state[tableName] = state[tableName].map((row, index) => {
                        if (index === rowNumber) {
                            const newRowExceptNameAndCalculationType = { "key": uuidv4(), ...GenerateTableRow(tableName) }
                            return { ...newRowExceptNameAndCalculationType, "name": row.name, [data_index]: row[data_index], "dbid": row.dbid }
                        }
                        return row;
                    });
                }
            } else {
                state[name] = value;
            }
        },
        D_Add_Table_Row(state, action) {
            const { name } = action.payload;
            state.screen_output[state.active_screen] = null
            state.isSaveDisabled = false;
            state.isGenerateOutputDisabled = true;
            state.isOutputScreenDisabled = true;
            const fiscalYear = state.revenue_onboarding_marketing_spend_details?.month_year?.split('-');
            const extraPayload = (["capacity_planning", "cost"].includes(name)) ? { deps: "other" } : {}
            const newRow = { "key": uuidv4(), ...GenerateTableRow(name, fiscalYear), ...extraPayload }
            if (Array.isArray(state[name])) {
                state[name] = [...state[name], newRow]
            }
        },
        D_Remove_Table_Row(state, action) {
            const { name, rowIndex, dbid } = action.payload;
            state.screen_output[state.active_screen] = null
            state.isSaveDisabled = false;
            state.isGenerateOutputDisabled = true;
            state.isOutputScreenDisabled = true;
            state[name] = state[name].filter((r) => r.dbid !== dbid);
            if (!state[name].length) state[name] = [{ "key": uuidv4(), ...GenerateTableRow(name) }];
        },
        D_Capture_CheckBox_Data(state, action) {
            const { name, value } = action.payload
            state.screen_output[state.active_screen] = null
            state.isSaveDisabled = false;
            state.isGenerateOutputDisabled = true;
            state.isOutputScreenDisabled = true;
            if (!!state[name].find(data => value === data)) {
                state[name] = state[name].filter((data, i) => data !== value)
            } else {
                state[name] = [...state[name], value]
            }
        },
        D_Handle_Show_Popup(state, action) {
            state.is_popup_open = action.payload?.status;
            state.category_list_type = action.payload?.type;
        },
        D_AddTag(state, action) {
            if (state.category_list_type in state.category_list_data) {
                state.category_list_data[state.category_list_type] = [...state.category_list_data[state.category_list_type], { "key": uuidv4(), "id": uuidv4(), "label": action.payload, "value": action.payload }]
            }
        },
        D_PatchTag(state, action) {
            state.category_list_data[state.category_list_type] = state.category_list_data[state.category_list_type].map((data) => (action.payload.directCostId === data.id) ? { ...data, "label": action.payload.directCostTitle, "value": action.payload.directCostTitle } : data)
        },
        D_RESET_ERROR_MESSAGE(state, action) {
            if (action.payload?.type in state.api_status) {
                state.api_status[action.payload.type] = { "status": 'idle', "message": null, "httpType": null }
            }
        },
        D_Reset_Complete_Api_Status(state) {
            state.api_status = API_MODULES;
        },
        D_Reset_ScreenToScreen_Api_Status(state) {
            state.screen_screen_api_status = { "status": 'idle', "message": null }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_Post_Cost_Screen1_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Post_Cost_Screen1_Data.fulfilled, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isSaveDisabled = true;
                state.isGenerateOutputDisabled = false;
            })
            .addCase(API_Post_Cost_Screen1_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
            })
            .addCase(API_Process_Cost_Screen1_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Process_Cost_Screen1_Data.fulfilled, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isGenerateOutputDisabled = true;
                state.isOutputScreenDisabled = false;
                state.screen_output[state.active_screen] = action.payload.output;
            })
            .addCase(API_Process_Cost_Screen1_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
                // state.active_screen = state.active_screen + 1;
            })

            .addCase(API_Post_Cost_Screen2_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Post_Cost_Screen2_Data.fulfilled, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isSaveDisabled = true;
                state.isGenerateOutputDisabled = false;
            })
            .addCase(API_Post_Cost_Screen2_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
            })
            .addCase(API_Process_Cost_Screen2_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Process_Cost_Screen2_Data.fulfilled, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isGenerateOutputDisabled = true;
                state.isOutputScreenDisabled = false;
                const output = action.payload;
                delete output.businessModelUpdated;
                delete output.returnsCancellationsAreChargeableEcommerce
                delete output.returnsCancellationsAreChargeableWebsite
                delete output.monthYear
                state.screen_output[state.active_screen] = output;
            })
            .addCase(API_Process_Cost_Screen2_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
            })

            .addCase(API_Post_Cost_Screen3_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Post_Cost_Screen3_Data.fulfilled, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isSaveDisabled = true;
                state.isGenerateOutputDisabled = false;
            })
            .addCase(API_Post_Cost_Screen3_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
            })
            .addCase(API_Process_Cost_Screen3_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Process_Cost_Screen3_Data.fulfilled, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isGenerateOutputDisabled = true;
                state.isOutputScreenDisabled = false;
                const output = action.payload;
                delete output?.businessModelUpdated;
                delete output.monthYear
                state.screen_output[state.active_screen] = output;
            })
            .addCase(API_Process_Cost_Screen3_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
                // state.active_screen = state.active_screen + 1;
            })

            .addCase(API_Post_Cost_Screen4_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Post_Cost_Screen4_Data.fulfilled, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isSaveDisabled = true;
                state.isGenerateOutputDisabled = false;
            })
            .addCase(API_Post_Cost_Screen4_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
            })
            .addCase(API_Process_Cost_Screen4_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Process_Cost_Screen4_Data.fulfilled, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isGenerateOutputDisabled = true;
                state.isOutputScreenDisabled = false;
                const output = action.payload;
                delete output?.businessModelUpdated;
                delete output.monthYear
                state.screen_output[state.active_screen] = output;
            })
            .addCase(API_Process_Cost_Screen4_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
            })

            .addCase(API_Post_Cost_Screen5_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Post_Cost_Screen5_Data.fulfilled, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isSaveDisabled = true;
                state.isGenerateOutputDisabled = false;
            })
            .addCase(API_Post_Cost_Screen5_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
            })
            .addCase(API_Process_Cost_Screen5_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Process_Cost_Screen5_Data.fulfilled, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isGenerateOutputDisabled = true;
                state.isOutputScreenDisabled = false;
                const output = action.payload;
                delete output?.businessModelUpdated;
                delete output.monthYear
                state.screen_output[state.active_screen] = output;
            })
            .addCase(API_Process_Cost_Screen5_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
                // state.active_screen = state.active_screen + 1;
            })


            .addCase(API_Post_Cost_Screen6_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Post_Cost_Screen6_Data.fulfilled, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isSaveDisabled = true;
                state.isGenerateOutputDisabled = false;
            })
            .addCase(API_Post_Cost_Screen6_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
            })
            .addCase(API_Process_Cost_Screen6_Data.pending, (state) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'loading';
            })
            .addCase(API_Process_Cost_Screen6_Data.fulfilled, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post.status = 'success';
                state.isGenerateOutputDisabled = true;
                state.isOutputScreenDisabled = false;
                const output = action.payload;
                delete output?.businessModelUpdated;
                delete output?.monthYear;
                delete output?.customCapex;
                state.screen_output[state.active_screen] = output;
            })
            .addCase(API_Process_Cost_Screen6_Data.rejected, (state, action) => {
                const targetScreen = state.active_screen;
                state.screen_save_process_api_status.counter = state.screen_save_process_api_status.counter + 1;
                state.screen_save_process_api_status.status[targetScreen].post = { status: "failed", message: action.error.message }
            })
            //pending- Default GET
            .addCase(API_Default_SalesChannel_Data.pending, (state) => { state.api_status.sales_channel_category_list = { ...state.api_status.sales_channel_category_list, "status": 'loading' } })
            .addCase(API_Default_DirectCost_Data.pending, (state) => { state.api_status.direct_cost_category_list = { ...state.api_status.direct_cost_category_list, "status": 'loading' } })
            .addCase(API_Default_CapacityPlanning_Data.pending, (state) => { state.api_status.capacity_planning_category_list = { ...state.api_status.capacity_planning_category_list, "status": 'loading' } })
            .addCase(API_Default_Cost_Data.pending, (state) => { state.api_status.cost_category_list = { ...state.api_status.cost_category_list, "status": 'loading' } })
            .addCase(API_Default_DerivedCost_Data.pending, (state) => { state.api_status.derived_cost_category_list = { ...state.api_status.derived_cost_category_list, "status": 'loading' } })
            .addCase(API_Default_UnderivedCost_Data.pending, (state) => { state.api_status.un_derived_cost_category_list = { ...state.api_status.un_derived_cost_category_list, "status": 'loading' } })
            .addCase(API_Default_CapexCost_Data.pending, (state) => { state.api_status.capex_category_list = { ...state.api_status.capex_category_list, "status": 'loading' } })
            .addCase(API_Default_GACost_Data.pending, (state) => { state.api_status.g_and_a_cost_category_list = { ...state.api_status.g_and_a_cost_category_list, "status": 'loading' } })

            //fulfilled- Default GET
            .addCase(API_Default_SalesChannel_Data.fulfilled, (state, action) => {
                const CHANNELS_NOT_DISABLED_OPTION = CHANNELS.filter((option) => option.isDisabled).map((option) => option.value);
                const defaultData = [...DropdownDataPreparation(action.payload), ...state.category_list_data.sales_channel_category_list]
                state.category_list_data.sales_channel_category_list = defaultData.filter((option) => !CHANNELS_NOT_DISABLED_OPTION.includes(option.value))
                state.category_list_data.sales_channel_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.sales_channel_category_list, "targetProp": "id" })
                state.api_status.sales_channel_category_list = { ...state.api_status.sales_channel_category_list, "status": 'success' }
            })
            .addCase(API_Default_DirectCost_Data.fulfilled, (state, action) => {
                state.category_list_data.direct_cost_category_list = [...DropdownDataPreparation(action.payload), ...state.category_list_data.direct_cost_category_list]
                state.category_list_data.direct_cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.direct_cost_category_list, "targetProp": "id" })
                state.api_status.direct_cost_category_list = { ...state.api_status.direct_cost_category_list, "status": 'success' }
            })
            .addCase(API_Default_CapacityPlanning_Data.fulfilled, (state, action) => {
                state.category_list_data.capacity_planning_category_list = [...DropdownDataPreparation(action.payload), ...state.category_list_data.capacity_planning_category_list]
                state.category_list_data.capacity_planning_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.capacity_planning_category_list, "targetProp": "id" })
                state.api_status.capacity_planning_category_list = { ...state.api_status.capacity_planning_category_list, "status": 'success' }
            })
            .addCase(API_Default_Cost_Data.fulfilled, (state, action) => {
                state.category_list_data.cost_category_list = [...DropdownDataPreparation(action.payload), ...state.category_list_data.cost_category_list]
                state.category_list_data.cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.cost_category_list, "targetProp": "id" })
                state.api_status.cost_category_list = { ...state.api_status.cost_category_list, "status": 'success' }
            })
            .addCase(API_Default_DerivedCost_Data.fulfilled, (state, action) => {
                state.category_list_data.derived_cost_category_list = [...DropdownDataPreparation(action.payload), ...state.category_list_data.derived_cost_category_list]
                state.category_list_data.derived_cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.derived_cost_category_list, "targetProp": "id" })
                state.api_status.derived_cost_category_list = { ...state.api_status.derived_cost_category_list, "status": 'success' }
            })
            .addCase(API_Default_UnderivedCost_Data.fulfilled, (state, action) => {
                state.category_list_data.un_derived_cost_category_list = [...DropdownDataPreparation(action.payload), ...state.category_list_data.un_derived_cost_category_list]
                state.category_list_data.un_derived_cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.un_derived_cost_category_list, "targetProp": "id" })
                state.api_status.un_derived_cost_category_list = { ...state.api_status.un_derived_cost_category_list, "status": 'success' }
            })
            .addCase(API_Default_CapexCost_Data.fulfilled, (state, action) => {
                state.category_list_data.capex_category_list = [...DropdownDataPreparation(action.payload), ...state.category_list_data.capex_category_list]
                state.category_list_data.capex_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.capex_category_list, "targetProp": "id" })
                state.api_status.capex_category_list = { ...state.api_status.capex_category_list, "status": 'success' }
            })
            .addCase(API_Default_GACost_Data.fulfilled, (state, action) => {
                state.category_list_data.g_and_a_cost_category_list = [...DropdownDataPreparation(action.payload), ...state.category_list_data.g_and_a_cost_category_list]
                state.category_list_data.g_and_a_cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.g_and_a_cost_category_list, "targetProp": "id" })
                state.api_status.g_and_a_cost_category_list = { ...state.api_status.g_and_a_cost_category_list, "status": 'success' }
            })
            //rejected- Default GET
            .addCase(API_Default_SalesChannel_Data.rejected, (state, action) => { state.api_status.sales_channel_category_list = { ...state.api_status.sales_channel_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_Default_DirectCost_Data.rejected, (state, action) => { state.api_status.direct_cost_category_list = { ...state.api_status.direct_cost_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_Default_CapacityPlanning_Data.rejected, (state, action) => { state.api_status.capacity_planning_category_list = { ...state.api_status.capacity_planning_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_Default_Cost_Data.rejected, (state, action) => { state.api_status.cost_category_list = { ...state.api_status.cost_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_Default_DerivedCost_Data.rejected, (state, action) => { state.api_status.derived_cost_category_list = { ...state.api_status.derived_cost_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_Default_UnderivedCost_Data.rejected, (state, action) => { state.api_status.un_derived_cost_category_list = { ...state.api_status.un_derived_cost_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_Default_CapexCost_Data.rejected, (state, action) => { state.api_status.capex_category_list = { ...state.api_status.capex_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_Default_GACost_Data.rejected, (state, action) => { state.api_status.g_and_a_cost_category_list = { ...state.api_status.g_and_a_cost_category_list, "status": 'failed', "message": action.error.message } })



            //pending- GET
            .addCase(API_GET_SalesChannelList_Data.pending, (state) => { state.api_status.sales_channel_category_list = { ...state.api_status.sales_channel_category_list, "status": 'loading' } })
            .addCase(API_GET_Direct_Cost_List_Data.pending, (state) => { state.api_status.direct_cost_category_list = { ...state.api_status.direct_cost_category_list, "status": 'loading' } })
            .addCase(API_GET_CapacityPlanning_Data.pending, (state) => { state.api_status.capacity_planning_category_list = { ...state.api_status.capacity_planning_category_list, "status": 'loading' } })
            .addCase(API_GET_Cost_Data.pending, (state) => { state.api_status.cost_category_list = { ...state.api_status.cost_category_list, "status": 'loading' } })
            .addCase(API_GET_DerivedCost_Data.pending, (state) => { state.api_status.derived_cost_category_list = { ...state.api_status.derived_cost_category_list, "status": 'loading' } })
            .addCase(API_GET_UnderivedCost_Data.pending, (state) => { state.api_status.un_derived_cost_category_list = { ...state.api_status.un_derived_cost_category_list, "status": 'loading' } })
            .addCase(API_GET_GACost_Data.pending, (state) => { state.api_status.g_and_a_cost_category_list = { ...state.api_status.g_and_a_cost_category_list, "status": 'loading' } })
            .addCase(API_GET_CapexCost_Data.pending, (state) => { state.api_status.capex_category_list = { ...state.api_status.capex_category_list, "status": 'loading' } })

            //fulfilled- GET
            .addCase(API_GET_SalesChannelList_Data.fulfilled, (state, action) => {
                state.category_list_data.sales_channel_category_list = [...state.category_list_data.sales_channel_category_list, ...DropdownDataPreparation(action.payload)]
                state.category_list_data.sales_channel_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.sales_channel_category_list, "targetProp": "id" })
                state.api_status.sales_channel_category_list = { ...state.api_status.sales_channel_category_list, "status": 'success' }
            })
            .addCase(API_GET_Direct_Cost_List_Data.fulfilled, (state, action) => {
                state.category_list_data.direct_cost_category_list = [...state.category_list_data.direct_cost_category_list, ...DropdownDataPreparation(action.payload)]
                state.category_list_data.direct_cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.direct_cost_category_list, "targetProp": "id" })
                state.api_status.direct_cost_category_list = { ...state.api_status.direct_cost_category_list, "status": 'success' }
            })
            .addCase(API_GET_CapacityPlanning_Data.fulfilled, (state, action) => {
                state.category_list_data.capacity_planning_category_list = [...state.category_list_data.capacity_planning_category_list, ...DropdownDataPreparation(action.payload)]
                state.category_list_data.capacity_planning_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.capacity_planning_category_list, "targetProp": "id" })
                state.api_status.capacity_planning_category_list = { ...state.api_status.capacity_planning_category_list, "status": 'success' }
            })
            .addCase(API_GET_Cost_Data.fulfilled, (state, action) => {
                state.category_list_data.cost_category_list = [...state.category_list_data.cost_category_list, ...DropdownDataPreparation(action.payload)]
                state.category_list_data.cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.cost_category_list, "targetProp": "id" })
                state.api_status.cost_category_list = { ...state.api_status.cost_category_list, "status": 'success' }
            })
            .addCase(API_GET_DerivedCost_Data.fulfilled, (state, action) => {
                state.category_list_data.derived_cost_category_list = [...state.category_list_data.derived_cost_category_list, ...DropdownDataPreparation(action.payload)]
                state.category_list_data.derived_cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.derived_cost_category_list, "targetProp": "id" })
                state.api_status.derived_cost_category_list = { ...state.api_status.derived_cost_category_list, "status": 'success' }
            })
            .addCase(API_GET_UnderivedCost_Data.fulfilled, (state, action) => {
                state.category_list_data.un_derived_cost_category_list = [...state.category_list_data.un_derived_cost_category_list, ...DropdownDataPreparation(action.payload)]
                state.category_list_data.un_derived_cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.un_derived_cost_category_list, "targetProp": "id" })
                state.api_status.un_derived_cost_category_list = { ...state.api_status.un_derived_cost_category_list, "status": 'success' }
            })
            .addCase(API_GET_GACost_Data.fulfilled, (state, action) => {
                state.category_list_data.g_and_a_cost_category_list = [...state.category_list_data.g_and_a_cost_category_list, ...DropdownDataPreparation(action.payload)]
                state.category_list_data.g_and_a_cost_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.g_and_a_cost_category_list, "targetProp": "id" })
                state.api_status.g_and_a_cost_category_list = { ...state.api_status.g_and_a_cost_category_list, "status": 'success' }
            })
            .addCase(API_GET_CapexCost_Data.fulfilled, (state, action) => {
                state.category_list_data.capex_category_list = [...state.category_list_data.capex_category_list, ...DropdownDataPreparation(action.payload)]
                state.category_list_data.capex_category_list = filterDuplicateObjectProp({ "list": state.category_list_data.capex_category_list, "targetProp": "id" })
                state.api_status.capex_category_list = { ...state.api_status.capex_category_list, "status": 'success' }
            })

            //rejected- GET
            .addCase(API_GET_SalesChannelList_Data.rejected, (state, action) => { state.api_status.sales_channel_category_list = { ...state.api_status.sales_channel_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_GET_Direct_Cost_List_Data.rejected, (state, action) => { state.api_status.direct_cost_category_list = { ...state.api_status.direct_cost_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_GET_CapacityPlanning_Data.rejected, (state, action) => { state.api_status.capacity_planning_category_list = { ...state.api_status.capacity_planning_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_GET_Cost_Data.rejected, (state, action) => { state.api_status.cost_category_list = { ...state.api_status.cost_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_GET_DerivedCost_Data.rejected, (state, action) => { state.api_status.derived_cost_category_list = { ...state.api_status.derived_cost_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_GET_UnderivedCost_Data.rejected, (state, action) => { state.api_status.un_derived_cost_category_list = { ...state.api_status.un_derived_cost_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_GET_GACost_Data.rejected, (state, action) => { state.api_status.g_and_a_cost_category_list = { ...state.api_status.g_and_a_cost_category_list, "status": 'failed', "message": action.error.message } })
            .addCase(API_GET_CapexCost_Data.rejected, (state, action) => { state.api_status.capex_category_list = { ...state.api_status.capex_category_list, "status": 'failed', "message": action.error.message } })



            //pending- CREATE
            .addCase(API_CREATE_SalesChannelList_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_CREATE_DirectCostList_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_CREATE_CapacityPlanning_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_CREATE_Cost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_CREATE_DerivedCost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_CREATE_UnderivedCost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_CREATE_GACost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_CREATE_CapexCost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })


            //fulfilled- CREATE
            .addCase(API_CREATE_SalesChannelList_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = [...state.category_list_data[state.category_list_type], TagDataFormat(action.payload)]
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": "success" }
            })
            .addCase(API_CREATE_DirectCostList_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = [...state.category_list_data[state.category_list_type], TagDataFormat(action.payload)]
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": "success" }
            })
            .addCase(API_CREATE_CapacityPlanning_Data.fulfilled, (state, action) => {
                state.category_list_data.capacity_planning_category_list = [...state.category_list_data.capacity_planning_category_list, TagDataFormat(action.payload)]
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": "success" }
            })
            .addCase(API_CREATE_Cost_Data.fulfilled, (state, action) => {
                state.category_list_data.cost_category_list = [...state.category_list_data.cost_category_list, TagDataFormat(action.payload)]
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": "success" }
            })
            .addCase(API_CREATE_DerivedCost_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = [...state.category_list_data[state.category_list_type], TagDataFormat(action.payload)]
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": "success" }
            })
            .addCase(API_CREATE_UnderivedCost_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = [...state.category_list_data[state.category_list_type], TagDataFormat(action.payload)]
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": "success" }
            })
            .addCase(API_CREATE_GACost_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = [...state.category_list_data[state.category_list_type], TagDataFormat(action.payload)]
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": "success" }
            })
            .addCase(API_CREATE_CapexCost_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = [...state.category_list_data[state.category_list_type], TagDataFormat(action.payload)]
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": "success" }
            })

            //rejected- CREATE
            .addCase(API_CREATE_SalesChannelList_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_CREATE_DirectCostList_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_CREATE_CapacityPlanning_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_CREATE_Cost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_CREATE_DerivedCost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_CREATE_UnderivedCost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_CREATE_GACost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_CREATE_CapexCost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })



            //pending- PUT
            .addCase(API_PUT_SalesChannelList_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_PUT_DirectCostList_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_PUT_CapacityPlanning_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_PUT_Cost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_PUT_DerivedCost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_PUT_UnderivedCost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_PUT_GACost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })
            .addCase(API_PUT_CapexCost_Data.pending, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'loading' } })

            //fulfilled- PUT
            .addCase(API_PUT_SalesChannelList_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = PUT_Category_Title({ "list": state.category_list_data[state.category_list_type], "payload": action.payload?.salesChannelUpdated })
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'success' }
                state.marketing_spend = state.marketing_spend.map((row) => row.dbid===action.payload?.salesChannelUpdated?._id ? {...row, "name": action.payload?.salesChannelUpdated.title} : row);
            })
            .addCase(API_PUT_DirectCostList_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = PUT_Category_Title({ "list": state.category_list_data[state.category_list_type], "payload": action.payload?.directCostUpdated })
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'success' }
                state.incurring_direct_cost = state.incurring_direct_cost.map((row) => row.dbid===action.payload?.directCostUpdated?._id ? {...row, "name": action.payload?.directCostUpdated.title} : row);
            })
            .addCase(API_PUT_CapacityPlanning_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = PUT_Category_Title({ "list": state.category_list_data[state.category_list_type], "payload": action.payload?.capacityPlanningUpdated })
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'success' }
                state.capacity_planning = state.capacity_planning.map((row) => row.dbid===action.payload?.capacityPlanningUpdated?._id ? {...row, "name": action.payload?.capacityPlanningUpdated.title} : row);
            })
            .addCase(API_PUT_Cost_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = PUT_Category_Title({ "list": state.category_list_data[state.category_list_type], "payload": action.payload?.costUpdated })
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'success' }
                state.cost = state.cost.map((row) => row.dbid===action.payload?.costUpdated?._id ? {...row, "name": action.payload?.costUpdated.title} : row);
            })
            .addCase(API_PUT_DerivedCost_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = PUT_Category_Title({ "list": state.category_list_data[state.category_list_type], "payload": action.payload?.derivedCostUpdated})
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'success' }
                state.dervied_costs_personnal_diff_department = state.dervied_costs_personnal_diff_department.map((row) => row.dbid===action.payload?.derivedCostUpdated?._id ? {...row, "name": action.payload?.derivedCostUpdated.title} : row);
            })
            .addCase(API_PUT_UnderivedCost_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = PUT_Category_Title({ "list": state.category_list_data[state.category_list_type], "payload": action.payload?.underivedCostUpdated })
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'success' }
                state.undervied_costs_personnal_diff_department = state.undervied_costs_personnal_diff_department.map((row) => row.dbid===action.payload?.underivedCostUpdated?._id ? {...row, "name": action.payload?.underivedCostUpdated?.title} : row);
            })
            .addCase(API_PUT_GACost_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = PUT_Category_Title({ "list": state.category_list_data[state.category_list_type], "payload": action.payload?.gaCostUpdated})
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'success' }
                state.currently_incurring_g_and_a_cost = state.currently_incurring_g_and_a_cost.map((row) => row.dbid===action.payload?.gaCostUpdated?._id ? {...row, "name": action.payload?.gaCostUpdated.title} : row);
            })
            .addCase(API_PUT_CapexCost_Data.fulfilled, (state, action) => {
                state.category_list_data[state.category_list_type] = PUT_Category_Title({ "list": state.category_list_data[state.category_list_type], "payload": action.payload?.capexCostUpdated })
                state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'success' }
                state.capex_cost = state.capex_cost.map((row) => row.dbid===action.payload?.capexCostUpdated?._id ? {...row, "name": action.payload?.capexCostUpdated.title} : row);
            })

            //rejected- PUT
            .addCase(API_PUT_SalesChannelList_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_PUT_DirectCostList_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_PUT_CapacityPlanning_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_PUT_Cost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_PUT_DerivedCost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_PUT_UnderivedCost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_PUT_GACost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })
            .addCase(API_PUT_CapexCost_Data.rejected, (state, action) => { state.api_status[state.category_list_type] = { ...state.api_status[state.category_list_type], "status": 'failed', "message": action.error.message } })

            .addCase(API_GET_COST_ONBOARDING_SCREEN_SLICE.pending, (state) => {
                state.screen_screen_api_status.status = 'loading';
            })
            .addCase(API_GET_COST_ONBOARDING_SCREEN_SLICE.fulfilled, (state, action) => {
                state.screen_screen_api_status.status = 'success';
                const INFO = action.payload?.info;
                if (!!INFO === false) return;
                const SCREEN_DATA = action.payload.screenData;
                const MODE_TYPES = action.payload?.MODE_TYPES;
                const screenNumberStatus = action.payload?.screenNumberStatus;
                const ACTIVE_SCREEN = screenNumberStatus.activeScreen;
                const PREVIOUS_SCREEN = screenNumberStatus.previousScreen;
                console.log("ACTIVE SCREEN", ACTIVE_SCREEN, "RESET SCREEN", PREVIOUS_SCREEN)
                state.screen_output = screen_ouput_data
                state.traceKeyNameFromGetCostScreenData = Object.keys(SCREEN_DATA);
                if(MODE_TYPES?.view && !state.traceKeyNameFromGetCostScreenData.includes("input")) return;
                if(state.traceKeyNameFromGetCostScreenData.includes("input")){ // wipe out previous screen logic
                    console.log("wipe out previous screen logic", JSON.stringify(PREVIOUS_SCREEN));
                    switch (JSON.stringify(PREVIOUS_SCREEN)) {
                        case '0': {
                            state.category_list_data.sales_channel_category_list = []
                            state.marketing_spend = []
                            state.marketing_spend_span_type = "last_six_months";
                            state.revenue_onboarding_marketing_spend_details = {
                                "marketing_spend_data_length": 0,
                                "month_year": null
                            }
                            state.cost_incurred_for_user_through_marketing = AppendUUIDKeyTable(costScreenLocals.cost.form[0].data[1].table.defaultRows)
                            break;
                        }
                        case '1': {
                            state.category_list_data.direct_cost_category_list = []
                            state.channel_sales = [];
                            state.incurring_direct_cost = AppendUUIDKeyTable(costScreenLocals.cost.form[1].data[0].table.defaultRows);
                            state.return_cancellation_chargeable_percentage = AppendUUIDKeyTable(costScreenLocals.cost.form[1].data[1].table.defaultRows)
                            break;
                        }
                        case '2': {
                            state.category_list_data.capacity_planning_category_list = []
                            state.category_list_data.cost_category_list = []
                            state.capacity_planning = AppendUUIDKeyTable(costScreenLocals.cost.form[2].data[1].table.defaultRows)
                            state.cost = AppendUUIDKeyTable(costScreenLocals.cost.form[2].data[2].table.defaultRows)
                            state.facilities_own_operation = []
                            break;
                        }
                        case '3': {
                            state.category_list_data.un_derived_cost_category_list = []
                            state.category_list_data.derived_cost_category_list = []
                            state.personnel_tl_ratio = null;
                            state.undervied_costs_personnal_diff_department = AppendUUIDKeyTable(costScreenLocals.cost.form[3].data[1].table.defaultRows);
                            state.dervied_costs_personnal_diff_department = AppendUUIDKeyTable(costScreenLocals.cost.form[3].data[2].table.defaultRows);
                            break;
                        }
                        case '4': {
                            state.category_list_data.g_and_a_cost_category_list = []
                            state.currently_incurring_g_and_a_cost = AppendUUIDKeyTable(costScreenLocals.cost.form[4].data[0].table.defaultRows);
                            break;
                        }
                        case '5': {
                            state.category_list_data.capex_category_list = []
                            state.capex_cost = AppendUUIDKeyTable(costScreenLocals.cost.form[5].data[0].table.defaultRows);
                            break;
                        }
                    }
                }
                state.categoryList = [];
                if(SCREEN_DATA?.salesChannelsListCombined){
                    state.categoryList = SCREEN_DATA?.salesChannelsListCombined?.map((catg) => ({ label: catg.title, id: catg._id }))
                }
                if(SCREEN_DATA?.productCategoryCombined){
                    state.categoryList = SCREEN_DATA?.productCategoryCombined?.map((catg) => ({ label: catg.title, id: catg._id }))
                }
                if(SCREEN_DATA?.directCostListCombined){
                    state.categoryList = [...state.categoryList, ...SCREEN_DATA?.directCostListCombined?.map((catg) => ({ label: catg.title, id: catg._id }))]
                }
                if(SCREEN_DATA?.derivedCostListCombined){
                    state.categoryList = [...state.categoryList, ...SCREEN_DATA?.derivedCostListCombined?.map((catg) => ({ label: catg.title, id: catg._id }))]
                }
                if(SCREEN_DATA?.capacityPlanningListCombined){
                    state.categoryList = [...state.categoryList, ...SCREEN_DATA?.capacityPlanningListCombined?.map((catg) => ({ label: catg.title, id: catg._id }))]
                }
                if(SCREEN_DATA?.underivedCostListCombined){
                    state.categoryList = [...state.categoryList, ...SCREEN_DATA?.underivedCostListCombined?.map((catg) => ({ label: catg.title, id: catg._id }))]
                }
                if(SCREEN_DATA?.gaCostListCombined){
                    state.categoryList = [...state.categoryList, ...SCREEN_DATA?.gaCostListCombined?.map((catg) => ({ label: catg.title, id: catg._id }))]
                }
                if(SCREEN_DATA?.capexCostListCombined){
                    state.categoryList = [...state.categoryList, ...SCREEN_DATA?.capexCostListCombined?.map((catg) => ({ label: catg.title, id: catg._id }))]
                }
                switch (INFO.screenReference) { // injecting active screen data
                    case 'costScreenOne': {
                        const formattedData = costScreenOneDataInjection({ "data": SCREEN_DATA, "input": SCREEN_DATA?.input, "output": SCREEN_DATA?.output })
                        const {
                            marketing_spend,
                            marketing_spend_span_type,
                            revenue_onboarding_marketing_spend_details,
                            cost_incurred_for_user_through_marketing,
                            output,
                            isSaveDisabled
                        } = formattedData || {};
                        let sales_channel_category_list = [
                            ...DropdownDataPreparation(SCREEN_DATA.defaultSalesChannelsList),
                            ...DropdownDataPreparation(SCREEN_DATA.salesChannelsList)
                        ]
                        const CHANNELS_NOT_DISABLED_OPTION = CHANNELS.filter((option) => option.isDisabled).map((option) => option.value);
                        state.category_list_data.sales_channel_category_list = filterDuplicateObjectProp({ "list": sales_channel_category_list, "targetProp": "id" })
                        state.category_list_data.sales_channel_category_list = state.category_list_data.sales_channel_category_list.filter((option) => !CHANNELS_NOT_DISABLED_OPTION.includes(option.value))
                        console.log(state.category_list_data.sales_channel_category_list,"state.category_list_data.sales_channel_category_list")
                        // console.log(formattedData, "formattedData", state.category_list_data.sales_channel_category_list);
                        const channelsToRemove = ['E-Commerce', 'Social Media', 'Website & Mobile App'];
                        for(let i=0; i<channelsToRemove.length; i++){
                            if(!SCREEN_DATA.revenueScreenOneInput.channels_of_sales.includes(channelsToRemove[i])){
                                state.category_list_data.sales_channel_category_list = state.category_list_data.sales_channel_category_list.filter((option) => !(option.value === channelsToRemove[i]))
                            }
                        }
                        state.marketing_spend = marketing_spend
                        state.marketing_spend_span_type = marketing_spend_span_type
                        state.revenue_onboarding_marketing_spend_details = revenue_onboarding_marketing_spend_details
                        state.cost_incurred_for_user_through_marketing = cost_incurred_for_user_through_marketing
                        state.screen_output = { ...state.screen_output, [0]: !!output ? output : null }
                        state.isSaveDisabled = isSaveDisabled;
                        break;
                    }
                    case 'costScreenTwo': {
                        let direct_cost_category_list = [
                            ...DropdownDataPreparation(SCREEN_DATA.defaultDirectCostList),
                            ...DropdownDataPreparation(SCREEN_DATA.directCostList)
                        ]
                        state.category_list_data.direct_cost_category_list = filterDuplicateObjectProp({ "list": direct_cost_category_list, "targetProp": "id" })
                        const formattedData = costScreenTwoDataInjection({ data: SCREEN_DATA, input: SCREEN_DATA?.input, output: SCREEN_DATA?.output, "defaultRowNameList": state.category_list_data.direct_cost_category_list });
                        const {
                            channel_sales,
                            incurring_direct_cost,
                            return_cancellation_chargeable_percentage,
                            output
                        } = formattedData || {}
                        state.channel_sales = channel_sales;
                        state.incurring_direct_cost = incurring_direct_cost;
                        state.return_cancellation_chargeable_percentage = return_cancellation_chargeable_percentage;
                        state.screen_output = { ...state.screen_output, [1]: output }
                        console.log('"exe cs2"');
                        break;
                    }
                    case 'costScreenThree': {
                        let capacity_planning_category_list = [
                            ...DropdownDataPreparation(SCREEN_DATA.defaultcapacityPlanningList),
                            ...DropdownDataPreparation(SCREEN_DATA.capacityPlanningList)
                        ]
                        let cost_category_list = [
                            ...DropdownDataPreparation(SCREEN_DATA.defaultCostList),
                            ...DropdownDataPreparation(SCREEN_DATA.costList)
                        ]
                        state.category_list_data.capacity_planning_category_list = filterDuplicateObjectProp({ "list": capacity_planning_category_list, "targetProp": "id" })
                        state.category_list_data.cost_category_list = filterDuplicateObjectProp({ "list": cost_category_list, "targetProp": "id" })
                        const formattedData = costScreenThreeDataInjection({ data: SCREEN_DATA, input: SCREEN_DATA?.input, output: SCREEN_DATA?.output, "defaultRowNameCapPlanningList": state.category_list_data.capacity_planning_category_list, "defaultRowNameCostList": state.category_list_data.cost_category_list });
                        const {
                            facilities_own_operation,
                            cost,
                            capacity_planning,
                            output
                        } = formattedData || {}
                        state.capacity_planning = capacity_planning
                        state.cost = cost
                        state.facilities_own_operation = facilities_own_operation
                        state.screen_output = { ...state.screen_output, [2]: output }
                        break;
                    }
                    case 'costScreenFour': {
                        let derived_planning_category_list = [
                            ...DropdownDataPreparation(SCREEN_DATA.defaultDrivedCostList),
                            ...DropdownDataPreparation(SCREEN_DATA.derivedCostList)
                        ]
                        let underived_category_list = [
                            ...DropdownDataPreparation(SCREEN_DATA.defaultUnderivedCostList),
                            ...DropdownDataPreparation(SCREEN_DATA.underivedCostList)
                        ]
                        state.category_list_data.derived_cost_category_list = filterDuplicateObjectProp({ "list": derived_planning_category_list, "targetProp": "id" })
                        state.category_list_data.un_derived_cost_category_list = filterDuplicateObjectProp({ "list": underived_category_list, "targetProp": "id" })
                        const formattedData = costScreenFourDataInjection({ input: SCREEN_DATA?.input, output: SCREEN_DATA?.output, "defaultRowNameDerviedCostList": state.category_list_data.derived_cost_category_list, "defaultRowNameUnDerviedCostList": state.category_list_data.un_derived_cost_category_list })
                        const {
                            personnel_tl_ratio,
                            undervied_costs_personnal_diff_department,
                            dervied_costs_personnal_diff_department,
                            output
                        } = formattedData || {};
                        state.personnel_tl_ratio = personnel_tl_ratio;
                        state.undervied_costs_personnal_diff_department = undervied_costs_personnal_diff_department;
                        state.dervied_costs_personnal_diff_department = dervied_costs_personnal_diff_department;
                        state.screen_output = { ...state.screen_output, [3]: output }
                        break;
                    }
                    case 'costScreenFive': {
                        let gaCost_category_list = [
                            ...DropdownDataPreparation(SCREEN_DATA.defaultGACostList),
                            ...DropdownDataPreparation(SCREEN_DATA.gaCostList)
                        ]
                        state.category_list_data.g_and_a_cost_category_list = filterDuplicateObjectProp({ "list": gaCost_category_list, "targetProp": "id" })
                        const screen_three_formatted_data = costScreenThreeDataInjection({ data: SCREEN_DATA?.costScreenThreeInput, input: SCREEN_DATA?.costScreenThreeInput, output: null, "defaultRowNameCapPlanningList": [], "defaultRowNameCostList": [] });
                        const screen_five_formatted_data = costScreenFiveDataInjection({ input: SCREEN_DATA?.input, output: SCREEN_DATA?.output, "defaultRowNameList": state.category_list_data.g_and_a_cost_category_list })
                        const {
                            currently_incurring_g_and_a_cost,
                            output
                        } = screen_five_formatted_data || {}
                        const {
                            facilities_own_operation,
                            cost,
                            capacity_planning
                        } = screen_three_formatted_data || {}
                        state.currently_incurring_g_and_a_cost = currently_incurring_g_and_a_cost;
                        state.screen_output = { ...state.screen_output, [4]: output }
                        state.facilities_own_operation = facilities_own_operation;
                        state.cost = cost;
                        state.capacity_planning = capacity_planning
                        const isLeasePresent = cost.find((row) => row.name==="Lease")
                        const isOverHeadCostPresent = currently_incurring_g_and_a_cost.find((row) => row.name==="Overhead Cost");
                        if(isLeasePresent && isOverHeadCostPresent && facilities_own_operation.includes("Office")){
                            state.currently_incurring_g_and_a_cost = currently_incurring_g_and_a_cost.map((row) => {
                                if(row.name==="Overhead Cost"){
                                    return { ...row, "value": { "percentOfLease": row.value.percentOfLease ? row.value.percentOfLease : null, "Amount": isLeasePresent.office } };
                                }
                                return row;
                            })
                        }
                        break;
                    }
                    case 'costScreenSix': {
                        let capex_category_list = [
                            ...DropdownDataPreparation(SCREEN_DATA.defaultCapexCostList),
                            ...DropdownDataPreparation(SCREEN_DATA.capexCostList)
                        ]
                        state.category_list_data.capex_category_list = filterDuplicateObjectProp({ "list": capex_category_list, "targetProp": "id" })
                        const formattedData = costScreenSixDataInjection({ input: SCREEN_DATA?.input, output: SCREEN_DATA?.output, "defaultRowNameList": state.category_list_data.capex_category_list })
                        const {
                            capex_cost,
                            output
                        } = formattedData || {}
                        state.capex_cost = capex_cost;
                        state.screen_output = { ...state.screen_output, [5]: output }
                        break;
                    }
                }
            })
            .addCase(API_GET_COST_ONBOARDING_SCREEN_SLICE.rejected, (state, action) => {
                state.screen_screen_api_status.status = 'failed';
            })
    }
});

export const { D_Reset_ScreenToScreen_Api_Status, D_Patch_Cost_SliderValue, D_Update_TableValue_Based_Stepper, D_Trace_Cost_StepperPredictionApiStatus, D_Reset_Save_Process_Error_Status, D_Patch_CostScreen_Fetched_Data_From_DB, D_Reset_category_list_data, D_Reset_Complete_Api_Status, D_Fetch_category_List_Status, D_Patch_Revenue_Onboarding_Data, D_Reset_Cost_Onboarding_State, D_Show_Error_Message, D_Handle_Show_Popup, D_ToggleScreen, D_Capture_Value, D_Add_Table_Row, D_Remove_Table_Row, D_Capture_CheckBox_Data, D_AddTag, D_PatchTag, D_RESET_ERROR_MESSAGE } = CostOnboardingSlice.actions;
export default CostOnboardingSlice.reducer;
