//hooks
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import useSaveProcessApiData from './CostOnboarding/hooks/useSaveProcessApiData';
import useFetchCategoryList from './CostOnboarding/hooks/useFetchCategoryList';
//library
import { useSelector, useDispatch } from "react-redux"
import Stepper from '../../../DesignSystem/Stepper/Stepper';
import HeadingFour from '../../../DesignSystem/TypographyHeadings/HeadingFour'
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton'
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton'
import costScreenlocals from '../../../localization/localization_en_cost_screen.json'
import COScreen1 from './CostOnboarding/COScreen1';
import COScreen2 from './CostOnboarding/COScreen2';
import COScreen3 from './CostOnboarding/COScreen3';
import COScreen4 from './CostOnboarding/COScreen4';
import COScreen5 from './CostOnboarding/COScreen5';
import COScreen6 from './CostOnboarding/COScreen6';
import CategoryButton from './CostOnboarding/microcomponents/CategoryButton';
import CategoryList from './CostOnboarding/subcomponents/CategoryList';
// redux action
import { API_GET_COST_ONBOARDING_SCREEN_SLICE, D_Reset_ScreenToScreen_Api_Status, D_ToggleScreen, D_Update_TableValue_Based_Stepper } from "../costSlice";
import OutputScreen from './RevenueOnboarding/OutputScreen';
import { checkFutureScreenDataFilled } from '../functionality/checkFutureScreenDataFilled';
import { fetchWarningPopupErrorMessage } from '../functionality/fetchWarningPopupErrorMessage';
import OopsPopUp from '../../reuseablecomponent/PopUpVarients/OopsPopUp';
import { D_UpdateFilterYearCharts } from "../businessModalSlice"
import { D_Update_Onboarding_Screen_Id } from '../../../features/appSlice'
import PageLoader from '../../reuseablecomponent/PageLoader';
import useFetchBMOtherScreen from '../../../hooks/useFetchBMOtherScreen';
import ComponentErrorBoundary from '../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const costScreenTitle = costScreenlocals.cost.title;
const costLocals = costScreenlocals.cost.form
const tagName = costScreenlocals.cost.tag_info
const CostScreenComponents = [COScreen1,COScreen2,COScreen3,COScreen4,COScreen5,COScreen6]

const CostOnboarding = () => {
    const dispatch = useDispatch();
    const [isFormFilled, setIsFormFilled] = useState(false);
    const navigator = useNavigate();
    const { isSaveDisabled, isGenerateOutputDisabled, isOutputScreenDisabled, category_list_type, isCategoryListFetchedFromServer, screen_screen_api_status, category_list_data, categoryList } = useSelector((state) => state.costScreen)
    const active_screen = useSelector((state) => state.costScreen.active_screen)
    const isTagModalOpen = useSelector((state) => state.costScreen.is_popup_open)
    const screen_output = useSelector((state) => state.costScreen.screen_output)
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)
    const overallScreenRefObj = useSelector((state) => state.businessModal.tracing_user_filled_input_screen)
    const tagDetails = tagName[JSON.stringify(active_screen)];
    const businessModelId = useSelector((state) => state.businessModal.active_BusinessModal_Id)
    const { fetchTargetScreenData, api_status } = useFetchBMOtherScreen();
    const traceKeyNameFromGetCostScreenData = useSelector((state) => state.costScreen.traceKeyNameFromGetCostScreenData);
    const debtWorkingData = useSelector((state) => state.businessModal.fetch_screen_screen_data)
    const { traceButtonType, updateRefreshCounter, topScroll, bottomScroll, resetRefreshCounter, SubmitDataToServer, refreshCounter, handleUpdateTraceButtonType, handleSaveButton, isFormFilledAsExpected, warningPopUpDetails, networkStatus, handleResetWarningPopup, updatePopupWarning, isLoading, updateTraceButtonType } = useSaveProcessApiData();
    useFetchCategoryList({ isCategoryListFetchedFromServer });
    React.useEffect(() => {
        dispatch(D_UpdateFilterYearCharts(new Date().getFullYear()));
        // dispatch(API_GET_COST_ONBOARDING_SCREEN_SLICE({ "activeScreen": 0, "previousScreen": 0 }))
    }, [active_screen])
    React.useEffect(() => {
        if(api_status==="success"){
            if(EDIT_MODE){
                navigator(`/product/home/business-modal/edit/${businessModelId}/debt-working`)
            }else{
                if(VIEW_MODE){
                    if(!debtWorkingData?.screenData?.input){
                        updatePopupWarning({ "isPopUpOpen": true, "traceButtonClicked": VIEW_MODE ? "view" :"edit" })
                        return;
                    }
                }
                navigator('/product/home/business-modal/debt-working')
            }
        }
    }, [api_status])

    const handleToggleNextScreen = () => {
        resetRefreshCounter();
        if(active_screen===costLocals.length-1){
            fetchTargetScreenData({ screenName: "debtWorkingScreen", previousScreen: "costScreen" });
        }else{
            dispatch(D_ToggleScreen({ type: "next" }))
        }
        if(EDIT_MODE && active_screen<costLocals.length-1){
            navigator(`/product/home/business-modal/edit/${businessModelId}/cost-screen`)
            dispatch(D_Update_Onboarding_Screen_Id({"screenName": "cost-screen", "value":active_screen+1}));
        }
    }

    const navigateToTargetScreen = () => {
        if(traceButtonType === "prev"){
            dispatch(D_ToggleScreen({ type: "prev" }))
            if(EDIT_MODE){
                navigator(`/product/home/business-modal/edit/${businessModelId}/cost-screen`)
                dispatch(D_Update_Onboarding_Screen_Id({"screenName": "cost-screen", "value":active_screen-1}));
            }
        }else{
            handleToggleNextScreen();
        }
    }

    React.useEffect(() => {
        if(screen_screen_api_status.status === "success"){
            dispatch(D_Reset_ScreenToScreen_Api_Status());
            if(VIEW_MODE){
                if(!traceKeyNameFromGetCostScreenData?.includes("input")){
                    updatePopupWarning({ "isPopUpOpen": true, "traceButtonClicked": VIEW_MODE ? "view" :"edit" })
                    return;
                }
            }
            navigateToTargetScreen();
        }
    }, [screen_screen_api_status.status])

    const VIEW_MODE = MODE_TYPE.view;
    const EDIT_MODE = MODE_TYPE.edit;

    const handleStepperOnChange = (value) => {
        dispatch(D_Update_TableValue_Based_Stepper({ tableName: 'marketing_spend', stepperValue: 2 }))
        // updateRefreshCounter();
        handleUpdateTraceButtonType("stepper");
        // if(![1].includes(trackComponentRendering[activeScreenNumber])) return;
        // setTraceButtonType("stepper")
        // setRefreshCounter((prev) => prev+1);
        // dispatch(D_Increement_SalesMargin({value}))
        // dispatch(D_Trace_StepperPredictionApiStatus(1));
    }
    const handleToggleScreen = ({ type }) => {
        updateTraceButtonType(type);
        const activeScreen = type==="next"
        ? active_screen===5 ? 5 : active_screen+1
        : active_screen===0 ? 0 : active_screen-1;
        const previousScreen = active_screen;
        dispatch(API_GET_COST_ONBOARDING_SCREEN_SLICE({ activeScreen, previousScreen }));
    }
    const arrayOfAllCatgegoryList = categoryList;
    console.log(arrayOfAllCatgegoryList, "arrayOfAllCatgegoryList")
    return (
        <div className='pt-[5rem] flex flex-col gap-y-[24px]' ref={topScroll}>
            <HeadingFour text={costScreenTitle[active_screen]} />
            { screen_screen_api_status.status==="loading" ? <PageLoader/> : null }
            {
                (warningPopUpDetails.isPopUpOpen && networkStatus) ? (
                    <OopsPopUp 
                        description1={fetchWarningPopupErrorMessage({ "buttonClickedName": warningPopUpDetails.traceButtonClicked })?.message?.description1} 
                        description2={fetchWarningPopupErrorMessage({ "buttonClickedName": warningPopUpDetails.traceButtonClicked })?.message?.description2} 
                        isOpen={warningPopUpDetails.isPopUpOpen} 
                        onClick={handleResetWarningPopup} 
                    />
                ) : null
            }
            <div className='px-[40px]'>
                <Stepper currentStage={active_screen} onboardingItems={costLocals.map((_,i) => i)} />
            </div>
            <ul className='flex justify-end gap-x-[1rem]'>
                {
                    tagDetails.map((tagDetail, index) => {
                        const { modalTypo, buttonName, dataIndex, warningTooltipDataIndex } = tagDetail || {};
                        return (
                            <React.Fragment key={index}>
                                { ( isTagModalOpen && category_list_type===dataIndex ) ? <CategoryList warningTooltipDataIndex={warningTooltipDataIndex} Title={modalTypo.title} InputPlaceholder={modalTypo.placeHolder} /> : null }
                                <CategoryButton type={dataIndex} text={buttonName} />
                            </React.Fragment>
                        )
                        
                    })
                }
            </ul>
            <div>
                {
                    costLocals.map((screen, index) => {
                        const Component = CostScreenComponents[index];
                        if(index === active_screen) return (
                            <ComponentErrorBoundary>
                                <Component 
                                    key={index}
                                    formData={screen}
                                    counterToTriggerFormStatus={refreshCounter}
                                    isFormFilledAsExpected={isFormFilledAsExpected}
                                />
                            </ComponentErrorBoundary>
                        )
                    })
                }
            </div>
            {/* {JSON.stringify(screen_output[active_screen])} */}
            <div className='flex justify-between' style={{ display: VIEW_MODE ? "none" : "flex" }}>
                <div style={{ "visibility": !isOutputScreenDisabled ? "hidden" : "visible" }}>
                    <SecondaryButton isDisabled={active_screen===0 || isLoading} text={"Back"} onClick={() => handleToggleScreen({ type: "prev" })} />
                </div>
                {!VIEW_MODE ? <PrimaryButton isLoading={isLoading && traceButtonType==="save"} isDisabled={isSaveDisabled || isLoading} text={"Save"} onClick={handleSaveButton} /> : null}
                <PrimaryButton isLoading={isLoading && traceButtonType==="output"} isDisabled={isGenerateOutputDisabled || isLoading || !!screen_output[active_screen]} text={"Generate Output"} onClick={() => handleUpdateTraceButtonType("output")} />
            </div>
            {/* isOutputScreenDisabled */}
            <div ref={bottomScroll} style={{ display: ( VIEW_MODE ? !!screen_output[active_screen]===false : isSaveDisabled===false || !!screen_output[active_screen]===false ) ? "none" : "block" }}>
                <ComponentErrorBoundary>
                    <OutputScreen 
                        category_list={arrayOfAllCatgegoryList} 
                        moduleType="cost" 
                        isStepperVisible={[0].includes(active_screen)} 
                        stepperOnChange={handleStepperOnChange} 
                        outputData={screen_output[active_screen]} 
                        screenNumber={active_screen} 
                        outputSliderValues={[]} 
                        type={"cost_screen"} 
                    />
                </ComponentErrorBoundary>
            </div>
            <div style={{ "visibility": (VIEW_MODE ? false : isSaveDisabled===false || !!screen_output[active_screen]===false) ? "hidden" : "visible" }} className='flex justify-between pb-[2rem]'>
                <SecondaryButton isDisabled={active_screen===0 || isLoading} text={"Back"} onClick={() => handleToggleScreen({ type: "prev" }) } />
                <PrimaryButton text={"Next"} onClick={() => handleToggleScreen({ type: "next" }) } />
            </div>
            {/* <PrimaryButton text={"Next"} onClick={() => handleToggleScreen({ type: "next" }) } /> */}
        </div>
    )
}

export default CostOnboarding