import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'

const TextSmall = ({ fontWeight = 700, color = "#111827", text, margin, padding, display="inline", independentStyles, className }) => {
  const styles = {
    color: color,
    lineHeight: "140%",
    fontWeight,
    ...generatePadding(padding?.direction, padding?.number),
    ...generateMargin(margin?.direction, margin?.number),
    display: display,
    ...independentStyles
  }
  return (
    <React.Fragment>
      <span style={styles} className={`text-[12px] font-bold font-InterRegular ${className}`}>{text}</span>
    </React.Fragment>
  )
}

export default TextSmall