export const segregateSelectOption = (data, selectOptions) => {
    let result = [];
    let mandatoryOption = "Website & Mobile App";
    let isPresent = data.includes(mandatoryOption);
    if (!isPresent) {
        return result;
    }
    data.map((value) => {
        if (!!selectOptions[value]) {
            result.push(selectOptions[value])
        }
    })
    return [].concat(...result).sort((a,b) => a.position - b.position)
}