export const extractKeywords = (data, type) => {
    const tagsData = data[0];
    try {
        const keyWords = [];
        tagsData?.split('\n')?.forEach((ele) => {
            const result = ele?.split(':')[0]?.split('.')[1];
            if (result !== undefined && result.length) {
                keyWords.push(result?.trimStart());
            }
        })
        const uniqueTags = new Set(keyWords);
        if (type === "fetchSolutions") {
            return Array.from(uniqueTags).toString();
        } else {
            const tagsWithLabelValue = Array.from(uniqueTags).map((tag) => ({ label: tag, value: tag }))
            return tagsWithLabelValue;
        }
    } catch (err) {
        throw new Error("Error in response data");
    }
}