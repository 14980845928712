const PayloadPreparationBMOther = ({ data, targetFormName, product_category, fiscal_year }) => {
    switch (targetFormName) {
        case 'annual_inventory_days_product_category': {
            let formattedData = data.map((subData) => {
                let yearData = []
                for(let attr of Object.entries(subData)){
                    const yearNumber = Number(attr[0]?.slice(-1))-1;
                    if(!isNaN(yearNumber)){
                        yearData = [ ...yearData, { "year": fiscal_year+yearNumber, "value": attr[1]===null ? 0 : attr[1] } ]
                    }
                }
                return {
                    "dbid": subData.dbid,
                    "name": subData.name,
                    "annual_inventory_days_product_category": yearData
                }
            })
            return formattedData;
        }
    }
}

export default PayloadPreparationBMOther