import React from "react";

const LinkOutline = ({ color = "#fff", ...props }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_12646)">
    <path d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM8 11H16V13H8V11Z" fill="#424242"/>
    </g>
    <defs>
    <clipPath id="clip0_1_12646">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
);
export default LinkOutline;
