import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import DefineForm from "./components/DefineForm"
import useCheckFormFillStatus from './hooks/useCheckFormFillStatus';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const checkToRender2ndTableComponent = (data) => {
    if(data.length===0) return false;
    const mandatoryColumns = {"WarehouseFactory":true,"Collection Center":true}
    let counter = 0;
    data.map((d) => {
        if(d in mandatoryColumns){
            counter++;
        }
    })
    return counter>0;
};

const COScreen3 = ({ formData, counterToTriggerFormStatus, isFormFilledAsExpected }) => {
    const dispatch = useDispatch();
    const facilities_own_operation = useSelector((state) => state.costScreen.facilities_own_operation)
    useCheckFormFillStatus({ formData, counterToTriggerFormStatus, isFormFilledAsExpected });
    return (
        <div className='flex flex-col gap-y-[24px]'>
            {
                formData.data.map((formItem, index) => {
                    if(index === 1 && !checkToRender2ndTableComponent(facilities_own_operation)) return; 
                    if(index === 2 && facilities_own_operation.length===0) return;
                    return (
                        <ComponentErrorBoundary>
                            <DefineForm key={index} formItem={formItem} />
                        </ComponentErrorBoundary>
                    )
                })
            }
        </div>
    )
}

export default COScreen3