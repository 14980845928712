import React from 'react';
import { Page, Text, View, Font, Document, StyleSheet } from '@react-pdf/renderer';

const ReactPDFDocument = ({ data, moduleName }) => {

    // const data = [
    //     {
    //         "prompt_title": "Hello! I'm here to assist you in identifying the problems and getting feasible solutions for them. Let's get started. Please enter the product & industry details.",
    //         "prompt_qa": [
    //             {
    //                 "question": "Product Details",
    //                 "answer": "apple"
    //             },
    //             {
    //                 "question": "Your Industry",
    //                 "answer": "fruit"
    //             }
    //         ],
    //         "prompt_response": {
    //             "text": "Here is a list of problems that your product is currently facing:",
    //             "description": test
    //         }
    //     },
    //     {
    //         "prompt_title": null,
    //         "prompt_qa": [],
    //         "prompt_response": {
    //             "text": "Here is a list of problems that your product is currently facing:",
    //             "description": test
    //         }
    //     },
    //     {
    //         "prompt_title": null,
    //         "prompt_qa": [],
    //         "prompt_response": {
    //             "text": "Here is a list of problems that your product is currently facing:",
    //             "description": test
    //         }
    //     },
    //     {
    //         "prompt_title": null,
    //         "prompt_qa": [],
    //         "prompt_response": {
    //             "text": "Here is a list of problems that your product is currently facing:",
    //             "description": test
    //         }
    //     }
    // ]

    const styles = StyleSheet.create({
        heading: {
            fontFamily: 'Helvetica-Bold',
            fontWeight: 600,
            fontSize: 12
        },
        flexRow: {
            flexDirection: 'row'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
            fontSize: 12,
        },
        questionSection: {
            backgroundColor: "#eeeeee",
            padding: 8,
            borderRadius: 5
        },
        marginBottomMd: {
            marginVertical: 12
        },
        promptAnswer: {
            borderWidth: 1,
            borderColor: "#beceff",
            borderRadius: 4,
            padding: 8,
            backgroundColor: "#fafafa",
            fontSize: 10
        }
    });

    return (
        <Document>
            <Page size="A4" wrap={false}>
                <Text style={{ ...styles.heading, marginLeft: 20, marginTop: 20 }}>{moduleName}</Text>
                <View style={styles.section} wrap={false}>
                    {
                        Array.isArray(data) && data.map((pageData, index) => {
                            if (index === 0) {
                                return (
                                    <View key={index}>
                                        <View style={styles.questionSection}>
                                            <Text style={{ ...styles.heading, borderRadius: 4, marginBottom: 8 }}>{pageData.prompt_title}</Text>
                                            <View>
                                                {
                                                    pageData.prompt_qa.map((qa, index) => {
                                                        return (
                                                            <View key={index} style={{ ...styles.flexRow, backgroundColor: "#FFFFFF", paddingVertical: 10, paddingHorizontal: 8, borderRadius: 4, marginBottom: 4 }}>
                                                                <Text style={styles.heading}>{qa.question}</Text>
                                                                <Text style={{ marginLeft: 5, color: "#372ee5" }}> - {qa.answer}</Text>
                                                            </View>
                                                        )
                                                    })
                                                }
                                            </View>
                                        </View>
                                        {
                                            pageData.prompt_response.description
                                                ? (
                                                    <View>
                                                        <Text style={{ ...styles.heading, ...styles.marginBottomMd }}>{pageData.prompt_response.text}</Text>
                                                        <Text style={styles.promptAnswer}>{pageData.prompt_response.description}</Text>
                                                    </View>
                                                ) : null
                                        }
                                    </View>
                                )
                            }
                            return (
                                <View key={index}>
                                    <Text style={{ ...styles.heading, ...styles.marginBottomMd }}>{pageData.prompt_response.text}</Text>
                                    <Text style={styles.promptAnswer}>{pageData.prompt_response.description}</Text>
                                </View>
                            )
                        })
                    }
                </View>
            </Page>
        </Document >
    )
}

export default ReactPDFDocument