import psmPDF from "./SampleExamplePDFs/PSM_PDF.svg"
import icpPDF from "./SampleExamplePDFs/ICP_PDF.svg"
import caPDF from "./SampleExamplePDFs/CA_PDF.png"
import bbcPDF from "./SampleExamplePDFs/BBC_PDF.svg"
import GTM_PDF from "./SampleExamplePDFs/GTM_PDF.png"
import MF_PDF from "./SampleExamplePDFs/MF_PDF.png"
import TMA_PDF from "./SampleExamplePDFs/TMA_PDF.png"
import LSC_PDF from "./SampleExamplePDFs/LSC_PDF.png"

import ailocals from "../../localization/localization_en_ai.json"

export const prompts = [
    {
        "competitorAnalysis": [
            {
                "id": 0,
                "mainTitle": "PRODUCT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Features",
                "description": "List of key features and functionalities of products in [Industry] market located in [Geography].",
                "status": "active"
            },
            {
                "id": 1,
                "mainTitle": "PRODUCT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Customer Support & Training",
                "description": "Report on the availability of customer support services in [Industry] Sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 2,
                "mainTitle": "PRODUCT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "USP",
                "description": "Comparison of USPs of top competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 3,
                "mainTitle": "PRODUCT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Tech Stacks",
                "description": "Analysis of technology stacks used by competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 4,
                "mainTitle": "PRODUCT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Pricing Ranges",
                "description": "Analysis of pricing ranges offered by key competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 5,
                "mainTitle": "PRODUCT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Voice Of Customer",
                "description": "Analysis of customer feedback for [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 6,
                "mainTitle": "MARKETING SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Industry Focus",
                "description": "Analysis of the current industry focus of [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 7,
                "mainTitle": "MARKETING SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Target Market",
                "description": "Description of the ideal target market for [Company Name] and competitors in [Industry] sector in [Geography].",
                "status": "active"
            },
            {
                "id": 8,
                "mainTitle": "MARKETING SECTION",
                productName: "competitorAnalysis",
                "subtitle": "ICP",
                "description": "Ideal Customer Profile (ICP) for [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 9,
                "mainTitle": "MARKETING SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Key Customers",
                "description": "Analysis of the primary customer base for [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 10,
                "mainTitle": "MARKETING SECTION",
                productName: "competitorAnalysis",
                "subtitle": "SEO Keywords",
                "description": "List of SEO keywords used by [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 11,
                "mainTitle": "MARKETING SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Branding & Messaging",
                "description": "Analysis of branding and messaging strategies of [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 12,
                "mainTitle": "MARKETING SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Digital Presence, Content Marketing and Partnerships & Collaborations",
                "description": "Report on lead generation strategies employed by key competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 13,
                "mainTitle": "SALES SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Sales Channels",
                "description": "List of sales channels utilized by business competitors of [Company Name] in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 14,
                "mainTitle": "SALES SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Sales Collaterals & Resources",
                "description": "Overview of sales collaterals and resources used by [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 15,
                "mainTitle": "SALES SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Sales Tools & Technology",
                "description": "List of sales tools and technology used by [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 16,
                "mainTitle": "SALES SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Lead Generation Strategies",
                "description": "Report on lead generation strategies employed by key competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 17,
                "mainTitle": "INVESTMENT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Investors, Funding Rounds & Total Investment",
                "description": "Report on primary investors and investment activities of [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 18,
                "mainTitle": "INVESTMENT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Valuation",
                "description": "Analysis of the current valuation of [Company Name] and top competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 19,
                "mainTitle": "INVESTMENT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Financial Performance",
                "description": "Report on financial performance of [Company Name] and primary competitors in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 20,
                "mainTitle": "INVESTMENT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Market Share",
                "description": "Analysis of market share estimation for each competitor relative to [Company Name] in [Industry] sector located in [Geography].",
                "status": "active"
            },
            {
                "id": 21,
                "mainTitle": "INVESTMENT SECTION",
                productName: "competitorAnalysis",
                "subtitle": "Public Perception",
                "description": "Analysis of public perception of [Company Name] and competitors in [Industry] sector located in [Geography].",
                "status": "active"
            }
        ]
    }
]

export const promptQuestions = ailocals.ai.prompt_questions;


export const samplepdfs = {
    "problemSolutionMatrix":psmPDF,
    "competitorAnalysis":caPDF,
    "idealCustomerProfile":icpPDF,
    "buildBusinessCanvas":bbcPDF,
    "leanStartupCanvas": LSC_PDF,
    "tma": TMA_PDF,
    "gtm": GTM_PDF,
    "marketingFunnel": MF_PDF
}