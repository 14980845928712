import React from 'react'
import { useNavigate } from "react-router-dom";
import OopsPopUp from '../../reuseablecomponent/PopUpVarients/OopsPopUp'
import settingLocals from "../../../localization/localization_en_setting.json"

const free_trial_expired = settingLocals.settings.tabs.Subscription.payment.free_trial_expired_error;

const FreeTrialExpiredModal = () => {
    const navigate = useNavigate();
    return (
        <div>
            <OopsPopUp
                isOpen={true}
                onClick={() => navigate('/product/home/setting/subscription')}
                buttonText={free_trial_expired.upgrade_now}
                description1={free_trial_expired.title}
                description2={free_trial_expired.description}
            />
        </div>
    )
}

export default FreeTrialExpiredModal