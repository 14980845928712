import React from 'react'
import { BLOG_IMG_REFERENCE } from '../../assets/website';
import BlogCardMoreInfo from '../../DesignSystem/Cards/website/BlogCardMoreInfo';
import HeadingThree from '../../DesignSystem/TypographyHeadings/HeadingThree';
import { useNavigate } from 'react-router-dom'
import ResponsiveHeadingThreeSix from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingThreeSix';

const BlogPostSection = ({ locals }) => {

    const navigate = useNavigate();
    const { primary_title, blog_list } = locals;

    const cards = blog_list.reverse().slice(0,4);

    return (
        <div className='flex flex-col gap-y-[24px] xl:p-[48px] p-[20px]'>

            <ResponsiveHeadingThreeSix text={primary_title} className='text-center' />

            <div className='grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[20px]'>
                {
                    cards.map((blog, index) => {
                        const handleOnClick= () => navigate(`/blogs/${blog.slug}`)
                        return (
                            <BlogCardMoreInfo
                                key={index}
                                handleOnClick={handleOnClick}
                                image={BLOG_IMG_REFERENCE[blog.img_refernce]}
                                heading={blog.title}
                                btnName={blog.btn_name}
                                postedByName={blog.posted_by.person_name}
                                postReadTimeLine={blog.posted_by.read_time}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default BlogPostSection