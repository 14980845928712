import React from 'react'
import classNames from "classnames"
import { why_pg } from '../../assets/website';
import website_locals from '../../localization/localization_en_website.json';
import MainContentSection from '../components/MainContentSection'
import ResponsiveHeadingThreeSix from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingThreeSix';
import BorderlessCard from '../../DesignSystem/Cards/website/BorderlessCard';
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import useScrollToTopPage from '../../hooks/useScrollToTopPage';

const WHY_PG_LOCALS = website_locals.website.why_predict_growth;

const WhyPredictGrowth = () => {
    useScrollToTopPage();
    return (
        <div>
            <MainContentSection
                locals={WHY_PG_LOCALS.main_content}
            />
            <div className='flex flex-col gap-y-[40px] mx-[16px]'>
                {
                    WHY_PG_LOCALS.features.map((feature, index) => {
                        return (
                            <div key={index} className={classNames(
                                'lg:flex justify-between',
                                { 'lg:flex-row': index % 2 == 0 },
                                { 'lg:flex-row-reverse': index % 2 != 0 }
                            )}>
                                <div className='xl:w-[44%] lg:w-[50%] pt-[2rem]'>
                                    <ResponsiveHeadingThreeSix text={feature.title} />
                                    <div className={classNames('flex flex-col gap-y-[24px] sm:mt-[2rem] mt-[1rem]')}>
                                        {
                                            feature.list.map((feat, index) => {
                                                return (
                                                    <BorderlessCard
                                                        key={index}
                                                        icon={why_pg[feat.icon_ref_name]}
                                                        heading={feat.title}
                                                        headingColor={"#424242"}
                                                        description={feat.description}
                                                        textColor={"#757575"}
                                                        parentClassName={"items-start"}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <img className='xl:w-[52%] lg:w-[50%] aspect-square object-contain' src={why_pg[feature.image_ref_name]} alt="" />

                            </div>
                        )
                    })
                }
            </div>
            <div className='bg-[#EBF1FF] py-[48px]'>
                <div className='max-w-[612px] flex justify-center flex-col items-center mx-auto'>
                    <ResponsiveHeadingThreeSix text={WHY_PG_LOCALS.distinctiveness.title} />
                    <TextLarge text={WHY_PG_LOCALS.distinctiveness.description} fontWeight={500} className={"text-center"} color={"#757575"} />
                </div>
                <div className='grid md:grid-cols-2 grid-cols-1 '>
                    {
                        WHY_PG_LOCALS.distinctiveness.list.map((feat, index) => {
                            return (
                                <div>
                                    <BorderlessCard
                                        key={index}
                                        icon={why_pg[feat.icon_ref_name]}
                                        heading={feat.title}
                                        headingColor={"#424242"}
                                        description={feat.description}
                                        textColor={"#757575"}
                                        parentClassName={"flex-col items-center gap-y-[24px] px-[32px] py-[24px]"}
                                        childClassName={"flex flex-col justify-center max-w-[550px]"}
                                        textAlignClassName={"text-center"}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default WhyPredictGrowth