import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Button, message } from "antd"
import DescribeFeature from '../reuseablecomponent/DescribeFeature';
import { resetUserFilledPassword, resetDefaultValuesOnUnMount, changePasswordProfileDataApi, updateProfileDataApi, fetchProfileDataApi, changeFormFields, togglePasswordComponent, resetStatus } from "./settingSlice";
import "./setting.css";
import UserForm from './UserForm';
import MessageNotification from '../reuseablecomponent/MessageNotification';
import PageSpinner from '../reuseablecomponent/PageSpinner';
import TextComponent from '../reuseablecomponent/TextVarients/TextComponent';
import { updateUserDetailsFromSetting } from '../../features/userSlice';
import setting_locals from "../../localization/localization_en_setting.json"
import Notification from '../../DesignSystem/Popup/Notification';
import { passwordValidatorForEnableBtn } from '../../utils/passwordValidator';

const locals = setting_locals.settings.tabs['Account Settings'].buttons;

const AccountSetting = () => {
    const dispatch = useDispatch();
    const AccountSettingData = useSelector((state) => state.setting.AccountSettingData)
    const ProfileMangement = useSelector((state) => state.setting["Profile Management"]);
    const number = useSelector((state) => state.setting.number)
    const password = useSelector((state) => state.setting["Password Management"])
    const userId = useSelector((state) => state.user.value._id);
    const loading = useSelector((state) => state.setting.loading);
    const errorMessage = useSelector((state) => state.setting.error);
    const successMessage = useSelector((state) => state.setting.success);
    useEffect(() => {
        dispatch(fetchProfileDataApi());
        dispatch(togglePasswordComponent(false))
        dispatch(changeFormFields())
        return () => {
            dispatch(togglePasswordComponent(true))
            dispatch(changeFormFields());
            dispatch(resetDefaultValuesOnUnMount());
            dispatch(resetUserFilledPassword());
        }
    }, [])
    useEffect(() => {
        if(AccountSettingData.title==="Profile Management" && loading==="success"){
            dispatch(updateUserDetailsFromSetting({username:ProfileMangement.Username.trim(),avatar:ProfileMangement.avatar,email:ProfileMangement.Email}));
        }
    },[loading])

    const callbackResetStatus = () => {
        dispatch(resetStatus());
    }

    return (
        <div>
            {loading!=="pending" && <Notification content={{error:errorMessage, success:successMessage}} type={loading} callbackResetStatus={callbackResetStatus} />}
            <div className='mb-[16px]'>
                <TextComponent text={AccountSettingData.title} color={"#111827"} fontSize={16} fontWeight={600} />
            </div>
            <div className='w-[77%]'>
                {loading === "loading" && <PageSpinner />}
                <UserForm />
                <div className='flex justify-between mt-[48px] w-[70%]'>
                    <Button disabled={loading === "loading"} type='primary' onClick={() => {
                        dispatch(togglePasswordComponent(!number))
                        dispatch(changeFormFields())
                    }} className='border text-primary border-primary'>{(!number) ? locals.switch_button.password : locals.switch_button.profile}</Button>
                    <Button loading={loading === "pending"} disabled={loading === "pending"} onClick={() => {
                        if (number) {
                            if (password["New Password"] === password["Confirm New Password"] && password["New Password"].trim().length > 0 && password["Confirm New Password"].trim().length > 0) {
                                if(passwordValidatorForEnableBtn(password["New Password"])){
                                    message.open({ type:"warning", content:"password doesn't meet the policy" })
                                    return;
                                }
                                dispatch(changePasswordProfileDataApi(userId));
                            }else{
                                message.open({
                                    type:"warning",
                                    content:"Check Password"
                                })
                            }
                        } else {
                            dispatch(updateProfileDataApi());
                        }
                    }} type='primary' className='bg-primary text-white'>{number ? locals.update_button.password : locals.update_button.profile}</Button>
                </div>
            </div>
        </div>
    )
}

export default AccountSetting