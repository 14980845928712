import React from 'react'
import { Select } from "antd";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const FilterSelectOption = ({
    value,
    options=[],
    onChange
}) => {
    return (
        <Select
            value={value}
            options={options}
            suffixIcon={<FilterAltIcon className='text-primary' />}
            onChange={onChange}
            className='min-w-[9.5rem]'
        />
    )
}

export default FilterSelectOption