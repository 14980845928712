import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from "react-redux";
import { fetchOnboardingDetails } from "../../features/onboardingSlice";
import { updatecommingFromSetting, updateStepper } from "../../features/onboardingSlice";
import { useNavigate } from 'react-router-dom';
import { avatar } from '../../data';
import ContactUsModal from '../../DesignSystem/Modals/ContactUsModal';
import setting_locals from "../../localization/localization_en_setting.json";

const locals = setting_locals.settings.tabs['Update Business Profile'];

const UpdateOnboarding = () => {
  const dispatch = useDispatch();
  const onboarding = useSelector((state) => state.onboarding.value);
  const listOfUsers = useSelector((state) => state.onboarding.teamDetails.moreSubDetails.data);
  const noOfUsers = useSelector((state) => state.onboarding.noOfUsers);
  const [showUserExceedModal, setShowUserExceedModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(updatecommingFromSetting(true));
    dispatch(fetchOnboardingDetails());
  }, [])
  const noOfUsersText = locals.form.team.value.replace("{data}", noOfUsers);
  return (
    <div className='bg-[#F5F5F5] py-[32px] px-[24px] rounded-[16px] w-[77%]'>
      <ContactUsModal isOpen={showUserExceedModal} handleOnClick={() => setShowUserExceedModal(false)} />
      <div className='flex items-center justify-between bg-white p-[20px] mb-[24px] rounded-[12px]'>
        <p style={{ margin: '0px' }} className='font-InterRegular text-[16px] font-medium tracking-wide'>{locals.form.company_name} - <span className='text-primary'>{onboarding?.Company}</span></p>
        <Button type='link' className='text-[14px] text-primary invisible'>{locals.buttons.edit}</Button>
      </div>
      <div className='flex items-center justify-between bg-white p-[20px] rounded-[12px] mb-[24px]'>
        <p style={{ margin: '0px' }} className='font-InterRegular text-[16px] font-medium tracking-wide'>{locals.form.business_stage} - <span className='text-primary'>{onboarding?.BusinessStage}</span></p>
        <Button onClick={() => {
          dispatch(updateStepper(1));
          dispatch(updatecommingFromSetting(true));
          navigate("/product/onboarding");
        }} type='link' className='text-[14px] text-primary'>{locals.buttons.edit}</Button>
      </div>
      <div className='mb-[24px] p-[20px] bg-white rounded-[12px]'>
        <div className='flex items-center justify-between'>
          <p style={{ margin: '0px' }} className='font-InterRegular text-[16px] font-medium tracking-wide'>{locals.form.team.label} - <span className='text-primary'>{noOfUsersText}</span></p>
          <Button onClick={() => {
            if (noOfUsers < 5) {
              dispatch(updateStepper(2));
              dispatch(updatecommingFromSetting(true));
              navigate("/product/onboarding");
            } else {
              setShowUserExceedModal(true);
            }
          }} type='link' className='text-[14px] text-primary'>{locals.buttons.edit}</Button>
        </div>
        <ul className='mt-[16px]'>
          {
            listOfUsers?.map((user) => {
              return (
                <li className='flex justify-between my-[8px]'>
                  <div className='flex'>
                    <div className='w-[1rem] h-[1rem] bg-black rounded-full'>{<img src={user?.avatar?.length==0 ? avatar[Math.floor(Math.random() * avatar.length)] : user.avatar} />}</div>
                    <span className='text-[#757575] text-[12px] font-InterRegular tracking-wide ml-[4px]'>{user.email}</span>
                  </div>
                  <span className='text-[#757575] text-[12px] font-InterRegular tracking-wide mr-[1rem]'>{user.role.slice(0,1).toUpperCase()+user.role.slice(1)}</span>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default UpdateOnboarding