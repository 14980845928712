import React, { useEffect } from "react";
import TabsComponent from "./Tabs";
import DescribeFeature from "../reuseablecomponent/DescribeFeature";
import './competitorAnalysis.css'
import responseLogo from '../AIService/assets/responseLogo.svg'
import { toggleWelcomeAi } from "../AIService/promptSlice";
import { useDispatch } from "react-redux";
import AIWelcome from "../reuseablecomponent/PopUpVarients/AIWelcome";
import caLocals from "../../localization/localization_en_competitor_analysis.json"
import { updatekeyChange } from "./CompetitorSlice";
import WarningLimitedResourceOopsPopup from "../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup";
import { paywall_locals } from "../../localization";
import useApp from "../../hooks/useApp";

const PAYWALL_LOCALS = paywall_locals.competitor_analysis;


const CompetitorAnalysis = () => {
  const dispatch = useDispatch();
  const calocals = caLocals.competitor_analysis;
  const { upgradeRenewPaywallPopup }  =useApp();
  useEffect(() => {
    return () => {
      dispatch(updatekeyChange({ key: String(Number(0) + 1) }));
    }
  }, [])
  return (
    <div className="mt-[68px] mx-auto">
      <DescribeFeature text={calocals.module_title} description={calocals.module_description} />
      <AIWelcome link={"/product/home/ai/promptin/competitorAnalysis"} featureName={"competitorAnalysis"} />
      <div className="w-full mt-[20px] mb-[20px]">
        <TabsComponent />
      </div>
      <WarningLimitedResourceOopsPopup
        description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].ai.description1}
        description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].ai.description2}
      >
        {({ handleTogglePopup, isFreeSubscription }) => {
          return (
            <button
              onClick={() => {
                if (isFreeSubscription) {
                  handleTogglePopup();
                  return;
                }
                dispatch(toggleWelcomeAi());
                console.log("hello")
              }}
              className="p-[7px] bg-primary text-white flex items-center fixed bottom-[1rem] right-[1rem] rounded-[8px]"
            >
              <img className="w-[30%]" src={responseLogo} />
              <span className="ml-[0.5rem]">Ask AI</span>
            </button>
          )
        }}
      </WarningLimitedResourceOopsPopup>
    </div>
  );
};

export default CompetitorAnalysis;
