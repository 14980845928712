import React, { useState } from 'react'
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from "react-redux";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import SelectOption from '../../../DesignSystem/Forms/SelectOption'
import SelectOptionWithAddMore from '../../../DesignSystem/Forms/SelectOptionWithAddMore'
import LinkButton from '../../../DesignSystem/Buttons/LinkButton'
import ChatComponent from '../../../DesignSystem/SVG/ChatComponent'
import DropdownAction from '../../../DesignSystem/Forms/DropdownAction'
import EDIT from "../../../assets/common/edit_outline.svg"
import DELETE from "../../../assets/common/delete_outline.svg"
import VIEW from "../../../assets/common/comment_outline.svg"
import { ISOStringFormat } from '../../../utils/ISOStringFormat';
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';
import { D_Toggle_CatgoryModal, D_Update_Row_Data_Status, D_Update_Active_Tag_Color, D_Handle_Delete_Modal, D_UpdateFunnelStage } from '../gtmSlice';
import SelectOptionRow from '../../../DesignSystem/Forms/SelectOptionRow';
import FormComponentProvider from '../../../DesignSystem/Forms/FormComponentProvider';
import { convertAmountInK } from '../../../utils/convertAmountInK';
import CloseSVG from './CloseSVG';
import { D_ToggleCommentSection, D_UpdateChatRowOrCell } from '../../Chat/chatSlice';
import { pickCurrencySymbolFromString } from '../../../utils/pickCurrencySymbolFromString';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import WarningLimitedResourceOopsPopup from '../../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';
import { paywall_locals } from '../../../localization';
import useApp from '../../../hooks/useApp';
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';
const weekFormat = 'MM/DD';
const monthFormat = 'YYYY/MM';

const { RangePicker } = DatePicker;

const iconReference = {
    "VIEW": <AddCommentOutlinedIcon fontSize='small' />,
    "EDIT": <ModeEditOutlinedIcon />,
    "DELETE": <DeleteOutlinedIcon />,
}

const RenderComponent = ({ PAYWALL_LOCALS, payloadData, column, value, record, rowIndex, handleOnChangeInput, module_type, CURRENCY, handleDeleteTag, readOnlyCell }) => {

    const ref = React.useRef();
    const dispatch = useDispatch();
    const permission = useSelector((state) => state.user.permission);
    // const  = useSelector((state) => state.gtm.gtm_strategy.currency.CURRENCY);
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false)
    // const CURRENCY = useSelector((state) => state.gtm.gtm_strategy.gtm_plan.currency.CURRENCY);
    const { upgradeRenewPaywallPopup } = useApp();

    switch (column.component_type) {
        case 'select_option': {
            const label = column?.select_options?.find((option) => option.value === value)?.label;
            const preSelectedOption = payloadData.map((d) => module_type === "marketing_funnel" ? d.stage : d.channel)
            const filteredOption = column?.select_options.filter((opt) => !preSelectedOption.includes(opt.value))
            return (
                <div className="w-full">
                    {
                        ((typeof value === "string" && value?.length > 0) || !!value)
                            ? <TextLarge fontWeight={500} text={label} />
                            : (
                                <SelectOption
                                    name={column.name}
                                    value={value}
                                    addMoreButtonName={column?.isAddMore ? column?.addMoreButtonName : null}
                                    onChangeEvent={(value) => {
                                        if (value !== "add_more") {
                                            handleOnChangeInput({ "data_index": column.name, value, rowIndex })
                                        }
                                    }}
                                    onClickAddMore={() => dispatch(D_Toggle_CatgoryModal({ module_type, "isOpen": true, "type": module_type === "marketing_funnel" ? "marketing_stage" : "gtmChannelList", "rowIndex": rowIndex }))}
                                    placeholder={column?.placeHolder}
                                    listOfOptions={filteredOption}
                                    handleOnClickAddItem={() => console.log("clicked")}
                                    isDisabled={!permission?.edit}
                                />
                            )
                    }
                </div>
            )
        }
        case 'select_option_with_add_more': {
            const targetValue = column?.select_options.find((option) => option.value === value)
            return (
                <div style={{ width: `${column.component_width}px` }}>
                    {
                        value
                            ? (
                                <div
                                    className='flex justify-center items-center rounded-md'
                                    style={{ minHeight: "56px", backgroundColor: targetValue?.backgroundColor ?? "#FFFFFF", color: "#FFFFFF" }}
                                >
                                    {value}
                                </div>
                            ) : (
                                <WarningLimitedResourceOopsPopup
                                    description1={PAYWALL_LOCALS?.[upgradeRenewPaywallPopup]?.only_tofu?.description1}
                                    description2={PAYWALL_LOCALS?.[upgradeRenewPaywallPopup]?.only_tofu?.description2}
                                >
                                    {({ handleTogglePopup, isFreeSubscription }) => {
                                        return (
                                            <SelectOptionWithAddMore
                                                name={column.name}
                                                value={value}
                                                optionTextAlign="center"
                                                isAddMore={false}
                                                onChangeEvent={(value) => {
                                                    if (isFreeSubscription && ["mofu", "bofu"].includes(value.toLowerCase()) ) {
                                                        handleTogglePopup();
                                                        return;
                                                    }
                                                    handleOnChangeInput({ "data_index": column.name, value, rowIndex })
                                                }}
                                                customPlaceholder={true}
                                                placeHolder={column?.placeHolder}
                                                minSelectHeight={56}
                                                listOfOptions={column?.select_options}
                                                optionStyle={{ padding: "1rem 0rem", marginBottom: "0.5rem", "display": "flex", alignItems: "center" }}
                                                isSelectOptionDisabled={!permission?.edit}
                                            />
                                        )
                                    }}
                                </WarningLimitedResourceOopsPopup>
                            )
                    }
                </div>
            )
        }
        case 'mutli_select_option': {
            return (
                <div className='w-[15rem]'>
                    <SelectOptionRow
                        name={column.name}
                        value={value}
                        disabled={readOnlyCell || !permission?.edit}
                        handleOnClick={(e) => { }}
                        placeHolder={column?.placeHolder}
                        listOfOptions={column?.select_options}
                        handleOnBlur={(value) => {
                            console.log(value, "value")
                        }}
                        onClickAddMore={() => {
                            dispatch(D_Toggle_CatgoryModal({ module_type, "isOpen": true, "type": column.name, "rowIndex": rowIndex }))
                            dispatch(D_UpdateFunnelStage(record.funnel_stage));
                        }}
                        addMoreButtonName="Add New"
                        handleOnChangeValue={(value) => {
                            if (value !== "add_more" || value !== "Add New") {
                                handleOnChangeInput({ "data_index": column.name, value, rowIndex })
                            }
                        }}
                    />
                    {/* {
                        column?.select_options.map((op) => {
                            return (
                                <div>
                                    {op.label}
                                </div>
                            )
                        })   
                    } */}
                </div>
            )
        }
        case 'month_year_calender': {
            return (
                <>
                    <div className='relative'>
                        <RangePicker
                            name={column?.name}
                            disabled={readOnlyCell || !permission?.edit}
                            value={value ? value.map((v) => dayjs(v)) : value}
                            onChange={(value) => {
                                handleOnChangeInput({ "data_index": column.name, value, rowIndex })
                            }}
                        />
                    </div>
                </>
            )
        }
        case 'link_button': {
            let checkIfDataExist = true;
            let total = 0;
            for (const field of Object.entries(value)) {
                if (field[1] === null) checkIfDataExist = false;
                total = total + Number(field[1]);
            }
            return (
                <>
                    {
                        checkIfDataExist
                            ? <TextLarge fontWeight={500} text={`${pickCurrencySymbolFromString(CURRENCY)} ${convertAmountInK(total)}`} />
                            : <LinkButton
                                isDisabled={!permission?.edit}
                                text={column.buttonName}
                                onClick={() => {
                                    if (column.isModalOpen) {
                                        dispatch(D_Update_Row_Data_Status({ module_type, "isEditChannelModalOpen": true, "crud_form_type": "VIEW", rowIndex }))
                                    }
                                }}
                            />
                    }
                </>
            )
        }
        case 'chat_button': {
            const chatCount = value >= 1000 ? String(value / 1000) + 'k+' : value
            return (
                <div className='flex justify-center items-center w-full'>
                    <WarningLimitedResourceOopsPopup
                        description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].comment.description1}
                        description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].comment.description2}
                    >
                        {
                            ({ handleTogglePopup, isFreeSubscription }) => {
                                return (
                                    <button disabled={readOnlyCell} className='disabled:cursor-not-allowed' onClick={() => {
                                        if (isFreeSubscription) {
                                            handleTogglePopup();
                                            return;
                                        }
                                        console.log(module_type, record.planId || record.marketingFunnelId)
                                        dispatch(D_UpdateChatRowOrCell({ _id: record.planId || record.marketingFunnelId, moduleType: module_type }))
                                        dispatch(D_ToggleCommentSection(true));
                                    }}>
                                        {/* <ChatComponent /> */}
                                        <div className='absolute right-1/2 top-[25%]'>
                                            <div style={{ display: typeof chatCount === "number" ? (chatCount === 0 ? 'none' : 'block') : 'block' }} className='text-[12px] absolute  bg-primary rounded-full text-white px-2'>
                                                {chatCount}
                                            </div>
                                        </div>
                                        <ModeCommentOutlinedIcon className='text-baseGray600 hover:text-mainPrimary' />
                                    </button>
                                )
                            }
                        }
                    </WarningLimitedResourceOopsPopup>
                </div>
            )
        }
        case 'dropdown_action': {
            return (
                <div className='w-full flex items-center justify-center'>
                    <WarningLimitedResourceOopsPopup
                        description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].comment.description1}
                        description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].comment.description2}
                    >
                        {({ handleTogglePopup, isFreeSubscription }) => {
                            return (
                                <DropdownAction
                                    imgIconOrCustomicon={"custom"}
                                    items={column.dropdown_options.map((item) => ({ ...item, "dropdown_icon_ref": iconReference[item.dropdown_icon_ref] }))}
                                    moduleType={column.moduleType}
                                    overlayStyle={{ minWidth: "12rem" }}

                                    handleOnClick={({ moduleType, ACTION_TYPE }) => {
                                        console.log(ACTION_TYPE, "ACTION_TYPE")
                                        switch (ACTION_TYPE) {
                                            case 'EDIT': {
                                                dispatch(D_Update_Row_Data_Status({ module_type, "isEditChannelModalOpen": true, "crud_form_type": "EDIT", rowIndex }))
                                                break;
                                            }
                                            case 'DELETE': {
                                                dispatch(D_Handle_Delete_Modal({ module_type, "isOpen": true, rowIndex }));
                                                break;
                                            }
                                            case 'COMMENT': {
                                                if (isFreeSubscription) {
                                                    handleTogglePopup();
                                                    return;
                                                }
                                                dispatch(D_UpdateChatRowOrCell({ _id: record.planId || record.marketingFunnelId, moduleType: module_type }))
                                                dispatch(D_ToggleCommentSection(true));
                                            }
                                        }
                                    }}
                                    isDisabled={!permission?.edit}
                                />
                            )
                        }}
                    </WarningLimitedResourceOopsPopup>
                </div>
            )
        }
        case 'tag': {
            const listOfColors = column?.tag_colors?.map((tag) => tag?.color);
            const usedColors = value?.map((tag) => tag?.tagColor?.color);
            const availableTagColor = listOfColors?.filter((color) => !usedColors?.includes(color));
            const activeColor = column.tag_colors?.find((tag) => tag?.color === availableTagColor[0]);
            return (
                <div className='flex flex-wrap gap-[0.5rem]'>
                    {
                        value?.map((tag) => {
                            return (
                                <div
                                    className='px-3 rounded-full flex items-center gap-x-[0.3rem] cursor-pointer'
                                    style={{ "backgroundColor": tag?.tagColor?.backgroundColor ?? "#FFFFFF", "color": tag?.tagColor?.color ?? "#000000" }}
                                >
                                    {tag.tagName}
                                    <button disabled={!permission?.edit} onClick={() => handleDeleteTag(tag.tagName)}>
                                        <CloseSVG color={tag?.tagColor?.color ?? "#000000"} />
                                    </button>
                                </div>
                            )
                        })
                    }
                    {
                        value?.length === column.MAX_NO_OF_TAGS
                            ? null
                            : (
                                <button
                                    className='px-3 rounded-full disabled:cursor-not-allowed'
                                    disabled={readOnlyCell || !permission?.edit}
                                    onClick={() => {
                                        dispatch(D_Toggle_CatgoryModal({ module_type, "isOpen": true, "type": "gtmTagList", "rowIndex": rowIndex }))
                                        dispatch(D_Update_Active_Tag_Color({ module_type, activeColor }));
                                    }}
                                    style={{ "backgroundColor": activeColor?.backgroundColor, "color": activeColor?.color, "border": `1px solid ${activeColor?.borderColor}` }}
                                >
                                    +
                                </button>
                            )
                    }
                </div>
            )
        }
        case 'InputNumber': {
            return (
                <>
                    {
                        typeof value === "number"
                            ? (
                                <>
                                    <TextLarge fontWeight={500} text={`${pickCurrencySymbolFromString(CURRENCY)} ${convertAmountInK(value)}`} />
                                </>
                            ) : (
                                <FormComponentProvider
                                    form={{ ...column, placeholder: column.placeholder.replace('CURRENCY', CURRENCY), isDisabled: readOnlyCell }}
                                    component_type={"InputNumber"}

                                    value={value}
                                    // customFormattedValue={isEditActive ? `$ ${value}k` : value}
                                    onBlurEvent={(value) => {
                                        handleOnChangeInput({ "data_index": column.name, value: value === null ? null : Number(value), rowIndex })
                                    }}
                                />
                            )
                    }
                </>
            )
        }
    }
}

export default RenderComponent
