import React from 'react'
import Location from './Location'
import FeatureCard from './FeatureCard'

const NestedCoreFeatures = ({children, color}) => {
  return (
    <div className='flex justify-start'>
      <div className='flex justify-center w-[28px] mt-[1rem] max-h-fit'>
        <div className='border-[0.5px] border-[#9e9e9e]'></div>
      </div>
      <div className='flex flex-col gap-y-[16px] ml-[0.9rem] pt-[8px]'>
        {
          children?.map((feature,index) => {
            return <FeatureCard componentUrl={feature.buttonUrl} featureRefIndex={feature.key} index={index} cardTitle={feature?.title} cardBgColor={color?.cardBgColor} cardBorder={color?.cardBorder} btnTextColor={feature?.btnTextColor} buttonText={feature?.buttonText} />
          })
        }
      </div>
    </div>
  )
}

export default NestedCoreFeatures