import React from 'react'
import classNames from 'classnames';
import { productImage, carousal_image } from '../../assets/website';
import PlaneCard from '../../DesignSystem/Cards/website/PlaneCard'
import HeadingThree from '../../DesignSystem/TypographyHeadings/HeadingThree'
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour'
import AntDCarousal from '../../DesignSystem/Carousal/AntDCarousal'
import HeadingSix from '../../DesignSystem/TypographyHeadings/HeadingSix';
import ResponsiveHeadingThreeSix from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingThreeSix';

const PricingToolkit = ({ pricingToolkit }) => {

    const [activeToolkit, setActiveToolkit] = React.useState(null); // pricingToolkit.toolkit_cards[0].carousal_data_index

    return (
        <div className='flex justify-center items-center flex-col gap-y-[1rem]'>
            <ResponsiveHeadingThreeSix text={pricingToolkit.title} className={"text-center"} />
            <div className='lg:grid hidden grid-cols-4 gap-x-[1rem]'>{/* lg:grid hidden grid-cols-4 2xl:gap-x-[40px] xl:gap-x-[20px] lg:gap-x-[10px] */}
                {
                    pricingToolkit.toolkit_cards.map((toolkit, index) => {
                        return (
                            <PlaneCard
                                key={index}
                                title={toolkit.title}
                                data_index={toolkit.carousal_data_index}
                                isActive={activeToolkit===toolkit?.carousal_data_index}
                                // handleOnClick={() => setActiveToolkit(toolkit.carousal_data_index)}
                                description={toolkit.description}
                            />
                        )
                    })
                }
            </div>
            <div className='flex justify-between w-full lg:hidden'>
                {
                    pricingToolkit.toolkit_cards.map((module, index) => {
                        return (
                            <div onClick={() => setActiveToolkit(module.carousal_data_index)}>
                                <HeadingSix key={index} text={module.title} className={classNames('text-baseGray500 lg:text-center sm:text-[18px] text-[11px] cursor-pointer', { 'text-p3 border-b-2 border-b-p3' : activeToolkit===module.carousal_data_index })} />
                            </div>
                        )
                    })
                }
            </div>
            <div className='xl:w-[50%] sm:w-[80%] w-full mx-auto sm:mt-[48px] mt-[12px]'>
                <AntDCarousal image={carousal_image} />{/* productImage[activeToolkit] */}
            </div>
        </div>
    )
}

export default PricingToolkit