import React, { useState } from 'react'
import { Table, message } from 'antd';
import TextLarge from '../TypographyDescriptions/TextLarge';
import Plus from "../../assets/common/plusPrimary.svg";
import { addRowInRO, removeRowInRO, storeTableValue } from '../../components/BusinessModal/revenueSlice';
import CustomStyleTextArea from '../Forms/InputVarients/CustomStyleTextArea';
import CustomNumber from '../../components/BusinessModal/subcomponents/ROFormComponents/components/CustomNumber';
import minus from "../../assets/common/Delete.svg"
import { useDispatch, useSelector } from "react-redux"
import TableColumnHeading, { TableToolTip } from '../../components/BusinessModal/subcomponents/ROFormComponents/components/TableColumnHeading';
import CRUDSelectOption from '../Forms/CRUDSelectOption';
import SelectOption from '../Forms/SelectOption';
import { D_Handle_On_Change_Select } from '../../components/BusinessModal/revenueSlice';
import { ValidateNumberData } from '../../components/BusinessModal/functionality/RevenueOnboarding/ValidateNumberData';
import { ValidateNumber } from '../../components/BusinessModal/components/CostOnboarding/utils/ValidateNumber';
import business_model_locals from '../../localization/localization_en_business_modal.json'
import OopsPopUp from '../../components/reuseablecomponent/PopUpVarients/OopsPopUp';
import { numberValidation } from '../../utils/numberValidation';
import appLocals from '../../localization/localization_en_app.json'
import StaticText from '../../components/BusinessModal/components/CostOnboarding/microcomponents/StaticText';

const Warning_Message = business_model_locals.business_modal.warning_tooltip;
const Validation_Warning_Message = appLocals.app.warning_message

const getProductCategoryValue = (categoryList, value) => {
    const Category = categoryList?.find((category) => category.value === value);
    if (Category !== undefined) {
        return Category.label;
    }
    return null;
}

const RenderFirstColumnInputType = ({ type, name, value, placeholder, rowIndex, dataIndex, column, options, noCategoryTag, record, validator, decimalPlace, isDisabled, columnIndex }) => {
    const dispatch = useDispatch();
    switch (type) {
        case 'text_area': {
            return (
                <CustomStyleTextArea isReadyOnly={isDisabled} isFontWeight={500} name={name} value={value === null ? '' : value} borderColor='white' maxLength={24}
                    placeHolder={placeholder}
                    onChangeEvent={(e) => {
                        dispatch(storeTableValue({ "name": e.target.name, "value": e.target.value, "rowNumber": rowIndex, "dataIndex": dataIndex }))
                    }}
                />
            )
        }
        case 'select_option': {
            const SORTED_LIST = options?.sort((a,b) => a?.label?.localeCompare(b?.label)) || options
            return (
                <>
                {
                    columnIndex===1 && !!value
                    ? <StaticText value={value} />
                    : (
                        <SelectOption
                            isDisabled={isDisabled}
                            value={value}
                            notFoundContent={noCategoryTag}
                            placeholder={placeholder}
                            listOfOptions={SORTED_LIST}
                            onChangeEvent={(value) => dispatch(D_Handle_On_Change_Select({ name, value, rowIndex, dataIndex }))}
                        />
                    )
                }
                </>
            )
        }
        case 'number_input': {
            return (
                <>
                <CustomNumber
                    constrain={validator}
                    // isReadOnly={false || isDisabled}
                    isDisabled={false || isDisabled}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    handleOnBlur={(event) => {
                        if(["positive_decimal_number","positive_negative_decimal_number","positive_number","negative_decimal_number"].includes(validator)){
                            message.destroy();
                            const Validation_Status = ValidateNumber({ "validation_type": validator, "value": event.target.value, "decimal_place": decimalPlace })
                            if(Validation_Status.status){
                                const percentageCheck = numberValidation({ value: String(Validation_Status.value), placeHolder: placeholder, validator,"isStrictPercentageCheck": column?.isStrictPercentageCheck })
                                if(!percentageCheck.status){
                                    message.open({ type: 'warning', content: percentageCheck.message});
                                    return;
                                }
                                dispatch(storeTableValue({ "name": name, "value": Number(Validation_Status.value), "rowNumber": rowIndex, "dataIndex": dataIndex }));
                                return;
                            }
                            dispatch(storeTableValue({ "name": name, "value": null, "rowNumber": rowIndex, "dataIndex": dataIndex }));
                            const percentageCheck = numberValidation({ value: String(Validation_Status.value), placeHolder: placeholder, validator,"isStrictPercentageCheck": column?.isStrictPercentageCheck })
                            if(!percentageCheck.status && event.target.value){
                                message.open({ type: 'warning', content: percentageCheck.message});
                                return;
                            }
                            if(decimalPlace===0){
                                switch(validator){
                                    case 'positive_decimal_number': {
                                        message.open({ type: 'warning', content: Validation_Warning_Message.positive_number}); return;
                                    }
                                }
                            }
                            if(event.target.value){
                                validator && Validation_Warning_Message?.[validator] && message.open({ type: 'warning', content: Validation_Warning_Message[validator]});
                            }
                        }
                    }}
                />
                </>
            )
        }
        default: return null;
    }
}

const VerticalScrollableTable = ({ tableProps, label, options, onChangeSelect }) => {
    const dispatch = useDispatch()
    const isDisabled = useSelector((state) => state.businessModal.MODE_TYPE.view)
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const { table_info_data, data, name, isReadOnly, categoryList, filteredOption } = tableProps;
    const decimalPlace = useSelector((state) => state.globalSetting.active_global_settings.decimal_places)
    const CURRENCY_SYMBOL = useSelector((state) => state.globalSetting.currencySymbol)
    let transformedData = data;
    const columns = table_info_data.table.columns?.map((column, columnIndex) => {
        const uniqueKey = `column-${columnIndex}`;
        return (
            {
                "key": uniqueKey,
                "fixed": !!column.fixed ? column.fixed : '',
                "title": <TableColumnHeading isMandatory={column.isMandatory} key={uniqueKey} title={column?.label} tooltip={column.tooltip} />,
                "dataIndex": column.dataIndex,
                "width": column.width,
                "render": (value, record, rowIndex) => {
                    const cellKey = `cell-${uniqueKey}-${rowIndex}`;
                    const isDataPresent = record.category !== null;
                    const isScreen2Value = ["manufacture", "buy", "product_category_1", "business_contribution", "returns_cancellations", "quantity_per_order", "social_media_marketplaces", "platform_commission_cost"].includes(name)
                    let customValue;
                    if(isScreen2Value){
                        customValue = getProductCategoryValue(categoryList, value)
                    }else {
                        customValue = value;
                    }
                    const IS_DELETE_ROWS = Boolean(table_info_data.table.isDeleteRows)
                    if(columnIndex === 0 && IS_DELETE_ROWS){
                        return <button disabled={isDisabled} className='flex justify-center w-full bg-white' onClick={() => dispatch(removeRowInRO({ "targetFeature": name, index: rowIndex }))}>
                        <img src={minus} alt="" /></button>
                    }
                    let placeHolder;
                    if(table_info_data.name==="key_details_user_acquisition_engagement"){
                        placeHolder = "mixed_tooltip" in table_info_data?.table ? (columnIndex===1 && record.question===table_info_data?.table?.deps_row_name_placeHolder ? table_info_data?.table?.mixed_tooltip["Questions"+record?.question?.split(' ')?.join('')] :column?.placeholder )  : column?.placeholder
                    }else{
                        placeHolder = column?.placeholder;
                    }
                    const validator = "mixed_tooltip" in table_info_data?.table ? columnIndex===1 && record.question===table_info_data?.table?.deps_row_name_placeHolder ? "positive_decimal_number" : column.validator : column.validator;
                    let PLACEHOLDER = placeHolder?.includes('CURRENCY') ? placeHolder?.replace('CURRENCY', CURRENCY_SYMBOL) : placeHolder;
                    if(PLACEHOLDER?.includes('.')){
                        PLACEHOLDER = decimalPlace===2 ? PLACEHOLDER : PLACEHOLDER.includes('%') ? PLACEHOLDER.split('.')[0]+'%' : PLACEHOLDER.split('.')[0]
                    }
                    return (
                        <div key={cellKey} className='w-[calc(100%-1%)] h-[calc(100%-1px)] bg-white mb-[1px]'>
                            {
                                columnIndex === (IS_DELETE_ROWS ? 1 : 0)
                                    ? <div className='w-full bg-white'>
                                    {table_info_data.table.isFirstCellEdit
                                        ? <div className='flex justify-between w-full h-[46px] bg-white'>
                                            <div className=' w-[95%] h-full flex items-center'>
                                                <RenderFirstColumnInputType
                                                    isDisabled={isDisabled}
                                                    record={record}
                                                    noCategoryTag={table_info_data?.table?.noDataTagContent}
                                                    decimalPlace={decimalPlace}
                                                    validator={validator}
                                                    rowIndex={rowIndex}
                                                    dataIndex={column.dataIndex}
                                                    name={name}
                                                    value={customValue}
                                                    placeholder={PLACEHOLDER}
                                                    type={column.component_type}
                                                    options={filteredOption}
                                                    onChangeSelect={onChangeSelect}
                                                    columnIndex={columnIndex}
                                                />
                                            </div>
                                        </div>
                                        : <div className='h-[46px] flex justify-between items-center px-[8px]'>
                                            <div className='flex'>
                                                {columnIndex === (IS_DELETE_ROWS ? 1 : 0) && tableProps?.tableTooltip?.[value]?.isMandatory ? <div><sub className='text-red-500 pr-[0.2rem] text-[1.2rem] pt-[0.2rem]'>*</sub></div> : null}
                                                <TextLarge
                                                    fontWeight={500}
                                                    text={value === null ? column.placeholder : customValue}
                                                    color={value === null && '#bfbfbf'}
                                                />
                                            </div>
                                            {columnIndex === (IS_DELETE_ROWS ? 1 : 0) && tableProps?.tableTooltip?.[value]?.tooltip ? <div className='w-[1rem] aspect-square'>
                                                <TableToolTip tooltip={tableProps.tableTooltip[value].tooltip} />
                                            </div> : null}
                                        </div>
                                    }
                                </div>
                                    : <>
                                    <CustomNumber
                                        constrain={validator}
                                        performActionOnClick={() => {
                                            if(isDisabled)return;
                                            if("name" in record){
                                                message.warning('Please select the product category first, then enter the value.', 2);
                                            }else if("channel" in record){
                                                message.warning('Please select the channel first, then enter the value.', 2)
                                            }
                                        }}
                                        isReadOnly={isDisabled===false ? ((("name" in record) || ("channel" in record)) ? !(!!record.name || !!record.channel) : false) || isDisabled : false}
                                        isDisabled={isDisabled}
                                        name={name}
                                        placeholder={PLACEHOLDER}
                                        value={value}
                                        handleOnBlur={(event) => {
                                            if(["positive_decimal_number","positive_negative_decimal_number","positive_number","negative_decimal_number"].includes(column.validator)){
                                                message.destroy();
                                                const Validation_Status = ValidateNumber({ "validation_type": validator, "value": event.target.value, "decimal_place": decimalPlace })
                                                if(Validation_Status.status){
                                                    const percentageCheck = numberValidation({ value: String(Validation_Status.value), placeHolder: PLACEHOLDER, validator,"isStrictPercentageCheck": column?.isStrictPercentageCheck })
                                                    if(!percentageCheck.status){
                                                        message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                                        dispatch(storeTableValue({ "name": name, "value": null, "rowNumber": rowIndex, "dataIndex": column.dataIndex }))
                                                        return;
                                                    }
                                                    dispatch(storeTableValue({ "name": name, "value": Number(Validation_Status.value), "rowNumber": rowIndex, "dataIndex": column.dataIndex }))
                                                    return;
                                                }
                                                dispatch(storeTableValue({ "name": name, "value": null, "rowNumber": rowIndex, "dataIndex": column.dataIndex }))
                                                const percentageCheck = numberValidation({ value: String(Validation_Status.value), placeHolder: PLACEHOLDER, validator,"isStrictPercentageCheck": column?.isStrictPercentageCheck })
                                                if(!percentageCheck.status && event.target.value){
                                                    message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                                    return;
                                                }
                                                if(decimalPlace===0){
                                                    switch(validator){
                                                        case 'positive_decimal_number': {
                                                            message.open({ type: 'warning', content: Validation_Warning_Message.positive_number, duration: 3}); return;
                                                        }
                                                    }
                                                }
                                                if(event.target.value){
                                                    console.log("exe")
                                                    validator && Validation_Warning_Message?.[validator] && message.open({ type: 'warning', content: Validation_Warning_Message[validator], duration: 3});
                                                }
                                            }
                                        }}
                                    />
                                    </>
                            }
                        </div>
                    )
                }
            }
        )
    })
    const totalColumnWidth = columns?.reduce((total, column) => total + (column.width || 0), 0);
    const { description1, description2, description3 } = Warning_Message[table_info_data.table.categoryName] || {}
    return (
        <div>
            {!!label 
            ? <div className='mt-[16px] mb-[16px] flex items-center gap-x-[0.25rem]'>
                <TextLarge text={label} color={"#757575"} fontWeight={500} />
                {!!table_info_data?.tooltip ? <div className='w-[18px] aspect-square'><TableToolTip tooltip={table_info_data?.tooltip} /></div> : null}
            </div> 
            : null}
            {
                description1
                ? (
                    <OopsPopUp
                        isOpen={isModalOpen}
                        description1={description1}
                        description2={description2}
                        description3={description3}
                        onClick={() => setIsModalOpen((prev) => !prev)}
                    />
                ) : null
            }
            <div>
                <Table
                    columns={columns}
                    dataSource={Array.isArray(transformedData) ? transformedData : []}
                    bordered
                    className='normal_input_table'
                    scroll={{
                        x: totalColumnWidth,
                    }}
                    pagination={{
                        pageSize: transformedData?.length,
                        position: ['none', 'none'],
                    }}
                />
            </div>
            <div className='flex justify-center mt-[8px]'>
                {
                    !!table_info_data?.table.isAddRows
                        ? <div className=''>
                            <button disabled={isDisabled} onClick={() => {
                                if (transformedData.length < table_info_data.table.max_no_rows) {
                                    dispatch(addRowInRO({ "targetFeature": name }))
                                } else {
                                    // message.warning(table_info_data?.table?.popup, 1)
                                    setIsModalOpen(true);
                                    // alert("max....");
                                }
                            }} className='flex items-center justify-center text-primary font-medium'>
                                <img src={Plus} width={24} className='mr-[0.2rem]' alt="" />
                                {table_info_data?.table.btn?.add}
                            </button>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default VerticalScrollableTable