import { useState, useEffect } from "react";
import { slides } from '../data';

export const useSlideShowFunctionality = () => {
    const [showControlButton, setShowControlButton] = useState(true);
    const [slideNumber, setSlideNumber] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    let showtimer;

    const hideControls = () => setTimeout(() => setShowControlButton(false), 2000);

    const resetShowControls = () => {
        showtimer = hideControls();
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        resetShowControls();
    }, []);

    useEffect(() => {
        const handleShowControls = () => {
            setShowControlButton(true);
            if (showtimer) clearTimeout(showtimer);
            showtimer = hideControls();
        }
        document.addEventListener('mousemove', handleShowControls);
        return () => document.removeEventListener('mousemove', handleShowControls);
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (slideNumber < slides.length - 1 && !isPaused) {
                setSlideNumber((prev) => prev + 1);
            } else {
                clearInterval(timer);
            }
        }, 2000);

        return () => {
            clearInterval(timer);
        };
    }, [slideNumber, isPaused]);

    const handleShowActiveState = (type) => {
        if (type === "prev" && slideNumber > 0) setSlideNumber((prev) => prev - 1);
        if (type === "next" && slideNumber < slides.length) setSlideNumber((prev) => prev + 1);
    }

    const handleToggleControls = () => {
        if (slideNumber === slides.length - 1) {
            setSlideNumber(0);
        } else {
            setIsPaused((prev) => !prev)
        }
    }

    return {
        handleShowActiveState,
        showControlButton,
        slideNumber,
        isPaused,
        slides,
        handleToggleControls
    }
}