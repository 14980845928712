import { generateUrlEndPoint } from "./endPointsGeneration"
import routesConfig from "../services/config/routesConfig.json"

export const checkIsRequiredAccessToken = (originalRequest) => {
    try {
        const urlSubEndPointList = originalRequest.split('/').filter((subPath) => subPath.length > 0);
        if (urlSubEndPointList.length >= 2) {
            const urlDetails = generateUrlEndPoint(routesConfig.root.subpaths, urlSubEndPointList[0], urlSubEndPointList[1], "response")
            const isCheckAccessToken = typeof urlDetails?.subConfig?.isTokenRequired === undefined ? true : Boolean(urlDetails?.subConfig?.isTokenRequired);
            return isCheckAccessToken;
        }
        return false;
    } catch (err) {
        return false;
    }
}