import React from 'react'
import { Button, Select } from "antd";
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import filter from "../../../../assets/common/filter.svg"
import { filterNestedCategory } from '../../functionality/filterNestedCategory';
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton';
import CollapseTable from '../../../../DesignSystem/Table/CollapseTable';
import TextLarge from '../../../../DesignSystem/TypographyDescriptions/TextLarge'
import HeadingSix from '../../../../DesignSystem/TypographyHeadings/HeadingSix'
import SecondaryButton from '../../../../DesignSystem/Buttons/SecondaryButton';
import bmLocals from '../../../../localization/localization_en_business_modal.json';
import { API_DOWNLOAD_REPORT_BUSINESS_MODAL_LIST, API_GET_REPORT_BUSINESS_MODAL_LIST, D_UpdateMyReportModeType, D_Update_Metric_Buisness_Model } from '../../businessModalSlice';
import HeadingFive from '../../../../DesignSystem/TypographyHeadings/HeadingFive';
import refresh from '../../../../assets/common/refresh.svg';
import LinkButton from '../../../../DesignSystem/Buttons/LinkButton';
import { formatTableData } from './formatTableData';
import { tablePayloadPreparation } from './tablePayloadPreparation';
import IndividualOutput from '../../subcomponents/IndividualOutput';
import downloadNotFilled from "../../../../assets/common/downloadNotFilled.svg"

const MY_REPORT_LOCALS = bmLocals.business_modal.my_reports;

const MyReportViewAndDownload = ({ filteredTargetCategoryText }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const businessModel = useSelector((state) => state.businessModal)
    const CURRENCY = useSelector((state) => state.globalSetting.currencySymbol)
    const decimal_places = useSelector((state) => state.globalSetting.active_global_settings.decimal_places)
    const { MODE, ACTIVE_BUSINESS_MODEL_ID, SELECTED_METRIC, SELECTED_METRIC_TABLE_DATA, categoryList } = useSelector((state) => state.businessModal.MY_REPORTS);
    const handleBackButton = () => {
        dispatch(D_UpdateMyReportModeType({ "SELECTED_METRIC": null, "MODE_TYPE": "DETAILS" }))
        navigate('/product/home/business-modal/my-report')
    }
    const businessModelName = businessModel.my_report_list?.businessModelList?.find((bm) => bm?.value === businessModel?.MY_REPORTS?.active_report_list_data?.[SELECTED_METRIC]?.activeId)?.label;
    const availableBusinessModelMetric = businessModel.my_report_list.reportBusinessModelList[SELECTED_METRIC].map((d) => ({ "label": d.title, "value": d._id }));
    const refetchReportData = () => {
        switch (SELECTED_METRIC) {
            case 'Average Order Value': {
                dispatch(API_GET_REPORT_BUSINESS_MODAL_LIST("average-order-value"));
                break;
            }
            case 'Balance Sheet': {
                dispatch(API_GET_REPORT_BUSINESS_MODAL_LIST("balance-sheet"));
                break;
            }
            case 'Cash Flow': {
                dispatch(API_GET_REPORT_BUSINESS_MODAL_LIST("cash-flow"));
                break;
            }
            case 'Marketing Cost': {
                dispatch(API_GET_REPORT_BUSINESS_MODAL_LIST("marketing-cost"));
                break;
            }
            case 'Profit & Loss': {
                dispatch(API_GET_REPORT_BUSINESS_MODAL_LIST("profit-and-loss"));
                break;
            }
        }
    }
    const data = tablePayloadPreparation({
        "report_type": SELECTED_METRIC,
        "data": businessModel.MY_REPORTS.active_report_list_data[SELECTED_METRIC].data.data,
        "metric": SELECTED_METRIC,
        "spanType": businessModel.MY_REPORTS.active_report_list_data[SELECTED_METRIC].spanType,
        "categoryList": businessModel.MY_REPORTS.categoryList
    })
    return (
        <div className='border-baseGray300 bg-baseGray100 p-[16px] rounded-lg border flex flex-col gap-y-[1rem]'>{/* individual report metric component */}
            <div className='flex justify-between'>
                <div className='flex gap-x-[0.3rem]'>
                    <HeadingSix text={SELECTED_METRIC} color={"#372EE5"} />
                    {/* {
                        ["Cash Flow","Profit & Loss"].includes(SELECTED_METRIC) 
                            ? null
                            : <TextLarge text={MY_REPORT_LOCALS.label_for_currency_metric.replace('CURRENCY', CURRENCY)} color={"#9E9E9E"} fontWeight={500} />
                    } */}
                </div>
                <ul className='flex gap-x-[16px]'>
                    {
                        MY_REPORT_LOCALS.filter_options[SELECTED_METRIC].map((filterOption, index) => {
                            return (
                                <Select
                                    key={index}
                                    value={businessModel.MY_REPORTS.active_report_list_data[SELECTED_METRIC][filterOption.data_index]}
                                    onChange={(value) => {
                                        dispatch(D_Update_Metric_Buisness_Model({ "data_index": filterOption.data_index, value }))
                                    }}
                                    style={{ minWidth: '13rem' }}
                                    // suffixIcon={<img src={filter} />}
                                    options={filterOption?.bm_list ? availableBusinessModelMetric : filterOption.options}
                                    placeholder={filterOption.placeHolder}
                                />
                            )
                        })
                    }
                </ul>
            </div>
            <div className='flex justify-between'>
                <HeadingFive text={businessModelName ? `${businessModelName}'s Business Model` : null} />
                <div className='flex justify-between gap-x-[0.5rem]'>
                    <PrimaryButton isIcon icon={refresh} text={"Refresh"} textColor='text-black' onClick={() => refetchReportData()} />
                    <PrimaryButton
                        isIcon
                        icon={downloadNotFilled}
                        onClick={() => dispatch(API_DOWNLOAD_REPORT_BUSINESS_MODAL_LIST({
                            "screenType": "my_report",
                            "tableName": SELECTED_METRIC,
                            "businessModelId": businessModel.MY_REPORTS.active_report_list_data[SELECTED_METRIC].activeId
                        }))}
                        text={MY_REPORT_LOCALS.details.cta_button.download}
                    />
                </div>
            </div>
            <div>
                <div className='bg-white p-[1rem] rounded-md'>
                    {
                        ["Balance Sheet", "Cash Flow", "Marketing Cost", "Profit & Loss"].includes(SELECTED_METRIC)
                            ? (
                                <div>
                                    <CollapseTable
                                        mainTableHeading={SELECTED_METRIC}
                                        currency={CURRENCY}
                                        data={data}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <IndividualOutput
                                        tableTitle={SELECTED_METRIC}
                                        dataSource={data}
                                        currency={CURRENCY}
                                    />
                                </div>
                            )
                    }
                </div>
                <div className='mt-[38px]'>
                    {
                        MODE === "VIEW"
                            ? (
                                <div className='flex justify-center'>
                                    <PrimaryButton onClick={handleBackButton} text={MY_REPORT_LOCALS.details.cta_button.back} />
                                </div>
                            ) : (
                                <div className='flex justify-between'>
                                    <SecondaryButton onClick={handleBackButton} text={MY_REPORT_LOCALS.details.cta_button.back} />
                                </div>
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default MyReportViewAndDownload