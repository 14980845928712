import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../services/api/api';
import routesConfig from '../../services/config/routesConfig.json'
import { generateUrlEndPoint } from '../../utils/endPointsGeneration';
import { D_UpdateCommentCount } from '../../features/psmSlice';
import { D_UpdateGTMChatCount } from '../GTM/gtmSlice';

export const defaultCommentModalPayload = {
    isModalOpen: false,
    isParentComment: false,
    ACTION_TYPE: null,
    activeChatId: null,
    editModalMessage: ""
}

export const fetchComments = createAsyncThunk(
    'chat/fetchComments',
    async (_, { getState, dispatch }) => {
        const state = getState();
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'chat', 'fetchAllComments');
            const urlWithParams = urlGenerator.url.replace(':commentId', state.chat.tableCellOrRowId);
            if (!!urlGenerator && state.chat.tableCellOrRowId) {
                const urlConfig = { ...urlGenerator, url: urlWithParams }
                const response = await api.request(urlConfig);
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const createParentCommentApi = createAsyncThunk(
    'chat/createParentCommentApi',
    async (_, { getState, dispatch }) => {
        try {
            const state = getState();
            let payload = { "tableCellOrRowId": state.chat.tableCellOrRowId, "message": state.chat.message }
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'chat', 'createComment');
            if (!!urlGenerator) {
                const urlConfig = { data: payload, ...urlGenerator }
                const response = await api.request(urlConfig);
                if(state.chat.moduleType === "problemSolutionMatrix"){
                    dispatch(D_UpdateCommentCount({ "count": state.chat.chatList.length+1, "tableCellOrRowId": state.chat.tableCellOrRowId }))
                }else if(["gtm_plan", "marketing_funnel"].includes(state.chat.moduleType)){
                    dispatch(D_UpdateGTMChatCount({ "count": state.chat.chatList.length+1, "tableCellOrRowId": state.chat.tableCellOrRowId, "moduleType": state.chat.moduleType }))
                }
                return { comment: response.data, user: state.user.value };
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const deleteParentCommentApi = createAsyncThunk(
    'chat/deleteParentCommentApi',
    async (_, { getState, dispatch }) => {
        try {
            const state = getState();
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'chat', 'deleteComment');
            const urlWithParams = `${urlGenerator.url}` + `?id=${state.chat.modalDetail.activeChatId}`
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, url: urlWithParams }
                const response = await api.request(urlConfig);
                if(state.chat.moduleType === "problemSolutionMatrix"){
                    dispatch(D_UpdateCommentCount({ "count": state.chat.chatList.length-1, "tableCellOrRowId": state.chat.tableCellOrRowId }))
                }else if(["gtm_plan", "marketing_funnel"].includes(state.chat.moduleType)){
                    dispatch(D_UpdateGTMChatCount({ "count": state.chat.chatList.length-1, "tableCellOrRowId": state.chat.tableCellOrRowId, "moduleType": state.chat.moduleType }))
                }
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const updateParentCommentApi = createAsyncThunk(
    'chat/updateParentCommentApi',
    async (message, { getState, dispatch }) => {
        try {
            const state = getState();
            const payload = { commentId: state.chat.modalDetail.activeChatId, message }
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'chat', 'updateComment');
            if (!!urlGenerator) {
                const urlConfig = { data: payload, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const createChildrenCommentApi = createAsyncThunk(
    'postingNestedComment/createChildrenCommentApi',
    async (_, { getState, dispatch }) => {
        try {
            const state = getState();
            const payload = { parentCommentId: state.chat.showReplyId, message: state.chat.replyMessage }
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'chat', 'addNestedComment');
            if (!!urlGenerator) {
                const urlConfig = { data: payload, ...urlGenerator }
                const response = await api.request(urlConfig);
                return { comment: response.data, user: state.user.value }
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const deleteChildrenCommentApi = createAsyncThunk(
    'deletingnestedcomment/deleteChildrenCommentApi',
    async (_, { getState, dispatch }) => {
        try {
            const state = getState();
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'chat', 'deleteNestedComment');
            const urlWithParams = `${urlGenerator.url}` + `?id=${state.chat.modalDetail.activeChatId}`
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, url: urlWithParams }
                const response = await api.request(urlConfig);
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const updateChildrenCommentApi = createAsyncThunk(
    'chat/updateChildrenCommentApi',
    async (editedMessage, { getState, dispatch }) => {
        try {
            const state = getState();
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'chat', 'updateNestedComments');
            const urlWithParams = `${urlGenerator.url}` + `?id=${state.chat.modalDetail.activeChatId}`
            if (!!urlGenerator) {
                const urlConfig = { data: { message: editedMessage }, ...urlGenerator, url: urlWithParams }
                const response = await api.request(urlConfig);
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);


const chatSlice = createSlice({
    name: 'chat',

    initialState: {

        "isCommentSectionVisible": false,

        "moduleType": null,
        "tableCellOrRowId": null,

        "modalDetail": defaultCommentModalPayload,

        "showReplyId": null,
        "replyMessage": null,

        "loadingStatus": "idle",
        "errorMessage": null,

        "chatList": [],
        "message": "",
        "nestedMessage": "",
        "updateMessage": { message: null, chartId: null },

        "parentId": null,
        "childrenId": null,

        "moduleType": null

    },

    reducers: {
        D_UpdateChatRowOrCell(state, action) {
            const { _id, moduleType } = action.payload;
            state.tableCellOrRowId = _id;
            state.moduleType = moduleType;
        },
        D_UpdateMessageText(state, action) {
            if (!(action.payload.key in state)) return;
            state[action.payload.key] = action.payload.value
        },
        D_UpdateActiveChatDetail(state, action) {
            const { isModalOpen, isParentComment, ACTION_TYPE, activeChatId, commentMessage } = action.payload;
            state.modalDetail = {
                "isModalOpen": isModalOpen,
                "isParentComment": isParentComment,
                "ACTION_TYPE": ACTION_TYPE,
                "activeChatId": activeChatId,
                "editModalMessage": commentMessage
            }
        },
        D_UpdateReplyMessageText(state, action) {
            state.replyMessage = action.payload;
        },
        D_UpdateToggleReplyMessage(state, action) {
            state.showReplyId = action.payload
        },
        D_ToggleCommentSection(state, action) {
            state.isCommentSectionVisible = action.payload;
        },
        D_ResetCommentState(state) {
            state.isCommentSectionVisible=false,
            state.moduleType = null;
            state.tableCellOrRowId = null;
            state.modalDetail = defaultCommentModalPayload;
            state.showReplyId = null;
            state.replyMessage = null;
            state.loadingStatus = "idle";
            state.errorMessage = null;
            state.chatList = [];
            state.message = "";
            state.nestedMessage = "";
            state.updateMessage = { message: null, chartId: null };
            state.parentId = null;
            state.childrenId = null;
            state.moduleType = null;
        },
        D_ResetLoadingStatus(state, action){
            state.loadingStatus = 'idle';
            state.errorMessage = null;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(createParentCommentApi.pending, (state) => {
                state.loadingStatus = "loading"
            })
            .addCase(createParentCommentApi.fulfilled, (state, action) => {
                state.loadingStatus = "success"
                const { comment, user } = action.payload;
                state.message = "";
                state.chatList = [
                    ...state.chatList,
                    {
                        _id: comment.commentId,
                        user: { _id: user.id, username: null, avatar: null },
                        message: comment.message,
                        childrenComments: [],
                        updatedAt: comment.updatedAt
                    }
                ]
            })
            .addCase(createParentCommentApi.rejected, (state, action) => {
                state.loadingStatus = "failed"
                state.errorMessage = action.error.message
            })
            .addCase(deleteParentCommentApi.pending, (state) => {
                state.loadingStatus = "loading"
            })
            .addCase(deleteParentCommentApi.fulfilled, (state, action) => {
                state.loadingStatus = "success"
                state.chatList = state.chatList.filter((chat) => chat._id !== action.payload.commentId)
                state.modalDetail = defaultCommentModalPayload
            })
            .addCase(deleteParentCommentApi.rejected, (state, action) => {
                state.loadingStatus = "failed"
                state.errorMessage = action.error.message
            })
            .addCase(updateParentCommentApi.pending, (state) => {
                state.loadingStatus = "loading"
            })
            .addCase(updateParentCommentApi.fulfilled, (state, action) => {
                state.loadingStatus = "success"
                state.chatList = state.chatList.map((chat) => chat._id === action.payload._id ? { ...chat, "message": action.payload.message } : chat)
                state.modalDetail = defaultCommentModalPayload
            })
            .addCase(updateParentCommentApi.rejected, (state, action) => {
                state.loadingStatus = "failed"
                state.errorMessage = action.error.message
            })
            .addCase(createChildrenCommentApi.pending, (state) => {
                state.loadingStatus = "loading"
            })
            .addCase(createChildrenCommentApi.fulfilled, (state, action) => {
                state.loadingStatus = "success"
                const { comment, user } = action.payload;
                const nestedData = {
                    _id: comment._id,
                    user: { _id: user.id, username: null, avatar: null },
                    message: comment.message,
                    updatedAt: comment.updatedAt
                }
                state.chatList = state.chatList.map((chat) => {
                    return chat._id === comment.parentCommentId ? { ...chat, "childrenComments": [...chat.childrenComments, nestedData] } : chat
                })
                state.replyMessage = ""
                state.showReplyId = null;
            })
            .addCase(createChildrenCommentApi.rejected, (state, action) => {
                state.loadingStatus = "failed"
                state.errorMessage = action.error.message
            })
            .addCase(deleteChildrenCommentApi.pending, (state) => {
                state.loadingStatus = "loading"
            })
            .addCase(deleteChildrenCommentApi.fulfilled, (state, action) => {
                state.loadingStatus = "success"
                state.chatList = state.chatList.map((chat) => {
                    if (chat._id === action.payload.parentCommentId) {
                        return {
                            ...chat,
                            "childrenComments": chat.childrenComments.filter((subChat) => subChat._id !== action.payload._id)
                        }
                    }
                    return chat;
                })
                state.modalDetail = defaultCommentModalPayload
            })
            .addCase(deleteChildrenCommentApi.rejected, (state, action) => {
                state.loadingStatus = "failed"
                state.errorMessage = action.error.message
            })
            .addCase(updateChildrenCommentApi.pending, (state) => {
                state.loadingStatus = "loading"
            })
            .addCase(updateChildrenCommentApi.fulfilled, (state, action) => {
                state.loadingStatus = "success"
                state.chatList = state.chatList.map((chat) => {
                    console.log(chat._id, action.payload.parentCommentId)
                    if (chat._id === action.payload.parentCommentId) {
                        return {
                            ...chat,
                            "childrenComments": chat.childrenComments.map((subChat) => subChat._id === action.payload._id ? { ...subChat, "message": action.payload.message } : subChat)
                        }
                    }
                    return chat;
                })
                state.modalDetail = defaultCommentModalPayload
            })
            .addCase(updateChildrenCommentApi.rejected, (state, action) => {
                state.loadingStatus = "failed"
                state.errorMessage = action.error.message
            })
            .addCase(fetchComments.pending, (state) => {
                state.loadingStatus = "loading"
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                state.loadingStatus = "success"
                state.chatList = action.payload;
            })
            .addCase(fetchComments.rejected, (state, action) => {
                state.loadingStatus = "failed"
                state.errorMessage = action.error.message
            })
    }
})


export const {
    D_ResetLoadingStatus,
    D_ResetCommentState,
    D_ToggleCommentSection,
    D_UpdateToggleReplyMessage,
    D_UpdateReplyMessageText,
    D_UpdateMessageText,
    D_UpdateChatRowOrCell,
    D_UpdateActiveChatDetail
} = chatSlice.actions
export default chatSlice.reducer