import { generateDefaultProductCategory } from "../data/data"
import { percentAndAvgForSmAndECommerProp } from "../functionality/RevenueOnboarding/generateDefaultData"
import { generateDefaultKeyDetailsData } from "../functionality/RevenueOnboarding/generateDefaultData"
import { default_total_mau_and_registered_users } from "../functionality/RevenueOnboarding/generateDefaultData"
import { generateSocialMediaMarketingPlaceData } from "../functionality/RevenueOnboarding/generateDefaultData"

export const resetRevenueScreenData = ({ screenNumber, default_channel_list }) => {
    // console.log("earsed resetting screen ",screenNumber);
    try {
        if (screenNumber === '0') {
            return {
                "channel_sales": [],
                "marketing_spend_per_sales_channel": {
                    type: "last_month_marketing_spend",
                },
                "marketing_spend_per_channel_fiscal_year": null,
                "acquiringUserOptions": [],
                "acquiring_app_users": []
            }
        } else if (screenNumber === '1') {
            return {
                "channel_sales": [],
                "marketing_spend_per_channel_fiscal_year": null,
                "manufacture_or_buy": null,
                "product_categories_details": {
                    "manufacture": generateDefaultProductCategory(3, null, "manufacture"),
                    "buy": []
                },
            }
        } else if (screenNumber === '2') {
            return {
                "channel_sales": [],
                "marketing_spend_conversion": null,
                "product_categories_details": {
                    "manufacture": generateDefaultProductCategory(3, null, "manufacture"),
                    "buy": []
                },
            }
        } else if (screenNumber === '3') {
            return {
                "channel_sales": [],
                "social_media_marketplaces": generateSocialMediaMarketingPlaceData(default_channel_list),
                "organic_paid_traffic_social_media_marketplaces": [{ "fixed_percentage": null, "varying_cost_over_time": null }],
                "organic_traffic_conversion_rate": [{ "fixed_percentage": null, "varying_cost_over_time": null }]
            }
        } else if (screenNumber === '4') {
            return {
                "channel_sales": [],
                "acquiring_app_users": [],
                "key_details_user_acquisition_engagement": generateDefaultKeyDetailsData(),
                "social_media": [percentAndAvgForSmAndECommerProp],
                "e_commerce": [percentAndAvgForSmAndECommerProp],
                "word_of_mouth_1": [percentAndAvgForSmAndECommerProp],
                "word_of_mouth_2": [percentAndAvgForSmAndECommerProp],
                "total_mau_and_registered_users": default_total_mau_and_registered_users,
            }

        } else if (screenNumber === '5') {
            return {
                "channel_sales": []
            }
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}