import React from 'react';
import { Line } from '@ant-design/plots';
import { COLOR_PLATE } from "../color-palette"
import { convertAmountInK } from '../../../utils/convertAmountInK';

const SetStyleLinePlotPoint = ({ 
    data,
    colors,

    unitInK,
    yAxisPreUnit,
    yAxisPostUnit,

    xLabelAutoRotate=false,
    XAxisBarLabelVisible=true,
    yAxisLabel="",
    xAxisLabel="",
    autoHideLabel=true,

    xAxisGridLine="white",
    yAxisGridLine="#EEEEEE",

    dataIndexNameXAxis="month_year",
    dataIndexNameYAxis="value",
    dataIndexNameSeries="category",

    animation=true,

    pointShape="circle",

    legendPosition="bottom-left"

}) => {
    try {
        const config = {
            data,
            xField: dataIndexNameXAxis,
            yField: dataIndexNameYAxis,
            seriesField: dataIndexNameSeries,
            yAxis: {
                label: {
                    autoHide: true,
                    formatter: (v) => unitInK ? `${yAxisPreUnit || ''}${convertAmountInK(v)}${yAxisPostUnit || ''}` : `${yAxisPreUnit || ''}${v}${yAxisPostUnit || ''}`
                },
                title: {
                    text: yAxisLabel,
                    style: {
                        fontSize: 11,
                        fill: "#9E9E9E"
                    }
                },
                grid: { // Set the grid axis lines color here
                    line: {
                        style: {
                            stroke: yAxisGridLine
                        },
                    },
                }
            },
            xAxis: {
                label: {
                    autoHide: autoHideLabel,
                    autoRotate: xLabelAutoRotate,
                    style: {
                        fontSize: XAxisBarLabelVisible ? 12 : 0,
                    },
                },
                title: {
                    text: xAxisLabel,
                    style: {
                        fontSize: 11,
                        fill: "#9E9E9E"
                    }
                },
                grid: {
                    line: {
                        style: {
                            stroke: xAxisGridLine,
                        },
                    },
                },
            },
            color: colors?.length>0 ? colors : COLOR_PLATE,
            point: {
                shape: pointShape
            },
            legend: {
                layout: 'horizontal',
                position: legendPosition
            }
        };

        return <Line {...config} animation={animation} />;
    } catch (error) {
        console.error('Error in SetStyleLinePlotPoint component:', error);
        throw new Error(error)
    }
};

export default SetStyleLinePlotPoint;