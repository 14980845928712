import React from 'react'
import eyeIcon from "../../../../assets/common/eyeIcon.svg"
import downloadNotFilled from "../../../../assets/common/downloadNotFilled.svg"
import BMButton from '../../subcomponents/Form/BMButton';
import TextLarge from '../../../../DesignSystem/TypographyDescriptions/TextLarge';
import HeadingSix from '../../../../DesignSystem/TypographyHeadings/HeadingSix';
import bmLocals from '../../../../localization/localization_en_business_modal.json';

const MY_REPORT_LOCALS = bmLocals.business_modal.my_reports;

const MyReportMetricList = ({ filteredTargetMetricText, handleOnModeClick }) => {
    
    return (
        <div className='bg-baseGray100 p-[16px] rounded-md mb-[24px]'> {/* my reports metric details */}
            <HeadingSix text={MY_REPORT_LOCALS.details.title} />
            <ul className='flex justify-center flex-col gap-y-[24px]'>
                {
                    MY_REPORT_LOCALS.details.modules.map((module, index) => {
                        if (!module.title.toLowerCase().includes(filteredTargetMetricText.toLowerCase())) return;
                        return (
                            <li key={index} className='flex justify-between border bg-white border-baseGray300 rounded-md px-[12px] py-[16px]'>
                                <div className='my-auto'>
                                    <TextLarge text={module.title} fontWeight={400} />
                                </div>
                                <div className='flex gap-x-[16px]'>
                                    {/* <BMButton handleOnClick={() => handleOnModeClick("VIEW", module.title)} icon={eyeIcon} type={"primary"} text={MY_REPORT_LOCALS.details.cta_button.view} />
                                    <BMButton handleOnClick={() => handleOnModeClick("DOWNLOAD", module.title)} icon={downloadNotFilled} type={"secondary"} text={MY_REPORT_LOCALS.details.cta_button.download} /> */}
                                    <BMButton handleOnClick={() => handleOnModeClick("DOWNLOAD", module.title)} icon={downloadNotFilled} type={"primary"} text={MY_REPORT_LOCALS.details.cta_button.view_download} />
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default MyReportMetricList