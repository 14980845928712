import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import store from '../../store';
import { persistor } from "../../main"
import { RESET_STATE } from "../../store";
import { checkIsRequiredAccessToken } from '../../utils/checkIsRequiredAccessToken';
import { updateBadResponse, discardSuccessApiUrlOnTraceRequest, updatePaymentStatus, D_App_Toggle_ShowErrorNextGeneratePopup } from '../../features/appSlice';
import { DUpdatePaymentStatus } from '../../features/userSlice';
import { updateAccessToken } from '../../features/userSlice';
import { updateMenuItemSelection } from '../../features/manageLinkSlice';
import { D_App_BackendNotificationService } from '../../features/appSlice';


export const rootPath = import.meta.env.VITE_REACT_ENVIRONMENT === "development"
? import.meta.env.VITE_REACT_DEV_API_URL
: (import.meta.env.VITE_REACT_ENVIRONMENT === "staging" ? import.meta.env.VITE_REACT_STAGING_API_URL : import.meta.env.VITE_REACT_PROD_API_URL)

const api = axios.create({
  baseURL: rootPath,
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    const token = store.getState().user.value.AccessToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async(response) => {
    // return response;
    const newAccessToken = response?.headers?.["x-new-access-token"];
    // const accessTokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('access_token='));
    // const accessToken = response?.headers?.accesstoken || response?.data?.AccessToken || undefined ; // unwanted code
    if(newAccessToken){ // to do update on reset token
      store.dispatch(updateAccessToken(newAccessToken));
    }
    if(response.status!==200 && response.status != 429){
      import.meta.env.VITE_REACT_ENVIRONMENT!=="development" && store.dispatch(discardSuccessApiUrlOnTraceRequest({ requestUrl: response?.config?.url }));
    }
    try {
      const decoded = jwtDecode(response.config.headers.Authorization);
      if(decoded && decoded?.org?.subscriptionPlan){
        store.dispatch(DUpdatePaymentStatus({
          "planStatus": decoded?.org?.subscriptionPlan ?? "expired", 
          "invitationStart": decoded?.org?.createdAt,
          "invitationExpiry": decoded?.org?.invitationExpiry,
          "invitedNoOfDays": decoded?.org?.invitedNoOfDays
        }))
      }
    } catch (error) {
      console.log(error);
    }
    return response;
  },
  async(error) => {
    const error_message = error?.response?.data?.error?.message ?? error.response?.data?.message
    if (error_message && (["data-room"].some(prefix => 'data-room/copy-file'.startsWith(prefix)))){
      let notificationCounter = store.getState().app.notification_trigger_details?.counter || 0;
      store.dispatch(D_App_BackendNotificationService({ "counter": notificationCounter+1, "message": error_message, "type": "error"}))
    } 
    const originalRequest = error.config;
    const isTokenRequired = checkIsRequiredAccessToken(originalRequest.url);
    // const accessTokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('access_token='));
    import.meta.env.VITE_REACT_ENVIRONMENT!=="development" && store.dispatch(updateBadResponse({ requestUrl: originalRequest.url }));
    const IS_BAD_RESPONSE_REPEATING = store.getState().app.traceBadResponse[originalRequest.url]
    const businessModelWarningErrorUrl = ['get-business-model-screen-data', 'save-business-model', 'process-business-model']
    let isBmError = false;
    businessModelWarningErrorUrl.map((url) => {
      if((error.response.config.url).includes(url)) isBmError = true;
    })
    if(isBmError){ // for busines modal 
      store.dispatch(D_App_Toggle_ShowErrorNextGeneratePopup());
    }
    // check 402 and return 
    if (error.response.status === 402){
      // store.dispatch(updatePaymentStatus({ "isWarningPopupOpen": true, "isPlanActive": false })); // false - trial / plan expired
      store.dispatch(updateMenuItemSelection({ "openKeys": ["0"], "selectedKeys": ["0"] }));
      return Promise.reject(error)
    }
    if (error.response?.data?.type==="token_error" || error.response?.data?.msg==="jwt expired"){
      store.dispatch({ type: RESET_STATE });
    }
    if (error.response.status === 400 && isTokenRequired && !IS_BAD_RESPONSE_REPEATING) {
      // try{
      //   store.dispatch({ type: RESET_STATE });
      //   // const access_token = await api.get('refresh-token/');
      //   // console.log(access_token);
      //   // return api(originalRequest);
      // }catch(error){
      //   store.dispatch({ type: RESET_STATE });
      // }
      // store.dispatch(logoutAction());
      // store.dispatch({ type: RESET_STATE });
    }
    return Promise.reject(error)
  }
);


const setBearerToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

export default api;
export { setBearerToken }
