export const generateMargin = (direction, value) => {
    const marginStyles = {};
    if (direction === 'left') {
        marginStyles.marginLeft = `${value}px`;
    } else if (direction === 'right') {
        marginStyles.marginRight = `${value}px`;
    } else if (direction === 'top') {
        marginStyles.marginTop = `${value}px`;
    } else if (direction === 'bottom') {
        marginStyles.marginBottom = `${value}px`;
    } else if (direction === 'horizontal') {
        marginStyles.marginLeft = `${value}px`;
        marginStyles.marginRight = `${value}px`;
    } else if (direction === 'vertical') {
        marginStyles.marginTop = `${value}px`;
        marginStyles.marginBottom = `${value}px`;
    } else {
        marginStyles.margin = `${value}px`;
    }
    return marginStyles;
};
