import React from 'react'
import { shapes } from '../../assets/website'
import PredictGrowthLogo from './PredictGrowthLogo'
import HeaderNavLink from './HeaderNavLink'
import PGButtonComponent from '../../DesignSystem/Buttons/PGButtonComponent'
import CollapsedMenu from './CollapsedMenu'
import { useNavigate } from 'react-router-dom'
import UserInformationForm from './UserInformationForm'

const { hambuger, close, up_arrow_primary } = shapes;

const PGHeader = ({ locals }) => {

    const navigate = useNavigate();

    const { navlinks, contact_us_btn, login_btn, request_demo_btn } = locals;
    const [isMenuCollapsed, setMenuCollapsed] = React.useState(true);

    const toggleCloseMenuBar = () => setMenuCollapsed((prev) => !prev);

    return (
        <div>
            <div className='h-[76px] max-w-[1440px] mx-auto fixed top-0 left-0 right-0 bg-white flex justify-between items-center z-[10000] py-[28px] lg:px-[40px] px-[24px] border-b border-[#9E9E9E] xl:border-none'>

                <div className='flex justify-between items-center gap-x-[48px] xl:max-w-fit w-full'>
                    <button onClick={() => navigate('/')}><PredictGrowthLogo /></button>
                    <div className='xl:block hidden mr-auto'>
                        <HeaderNavLink navlinks={navlinks} />
                    </div>
                    <div className='flex xl:hidden justify-center items-center'>
                        <PGButtonComponent onClick={() => navigate('/product')} intent={"link"}>{login_btn?.cta_name}</PGButtonComponent>
                        <PGButtonComponent onClick={() => setMenuCollapsed((prev) => !prev)} className={'border p-0 bg-transparent border-none'}>
                            <img src={isMenuCollapsed ? hambuger : close} alt="" />
                        </PGButtonComponent>
                    </div>
                </div>

                <div className='xl:flex gap-x-[16px] hidden'>
                    <UserInformationForm>
                        {
                            ({ handleTogglePopup }) => (
                                <PGButtonComponent
                                    onClick={() => {
                                        // if(contact_us_btn?.is_form){
                                        //     handleTogglePopup({ "form_name": contact_us_btn?.form_name })
                                        // }else{
                                        //     navigate(contact_us_btn?.path_link);
                                        // }
                                        navigate('/contact-us');
                                    }}
                                    intent={"link"}
                                >
                                    {contact_us_btn?.cta_name}
                                </PGButtonComponent>
                            )
                        }
                    </UserInformationForm>
                    <PGButtonComponent onClick={() => navigate(login_btn?.path_link)} intent={"link"}>{login_btn?.cta_name}</PGButtonComponent>
                    <PGButtonComponent onClick={() => navigate(request_demo_btn?.path_link)} intent={"primary"}>{request_demo_btn?.cta_name}</PGButtonComponent>
                </div>

            </div>
            {
                !isMenuCollapsed
                    ? (
                        <div className='xl:hidden block bg-white w-full h-screen fixed -top-[0.1rem] left-0 z-[100000] pt-[0.14rem]'>
                            <div className='flex h-[76px] z-[100000] bg-white justify-between items-center gap-x-[48px] xl:max-w-fit w-full py-[26px] px-[24px] border-b border-[#9E9E9E]'>
                                <button onClick={() => {
                                    navigate('/')
                                    toggleCloseMenuBar();
                                }}><PredictGrowthLogo /></button>
                                <div className='flex xl:hidden justify-center items-center'>
                                    <PGButtonComponent onClick={() => {
                                        navigate(login_btn?.path_link)
                                        toggleCloseMenuBar();
                                    }} intent={"link"}>{login_btn.cta_name}</PGButtonComponent>
                                    <PGButtonComponent onClick={() => setMenuCollapsed((prev) => !prev)} className={'border p-0 bg-transparent border-none'}>
                                        <img src={isMenuCollapsed ? hambuger : close} alt="" />
                                    </PGButtonComponent>
                                </div>
                            </div>
                            <div className='fixed top-0 left-0 right-0 -z-10 h-screen'>
                                <CollapsedMenu toggleCloseMenuBar={toggleCloseMenuBar} navlinks={navlinks} contact_us_btn={contact_us_btn} login_btn={login_btn} />
                            </div>
                        </div>
                    ) : null
            }
        </div>
    )
}

export default PGHeader