import React, { useEffect, useState } from 'react'
import webLocals from "../../localization/localization_en_website.json";
import ResponsiveHeadingTwoFive from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingTwoFive';
import TextExtraLarge from '../../DesignSystem/TypographyDescriptions/TextExtraLarge';
import ClientInformationForm from '../../components/reuseablecomponent/Form/ClientInformationForm';
import PGButtonComponent from '../../DesignSystem/Buttons/PGButtonComponent';
import { validator } from '../../utils/validator';
import { useDispatch, useSelector } from 'react-redux';
import { API_Post_WebsiteForm } from '../../features/appSlice';
import { message } from 'antd';

const contact_us_page = webLocals.website.contact_us;
const contact_us_form = webLocals.website.collect_user_details.contact_us;

const ContactUs = () => {

    const targetForm = "contact_us";
    const INITIAL_STATE = { name: null, company_name: null, email_address: null, phone_number: null, designation: null, country: null, message: null }

    const dispatch = useDispatch();
    const api_status = useSelector((state) => state.app.website_form.api_status)
    const [isShowWarning, setIsShowWarning] = useState(false);
    const [Form, setForm] = useState(INITIAL_STATE);
    const storeFormValue = ({ name, value }) => setForm((prev) => ({ ...prev, [name]: value }));

    useEffect(() => {
        if (api_status?.status === "success") {
            setForm(INITIAL_STATE);
            setIsShowWarning(false);
        }
        if (api_status?.status === "failed") {
            message.open({ type: "error", "content": api_status?.message });
        }
    }, [api_status?.status]);

    const handleSubmitForm = () => {
        let isDataFilled = true;
        const data = Form;
        contact_us_form.form.map((f) => {
            if (f.is_required && validator({ "validator": f.validator, "value": data[f.name] }) === false) isDataFilled = false;
        })
        if (!isDataFilled) {
            setIsShowWarning(true);
        } else {
            window.dataLayer.push({
                event: `form_submit_${targetForm}`,
            });
            dispatch(API_Post_WebsiteForm({ "formType": targetForm, "data": Form }));
        }
    }

    return (
        <div className='relative 2xl:p-0 m-[12px] flex items-center justify-center w-[calc(100%-24px)] min-h-[100vh]'>
            <div className='w-full sm:w-[650px] flex flex-col gap-y-[40px] py-[24px]'>
                <div className='flex flex-col gap-y-[4px]'>
                    <ResponsiveHeadingTwoFive className={"sm:text-white text-black"} text={contact_us_page.title} />
                    <TextExtraLarge isStyleColor={false} className={"sm:text-white text-black"} text={contact_us_page.description} fontWeight={400} />
                </div>
                <div className='shadow-md sm:p-[24px] p-[8px] border rounded-lg bg-white'>
                    <ClientInformationForm
                        isShowWarning={isShowWarning}
                        FormValues={Form}
                        storeFormValue={storeFormValue}
                        form={contact_us_form.form}
                        warningFields={contact_us_form.incomplete_form_warning_msg}
                    />
                    <PGButtonComponent
                        onClick={handleSubmitForm}
                        disabled={api_status?.status === "loading"}
                        className={'w-full mt-[24px]'}
                    >
                        {
                            api_status?.status === "loading"
                            ? contact_us_form.loadingSubmitText
                            : contact_us_form.submit
                        }
                    </PGButtonComponent>
                </div>
            </div>
            <div className='h-[100%] top-0 -z-10 absolute w-full bg-white sm:block hidden'>
                <div className='h-[50%] bg-[#21208F]'></div>
            </div>
        </div>
    )
}

export default ContactUs