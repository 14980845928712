import React from 'react';
import { Table } from "antd";
import TextLarge from '../TypographyDescriptions/TextLarge';
import TextMedium from '../TypographyDescriptions/TextMedium';
import SkeletonLoader from '../Loader/SkeletonLoader';

const StaticTableRender = ({ columns, data, isDataLoading }) => {

    const tableColumns = columns.map((col, columnIndex) => {
        return (
            {
                "title": <div className='p-[0.7rem]'><TextLarge fontWeight={600} text={col.title} /></div>,
                "dataIndex": col.data_index,
                "key": col.data_index,
                "fixed": col.fixed,
                "width": col.width,
                "render": (value, record, rowIndex) => {
                    if(isDataLoading){
                        return (
                            <div className='py-[1rem] pl-[1rem]'>
                                <SkeletonLoader width={150} height={30} />
                            </div>
                        )
                    }
                    if (typeof value === "string") {
                        return <TextMedium text={value} fontWeight={500} display='block' className={'p-[1rem]'} />
                    }
                }
            }
        )
    })

    const totalColumnWidth = columns?.reduce((total, column) => total + (column.width || 0), 0);

    return (
        <Table
            bordered
            columns={tableColumns}
            dataSource={data}
            scroll={{
                x: totalColumnWidth,
            }}
            className='normal_input_table'
            pagination={{
                pageSize: data.length,
                position: ['none', 'none'],
            }}
        />
    )
}

export default StaticTableRender