import React, { useState } from 'react'
import playFront from "../../../assets/common/playFront.svg"
import playBack from "../../../assets/common/playBack.svg"
import IconButton from '../../../DesignSystem/Buttons/IconButton'
import hoveredPause from '../../../assets/common/hoveredPause.svg'
import activePause from '../../../assets/common/activePause.svg'
import hoveredPlay from '../../../assets/common/hoveredPlay.svg'
import activePlay from '../../../assets/common/activePlay.svg'

const SlideShowControl = ({ isPaused=false, numberOfSlides, handleShowActiveSlide, onMouseHover, onMouseLeave, handleTogglePlay, isHovered, isSlideShow=false }) => {
    const icon = isPaused ? (!isHovered ? activePlay : hoveredPlay) : (!isHovered ? activePause : hoveredPause);
    return (
        <div className='py-[16px] bg-[#111827] flex gap-x-[100px] h-full justify-center items-center'>
            <IconButton onClick={() => handleShowActiveSlide("prev")} icon={playBack} btnBgColor='black' />
            {isSlideShow && <IconButton onMouseHover={onMouseHover} onMouseLeave={onMouseLeave} onClick={handleTogglePlay} icon={icon} btnBgColor='white' />}
            <IconButton onClick={() => handleShowActiveSlide("next")} icon={playFront} btnBgColor='black' />
        </div>
    )
}

export default SlideShowControl