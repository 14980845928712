import React, { useState } from "react";
import TextLarge from "../TypographyDescriptions/TextLarge";
import { useNavigate } from "react-router-dom";
import FolderSVG from "../SVG/FolderSVG";
import file from "../../assets/common/file.svg";
import profile from "../../assets/PSM/profile.svg";
import CustomCheckBox from "../Forms/CheckBoxVarients/CustomCheckBox";
import TextMedium from "../TypographyDescriptions/TextMedium";
import ActionMenuArchiveCard from "./ActionMenuArchiveCard";

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
};

const ArchiveRoomDashboardCard = ({
    cardProfileImg,
    bgColor = "#372EE5",
    color,
    cardTitle = "Title",
    onCheckboxChange,
    cardBorderColor = "#9E9E9E",
    id,
    fileCount,
    skeleton = false, // Added skeleton prop
    onClose
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const navigate = useNavigate();

    const handleCheckboxChange = (event) => {
        event.stopPropagation();
        setIsChecked(!isChecked);
        onCheckboxChange(!isChecked, { title: cardTitle, id: id });
    };

    const handleMainClose = () => {
        onClose()
    }

    const handleCardClick = () => {
        navigate(`/product/home/data-room/archive/${cardTitle}/files`)
    };

    const cardStyle = {
        borderColor: isChecked
            ? "var(--Main-Colors-Primary-color, #372EE5)"
            : cardBorderColor,
        backgroundColor: isChecked ? "#EBF1FF" : "",
        border: isChecked
            ? "2px solid var(--Main-Colors-Primary-color, #372EE5)"
            : `${!skeleton ? `2px solid ${cardBorderColor}` : ""}`,
    };

    const handleActionMenuClick = (event) => {
        event.stopPropagation(); // Prevent click event from bubbling up
    };
    console.log(fileCount)
    return (
        <React.Fragment>
            <div
                style={cardStyle}
                className={`flex flex-col sm:flex-row justify-between relative w-full h-full p-3 shadow-lg rounded-2xl cursor-pointer gap-5 ${skeleton ? 'bg-gray-100 border-none' : ''}`} // Conditional background for skeleton
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {skeleton ? (
                    <div className="flex flex-col gap-3 py-2 animate-pulse"> {/* Skeleton Loader */}
                        <div className="flex items-center">
                            <div className="w-12 h-12 bg-gray-300 rounded-full"></div>
                            <div className="ml-4 w-1/2 h-4 bg-gray-300 rounded"></div>
                        </div>
                        <div
                            style={{ borderColor: cardBorderColor }}
                            className="flex flex-col sm:flex-row items-center"
                        >
                            <div className="flex items-center justify-center h-full gap-1 mb-2 sm:mb-0">
                                <div className="ml-2 w-14 h-4 bg-gray-300 rounded"></div>
                            </div>
                            <div
                                style={{ backgroundColor: cardBorderColor }}
                                className="w-[0.1px] h-[24px] mx-2 hidden sm:block"
                            ></div>
                            <div className="flex gap-1 items-center">
                                <div className="w-16 h-4 bg-gray-300 rounded"></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex flex-col gap-3 py-2">
                            <div className="flex items-center" onClick={handleCardClick}>
                                <div>
                                    <FolderSVG color={color} />
                                </div>
                                <TextLarge
                                    fontWeight={600}
                                    text={truncateText(cardTitle, 10)}
                                    color={"#111827"}
                                    margin={{ direction: "left", number: 8 }}
                                />
                            </div>
                            <div
                                style={{ borderColor: cardBorderColor }}
                                className="flex flex-col sm:flex-row items-center"
                            >
                                <div className="flex items-center justify-center h-full gap-1 mb-2 sm:mb-0">
                                    <div className="flex -space-x-2">
                                        <img className="w-1/2 h-1/2 rounded-full" src={profile} />
                                        <img className="w-1/2 h-1/2 rounded-full" src={profile} />
                                        <img className="w-1/2 h-1/2 rounded-full" src={profile} />
                                    </div>
                                    <TextMedium
                                        fontWeight={500}
                                        text={"Members"}
                                        color={"#757575"}
                                        margin={{ direction: "left" }}
                                        className="whitespace-nowrap overflow-hidden text-ellipsis"
                                    />
                                </div>
                                <div
                                    style={{ backgroundColor: cardBorderColor }}
                                    className="w-[0.1px] h-[24px] mx-2 hidden sm:block"
                                ></div>
                                <div className="flex gap-1">
                                    <img src={file} />
                                    <TextMedium
                                        fontWeight={500}
                                        text={fileCount === 1 ? "1 File" : fileCount + " Files"}
                                        color={"#757575"}
                                        margin={{ direction: "left" }}
                                        className="whitespace-nowrap overflow-hidden text-ellipsis"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between">
                            <div className="relative flex justify-end" onClick={handleActionMenuClick}>
                                <ActionMenuArchiveCard documentId={id} onClose={handleMainClose} />
                            </div>
                            {(isHovered || isChecked) && (
                                <button onClick={handleCheckboxChange}>
                                    <CustomCheckBox
                                        isChecked={isChecked}
                                        handleOnClick={handleCheckboxChange}
                                    />
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </React.Fragment>
    );
};

export default ArchiveRoomDashboardCard;

