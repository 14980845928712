import React from 'react'
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium'
import ReactLibraryMarquee from '../../DesignSystem/Marquee/ReactLibraryMarquee'
import classNames from "classnames"
import { cn } from '../../utils/cn'

const PoweredByMarquee = ({ title, marqueeImages, imgClassName = 'xl:mx-[6rem] w-[12rem] object-contain lg:mx-[4rem] md:mx-[2.5rem] mx-[1rem] max-h-[40px]', isStatic, parentClassName }) => {
    return (
        <div className={cn(classNames(
            'bg-[#F5F5F5] flex flex-col items-center justify-center overflow-hidden py-[20px]',
            parentClassName
        ))}>
            <TextMedium fontWeight={500} text={title} color='#757575' />
            {
                isStatic
                    ? (
                        <div className='flex justify-around w-full mt-[10px]'>
                            {
                                marqueeImages.map((marqueeImage, index) => {
                                    return (
                                        <img key={index} src={marqueeImage} className='object-contain sm:w-[12rem] w-[8rem] max-h-[40px]' alt="" />
                                    )
                                })
                            }
                        </div>
                    ) : (
                        <div className='mt-[10px]'>
                            <ReactLibraryMarquee speed={100}>
                                {
                                    marqueeImages.map((marqueeImage, index) => {
                                        return (
                                            <div>
                                                <img key={index} src={marqueeImage} className={cn(imgClassName)} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </ReactLibraryMarquee>
                        </div >
                    )
            }
        </div >
    )
}

export default PoweredByMarquee