import { convertArrIntoString } from "./convertArrIntoString";

export const convertDSIntoText = (data) => {
    try {
        if (typeof data === "string") return data;
        if (Array.isArray(data)) return convertArrIntoString(data) || ""
        if (
            (data !== null && typeof data === 'object' && !Array.isArray(data) )
                && 
            (data?.list?.[0] !== null && typeof data?.list?.[0] === "object" && !Array.isArray(data?.list?.[0]))
        ){
            return data.title + ' ' + data?.list?.[0]?.title + ' ' + convertArrIntoString(data?.list?.[0]?.list) 
        }
        if (data !== null && typeof data === 'object' && !Array.isArray(data)){
            return data.title + ' ' + convertArrIntoString(data?.list) || ""
        }
    } catch (error) {
        return "";
    }
}