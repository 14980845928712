import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'

const HeadingFour = ({ text, color, margin, padding, className, headingCSSStyles }) => {
  return (
    <React.Fragment>
        <h4 style={{color:color,...generatePadding(padding?.direction,padding?.number), ...generateMargin(margin?.direction,margin?.number), ...headingCSSStyles}} className={`text-[24px] font-bold font-DMSans ${className}`}>{text}</h4>
    </React.Fragment>
  )
}

export default HeadingFour