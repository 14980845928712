import React from 'react'
import { stickyNotesPalette } from './data';
import { updateSlideNumber } from './buildbusinesscanvasSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import editbbc from "../../assets/common/editbbc.svg";
import { Button } from "antd";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

const StickyNoteCompo = ({ bgColor, data, SlideNumber, showEditIcon }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <div className={`${bgColor} p-[39px] mb-[39px]`}>
            {
                data.map((d, index) => {
                    return <div key={index}>
                        <div className={`flex bg-white min-h-[210px] shadow-lg p-[12px] ${index===1 && 'my-[39px]'}`}>
                            <div className='w-[30%] border-r border-[#BDBDBD] flex items-center'>
                                <p style={{ paddingBottom: '0px', marginBottom:'4px' }} className='text-[#111827 flex items-center bg-white h-[100%] font-InterRegular tracking-wide text-[16px] font-medium'>{d[0]}</p>
                            </div>
                            <ul className='flex justify-start py-[24px] flex-wrap w-[70%]'>
                                {
                                    d[1].map((data, index) => {
                                        return <li key={index} className={`${stickyNotesPalette[d[0]].color} shadow-xl p-[12px] ml-[1.5%] mb-[30px] relative w-[140px] h-[140px]`}>
                                            <p style={{padding:'0'}} className='text-[#111827] break-words font-InterRegular text-[14px] font-medium tracking-wide'>{data}</p>
                                        </li>
                                    })
                                }
                            </ul>
                            {showEditIcon && <span><Button onClick={() => {
                                dispatch(updateSlideNumber({type:"independent",SlideNumber:SlideNumber}));
                                navigate('/product/home/businesscanvas/buildbusinesscanvas');
                            }} className='p-0 pl-[5px] border-none pb-[5px]'><ModeEditOutlinedIcon className='text-primary' /></Button></span>}
                        </div>
                    </div>
                })
            }
        </div>
    )
}

export default StickyNoteCompo