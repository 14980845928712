import React, { useEffect } from 'react';
import { message } from 'antd';
const MessageNotification = ({ type, content, status }) => {
  console.log("text")
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    const message = () => {
      messageApi.open({
        type,
        content
      });
    };
    if(status==="failed"){
      message();
    }
  }, [status])
  return (
    <React.Fragment>
      {contextHolder}
    </React.Fragment>
  );
};
export default MessageNotification;