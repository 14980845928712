import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TitleDescription from '../subcomponents/TitleDescription'
import { convertDSIntoText } from '../helper/convertDSIntoText';

const ListTitleDescription = ({ list }) => {
    const navigate = useNavigate();
    const { topicid, catgid } = useParams() || {};

    return (
        <div className='border border-[#bcbcbd] border-t-[4px] border-t-p3 rounded-md px-[24px] divide-y-2'>
            {
                list?.map((qa, index) => {
                    return (
                        <TitleDescription
                            key={index}
                            title={qa?.title}
                            description={convertDSIntoText(qa?.answer)}
                            className="py-[40px]"
                            isClampText={true}
                            handleOnClick={() => navigate(`/product/home/help-guide/${topicid}/${catgid}/${qa._id}`)}
                        />
                    )
                })
            }
        </div>
    )
}

export default ListTitleDescription