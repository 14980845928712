import React from 'react'
import OopsEmoji from "../../assets/common/oopsEmoji.svg";
import CustomText from '../TypographyDescriptions/CustomText';
import BaseModal from './BaseModal';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const WarningModal = ({ description1, description2, description3, cancelText, successText, handleOnClick1, handleOnClick2, isOpen, handleOnCloseModal }) => {
    return (
        <React.Fragment>
            <BaseModal isOpen={isOpen} isShowCloseButton={true} handleCloseModal={handleOnCloseModal}>
                <div className='flex flex-col gap-y-0 justify-center items-center'>
                    <img src={OopsEmoji} alt="" className='mb-[24px]' />
                    <div className='text-center'>
                        <CustomText display={"block"} color='#372EE5' text={description1} fontWeight={400} fontSize={15} fontVarient={"Inter"} />
                        <CustomText display={"block"} color='#372EE5' text={description2} fontWeight={400} fontSize={15} fontVarient={"Inter"} />
                        <CustomText display={"block"} color='#372EE5' text={description3} fontWeight={400} fontSize={15} fontVarient={"Inter"} />
                    </div>
                    <div className='mt-[24px] flex justify-between w-full'>
                        <SecondaryButton text={cancelText} size='small' onClick={handleOnClick1} />
                        <PrimaryButton text={successText} size='small' onClick={handleOnClick2} />
                    </div>
                </div>
            </BaseModal>
        </React.Fragment>
    )
}

export default WarningModal