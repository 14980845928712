import bmLocals from "../../../../localization/localization_en_business_modal.json"

const my_report = bmLocals.business_modal.my_reports.tableTitle;

export const convertValueToFixed = ({ value, decimal_place }) => value===null||value===undefined||isNaN(value) ? null : value.toFixed(decimal_place)

const seggregateTotalValueRow = (data, decimalPlace) => {
    let totalValueRow = {};
    data.map((d) => {
        totalValueRow = { ...totalValueRow, [`${d.month}-${d.year}`]: convertValueToFixed({ "value": d.value, "decimal_place": decimalPlace }) }
    })
    return totalValueRow;
}
const seggregateNestedRowValue = (data, decimalPlace) => {
    let nestedArrayRow = {};
    data.map((d) => {
        d?.breakdown?.map((nestedD) => {
            if (typeof nestedD !== "object") return;
            const keyName = nestedD.title;
            const nestedValue = nestedD.value;
            nestedArrayRow = nestedArrayRow[keyName] === undefined
                ? { ...nestedArrayRow, [keyName]: { [`${d.month}-${d.year}`]: convertValueToFixed({ "value": nestedValue, "decimal_place": decimalPlace }) } }
                : { ...nestedArrayRow, [keyName]: { ...nestedArrayRow[keyName], [`${d.month}-${d.year}`]: convertValueToFixed({ "value": nestedValue, "decimal_place": decimalPlace }) } }
        })
    })
    return nestedArrayRow;
}


export const formatTableData = ({ metricType, data, decimalPlace }) => {
    if (metricType === "Marketing Cost") {
        let totalRow = seggregateTotalValueRow(data, decimalPlace);
        let nestedArrayRow = seggregateNestedRowValue(data, decimalPlace);
        let children = [];
        for (let property of Object.entries(nestedArrayRow)) {
            children = [...children, { [my_report["Marketing Cost"].tableName]: property[0], ...property[1] }]
        }
        let finalData = [];
        finalData = [{ [my_report["Marketing Cost"].tableName]: my_report["Marketing Cost"].total_marketing_Cost, ...totalRow, children }]
        console.log(finalData)
        return finalData;
    }
}