import React from 'react'
import RenderOrderUnorderList from './RenderOrderUnorderList';
import RenderTable from './RenderTable';
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium';

const RenderObjectData = ({ data }) => {

    return (
        <div className='flex flex-col gap-y-[0.5rem]'>
            {
                Object.entries(data || {}).map(([key, value], index) => {

                    // ignore rendering
                    if (["list_type", "mixed_list_type"].includes(key)) return;

                    // string
                    if (typeof value === "string") return <TextMedium key={index} fontWeight={400} text={value} />

                    // array of string
                    if (Array.isArray(value) && typeof value[0] === "string") {
                        return (
                            <RenderOrderUnorderList
                                key={index}
                                data={data}
                                value={value}
                            />
                        )
                    }

                    if(key === "table"){
                        return (
                            <RenderTable
                                data={value.data}
                                columns={value.columns}
                            />
                        )
                    }

                    if (typeof value?.[0] === "object") {

                        let ListComponent = data?.list_type ? data?.list_type === "number" ? 'ol' : 'ul' : 'span';
                        // if(data?.mixed_list_type){
                        //     ListComponent = data?.mixed_list_type
                        // }else{
                        //     ListComponent = data?.list_type ? data?.list_type === "number" ? 'ol' : 'ul' : 'span';
                        // }

                        return (
                            <ListComponent
                                key={index}
                                style={{
                                    "paddingLeft": data?.list_type === "none" ? 0 : '1.5rem',
                                    "listStyleType": data?.list_type
                                }}
                                className="flex flex-col gap-y-[0.5rem]"
                            >
                                {
                                    value.map((value, index) => {
                                        return (
                                            <li key={index}>
                                                {/* <div className='bg-red-500'>{data?.list_type}</div> */}
                                                <RenderObjectData key={index} data={value} />
                                            </li>
                                        )
                                    })
                                }
                            </ListComponent>
                        )
                    }

                })
            }
        </div>
    )
}

export default RenderObjectData