import React from 'react'
import HeadingFive from '../../DesignSystem/TypographyHeadings/HeadingFive';
import PredictGrowthLogo from './PredictGrowthLogo';
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import { socialMediaIcons } from '../../assets/website';
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium';
import { useNavigate } from 'react-router-dom';
import Privacy_Policy from "../../assets/main_assets/Privacy_Policy.pdf"
import Terms_and_Conditions from "../../assets/main_assets/Terms_and_Conditions.pdf"

const PGFooter = ({ locals }) => {

    const { links, follow_us, copy_right, reg_address } = locals;
    const navigate = useNavigate();

    return (
        <div className='bg-maindarkShade1 xl:px-[5rem] px-[20px]'>

            {/* link components */}
            <div className='grid lg:grid-cols-5 py-[40px] gap-y-[16px]'>
                {
                    links?.map((linkSection, index) => {
                        return (
                            <>
                                <ul key={index} className='flex flex-col gap-y-[30px]'>
                                    {
                                        linkSection.isLogo
                                            ? (
                                                <PredictGrowthLogo fill='#FFFFFF' />
                                            ) : <HeadingFive text={linkSection.title} color={"#FFFFFF"} />
                                    }
                                    <li className='flex flex-col gap-y-[24px]'>
                                        {
                                            linkSection?.link_list?.map((link) => {
                                                if (link.credentials) {
                                                    return (
                                                        <>s</>
                                                    )
                                                }
                                                return (
                                                    <button onClick={() => navigate(link.href)} className='flex'><TextLarge fontWeight={400} text={link.label} color={"#FFFFFF"} /></button>
                                                )
                                            })
                                        }
                                    </li>
                                </ul>
                                {index < links.length - 1 ? <hr className='lg:hidden block' /> : null}
                            </>
                        )
                    })
                }
            </div>

            <hr />

            {/* social media links */}
            <div className='flex items-center justify-center gap-x-[40px] py-[40px]'>
                <HeadingFive text={follow_us.label} color={"#FFFFFF"} />
                <ul className='flex gap-x-[40px]'>
                    {
                        follow_us?.social_media_links?.map((smLink, index) => {
                            return <a href={smLink.href} target="_blank" rel="noopener noreferrer">
                                <img key={index} className='w-[32px] aspect-square' src={socialMediaIcons?.[smLink?.image_ref]} alt="" />
                            </a>
                        })
                    }
                </ul>
            </div>
            <hr />
            <div className='flex gap-x-[40px] py-[40px]'>
                <div>
                    <HeadingFive text={reg_address.label} color={"#FFFFFF"} />
                    <div>
                        <TextMedium fontWeight={400} text={reg_address.addr_line_0} color='#FFFFFF' />
                    </div>
                    <div>
                        <TextMedium fontWeight={400} text={reg_address.addr_line_1} color='#FFFFFF' />
                    </div>
                    <div>
                        <TextMedium fontWeight={400} text={reg_address.addr_line_2} color='#FFFFFF' />
                    </div>
                    <div>
                        <TextMedium fontWeight={400} text={reg_address.addr_line_3} color='#FFFFFF' />
                    </div>
                    <div>
                        <TextMedium fontWeight={400} text={reg_address.addr_line_4} color='#FFFFFF' />
                    </div>
                    <div>
                        <TextMedium fontWeight={400} text={reg_address.addr_line_5} color='#FFFFFF' />
                    </div>
                </div>
            </div>

            {/* copyrights detail */}
            <div className='flex md:flex-row flex-col gap-y-[24px] justify-between py-[12px]'>
                <TextMedium fontWeight={400} text={copy_right.copy_right_text} color='#FFFFFF' />
                <div className='flex gap-x-[24px]'>
                    <button onClick={() => navigate('/refund-policy')}><TextMedium fontWeight={400} text={copy_right.refund_policy} color='#FFFFFF' /></button>
                    <button onClick={() => navigate('/privacy-policy')}><TextMedium fontWeight={400} text={copy_right.privacy_policy} color='#FFFFFF' /></button>
                    <button onClick={() => navigate('/terms-and-conditions')}><TextMedium fontWeight={400} text={copy_right.terms_condiion} color='#FFFFFF' /></button>
                </div>
            </div>

        </div>
    )
}

export default PGFooter