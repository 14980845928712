const HomeSVG = ({ color="#fff",...props }) => (
    <svg
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g>
            <path
                fill={color}
                d="m12 5.69 5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5ZM12 3 2 12h3v8h6v-6h2v6h6v-8h3L12 3Z"
            />
        </g>
    </svg>
)
export default HomeSVG
