import React, { useState } from 'react';
import { Button, Modal, Rate } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { updateOptimalSolutionModalToggle } from '../../features/psmSlice';
import './psm.css'
import psmLocals from "../../localization/localization_en_problem_solution_matrix.json"
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';

const OptimalSolution = () => {
    const dispatch = useDispatch();
    const modalOpen = useSelector((state) => state.psm.optimalSolution.modelOpen)
    const { solutionDescription, optimalSolutionRating } = useSelector((state) => state.psm.optimalSolution);
    const top4Solution = Object.entries(optimalSolutionRating.feasibilityRatings).sort((a, b) => b[1] - a[1]).slice(0, 4)
    const { text1, text2, text3, text4 } = psmLocals.problem_solution_matrix.tooltip_modal_description.optimal_solution.description || {}
    console.log(optimalSolutionRating)
    return (
        <Modal
            title=""
            centered
            open={modalOpen}
            width={800}
        >
            <div className='mt-5 flex flex-col mb-[2rem]'>
                <span className='italic text-center font-InterRegular text-[16px] text-[#909090]'>{text1}</span>
                <span className='italic text-center font-InterRegular text-[16px] text-[#909090]'>{text2}</span>
                <span className='italic text-center font-InterRegular text-[16px] text-[#909090]'>{text3}</span>
                <span className='italic text-center font-InterRegular text-[16px] text-[#909090]'>{text4}</span>
            </div>
            <div className='flex justify-center mb-[2rem] min-h-[238px]'>
                <div className='w-[100%] py-[2rem] px-[2rem] flex flex-col items-center justify-center border rounded-[12px] shadow-xl'>
                    <p className='text-primary font-DMSans text-[24px] text-center'>"{solutionDescription}"</p>
                    <div className='flex justify-between bg-[#f5f5f5] w-full p-[24px] rounded-lg border-[#dcdcdc] border'>
                        {
                            top4Solution.map((metric, index) => {
                                return (
                                    <>
                                    <div className='flex flex-col gap-y-[0.5rem]'>
                                        <TextLarge fontWeight={500} text={psmLocals.problem_solution_matrix.rate_feasibility_parameter.metricTitleMapping[metric[0]]} />
                                        <Rate value={metric[1]} disabled />
                                    </div>
                                    <div className='h-full w-[1px] border border-[#dcdcdc]' style={{ display: index===top4Solution.length-1 ? "none": "block" }}></div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='flex justify-center'>
                <Button className='bg-primary text-white py-2 h-auto px-4 text-[16px]' onClick={() => dispatch(updateOptimalSolutionModalToggle())}>{psmLocals.problem_solution_matrix.tooltip_modal_description.optimal_solution.exit_modal_btn_text}</Button>
            </div>
        </Modal>
    )
}

export default OptimalSolution