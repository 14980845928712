export const TagDataFormat = (d) => {
    return { id: d._id,label: d.title, value: d.title, isDefault: d.status==="default" }
}

export const DropdownDataPreparation = (data) => {
    return data.map((d) => (TagDataFormat(d)))
}

export const PUT_Category_Title = ({ list, payload }) => {
    return list.map((tag) => payload?._id===tag.id ? { ...tag, "title": payload?.title } : tag)
}

export const DropdownGtmDataPreparation = (data) => {
    return data.map((d) => {
        return (
            {
                label: d.title,
                value: d._id,
                isDefault: d.status==="default",
                funnel_stage: d?.funnel_stage
            }
        )
    })
}