import React from 'react'
import TextExtraLarge from '../../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge'
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton'
import ResponsiveHeadingFourInter from '../../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter'

const GuideQuestionListOfSubQuestion = ({ moduleIcon, heading, description, subQuestionIcon, listOfSubQuestions, secondaryButtonName, handleOnClick }) => {
    return (
        <div onClick={handleOnClick} className='flex gap-x-[8px] justify-start p-[24px] rounded-[8px] border border-[#bcbcbd] border-t-[4px] border-t-p3 shadow-md cursor-pointer'>
            <img src={moduleIcon} className='w-[40px] aspect-square mb-auto' alt="" />
            <div className='flex flex-col gap-y-[24px]'>
                <div className='flex flex-col gap-y-[8px]'>
                    <ResponsiveHeadingFourInter text={heading} />
                    <TextExtraLarge fontWeight={400} text={description} color='#757575' />
                </div>
                <div className='flex flex-col gap-y-[12px]'>
                    {
                        listOfSubQuestions?.map((question, index) => {
                            return (
                                <div key={index} className='flex justify-start gap-x-[8px]'>
                                    <img src={subQuestionIcon} alt="" />
                                    <TextLarge text={question} color='#616161' fontWeight={500} />
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    <SecondaryButton text={secondaryButtonName} />
                </div>
            </div>
        </div>
    )
}

export default GuideQuestionListOfSubQuestion