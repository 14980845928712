import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setBearerToken } from '../services/api/api';
import { createUserEndpoint, verifyEmailEndpoint, verifyResetPasswordLinkEndpoint, signInEndpoint, resendVerifyEmailEndpoint, createUserNameEndpoint, dashboardProductUsageProgressEndpoint } from '../services/endPoints/userAPI';
import { generateUrlEndPoint } from '../utils/endPointsGeneration';
import routesConfig from "../services/config/routesConfig.json"

import api from '../services/api/api'
import { validateEmail } from '../utils/validation';

export const getPing = createAsyncThunk(
  'ping', async () => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'ping', 'main');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator }
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const createUser = createAsyncThunk(
  'user/createUser', async (userdata) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'createAccount');
      if (!!urlGenerator) {
        const urlConfig = { data: userdata, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  'user/verifyEmail', async (emailToken) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'verifyEmail');
      if (!!urlGenerator) {
        const urlConfig = { data: emailToken, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response?.data;
      }
    }
    catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const verifyResetPasswordLink = createAsyncThunk(
  'user/verifyResetPasswordLink', async (newCredential) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'verifyResetPassword');
      if (!!urlGenerator) {
        const urlConfig = { data: newCredential, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response?.data;
      }
    }
    catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const signIn = createAsyncThunk(
  'user/signIn', async (userdata) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'login');
      if (!!urlGenerator) {
        const urlConfig = { data: userdata, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      console.log(error, "error");
      throw new Error('Login Failed');
    }
  }
);

export const resendVerifyEmail = createAsyncThunk(
  'user/resendverifyemaillink', async (email) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'resendVerifyAccount');
      if (!!urlGenerator) {
        const urlConfig = { data: email, ...urlGenerator }
        const response = await api.request(urlConfig);
        console.log(response);
        return response?.data;
      }
    }
    catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const createUserName = createAsyncThunk(
  "user/createUserName",
  async (_, { getState, dispatch }) => {
    const state = getState();
    setBearerToken(state.user.value.AccessToken);
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'invitedUsername');
      if (!!urlGenerator) {
        const urlConfig = { data: { name: state.user.value.username }, ...urlGenerator }
        const response = await api.request(urlConfig);
        console.log(response);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const dashboardProductUsageProgress = createAsyncThunk(
  "user/dashboardProductUsageProgress",
  async (_, { getState, dispatch }) => {
    const state = getState();
    setBearerToken(state.user.value.AccessToken);
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'dashboardStepper');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator }
        const response = await api.request(urlConfig);
        console.log(response);
        return response?.data;
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const getSubscriptionDetail = createAsyncThunk(
  'setting/getSubscriptionDetail',
  async (data) => {
      try {
          const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'stripe', 'get-subscription-details');
          if (!!urlGenerator) {
              const urlConfig = { ...urlGenerator, data }
              const response = await api.request(urlConfig);
              return response?.data;
          }
      } catch (error) {
          throw new Error(error.response.data.errMsg);
      }
  }
);

export const fetchUserAfterOnboarding = createAsyncThunk(
  'user/fetchUserAfterOnboarding',
  async (data) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'inviteUserAfterOnboarding');
          if (!!urlGenerator) {
              const urlConfig = { ...urlGenerator, data }
              const response = await api.request(urlConfig);
              return response?.data;
          }
    }catch(error){
      throw new Error(error.response.data.errMsg);
    }
  }
)

export const fetchCurrentUserPermission = createAsyncThunk(
  'user/fetchCurrentUserPermission',
  async (data) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'fetchActiveUserPermission');
          if (!!urlGenerator) {
              const urlConfig = { ...urlGenerator, data }
              const response = await api.request(urlConfig);
              return response?.data;
          }
    }catch(error){
      throw new Error(error.response.data.errMsg);
    }
  }
)

const initialState = {
  value: { id: '', username: '',avatar:'', email: '', AccessToken: '', isAdmin: null },
  permission: {},
  createacc:{
    username:'',
    email:'',
    countryCode: 'IN',
    phoneNumber: '',
    companyName: '',
    password:'',
    nsrcel_cohort_name: '',
    catalystaic_cohort_name: '',
    appsumo_cohort_name: '',
    isEmailValid:false
  },
  isTeamMemberInvited: false,
  subscriptionStatus: false,
  planStatus: 'expired',
  status: 'idle',// 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  emailVerified: false,
  redirectToAskNamePage: false,
  stepperProductUsage: {},
  subscriptionStarted:false,
  freetrialexpireDetails:null,
  resetPasswordStatus:'',
  isOnboardingComplete:false,
  subscriptionDetails: { "planStatus": "expired" },
  "isPaymentMadeOnce": false,
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    updateTeamMemberInvite(state, action){
      state.isTeamMemberInvited = true;
    },
    // getSubscriptionDetail(state, action){
    //   state.freetrialexpireDetails = action.payload.value.planDetails;
    // },
    updateUserDetailsFromSetting(state,action){
      state.value = {...state.value,...action.payload};
    },
    user: (state, action) => {
      state.value = action.payload;
    },
    updateErr: (state) => {
      state.status = "idle";
      state.error = null;
    },
    updateAccessToken: (state, action) => {
      state.value.AccessToken = action.payload;
    },
    updateUserName: (state, action) => {
      state.value.username = action.payload.name;
    },
    updateStatus(state){
      state.status = 'idle';
    },
    updateCreateAccCred(state,action){
      const { key, value } = action.payload;
      state.createacc = {...state.createacc,[key]:value}
      if(key==="email"){
        const check = validateEmail(state.createacc.email);
        state.createacc = {...state.createacc,["isEmailValid"]:check}
      }
    },
    resetStatus(state){
      state.status = 'idle',
      state.error = null
    },
    updateOnboardingComplete(state,action){
      state.isOnboardingComplete = action.payload;
    },
    resetPasswordState(state, action){
      state.resetPasswordStatus = 'idle'
    },
    DUpdatePaymentStatus(state, action){
      state.subscriptionDetails = {
        ...state.subscriptionDetails,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.value.email = action.payload.email;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(signIn.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isOnboardingComplete = action.payload.value.isOnboardingCompleted===3
        if (action.payload.value.username === undefined) {
          state.redirectToAskNamePage = true;
        }
        state.value = action.payload.value;
        state.permission = action.payload.permission;
        state.emailVerified = action.payload.value.isVerified;
        state.freetrialexpireDetails = action.payload.value.planDetails;
        state.planStatus = action.payload.value.planStatus;
        // state.planStatus = 'free';
        // state.subscriptionStatus = false;
        state.subscriptionDetails = {
          ...state.subscriptionDetails,
          "planStatus": action.payload.value.planStatus
        };
        state.isPaymentMadeOnce = action.payload.value?.isPaymentMadeOnce ?? false;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(verifyEmail.pending, (state) => {
        state.status = "loading";
        state.emailVerified = false;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.status = "success";
        console.log(action.payload, "action.payload");
        if (action.payload.value.isVerified) {
          state.value = action.payload.value;
          state.permission = action.payload.permission;
          state.emailVerified = action.payload.value.isVerified;
          state.freetrialexpireDetails = action.payload.value.planDetails;
          state.planStatus = action.payload.value.planDetails.planStatus;
          state.isPaymentMadeOnce = action.payload.value.isPaymentMadeOnce ?? false;
          console.log(state.planStatus);
        } else {
          state.emailVerified = false;
        }
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.emailVerified = false;
      })
      .addCase(dashboardProductUsageProgress.fulfilled, (state, action) => {
        state.stepperProductUsage = action.payload.productTrackUsage;
      })
      .addCase(createUserName.fulfilled, (state,action) => {
        state.redirectToAskNamePage = false;
      })
      .addCase(verifyResetPasswordLink.pending, (state) => {
        state.resetPasswordStatus = 'loading';
      })
      .addCase(verifyResetPasswordLink.fulfilled, (state, action) => {
        state.resetPasswordStatus = 'succeeded';
      })
      .addCase(verifyResetPasswordLink.rejected, (state, action) => {
        state.resetPasswordStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(getSubscriptionDetail.pending, (state, action) => {
        state.status = 'pending';
    })
    .addCase(getSubscriptionDetail.fulfilled, (state, action) => {
        state.status = 'success';
        // state.subscriptionDetails = action.payload.subscriptionDetails;
        // const { subscriptionStatus, planStatus, planDetails } = action.payload.subscriptionDetails;
        // state.subscriptionStatus = subscriptionStatus;
        // state.planStatus = planStatus;
        // state.freetrialexpireDetails = planDetails;
    })
    .addCase(getSubscriptionDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
    })
    .addCase(fetchCurrentUserPermission.fulfilled, (state, action) => {
      state.permission = action.payload.permission;
    })
  }
})

export const userInfo = (state) => state.user.value;
export const userInfoStatus = (state) => state.user.status;
export const userInfoError = (state) => state.user.error;

export const userEmailVerificationStatus = (state) => state.user.emailVerified;

export const { DUpdatePaymentStatus, resetPasswordState, updateUserDetailsFromSetting, resetStatus, updateCreateAccCred, updateStatus, user, updateErr, updateAccessToken, updateUserName, updateOnboardingComplete, updateTeamMemberInvite } = userSlice.actions
export default userSlice.reducer