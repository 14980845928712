import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, DatePicker, Switch, Row, Col, Modal, Spin, message, ConfigProvider } from 'antd';
import CloseButton from "../../SVG/CloseButtonSVG";
import ModalSection from "./ModalSection";
import moment from "moment";
import { addInvestorApi, createStageOfFunding, getStageOfFundingList, getDeafultStageOfFundingList } from "../../../features/dataRoomSlice";
import { useDispatch } from "react-redux";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";

const { Option } = Select;

const EditInvestorModal = ({ isOpen, onClose, documentList, onSecondaryClose }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [customModalOpen, setCustomModalOpen] = useState(false); // State for custom modal
    const [successModal, setSuccessModal] = useState(false);
    const [inputTitle, setInputTitle] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [loadingStages, setLoadingStages] = useState(false); // State for loader
    const [stageOfFunding, setStageOfFunding] = useState([]);
    const [loading, setLoading] = useState(false)
    const [reClick, setReClick] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchStageOfFundingApis = async () => {
            if (modalOpen) {
                setLoadingStages(true);
                try {
                    const response = await dispatch(getDeafultStageOfFundingList());
                    const response2 = await dispatch(getStageOfFundingList());
                    const mergedStages = [...response?.payload, ...response2?.payload];
                    setStageOfFunding(mergedStages);
                } finally {
                    setLoadingStages(false);
                }
            }
        }
        fetchStageOfFundingApis();
    }, [modalOpen, dispatch, reClick]);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };

    const handleCancel = () => {
        setModalOpen(false);
        onSecondaryClose();
    };

    const handleSwitchChange = (checked) => {
        setIsChecked(checked);
    };

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true)
        const body = {
            email: values.emailAddress,
            investorName: values.investorName,
            investmentAmount: values.investmentAmount,
            stageOfFunding: selectedStage ? selectedStage : values.stageOfFunding,
            investorAccessExpiry: values.accessExpiry.format('YYYY-MM-DD'),
            canAddTeamMember: values.canAddTeamMembers,
            accessForTeam: values.accessForTeam,
            accessForInvestor: values.accessForInvestor,
        };
        console.log(body)
        if (body.email === undefined || body.email === "" || body.investorName === undefined || body.investorName === "" || body.investmentAmount === undefined || body.stageOfFunding === undefined || body.accessForInvestor === undefined || body.accessForTeam === undefined || body.investmentAmount === "") {
            message.error("Please fill all the fields");
            setLoading(false)
            return
        }
        dispatch(addInvestorApi(body))
            .then((response) => {
                if (response.error.message) {
                    message.error(response.error.message);
                }
                setSuccessModal(true);
                setModalOpen(false);
                setLoading(false);
                handleOk();
            })
            .catch((error) => {
                message.error("Failed to add investor");
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleCustomOptionChange = (value) => {
        if (value === 'custom') {
            setCustomModalOpen(true);
        } else {
            setSelectedStage(value);
        }
    };

    const handleCustomStageSubmit = () => {
        setLoading(true)
        // const body = { stageOfFundingTitle: inputTitle };
        // dispatch(createStageOfFunding(body))
        //     .then(() => {
        //         setCustomModalOpen(false);
        //         setSelectedStage(inputTitle);
        //         form.setFieldsValue({ stageOfFunding: inputTitle });
        //     })
        //     .catch((error) => {
        //         console.error('Failed to create custom stage:', error);
        //     });
        // setLoading(false)
        // setReClick(!reClick)
    };

    return (
        <>
            {successModal && (
                <ModalSection title={"Info"} text="Investor has been successfully added" closeHandler={() => { setSuccessModal(false); setModalOpen(false) }} />
            )}
            <Modal
                title={<span style={{ fontSize: "20px" }}>Investor Details</span>}
                centered
                open={modalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <button
                    className="absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all"
                    onClick={handleCancel}
                >
                    <CloseButton />
                </button>
                <div className="max-w-lg mx-auto bg-white rounded-lg">
                    <Form
                        form={form}
                        layout="vertical"
                        name="investorForm"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            investorName: documentList?.username,
                            emailAddress: documentList?.email,
                            investmentAmount: documentList?.investmentAmount,
                            stageOfFunding: documentList?.stageOfFunding,
                            accessExpiry: moment(documentList?.investorAccessExpiry),
                            accessForInvestor: documentList?.accessForInvestors,
                            canAddTeamMembers: documentList?.canAddTeamMember,
                            accessForTeam: documentList?.accessForTeams,
                        }}
                    >
                        <Form.Item
                            label="Investor Name"
                            name="investorName"
                            className="font-medium"
                        >
                            <Input placeholder="Enter Name" className="p-2 border rounded" />
                        </Form.Item>

                        <Form.Item
                            label="Email Address"
                            name="emailAddress"
                            className="font-medium"
                        >
                            <Input placeholder="Enter your work email" className="p-2 border rounded" />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Investment Amount"
                                    name="investmentAmount"
                                    className="font-medium"
                                >
                                    <Input placeholder="0" className="p-2 border rounded" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Stage Of Funding"
                                    name="stageOfFunding"
                                    className="font-medium"
                                >
                                    <Select placeholder="Select" className="w-full" onChange={handleCustomOptionChange}>
                                        {loadingStages ? (
                                            <Option value="loading">
                                                <div role="status">
                                                    <svg aria-hidden="true" class="inline w-10 h-10 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                    </svg>
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </Option>
                                        ) : (
                                            <>
                                                {stageOfFunding?.map((value) => (
                                                    <Option key={value._id} value={value._id}>{value.title}</Option>
                                                ))}
                                                <Option value="custom">Add Custom</Option>
                                            </>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Access Expiry"
                                    name="accessExpiry"
                                    className="font-medium"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="DD/MM/YYYY" className="p-1 border rounded" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Access For Investor"
                                    name="accessForInvestor"
                                    className="font-medium"
                                >
                                    <Select placeholder="Select" className="w-full">
                                    <Option value="View Only">View Only</Option>
                                        <Option value="Download Only">Download Only</Option>
                                        <Option value="Print Only">Print Only</Option>
                                        <Option value="View & Download">{"View & Download"}</Option>
                                        <Option value="View & Print">{"View & Print"}</Option>
                                        <Option value="Download and Print">{"Download & Print"}</Option>
                                        <Option value="View & Download">{"View, Download & Print"}</Option>
                                        <Option value="No Access">{"No Access"}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Can Add Team Members" name="canAddTeamMembers" valuePropName="checked" className="font-medium flex flex-row">
                                    <div className="flex flex-row items-center gap-2">
                                        <div className={`flex items-center justify-center font-semibold ${isChecked ? "text-[#9E9E9E]" : "text-[#3a2ad7]"}`}>No</div>
                                        <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: "#fff"
                                            },
                                            components: {
                                            Switch: {
                                               handleBg: "#fff"
                                            },
                                            },
                                        }}
                                        >
                                            <Switch checked={isChecked} onChange={handleSwitchChange}/>
                                        </ConfigProvider>
                                        <div className={`flex items-center justify-center font-semibold ${!isChecked ? "text-[#9E9E9E]" : "text-[#3a2ad7]"}`}>Yes</div>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Access For Team"
                                    name="accessForTeam"
                                    className="font-medium"
                                >
                                    <Select placeholder="Select" className="w-full">
                                        <Option value="View Only">View Only</Option>
                                        <Option value="Download Only">Download Only</Option>
                                        <Option value="Print Only">Print Only</Option>
                                        <Option value="View & Download">{"View & Download"}</Option>
                                        <Option value="View & Print">{"View & Print"}</Option>
                                        <Option value="Download and Print">{"Download & Print"}</Option>
                                        <Option value="View & Download">{"View, Download & Print"}</Option>
                                        <Option value="No Access">{"No Access"}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="flex w-[100%] justify-between flex-row">
                            <Button type="default" htmlType="button" disabled={loading} onClick={handleCancel} className="border-[#9E9E9E] rounded-md text-[#9E9E9E] font-semibold">
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading} className="bg-[#372EE5] text-white font-semibold rounded-md">
                                Add
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>

            <Modal
                title="Add Custom Stage of Funding"
                centered
                open={customModalOpen}
                onOk={handleCustomStageSubmit}
                onCancel={handleCancel}
            >
                <Form
                    layout="vertical"
                    name="customStageForm"
                    onFinish={handleCustomStageSubmit}
                >
                    <Form.Item
                        label="Custom Stage Title"
                        name="customStage"
                        rules={[{ required: true, message: 'Please enter a custom stage title!' }]}
                    >
                        <Input
                            placeholder="Enter custom stage title"
                            onChange={(e) => setInputTitle(e.target.value)}
                        />
                    </Form.Item>
                </Form>
                <div className="flex justify-between items-center">
                    <SecondaryButton size="Medium" text={"Cancel"} onClick={handleCancel} isDisabled={loading}/>
                    <PrimaryButton size="Medium" text={"Add"} onClick={handleCustomStageSubmit} isLoading={loading} isDisabled={loading}/>
                </div>
            </Modal>
        </>
    );
};

export default EditInvestorModal;

