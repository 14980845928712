import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import routesConfig from "../../services/config/routesConfig.json";
import api from "../../services/api/api";
import { v4 as uuidv4 } from "uuid";
import working_capital_locals from "../../localization/localization_en_pAndL_workCap_debtWork_cashFlow_balanceSheet.json";
import { filterDuplicateObjectProp } from "../../utils/filterDuplicateObjectProp";

const INITIAL_STATE = {
    "output": null,
    "product_category": [],
    "fiscalStartYear": null,
    "monthYearValues": [],
    "CHANNEL_SALES": [],
    "product_category_details": [],
    "isSaveDisabled": false,
    "isGenerateOutputDisabled": true,
    "save_process_api_status": { "status": "idle", counter: 0, message: null },
    "annual_inventory_days_product_category": [],
    "net_payment_term_customer": working_capital_locals.working_capital.form.data[1].table.default_rows,
    "percent_revenue_other_current_assets": null,
    "net_payment_term_vendor": null,
    "percent_payroll_short_long_term_probation": null,
    "categoryList": []
}

export const API_Save_WorkingCapital_Data = createAsyncThunk(
    "workingCapital/API_Save_WorkingCapital_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-working-capital');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Process_WorkingCapital_Data = createAsyncThunk(
    "workingCapital/API_Process_WorkingCapital_Data",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'process-business-model-working-capital');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const WorkingCapitalSlice = createSlice({
    name: "working-capital",
    initialState: {
        "workingCapStore": INITIAL_STATE
    },
    reducers: {
        D_ShowSaveActiveButton(state, action) {
            if (state.workingCapStore.isSaveDisabled) {
                state.workingCapStore.isSaveDisabled = false;
                state.workingCapStore.isGenerateOutputDisabled = true;
            }
        },
        D_Reset_ErrorMessage(state, action) {
            state.workingCapStore.save_process_api_status = { "status": "idle", counter: 0, message: null }
        },
        D_Update_Net_Payment_term_Data_Based_RO_Screen1(state, action) {
            if (state.workingCapStore.isSaveDisabled) {
                state.workingCapStore.isSaveDisabled = false;
                state.workingCapStore.isGenerateOutputDisabled = true;
            }
            const { CHANNELS } = action.payload;
            let targetData = state.workingCapStore.net_payment_term_customer;
            targetData.filter((data) => CHANNELS.includes(data.channel_name))
            working_capital_locals.working_capital.form.data[1].table.default_rows.map((defaultRow) => {
                if (CHANNELS.includes(defaultRow.channel_name)) {
                    if (targetData.find((oldData) => oldData.channel_name === defaultRow.channel_name) === undefined) {
                        targetData = [...targetData, defaultRow]
                    }
                }
            })
            state.workingCapStore.net_payment_term_customer = targetData;
        },
        D_Capture_Value_WorkingCapital(state, action) {
            if (state.workingCapStore.isSaveDisabled) {
                state.workingCapStore.isSaveDisabled = false;
                state.workingCapStore.isGenerateOutputDisabled = true;
            }
            const { name, tableName, value, rowNumber, data_index } = action.payload;
            if(state.workingCapStore.output){
                state.workingCapStore.output = null;
            }
            state.workingCapStore[tableName] = state.workingCapStore[tableName].map((row, index) => index === rowNumber ? { ...row, [data_index]: isNaN(value) ? value : value === null ? null : Number(value) } : row);
        },
        D_Capture_Normal_Input_Value_WorkingCapital(state, action) {
            if(state.workingCapStore.output){
                state.workingCapStore.output = null;
            }
            if (state.workingCapStore.isSaveDisabled) {
                state.workingCapStore.isSaveDisabled = false;
                state.workingCapStore.isGenerateOutputDisabled = true;
            }
            if (action.payload.name in state.workingCapStore) state.workingCapStore[action.payload.name] = action.payload.value;
        },
        D_Update_Screen_Screen_WorkingCap(state, action) {
            const SCREEN_DATA = action.payload.screenData
            state.workingCapStore.product_category = SCREEN_DATA?.productCategoryList?.map((catg) => ({ label: catg.title, value: catg._id }))
            state.workingCapStore.product_category = filterDuplicateObjectProp({ list: state.workingCapStore.product_category, targetProp: "value" })
            state.workingCapStore.CHANNEL_SALES = SCREEN_DATA?.revenueScreenOneInput.channels_of_sales
            if (SCREEN_DATA?.input) {
                const {
                    annual_inventory_days_product_category,
                    net_payment_term_customer,
                    percent_revenue_other_current_assets,
                    net_payment_term_vendor,
                    percent_payroll_short_long_term_probation
                } = SCREEN_DATA?.input
                state.workingCapStore.annual_inventory_days_product_category = annual_inventory_days_product_category.map((catg) => {
                    const labelId = state.workingCapStore.product_category.find((tag) => tag.label === catg.name)?.value
                    let yearObj = {}
                    catg["annual_inventory_days_product_category"].map((yearVal, index) => {
                        yearObj = { ...yearObj, [`year${index+1}`]: yearVal.value }
                    })
                    return ({ "dbid": catg.dbid, "name": catg.name, ...yearObj })
                })
                state.workingCapStore.net_payment_term_customer = net_payment_term_customer
                state.workingCapStore.percent_revenue_other_current_assets = percent_revenue_other_current_assets
                state.workingCapStore.net_payment_term_vendor = net_payment_term_vendor
                state.workingCapStore.percent_payroll_short_long_term_probation = percent_payroll_short_long_term_probation
            }else{
                const bunchofYear = { "year1": null, "year2": null, "year3": null, "year4": null, "year5":null}
                state.workingCapStore.annual_inventory_days_product_category = SCREEN_DATA.revenueScreenTwoInput.product_categories_details.map((pc) => {
                    return {"dbid": pc.dbid, "name": pc.title, ...bunchofYear}
                })
            }
            state.workingCapStore.monthYearValues = SCREEN_DATA.monthYearValues;
            state.workingCapStore.net_payment_term_customer = state.workingCapStore.net_payment_term_customer.filter((row) => state.workingCapStore.CHANNEL_SALES.includes(row.channel_name))
            state.workingCapStore.fiscalStartYear = SCREEN_DATA.revenueScreenTwoInput.first_year
            state.workingCapStore.output = SCREEN_DATA?.output ? SCREEN_DATA?.output : null;
            state.workingCapStore.isSaveDisabled = state.workingCapStore.output ? true : false
            state.workingCapStore.isGenerateOutputDisabled = state.workingCapStore.output ? true : false
            state.workingCapStore.categoryList = [
                ...SCREEN_DATA?.productCategoryCombined?.map((catg) => ({ label: catg.title, value: catg._id })),
                ...SCREEN_DATA?.marketplaceChannelListCombined?.map((catg) => ({ label: catg.title, value: catg._id })),
            ]
        },
        D_Reset_WorkingCapital_State(state) {
            state.workingCapStore = INITIAL_STATE;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_Save_WorkingCapital_Data.pending, (state) => {
                state.workingCapStore.save_process_api_status.counter = state.workingCapStore.save_process_api_status.counter + 1
                state.workingCapStore.save_process_api_status.status = 'loading';
            })
            .addCase(API_Save_WorkingCapital_Data.fulfilled, (state) => {
                state.workingCapStore.save_process_api_status.counter = state.workingCapStore.save_process_api_status.counter + 1
                state.workingCapStore.save_process_api_status.status = 'success';
                state.workingCapStore.isSaveDisabled = true;
                state.workingCapStore.isGenerateOutputDisabled = false;
            })
            .addCase(API_Save_WorkingCapital_Data.rejected, (state, action) => {
                state.workingCapStore.save_process_api_status.counter = state.workingCapStore.save_process_api_status.counter + 1
                state.workingCapStore.save_process_api_status.status = 'failed';
                state.workingCapStore.save_process_api_status.message = action.error.message
            })
            .addCase(API_Process_WorkingCapital_Data.pending, (state) => {
                state.workingCapStore.save_process_api_status.counter = state.workingCapStore.save_process_api_status.counter + 1
                state.workingCapStore.save_process_api_status.status = 'loading';
            })
            .addCase(API_Process_WorkingCapital_Data.fulfilled, (state, action) => {
                state.workingCapStore.save_process_api_status.counter = state.workingCapStore.save_process_api_status.counter + 1
                state.workingCapStore.save_process_api_status.status = 'success';
                state.workingCapStore.isGenerateOutputDisabled = true;
                state.workingCapStore.output = action.payload;
            })
            .addCase(API_Process_WorkingCapital_Data.rejected, (state, action) => {
                state.workingCapStore.save_process_api_status.counter = state.workingCapStore.save_process_api_status.counter + 1
                state.workingCapStore.save_process_api_status.status = 'failed';
                state.workingCapStore.save_process_api_status.message = action.error.message
            })
    }
});

export const { D_Update_Screen_Screen_WorkingCap, D_ShowSaveActiveButton, D_Reset_ErrorMessage, D_Reset_WorkingCapital_State, D_Capture_Value_WorkingCapital, D_Capture_Normal_Input_Value_WorkingCapital, D_Update_Net_Payment_term_Data_Based_RO_Screen1 } = WorkingCapitalSlice.actions;
export default WorkingCapitalSlice.reducer;