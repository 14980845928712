import React, { useState } from 'react'
import { message } from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import MyDashbaordCard from '../../DesignSystem/Cards/MyDashbaordCard'
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour'
import myDashboardDefaultLocals from '../../localization/localization_en_business_modal.json'
import './my-dashboard-styles.css';
import MetricChart from './components/MyDashboard/MetricChart'
import { formatDataForDashboard } from './functionality/formatDataForDashboard'
import OopsPopUp from '../reuseablecomponent/PopUpVarients/OopsPopUp'
import { updateItemSelection } from '../../features/manageLinkSlice';
import { totalRevenuePalette, avgOrderValuePalette, marketingCostPalette, cashFlowPalette, totalExpNetIncomePalette } from '../../DesignSystem/Colors/businessModelPalette'
import { API_POST_BUSINESS_DASHBOARD_OVERALL_DATA, D_Reset_Business_Model_Status } from './businessModalSlice'
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton'
import BaseModal from '../../DesignSystem/Modals/BaseModal'
import PageLoader from '../reuseablecomponent/PageLoader'

const COLOR_PALETTE = { totalRevenuePalette, avgOrderValuePalette, marketingCostPalette, cashFlowPalette, totalExpNetIncomePalette }
const MY_DASHBOARD_LOCALS_DEFAULT = myDashboardDefaultLocals.business_modal['my-dashboard'];

const BusinessModelMyDashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [IS_BUSINESS_MODAL_MARKED_AS_STAR_STATUS, setIS_BUSINESS_MODAL_MARKED_AS_STAR_STATUS] = useState(true);
    const IS_MENU_COLLAPSED = useSelector((state) => state.link.collapsed)
    const COMPANY_NAME = useSelector((state) => state.onboarding.value.Company);
    const { fetching_business_modal_dashboard_status, business_modal_dashboard, is_business_model_dashboard_data_exist, metric_sum } = useSelector((state) => state.businessModal);
    const CURRENT_YEAR_NEXT_YEAR = `(FY${new Date().getFullYear().toString().slice(-2) - 1}-${(new Date().getFullYear() + 1).toString().slice(-2) - 1})`;
    const DASHBOARD_DATA = formatDataForDashboard()
    const [modalDetails, setModalDetail] = useState({ isModalOpen: false, modalDetails: {} });
    const { cardMetricData, financialRatio, CurrentYearProjection } = business_modal_dashboard || {}
    React.useEffect(() => {
        dispatch(D_Reset_Business_Model_Status());
        dispatch(API_POST_BUSINESS_DASHBOARD_OVERALL_DATA());
    }, [])
    React.useEffect(() => {
        if (fetching_business_modal_dashboard_status.status === "success") {
            setIS_BUSINESS_MODAL_MARKED_AS_STAR_STATUS(false);
        }
        if (fetching_business_modal_dashboard_status.status === "failed") {
            message.open({ type: 'error', content: fetching_business_modal_dashboard_status.message, duration: 3 });
            dispatch(D_Reset_Business_Model_Status());
            setIS_BUSINESS_MODAL_MARKED_AS_STAR_STATUS(true);
        }
    }, [fetching_business_modal_dashboard_status.status])
    const handleStoreChartData = (data) => {
        setModalDetail((prev) => ({...prev, isModalOpen: true, modalDetails: data}))
    }
    const { data, xAxisLabel, yAxisLabel, colorPalette, year, metricName, chartVarient } = modalDetails?.modalDetails || {}
    console.log(fetching_business_modal_dashboard_status.status, "fetching_business_modal_dashboard_status")
    return (
        <React.Fragment>
            { 
                fetching_business_modal_dashboard_status.status==="loading"
                ? <PageLoader/>
                : null
            }
            {
                modalDetails.isModalOpen
                    ? (
                        <BaseModal modalClassname={'xl:min-w-[1220px] min-w-[85%]'} isOpen={modalDetails.isModalOpen} isShowCloseButton handleCloseModal={() => setModalDetail((prev) => ({...prev, isModalOpen: false, modalDetails: {}}))}>
                            <div className='pt-[1.5rem] min-w-full'>
                            <MetricChart
                                data={data}
                                xAxisLabel={xAxisLabel}
                                yAxisLabel={yAxisLabel}
                                isDataFromModal={true}
                                colorPalette={colorPalette}
                                year={year}
                                metricName={metricName}
                                chartVarient={chartVarient}
                            />
                            </div>
                        </BaseModal>
                    ) : null
            }
            {
                IS_BUSINESS_MODAL_MARKED_AS_STAR_STATUS===false && fetching_business_modal_dashboard_status.status!=="loading"
                    ? <OopsPopUp
                        isOpen={!IS_BUSINESS_MODAL_MARKED_AS_STAR_STATUS===false}
                        onClick={() => {
                            navigate('/product/home/business-modal');
                            dispatch(updateItemSelection({ "activeItem": "2.1" }));
                        }}
                        description1={MY_DASHBOARD_LOCALS_DEFAULT.not_market_business_modal}
                        buttonText={MY_DASHBOARD_LOCALS_DEFAULT.not_market_business_modal_popup_name}
                    />
                    : null
            }
            <div className='mt-[4rem] bg-baseGray200 min-h-[calc(100vh-4rem)] px-[20px]'>
                <HeadingFour text={`${COMPANY_NAME.slice(0, 1).toUpperCase() + COMPANY_NAME.slice(1)} Dashboard`} />
                <ul className='flex gap-[24px] flex-wrap justify-between items-center'>
                    {
                        MY_DASHBOARD_LOCALS_DEFAULT.metrics.map((metric, index) => {
                            return (
                                <MyDashbaordCard
                                    key={index}
                                    index={index}
                                    title={metric.title}
                                    description={is_business_model_dashboard_data_exist ? CurrentYearProjection : CURRENT_YEAR_NEXT_YEAR}
                                    value={is_business_model_dashboard_data_exist ? cardMetricData?.[metric?.data_index] : metric.value}
                                    textColor={index % 2 === 0 ? '#FFFFFF' : '#4B54FF'}
                                    descriptionColor={index % 2 === 0 ? '#E0E0E0' : '#6D7FFF'}
                                    borderColor={index % 2 === 0 ? '#4B54FF' : '#4B54FF'}
                                    backgroundColor={index % 2 === 0 ? '#4B54FF' : '#FFFFFF'}
                                />
                            )
                        })
                    }
                </ul>
                <ul className={`${IS_MENU_COLLAPSED ? 'parent' : 'sub_parent'} my-dash-bm pb-[24px]`}>
                    {
                        MY_DASHBOARD_LOCALS_DEFAULT.component_render_position_template.map((metricData, index) => {
                            const { data, x_axis, y_axis } = MY_DASHBOARD_LOCALS_DEFAULT.chart_default_data[metricData.dataIndex]
                            let finalData = data;
                            if (metricData.dataIndex === "financial_ratio" && is_business_model_dashboard_data_exist) {
                                finalData = data.map((d) => ({ ...d, "value": financialRatio?.[d?.data_index] }));
                            }else if(is_business_model_dashboard_data_exist && business_modal_dashboard && business_modal_dashboard?.chart_data){
                                finalData =  business_modal_dashboard.chart_data[metricData.dataIndex]
                                if(["barChart", "pieChart", "donutChart"].includes(metricData.componentName)){
                                    finalData = finalData.slice(0,5)
                                }
                            }
                            return (
                                <div
                                    key={index}
                                    className="child relative overflow-hidden"
                                > 
                                    <MetricChart
                                        data={finalData}
                                        xAxisLabel={x_axis}
                                        yAxisLabel={y_axis}
                                        handleStoreChartData={handleStoreChartData}
                                        metric_sum={metric_sum}
                                        completeData={{  
                                            data: business_modal_dashboard?.chart_data?.[metricData?.dataIndex],
                                            xAxisLabel: x_axis, 
                                            yAxisLabel: y_axis,
                                            colorPalette: COLOR_PALETTE[metricData.colorPaletteRef],
                                            year: is_business_model_dashboard_data_exist ? CurrentYearProjection : CURRENT_YEAR_NEXT_YEAR,  
                                            metricName: metricData.metricName,
                                            chartVarient: metricData.componentName
                                        }}
                                        isShowPopUpAnalytics={index!==MY_DASHBOARD_LOCALS_DEFAULT.component_render_position_template.length-1}
                                        data_index={metricData.dataIndex}
                                        colorPalette={COLOR_PALETTE[metricData.colorPaletteRef]}
                                        year={is_business_model_dashboard_data_exist ? CurrentYearProjection : CURRENT_YEAR_NEXT_YEAR}
                                        metricName={metricData.metricName}
                                        chartVarient={metricData.componentName}
                                    />
                                </div>
                            )
                        })
                    }
                </ul>
            </div>
        </React.Fragment>
    )
}

export default BusinessModelMyDashboard