import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Progress, Button, Skeleton, Empty, Input } from "antd";
import "../index.css";
import DataRoom from "../localization/data_room/localization_en_data_room.json";
import DataRoomDashboardCard from "../DesignSystem/Cards/DataRoomDashboardCard";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import { listDirectoryApi } from "../features/dataRoomSlice";
import {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    DeleteOutlined,
    ShareAltOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import TextLarge from "../DesignSystem/TypographyDescriptions/TextLarge";
import TextExtraLarge from "../DesignSystem/TypographyDescriptions/TextExtraLarge";
import WidgetsIcon from '@mui/icons-material/Widgets';
import SubjectIcon from '@mui/icons-material/Subject';
import CheckIcon from '@mui/icons-material/Check';
import AntDTable from "../DesignSystem/Table/AntDTable";
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextSmall from "../DesignSystem/TypographyDescriptions/TextSmall";
import ActionMenuFolderCard from "../DesignSystem/Cards/ActionMenuFolderCard";
import ActionMenuInvestorCard from "../DesignSystem/Cards/ActionMenuInvestorCard";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import DeleteSVG from "../DesignSystem/SVG/DeleteSVG";

const IconCheckedComponent = ({ icon, isChecked, onClick }) => {
    return (
        <div 
            onClick={onClick} 
            className={`flex gap-x-[0.2rem] cursor-pointer px-2 ${isChecked ? "text-primary" : "text-black"}`}
        >
            { isChecked && <CheckIcon fontSize="small" /> }
            { icon }
        </div>
    )
}

export default function AdminDataRoom() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const linkInfo = useSelector((state) => state.link);
    const activeItem = useSelector((state) => state.link.activeItem);
    const openKeys = useSelector((state) => state.link.openKeys);
    const username = useSelector((state) => state.user.value.username);
    const [featureData, setFeatureData] = useState({});
    const [flippedCards, setFlippedCards] = useState(
        new Array(featureData["cards"]?.length).fill(false)
    );
    const status = useSelector((state) => state.link.status);
    const progress = useSelector((state) => state.link.progress);
    const onboarding = useSelector((state) => state.onboarding.value.Company);
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [checkedCards, setCheckedCards] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [data, setData] = useState(null);

    const [keysData, setKeysData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [reClick, setReClick] = useState(false);
    const [loading, setLoading] = useState(true);
    const [folderLoader, setFolderLoader] = useState(false);
    const [filteredData, setFilteredData] = useState(keysData);
    const [searchVisible, setSearchVisible] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [secondayLoad, setSecondaryLoad] = useState(false);
    const [shareDisable, setShareDsabled] = useState(true);

    const [isShowDirInFlatView, setIsShowDirInFlatView] = useState(false);

    useEffect(() => {
        setFilteredData(keysData.filter((item) =>
            item.title.toLowerCase().includes(searchInput.toLowerCase())
        ));
    }, [searchInput, keysData]);

    useEffect(() => {
        if(checkedCardDetails.length === 1) {
            setShareDsabled(false);
        } else {
            setShareDsabled(true);
        }
    }, [checkedCardDetails])

    const handleSearchToggle = () => {
        setSearchVisible(!searchVisible);
        if(searchVisible) {
            setSearchInput('')
        }
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(listDirectoryApi());
                const listOfDirectories = response.payload?.listOfDirectories || [];
                const countOfFiles = response.payload?.countOfFiles || [];
                const listOfSharing = response.payload?.listOfSharing || [];
                const listOfArchivedFiles = response.payload?.listOfArchivedFiles || [];
                const listOfArchivedDirectories = response.payload?.listOfArchivedDirectories || [];

                const extractedData = listOfDirectories.map((obj) => {
                    const fileCount = countOfFiles.find(item => item.directoryId === obj._id)?.filesListCount || 0;
                    const sharingDetails = listOfSharing.find(sharingList =>
                        sharingList.some(sharing => sharing.documentId === obj._id)
                    ) || [];

                    return {
                        id: obj._id,
                        title: obj.name,
                        size: obj.size,
                        color: obj.color === "" ? "grey" : obj.color,
                        description: obj.description,
                        createdAt: obj.createdAt,
                        fileCount: fileCount,
                        sharingDetails: sharingDetails,
                        archiveStatus: obj.archiveStatus,
                        lockStatus: obj.lockStatus,
                        s3Response: obj.s3Response,
                        s3Details: obj.s3Details,
                    };
                });
                console.log(extractedData)
                setKeysData(extractedData);
            } catch (error) {
                console.error("Failed to list directory", error);
                setError(error);
            } finally {
                setLoading(false);
                setFolderLoader(false);
                setSecondaryLoad(false);
            }
        };

        fetchData();
    }, [dispatch, isModalVisible, reClick]);

    useEffect(() => {
        setSecondaryLoad(true);
    }, [reClick])

    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card.id !== cardDetails.id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);

    // Modal
    const [modalTitle, setModalTitle] = useState(null);

    const handleButtonClick = () => {
        setModalTitle("Add Folder");
        setFolderLoader(true);
        console.log("updateFolderLoader");
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
        setReClick(!reClick)
    };

    const onDownloadClick = () => {
        setIsModalVisible(true);
        setModalTitle("Info");
        const filteredData = checkedCardDetails.filter((x) => x.title);
        console.log(filteredData);
        setData(checkedCardDetails);
    };

    const handleShareClick = () => {
        setIsModalVisible(true);
        setModalTitle("Share Folder");
        console.log("Share Folder");
        const idMap = new Map(checkedCardDetails.map(item => [item.id, item]));
        console.log(idMap);
        const filterData = keysData.filter(detail => idMap.has(detail.id));
        console.log(filterData);
        setData(filterData); // Pass the complete sharing details here
    };

    const OnDeleteClick = () => {
        const idsToDelete = checkedCardDetails.map(card => card.id);
        setIsModalVisible(true);
        setModalTitle("Delete");
        setData(idsToDelete);
    };

    const handleCardClick = ({ role, cardTitle }) => {
        if (role === "admin") {
            navigate(`/product/home/data-room/admin/${cardTitle}/files`);
        } else if (role === "investor") {
            return
        } else {
            navigate(`/product/home/data-room/user/files`)
        }
    };

    return (
        <div className="pt-[6rem] min-h-[100vh]">
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    closeHandler={closeModal}
                    documentList={data} // Pass the complete data to the modal
                    text={"This feature is not currently available for this version of the app."}
                    secondaryCloseHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("")
                    }}
                />
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
                <div className="mx-auto">
                    <DescribeFeature
                        text={DataRoom.data_room.dasboard.title}
                        description={DataRoom.data_room.dasboard.description}
                    />
                    <div className="flex justify-between items-center">
                        <p className="font-DMSans text-[#111827] text-[18px] mt-6">
                            Folders
                        </p>
                        <div className="flex gap-2 items-center">
                            {searchVisible && (
                                <Input
                                    placeholder="Search..."
                                    value={searchInput}
                                    onChange={handleSearchChange}
                                    style={{ width: 200 }}
                                />
                            )}

                            <Button
                                icon={<SearchOutlined />}
                                className="flex items-center border-none justify-center bg-[#F5F5F5] text-[#372EE5]"
                                onClick={handleSearchToggle}
                            />
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={handleButtonClick}
                                className="bg-[#372EE5] font-semibold"
                            >
                                Add Folder
                            </Button>
                        </div>
                    </div>
                    <div className="w-full h-fit flex justify-between">
                        <div className="flex gap-5 mt-4">
                            <PrimaryButton
                                type="primary"
                                icon={<PersonAddAltIcon fontSize="small"/>}
                                isDisabled={shareDisable}
                                className="bg-[#372EE5]"
                                onClick={handleShareClick}
                                isIcon={true}
                                text={"Share"}
                            >
                                Share
                            </PrimaryButton>
                            <PrimaryButton
                                type="primary"
                                danger
                                className={buttonDisabled ? "" : "bg-red-500"}
                                icon={<DeleteSVG height="20" width="20" color={`${buttonDisabled ? "#b8b8b8" : "#ffffff"}`} />}
                                isDisabled={buttonDisabled}
                                onClick={OnDeleteClick}
                                text={"Delete"}
                                isIcon={true}
                            >
                                Delete
                            </PrimaryButton>
                        </div>
                        <div className="flex items-center gap-x-[1rem]">
                            <div className="flex items-center border border-primary p-1 rounded-full divide-x-2 divide-primary">
                                <IconCheckedComponent
                                    icon={<WidgetsIcon fontSize="small" />}
                                    isChecked={!isShowDirInFlatView}
                                    onClick={() => setIsShowDirInFlatView(false)}
                                />
                                <IconCheckedComponent
                                    icon={<SubjectIcon fontSize="small" />}
                                    isChecked={isShowDirInFlatView}
                                    onClick={() => setIsShowDirInFlatView(true)}
                                />
                            </div>
                            <div>
                            <Button
                                onClick={() => { setReClick(!reClick);}} // Call handleReloadClick on click
                                className="bg-[#F5F5F5] text-[#372EE5] flex items-center justify-center"
                                disabled={secondayLoad}
                            >
                                {secondayLoad ? ( 
                                    <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg lg:overflow-visible">
                                        <svg class="w-3.5 h-4 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                        <path
                                            d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                                            stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path
                                            d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                                            stroke="#372EE5" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-900">
                                        </path>
                                        </svg>
                                    </div>
                                ) : (
                                    <ReloadOutlined />
                                )
                                }
                            </Button>
                        </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-7 w-full my-5 gap-5">
                            {Array.from({ length: 8 }).map((_, index) => (
                                <Skeleton.Input
                                    key={index}
                                    active
                                    style={{ width: 250, height: 110, borderRadius: 12 }}
                                />
                            ))}
                        </div>
                    ) : keysData.length > 0 ? (
                        <div className={`${isShowDirInFlatView ? '' : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-7 w-full my-5 gap-5'}`}>
                            {
                                filteredData.length > 0
                                ? (
                                    isShowDirInFlatView
                                    ? (
                                        <AntDTable
                                            columns={DataRoom.data_room.table.list_folder_columns}
                                            data={filteredData ?? []}
                                            headerBorderRadius={0}
                                            className="mt-4"
                                            isBordered={false}
                                            customComponent={({ record, col }) => {
                                                if(col.component_type === "dir_text"){
                                                    return (
                                                        <button
                                                            onClick={() => {
                                                                handleCardClick({ "role": "admin", "cardTitle": record.title })
                                                            }} 
                                                            className="flex gap-x-[0.5rem] items-center py-1 pl-2"
                                                        >
                                                            <FolderIcon
                                                                style={{
                                                                    "color": record?.color
                                                                }}
                                                            />
                                                            <TextSmall
                                                                text={record?.title}
                                                                fontWeight={400}
                                                            />
                                                        </button>
                                                    )
                                                }
                                                if(col.component_type === "three_dot_menu"){
                                                    console.log("This is the record : ", record)
                                                    return (
                                                        <div className="pl-2 flex justify-end">
                                                            {/* <MoreVertIcon/> */}
                                                            <div className="relative flex justify-end" onClick={(event) => event.stopPropagation()}>
                                                                {"admin" === "admin" ? (
                                                                    <ActionMenuFolderCard 
                                                                        documentId={record.id} 
                                                                        handleClose={() => { setReClick(!reClick); console.log("Changed") }} 
                                                                        handleDashColorChange={(value, color) => { 
                                                                            console.log(value, color)
                                                                            setFilteredData((prev) => {
                                                                                return prev.map((dir) => {
                                                                                    if(dir.id === record.id) {
                                                                                        return ({
                                                                                            ...dir,
                                                                                            color
                                                                                        })
                                                                                    }
                                                                                    return dir;
                                                                                })
                                                                            })
                                                                            // setIsLoading(value); if (color) { setStatusColor(color) } 
                                                                        }} 
                                                                            directoryName={record.title}
                                                                            count={keysData.filter((item) => item.id === record.id)[0].fileCount}
                                                                        />
                                                                ) : record.role === "investor" ? (
                                                                    <ActionMenuInvestorCard documentId={record.id} />
                                                                ) : (
                                                                    <ActionMenuInvestorCard documentId={record.id} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }}
                                        />
                                    )
                                    : (
                                        filteredData.map((card, index) => (
                                            <DataRoomDashboardCard
                                                key={index}
                                                cardTitle={card.title}
                                                color={card.color}
                                                onCheckboxChange={handleCardCheckboxChange}
                                                id={card.id}
                                                handleChange={() => { setReClick(!reClick); console.log("Changed") }}
                                                isloading={false}
                                                fileCount={card.fileCount}
                                                sharedCount={card.sharingDetails.length}
                                                role={"admin"}
                                            />
                                        ))
                                    )
                                ) : (
                                    <div className="text-nowrap mt-2">
                                        <TextLarge
                                            fontWeight={400}
                                            text="No Search results found for"
                                        /> &nbsp;
                                        <TextExtraLarge
                                            fontWeight={600}
                                            text={searchInput}
                                            style={{
                                                textWrap: "wrap" 
                                            }}
                                        />
                                    </div>
                                )
                            }
                            {folderLoader && (
                                <Skeleton.Input
                                    active
                                    style={{ width: 250, height: 110, borderRadius: 12 }}
                                />
                            )}
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center my-20">
                            <Empty description="No folders available" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

