export function getLatestActiveSubscription(subscriptionListRazorPayInfo) {
    if (subscriptionListRazorPayInfo.length === 0) {
        return {};
    }

    const activeSubscriptions = subscriptionListRazorPayInfo
        .filter(subscription => subscription.existingSubscription.status === 'active');

    if (activeSubscriptions.length > 0) {
        const latestSubscription = activeSubscriptions.reduce((latest, current) => {
            return current.existingSubscription.end_at > latest.existingSubscription.end_at ? current : latest;
        });

        const { id, end_at, status, plan_id } = latestSubscription.existingSubscription;
        const currency = latestSubscription.existingInvoices.items[0]?.currency?.toLowerCase() || 'inr';
        return { id, end_at, status, plan_id, currency };
    } else {
        const latestAttempt = subscriptionListRazorPayInfo.reduce((latest, current) => {
            return current.existingSubscription.created_at > latest.existingSubscription.created_at ? current : latest;
        }, subscriptionListRazorPayInfo[0]);

        const { id, end_at, status, plan_id } = latestAttempt.existingSubscription;
        const currency = latestAttempt.existingInvoices.items[0]?.currency?.toLowerCase() || 'inr';
        return { id, end_at, status, plan_id, currency };
    }
}
