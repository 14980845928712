import React from 'react'
import classNames from 'classnames';
import ProfileWithAction from './ProfileWithAction'
import TextareaAutosize from 'react-textarea-autosize';
import CustomLinkButton from '../../../DesignSystem/Buttons/CustomLinkButton'
import { D_UpdateToggleReplyMessage, D_UpdateReplyMessageText, createChildrenCommentApi } from "../chatSlice"
import { useDispatch } from 'react-redux';
import locals from "../../../localization/localization_en_chat.json"

const ConversationList = ({ chat, comment_list, presentActiveUser }) => {

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(D_UpdateReplyMessageText(null));
    }, [chat?.showReplyId])

    return (
        <ul className='flex flex-col gap-y-[0.75rem]'>
            {
                comment_list.map((comment, index) => {

                    let username = comment.user.username;
                    let avatar = comment.user.avatar;
                    if (comment.user._id === presentActiveUser._id) {
                        avatar = presentActiveUser.avatar
                        username = presentActiveUser.username
                    }

                    let isParentComment = "childrenComments" in comment

                    return (

                        <li key={index} className='flex flex-col gap-y-[0.85rem]'>

                            <ProfileWithAction
                                comment={comment}
                                user={{
                                    ...comment.user,
                                    avatar,
                                    username
                                }}
                                isParentComment={isParentComment}
                            />

                            <div>{comment.message}</div>

                            {
                                comment?.childrenComments?.length > 0 || chat.showReplyId
                                    ? (
                                        <>
                                            {
                                                (comment?.childrenComments?.length)
                                                    ? (
                                                        <div className='ml-[2rem]'>
                                                            <ConversationList
                                                                chat={chat}
                                                                presentActiveUser={presentActiveUser}
                                                                comment_list={comment.childrenComments}
                                                            />
                                                            {
                                                                chat.showReplyId === comment._id
                                                                    ? (
                                                                        <div className='mt-[1rem] flex flex-col gap-y-[0.5rem]'>
                                                                            <ProfileWithAction
                                                                                comment={comment}
                                                                                isShowAction={false}
                                                                                isShowTimeStamp={false}
                                                                                user={{
                                                                                    ...comment.user,
                                                                                    avatar,
                                                                                    username
                                                                                }}
                                                                                isParentComment={isParentComment}
                                                                            />
                                                                            <TextareaAutosize
                                                                                value={chat.replyMessage}
                                                                                onChange={(e) => dispatch(D_UpdateReplyMessageText(e.target.value))}
                                                                                style={{ resize: 'none' }}
                                                                                className='outline-none text-[14px] border-[#BECEFF] border p-2 rounded-md w-full'
                                                                                placeholder={locals.btn_name.reply_here}
                                                                                autoSize
                                                                            />
                                                                        </div>
                                                                    ) : null
                                                            }
                                                        </div>
                                                    ) : (
                                                        chat.showReplyId === comment._id
                                                            ? (
                                                                <div className='ml-[2rem] mt-[1rem] flex flex-col gap-y-[0.5rem]'>
                                                                    <ProfileWithAction
                                                                        comment={comment}
                                                                        isShowAction={false}
                                                                        isShowTimeStamp={false}
                                                                        user={{
                                                                            ...comment.user,
                                                                            avatar,
                                                                            username
                                                                        }}
                                                                        isParentComment={isParentComment}
                                                                    />
                                                                    <TextareaAutosize
                                                                        value={chat.replyMessage}
                                                                        onChange={(e) => dispatch(D_UpdateReplyMessageText(e.target.value))}
                                                                        style={{ resize: 'none' }}
                                                                        className='outline-none text-[14px] border-[#BECEFF] border p-2 rounded-md w-full'
                                                                        placeholder={locals.btn_name.reply_here}
                                                                        autoSize
                                                                    />
                                                                </div>
                                                            ) : null
                                                    )
                                            }
                                        </>
                                    ) : null
                            }
                            {
                                isParentComment
                                    ? (
                                        <div className={classNames('flex items-center', { 'justify-between flex-row-reverse': chat.showReplyId === comment._id })}>
                                            <CustomLinkButton
                                                text={chat.showReplyId === comment._id ? locals.btn_name.add : locals.btn_name.reply}
                                                handleOnClick={() => {
                                                    chat.showReplyId !== comment._id
                                                        ? dispatch(D_UpdateToggleReplyMessage(comment._id))
                                                        : dispatch(createChildrenCommentApi())
                                                }}
                                            />
                                            {
                                                chat.showReplyId === comment._id
                                                    ? (
                                                        <button className='text-red-500' onClick={() => dispatch(D_UpdateToggleReplyMessage(null))}>{locals.btn_name.cancel}</button>
                                                    ) : null
                                            }
                                        </div>
                                    ) : null
                            }
                        </li>

                    )
                })
            }
        </ul>
    )
}

export default ConversationList