import React from 'react'
import { Checkbox } from "antd"
import edit_gray from "../../../assets/common/edit_gray.svg"
import TextExtraLarge from '../../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton'
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton'
import PrimaryDangerButton from '../../../DesignSystem/Buttons/PrimaryDangerButton'
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge'

const Tag = ({ tagName = "Tag 1", onClick, actionType = "edit" }) => {
    return (
        <button onClick={onClick} className='rounded-full flex items-center justify-between gap-x-[0.5rem] border px-[16px] py-[5px]'>
            {actionType === "edit" ? <Checkbox className='permission' /> : null}
            <TextLarge fontWeight={500} text={tagName} />
            {actionType === "delete" ? <img src={edit_gray} alt="" /> : null}
        </button>
    )
}

const CategoryList = ({ actionType = "delete", isActionActive = false }) => {
    const tags = Array.from({ length: 14 }).map(() => ({ "label": "Tag 1", "id": "sxsxnsixs" }))
    return (
        <div className='bg-white px-[17.5px] flex flex-col gap-[17px] rounded-md border border-[#BDBDBD] p-[12px]'>
            {/* catg name and action */}
            <div className='flex items-center justify-between border-b border-[#BDBDBD] py-[17.5px]'>
                <TextExtraLarge text={"Product Category"} fontWeight={500} />
                <div className='flex items-center justify-between gap-x-[16px] lg:max-w-[345px]'>
                    <PrimaryButton text={"Add More"} />
                    <SecondaryButton text={"Edit"} />
                    <PrimaryDangerButton text={"Delete"} />
                </div>
            </div>
            {/* list of tags */}
            <ul className='flex flex-wrap gap-[8px]'>
                {
                    tags.map((tag) => {
                        return <Tag tagName={tag.label} />
                    })
                }
            </ul>
            {/* edit and delete action button */}
            {
                isActionActive
                    ? (
                        <div className='flex items-center justify-between'>
                            <SecondaryButton text={"Cancel"} />
                            {actionType === "delete" ? <PrimaryDangerButton text={"Delete All"} /> : <PrimaryButton text={"Done"} />}
                        </div>
                    ) : null
            }
        </div>
    )
}

const BMCategoryList = () => {
    // const data = 
    return (
        <div className='p-[24px] bg-[#F5F5F5] rounded-xl flex flex-col gap-y-[24px]'>
            <CategoryList />
            <CategoryList />
            <CategoryList />
            <CategoryList />
            <CategoryList />
            <CategoryList />
        </div>
    )
}

export default BMCategoryList