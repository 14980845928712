import React, { useState, useEffect } from "react";
import {
  updateMenuItemSelection,
  updateItemSelection,
} from "../features/manageLinkSlice";
import Play from "../assets/Home/Play.svg";
import MoreSquare from "../assets/Home/More Square.svg";
import { useSelector, useDispatch } from "react-redux";
import { exploreCoreFeature } from "../data";
import { useNavigate } from "react-router-dom";
import { Progress, Button } from "antd";
import Lock from "../assets/Home/menu/Lock.svg";
import { fetchActivity } from "../features/manageLinkSlice";
import "../index.css";
import { fetchOnboardingDetails } from "../features/onboardingSlice";
import dashboardLocals from "../localization/localization_en_dashboard.json";
import LockSVG from "../components/Menu/LockSVG";
import SkeletonLoader from "../DesignSystem/Loader/SkeletonLoader";

export default function DataRoom() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const linkInfo = useSelector((state) => state.link);
  const activeItem = useSelector((state) => state.link.activeItem);
  const openKeys = useSelector((state) => state.link.openKeys);
  const username = useSelector((state) => state.user.value.username);
  const adminUser = useSelector((state) => state.user.value.isAdmin);
  const [featureData, setFeatureData] = useState({});
  const [flippedCards, setFlippedCards] = useState(
    new Array(featureData["cards"]?.length).fill(false)
  );
  const status = useSelector((state) => state.link.status);
  const progress = useSelector((state) => state.link.progress);
  const onboarding = useSelector((state) => state.onboarding.value.Company);
  const [animateOnMount, setAnimateOnMount] = useState(false);

  useEffect(() => {
    if (!onboarding?.length) {
      dispatch(fetchOnboardingDetails());
    }
  }, []);

  const handleCardClick = (event, index) => {
    if (!event.target.matches("Button")) {
      const newFlippedCards = [...flippedCards];
      newFlippedCards[index] = !newFlippedCards[index];
      setFlippedCards(newFlippedCards);
    }
  };
  useEffect(() => {
    if (status === "succeeded") {
      const copyOfCards = featureData["cards"];
      const updatedCards = copyOfCards?.map((card, i) => {
        return {
          ...card,
          progress: progress[i]?.progress,
          activityCount: progress[i]?.activityCount,
        };
      });
      setFeatureData((featureData) => ({
        ...featureData,
        cards: updatedCards,
      }));
    }
  }, [status]);
  useEffect(() => {
    const feature = exploreCoreFeature.find(
      (feature) => feature.key === openKeys[openKeys.length - 1]
    );
    if (feature !== undefined) {
      setFeatureData(feature);
      setAnimateOnMount(true);
      dispatch(
        fetchActivity({ index1: feature.index1, index2: feature.index2 })
      );
      setFlippedCards(new Array(feature["cards"]?.length).fill(false));
    }
    const timerToResetAnimation = setTimeout(() => {
      setAnimateOnMount(false);
    }, 700);
    return () => clearTimeout(timerToResetAnimation);
  }, [activeItem]);
  return (
    <div className="pt-[6rem] w-[75%] min-h-[100vh]">
      <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
        {adminUser ? (
          navigate("/product/home/data-room/admin")
        ) : (
          <div className="mx-auto">
            <h1>DataRoom</h1>
          </div>
        )}
      </div>
    </div>
  );
}
