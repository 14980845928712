import React from 'react'
import TextExtraLarge from '../TypographyDescriptions/TextExtraLarge'
import TextMedium from '../TypographyDescriptions/TextMedium'
import HeadingFour from '../TypographyHeadings/HeadingFour'

const MyDashbaordCard = ({

    title,
    description,
    value,

    textColor = "white",
    descriptionColor = "#E0E0E0",
    borderColor = "#4B54FF",
    backgroundColor = "#4B54FF",

}) => {
    return (
        <div className='py-[12px] px-[16px] rounded-[8px]' style={{ border: `1px solid ${borderColor}`, backgroundColor, minWidth: '245px' }}>
            <div className='flex flex-col gap-0'>
                <TextExtraLarge fontWeight={600} text={title} color={textColor} />
                <TextMedium text={description} color={descriptionColor} />
            </div>
            <HeadingFour margin={{ direction: "bottom", number: "0" }} text={value} color={textColor} />
        </div>
    )
}

export default MyDashbaordCard