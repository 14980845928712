export const ROPreparePayloadIsNullDataCheck = (revenueOnboardingData, formItem) => {
    const { product_categories_details, projection_duration, manufacture_or_buy, marketing_spend_conversion, social_media_marketplaces, organic_paid_traffic_social_media_marketplaces } = revenueOnboardingData 
    if(formItem?.name === "key_details_user_acquisition_engagement"){
        return {
            data: revenueOnboardingData[formItem.name]?.filter((r) => r.visible===true).map((subdata) => ({fixed: subdata.fixed})),
            mandatoryRows: revenueOnboardingData[formItem.name]?.map((dataSlice,index) => {
                return formItem.table.defaultData[dataSlice.question].isMandatory;
            }),
            type: "tableRowCheck"
        }
    }
    if(formItem?.name === "marketing_spend_conversion"){
        return {
            "data": marketing_spend_conversion,
            "type": formItem.componentType
        }
    }
    if(formItem?.name === "platform_commission_cost"){
        return {
            "data": [...product_categories_details.manufacture, ...product_categories_details.buy],
            "type":"table",
            columns: formItem.table.columns
        }
    }
    if(["total_mau_and_registered_users", "social_media_marketplaces", "organic_paid_traffic_social_media_marketplaces", "organic_traffic_conversion_rate", "social_media", "e_commerce", "word_of_mouth_1", "word_of_mouth_2"].includes(formItem.name)){
        return {
            "data": revenueOnboardingData[formItem?.name],
            "type": "table",
            columns: formItem.table.columns
        }
    }
    if(formItem?.name === "manufacture_or_buy"){
        const manufactureData = product_categories_details.manufacture.map((data) => {
            const { name, raw_material_cost, other_direct_cost } = data;
            return { name, raw_material_cost, other_direct_cost, manufacture_or_buy }
        })
        const buyData = product_categories_details.buy.map((data) => {
            const { name, purchase_price, change_yoy_percent } = data;
            return { name, purchase_price, change_yoy_percent, manufacture_or_buy }
        })
        return {
            "data": [...manufactureData, ...buyData],
            "type": formItem.componentType
        }
    }
    if (formItem?.name === "product_category_1") {
        return {
            "data": [
                ...product_categories_details.manufacture.map((data) => {
                    const { name, tax, sales_margin_percent } = data;
                    return { name, tax, sales_margin_percent }
                }),
                ...product_categories_details.buy.map((data) => {
                    const { name, tax, sales_margin_percent } = data;
                    return { name, tax, sales_margin_percent }
                }),
            ],
            "type": formItem.componentType
        }
    }
    if (formItem?.name === "product_category_2_selection") {
        return {
            "data": {
                "business_contribution": [...product_categories_details.manufacture.map((data) => (data.business_contribution)), ...product_categories_details.buy.map((data) => (data.business_contribution))],
                "returns_cancellations": [...product_categories_details.manufacture.map((data) => (data.returns_cancellations)), ...product_categories_details.buy.map((data) => (data.returns_cancellations))],
                "quantity_per_order": [...product_categories_details.manufacture.map((data) => (data.quantity_per_order)),...product_categories_details.buy.map((data) => (data.quantity_per_order))],
            },
            "restrict_value":projection_duration,
            "type": "tab_table"
        }
    }
    if (formItem?.name === "key_details_user_acquisition_engagement"){
        return {
            "data": revenueOnboardingData[formItem?.name],
            "type": "table",
            "columns": formItem.table.columns,
        }
    }
    if (["sm_buyer", "e_commerce_buyer", "word_of_mouth_1", "word_of_mouth_2"].includes(formItem?.name)){
        return {
            "data": revenueOnboardingData[formItem?.name],
            "type": "table",
            "columns": formItem.table.columns,
        }
    }
    if(formItem?.name === "platform_commission_cost"){
        return {
            "data": revenueOnboardingData[formItem?.name],
            "type": "table",
            "columns": formItem.table.columns,
        }
    }
    if(['sm_marketing_places', 'paid_traffic_sm', 'traffic_conversion_rate'].includes(formItem?.name)){
        return {
            "data": revenueOnboardingData[formItem?.name],
            "type": "table",
            "columns": formItem.table.columns,
        }
    }
}