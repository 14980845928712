import React, { useState } from 'react'
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import IndividualOutput from '../../subcomponents/IndividualOutput'
import TextLarge from '../../../../DesignSystem/TypographyDescriptions/TextLarge'
import TextExtraLarge from '../../../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import { outputTablePayloadPreparation } from '../../functionality/OutputTablePayloadPreparationFunction'
import LibrarySlider from '../../../../DesignSystem/Slider/LibrarySlider'
import { D_Patch_SliderValue } from '../../revenueSlice'
import Loader from '../../subcomponents/Loader';
import SetStyleLinePlotPoint from '../../../../DesignSystem/GraphAndCharts/Line/SetStyleLinePlotPoint';
import bm_modal_locals from "../../../../localization/localization_en_business_modal.json";
import CollapseTable from '../../../../DesignSystem/Table/CollapseTable';
import SelectOption from '../../../../DesignSystem/Forms/SelectOption';
import { D_UpdateFilterYearCharts, API_DOWNLOAD_REPORT_BUSINESS_MODAL_LIST } from '../../businessModalSlice';
import { businessModelOutputPalette } from '../../../../DesignSystem/Colors/businessModelPalette';
import { D_Patch_Cost_SliderValue } from '../../costSlice';
import { D_Patch_PANDL_SliderValue } from '../../pAndLSlice'
import downloadFile from '../../../../assets/common/downloadFile.svg'
import LinkButton from '../../../../DesignSystem/Buttons/LinkButton';
import { D_Reset_All_Api_Status } from '../../businessModalSlice';
import classNames from 'classnames';
import { cn } from '../../../../utils/cn';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

const outputTableLabel = bm_modal_locals.business_modal.revenue.output_table_label;
const chart_data_labels = bm_modal_locals.business_modal.revenue.chart_data_labels;
const ouputTableEquipCurrency = bm_modal_locals.business_modal.revenue.output_table_refernce_currency;

const stepperValueMapping = { 0: -5, 25: -2.5, 50: 0, 75: 2.5, 100: 5 }

const convertNumberToString = (number) => {
    if(number===1) return "One"
    if(number===2) return "Two"
    if(number===3) return "Three"
    if(number===4) return "Four"
    if(number===5) return "Five"
    if(number===6) return "Six"
}

const RenderCalculationTable = ({ data, currency, isDropDownPresent, type, handleOnSelect, spanType }) => {
    const TABLE_HEADING = Object.keys(data);
    const TABLE_DATA = Object.values(data);
    const [downloadReportTitle, setDownloadReportTitle] = React.useState("");
    const dowload_report_status = useSelector((state) => state.businessModal.api_status.dowload_report_status)
    const dispatch = useDispatch();
    const businessModelId = useSelector((state) => state.businessModal.active_BusinessModal_Id)
    const revenueActiveScreenNumber = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.activeScreenNumber)
    const costActiveScreenNumber = useSelector((state) => state.costScreen.active_screen)
    const revenueTrackComponentRendering = useSelector((state) => state.revenueOnboarding.revenueOnboardingData.trackComponentRendering)
    const pAndLOutputSliderValues = useSelector((state) => state.pAndL.pAndLStore.outputSliderValues)
    const isReportLoading = dowload_report_status.status === "loading"
    const [cashFlowSpanType, setCashFlowSpanType] = useState("monthly");
    React.useEffect(() => {
        dispatch(D_Reset_All_Api_Status())
    }, [])
    const handleDownloadExcelFile = ({ tableName, type }) => {
        let screenIdentifier;
        switch (type) {
            case 'revenue_screen': {
                screenIdentifier = `revenueScreen${convertNumberToString(revenueTrackComponentRendering[revenueActiveScreenNumber] + 1)}`
                break;
            }
            case 'cost_screen': {
                screenIdentifier = `costScreen${convertNumberToString(Number(costActiveScreenNumber) + 1)}`
            }
            case 'pandl': {
                screenIdentifier = "pnlScreen"
            }
            case 'workingCapital': {
                screenIdentifier = "workingCapitalScreen"
            }
        }
        let screenType = type;
        if (type === "pandl") {
            screenType = "pnlScreen"
        } else if (type === "workingCapital") {
            screenType = "workingCapitalScreen"
        }
        let payload = {
            "screenType": ["revenue_screen", "cost_screen", "pandl", "workingCapital"].includes(type) ? screenIdentifier : screenType,
            "tableName": ["revenue_screen", "cost_screen"].includes(type) ? tableName : screenType,
            businessModelId
        };
        if (type === "pandl" && pAndLOutputSliderValues.nextValue !== 50) {
            payload = { ...payload, "stepperValue": stepperValueMapping[pAndLOutputSliderValues.nextValue] }
        }
        dispatch(API_DOWNLOAD_REPORT_BUSINESS_MODAL_LIST(payload));
        setDownloadReportTitle(tableName)
    }
    return (
        <div>
            {
                TABLE_HEADING.map((outputTable, index) => {
                    const { label, subLabel, tableTitle, CustomTableLabelName, mainTableHeading } = outputTableLabel[outputTable] || {}
                    if (["monthYear"].includes(outputTable)) return;
                    if (TABLE_DATA[index]?.length === 0) return;
                    const TABLE_TITLE = outputTableLabel?.[outputTable] ? (mainTableHeading ? mainTableHeading : tableTitle) : outputTable
                    const TABLE_NAME =  outputTableLabel?.[outputTable] ? (CustomTableLabelName ? CustomTableLabelName : label) : outputTable
                    // if(import.meta.env.VITE_REACT_ENVIRONMENT==="development" && [""].includes(TABLE_TITLE)) return;
                    const isCashflow = ["Cashflow Monthly", "Cashflow Yearly"].includes(tableTitle)
                    const targetCashFlowTable = isCashflow ? (cashFlowSpanType === "monthly" && tableTitle === "Cashflow Monthly") ? true : (cashFlowSpanType === "yearly" && tableTitle === "Cashflow Yearly") ? true : false : false
                    return (
                        <div className={cn(classNames('flex flex-col gap-y-[0.2rem] p-3 rounded-lg bg-baseGray200', { 'mb-[40px]': !isCashflow }, { 'bg-transparent p-0' : isCashflow && !targetCashFlowTable }))}>
                            <div style={{ visibility: !["Summary"].includes(TABLE_NAME) ? "visible" : "hidden" }}>
                                <TextLarge text={TABLE_NAME} fontWeight={500} />
                            </div>
                            <ComponentErrorBoundary>
                            <div>
                                <div className='flex items-center justify-between'>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: "center" }}>

                                            <div style={{ display: !['revenue_screen', 'cost_screen', 'pandl', 'workingCapital'].includes(type) ? "block" : "none" }}>
                                                {
                                                    (["pandl", "workingCapital"].includes(type) || ["Average Sales Price", "Average Order Value", "Revenue By Category", "Orders By Category", "Orders by Category", "Summary", "Monthly Retention Marketing", "Total Marketing Cost", "Total Operating Expenses", "Total Head Count", "Total Payroll", "Total G&A", "Total CAPEX"].includes(TABLE_NAME))
                                                        ? (
                                                            <LinkButton isLoading={isReportLoading && downloadReportTitle === TABLE_NAME} isDisabled={isReportLoading} icon={downloadFile} text={"Download"} onClick={() => handleDownloadExcelFile({ "tableName": TABLE_NAME, type })} />
                                                        ) : null
                                                }
                                            </div>
                                            {
                                                (isCashflow || isDropDownPresent) && (
                                                    <div style={{ display: ((targetCashFlowTable) || isDropDownPresent) ? 'flex' : 'none' }}>
                                                        <SelectOption
                                                            value={isCashflow ? cashFlowSpanType : spanType}
                                                            onChangeEvent={(value) => isCashflow ? setCashFlowSpanType(value) : handleOnSelect(value)}
                                                            listOfOptions={[{ label: "Monthly", value: "monthly" }, { label: "Yearly", value: "yearly" }]}
                                                            placeholder={"Select Period"} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* {
                                    subLabel ? <TextLarge color={"#757575"} text={subLabel.replace('CURRENCY',currency)} fontWeight={500} /> : null
                                } */}
                            </div>
                            {
                                isCashflow ? (
                                    (cashFlowSpanType === "monthly" && tableTitle === "Cashflow Monthly") ||
                                        (cashFlowSpanType === "yearly" && tableTitle === "Cashflow Yearly")
                                        ? <CollapseTable currency={currency} data={TABLE_DATA[index]} mainTableHeading={TABLE_TITLE} fixedIndexConfig={[0]} />
                                        : null
                                ) : (
                                    ["Total Operating Expenses", "Total Marketing Cost", "Particulars", "Summary", "", ""].includes(tableTitle)
                                        ? <CollapseTable currency={currency} data={TABLE_DATA[index]} mainTableHeading={TABLE_TITLE} fixedIndexConfig={[0]} />
                                        : <IndividualOutput test={index} tableName={label} currency={currency} tableTitle={TABLE_TITLE} dataSource={TABLE_DATA[index]} />
                                )
                            }
                            </ComponentErrorBoundary>
                        </div>
                    )
                })
            }
        </div>
    )
}

const PayloadPreparationGraph = (data) => {
    let chart_data = { "averageSalesPrice": [], "averageOrderValue": [], "revenueByCategory": [], "orderByCategory": [], "ordersByCategory": [], "totalMarketingCost": [], "totalOperatingExpenses": [], "totalCAPEX": [], "totalHeadCount": [], "totalPayroll": [], "totalGA": [] }
    if (typeof data !== "object") return <>Invalid data for graph</>
    for (const attr of Object.entries(data)) {
        let data = [];
        if (attr[0] in chart_data) {
            let MODIFIED_DATA = attr[1]
            if ("children" in attr[1][0]) {
                MODIFIED_DATA = attr[1][0].children;
            }
            MODIFIED_DATA.map((catg) => {
                const catgoryName = catg.title;
                for (const sensitiveData of Object.entries(catg)) {
                    if (sensitiveData[0] !== "title" && sensitiveData[0] !== "children") data.push({ "month_year": String(sensitiveData[0]).slice(0, sensitiveData[0].length - 4) + String(sensitiveData[0]).slice(-2), "value": sensitiveData[1], "category": catgoryName })
                }
            })
            chart_data = { ...chart_data, [attr[0]]: data }
        }
    }
    return chart_data;
}

const RenderChartsTable = ({ data, CURRENCY, filterYear }) => {
    try{
        const graphHeading = Object.keys(data);
        const graphData = Object.values(data);
        return graphHeading.map((chartOutput, index) => {
            const shortenYear = String(filterYear).slice(2);
            const dataBasedOnYear = graphData[index].filter((subData) => subData.month_year.includes(shortenYear))
            if (graphData[index].length === 0) return;
            return (
                <div className='bg-white p-3 rounded-lg flex flex-col gap-y-[1.5rem]'>
                    <TextLarge text={chart_data_labels?.[chartOutput]?.title} />
                    <ComponentErrorBoundary>
                        <SetStyleLinePlotPoint
                            test={index}
                            colors={businessModelOutputPalette}
                            xAxisLabel={chart_data_labels?.[chartOutput]?.xAxisLabel}
                            yAxisLabel={chart_data_labels?.[chartOutput]?.yAxisLabel}
                            data={dataBasedOnYear}
                            unitInK={true}
                            yAxisPreUnit={ouputTableEquipCurrency.includes(chart_data_labels?.[chartOutput]?.title) ? '' : CURRENCY}
                        />
                    </ComponentErrorBoundary>
                </div>
            )
        })
    }catch(error){
        throw new Error(error);
    }
}

const OutputScreen = ({ category_list, moduleType, outputData, screenNumber, outputSliderValues, type, stepperOnChange, isStepperVisible, isSummaryScreenLoading, handleOnSelect, isDropDownPresent, spanType = "monthly" }) => {
    const dispatch = useDispatch();
    const CURRENCY = useSelector((state) => state.globalSetting.currencySymbol)
    const GraphFilterYear = useSelector((state) => state.businessModal.graph_filter_year_trace)
    const PROJECTION_DURATION = useSelector((state) => state.globalSetting.active_global_settings.projection_duration)
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)
    const handleOnAfterChange = (value) => {
        if ([0, 25, 50, 75, 100].includes(value)) {
            if (moduleType === "cost") {
                dispatch(D_Patch_Cost_SliderValue({ value }))
            }
            if (moduleType === "revenue") {
                dispatch(D_Patch_SliderValue({ screenNumber, value }));
            }
            if (moduleType === "pandl") {
                dispatch(D_Patch_PANDL_SliderValue({ value }));
            }
            stepperOnChange && stepperOnChange(value)
            return;
        }
        message.warning("Please move the slider handle to a value within the acceptable limits.", 2);
    }
    const transformedData = outputTablePayloadPreparation(outputData, screenNumber, type, spanType, category_list)
    const graphOutputs = PayloadPreparationGraph(transformedData);
    const isGraphHasData = Object?.values(graphOutputs)?.find((attr) => attr !== null && attr?.length > 0);
    if (typeof outputData !== "object" || !!transformedData == false) return;
    let middleValueName = "Base";
    if (moduleType === "revenue") {
        middleValueName = "Sales Margin %"
    } else if (moduleType === "cost") {
        middleValueName = "MoM Spend Growth"
    } else if (moduleType === "pandl") {
        middleValueName = "Base Value"
    }
    let stepperValue;
    if (moduleType === "pandl") {
        stepperValue = outputSliderValues.nextValue;
    } else {
        stepperValue = outputSliderValues?.[screenNumber]?.nextValue
    }
    return (
        <React.Fragment>
            <div style={{ display: isSummaryScreenLoading ? "none" : "block" }} className='border border-p7 rounded-xl bg-[#BECEFF]/60 mt-[2rem] p-[16px] flex flex-col gap-y-[16px] w-full min-h-fit overflow-hidden relative'>
                {false ? <Loader /> : null}
                <div className='py-[0.5rem]'>
                    {
                        !["pandl"].includes(type)
                            ? <TextExtraLarge text={"Outputs"} fontWeight={500} />
                            : null
                    }
                </div>
                {(screenNumber <= 1 || type === "pandl") ? <div className='xl:w-[60%] w-[80%] ml-auto mr-[2rem]'>
                    {isStepperVisible ? <LibrarySlider middleValueName={middleValueName} isDisabled={MODE_TYPE.view} value={stepperValue} handleOnAfterChange={handleOnAfterChange} /> : null}
                </div> : null}
                <ComponentErrorBoundary>
                    <RenderCalculationTable currency={CURRENCY} data={transformedData} isDropDownPresent={isDropDownPresent} type={type} handleOnSelect={handleOnSelect} spanType={spanType} />
                </ComponentErrorBoundary>
            </div>
            <div>
                {
                    isGraphHasData
                        ? (
                            <div className='border border-p7 mt-[1rem] rounded-xl bg-[#BECEFF]/60 p-[16px] flex flex-col gap-y-[16px] w-full min-h-fit overflow-hidden relative'>
                                <div className='flex flex-col gap-y-[1rem] border-slate-500'>
                                    <div className='flex items-center justify-between'>
                                        <TextExtraLarge text={"Charts"} fontWeight={500} />
                                        <div className='flex items-center justify-end gap-x-[1rem]'>
                                            <TextLarge text={"Filter By Year"} fontWeight={500} />
                                            <div className='w-[15rem]'>
                                                <SelectOption
                                                    placeholder={"Select Year"}
                                                    onChangeEvent={(value) => dispatch(D_UpdateFilterYearCharts(value))}
                                                    value={GraphFilterYear}
                                                    listOfOptions={Array.from({ length: PROJECTION_DURATION }).map((_, index) => {
                                                        const current_year = new Date().getFullYear();
                                                        return (
                                                            { "label": current_year + index, "value": current_year + index }
                                                        )
                                                    })} />
                                            </div>
                                        </div>
                                    </div>
                                    <ComponentErrorBoundary>
                                        <RenderChartsTable filterYear={GraphFilterYear} data={graphOutputs} CURRENCY={CURRENCY} />
                                    </ComponentErrorBoundary>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>
        </React.Fragment>
    )
}

export default OutputScreen
