import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, message, Modal, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import tooltip from "../components/targetmarketanalysis/assets/tooltip.svg"
import { postNewFeatureProgressApi } from "./maindashboardstepper/stepperProgressSlice";
import {
  updateShowSegmentInput,
  updateSegmentType,
  updateSegmentData,
  patchSegmentData,
  postSegmentData
} from "../features/targetmarketanalysisSlice";
import useCheckStatusStepperProgress from "../utils/checkStatusStepperProgress";
import MessageNotification from "../components/reuseablecomponent/MessageNotification"
import { tmaResetStatusWithErr } from "../features/targetmarketanalysisSlice";
import { postUserActivityDataApi } from "./UserActivity/userActivitySlice";
import tooltipNotFilled from "../assets/common/tooltipNotFilled.svg"
import tmaLocals from "../localization/localization_en_target_market_analysis.json"
import notificationLocals from "../localization/localization.en_notification_message.json"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TMASegmentInputs = ({ isOpen }) => {
  const tmaDetails = useSelector((state) => state.tma);
  const [messageApi, contextHolder] = message.useMessage();
  const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);
  const permission = useSelector((state) => state.user.permission);
  const status = useSelector((state) => state.tma.status);
  const error = useSelector((state) => state.tma.error);
  const checkFeatureUsageStatus = useCheckStatusStepperProgress(tmaLocals.target_market_analysis.module_title);
  const [isShowTooltip, setisShowTooltip] = useState(false);
  const [tma, setTma] = useState(
    tmaDetails.segmentType.new
      ? tmaDetails.default
      : tmaDetails.segmentUpdateInputs
  );
  useEffect(() => {
    if (status === "failed" || status === "succeeded") {
      const timer = setTimeout(() => {
        dispatch(tmaResetStatusWithErr());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [status]);
  useEffect(() => {
    setTma(tmaDetails.segmentType.new
      ? tmaDetails.default
      : tmaDetails.segmentUpdateInputs)
  }, [tmaDetails.segmentType.segmentUpdateInputs, tmaDetails])
  const dispatch = useDispatch();

  const handleInputChange = () => {
    let isNull = false;
    setIsShowErrorMessage(true);
    for (let i in tma) {
      if (tma[i] === null) {
        // messageApi.open({
        //   type: 'warning',
        //   content: tmaLocals.target_market_analysis.warning_err_message.fill_input,
        // });
        isNull = true;
        break;
      }
      isNull = false;
    }
    if (!isNull) {
      if (tmaDetails.segmentType.new) {
        let isPresent = tmaDetails.segmentData.find((segment) => {
          if (segment.segmentName === tma.segmentName) {
            return segment;
          }
        })
        if (isPresent) {
          messageApi.open({
            type: 'warning',
            content: tmaLocals.target_market_analysis.warning_err_message.already_exist,
          });
          return;
        } else {
          dispatch(updateSegmentData(tma));
          if (!checkFeatureUsageStatus) {
            dispatch(postNewFeatureProgressApi({ status: 1, featureName: tmaLocals.target_market_analysis.module_title }))
          }
        }
      } else {
        const editNotification = notificationLocals.notification_message.target_market_analysis.edit;
        dispatch(patchSegmentData({ updatedData: tma, updateSegment: true }));
        dispatch(postUserActivityDataApi({ ...editNotification }))
      }
      dispatch(updateSegmentType({ new: false, upte: false }));
      dispatch(postSegmentData());
      // dispatch(updateShowSegmentInput());
    } else {
    }
  };

  const inputFields = [
    {
      key: "segmentName",
      className: 'w-[300px]',
      component: Input,
      type: "text"
    },
    {
      key: "APV",
      component: InputNumber,
      type: "number",
      props: { min: 1 },
      className: 'w-[428px]',
    },
    {
      key: "PF",
      component: InputNumber,
      type: "number",
      props: { type: "Number", min: 1 },
      className: 'w-[100px]',
    },
    {
      key: "ACL",
      component: InputNumber,
      type: "number",
      props: { type: "Number", min: 1 },
      className: 'w-[100px]'
    },
    {
      key: "Customers",
      component: InputNumber,
      type: "number",
      props: { type: "Number", min: 1 },
      className: 'w-[100px]'
    },
  ];


  return (
    <Modal width={820} open={isOpen} centered>
      {contextHolder}
      {status == "failed" && error?.length > 0 && <MessageNotification status={status} type={"error"} content={error} />}
      <div className="bg-white">
        <div className="relative">
          <p style={{ marginBottom: "0px" }} className="font-DMSans text-[20px] py-[20px] bg-[#372EE5] text-white w-full shadow-xl text-center">
            {tmaLocals.target_market_analysis.form_modal.title}
          </p>
          <button onClick={() => setisShowTooltip((prev) => !prev)} className="absolute top-[32%] right-[25px]"><InfoOutlinedIcon className="text-white" /></button>
        </div>
        <div className="h-[80%] scrollbar-hide overflow-scroll pb-[1rem]">
          <Form className="px-[40px] mt-[20px] w-full ">
            {inputFields.map((field, index) => {
              const unit = tmaLocals.target_market_analysis.form_modal.questions[index].unit;
              return (
                <div className="flex" key={index}>
                  <div className="w-[30px] h-[30px] mr-[8px] aspect-square text-[#372EE5] border-[#372EE5] border rounded-full flex justify-center items-center bg-[#EBF1FF]">
                    {index + 1}
                  </div>
                  <Form.Item>
                    <div className="">
                      <span>{JSON.parse(JSON.stringify(tmaLocals.target_market_analysis.form_modal.questions[index].question))}</span>
                      <span className="text-red-500 pl-1">*</span>
                    </div>
                    {isShowTooltip && <div className="text-[#757575] font-InterRegular italic text-[10px] font-normal tracking-wide"><span>{tmaLocals.target_market_analysis.form_modal.questions[index].tooltip}</span></div>}
                    <div className="w-[100%] flex items-center mt-[12px]">
                      <div className={`${index <= 1 ? 'w-[300px]' : 'w-[85px]'}`}>
                        {
                          field.type === "text"
                            ? <Input
                              name={field.key}
                              value={tma[field.key]}
                              disabled={!permission.edit}
                              onChange={(e) => {
                                setTma((prev) => ({
                                  ...prev,
                                  [field.key]: e.target.value,
                                }));
                              }}
                              style={{ borderRadius: "6px", paddingLeft: "0.75rem" }}
                              placeholder={tmaLocals.target_market_analysis.form_modal.questions[index].placeholder}
                            />
                            : <InputNumber
                              name={field.key}
                              value={tma[field.key]}
                              disabled={!permission.edit}
                              controls={false}
                              min={1}
                              onChange={(value) => {
                                setTma((prev) => ({
                                  ...prev,
                                  [field.key]: value,
                                }));
                              }}
                              style={{ borderRadius: "6px", paddingLeft: "0.5rem" }}
                              className="py-[0.2rem]"
                              placeholder={tmaLocals.target_market_analysis.form_modal.questions[index].placeholder} />
                        }
                      </div>
                      {unit != null && <span className="ml-[0.5rem]"> {unit}</span>}
                    </div>
                    {
                      isShowErrorMessage && tma[field.key] === null && <div className="text-red-500 text-[12px]">{tmaLocals.target_market_analysis.validation_empty_msg}</div>
                    }
                  </Form.Item>
                </div>
              );
            })}

            <div className="flex justify-center">
              <Button
                onClick={() => {
                  setIsShowErrorMessage(false)
                  dispatch(updateShowSegmentInput());
                  dispatch(updateSegmentType({ new: false, upte: false }));
                }}
                className="text-[#372EE5] border mr-[48px] border-[#372EE5]"
              >
                {tmaLocals.target_market_analysis.form_modal.close_btn_text}
              </Button>
              <Form.Item style={{ marginBottom: "0" }}>
                <Button
                  loading={status == "pending"}
                  disabled={!permission.edit || status == "pending"}
                  onClick={handleInputChange}
                  className="bg-[#372EE5]"
                  type="primary"
                >
                  {tmaLocals.target_market_analysis.form_modal.completed_btn_text}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default TMASegmentInputs;
