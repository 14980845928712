import React from 'react'
import { Button } from 'antd'

const ImageButton = ({ onClick, isDisabled, isLoading, image }) => {
  return (
    <Button className='cursor-pointer flex items-center justify-center border-none bg-transparent w-[25px] h-[25px] aspect-square' style={{padding:0}} loading={isLoading} disabled={isDisabled} onClick={onClick}>
        <img src={image} className='w-[24px] aspect-square' alt="" />
    </Button>
  )
}

export default ImageButton