import React from 'react'
import TextLarge from '../../../../../DesignSystem/TypographyDescriptions/TextLarge'

const DisabledCell = ({ value }) => {
    return (
        <div>
            <div className='flex justify-between items-center px-[8px] bg-baseGray400'>
                <div className='py-[12px] px-[8px] flex justify-between'>
                    <div className='flex invisible'>
                        <TextLarge fontWeight={500} text={value} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisabledCell