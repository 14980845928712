import React from 'react'

const ImageCard = ({ isSelected, imgUrl, alt }) => {
    return (
        <React.Fragment>
            <div className={`${isSelected ? 'border-[3px] border-mainPrimary' : ''} h-full aspect-video rounded-[8px]`}>
                <img className='aspect-video w-[100%] h-[100%] rounded-[5px]' src={imgUrl} alt={alt} />
            </div>
        </React.Fragment>
    )
}

export default ImageCard