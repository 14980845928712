import React from 'react'
import ToolTip from '../../../../../DesignSystem/ToolTip/ToolTip'
import TextExtraLarge from '../../../../../DesignSystem/TypographyDescriptions/TextExtraLarge'

const FormLabel = ({ label, isMandatory, tooltip }) => {
    return (
        <label className='flex mb-[14px] gap-x-[8px]'>
            {isMandatory ? <sub className='text-red-500  text-[1.2rem] pt-[0.2rem]'>*</sub> : null}
            <div>
                <TextExtraLarge text={label} fontWeight={500} />
                {!!tooltip ? <div className='cursor-pointer inline-block pl-1'> <ToolTip placement={"top"} tooltip={tooltip} /> </div> : null}
            </div>
        </label>
    )
}

export default FormLabel