import React from 'react'
import { useSelector } from "react-redux"
import { useLocation } from 'react-router-dom'

const useLocationWebOrProduct = () => {

    const location = useLocation().pathname;
    const user = useSelector((state) => state.user);
    const IS_USER_IN_PRODUCT = user?.value?.id?.length > 0 && location?.includes('product/home');

    return IS_USER_IN_PRODUCT;

}

export default useLocationWebOrProduct