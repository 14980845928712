import React from 'react'
import TextLarge from '../../../../../DesignSystem/TypographyDescriptions/TextLarge'

const StaticText = ({ cellColor, visibilityType, value, placeHolder, is_mandatory }) => {
    return (
        <div className='px-[4px] flex justify-between' style={{ backgroundColor: cellColor }}>
            <div className='flex' style={{ visibility: visibilityType }}>
                {is_mandatory ? <div><sub className='text-red-500 pr-[0.2rem] text-[1.2rem] pt-[0.2rem]'>*</sub></div> : null}
                <TextLarge fontWeight={500} text={!!value ? value : placeHolder} color={value === null && '#bfbfbf'} />
            </div>
        </div>
    )
}

export default StaticText