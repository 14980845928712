import React, { useState } from 'react'
import { useSelector } from "react-redux";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import FormComponentProvider, { IndexLabel } from '../../../DesignSystem/Forms/FormComponentProvider'
import SelectOption from '../../../DesignSystem/Forms/SelectOption'
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge'
import SelectOptionWithAddMore from '../../../DesignSystem/Forms/SelectOptionWithAddMore'
import SelectOptionRow from '../../../DesignSystem/Forms/SelectOptionRow'
import TextSmall from '../../../DesignSystem/TypographyDescriptions/TextSmall';
import gtmlocals from "../../../localization/localization_en_gtm.json"
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';
const weekFormat = 'MM/DD';
const monthFormat = 'YYYY/MM';

const { RangePicker } = DatePicker;

const ErrorMessage = () => {
    return (
        <div className='text-red-500 text-[12px]'>{gtmlocals.gtm_strategy.error_message.not_filled_form}</div>
    )
}

const FormList = ({ form, UpdateBudgetData, isShowFormNotFilled, budgetData, channelList, editTempChannelData, handleOnChangeEditFormInput, handleOnCurrencyChange, currency, module_type }) => {
    const { show_toolip, crud_form_type, rowIndex, table_data, categoryList } = useSelector((state) => state.gtm.gtm_strategy.gtm_plan);
    const { currencyList } = useSelector((state) => state.gtm.gtm_strategy)
    return (
        <div className='flex flex-col gap-y-[24px]'>
            {
                module_type === "marketing_funnel" || crud_form_type==="EDIT"
                ? (
                        <div className='grid grid-cols-12 gap-[18px]'>
                            {
                                form?.row_question?.map((q, index) => {
                                    if (q.component_type === "select_option_with_add_more") {
                                        return (
                                            <div className={(index === 1 || index === 2) ? 'col-span-5' : 'col-span-12'}>
                                                <TextLarge text={q.label} fontWeight={500} />
                                                <div className='mt-1'>
                                                    <SelectOptionWithAddMore
                                                        name={q.name}
                                                        optionTextAlign={index === 1 ? "center" : "left"}
                                                        isAddMore={false}
                                                        value={editTempChannelData[q.name]}
                                                        onChangeEvent={(value) => handleOnChangeEditFormInput({ "data_index": q.name, value })}
                                                        customPlaceholder={true}
                                                        placeHolder={q?.placeHolder}
                                                        minSelectHeight={56}
                                                        isPlaceHolderCenter={index === 1}
                                                        listOfOptions={q?.select_options}
                                                        optionStyle={{ padding: index === 1 ? "1rem 0rem" : 0, marginBottom: "0.5rem", "display": "flex", alignItems: "center" }}
                                                    />
                                                </div>
                                                { editTempChannelData[q.name]===null && isShowFormNotFilled && <ErrorMessage/> }
                                            </div>
                                        )
                                    }
                                    if (q.component_type === "month_year_calender") {
                                        return (
                                            <div className={(index === 1 || index === 2) ? 'col-span-7' : 'col-span-12'}>
                                                <TextLarge text={q.label} fontWeight={500} />
                                                <div className='mt-1'>
                                                    <RangePicker
                                                        style={{ minHeight: "3.7rem", width: "100%" }}
                                                        name={q?.name}
                                                        value={editTempChannelData?.timeline ? editTempChannelData?.timeline.map((v) => dayjs(v)) : editTempChannelData?.timeline}
                                                        onChange={(value) => {
                                                            handleOnChangeEditFormInput({ "data_index": q.name, value })
                                                        }}
                                                    />
                                                </div>
                                                { editTempChannelData?.timeline===null && isShowFormNotFilled && <ErrorMessage/> }
                                            </div>
                                        )
                                    }
                                    if (q.component_type === "mutli_select") {
                                        return (
                                            <div className={(index === 1 || index === 2) ? 'col-span-7' : 'col-span-12'}>
                                                <TextLarge text={q.label} fontWeight={500} />
                                                <div className='mt-1'>
                                                    <SelectOptionRow
                                                        name={q.name}
                                                        disabled={module_type==="gtm_list" ? false : editTempChannelData["funnel_stage"]===null}
                                                        value={editTempChannelData[q.name]}
                                                        styles={{ minHeight: "3.7rem" }}
                                                        placeHolder={q?.placeHolder}
                                                        listOfOptions={module_type==="gtm_list" ? q?.select_options : q?.select_options.filter((opt) => opt.funnel_stage===editTempChannelData["funnel_stage"])}
                                                        handleOnChangeValue={(value) => handleOnChangeEditFormInput({ "data_index": q.name, value })}
                                                    />
                                                </div>
                                                { (editTempChannelData[q.name]===null || editTempChannelData[q.name]?.length==0) && isShowFormNotFilled && <ErrorMessage/> }
                                            </div>
                                        )
                                    }
                                    if (q.component_type === "select_option") {
                                        return (
                                            <div className={(index === 1 || index === 2) ? 'col-span-7' : 'col-span-12'}>
                                                <TextLarge text={q.label} fontWeight={500} />
                                                <div className='mt-1'>
                                                    <SelectOption
                                                        name={q.name}
                                                        value={editTempChannelData[q.name]}
                                                        styles={{ minHeight: "3.7rem" }}
                                                        placeholder={q?.placeHolder}
                                                        listOfOptions={q?.select_options}
                                                        onChangeEvent={(value) => handleOnChangeEditFormInput({ "data_index": q.name, value })}
                                                    />
                                                </div>
                                                { editTempChannelData[q.name]===null && isShowFormNotFilled && <ErrorMessage/> }
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    ) : null
            }
            <div style={{ backgroundColor: crud_form_type === "EDIT" || module_type === "marketing_funnel" ? "#FAFAFA" : null, border: crud_form_type === "EDIT" || module_type === "marketing_funnel" ? `1px solid ${"#E0E0E0"}` : null }} className='p-[12px] rounded-xl mb-[24px]'>
                <div className='flex items-center justify-between'>
                    {
                        crud_form_type === "EDIT" || module_type === "marketing_funnel"
                            ? <TextLarge text={form?.subform_name} fontWeight={500} />
                            : null
                    }
                    <div className=' flex gap-x-1 items-center'>
                        <div className='min-w-fit'>
                            <TextLarge text={form.currency_option.label} fontWeight={500} /> 
                        </div>
                        <SelectOption
                            name={form.currency_option.name}
                            value={currency.value}
                            onChangeEvent={(value) => {
                                handleOnCurrencyChange(currencyList.find((curr) => curr.value === value))
                            }}
                            placeholder={form.currency_option.placeHolder}
                            listOfOptions={currencyList}
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-y-[24px] py-[16px]'>
                {
                    form.questions.map((f, index) => {
                        const value = crud_form_type === "EDIT" || module_type === "marketing_funnel" ? module_type === "marketing_funnel" ? editTempChannelData[f.name] : editTempChannelData?.budget?.[f?.name] : budgetData?.[f?.name]
                        return (
                            <div className='flex gap-x-[8px]'>
                                {
                                    crud_form_type === "VIEW"
                                        ? <IndexLabel index={index + 1} />
                                        : null
                                }
                                <div>
                                    <div className='relative'>
                                        {f.label ? <TextLarge text={f.label} fontWeight={500} color={"#757575"} /> : null}
                                        <sub className='text-red-500 bg-green-500 pr-[0.2rem] text-[1.2rem] absolute bottom-3'>*</sub>
                                    </div>
                                    {f?.tooltip && show_toolip && crud_form_type==="VIEW" ? <div className='italic'><TextSmall color='#757575' text={f?.tooltip} fontWeight={500} /> </div> : null}
                                    <FormComponentProvider
                                        key={index}
                                        form={{ ...f, "placeholder": f.placeholder?.includes('CURRENCY') ? f.placeholder.replace('CURRENCY', currency.CURRENCY) : f.placeholder }}
                                        onBlurEvent={(value) => module_type === "marketing_funnel" ? handleOnChangeEditFormInput({ "data_index": f.name, value }) : crud_form_type === "EDIT" ? Number(handleOnChangeEditFormInput({ "data_index": f.name, value, "parent_index": f.parent_index })) : UpdateBudgetData({ name: f.name, value })}
                                        isShowTooltip={show_toolip}
                                        styles={{ width: "175px", border: "1px solid #9E9E9E", borderRadius: "0.25rem", overflow: "hidden", marginTop: "0.25rem", backgroundColor: "#FFFFFF", padding: "0.7rem 0.5rem", color: "red" }}
                                        value={value}
                                        component_type={f.component_type}
                                    />
                                    { value===null && isShowFormNotFilled && <ErrorMessage/> }
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default FormList