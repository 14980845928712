const AddSlideSVG = ({ color="#fff",...props }) => (
    <svg
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <g>
            <path
                fill={color}
                d="M11 15h2v-3h3v-2h-3V7h-2v3H8v2h3v3ZM21 3H3c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h18c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2Zm0 14H3V5h18v12Z"
            />
        </g>
    </svg>
)
export default AddSlideSVG