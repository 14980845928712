import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { help_guide_icons } from '../../assets/website'
import GuideQuestionListOfSubQuestion from './subcomponents/GuideQuestionListOfSubQuestion'
import locals from '../../localization/localization_en_website.json'
import TextExtraLarge from '../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import HeadingTwo from '../../DesignSystem/TypographyHeadings/HeadingTwo'
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour'
import LogoInputComponent from '../subcomponents/LogoInputComponent'
import SearchIcon from '@mui/icons-material/Search'
import TagSelection from '../components/TagSelection'
import { useDispatch, useSelector } from 'react-redux'
import { formatCatgQAData } from './helper/formatCatgQAData'
import SearchNotFound from '../components/SearchNotFound'
import SecondaryButton from '../../DesignSystem/Buttons/SecondaryButton'
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton'
import { API_Fetch_ResourceGuideTopicData } from '../../features/appSlice'
import CardSkeletonLoader from './components/CardSkeletonLoader'
import HeadingFive from '../../DesignSystem/TypographyHeadings/HeadingFive'
import useOutsideClick from '../../hooks/useOutsideClick'
import useScrollToTopPage from '../../hooks/useScrollToTopPage'
import { resource_guide_fetch_data_params } from '../../data'
import BlurContainer from '../../DesignSystem/Container/BlurContainer'
import useApp from '../../hooks/useApp'
import { paywall_locals } from '../../localization'

const RESOURCE_GUIDE = locals.website['resource-guide'];

const ResourceGuide = () => {
    const ref_search = useRef(null);
    const [tag, setActiveTag] = useState(null);
    const [isShowMore, setisShowMore] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { isFreeSubscription, upgradeRenewPaywallPopup } = useApp();
    const [filteredText, setFilteredText] = useState("");
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    useOutsideClick(ref_search, () => setIsSearchOpen(false));
    const { data, api_status } = useSelector((state) => state.app.resource_guide)
    const previouStackRoute = useSelector((state) => state.app.stackLastSingletVisitedRoute)

    useScrollToTopPage({ deps: [] });

    const cardList = useMemo(() => {
        return (
            formatCatgQAData({
                "topicList": data?.topicList,
                "categoryList": data?.categoryList || [],
                "qAList": data?.qAList || []
            })
        )
        //.filter((catg) => catg?.category?.toLowerCase()?.includes(filteredText))
    }, [data])

    const list_of_dropdown_title = cardList
        ?.map((c) => ({ "topicId": c?.topicId, "catgId": c?._id, "title": c?.category }))
        ?.filter((catg) => catg?.title?.toLowerCase()?.includes(filteredText?.toLowerCase()))

    const topicList = useMemo(() => {
        return data?.topicList?.map((t) => ({ label: t.topic, value: t._id }))
    }, [data])

    useEffect(() => {
        if (topicList?.length > 0) {
            const bp = topicList?.find((t) => t.label === "Business Model Canvas")
            setActiveTag(bp?.value)
        }
    }, [topicList?.length])

    useEffect(() => {
        // if (!!data === false || cardList?.length === 0){}
        const active_topic = location.pathname.split('/')[4];
        if(active_topic in  resource_guide_fetch_data_params){
            dispatch(API_Fetch_ResourceGuideTopicData({ "topicName": resource_guide_fetch_data_params[active_topic] }))
        }
    }, [])

    console.log(upgradeRenewPaywallPopup, "upgradeRenewPaywallPopup")

    return (
        <div className='p-2 pt-[85px] flex flex-col gap-y-[16px] items-center justify-center w-full'>
            <div className='flex flex-col gap-y-[8px]  items-center justify-center'>
                <HeadingTwo headingCSSStyles={{ marginBottom: 0 }} text={RESOURCE_GUIDE.main_content.title} className={"text-center sm:block hidden"} />
                <HeadingFour headingCSSStyles={{ marginBottom: 0 }} text={RESOURCE_GUIDE.main_content.title} className={"text-center block sm:hidden"} />
                <TextExtraLarge fontWeight={400} text={RESOURCE_GUIDE.main_content.description} color='#9E9E9E' />
            </div>
            <div ref={ref_search} className='relative min-w-[500px]'>
                <LogoInputComponent
                    placeholder={"Search"}
                    value={filteredText}
                    prefixIcon={<SearchIcon fontSize='large' className='text-baseGray600' />}
                    className={"py-[0.4rem] text-[20px] max-w-[514px]"}
                    handleOnChange={(e) => {
                        setFilteredText(e.target.value)
                        setIsSearchOpen(true);
                    }}
                />
                {
                    isSearchOpen && filteredText?.trim()?.length > 0 && (
                        <div className='absolute bg-white border rounded-md shadow-md p-[1rem] min-w-full divide-y-[1px] z-10'>
                            {
                                list_of_dropdown_title?.length > 0
                                    ? (
                                        list_of_dropdown_title?.map((catg) => {
                                            return (
                                                <button
                                                    className='w-full text-start py-[0.2rem]'
                                                    onClick={() => navigate(`/product/home/resource-guide/${catg?.topicId}/${catg?.catgId}`)}
                                                >
                                                    <HeadingFive text={catg?.title} />
                                                </button>
                                            )
                                        })
                                    ) : (
                                        <SearchNotFound searchText={filteredText} fontSize={18} />
                                    )
                            }
                        </div>
                    )
                }
            </div>
            {/* {
                topicList?.length > 0
                    ? (
                        <div>
                            <TagSelection
                                activeTag={tag}
                                list_of_tags={topicList || []}
                                className=""
                                handleOnClick={(value) => setActiveTag(value)}
                            />
                        </div>
                    ) : null
            } */}
            <div className='w-full'>
                {
                    (cardList?.length === 0 && !!data === true)
                        ? <SearchNotFound searchText={filteredText} fontSize={18} />
                        : (
                            <>
                                <div className='grid grid-cols-2 gap-[40px] py-[10px] w-full'>
                                    {
                                        (api_status === "loading" ? Array.from({ length: 2 }) : cardList?.slice(0, 2))?.map((card, index) => {
                                            if (api_status === "loading") {
                                                return <CardSkeletonLoader key={index} />
                                            }
                                            return (
                                                <GuideQuestionListOfSubQuestion
                                                    key={index}
                                                    moduleIcon={`https://d1evyh1lx15d6l.cloudfront.net/images/help_guide/${card?.icon}`}
                                                    heading={card?.category}
                                                    isIconVisible={false}
                                                    description={`FAQ on ${card?.category}`}
                                                    subQuestionIcon={help_guide_icons.document}
                                                    listOfSubQuestions={data?.categorySummary?.[card?._id]?.qTopThree}
                                                    secondaryButtonName={`See all (${data?.categorySummary?.[card?._id]?.count})`}
                                                    handleOnClick={() => navigate(`/product/home/resource-guide/${card?.topicId}/${card?._id}`)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                <BlurContainer
                                    isShowBlurEffect={!(api_status === "loading" || !isFreeSubscription)}
                                    isShowButton
                                    className={'mt-[35px]'}
                                    text={paywall_locals.learn_concepts[upgradeRenewPaywallPopup].remaining_cards}
                                >
                                    <div className='grid grid-cols-2 gap-[40px] py-[10px] w-full'>
                                        {
                                            (api_status === "loading" ? Array.from({ length: 2 }) : cardList?.slice(2, isShowMore ? cardList?.length : 6))?.map((card, index) => {
                                                if (api_status === "loading") {
                                                    return <CardSkeletonLoader key={index} />
                                                }
                                                return (
                                                    <GuideQuestionListOfSubQuestion
                                                        key={index}
                                                        moduleIcon={`https://d1evyh1lx15d6l.cloudfront.net/images/help_guide/${card?.icon}`}
                                                        heading={card?.category}
                                                        isIconVisible={false}
                                                        description={`FAQ on ${card?.category}`}
                                                        subQuestionIcon={help_guide_icons.document}
                                                        listOfSubQuestions={data?.categorySummary?.[card?._id]?.qTopThree}
                                                        secondaryButtonName={`See all (${data?.categorySummary?.[card?._id]?.count})`}
                                                        handleOnClick={() => navigate(`/product/home/resource-guide/${card?.topicId}/${card?._id}`)}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </BlurContainer>
                            </>
                        )
                }
            </div>
            <div className='flex justify-between w-full'>
                <PrimaryButton onClick={() => navigate('/product/home')} text={"Back"} />
                {(!isShowMore && cardList.length>6) ? <PrimaryButton onClick={() => setisShowMore((prev) => !prev)} text={"Show More"} /> : null}
            </div>
        </div>
    )
}

export default ResourceGuide