import React, { useState } from 'react'
import { Modal } from "antd";
import oopsEmoji from "../../../assets/common/oopsEmoji.svg";
import classNames from "classnames";
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton';
import CloseIcon from '@mui/icons-material/Close';

const WarningOopsPopup = ({ description1, description2, description3, leftButton = "Cancel", rightButton="Done", children, footerStyle="flex justify-between", handleOnSuccess, handleOnCancel, isLoading, isDisabled }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleTogglePopup = () => setIsModalOpen((prev) => !prev)
    return (
        <React.Fragment>
            <Modal centered open={isModalOpen}>
                <div className='flex flex-col gap-y-0 justify-center items-center'>
                    <img src={oopsEmoji} alt="" className='mb-[24px]' />
                    <span className='text-center text-black'>{description1}</span>
                    {description2 && <span className='text-center text-black'>{description2}</span>}
                    {description3 && <span className='text-center text-black'>{description3}</span>}
                    <div className={classNames(
                        'mt-[24px] w-full',
                        footerStyle
                    )}>
                        <SecondaryButton text={leftButton} onClick={() => {
                            setIsModalOpen((prev) => !prev)
                            handleOnCancel();
                        }} />
                        <PrimaryButton isLoading={isLoading}  isDisabled={isDisabled} text={rightButton}  onClick={() => handleOnSuccess()} />
                    </div>
                    <button onClick={() => setIsModalOpen((prev) => !prev)}><CloseIcon className='absolute top-[1rem] right-[1rem]' /></button>
                </div>
            </Modal>
            {typeof children === "function" ? children({ handleTogglePopup }) : null}
        </React.Fragment>
    )
}

export default WarningOopsPopup