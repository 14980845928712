import React from 'react'
import { Modal, Button } from 'antd'
import TextComponent from '../reuseablecomponent/TextVarients/TextComponent'
import ButtonComponent from '../reuseablecomponent/ButtonVarients/ButtonComponent'
import close from "../../assets/common/close.svg";
import modalLocals from "../../localization/localization_en_modals.json"

const DeleteNestedCommentModal = ({ onCancel, onDelete, isOpen }) => {
    console.log(isOpen)
    return (
        <React.Fragment>
            <div className='z-[1000]'>
                <Modal
                    centered
                    open={isOpen}
                    width={485}
        
                >
                    <Button onClick={onCancel} className='h-auto absolute top-0 right-0 border-none'><img src={close} alt="" /></Button>
                    <TextComponent fontSize={17} fontWeight={600} text={modalLocals.delete_nested_comment.title} />
                    <div className='mt-[20px]'>
                        <TextComponent fontSize={14} fontWeight={500} text={modalLocals.delete_nested_comment.confirm_question} />
                        <TextComponent fontSize={14} fontWeight={500} text={modalLocals.delete_nested_comment.alert_msg} />
                    </div>
                    <div className='flex justify-between mt-[32px]'>
                        <ButtonComponent onClick={onCancel} type={"link"} isBorder={true} borderColor={"#757575"} bgColor={"#fff"} text={modalLocals.delete_nested_comment.button_text.cancel} textColor={"#757575"} />
                        <ButtonComponent onClick={onDelete} bgColor={"#F75555"} text={modalLocals.delete_nested_comment.button_text.confirm} textColor={"white"} />
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default DeleteNestedCommentModal