import React from 'react';

const FolderSVG = ({color="#fff",...props  }) => (
 

<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M24.8537 6.71984C24.2726 6.30055 23.6162 5.99724 22.9203 5.8265C22.4403 5.69317 21.947 5.6265 21.4403 5.6265H16.4803C15.707 5.6265 15.6537 5.55984 15.2403 5.01317L13.3737 2.53317C12.507 1.37317 11.827 0.666504 9.65366 0.666504H6.56033C3.30699 0.666504 0.666992 3.3065 0.666992 6.55984V21.4398C0.666992 24.6932 3.30699 27.3332 6.56033 27.3332H21.4403C24.6937 27.3332 27.3337 24.6932 27.3337 21.4398V11.5198C27.3337 9.53317 26.3603 7.7865 24.8537 6.71984ZM17.187 19.7865H10.8003C10.2803 19.7865 9.88033 19.3732 9.88033 18.8532C9.88033 18.3465 10.2803 17.9198 10.8003 17.9198H17.187C17.707 17.9198 18.1203 18.3465 18.1203 18.8532C18.1203 19.3732 17.707 19.7865 17.187 19.7865Z" fill={color}/>
</svg>


);

export default FolderSVG;
