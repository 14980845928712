import React from 'react'
import ImageCard from "../../../DesignSystem/Cards/ImageCard"
import { slides } from '../data'

const SlideCard = ({ activeSlideNumber }) => {
    return (
        <React.Fragment>
            <div className='w-[100%] relative bg-[#EEEEEE] h-full scrollbar-hide overflow-x-auto'>
                <ul className='flex flex-row gap-x-3 absolute h-full p-[12px]'>
                    {
                        slides.map((slide, index) => {
                            return (
                                <li key={index}>
                                    <ImageCard imgUrl={slide} alt="" isSelected={activeSlideNumber === index} />
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </React.Fragment>
    )
}

export default SlideCard