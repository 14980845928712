import React from 'react';
import { Helmet } from 'react-helmet';

const WebsiteHelmet = ({ title, description, keywords, previewImgUrl, ogUrl, imageWidth, imageHeight, author, publishedDate }) => {
  const absolutePreviewImgUrl = `${window.location.origin}${previewImgUrl}`;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={Array.isArray(keywords) ? keywords.join(', ') : ''} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={absolutePreviewImgUrl} />
      <meta property="og:url" content={ogUrl} />
      {imageWidth && <meta property="og:image:width" content={imageWidth} />}
      {imageHeight && <meta property="og:image:height" content={imageHeight} />}
      {author && <meta property="og:author" content={author} />}
      {publishedDate && <meta property="og:published_time" content={publishedDate} />}
    </Helmet>
  )
}

export default WebsiteHelmet;