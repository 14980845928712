import { Switch } from 'antd';
import "./index.css";

const SwitchButton = ({ isDisabled=false, checked = false, size = "large", isLoading=false, handleOnChange, defaultChecked }) => {
    return (
        <div>
            <Switch
                size={size}
                disabled={isDisabled}
                className='pg_switch'
                checked={checked}
                defaultChecked={defaultChecked}
                loading={isLoading}
                onChange={handleOnChange}
            />
        </div>
    );
}

export default SwitchButton