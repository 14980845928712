import React from 'react'
import { useNavigate } from "react-router-dom";
import HeadingSix from '../../../DesignSystem/TypographyHeadings/HeadingSix';
import settingLocals from "../../../localization/localization_en_setting.json"
import BaseModal from '../../../DesignSystem/Modals/BaseModal';
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium';
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';

const upgrade_access_plan = settingLocals.settings.tabs.Subscription.upgrade_access_plan;

const UnlockFullAccess = ({ onClick }) => {
    const navigate = useNavigate();
    return (
        <div>
            <BaseModal
                isOpen={true}
                isShowCloseButton
                handleCloseModal={onClick}
            >
                <div className='flex flex-col gap-y-[1.5rem]'>
                    <HeadingSix headingCSSStyles={{ margin: 0 }} text={upgrade_access_plan.title} />
                    <TextMedium text={upgrade_access_plan.description} fontWeight={500} />
                    <div className='flex justify-center'>
                        <PrimaryButton onClick={
                            () => {
                                onClick();//hide modal on click of upgrade or redirect to subscription
                                navigate('/product/home/setting/subscription');
                            }
                        } text={upgrade_access_plan.upgrade} />
                    </div>
                </div>
            </BaseModal>            
        </div>
    )
}

export default UnlockFullAccess