import React, { useState } from 'react'
import { shapes } from '../../assets/website'
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import PGButtonComponent from '../../DesignSystem/Buttons/PGButtonComponent';
import { useNavigate } from 'react-router-dom'

const { down_arrow, down_arrow_primary } = shapes;

const HeaderNavLink = ({ navlinks }) => {

    const navigate = useNavigate();

    const [activeNavLink, setActiveNavLink] = useState(null)

    return (
        <div className='flex xl:gap-x-[42px]'>

            {
                navlinks.map((navLinkSection, index) => {
                    return (
                        <div key={index} className='relative subnav'>
                            <PGButtonComponent
                                onMouseEnter={() => setActiveNavLink(index)}
                                onMouseLeave={() => setActiveNavLink(null)}
                                className='p-0 bg-transparent text-black gap-x-[4px] outline-none'
                            >
                                <button
                                    onClick={() => navLinkSection?.href ? navigate(navLinkSection?.href) : null}
                                >
                                    <TextLarge text={navLinkSection.title} fontWeight={500} className={"text-[#757575] hover:text-primary"} />
                                </button>
                                {navLinkSection?.sub_link?.length > 0 ? <img src={activeNavLink===index ? down_arrow_primary : down_arrow} className='' alt="" /> : null}
                            </PGButtonComponent>
                            {
                                navLinkSection?.sub_link?.length > 0
                                    ? (
                                        <ul className='absolute bg-white shadow-md border p-[0.5rem] flex flex-col gap-y-[1.5rem] subnav-content'>
                                            {
                                                navLinkSection?.sub_link?.map((link, index) => {
                                                    return (
                                                        <div className='w-[12rem] mb-1'>
                                                            <div key={index}>
                                                                <div onClick={() => navigate(link?.href)} className='hover:bg-primary hover:text-white text-primary p-[0.35rem] w-full transition-all cursor-pointer rounded-md'>{link.title}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </ul>
                                    ) : null
                            }
                        </div>
                    )
                })
            }

        </div>
    )
}

export default HeaderNavLink