export const filterDuplicateObjectProp = ({ list, targetProp = "id" }) => {
    try {
        return list.filter((individualObj, index, selfCheckOriginalArray) =>
            index === selfCheckOriginalArray.findIndex((ob) => (
                individualObj?.[targetProp] === ob?.[targetProp]
            ))
        )
    }
    catch (e) {
        throw new Error("Error ocurred while filtering");
    }
}