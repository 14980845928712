import React from 'react'
import { message, Modal } from "antd";
import TextInput from '../../../DesignSystem/Forms/InputVarients/TextInput'
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton'
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton'
import { API_CREATE_BUSINESS_MODAL } from '../businessModalSlice'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import DescribeFeature from '../../reuseablecomponent/DescribeFeature';

const CreateBusinessModalPopup = ({ isModalOpen, handleCloseModal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiStatus = useSelector((state) => state.businessModal.api_status.create_business_modal)
    const [businessModalName, setBusinessModalName] = React.useState(null);
    const isLoading = apiStatus.status === "loading";
    React.useEffect(() => {
        // if (apiStatus.status === "failed") message.error(apiStatus.message, 2)
        if (apiStatus.status === "success") {
            navigate('/product/home/business-modal/setting');
        } // we must send modal id while navigation
        //reset state
    }, [apiStatus.status])
    return (
        <React.Fragment>
            <Modal open={isModalOpen} centered>
                <DescribeFeature text={"Business Model Name"} description={""} />
                <TextInput maxCharLength={64} warning_message_name={"business_model_name"} isDisabled={isLoading} value={businessModalName} onChangeEvent={(e) => setBusinessModalName(e.target.value)} placeholder={"Enter Name"} />
                <div className='flex justify-between mt-[1rem]'>
                    <SecondaryButton onClick={handleCloseModal} isDisabled={isLoading} text={"Cancel"} />
                    <PrimaryButton onClick={() => {
                        if (businessModalName?.trim()?.length <= 30) {
                            dispatch(API_CREATE_BUSINESS_MODAL(businessModalName))
                        } else {
                            messageApi.warning('Character limit exceeded.', 2)
                        }
                    }} isLoading={isLoading} isDisabled={isLoading} text={"Create"} />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default CreateBusinessModalPopup