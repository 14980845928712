import React from 'react'
import youAreHere from "../../../../assets/common/youAreHere.svg"
import TextComponent from '../../../reuseablecomponent/TextVarients/TextComponent'
import locals from "../../../../localization/localization_en_stepper.json"
import LocationPointerAnimation from '../../../../DesignSystem/animations/location/LocationPointerAnimation';

const stepperLocals  = locals.stepper;

const Location = ({ topPosition }) => {
  return (
    <div className={`${topPosition}  ml-[14px]`}>
        <div className='w-[3rem]'><LocationPointerAnimation color={"#372EE5"} /></div>
        {/* <TextComponent color={"#372EE5"} fontSize={18} fontWeight={500} text={stepperLocals.captions.current_location_text} /> */}
    </div>
  )
}

export default Location