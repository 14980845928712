import React from 'react'
import MarketingSpendTable from '../subcomponents/MarketingSpendTable'
import EditableTable from '../subcomponents/EditableTable'
import CheckBoxInput from '../../../subcomponents/ROFormComponents/components/CheckBoxInput'
import RenderTableInputComponent from './RenderTableInputComponent'
import { useSelector } from "react-redux";
const GenerateForm = ({ type, payloadData }) => {
    const VIEW_MODE = useSelector((state) => state.businessModal.MODE_TYPE.view)
    switch(type){
        case 'marketing_spend_table': return <MarketingSpendTable payloadData={payloadData} />
        case 'editable_table': return <EditableTable VIEW_MODE={VIEW_MODE} payloadData={payloadData} />
        case 'inhouse_checkbox': return <CheckBoxInput isDisabled={VIEW_MODE} isReadOnly={VIEW_MODE} {...payloadData.checkBoxProps} />
        case 'number_input': return <div className='w-[84px]'><RenderTableInputComponent VIEW_MODE={VIEW_MODE} cellDetail= { { component_type: "number_input" } } {...payloadData.inputProps} /></div>
    }
}

export default GenerateForm