import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Space } from "antd";
import AdminDataRoom from "../../localization/data_room/localization_en_admin_data_room.json";
import MoveArrow from "../SVG/MoveArrowSVG";
import AcessPanel from "../SVG/AcessPanelSVG";
import AddTask from "../SVG/AddTaskSVG";
import Info from "../SVG/InfoSVG";
import Create from "../SVG/CreateSVG.jsx";
import DeleteOutlineSVG from "../SVG/DeleteOutlineSVG.jsx";
import DownloadOutlineSVG from "../SVG/DownloadOutlineSVG.jsx";
import ColorCodeSVG from "../SVG/ColorCodeSVG.jsx";
import DiscriptionSVG from "../SVG/DiscriptionSVG.jsx";
import LockOutlined from "../SVG/LockOutlineSVG.jsx";
import ArchiveOutline from "../SVG/ArchiveOutlineSVG.jsx";
import LinkOutline from "../SVG/LinkOutlineSVG.jsx";
import CopyOutline from "../SVG/CopyOutlineSVG.jsx";
import grayDots from "../../assets/common/grayDots.svg";
import ModalSection from "./ActionMenuOptionModal/ModalSection.jsx";
import UpdateOutlined from "../SVG/UpdateSVG.jsx";

const ActionMenuInvestorCard = ({documentId}) => {
  const [modalTitle, setModalTitle] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentList, setDocumentList] = useState(null)

  const handleMenuClick = (e) => {
    console.log("click", e);
    setModalTitle(AdminDataRoom.admin_action_menu.actionMenu[e.key]);
    setIsModalVisible(true);
    setDocumentList(documentId);
  };

  const closeModalHandler = () => {
    setIsModalVisible(false);
    setModalTitle(null);
  };

  const menuItems = [
    {
      label: (
        <Space className="py-2">
          <Info />
          {AdminDataRoom.admin_action_menu.actionMenu[4]}
        </Space>
      ),
      key: "24",
    },
    {
      label: (
        <Space className="py-2">
          <UpdateOutlined />{AdminDataRoom.admin_action_menu.actionMenu[22]}
        </Space>
      ),
      key: "22",
    },
  ];

  const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

  return (
    <Space wrap>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Space className="cursor-pointer opacity-75 hover:opacity-100">
          <img src={grayDots} onClick={handleMenuClick} />
        </Space>
      </Dropdown>
      {isModalVisible && (
        <ModalSection
          title={modalTitle}
          closeHandler={closeModalHandler}
          documentList={documentList} // Pass any required document list or other props
        />
      )}
    </Space>
  );
};

export default ActionMenuInvestorCard;
