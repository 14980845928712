import React from "react";
import { Table } from "antd";

const CompetitorOtherAttrTable = ({ columns, data }) => {
  return (
    <div>
      <div className=" w-[100%] mt-[0.7rem] mx-auto">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1100, // 800 Adjust this value to fit your content
            y: 440,
          }}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default CompetitorOtherAttrTable;
