import React from 'react'
import ToolTipCard from './ToolTipCard'
import { stepperToolTip } from '../data'
import DescriptionToolTip from './DescriptionToolTip'

const ToolTipModule = () => {
    return (
        <React.Fragment>
            <div className='fled flex-col max-w-[45%]'>
                <ul>
                    {
                        stepperToolTip.map((tooltip,index) => {
                            return (
                                <li key={index}>
                                    <DescriptionToolTip heading={tooltip.title} description={tooltip.description} />
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </React.Fragment>
    )
}

export default ToolTipModule