import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Empty, Skeleton } from "antd";
import "../index.css";
import DataRoomDashboardCard from "../DesignSystem/Cards/DataRoomDashboardCard";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import { getDetailsDirectoryApi, sharedWithMeApi } from "../features/dataRoomSlice";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import UserDataRoomFiles from "./UserDataRoomFiles";

export default function UserDataRoom() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const linkInfo = useSelector((state) => state.link);
    const activeItem = useSelector((state) => state.link.activeItem);
    const openKeys = useSelector((state) => state.link.openKeys);
    const username = useSelector((state) => state.user.value.username);
    const [featureData, setFeatureData] = useState({});
    const [flippedCards, setFlippedCards] = useState(
        new Array(featureData["cards"]?.length).fill(false)
    );
    const status = useSelector((state) => state.link.status);
    const progress = useSelector((state) => state.link.progress);
    const onboarding = useSelector((state) => state.onboarding.value.Company);
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [checkedCards, setCheckedCards] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [data, setData] = useState([]);
    const [detailsData, setDetailsData] = useState([]);
    const user = useSelector((state) => state.user);
    const [keysData, setKeysData] = useState([]);
    const [loading, setLoading] = useState(true); // Added loading state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { payload } = await dispatch(sharedWithMeApi());

                console.log(payload?.shareDirectoryInfoList[0]);

                const extractedData = payload?.shareDirectoryInfoList[0].map((obj) => {
                    console.log(obj);
                    return {
                        id: obj._id,
                        organisationId: obj.organisationId,
                        userId: obj.userId,
                        name: obj.name,
                        path: obj.path,
                        type: obj.type,
                        archiveStatus: obj.archiveStatus,
                        size: obj.size,
                        color: obj.color === "" ? "grey" : obj.color,
                        description: obj.description,
                        createdAt: obj.createdAt
                    };
                });

                setKeysData(extractedData);

                const promises = extractedData.map(async (value, index) => {
                    const body = { documentId: value.id };
                    const response = await dispatch(getDetailsDirectoryApi(body));
                    const additionalData = response?.payload?.filesListCount;
                    return {
                        ...value,
                        filesCount: additionalData
                    };
                });

                const updatedData = await Promise.all(promises);
                console.log(updatedData);
                setKeysData(updatedData);
                setLoading(false); // Set loading to false when data is loaded
            } catch (error) {
                console.error("Failed to list directory", error);
                setError(error);
            } finally {
                setLoading(false)
            }
        };

        fetchData();
    }, [dispatch]);

    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card.id !== cardDetails.id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);

    // Modal
    const [modalTitle, setModalTitle] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleButtonClick = () => {
        setModalTitle("Add Folder"); // Change the index as needed
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };

    const onDownloadClick = () => {
        console.log(checkedCardDetails[0]);
        setIsModalVisible(true);
        setModalTitle("Info");
    };

    const OnDeleteClick = () => {
        setIsModalVisible(true);
        setModalTitle("Delete");
    };

    return (
        <div className="pt-[6rem] min-h-[100vh]">
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                    }}
                    documentList={data}
                    text={"This feature is not currently available for this version of the website."}
                />
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
                <div className="mx-auto">
                    <DescribeFeature
                        text={"Shared With Me"}
                        description={"Access and manage folders relevant to your investors, and control which folders are sent to each investor."}
                    />
                    <div className="flex justify-between items-center">
                        <p className="font-DMSans text-[#111827] text-[18px] mt-6">
                            Folders
                        </p>
                        <div className="flex gap-2 items-center">
                            <Button
                                icon={<SearchOutlined />}
                                className="flex items-center border-none justify-center bg-[#F5F5F5] text-[#372EE5]"
                            />
                        </div>
                    </div>
                    <div className="flex gap-5 mt-4">
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            disabled={buttonDisabled}
                            className="bg-[#372EE5]"
                            onClick={onDownloadClick}
                        >
                            Download
                        </Button>
                    </div>
                    {loading ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-7 w-full my-5 gap-5">
                            {Array.from({ length: 6 }).map((_, index) => (
                                <Skeleton.Input
                                    key={index}
                                    active
                                    style={{ width: 250, height: 110, borderRadius: 12 }}
                                />
                            ))}
                        </div>
                    ) : keysData.length === 0 ? (
                        <Empty description="No Data Available" className="w-full h-full flex items-center justify-center my-20" />
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-7 w-full my-5 gap-5">
                            {keysData.map((card, index) => (
                                <DataRoomDashboardCard
                                    key={index}
                                    cardTitle={card.name}
                                    color={card.color}
                                    onCheckboxChange={handleCardCheckboxChange}
                                    id={card.id}
                                    hasPermission={user.value.isAdmin}
                                    fileCount={card?.filesCount || 0}
                                    sharedCount={0}
                                    role={"user"}
                                />
                            ))}
                        </div>
                    )}
                    <div className="flex justify-between items-center">
                        <p className="font-DMSans text-[#111827] text-[18px] mt-6">
                            Files
                        </p>
                    </div>
                   <UserDataRoomFiles /> 
                </div>
            </div>
        </div>
    );
}


