import React from 'react'
import { message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import profile_green from "../../assets/PSM/profile_green.svg"
import MessageInput from './subcomponents/MessageInput';
import ConversationList from './subcomponents/ConversationList';
import {
    D_ResetCommentState,
    D_UpdateMessageText,
    D_ResetLoadingStatus,
    createParentCommentApi,
    deleteParentCommentApi,
    deleteChildrenCommentApi,
    D_UpdateActiveChatDetail,
    updateParentCommentApi,
    updateChildrenCommentApi,
    defaultCommentModalPayload,
    fetchComments
} from './chatSlice';
import ConfirmModal from '../../DesignSystem/Modals/ConfirmModal';
import InputTextModal from '../../DesignSystem/Modals/InputTextModal';
import ArrowCloseConversation from '../../assets/PSM/ArrowCloseConversation.svg'
import locals from "../../localization/localization_en_chat.json"

const EDIT_MODAL_LOCALS = locals.modal.edit
const DELETE_MODAL_LOCALS = locals.modal.delete

const CommentFeature = () => {

    const dispatch = useDispatch();

    const chat = useSelector((state) => state.chat);
    const [editMsg, setEditMsg] = React.useState(chat.modalDetail.editModalMessage)
    const { id, avatar, username } = useSelector((state) => state.user.value)
    const { isModalOpen, isParentComment, ACTION_TYPE, activeChatId, editModalMessage } = chat.modalDetail

    React.useEffect(() => {
        if(chat.loadingStatus==="failed"){
            message.destroy();
            message.open({ type: 'error', content: chat?.errorMessage, duration: 3});
            dispatch(D_ResetLoadingStatus());
        }
    }, [chat.loadingStatus])

    React.useEffect(() => setEditMsg(editModalMessage), [editModalMessage])

    React.useEffect(() => {
        return () => dispatch(D_ResetCommentState())
    }, [])
    React.useEffect(() => {
        dispatch(D_ResetLoadingStatus());
        dispatch(fetchComments());
    }, [chat?.tableCellOrRowId])


    const onChangeParentComment = (value) => dispatch(D_UpdateMessageText({ "key": "message", value }))
    const submitParentComment = () => dispatch(createParentCommentApi())

    const handlePerformAction = () => {
        if (ACTION_TYPE === "DELETE") {
            if (isParentComment) {
                dispatch(deleteParentCommentApi());
                return;
            }
            dispatch(deleteChildrenCommentApi());
        }
        if (ACTION_TYPE === "EDIT") {
            if (isParentComment) {
                dispatch(updateParentCommentApi(editMsg));
                return;
            }
            dispatch(updateChildrenCommentApi(editMsg));
        }
    }


    return (
        <div className='w-[300px] border h-full p-5 flex flex-col gap-y-[0.5rem] relative'>

            <button
                onClick={() => dispatch(D_ResetCommentState())}
                className='absolute top-1/2 -translate-y-1/2 -left-[1.2rem]'
            >
                <img src={ArrowCloseConversation} alt="" />
            </button>

            <ConfirmModal
                isOpen={isModalOpen && ACTION_TYPE === "DELETE"}
                title={DELETE_MODAL_LOCALS.title}
                description1={DELETE_MODAL_LOCALS.description1}
                description2={DELETE_MODAL_LOCALS.description2}
                confirmButtonName={DELETE_MODAL_LOCALS.confirm_button}
                isLoading={chat.loadingStatus==="loading"}
                isDisabled={chat.loadingStatus==="loading"}
                onCancel={() => dispatch(D_UpdateActiveChatDetail(defaultCommentModalPayload))}
                onConfirm={() => handlePerformAction()}
            />

            <InputTextModal
                isOpen={isModalOpen && ACTION_TYPE === "EDIT"}
                value={editMsg}
                title={EDIT_MODAL_LOCALS.title}
                placeHolder={EDIT_MODAL_LOCALS.placeholder}
                onChangeEvent={(e) => setEditMsg(e.target.value)}
                confirmButtonName={EDIT_MODAL_LOCALS.confirm_button}
                isLoading={chat.loadingStatus==="loading"}
                isDisabled={chat.loadingStatus==="loading"}
                onCancel={() => dispatch(D_UpdateActiveChatDetail(defaultCommentModalPayload))}
                onConfirm={() => handlePerformAction()}
            />

            <MessageInput
                avatar={avatar}
                username={username}
                isLoading={chat.loadingStatus==="loading"}
                message={chat.message}
                placeHolderAvatar={profile_green}
                submitParentComment={submitParentComment}
                onChangeParentComment={onChangeParentComment}
            />

            <hr />

            <div className='overflow-y-auto scrollbar-hide'>
                <ConversationList
                    chat={chat}
                    comment_list={chat.chatList}
                    presentActiveUser={{ _id: id, avatar, username }}
                />
            </div>


        </div>
    )
}

export default CommentFeature

// const data = [
//     {
//         _id: "mongo_id_parent_comment_1",
//         user: { _id: "user_id_1", username: "user1", avatar: "" },
//         message: "parent_message_1",
//         childrenComments: [
//             {
//                 _id: "mongo_id_parent_comment_1",
//                 user: { _id: "user_id_1", username: "user1", avatar: "" },
//                 message: "children_message_11",
//                 updatedAt: "2024-04-22T05:57:20.756"
//             },
//             {
//                 _id: "mongo_id_parent_comment_1",
//                 user: { _id: "user_id_1", username: "user1", avatar: "" },
//                 message: "children_message_12",
//                 updatedAt: "2024-04-22T05:57:20.756"
//             }
//         ],
//         updatedAt: "2024-04-22T05:57:20.756"
//     },
//     {
//         _id: "mongo_id_parent_comment_2",
//         user: { _id: "user_id_1", username: "user2", avatar: "" },
//         message: "parent_message_2",
//         childrenComments: [
//             {
//                 _id: "mongo_id_parent_comment_2",
//                 user: { _id: "user_id_2", username: "user2", avatar: "" },
//                 message: "children_message_21",
//                 updatedAt: "2024-04-22T05:57:20.756"
//             }
//         ],
//         updatedAt: "2024-04-22T05:57:20.756"
//     }
// ]