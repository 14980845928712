import React from 'react'
import { RingProgress } from '@ant-design/plots';
import onboardingLocals from "../localization/localization_en_onboarding.json"
import useInviteTeamMember from '../hooks/useInviteTeamMember';
import oopsEmoji from "../assets/common/oopsEmoji.svg";
import PrimaryButton from '../DesignSystem/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import useApp from '../hooks/useApp';

const OnboardingRingProgress = () => {
  const config = {
    height: 100,
    width: 100,
    autoFit: false,
    percent: 0.7,
    color: ['#372ee5'],
    statistic: {
      content: {
        formatter: () => '100%',
        style: {
          fontSize: 24,
          fontWeight: 'semiBold',
          color: '#372ee5',
        },
      },
    },
    animate: {
      duration: 2000,
      easing: 'easeInOutQuad',
    },
  };
  return <RingProgress {...config} />;
};

const OnboardingCompleteMobile = ({ type = "authview" }) => {
  const navigate = useNavigate();
  const { handleLogout } = useApp();
  useInviteTeamMember();
  return (
    <div className='min-h-[100vh] flex flex-col justify-center items-center'>
      {
        type === "authview" && (
          <div className='flex flex-col justify-center items-center'>
            <OnboardingRingProgress />
            <p className='text-primary font-DMSans text-[20px] pt-[11px]'>{onboardingLocals.onboarding.mobile_text.greet_msg}</p>
          </div>
        )
      }
      <div className='text-[#111827] font-DMSans font-medium text-center mx-[24px] text-[20px] mt-[3rem]'>
        {
          type === "authview" ? (
            <p className='text-primary font-DMSans text-[20px] pt-[11px]'>{onboardingLocals.onboarding.mobile_text.guidance_msg1}</p>
          ) : (
            <img src={oopsEmoji} className='mx-auto pb-[11px]' alt="" />
          )
        }
        <p className='font-DMSans text-[20px] pt-[11px]'>{onboardingLocals.onboarding.mobile_text.guidance_msg2}</p>
        {
          type === "authview"
            ? null
            : <PrimaryButton onClick={() => navigate('/')} text={onboardingLocals.onboarding.mobile_text.cta_to_home} />
        }
        {
          type === "authview" && (
            <p className='font-DMSans text-[20px] pt-[11px]'>
              {onboardingLocals.onboarding.mobile_text.guidance_msg3}
              <span onClick={() => {
                handleLogout();
                navigate('/');
              }} className='text-primary'> {onboardingLocals.onboarding.mobile_text.enquiry_email}</span>
            </p>
          )
        }
      </div>
    </div>
  )
}

export default OnboardingCompleteMobile