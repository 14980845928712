import classNames from 'classnames'
import React from 'react'
import TextExtraLarge from '../../TypographyDescriptions/TextExtraLarge'

const IconLabel = ({ icon, text }) => {
    return (
        <div className={classNames(
            'flex items-center gap-x-[12px] pl-[16px] pr-[40px] py-[20px] rounded-[8px] bg-white relative border-t-[4px] border-t-p3 border-[1.5px] border-p3 h-full'
        )}>
            <img className='w-[40px]' src={icon} alt="" />
            <TextExtraLarge
                text={text}
            />
        </div>
    )
}

export default IconLabel