import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { API_GET_WORKIN_CAP_DEBT_CASHFLOW_PL_BALANCE_SHEET_ONBOARDING_SCREEN_SLICE, D_Reset_Screen_Screen_Api_Status } from '../components/BusinessModal/businessModalSlice';
import { D_Update_Screen_Screen_DebtWorking, D_Reset_DebtWorking_State } from '../components/BusinessModal/debtWorkingSlice';
import { D_Update_Screen_Screen_WorkingCap, D_Reset_WorkingCapital_State } from '../components/BusinessModal/workingCapitalSlice';
import { D_Update_Screen_Screen_CashFlow, D_Reset_CashFlow_State } from '../components/BusinessModal/cashFlowSlice';
import { D_Update_Screen_Screen_BalanceSheet, D_Reset_BalanceSheet_State } from '../components/BusinessModal/balanceSheetSlice';
import { D_Reset_Cost_Onboarding_State } from '../components/BusinessModal/costSlice';
import { D_Reset_P_AND_L_State } from '../components/BusinessModal/pAndLSlice';
import { D_Update_Screen_Screen_pAndL } from '../components/BusinessModal/pAndLSlice';

const useFetchBMOtherScreen = () => {
    const dispatch = useDispatch();
    const [screen, setScreen] = useState(null);
    const [prevScreen, setPrevScreen] = useState(null);
    const api_status = useSelector((state) => state.businessModal.fetch_screen_screen_bm_api_status)
    const screen_data = useSelector((state) => state.businessModal.fetch_screen_screen_data)
    const MODE_TYPE = useSelector((state) => state.businessModal.MODE_TYPE)

    useEffect(() => {
        dispatch(D_Reset_Screen_Screen_Api_Status());
    }, [])

    useEffect(() => {
        if (api_status.status === "success") {
            dispatch(D_Reset_Screen_Screen_Api_Status());
            //reset screen data
            let isInputPresent = screen_data?.isInputPresent
            if(isInputPresent){
                if(screen_data.previousScreen==="costScreen"){
                    dispatch(D_Reset_Cost_Onboarding_State());
                }else if(screen_data.previousScreen==="debtWorkingScreen"){
                    dispatch(D_Reset_DebtWorking_State());
                }else if(screen_data.previousScreen==="workingCapitalScreen"){
                    dispatch(D_Reset_WorkingCapital_State());
                }
                else if(screen_data.previousScreen==="cashFlowScreen"){
                    dispatch(D_Reset_CashFlow_State());
                }
                else if(screen_data.previousScreen==="balanceSheetScreen"){
                    dispatch(D_Reset_BalanceSheet_State());
                }else if(screen_data.previousScreen==="pnlScreen"){
                    dispatch(D_Reset_P_AND_L_State());
                }
            }
            //update new data
            if(screen_data.activeScreen==="debtWorkingScreen"){
                dispatch(D_Update_Screen_Screen_DebtWorking(screen_data));
            }else if(screen_data.activeScreen==="workingCapitalScreen"){
                dispatch(D_Update_Screen_Screen_WorkingCap(screen_data));
            }
            else if(screen_data.activeScreen==="cashFlowScreen"){
                dispatch(D_Update_Screen_Screen_CashFlow(screen_data));
            }
            else if(screen_data.activeScreen==="balanceSheetScreen"){
                dispatch(D_Update_Screen_Screen_BalanceSheet(screen_data));
            }else if(screen_data.activeScreen==="pnlScreen"){
                console.log(screen_data,"screen_data")
                dispatch(D_Update_Screen_Screen_pAndL(screen_data));
            }
        }
    }, [api_status.status])

    const fetchTargetScreenData = ({ screenName, previousScreen }) => {
        setScreen(screenName);
        dispatch(API_GET_WORKIN_CAP_DEBT_CASHFLOW_PL_BALANCE_SHEET_ONBOARDING_SCREEN_SLICE({ "activeScreen": screenName, "previousScreen": previousScreen }));
    }

    return {
        "isFutureInputPresent": screen_data?.isInputPresent,
        fetchTargetScreenData,
        "activeModeType": MODE_TYPE,
        "api_status": api_status.status,
    }
}

export default useFetchBMOtherScreen