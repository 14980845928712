export function commaSeparatorNumberFormat({ number, prefix='', suffix='' }) {
    try {
        // base condition
        if (number === null || number === undefined || number === 0) {
            return typeof (number) === "number" ? `${prefix}${number}${suffix}` : number;
        }
        let stringNumber = String(number);
        // seperate decimal and whole number
        if(stringNumber.includes('.')){
            if(stringNumber.split('.')[0].length<=3){
                return `${prefix} ${stringNumber}${suffix}`
            }
            stringNumber = stringNumber.split('.')[0];
        }
        // seperate whole numbers and negative symbol
        if(stringNumber.includes('-')){
            stringNumber = stringNumber.split('-')[1]
        }
        // main condition - function only whole number
        let formattedNumber = '';
        let counter = 0;

        while (stringNumber.length !== 0) {
            formattedNumber = String(Number(stringNumber) % 10) + String(formattedNumber);
            stringNumber = String(stringNumber.slice(0, stringNumber.length - 1))
            counter = counter + 1;
            if (counter % 3 === 0) {
                formattedNumber = ',' + formattedNumber
            }
        }

        formattedNumber.trim();
        formattedNumber = formattedNumber[0] === ',' ? formattedNumber.slice(1) : formattedNumber

        if(String(number).includes('.')){
            return (
                !String(number).includes('-')
                ? `${prefix} ${String(formattedNumber)+'.'+String(number).split('.')[1]}${suffix}` 
                : `${prefix} ${'-'+String(formattedNumber)+'.'+String(number).split('.')[1]}${suffix}`
            )
        }else{
            return (
                !String(number).includes('-')
                ? `${prefix} ${formattedNumber}${suffix}`
                : `${prefix} ${'-'+formattedNumber}${suffix}`
            )
        }
    } catch (e) {
        console.log(e);
        return number;
    }
}
// const test = [1, 12, 123, 1234, 12345, 123456, 1234567, 123456789, 12345678910]
// test.map((t) => {
//     console.log(commaSeparatorNumberFormat({ number: t, prefix: "$" }), t)
// })