import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import DefineForm from "./components/DefineForm"
import useCheckFormFillStatus from './hooks/useCheckFormFillStatus';
import ComponentErrorBoundary from '../../../reuseablecomponent/ErrorBoundary/ComponentErrorBoundary';

export const checkIfValueExists = ({ channelsData, depsData }) => {
    let isPresent = false;
    depsData.forEach((deps) => {
        if(channelsData.includes(deps)){
            isPresent = true;
        }
    })
    return isPresent;
}

const refineFormColumn = ({ formItem, channel_sales }) => {
    const Channel_Names = channel_sales; //["E-Commerce","Social Media","Website & Mobile App"]
    let filteredColumnForm = [];
    filteredColumnForm = {
        ...formItem,
        "table": {
            ...formItem.table,
            "columns": formItem.table.columns.filter((col) => "control_deps" in col ? checkIfValueExists({ "channelsData": Channel_Names, "depsData": col["control_deps"] }) : true)
        }
    }
    return filteredColumnForm
}

const COScreen2 = ({ formData, counterToTriggerFormStatus, isFormFilledAsExpected }) => {
    const costScreen = useSelector((state) => state.costScreen);
    const channel_sales = costScreen.channel_sales;
    useCheckFormFillStatus({ formData, counterToTriggerFormStatus, isFormFilledAsExpected });
    return (
        <div className='flex flex-col gap-y-[24px]'>
            {
                formData.data.map((formItem, index) => {
                    const filteredColumn = refineFormColumn({ formItem, channel_sales })
                    if(index===1 && !costScreen["incurring_direct_cost"].find((row) => row.name === "Reverse Logistics Costs")) return;
                    return (
                        <ComponentErrorBoundary>
                            <DefineForm key={index} formItem={filteredColumn} />
                        </ComponentErrorBoundary>
                    )
                })
            }
        </div>
    )
}

export default COScreen2