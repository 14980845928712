import React from "react";

const DownloadOutlineSVG = ({ color = "#fff", ...props }) => (
  
<svg width="20" height="20" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 6H10V0H4V6H0L7 13L14 6ZM6 8V2H8V8H9.17L7 10.17L4.83 8H6ZM0 15H14V17H0V15Z" fill="#424242"/>
</svg>

);
export default DownloadOutlineSVG;
