export function calculateTMAPieChart(segmentData, noOfSegments) {
    try {
        // Calculate CLV for each segment
        if (!segmentData) {
            return { "totalMarketSize": 0, "data": [] };
        }
        let calculatedSegmentData = segmentData.map((segment) => ({
            segmentName: segment.segmentName,
            CLV: segment.APV * segment.PF * segment.ACL,
            customers: segment.Customers,
        }));
        // Calculate total value of each segment
        calculatedSegmentData.forEach((segment) => {
            segment.totalValue = segment.CLV * segment.customers;
        });
        calculatedSegmentData = calculatedSegmentData.sort((a, b) => a.totalValue-b.totalValue).slice(0, noOfSegments ?? segmentData.length);
        // Calculate total value of all segments combined
        const totalMarketSize = calculatedSegmentData.reduce(
            (total, segment) => total + segment.totalValue,
            0
        );
        // Calculate percentages for each segment
        calculatedSegmentData.forEach((segment) => {
            segment.percentage = (segment.totalValue / totalMarketSize) * 100;
        });
        if (!calculatedSegmentData) {
            return { "totalMarketSize": 0, "data": [] };
        }
        const finalData = calculatedSegmentData.map((segment, i) => ({
            type: segment.segmentName,
            value: Math.round(segment.percentage),
            segValue: segment.totalValue
        }));
        return { totalMarketSize, "data": finalData }
    } catch (error) {
        console.log(error);
        return { "totalMarketSize": 0, "data": [] };
    }
}