import { useEffect } from 'react'

const useOutsideClick = (watchRef, callBack) => {
    const handleClick = (e) => {
        if(watchRef.current && !watchRef.current.contains(e.target)){
            callBack();
        }
    }
    useEffect(()=>{
        document.addEventListener('mousedown',handleClick);
        return () => document.removeEventListener('mousedown',handleClick);
    })
}

export default useOutsideClick