import React from 'react'
import { Switch } from 'antd';
import { cn } from '../../utils/cn'
import SwitchButton from '../../DesignSystem/Buttons/SwitchButton';
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import TextExtraLarge from '../../DesignSystem/TypographyDescriptions/TextExtraLarge';
import TextSmall from '../../DesignSystem/TypographyDescriptions/TextSmall';

const TogglePricingComponent = ({ className, locals, isChecked, handleOnChange }) => {
    return (
        <div className={cn('border rounded-md border-p6 p-[8px] w-[275px] mx-auto flex flex-col items-center gap-y-[12px]', className)}>
            <TextExtraLarge text={locals.title} color='#372EE5' />
            <div className='flex items-center justify-center gap-x-[8px]'>
                <TextLarge fontWeight={500} color={!isChecked ? "#372EE5" : "#757575"} text={locals.monthly.label} />
                <SwitchButton checked={isChecked} handleOnChange={handleOnChange} />
                <TextLarge fontWeight={500} color={isChecked ? "#372EE5" : "#757575"} text={locals.yearly.label}/>
            </div>
            <TextSmall className={'italic'} fontWeight={500} color='#757575' text={locals.disclaimer} />
        </div>
    )
}

export default TogglePricingComponent