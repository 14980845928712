import React from 'react'
import HeadingFour from '../../TypographyHeadings/HeadingFour'
import TextMedium from '../../TypographyDescriptions/TextMedium'
import TextSmall from '../../TypographyDescriptions/TextSmall'
import ResponsiveHeadingFourInter from '../../TypographyHeadings/ResponsiveHeadingFourInter'

const ImgTextDescription = ({ image, heading, subheading1, subeheading2, description1, description2, banner_bg_color }) => {
    return (
        <div className='rounded-[24px] flex flex-col gap-y-[12px] overflow-hidden'>
            <div className='h-[240px]' style={{ "backgroundColor": banner_bg_color }}>
                <img src={image} style={{ maxWidth: '100%', maxHeight: '100%' }} className='mx-auto' alt="" />
            </div>
            <div className='flex flex-col gap-y-[0.5rem] px-[16px]'>
                <ResponsiveHeadingFourInter color={"#372EE5"} text={heading} />
                <div className='flex flex-col gap-y-[16px]'>
                    <div className='flex flex-col gap-y-[8px]'>
                        <div className='w-full border-b border-b-primary'>
                            <TextMedium fontWeight={700} color='#372EE5' text={subheading1} />
                        </div>
                        <TextSmall fontWeight={500} text={description1} />
                    </div>
                    <div className='flex flex-col gap-y-[8px]'>
                        <div className='w-full border-b border-b-primary'>
                            <TextMedium fontWeight={700} color='#372EE5' text={subeheading2} />
                        </div>
                        <TextSmall fontWeight={500} text={description2} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImgTextDescription