import React from 'react'
import { message } from "antd";
import SelectOption from './SelectOption'
import NumericInput from './InputVarients/NumericInput'
import TextLarge from '../TypographyDescriptions/TextLarge'
import { numberValidation } from '../../utils/numberValidation';
import appLocals from '../../localization/localization_en_app.json'
import CustomNumber from '../../components/BusinessModal/subcomponents/ROFormComponents/components/CustomNumber'
import { ValidateNumber } from '../../components/BusinessModal/components/CostOnboarding/utils/ValidateNumber';
import TextSmall from '../TypographyDescriptions/TextSmall';

const Validation_Warning_Message = appLocals.app.warning_message

export const IndexLabel = ({ index, styles }) => {
    return (
        <div
            style={{
                backgroundColor: "#EBF1FF",
                borderColor: "#372EE5",
                color: "#372EE5",
                borderWidth: "1px",
                ...styles
            }}
            className='min-w-[28px] max-h-[28px] aspect-square rounded-full flex items-center justify-center'
        >
            {index}
        </div>
    )
}

const SelectOptionLabelLeft = ({ text, value, form, onChangeEvent }) => {
    return (
        <div>
            <TextLarge text={text} fontWeight={500} />
            <SelectOption
                name={form.name}
                value={value}
                listOfOptions={form.options}
                onChangeEvent={(value) => onChangeEvent(value)}
                placeholder={form.placeholder}
            />
        </div>
    )
}

export const InputNumber = ({ value, form, styles, onBlurEvent }) => {
    return (
        <div>
            <div style={{ ...styles }}>
                <CustomNumber
                    borderColor={form.borderColor}
                    constrain={form.validator}
                    isReadOnly={form.isReadOnly}
                    isDisabled={form.isDisabled}
                    name={form.name}
                    styles={{ padding: 0 }}
                    placeholder={form.placeholder}
                    isStrictPercentageCheck={form.isStrictPercentageCheck}
                    value={value}
                    handleOnBlur={(event) => {
                        const numberValue = event.target.value;
                        if (["positive_decimal_number", "positive_negative_decimal_number", "positive_number", "positive_number_min_1_max_12", "positive_number_min_1"].includes(form?.validator)) {
                            message.destroy()
                            const Validation_Status = ValidateNumber({ "validation_type": form.validator, "value": numberValue, "decimal_place": form.decimal_place });
                            if (Validation_Status.status) {
                                const percentageCheck = numberValidation({ value: String(Validation_Status.value), "placeHolder": form.placeholder, "validator": form?.validator, isStrictPercentageCheck: form.isStrictPercentageCheck })
                                if(String(Validation_Status.value).length!==String(numberValue).length){
                                    onBlurEvent(null);
                                    message.open({ type: 'warning', content: Validation_Warning_Message["positive_decimal_with_limit_decimal_number"].replace('NUMBER_DECIMAL', 2), duration: 3 });
                                    return;
                                }
                                if (!percentageCheck.status) {
                                    message.open({ type: 'warning', content: percentageCheck.message, duration: 3 });
                                    return;
                                }
                                onBlurEvent(Validation_Status.value)
                            } else {
                                onBlurEvent(null)
                                const percentageCheck = numberValidation({ value: String(Validation_Status.value), "placeHolder": form.placeholder, "validator": form?.validator, isStrictPercentageCheck: form.isStrictPercentageCheck })
                                if (!percentageCheck.status && event.target.value) {
                                    message.open({ type: 'warning', content: percentageCheck.message, duration: 3 });
                                    return;
                                }
                                if (event.target.value) {
                                    form.validator && Validation_Warning_Message?.[form.validator] && message.open({ type: 'warning', content: Validation_Warning_Message[form.validator], duration: 3 });
                                }
                            }
                        }
                    }}
                />
            </div>
        </div>
    )
}

const FormComponentProvider = ({
    component_type, formNumber,
    text, value, form, styles,
    onChangeEvent, onBlurEvent
}) => {
    switch (component_type) {
        case 'IndexLabel': {
            return <IndexLabel styles={styles} index={formNumber} />
        }
        case 'SelectOptionLabelLeft': {
            return <SelectOptionLabelLeft text={text} value={value} form={form} onChangeEvent={onChangeEvent} />
        }
        case 'InputNumber': {
            return <InputNumber styles={styles} label={text} value={value} form={form} onBlurEvent={onBlurEvent} />
        }
    }
}

export default FormComponentProvider