export const IdentifyReadOnly = ({ name, record }) => {
    try {
        let isDeleteButtonReadOnly = false;
        let isReadOnly = false;
        if (name === "marketing_spend") {
            if (["E-Commerce", "Social Media", "Website & Mobile App"].includes(record.name)) {
                isReadOnly = true
                isDeleteButtonReadOnly = true;
            } else {
                isReadOnly = "name" in record ? !!record["name"] === false : false;
                isDeleteButtonReadOnly = false;
            }
        } else {
            isReadOnly = "name" in record ? !!record["name"] === false : false;
            isDeleteButtonReadOnly = false;
        }
        return { isReadOnly, isDeleteButtonReadOnly }
    } catch (err) {
        return { "isReadOnly": false, "isDeleteButtonReadOnly": false };
    }
}