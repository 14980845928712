import React from 'react'
import classNames from 'classnames'
import HeadingSix from '../../TypographyHeadings/HeadingSix'

const PlaneCard = ({ title, isActive, description, handleOnClick }) => {
    const [isHovered, setIsHovered] = React.useState(false);
    return (
        <div
            // onClick={() => handleOnClick()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classNames(
                'xl:p-[16px] p-[12px] flex flex-col gap-y-[8px] items-center justify-start border border-baseGray500 hover:border-p5 rounded-[8px] hover:bg-p5/20 transition-all cursor-pointer',
                { 'border-p5 bg-p5/20' : isActive }
            )}
        >
            <HeadingSix headingCSSStyles={{ marginBottom: 0 }} text={title} className={classNames('text-baseGray500 text-center', { 'text-p3' : isHovered || isActive})} />
            <span className={classNames('text-center text-[14px]', {'text-p5' : isHovered || isActive }, { 'text-baseGray500': !isHovered })}>{description}</span>
        </div>
    )
}

export default PlaneCard