import React, { useState } from "react";
import { Dropdown, Menu, Space } from "antd";
import AdminDataRoom from "../../localization/data_room/localization_en_admin_data_room.json";
import Info from "../SVG/InfoSVG";
import DeleteOutlineSVG from "../SVG/DeleteOutlineSVG.jsx";
import grayDots from "../../assets/common/grayDots.svg";
import ModalSection from "./ActionMenuOptionModal/ModalSection.jsx";
import EditIcon from '@mui/icons-material/Edit';

const ActionMenuInvestorFlowCard = ({data}) => {
  const [modalTitle, setModalTitle] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleMenuClick = (e) => {
    console.log("click", e);
    setModalTitle(AdminDataRoom.admin_action_menu.actionMenu[e.key]);
    setIsModalVisible(true);
  };

  const closeModalHandler = () => {
    setIsModalVisible(false);
    setModalTitle(null);
  };

  const handleDeleteClick = () => {
      setModalTitle("Info");
      setIsModalVisible(true);
  }

  const menuItems = [
    {
      label: (
        <Space className="p-2">
          <EditIcon />{"Edit"}
        </Space>
      ),
      key: "23",
    },
  ];
  

  const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

  return (
    <Space wrap>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Space className="cursor-pointer opacity-75 hover:opacity-100">
          <img src={grayDots} onClick={handleMenuClick} />
        </Space>
      </Dropdown>
      {isModalVisible && (
        <ModalSection
          title={modalTitle}
          closeHandler={closeModalHandler}
          documentList={data}
          text={"This feature is not currently available for this version of the app"}
        />
      )}
    </Space>
  );
};

export default ActionMenuInvestorFlowCard;
