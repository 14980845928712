import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { Modal, message, Input } from "antd";
import Notification from '../Notification';
import close from "../../../../assets/common/close.svg";
import TextInput from '../../../../DesignSystem/Forms/InputVarients/TextInput';
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton';
import HeadingFive from '../../../../DesignSystem/TypographyHeadings/HeadingFive';
import Edit from "../../../../assets/common/editDark.svg"
import bmLocals from "../../../../localization/localization_en_business_modal.json"
import OopsPopUp from '../../../reuseablecomponent/PopUpVarients/OopsPopUp';

const MAX_TAG_EXCEEDED = bmLocals.business_modal.warning_tooltip;


const ListModal = ({
    Title,
    ListData,
    ApiStatus,
    tooltipMessages,
    warningDataIndex,
    isTagModalOpen,
    InputPlaceholder,
    maxCharLength=32,
    ApiCallingFunction,
    CallbackToggleModal,
    CallbackResetNotificationMessage,
    isTagLimitationExist=true
}) => {
    const [tag, setTag] = React.useState("")
    const VIEW_MODE = useSelector((state) => state.businessModal.MODE_TYPE);
    const [messageHolder, contextHolder] = message.useMessage();
    const [ isModalOpen, setIsModalOpen ] = React.useState(false)
    const [editStatus, setIsEditStatus] = React.useState({ "isEdit": false, tagId: null })
    React.useEffect(() => {
        if(ApiStatus?.status === "success"){
            setTag("");
            setIsEditStatus(() => ({ isEdit: false, tagId: "" }))
        }
    }, [ApiStatus?.status])
    const isLoading = ApiStatus?.status === "loading";
    const checkTagExistsInList = () => {
        if(ListData.length>20 && isTagLimitationExist){
            setIsModalOpen(true);
            return;
        }
        const tags = ListData.map((data) => (data.title));
        if(!tags.includes(tag?.trim())){
            if(editStatus.isEdit){
                ApiCallingFunction("patch",{ "id": editStatus.tagId, "tag": tag });
                return;
            }
            ApiCallingFunction("create", { "tag": tag?.trim() });
            return;
        }
        messageHolder.warning(tooltipMessages.warning,2);
    }
    const { description1, description2, description3 } = MAX_TAG_EXCEEDED[warningDataIndex] || {}
    return (
        <div>
            {contextHolder}
            {!!ApiStatus?.status ? <Notification content={ApiStatus?.message} type={ApiStatus?.status} callbackResetStatus={() => CallbackResetNotificationMessage()} /> : null}
            <Modal open={isTagModalOpen} centered>
                <div className='flex justify-between items-center w-full'>
                    {
                        isModalOpen 
                        ? (
                            <OopsPopUp
                                isOpen={isModalOpen}
                                description1={description1}
                                description2={description2}
                                description3={description3}
                                onClick={() => setIsModalOpen((prev) => !prev)}
                            />
                        ) : null
                    }
                    <HeadingFive text={Title} />
                    <button onClick={() => CallbackToggleModal()}>
                        <img src={close} className='pb-[1rem]' alt="" />
                    </button>
                </div>
                <div className='flex items-center gap-x-[1rem]'>
                    <TextInput warning_message_name={"tag_name"} isDisabled={isLoading} value={tag} placeholder={InputPlaceholder} onChangeEvent={(e) => {
                        messageHolder.destroy();
                        if(typeof e.target.value === "string" && e.target.value.length<=maxCharLength){
                            setTag(e.target.value)
                        }else{
                            messageHolder.warning("character limit exceeded.")
                        }
                    }}/>
                    <div>
                        <PrimaryButton isLoading={isLoading} isDisabled={isLoading || VIEW_MODE.view} text={editStatus.isEdit ? "Update" : "Add"} onClick={checkTagExistsInList} />
                    </div>
                </div>
                <div className='flex flex-wrap gap-[0.5rem] pt-[1rem]'>
                    {ListData.map((categoryTag) => {
                        return (
                            <div className='flex gap-x-[0.4rem] items-center border rounded-md px-[0.4rem] py-[0.2rem]'>
                                <span>{categoryTag?.title || categoryTag?.label}</span>
                                {
                                    categoryTag?.isDefault
                                    ? null
                                    : (
                                        <button disabled={isLoading} onClick={() => {
                                            setIsEditStatus((prev) => ({ isEdit: true, tagId: categoryTag.id || categoryTag.value }));
                                            setTag(categoryTag?.title || categoryTag?.label);
                                        }}><img width={14} src={Edit} alt="" /></button>
                                    )
                                }
                                
                            </div>
                        )
                    })}
                </div>
            </Modal>
        </div>
    )
}

export default ListModal