import React from 'react'
import pg_watermark from "../../assets/common/pg_watermark.svg"
import { refund_policy } from '../data/refundPolicy'

const RefundPolicy = () => {
    return (
        <div>
            <img className='w-[388px] aspect-square absolute top-[10rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <div 
                className='tc_pcpy py-[40px] relative mx-[18px] flex flex-col gap-y-[2rem]' 
                dangerouslySetInnerHTML={{ __html: refund_policy.content }}
            />
        </div>
    )
}

export default RefundPolicy