import React, { useEffect, useState } from 'react'
import TextComponent from '../reuseablecomponent/TextVarients/TextComponent'
import ButtonComponent from '../reuseablecomponent/ButtonVarients/ButtonComponent'
import deleteOutline from "../../assets/common/deleteOutline.svg"
import { paymentCards } from '../../data'
import { useDispatch, useSelector } from "react-redux";
import { appendNewPaymentCard, toggleDeleteModal, storeDeleteCardIndex, addPaymentForUserApi } from "./settingSlice"
import MessageNotification from "../reuseablecomponent/MessageNotification";
import OopsPopUp from '../reuseablecomponent/PopUpVarients/OopsPopUp'
import setting_locals from "../../localization/localization_en_setting.json"

const locals = setting_locals.settings.tabs.Subscription.payment.payment_method;

const PaymentMethod = () => {
    const dispatch = useDispatch();
    const paymentCardsData = useSelector((state) => state.setting.paymentCardsData);
    const [showToolTipForDeletePayment, setShowToolTipForDeletePayment] = useState(false);
    const [isToolTipOpen, setIsToolTipOpen] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowToolTipForDeletePayment(false);
        }, 1500)
        return () => clearTimeout(timer);
    }, [showToolTipForDeletePayment])
    const handleDeleteCard = (index) => {
        if (paymentCardsData.length <= 1) {
            // setShowToolTipForDeletePayment((prev) => !prev);
            setIsToolTipOpen((prev) => !prev);
            return;
        }
        dispatch(storeDeleteCardIndex(index));
        dispatch(toggleDeleteModal());
    }
    const handlePaymentCardBtn = () => {
        // dispatch(addPaymentForUserApi({cardType: "visa", cardnumber: 4242424242424242, monthExpiry:12, year:2025, cvc:123}));
        dispatch(appendNewPaymentCard({ cardType: "visa", cardnumber: 4242424242424242, monthExpiry:12, year:2025, cvc:123 }));
    }
    const handleToolTip = () => {
        setIsToolTipOpen((prev) => !prev);
    }
    return (
        <div className='mb-[24px]'>
            {showToolTipForDeletePayment && <MessageNotification content={locals.modal.warning_popup.text} status={"failed"} type={"warning"} />}
            { isToolTipOpen && <OopsPopUp type={"primary"} description1={locals.modal.warning_popup.text} isOpen={isToolTipOpen} onClick={handleToolTip} /> }
            <div>
                <TextComponent fontSize={16} fontWeight={500} text={locals.title} />
                <div className='p-[16px] border border-[#757575] rounded-[16px] mt-[16px]'>
                    {
                        (paymentCardsData?.length === 0)
                            ? <div className='rounded-[8px] bg-[#F4F7FF] p-[16px] mb-[1rem]'>
                                <p style={{margin:0}}>{locals.no_card}</p>
                                <p className='text-[#757575] text-sm mt-1'>{locals.card_add_suggestion}</p>
                            </div>
                            : <ul className="listofCardType">
                                {
                                    paymentCardsData?.map((card, index) => {
                                        return (
                                            <li key={index} className='flex justify-between items-center mb-[16px] rounded-[8px] bg-[#F4F7FF] p-[16px]'>
                                                <div className='flex justify-between items-center'>
                                                    <img src={paymentCards[card.cardType]} alt="" />
                                                    <div className='ml-[24px]'>
                                                        <TextComponent fontSize={14} fontWeight={500} text={`${locals.card_clue_description} ${card.cardnumber}`} />
                                                    </div>
                                                </div>
                                                <ButtonComponent onClick={() => handleDeleteCard(index)} isDisabled={false} isLoading={false} textColor={"#fff"} isPreImg={true} preImg={deleteOutline} type={"link"} />
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                    }
                    <div className="button flex justify-end">
                        <ButtonComponent onClick={handlePaymentCardBtn} bgColor={"#372EE5"} isDisabled={false} isLoading={false} text={locals.add_payment_btn.button_text} textColor={"#fff"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentMethod