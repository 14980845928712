import React from 'react'
import Lock from "../../../../assets/common/Lock.svg"
import TextComponent from '../../../reuseablecomponent/TextVarients/TextComponent'
import "../../../../index.css";
import locals from "../../../../localization/localization_en_stepper.json"

const stepperLocals  = locals.stepper;

const Locked = ({ cardBgColor, cardBorder }) => {
  return (
    <React.Fragment>
        <div style={{backgroundColor:"#9FA6C1",border:`1px solid ${"#8A8FA3"}`}} className={`flex ml-[2.7rem] items-center justify-between rounded-[8px] p-[16px] w-[355px]`}>
            <TextComponent color={"#FFF"} fontSize={14} fontWeight={600} text={stepperLocals.captions.module_under_construction_heading} />
            <div className='p-1 lockedFeatureBg rounded-full'>
                <img src={Lock} alt="" />
            </div>
        </div>
    </React.Fragment>
  )
}

export default Locked