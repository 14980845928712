import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import routesConfig from "../../services/config/routesConfig.json";
import api from "../../services/api/api";
import { v4 as uuidv4 } from "uuid";
import locals from "../../localization/localization_en_gtm.json"
import { pickCurrencySymbolFromString } from "../../utils/pickCurrencySymbolFromString";
import { filterDuplicateObjectProp } from "../../utils/filterDuplicateObjectProp";
import { DropdownGtmDataPreparation } from "../BusinessModal/components/CostOnboarding/utils/DropdownDataPreparation";
import { FormatGTMDataCompatableBE, FormatGTMDataCompatableFE, FormatMarketingFunnelDataCompatableBE, FormatMarketingFunnelDataCompatableFE } from "./helper/FormatDataCompatableForBE";
import { downloadBackendFile } from "../../utils/downloadBackendFile";


const DEFAULT_ROW = {
    "gtm_plan": locals.gtm_strategy.gtm.table.skeleton_row,
    "marketing_funnel": locals.gtm_strategy.marketing_funnel.table.skeleton_row
}

export const API_Fetch_Currency_List = createAsyncThunk(
    "gtm/API_Fetch_Currency_List",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-currency-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_Default_SalesActivityList = createAsyncThunk(
    "gtm/API_Fetch_Default_SalesActivityList",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'get-default-sales-activity-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_SalesActivityList = createAsyncThunk(
    "gtm/API_Fetch_SalesActivityList",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'get-sales-activity-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Create_SalesActivityTag = createAsyncThunk(
    "gtm/API_Create_SalesActivityTag",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'create-sales-activity');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);


export const API_Update_SalesActivityTag = createAsyncThunk(
    "gtm/API_Update_SalesActivityTag",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'update-sales-activity');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_Default_ChannelList = createAsyncThunk(
    "gtm/API_Fetch_Default_ChannelList",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'get-default-channel-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_ChannelList = createAsyncThunk(
    "gtm/API_Fetch_ChannelList",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'get-channel-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Create_ChannelTag = createAsyncThunk(
    "gtm/API_Create_ChannelTag",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'create-channel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);


export const API_Update_ChannelTag = createAsyncThunk(
    "gtm/API_Update_ChannelTag",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'update-channel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_Default_ContentAssetList = createAsyncThunk(
    "gtm/API_Fetch_Default_ContentAssetList",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'get-default-content-asset-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_ContentAssetList = createAsyncThunk(
    "gtm/API_Fetch_ContentAssetList",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'get-content-asset-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Create_ContentAssetTag = createAsyncThunk(
    "gtm/API_Create_ContentAssetTag",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'create-content-asset');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);


export const API_Update_ContentAssetTag = createAsyncThunk(
    "gtm/API_Update_ContentAssetTag",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'update-content-asset');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_GTM_LIST = createAsyncThunk(
    "gtm/API_Fetch_GTM_LIST",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'get-gtm-plan-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Create_GTM_LIST = createAsyncThunk(
    "gtm/API_Create_GTM_LIST",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'create-gtm-plan');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Patch_GTM_LIST = createAsyncThunk(
    "gtm/API_Patch_GTM_LIST",
    async (payload) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'update-gtm-plan');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: FormatGTMDataCompatableBE(payload) }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Delete_GTM_LIST = createAsyncThunk(
    "gtm/API_Delete_GTM_LIST",
    async (id) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'delete-gtm-plan');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "params": { planId: id } }
                const response = await api.request(urlConfig);
                return { "deletedDoc": id }
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_MarketingFunnel_LIST = createAsyncThunk(
    "gtm/API_Fetch_MarketingFunnel_LIST",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'list-marketing-funnel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Create_MarketingFunnel_LIST = createAsyncThunk(
    "gtm/API_Create_MarketingFunnel_LIST",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'create-marketing-funnel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Patch_MarketingFunnel_LIST = createAsyncThunk(
    "gtm/API_Patch_MarketingFunnel_LIST",
    async (payload) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'update-marketing-funnel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: FormatMarketingFunnelDataCompatableBE(payload) }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Delete_MarketingFunnel_LIST = createAsyncThunk(
    "gtm/API_Delete_MarketingFunnel_LIST",
    async (id) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'delete-marketing-funnel');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "params": { marketingFunnelId: id } }
                const response = await api.request(urlConfig);
                return { "deletedDoc": id }
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_DOWNLOAD_GTM_LIST = createAsyncThunk(
    "gtm/API_DOWNLOAD_GTM_LIST",
    async (module_type, { getState }) => {
        try {
            const state = getState();
            let urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', module_type==="gtm_plan" ? "download-csv-plan" : "download-csv-marketing-funnel");
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "responseType": 'blob'}
                const response = await api.request(urlConfig);
                const apiHeaderfileName = response.headers.get('content-disposition')
                downloadBackendFile({ "response": response.data, "fileName": apiHeaderfileName.split('=')?.[1] || "file" })
                return response;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_Default_MarketingStageList = createAsyncThunk(
    "gtm/API_Fetch_Default_MarketingStageList",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'get-default-stage-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_MarketingStage_List = createAsyncThunk(
    "gtm/API_Fetch_MarketingStage_List",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'get-stage-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Create_MarketingStage_Tag = createAsyncThunk(
    "gtm/API_Create_MarketingStage_Tag",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'create-stage');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);


export const API_Update_MarketingStage_Tag = createAsyncThunk(
    "gtm/API_Update_MarketingStage_Tag",
    async (payloadData) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'gtm', 'update-stage');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const INITIAL_STATE = {
    "currency": {
        "CURRENCY": "$",
        "value": null
    },
    "currencyList": [],
    "gtm_plan": {
        "row_colors": [],
        "table_data": [],
        "isDeleteModalOpen": false,
        "isEditChannelModalOpen": false,
        "crud_form_type": null,
        "rowIndex": null,
        "show_toolip": false,
        "editRowDetails": {},
        "categoryModal": {
            "isOpen": false,
            "type": null,
            "rowIndex": null
        },
        "activeTagColor": null,
    },
    "marketing_funnel": {
        "table_data": [],
        "row_colors": [],
        "categoryModal": {
            "isOpen": false,
            "type": null,
            "rowIndex": null
        },
        "rowIndex": null,
        "isEditChannelModalOpen": false,
        "crud_form_type": null,
        "isDeleteModalOpen": false,
        "editRowDetails": {},
        "activeTagColor": null
    },
    "api_status_module": { 
        "stageList": { "status": "idle", "message": null, "httpType": null },
        "sales_activities": { "status": "idle", "message": null, "httpType": null },
        "content_assets": { "status": "idle", "message": null, "httpType": null },
        "gtmChannelList": { "status": "idle", "message": null, "httpType": null },
        "marketing_stage": { "status": "idle", "message": null, "httpType": null },
    },
    "categoryList": {
        "stageList": [],
        "sales_activities": [],
        "content_assets": [],
        "gtmChannelList": [],
        "marketing_stage": []
    },
    "create_delete_patch_api_status": { "status": "idle", "message": null, "httpType": null },
    "funnel_stage": null
}

// export const API_Save_BalanceSheet_Data = createAsyncThunk(
//     "gtm_strategy/API_Save_BalanceSheet_Data",
//     async (payloadData) => {
//         try {
//             const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'save-business-model-balance-sheet');
//             if (!!urlGenerator) {
//                 const urlConfig = { ...urlGenerator, "data": payloadData }
//                 const response = await api.request(urlConfig);
//                 return response?.data;
//             }
//         } catch (error) {
//             throw new Error(error.response.data.error.message);
//         }
//     }
// );

const gtmSlice = createSlice({
    name: "gtm-slice",
    initialState: {
        "gtm_strategy": INITIAL_STATE
    },
    reducers: {
        D_Toggle_ChannelTooltip(state, action) {
            state.gtm_strategy.gtm_plan.show_toolip = !state.gtm_strategy.gtm_plan.show_toolip
        },
        D_Patch_TableColor(state, action) {
            const { module_type, rowIndex, selectedColor } = action.payload;
            state.gtm_strategy[module_type].row_colors = state.gtm_strategy[module_type].row_colors.map((c, index) => index===rowIndex ? selectedColor : c)
        },
        D_Add_Table_Row(state, action) {
            const { module_type } = action.payload || {};
            state.gtm_strategy[module_type].table_data = [...state.gtm_strategy[module_type].table_data, DEFAULT_ROW[module_type]];
            state.gtm_strategy[module_type].row_colors = [...state.gtm_strategy[module_type].row_colors, null];
            // state.gtm_strategy[module_type].table_data = []
        },
        D_Delete_Table_Row(state, action) {
            const { module_type, rowIndex } = action.payload || {};
            state.gtm_strategy[module_type].table_data = state.gtm_strategy[module_type].table_data.filter((_, index) => index!==rowIndex);
            state.gtm_strategy[module_type].row_colors = state.gtm_strategy[module_type].row_colors.filter((_, index) => index!==rowIndex);
            state.gtm_strategy[module_type].isDeleteModalOpen = false;
            state.gtm_strategy[module_type].rowIndex = null;
        },
        D_Update_Table_Row(state, action) {
            state.gtm_strategy.gtm_plan.isEditChannelModalOpen = !state.gtm_strategy.gtm_plan.isEditChannelModalOpen;
            state.gtm_strategy.gtm_plan.crud_form_type = action.payload.crud_form_type;
            state.gtm_strategy.gtm_plan.rowIndex = action.payload?.rowIndex
        },
        D_Capture_TableData(state, action) {
            const { module_type, data_index, value, rowIndex, MODE_TYPE, currency } = action.payload || {};
            if(MODE_TYPE==="EDIT"){
                const { module_type, MODE_TYPE, value, rowIndex, currency } = action.payload;
                state.gtm_strategy[module_type].table_data = state.gtm_strategy[module_type].table_data.map((row, index) => {
                    return index === rowIndex ? {...row, ...value, currency: currency?.value ?? row.currency} : row
                })
                state.gtm_strategy.currency = currency;
            }else{
                state.gtm_strategy[module_type].table_data = state.gtm_strategy[module_type].table_data.map((row, index) => {
                    if (rowIndex === index) {
                        return ({ ...row, [data_index]: value, currency: currency?.value ?? row.currency  })
                    }
                    return row;
                })
                if(MODE_TYPE==="VIEW"){
                    state.gtm_strategy.currency = currency;
                }
            }
            state.gtm_strategy.gtm_plan = { ...state.gtm_strategy.gtm_plan, "isEditChannelModalOpen": false, "rowIndex": null }
        },
        D_Handle_Delete_Modal(state, action){
            state.gtm_strategy[action.payload.module_type].isDeleteModalOpen = action.payload.isOpen;
            state.gtm_strategy[action.payload.module_type].rowIndex = action.payload.rowIndex;
        },
        D_Toggle_CatgoryModal(state, action) {
            const { isOpen, type, rowIndex, module_type } = action.payload;
            state.gtm_strategy[module_type] = {
                ...state.gtm_strategy[module_type], "categoryModal": { isOpen, type, rowIndex }
            }
        },
        D_Update_Row_Data_Status(state, action) {
            const { isEditChannelModalOpen, crud_form_type, rowIndex, module_type } = action.payload || {};
            state.gtm_strategy[module_type] = { ...state.gtm_strategy[module_type], isEditChannelModalOpen, crud_form_type, rowIndex }
            if (crud_form_type === "EDIT") {
                state.gtm_strategy[module_type].editRowDetails = state.gtm_strategy[module_type].table_data.find((_, index) => (rowIndex === index));
            }
        },
        D_Update_Active_Tag_Color(state, action) {
            state.gtm_strategy[action.payload.module_type].activeTagColor = action.payload.activeColor;
        },
        D_Create_TagName(state, action) { // move this logic to thunk
            const { tagName, module_type } = action.payload;
            state.gtm_strategy[module_type].table_data = state.gtm_strategy[module_type].table_data.map((r, index) => {
                if (index === state.gtm_strategy[module_type].categoryModal.rowIndex) {
                    return { ...r, "tags": [...r.tags, { tagName, "tagColor": state.gtm_strategy[module_type].activeTagColor }] }
                }
                return r;
            })
            state.gtm_strategy[module_type] = {
                ...state.gtm_strategy[module_type], "categoryModal": { isOpen: false, type: null, rowIndex: null }
            }
        },
        D_Reset_TagStatus(state, action){
            state.gtm_strategy.api_status_module[action.payload.target_module] = { "status": "idle", "message": null, "httpType": null };
        },
        D_RemoveTag(state, action){
            const { module_name, tagName, rowIndex } = action.payload;
            state.gtm_strategy[module_name].table_data = state.gtm_strategy[module_name].table_data.map((r, index) => {
                if(index===rowIndex){
                    return {...r, tags: r.tags.filter((t) => t.tagName!==tagName)}
                }
                return r;
            })
        },
        D_UpdateFunnelStage(state, action){
            state.gtm_strategy.funnel_stage = action.payload;
        },
        D_UpdateGTMChatCount(state, action){
            const { moduleType, count, tableCellOrRowId } = action.payload;
            state.gtm_strategy[moduleType].table_data = state.gtm_strategy[moduleType].table_data.map((row) => {
                if(row?.[moduleType==="gtm_plan" ? "planId": "marketingFunnelId"] === tableCellOrRowId){
                    return {...row, "chat_count": count}
                }
                return row;
            })
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_Fetch_Currency_List.pending, (state) => {
            })
            .addCase(API_Fetch_Currency_List.fulfilled, (state, action) => {
                if(state.gtm_strategy.currencyList.length>0) return;
                 state.gtm_strategy.currencyList = action.payload?.map((currency) => {
                    if(currency.code === "USD"){
                        const label = pickCurrencySymbolFromString(currency.displayLabel)
                        state.gtm_strategy.currency = { "CURRENCY": label, "value": currency._id };
                    }
                    return ({ "label": currency.displayLabel, "value": currency._id, "code": currency.code })
                })
            })
            .addCase(API_Fetch_Currency_List.rejected, (state, action) => {
                // state.save_process_api_status.status = 'failed';
                // state.save_process_api_status.message = action.error.message
            })
            .addCase(API_Fetch_Default_MarketingStageList.pending, (state) => { state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'loading' } })
            .addCase(API_Fetch_Default_SalesActivityList.pending, (state) => { state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'loading' } })
            .addCase(API_Fetch_Default_ChannelList.pending, (state) => { state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'loading' } })
            .addCase(API_Fetch_Default_ContentAssetList.pending, (state) => { state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'loading' } })

            .addCase(API_Fetch_Default_MarketingStageList.fulfilled, (state, action) => {
                const list = [...DropdownGtmDataPreparation(action.payload), ...state.gtm_strategy.categoryList.marketing_stage]
                state.gtm_strategy.categoryList.marketing_stage = filterDuplicateObjectProp({ "list": list, "targetProp": "value" })
                state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'success' }
            })
            .addCase(API_Fetch_Default_SalesActivityList.fulfilled, (state, action) => {
                const list = [...DropdownGtmDataPreparation(action.payload), ...state.gtm_strategy.categoryList.sales_activities]
                state.gtm_strategy.categoryList.sales_activities = filterDuplicateObjectProp({ "list": list, "targetProp": "value" })
                state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'success' }
            })
            .addCase(API_Fetch_Default_ChannelList.fulfilled, (state, action) => {
                const list = [...DropdownGtmDataPreparation(action.payload), ...state.gtm_strategy.categoryList.gtmChannelList]
                state.gtm_strategy.categoryList.gtmChannelList = filterDuplicateObjectProp({ "list": list, "targetProp": "value" })
                state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'success' }
            })
            .addCase(API_Fetch_Default_ContentAssetList.fulfilled, (state, action) => {
                const list = [...DropdownGtmDataPreparation(action.payload), ...state.gtm_strategy.categoryList.content_assets]
                state.gtm_strategy.categoryList.content_assets = filterDuplicateObjectProp({ "list": list, "targetProp": "value" })
                state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'success' }
            })

            .addCase(API_Fetch_Default_MarketingStageList.rejected, (state, action) => { state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'failed', "message": action.error.message } })
            .addCase(API_Fetch_Default_SalesActivityList.rejected, (state, action) => { state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'failed', "message": action.error.message } })
            .addCase(API_Fetch_Default_ChannelList.rejected, (state, action) => { state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'failed', "message": action.error.message } })
            .addCase(API_Fetch_Default_ContentAssetList.rejected, (state, action) => { state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'failed', "message": action.error.message } })


            .addCase(API_Fetch_MarketingStage_List.pending, (state) => { state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'loading' } })
            .addCase(API_Fetch_SalesActivityList.pending, (state) => { state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'loading' } })
            .addCase(API_Fetch_ChannelList.pending, (state) => { state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'loading' } })
            .addCase(API_Fetch_ContentAssetList.pending, (state) => { state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'loading' } })

            .addCase(API_Fetch_MarketingStage_List.fulfilled, (state, action) => {
                let list = [...state.gtm_strategy.categoryList.marketing_stage, ...DropdownGtmDataPreparation(action.payload)]
                state.gtm_strategy.categoryList.marketing_stage = filterDuplicateObjectProp({ "list": list, "targetProp": "value" })
                state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'success' }
            })
            .addCase(API_Fetch_SalesActivityList.fulfilled, (state, action) => {
                let list = [...state.gtm_strategy.categoryList.sales_activities, ...DropdownGtmDataPreparation(action.payload)]
                state.gtm_strategy.categoryList.sales_activities = filterDuplicateObjectProp({ "list": list, "targetProp": "value" })
                state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'success' }
            })
            .addCase(API_Fetch_ChannelList.fulfilled, (state, action) => {
                let list = [...state.gtm_strategy.categoryList.gtmChannelList, ...DropdownGtmDataPreparation(action.payload)]
                state.gtm_strategy.categoryList.gtmChannelList = filterDuplicateObjectProp({ "list": list, "targetProp": "value" })
                state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'success' }
            })
            .addCase(API_Fetch_ContentAssetList.fulfilled, (state, action) => {
                let list = [...state.gtm_strategy.categoryList.content_assets, ...DropdownGtmDataPreparation(action.payload)]
                state.gtm_strategy.categoryList.content_assets = filterDuplicateObjectProp({ "list": list, "targetProp": "value" })
                state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'success' }
            })

            .addCase(API_Fetch_MarketingStage_List.rejected, (state, action) => { state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'failed', "message": action.error.message } })
            .addCase(API_Fetch_SalesActivityList.rejected, (state, action) => { state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'failed', "message": action.error.message } })
            .addCase(API_Fetch_ChannelList.rejected, (state, action) => { state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'failed', "message": action.error.message } })
            .addCase(API_Fetch_ContentAssetList.rejected, (state, action) => { state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'failed', "message": action.error.message } })

            .addCase(API_Create_MarketingStage_Tag.pending, (state, action) => { state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'loading' } })
            .addCase(API_Create_ChannelTag.pending, (state, action) => { state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'loading' } })
            .addCase(API_Create_ContentAssetTag.pending, (state, action) => { state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'loading' } })
            .addCase(API_Create_SalesActivityTag.pending, (state, action) => { state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'loading' } })

            .addCase(API_Create_MarketingStage_Tag.fulfilled, (state, action) => {
                state.gtm_strategy.categoryList.marketing_stage = [...state.gtm_strategy.categoryList.marketing_stage, { "label": action.payload.title, value: action.payload._id, isDefault: false }]
                state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": "success" }
            })
            .addCase(API_Create_ChannelTag.fulfilled, (state, action) => {
                state.gtm_strategy.categoryList.gtmChannelList = [...state.gtm_strategy.categoryList.gtmChannelList, { "label": action.payload.title, value: action.payload._id, isDefault: false }]
                state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": "success" }
            })
            .addCase(API_Create_ContentAssetTag.fulfilled, (state, action) => {
                state.gtm_strategy.categoryList.content_assets = [...state.gtm_strategy.categoryList.content_assets, { "label": action.payload.title, value: action.payload._id, isDefault: false, "funnel_stage": action.payload.funnel_stage }]
                state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": "success" }
            })
            .addCase(API_Create_SalesActivityTag.fulfilled, (state, action) => {
                state.gtm_strategy.categoryList.sales_activities = [...state.gtm_strategy.categoryList.sales_activities, { "label": action.payload.title, value: action.payload._id, isDefault: false, "funnel_stage": action.payload.funnel_stage }]
                state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": "success" }
            })

            .addCase(API_Create_MarketingStage_Tag.rejected, (state, action) => { state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'failed', "message": action.error.message } })
            .addCase(API_Create_ChannelTag.rejected, (state, action) => { state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'failed', "message": action.error.message } })
            .addCase(API_Create_ContentAssetTag.rejected, (state, action) => { state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'failed', "message": action.error.message } })
            .addCase(API_Create_SalesActivityTag.rejected, (state, action) => { state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'failed', "message": action.error.message } })

            .addCase(API_Update_MarketingStage_Tag.pending, (state, action) => { state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'loading' } })
            .addCase(API_Update_ChannelTag.pending, (state, action) => { state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'loading' } })
            .addCase(API_Update_ContentAssetTag.pending, (state, action) => { state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'loading' } })
            .addCase(API_Update_SalesActivityTag.pending, (state, action) => { state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'loading' } })

            .addCase(API_Update_MarketingStage_Tag.fulfilled, (state, action) => {
                state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'success'}
                const updated = action.payload.stageUpdated
                state.gtm_strategy.categoryList.marketing_stage = state.gtm_strategy.categoryList.marketing_stage.map((tag) => tag.value===updated._id ? {...tag, "label": updated.title} : tag);
            })
            .addCase(API_Update_ChannelTag.fulfilled, (state, action) => {
                state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'success'}
                const updated = action.payload.channelUpdated
                state.gtm_strategy.categoryList.gtmChannelList = state.gtm_strategy.categoryList.gtmChannelList.map((tag) => tag.value===updated._id ? {...tag, "label": updated.title} : tag);
            })
            .addCase(API_Update_ContentAssetTag.fulfilled, (state, action) => {
                state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'success'}
                const updated = action.payload.contentAssetUpdated
                state.gtm_strategy.categoryList.content_assets = state.gtm_strategy.categoryList.content_assets.map((tag) => tag.value===updated._id ? {...tag, "label": updated.title} : tag);
            })
            .addCase(API_Update_SalesActivityTag.fulfilled, (state, action) => {
                state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'success'}
                const updated = action.payload.salesActivitiesUpdated
                state.gtm_strategy.categoryList.sales_activities = state.gtm_strategy.categoryList.sales_activities.map((tag) => tag.value===updated._id ? {...tag, "label": updated.title} : tag);
            })

            .addCase(API_Update_MarketingStage_Tag.rejected, (state, action) => { state.gtm_strategy.api_status_module.marketing_stage = { ...state.gtm_strategy.api_status_module.marketing_stage, "status": 'failed', "message": action.error.message } })
            .addCase(API_Update_ChannelTag.rejected, (state, action) => { state.gtm_strategy.api_status_module.gtmChannelList = { ...state.gtm_strategy.api_status_module.gtmChannelList, "status": 'failed', "message": action.error.message } })
            .addCase(API_Update_ContentAssetTag.rejected, (state, action) => { state.gtm_strategy.api_status_module.content_assets = { ...state.gtm_strategy.api_status_module.content_assets, "status": 'failed', "message": action.error.message } })
            .addCase(API_Update_SalesActivityTag.rejected, (state, action) => { state.gtm_strategy.api_status_module.sales_activities = { ...state.gtm_strategy.api_status_module.sales_activities, "status": 'failed', "message": action.error.message } })

            .addCase(API_Fetch_GTM_LIST.pending, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'loading';
            })
            .addCase(API_Fetch_GTM_LIST.fulfilled, (state, action) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'success';
                state.gtm_strategy.gtm_plan.table_data = FormatGTMDataCompatableFE(action.payload.gtmPlanList).map((r) => {
                    return {...r, "chat_count": action.payload.gtmPlanChatCountMapping[r?.planId] || 0}
                })
            })
            .addCase(API_Fetch_GTM_LIST.rejected, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'failed';
            })

            .addCase(API_Create_GTM_LIST.pending, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'loading';
            })
            .addCase(API_Create_GTM_LIST.fulfilled, (state, action) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'success';
                state.gtm_strategy.gtm_plan.table_data = [
                    ...state.gtm_strategy.gtm_plan.table_data,
                    {...DEFAULT_ROW.gtm_plan, "planId": action.payload._id, "currency": state.gtm_strategy.currency.value}
                ]
            })
            .addCase(API_Create_GTM_LIST.rejected, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'failed';
            })
            .addCase(API_Delete_GTM_LIST.pending, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'loading';
            })
            .addCase(API_Delete_GTM_LIST.fulfilled, (state, action) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'success';
                state.gtm_strategy.gtm_plan.table_data = state.gtm_strategy.gtm_plan.table_data.filter((row) => row.planId!=action.payload.deletedDoc);
                // state.gtm_strategy.gtm_plan.row_colors = state.gtm_strategy.gtm_plan.row_colors.filter((_, index) => index!==rowIndex);
                state.gtm_strategy.gtm_plan.isDeleteModalOpen = false;
                state.gtm_strategy.gtm_plan.rowIndex = null;
            })
            .addCase(API_Delete_GTM_LIST.rejected, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'failed';
            })

            .addCase(API_Fetch_MarketingFunnel_LIST.pending, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'loading';
            })
            .addCase(API_Fetch_MarketingFunnel_LIST.fulfilled, (state, action) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'success';
                state.gtm_strategy.marketing_funnel.table_data = FormatMarketingFunnelDataCompatableFE(action.payload.gtmMarketingFunnelList).map((r) => {
                    return {...r, "chat_count": action.payload.gtmMarketingFunnelChatCountMapping[r?.marketingFunnelId] || 0}
                })
            })
            .addCase(API_Fetch_MarketingFunnel_LIST.rejected, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'failed';
            })
            
            .addCase(API_Create_MarketingFunnel_LIST.pending, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'loading';
            })
            .addCase(API_Create_MarketingFunnel_LIST.fulfilled, (state, action) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'success';
                state.gtm_strategy.marketing_funnel.table_data = [
                    ...state.gtm_strategy.marketing_funnel.table_data,
                    {...DEFAULT_ROW.marketing_funnel, "marketingFunnelId": action.payload._id, "currency": state.gtm_strategy.currency.value}
                ]
            })
            .addCase(API_Create_MarketingFunnel_LIST.rejected, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'failed';
            })
            .addCase(API_Delete_MarketingFunnel_LIST.pending, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'loading';
            })
            .addCase(API_Delete_MarketingFunnel_LIST.fulfilled, (state, action) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'success';
                state.gtm_strategy.marketing_funnel.table_data = state.gtm_strategy.marketing_funnel.table_data.filter((row) => row.marketingFunnelId!=action.payload.deletedDoc);
                // state.gtm_strategy.gtm_plan.row_colors = state.gtm_strategy.gtm_plan.row_colors.filter((_, index) => index!==rowIndex);
                state.gtm_strategy.marketing_funnel.isDeleteModalOpen = false;
                state.gtm_strategy.marketing_funnel.rowIndex = null;
            })
            .addCase(API_Delete_MarketingFunnel_LIST.rejected, (state) => {
                state.gtm_strategy.create_delete_patch_api_status.status = 'failed';
            })
    }
});

export const { D_UpdateGTMChatCount, D_UpdateFunnelStage, D_RemoveTag, D_Reset_TagStatus, D_Patch_TableColor, D_Toggle_ChannelTooltip, D_Add_Table_Row, D_Update_Table_Row, D_Capture_TableData, D_Toggle_CatgoryModal, D_Create_TagName, D_Update_Row_Data_Status, D_Update_Active_Tag_Color, D_Delete_Table_Row, D_Handle_Delete_Modal } = gtmSlice.actions;
export default gtmSlice.reducer;