import { Column } from '@ant-design/plots';
import { convertAmountInK } from '../../../utils/convertAmountInK';
import { COLOR_PLATE } from '../color-palette';

// expected input array of hex colors and value of type { type: 'category' , value:''}
const BarChartAntD = ({
    data,
    colors,

    dataIndexNameXAxis = "type",
    dataIndexNameYAxis = "value",
    dataIndexNameSeries = "type",

    xAxisLabel = null,
    XAxisBarLabelVisible = false,
    xLabelAutoRotate = true,
    yAxisLabel = null,
    yAxisPrePostUnitValue,
    xAxisGridLine="white",
    yAxisGridLine="#EEEEEE",

    barLabelVisible = true,
    barLabelColor = null,
    barLabelPosition = "middle",
    barDifferenceWidth = 0.8,
    barPrePostUnitValue,

    animation=true,

    legendPosition = "bottom-left"

}) => {
    const config = {
        data,
        xField: dataIndexNameXAxis,
        yField: dataIndexNameYAxis,
        seriesField: dataIndexNameSeries,
        style: {
            maxWidth: 1220,
        },
        legend: {
            layout: 'horizontal',
            position: legendPosition
        },
        label: {
            visible: barLabelVisible,
            position: barLabelPosition,
            style: {
                fill: barLabelColor,
                opacity: 1,
                fontSize: barLabelVisible ? 12 : 0
            },
            content: (item) => `${barPrePostUnitValue?.pre || ''} ${item.value} ${barPrePostUnitValue?.post || ''}`,
        },
        xAxis: {
            label: {
                autoHide: false,
                autoRotate: xLabelAutoRotate,
                style: {
                    fontSize: XAxisBarLabelVisible ? 12 : 0,
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: xAxisGridLine,
                    },
                },
            },
            title: {
                text: xAxisLabel,
                style: {
                    fontSize: 11,
                    fill: "#9E9E9E"
                }
            }
        },
        yAxis: {
            title: {
                text: yAxisLabel,
                style: {
                    fontSize: 11,
                    fill: "#9E9E9E"
                },
            },
            label: {
                formatter: (value) => `${barPrePostUnitValue?.pre || ''} ${convertAmountInK(value)} ${yAxisPrePostUnitValue?.post || ''}`,
                style: {
                    fontSize: 12,
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: yAxisGridLine
                    },
                },
            }
        },
        columnWidthRatio: barDifferenceWidth,
        colorField: "type",
        color: data?.length <= colors?.length ? colors : COLOR_PLATE
    };
    return (
        <div className="">
            <Column {...config} animation={animation} />
        </div>
    );
};
export default BarChartAntD