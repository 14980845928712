import React, { useEffect, useState } from 'react'
import { animateScroll as scroll } from "react-scroll"
import { useDispatch, useSelector } from "react-redux";
import down_arrow from "../assets/down_arrow.svg"
import { resetFloatingIconCount } from '../promptSlice';

const FloatingScrollIcon = () => {
    const isShow = useSelector((state) => state.prompt.isShowFloatingIconCount)
    const dispatch = useDispatch();
    const [s, isS] = useState(isShow!==0);
    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;
        const documentHeight = document.documentElement.scrollHeight;
        if(Math.abs(Number.parseInt(windowHeight+ scrollPosition) - documentHeight) <= 10){
            isS(false);
        }else{
            isS(true);
        }
    };
    useEffect(() => {
        return () => dispatch(resetFloatingIconCount);
    })
    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isShow]);
    return (
        <React.Fragment>
            <div className={`${s ? 'visible' : 'invisible'} cursor-pointer bg-primary sticky bottom-[5rem] left-[calc(50%+105px)] w-fit p-1 rounded-full`} onClick={() => scroll.scrollToBottom()}>
                <img src={down_arrow} alt="" />
            </div>
        </React.Fragment>
    )
}

export default FloatingScrollIcon