import React from 'react'
import StepCounterWithTitle from './StepCounterWithTitle'
import StepNumber from './StepNumber'
import ParentModuleActiveOrInActivePosition from './ParentModuleActiveOrInActivePosition'
import NestedCoreFeatures from './NestedCoreFeatures'
import { stepperConfig } from '../data'
import SubParentComponent from './SubParentComponent'

const ProductFeatures = ({ stepperProgressData }) => {
    return (
        <React.Fragment>
            <div className='flex flex-col gap-y-[40px]'>
                {
                    stepperConfig.map((module, index) => {
                        return (
                            <div key={index} className='min-w-[47%] flex flex-col'>
                                <StepCounterWithTitle stepNumber={index + 1} productTitle={module.title} />
                                <SubParentComponent stepperProgressData={stepperProgressData[index]} subParent={module} />
                            </div>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default ProductFeatures