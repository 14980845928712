import PrePostNumericInput from "../../../../DesignSystem/Forms/InputVarients/PrePostNumericInput";
import VerticalScrollableTable from "../../../../DesignSystem/Table/VerticalScrollableTable";
import ToggleTableAttribute from "./components/ToggleTableAttribute";
import ToggleTableLabel from "./components/ToggleTableLabel";
import CustomNumber from "./components/CustomNumber";
import RadioInput from "./components/RadioInput"
import SwitchTable from "./components/SwitchTable";
import TabTable from "./components/TabTable";
import CheckBoxInput from "./components/CheckBoxInput";
import { useSelector } from "react-redux";

const GenerateForm = ({
    type,
    tableProps,
    numericInputProps,
    radioInputProps,
    toggletableAttrProps,
    toggleTableProps,
    editableTableProps,
    toggleTableLabelProps,
    numberProps,
    tabTableProps,
    checkBoxInputProps
}) => {
    const IS_VIEW = useSelector((state) => state.businessModal.MODE_TYPE.view)
    switch (type) {
        case 'radio_input': return <RadioInput {...radioInputProps} />;
        case 'check_box_input': return <CheckBoxInput {...checkBoxInputProps} />;
        case 'toggle_table_attr': return <ToggleTableAttribute toggletableAttrProps={toggletableAttrProps} />
        case 'table': return <VerticalScrollableTable isDisabled={IS_VIEW} tableProps={tableProps} />
        case 'switch_table': return <SwitchTable SwitchTableProps={toggleTableProps} />
        case 'post_numeric_input': return <PrePostNumericInput {...numericInputProps} />
        case 'editable_table': return <VerticalScrollableTable isDisabled={IS_VIEW} tableProps={ editableTableProps }/>
        case 'toggle_table_label': return <ToggleTableLabel toggleTableLabelProps={toggleTableLabelProps} />
        case 'normal_edit_table': return <VerticalScrollableTable tableProps={ editableTableProps } />
        case 'number_input' : return <CustomNumber {...numberProps} isReadOnly={IS_VIEW} isBordered={true} />
        case 'tab_table': return <TabTable tabTableProps={tabTableProps} />
    }
}

export default GenerateForm