import classNames from 'classnames';

const CustomRadio = ({ isChecked=false, isDisabled, cursor }) => {
    const checkedStyle = classNames(' w-[calc(100%-20%)] duration-100','aspect-square rounded-full bg-primary', {'scale-100':isChecked, 'scale-0': !isChecked})
    return (
        <div disabled={isDisabled} style={{padding:0,...cursor}} className={`${isChecked ? 'border-primary' : 'border-[#616161]'} w-[20px] h-[20px] aspect-square rounded-full border relative flex items-center justify-center cursor-pointer`}>
            <div className={checkedStyle}></div>
        </div>
    )
}

export default CustomRadio