import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateUrlEndPoint } from "../../utils/endPointsGeneration";
import routesConfig from "../../services/config/routesConfig.json";
import api from "../../services/api/api";
import { v4 as uuidv4 } from "uuid";
import bmLocals from "../../localization/localization_en_business_modal.json"
import { generateGlobalSettingDefaultData } from "./functionality/generateGlobalSettingDefaultData";

const gs = bmLocals.business_modal.settings.form;

export const API_Fetch_Global_Setting = createAsyncThunk(
    "globalSetting/API_Fetch_Global_Setting",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-global-settings');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Create_Global_Setting = createAsyncThunk(
    "globalSetting/API_Create_Global_Setting",
    async (_, { getState, dispatch }) => {
        try {
            const state = getState();
            const { currency, projection_duration, decimal_places, tds_rate_vendor, tds_rate_employee, gst_credit, fiscal_start_year, depreciationRate, tax_rate } = state.globalSetting.previous_global_settings;
            const payloadData = { businessModelId: state.businessModal.active_BusinessModal_Id,currency, projectionDuration: projection_duration, decimalPlaces: decimal_places, TDSRateVendor: tds_rate_vendor, TDSRateEmployee: tds_rate_employee, GSTCredit: gst_credit, FiscalYearStartMonth: fiscal_start_year, FiscalYear: (new Date()).getFullYear(), depreciationRate: depreciationRate, "taxRate": tax_rate }
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'create-global-settings');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Patch_Global_Setting = createAsyncThunk(
    "globalSetting/API_Patch_Global_Setting",
    async (_, { getState, dispatch }) => {
        try {
            const state = getState();
            console.log("patching")
            const { globalSettingsId } = state.globalSetting;
            const { currency, projection_duration, decimal_places, tds_rate_vendor, tds_rate_employee, gst_credit, fiscal_start_year, depreciationRate, tax_rate } = state.globalSetting.previous_global_settings;
            const payloadData = { businessModelId: state.businessModal.active_BusinessModal_Id, globalSettingsId, currency, projectionDuration: projection_duration, decimalPlaces: decimal_places, TDSRateVendor: tds_rate_vendor, TDSRateEmployee: tds_rate_employee, GSTCredit: gst_credit, FiscalYearStartMonth: fiscal_start_year, FiscalYear: (new Date()).getFullYear(), depreciationRate, "taxRate": tax_rate }
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'update-global-settings');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data: payloadData }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_Currency_List_Setting = createAsyncThunk(
    "globalSetting/API_Fetch_Currency_List_Setting",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'get-currency-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Populate_Currency_Setting = createAsyncThunk(
    "globalSetting/API_Update_Currency_List_Setting",
    async () => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'businessModal', 'populate-currency');
            console.log(urlGenerator, "generator");
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const gsDefaultSensitiveMetric = generateGlobalSettingDefaultData(gs)
const settingSchema = {
    "product_category": [],
    "channel_name": [],
    "currency": null,
    ...gsDefaultSensitiveMetric
}

const GlobalSettingSlice = createSlice({
    name: "global-setting",
    initialState: {
        "globalSettingsId": null,
        "isSettingViewOnlyMode": false,
        "currencyList": [],
        "businessModalList": [],
        "currencySymbol": "₹",
        "api_status": {
            "currency": { "status": 'idle', "message": null, "httpType": null },
            "product_category": { "status": 'idle', "message": null, "httpType": null },
            "global_setting": { "status": 'idle', "message": null, "httpType": null },
            "business_modal_list": { "status": 'idle', "message": null, "httpType": null },
            "channel_name": { "status": 'idle', "message": null, "httpType": null },
            "create_business_modal": { "status": 'idle', "message": null, "httpType": null }
        },
        "isEditSetting": false,
        "isAddProductCategoryModalOpen": false,
        "previous_global_settings": settingSchema,
        "active_global_settings": settingSchema,
    },
    reducers: {
        D_Update_Setting_Mode_Type(state, action){
            state.isSettingViewOnlyMode = action.payload; // user navigates from bm to setting[view] 
        },
        D_Store_GlobalSettingData(state, action){
            const { settingMetric } = action.payload;
            // need to dicuss gs id
            state.globalSettingsId = null
            const selectedCurrency = state.currencyList.find((currency) => (currency.value === settingMetric.currency))
            state.currencySymbol = selectedCurrency?.label?.split(' ')?.[1]?.[1];
            state.active_global_settings = {
                ...state.active_global_settings,
                currency: settingMetric.currency,
                projection_duration: settingMetric.projectionDuration,
                decimal_places: settingMetric.decimalPlaces,
                tds_rate_vendor: settingMetric.TDSRateVendor,
                tds_rate_employee: settingMetric.TDSRateEmployee,
                gst_credit: settingMetric.GSTCredit,
                fiscal_start_year: settingMetric.FiscalYearStartMonth,
                tax_rate: settingMetric?.taxRate ? settingMetric?.taxRate : 26,
                depreciationRate: settingMetric.depreciationRate
            }
            state.previous_global_settings = state.active_global_settings;
        },
        DPatchGlobalSetting(state, action){
            state.globalSettingsId = action.payload.globalSettings?._id || null;
        },
        DCreateChannelName(state, action){
            state.active_global_settings.channel_name = [...state.active_global_settings.channel_name, { "id": uuidv4(), "title": action.payload }]
        },
        DPatchChannelName(state, action){
            state.active_global_settings.channel_name = state.active_global_settings.channel_name.map((data) => (action.payload.channelNameId === data.id) ? {...data, "title": action.payload.channelNameTitle} : data)
        },
        DResetNotificationMessage(state, action){
            const type = action.payload.type;
            if(!["currency", "product_category", "global_setting"].includes(type)) return;
            state.api_status[action.payload.type] = { "status": 'idle', "message": null, "httpType": null }
        },
        DResetHttpType(state, action){
            state.api_status.global_setting.httpType = null
        },
        DControlUserEdit: (state, action) => {
            state.isEditSetting = !state.isEditSetting;
            if (!state.isEditSetting) {
                state.previous_global_settings = state.active_global_settings;
            }
        },
        DUpdateSelectOption: (state, action) => {
            const { name, value } = action.payload;
            state.previous_global_settings[name] = value;
        },
        DUpdateBMSetting: (state, action) => { // move logic on api call
            const { type } = action.payload;
            state.isEditSetting = !state.isEditSetting;
            if (type === "cancel") {
                state.previous_global_settings = state.active_global_settings;
                return;
            }
            state.active_global_settings = state.previous_global_settings;
        },
        D_RESET_ERROR_MESSAGE(state, action){
            if(action.payload?.type in state.api_status){
                state.api_status[action.payload.type] = { "status": 'idle', "message": null }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(API_Fetch_Currency_List_Setting.pending, (state) => {
                state.api_status.currency.status = 'loading';
            })
            .addCase(API_Fetch_Currency_List_Setting.fulfilled, (state, action) => {
                state.api_status.currency.status = 'success';
                if (Array.isArray(action.payload)) {
                    let defaultCurrencyValue = null;
                    state.currencyList = action.payload?.map((currency) => {
                        if(currency.code === "INR"){
                            defaultCurrencyValue = currency._id;
                        }
                        return ({ "label": currency.displayLabel, "value": currency._id, "code": currency.code })
                    })
                    state.active_global_settings.currency = defaultCurrencyValue;
                    state.previous_global_settings = state.active_global_settings;
                }
            })
            .addCase(API_Fetch_Currency_List_Setting.rejected, (state, action) => {
                state.api_status.currency = { status: 'failed', message: action.error.message }
            })
            .addCase(API_Populate_Currency_Setting.pending, (state) => {
                state.api_status.currency.status = 'loading';
            })
            .addCase(API_Populate_Currency_Setting.fulfilled, (state, action) => {
                state.api_status.currency.status = 'success';
            })
            .addCase(API_Populate_Currency_Setting.rejected, (state, action) => {
                state.api_status.currency = { status: 'failed', message: action.error.message }
            })
            .addCase(API_Create_Global_Setting.pending, (state) => {
                state.api_status.global_setting.status = 'loading';
            })
            .addCase(API_Create_Global_Setting.fulfilled, (state, action) => {
                state.api_status.global_setting.status = 'success';
                state.active_global_settings = state.previous_global_settings;
                state.api_status.global_setting.httpType="create"
            })
            .addCase(API_Create_Global_Setting.rejected, (state, action) => {
                state.api_status.global_setting = { status: 'failed', message: action.error.message }
            })
            .addCase(API_Patch_Global_Setting.pending, (state) => {
                state.api_status.global_setting.status = 'loading';
            })
            .addCase(API_Patch_Global_Setting.fulfilled, (state, action) => {
                state.api_status.global_setting.status = 'success';
                state.active_global_settings = state.previous_global_settings;
                state.api_status.global_setting.httpType="patch"
                state.isEditSetting = false;
                const selectedCurrency = state?.currencyList?.find((currency) => currency?.value === state?.active_global_settings?.currency)
                state.currencySymbol = selectedCurrency?.label?.split(' ')?.[1]?.[1];
            })
            .addCase(API_Patch_Global_Setting.rejected, (state, action) => {
                state.api_status.global_setting = { status: 'failed', message: action.error.message }
            })
            .addCase(API_Fetch_Global_Setting.pending, (state) => {
                state.api_status.global_setting.status = 'loading';
            })
            .addCase(API_Fetch_Global_Setting.fulfilled, (state, action) => {
                state.api_status.global_setting.status = 'success';
                if (Array.isArray(action.payload) === false) {
                    state.previous_global_settings = action.payload;
                    state.active_global_settings = action.payload;
                    state.globalSettingsId = action.payload._id;
                } else {
                    if(action.payload.length === 0) return ;
                    const { _id, currency,  projectionDuration, decimalPlaces, TDSRateVendor, TDSRateEmployee, GSTCredit, FiscalYearStartMonth, FiscalYear, depreciationRate, taxRate} = action.payload[0] || {}
                    state.globalSettingsId = _id
                    state.active_global_settings = {
                        ...state.active_global_settings, 
                        currency: currency,
                        projection_duration: projectionDuration,
                        decimal_places: decimalPlaces,
                        tds_rate_vendor: TDSRateVendor,
                        tds_rate_employee: TDSRateEmployee,
                        gst_credit: GSTCredit,
                        fiscal_start_year: FiscalYearStartMonth,
                        tax_rate: taxRate ? taxRate : 26,
                        depreciationRate: depreciationRate,
                        product_category: state.active_global_settings.product_category.length ? state.active_global_settings.product_category : []
                    }
                    const selectedCurrency = state?.currencyList?.find((curr) => curr?.value === currency)
                    state.currencySymbol = selectedCurrency?.label?.split(' ')?.[1]?.[1];
                    state.previous_global_settings = state.active_global_settings;
                    state.isEditSetting = false;
                }
            })
            .addCase(API_Fetch_Global_Setting.rejected, (state, action) => {
                state.api_status.global_setting = { status: 'failed', message: action.error.message }
            })
    }
});

export const { D_Update_Setting_Mode_Type, D_Store_GlobalSettingData, DCreateChannelName, DPatchChannelName, DResetNotificationMessage, DUpdateSelectOption, DUpdateBMSetting, DControlUserEdit, D_RESET_ERROR_MESSAGE, DResetHttpType } = GlobalSettingSlice.actions;
export default GlobalSettingSlice.reducer;


// .addCase(API_Create_Global_Setting.pending, (state) => {
//     state.api_status.status = 'loading';
// })
// .addCase(API_Create_Global_Setting.fulfilled, (state, action) => {
//     state.api_status.status = 'success';
//     state.previous_global_settings = state.active_global_settings;
// })
// .addCase(API_Create_Global_Setting.rejected, (state, action) => {
//     state.api_status = { status: 'failed', error: action.error.message }
// })
