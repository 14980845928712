import React from 'react'
import HeadingTwo from '../../DesignSystem/TypographyHeadings/HeadingTwo'
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge'
import PGButtonComponent from '../../DesignSystem/Buttons/PGButtonComponent'
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour'
import { company_icons } from '../../assets/website'
import { cn } from '../../utils/cn'
import { useNavigate } from 'react-router-dom'
import ResponsiveHeadingTwoFive from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingTwoFive'
import UserInformationForm from './UserInformationForm'

const MainContentSection = ({ locals, backgroundColor = "#FFFFFF", headingColor = "#000000", textColor = "#757575", shapshot, primaryButtonClass, secondaryButtonClass, className }) => {

    const navigate = useNavigate();
    const { title, power_title, description, req_btn, join_community_btn } = locals || {};

    return (
        <div
            style={{ backgroundColor }}
            className={cn('flex flex-col lg:gap-y-[80px] sm:gap-[56px] gap-y-[40px] py-[56px] px-[16px]', className)}
        >

            <div className='max-w-[970px] mx-auto'>

                {
                    power_title
                        ? (
                            <div className='flex justify-center items-center mb-[20px]'>
                                <TextLarge color={'#372EE5'} fontWeight={500} text={power_title} className={'bg-p9 px-[24px] py-[12px] rounded-full'} />
                            </div>
                        ) : null
                }

                <div className='flex flex-col gap-y-[8px] items-center justify-center'>
                    <ResponsiveHeadingTwoFive color={headingColor} text={title} className={'text-center'} />
                    {
                        description
                            ? (
                                <>
                                    <div
                                        style={{ "color": textColor }}
                                        dangerouslySetInnerHTML={{ __html: description }}
                                        className={`text-[16px] tracking-wide font-InterRegular text-center font-medium sm:block hidden`}
                                    ></div>
                                    <div
                                        style={{ "color": textColor }}
                                        dangerouslySetInnerHTML={{ __html: description?.replace('<br/>', '') }}
                                        className={`text-[16px] tracking-wide font-InterRegular text-center font-medium block sm:hidden`}
                                    ></div>
                                </>
                            ) : null
                    }
                    {
                        req_btn || join_community_btn
                            ? (
                                <div className='flex sm:flex-row flex-col gap-[24px] mt-[20px]'>
                                    {
                                        req_btn
                                            ? <UserInformationForm>
                                                {
                                                    ({ handleTogglePopup }) => {
                                                        return (
                                                            <PGButtonComponent onClick={() => req_btn?.is_form ? handleTogglePopup({ "form_name": req_btn.form_name }) : navigate(req_btn?.path_link)} className={primaryButtonClass}>{req_btn.cta_name}</PGButtonComponent>
                                                        )
                                                    }
                                                }
                                            </UserInformationForm>
                                            : null
                                    }
                                    {
                                        join_community_btn
                                            ? <UserInformationForm>
                                                {
                                                    ({ handleTogglePopup }) => {
                                                        return (
                                                            <PGButtonComponent onClick={() => join_community_btn?.is_form ? handleTogglePopup({ "form_name": join_community_btn.form_name }) : null} className={secondaryButtonClass} intent={"secondary"}>{join_community_btn.cta_name}</PGButtonComponent>
                                                        )
                                                    }
                                                }
                                            </UserInformationForm>
                                            : null
                                    }
                                </div>
                            ) : null
                    }
                </div>

            </div>

            {
                shapshot
                    ? <img src={shapshot} alt="" />
                    : null
            }

        </div>
    )
}

export default MainContentSection
