//library
import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Table, message } from 'antd';
//assets
import white_plus from "../../../assets/common/white_plus.svg"
//components
import OopsPopUp from '../../reuseablecomponent/PopUpVarients/OopsPopUp';
//utils
//redux actions
import TableColumnHeading from '../../BusinessModal/subcomponents/ROFormComponents/components/TableColumnHeading';
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';
import RenderComponent from './RenderComponent';
import { D_Add_Table_Row } from '../gtmSlice';
import TextExtraLarge from '../../../DesignSystem/TypographyDescriptions/TextExtraLarge';
import TextMedium from '../../../DesignSystem/TypographyDescriptions/TextMedium';
import { postNewFeatureProgressApi } from '../../maindashboardstepper/stepperProgressSlice';
import useCheckStatusStepperProgress from "../../../utils/checkStatusStepperProgress";
import RedirectModuleToCardView from '../../reuseablecomponent/RedirectModuleToCardView';
import WarningLimitedResourceOopsPopup from '../../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';
import useApp from '../../../hooks/useApp';


// const localization = locals.gtm_strategy.gtm;

const isDataFilledForActivityComplete = ({ module_type, payloadData }) => {
    let isDataFilled = true;
    if (module_type === "marketing_funnel") {
        const targetDataFilled = {
            "TOFU": 0,
            "MOFU": 0,
            "BOFU": 0
        }
        payloadData?.map((r) => {
            targetDataFilled[r.funnel_stage] = 1;
        });
        Object.entries(targetDataFilled).map((d) => {
            if (d[1] === 0) {
                isDataFilled = false;
            }
        })
    } else {
        isDataFilled = payloadData?.length > 3;
    }
    return isDataFilled;
}


const EditableTable = ({ payloadData = [], localization, handleOnChangeInput, module_type, handleApplyColorForRow, row_colors, createRow, handleDeleteTag, PAYWALL_LOCALS, isRestrictRowAddition }) => {
    const dispatch = useDispatch()
    const currencyList = useSelector((state) => state.gtm.gtm_strategy.currencyList)
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [modalContent, setModalContent] = React.useState({});
    const featureName = module_type === "gtm_plan" ? "GTM Plan" : "Marketing Funnel";
    const checkFeatureUsageStatus = useCheckStatusStepperProgress(featureName);
    const permission = useSelector((state) => state.user.permission);
    const { upgradeRenewPaywallPopup } = useApp();

    const TABLE_DATA = payloadData;

    const TABLE_COLUMNS = localization.table.columns?.map((column, columnIndex) => {
        return (
            {
                "fixed": column.fixed,
                "title": <div className='pt-[20px] pb-[13px] pl-[16px]'><TextExtraLarge color='#757575' text={column.title} fontWeight={500} /></div>,
                "dataIndex": column?.name || 250,
                "width": column.width,
                "render": (value, record, rowIndex) => {
                    const CURRENCY = currencyList?.find((c) => record?.currency === c.value)?.label;
                    let readOnlyCell = false;
                    let filteredOptions = column.select_options;
                    if (module_type === "marketing_funnel") {
                        if (!["stage", "funnel_stage"].includes(column.name)) {
                            readOnlyCell = !record.stage || !record.funnel_stage;
                            if ("select_option_list_ref" in column) {
                                filteredOptions = column.select_options.filter((option) => option.funnel_stage === record.funnel_stage);
                            }
                        }
                    }
                    return (
                        <div className='flex justify-between items-center px-[16px] py-[8px]' onClick={() => handleApplyColorForRow(rowIndex)}>
                            <RenderComponent
                                key={`${rowIndex}+${columnIndex}`}
                                readOnlyCell={readOnlyCell}
                                value={value} column={{ ...column, "select_options": filteredOptions }} rowIndex={rowIndex} record={record}
                                handleOnChangeInput={handleOnChangeInput}
                                module_type={module_type}
                                CURRENCY={CURRENCY}
                                handleDeleteTag={(tagName) => handleDeleteTag({ "module_name": module_type, tagName, rowIndex })}
                                payloadData={payloadData}
                                PAYWALL_LOCALS={PAYWALL_LOCALS}
                            />
                        </div>
                    )
                }
            }
        )
    })
    const totalColumnWidth = TABLE_COLUMNS?.reduce((total, column) => total + (column.width || 0), 0);
    const rowClassName = (_, index) => {
        return row_colors?.[index] ?? "white_color"
    }
    return (
        <div>
            {
                modalContent?.description1
                    ? (
                        <OopsPopUp
                            isOpen={isModalOpen}
                            description1={modalContent?.description1}
                            description2={modalContent?.description2}
                            description3={modalContent?.description3}
                            onClick={() => setIsModalOpen((prev) => !prev)}
                        />
                    ) : null
            }
            <div>
                <Table
                    columns={TABLE_COLUMNS}
                    dataSource={TABLE_DATA}
                    bordered
                    scroll={{
                        x: totalColumnWidth
                    }}
                    className='normal_input_table'
                    rowClassName={rowClassName}
                    pagination={{
                        pageSize: TABLE_DATA?.length,
                        position: ['none', 'none'],
                    }}
                    locale={{
                        emptyText: (
                            <div className='py-[1rem] flex flex-col'>
                                <TextMedium fontWeight={500} color='' text={localization.table.no_content.description1} />
                                <TextMedium fontWeight={500} color='' text={localization.table.no_content.description2} />
                                <TextMedium fontWeight={500} color='' text={localization.table.no_content.description3} />
                                <TextMedium fontWeight={500} color='' text={localization.table.no_content?.description4} />
                            </div>
                        )
                    }}
                />
            </div>
            <div className='mt-[24px] flex justify-between'>
                <RedirectModuleToCardView moduleName={"GTMPlan"} />
                <WarningLimitedResourceOopsPopup
                    description1={PAYWALL_LOCALS?.[upgradeRenewPaywallPopup]?.add_stage?.description1}
                    description2={PAYWALL_LOCALS?.[upgradeRenewPaywallPopup]?.add_stage?.description2}
                >
                    {({ handleTogglePopup, isFreeSubscription }) => {
                        return (
                            <PrimaryButton isDisabled={!permission?.edit} onClick={() => {
                                if (isFreeSubscription && payloadData.length===1 && isRestrictRowAddition) {
                                    handleTogglePopup();
                                    return;
                                }
                                if (payloadData.length <= localization.table.max_no_rows) {
                                    const isActivityCompleted = isDataFilledForActivityComplete({ module_type, payloadData })
                                    if (isActivityCompleted && !checkFeatureUsageStatus) {
                                        dispatch(postNewFeatureProgressApi({ "status": 1, "featureName": featureName }))
                                    }
                                    if (TABLE_DATA.length == 0) {
                                        createRow();
                                        return;
                                    }
                                    // dispatch(D_Add_Table_Row({ "module_type": localization.module_type }))
                                    const lastRow = TABLE_DATA?.[TABLE_DATA?.length - 1] || {};
                                    let isPartialRowFilled = false;
                                    for (const prop of Object.entries(lastRow)) {
                                        if (
                                            (!["planId", "marketingFunnelId", "currency"].includes(prop[0]))
                                            &&
                                            (
                                                (Array.isArray(prop[1]) && prop[1].length > 0) ||
                                                (typeof prop[1] === "number" && prop[1] !== 0) ||
                                                (!!prop[1] && (Array.isArray(prop[1]) ? prop[1].length > 0 : false))
                                            )
                                        ) {
                                            isPartialRowFilled = true;
                                        }
                                    }
                                    if (isPartialRowFilled) {
                                        createRow();
                                    } else {
                                        setIsModalOpen(true);
                                        setModalContent(localization.table.parially_filled_data)
                                    }
                                    return;
                                }
                                setIsModalOpen(true);
                                setModalContent(localization.table.max_row_exceed)
                            }} text={localization?.action_button_name?.add_table_row} isIcon={true} icon={white_plus} />
                        )
                    }}
                </WarningLimitedResourceOopsPopup>
                <div></div>
            </div>
        </div>
    )
}

export default EditableTable