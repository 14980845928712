import React from "react";

const MoveArrow = ({ color = "#fff", ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4.83L13.17 8L10 11.17V10H2V6H10V4.83ZM8 0V4H0V12H8V16L16 8L8 0Z"
      fill="#424242"
    />
  </svg>
);
export default MoveArrow;
