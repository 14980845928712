import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { restrictedResourcesForUser } from './data';
import { useNavigate } from 'react-router-dom';
import { getTokenInfoApi } from '../../features/dataRoomSlice';
import { useParams } from 'react-router-dom';
import useApp from '../../hooks/useApp';

const Protected = ({ children }) => {
  const location = useLocation();
  const { isPaymentActive, handleNavigateToHome, handleRestrictAccessModuleDuePayment } = useApp();
  const isAdmin = useSelector((state) => state.user?.value?.isAdmin);
  const token = useSelector((state) => state.user.value.AccessToken);
  const redirectToAskNamePage = useSelector((state) => state.user.redirectToAskNamePage);
  const user = useSelector((state) => state.user);
  const isOnboardingComplete = user.isOnboardingComplete;
  const [role, setRole] = useState(null);

  const { directoryId } = useParams() || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTokenInfo = async () => {
      const response = await dispatch(getTokenInfoApi());
      console.log(user);
      setRole(response?.payload.role);
    };
    fetchTokenInfo();

    console.log("This is the data of the user", user);

    const allowedRoutes = {
      investor: [
        '/product/home/data-room/investor',
        `/product/home/data-room/investor/${directoryId}/files`,
        '/product/home/data-room/qa',
        '/product/home',
      ],
      user: [
        '/product/home/data-room/user',
        `/product/home/data-room/user/${directoryId}/files`,
        '/product/home/data-room/qa',
        '/product/home',
      ],
    };

    const restrictedRoutesForAdmin = [
      '/product/home/data-room/investor',
      '/product/home/data-room/user',
      '/product/home/data-room/investor/files',
      '/product/home/data-room/user/files',
    ];

    if (redirectToAskNamePage) {
      navigate('/product/new/username');
    } else if (!isOnboardingComplete && !isAdmin && user.value.id) {
      navigate('/product');
    } else if (token && token.length) {
      if (isAdmin && restrictedRoutesForAdmin.includes(location.pathname)) {
        navigate('/product/home');
      } else if (!isAdmin) {
        if (restrictedResourcesForUser?.includes(location.pathname)) {
          navigate('/product/home');
        }
        if (role) {
          if (
            (role === 'investor' && !allowedRoutes.investor.includes(location.pathname))
          ) {
            navigate(allowedRoutes.investor[0]);
          } else if (
            (role === 'user' && !allowedRoutes.user.includes(location.pathname))
          ) {
            navigate(allowedRoutes.user[0]);
          }
        }
      }
    } else {
      navigate('/product');
    }
  }, [token, isAdmin, location.pathname, navigate, role, redirectToAskNamePage, isOnboardingComplete, user.value.id, dispatch]);

  return children;
};

export default Protected;
