import React, { useEffect } from 'react';
import { message } from 'antd';
const Notification = ({ type, content, callbackResetStatus }) => {
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {

        const Message = (type, msg) => {
            messageApi.open({
                type,
                content:msg
            });
            const timer = setTimeout(() => {
                callbackResetStatus();
            },2500);
            return () => clearTimeout(timer);
        };

        if(type==="success")Message("success", content.success);
        if(type==="failed")Message("error", content.error);

    }, [type])
    return (
        <React.Fragment>
            {contextHolder}
        </React.Fragment>
    );
};
export default Notification;