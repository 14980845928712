import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'

const TextExtraSmall = ({ fontWeight = 700, color = "#111827", text = "Title", margin, padding, display="inline", className }) => {
  const styles = {
    color: color,
    lineHeight: "140%",
    fontWeight,
    ...generatePadding(padding?.direction, padding?.number),
    ...generateMargin(margin?.direction, margin?.number),
    display: display
  }
  return (
    <React.Fragment>
      <span style={styles} className={`text-[10px] leading-6 font-bold font-InterRegular ${className}`}>{text}</span>
    </React.Fragment>
  )
}

export default TextExtraSmall