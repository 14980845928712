export const downloadBackendFile = ({ response, fileName }) => {
    try {
        const url = URL.createObjectURL(response);
        const downloadLinkElement = document.createElement('a');
        downloadLinkElement.href = url;
        downloadLinkElement.setAttribute('download', fileName);
        document.body.appendChild(downloadLinkElement);
        downloadLinkElement.click();
        document.body.removeChild(downloadLinkElement);
        URL.revokeObjectURL(url);
        return;
    } catch (error) {
        throw new Error(error.message);
    }
}