import React from 'react'
import dayjs from 'dayjs';
import { useSelector, useDispatch } from "react-redux"
import { Table, DatePicker, message } from 'antd';
import CustomNumber from './CustomNumber';
import TableColumnHeading from './TableColumnHeading';
import { storeTableValue } from '../../../revenueSlice';
import TextLarge from '../../../../../DesignSystem/TypographyDescriptions/TextLarge';
import SwitchButton from '../../../../../DesignSystem/Buttons/SwitchButton';
import { toggleTableAttribute } from '../../../revenueSlice'
import { bmFiscalYearValidation } from '../../../functionality/bmFiscalYearValidation';
import { TableToolTip } from './TableColumnHeading';
import { ValidateNumber } from '../../../components/CostOnboarding/utils/ValidateNumber';
import { numberValidation, topLevelRenderNotifcationBasedDecimalValue } from '../../../../../utils/numberValidation';
import appLocals from '../../../../../localization/localization_en_app.json'

const warningMessage = appLocals.app.warning_message;

const ToggleTableAttribute = ({ toggletableAttrProps }) => {
    // console.log(toggletableAttrProps)
    const dispatch = useDispatch()
    const { channel_sales, tableDefination, isChecked } = toggletableAttrProps.data;
    const revenueOnboardingData = useSelector((state) => state.revenueOnboarding.revenueOnboardingData)
    const decimalPlace = useSelector((state) => state.globalSetting.active_global_settings.decimal_places)
    const marketing_spend_fiscal_year = revenueOnboardingData.marketing_spend_per_channel_fiscal_year;
    let COLUMNS = [];
    const IS_DISABLED = toggletableAttrProps.isDisabled
    if(isChecked){
        toggletableAttrProps.data.tableDefination.table.last_6_month.columns.map((column) => {
            if(toggletableAttrProps.data.channel_sales.includes(column.title)){
                COLUMNS = [...COLUMNS, column];
            }
        })
        COLUMNS = [toggletableAttrProps.data.tableDefination.table.last_6_month.columns[0], ...COLUMNS];
    }else{
        COLUMNS = [toggletableAttrProps.data.tableDefination.table.last_month.columns[0]]
        for (const channel of Object.entries(toggletableAttrProps.data.tableData[0])){
            if(["E-Commerce", "Social Media", "Website & Mobile App"].includes(channel[0])){
                COLUMNS = [...COLUMNS, { dataIndex: channel[0], title: channel[0]}]
            }
        }
    }
    // console.log(COLUMNS,"columns")
    const ref = {
        "Last Month Marketing Spend":"last_month_marketing_spend",
        "MoM Spend Growth":"mom_spend_growth_percent"
    }
    let TABLE_COLUMNS = COLUMNS.map((individualColumn, columnIndex) => {
        const columnTooltip = toggletableAttrProps.data.tableDefination.table.last_month.default_row[individualColumn.title]?.tooltip
        return ({
            key: columnIndex,
            title: () => <TableColumnHeading isMandatory={individualColumn.isMandatory} tooltip={columnTooltip} title={individualColumn.title} />,
            fixed: (columnIndex === 0) ? 'left' : '',
            width: 250,
            dataIndex: individualColumn.dataIndex,
            render: (value, record, rowIndex) => {
                const rowTooltip = toggletableAttrProps.data.tableDefination.table.last_month.default_row[value]?.tooltip
                const validator = record.category==="MoM Spend Growth" ? "positive_negative_decimal_number" : "positive_decimal_number"
                const placeholder = isChecked ? individualColumn.placeholder : toggletableAttrProps.data.tableDefination.table.last_month.columns[rowIndex+1].placeholder
                const tooltip = validator==="positive_decimal_number" ? decimalPlace===0 ? warningMessage.positive_number : warningMessage["positive_decimal_number"] : decimalPlace===0 ? warningMessage.positive_negative_without_decimal_above_hundred : warningMessage["positive_negative_percentage_with_decimal"]
                return (
                    columnIndex === 0
                        ? <div className='max-w-[249px] h-[46px] flex justify-between items-center mb-[1px]'>
                            <div className='flex'>
                                { isChecked 
                                ? <sub className='text-red-500 pr-[0.2rem] pl-[0.3rem] text-[1.2rem] pb-[1rem]'>*</sub> 
                                : <sub style={{ visibility: !isChecked && rowIndex === 0 ? "visible" : "hidden" }} className='text-red-500 pl-[0.3rem] text-[1.2rem]'>*</sub> }
                                <div className='pl-[1rem]'>{value}</div>
                            </div>
                            <div style={{ display: isChecked ? "none" : "block" }}>
                                <TableToolTip tooltip={rowTooltip} />
                            </div>
                        </div>
                        : <div className='w-[225px] h-[46px] flex items-center mb-[1px]'>
                            {/* {validator} */}
                            <CustomNumber
                                isReadOnly={IS_DISABLED}
                                constrain={isChecked ? individualColumn.validator : toggletableAttrProps.data.tableDefination.table.last_month.columns[rowIndex+1].validator}
                                value={value}
                                handleOnBlur={(event) => {
                                    if(["positive_decimal_number","positive_negative_decimal_number","positive_number","negative_decimal_number"].includes(validator)){
                                        message.destroy();
                                        const Validation_Status = ValidateNumber({ "validation_type": validator, "value": event.target.value, "decimal_place": decimalPlace })
                                        console.log(Validation_Status)
                                        if(Validation_Status.status){
                                            const percentageCheck = numberValidation({ value: String(Validation_Status.value), placeHolder: placeholder, validator,"isStrictPercentageCheck": individualColumn?.isStrictPercentageCheck || record.category==="MoM Spend Growth" })
                                            if(!percentageCheck.status){
                                                message.open({ type: 'warning', content: percentageCheck.message, duration: 3});
                                                return;
                                            }
                                            dispatch(storeTableValue({ "name": "marketing_spend_per_sales_channel", "value": Number(Validation_Status.value), "rowNumber": rowIndex, "dataIndex": isChecked ? record.category : ref[record.category], attribute: isChecked ? individualColumn.dataIndex : individualColumn.dataIndex }))
                                            return;
                                        }
                                        dispatch(storeTableValue({ "name": "marketing_spend_per_sales_channel", "value": null, "rowNumber": rowIndex, "dataIndex": isChecked ? record.category : ref[record.category], attribute: isChecked ? individualColumn.dataIndex : individualColumn.dataIndex }))
                                        if(event.target.value){
                                            message.open({ type: 'warning', content: tooltip, duration: 3});
                                        }
                                    }
                                }}
                                placeholder={placeholder}
                            />
                        </div>
                )
            }
        })
    });
    const totalColumnWidth = TABLE_COLUMNS?.reduce((total, column) => total + (column.width || 0), 0);
    return (
        <div>
            <div className='mb-[1rem]'>
                <div className='mb-[1rem]'>
                    <DatePicker
                        disabled={IS_DISABLED}
                        allowClear={false}
                        value={!!marketing_spend_fiscal_year ? dayjs(marketing_spend_fiscal_year, ['YYYY/MM']) : null}
                        defaultValue={null}
                        onChange={(date, dateString) => {
                            if (bmFiscalYearValidation(dateString)) {
                                toggletableAttrProps.handleDateSelection({ type: "marketing_spend_per_channel_fiscal_year", value: dateString })
                            } else {
                                toggletableAttrProps.handleDateSelection({ type: "marketing_spend_per_channel_fiscal_year", value: null })
                                message.warning("Please enter a value within the next 5 years or within the past 5 years")
                            }
                        }}
                        placeholder='Select Month'
                        picker="month"
                    />
                    {(revenueOnboardingData.isShowError && marketing_spend_fiscal_year === null) ? <div className='text-sm mt-[0.5rem] text-red-600'>Please fill out fiscal year</div> : null}
                </div>
                <div className='flex gap-x-[8px]'>
                    <TextLarge fontWeight={500} text={tableDefination.switch.left.label} />
                    <SwitchButton checked={isChecked} handleOnChange={() => dispatch(toggleTableAttribute())} />
                    <TextLarge fontWeight={500} text={tableDefination.switch.right.label} />
                </div>
            </div>
            <div>
                <Table
                    columns={TABLE_COLUMNS}
                    dataSource={toggletableAttrProps.data.tableData}
                    scroll={{
                        x:totalColumnWidth
                    }}
                    className='normal_input_table'
                    bordered
                    pagination={{
                        position: ['none', 'none'],
                    }}
                />
            </div>
        </div>
    )
}

export default ToggleTableAttribute