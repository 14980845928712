import React from 'react'

const PredictGrowthLogo = ({ fill="#372EE5" }) => {
    return (
        <svg width="152" height="23" viewBox="0 0 152 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10181_128755)">
                <path d="M33.3288 4.26855H28.9082V17.2163H30.6646V12.6669H33.3288C35.6784 12.6669 37.4494 10.7971 37.4494 8.47592C37.4494 6.14606 35.6784 4.26855 33.3288 4.26855ZM33.3288 10.568H30.6646V6.36856H33.3288C34.6148 6.36856 35.5139 7.17542 35.5139 8.47592C35.5135 9.7674 34.6148 10.568 33.3288 10.568Z" fill={fill} />
                <path d="M40.8645 8.32751V6.63574H39.1074V17.1335H40.8645V11.9259C40.8645 9.66308 42.6313 9.43498 43.1522 9.43498H43.6758V7.33529H43.1522C42.2653 7.33529 41.5677 7.87514 40.8645 8.32751Z" fill={fill} />
                <path d="M48.842 7.57422C46.107 7.57422 44.0449 9.64232 44.0449 12.3836C44.0449 15.17 46.0896 17.1933 48.9075 17.1933C50.6409 17.1933 52.1525 16.4049 52.9519 15.0842L53.2291 14.6273L51.5703 13.6508L51.3061 14.1305C50.8881 14.8926 49.9966 15.3627 48.9236 15.3627C47.5047 15.3627 46.4403 14.4194 46.0764 13.3692H53.5066V12.8113C53.5066 12.7564 53.4686 12.7016 53.4724 12.6464C53.4773 12.57 53.4609 12.494 53.4609 12.4173C53.4612 10.0693 51.8279 7.57422 48.842 7.57422ZM46.0655 11.6188C46.4072 10.2193 47.4639 9.56212 48.842 9.56212C50.1385 9.56212 51.106 10.2193 51.426 11.6188H46.0655Z" fill={fill} />
                <path d="M62.2923 8.67315C61.5892 7.96804 60.483 7.57258 59.3325 7.57258C56.6429 7.57258 54.5605 9.68616 54.5605 12.3819C54.5605 15.0798 56.6307 17.1917 59.3203 17.1917C60.4704 17.1917 61.5892 16.7963 62.292 16.0915V17.2163H64.4005V4.26855L62.292 5.31809V8.67315H62.2923ZM59.4305 15.3458C57.7707 15.3458 56.4714 14.0439 56.4714 12.3816C56.4714 10.7208 57.771 9.41887 59.4305 9.41887C61.0896 9.41887 62.3889 10.7208 62.3889 12.3816C62.3889 14.0439 61.0892 15.3458 59.4305 15.3458Z" fill={fill} />
                <path d="M68.2629 7.68262H66.5059V17.1308H68.2629V7.68262Z" fill={fill} />
                <path d="M67.516 4.47461C66.8596 4.47461 66.3262 4.99953 66.3262 5.64353C66.3262 6.29726 66.8596 6.82878 67.516 6.82878C68.1721 6.82878 68.7066 6.29726 68.7066 5.64353C68.7066 4.99953 68.1721 4.47461 67.516 4.47461Z" fill={fill} />
                <path d="M77.5614 13.9219C77.0841 14.8142 76.1519 15.3471 75.0666 15.3471C73.3793 15.3471 72.1082 14.073 72.1082 12.383C72.1082 10.695 73.3793 9.42019 75.0666 9.42019C76.1683 9.42019 77.1002 9.95588 77.4983 10.8176L77.7441 11.3473L79.3415 10.4166L79.1393 9.97983C78.4511 8.49568 76.8906 7.57324 75.0666 7.57324C72.2947 7.57324 70.2051 9.64134 70.2051 12.3826C70.2051 15.1242 72.2947 17.1923 75.0666 17.1923C76.8505 17.1923 78.4316 16.2855 79.1943 14.8242L79.4255 14.3823L77.8239 13.4318L77.5614 13.9219Z" fill={fill} />
                <path d="M83.7249 5.28418L81.6164 6.19064V7.6866H79.8594V9.43635H81.6164V14.4196C81.6164 15.3188 81.9211 16.0038 82.404 16.4565C82.8711 16.8939 83.56 17.1061 84.4518 17.1061C84.7959 17.1061 85.2467 17.0731 85.672 17.0092L86.1848 16.9401V15.1695L85.5601 15.2202C85.2282 15.2494 84.9008 15.2758 84.6115 15.2758C83.8319 15.2758 83.7249 15.1556 83.7249 14.4193V9.43635H86.1848V7.6866H83.7249V5.28418Z" fill={fill} />
                <path d="M98.488 12.2327H103.207C102.803 13.9828 101.269 15.1701 99.1497 15.1701C96.4527 15.1701 94.4949 13.3183 94.4949 10.6798C94.4949 8.04131 96.4245 6.15791 99.0841 6.15791C100.666 6.15791 102.122 7.00223 102.796 8.28364L103.055 8.78738L104.762 7.7792L104.501 7.32961C103.421 5.45627 101.345 4.29395 99.0845 4.29395C95.364 4.29395 92.5586 7.06645 92.5586 10.743C92.5586 14.4192 95.3518 17.1924 99.1089 17.1924C102.641 17.1924 105.165 14.8323 105.165 11.5797V10.4833H98.488V12.2327Z" fill={fill} />
                <path d="M109.027 8.33081V6.28906H106.918V17.1368H109.027V11.9292C109.027 9.66634 110.615 9.43829 111.135 9.43829H111.838V7.3386H111.135C110.248 7.3386 109.378 7.87845 109.027 8.33081Z" fill={fill} />
                <path d="M116.895 7.57422C114.169 7.57422 112.033 9.6878 112.033 12.3836C112.033 15.0814 114.169 17.1933 116.895 17.1933C119.612 17.1933 121.741 15.0814 121.741 12.3836C121.741 9.6878 119.612 7.57422 116.895 7.57422ZM116.895 15.3474C115.236 15.3474 113.936 14.0455 113.936 12.3832C113.936 10.7224 115.236 9.42047 116.895 9.42047C118.545 9.42047 119.838 10.7224 119.838 12.3832C119.838 14.0455 118.545 15.3474 116.895 15.3474Z" fill={fill} />
                <path d="M131.48 13.5772L129.575 7.68262H127.944L126.039 13.5772L124.23 7.68262H122.238L125.176 17.1308H125.96L126.013 17.3038L126.069 17.1308H126.857L128.759 11.323L130.662 17.1308H131.45L131.506 17.3038L131.559 17.1308H132.343L135.281 7.68262H133.289L131.48 13.5772Z" fill={fill} />
                <path d="M139.25 5.28418L137.493 6.19064V7.6866H135.736V9.43635H137.493V14.4196C137.493 15.3188 137.781 16.0038 138.265 16.4565C138.731 16.8939 139.413 17.1061 140.303 17.1061C140.648 17.1061 141.111 17.0731 141.537 17.0092L142.061 16.9401V15.1695L141.42 15.2202C141.089 15.2494 140.594 15.2758 140.305 15.2758C139.525 15.2758 139.25 15.1556 139.25 14.4193V9.43635H142.061V7.6866H139.25V5.28418H139.25Z" fill={fill} />
                <path d="M148.093 7.5729C147.137 7.5729 146.288 7.83883 145.585 8.34986V4.26855L143.828 5.31802V17.1305H145.585V12.1528C145.585 10.4156 146.431 9.41884 147.924 9.41884C149.141 9.41884 149.802 10.116 149.802 11.3821V17.1305H151.91V11.3821C151.91 9.10396 150.321 7.5729 148.093 7.5729Z" fill={fill} />
                <path d="M21.3393 5.47584L18.3175 18.266C17.4072 22.1251 14.2797 23.0739 11.3771 20.358L8.93879 18.0771C10.5585 16.6836 12.0802 15.1446 13.4015 13.3945C14.2661 12.2471 15.0359 11.0049 15.64 9.65439C16.2411 8.30633 16.6695 6.84717 16.8386 5.33524C16.8557 5.1679 16.8414 4.98945 16.7961 4.81448C16.5921 4.07605 15.8227 3.64207 15.0812 3.84552L14.8671 3.90454L7.20613 6.00423C7.00636 6.06082 6.79858 6.14414 6.60858 6.25628C5.42779 6.94576 5.03106 8.45735 5.72691 9.63634C6.41858 10.8129 7.94068 11.2076 9.12147 10.5185L13.398 8.01991C12.9378 9.38811 12.235 10.7112 11.3809 11.9499C10.2353 13.6156 8.84013 15.1449 7.31908 16.5621L1.7669 11.3687C-1.13575 8.65315 -0.380287 5.48313 3.43226 4.3392L16.058 0.538713C19.8706 -0.606259 22.2496 1.61217 21.3393 5.47584Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_10181_128755">
                    <rect width="152" height="21.8718" fill="white" transform="translate(0 0.237305)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default PredictGrowthLogo