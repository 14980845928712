import React, { useState } from 'react'
import resetIcon from "../../assets/common/resetIcon.svg"

const Paint = ({ isPaintOpen, handleSelectedColor }) => {
    const lastColor = "#CCC"
    const colorPalette = [
        {
            borderColor:"#9121FE",
            bgColor:"#F1E3FF"
        },
        {
            borderColor:"#372EE5",
            bgColor:"#E7EDFF"
        },
        {
            borderColor:"#F00",
            bgColor:"#FFE9E9"
        },
        {
            borderColor:"#00FF5D",
            bgColor:"#F2FFF7"
        },
        {
            borderColor:"#FACC15",
            bgColor:"#FFF9E1"
        },
        {
            borderColor:"#000",
            bgColor:lastColor
        },
    ]
  return (
    <div className={`${(isPaintOpen) ? 'block' : 'hidden'}`}>
        <ul className='absolute -top-[1rem] right-[7.5rem] p-[8px] flex bg-white rounded-[8px] border border-black shadow-lg'>
            {
                colorPalette.map((palette,index) => {
                    return (
                        <li onClick={() => {
                            handleSelectedColor(index==colorPalette.length-1 ? '#FFF' : palette.bgColor);
                        }} style={{backgroundColor:palette.bgColor,borderColor:palette.borderColor}} className={`${index==colorPalette.length-1 && 'relative'} mx-[16px] cursor-pointer w-[24px] h-[24px] border rounded-full`}>
                            {index==colorPalette.length-1 && <img className='absolute top-[22%] left-1/2 -translate-x-1/2' src={resetIcon} alt="" />}
                        </li>
                    )
                })
            }
        </ul>
    </div>
  )
}

export default Paint