import React from 'react'
import module_locals from "../../../localization/localization_en_website.json"
import ModuleTemplate from '../../components/ModuleTemplate';

const PITCH_DECK_LOCALS = module_locals.website.modules['pitch-deck'];

const MPitchDeck = () => {
    return (
        <div>
            <ModuleTemplate
                locals={PITCH_DECK_LOCALS}
            />
        </div>
    )
}

export default MPitchDeck