import React, { useState, useRef } from "react"
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { D_Show_Error_Message } from "../../../costSlice"
import { API_Post_Cost_Screen1_Data, API_Post_Cost_Screen2_Data, API_Post_Cost_Screen3_Data, API_Post_Cost_Screen4_Data, API_Post_Cost_Screen5_Data, API_Post_Cost_Screen6_Data } from "../../../costSlice"
import { API_Process_Cost_Screen1_Data, API_Process_Cost_Screen2_Data, API_Process_Cost_Screen3_Data, API_Process_Cost_Screen4_Data, API_Process_Cost_Screen5_Data, API_Process_Cost_Screen6_Data, D_Reset_Save_Process_Error_Status } from "../../../costSlice"
import { NetworkPayloadPreparation } from "../utils/NetworkPayloadPreparation";
import costScreenLocals from "../../../../../localization/localization_en_cost_screen.json";

const form = costScreenLocals.cost.form;

const useSaveProcessApiData = () => {
    const dispatch = useDispatch();
    const topScroll = useRef(null);
    const bottomScroll = useRef(null);
    const [refreshCounter, setRefreshCounter] = React.useState(0);
    const [traceButtonType, setTraceButtonType] = React.useState("none");
    const networkStatus = useSelector((state) => state.app.networkStatus);
    const [isLoading, setIsLoading] = useState(false);
    const businessModelId = useSelector((state) => state.businessModal.active_BusinessModal_Id);
    const [warningPopUpDetails, setWarningPopUpDetails] = React.useState({ traceButtonClicked: null, isPopUpOpen: false })
    const costScreen = useSelector((state) => state.costScreen)
    const category_list_data = useSelector((state) => state.costScreen.category_list_data)
    const activeScreen = useSelector((state) => state.costScreen.active_screen)
    const api_save_process = useSelector((state) => state.costScreen.screen_save_process_api_status)
    const [traceStepperApiCall, setTraceStepperApiCall] = useState(0);
    React.useEffect(() => {
        topScroll?.current?.scrollIntoView({ behavior: 'smooth' });
        setRefreshCounter(0);
        dispatch(D_Show_Error_Message(false));
    }, [costScreen.active_screen])
    React.useEffect(() => {
        const status = api_save_process.status[activeScreen].post.status;
        if(status==="loading") setIsLoading(true);
        if(status==="success"){
            setIsLoading(false);
            if(traceButtonType==="stepper"){
                if(traceStepperApiCall===0){
                    setTraceStepperApiCall(1);
                    SubmitDataToServer("output")
                }
            }
            if(traceButtonType==="output"){
                bottomScroll?.current?.scrollIntoView({ behavior: 'smooth' });
            }
        }
        if(status==="failed"){
            setIsLoading(false);
            setWarningPopUpDetails((prev) => ({...prev, "isPopUpOpen": true}))
            dispatch(D_Reset_Save_Process_Error_Status());
        }
    }, [api_save_process.counter])

    const SubmitDataToServer = (type) => {
        const screenNumber = costScreen.active_screen
        const payloadData = NetworkPayloadPreparation({ screenNumber, businessModelId , "costScreenData": costScreen, "formItem": form[screenNumber].data, "category_list_data": category_list_data })
        switch(costScreen.active_screen){
            case 0 : {
                if(type==="output"){
                    dispatch(API_Process_Cost_Screen1_Data(payloadData));
                    return;
                }
                dispatch(API_Post_Cost_Screen1_Data(payloadData));
                return;
            }
            case 1 : {
                if(type==="output"){
                    dispatch(API_Process_Cost_Screen2_Data(payloadData));
                    return;
                }
                dispatch(API_Post_Cost_Screen2_Data(payloadData));
                return;
            }
            case 2 : {
                if(type==="output"){
                    dispatch(API_Process_Cost_Screen3_Data(payloadData));
                    return;
                }
                dispatch(API_Post_Cost_Screen3_Data(payloadData));
                return;
            }
            case 3 : {
                if(type==="output"){
                    dispatch(API_Process_Cost_Screen4_Data(payloadData));
                    return;
                }
                dispatch(API_Post_Cost_Screen4_Data(payloadData));
                return;
            }
            case 4 : {
                if(type==="output"){
                    dispatch(API_Process_Cost_Screen5_Data(payloadData));
                    return;
                }
                dispatch(API_Post_Cost_Screen5_Data(payloadData));
                return;
            }
            case 5 : {
                if(type==="output"){
                    dispatch(API_Process_Cost_Screen6_Data(payloadData));
                    return;
                }
                dispatch(API_Post_Cost_Screen6_Data(payloadData));
                return;
            }
        }
    }
    const updateRefreshCounter = () => {
        setRefreshCounter((prev) => prev+1);
    }
    const resetRefreshCounter = () => {
        setRefreshCounter(0);
    }
    const handleSaveButton = () => {
        setRefreshCounter((prev) => prev+1)
        setTraceButtonType("save");
    }
    const isFormFilledAsExpected = (status) => {
        if(refreshCounter===0) return;
        if (!status) {
            if(refreshCounter===0) return;
            // if(traceButtonType==="save") message.warning("Please fill out all mandatory fields to proceed", 2);
            dispatch(D_Show_Error_Message(true));
        }else{
            SubmitDataToServer("save")
            setWarningPopUpDetails({ traceButtonClicked: "save", isPopUpOpen: false })
        }
    }
    const handleUpdateTraceButtonType = (type) => {
        setTraceButtonType(type);
        if(type==="output"){
            SubmitDataToServer(type);
            setWarningPopUpDetails({ traceButtonClicked: "process", isPopUpOpen: false })
        }
        if(type==="stepper"){
            SubmitDataToServer(type);
            setTraceStepperApiCall(0);
        }
    }

    const handleResetWarningPopup = () => {
        setWarningPopUpDetails({ traceButtonClicked: null, isPopUpOpen: false })
    }
    const updatePopupWarning = ({ isPopUpOpen, traceButtonClicked }) => {
        setWarningPopUpDetails((prev) => ({...prev, isPopUpOpen, traceButtonClicked }))
    }
    const updateTraceButtonType = (type) => {
        setTraceButtonType(type)
    }

    return { updateTraceButtonType, updateRefreshCounter, topScroll, bottomScroll, resetRefreshCounter, traceButtonType, refreshCounter, SubmitDataToServer, handleSaveButton, isFormFilledAsExpected, handleUpdateTraceButtonType, warningPopUpDetails, networkStatus, handleResetWarningPopup, updatePopupWarning, isLoading }
}

export default useSaveProcessApiData