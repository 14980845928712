import { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import CloseIcon from '@mui/icons-material/Close';

const VideoModal = ({ children, video }) => {
    const videoRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleToggleModal = () => setIsModalOpen((prev) => !prev)

    useEffect(() => {
        if(videoRef.current){
            if(isModalOpen){
                videoRef.current.src = video;
                videoRef.current.play();
            }else{
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
                videoRef.current.src = '';
            }
        }
    }, [isModalOpen, video])
    return (
        <>
            <Modal
                zIndex={100000}
                open={isModalOpen}
                centered
                style={{
                    minWidth: "75%"
                }}
                className='remove_padding_modal'
                onCancel={() => handleToggleModal()}
            >
                <button className='absolute top-[1rem] right-[1rem] z-10 bg-primary rounded-full p-1 shadow-black/40 shadow-xl' onClick={handleToggleModal}>
                    <CloseIcon className='text-white' />
                </button>
                <video ref={videoRef} autoPlay={isModalOpen} controlsList="nodownload" disablePictureInPicture className="h-full w-full rounded-lg" controls>
                    <source src={isModalOpen ? video : null} type="video/mp4" />
                </video>
            </Modal>
            {typeof children === "function" ? children({ handleToggleModal }) : null}
        </>
    )
}

export default VideoModal