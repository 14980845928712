import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setBearerToken } from '../services/api/api';
import api from '../services/api/api'
import { updateAccessToken } from './userSlice';
import { getActivityEndPoint } from '../services/endPoints/activityAPI';
import routesConfig from "../services/config/routesConfig.json"
import { generateUrlEndPoint } from '../utils/endPointsGeneration';

export const fetchActivity = createAsyncThunk(
    'link/fetchActivity', async (data, { getState, dispatch }) => {
        try {
          const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'stepperProgress', 'activity');
            if (!!urlGenerator) {
                const urlConfig = { data, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
)

const initialState = {
  openKeys:['0'],
  selectedKeys:['0'],
  activeItem:'0',
  collapsed:false,
  status:'idle',
  progress:[]
}

const manageLinkSlice = createSlice({
  name: 'link',
  initialState:initialState,
  reducers: {
    updateMenuItemSelection : (state, action) => {
      state.openKeys = action.payload.openKeys;
      state.selectedKeys = action.payload.selectedKeys;
    },
    updateCollapsedSideBar : (state) => {
      state.collapsed = !state.collapsed;
    },
    updateItemSelection : (state, action) => {
      state.activeItem = action.payload.activeItem;
    },
    controlledUpdateCollapsedSideBar: (state, action) => {
      state.collapsed = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchActivity.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchActivity.fulfilled, (state, action) => {
            state.progress = action.payload.data;
            state.status = 'succeeded';
        })
        .addCase(fetchActivity.rejected, (state, action) => {
            state.status = 'failed';
        })
}
})


export const { updateMenuItemSelection, updateCollapsedSideBar, updateItemSelection, controlledUpdateCollapsedSideBar } = manageLinkSlice.actions
export default manageLinkSlice.reducer