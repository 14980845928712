import React from 'react'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const useDownloadLPDF = () => {

    const pdfref = React.useRef(null);

    const handleDownloadPDF = ({ fileName }) => {
        const input = pdfref.current;
          html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
              orientation: 'landscape',
            });
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 0;
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
            pdf.save(`${fileName ?? download}.pdf`)
        });
    }

    return { handleDownloadPDF, pdfref }

}

export default useDownloadLPDF