import React from 'react'
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const SearchInput = ({ size = "large", placeholder="Search", value, name, onChangeEvent, onBlurEvent, isDisabled, isReadyOnly, isRequired, maxCharLength, pattern, borderColor, preImg, iconSize="1.2rem", className="" }) => {
    return (
        <Input
            addonBefore={<SearchOutlined style={{ fontSize: iconSize }} />} 
            style={{ borderRadius: '6px'}}
            placeholder={placeholder}
            prefix={preImg && <img src={preImg}/>}
            name={name}
            pattern={pattern}
            value={value}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadyOnly}
            onChange={onChangeEvent}
            onBlur={onBlurEvent}
            maxLength={maxCharLength}
            className={className}
            onPressEnter={(e) => e.preventDefault()}
        />
    )
}

export default SearchInput