import React from 'react'
import DescribeFeature from '../../reuseablecomponent/DescribeFeature'
import SelectOption from '../../../DesignSystem/Forms/SelectOption';
import businessModalTypo from "../../../localization/localization_en_business_modal.json"
import TextExtraLarge from '../../../DesignSystem/TypographyDescriptions/TextExtraLarge';
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton';
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton';
import { DUpdateSelectOption, DUpdateBMSetting, DControlUserEdit } from '../globalSettingSlice';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import Notification from '../subcomponents/Notification';
import { useLocation } from "react-router-dom";
import { message, Input } from 'antd';
import { D_Patch_Global_Setting_BM_List } from '../businessModalSlice'
import { API_Fetch_Currency_List_Setting, API_Fetch_Global_Setting, API_Create_Global_Setting, API_Patch_Global_Setting, DResetHttpType, API_Populate_Currency_Setting, DResetNotificationMessage, D_Update_Setting_Mode_Type } from '../globalSettingSlice';
import CustomNumber from '../subcomponents/ROFormComponents/components/CustomNumber';
import appLocals from "../../../localization/localization_en_app.json"
import editPrimary from "../../../assets/common/editPrimary.svg"

const warning_message = appLocals.app.warning_message;

const locals = businessModalTypo.business_modal.settings;

const validateSettingOnSubmit = (settingValue) => {
    let isNull = false;
    if (typeof settingValue === "object") {
        isNull = Object.values(settingValue).every((value) => {
            if (Array.isArray(value)) return true;
            if (typeof value === "number") return true;
            return !!value
        })
    }
    return isNull;
}

const GlobalSetting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [notification, contextHolder] = message.useMessage();
    const lastVisitedRoute = useSelector((state) => state.app.lastVisitedRoute)
    const { isEditSetting, previous_global_settings, currencyList, globalSettingsId, api_status, isSettingViewOnlyMode } = useSelector((state) => state.globalSetting);
    const { list_of_business_modal, active_BusinessModal_Id, MODE_TYPE } = useSelector((state) => state.businessModal)
    const edit_business_model_navigation_screen_id = useSelector((state) => state.app.edit_business_model_navigation_screen_id);
    React.useEffect(() => {
        // dispatch(API_Populate_Currency_Setting()); 
        if (isSettingViewOnlyMode === false) {
            const activeBusinessModel = list_of_business_modal.find((bm) => bm._id === active_BusinessModal_Id)
            if (list_of_business_modal.length > 0 && activeBusinessModel.global_setting === null) {
                (globalSettingsId === null) && dispatch(API_Fetch_Global_Setting());
                (globalSettingsId) && dispatch(D_Patch_Global_Setting_BM_List({ "gs": previous_global_settings }));
            }
        }
    }, [globalSettingsId])
    const handleNavigateToLastVisitedRoute = () => {
        if ([
            '/product/home/business-modal/revenue-onboarding', 
            `/product/home/business-modal/edit/${active_BusinessModal_Id}/revenue-screen}`,
            '/product/home/business-modal/cost-onboarding',
            `/product/home/business-modal/edit/${active_BusinessModal_Id}/cost-screen}`, 
            '/product/home/business-modal/working-capital', 
            `/product/home/business-modal/edit/${active_BusinessModal_Id}/working-capital`,
            '/product/home/business-modal/debt-working',
            `/product/home/business-modal/edit/${active_BusinessModal_Id}/debt-working`, 
            '/product/home/business-modal/cash-flow', 
            `/product/home/business-modal/edit/${active_BusinessModal_Id}/cash-flow`,
            '/product/home/business-modal/balance-sheet',
            `/product/home/business-modal/edit/${active_BusinessModal_Id}/balance-sheet`,
            '/product/home/business-modal/p&l',
            `/product/home/business-modal/edit/${active_BusinessModal_Id}/p&l`
        ].includes(lastVisitedRoute)) {
            navigate(lastVisitedRoute);
        } else {
            navigate('/product/home/business-modal/revenue-onboarding');
        }
    }
    React.useEffect(() => {
        if (api_status.global_setting.status === "success" && (api_status.global_setting.httpType === "create" || api_status.global_setting.httpType === "patch")) {
            handleNavigateToLastVisitedRoute();
        }
        return () => {
            dispatch(DResetHttpType())
            dispatch(D_Update_Setting_Mode_Type(false))
        }
    }, [api_status.global_setting.status])
    const resetNotificationMessage = () => dispatch(DResetNotificationMessage({ type: "global_setting" }));
    return (
        <div style={{ marginTop: location.pathname === "/product/home/settings" ? '0rem' : '4.5rem' }}>
            {contextHolder}
            <Notification type={api_status.global_setting.status} content={api_status.global_setting.message} callbackResetStatus={resetNotificationMessage} />
            {location.pathname !== "/product/home/settings" ? <DescribeFeature text={locals.title} description={locals.description} /> : null}
            <div className='flex justify-end mt-[20px]'>
                <SecondaryButton icon={<img src={!isEditSetting ? editPrimary : null} />} isDisabled={api_status.global_setting.status === "loading" || MODE_TYPE.view} text={!isEditSetting ? "Edit" : "Cancel"} onClick={() => dispatch(DControlUserEdit())} />
            </div>
            <div className='flex flex-col gap-y-[24px] p-[24px] bg-baseGray100 rounded-[8px] mt-[20px]'>
                {
                    locals.form.map((settingFormItem, index) => {
                        const selectOptions = settingFormItem.name === "currency" ? currencyList : settingFormItem.select_option;
                        return (
                            <div key={index} className='flex justify-between rounded-[4px] p-[20px] bg-white'>
                                <TextExtraLarge text={settingFormItem.label} fontWeight={500} />
                                <div className='w-[220px]'>
                                    {
                                        settingFormItem.type === "number_input"
                                            ? (
                                                <div className='w-[220px]'>
                                                    <CustomNumber
                                                        value={previous_global_settings?.[settingFormItem?.name]}
                                                        handleOnBlur={(event) => {
                                                            if(Number(event.target.value)>=0 && Number(event.target.value)<=100){
                                                                dispatch(DUpdateSelectOption({ name: settingFormItem.name, value: Number(event.target.value) }))   
                                                            }else{
                                                                dispatch(DUpdateSelectOption({ name: settingFormItem.name, value: null }))   
                                                                message.warning(warning_message.positive_percentage_without_decimal)
                                                            }
                                                        }}
                                                        isDisabled={!isEditSetting || api_status.global_setting.status === "loading"}
                                                        validationType='positive_limit_no_decimal_value'
                                                        className='gs_disabled'
                                                        fontColor='#111827'
                                                        placeholder={settingFormItem.placeholder}
                                                        suffixIcon={'%'}
                                                        isBordered={true}
                                                        borderColor="#dbdbdb"
                                                    />
                                                </div>
                                            ) : null
                                    }
                                    {
                                        settingFormItem.type === "select"
                                            ? (
                                                <SelectOption
                                                    name={settingFormItem.name}
                                                    value={(settingFormItem.name==="currency" && selectOptions.length===0) ? null : previous_global_settings?.[settingFormItem?.name]}
                                                    isDisabled={!isEditSetting || api_status.global_setting.status === "loading"}
                                                    onChangeEvent={(value) => {
                                                        dispatch(DUpdateSelectOption({ name: settingFormItem.name, value }))
                                                    }}
                                                    listOfOptions={selectOptions}
                                                    placeholder={settingFormItem.placeholder}
                                                />
                                            ) : null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                {
                    isSettingViewOnlyMode === false
                        ? (
                            <div className='flex justify-end mt-[40px]'>
                                <PrimaryButton isDisabled={api_status.global_setting.status === "loading" || currencyList.length===0} isLoading={api_status.global_setting.status === "loading"} text={!!globalSettingsId ? "Next" : MODE_TYPE.view ? "Back" :"Save"} onClick={() => {
                                    if(MODE_TYPE.view){
                                        handleNavigateToLastVisitedRoute();
                                        return;
                                    }
                                    if (validateSettingOnSubmit(previous_global_settings)) {
                                        dispatch(!!globalSettingsId ? API_Patch_Global_Setting() : API_Create_Global_Setting())
                                    } else {
                                        notification.open({ type: "warning", content: "Fill out all the fields to proceed further." })
                                    }
                                }} />
                            </div>
                        )
                        : (
                            <div className='flex justify-center mt-[40px]'>
                                <PrimaryButton
                                    isDisabled={api_status.global_setting.status === "loading"}
                                    isLoading={api_status.global_setting.status === "loading"}
                                    text={"Back"}
                                    onClick={() => navigate('/product/home/business-modal')}
                                />
                            </div>
                        )
                }

            </div>
        </div>
    )
}

export default GlobalSetting