import React, { useEffect, useState } from "react";
import { Button, Checkbox, message, Form, Input } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import checkcircle from "../assets/Login/check-circle.svg";
import Loginpana2 from "../assets/Login/Loginpana2.svg";
import Message from "../assets/Login/Message.svg";
import Lock from "../assets/Login/Lock.svg";
import { Link } from "react-router-dom";
import { signIn, userInfoError, userInfoStatus, getPing } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { userInfo, updateErr } from "../features/userSlice";
import test1 from "../assets/Login/loginvector.svg";
import { useNavigate } from "react-router-dom";
import logotest from "../assets/Header/logotest.svg";
import { updateModelShow } from "../features/spamSlice";
import brand from "../assets/common/brand.svg";
import "./auth/auth.css";
import "../index.css";
import TNC from "../assets/docs/Terms_and_Conditions.pdf";
import PP from "../assets/docs/Privacy_Policy.pdf";
import ForgotPasswordModal from "../components/modals/ForgotPasswordModal";
import loginLocals from "../localization/localization_en_login.json"
import footerLocals from "../localization/localization_en_footer.json"
import AuthHeader from "./components/AuthHeader";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';

const NewLoginPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userStatus = useSelector(userInfoStatus);
  const userinfo = useSelector(userInfo);
  const redirectToAskNamePage = useSelector((state) => state.user?.redirectToAskNamePage);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setEmailValid] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const status = useSelector((state) => state?.user?.status);
  const isShowForgotPassword = useSelector((state) => state?.spam?.model)
  const isOnboardingComplete = useSelector((state) => state.user.isOnboardingCompleted)

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailValid(validateEmail(emailValue));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordVisibilityChange = () => {
    setPasswordVisible((prev) => !prev);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isLoginDisabled = !isEmailValid || password.length === 0;

  const formFields = [
    {
      name: "email",
      label: loginLocals.login.form.email.label,
      className: `resp_login_width ${email && !isEmailValid ? "border-red-500" : ""}`,
      rules: [
        {
          required: true,
          message: "",
        },
      ],
      inputProps: {
        placeholder: loginLocals.login.form.email.placeholder,
        className: `pl-[17px] resp_inp text-[15px] font-InterRegular rounded-[12px] h-[54px] ${email && !isEmailValid ? "border-red-500" : ""
          }`,
        prefix: (
          <EmailIcon fontSize="small" className="text-baseGray500" />
        ),
        suffix: email && isEmailValid && (
          <img className="w-[18px]" src={checkcircle} alt="" />
        ),
        onChange: handleEmailChange,
      },
    },
    {
      name: "password",
      label: loginLocals.login.form.password.label,
      className: "resp_login_width",
      rules: [
        {
          required: true,
          message: "",
        },
      ],
      inputProps: {
        placeholder: loginLocals.login.form.password.placeholder,
        className: "pl-[17px] resp_inp text-[15px] rounded-[12px] h-[54px]",
        prefix: (
          <LockIcon fontSize="small" className="text-baseGray500" />
        ),
        iconRender: (visible) =>
          visible ? (
            <EyeOutlined onClick={handlePasswordVisibilityChange} />
          ) : (
            <EyeInvisibleOutlined onClick={handlePasswordVisibilityChange} />
          ),
        onChange: handlePasswordChange,
        visibilityToggle: true,
        visible: passwordVisible.toString(),
      },
    },
  ];

  const onFinish = (values) => {
    dispatch(signIn(values));
  };

  useEffect(() => {
    dispatch(getPing());
    dispatch(updateErr());
  }, [])

  useEffect(() => {
    if (status === "failed") {
      message.open({
        type: "error",
        content: loginLocals.login.form.error_message.invalidCredentialsMessage
      })
    }
    if (redirectToAskNamePage) {
      navigate("/product/new/username")
    }
    if (status === "succeeded" && userinfo?.id?.length > 0 && !redirectToAskNamePage) {
      form.resetFields();
      if(isOnboardingComplete){
        navigate("/product/home");
      }else{
        navigate("/product/onboarding");
      }
    }
    if (status === "failed") {
      dispatch(updateErr())
    }
  }, [status, redirectToAskNamePage]);

  // console.log(isLoginDisabled, status)
  return (
    <div className="flex">
      {isShowForgotPassword && <ForgotPasswordModal />}
      <div className="absolute hidden top-0 left-0 right-0 md:flex -z-[10000] min-h-full respHideGray">
        <div className="left_shadow bg-white w-[60%]"></div>
        <div className="left_shadow bg-[#FAFAFA] w-[55%]"></div>
      </div>
      <div className="left_part w-[60%] min-h-[99.7vh]">
        <div className="w-[100%] h-[100%]">
          <div className="mx-[64px] flex flex-col justify-between bg-white h-[100%] resWrapper">
            <AuthHeader/>
            <div className="credentials_screen">
              <Form
                initialValues={{
                  email: import.meta.env.VITE_REACT_ENVIRONMENT === "development" ? import.meta.env.VITE_REACT_USER_EMAIL : "",
                  password: import.meta.env.VITE_REACT_ENVIRONMENT === "development" ? import.meta.env.VITE_REACT_USER_PASSWORD : "",
                  remember: true,
                }}
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                className="lg:w-[528px] responsiveForm"
              >
                <div className="inputfields">
                  <p
                    style={{ marginTop: "0px", marginBottom: "24px" }}
                    className="text-[24px] text-[#2C2C2C] font-DMSans font-bold lg:ml-[10px]"
                  >
                    {loginLocals.login.mainTitle}
                  </p>
                  {formFields.map((field) => (
                    <Form.Item
                      key={field?.name}
                      name={field?.name}
                      className={field?.className}
                      rules={field?.rules}
                      style={{ marginTop: "0px", marginBottom: "16px" }}
                    >
                      {field?.name === "password" ? (
                        <Input.Password {...field.inputProps} />
                      ) : (
                        <Input {...field.inputProps} />
                      )}
                    </Form.Item>
                  ))}
                  <div className="flex justify-end">
                    <p onClick={() => {
                      dispatch(updateModelShow(true));
                    }} style={{ marginTop: "0px", marginBottom: "24px" }} className="font-InterRegular md:font-medium text-[12px] tracking-wide cursor-pointer text-primary">
                      {loginLocals.login.forgot_password_link_text}
                    </p>
                  </div>
                  <Form.Item style={{ margin: "0rem 0rem" }}>
                    <Button
                      aria-disabled={isLoginDisabled}
                      className={`${!isLoginDisabled && "btn-onboarding-grad"
                        } text-[16px] respLogin flex justify-center mb-[12px] py-[14px] rounded-xl resp_btn font-semibold font-InterRegular tracking-wide border-none resp_login_width w-full`}
                      htmlType="submit"
                      disabled={isLoginDisabled && status === "loading"}
                      style={{
                        marginBottom: "12px",
                        height: "auto",
                        backgroundColor: true ? "#f5f8ff" : "",
                        color: isLoginDisabled ? "#9693f2" : "white",
                      }}
                      loading={status === "loading"}
                    >
                      {loginLocals.login.login_button_title}
                    </Button>
                  </Form.Item>
                </div>
                <div className="text-start desktopAcc font-InterRegular">
                  <span className="text-[14px] pr-2">
                    {loginLocals.login.dont_have_account_text}
                  </span>
                  <Link
                    to={"/product/signup"}
                    className="text-[#372ee5]"
                  >
                    {loginLocals.login.sign_up_link_text}
                  </Link>
                </div>
              </Form>
            </div>
            <div className="text-start mob_acc font-InterRegular">
              <span className="text-[14px] pr-2">
                {loginLocals.login.dont_have_account_text}
              </span>
              <Link
                to={"/product/signup"}
                className="text-[#372ee5]"
              >
                {loginLocals.login.sign_up_link_text}
              </Link>
            </div>
            <div className="text-[#9E9E9E] footer_left mb-[31px] text-[13px] tracking-wide font-InterRegular">
              {footerLocals.footer.copyright_text}
            </div>
          </div>
        </div>
      </div>
      <div className="right_part w-[40%] bg-[#FAFAFA] flex relative items-center min-h-[99.7vh] h-[10rem]">
        <img src={test1} alt="" className="xl:ml-[35px] w-[70%] h-[70%]" />
        <div className="footer absolute bottom-0 right-[64px] text-[13px] font-InterRegular flex text-[#9E9E9E]">
          <p style={{ marginBottom: "31px" }} className="mr-[24px]">
            <button onClick={() => navigate('/refund-policy')}>{footerLocals.footer.refund_policy}</button>
          </p>
          <p style={{ marginBottom: "31px" }} className="mr-[24px]">
            <button onClick={() => navigate('/terms-and-conditions')}>{footerLocals.footer.terms_and_condition_text}</button>
          </p>
          <p style={{ marginBottom: "31px" }}>
            <button onClick={() => navigate('/privacy-policy')}>{footerLocals.footer.privacy_policy_text}</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewLoginPage;
