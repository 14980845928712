import React, { useEffect, useState } from 'react'
import closeSquare from "./assets/closeSquare.svg"
import { Modal, Button, Radio, Input, Select, message } from "antd";
import { keepUserDetailsData, filterUser, toggleActiveStateOrDeleteUserDataApi, updateuserId, patchUserDetailsDataApi, updateUserDetails, toggleUserSettingModal, resetLoading } from "./settingSlice";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from '../../utils/validation';
import MessageNotification from '../reuseablecomponent/MessageNotification';
import setting_locals from "../../localization/localization_en_setting.json";

const locals = setting_locals.settings.tabs['Users Settings'].modal;

const UserSettingModal = ({ type, isOpen, options }) => {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const heading = type === "Delete" ? locals.delete.title : locals.disable.title;
    const buttonName = type === "Delete" ? locals.delete.danger : locals.disable.title;
    const keepUserDetails = useSelector((state) => state.setting.keepUserDetails);
    const [individualUser, setIndividualUser] = useState(keepUserDetails);
    const loading = useSelector((state) => state.setting.loading);
    const error = useSelector((state) => state.setting.error);
    const activeuserId = useSelector((state) => state.setting.activeuserId)
    const [disabledReason, setDisabledReason] = useState(null);
    const [otherReason, setOtherReason] = useState("");
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setDisabledReason(e.target.value);
    };
    useEffect(() => {
        if (loading === "success") {
            if (type === "edit") {
                dispatch(updateUserDetails({ data: individualUser }));
            }
            dispatch(resetLoading())
            dispatch(toggleUserSettingModal());
        }
        return () => {
            dispatch(updateuserId(null));
            dispatch(keepUserDetailsData(null));
        }
    }, [loading])
    const handleUserDetailsUpdate = () => {
        if (validateEmail(individualUser?.["email"])) {
            dispatch(patchUserDetailsDataApi(individualUser));
        } else {
            messageApi.open({
                type: "warning",
                content: setting_locals.settings.tabs['Users Settings'].warning_msg.email
            })
        }
    }
    return (
        <div className='modal'>
            {loading == "failed" && <MessageNotification type={"error"} status={loading} content={error} />}
            <Modal centered open={isOpen} className='relative'>
                {contextHolder}
                {
                    (type !== "edit") ? (
                        <div>
                            <Button onClick={() => dispatch(toggleUserSettingModal())} type='link' className='absolute h-auto bg-transparent top-[1rem] right-[1rem] cursor-pointer'>
                                <img src={closeSquare} alt="" />
                            </Button>
                            <p className='text-[#111827] font-DMSans text-[18px]'>{heading}</p>
                            {
                                type === "Delete"
                                    ? <div>
                                        <p>{locals.delete.description1}</p>
                                        <p>{locals.delete.description2}</p>
                                    </div>
                                    : <div>
                                        <p>{locals.disable.description}</p>
                                        <div>
                                            <Radio.Group onChange={onChange}>
                                                {
                                                    locals.disable.reason.map((r, i) => {
                                                        return <Radio className='my-[0.2rem]' value={r} key={i}>{r}</Radio>
                                                    })
                                                }
                                            </Radio.Group>
                                        </div>
                                        <div>
                                            <input onChange={(e) => setOtherReason(e.target.value)} disabled={disabledReason!=="Other:"} type="text" placeholder={locals.disable.custom_reason} className='border-b border-primary font-InterRegular text-[14px] p-1 font-medium tracking-wide outline-none ml-[5rem]' />
                                        </div>
                                    </div>
                            }
                            <div className='flex justify-end'>
                                <Button disabled={(type === "Delete") ? false : (disabledReason !== null) ? false : true} loading={loading === "pending"} onClick={() => {
                                    if (type === "Delete") {
                                        dispatch(toggleActiveStateOrDeleteUserDataApi({ userId: activeuserId, isDelete: true, }));
                                        dispatch(filterUser(activeuserId));
                                    } else {
                                        if (disabledReason) {
                                            dispatch(toggleActiveStateOrDeleteUserDataApi({ userId: activeuserId, reason: disabledReason=="Other:" ? otherReason : disabledReason, isActive: false, isDelete: false }));
                                            dispatch(updateUserDetails({ data: { ...individualUser, status: { status: "inactive" } } }));
                                        }
                                    }
                                }} type='primary' className='bg-[#F75555] text-white'>{buttonName}</Button>
                            </div>
                        </div>
                    )
                        : <div>
                            <button onClick={() => dispatch(toggleUserSettingModal())} className='absolute top-[1rem] right-[1rem]'><img src={closeSquare} alt="" /></button>
                            <div className='my-[22px]'>
                                <label htmlFor="username" className='text-primary font-InterRegular text-[16px] font-medium tracking-wide'>{locals.edit.form[0].label}</label>
                                <div className='mt-[8px]'>
                                    <Input value={individualUser?.["username"]} onChange={(e) => {
                                        setIndividualUser((prev) => ({ ...prev, username: e.target.value }));
                                    }} style={{ border: '1px solid #111827', borderRadius: "8px", padding:'8px' }} id="username" placeholder={locals.edit.form[0].placeHolder} />
                                </div>
                            </div>
                            {/* <div className='my-[22px]'>
                                <label htmlFor="email" className='text-primary font-InterRegular text-[16px] font-medium tracking-wide'>{locals.edit.form[1].label}</label>
                                <div className='mt-[8px]'>
                                    <Input value={individualUser?.["email"]} onChange={(e) => {
                                        setIndividualUser((prev) => ({ ...prev, email: e.target.value }));
                                    }} style={{ border: '1px solid #111827', borderRadius: "8px", padding:'8px' }} id="email" placeholder={locals.edit.form[1].placeHolder} />
                                </div>
                            </div> */}
                            {/*<div>
                                <label htmlFor="role" className='text-primary'>Role</label>
                                <div>
                                    <Select onChange={(value) => {
                                        setIndividualUser((prev) => ({...prev,role: { role: value }}))
                                    }} value={individualUser.role.role} placeholder="Role" options={options} />
                                </div>
                                </div>*/}
                            <div className='flex justify-end'>
                                <Button loading={loading === "pending"} onClick={() => {
                                    handleUserDetailsUpdate();
                                    // alert("")
                                }} className='bg-primary text-white py-[8px] px-[22px] text-[16px] font-InterRegular tracking-wide h-auto'>{locals.edit.confirm}</Button>
                            </div>
                        </div>
                }
            </Modal>
        </div>
    )
}

export default UserSettingModal