import React, { useEffect } from 'react'
import { Tabs, Button } from 'antd';
import { useSelector } from "react-redux";
import ICPForm from './ICPForm';
import { useDispatch } from "react-redux";
import { resetError, fetchAnsICPDataApi, postingDefaultAnsICPDataApi, updateTabNumber, updateDefaultValue } from './ICPSlice';
import './icp.css'
import SaveAsModal from './SaveAsModal';
import { toggleModal } from './ICPSlice';
import { useNavigate } from 'react-router-dom';
import MessageNotification from '../reuseablecomponent/MessageNotification';
import PageSpinner from '../reuseablecomponent/PageSpinner';
import icpLocals from "../../localization/localization_en_ideal_customer_profile.json"

const ICPEditFeature = () => {
    const locals = icpLocals.ideal_customer_profile;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const status = useSelector((state) => state.icp.status);
    const activeDocId = useSelector((state) => state.icp.activeDocId);
    const workspace = useSelector((state) => state.icp.workSpace);
    const draftICP = useSelector((state) => state.icp.draftICP);
    const TemplateName = useSelector((state) => state.icp.TemplateName);
    const DraftStatus = useSelector((state) => state.icp.DraftStatus);
    const fetchstatus = useSelector((state) => state.icp.fetchStatus);
    const commingFrom = useSelector((state) => state.icp.commingFrom); 
    const postEditDataErr = useSelector((state) => state.icp.postEditDataErr);
    const isWorkSpaceHasName = useSelector((state) => state.icp.isWorkSpaceHasName);
    const isSaveModalOpen = useSelector((state) => state.icp.modelIsOpen);
    useEffect(() => {
        if (draftICP !== null) {
            if (draftICP[TemplateName].docId) {
                console.log("caling old data");
                dispatch(fetchAnsICPDataApi());
            } else {
                console.log("caling new data");
                dispatch(postingDefaultAnsICPDataApi());
                dispatch(updateDefaultValue());
            }
        }
    }, [DraftStatus])
    useEffect(()=>{
        if(postEditDataErr?.length>3){
            const timer = setTimeout(()=>{
                dispatch(resetError());
            },3000)
            clearTimeout(timer);
        }
    },[postEditDataErr])
    const tabNumber = useSelector((state) => state.icp.tabNumber);
    const items = useSelector((state) => state.icp.items);
    const permission = useSelector((state) => state.user.permission);
    return (
        <div className={`${items?.length === 4 ? 'w-[calc(80%-2rem)]' : 'w-[calc(100%-2rem)]'} pt-[6.5rem] h-screen`}>
            { fetchstatus==="failed" && <MessageNotification status={fetchstatus} content={error} type={"error"} /> }
            { postEditDataErr?.length>0 && <MessageNotification status={status} content={postEditDataErr} type={"error"} /> }
            { fetchstatus==="loading" && <PageSpinner/> }
            { isSaveModalOpen && <SaveAsModal saveType="editSave" /> }
            <h3 className='font-DMSans mb-[16px] text-[18px] font-semibold'>{locals.edit_template.title} <span className='text-primary'>{TemplateName}</span></h3>
            <div>
                <ul className='bg-[#F5F5F5] rounded-[12px] p-[16px] flex items-center justify-between gap-x-[1rem] overflow-x-auto'>
                    {
                        items?.map((item, index) => {
                            return <div key={index} onClick={() => dispatch(updateTabNumber({ type: "independent", independentNumber: index }))} className={`${index === tabNumber ? 'text-primary bg-white rounded-[8px] py-[8px] px-[10px]' : 'text-[#757575]'} font-InterRegular text-[16px] font-medium tracking-wide cursor-pointer min-w-fit`}>{item.label}</div>
                        })
                    }
                </ul>
            </div>
            <ICPForm activeTabName={items !== null ? items[tabNumber]?.label : null} />
            <div className={`pl-[1.5rem] flex justify-between my-[32px]`}>
                <Button onClick={() => {
                    if(tabNumber === 0){
                        navigate('/product/home/buildmarketanalysis/idealcustomerprofile')
                    }else{
                        dispatch(updateTabNumber({ type: "dec" }))
                    }
                }} className={`text-primary border border-primary`}>{locals.direction[0]}</Button>
                <Button disabled={!permission.edit} onClick={() => {
                    if(tabNumber < items?.length - 1){
                        dispatch(updateTabNumber({ type: "inc" }))
                    }else{
                        let checkWorkSpaceName = workspace.find((space,i) => {
                            if(space._id == activeDocId){
                                return true;
                            }
                        })
                        if(!isWorkSpaceHasName){
                            dispatch(toggleModal(true));
                            // console.log("exe")   
                        }else{
                            navigate("/product/home/buildmarketanalysis/idealcustomerprofile");
                        }
                    }
                }} className='bg-primary text-white'>{tabNumber === items?.length - 1 ? locals.direction[2] : locals.direction[1]}</Button>
            </div>
        </div>
    )
}

export default ICPEditFeature