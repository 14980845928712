import React from 'react'
import pg_watermark from "../../assets/common/pg_watermark.svg"
import { terms_condition } from '../data/termsCondition'

const TermsAndCondition = () => {
    return (
        <div>
            <img className='w-[388px] aspect-square absolute top-[10rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <img className='w-[388px] aspect-square absolute top-[100rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <img className='w-[388px] aspect-square absolute top-[200rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <img className='w-[388px] aspect-square absolute top-[300rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <img className='w-[388px] aspect-square absolute top-[400rem] left-1/2 -translate-x-1/2' src={pg_watermark} alt="" />
            <div
                className='tc_pcpy py-[40px] relative mx-[18px] flex flex-col gap-y-[2rem]'
                dangerouslySetInnerHTML={{ __html: terms_condition.content }}
            />
        </div>
    )
}

export default TermsAndCondition