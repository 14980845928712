export const RenderPlaceHolder = ({table, record, column, rowIndex}) => {
    let placeHolder;
    let defaultTargetDataIndexAttribute = "deps_field_name" in table ? table["deps_field_name"] : "name"
    if("custom_units" in table){
        const rowTitle = record?.[defaultTargetDataIndexAttribute]?.split(' ')?.join('_')
        const columnTitle = column.title.split(' ').join('_')
        if(rowTitle===undefined){
            placeHolder = table.custom_units[`_${columnTitle}`]
            return placeHolder;
        }else if(columnTitle==="undefined"){
            return "Default_Placeholder"
        }else{
            const rowColumnName = rowTitle+'_'+columnTitle
            if(!(rowColumnName in table.custom_units)){
                return table.custom_units[`_${columnTitle}`]
            }
            placeHolder = table.custom_units[rowColumnName]
            return placeHolder;
        }
    }
    if("units" in table){
        placeHolder = table?.units[String(rowIndex)];
        return placeHolder;
    }
    return column.placeHolder
}