import basicBS from "./assets/basic/basicBS.svg"
import basicDP from "./assets/basic/basicDP.svg"
import basicDP2 from "./assets/basic/basicDP2.svg"
import basicDP3 from "./assets/basic/basicDP3.svg"
import basicLP from "./assets/basic/basicLP.svg"

import AddSlideSVG from '../../DesignSystem/SVG/AddSlideSVG'
import BgColorSVG from '../../DesignSystem/SVG/BgColorSVG'
import EditSVG from '../../DesignSystem/SVG/EditSVG'
import HomeSVG from '../../DesignSystem/SVG/HomeSVG'
import ImageSVG from '../../DesignSystem/SVG/ImageSVG'
import KitSVG from '../../DesignSystem/SVG/KitSVG'
import PlusSVG from '../../DesignSystem/SVG/PlusSVG'
import PrintSVG from '../../DesignSystem/SVG/PrintSVG'

import bold from "./assets/menu/bold.svg"
import centerAlign from "./assets/menu/centerAlign.svg"
import italic from "./assets/menu/italic.svg"
import leftAlign from "./assets/menu/leftAlign.svg"
import PaintFill from "./assets/menu/PaintFill.svg"
import rightAlign from "./assets/menu/rightAlign.svg"
import underline from "./assets/menu/underline.svg"

export const Templates = Object.freeze({
    "Basic": {
        title: "Basic",
        data: [
            {
                title: "Business Strategy",
                tooltip: "ToolTip Message",
                backgroundImage: basicBS
            },
            {
                title: "Business Strategy",
                tooltip: "ToolTip Message",
                backgroundImage: basicBS
            },
            {
                title: "Dark Presentation Template",
                tooltip: "ToolTip Message",
                backgroundImage: basicDP
            },
            {
                title: "Light Presentation Template",
                tooltip: "ToolTip Message",
                backgroundImage: basicLP
            },
            {
                title: "Dark Presentation Template",
                tooltip: "ToolTip Message",
                backgroundImage: basicDP2
            },
            {
                title: "Dark Presentation Template",
                tooltip: "ToolTip Message",
                backgroundImage: basicDP3
            },
            {
                title: "Light Presentation Template",
                tooltip: "ToolTip Message",
                backgroundImage: basicLP
            },
            {
                title: "Dark Presentation Template",
                tooltip: "ToolTip Message",
                backgroundImage: basicDP2
            },
            {
                title: "Dark Presentation Template",
                tooltip: "ToolTip Message",
                backgroundImage: basicDP3
            },
            {
                title: "Dark Presentation Template",
                tooltip: "ToolTip Message",
                backgroundImage: basicDP3
            },
        ]
    },
    "Industry": {},
    "Funding Stage": {},
    "Marketing": {}
})

export const slides = [
    "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png",
    "https://t4.ftcdn.net/jpg/01/43/23/83/360_F_143238306_lh0ap42wgot36y44WybfQpvsJB5A1CHc.jpg",
    "https://www.guidingtech.com/wp-content/uploads/demo-mode-on-Android.jpg",
    "https://images.ctfassets.net/pdf29us7flmy/2FGoY1OTJEAy7M5myH5XgE/88a4e0fffa926bbcb89510a488690036/Submitting-a-writing-sample-for-job-FINAL-no-paper-02.jpg"
]

export const editSlideShowHeaderData = {
    "HomeSVG": HomeSVG,
    "PlusSVG": PlusSVG,
    "EditSVG": EditSVG,
    "ImageSVG": ImageSVG,
    "KitSVG": KitSVG,
    "BgColorSVG": BgColorSVG,
    "AddSlideSVG": AddSlideSVG,
    "PrintSVG": PrintSVG
}

export const menuIcons = [PaintFill, leftAlign, centerAlign, rightAlign, bold, italic, underline];