import { useState } from 'react'
import { Tooltip } from 'antd'

const ControlledToggleToolTip = ({ children, index, i, isTooltipDisabled, text, placement }) => {
    const [tooltipShowHideCellTrack, setTooltipShowHideCellTrack] = useState({ index: null, i: null });
    const handleToggleToolTipVisibility = (coord) => setTooltipShowHideCellTrack(() => ({ index:coord.index, i:coord.i }))
    return (
        <Tooltip
            open={!isTooltipDisabled && index===tooltipShowHideCellTrack.index && i===tooltipShowHideCellTrack.i}
            placement={placement} 
            title={text} 
            color={"#372EE5"}
            onMouseEnter={() => handleToggleToolTipVisibility({index, i})}
            onMouseLeave={() => handleToggleToolTipVisibility({index:null,i:null})}
        >
            {children}
        </Tooltip>
    )
}

export default ControlledToggleToolTip