import React from 'react'
import module_locals from "../../../localization/localization_en_website.json"
import ModuleTemplate from '../../components/ModuleTemplate';

const BUSINESS_PLANNING_LOCALS = module_locals.website.modules['business-planning'];

const MBusinessPlanning = () => {
    return (
        <div>
            <ModuleTemplate
                locals={BUSINESS_PLANNING_LOCALS}
            />
        </div>
    )
}

export default MBusinessPlanning