import { message } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import BaseModal from '../../../DesignSystem/Modals/BaseModal'
import HeadingFour from '../../../DesignSystem/TypographyHeadings/HeadingFour'
import SecondaryButton from '../../../DesignSystem/Buttons/SecondaryButton'
import PrimaryButton from '../../../DesignSystem/Buttons/PrimaryButton'
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge'
import TextInput from '../../../DesignSystem/Forms/InputVarients/TextInput'

const TextEditModal = ({ heading, subTitle, backCtaName="Cancel", apiStatus, confirmCtaName="Save", placeholder, maxCharLength, errorMessage, initialValue = null, handleOnClick, children, handleResetMsg }) => {

    const [inputText, setInputText] = useState(initialValue);
    const togglePopupRef = useRef(() => { });

    useEffect(() => {
        if (apiStatus === "idle")    return;
        if (apiStatus === "success") togglePopupRef?.current();
        if (apiStatus === "failed") message.open({ "type": "error", "content": errorMessage })
        handleResetMsg();
    }, [apiStatus])

    return (
        <>
            <BaseModal>
                {
                    ({ togglePopup }) => {
                        togglePopupRef.current = togglePopup;
                        return (
                            <div>
                                <HeadingFour text={heading} />
                                <div className='flex flex-col gap-y-[0.5rem] mb-[2rem]'>
                                    <TextLarge text={subTitle} />
                                    <TextInput
                                        value={inputText}
                                        isDisabled={apiStatus==="loading"}
                                        placeholder={placeholder}
                                        maxCharLength={maxCharLength}
                                        onChangeEvent={(e) => setInputText(e.target.value)}
                                    />
                                </div>
                                <div className='flex justify-between'>
                                    <SecondaryButton text={backCtaName} onClick={togglePopup} />
                                    <PrimaryButton
                                        text={confirmCtaName}
                                        isLoading={apiStatus==="loading"}
                                        isDisabled={apiStatus==="loading"}
                                        onClick={() => handleOnClick(inputText)}
                                    />
                                </div>
                            </div>
                        )
                    }
                }
            </BaseModal>
            {children && typeof children === 'function' && children({ togglePopup: togglePopupRef.current })}
        </>
    )
}

export default TextEditModal