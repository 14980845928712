export const passwordValidator = (rule, value, callback) => {
    if (value.length < 8) {
        callback("Password must be at least 8 characters");
    } else if (!/[a-z]/.test(value)) {
        callback("Password must contain at least one lowercase character");
    } else if (!/[A-Z]/.test(value)) {
        callback("Password must contain at least one uppercase character");
    } else if (!/[!@#$%^&()_+{}\[\]:;<>,.?~\\-]/.test(value)) {
        callback("Password must contain at least one special character");
    } else if(!/\d/.test(value)){
        callback("Password must contain at least one number");
    } else {
        callback(); // No errors, validation passed
    }
}

export const passwordValidatorForEnableBtn = (value) => {
    return (
        value.length < 8 ||
        !/[a-z]/.test(value) ||
        !/[A-Z]/.test(value) ||
        !/\d/.test(value) ||
        !/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(value)
    )
}