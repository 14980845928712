import React from 'react'
import PrimaryButton from '../../../../DesignSystem/Buttons/PrimaryButton';
import { useSelector } from "react-redux";
import BarChartAntD from '../../../../DesignSystem/GraphAndCharts/Barchart/BarChartAntD';
import TextExtraLarge from '../../../../DesignSystem/TypographyDescriptions/TextExtraLarge'
import TextSmall from '../../../../DesignSystem/TypographyDescriptions/TextSmall'
import SetStyleLinePlotPoint from '../../../../DesignSystem/GraphAndCharts/Line/SetStyleLinePlotPoint';
import PieChartAntD from '../../../../DesignSystem/GraphAndCharts/Piechart/PieChartAntD';
import DonutChartAntD from '../../../../DesignSystem/GraphAndCharts/Piechart/DonutChartAntD';
import TextMedium from '../../../../DesignSystem/TypographyDescriptions/TextMedium';
import { convertAmountInK } from '../../../../utils/convertAmountInK';


const MetricChart = ({ data_index, isShowPopUpAnalytics, isDataFromModal=false, year, handleStoreChartData, completeData, chartVarient, data, metricName, xAxisLabel, yAxisLabel, colorPalette, metric_sum }) => {
    const CURRENCY = useSelector((state) => state.globalSetting.currencySymbol)
    const [isMouseHovered, setIsMouseHovered] = React.useState(false);

    const totalSum = data?.reduce((total, num) => total+num.value, 0);
    const metricValue = typeof totalSum === "number" ? Number(convertAmountInK(totalSum))?.toFixed(2) || 0 : 0;
    

    return (
        <div
            className='flex flex-col gap-y-[10px]'
            onMouseEnter={() => setIsMouseHovered(true)}
            onMouseLeave={() => setIsMouseHovered(false)}
        >
            {
                isMouseHovered && !isDataFromModal && isShowPopUpAnalytics
                    ? (
                        <div className='w-full h-full absolute bg-white/80 z-10 transition-all'>
                            <div className='absolute -translate-y-1/2 top-1/2 left-1/2 -translate-x-1/2'>
                                <PrimaryButton onClick={() => handleStoreChartData(completeData)} isIcon text={"View Analytics"} />
                            </div>
                        </div>
                    ) : null
            }
            <div className='flex justify-between'>
                <TextExtraLarge text={metricName} />
                <div className='flex flex-col items-center'>
                    {!["financial_ratio"].includes(data_index) ? <TextExtraLarge text={metric_sum[data_index]} color='#372EE5' /> : null}
                    <TextSmall text={year} color='#9E9E9E' />
                </div>
            </div>
            {
                chartVarient === "barChart"
                    ? (
                        <div className=''>
                            <BarChartAntD
                                data={data}
                                colors={colorPalette}
                                xAxisLabel={xAxisLabel}
                                yAxisLabel={yAxisLabel}
                                barPrePostUnitValue={{ "pre": CURRENCY }}
                                yAxisCustomUnitValue={'k'}
                                barDifferenceWidth={0.8}
                                barLabelVisible={false}
                                animation={false}
                            />
                        </div>
                    ) : null
            }
            {
                chartVarient === "multiLineChart"
                    ? (
                        <SetStyleLinePlotPoint
                            animation={false}
                            colors={colorPalette}
                            unitInK={true}
                            yAxisPreUnit={CURRENCY}
                            data={data}
                            yAxisLabel={yAxisLabel}
                            xAxisLabel={xAxisLabel}
                            dataIndexNameXAxis='year'
                            dataIndexNameYAxis='value'
                            dataIndexNameSeries='category'
                        />
                    ) : null
            }
            {
                chartVarient === "pieChart"
                    ? (
                        <PieChartAntD
                            colors={colorPalette}
                            data={data}
                            animation={false}
                        />
                    ) : null
            }
            {
                chartVarient === "donutChart"
                    ? (
                        <DonutChartAntD
                            colors={colorPalette}
                            data={data}
                            animation={false}
                            donutCenterValuePreUnit={CURRENCY}
                        />
                    ) : null
            }
            {
                chartVarient === "renderInformation"
                    ? (
                        <div className='flex flex-col gap-y-[24px] mt-[24px]'>
                            {
                                data.map((data, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                border: `1px solid #4B54FF`,
                                                backgroundColor: index % 2 == 0 ? "#4B54FF" : "#F5F5F5",
                                                borderRadius: '8px',
                                                padding: "12px"
                                            }}
                                            className='flex justify-between'
                                        >
                                            <TextMedium color={index % 2 == 0 ? "#FFFFFF" : "#4B54FF"} text={data.label} />
                                            <TextMedium color={index % 2 == 0 ? "#FFFFFF" : "#4B54FF"} text={data.value} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : null
            }
        </div>
    )
}

export default MetricChart