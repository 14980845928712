import React from 'react'
import HeadingThree from '../../DesignSystem/TypographyHeadings/HeadingThree';
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium';
import PlaneCardMoreInfo from '../../DesignSystem/Cards/website/PlaneCardMoreInfo';
import { useNavigate } from 'react-router-dom'
import ResponsiveHeadingThreeSix from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingThreeSix';

const ServeFounderDetail = ({ locals }) => {

    const navigate = useNavigate();
    const { title, description1, description2, cards } = locals;

    return (
        <div className='xl:mx-[48px] mx-[18px] flex flex-col sm:gap-y-[80px] gap-y-[32px]'>

            <div className='max-w-[724px] flex flex-col justify-center items-center mx-auto gap-y-[0.5rem]'>
                <ResponsiveHeadingThreeSix text={title} color={"#4B54FF"} className={"text-center"}  />
                <TextMedium text={description1} fontWeight={400} className="text-center" />
                <TextMedium text={description2} fontWeight={400} className="text-center" />
            </div>

            <div className='grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 xl:gap-x-[40px] lg:gap-x-[10px] gap-[20px]'>
                {
                    cards.map((card, index) => {
                        const handleOnClick= () => navigate(card.path_link)
                        return (
                            <PlaneCardMoreInfo
                                key={index}
                                handleOnClick={handleOnClick}
                                title={card.title}
                                description={card.description}
                                btn={card.button_name}
                            />
                        )
                    })
                }
            </div>

        </div>
    )
}

export default ServeFounderDetail