import React from 'react'
import { Space } from "antd";
import plusWhite from "../../../assets/common/plusWhite.svg";
import NormalSearch from '../../../DesignSystem/Search/NormalSearch'
import CustomButtonWithPrefixIcon from '../../../DesignSystem/Buttons/CustomButtonWithPrefixIcon';

const SearchAndCreateTemplate = () => {
  const handleCreateTemplate = () => { };
  return (
    <Space direction='horizontal' className='justify-between'>
      <div className='w-[500px]'>
        <NormalSearch placeholder={"Search"} />
      </div>
      <CustomButtonWithPrefixIcon onClick={handleCreateTemplate} size='small' fontWeight={600} btnText={"Create Template"} prefixIcon={plusWhite} />
    </Space>
  )
}

export default SearchAndCreateTemplate