import { months } from "../data/data";
import bmlocals from "../../../localization/localization_en_business_modal.json"

export const payloadPreparation = (type, revenueOnboardingData, formItem, common) => {
    if (type === "table") {
        return (
            {
                "data": revenueOnboardingData[formItem.name],
                "tableRefName": formItem.tableName,
                "table_info_data": formItem.table,
                ...common
            }
        )
    }
}

const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

const namingConvention = {
    last_month: "last_month_marketing_spend",
    last_6_month: "last_six_months",
    last_month_ms: "last_month_marketing_spend",
    month_on_month_ms: "mom_spend_growth_percent",
    "Social Media": "social_media",
    "E-commerce": "ecommerce",
    "Website & Mobile App": "website_mobile_app"
}

const segregateFullOrHalfProductCategoryData = (productCatgYearData, projection_duration, start_year) => {
    let data = [];
    for (let i = 0; i < projection_duration; i++) {
        data.push({ "year": start_year + i, "value": productCatgYearData[`year${i + 1}`] })
    }
    return data;
}

const payloadPreparationProductCategory = ({ supplyChainType, manufacture, buy, product_category_1, business_contribution, returns_cancellations, quantity_per_order, projection_duration, start_year, productCategoryNamingRef }) => {
    const isManufacturer = supplyChainType === "manufacture";
    const manufactureOrBy = isManufacturer ? 'manufacture' : "buy";
    const data = isManufacturer ? manufacture : buy;
    const productCategoryDetails = data.map((productCategory, index) => {
        let productCategoryDetail = {
            "name": productCategoryNamingRef?.[productCategory?.category],
            "id": productCategoryNamingRef?.[productCategory?.category],
            "type": isManufacturer ? "manufacture" : "buy",
            "raw_material_cost": isManufacturer ? productCategory.raw_material_coast : 0,
            "other_direct_cost": isManufacturer ? productCategory.direct_cost : 0,
            "change_yoy_percent": isManufacturer ? productCategory.change_yoy : 0,
            "purchase_price": isManufacturer ? 0 : buy[index].purchase_price,
            "change_yoy_percent": isManufacturer ? 0 : buy[index].change_yoy,
            "tax": product_category_1[manufactureOrBy][index].tax_rate,
            "sales_margin_percent": product_category_1[manufactureOrBy][index].sales_margin,
            "business_contribution": segregateFullOrHalfProductCategoryData(business_contribution[manufactureOrBy][index], projection_duration, start_year),
            "returns_cancellations": segregateFullOrHalfProductCategoryData(returns_cancellations[manufactureOrBy][index], projection_duration, start_year),
            "quantity_per_order": segregateFullOrHalfProductCategoryData(quantity_per_order[manufactureOrBy][index], projection_duration, start_year)
        };
        return productCategoryDetail;
    })
    return productCategoryDetails;
}

export const PayloadPreparationRevenueOnboardingScreen = ({ type, revenueOnboardingData }) => {
    const { channel_sales, acquiring_app_users, marketing_spend_per_sales_channel, marketing_spend_per_channel_fiscal_year, product_categories_details, manufacture_or_buy, product_category, channel_catgeory } = revenueOnboardingData;
    const month_year = marketing_spend_per_channel_fiscal_year?.split('-')
    const month = months[Number(month_year?.[1])]?.value
    const year = Number(month_year?.[0])
    let productCategoryLabel;
    product_category.forEach((pc) => {
        productCategoryLabel = {...productCategoryLabel, [pc.id]: pc.title}
    })
    switch (type) {
        case 0: {
            let dataWithNoNull = {};
            for(const attribute of Object.entries(marketing_spend_per_sales_channel)){
                if(typeof attribute[1] === "object"){
                    const IS_LAST_MONTH_TYPE = marketing_spend_per_sales_channel.type === "last_month_marketing_spend"
                    if(IS_LAST_MONTH_TYPE){
                        dataWithNoNull = {
                            ...dataWithNoNull,
                            [attribute[0]]: {
                                "last_month_marketing_spend": IS_LAST_MONTH_TYPE ? attribute[1].last_month_marketing_spend===null ? 0 : attribute[1].last_month_marketing_spend : null,
                                "mom_spend_growth_percent": IS_LAST_MONTH_TYPE ? attribute[1].mom_spend_growth_percent===null ? 0 : attribute[1].mom_spend_growth_percent : null
                            }
                        }
                    }else{
                        dataWithNoNull = {
                            ...dataWithNoNull,
                            [attribute[0]]: {
                                "last_six_months": attribute[1].last_six_months.map((month) => ({...month, "spend_value": IS_LAST_MONTH_TYPE ? null : month.spend_value===null ? 0 : month.spend_value }))
                            }
                        }
                    }
                }else{
                    dataWithNoNull = { ...dataWithNoNull, [attribute[0]]: attribute[1] }
                }
            }
            return {
                "first_month": Number(month_year[1]),
                "first_year": Number(month_year[0]),
                "channels_of_sales": channel_sales,
                "marketing_spend_per_sales_channel": { ...dataWithNoNull, "first_month": month, "first_year": year },
                "users_acquiring": acquiring_app_users
            }
        }
        case 1: {
            const first_year = Number(month_year[0]);
            return {
                "first_month": Number(month_year[1]),
                "first_year": Number(month_year[0]),
                manufacture_or_buy,
                "first_month": month, "first_year": year,
                "product_categories_details": [
                    ...product_categories_details.manufacture.map((data) => {
                        return {
                            ...data, "name": data.name, "title": productCategoryLabel[data.name], "id": data.name,"dbid": data.name,"type": "manufacture", 
                            "change_yoy_percent": data.change_yoy_percent ? data.change_yoy_percent : 0,
                            "commission_cost": data.commission_cost,
                            "purchase_price": !!data.purchase_price ? data.purchase_price : 0,
                            "platform_commission_cost_varying_cost_over_time": data.platform_commission_cost_varying_cost_over_time,
                            "varying_cost_over_time": !!data.varying_cost_over_time ? data.varying_cost_over_time : 0, "tax": !!data.tax ? data.tax : 0,"sales_margin_percent": !!data.sales_margin_percent ? data.sales_margin_percent : 0,
                            "business_contribution": data.business_contribution.map((subMini, i) => ({...subMini, value: !!subMini.value ? subMini.value : 0, "year": first_year+i})),
                            "returns_cancellations": data.returns_cancellations.map((subMini, i) => ({...subMini, value: !!subMini.value ? subMini.value : 0, "year": first_year+i})),
                            "quantity_per_order": data.quantity_per_order.map((subMini, i) => ({...subMini, value: !!subMini.value ? subMini.value : 0, "year": first_year+i}))
                        }
                    }),
                    ...product_categories_details.buy.map((data) => ({
                        ...data, "name": data.name, "title": productCategoryLabel[data.name], "id": data.name, "dbid": data.name, "type": "buy", "varying_cost_over_time": 0, "raw_material_cost": 0, "other_direct_cost": 0, "varying_cost_over_time": 0,
                        "tax": !!data.tax ? data.tax : 0,"sales_margin_percent": !!data.sales_margin_percent ? data.sales_margin_percent : 0,
                        "commission_cost":data.commission_cost ? data.commission_cost : 0,"platform_commission_cost_varying_cost_over_time":data.platform_commission_cost_varying_cost_over_time ? data.platform_commission_cost_varying_cost_over_time : 0,
                        "business_contribution": data.business_contribution.map((subMini, i) => ({...subMini, value: !!subMini.value ? subMini.value : 0, "year": first_year+i})),
                        "returns_cancellations": data.returns_cancellations.map((subMini, i) => ({...subMini, value: !!subMini.value ? subMini.value : 0, "year": first_year+i})),
                        "quantity_per_order": data.quantity_per_order.map((subMini, i) => ({...subMini, value: !!subMini.value ? subMini.value : 0, "year": first_year+i}))
                    })),
                ]
            }
        }
        case 2: {
            console.log(product_categories_details,"product_categories_details ---debug---");
            return {
                "first_month": Number(month_year[1]),
                "first_year": Number(month_year[0]),
                "marketing_spend_conversion": revenueOnboardingData["marketing_spend_conversion"],
                "platform_commission_cost": [
                    ...product_categories_details.manufacture.map((data) => {
                        const { platform_commission_cost_varying_cost_over_time } = data;
                        return {
                            "name":data.name, "title": productCategoryLabel[data.name], "id": data.name,"dbid": data.name,"type": "manufacture",
                            "commission_cost": data["commission_cost"],
                            "platform_commission_cost_varying_cost_over_time": !!platform_commission_cost_varying_cost_over_time ? platform_commission_cost_varying_cost_over_time : 0
                        }
                    }),
                    ...product_categories_details.buy.map((data) => {
                        const { platform_commission_cost_varying_cost_over_time } = data;
                        return {
                            "name":data.name, "title": productCategoryLabel[data.name], "id": data.name,"dbid": data.name,"type": "buy", 
                            "commission_cost": data["commission_cost"],
                            "platform_commission_cost_varying_cost_over_time": !!platform_commission_cost_varying_cost_over_time ? platform_commission_cost_varying_cost_over_time : 0
                        }
                    })
                ]
            }
        }
        case 3: {
            return {
                "first_month": Number(month_year[1]),
                "first_year": Number(month_year[0]),
                "social_media_marketplaces": revenueOnboardingData["social_media_marketplaces"].map((data) => {
                    const name = channel_catgeory?.find((tag) => tag?.id===data?.channel)?.title;
                    return {...data, "title": name, "id": data.channel, "dbid": data.channel, "varying_cost_over_time": !!data["varying_cost_over_time"] ? data["varying_cost_over_time"] : 0}
                }),
                "organic_paid_traffic_social_media_marketplaces": revenueOnboardingData["organic_paid_traffic_social_media_marketplaces"].map((data) => {
                    return {...data, "varying_cost_over_time": !!data["varying_cost_over_time"] ? data["varying_cost_over_time"] : 0}
                }),
                "organic_traffic_conversion_rate": revenueOnboardingData["organic_traffic_conversion_rate"].map((data) => {
                    return {...data, "varying_cost_over_time": !!data["varying_cost_over_time"] ? data["varying_cost_over_time"] : 0}
                })
            }
        }
        case 4: {
            const user_acquisition_engagement_retention = revenueOnboardingData["key_details_user_acquisition_engagement"];
            return {
                "first_month": Number(month_year[1]),
                "first_year": Number(month_year[0]),
                "user_acquisition_engagement_retention": user_acquisition_engagement_retention.map((subdata) => ({"question":subdata.question,"fixed": !!subdata["fixed"] ? subdata["fixed"] : 0, "varying_amount_over_time": !!subdata["varying_amount_over_time"] ? subdata["varying_amount_over_time"] : 0})),
                "referal_registered_users": {
                    "social_media": revenueOnboardingData["social_media"].map((subdata) => ({...subdata, fixed:!!subdata["fixed"] ? subdata["fixed"] : 0, varying_over_time: !!subdata["varying_over_time"] ? subdata["varying_over_time"] : 0})),
                    "e_commerce": revenueOnboardingData["e_commerce"].map((subdata) => ({...subdata, fixed:!!subdata["fixed"] ? subdata["fixed"] : 0, varying_over_time: !!subdata["varying_over_time"] ? subdata["varying_over_time"] : 0})),
                    "word_of_mouth_1": revenueOnboardingData["word_of_mouth_1"].map((subdata) => ({...subdata, fixed:!!subdata["fixed"] ? subdata["fixed"] : 0, varying_over_time: !!subdata["varying_over_time"] ? subdata["varying_over_time"] : 0})),
                    "word_of_mouth_2": revenueOnboardingData["word_of_mouth_2"].map((subdata) => ({...subdata, fixed:!!subdata["fixed"] ? subdata["fixed"] : 0, varying_over_time: !!subdata["varying_over_time"] ? subdata["varying_over_time"] : 0})),
                },
                "total_mau_and_registered_users": revenueOnboardingData["total_mau_and_registered_users"].map((subdata) => ({...subdata, "new_registered_users": !!subdata.new_registered_users ? subdata.new_registered_users : 0, "total_mau": !!subdata.total_mau ? subdata.total_mau : 0}))
            }
        }
    }
}

export const PayloadPreparationGlobalSetting = ({ globalSetting, currencyCodeValue }) => {
    const { currency, projection_duration, decimal_places, tds_rate_vendor, tds_rate_employee, gst_credit, fiscal_start_year } = globalSetting;
    return {
        "global_settings": {
            "currency": currencyCodeValue?.toLowerCase(),
            "projection_duration": projection_duration,
            "decimal_places": decimal_places,
            "tds_rate_vendor": tds_rate_vendor,
            "tds_rate_employee": tds_rate_employee,
            "gst_credit": gst_credit,
            "fiscal_year_start_month": fiscal_start_year,
            "fiscal_year": (new Date()).getFullYear()
        }
    }
}