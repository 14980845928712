import React, { useState, useEffect } from 'react'
import WarningModal from './WarningModal';
import SingleInputModal from './subModal/SingleInputModal';
import OopsPopUp from '../../components/reuseablecomponent/PopUpVarients/OopsPopUp';

const UpgradePlanOnboarding = ({ handleOnCloseModal, isShowUpgrade }) => {
  const [currentUpgradePlanOnboarding, setcurrentUpgradePlanOnboarding] = useState(0);
  const [maxNumberOfUser, setMaxNumberOfUser] = useState(null);

  useEffect(()=>{
    return () => setMaxNumberOfUser(0);
  },[])

  const moveNextStage = () => setcurrentUpgradePlanOnboarding((prev) => prev + 1);

  return (
    <React.Fragment>
      {
        isShowUpgrade && (
          <React.Fragment>
            <WarningModal
              description1={"To add more users, Please click below to request for upgrade."}
              cancelText={"Do It Later"}
              successText={"Upgrade Now"}
              isOpen={currentUpgradePlanOnboarding === 0}
              handleOnClick1={handleOnCloseModal}
              handleOnClick2={moveNextStage}
              handleOnCloseModal={handleOnCloseModal}
            />
            <SingleInputModal
              mainTitle={"Request For Upgrade"}
              label={"How many users would you like to add ?"}
              borderColor={"#96ADFF"}
              placeholder={"Number of Users"}
              handleOnClick={() => {
                if (!!maxNumberOfUser) {
                  moveNextStage()
                } else {
                  alert();
                }
              }}
              btnText={"Done"}
              value={maxNumberOfUser}
              onChangeEvent={(value) => setMaxNumberOfUser(value)}
              isOpen={currentUpgradePlanOnboarding == 1}
              handleOnCloseModal={handleOnCloseModal}
            />
            <OopsPopUp
              description1={"Thank you for your request. Our team will shortly connect with you."}
              onClick={handleOnCloseModal}
              isOpen={currentUpgradePlanOnboarding == 2}
            />
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

export default UpgradePlanOnboarding