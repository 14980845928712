const LockSVG = ({ color }) => {
    return (
        <svg viewBox="0 0 40 40" fill="none">
            <path d="M27.3735 15.7464V12.1681C27.3735 7.97975 23.9769 4.58308 19.7885 4.58308C15.6002 4.56475 12.1902 7.94475 12.1719 12.1347V12.1681V15.7464" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.139 35.4163H13.404C9.91398 35.4163 7.08398 32.5879 7.08398 29.0963V21.9479C7.08398 18.4563 9.91398 15.6279 13.404 15.6279H26.139C29.629 15.6279 32.459 18.4563 32.459 21.9479V29.0963C32.459 32.5879 29.629 35.4163 26.139 35.4163Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.7728 23.6719V27.3735" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default LockSVG