export const formatpricinglist = (data) => {
    try {
        const inr_list = [];
        const usd_list = [];
        (data?.planList?.allPlans?.items ?? []).map((plan) => {
            const price = `${plan.item.currency === "USD" ? "$" : "₹"} ${(plan.item.amount / 100).toLocaleString()} /-`;
            const spanType = plan.period.slice(0, -2);
            if (plan.item.currency === "USD") {
                usd_list.push({
                    "plan_id": plan.id,
                    "span_type": spanType,
                    "price": `${plan.period.charAt(0).toUpperCase() + plan.period.slice(1)} - ${price}`,
                    "plan_name": plan.item.name
                })
            } else if (plan.item.currency === "INR") {
                inr_list.push({
                    "plan_id": plan.id,
                    "span_type": spanType,
                    "price": `${plan.period.charAt(0).toUpperCase() + plan.period.slice(1)} - ${price}`,
                    "plan_name": plan.item.name
                })
            }
        })
        return {
            inr_list,
            usd_list
        }
    } catch (error) {
        console.log(error);
        return []
    }
}