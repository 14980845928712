import React from 'react'
import { generateMargin } from '../Spacing/Margin'
import { generatePadding } from '../Spacing/Padding'

const CustomText = ({ fontWeight = 700, text, color = "#111827", margin, padding, fontSize, fontVarient, display="inline" }) => {
    const textStyles = {
        color: color,
        fontSize: `${fontSize}px`,
        lineHeight: "140%",
        fontWeight,
        ...generatePadding(padding?.direction, padding?.number),
        ...generateMargin(margin?.direction, margin?.number),
        display: display
    };
    return (
        <React.Fragment>
            <span style={textStyles}
                className={`${fontVarient==="DMSans" ? 'font-DMSans' : 'font-InterRegular'} text-[18px] font-bold`}>{text}</span>
        </React.Fragment>
    )
}

export default CustomText