import { Pie } from "@ant-design/plots";
import { COLOR_PLATE } from "../color-palette"
import { convertAmountInK } from "../../../utils/convertAmountInK";

const DonutChartAntD = ({
    data,
    colors,

    labelPlacement = "outer",

    dataIndexName = "value",
    colorFieldDataIndex = "type",

    donutCenterValuePreUnit="$",
    donutCenterValuePostUnit='k',

    legendPosition="bottom-left",

    animation,

    centerFontSize=25,
    isRespPadding=false,
    isLabelVisible=true,
    legendVisible=true,

    customCenterValue

}) => {
    const isPaddingAttr = isRespPadding ? { padding : 0 } : {}
    const total = data.reduce((sum, entry) => sum + entry.value, 0);
    const config = {
        appendPadding: 10,
        data,
        angleField: dataIndexName,
        colorField: colorFieldDataIndex,
        color: data?.length <= colors?.length ? colors : COLOR_PLATE,
        radius: 1,
        innerRadius: 0.6,
        legend: {
            visible: legendVisible,
            layout: 'horizontal',
            position: legendPosition,
        },
        label: {
            type: labelPlacement,
            offset: isLabelVisible ? '20%' : '0%',
            content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            style: {
                textAlign: 'center',
                fontSize: isLabelVisible ? 12 : 0,
            },
            autoRotate: true,
        },
        tooltip: {},
        interactions: [
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: centerFontSize,
                },
                content: customCenterValue ?? convertAmountInK(total, donutCenterValuePreUnit, donutCenterValuePostUnit)
            },
        },
        ...isPaddingAttr
    };
    return <Pie {...config} animation={animation} />;
};
// convertAmountInK(total, donutCenterValuePreUnit, donutCenterValuePostUnit)
export default DonutChartAntD;
