import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import locals from "../../localization/localization_en_gtm.json"
import DescribeFeature from '../reuseablecomponent/DescribeFeature'
import EditableTable from './components/EditableTable';
import {
    D_Capture_TableData,
    D_Toggle_CatgoryModal,
    D_Create_TagName,
    D_Handle_Delete_Modal,
    D_Patch_TableColor,
    API_Fetch_Default_ChannelList,
    API_Fetch_ChannelList,
    API_Create_GTM_LIST,
    API_Delete_GTM_LIST,
    API_Create_ChannelTag,
    D_Reset_TagStatus,
    API_Update_ChannelTag,
    D_RemoveTag,
    API_Patch_GTM_LIST,
    API_Fetch_GTM_LIST,
    API_DOWNLOAD_GTM_LIST
} from './gtmSlice';
import PopUpFormModal from './components/PopUpFormModal';
import FormList from './components/FormList';
import ListModal from '../BusinessModal/subcomponents/Form/ListModal';
import { API_Fetch_Currency_List } from './gtmSlice';
import { pickCurrencySymbolFromString } from '../../utils/pickCurrencySymbolFromString';
import ConfirmModal from '../../DesignSystem/Modals/ConfirmModal';
import PaintFeature from '../reuseablecomponent/PaintFeature';
import DownloadFeature from '../reuseablecomponent/DownloadFeature';
import paint_brush from '../../assets/PSM/paint.svg';
import useMousePosition from '../../hooks/useMousePosition';
import AIWelcome from '../reuseablecomponent/PopUpVarients/AIWelcome';
import AIButton from '../../DesignSystem/Buttons/AIButton';
import PageLoader from '../reuseablecomponent/PageLoader';
import OopsPopUp from '../reuseablecomponent/PopUpVarients/OopsPopUp';
import { D_ToggleCommentSection } from '../Chat/chatSlice';
import WarningFreeTrialAccessResource from "../reuseablecomponent/PopUpVarients/WarningFreeTrialAccessResource"
import WarningLimitedResourceOopsPopup from '../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';
import { paywall_locals } from '../../localization';
import useApp from '../../hooks/useApp';

const PAYWALL_LOCALS = paywall_locals.gtm;

const gtm_locals = locals.gtm_strategy.gtm;
const download_warning = gtm_locals.warning_popup.no_data_download_file;
const categoryListLocals = gtm_locals.category_modal;

const INITIAL_BUDGET = {
    "budget_allocation_advertisement_campaings_promotion": null,
    "budget_total_expenditure_salaries_wages_benefit_team": null,
    "budget_total_amount_miscellaneous_expenses": null
}

const GTMROW = {
    channel: null,
    currency: null,
    budget_allocation_advertisement_campaings_promotion: null,
    budget_total_expenditure_salaries_wages_benefit_team: null,
    budget_total_amount_miscellaneous_expenses: null,
    priority: "",
    timeline_start_date: null,
    timeline_end_date: null,
    tags: []
}

const GoToMarketPlan = () => {

    const dispatch = useDispatch();
    const { x, y } = useMousePosition()
    const [state, setstate] = React.useState(INITIAL_BUDGET);
    const [tool, setTool] = useState({ "paint": { "isOpen": false, "selectedColor": null }, "download": { "isOpen": false, "downloadType": null } });
    const { table_data, isEditChannelModalOpen, editRowDetails, crud_form_type, rowIndex, categoryModal, isDeleteModalOpen, row_colors, activeTagColor } = useSelector((state) => state.gtm.gtm_strategy.gtm_plan);
    const { currency, create_delete_patch_api_status, categoryList, api_status_module, currencyList } = useSelector((state) => state.gtm.gtm_strategy);
    const [editChannelRowData, setEditChannelRowData] = useState(editRowDetails);
    const [memCurrency, setMemCurrency] = useState(currency);
    const { Title, placeHolder, maxCharLength } = categoryListLocals?.[categoryModal?.type] || {};
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [modalContent, setModalContent] = React.useState({});
    const [isCreateDeleteActionHappening, setIsCreateDeleteActionHappening] = useState({ "type": null });
    const { upgradeRenewPaywallPopup } = useApp();
    const [isShowFormNotFilled, setIsShowFormNotFilled] = useState(false);

    React.useEffect(() => {
        if(create_delete_patch_api_status.status==="success" || create_delete_patch_api_status.status==="failed"){
            setIsCreateDeleteActionHappening({ type: null })
        }
    }, [create_delete_patch_api_status.status])

    React.useEffect(() => {
        dispatch(API_Fetch_Currency_List());
        dispatch(API_Fetch_Default_ChannelList())
        dispatch(API_Fetch_ChannelList())
        dispatch(API_Fetch_GTM_LIST())
        return () => {
            dispatch(D_ToggleCommentSection(false))
        }
    }, []);

    React.useEffect(() => {
        if (crud_form_type === "EDIT") {
            setEditChannelRowData(editRowDetails);
            const targetCurrency = currencyList.find((c) => c.value===editRowDetails.currency)
            setMemCurrency({ "CURRENCY": pickCurrencySymbolFromString(targetCurrency?.label), "value": targetCurrency?.value })
        }
    }, [crud_form_type, rowIndex])

    const handleOnChangeTableInput = ({ data_index, value, rowIndex }) => {
        dispatch(D_Capture_TableData({ data_index, value, rowIndex, "module_type": gtm_locals.module_type }))
        dispatch(API_Patch_GTM_LIST({ ...table_data[rowIndex], [data_index]: value }))
    }

    const handleOnChangeEditFormInput = ({ parent_index, data_index, value }) => {
        if (data_index in editChannelRowData) setEditChannelRowData((prev) => ({ ...prev, [data_index]: value }));
        if (!!parent_index && data_index in editChannelRowData?.[parent_index]) setEditChannelRowData((prev) => ({ ...prev, [parent_index]: { ...prev[parent_index], [data_index]: value } }));
    }

    const handleOnCurrencyChange = (curr) => {
        setMemCurrency(() => ({ "CURRENCY": pickCurrencySymbolFromString(curr.label), "value": curr.value }))
    }

    const UpdateBudgetData = ({ name, value, type }) => {
        if (type === "reset") setstate(INITIAL_BUDGET);
        if (name in state) {
            setstate((prev) => ({ ...prev, [name]: value === null ? null : Number(value) }))
        }
    }

    const ApiCallingFunction = (type, arg) => {
        if(categoryModal.type === "gtmTagList"){
            dispatch(API_Patch_GTM_LIST({ ...table_data[categoryModal.rowIndex], "tags": [...table_data[categoryModal.rowIndex].tags, { "tagName": arg.tag, "tagColor": activeTagColor }] }))
            dispatch(D_Create_TagName({ "module_type": gtm_locals.module_type, "tagName": arg.tag }))
            return;
        }
        if(type==="create") dispatch(API_Create_ChannelTag({ channelTitle: arg.tag }))
        if(type==="patch")  dispatch(API_Update_ChannelTag({ channelId: arg.id, channelTitle: arg.tag }))
    }

    const CallbackToggleModal = () => dispatch(D_Toggle_CatgoryModal({ "module_type": gtm_locals.module_type, "isOpen": false, "catgType": null, "rowIndex": null }))
    const CallbackResetNotificationMessage = () => dispatch(D_Reset_TagStatus({ "target_module": categoryModal?.type }))

    const handleToolInteraction = ({ type, isOpen, selectedColor, downloadType }) => {
        setTool((prev) => ({
            ...prev, [type]: type === "paint" ? { isOpen, selectedColor } : { isOpen, downloadType }
        }))
    }

    const handleApplyColorForRow = (rowIndex) => {
        if (tool.paint.isOpen && tool.paint.selectedColor) {
            const colorToClassNameMapping = {
                "#E0C0FF": "purpl_color",
                "#A7C0FF": "viole_color",
                "#FFCACA": "oragn_color",
                "#E4FFEE": "green_color",
                "#FFEEAB": "yello_color",
                "#FFFFFF": "white_color"
            }
            dispatch(D_Patch_TableColor({ "module_type": gtm_locals.module_type, rowIndex, "selectedColor": colorToClassNameMapping[tool.paint.selectedColor] }))
            setTool((prev) => ({
                ...prev, "paint": { "isOpen": false, "selectedColor": null }
            }))
        }
    }
    const handleCreateGtmChannelRow = () => { 
        dispatch(API_Create_GTM_LIST(GTMROW))
        setIsCreateDeleteActionHappening({ type: "create" })
    }
    const handleDeleteGtmChannelRow = () => { 
        if(table_data[rowIndex].planId){
            dispatch(API_Delete_GTM_LIST(table_data[rowIndex].planId))
            setIsCreateDeleteActionHappening({ type: "delete" })
        }
    }
    const handleDeleteTag = ({ module_name, tagName, rowIndex }) => {
        dispatch(D_RemoveTag({ module_name, tagName, rowIndex }))
    }

    const patchRowFormPopup = ({ formData, rowIndex, currency }) => {
        console.log(formData, "formData")
        console.log({ ...table_data[rowIndex], ...formData, currency: currency.value }, "==debug==")
        dispatch(API_Patch_GTM_LIST({ ...table_data[rowIndex], ...formData, currency: currency.value }))
    }

    const modalInjectedSelectOptionLocals = {...gtm_locals.modal.form.form, "row_question": gtm_locals.modal.form.form.row_question.map((q) => "select_option_list_ref" in q ? {...q, "select_options": categoryList[q?.select_option_list_ref]} : q)}
    const injectedSelectOptionLocals = {...gtm_locals, "table": { ...gtm_locals.table, "columns": gtm_locals.table.columns.map((col) => "select_option_list_ref" in col ? {...col, "select_options": categoryList[col?.select_option_list_ref]} : col) }}

    const updateActionButtonType = (type) => setIsCreateDeleteActionHappening({ type })
    return (
        <div className='relative min-h-[100dvh] pt-[4.5rem]' style={{ "cursor": tool.paint.isOpen && tool.paint.selectedColor ? "none" : "default" }}>
            <DescribeFeature text={gtm_locals.title} description={""} />
            <AIWelcome featureName={"gtm"} link={"/product/home/ai/promptin/go-to-market-plan"} />
            <AIButton />
            {
                modalContent?.description1
                ? (
                    <OopsPopUp
                        isOpen={isModalOpen}
                        description1={modalContent?.description1}
                        description2={modalContent?.description2}
                        description3={modalContent?.description3}
                        onClick={() => setIsModalOpen((prev) => !prev)}
                    />
                ) : null
            }
            { isCreateDeleteActionHappening.type==="create" && create_delete_patch_api_status.status==="loading" && <PageLoader /> }
            {
                tool.paint.isOpen && tool.paint.selectedColor
                    ? <img src={paint_brush} alt="" className='z-10 pointer-events-none' style={{ position: "fixed", "top": y, "left": x }} />
                    : null
            }
            <div className='flex items-center justify-end gap-x-[1rem] my-[0.5rem]'>
                {/* <PaintFeature
                    isOpen={tool.paint.isOpen}
                    onClickButton={() => {
                        handleToolInteraction({ type: "paint", isOpen: !tool.paint.isOpen, selectedColor: null })
                    }}
                    onSelectColor={(color) => {
                        handleToolInteraction({ type: "paint", isOpen: true, selectedColor: color })
                    }}
                /> */}
                <WarningLimitedResourceOopsPopup
                    module_data={{ "module": "gtm", "data": table_data }}
                    description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description1}
                    description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description2}
                >
                    {({ handleTogglePopup, isFreeSubscription, accessDownload }) => (
                        <DownloadFeature isOpen={tool.download.isOpen}
                        onClickButton={() => {
                            if(isFreeSubscription || accessDownload){
                                handleTogglePopup();
                                return;
                            }
                            handleToolInteraction({ type: "download", isOpen: !tool.download.isOpen })
                        }}
                        handleDownloadType={(downloadType) => {
                            handleToolInteraction({ type: "download", isOpen: false, downloadType })
                            if(table_data.length===0){
                                setIsModalOpen(true);
                                setModalContent(download_warning)
                                return;
                            }
                            if(downloadType.type === 'csv'){
                                dispatch(API_DOWNLOAD_GTM_LIST(gtm_locals.module_type))
                            }
                        }} />
                    )}
                </WarningLimitedResourceOopsPopup>
            </div>
            <ConfirmModal
                isOpen={isDeleteModalOpen}
                title={gtm_locals.warning_popup.delete_channel.title}
                description1={gtm_locals.warning_popup.delete_channel.description1}
                description2={gtm_locals.warning_popup.delete_channel.description2}
                confirmButtonName={gtm_locals.warning_popup.delete_channel.confirmButtonName}
                isLoading={create_delete_patch_api_status.status==="loading"}
                onCancel={() => dispatch(D_Handle_Delete_Modal({ "module_type": gtm_locals.module_type, "isOpen": false, "rowIndex": null }))}
                onConfirm={() => handleDeleteGtmChannelRow()}
            />
            {/* dispatch(D_Delete_Table_Row({ "module_type": gtm_locals.module_type, rowIndex })) */}
            {
                isEditChannelModalOpen
                    ? <PopUpFormModal
                        formData={crud_form_type === "EDIT" ? editChannelRowData : state}
                        form={modalInjectedSelectOptionLocals}
                        incompleteForm={modalInjectedSelectOptionLocals.incomplete_form}
                        isModalOpen={true}
                        UpdateBudgetData={UpdateBudgetData}
                        CRUDType={crud_form_type}
                        currency={memCurrency}
                        patchRowFormPopup={patchRowFormPopup}
                        toggleShowErrorMessage={() => setIsShowFormNotFilled(true)}
                        toggleHideErrorMessage={() => setIsShowFormNotFilled(false)}
                        children={
                            <FormList
                                channelList={gtm_locals.table.columns[0].select_options}
                                editTempChannelData={editChannelRowData}
                                budgetData={state}
                                UpdateBudgetData={UpdateBudgetData}
                                form={modalInjectedSelectOptionLocals}
                                currency={memCurrency}
                                handleOnCurrencyChange={handleOnCurrencyChange}
                                handleOnChangeEditFormInput={handleOnChangeEditFormInput}
                                isShowFormNotFilled={isShowFormNotFilled}
                            />
                        }
                        module_type={"gtm_plan"}
                        rowIndex={rowIndex}
                    />
                    : null
            }
            <div>
                <EditableTable
                    handleDeleteTag={handleDeleteTag}
                    updateActionButtonType={updateActionButtonType}
                    createRow={handleCreateGtmChannelRow}
                    localization={injectedSelectOptionLocals}
                    payloadData={table_data}
                    row_colors={row_colors}
                    handleOnChangeInput={handleOnChangeTableInput}
                    module_type={gtm_locals.module_type}
                    handleApplyColorForRow={handleApplyColorForRow}
                    PAYWALL_LOCALS={PAYWALL_LOCALS}
                    isRestrictRowAddition={true}
                />
            </div>
            {
                categoryModal.isOpen
                    ? (
                        <ListModal
                            Title={Title}
                            isTagLimitationExist={false}
                            warningDataIndex={categoryModal?.catgType}
                            InputPlaceholder={placeHolder}
                            ListData={categoryList[categoryModal?.type] || []}
                            maxCharLength={maxCharLength}
                            ApiStatus={api_status_module[categoryModal?.type] || { "status": "idle", "message": null }}
                            tooltipMessages={{ warning: 'name exist already in list' }}
                            ApiCallingFunction={ApiCallingFunction}
                            isTagModalOpen={categoryModal.isOpen}
                            CallbackToggleModal={CallbackToggleModal}
                            CallbackResetNotificationMessage={CallbackResetNotificationMessage}
                        />
                    ) : null
            }
        </div>
    )
}

export default GoToMarketPlan